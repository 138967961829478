/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, Input, OnInit } from "@angular/core";
import { IFeedResultItem } from "@meta/api-interfaces";
import { MetaHelperService } from "../../../services/metaHelper.service";

export class MetaTimelineTemplatePipelineObjectStatusChanged {
  data: IFeedResultItem<{
    pipeline: {
      id: string;
      name: string;
      link: string;
    };
    current: {
      status: {
        id: number;
        name: string;
        icon: string;
        type: string;
      };
    };
    previous: {
      status: {
        id: number;
        name: string;
        icon: string;
        type: string;
      };
    };
  }>;
}

@Component({
  selector: "meta-timeline-template-pipeline-object-status-changed",
  template: `{{ item.data.entity.name }}
    <a [href]="item.data.entity.link">
      <meta-tag
        [maParams]="{ label: tplLabelPipelineObject, color: colorPipelineObject, colorText: colorText, maxWidth: 250 }"
      ></meta-tag>
    </a>
    wurde
    <span
      class="log-default text-color-{{
        item.data.data.current.status.id === 255 ? 'error' : item.data.data.current.status.type
      }}"
      ><i *ngIf="item.data.data.current.status.icon" class="far fa-{{ item.data.data.current.status.icon }} mr-1"></i
      >{{ item.data.data.current.status.name }}</span
    >

    <ng-template #tplLabelPipelineObject
      ><i class="far fa-{{ item.data.entity.icon }}"></i>{{ item.data.entity.title }}</ng-template
    > `,
})
export class MetaTimelineTemplatePipelineObjectStatusChangedComponent implements OnInit {
  @Input() public item: MetaTimelineTemplatePipelineObjectStatusChanged;

  public colorPipelineObject;
  public colorText;

  constructor(public metaHelperService: MetaHelperService) {}

  ngOnInit() {
    this.colorPipelineObject = this.metaHelperService.generateColor(this.item.data.entity.type);
    this.colorText = getComputedStyle(document.documentElement).getPropertyValue("--component-background");
  }
}
