/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { deepMerge } from "../deepMerge";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { ValidatableComponent } from "./validatableComponent";
import { MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";

@MetaFormComponentInfo({
  selector: "ma-slider",
  type: "slider",
  name: "Slider",
})
export class MetaFormSliderComponent extends ValidatableComponent {
  @MetaFormComponentProperty({
    type: "boolean",
    default: false,
    name: "Range",
    description: "Is the slider a range control?",
  })
  public range? = false;
  @MetaFormComponentProperty({
    type: "number",
    default: 0,
    name: "Min",
    description: "Min. value",
  })
  public min?: number;
  @MetaFormComponentProperty({
    type: "number",
    default: 100,
    name: "Max",
    description: "Max. value",
  })
  public max?: number;

  constructor() {
    super();
  }

  public static create<T>(dataSource: T, opts: Partial<MetaFormSliderComponent>) {
    const input = new MetaFormSliderComponent();
    input.setOptions(opts);
    return input;
  }

  public toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        range: this.range,
        min: this.min,
        max: this.max,
      },
    });
  }
}
