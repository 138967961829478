/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { FinalFormatterMiddleware, TolgeePlugin } from "@tolgee/core/src/types";
import { template } from "lodash";
import { FormatIcu } from "@tolgee/format-icu";

const interpolate = /{?{([\s\S]+?)}}?/g;
const testIcu = /{.+, plural/;

export const FormatNested = (): TolgeePlugin => (tolgee, tools) => {
  let simpleFormatter: FinalFormatterMiddleware;
  FormatIcu()(tolgee, {
    setFinalFormatter: (f: FinalFormatterMiddleware) => (simpleFormatter = f),
  } as any);
  tools.setFinalFormatter({
    format: (opts) => {
      if (opts.params && Object.keys(opts.params).length > 0 && opts.translation.includes("{")) {
        const isIcu = testIcu.test(opts.translation);
        if (isIcu) {
          return simpleFormatter.format(opts);
        } else {
          return template(opts.translation, { interpolate })({ ...(opts.params || {}), t: tolgee.t });
        }
      } else {
        return opts.translation;
      }
    },
  });
  return tolgee;
};
