/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, NgModule, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormlyModule } from "@ngx-formly/core";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzStatisticModule } from "ng-zorro-antd/statistic";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaActionHandlerFactory } from "../../base/metaForm/actions/actionHandler.factory";
import { PipesModule } from "../../pipes/pipes.module";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";

export class MetaStatistic {
  defaultValue?: string | number = "---";
  label?: string | number;
  prefixType?: "string" | "icon" = "icon";
  prefix?: string;
  suffixType?: "string" | "icon" = "string";
  suffix?: string;
  color?: "default" | "success" | "danger" | "warning" = "default";
  size?: "default" | "small" = "default";
  align?: "left" | "right" | "center" = "left";
  showGroupWrapper? = false;
  digits?: number;
  shorten?: boolean;
  format?: string;
  showDummy?: boolean;
  hasOnClick?: boolean;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-statistic",
  template: `
    <nz-statistic
      class="{{ ma.size }} {{ ma.align }}"
      [nzTitle]="ma.label"
      nz-tooltip
      nzTooltipTitle="{{ ma.label }}"
      [nzValueTemplate]="valueTpl"
    >
    </nz-statistic>
    <ng-template #valueTpl>
      <ng-container *ngIf="!ma.showDummy">
        <div
          (click)="handleClick($event)"
          role="button"
          class="content-wrapper stat-color-{{ displayData['color'] || 'default' }}"
          [ngClass]="{ clickable: ma.hasOnClick }"
        >
          <ng-container *ngIf="displayData['prefix'] || ma.prefix">
            <i *ngIf="ma.prefixType === 'icon'" class="fal fa-{{ displayData['prefix'] || ma.prefix }}"></i>
            <span *ngIf="ma.prefixType !== 'icon'">{{ displayData["prefix"] || ma.prefix }}</span>
          </ng-container>
          <ng-container *ngIf="!isLoading && ma.format === 'number'">
            {{
              (model[id] !== undefined ? model[id] : ma.defaultValue)
                | metaNumber: { digits: ma.digits, shorten: ma.shorten }
            }}
          </ng-container>
          <ng-container *ngIf="!isLoading && ma.format !== 'number'">
            {{ model[id] !== undefined ? displayData["value"] || model[id] : ma.defaultValue }}
          </ng-container>
          <ng-container *ngIf="(displayData['suffix'] || ma.suffix) && !isLoading">
            <i *ngIf="ma.suffixType === 'icon'" class="fal fa-{{ displayData['suffix'] || ma.suffix }} suffix"></i>
            <span *ngIf="ma.suffixType !== 'icon'" class="suffix">{{ displayData["suffix"] || ma.suffix }}</span>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="ma.showDummy">
        <div class="content-wrapper stat-color-default">
          <ng-container *ngIf="ma.prefix">
            <i *ngIf="!ma.prefixType || ma.prefixType === 'icon'" class="fal fa-{{ ma.prefix }}"></i>
            <span *ngIf="ma.prefixType && ma.prefixType !== 'icon'">{{ ma.prefix }}</span>
          </ng-container>
          <ng-container *ngIf="ma.format === 'number'">
            {{ ma.defaultValue || 0 | metaNumber: { digits: ma.digits, shorten: ma.shorten } }}
          </ng-container>
          <ng-container *ngIf="ma.format !== 'number'">
            {{ ma.defaultValue || 0 }}
          </ng-container>
          <ng-container *ngIf="ma.suffix">
            <i *ngIf="ma.suffixType === 'icon'" class="fal fa-{{ ma.suffix }} suffix"></i>
            <span *ngIf="ma.suffixType !== 'icon'" class="suffix">{{ ma.suffix }}</span>
          </ng-container>
        </div>
      </ng-container>
    </ng-template>
  `,
  styleUrls: ["./metaStatistic.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaStatisticComponent extends MetaComponentBase {
  @ViewChild("clickIcon") clickIcon: ElementRef<HTMLElement>;

  constructor(private readonly _metaActionHandler: MetaActionHandlerFactory) {
    super();
    super.maParams = new MetaStatistic();
  }

  get ma(): MetaStatistic {
    return super.ma;
  }

  public async handleClick($event: MouseEvent) {
    if (this.field && this.ma.hasOnClick) {
      await this._metaActionHandler.executeClickAction({
        formId: this.formState.formId,
        controlId: this.field.id,
        data: this.formState.data(),
        index: this.metaHelperService.getFormlyFieldArrayIndex(this.field),
        subFormPath: this.metaHelperService.getFormlySubFormPath(this.field),
        ctx: this.model?._ctx,
        formIsValid: true,
        passthroughData: { formId: this.formState.formId },
      });
    }
  }
}

@NgModule({
  declarations: [MetaStatisticComponent],
  imports: [
    CommonModule,
    FormlyModule,
    NzWaveModule,
    NzButtonModule,
    NzPopconfirmModule,
    NzStatisticModule,
    NzToolTipModule,
    PipesModule,
    NzSkeletonModule,
  ],
  exports: [MetaStatisticComponent],
})
export class MetaStatisticModule {}
