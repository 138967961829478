/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  EventEmitter,
  forwardRef,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MetaSelectType } from "@meta/enums";
import { FormlyModule } from "@ngx-formly/core";
import { NgxTolgeeModule } from "@tolgee/ngx";
import * as _ from "lodash";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzSelectModeType } from "ng-zorro-antd/select/select.types";
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  firstValueFrom,
  mergeMap,
  shareReplay,
  skip,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import { filter } from "rxjs/operators";
import * as uuid from "uuid";
import { MetaComponentBase, MetaFormBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaActionHandlerFactory } from "../../base/metaForm/actions/actionHandler.factory";
import { MetaFormService } from "../../base/metaForm/metaForm.service";
import { MetaNumberPipe } from "../../pipes/metaNumber.pipe";
import { PipesModule } from "../../pipes/pipes.module";
import { MetaEventService } from "../../services/metaEvents.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaButtonModule } from "../metaButton/metaButton.component";
import { MetaEmptyModule } from "../metaEmpty/metaEmpty.component";
import { MetaLoaderModule } from "../metaLoader/metaLoader.component";
import { MetaSectionModule } from "../metaSection/metaSection.component";
import { MetaTagModule } from "../metaTag/metaTag.component";
import { MetaSelectService, SelectData } from "./metaSelect.service";

export class MetaSelect extends MetaFormBase {
  contextData?: any;
  mode?: NzSelectModeType = "default";
  returnType?: "value" | "object" = "value";
  skip? = 0;
  take? = 50;
  data?: any;
  clearable? = true;
  datasourceLabel? = "label";
  datasourceValue? = "value";
  translateLabels?: boolean;
  selectGroupField?: string;
  dropdownMinWidth?: number;
  appendAndRemoveMode?: boolean;
  onSelect?: boolean;
  onClick?: boolean;
  onBeforeOpen?: boolean;
  selectDataUrl?: string;
  loadDataOnInit?: boolean;
  tagId?: string;
  editForm?: string;
  noDataDescription?: string;
  noDataFoundDescription?: string;
  reloadDataOnOpen?: boolean;
  specPreviewValue?: any[];
  parentFormId?: string = null;
  maxHeight?: number;
  maxTagCount?: number;
  isTableControl?: boolean;
  noRefreshCheck?: boolean;
}

export class IMetaSelectData {
  label: string;
  value: string | number | symbol;
}

export class ILoadSelectData {
  skip: number;
  take: number;
  searchTerm: string;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-select",
  template: `
    <ng-container *ngIf="editing && !ma.readonly; else outputTpl">
      <nz-select
        id="selectEle"
        *ngIf="field; else ngModelTpl"
        [compareWith]="compare"
        nzAllowClear
        nzShowSearch
        [nzMode]="ma.mode"
        [nzDropdownRender]="renderTpl"
        [nzNotFoundContent]="notFoundTemplate"
        [nzServerSearch]="true"
        [nzDropdownMatchSelectWidth]="true"
        [nzDropdownStyle]="{ minWidth: ma?.dropdownMinWidth ? ma?.dropdownMinWidth + 'px' : 'inherit' }"
        [nzCustomTemplate]="ma.mode === metaSelectType.single ? singleTemplate : multipleTemplate"
        [nzOptionOverflowSize]="15"
        [nzOptionHeightPx]="ma.selectGroupField ? 54 : 32"
        [nzMaxTagCount]="ma.maxTagCount"
        [nzPlaceHolder]="ma.placeholder || 'Wählen...'"
        [formControl]="fc"
        [formlyAttributes]="field"
        (nzOnSearch)="search($event)"
        (nzScrollToBottom)="scrollToBottom()"
        (nzOpenChange)="open($event)"
        [nzOpen]="expanded"
        (ngModelChange)="onModelChange($event)"
        [ngClass]="{
          'overflow-scroll': ma.maxHeight && !expanded,
          'overflow-expand': ma.maxHeight && expanded
        }"
        [ngStyle]="{ 'max-height': ma.maxHeight && !expanded ? ma.maxHeight + 'px' : 'inherit' }"
      >
        <nz-option
          *ngFor="let option of ma?.data || (metaSelectService.selectData$ | async); trackBy: trackById"
          [nzDisabled]="option?.disabled || option?.locked === 1 || option?.locked === true"
          [nzValue]="ma.returnType === 'value' || ma.returnType === 'tags' ? option[ma.datasourceValue] : option"
          [nzLabel]="ma.translateLabels ? (option[ma.datasourceLabel] | translate) : option[ma.datasourceLabel]"
          [nzCustomContent]="true"
        >
          <span [ngClass]="{ add: option.new, 'text-danger': option?.locked === 1 || option?.locked === true }"
            ><i
              class="fas fa-lock"
              [style.paddingLeft.px]="8"
              *ngIf="option?.locked === 1 || option?.locked === true"
            ></i
            ><span *ngIf="option?.locked === 1 || option?.locked === true">&nbsp;</span
            ><span *ngIf="option.icon" [innerHTML]="option.icon | sanitizeHtml"></span>
            <span
              [innerHTML]="ma.translateLabels ? (option[ma.datasourceLabel] | translate) : option[ma.datasourceLabel]"
            ></span>
            <span *ngIf="option.new">einladen</span></span
          >
          <div
            *ngIf="
              (option?.primaryItem && option?.primaryItem[ma.selectGroupField]) ||
              option[ma.selectGroupField] ||
              option.groupLabel
            "
            class="select-sub-option"
            [ngClass]="{ locked: option?.locked }"
          >
            {{
              option?.groupLabel ||
                (option?.primaryItem ? option?.primaryItem[ma.selectGroupField] : option[ma.selectGroupField] || "-")
            }}
          </div>
        </nz-option>
      </nz-select>
      <ng-template #multipleTemplate let-selected>
        <div
          (click)="onOptionClick($event, selected)"
          [ngClass]="{
            remove: getOperation(selected?.nzValue[ma.datasourceValue]) === 'remove',
            clickable: ma.appendAndRemoveMode
          }"
          class="ant-select-selection-item-content"
        >
          {{ selected.nzLabel || selected?.nzValue[ma.datasourceLabel] }}
        </div>
      </ng-template>
      <ng-template #singleTemplate let-selected>
        <span
          [ngClass]="{
            'text-danger':
              getSelectMetadata(selected?.nzValue)?.locked === 1 ||
              getSelectMetadata(selected?.nzValue)?.locked === true
          }"
          ><i
            class="fal fa-lock"
            *ngIf="
              getSelectMetadata(selected?.nzValue)?.locked === 1 ||
              getSelectMetadata(selected?.nzValue)?.locked === true
            "
          ></i
          >&nbsp;<span [innerHTML]="selected.nzLabel"></span
        ></span>
      </ng-template>
      <ng-template #ngModelTpl>
        <nz-select
          [compareWith]="compare"
          [nzAllowClear]="ma.clearable"
          nzShowSearch
          [nzMode]="ma.mode"
          [nzDropdownRender]="renderTpl"
          [nzNotFoundContent]="notFoundTemplate"
          [nzServerSearch]="false"
          [nzDropdownMatchSelectWidth]="true"
          [nzCustomTemplate]="ma.mode === metaSelectType.single ? singleTemplate : null"
          [nzDropdownStyle]="{ minWidth: ma?.dropdownMinWidth ? ma?.dropdownMinWidth + 'px' : 'inherit' }"
          [nzOptionOverflowSize]="15"
          [nzOptionHeightPx]="ma.selectGroupField ? 54 : 32"
          [nzPlaceHolder]="ma.placeholder || 'Wählen...'"
          [(ngModel)]="value"
          [nzMaxTagCount]="ma.maxTagCount"
          (ngModelChange)="onNgChange($event)"
          (nzOnSearch)="search($event)"
          (nzScrollToBottom)="scrollToBottom()"
          (nzOpenChange)="open($event)"
          [nzDisabled]="ma.disabled"
        >
          <nz-option
            *ngFor="let option of ma?.data || (metaSelectService.selectData$ | async); trackBy: trackById"
            [nzDisabled]="option?.disabled || option?.locked === 1 || option?.locked === true"
            [nzValue]="ma.returnType === 'value' || ma.returnType === 'tags' ? option[ma.datasourceValue] : option"
            [nzLabel]="ma.translateLabels ? (option[ma.datasourceLabel] | translate) : option[ma.datasourceLabel]"
            [nzCustomContent]="true"
          >
            <span [ngClass]="{ add: option.new, 'text-danger': option?.locked === 1 || option?.locked === true }"
              ><i
                class="fas fa-lock"
                [style.paddingLeft.px]="8"
                *ngIf="option?.locked === 1 || option?.locked === true"
              ></i
              >&nbsp;<span *ngIf="option.icon" [innerHTML]="option.icon | sanitizeHtml"></span>
              {{ ma.translateLabels ? (option[ma.datasourceLabel] | translate) : option[ma.datasourceLabel] }}
              <span *ngIf="option.new">einladen</span></span
            >
            <div
              *ngIf="
                (option?.primaryItem && option?.primaryItem[ma.selectGroupField]) ||
                option[ma.selectGroupField] ||
                option.groupLabel
              "
              class="select-sub-option"
              [ngClass]="{ locked: option?.locked }"
            >
              {{
                option?.groupLabel ||
                  (option?.primaryItem ? option?.primaryItem[ma.selectGroupField] : option[ma.selectGroupField] || "-")
              }}
            </div>
          </nz-option>
        </nz-select>
        <ng-template #singleTemplate let-selected>
          <span
            [ngClass]="{
              'text-danger':
                getSelectMetadata(selected?.nzValue)?.locked === 1 ||
                getSelectMetadata(selected?.nzValue)?.locked === true
            }"
            ><i
              class="fas fa-lock"
              *ngIf="
                getSelectMetadata(selected?.nzValue)?.locked === 1 ||
                getSelectMetadata(selected?.nzValue)?.locked === true
              "
            ></i
            >&nbsp;{{ selected.nzLabel }}</span
          >
        </ng-template>
      </ng-template>
      <ng-template #renderTpl>
        <meta-loader *ngIf="isLoading && !ma.editForm" [@fadeInFromBottom] [maParams]="{ scale: 50 }"></meta-loader>
        <meta-loader
          *ngIf="isLoading && ma.editForm"
          [@fadeInFromBottomWithEdit]
          [maParams]="{ scale: 50 }"
          [style.bottom.px]="30"
        ></meta-loader>
        <ng-container *ngIf="ma.editForm">
          <meta-section
            [maParams]="{
              sectionType: 'hr',
              spacingBottom: 1,
              spacingTop: 1
            }"
          ></meta-section>
          <div class="custom-overlay-container">
            <meta-button
              [maParams]="{
                label: 'Einträge bearbeiten',
                icon: 'pencil-alt',
                size: 'small',
                type: 'primary',
                fullWidth: true
              }"
              (click)="edit()"
              class="btn-save"
            ></meta-button>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-template #outputTpl>
      <p *ngIf="ma.mode === 'default'; else multiOutputTpl" class="output">
        @if (returnDisplayValue(); as displayValue) {
          <span [innerHTML]="displayValue"></span>
          @if (getLink(); as link) {
            <a title="Eintrag Anzeigen" class="select-link" [routerLink]="link"><i class="fal fa-link"></i></a>
          }
        } @else {
          -
        }

        <ng-container *ngIf="ma.onClick">
          <meta-button
            [maParams]="{
              icon: 'bolt',
              iconStyle: 'fas',
              type: 'link',
              size: 'small'
            }"
            [style.display]="'inline-block'"
            (click)="onClick()"
          ></meta-button>
        </ng-container>
      </p>
      <ng-template #multiOutputTpl>
        <p class="output">
          <ng-container *ngIf="ma.specPreviewValue || returnDisplayValue() || [] as items">
            <ng-container *ngIf="items.length === 0">-</ng-container>
            <meta-tag
              *ngFor="let item of items; let i = index"
              [maParams]="{ label: item.label || item.value }"
            ></meta-tag>
          </ng-container>
        </p>
      </ng-template>
    </ng-template>
    <ng-template #notFoundTemplate>
      <meta-empty
        *ngIf="!isLoading"
        [maParams]="{
          icon: 'inbox',
          description:
            search?.length > 0
              ? ma.noDataFoundDescription || 'Keine Daten gefunden ...'
              : ma.noDataDescription || 'Keine Daten gefunden ...'
        }"
      ></meta-empty>
    </ng-template>
  `,
  styleUrls: ["./metaSelect.component.less"],
  providers: [
    MetaSelectService,
    MetaNumberPipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetaSelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaSelectComponent extends MetaComponentBase implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  public _id: string;
  public _itemId: string;
  public searchTerm = new BehaviorSubject<string>("");
  @Output()
  public loadData = new EventEmitter<ILoadSelectData>();
  @Output()
  public dataLoaded = new EventEmitter<SelectData[]>();
  public _skip = new BehaviorSubject<number>(null);
  public metaSelectType = MetaSelectType;
  public lastTriggerValue: any;
  public expanded = false;
  public overflown = false;
  public params: any;
  private _prevModelValue: any;
  private _hasPendingUpdates: boolean;
  // HACK: WeakMap storing values for faster compare
  private compareHack = new WeakMap<any, string>();

  constructor(
    public metaSelectService: MetaSelectService,
    private readonly _metaActionHandler: MetaActionHandlerFactory,
    private readonly _metaEventService: MetaEventService,
    private readonly _metaFormService: MetaFormService,
  ) {
    super();
    super.maParams = new MetaSelect();
    effect(() => {
      if (this.field) {
        const displayData = this.formState.displayData()[this._id];
        if (this.formControl?.value !== undefined || this._hasPendingUpdates) {
          this._addPreviewValueToData(this.formControl.value);
          this._hasPendingUpdates = false;
        }
      }
    });
  }

  get ma(): MetaSelect {
    return super.ma;
  }

  async ngOnInit() {
    super.ngOnInit();
    if (!this.field) {
      this._id = this.ma.id || uuid.v4();
      this._itemId = null;
    } else {
      this._id = this.id;
      this._itemId = this.formState.itemId();
    }

    this.metaSelectService.selectData$.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.dataLoaded.emit(data);
    });

    this.searchTerm
      .pipe(
        skip(1), // Skip initial value from BehaviorSubject
        tap((e) => {
          this.isLoading = true;
          this.changeDetectorRef.markForCheck();
        }),
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
        switchMap((term: string) =>
          this.metaSelectService.getData({
            formId: this.field ? this.formState.formId : this.ma.parentFormId,
            fieldId: this._id,
            skip: 0,
            take: this.ma.take,
            filter: term,
            data: this.field ? this.formState.data() : this.ma.contextData,
            contextId: this.field ? this.formState.data()._ctx : this.ma.contextData?._ctx,
            selectDataUrl: this.ma.selectDataUrl,
            tagId: this.ma.tagId,
            subformPath: this.field ? this.metaHelperService.getFormlySubFormPath(this.field) : undefined,
            index: this.field ? this.metaHelperService.getFormlyFieldArrayIndex(this.field) : undefined,
            refresh: this.ma.reloadDataOnOpen,
            params: this.params,
          }),
        ),
        tap(() => {
          this.isLoading = false;
          this.changeDetectorRef.markForCheck();
        }),
      )
      .subscribe()
      .add(() => {
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
      });

    this._skip
      .pipe(
        skip(1), // Skip initial value from BehaviorSubject
        takeUntil(this.destroyed$),
        tap((e) => {
          this.isLoading = true;
          this.changeDetectorRef.markForCheck();
        }),
        mergeMap((_skip: number) =>
          this.metaSelectService.getData({
            formId: this.field ? this.formState.formId : this.ma.parentFormId,
            fieldId: this._id,
            skip: _skip,
            take: this.ma.take,
            filter: this.searchTerm.getValue(),
            data: this.field ? this.formState.data() : this.ma.contextData,
            contextId: this.field ? this.formState.data()._ctx : this.ma.contextData?._ctx,
            selectDataUrl: this.ma.selectDataUrl,
            tagId: this.ma.tagId,
            subformPath: this.field ? this.metaHelperService.getFormlySubFormPath(this.field) : undefined,
            index: this.field ? this.metaHelperService.getFormlyFieldArrayIndex(this.field) : undefined,
            refresh: this.ma.reloadDataOnOpen,
            params: this.params,
          }),
        ),
        tap(() => {
          this.isLoading = false;
          this.changeDetectorRef.markForCheck();
        }),
      )
      .subscribe()
      .add(() => {
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
      });

    if (this.field) {
      this._metaEventService.changeTrigger$
        .pipe(
          debounceTime(50),
          takeUntil(this.destroyed$),
          filter((x) => x !== undefined && x[this.id] !== undefined && this.returnDisplayValue()),
          distinctUntilChanged((a: any, b: any) => JSON.stringify(a[this.id]) === JSON.stringify(b[this.id])),
        )
        .subscribe({
          next: () => {
            this._hasPendingUpdates = true;
          },
        });
      this.options.formState
        .onFormDataReady()
        .pipe(
          takeUntil(this.destroyed$),
          filter((e) => e !== null && e !== undefined),
        )
        .subscribe(() => {
          this.init();
        });

      this._metaEventService.setFilterTrigger$
        .pipe(
          filter((x) => x !== undefined && x !== null && x.fieldId === this.id),
          debounceTime(50),
          takeUntil(this.destroyed$),
        )
        .subscribe({
          next: (trigger) => {
            this.params = {};
            this.metaSelectService.clearData();
            for (const [key, value] of Object.entries(trigger.filter)) {
              this.params[key] = value;
            }
            this.refresh();
            this.changeDetectorRef.markForCheck();
          },
        });
    }

    if (this.field && this.props.selectDataUrl) {
      this.open(true);
    }
    if (this.ma.loadDataOnInit) {
      this._skip.next(0);
    }

    if (this.field) {
      const pendingUpdatsIndex = this.formState.pendingUpdates.indexOf(this._id);
      if (pendingUpdatsIndex !== -1) {
        await this._executeSelectAction(this.model);
        this.formState.pendingUpdates.splice(pendingUpdatsIndex, 1);
      }
    }
  }

  async ngAfterViewInit() {
    if (!this.field) {
      this.init();
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.maParams.currentValue?.contextData) {
      this.ma.contextData = changes.maParams.currentValue.contextData;
    }
  }

  init() {
    setTimeout(async () => {
      if (this.value || this.field?.formControl.value) {
        this._addPreviewValueToData(this.value || this.field.formControl.value);
      } else if (this.field) {
        this.field.formControl?.valueChanges
          ?.pipe(shareReplay(2), distinctUntilChanged(), takeUntil(this.destroyed$))
          .subscribe({
            next: async (value) => {
              const data = await firstValueFrom(this.metaSelectService.selectData$);
              if (data.length === 0) {
                this._addPreviewValueToData(value);
              } else {
                await this._executeSelectAction(data.find((e) => e[this.ma.datasourceValue]));
              }
              this.lastTriggerValue = value;
            },
          });
        this._metaEventService.changeTrigger$
          .pipe(
            debounceTime(50),
            filter((x) => x !== undefined && x !== null && x[this._id] !== undefined),
            distinctUntilChanged((a: any, b: any) => {
              return JSON.stringify(a[this._id]) === JSON.stringify(b[this._id]);
            }),
            takeUntil(this.destroyed$),
          )
          .subscribe({
            next: async (trigger) => {
              await this._executeSelectAction(trigger[this._id]);
            },
          });
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  public search(term: string): void {
    if (this.searchTerm.value === term) {
      return;
    }

    this.searchTerm.next(term);
  }

  public async scrollToBottom() {
    if (this.field || this.ma.selectDataUrl) {
      const data = await firstValueFrom(this.metaSelectService.selectData$);
      this._skip.next(data.length + this.ma.take);
    } else {
      this.loadData.next({
        skip: this.ma.data?.length || 0,
        take: this.ma.take,
        searchTerm: this.searchTerm.getValue(),
      });
    }
  }

  public async open(state: boolean) {
    if (this.ma.onBeforeOpen && state) {
      let model =
        this.metaHelperService.getFormlySubFormPath(this.field).length > 0 ? this.formState.data() : this.model;
      if (Object.keys(model).length === 0) {
        model = this.form.value;
      }
      await this._metaActionHandler.executeBeforeOpenAction({
        formId: this.formState.formId,
        controlId: this._id,
        data: model,
        ctx: model._ctx,
        subFormPath: this.metaHelperService.getFormlySubFormPath(this.field),
        index: this.metaHelperService.getFormlyFieldArrayIndex(this.field),
      });
    }

    if (state) {
      this.refresh();
    }
    this.expanded = state;
  }

  public refresh() {
    if (!this.field && !this.ma.isTableControl && !this.ma.tagId && !this.ma.noRefreshCheck) {
      return;
    }

    this.isLoading = true;
    this.metaSelectService
      .getData({
        formId: this.field ? this.formState.formId : this.ma.parentFormId,
        fieldId: this._id,
        skip: 0,
        take: this._skip.getValue() + this.ma.take,
        filter: this.searchTerm.getValue(),
        data: this.field ? this.formState.data() : this.ma.contextData,
        contextId: this.field ? this.model._ctx : this.ma.contextData?._ctx,
        selectDataUrl: this.ma.selectDataUrl,
        tagId: this.ma.tagId,
        subformPath: this.field ? this.metaHelperService.getFormlySubFormPath(this.field) : undefined,
        index: this.field ? this.metaHelperService.getFormlyFieldArrayIndex(this.field) : undefined,
        refresh: true,
        reset: true,
        isTableControl: this.ma.isTableControl,
        params: this.params,
      })
      .subscribe()
      .add(() => {
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  public trackById(index: number, item: SelectData) {
    return item[this.ma?.datasourceValue || "value"];
  }

  public getLink() {
    if (this.ma.mode !== "default") {
      return this.field ? _.get(this.formState, ["displayResult", this.field.id, "link"], null) : null;
    } else {
      this.subFormPath = this.subFormPath || this.metaHelperService.getFormlySubFormPath(this.field);
      this.subFormIndex = this.subFormIndex || this.metaHelperService.getFormlyFieldArrayIndex(this.field, true);
      if (this.subFormPath.length === 1) {
        try {
          return this.formState.displayData()[this.subFormPath[0]].values[this.subFormIndex[0]][this.field.id].link;
        } catch (e) {
          return null;
        }
      }
      if (this.subFormPath.length === 2) {
        try {
          return this.formState.displayData()[this.subFormPath[0]].values[this.subFormIndex[0]][this.subFormPath[1]]
            .values[this.subFormIndex[1]][this.field.id].link;
        } catch (e) {
          return null;
        }
      } else {
        return this.field ? this.ma?.displayData?.link : null;
      }
    }
  }

  public returnDisplayValue() {
    if (this.ma.isTableControl) {
      return this.ma.contextData?.displayResultSet[this._id].value;
    }

    if (this.ma.mode !== "default") {
      return this.field ? this.formState.displayData?.()[this._id]?.values : this.value || null;
    } else {
      this.subFormPath = this.subFormPath || this.metaHelperService.getFormlySubFormPath(this.field);
      this.subFormIndex = this.metaHelperService.getFormlyFieldArrayIndex(this.field, true);
      if (this.subFormPath.length > 0) {
        const item = _.get(this.formState.displayData(), this.subFormPath.concat(["values"]));
        return item?.length > 0 && item[this.subFormIndex[0]]
          ? item[this.subFormIndex[0]][this.field.id]?.value || this.value || null
          : null;
      } else {
        return this.field ? this.formState.displayData?.()[this._id]?.value : this.value || null;
      }
    }
  }

  public async onModelChange(items: any[]) {
    if (!items) {
      items = [];
    }
    if (_.isEqual(this._prevModelValue, items)) {
      return;
    } else {
      this._prevModelValue = items;
    }

    if (this.ma.appendAndRemoveMode && this.ma.mode !== MetaSelectType.single) {
      this._prevModelValue = items;
      this.model[this._id] = items.map((v) => {
        if (!v.operation) {
          v.operation = "append";
        }
        return v;
      });
    }
    await this._executeSelectAction(items);
  }

  public async onNgChange(value: any) {
    if (this.ma.mode !== "default" && value.length === 0) {
      value = null;
    }
    this.onChange(value);
    const data = await firstValueFrom(this.metaSelectService.selectData$);
    if (!this.field && data.length === 0) {
      this._addPreviewValueToData(value);
    }
  }

  // TODO: Fix performance issue
  public compare = (o1: any, o2: any) => {
    const v1 = this.compareHack.get(o1);
    if (v1) {
      const v2 = this.compareHack.get(o2);
      if (v2) {
        return v1 === v2;
      }
    }
    // Tags should never return an object
    if (this.ma.returnType === "value" || this.ma.mode === "tags") {
      return o1 !== undefined && o2 !== undefined ? o1 === o2 : false;
    } else {
      const c1 = o1 && typeof o1 === "object" ? o1[this.ma.datasourceValue] || o1[`${this._id}-value`] : undefined;
      const c2 = o2 && typeof o2 === "object" ? o2[this.ma.datasourceValue] : undefined;
      if (o1 && typeof o1 === "object") {
        this.compareHack.set(o1, c1);
      }
      if (o2 && typeof o2 === "object") {
        this.compareHack.set(o2, c2);
      }
      return c1 === c2;
    }
  };

  public async edit() {
    const dialog = await this._metaFormService.openModal({
      formId: this.ma.editForm,
      bodyStyle: {
        height: "70vh",
      },
      size: "large",
      editing: false,
    });
    dialog.afterClose.subscribe(() => {
      this.refresh();
    });
  }

  public onOptionClick(event: Event, option: any) {
    event.stopPropagation();
    if (this.ma.appendAndRemoveMode && this.ma.mode !== MetaSelectType.single) {
      this.formControl.patchValue(
        this.formControl.value.map((v) => {
          if (v.value === option.nzValue.value) {
            if (v.operation === "remove") {
              v.operation = "append";
            } else {
              v.operation = "remove";
            }
            option.operation = v.operation;
          }
          return v;
        }),
      );
    }
  }

  public getOperation(value): string | void {
    for (let i = 0; i < this.formControl.value.length; i++) {
      const v = this.formControl.value[i];
      if (v.value === value) {
        return v.operation;
      }
    }
  }

  public getSelectMetadata(value: any): any {
    return this.ma?.data?.find((d) => d[this.ma.datasourceValue] === value);
  }

  public async onClick() {
    if (this.ma.onClick) {
      const model =
        this.metaHelperService.getFormlySubFormPath(this.field).length > 0 ? this.formState.data() : this.model;
      await this._metaActionHandler.executeClickAction({
        formId: this.formState.formId,
        controlId: this._id,
        ctx: model._ctx,
        data: {
          ...model,
        },
        passthroughData: { formId: this.formState.formId },
        subFormPath: this.metaHelperService.getFormlySubFormPath(this.field),
        index: this.metaHelperService.getFormlyFieldArrayIndex(this.field),
        formIsValid: true,
      });
    }
  }

  private _addPreviewValueToData(value: string | number | Array<unknown>) {
    let displayVal = this.returnDisplayValue();
    if (value !== null && value !== undefined && !this.ma.selectDataUrl && displayVal) {
      let data: SelectData[] = [];
      if (this.ma.mode !== "default") {
        if ((value as Array<any>)?.length > 0) {
          (value as Array<any>).forEach((val, i) => {
            if (
              (displayVal[i]?.[this.ma.datasourceLabel] || this.returnDisplayValue()[i]?.[this.ma.datasourceValue]) !==
              undefined
            ) {
              data.push({
                [this.ma.datasourceValue]: val[`${this._id}-value`] || val.value,
                [this.ma.datasourceLabel]:
                  displayVal[i]?.[this.ma.datasourceLabel] || displayVal[i]?.[this.ma.datasourceValue],
              });
            }
          });
        }
      } else {
        if (displayVal !== undefined) {
          data = [
            {
              [this.ma.datasourceValue]: value,
              [this.ma.datasourceLabel]: displayVal,
            },
          ];
        }
      }
      this.metaSelectService.setData(data, this.ma.datasourceValue);
    }
  }

  private async _executeSelectAction(item: any) {
    if (!this.ma.onSelect) {
      return;
    }
    setTimeout(async () => {
      let model =
        this.metaHelperService.getFormlySubFormPath(this.field).length > 0 ? this.formState.data() : this.model;
      if (Object.keys(model).length === 0) {
        model = this.form.value;
      }
      await new Promise((r) => setTimeout(r, 200));
      await this._metaActionHandler.executeSelectAction({
        formId: this.formState.formId,
        controlId: this._id,
        data: {
          value: item,
          ...model,
        },
        ctx: model._ctx,
        subFormPath: this.metaHelperService.getFormlySubFormPath(this.field),
        index: this.metaHelperService.getFormlyFieldArrayIndex(this.field),
      });
    });
  }
}

@NgModule({
  declarations: [MetaSelectComponent],
  imports: [
    CommonModule,
    FormlyModule,
    NzSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MetaLoaderModule,
    MetaTagModule,
    MetaSectionModule,
    MetaButtonModule,
    RouterModule,
    MetaEmptyModule,
    NzButtonModule,
    NzIconModule,
    PipesModule,
    NgxTolgeeModule,
  ],
  exports: [MetaSelectComponent],
})
export class MetaSelectModule {}
