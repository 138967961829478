/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { DragDropModule, transferArrayItem } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgModule,
  OnDestroy,
  OnInit,
  Optional,
  ViewEncapsulation,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { EntityType } from "@meta/enums";
import { FormlyModule } from "@ngx-formly/core";
import * as _ from "lodash";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzModalRef } from "ng-zorro-antd/modal";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { shouldStreamOutput } from "nx/src/tasks-runner/utils";
import { firstValueFrom } from "rxjs";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaFormModule } from "../../base/metaForm/metaForm.component";
import { MetaAvatarModule } from "../../components/metaAvatar/metaAvatar.component";
import { MetaButtonModule } from "../../components/metaButton/metaButton.component";
import { MetaCheckboxModule } from "../../components/metaCheckbox/metaCheckbox.component";
import { MetaDrodpownModule, MetaDropdownActionButton } from "../../components/metaDropdown/metaDropdown.component";
import { MetaEmptyModule } from "../../components/metaEmpty/metaEmpty.component";
import { MetaFieldWrapperModule } from "../../components/metaFieldWrapper/metaFieldWrapper.component";
import { MetaInputModule } from "../../components/metaInput/metaInput.component";
import { MetaSectionModule } from "../../components/metaSection/metaSection.component";
import { MetaSelectComponent, MetaSelectModule } from "../../components/metaSelect/metaSelect.component";
import { modalActionTarget, modalSublabelTarget } from "../../components/metaToolbar/metaToolbar.interface";
import { MetaToolbarModule } from "../../components/metaToolbar/metaToolbar.component";
import { PipesModule } from "../../pipes/pipes.module";
import { DirtyCheckService } from "../../services/dirtyCheckService";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaModalService } from "../../services/metaModalService";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaInfoboxModule } from "../metaInfobox/metaInfobox.component";
import { MetaPopoverModule } from "../metaPopover/metaPopover.component";
import { MetaCalculactionPosition, MetaCalculationService } from "./metaCalculation.service";

export class MetaCalculation {
  public parentFormId: string;
  public itemId: string;
  public height: string | "auto" = "auto";
}

@MetaUnsubscribe()
@Component({
  selector: "meta-calculation",
  template: `
    <ng-template #toolbarSubLabel> {{ metaCalculationService.titel || "" }}</ng-template>

    <meta-toolbar
      [maParams]="{
        toolbarActionTarget: modalActionTarget + '-' + formState.formId,
        toolbarSubLabelTarget: modalSublabelTarget + '-' + formState.formId,
        sublabel: toolbarSubLabel
      }"
    >
      <ng-container *ngIf="metaCalculationService.hasCalculation$ | async">
        <meta-button
          *ngIf="!(metaCalculationService.editing$ | async)"
          [maParams]="{
            loading: isLoadingEditMode,
            label: 'Bearbeiten',
            type: 'primary'
          }"
          (click)="edit()"
        ></meta-button>
        <meta-button
          *ngIf="metaCalculationService.editing$ | async"
          [maParams]="{
            label: 'Abbrechen',
            icon: 'times'
          }"
          (click)="handleCancel()"
        ></meta-button>
        <meta-button
          *ngIf="metaCalculationService.editing$ | async"
          [maParams]="{
            label: 'Entwurf speichern',
            loading: metaCalculationService.isLoading,
            icon: 'save'
          }"
          [nzTooltipTitle]="'Speichern eines Entwurfs übernimmt die Werte nicht in das Angebot.'"
          nz-tooltip
          (click)="handleSave()"
        ></meta-button>
        <meta-button
          *ngIf="metaCalculationService.editing$ | async"
          [maParams]="{
            label: 'Speichern & übernehmen',
            loading: metaCalculationService.isLoading,
            icon: 'share-square',
            type: 'primary'
          }"
          [nzTooltipTitle]="'Speichert die Kalkulation und übernimmt die Werte in das Angebot.'"
          nz-tooltip
          (click)="handleSave(true)"
        ></meta-button>
        <meta-section [maParams]="{ sectionType: 'vr' }"></meta-section>
        <meta-select
          #versionSelect
          [maParams]="{
            data: metaCalculationService.versionData$ | async,
            editing: true
          }"
          (ngModelChange)="onVersionChange($event, versionSelect)"
          [ngModel]="metaCalculationService.versionModel.version"
          [style.width.px]="300"
        ></meta-select>
        <meta-dropdown
          maAutoclose
          [maParams]="{
            loading: metaCalculationService.isCreating,
            icon: 'ellipsis-v',
            items: pipelineCrudActions
          }"
        ></meta-dropdown>
        <meta-section [maParams]="{ sectionType: 'vr' }"></meta-section>
        <meta-button
          [maParams]="{
            icon: metaCalculationService.expandSubpos ? 'compress-alt' : 'expand-alt'
          }"
          [nzTooltipTitle]="
            metaCalculationService.expandSubpos ? 'Alle Positionen reduzieren' : 'Alle Positionen erweitern'
          "
          nz-tooltip
          (click)="metaCalculationService.toggleSubpos()"
        ></meta-button>
      </ng-container>
    </meta-toolbar>

    <div
      class="calc-wrapper"
      *ngIf="
        (metaCalculationService.hasCalculation$ | async) === true &&
        (metaCalculationService.positions$ | async) as positions
      "
    >
      <div class="calc-labels">
        <div class="calc-labels-header">
          <div *ngIf="metaCalculationService.editing$ | async" class="calc-labels-item-header">
            <meta-infobox
              [maParams]="{ label: 'Ziehe ein Element in die Liste um eine neue Position hinzuzufügen.' }"
            ></meta-infobox>
            <div nz-row>
              <div nz-col [nzSpan]="12">
                <div
                  cdkDropList
                  [cdkDropListConnectedTo]="connectedGroups"
                  [cdkDropListDisabled]="!(metaCalculationService.editing$ | async)"
                >
                  <div
                    cdkDrag
                    class="calc-labels-drag-item"
                    cdkDragPreviewClass="calc-labels-drag-preview"
                    (mouseenter)="onGroupDragStart($event)"
                    [cdkDragLockAxis]="'y'"
                    [cdkDragData]="{}"
                  >
                    + Gruppe
                  </div>
                </div>
              </div>
              <div nz-col [nzSpan]="12">
                <div
                  cdkDropList
                  [cdkDropListConnectedTo]="connectecItems"
                  [cdkDropListDisabled]="!(metaCalculationService.editing$ | async)"
                >
                  <div
                    class="calc-labels-drag-item"
                    cdkDrag
                    cdkDragPreviewClass="calc-labels-drag-preview"
                    (mouseenter)="onItemDragStart($event)"
                    [cdkDragLockAxis]="'y'"
                    [cdkDragData]="{}"
                  >
                    + Posten
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="calc-labels-group"
          id="groups-drag-container"
          cdkDropList
          cdkDropListGroup
          (cdkDropListDropped)="dropGroupEntity($event)"
          [cdkDropListDisabled]="!(metaCalculationService.editing$ | async)"
        >
          <div
            *ngFor="let item of metaCalculationService.items$ | async; trackBy: trackById; let i = index"
            cdkDrag
            [cdkDragDisabled]="item.sortOrder === 0"
            cdkDragPreviewClass="calc-labels-drag-preview"
            [cdkDragLockAxis]="'y'"
            [cdkDragData]="item"
          >
            <div
              class="calc-labels-childs"
              [ngClass]="{ 'calc-labels-childs-placeholder': item?.children?.length === 0 }"
              [id]="'childs-drag-container' + item.typId"
              cdkDropList
              (cdkDropListDropped)="dropChildEntity($event, i)"
              [cdkDropListDisabled]="!(metaCalculationService.editing$ | async)"
            >
              <div
                *ngFor="let child of item.children; trackBy: trackById; let j = index"
                class="calc-labels-cell calc-indent"
                cdkDrag
                cdkDragPreviewClass="list-input-drag-preview"
                [cdkDragLockAxis]="'y'"
                [cdkDragData]="child"
              >
                <div *ngIf="metaCalculationService.editing$ | async" class="row-handler-wrapper">
                  <div class="drag-handler" cdkDragHandle>
                    <i class="fal fa-bars"></i>
                  </div>
                </div>
                <span class="calc-labels-counter">{{ child.calcType === "add" ? "+" : "-" }} </span>
                <meta-input
                  [maParams]="{
                    placeholder: 'Bezeichnung',
                    editing: metaCalculationService.editing$ | async,
                    disabled: metaCalculationService.saving$ | async
                  }"
                  [(ngModel)]="metaCalculationService.itemModel['title' + child.typId]"
                  [style.width.%]="100"
                ></meta-input>
                <div class="calc-labels-actions" *ngIf="metaCalculationService.editing$ | async">
                  <meta-popover
                    [maParams]="{
                      content: itemSettingsTemplate,
                      modalWidth: '250px',
                      position: 'right',
                      iconStyle: 'fas',
                      type: 'link',
                      icon: 'cog',
                      label: 'Einstellungen'
                    }"
                    (click)="metaCalculationService.initItemForm(j, i)"
                  ></meta-popover>
                  <ng-template #itemSettingsTemplate>
                    <meta-field-wrapper
                      [maParams]="{
                        label: 'Berechnungsart wählen',
                        layout: 'vertical'
                      }"
                    >
                      <meta-select
                        [maParams]="{
                          data: metaCalculationService.calcTypes,
                          editing: true,
                          placeholder: 'Berechnungsart wählen'
                        }"
                        (ngModelChange)="metaCalculationService.onCalcTypeChange($event, j, i)"
                        [(ngModel)]="metaCalculationService.itemSettingsModel.calcType"
                      ></meta-select>
                    </meta-field-wrapper>
                    <meta-field-wrapper
                      [maParams]="{
                        label: 'Wertart wählen',
                        layout: 'vertical'
                      }"
                    >
                      <meta-select
                        [maParams]="{
                          data: metaCalculationService.valueTypes,
                          editing: true,
                          placeholder: 'Wertart wählen'
                        }"
                        (ngModelChange)="metaCalculationService.onValueTypeChange($event, j, i)"
                        [(ngModel)]="metaCalculationService.itemSettingsModel.valueType"
                      ></meta-select>
                    </meta-field-wrapper>
                  </ng-template>
                  <meta-button
                    [maParams]="{
                      icon: 'trash',
                      iconStyle: 'fas',
                      danger: true,
                      type: 'link',
                      size: 'small'
                    }"
                    (click)="metaCalculationService.removeItem(j, i)"
                  ></meta-button>
                </div>
              </div>
            </div>
            <div
              class="calc-labels-cell calc-strong calc-alt"
              [ngClass]="{ 'calc-double-line': item.groupType === 'major', 'calc-label-total': item.sticky }"
            >
              <div *ngIf="(metaCalculationService.editing$ | async) && item.sortOrder > 0" class="row-handler-wrapper">
                <div class="drag-handler" cdkDragHandle>
                  <i class="fal fa-bars"></i>
                </div>
              </div>
              <span class="calc-labels-counter">= </span>
              <meta-input
                [maParams]="{
                  placeholder: 'Bezeichnung',
                  editing: metaCalculationService.editing$ | async,
                  disabled: metaCalculationService.saving$ | async
                }"
                [(ngModel)]="metaCalculationService.itemModel['title' + item.typId]"
                [style.width.%]="100"
              ></meta-input>
              <div class="calc-labels-actions" *ngIf="metaCalculationService.editing$ | async">
                <meta-popover
                  [maParams]="{
                    content: groupSettingsTemplate,
                    modalWidth: '175px',
                    position: 'right',
                    iconStyle: 'fas',
                    type: 'link',
                    icon: 'cog',
                    label: 'Einstellungen'
                  }"
                  (click)="metaCalculationService.initGroupForm(i)"
                ></meta-popover>
                <ng-template #groupSettingsTemplate>
                  <meta-field-wrapper
                    [maParams]="{
                      inputWidth: 4,
                      labelWidth: 20,
                      label: 'Hauptgruppe'
                    }"
                  >
                    <meta-checkbox
                      [maParams]="{
                        editing: true
                      }"
                      (ngModelChange)="metaCalculationService.onGroupTypeChange($event, i)"
                      [(ngModel)]="metaCalculationService.groupSettingsModel.groupType"
                    ></meta-checkbox>
                  </meta-field-wrapper>
                </ng-template>
                <meta-button
                  [maParams]="{
                    icon: 'trash',
                    iconStyle: 'fas',
                    type: 'link',
                    danger: true,
                    size: 'small'
                  }"
                  (click)="metaCalculationService.removeGroup(i)"
                ></meta-button>
              </div>
            </div>
            <meta-section
              *ngIf="item.groupType === 'major'"
              [maParams]="{
                sectionType: 'stripes'
              }"
            ></meta-section>
          </div>
        </div>
      </div>
      <div
        *ngFor="let position of positions; trackBy: trackByIndex; let i = index; let even = even"
        class="calc-position"
        [ngClass]="{ 'calc-even': even }"
      >
        <div class="calc-position-wrapper">
          <div class="calc-position-header">
            <ng-template #tmpPopoverContent>
              <meta-form
                maFormId="OfferCalculationTooltip"
                [maItemId]="positions[i].ArtikelNr"
                [maHideToolbar]="true"
              ></meta-form>
            </ng-template>
            <ng-container>
              <meta-button
                class="calc-tooltip-toggle"
                [maParams]="{
                  icon: position.showTooltip ? 'times' : 'chart-bar'
                }"
                [nzPopoverContent]="tmpPopoverContent"
                [nzPopoverPlacement]="['rightTop', 'leftTop', 'bottom']"
                [nzPopoverOverlayClassName]="'popover-content-large'"
                [nzPopoverOverlayStyle]="{
                  'max-height': '80vh',
                  'max-width': '80vw',
                  'overflow-y': 'auto'
                }"
                [nzPopoverTrigger]="null"
                [(nzPopoverVisible)]="position.showTooltip"
                nz-popover
                (click)="toggleTooltip(position, i)"
              ></meta-button>
              <meta-button
                *ngIf="position.subpos?.length > 0"
                [maParams]="{
                  type: 'link',
                  label: '+' + position.subpos.length,
                  icon: position.showSubpos ? 'chevron-left' : 'chevron-right'
                }"
                class="calc-position-toggle"
                (click)="position.showSubpos = !position.showSubpos"
              ></meta-button>
              <meta-avatar
                [maParams]="{
                  label: positions[i].Artikelbezeichnung,
                  size: 'large'
                }"
                class="mb-3"
              ></meta-avatar>
              <span class="calc-position-number">{{ positions[i].ArtikelNr }}</span>
              <span class="calc-position-title">{{
                positions[i].ArtikelbezKurz || positions[i].Artikelbezeichnung
              }}</span>
              <span class="calc-position-quantity"
                >{{ positions[i][metaCalculationService.quantityField] | metaNumber: { minDigits: 0 } }}
                {{ positions[i][metaCalculationService.quantityUnitField] }} / Preiseinheit
                {{ positions[i][metaCalculationService.priceUnitField] | metaNumber: { minDigits: 0 } }}</span
              >
            </ng-container>
          </div>

          <ng-container *ngFor="let item of metaCalculationService.items$ | async; trackBy: trackById; let j = index">
            <div
              class="calc-position-childs"
              [ngClass]="{ 'calc-position-childs-placeholder': item?.children?.length === 0 }"
            >
              <div
                *ngFor="let child of item.children; trackBy: trackById; let k = index"
                class="calc-position-cell calc-position-item"
              >
                <div class="calc-position-input">
                  <meta-input
                    [maParams]="{
                      suffix: child.valueType === 'currency' ? 'Eur' : '%',
                      inputType: child.valueType,
                      editing: metaCalculationService.editing$ | async,
                      disabled: metaCalculationService.saving$ | async
                    }"
                    (ngModelChange)="onValueChange(i)"
                    [(ngModel)]="metaCalculationService.positionsModel[i]['value' + child.typId + '_' + i]"
                  ></meta-input>
                </div>
                <div class="calc-position-output">
                  <span
                    >{{
                      position["_values"]["value" + child.typId] | metaNumber: { minDigits: 2, digits: 2 }
                    }}
                    Eur</span
                  >
                </div>
              </div>
            </div>
            <div
              *ngIf="item.sortOrder !== 0"
              class="calc-position-cell calc-position-item calc-strong calc-alt"
              [ngClass]="{ 'calc-double-line': item.groupType === 'major', 'calc-position-total': item.sticky }"
            >
              <div class="calc-position-input"></div>
              <div class="calc-position-output">
                <span>{{ position["_sum"]["sum" + item.typId] | metaNumber: { minDigits: 2, digits: 2 } }} Eur</span>
              </div>
            </div>
            <div
              *ngIf="item.sortOrder === 0"
              class="calc-position-cell calc-position-item calc-strong calc-alt"
              [ngClass]="{ 'calc-double-line': item.groupType === 'major', 'calc-position-total': item.sticky }"
            >
              <div class="calc-position-input">
                <meta-select
                  [maParams]="{
                    data: metaCalculationService.inputSources,
                    editing: metaCalculationService.editing$ | async,
                    disabled: metaCalculationService.saving$ | async,
                    placeholder: 'Preis wählen...',
                    dropdownMinWidth: 250
                  }"
                  (ngModelChange)="
                    onInputSourceChange(
                      $event,
                      i,
                      item.typId,
                      positions[i].ArtikelNr,
                      positions[i][metaCalculationService.quantityField],
                      position.subpos
                    )
                  "
                  [(ngModel)]="metaCalculationService.itemSettingsModel.inputSource[i]"
                ></meta-select>
              </div>
              <div class="calc-position-output">
                <meta-input
                  [maParams]="{
                    suffix: 'Eur',
                    inputType: 'currency',
                    editing: metaCalculationService.editing$ | async,
                    disabled: metaCalculationService.saving$ | async
                  }"
                  (ngModelChange)="onBaseValueChange($event, item.typId, i)"
                  [(ngModel)]="metaCalculationService.positionsModel[i]['sum' + item.typId + '_' + i]"
                ></meta-input>
              </div>
            </div>
            <meta-section *ngIf="item.groupType === 'major'" [maParams]="{ sectionType: 'stripes' }"></meta-section>
          </ng-container>
        </div>
        <div *ngIf="position.showSubpos" class="calc-position calc-subposition">
          <div
            class="calc-position-wrapper calc-subposition-wrapper"
            *ngFor="let subpos of position.subpos; trackBy: trackByIndex; let subindex = index"
          >
            <div class="calc-position-header">
              <ng-container>
                <span class="calc-position-quantity"
                  >{{ subpos[metaCalculationService.quantityField] }}
                  {{ positions[i][metaCalculationService.quantityUnitField] }} / Preiseinheit
                  {{ positions[i][metaCalculationService.priceUnitField] }}</span
                >
              </ng-container>
            </div>

            <ng-container *ngFor="let item of metaCalculationService.items$ | async; trackBy: trackById; let j = index">
              <div
                class="calc-position-childs"
                [ngClass]="{ 'calc-position-childs-placeholder': item?.children?.length === 0 }"
              >
                <div
                  *ngFor="let child of item.children; trackBy: trackById; let k = index"
                  class="calc-position-cell calc-position-item"
                >
                  <div class="calc-position-input">
                    <meta-input
                      [maParams]="{
                        suffix: child.valueType === 'currency' ? 'Eur' : '%',
                        inputType: child.valueType,
                        editing: metaCalculationService.editing$ | async,
                        disabled: metaCalculationService.saving$ | async
                      }"
                      (ngModelChange)="onValueChange(i, subindex)"
                      [(ngModel)]="
                        metaCalculationService.positionsModel[i]['value' + child.typId + '_' + i + '_' + subindex]
                      "
                    ></meta-input>
                  </div>
                  <div class="calc-position-output">
                    <span
                      >{{
                        subpos["_values"]["value" + child.typId] | metaNumber: { minDigits: 2, digits: 2 }
                      }}
                      Eur</span
                    >
                  </div>
                </div>
              </div>
              <div
                *ngIf="item.sortOrder !== 0"
                class="calc-position-cell calc-position-item calc-strong calc-alt"
                [ngClass]="{ 'calc-double-line': item.groupType === 'major', 'calc-position-total': item.sticky }"
              >
                <div class="calc-position-input"></div>
                <div class="calc-position-output">
                  <span>{{ subpos["_sum"]["sum" + item.typId] | metaNumber: { minDigits: 2, digits: 2 } }} Eur</span>
                </div>
              </div>
              <div
                *ngIf="item.sortOrder === 0"
                class="calc-position-cell calc-position-item calc-strong calc-alt"
                [ngClass]="{ 'calc-double-line': item.groupType === 'major', 'calc-position-total': item.sticky }"
              >
                <div class="calc-position-input"></div>
                <div class="calc-position-output">
                  <meta-input
                    [maParams]="{
                      suffix: 'Eur',
                      inputType: 'currency',
                      editing: metaCalculationService.editing$ | async,
                      disabled: metaCalculationService.saving$ | async
                    }"
                    (ngModelChange)="onBaseValueChange($event, item.typId, i, subindex)"
                    [(ngModel)]="
                      metaCalculationService.positionsModel[i]['sum' + item.typId + '_' + i + '_' + subindex]
                    "
                  ></meta-input>
                </div>
              </div>
              <meta-section *ngIf="item.groupType === 'major'" [maParams]="{ sectionType: 'stripes' }"></meta-section>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <meta-empty
      *ngIf="(metaCalculationService.hasCalculation$ | async) === false"
      [maParams]="{
        iconStyle: 'fas',
        description: 'Für dieses Angebot existiert noch keine Kalkulation. <br/>Wählen Sie eine vorhandene Vorlage.',
        icon: 'calculator',
        action: tmpNew
      }"
    ></meta-empty>

    <ng-template #tmpNew>
      <meta-select
        [maParams]="{
          data: metaCalculationService.templates$ | async,
          placeholder: 'Vorlage wählen...',
          editing: true,
          datasourceLabel: 'label',
          datasourceValue: 'id'
        }"
        [(ngModel)]="metaCalculationService.templateModel.template"
      ></meta-select>
      <meta-button
        class="mt-2"
        [maParams]="{
          label: 'Kalkulation erstellen',
          loading: metaCalculationService.isCreating,
          icon: 'plus',
          disabled: !metaCalculationService.templateModel.template,
          type: 'primary',
          fullWidth: true
        }"
        (click)="metaCalculationService.createCalculation()"
      ></meta-button>
    </ng-template>
  `,
  styleUrls: ["metaCalculation.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [MetaCalculationService],
})
export class MetaCalculationComponent extends MetaComponentBase implements OnInit, OnDestroy {
  public modalActionTarget = modalActionTarget;
  public itemModelCopy: any = {};
  public positionsModelCopy: any = {};
  public groupSettingsModelCopy: any = {};
  public itemSettingsModelCopy: any = {};

  public connectedGroups: string[] = [];
  public connectecItems: string[] = [];
  public height: number;
  public newVersion: boolean;
  public toolbarLabelTarget = "meta-subform-label-container";
  public toolbarActionTarget = "meta-subform-actions-container";
  public basewidth = 283;
  public isLoadingEditMode: boolean;
  public pipelineCrudActions: MetaDropdownActionButton[] = [
    {
      label: "Neue Version erstellen",
      icon: "plus",
      onClick: async () => {
        if (this._dirtyCheckService.isDirty(this._modalRef)) {
          if (!(await firstValueFrom(this._dirtyCheckService.showDirtyAlert(this._modalRef)))) {
            return;
          }
        }
        this.metaCalculationService.editing$.next(false);
        await this.metaCalculationService.createCalculation(this.metaCalculationService.versionModel.version);
      },
    },
    {
      label: "Version Löschen",
      icon: "trash",
      onClick: async () => {
        if (this._dirtyCheckService.isDirty(this._modalRef)) {
          if (!(await firstValueFrom(this._dirtyCheckService.showDirtyAlert(this._modalRef)))) {
            return;
          }
        }
        this.metaCalculationService.editing$.next(false);
        this.deleteCalculation();
      },
    },
  ];
  protected readonly modalSublabelTarget = modalSublabelTarget;
  private _prevPositions: string;

  constructor(
    _changeDetectorRef: ChangeDetectorRef,
    _metaHelperService: MetaHelperService,
    private _modalService: MetaModalService,
    public metaCalculationService: MetaCalculationService,
    private readonly _dirtyCheckService: DirtyCheckService,
    @Optional() private readonly _modalRef: NzModalRef,
  ) {
    super();
    super.maParams = new MetaCalculation();
  }

  get ma(): MetaCalculation {
    return super.ma;
  }

  async ngOnInit() {
    this.metaCalculationService.parentFormId = this.ma.parentFormId;
    this.metaCalculationService.sourceId = this.formState.itemId();

    setTimeout(() => {
      this.metaCalculationService.getItems();
    });
    this._dirtyCheckService.registerObject(
      this,
      () => {
        return this.metaCalculationService.editing$.value;
      },
      this._modalRef,
    );
  }

  ngOnDestroy() {
    this._dirtyCheckService.unregisterObject(this);
    (this as any).destroyed$.next();
    (this as any).destroyed$.complete();
  }

  public trackByIndex(index, item) {
    return index;
  }

  public trackById(index, item) {
    return item.id;
  }

  public async dropGroupEntity(event: any) {
    if (event.currentIndex > 0) {
      const items = this.metaCalculationService.items$.getValue();
      if (event.previousContainer === event.container) {
        const f = items.splice(event.previousIndex, 1)[0];
        items.splice(event.currentIndex, 0, f);
      } else {
        if (!event.previousContainer.data) {
          this.metaCalculationService.addGroup("Neue Gruppe", event.currentIndex);
        } else {
          transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex,
          );
        }
      }
      items.forEach((item, i) => {
        item.sortOrder = i;
      });
      this.metaCalculationService.items$.next(items);
      this.metaCalculationService._updateSums();
      //this.metaCalculationService.itemForm.markAsDirty();
    }
  }

  public async dropChildEntity(event: any, parentIndex: number) {
    const items = this.metaCalculationService.items$.getValue();
    if (event.previousContainer === event.container) {
      const f = items[parentIndex].children.splice(event.previousIndex, 1)[0];
      items[parentIndex].children.splice(event.currentIndex, 0, f);
    } else if (event.previousContainer.id.indexOf("cdk-drop-list") > -1) {
      if (!event.previousContainer.data) {
        this.metaCalculationService.addItem("Neuer Posten", event.currentIndex, parentIndex);
      } else {
        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      }
    } else {
      const prevId = event.previousContainer.id.substr(21, 100);
      const nextId = event.container.id.substr(21, 100);
      const prevItemIndex = this.metaCalculationService.items$.getValue().findIndex((item) => item.typId === prevId);
      const nextItemIndex = this.metaCalculationService.items$.getValue().findIndex((item) => item.typId === nextId);
      const f = items[prevItemIndex].children.splice(event.previousIndex, 1)[0];
      items[nextItemIndex].children.splice(event.currentIndex, 0, f);
    }
    items.forEach((item, i) => {
      if (item.children) {
        item.children.map((child, j) => {
          child.sortOrder = j;
        });
      }
      item.sortOrder = i;
    });
    this.metaCalculationService.items$.next(items);
    this.metaCalculationService._updateSums();
    //this.metaCalculationService.itemForm.markAsDirty();
    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  public onGroupDragStart(event: any) {
    this.connectecItems = [];
    this.connectedGroups = ["groups-drag-container"];
  }

  public onItemDragStart(event: any) {
    this.connectedGroups = [];
    this.connectecItems = this.metaCalculationService.items$.getValue().map((o) => `childs-drag-container${o.typId}`);
  }

  public async onInputSourceChange(
    event: any,
    parentIndex: number,
    id: string,
    articleNo: string,
    amount: number,
    subpos,
  ) {
    const result = await this.metaCalculationService.onInputSourceChange(event, articleNo, amount);
    this.metaCalculationService.positionsModel[parentIndex]["sum" + id + "_" + parentIndex] = result;
    this.onBaseValueChange(result, id, parentIndex);
    this.metaCalculationService.itemSettingsModel.inputSource[parentIndex] = null;
    let subindex = 0;
    for (const pos of subpos) {
      this.metaCalculationService.positionsModel[parentIndex]["sum" + id + "_" + parentIndex + "_" + subindex] = result;
      this.onBaseValueChange(result, id, parentIndex, subindex);
      subindex++;
    }
  }

  public onBaseValueChange(value: any, id: string, positionIndex: number, subPositionIndex?: number) {
    const positions = this.metaCalculationService.positions$.getValue();
    if (subPositionIndex === undefined) {
      positions[positionIndex]["_sum"][`sum${id}`] = value;
    } else {
      positions[positionIndex].subpos[subPositionIndex]["_sum"][`sum${id}`] = value;
    }
    this.metaCalculationService.positions$.next(positions);

    this.onValueChange(positionIndex, subPositionIndex);
    /*this.metaCalculationService.positionsForm[positionIndex].updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });*/
  }

  public onValueChange(positionIndex: number, subPositionIndex?: number) {
    setTimeout(() => {
      const items = this.metaCalculationService.items$.getValue();
      items.forEach((group, i) => {
        if (group.children) {
          group.children.forEach((child) => {
            if (child.valueType === "percent") {
              if (subPositionIndex === undefined) {
                this.metaCalculationService.percentOf(
                  this.metaCalculationService.positionsModel[positionIndex][`value${child.typId}_${positionIndex}`],
                  positionIndex,
                  i,
                  child,
                );
              } else {
                this.metaCalculationService.percentOf(
                  this.metaCalculationService.positionsModel[positionIndex][
                    `value${child.typId}_${positionIndex}_${subPositionIndex}`
                  ],
                  positionIndex,
                  i,
                  child,
                  subPositionIndex,
                );
              }
            }
            if (child.valueType === "currency") {
              if (subPositionIndex === undefined) {
                this.metaCalculationService.division(
                  this.metaCalculationService.positionsModel[positionIndex][`value${child.typId}_${positionIndex}`],
                  positionIndex,
                  i,
                  child,
                );
              } else {
                this.metaCalculationService.division(
                  this.metaCalculationService.positionsModel[positionIndex][
                    `value${child.typId}_${positionIndex}_${subPositionIndex}`
                  ],
                  positionIndex,
                  i,
                  child,
                  subPositionIndex,
                );
              }
            }
          });
        }
      });
    });
  }

  public getOffset(element) {
    let top = 0,
      left = 0;
    do {
      top += element.offsetTop || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while (element);

    return {
      top: top,
      left: left,
    };
  }

  public async onVersionChange(version: number, ref: MetaSelectComponent) {
    if (this._dirtyCheckService.isDirty(this._modalRef)) {
      if (!(await firstValueFrom(this._dirtyCheckService.showDirtyAlert(this._modalRef)))) {
        ref.writeValue(this.metaCalculationService.versionModel.version);
        this.changeDetectorRef.detectChanges();
        return;
      }
    }
    this.metaCalculationService.editing$.next(false);
    this.metaCalculationService.getCalculation(this.metaCalculationService.sourceId, EntityType.Angebot, version);
    this.metaCalculationService.versionModel.version = version;
    this.changeDetectorRef.markForCheck();
  }

  public deleteCalculation(): void {
    this.metaCalculationService.deleteCalculation(this.metaCalculationService.sourceId, EntityType.Angebot);
  }

  public edit() {
    this.itemModelCopy = _.cloneDeep(this.metaCalculationService.itemModel);
    this.positionsModelCopy = _.cloneDeep(this.metaCalculationService.positionsModel);
    this.groupSettingsModelCopy = _.cloneDeep(this.metaCalculationService.groupSettingsModel);
    this.itemSettingsModelCopy = _.cloneDeep(this.metaCalculationService.itemSettingsModel);
    this.metaCalculationService.editing$.next(true);
  }

  public handleSave(applyToOffer = false) {
    this.metaCalculationService.saving$.next(true);
    this.metaCalculationService.updateCalculation(this.metaCalculationService.versionModel.version);
    this.isLoadingEditMode = false;
    if (applyToOffer) {
      const sub = this.metaCalculationService.editing$.subscribe(async (editing) => {
        if (!editing) {
          sub.unsubscribe();
          await this.metaCalculationService.updateOffer();
          this._modalRef.close();
        }
      });
    }
  }

  public handleCancel() {
    this.metaCalculationService.itemModel = _.cloneDeep(this.itemModelCopy);
    this.metaCalculationService.positionsModel = _.cloneDeep(this.positionsModelCopy);
    this.metaCalculationService.groupSettingsModel = _.cloneDeep(this.groupSettingsModelCopy);
    this.metaCalculationService.itemSettingsModel = _.cloneDeep(this.itemSettingsModelCopy);
    this.metaCalculationService.editing$.next(false);
  }

  public toggleTooltip(position: MetaCalculactionPosition, index: number) {
    const pos = this.metaCalculationService.positions$.value;
    pos.forEach((p, i) => {
      if (i !== index) {
        p.showTooltip = false;
      }
    });
    this.metaCalculationService.positions$.next(pos);
    this.changeDetectorRef.detectChanges();
    pos.forEach((p, i) => {
      if (i === index) {
        p.showTooltip = !position.showTooltip;
      }
    });
    this.metaCalculationService.positions$.next(pos);
  }
}

@NgModule({
  declarations: [MetaCalculationComponent],
  imports: [
    CommonModule,
    FormlyModule,
    MetaInfoboxModule,
    DragDropModule,
    MetaButtonModule,
    MetaPopoverModule,
    NzGridModule,
    NzToolTipModule,
    FormsModule,
    NzPopoverModule,
    PipesModule,
    MetaToolbarModule,
    MetaSectionModule,
    MetaDrodpownModule,
    MetaSelectModule,
    MetaInputModule,
    MetaFieldWrapperModule,
    MetaCheckboxModule,
    MetaAvatarModule,
    MetaEmptyModule,
    MetaFormModule,
  ],
  exports: [MetaCalculationComponent],
})
export class MetaCalculationModule {}
