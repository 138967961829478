/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  ContentChild,
  DestroyRef,
  effect,
  inject,
  Input,
  OnInit,
  Signal,
  ViewEncapsulation,
} from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { FormsModule, UntypedFormGroup } from "@angular/forms";
import { Router, RouterModule, RouterOutlet } from "@angular/router";
import { IAppConfig } from "@meta/api-interfaces";
import { Layouts, Menu, ScrollStates, Themes } from "@meta/enums";
import * as Sentry from "@sentry/angular-ivy";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzMessageRef, NzMessageService } from "ng-zorro-antd/message";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { Socket } from "ngx-socket-io";
import { fromEvent, take } from "rxjs";
import { debounceTime, skip } from "rxjs/operators";
import { ChatModule } from "../../../../../../apps/meta-argon/src/app/pages/chat/chat.module";
import { NotificationService } from "../../../../../../apps/meta-argon/src/app/pages/notification/notification.service";
import { SearchService } from "../../../../../../apps/meta-argon/src/app/pages/search/search.service";
import { AppConfigService } from "../../../../../../apps/meta-argon/src/app/services/app-config.service";
import { environment } from "../../../../../../apps/meta-argon/src/environments/environment";
import { MetaMenuItems } from "../../interfaces/interfaces";
import { MetaAppService } from "../../services/metaApp.service";
import { MetaModalService } from "../../services/metaModalService";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { UserService } from "../../services/user.service";
import { MetaAvatarModule } from "../metaAvatar/metaAvatar.component";
import { MetaBrandModule } from "../metaBrand/metaBrand.component";
import { MetaButtonModule } from "../metaButton/metaButton.component";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { MetaEmptyModule } from "../metaEmpty/metaEmpty.component";
import { MetaFormHeaderModule } from "../metaFormHeader/metaFormHeader.component";
import { GetChildMenuItemsPipe } from "./metaGetChildMenuItems.pipe";
import { MetaLayoutService } from "./metaLayout.service";

@MetaUnsubscribe()
@Component({
  selector: "meta-layout",
  templateUrl: "./metaLayout.component.html",
  styleUrls: ["./metaLayout.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    NzLayoutModule,
    NzBreadCrumbModule,
    NzMenuModule,
    RouterModule,
    MetaBrandModule,
    MetaFormHeaderModule,
    NzToolTipModule,
    NzInputModule,
    FormsModule,
    MetaEmptyModule,
    MetaButtonModule,
    NzBadgeModule,
    NzSelectModule,
    MetaAvatarModule,
    NzAlertModule,
    NzButtonModule,
    ChatModule,
    GetChildMenuItemsPipe,
    NgxTolgeeModule,
  ],
})
export class MetaLayoutComponent implements OnInit, AfterContentInit {
  @Input() public maLayout: Layouts;
  @Input() public maBackground: string;
  @Input() public maItems: MetaMenuItems[] = [];
  @ContentChild(RouterOutlet, { static: true }) router: RouterOutlet;

  public layout: Layouts = Layouts.horizontal;
  public layoutOverride: Layouts | string;
  public layouts = Layouts;
  public menu: Menu;
  public user: any;
  public form: UntypedFormGroup;
  public isCollapsed = true;
  public currentTheme: Themes;
  public previousTheme: Themes;
  public useBlankLayout: boolean;
  public absoluteLayout = false;
  public enableSearchFeature = true;
  public searchValue = "";
  public menuLoaded = false;
  public readonly userSignal: Signal<any>;
  public breakpoint: Signal<"xs" | "sm" | "md" | "lg" | "xl" | "xxl" | null>;
  public majorVersion = computed(() => {
    return `V${this.appConfigService.appConfigSignal()?.versionInfo.version.split(".").shift() || ""}`;
  });
  private _scrollState: ScrollStates = ScrollStates.belowEightyPercentBottom;
  private sentryInitialized = false;
  public readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly _metaAppRepository: MetaAppService,
    public readonly notificationCenter: NotificationService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _http: HttpClient,
    public readonly _router: Router,
    public readonly userService: UserService,
    public readonly socket: Socket,
    public readonly searchService: SearchService,
    private readonly modalService: MetaModalService,
    private readonly messageService: NzMessageService,
    public readonly appConfigService: AppConfigService,
    public readonly t: TranslateService,
    public readonly metaLayoutService: MetaLayoutService,
  ) {
    this.breakpoint = this._metaAppRepository.appBreakpoint.asReadonly();
    let messageRef: NzMessageRef;
    this.appConfigService.offline$.pipe(takeUntilDestroyed()).subscribe(() => {
      if (!messageRef) {
        messageRef = this.messageService.warning(t.instant("system.messages.offline", "Argon offline."), {
          nzDuration: 0,
        });
      }
    });

    this.appConfigService.online$
      .pipe(takeUntilDestroyed())
      .pipe(skip(1))
      .subscribe(() => {
        if (messageRef) {
          this.messageService.remove(messageRef.messageId);
          messageRef = null;
        }
        this.messageService.success(t.instant("system.messages.connected", "Argon Connected."), {
          nzDuration: 5_000,
        });
      });

    this.appConfigService.onRestart$.pipe(debounceTime(350), takeUntilDestroyed()).subscribe(() => {
      const messageRef = this.messageService.loading(t.instant("system.messages.restarted", "Argon restarting."), {
        nzDuration: 0,
      });
      this.appConfigService.onRestartComplete$
        .pipe(debounceTime(350))
        .pipe(take(1))
        .subscribe(() => {
          this.messageService.remove(messageRef.messageId);
          this.messageService.success(t.instant("system.messages.restarted", "Argon restarted."), {
            nzDuration: 5_000,
          });
        });
    });

    this.userSignal = toSignal(this.userService.user);
    effect(
      () => {
        if (
          !this.sentryInitialized &&
          this.appConfigService.appConfigSignal()?.features.sentry.enabled &&
          environment.sentry.dsn &&
          this.userSignal()
        ) {
          this.initSentry(this.appConfigService.appConfigSignal());
        }
        if (!this.menuLoaded && this.userSignal()) {
          this.menuLoaded = true;
          this.metaLayoutService.getMenu().catch(() => {
            this.menuLoaded = false;
          });
        }
      },
      { allowSignalWrites: true },
    );
  }

  ngAfterContentInit(): void {
    if (this.router) {
      this.router.activateEvents.subscribe(async () => {
        const data: Record<string, any> = this.router.activatedRouteData || {};
        if (this.layoutOverride === "blank") {
          await this.metaLayoutService.getMenu();
        }
        if (data["layoutOverride"]) {
          this.layoutOverride = data["layoutOverride"];
        } else {
          this.layoutOverride = null;
        }
        this.absoluteLayout = !!data["absoluteLayout"];
        this._changeDetectorRef.markForCheck();
      });
    }
  }

  ngOnInit() {
    this._metaAppRepository.app$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (settings) => {
        this.setTheme(settings.theme).catch(() => {});
        this.layout = settings.layout || Layouts.horizontal;

        this.menu = settings.menu || Menu.dynamic;
        this.isCollapsed = this.menu === Menu.dynamic ? true : !!settings.menuCollapsed;
        this._changeDetectorRef.markForCheck();
      },
    });
    fromEvent(window.matchMedia("(prefers-color-scheme: dark)"), "change")
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this._metaAppRepository.app$.value.theme === "system") {
          this.setTheme(Themes.system).catch(() => {});
        }
      });

    // TODO: Do we relly need to trigger change detection on scroll??
    window.addEventListener("scroll", this.onWindowScroll.bind(this), {
      passive: true,
    });
    this.appConfigService.versionChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event) => {
      this.modalService.info({
        nzOkText: "Anwendung Neuladen",
        nzTitle: `
Argon wurde erfolgreich von <b>${event.previous.version}(${event.previous.buildNum || "-"})</b> auf <b>${
          event.current.version
        }(${event.current.buildNum || "-"})</b> aktualisiert.
`.trim(),
        nzOkLoading: false,
        nzOkDanger: true,
        nzOnOk: () => {
          window.location.reload();
          return false;
        },
        nzCancelText: null,
        nzKeyboard: false,
        nzClosable: false,
        nzCloseOnNavigation: false,
        nzMaskClosable: false,
      });
    });
  }

  public async openSettings() {
    const { SettingsComponent } = await import(
      "../../../../../../apps/meta-argon/src/app/pages/settings/settings.component"
    );
    this.modalService.create({
      nzContent: SettingsComponent,
      nzWidth: "1024px",
    });
  }

  public onWindowScroll($event: any) {
    const element = $event.target.children[0];
    if (
      element.scrollTop + element.clientHeight < element.scrollHeight * 0.8 &&
      this._scrollState !== ScrollStates.belowEightyPercentBottom
    ) {
      this._scrollState = ScrollStates.belowEightyPercentBottom;
      this._metaAppRepository.setAppSettings({ scrollState: ScrollStates.belowEightyPercentBottom }, false);
    }
    if (
      element.scrollTop + element.clientHeight >= element.scrollHeight * 0.8 &&
      this._scrollState !== ScrollStates.aboveEightyPercentBottom
    ) {
      this._scrollState = ScrollStates.aboveEightyPercentBottom;
      this._metaAppRepository.setAppSettings({ scrollState: ScrollStates.aboveEightyPercentBottom }, false);
    }
    if (
      element.scrollTop + element.clientHeight >= element.scrollHeight &&
      this._scrollState !== ScrollStates.OnehundredPercentBottom
    ) {
      this._scrollState = ScrollStates.OnehundredPercentBottom;
      this._metaAppRepository.setAppSettings({ scrollState: ScrollStates.OnehundredPercentBottom }, false);
    }
  }

  public toggleCollapse(state: boolean, toggleCollapsed = false) {
    if (toggleCollapsed && this.isCollapsed) {
      setTimeout(() => {
        this.isCollapsed = false;
        this._changeDetectorRef.markForCheck();
      });
    }

    this._metaAppRepository.setAppSettings({
      menuCollapsed: state,
    });
  }

  public initSentry(config: IAppConfig) {
    this.appConfigService.appConfig;
    const integrations: any[] = [];
    if (config.features.sentry.tracing) {
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      integrations.push(Sentry.browserTracingIntegration({}));
    }
    if (config.features.sentry.replay) {
      // Registers the Replay integration,
      // which automatically captures Session Replays
      integrations.push(Sentry.replayIntegration({}));
    }

    if (config.features.sentry.feedback) {
      integrations.push(
        Sentry.feedbackIntegration({
          // Additional SDK configuration goes in here, for example:
          colorScheme: "system",
          showName: false,
          showEmail: false,
          formTitle: this.t.instant("sentry.feedback.formTitle"),
          buttonLabel: this.t.instant("sentry.buttonLabel"),
          messageLabel: this.t.instant("sentry.feedback.messageLabel"),
          isRequiredLabel: "*",
          submitButtonLabel: this.t.instant("sentry.feedback.submitButtonLabel"),
          cancelButtonLabel: this.t.instant("common.cancel"),
          messagePlaceholder: this.t.instant("sentry.feedback.messagePlaceholder"),
        }),
      );
    }

    Sentry.init({
      dsn: environment.sentry.dsn,
      integrations, // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: config.features.sentry.tracesSampleRate,
      environment: config.versionInfo.buildNum ? "production" : "dev",
      release: config.versionInfo.buildNum || config.versionInfo.gitRef || null,
      tracePropagationTargets: [...config.features.sentry.origins], // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: config.features.sentry.replaysSessionSampleRate,
      replaysOnErrorSampleRate: config.features.sentry.replaysOnErrorSampleRate,
    });
    this.sentryInitialized = true;
  }

  public addHoverClass() {
    if (this.menu === Menu.dynamic) {
      this.isCollapsed = false;
      this._changeDetectorRef.markForCheck();
    }
  }

  public removeHoverClass() {
    if (this.menu === Menu.dynamic) {
      this.isCollapsed = true;
      this._changeDetectorRef.markForCheck();
    }
  }

  copyVersion() {
    navigator.clipboard
      .writeText(
        `
Version: ${this.appConfigService.appConfig?.versionInfo.version}
Date: ${this.appConfigService.appConfig?.versionInfo.buildAt}
Ref: #${this.appConfigService.appConfig?.versionInfo.gitRef}
Build: ${this.appConfigService.appConfig?.versionInfo.buildNum || "-"}
UserAgent: ${navigator.userAgent}
    `.trim(),
      )
      .catch(() => alert("Fehler"));
  }

  public switchUser(user: string) {
    this._http.post("auth/switch-user", { user }).subscribe(() => {
      sessionStorage.clear();
      window.location.reload();
    });
  }

  public openSearch() {
    this.searchService.showSearch();
  }

  private async setTheme(theme: Themes): Promise<Event> {
    if (theme === Themes.system) {
      theme =
        window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches ? Themes.dark : Themes.default;
    }
    if (this.currentTheme === theme) {
      return;
    }

    if (theme === Themes.dark) {
      document.querySelector('meta[name="theme-color"]').setAttribute("content", "#232331");
      document.querySelector("body").style.backgroundColor = "#232331";
    } else {
      document.querySelector('meta[name="theme-color"]').setAttribute("content", "#e8eaed");
      document.querySelector("body").style.backgroundColor = "#e8eaed";
    }

    this.previousTheme = this.currentTheme;
    this.currentTheme = theme;
    document.documentElement.classList.add(theme);
    const res = await new Promise<any>((resolve, reject) => {
      const style = document.createElement("link");
      style.rel = "stylesheet";
      style.href = `${theme}.css?cache-bust=${window["BUILD_NUM"] || Date.now()}`;
      style.id = theme;
      style.onload = (ev) => resolve(ev);
      style.onerror = (err) => {
        Sentry.captureException(new Error("Theme loading error."), {
          extra: {
            theme,
            error: err,
          },
          level: "fatal",
        });
        reject(err);
      };
      document.head.append(style);
    });
    if (this.previousTheme) {
      this.removeUnusedTheme(this.previousTheme);
    }
    return res;
  }

  private removeUnusedTheme(theme: string): void {
    document.documentElement.classList.remove(theme);
    const removedThemeStyle = document.getElementById(theme);
    if (removedThemeStyle) {
      document.head.removeChild(removedThemeStyle);
    }
  }
}
