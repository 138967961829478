/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import * as _ from "lodash";
import { MetaFormComponentInfo, MetaFormComponentProperty } from "../components";
import { List } from "./list";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-split-view-master",
  type: "split-view-master",
  name: "Split View Master",
})
export class SplitViewMasterComponent extends List {
  static selector = "ma-split-view-master";

  @MetaFormComponentProperty({
    name: "Search Form",
    type: "boolean",
  })
  public createable?: string = "creatable";

  constructor(props: Partial<SplitViewMasterComponent>) {
    super(props);
  }

  public static createElement(attributes, children: any[]): any {
    return new SplitViewMasterComponent({
      ...attributes,
      children,
    });
  }

  toFormly(config: IFormUserConfiguration): any {
    return {
      ...deepMerge(super.toFormly(config), {
        type: "meta-list",
        props: {
          createable: !!this.createable,
        },
      }),
      wrappers: [],
    };
  }
}
