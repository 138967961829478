/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaBaseValidator } from "./baseValidator";

export interface IMetaValidatorCustomOptions {
  validate: () => Promise<boolean>;
}

export class CustomValidator extends MetaBaseValidator {
  constructor(public options: IMetaValidatorCustomOptions) {
    super();
  }

  validate(): Promise<boolean> {
    return undefined;
  }
}
