/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  NgModule,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MetaChartCombinationDYSeriesType,
  MetaChartCombinationSeriesType,
  MetaChartErrorSeriesType,
  MetaChartFunnelType,
  MetaChartMSStackedLineSeriesType,
  MetaChartMSStackedSeriesType,
  MetaChartMultiSeriesType,
  MetaChartSankeySeriesType,
  MetaChartSeriesType,
  MetaChartSingleSeriesType,
  MetaChartSparkSeriesType,
  MetaChartXYPlotSeriesType,
  MetaGroupStyle,
  MetaSize,
  Themes,
} from "@meta/enums";
import { select } from "@ngneat/elf";
import { FormlyModule } from "@ngx-formly/core";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { FusionChartsComponent, FusionChartsModule } from "angular-fusioncharts";
import type FusionChartInstance from "angular-fusioncharts/interfaces/FusionChartInstance";
import { FusionChartsService } from "angular-fusioncharts/src/fusioncharts.service";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzStatisticModule } from "ng-zorro-antd/statistic";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { firstValueFrom, Observable, skip, Subject, Subscription, takeUntil } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { PipesModule } from "../../pipes/pipes.module";
import { MetaAppService } from "../../services/metaApp.service";
import { MetaEventService } from "../../services/metaEvents.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaButton, MetaButtonModule } from "../metaButton/metaButton.component";
import { MetaDrodpownModule, MetaDropdownActionButton } from "../metaDropdown/metaDropdown.component";
import { MetaEmptyModule } from "../metaEmpty/metaEmpty.component";
import { MetaGroupModule } from "../metaGroup/metaGroup.component";
import { MetaIconModule } from "../metaIcon/metaIcon.component";
import { MetaLoaderModule } from "../metaLoader/metaLoader.component";
import { MetaToolbarModule } from "../metaToolbar/metaToolbar.component";
import { IChartConfigState, MetaChartRepository } from "./metaChart.repository";
import { MetaChartService } from "./metaChart.service";
import { ChartTheme } from "./theme";
import { chartTypeTranslations } from "./translations";

export class MetaChart {
  label: string;
  seriesType: MetaChartSeriesType;
  condition?: any;
  filters?: any;
  height?: any;
  noDataLabel? = "Keine Daten";
  noDataIcon? = "chart-line";
  showGroupWrapper? = false;
  groupWrapperStyle?: MetaGroupStyle = MetaGroupStyle.default;
  groupWrapperSize?: MetaSize = MetaSize.default;
  showDummy?: boolean;
  showLegend?: boolean;
  fullHeight?: boolean;
  showValues?: boolean = true;
  style: "default" | "blank" = "default";
}

@MetaUnsubscribe()
@Component({
  selector: "meta-chart",
  template: `
    <meta-group
      *ngIf="ma.showGroupWrapper"
      [maParams]="{
        actionTemplate: actionTemplate,
        label: ma.label || (field ? formState.form().label : null),
        fullHeight: fullHeight,
        transparent: ma.groupWrapperStyle === 'transparent',
        flex: 1
      }"
    >
      <ng-container *ngTemplateOutlet="chartsTemplate"></ng-container>
    </meta-group>
    <ng-container *ngIf="!ma.showGroupWrapper">
      <ng-container *ngTemplateOutlet="chartsTemplate"></ng-container>
    </ng-container>

    <ng-template #chartsTemplate>
      <div
        *ngIf="!noData && !ma.showDummy"
        class="fc-wrapper"
        [style]="{ height: ma.height ? ma.height + 'px' : '100%', width: '100%' }"
      >
        <meta-button
          *ngIf="
            (chartConfig$ | async)?.seriesType === MetaChartSeriesType.zoomline ||
            ma.seriesType === MetaChartSeriesType.zoomline
          "
          (click)="zoomOut()"
          [maParams]="{
            fullWidth: false,
            icon: 'rotate-left',
            type: 'primary',
            size: 'small',
            disabled: isLoading || !zoomedIn
          }"
          class="reset-zoom"
        >
        </meta-button>
        <fusioncharts
          *ngIf="data && !isLoading"
          [dataSource]="data"
          [events]="events"
          dataFormat="JSON"
          [type]="(chartConfig$ | async)?.seriesType || ma.seriesType"
          width="100%"
          [height]="ma.height || '100%'"
          (initialized)="chartInitialized($event)"
        ></fusioncharts>
      </div>
      <meta-empty
        *ngIf="noData"
        [style.height.px]="ma.height"
        [maParams]="{ icon: ma.noDataIcon, description: ma.noDataLabel }"
      ></meta-empty>
      <ng-container *ngIf="!data && !isLoading"> NO DATA</ng-container>
      <ng-container *ngIf="isLoading && !ma.showDummy">
        <meta-loader [style.minHeight.px]="ma.height + 'px' || '100%'"></meta-loader>
      </ng-container>
      <ng-container *ngIf="ma.showDummy">
        <nz-skeleton></nz-skeleton>
      </ng-container>
    </ng-template>

    <ng-template #actionTemplate>
      <meta-dropdown
        *ngIf="actions.length > 0"
        [maParams]="{ icon: 'ellipsis', iconStyle: 'fas', items: actions, type: 'text' }"
      ></meta-dropdown>
    </ng-template>
  `,
  styleUrls: ["./metaChart.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaChartComponent extends MetaComponentBase implements OnInit, OnDestroy, AfterViewInit {
  private static ladingProm: Promise<void>;
  public data: any = null;
  public events: any = null;
  public defaultTheme = "candy";
  public toolbarActionTarget: string;
  public chartConfig$: Observable<IChartConfigState>;
  public actions: MetaDropdownActionButton[] = [];
  @ViewChild(FusionChartsComponent, { static: false })
  public chart: FusionChartsComponent;
  public chartInstance: FusionChartInstance;
  public noData = false;
  public isMaximised = false;
  public loadedAsWidget = false;
  public theme: Themes;
  public fullHeight: boolean;
  public zoomedIn: boolean = false;
  protected readonly MetaChartSeriesType = MetaChartSeriesType;
  private resizeObserver: ResizeObserver;
  private filters: any[] = [];
  private resizeSubject = new Subject<DOMRectReadOnly>();
  private filterSubscriptions: Subscription[] = [];
  private chartThemeDarkLoaded: boolean;
  private chartThemeLightLoaded: boolean;

  constructor(
    protected _chartService: MetaChartService,
    private _host: ElementRef,
    private _metaChartRepository: MetaChartRepository,
    private _router: Router,
    private _route: ActivatedRoute,
    private _metaAppRepository: MetaAppService,
    private _metaEventService: MetaEventService,
    private readonly ele: ElementRef<HTMLElement>,
  ) {
    super();
    super.maParams = new MetaChart();
    this.isLoading = true;
  }

  get ma(): MetaChart {
    return super.ma;
  }

  private static async loadCharts() {
    if (!this.ladingProm) {
      this.ladingProm = (async () => {
        const charts = await import("fusioncharts").then((e) => e["default"]);
        const maps = await import("fusionmaps/fusioncharts.maps").then((e) => e["default"]);
        const europe = await import("fusionmaps/maps/fusioncharts.europe2").then((e) => e["default"]);
        charts.setScriptBaseURI("/assets/fusioncharts/");
        const theme = await import("fusioncharts/themes/fusioncharts.theme.candy").then((e) => e["default"]);
        FusionChartsService.setFCRoot(charts);
        FusionChartsModule.fcRoot(charts, maps, europe, theme);
      })();
    }
    await this.ladingProm;
  }

  public maximizeWindow(): void {
    if (this.field) {
      this._router.navigate([this.formState.formId], {
        queryParams: {
          maximized: 1,
        },
      });
    }
  }

  public exportChart(format: string) {
    this.chartInstance.chart["exportChart"]({
      exportFormat: format,
    });
    this.chartInstance.chart;
  }

  public reset() {
    this._metaChartRepository.setConfig(this.id, {
      seriesType: null,
    });
    this.changeDetectorRef.markForCheck();
    this.updateChart();
  }

  public async buildFilters() {
    const filterValues = await firstValueFrom<Record<string, any>>(
      this._metaChartRepository.chartFilter$.pipe(select((s) => s)),
    );
    const params = {
      ...Object.fromEntries(
        this.filters.map((e) => {
          return [e.id, filterValues ? filterValues[e.id]?.value || e.default : e.default];
        }),
      ),
    };
    return params;
  }

  public async loadData() {
    if (!this.formState.formId) {
      return;
    }

    let chartStyle = {};

    if (this.ma.style === "blank") {
      chartStyle = {
        showYAxisValues: 0,
        showXAxisValues: 0,
        canvasPadding: 0,
        chartLeftMargin: 0,
        chartRightMargin: 0,
        chartTopMargin: 0,
        chartBottomMargin: 0,
        canvasRightPadding: 0,
        canvasTopPadding: 0,
        canvasBottomPadding: 0,
        canvasLeftMargin: 0,
        canvasRightMargin: 0,
        canvasTopMargin: 0,
        canvasBottomMargin: 0,
        pieRadius: 130,
        showLabels: 0,
        showLegend: 0,
        showValues: 0,
      };
    }

    const f = await this.buildFilters();
    this._chartService
      .getChart(
        this.formState.formId,
        String(this.key),
        {
          ...this.ma.condition,
          params: f,
          parentContextId: this.model._ctx,
        },
        this.ma.filters,
      )
      .subscribe(async (data) => {
        await this.getTheme();
        this.data = {
          ...data,
          chart: {
            ...data.chart,
            ...chartStyle,
            theme:
              this.theme === Themes.dark
                ? "themeDark"
                : this.theme === Themes.default
                  ? "themeLight"
                  : this.defaultTheme,
            exportEnabled: true,
            showLegend: this.ma.showLegend,
            showValues: this.ma.showValues,
          },
          width: "100%",
          height: "100%",
        };
        this.noData = this.isDataEmpty(this.data);
        this.updateChartTheme();
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  public getChartTypeActions(config: IChartConfigState) {
    let group;
    if (Object.keys(MetaChartSingleSeriesType).find((o: MetaChartSeriesType) => o === this.ma.seriesType)) {
      group = MetaChartSingleSeriesType;
    } else if (Object.keys(MetaChartFunnelType).find((o: MetaChartSeriesType) => o === this.ma.seriesType)) {
      group = MetaChartFunnelType;
    } else if (Object.keys(MetaChartMultiSeriesType).find((o: MetaChartSeriesType) => o === this.ma.seriesType)) {
      group = MetaChartMultiSeriesType;
    } else if (Object.keys(MetaChartCombinationSeriesType).find((o: MetaChartSeriesType) => o === this.ma.seriesType)) {
      group = MetaChartCombinationSeriesType;
    } else if (
      Object.keys(MetaChartCombinationDYSeriesType).find((o: MetaChartSeriesType) => o === this.ma.seriesType)
    ) {
      group = MetaChartCombinationDYSeriesType;
    } else if (Object.keys(MetaChartXYPlotSeriesType).find((o: MetaChartSeriesType) => o === this.ma.seriesType)) {
      group = MetaChartXYPlotSeriesType;
    } else if (Object.keys(MetaChartMSStackedSeriesType).find((o: MetaChartSeriesType) => o === this.ma.seriesType)) {
      group = MetaChartMSStackedSeriesType;
    } else if (
      Object.keys(MetaChartMSStackedLineSeriesType).find((o: MetaChartSeriesType) => o === this.ma.seriesType)
    ) {
      group = MetaChartMSStackedLineSeriesType;
    } else if (Object.keys(MetaChartSparkSeriesType).find((o: MetaChartSeriesType) => o === this.ma.seriesType)) {
      group = MetaChartSparkSeriesType;
    } else if (Object.keys(MetaChartErrorSeriesType).find((o: MetaChartSeriesType) => o === this.ma.seriesType)) {
      group = MetaChartErrorSeriesType;
    } else if (Object.keys(MetaChartSankeySeriesType).find((o: MetaChartSeriesType) => o === this.ma.seriesType)) {
      group = MetaChartSankeySeriesType;
    }

    return {
      label: "Darstellungstyp ändern",
      icon: "chart-bar",
      disabled: !group,
      childs: group
        ? Object.keys(group).map((type: MetaChartSeriesType): MetaButton => {
            return {
              label: chartTypeTranslations.find((o) => o.id === type).label,
              onClick: (() => {
                this._metaChartRepository.setConfig(this.id, {
                  seriesType: type,
                });
                this.updateChart();
              }).bind(this),
              icon: (config.seriesType || this.ma.seriesType) === type ? "check" : "",
            };
          })
        : null,
    };
  }

  public updateActions(config: IChartConfigState) {
    let actions = [];
    if (this.loadedAsWidget) {
      actions.push({
        label: "Maximieren",
        onClick: () => this.maximizeWindow(),
        icon: "expand",
      });
    }

    actions = actions.concat([
      {
        label: "Diagramm Zurücksetzen",
        onClick: (() => this.reset()).bind(this),
        icon: "history",
      },
      {
        label: "Diagramm exportieren",
        icon: "file-export",
        childs: [
          {
            label: "Als PNG exportieren",
            icon: "file-image",
            onClick: (() => this.exportChart("png")).bind(this),
          },
          {
            label: "Als JPG exportieren",
            icon: "file-image",
            onClick: (() => this.exportChart("jpg")).bind(this),
          },
          {
            label: "Als PDF exportieren",
            icon: "file-pdf",
            onClick: (() => this.exportChart("pdf")).bind(this),
          },
          {
            label: "Als SVG exportieren",
            icon: "file-code",
            onClick: (() => this.exportChart("svg")).bind(this),
          },
          {
            label: "Als CSV exportieren",
            icon: "file-csv",
            onClick: (() => this.exportChart("csv")).bind(this),
          },
          {
            label: "Als Excel-Datei exportieren",
            icon: "file-excel",
            onClick: (() => this.exportChart("xlsx")).bind(this),
          },
        ],
      },
      this.getChartTypeActions(config),
    ]);

    this.actions = actions;
  }

  public updateChart() {
    this.loadData();
  }

  private initLazy() {
    console.log("INIT CHART");
    MetaChartComponent.loadCharts().then(() => {
      this.chartConfig$ = this._metaChartRepository.chartConfig$.pipe(select((s) => s[this.id]));
      this.filterSubscriptions.push(
        this.chartConfig$.pipe(takeUntil(this.destroyed$)).subscribe((config) => {
          this.updateActions(config || {});
        }),
      );

      const form = this.formState.form();
      if (form) {
        for (const filter of form.filters || []) {
          const filterObs = this._metaChartRepository.chartFilter$.pipe(
            select((s) => s[filter.id]),
            skip(1),
          );
          this.filterSubscriptions.push(
            filterObs.subscribe(() => {
              this.updateChart();
            }),
          );
        }
        this.filters = form.filters || [];
      }
      // Wait until formData is loaded
      firstValueFrom(this.options.formState.onFormDataReady().pipe(debounceTime(750))).then(() => {
        this.updateChart();
      });

      this.toolbarActionTarget = `meta-widgets-header-actions-container-${this.formState.formId}`;
    });
    this._metaEventService.setFilterTrigger$
      .pipe(
        skip(1),
        debounceTime(50),
        filter((x) => x !== undefined && x !== null && x.fieldId === this.id),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: async (trigger) => {
          for (const [key, value] of Object.entries(trigger.filter)) {
            this.filters.push({
              id: key,
              default: null,
            });
            this._metaChartRepository.setFilter(key, { value });
          }
          this.updateChart();
        },
      });
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.ma.showDummy) {
      return;
    }
    // Set events
    this.events = {
      zoomedIn: (eventObj, dataObj) => {
        this.zoomedIn = true;
      },
    };

    this.fullHeight = !!this._route.snapshot?.queryParams?.maximized || this.ma.fullHeight;
    this.loadedAsWidget = this.formState.loadedAsWidget;
  }

  ngAfterViewInit(): void {
    requestAnimationFrame(() => {
      let initialized = false;
      const observer = new IntersectionObserver((e) => {
        if (e[0].isIntersecting) {
          if (!initialized) {
            this.initLazy();
          }
          initialized = true;
          observer.unobserve(this.ele.nativeElement);
        }
      }, {});
      this.destroyRef.onDestroy(() => {
        observer.unobserve(this.ele.nativeElement);
        observer.disconnect();
      });
      observer.observe(this.ele.nativeElement);
    });
  }

  ngOnDestroy(): void {
    this.unregisterResizeObserver();
    this.filterSubscriptions.forEach((s) => s.unsubscribe());
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  unregisterResizeObserver() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
    if (this.resizeSubject) {
      this.resizeSubject.unsubscribe();
      this.resizeSubject = null;
    }
  }

  async chartInitialized(chart: FusionChartInstance) {
    this.unregisterResizeObserver();
    this.resizeSubject = new Subject();
    this.chartInstance = chart;
    try {
      const closestCardBody = (this._host.nativeElement as HTMLElement).closest(".ant-card-body");
      if (closestCardBody) {
        this.resizeObserver = new ResizeObserver((entries) => {
          this.resizeSubject.next(entries[0].contentRect);
        });
        this.resizeObserver.observe(closestCardBody);
      }
      this.resizeSubject.pipe(skip(1), debounceTime(750)).subscribe(() => {
        chart.chart["render"]();
      });
    } catch (e) {
      console.error(e);
    }
  }

  public zoomOut() {
    this.chartInstance.chart["resetChart"]();
    this.zoomedIn = false;
  }

  private updateChartTheme() {
    if (this.theme === Themes.dark) {
      this.data["chart"].theme = "themeDark";
    } else {
      this.data["chart"].theme = "themeLight";
    }
    // Overwrite color ranges
    if (this.data["colorRange"]) {
      if (this.data["colorRange"].color.length > 0) {
        this.data["colorRange"].code = this.data["colorRange"].code
          ? this.data["colorRange"].code
          : getComputedStyle(document.documentElement).getPropertyValue("--charts-color-01");
        this.data["colorRange"].color.map((c, i) => {
          c.code = (
            this.data["colorRange"].color[i].code
              ? c.code
              : getComputedStyle(document.documentElement).getPropertyValue("--charts-color-0" + (i + 2))
          ).trim();
          return c;
        });
      }
    }
    // Overwrite colors based on chart-type
    switch (this.ma.seriesType) {
      case MetaChartSeriesType.multilevelpie:
        let counter = 1;
        this.data["category"].forEach((cat) => {
          cat.color = getComputedStyle(document.documentElement).getPropertyValue("--component-background");
          const iter = (c) => {
            for (let i = 0; i < c.category.length; i++) {
              c.category[i].color = getComputedStyle(document.documentElement).getPropertyValue(
                "--charts-color-0" + counter,
              );
              if (c.category[i].category) {
                iter(c.category[i]);
              }
            }
            counter = counter === 8 ? 1 : counter + 1;
          };
          iter(cat);
        });
        break;
      case MetaChartSeriesType.scatter:
      case MetaChartSeriesType.zoomscatter:
      case MetaChartSeriesType.errorline:
      case MetaChartSeriesType.errorscatter:
      case MetaChartSeriesType.inversemsarea:
      case MetaChartSeriesType.inversemsline:
      case MetaChartSeriesType.line:
      case MetaChartSeriesType.spline:
      case MetaChartSeriesType.splinearea:
      case MetaChartSeriesType.msspline:
      case MetaChartSeriesType.mssplinearea:
        if (Array.isArray(this.data["dataset"])) {
          this.data["dataset"].map((data, i) => {
            data.anchorBgColor = this.data["dataset"][i].anchorBgColor
              ? data.anchorBgColor
              : getComputedStyle(document.documentElement).getPropertyValue("--charts-colorset-0" + (i + 1));
            return data;
          });
        }

        break;
      case MetaChartSeriesType.bulb:
      case MetaChartSeriesType.hled:
      case MetaChartSeriesType.vled:
        if (this.data["colorRange"].color.length > 0) {
          this.data["colorRange"].color.map((c, i) => {
            c.code = this.data["colorRange"].color[i].code
              ? c.code
              : getComputedStyle(document.documentElement).getPropertyValue("--charts-colorset-0" + (i + 1));
            return c;
          });
        }
        break;
      case MetaChartSeriesType.treemap:
        if (this.data["colorRange"].color.length > 0) {
          this.data["colorRange"].code = this.data["colorRange"].code
            ? this.data["colorRange"].code
            : getComputedStyle(document.documentElement).getPropertyValue("--charts-colorset-01");
          this.data["colorRange"].color.map((c, i) => {
            c.code = this.data["colorRange"].color[i].code
              ? c.code
              : getComputedStyle(document.documentElement).getPropertyValue("--charts-colorset-0" + (i + 2));
            return c;
          });
        }
        break;
      case MetaChartSeriesType.angulargauge:
        (this.data as any).colorrange.color.forEach((color, index) => {
          color.code = getComputedStyle(document.documentElement).getPropertyValue(`--charts-colorset-0${index + 1}`);
        });
        (this.data as any).trendpoints.point.forEach((point, index) => {
          point.color = getComputedStyle(document.documentElement).getPropertyValue(`--error-color`);
          point.markerbordercolor = getComputedStyle(document.documentElement).getPropertyValue(`--error-color`);
        });
        break;
    }
    this.changeDetectorRef.markForCheck();
  }

  private async getTheme() {
    const settings = await firstValueFrom(this._metaAppRepository.app$);
    this.theme = settings.theme;

    if (this.theme === Themes.dark && !this.chartThemeDarkLoaded) {
      ChartTheme("themeDark");
      this.chartThemeDarkLoaded = true;
    } else if (!this.chartThemeLightLoaded) {
      ChartTheme("themeLight");
      this.chartThemeLightLoaded = true;
    }
  }

  private isDataEmpty(data: any) {
    if (data.dataset) {
      for (const set of data.dataset) {
        if (!this.isDataEmpty(set)) return false;
      }
      return true;
    }
    if (!data.data || !Array.isArray(data.data) || data.length === 0) return false;

    let sum = 0;
    for (const item of data.data) {
      if (item.value && typeof item.value === "number") {
        sum += item.value;
      }
    }
    return sum === 0;
  }
}

//FusionCharts.setScriptBaseURI("/assets/fusioncharts/");

@NgModule({
  declarations: [MetaChartComponent],
  providers: [],
  imports: [
    CommonModule,
    FusionChartsModule,
    FormlyModule,
    NzWaveModule,
    NzButtonModule,
    NzPopconfirmModule,
    NzStatisticModule,
    NzToolTipModule,
    PipesModule,
    MetaIconModule,
    MetaToolbarModule,
    MetaDrodpownModule,
    MetaEmptyModule,
    MetaLoaderModule,
    MetaGroupModule,
    MetaButtonModule,
    NzSkeletonModule,
    NgxTolgeeModule,
  ],
  exports: [MetaChartComponent],
})
export class MetaChartModule {}
