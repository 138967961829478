/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { Injectable } from "@angular/core";
import { BehaviorSubject, firstValueFrom, Observable, Subject, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { MetaSchedulerGetDataResponse, MetaSchedulerGetDataResponseItems } from "./metaScheduler.component";
import { map } from "rxjs/operators";

class GetDataParams {
  formId: string;
  ganttId: string;
  contextId: string;
  start?: string;
  end?: string;
  searchString?: string;
  filterState: Record<string, any> = {};
}

export interface ISchedulerLegendItem {
  label: string;
  color: string;
  icon?: string;
  value?: string;
}

@Injectable()
export class MetaSchedulerService {
  private _data: BehaviorSubject<MetaSchedulerGetDataResponse> = new BehaviorSubject(null);
  constructor(public http: HttpClient) {}

  public getLegend(params: Omit<GetDataParams, "start" | "end" | "contextId" | "filterState">) {
    return this.http.get<ISchedulerLegendItem[]>(`forms/scheduler/${params.formId}/${params.ganttId}/legend`);
  }

  public handleEventChanged(
    params: Omit<GetDataParams, "start" | "end" | "filterState">,
    item: MetaSchedulerGetDataResponseItems,
  ) {
    return firstValueFrom(
      this.http.post<{ successful: boolean }>(`forms/scheduler/${params.formId}/${params.ganttId}/change`, {
        ctx: params.contextId || 0,
        item,
      }),
    );
  }

  public handleClick(
    params: Omit<GetDataParams, "start" | "end" | "filterState">,
    item: MetaSchedulerGetDataResponseItems,
  ) {
    return firstValueFrom(
      this.http.post<any>(`forms/scheduler/${params.formId}/${params.ganttId}/click`, {
        ctx: params.contextId || undefined,
        item,
      }),
    );
  }

  public handleSectionClick(params: Omit<GetDataParams, "start" | "end" | "filterState">, section: string) {
    return firstValueFrom(
      this.http.post<any>(`forms/scheduler/${params.formId}/${params.ganttId}/section-click`, {
        section,
      }),
    );
  }

  public getFilters(params: Omit<GetDataParams, "start" | "end" | "contextId" | "filterState">) {
    return this.http.get<any[]>(`forms/scheduler/${params.formId}/${params.ganttId}/filters`).pipe(
      map((filters) => {
        return filters.map((filter) => {
          filter["loaded$"] = new Subject();
          return filter;
        });
      }),
    );
  }

  public getAutocomplete(params: Omit<GetDataParams, "start" | "end" | "contextId" | "filterState">) {
    return this.http.get<any[]>(`forms/scheduler/${params.formId}/${params.ganttId}/autocomplete`, {
      params: {
        q: params.searchString,
      },
    });
  }

  public getItems(params: GetDataParams): Observable<MetaSchedulerGetDataResponse> {
    return this.http
      .get<MetaSchedulerGetDataResponse>(`forms/scheduler/${params.formId}/${params.ganttId}/data`, {
        params: {
          ctx: params.contextId || 0,
          start: params.start,
          end: params.end,
          ...(params.searchString ? { q: params.searchString } : {}),
          filter: JSON.stringify(params.filterState),
        },
      })
      .pipe(
        tap((data) => {
          this._data.next(data);
        }),
      );
  }
}
