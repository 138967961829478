/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { inject, NgModule } from "@angular/core";
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy, RouterModule, Routes } from "@angular/router";
import { IsAuthenticatedGuard } from "./guards/isAuthenticated";
import { isAdminAuthenticatedGuard } from "./guards/isAdminGuard";
import { MetaDeepLinkingService } from "./services/meta-deep-linking.service";

export class MetaRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    let reuseRoute = false;
    if (future.firstChild?.data?.shouldReuse && curr.firstChild?.data?.shouldReuse) {
      reuseRoute = future.firstChild?.params?.formId === curr.firstChild?.params?.formId;
    } else if (future.data?.shouldReuse && curr.data?.shouldReuse) {
      reuseRoute = future.params?.formId === curr.params?.formId;
    } else {
      reuseRoute = false;
    }
    return reuseRoute;
  }
}

const routes: Routes = [
  {
    path: "error",
    loadChildren: () => import("./pages/error/error.module").then((m) => m.ErrorModule),
  },
  {
    path: "auth",
    loadChildren: () => import("./pages/auth/auth.module").then((m) => m.AuthModule),
    data: {
      layoutOverride: "blank",
    },
  },
  {
    path: "admin/setup",
    loadChildren: () => import("./pages/setup/setup.module").then((m) => m.SetupModule),
    data: {
      layoutOverride: "blank",
    },
  },
  {
    path: "admin",
    loadChildren: () => import("./pages/admin/admin.module").then((m) => m.AdminModule),
    canActivate: [IsAuthenticatedGuard],
    data: {
      layoutOverride: "blank",
    },
  },
  {
    path: "dashboards",
    loadChildren: () => import("./pages/dashboard/dashboard.module").then((m) => m.DashboardModule),
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: "workflow",
    loadChildren: () => import("./pages/workflow/workflow.module").then((m) => m.WorkflowModule),
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: "pipeline",
    loadChildren: () => import("./pages/pipeline/pipeline.module").then((m) => m.PipelineModule),
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: "inventory-booking",
    loadChildren: () =>
      import("./pages/inventory-booking/inventoryBooking.module").then((m) => m.InventoryBookingModule),
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: "hub",
    loadChildren: () => import("./pages/hub/hub.module").then((m) => m.HubModule),
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: "approve",
    loadChildren: () => import("./pages/approve/approve.module").then((m) => m.ApproveModule),
    canActivate: [IsAuthenticatedGuard],
    data: {
      shouldReuse: true,
    },
  },
  {
    path: "deep-link",
    canActivate: [
      (route: ActivatedRouteSnapshot) => {
        const s = inject(MetaDeepLinkingService);
        const r = s.open(route.queryParams);
        return r ? r : false;
      },
    ],
    children: [],
  },
  {
    path: "documents",
    loadChildren: () => import("./pages/documents/documents.module").then((m) => m.DocumentsModule),
    canActivate: [IsAuthenticatedGuard],
    data: {
      shouldReuse: true,
    },
  },
  {
    path: "lead-management",
    pathMatch: "full",
    data: {
      formId: "lead-management",
    },
    loadChildren: () => import("./pages/pipeline/pipeline.module").then((m) => m.PipelineModule),
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: "project-management",
    data: {
      formId: "project-management",
    },
    pathMatch: "full",
    loadChildren: () => import("./pages/pipeline/pipeline.module").then((m) => m.PipelineModule),
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: "form-configuration",
    loadChildren: () =>
      import("./pages/form-configuration/form-configuration.module").then((m) => m.FormConfigurationModule),
    canActivate: [IsAuthenticatedGuard],
    data: {
      shouldReuse: true,
    },
  },
  {
    path: "mail",
    pathMatch: "full",
    loadChildren: () => import("./pages/mail-client/mail-client.module").then((m) => m.MailClientModule),
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: ":formId",
    loadChildren: () => import("./pages/base/base.module").then((m) => m.BaseModule),
    canActivate: [IsAuthenticatedGuard],
    data: {
      shouldReuse: true,
    },
  },
  {
    path: ":formId/:itemId",
    loadChildren: () => import("./pages/base/base.module").then((m) => m.BaseModule),
    canActivate: [IsAuthenticatedGuard],
    data: {
      shouldReuse: true,
    },
  },
  { path: "", pathMatch: "full", redirectTo: "/hub" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: MetaRouteReuseStrategy,
    },
  ],
})
export class AppRoutingModule {}
