/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NzCardComponent, NzCardMetaComponent } from "ng-zorro-antd/card";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NzModalFooterDirective, NzModalTitleDirective } from "ng-zorro-antd/modal";
import { NzResultModule } from "ng-zorro-antd/result";
import { MetaButtonModule, MetaTextareaModule, MetaToolbarModule } from "@meta/ui";
import { ApproveService } from "./approve.service";
import { NzListModule } from "ng-zorro-antd/list";
import { NzGridModule } from "ng-zorro-antd/grid";
import { FormsModule } from "@angular/forms";
import { MetaSplitViewModule } from "../../../../../../libs/ui/src/lib/components/metaSplitView/metaSplitView.component";
import { ApproveModalComponent } from "./approveModal.component";

@NgModule({
  declarations: [ApproveModalComponent],
  imports: [
    CommonModule,
    MetaToolbarModule,
    MetaTextareaModule,
    MetaButtonModule,
    NzResultModule,
    NzListModule,
    NzGridModule,
    FormsModule,
    MetaSplitViewModule,
    NzModalTitleDirective,
    NzModalFooterDirective,
    NzCardComponent,
    NzIconDirective,
    NzCardMetaComponent,
  ],
  providers: [ApproveService],
  exports: [],
})
export class ApproveModule {}
