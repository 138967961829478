<!--
  ~ Copyright (C) MetaCarp GmbH - All Rights Reserved
  ~ Unauthorized copying of this file, via any medium is strictly prohibited
  ~ Proprietary and confidential
  ~ Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
  ~ Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
  -->

<ng-template #nzTreeTemplate let-node>
  <span class="node-container">
    <span (click)="onClick(node)" *ngIf="node.prefix" [innerHTML]="node.prefix | sanitizeHtml"
          class="node-prefix">&#125;</span>
    <span [style.color]="node.color | nodeColor" (click)="onClick(node)" class="node-title" [innerHTML]="node.title | sanitizeHtml"></span>
    <span (click)="onClick(node)" *ngIf="node.suffix" class="node-suffix"
          [innerHTML]="node.suffix | sanitizeHtml"></span>
    <a *ngIf="node.link" [routerLink]="node.link" class="node-link"><i class="fas fa-link"></i></a>
  </span>
</ng-template>


<nz-tree-view *ngIf="dataSource" [nzTreeControl]="treeControl" [nzDataSource]="dataSource">
  <nz-tree-node *nzTreeNodeDef="let node" nzTreeNodeIndentLine>
    <nz-tree-node-toggle nzTreeNodeNoopToggle></nz-tree-node-toggle>
    <nz-tree-node-option
      [nzDisabled]="node.disabled"
      [nzSelected]="selectListSelection.isSelected(node)"
    >
      <ng-container *ngTemplateOutlet="nzTreeTemplate; context: { $implicit: node }"></ng-container>
    </nz-tree-node-option>
  </nz-tree-node>

  <nz-tree-node *nzTreeNodeDef="let node; when: hasChild" nzTreeNodeIndentLine>
    <nz-tree-node-toggle>
      <span nz-icon nzType="caret-down" nzTreeNodeToggleRotateIcon></span>
    </nz-tree-node-toggle>
    <nz-tree-node-option
      [nzDisabled]="node.disabled"
      [nzSelected]="selectListSelection.isSelected(node)"
    >
      <ng-container *ngTemplateOutlet="nzTreeTemplate; context: { $implicit: node }"></ng-container>
    </nz-tree-node-option>
  </nz-tree-node
  >
</nz-tree-view>
