/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { ComponentRef, Injectable } from "@angular/core";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { SearchComponent } from "./search.component";
import { HttpClient } from "@angular/common/http";
import { Observable, of, shareReplay, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { TranslateService } from "@tolgee/ngx";

@Injectable({
  providedIn: "platform",
})
export class SearchService {
  private readonly searchPortal: ComponentPortal<SearchComponent>;
  private overlayRef: OverlayRef;
  private comp: ComponentRef<SearchComponent>;
  private readonly calculator = true;
  public readonly types$: Observable<any[]>;

  constructor(
    private readonly overlay: Overlay,
    private http: HttpClient,
    private readonly translateService: TranslateService,
  ) {
    this.searchPortal = new ComponentPortal(SearchComponent);
    document.addEventListener("keydown", (e: KeyboardEvent) => {
      if ((e.ctrlKey && e.code === "Space") || (e.altKey && e.code === "Space")) {
        e.preventDefault();
        this.toggleSearch();
      }
      if (this.overlayRef && e.code === "Escape") {
        e.preventDefault();
        if (this.comp.instance.handleClose()) {
          this.hideSearch();
        }
      }
    });
    this.types$ = this.http.get<any[]>("entity/types").pipe(
      map((e) => e.filter((d) => d.searchable)),
      shareReplay(),
    );
  }

  public init() {}

  public toggleFavorite(id: string, type: string, remove: boolean) {
    if (remove) {
      return this.http.delete<any>("favorites", {
        body: {
          quellId: id,
          type: type,
        },
      });
    } else {
      return this.http.post<any>("favorites", {
        quellId: id,
        type: type,
      });
    }
  }

  public search(q: string, categories?: string[], tags: string[] = [], page = 0, hitsPerPage = 25) {
    if (!q || q.trim().length === 0) {
      return of(null);
    }
    if (q === "*") {
      q = "";
    }
    if (q.indexOf("#") === 0) {
      q = q.slice(1).trim().toLowerCase();
      return this.types$.pipe(
        map((types) => {
          return {
            result: types
              .filter(
                (t) => String(t.label).toLowerCase().includes(q) || String(t.labelPlural).toLowerCase().includes(q),
              )
              .map((t) => {
                return {
                  id: t.value,
                  icon: t.icon,
                  type: "category",
                  typeName: "Kategorie",
                  titel: this.translateService.instant(
                    `entities.${String(t.value).toLowerCase()}.name_plural`,
                    t.label,
                  ),
                  class: "shortcut",
                  item: t,
                };
              }),
          };
        }),
      );
    }
    return this.http
      .get<any>("entity/search", {
        params: {
          q,
          page,
          hitsPerPage,
          ...(categories.length > 0 ? { type: JSON.stringify(categories) } : {}),
          ...(tags.length > 0 ? { tags: JSON.stringify(tags) } : {}),
        },
      })
      .pipe(
        switchMap(async (e) => {
          if (page === 1 && this.calculator) {
            try {
              const mathjs = await import("mathjs");
              const expr = q.trim();
              const res = mathjs.evaluate(expr);
              if (res !== undefined && res !== null && String(res) !== expr && typeof res !== "function") {
                const numberFormatter = new Intl.NumberFormat();
                e.result.unshift({
                  id: "calc",
                  icon: "calculator",
                  type: "calc",
                  typeName: "Ergebnis",
                  titel: `${expr.trim()} = <b>${typeof res === "number" ? numberFormatter.format(res) : res}</b>`,
                  class: "shortcut",
                });
              }
            } catch (e) {}
          }
          return e;
        }),
      );
  }

  public showSearch() {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        scrollStrategy: this.overlay.scrollStrategies.block(),
      });
      this.comp = this.overlayRef.attach(this.searchPortal);
    }
  }

  public hideSearch() {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
      this.overlayRef = null;
      this.comp = null;
    }
  }

  private toggleSearch() {
    if (this.overlayRef) {
      this.hideSearch();
    } else {
      this.showSearch();
    }
  }
}
