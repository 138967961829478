/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { ILiveCardData } from "./live-card";

export interface IFeedResultItem<T = Record<string, any>> {
  ID: number;
  id: number;
  operation: {
    id: string;
    name: string;
    icon: string;
  };
  data: T;
  entity: {
    link: string;
    id: string;
    title: string;
    name: string;
    icon: string;
    type: string;
    displayId?: string;
    color?: string;
  };
  date: string;
  user: {
    name: string;
    id: string;
  };
  comments: Array<any>;
  cardData?: ILiveCardData;
}
