/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgModule, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";

export class MetaAvatar {
  data?: MetaAvatarData[];
  label?: string;
  colorString?: string;
  id?: string;
  type?: string = "ANW";
  src?: string;
  icon?: string;
  badgeIcon?: string;
  tooltip?: string;
  size?: "large" | "small" | "tiny" | "default" | number = "default";
  letterCount? = 2;
  letterDelimiter? = " ";
  maxItems? = 6;
}

export class MetaAvatarData {
  label: string;
  id?: string;
  src?: string;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-avatar",
  template: `
    <ng-container *ngIf="maParams as maParams">
      <nz-badge [nzCount]="maParams.badgeIcon ? iconTemplate : null" style="margin-right: 24px;">
        <nz-avatar
          *ngIf="!maParams.data"
          [ngStyle]="{ 'background-color': color }"
          nz-tooltip
          class="single size-{{ maParams.size }}"
          [style.--bg-image]="src"
          [nzTooltipTitle]="!hideTooltip ? maParams.tooltip || maParams.label : null"
          [style.fontSize]="imageSize ? 'calc(1rem*' + maParams.size + '/35)' : 'inherit'"
          [nzSize]="maParams.size"
          [nzText]="!maParams.icon ? letter : null"
        ></nz-avatar>
        <ng-container *ngIf="maParams.data">
          <div class="ant-avatar-group">
            <ng-container *ngFor="let avatar of maParams.data; let index = index; trackBy: trackById">
              <nz-avatar
                *ngIf="index < maxItems"
                nz-tooltip
                [nzTooltipTitle]="hideTooltip ? null : avatar.label"
                [style.--bg-image]="avatar.src"
                [ngStyle]="{ 'background-color': colors[index] }"
                [nzSize]="maParams.size"
                [nzText]="imageSize <= 20 ? '' : letters[index]"
              ></nz-avatar>
              <ng-container *ngIf="index === maxItems">
                <nz-avatar
                  [nzSize]="maParams.size"
                  [nzText]="'+' + (maParams.data.length - index)"
                  [style.--bg-image]="avatar.src"
                  nz-popover
                  [ngStyle]="{ 'background-color': colors[index] }"
                  nzPopoverTrigger="click"
                  (click)="$event.stopPropagation()"
                  [(nzPopoverVisible)]="popoverVisible"
                  [nzPopoverContent]="contentTemplate"
                >
                </nz-avatar>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </nz-badge>
      <ng-template #iconTemplate>
        <i class="far fa-{{ maParams.badgeIcon }} ant-scroll-number-custom-component"></i>
      </ng-template>
      <ng-template #contentTemplate>
        <div class="ant-avatar-group">
          <ng-container *ngFor="let avatar of maParams.data; let index = index; trackBy: trackById">
            <nz-avatar
              *ngIf="index >= maxItems"
              nz-tooltip
              [nzTooltipTitle]="hideTooltip ? null : avatar.label"
              [ngStyle]="{ 'background-color': colors[index] }"
              [nzSize]="maParams.size"
              [nzText]="letters[index]"
              [nzSrc]="avatar.src"
            ></nz-avatar>
          </ng-container>
        </div>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ["./metaAvatar.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaAvatarComponent implements OnInit, OnChanges {
  public static cache: Record<string, string> = {};
  public letter: string | null = null;
  public color: string;
  public letters: string[] = [];
  public colors: string[] = [];
  public popoverVisible = false;
  public imageSize = 70;
  public maxItems = 3;
  public src: string;
  @Input()
  public hideTooltip = false;

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private metaHelperService: MetaHelperService,
  ) {}

  private _maParams: MetaAvatar = new MetaAvatar();

  @Input()
  public get maParams(): MetaAvatar {
    return this._maParams;
  }

  public set maParams(value: MetaAvatar) {
    this._maParams = { ...this._maParams, ...value };
  }

  ngOnInit() {
    this.updateAvatar();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateAvatar();
  }

  public getSrc(data: MetaAvatarData) {
    return !this.maParams.src
      ? `/api/v2/file/avatar/${this.maParams.type}/${data.id}?width=${this.imageSize * 2}&height=${this.imageSize * 2}`
      : this.maParams.src;
  }

  public updateAvatar() {
    if (this.maParams.src) {
      this.src = `url(${this.maParams.src})`;
    }
    if (typeof this.maParams.size === "number") {
      this.imageSize = this.maParams.size;
    }
    if (this.maParams.label) {
      this.letter = this.metaHelperService.generateLetterAvatar(
        this.maParams.label,
        this.maParams.letterDelimiter,
        this.maParams.letterCount,
      );
      this.color = this.metaHelperService.generateColor(this.maParams.colorString || this.letter);
    }
    if (this.maParams.data) {
      this.maParams.data.forEach((a, i) => {
        this.letters[i] = this.metaHelperService.generateLetterAvatar(
          a.label,
          this.maParams.letterDelimiter,
          this.maParams.letterCount,
        );
        this.colors[i] = this.metaHelperService.generateColor(this.maParams.colorString || this.letters[i]);
        a.src = `url(${this.getSrc(a)})`;
      });
    }
    if (this.maParams.id) {
      this.src = `url(${this.getSrc({ id: this.maParams.id, label: this.maParams.label })})`;
    }
  }

  public trackById(index: number, item: any) {
    return item.id;
  }
}

@NgModule({
  declarations: [MetaAvatarComponent],
  imports: [CommonModule, NzAvatarModule, NzToolTipModule, NzBadgeModule, NzPopoverModule],
  exports: [MetaAvatarComponent],
})
export class MetaAvatarModule {}
