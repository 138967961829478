/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  Input,
  NgZone,
  signal,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { NzSliderModule } from "ng-zorro-antd/slider";
import { FormsModule } from "@angular/forms";
import { NzButtonModule } from "ng-zorro-antd/button";
import { DatePipe } from "@angular/common";
import { NzPopoverModule } from "ng-zorro-antd/popover";

@Component({
  standalone: true,
  template: `
    <div (mousemove)="mouseMove()" (dblclick)="toggleFullscreen()" class="player-wrapper">
      <video #videoElement [volume]="volume" [autoplay]="autoplay" preload="metadata" [src]="src"></video>

      @if (type === "audio") {
        <div class="audio-image-container">
          <i class="fal fa-music"></i>
        </div>

        <img alt="Background animation" class="audio-svg" src="/assets/images/aurora.svg" />
      }

      @if (controlSignal()) {
        <div class="controls">
          @if (stateSignal() === "PLAYING") {
            <button nz-button (click)="pause()">
              <i class="fal fa-pause"></i>
            </button>
          } @else {
            <button nz-button (click)="play()">
              <i class="fal fa-play"></i>
            </button>
          }
          <span>{{ timeSignal() * 1000 | date: "mm:ss" }}</span>
          <nz-slider
            [ngModel]="timeSignal()"
            (ngModelChange)="videoElement.currentTime = $event"
            [nzMin]="0"
            [nzMax]="durationSignal()"
          ></nz-slider>
          @if (size === "default" || fullscreenSignal()) {
            <span>{{ durationSignal() * 1000 | date: "mm:ss" }}</span>
          }
          <ng-template #volTmpl>
            <div style="height: 125px">
              <nz-slider [nzMin]="0" [nzMax]="1" [nzStep]="0.01" nzVertical [(ngModel)]="volume"></nz-slider>
            </div>
          </ng-template>

          <button
            (dblclick)="toggleMute()"
            nz-button
            nz-popover
            [nzPopoverTrigger]="'click'"
            [nzPopoverContent]="volTmpl"
          >
            @if (volume === 0) {
              <i class="fal fa-volume-mute"></i>
            } @else {
              <i class="fal fa-volume"></i>
            }
          </button>

          @if (airplaySignal()) {
            <button nz-button (click)="airplay()">
              <i class="fal fa-airplay"></i>
            </button>
          }

          @if (size === "default" || fullscreenSignal()) {
            @if (fullscreenSignal()) {
              <button nz-button (click)="exitFullscreen()">
                <i class="fal fa-minimize"></i>
              </button>
            } @else {
              <button nz-button (click)="requestFullscreen()">
                <i class="fal fa-maximize"></i>
              </button>
            }
          }
        </div>
      }
    </div>
  `,
  selector: "meta-media-library-player",
  imports: [NzSliderModule, FormsModule, NzButtonModule, DatePipe, NzPopoverModule],
  styleUrl: "./metaMediaLibraryPlayer.component.less",
})
export class MetaMediaLibraryPlayerComponent implements AfterViewInit {
  public readonly timeSignal = signal<number>(0);
  public readonly durationSignal = signal<number>(0);
  public readonly stateSignal = signal<"PAUSED" | "PLAYING" | "STOPPED">("STOPPED");
  public readonly fullscreenSignal = signal<boolean>(false);
  public readonly controlSignal = signal<boolean>(true);
  public readonly airplaySignal = signal<boolean>(false);

  private mouseTimeoutRef: any;

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly destroyRef: DestroyRef,
  ) {}

  ngAfterViewInit(): void {
    this.videoElement.nativeElement.addEventListener("timeupdate", () => {
      this.timeSignal.set(this.videoElement.nativeElement.currentTime);
    });

    this.videoElement.nativeElement.addEventListener("pause", () => {
      this.stateSignal.set("PAUSED");
    });

    this.videoElement.nativeElement.addEventListener("play", () => {
      this.stateSignal.set("PLAYING");
    });

    this.videoElement.nativeElement.addEventListener("ended", () => {
      this.stateSignal.set("STOPPED");
    });

    this.videoElement.nativeElement.addEventListener("durationchange", () => {
      this.durationSignal.set(this.videoElement.nativeElement.duration);
    });

    if (window["WebKitPlaybackTargetAvailabilityEvent"]) {
      this.videoElement.nativeElement.addEventListener("webkitplaybacktargetavailabilitychanged", (event: any) => {
        switch (event.availability) {
          case "available":
            this.airplaySignal.set(true);
            break;
          case "not-available":
            this.airplaySignal.set(false);
            break;
        }
      });
      this.videoElement.nativeElement.addEventListener(
        "webkitcurrentplaybacktargetiswirelesschanged",
        (event: any) => {},
      );
    }

    const fullscreenchangeListener = (event: any) => {
      this.fullscreenSignal.set(!!document.fullscreenElement);
    };
    document.addEventListener("fullscreenchange", fullscreenchangeListener);
    this.destroyRef.onDestroy(() => {
      document.removeEventListener("fullscreenchange", fullscreenchangeListener);
    });

    if (this.autoplay) {
      this.play().catch(() => {});
    }
  }
  @ViewChild("videoElement", {
    static: true,
  })
  public videoElement: ElementRef<HTMLVideoElement>;

  @Input()
  public src: SafeResourceUrl;

  @Input()
  public type: "video" | "audio" = "video";

  @Input()
  public autoplay = false;

  public volume = 1;
  @Input() public size: "default" | "small" = "default";

  public play() {
    return this.videoElement.nativeElement?.play();
  }
  public pause() {
    return this.videoElement.nativeElement?.pause();
  }

  public togglePlay() {
    return this.videoElement.nativeElement.paused ? this.play() : this.pause();
  }

  public toggleMute() {
    this.volume = this.volume === 1 ? 0 : 1;
    this.videoElement.nativeElement.volume = this.volume;
  }

  public toggleFullscreen() {
    if (this.fullscreenSignal()) {
      return this.exitFullscreen();
    } else {
      return this.requestFullscreen();
    }
  }

  public requestFullscreen() {
    return (this.viewContainerRef.element.nativeElement as HTMLElement)
      .querySelector(".player-wrapper")
      .requestFullscreen();
  }

  public exitFullscreen() {
    return window.document.exitFullscreen();
  }

  public mouseMove() {
    this.controlSignal.set(true);
    if (this.mouseTimeoutRef) {
      clearTimeout(this.mouseTimeoutRef);
    }
    this.mouseTimeoutRef = setTimeout(() => this.controlSignal.set(false), 8_000);
  }

  public airplay() {
    this.videoElement.nativeElement["webkitShowPlaybackTargetPicker"]();
  }
}
