/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ValidatableComponent } from "./validatableComponent";
import { ExposeProperty, MetaFormComponentInfo } from "./baseComponent";
import * as _ from "lodash";
import { Exclude, Expose } from "class-transformer";
import { FormEventActionHandler } from "../interfaces";
import { MetaRadioStyle } from "@meta/enums";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-radio",
  type: "radio",
  name: "Radio",
  icon: "circle-dot",
  palletGroup: "input",
  hideInPallet: true,
})
export class MetaFormRadioComponent extends ValidatableComponent {
  @ExposeProperty() public data?: { value: number; label: string };
  public onSelect?: FormEventActionHandler;
  @ExposeProperty()
  style?: MetaRadioStyle | string = MetaRadioStyle.default;
  @ExposeProperty()
  iconOnly? = false;

  constructor() {
    super();
  }

  public static create<T>(dataSource: T, opts: Partial<MetaFormRadioComponent>) {
    const input = new MetaFormRadioComponent();
    input.setOptions(opts);
    return input;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        data: this.data,
        onSelect: !!this.onSelect,
        style: this.style,
        iconOnly: this.iconOnly,
      },
    });
  }
}
