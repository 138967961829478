/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormlyModule } from "@ngx-formly/core";
import { MetaActionHandlerFactory } from "../../base/metaForm/actions/actionHandler.factory";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { MetaColor } from "@meta/enums";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { Subject, takeUntil } from "rxjs";
import { filter } from "rxjs/operators";

export class MetaProgress {
  percent: number;
  steps?: number;
  isStepped? = false;
  appendToToolbar? = false;
  type?: MetaProgressType = MetaProgressType.line;
  size?: MetaProgressSize = MetaProgressSize.default;
  onClick? = false;
  showInfo? = true;
  color? = null;
  stepData?: any[];
  hideStepLabel? = false;
  tresholds?: MetaProgressTreshold = {
    [MetaColor.success]: 100,
    [MetaColor.warning]: 50,
    [MetaColor.danger]: 25,
  };
}

export enum MetaProgressType {
  line = "line",
  circle = "circle",
  dashboard = "dashboard",
  bigArrows = "bigArrows",
}

export enum MetaProgressSize {
  default = "default",
  small = "small",
}

export type MetaProgressTreshold = {
  [key in MetaColor]?: number;
};

@MetaUnsubscribe()
@Component({
  selector: "meta-progress",
  template: ` @if (ma.isStepped) {
      <ng-container *ngIf="field || ma.stepData">
        <div
          class="step-container {{ getClass() }}"
          [ngClass]="{ appendToToolbar: ma.appendToToolbar, 'big-arrows': ma.type === 'bigArrows' }"
        >
          <ng-container *ngFor="let step of ma.stepData || (formState?.displayData() || {})[field.id]?.steps">
            <div
              (click)="onStepClick(step)"
              nz-tooltip
              [nzTooltipMouseEnterDelay]="0.3"
              nzTooltipTitle="{{ step.label }}"
              class="step-item step-{{ step.color }}"
            >
              <span class="step-item-label">{{ this.ma.hideStepLabel ? "" : step.label }}</span>
            </div>
          </ng-container>
        </div>
      </ng-container>
    } @else {
      <div class="progress-container">
        <nz-progress
          [nzType]="ma.type === 'bigArrows' ? 'line' : ma.type"
          [class]="getClass()"
          [nzPercent]="percent"
          [nzShowInfo]="ma.steps ? false : ma.showInfo"
          [nzSize]="ma.size ? ma.size : null"
          [nzSteps]="ma.steps ? ma.steps : null"
          [nzStrokeColor]="ma.steps ? strokeColor : null"
        ></nz-progress>
      </div>
    }`,
  styleUrls: ["./metaProgress.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaProgressComponent extends MetaComponentBase implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  public color: string;
  public percent: number;
  private lastPercentValue: number;
  private _destroyed$ = new Subject();

  get ma(): MetaProgress {
    return super.ma;
  }
  constructor(
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _renderer: Renderer2,
    private readonly _metaActionHandler: MetaActionHandlerFactory,
  ) {
    super();
    super.maParams = new MetaProgress();
  }

  private _strokeColor = getComputedStyle(document.documentElement).getPropertyValue("--text-color");
  get strokeColor() {
    return this._strokeColor;
  }

  set strokeColor(color: string) {
    this._strokeColor = color;
  }

  ngOnInit() {
    this._setValue();

    if (this.field) {
      this.formState.data$
        .pipe(
          takeUntil(this._destroyed$),
          filter((x) => x !== undefined),
        )
        .subscribe({
          next: async (data) => {
            this._setValue(data[this.id]);
          },
        });
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._destroyed$.next(null);
    this._destroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    this._setValue();
  }

  ngAfterViewInit(): void {
    if (!this.ma.steps) return;

    const steps = this._viewContainerRef.element.nativeElement.querySelectorAll(".ant-progress-steps-item");

    const stepPercent = 100 / this.ma.steps;

    const completedStepCount = this.percent / stepPercent;

    steps.forEach((step, index) => {
      const isCompletedStep = index < completedStepCount;

      if (isCompletedStep) return (step.style.color = "#efdfc2a6");

      step.style.color = "transparent";
    });
  }

  public getClass() {
    if (this.ma.color) {
      switch (this.ma.color) {
        case "0":
          return MetaColor.danger;
        case "1":
          return MetaColor.warning;
        case "2":
          return MetaColor.success;
        default:
          return this.ma.color;
      }
    }

    if (this.color && this.lastPercentValue === this.ma.percent) {
      return this.color;
    }

    let color;

    const tresholds = Object.values(this.ma.tresholds).sort((a: any, b: any) => b - a);
    for (let i = 0; i < tresholds.length; i++) {
      if (this.ma.percent <= tresholds[i]) {
        for (const key in this.ma.tresholds) {
          if (this.ma.tresholds.hasOwnProperty(key) && this.ma.tresholds[key] === tresholds[i]) {
            color = key;
          }
        }
      }
    }

    this.color = color;
    this.lastPercentValue = this.percent;

    return color;
  }

  private _setValue(value?: number) {
    value = value || this.ma.percent;

    if (value) {
      this.percent = Math.round(value * 100);
    }
    this.strokeColor = getComputedStyle(document.documentElement).getPropertyValue("--text-color");
    const elements = document.querySelectorAll('.ant-progress-steps-item[style*="background-color:"]');
    [].forEach.call(elements, (element) => {
      this._renderer.setAttribute(
        element,
        "style",
        `background-color:${getComputedStyle(document.documentElement).getPropertyValue("--text-color")}`,
      );
    });
    this.changeDetectorRef.markForCheck();
  }

  onStepClick(step: Record<string, any>) {
    if (this.ma.onClick) {
      this._metaActionHandler.executeClickAction({
        controlId: this.field.id,
        formId: this.formState.formId,
        data: {
          step,
        },
        formIsValid: true,
        passthroughData: { formId: this.formState.formId },
        ctx: this.model._ctx,
      });
    }
  }
}

@NgModule({
  declarations: [MetaProgressComponent],
  imports: [CommonModule, FormlyModule, NzProgressModule, NzToolTipModule],
  exports: [MetaProgressComponent],
})
export class MetaProgressModule {}
