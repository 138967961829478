/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule, ViewEncapsulation } from "@angular/core";
import { FormlyModule } from "@ngx-formly/core";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaHubService } from "./metaHub.service";

export class MetaHub {}

@MetaUnsubscribe()
@Component({
  selector: "meta-hub",
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaHubComponent extends MetaComponentBase {
  constructor(_changeDetectorRef: ChangeDetectorRef, _metaHelperService: MetaHelperService) {
    super();
    super.maParams = new MetaHub();
  }

  get ma(): MetaHub {
    return super.ma;
  }
}

@NgModule({
  declarations: [MetaHubComponent],
  imports: [CommonModule, FormlyModule, NzWaveModule, NzButtonModule, NzPopconfirmModule],
  exports: [MetaHubComponent],
  providers: [MetaHubService],
})
export class MetaHubModule {}
