/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaBaseValidator } from "./baseValidator";
import * as validator from "class-validator";

export class IsUppercaseValidatorOptions {}

export class IsUppercase extends MetaBaseValidator {
  constructor(public options?: IsUppercaseValidatorOptions) {
    super();
  }

  async validate(value): Promise<boolean> {
    return validator.isUppercase(value);
  }
}
