/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { DestroyRef, inject, Injectable } from "@angular/core";
import {
  ServerTemplateResponse,
  ServerTemplateResponseGetSelectionAction,
  ServerTemplateResponseInitializeFormAction,
  ServerTemplateResponseReloadFormAction,
  ServerTemplateResponseSaveAction,
  ServerTemplateResponseSetDisplayValueAction,
  ServerTemplateResponseSetValueAction,
  ServerTemplateResponseUpdateDisplayValuesFormAction,
} from "@meta/forms";
import { plainToInstance } from "class-transformer";
import { MetaFormService } from "../metaForm.service";
import { FormSupervisorService } from "../metaForm.supervisor";
import { GetSelectionActionHandler } from "./getSelection.action";
import { InitializeFormActionHandler } from "./initializeForm.action";
import { ReloadFormActionHandler } from "./reload.action";
import { SaveActionHandler } from "./save.action";
import { SetDisplayValueActionHandler } from "./setDisplayValue.action";
import { SetValueActionHandler } from "./setValue.action";
import { UpdateDisplayValuesActionHandler } from "./updateDisplayValues.action";

@Injectable()
export class LocalActionHandlerFactory {
  public handlers = new Map();
  private isRegistered = false;

  constructor(
    public metaFormService: MetaFormService,
    private supervisor: FormSupervisorService,
  ) {
    this.handlers.set(ServerTemplateResponseSetValueAction, inject(SetValueActionHandler));
    this.handlers.set(ServerTemplateResponseInitializeFormAction, inject(InitializeFormActionHandler));
    this.handlers.set(ServerTemplateResponseReloadFormAction, inject(ReloadFormActionHandler));
    this.handlers.set(ServerTemplateResponseSaveAction, inject(SaveActionHandler));
    this.handlers.set(ServerTemplateResponseSetDisplayValueAction, inject(SetDisplayValueActionHandler));
    this.handlers.set(ServerTemplateResponseGetSelectionAction, inject(GetSelectionActionHandler));
    this.handlers.set(ServerTemplateResponseUpdateDisplayValuesFormAction, inject(UpdateDisplayValuesActionHandler));
  }

  public registerFormService(formId: string, destroyRef: DestroyRef) {
    if (!this.isRegistered) {
      this.supervisor.registerFormService(formId, this, destroyRef);
      this.isRegistered = true;
    }
  }

  public async handleSocketResponse(data: any, ack: CallableFunction) {
    const res = plainToInstance(ServerTemplateResponse, data);
    const resp = await this.handleActionResponse({
      res,
      formId: res.formId,
      subFormPath: res.subFormPath,
      index: res.index,
      passthroughData: res.passthroughData,
    });
    ack(resp);
  }

  async handleActionResponse(opts: {
    res: any;
    formId: string;
    subFormPath?: string[];
    index: number;
    passthroughData: Record<string, any>;
  }) {
    const responses: any[] = [];
    for (const action of opts.res.actions) {
      const handler = this.handlers.get(action.constructor);
      if (handler) {
        const resp = await handler.handle(action, opts);
        responses.push(resp);
      } else {
        console.warn(`No handler found for action type: ${action.constructor.name}`);
      }
    }
    responses.push(Date.now());
    return responses;
  }
}
