/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, NgModule, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { MetaIconStyle } from "@meta/enums";
import { FormlyModule } from "@ngx-formly/core";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaButtonModule } from "../metaButton/metaButton.component";

export class MetaPopover {
  public content: string | TemplateRef<any>;
  public icon? = "cog";
  public iconStyle?: MetaIconStyle | string = MetaIconStyle.Light;
  public label? = "";
  public size?: "large" | "default" | "small" = "default";
  public type?: "primary" | "default" | "link" | "danger" = "default";
  public position?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom" = "bottomRight";
  public disabled? = false;
  public modalWidth? = "auto";
}

@MetaUnsubscribe()
@Component({
  selector: "meta-popover",
  template: `
    <meta-button
      [maParams]="{
        disabled: ma.disabled,
        iconStyle: ma.iconStyle,
        icon: ma.icon,
        size: ma.size,
        type: ma.type
      }"
      [nzPopoverContent]="ma.content"
      [nzPopoverPlacement]="ma.position"
      [nzPopoverTitle]="ma.label"
      [nzPopoverTrigger]="'click'"
      [nzPopoverOverlayStyle]="{ width: ma.modalWidth }"
      nz-popover
    >
      <ng-content></ng-content>
    </meta-button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaPopoverComponent extends MetaComponentBase {
  @ViewChild("meta-button", { read: ElementRef })
  public ref: ElementRef;

  constructor() {
    super();
    super.maParams = new MetaPopover();
  }

  get ma(): MetaPopover {
    return super.ma;
  }
}

@NgModule({
  declarations: [MetaPopoverComponent],
  imports: [CommonModule, FormlyModule, NzPopoverModule, MetaButtonModule],
  exports: [MetaPopoverComponent],
})
export class MetaPopoverModule {}
