/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { DestroyRef, EventEmitter, inject, Injectable } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { flatten } from "lodash";
import { MetaForm } from "../base/metaForm/metaForm.interface";
import { NzModalRef } from "ng-zorro-antd/modal";
import { debounceTime, map } from "rxjs/operators";
import { firstValueFrom, Observable, Subscription } from "rxjs";
import { MetaModalService } from "./metaModalService";
import { TranslateService } from "@tolgee/ngx";

@Injectable({ providedIn: "root" })
export class DirtyCheckService {
  private registeredObjects = new Map<any, { modalRef?: NzModalRef; dirtyCheckFunction?: () => boolean }>();
  private registeredForms = new Map<
    UntypedFormGroup,
    { metaForm: MetaForm; form: UntypedFormGroup; modalRef?: NzModalRef; subscriptions: Subscription[] }
  >();

  constructor(
    private modalService: MetaModalService,
    private readonly translateService: TranslateService,
  ) {
    window.onbeforeunload = (e) => {
      if (this.getDirtyForms().length > 0 || this.getDirtyObjects().length > 0) {
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    };
  }

  public registerObject<T = any>(
    o: T,
    dirtyCheckFunction?: () => boolean,
    modalRef?: NzModalRef,
    destroyRef?: DestroyRef,
  ) {
    if (!this.registeredObjects.has(o)) {
      this.registeredObjects.set(o, {
        modalRef,
        dirtyCheckFunction,
      });
      if (modalRef) {
        const nzOnCancel = modalRef.getConfig().nzOnCancel;
        modalRef.updateConfig({
          nzOnCancel: async (e) => {
            if (dirtyCheckFunction()) {
              const acceptAlert = await firstValueFrom(this.showDirtyAlert(modalRef));
              if (!acceptAlert) {
                return false;
              }
            }
            if (nzOnCancel) {
              if (nzOnCancel instanceof EventEmitter) {
                nzOnCancel.emit(e);
              } else {
                return nzOnCancel(e);
              }
              return true;
            }
          },
        });
      }
      if (destroyRef) {
        destroyRef.onDestroy(() => this.unregisterObject(o, modalRef));
      }
    }
  }

  public unregisterObject<T = any>(o: T, modalRef?: NzModalRef) {
    if (this.registeredObjects.has(o)) {
      this.registeredObjects.delete(o);
    }
  }

  public registerForm(form: UntypedFormGroup, metaForm: MetaForm, modalRef?: NzModalRef, destroyRef?: DestroyRef) {
    const subscriptions = [
      form.valueChanges.pipe(debounceTime(100)).subscribe((value) => {
        if (form.dirty) {
          this.formValueChanges(value, form, metaForm);
        }
      }),
    ];
    this.registeredForms.set(form, { form, metaForm, modalRef: modalRef || undefined, subscriptions });
    if (destroyRef) {
      destroyRef.onDestroy(() => this.unregisterForm(form));
    }
  }

  public formValueChanges(value: any, form: UntypedFormGroup, metaForm: MetaForm) {
    //console.log(metaForm.id, value);
  }

  public unregisterForm(form: UntypedFormGroup) {
    const data = this.registeredForms.get(form);
    if (data) {
      data.subscriptions.forEach((s) => s.unsubscribe());
      this.registeredForms.delete(form);
    }
  }

  public getDirtyFields() {
    const dirtyForms = this.getDirtyForms();
    return dirtyForms.map(({ form, metaForm }) => {
      return {
        form,
        metaForm,
        controls: Object.keys(this.getDirtyState(form)).map((k) => form.controls[k]),
      };
    });
  }

  public isDirty(modalRef?: NzModalRef) {
    return this.getDirtyForms(modalRef).length > 0 || this.getDirtyObjects().length > 0;
  }

  public showDirtyAlert(modalRef?: NzModalRef): Observable<boolean> {
    const forms = this.getDirtyForms(modalRef);

    const d = flatten<string>(
      this.getDirtyFields().map((e) => {
        return e.controls?.map((c) => c["_fields"]?.[0]?.["props"]?.["label"]);
      }),
    ).filter((e) => !!e);
    let title: string;
    const content = `
${this.translateService.instant("dirty_alert.content").replace("\n", "<br>")}<br><br>
    `.trim();
    const nzOkText = this.translateService.instant("dirty_alert.ok_text");

    if (forms[0] && forms[0].metaForm) {
      title = this.translateService.instant("dirty_alert.title_with_form", { name: forms[0].metaForm.label });
    } else {
      title = this.translateService.instant("dirty_alert.title");
    }
    const ref = this.modalService.confirm({
      nzTitle: title,
      nzContent: content,
      nzOkText,
      nzOkDanger: true,
      nzOnOk: () => ({ result: true }),
      nzOnCancel: () => ({ result: false }),
    });
    return ref.afterClose.pipe(map((e) => e.result));
  }

  public getDirtyObjects(filterModalRef?: NzModalRef) {
    return [...this.registeredObjects.entries()]
      .filter(([o, e]) => {
        if (filterModalRef && e.modalRef !== filterModalRef) {
          return false;
        }
        return e.dirtyCheckFunction ? e.dirtyCheckFunction() : true;
      })
      .map(([o]) => o);
  }

  private getDirtyForms(filterModalRef?: NzModalRef) {
    return [...this.registeredForms.values()].filter(({ form, metaForm, modalRef }) => {
      return form.dirty && form.touched && modalRef === filterModalRef;
    });
  }

  private getDirtyState(form: UntypedFormGroup): any {
    return Object.keys(form.controls).reduce<any>((dirtyState, controlKey) => {
      const control = form.controls[controlKey];
      if (!control.dirty) {
        return dirtyState;
      }
      if (control instanceof UntypedFormGroup) {
        return {
          ...dirtyState,
          [controlKey]: this.getDirtyState(control),
        };
      }
      return {
        ...dirtyState,
        [controlKey]: control.value,
      };
    }, {});
  }
}
