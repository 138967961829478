/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  DestroyRef,
  Directive,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { TranslateService } from "@tolgee/ngx";
import {  Subscription } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MetaLiveCardComponent } from "@meta/ui";
import { JsonPipe } from "@angular/common";

@Directive({ selector: "[meta-translate-child]", standalone: true })
export class MetaTranslateChildDirective {
  constructor(public readonly templateRef: TemplateRef<any>) {}
  @Input({ required: true })
  public key: string;
}

@Component({
  selector: "meta-translate",
  standalone: true,
  template: `
    <ng-container #cont></ng-container>
    <ng-template let-val #tmpl>{{ val }}</ng-template>
    <ng-template let-val #liveCard>
      @defer {
        <meta-live-card
          [cardLazyLoad]="true"
          [cardType]="val.entityType"
          [cardSize]="val.size"
          [cardId]="val.entityId"
        ></meta-live-card>
      }
    </ng-template>
  `,
  imports: [MetaTranslateChildDirective, MetaLiveCardComponent, JsonPipe],
})
export class MetaTranslateComponent implements OnChanges, AfterViewInit {
  private translateSubscription: Subscription;
  constructor(
    private readonly translateService: TranslateService,
    private readonly destroyRef: DestroyRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  public ngAfterViewInit() {
    this.translate(this.key);
    this.children.changes.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((e) => {
      this.translate(this.key);
    });
    this.isInitialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isInitialized) {
      return;
    }
    if (changes["key"]) {
      if (changes["key"].currentValue) {
        this.translate(changes["key"].currentValue);
      } else {
        this.vc.clear();
      }
    } else if (changes["data"]) {
      this.translate(this.key);
    }
  }
  @Input({ required: true })
  public key: string;

  @Input({ required: false })
  public ns: string = null;

  @Input({ required: false })
  public data: Record<string, string>;

  @ContentChildren(MetaTranslateChildDirective)
  public children!: QueryList<MetaTranslateChildDirective>;

  @ViewChild("cont", { read: ViewContainerRef, static: true })
  public vc: ViewContainerRef;

  @ViewChild("tmpl", { static: true })
  public tmpl: TemplateRef<any>;

  @ViewChild("liveCard", { static: true })
  public liveCard: TemplateRef<any>;

  private isInitialized = false;

  private translate(key: string) {
    const children = this.children?.toArray() || [];
    const cmp = "(((CMP)))";
    const keys = Object.fromEntries(
      children.map((e) => {
        return [e.key, `!____!${cmp}${e.key}!____!`];
      }),
    );
    if (this.translateSubscription) {
      this.translateSubscription.unsubscribe();
      this.translateSubscription = null;
    }

    let componentData: Record<string, { template: TemplateRef<any>; props: Record<string, any> }> = {};

    this.translateSubscription = this.translateService
      .translate(key, {
        ...(this.data || {}),
        ...keys,
        ns: this.ns,
        entityCard: (entityType: string, entityId: string, size = "default") => {
          const k = `${Object.keys(componentData).length}`;
          componentData[k] = {
            template: this.liveCard,
            props: { entityType, entityId, size },
          };
          return `!____!${cmp}${k}!____!`;
        },
      } as any)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((e) => {
        this.vc.clear();
        if (e) {
          e.split(/!____!/gim).forEach(
            (e) => {
              if (e.indexOf(cmp) === 0) {
                const k = e.slice(cmp.length);
                if (componentData[k]) {
                  this.vc.createEmbeddedView(componentData[k].template, { $implicit: componentData[k].props || {} });
                } else {
                  const c = children.find((g) => g.key === k);
                  if (c) {
                    this.vc.createEmbeddedView(c.templateRef, { $implicit: k });
                  }
                }
              } else {
                this.vc.createEmbeddedView(this.tmpl, { $implicit: e });
              }
            },
            (e: Error) => {
              this.vc.clear();
              this.vc.createEmbeddedView(this.tmpl, { $implicit: String(e) });
            },
          );
        }
        this.changeDetectorRef.markForCheck();
      });
  }
}
