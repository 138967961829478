/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Allow, IsBoolean, IsDate, IsNumber, IsOptional, IsString, IsUUID, MinLength } from "class-validator";
import { Transform, Type } from "class-transformer";

export enum COLLECTION_CHILD_TYPE {
  FILE = "file",
  COLLECTION = "collection",
}

export class CollectionChild {
  id: string;
  type: COLLECTION_CHILD_TYPE;
  name: string;
  owner: string;
  size?: number;
  mime?: string;
  created: Date;
}

export class CollectionPath {
  name: string;
  id: string;
}

export class Collection {
  name: string;
  id: string;
  owner: string;
  size: number;
  children: CollectionChild[];
  collectionPath: CollectionPath[];
}

export class RenameCollectionRequest {
  @IsString()
  @MinLength(1)
  name: string;
  @IsString()
  @IsOptional()
  description: string;
}

export class FileAssignementRequest {
  @IsString()
  @IsOptional()
  @Transform((v) => String(v.value))
  targetId?: string;

  @IsString()
  @IsOptional()
  @Transform((v) => String(v.value))
  targetType?: string;

  @IsString()
  @IsOptional()
  formId: string;
}

export class AddItemToCollectionRequest {
  @IsUUID()
  id: string;
}

export class PrintFileRequest {
  @IsString()
  @IsOptional()
  printer: string;
  @IsString()
  type: string;
  @IsString()
  @IsOptional()
  id: string;
  @IsString()
  @IsOptional()
  formId: string;
  @IsNumber()
  @Type(() => Number)
  copies: number;
  @IsBoolean()
  @Transform(({ value }) => (typeof value === "string" ? value === "true" : !!value))
  bw: boolean;
  @IsBoolean()
  @Transform(({ value }) => (typeof value === "string" ? value === "true" : !!value))
  landscape: boolean;
  @IsBoolean()
  @Transform(({ value }) => (typeof value === "string" ? value === "true" : !!value))
  duplex: boolean;
  @IsString()
  @IsOptional()
  pageSize: string;
  @IsNumber()
  @Type(() => Number)
  @IsOptional()
  bin: number;
  @IsString()
  @IsOptional()
  locale: string;
}

export class PrintDataRequest {
  @IsString()
  printer: string;
}

export class RenameFileRequest {
  @IsString()
  @IsOptional()
  @MinLength(1)
  name: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsString()
  @IsOptional()
  collection?: string;

  @IsOptional()
  @IsNumber()
  @Transform((v) => {
    if (v.value === null || v.value === undefined) {
      return null;
    }
    return parseInt(v.value, 10);
  })
  classification?: number;

  @IsDate()
  @IsOptional()
  @Type(() => Date)
  validUntil?: Date;

  @IsDate()
  @IsOptional()
  @Type(() => Date)
  validFrom?: Date;

  @Allow()
  @IsOptional()
  itemId?: string;

  @IsString()
  @IsOptional()
  formId?: string;

  @IsString()
  @IsOptional()
  @Transform((v) => String(v.value))
  targetId?: string;

  @IsString()
  @IsOptional()
  @Transform((v) => String(v.value))
  targetType?: string;

  @IsBoolean()
  @IsOptional()
  @Transform((v) => {
    if (typeof v.value === "boolean") {
      return v.value;
    }
    return v.value === "true";
  })
  external?: boolean;

  @IsOptional()
  @IsString({
    each: true,
  })
  @Transform((v) => {
    if (typeof v.value === "string") {
      return JSON.parse(v.value);
    }
    return v.value;
  })
  tags?: string[];

  @IsOptional()
  @Allow()
  retentionPolicy?: number;
}
