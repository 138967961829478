/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponent, MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";
import * as _ from "lodash";
import { MetaInfoboxType } from "@meta/enums";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-infobox",
  type: "infobox",
  name: "Infobox",
  icon: "info",
})
export class MetaFormInfoboxComponent extends MetaFormComponent {
  @MetaFormComponentProperty({
    name: "Text",
    type: "text",
    tab: "general",
    isTemplate: true,
    showAsTextarea: true,
  })
  public label: string;
  @MetaFormComponentProperty({
    name: "Type",
    type: "select",
    values: [
      { label: "Info", value: "info" },
      { label: "success", value: "success" },
      { label: "warning", value: "warning" },
      { label: "error", value: "error" },
    ],
    default: "info",
    tab: "style",
  })
  public infoboxType: MetaInfoboxType = MetaInfoboxType.info;

  public static create(opts: Partial<MetaFormInfoboxComponent>) {
    const item = new MetaFormInfoboxComponent();
    Object.assign(item, opts);
    return item;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      key: this.id,
      type: `meta-${this.constructor["meta"]["type"]}`,
      props: {
        label: this.label,
        type: this.infoboxType,
        hasCondition: !!this.condition,
      },
    });
  }
}
