/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { DataSourceParameters, ExposeProperty, MetaFormComponentInfo } from "./baseComponent";
import { ListInputComponent } from "./listInputComponent";
import { MetaFormSelectComponent } from "./selectComponent";
import type { DataSourceType } from "@meta/database/lib/types";
import * as _ from "lodash";
import type { FormEventActionHandler } from "../interfaces";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-multiselect",
  type: "multiselect",
  name: "Multiselect",
  icon: "ballot",
  palletGroup: "input",
})
export class MetaFormMultiSelectComponent<DataSource = {}, SelectDataSource = {}> extends ListInputComponent {
  @ExposeProperty()
  public selectLabelField: SelectDataSource extends (infer T)[] ? keyof T : keyof SelectDataSource;
  @ExposeProperty()
  public selectValueField: SelectDataSource extends (infer T)[] ? keyof T : keyof SelectDataSource;
  @ExposeProperty()
  public selectDataSource: DataSourceType;
  @ExposeProperty()
  public onSelect?: FormEventActionHandler;
  @ExposeProperty()
  public loadDataOnInit? = false;

  @ExposeProperty()
  public selectId: string;

  @ExposeProperty() public icon?: string;

  /**
   * Max height of the multiselect's Input in px
   */
  @ExposeProperty()
  public maxHeight: number;

  /**
   * The Form used to edit the entries
   */
  @ExposeProperty() public editForm?: string;

  public selectDataSourceParams: DataSourceParameters;

  public static createElement(attributes: Record<string, any>) {
    const ele = new MetaFormMultiSelectComponent();
    Object.assign(ele, attributes);
    const select = new MetaFormSelectComponent();
    select.selectLabelField = ele.selectLabelField;
    select.selectValueField = ele.selectValueField;
    select.selectDataSource = ele.selectDataSource;
    select.selectDataSourceParams = ele.selectDataSourceParams;
    select.path = ele.path;
    ele.displayValue = `{{${ele.selectLabelField}}}`;
    ele.path = null;
    ele.children.push(select);
    return ele;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        editForm: this.editForm,
        onSelect: !!this.onSelect,
        loadDataOnInit: this.loadDataOnInit,
        maxHeight: this.maxHeight,
      },
    });
  }
}
