/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormSelectComponent } from "./selectComponent";
import { ExposeProperty, MetaFormComponentInfo } from "./baseComponent";

@MetaFormComponentInfo({
  selector: "ma-tree-select",
  type: "treeSelect",
  name: "TreeSelectComponent",
})
export class TreeSelectComponent extends MetaFormSelectComponent {
  @ExposeProperty()
  selectParentField: string;

  constructor() {
    super();
  }

  public static createElement(attributes: Record<string, any>) {
    const ele = new TreeSelectComponent();
    Object.assign(ele, attributes);
    return ele;
  }
}
