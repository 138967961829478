/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, Input, OnInit } from "@angular/core";
import { MetaHelperService } from "../../../services/metaHelper.service";
import { MetaMediaLibraryService } from "../../metaMediaLibrary/metaMediaLibrary.service";
import { IFeedResultItem } from "@meta/api-interfaces";

export class MetaTimelineTemplateDocumentUploaded {
  data: IFeedResultItem<{
    collection: {
      id: string;
      name: string;
    };
    filename: string;
    size: number;
    thumb: boolean;
    type: {
      label: string;
      value: string;
    };
  }>;
}

@Component({
  selector: "meta-timeline-template-document-uploaded",
  template: `
    <meta-translate [key]="'timeline.document-uploaded'">
      <ng-template meta-translate-child [key]="'file'">
        <meta-live-card
          [cardLazyLoad]="true"
          cardType="FILE"
          cardSize="link"
          [cardId]="item.data.entity.id"
          [cardData]="item.data.cardData"
        ></meta-live-card>
      </ng-template>
      <ng-template meta-translate-child [key]="'collection'">
        <span class="log-default">{{ item.data.data.collection?.name || "Hauptverzeichnis" }}</span>
      </ng-template>
    </meta-translate>
    <ng-template #tplLabel><i class="far fa-{{ item.data.entity.icon }}"></i>{{ item.data.entity.title }}</ng-template>
  `,
})
export class MetaTimelineTemplateDocumentUploadedComponent implements OnInit {
  @Input() public item: MetaTimelineTemplateDocumentUploaded;

  public color: string;
  public colorText: string;

  constructor(
    public metaHelperService: MetaHelperService,
    private metaMediaLibraryService: MetaMediaLibraryService,
  ) {}

  ngOnInit() {
    this.color = this.metaHelperService.generateColor(this.item.data.entity.type);
    this.colorText = getComputedStyle(document.documentElement).getPropertyValue("--component-background");
  }

  openFile() {
    this.metaMediaLibraryService.previewFileById(this.item.data.entity.id);
  }
}
