/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule, ViewEncapsulation } from "@angular/core";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";

export class MetaPill {
  label: string;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-pill",
  template: `
    <div class="meta-pill">
      <div class="meta-pill-content">
        {{ ma.label }}
      </div>
    </div>
  `,
  styleUrls: ["./metaPill.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaPillComponent extends MetaComponentBase {
  constructor(_changeDetectorRef: ChangeDetectorRef, _metaHelperService: MetaHelperService) {
    super();
    super.maParams = new MetaPill();
  }

  get ma(): MetaPill {
    return super.ma;
  }
}

@NgModule({
  declarations: [MetaPillComponent],
  imports: [CommonModule],
  exports: [MetaPillComponent],
})
export class MetaPillModule {}
