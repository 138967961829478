/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import {
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ChatService, IChatMessage } from "./chat.service";
import { BehaviorSubject, finalize, Observable, of } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { catchError, take, tap } from "rxjs/operators";
import { FormControl, FormGroup } from "@angular/forms";
import { UserService } from "../../../../../../libs/ui/src/lib/services/user.service";
import { ElevenLabs } from "./elevenLabs";
import { marked } from "marked";
import { htmlToText, HtmlToTextOptions } from "html-to-text";
import { MetaModalService } from "../../../../../../libs/ui/src/lib/services/metaModalService";
import { Title } from "@angular/platform-browser";

@Component({
  template: `
    <ng-template #settingsTemplate>
      <ul class="voice-list">
        <li *ngFor="let item of voices">
          <span>{{ item.label }}</span>
          <button nz-button *ngIf="item.value !== defaultVoiceUri" (click)="defaultVoiceUri = item.value">
            Als Standard
          </button>
          <button nz-button (click)="previewVoice(item.value, item.label, item.preview_url)">
            <i class="fal fa-play-circle"></i>
          </button>
        </li>
      </ul>
    </ng-template>
    <div class="blur-backdrop"></div>
    <div class="chat-title">
      <span (click)="abort()">{{ "assistant.title" | translate }}</span>
      <i (click)="showSettings()" class="fal fa-cog"></i>
      <i (click)="chatService.chatPopover.hide()" class="fal fa-times ml-3"></i>
    </div>

    <div class="chat-container">
      <div *ngIf="conversation.length === 0" class="chat-empty">
        <meta-icon
          [maParams]="{
            icon: '/assets/animations/chatbot/robot.json',
            scale: 100,
            width: 100,
            size: 100,
            delay: 0
          }"
        ></meta-icon>
        <span>{{ "assistant.empty_message" | translate: { name: userFirstname } }}</span>
      </div>
      @for (c of conversation; track c) {
        <ng-container>
          <div *ngIf="c | async as result" class="chat-message chat-message-{{ result.type }}">
            <div class="chat-message-user">
              <div class="chat-avatar chat-avatar-{{ result.type }}">
                <img *ngIf="result.type === 'user'" [src]="userAvatar" alt="{{ result.user }}" />
                <i *ngIf="result.type === 'ai'" class="fas fa-message-bot"></i>
              </div>
              <span>{{ result.type === "user" ? userName : "Assistent" }}</span>
            </div>
            <div
              *ngIf="result.content"
              class="chat-message-content markdown"
              [innerHTML]="result.content | renderMarkDown"
            ></div>
            <div *ngIf="!result.content" class="chat-message-content">
              @if (chatService.toolRunning() && $last) {
                <nz-badge nzStatus="processing" nzText="Daten werden abgerufen…"></nz-badge>
              } @else {
                <i class="fal fa-spinner fa-spin"></i>
              }
            </div>
          </div>
        </ng-container>
      }
    </div>
    <div class="suggestions-container" *ngIf="$suggestions | async as suggestions">
      <div role="button" (click)="form.get('input').setValue(s); send()" *ngFor="let s of suggestions">{{ s }}</div>
    </div>
    <div [formGroup]="form" class="chat-action">
      <input
        placeholder="{{ speechRecognitionActive ? 'Listening…' : ('assistant_input_placeholder' | translate) }}"
        formControlName="input"
        (keyup.enter)="send()"
        class="chat-input"
        [disabled]="loading"
        (focus)="onFocus($event)"
      />

      <nz-button-group *ngIf="loading">
        <button (click)="abort()" nz-button nzDanger nzType="primary">
          <i class="fal fa-spin fa-spinner-third"></i>
        </button>
      </nz-button-group>

      <nz-button-group *ngIf="!loading">
        <button (click)="send()" nz-button nzType="primary">
          <i class="fal fa-send"></i>
        </button>
        <button
          (click)="speechRecognitionActive ? stopSpeechRecognition() : startSpeechRecognition()"
          *ngIf="speechRecognition"
          nz-button
        >
          <i [ngClass]="{ 'fa-fade': speechRecognitionActive }" class="fal fa-microphone"></i>
        </button>
        <button
          nz-popconfirm
          title="Konversation zurücksetzen."
          nzPopconfirmTitle="Mochtest du wirklich diese Konversation zurücksetzen.?"
          (nzOnConfirm)="resetConversation()"
          *ngIf="conversation.length > 0"
          nz-button
        >
          <i class="fal fa-bomb"></i>
        </button>
      </nz-button-group>
    </div>
  `,
  selector: "meta-chat",
  styleUrls: ["./chat.component.less", "./chat.theme.less"],
})
export class ChatComponent implements OnDestroy, OnInit {
  public static conversation: Array<Observable<IChatMessage>> = [];

  public conversation: Array<Observable<IChatMessage>>;
  public conversationId: string;

  public form = new FormGroup({
    input: new FormControl(""),
  });

  public speechRecognition: any;
  public speechRecognitionActive = false;
  public speechRecognitionWasActive = false;

  public userAvatar: string;
  public userFirstname: string;
  public loading = false;
  public abortController = new AbortController();

  public elevenLabs = new ElevenLabs();

  @Input()
  public additionalContext: string;

  @ViewChild("settingsTemplate")
  public settingsTemplate: TemplateRef<any>;
  public $suggestions: Observable<string[]>;
  public userName: string;

  constructor(
    public readonly chatService: ChatService,
    private readonly userService: UserService,
    private readonly zone: NgZone,
    private readonly ref: ChangeDetectorRef,
    private readonly modalService: MetaModalService,
    private readonly titleService: Title,
  ) {
    this.conversation = ChatComponent.conversation;
    sessionStorage["conversationId"] = this.conversationId = sessionStorage["conversationId"] || uuidv4();
    this.userAvatar = `/api/v2/file/avatar/ANW/${this.userService.user.value.id}`;
    this.userFirstname = this.userService.user.value.vorName || this.userService.user.value.nachName;
    this.userName = `${this.userService.user.value.vorName} ${this.userService.user.value.nachName}`;

    const SpeechRecognition = window["SpeechRecognition"] || window["webkitSpeechRecognition"];
    if (SpeechRecognition) {
      this.speechRecognition = new SpeechRecognition();
      this.speechRecognition.continuous = false;
      this.speechRecognition.lang = "de-DE";
      this.speechRecognition.interimResults = true;
      this.speechRecognition.onend = () => {
        this.speechRecognitionActive = false;
      };
      this.speechRecognition.onresult = (event: any) => {
        this.zone.runTask(() => {
          const t = event.results[0][0].transcript;
          this.form.get("input").setValue(t);
          if (event.results[0].isFinal) {
            this.send();
            this.ref.markForCheck();
            this.speechRecognitionWasActive = true;
          }
        });
      };
    }
  }

  private spokenText: string[] = [];
  public get defaultVoiceUri() {
    return window.localStorage["AI_VOICE"];
  }

  public set defaultVoiceUri(voice: string) {
    window.localStorage["AI_VOICE"] = voice;
  }

  public voices: { label: string; value: string; preview_url?: string }[] = [];

  private _say(text: string) {
    if (this.elevenLabs.enabled && this.defaultVoiceUri.includes("eleven-labs")) {
      return this.elevenLabs.queue(text, this.defaultVoiceUri.split(".")[1]);
    }

    const synth = window.speechSynthesis;
    const voices = synth.getVoices();
    const utterThis = new SpeechSynthesisUtterance(text);
    utterThis.voice =
      voices.find((v) => v.voiceURI === this.defaultVoiceUri) || voices.find((v) => v.default) || voices[0];
    utterThis.pitch = 1;
    utterThis.rate = 1;
    return new Promise<void>((resolve) => {
      let resolved = false;
      utterThis.addEventListener("error", () => {
        if (!resolved) {
          resolve();
          resolved = true;
        }
      });
      utterThis.addEventListener("end", () => {
        if (!resolved) {
          resolve();
          resolved = true;
        }
      });
      synth.speak(utterThis);
    });
  }

  public say(text: string, end = false) {
    if (!this.defaultVoiceUri) {
      return;
    }
    const textToSay = text.split(/(?<=.{30,})[.?!:]\s/gim);
    if (!end) {
      textToSay.pop();
    }
    const proms: Promise<void>[] = [];
    if (textToSay.length > 0) {
      for (let t of textToSay) {
        if (!this.spokenText.includes(t)) {
          this.spokenText.push(t);
          proms.push(this._say(t.trim()));
        }
      }
    }
    if (end) {
      Promise.all(proms).then(() => {
        if (this.speechRecognitionWasActive) {
          this.startSpeechRecognition();
        }
        this.ref.markForCheck();
      });
    }
  }

  public send() {
    if (this.loading) {
      return;
    }
    const question = String(this.form.get("input").value).trim();
    if (question.length === 0) {
      return;
    }
    this.$suggestions = of(null);
    const user = this.userService.user.value;
    let c: string;
    this.abortController = new AbortController();
    const htmlToTextOpts: HtmlToTextOptions = {
      formatters: {
        linkFormatter: (elem, walk, builder, formatOptions) => {
          walk(elem.children, builder);
        },
      },
      selectors: [
        // Assign it to `foo` tags.
        {
          selector: "a",
          format: "linkFormatter",
          options: { leadingLineBreaks: 0, trailingLineBreaks: 0 },
        },
      ],
    };
    this.conversation = [
      ...this.conversation,
      new BehaviorSubject({
        user: (user.vorName || "") + " " + (user.nachName || ""),
        content: question,
        type: "user",
      }),
      this.chatService
        .chat(
          question,
          this.conversationId,
          this.abortController.signal,
          `${this.titleService.getTitle()}. ${this.additionalContext || ""}`.trim(),
        )
        .pipe(
          tap((t) => {
            setTimeout(() => this.scrollBottom(), 350);
            this.say(htmlToText(marked(t.content).trim(), htmlToTextOpts).trim());
            c = t.content;
          }),
          catchError((e, o) => {
            const errorMessage: IChatMessage = {
              type: "ai",
              user: "Assistent",
              content: c,
            };
            if (e instanceof Error && e.name === "AbortError") {
              errorMessage.content = `${c || ""} \n\n**Vorgang Abgebrochen.**`.trim();
            } else {
              errorMessage.content = `#Ein Fehler is aufgetreten \n\n\`\`\`\n${JSON.stringify(e, null, 2)}\n\`\`\``;
            }
            return of(errorMessage);
          }),
          finalize(() => {
            this.loading = false;
            this.ref.markForCheck();
            if (c && c.length > 0) {
              this.say(htmlToText(marked(c).trim(), htmlToTextOpts).trim(), true);
            }
            setTimeout(() => {
              this.loadSuggestions();
              this.ref.markForCheck();
            }, 350);
          }),
        ),
    ];
    ChatComponent.conversation = this.conversation;
    this.form.get("input").setValue("");
    this.loading = true;
    setTimeout(() => this.scrollBottom(), 350);
  }

  public resetConversation() {
    if (this.loading) return;
    ChatComponent.conversation = this.conversation = [];
    sessionStorage["conversationId"] = this.conversationId = uuidv4();
    this.form.get("input").setValue("");
    this.loadSuggestions();
  }

  private scrollBottom() {
    const d = document.querySelector(".chat-container");
    if (d) {
      d.scrollTo({
        top: d.scrollHeight,
        behavior: "auto",
      });
    }
  }

  public startSpeechRecognition() {
    this.speechRecognition?.start();
    this.speechRecognitionActive = true;
  }

  public stopSpeechRecognition() {
    this.speechRecognition?.stop();
    this.speechRecognitionActive = false;
    this.speechRecognitionWasActive = false;
  }

  public ngOnDestroy(): void {
    if (this.speechRecognitionActive) {
      this.stopSpeechRecognition();
    }
  }

  public ngOnInit(): void {
    window.speechSynthesis?.getVoices();
    if (this.conversation.length === 0) {
      this.chatService
        .getChat(this.conversationId)
        .then((conversation) => {
          if (conversation) {
            ChatComponent.conversation = this.conversation = conversation.filter((c) => c.content).map((c) => of(c));
          }
          this.ref.markForCheck();
        })
        .catch(console.error);
    }
    this.loadSuggestions();
  }

  public abort() {
    window.speechSynthesis?.cancel();
    if (this.loading) {
      this.abortController?.abort();
    }
  }

  public async showSettings() {
    this.voices = [
      {
        label: "Kein Sprachausgabe",
        value: "",
      },
      ...(window.speechSynthesis
        ? window.speechSynthesis
            .getVoices()
            .filter((v) => v.lang === "de-DE")
            .map((v) => {
              return {
                label: `${v.name} - ${v.lang}`,
                value: v.voiceURI,
              };
            })
        : []),
    ];
    if (this.elevenLabs.enabled) {
      this.voices.push(...(await this.elevenLabs.getVoices()));
    }
    this.modalService.create({
      nzTitle: "Einstellungen",
      nzContent: this.settingsTemplate,
      nzZIndex: 999999,
    });
  }

  public previewVoice(value: string, name: string, preview_url?: string) {
    if (preview_url) {
      new Audio(preview_url).play();
      return;
    }
    const synth = window.speechSynthesis;
    const voice = synth.getVoices().find((v) => v.voiceURI === value);
    const utterThis = new SpeechSynthesisUtterance(`Hallo ${this.userFirstname} ich bin ${name}.`);
    utterThis.voice = voice;
    utterThis.pitch = 1;
    utterThis.rate = 1;
    synth.speak(utterThis);
  }

  private loadSuggestions() {
    this.$suggestions = this.chatService.getSuggestions(this.conversationId).pipe(catchError(() => []));
    this.$suggestions.pipe(take(1)).subscribe(() => this.scrollBottom());
  }

  public onFocus($event: any) {
    setTimeout(() => this.scrollBottom(), 350);
  }
}
