/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { NzCascaderModule } from "ng-zorro-antd/cascader";
import { NzListModule } from "ng-zorro-antd/list";
import { NzSanitizerPipe } from "ng-zorro-antd/pipes";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NgxEditorModule } from "ngx-editor";
import { MetaTranslateChildDirective, MetaTranslateComponent } from "../metaTranslate/metaTranslate.component";
import { MetaEditorComponent } from "./metaEditor.component";

@NgModule({
  exports: [MetaEditorComponent],
  declarations: [MetaEditorComponent],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgxEditorModule,
    NzCascaderModule,
    NzListModule,
    NzTagModule,
    NgxTolgeeModule,
    MetaTranslateComponent,
    MetaTranslateChildDirective,
    NzSanitizerPipe,
  ],
})
export class MetaEditorModule {}
