/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, Input, OnInit } from "@angular/core";
import { IFeedResultItem } from "@meta/api-interfaces";
import { MetaHelperService } from "../../../services/metaHelper.service";

export class MetaTimelineTemplatePipelineObjectAssigned {
  data: IFeedResultItem<{
    entity: string;
    entityName: string;
    title: string;
    user: {
      current: {
        id: string;
        name: string;
        link: string;
      };
      previous: {
        id: string;
        name: string;
        link: string;
      };
    };
  }>;
}

@Component({
  selector: "meta-timeline-template-pipeline-object-assigned",
  template: `{{ item.data.entity.name }}
    <a [href]="item.data.entity.link">
      <meta-tag
        [maParams]="{ label: tplLabelPipelineObject, color: colorPipelineObject, colorText: colorText, maxWidth: 400 }"
      ></meta-tag>
    </a>
    wurde dem Anwender
    <a [href]="item.data.data.user.current.link">
      <meta-tag [maParams]="{ label: tplUser, color: colorUser, colorText: colorText, maxWidth: 250 }"></meta-tag>
    </a>
    zugewiesen.

    <ng-template #tplUser
      ><i class="far fa-{{ item.data.entity.icon }}"></i>{{ item.data.data.user.current.name }}
    </ng-template>
    <ng-template #tplLabelPipelineObject
      ><i class="far fa-{{ item.data.entity.icon }}"></i>{{ item.data.entity.title }}</ng-template
    > `,
})
export class MetaTimelineTemplatePipelineObjectAssignedComponent implements OnInit {
  @Input() public item: MetaTimelineTemplatePipelineObjectAssigned;

  public colorUser: string;
  public colorPipelineObject: string;
  public colorText: string;

  constructor(public metaHelperService: MetaHelperService) {}

  ngOnInit() {
    this.colorUser = this.metaHelperService.generateColor("ANW");
    this.colorPipelineObject = this.metaHelperService.generateColor(this.item.data.entity.type);
    this.colorText = getComputedStyle(document.documentElement).getPropertyValue("--component-background");
  }
}
