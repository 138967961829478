/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Allow, IsArray, IsBoolean, IsDate, IsNumber, IsOptional, IsString } from "class-validator";
import { Type } from "class-transformer";
import { IMetaPipelineViews } from "@meta/forms";

export class PipelineV2InfoDto {
  @IsString()
  name: string;

  @IsString()
  id: string;

  @IsString()
  type: string;

  @IsString()
  @IsOptional()
  typeName?: string;

  @IsBoolean()
  @IsOptional()
  showReasonForDelete?: boolean;

  @IsString()
  @IsOptional()
  createFormId?: string;

  @IsString()
  @IsOptional()
  icon?: string;

  @IsOptional()
  @Allow()
  actions?: any[];

  @IsString()
  @IsOptional()
  detailFormId?: string;

  @IsArray()
  @IsOptional()
  views?: IMetaPipelineViews[];

  @IsString()
  @IsOptional()
  deleteActionLabel?: string;

  @IsString()
  @IsOptional()
  deleteActionState?: string;

  @IsString()
  @IsOptional()
  formName?: string;

  @IsBoolean()
  @IsOptional()
  onStateChangeAction?: boolean;
}

export class PipelineV2ColumnInfo {
  @IsString()
  name: string;

  @IsString()
  id: string;

  @IsString()
  @IsOptional()
  icon?: string;

  @IsNumber()
  order: number;

  @Allow()
  @IsOptional()
  workflows?: any[];
}

export class PipelineV2Layout extends PipelineV2InfoDto {
  @IsArray()
  @Type(() => PipelineV2ColumnInfo)
  columns: PipelineV2ColumnInfo[];
}

export class PipelineV2Reasons {
  @IsString()
  label: string;

  @IsNumber()
  value: number;
}

export class GetPipelineColumnsObjectsOptionColumn {
  @IsString()
  id: string;
  @IsNumber()
  take: number;
  @IsString()
  @IsOptional()
  objectBeforeId?: string;
}

export class GetPipelineColumnsObjectsOptionDto {
  @IsArray()
  @Type(() => GetPipelineColumnsObjectsOptionColumn)
  columns: GetPipelineColumnsObjectsOptionColumn[];
  @IsArray()
  @IsOptional()
  users?: string[] = [];

  @IsOptional()
  @IsNumber()
  state?: number;

  @IsOptional()
  @IsString()
  group?: string;

  @IsOptional()
  @IsString()
  searchString?: string;
}

export class PipelineSetStateEvent {
  @IsString()
  objectId: string;
  @IsNumber()
  state: number;
  @IsNumber()
  @IsOptional()
  reason: number;
}

export class PipelineMoveEvent {
  @IsString()
  objectId: string;
  @IsString()
  targetColumnId: string;
  @IsString()
  @IsOptional()
  beforeObjectId?: string;
  @IsOptional()
  afterObjectId?: string;
  @IsString()
  operationId: string;

  @IsString()
  @IsOptional()
  groupPath?: string;
  @Allow()
  @IsOptional()
  groupValue?: string;
}

export class PipelineMoveResponse {
  @IsBoolean()
  successful: boolean;
}

export class PipelineUser {
  @IsString()
  name: string;
  @IsString()
  id: string;
}

export class CreatePipelineRequestDto {
  @IsString()
  name: string;
  @IsString()
  type: string;
  @IsDate()
  @Type(() => Date)
  startDate?: Date;
  @IsDate()
  @Type(() => Date)
  endDate?: Date;
}

export class CopyPipelineRequestDto {
  @IsString()
  name: string;
  @IsString()
  type: string;
  @IsString()
  idToCopy: string;
  @IsDate()
  @Type(() => Date)
  startDate?: Date;
  @IsDate()
  @Type(() => Date)
  endDate?: Date;
}
