/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, firstValueFrom, Observable, Subject, tap } from "rxjs";
import { map } from "rxjs/operators";
import { MetaGanttGetDataResponse, MetaGanttGetDataResponseItems } from "./metaGantt.component";

class GetDataParams {
  formId: string;
  ganttId: string;
  contextId: string;
  start?: string;
  end?: string;
  searchString?: string;
  filterState: Record<string, any> = {};
}

@Injectable()
export class MetaGanttService {
  private _data: BehaviorSubject<MetaGanttGetDataResponse> = new BehaviorSubject(null);

  constructor(public http: HttpClient) {}

  public handleEventChanged(
    params: Omit<GetDataParams, "start" | "end" | "filterState">,
    item: MetaGanttGetDataResponseItems,
  ) {
    return firstValueFrom(
      this.http.post<{ successful: boolean }>(`forms/gantt/${params.formId}/${params.ganttId}/change`, {
        ctx: params.contextId || 0,
        item,
      }),
    );
  }

  public handleLinkAdd(
    params: Omit<GetDataParams, "start" | "end" | "filterState">,
    source: MetaGanttGetDataResponseItems,
    target: MetaGanttGetDataResponseItems,
    type: number,
  ) {
    return firstValueFrom(
      this.http.post<{ successful: boolean }>(`forms/gantt/${params.formId}/${params.ganttId}/link`, {
        ctx: params.contextId || 0,
        source,
        target,
        type,
      }),
    );
  }

  public handleSortChange(
    params: Omit<GetDataParams, "start" | "end" | "filterState">,
    item: MetaGanttGetDataResponseItems,
    from: number,
    to: number,
  ) {
    return firstValueFrom(
      this.http.post<{ successful: boolean }>(`forms/gantt/${params.formId}/${params.ganttId}/move`, {
        ctx: params.contextId || 0,
        item,
        from,
        to,
      }),
    );
  }

  public handleLinkRemove(
    params: Omit<GetDataParams, "start" | "end" | "filterState">,
    id: string | number,
    source: MetaGanttGetDataResponseItems,
    target: MetaGanttGetDataResponseItems,
  ) {
    return firstValueFrom(
      this.http.post<{ successful: boolean }>(`forms/gantt/${params.formId}/${params.ganttId}/unlink`, {
        ctx: params.contextId || 0,
        id,
        source,
        target,
      }),
    );
  }

  public handleTaskCreate(params: Omit<GetDataParams, "start" | "end" | "filterState">, data: Record<string, any>) {
    return firstValueFrom(
      this.http.post<any>(`forms/gantt/${params.formId}/${params.ganttId}/add-item`, {
        ctx: params.contextId || 0,
        data: data,
      }),
    );
  }

  public handleTaskClick(
    params: Omit<GetDataParams, "start" | "end" | "filterState">,
    item: MetaGanttGetDataResponseItems,
  ) {
    return firstValueFrom(
      this.http.post<any>(`forms/gantt/${params.formId}/${params.ganttId}/click`, {
        ctx: params.contextId || 0,
        item,
      }),
    );
  }

  public handleTaskDelete(
    params: Omit<GetDataParams, "start" | "end" | "filterState">,
    item: MetaGanttGetDataResponseItems,
  ) {
    return firstValueFrom(
      this.http.post<any>(`forms/gantt/${params.formId}/${params.ganttId}/delete`, {
        ctx: params.contextId || 0,
        item,
      }),
    );
  }

  public handleProjectClick(
    params: Omit<GetDataParams, "start" | "end" | "filterState">,
    item: MetaGanttGetDataResponseItems,
  ) {
    return firstValueFrom(
      this.http.post<any>(`forms/gantt/${params.formId}/${params.ganttId}/click`, {
        ctx: params.contextId || 0,
        item,
      }),
    );
  }

  public handleSectionClick(params: Omit<GetDataParams, "start" | "end" | "filterState">, section: string) {
    return firstValueFrom(
      this.http.post<any>(`forms/gantt/${params.formId}/${params.ganttId}/section-click`, {
        section,
      }),
    );
  }

  public getFilters(params: Omit<GetDataParams, "start" | "end" | "contextId" | "filterState">) {
    return this.http.get<any[]>(`forms/gantt/${params.formId}/${params.ganttId}/filters`).pipe(
      map((filters) => {
        return filters.map((filter) => {
          filter["loaded$"] = new Subject();
          return filter;
        });
      }),
    );
  }

  public getAutocomplete(params: Omit<GetDataParams, "start" | "end" | "contextId" | "filterState">) {
    return this.http.get<any[]>(`forms/gantt/${params.formId}/${params.ganttId}/autocomplete`, {
      params: {
        q: params.searchString,
      },
    });
  }

  public getItems(params: GetDataParams): Observable<MetaGanttGetDataResponse> {
    return this.http
      .get<MetaGanttGetDataResponse>(`forms/gantt/${params.formId}/${params.ganttId}/data`, {
        params: {
          ctx: params.contextId || 0,
          start: params.start,
          end: params.end,
          ...(params.searchString ? { q: params.searchString } : {}),
          filter: JSON.stringify(params.filterState),
        },
      })
      .pipe(
        tap((data) => {
          this._data.next(data);
        }),
      );
  }
}
