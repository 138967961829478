/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

/*
Fragen:
- Sollen Hooks den Anwendungsverlauf unterbrechen können?  Ja sie könnnnen den verlauf Abbrechen
- Sollen Hooks Vor oder nach der Validierung ausgeführt werden? Hook nach der Validirung
- Was soll mit dem result der SP passieren? Soll an nächsten hook weitergegeben werden
- Soll der After update hook die Aktualisierten daten bekommen? Ja soll übergeben werden  / soll generell zurckgegeben werden
- Soll der After create hook die erstellte entity zurückgeben? Ja soll übergeben werden / soll generell zurckgegeben werden
- bei table create & update bei procedure execute
- template als util in eigenen ordner? ja machen
 */

import { Exclude, Expose } from "class-transformer";

export type HookType =
  // Entity Change Hooks
  | "beforeCreate"
  | "afterCreate"
  | "beforeUpdate"
  | "afterUpdate"
  | "beforeDelete"
  | "beforeExecute"
  | "afterExecute"
  // Form Hooks
  | "onOpen"
  | "afterOpen"
  | "beforeClose"
  | "afterClose"
  | "fieldBlur"
  | "onPublish"
  | "fieldFocus"
  | "beforePublish"
  | "afterPublish";

export abstract class HookAction {}

export class EmailAction extends HookAction {
  @Expose() public to: string;
  @Expose() public cc?: string;
  @Expose() public subject: string;
  @Expose() public body: string;
  @Expose() public isHtml?: boolean;

  public static create(opts: Pick<EmailAction, "to" | "cc" | "body" | "subject" | "isHtml">) {
    const action = new EmailAction();
    action.to = opts.to;
    action.cc = opts.cc;
    action.subject = opts.subject;
    action.body = opts.body;
    return action;
  }
}

export class EvalTemplateAction extends HookAction {
  constructor(public readonly template: string) {
    super();
  }

  public static create(template: string) {
    return new EvalTemplateAction(template);
  }
}

export class EvalScriptAction extends HookAction {
  @Exclude()
  public readonly func: CallableFunction;

  constructor(func: CallableFunction) {
    super();
    this.func = func;
  }

  public static create(func: CallableFunction) {
    return new EvalScriptAction(func);
  }
}

export class SocketAlertAction extends HookAction {
  @Expose() public msg: string;
  @Expose() public broadcast = false;

  public static create(options: Partial<SocketAlertAction>) {
    const result = new SocketAlertAction();
    Object.assign(result, options);
    return result;
  }
}
