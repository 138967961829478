/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { PortalModule, TemplatePortal } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  input,
  Input,
  NgModule,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { MetaButtonType } from "@meta/enums";
import * as moment from "moment";
import { MetaFormService } from "../../base/metaForm/metaForm.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaAvatarModule } from "../metaAvatar/metaAvatar.component";
import { MetaButtonModule } from "../metaButton/metaButton.component";
import { MetaFormHeaderService } from "../metaFormHeader/metaFormHeader.service";
import { MetaIconModule } from "../metaIcon/metaIcon.component";
import { MetaSectionModule } from "../metaSection/metaSection.component";

export class IMetaToolbar {
  label?: string;
  sublabel?: string | TemplateRef<any>;
  icon?: string;
  tabIndex?: number;
  currentTab?: number;
  showBackButton? = true;
  searchFormId?: string;
  toolbarTargetId?: string;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-toolbar",
  template: `
    <ng-template #actionsPortal="cdkPortal" cdkPortal>
      <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
    </ng-template>

    <ng-template #labelPortal="cdkPortal" cdkPortal>
      @if (maParams.showBackButton && !loadedAsModal()) {
        <meta-button
          [maParams]="{
            icon: 'arrow-left',
            type: MetaButtonType.link,
            onClick: back
          }"
        ></meta-button>
      }

      <meta-button
        *ngIf="maParams.searchFormId"
        class="nav-search"
        [maParams]="{
          icon: 'search',
          type: MetaButtonType.link,
          onClick: goToSearch.bind(this)
        }"
      ></meta-button>

      @if (maParams.icon) {
        <meta-icon
          [maParams]="{ icon: maParams.icon, size: 36, scale: 50, style: 'avatar' }"
          class="icon-bg"
        ></meta-icon>
        <meta-icon
          *ngIf="maParams.icon"
          [maParams]="{ icon: maParams.icon, size: 36, scale: 50, style: 'avatar' }"
        ></meta-icon>
      }

      <span [innerHTML]="maParams.label"></span>
    </ng-template>

    <ng-template #sublabelPortal="cdkPortal" cdkPortal>
      <ng-container *ngTemplateOutlet="hasSubLabelAsTemplate ? $any(maParams.sublabel) : plainText"></ng-container>

      <ng-template #plainText><span [innerHtml]="maParams.sublabel"></span></ng-template>
    </ng-template>

    <ng-template #actionsTemplate>
      <ng-content></ng-content>
    </ng-template>
  `,
  styleUrls: ["./metaToolbar.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaToolbarComponent implements AfterViewInit {
  @ViewChild("actionsPortal") actionsPortal: TemplatePortal;
  @ViewChild("labelPortal") labelPortal: TemplatePortal;
  @ViewChild("sublabelPortal") sublabelPortal: TemplatePortal;
  public moment: any = moment;
  public MetaButtonType = MetaButtonType;
  public loadedAsModal = input(false);

  constructor(
    private _router: Router,
    public metaFormHeaderService: MetaFormHeaderService,
    public metaFormService: MetaFormService,
    private readonly destroyRef: DestroyRef,
  ) {}

  private _maParams: IMetaToolbar = new IMetaToolbar();

  @Input()
  public get maParams(): IMetaToolbar {
    return this._maParams;
  }

  public set maParams(value: IMetaToolbar) {
    this._maParams = { ...this._maParams, ...value };
  }

  get hasSubLabelAsTemplate() {
    return this.maParams.sublabel instanceof TemplateRef;
  }

  private get targetId() {
    return (
      this.maParams?.toolbarTargetId ||
      (this.metaFormService.formState?.modalRef ? this.metaFormService.formState.formId : "default")
    );
  }

  ngAfterViewInit(): void {
    this._setTemplates();
  }

  public back() {
    history.back();
  }

  public goToSearch() {
    this._router.navigate([this.maParams.searchFormId]);
  }

  private _setTemplates() {
    if (this.actionsPortal) {
      this.metaFormHeaderService?.attachActionsPortal(this.actionsPortal, this.destroyRef, this.targetId);
    }
    if (this.labelPortal) {
      this.metaFormHeaderService?.attachLabelPortal(this.labelPortal, this.destroyRef, this.targetId);
    }
    if (this.sublabelPortal) {
      this.metaFormHeaderService?.attachSublabelPortal(this.sublabelPortal, this.destroyRef, this.targetId);
    }
  }
}

@NgModule({
  declarations: [MetaToolbarComponent],
  imports: [CommonModule, PortalModule, MetaIconModule, MetaAvatarModule, MetaButtonModule, MetaSectionModule],
  exports: [MetaToolbarComponent],
})
export class MetaToolbarModule {}
