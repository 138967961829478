/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { NzModalRef } from "ng-zorro-antd/modal";
import { firstValueFrom } from "rxjs";
import { IAddressObject } from "../metaAddress.component";

@Component({
  templateUrl: "./metaAddressEditModal.component.html",
  styleUrls: ["./metaAddressEditModal.component.less"],
})
export class MetaAddressEditModalComponent implements OnInit {
  @Input() id: string;
  public address: IAddressObject;

  constructor(
    private readonly modalRef: NzModalRef,
    protected readonly http: HttpClient,
  ) {
    this.modalRef.updateConfig({
      nzTitle: "Adresse Bearbeiten",
      nzOnOk: () => firstValueFrom(this.saveAddress()).then((e) => e.id),
      nzOkText: "Speichern",
    });
  }

  public loadAddress(addressNr: number | string) {
    return this.http.get<IAddressObject>("forms/addressData", {
      params: {
        addressNr: String(addressNr),
      },
    });
  }

  public saveAddress() {
    return this.http.patch<IAddressObject>(`forms/addressData`, {
      ...this.address,
    });
  }

  public ngOnInit(): void {
    if (this.id) {
      this.loadAddress(this.id).subscribe((address) => {
        this.address = address;
      });
    }
  }
}
