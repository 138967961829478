/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaBaseValidator } from "./baseValidator";
import * as validator from "class-validator";
import { MetaFormComponentInfo } from "../components";

export class MinValidatorOptions {
  min: number;
}

@MetaFormComponentInfo({
  selector: "ma-min-validator",
  type: "min-validator",
  name: "Min Validator",
})
export class MinValidator extends MetaBaseValidator {
  public min: number;

  constructor(public options: MinValidatorOptions) {
    super();
    this.min = options?.min;
  }

  public static createElement(attributes: MinValidatorOptions) {
    return new MinValidator(attributes);
  }

  async validate(value): Promise<boolean> {
    return validator.min(Number(value), this.options.min);
  }

  toFormly(formlyFieldConfig: Record<string, any>) {
    formlyFieldConfig.props["min"] = this.options.min;
    return super.toFormly(formlyFieldConfig, true);
  }
}
