/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponentInfo, MetaFormComponentProperty, TabsComponent } from "../components";
import { EditableForm } from "./editableForm";
import { IFormUserConfiguration } from "../formUserConfiguration";

@MetaFormComponentInfo({
  selector: "ma-page",
  type: "page",
  icon: "file-edit",
  name: "Seite",
})
export class Page extends EditableForm {
  static selector = "ma-page";

  @MetaFormComponentProperty({
    name: "Search Form",
    type: "subform",
  })
  public searchable?: string;

  constructor(props: Partial<Page>) {
    super(props);
  }

  public static createElement(attributes: Partial<Page>, children: any[]): any {
    if (children[0] instanceof TabsComponent && children[0].routerTabs === undefined) {
      children[0].routerTabs = true;
    }
    return new Page({
      ...attributes,
      children,
    });
  }

  toFormly(config: IFormUserConfiguration): any {
    return {
      ...super.toFormly(config),
      type: "page",
      searchable: this.searchable ? this.searchable : false,
      versionable: this.versionable,
      calculateFillingLevel: this.calculateFillingLevel,
    };
  }
}
