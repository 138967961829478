/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core";
import type { ILiveCardData } from "@meta/api-interfaces";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NzButtonModule } from "ng-zorro-antd/button";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
import { ArgonRouterLink } from "../../pipes/metaRouterLink";
import { MetaTagModule } from "../metaTag/metaTag.component";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { NgxTolgeeModule } from "@tolgee/ngx";

@Component({
  standalone: true,
  template: `
    @if (cardSize === "link") {
      @if (cardData) {
        <ng-template #tmpl>
          <b>{{ cardTypeTransKey | translate }}</b> {{ cardData.title }}</ng-template
        >
        <a
          [routerLink]="cardData.link | argonRouterLink: 'url'"
          [queryParams]="cardData.link | argonRouterLink: 'params'"
          target="{{ openInNewTab ? '_blank' : '_self' }}"
          [title]="cardData.title"
        >
          <meta-tag
            [maParams]="{
              label: tmpl,
              icon: cardData.icon,
              color: cardData.color || 'success',
              type: 'primary',
              maxWidth: 250
            }"
          ></meta-tag>
        </a>
      } @else if (error) {
        <ng-template #errTmpl
          ><b>{{ cardTypeTransKey | translate }}</b> Nicht gefunden
        </ng-template>
        <meta-tag
          [maParams]="{ label: fallbackText || errTmpl, color: 'error', type: 'danger', maxWidth: 250 }"
        ></meta-tag>
      } @else {
        <meta-tag [maParams]="{ label: fallbackText || '...', maxWidth: 250 }"></meta-tag>
      }
    } @else {
      <div class="timeline-live-card" [ngClass]="cardSize">
        <ng-container *ngIf="cardData && !error">
          <div *ngIf="cardData.image && !imageError" class="timeline-live-card-img">
            <img
              (error)="onImageError($event)"
              loading="lazy"
              src="/api/v2/file/{{ cardData.image }}/download"
              alt=""
            />
          </div>
          <div *ngIf="!cardData.image || imageError" class="timeline-live-icon">
            <i class="fal fa-{{ cardData.icon }}"></i>
          </div>
          <div class="timeline-live-inner">
            <span *ngIf="cardData.title"
              ><a
                [routerLink]="cardData.link | argonRouterLink: 'url'"
                [queryParams]="cardData.link | argonRouterLink: 'params'"
                target="{{ openInNewTab ? '_blank' : '_self' }}"
              >
                @if (cardStandalone) {
                  <b>{{ cardTypeTransKey | translate }}: </b>
                }
                {{ cardData.title }}</a
              ></span
            >
            <p *ngIf="cardData.content && cardSize === 'default'" [innerHTML]="cardData.content"></p>
            <div *ngIf="cardData.progress">
              <progress [value]="cardData.progress.percent"></progress>
              {{ cardData.progress.label || "" }}
            </div>
            <nz-button-group *ngIf="cardData.actions?.length > 0">
              <button
                [routerLink]="a.link | argonRouterLink: 'url'"
                [queryParams]="a.link | argonRouterLink: 'params'"
                nzSize="small"
                nzType="dashed"
                *ngFor="let a of cardData.actions"
                nz-button
              >
                {{ a.title }}
              </button>
            </nz-button-group>
          </div>
        </ng-container>
        <ng-container *ngIf="error">
          <div class="timeline-live-icon">
            <i class="fal fa-exclamation-triangle"></i>
          </div>
          <div class="timeline-live-inner">
            Fehler beim Laden der Daten.
            <br />
            [{{ cardType }}] = {{ cardId }}
          </div>
        </ng-container>
      </div>
    }
  `,
  selector: "meta-live-card",
  imports: [
    CommonModule,
    RouterModule,
    NzButtonModule,
    ArgonRouterLink,
    MetaTagModule,
    NzTooltipDirective,
    NgxTolgeeModule,
  ],
  styleUrls: ["./metaLiveCard.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaLiveCardComponent implements OnDestroy, OnChanges, AfterViewInit {
  private readonly observer: IntersectionObserver;
  private intervalRef: any;
  private readonly refreshInterval = 10_000;
  public isVisible = false;
  public loaded = false;
  public error = false;
  constructor(
    private readonly http: HttpClient,
    private readonly ref: ChangeDetectorRef,
    private readonly ele: ElementRef<HTMLElement>,
  ) {
    this.observer = new IntersectionObserver((e) => {
      if (e[0].isIntersecting) {
        this.onVisible();
      } else {
        this.onHide();
      }
    }, {});
  }
  public imageError = false;

  @Input()
  cardData: ILiveCardData;

  @Output()
  cardDataChange = new EventEmitter<ILiveCardData>();

  @Input()
  cardType: string;

  @Input()
  cardId: string;

  @Input()
  cardSize: "default" | "small" | "link" | "inline" = "default";

  @Input()
  cardStandalone = true;

  /**
   * Refreshes the Card Content Automatically
   */
  @Input()
  cardAutoRefresh = false;

  /**
   * Only Load data if card becomes visible.
   */
  @Input()
  cardLazyLoad = false;

  cardTypeTransKey: string = "";

  @Input()
  fallbackText: string;

  @Input()
  openInNewTab = false;

  public onImageError(e: ErrorEvent) {
    this.imageError = true;
  }

  private onVisible() {
    this.isVisible = true;
    this.loadData();
    if (!this.intervalRef && this.cardAutoRefresh) {
      this.intervalRef = setInterval(() => this.refresh(), this.refreshInterval);
    }
  }

  private onHide() {
    this.isVisible = false;
    if (this.intervalRef) {
      clearInterval(this.intervalRef);
      this.intervalRef = null;
    }
  }

  public refresh() {
    if (!this.isVisible || !this.loaded || this.cardSize === "link") {
      return;
    }
    firstValueFrom(this.http.get(`entity/cardData/${this.cardData.type}/${this.cardData.id}`)).then(
      (e) => {
        this.imageError = false;
        this.cardData = e;
        this.cardDataChange.next(this.cardData);
        this.cardTypeTransKey = `entities.${this.cardData.type?.toLowerCase()}.name_singular`;
        this.error = false;
        this.ref.markForCheck();
      },
      (e) => {
        console.error(e);
        this.error = true;
        this.ref.markForCheck();
      },
    );
  }

  public ngOnDestroy(): void {
    if (this.intervalRef) {
      clearInterval(this.intervalRef);
      this.intervalRef = null;
    }
    this.observer.unobserve(this.ele.nativeElement);
    this.observer.disconnect();
  }

  private loadData() {
    if (this.cardType && this.cardId && !this.loaded && (this.isVisible || !this.cardLazyLoad)) {
      this.cardTypeTransKey = `entities.${this.cardType?.toLowerCase()}.name_singular`;
      this.loaded = true;
      firstValueFrom(this.http.get(`entity/cardData/${this.cardType}/${this.cardId}`)).then(
        (e) => {
          this.imageError = false;
          this.cardData = e;
          this.cardDataChange.next(this.cardData);
          this.cardTypeTransKey = `entities.${this.cardData.type?.toLowerCase()}.name_singular`;
          this.error = false;
          this.ref.markForCheck();
        },
        (e) => {
          this.error = true;
          this.ref.markForCheck();
        },
      );
    }
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["cardType"] || changes["cardId"]) {
      this.loadData();
    }

    if (changes["cardData"]) {
      this.cardTypeTransKey = `entities.${this.cardData?.type?.toLowerCase()}.name_singular`;
      this.loaded = !!changes["cardData"].currentValue;
    }
  }

  public ngAfterViewInit(): void {
    requestAnimationFrame(() => this.observer.observe(this.ele.nativeElement));
  }
}
