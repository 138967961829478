/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { MetaMediaLibraryService } from "./metaMediaLibrary.service";
import { IMetaMediaLibraryFile, retentionPolicyText } from "./interfaces";
import { MetaMediaLibraryFilePreviewComponent } from "./metaMediaLibraryFilePreview.component";

@Component({
  selector: "meta-media-library-file-info",
  styleUrls: ["./metaMediaLibraryFileInfo.component.less"],
  template: `
    <div class="media-library-info" [ngClass]="{ empty: !file }">
      <ng-container *ngIf="file">
        <div class="media-library-info-body">
          @if (!hidePreview && file.type !== "collection") {
            <nz-collapse [nzBordered]="false">
              <nz-collapse-panel #p nzActive nzHeader="Vorschau">
                <meta-media-library-file-preview
                  #preview
                  *ngIf="p.nzActive"
                  [size]="'small'"
                  [file]="file"
                ></meta-media-library-file-preview>
              </nz-collapse-panel>
            </nz-collapse>
          }
          @if (file.description) {
            <p class="description">{{ file.description || "-" }}</p>
          }
          <nz-descriptions [nzColumn]="1" nzBordered nzSize="small">
            <nz-descriptions-item nzTitle="Name">{{ file.name }}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Größe">{{ file.size | nzBytes }}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Type">{{ file.fileDescription }}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Erstellt">{{ file.date | date: "medium" }}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Eigentümer">
              <meta-live-card cardSize="link" cardType="ANW" [cardId]="file.ownerId" [fallbackText]="file.owner" />
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Öffentlich">{{ file.external ? "Ja" : "Nein" }}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Klassifizierung">{{
              fileInfoData?.classificationName || file.classificationName || "-"
            }}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Aufbewahrungsrichtlinie">{{
              retentionPolicyText[fileInfoData?.retentionPolicy || file.retentionPolicy]
            }}</nz-descriptions-item>

            <nz-descriptions-item nzTitle="Gültigkeit"
              >{{ file.validFrom | date: "medium" }} - {{ file.validTo | date: "medium" }}
            </nz-descriptions-item>
            @if (file.type !== "collection") {
              <nz-descriptions-item nzTitle="Tags">
                @for (t of fileInfoData?.tags; track $index) {
                  <nz-tag>{{ t.name }}</nz-tag>
                } @empty {
                  -
                }
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Verknüpfungen">
                @for (l of $any(fileInfoData?.links); track $index) {
                  <meta-live-card
                    cardSize="link"
                    [cardType]="l.type"
                    [cardId]="l.id"
                    [cardLazyLoad]="true"
                  ></meta-live-card>
                } @empty {
                  -
                }
              </nz-descriptions-item>
            }
          </nz-descriptions>
        </div>
        <div class="media-library-info-actions">
          <ng-container *ngIf="actionsTemplate">
            <ng-container *ngTemplateOutlet="actionsTemplate; context: { $implicit: file }"></ng-container>
          </ng-container>
          <ng-container *ngIf="file.type !== 'collection'">
            <button *ngIf="!hidePreviewButton" (click)="openPreview()" title="Vorschau" nz-button>
              <i class="fal fa-eye"></i>
            </button>
            <button (click)="download()" title="Herunterladen" nz-button>
              <i class="fal fa-file-arrow-down"></i>
            </button>
            <button (click)="print()" title="Drucken" nz-button>
              <i class="fal fa-print"></i>
            </button>
            <button (click)="share()" title="Teilen" nz-button>
              <i class="fal fa-share"></i>
            </button>

            <button (click)="edit()" title="Bearbeiten" nz-button>
              <i class="fal fa-pen"></i>
            </button>
            <button
              nzType="primary"
              [disabled]="fileInfoData?.retentionPolicy > 0"
              (click)="delete()"
              title="Teilen"
              nzDanger
              nz-button
            >
              <i class="fal fa-trash"></i>
            </button>
          </ng-container>
          <ng-container *ngIf="file.type === 'collection'">
            <button nzType="primary" (click)="editCollection()" title="Bearbeiten" nz-button>
              <i class="fal fa-pen"></i>
            </button>
            <button (click)="deleteCollection()" title="Löschen" nzType="primary" nzDanger nz-button>
              <i class="fal fa-trash"></i>
            </button>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!file">
        <div class="media-library-info-empty">
          <i class="fal fa-info-circle"></i>
          <span>Kein Dokument Ausgewählt</span>
        </div>
      </ng-container>
    </div>
  `,
})
export class MetaMediaLibraryFileInfoComponent {
  @Input()
  public file: IMetaMediaLibraryFile;
  @Input()
  public hidePreviewButton = false;
  @Input()
  public hidePreview = false;
  @Input()
  public isFormly = false;
  @Input()
  public actionsTemplate: TemplateRef<any>;
  @Output()
  public fileDeleted = new EventEmitter();
  @Output()
  public fileChanged = new EventEmitter();
  public get fileInfoData() {
    return (this.file as any) || {};
  }

  @ViewChild("preview", { static: false })
  public previewEle: MetaMediaLibraryFilePreviewComponent;

  public readonly retentionPolicyText = retentionPolicyText;

  constructor(
    public readonly changeDetectorRef: ChangeDetectorRef,
    public readonly metaMediaLibraryService: MetaMediaLibraryService,
  ) {}

  openPreview() {
    this.metaMediaLibraryService.previewFile(this.file);
  }

  download() {
    window.open(`/api/v2/file/${this.file.id}/download`, "_blank");
  }

  share() {
    this.metaMediaLibraryService.shareFile([this.file]).then(() => {
      this.refresh();
    });
  }

  delete() {
    if (!this.isFormly) {
      this.metaMediaLibraryService.deleteFile(this.file).then((result) => {
        if (result.success) {
          this.fileDeleted.emit(this.file);
        }
      });
    }
  }

  refresh() {
    if (this.file.id && this.file.type !== "collection") {
      this.metaMediaLibraryService.getFile(this.file.id).then((file) => {
        this.file = file;
        this.fileChanged.emit(this.file);
        this.changeDetectorRef.markForCheck();
      });
    } else {
      this.fileChanged.emit(this.file);
    }
  }

  edit() {
    this.metaMediaLibraryService.editFile(this.file).then(() => {
      this.refresh();
    });
  }

  print() {
    this.metaMediaLibraryService.printFile(this.file, {});
  }

  deleteCollection() {
    this.metaMediaLibraryService.deleteCollection(this.file).then(() => {
      this.refresh();
    });
  }

  editCollection() {
    this.metaMediaLibraryService.editCollection(this.file).then(() => {
      this.refresh();
    });
  }

  stop() {
    this.previewEle?.stop();
  }
}
