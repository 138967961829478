/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { IWorkflow, IWorkflowDescription, IWorkflowNode, IWorkflowNodeResultDescription } from "@meta/api-interfaces";
import * as _ from "lodash";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
import { Signal, signal, WritableSignal } from "@angular/core";

export class VariablesService {
  public readonly schemaSignal: Signal<any>;
  public readonly loadingSignal = signal(false);
  private includeSelf = false;
  private _schemaSignal: WritableSignal<any>;

  constructor(
    private readonly node: IWorkflowNode,
    private readonly workflow: IWorkflow,
    public description: IWorkflowDescription,
    private readonly http: HttpClient,
    public update: () => void,
    public readonly editable: boolean,
  ) {
    this._schemaSignal = signal(this.getSchema());
    this.schemaSignal = this._schemaSignal.asReadonly();
  }

  public setDescription(description: IWorkflowDescription) {
    this.description = description;
    this._schemaSignal.set(this.getSchema());
    this.loadingSignal.set(false);
  }

  public setIncludeSelf(includeSelf: boolean) {
    this.includeSelf = includeSelf;
    this._schemaSignal.set(this.getSchema());
  }

  public getCurrentNodeData() {
    const previousNodes = this.getPreviousNodes(this.workflow, this.node);
    if (this.includeSelf) {
      previousNodes.push({
        ...this.node,
        port: null,
      });
    }
    const data: { data: IWorkflowNodeResultDescription; node: IWorkflowNode; port: string }[] = [];
    for (const node of previousNodes) {
      Object.entries(this.description.nodes[node.id].output)
        .filter(([name]) => name === node.port || node.port === null)
        .forEach(([name, nodeDesc]) => {
          data.push({
            data: nodeDesc,
            node,
            port: nodeDesc.name,
          });
        });
    }
    return { data, variables: this.description.variables || [] };
  }

  public async getProcedureMetadata(name: string) {
    return firstValueFrom(this.http.get("workflow/proc-info", { params: { name } }));
  }

  public async getNodeEditorData(nodeId: string, workflow: IWorkflow) {
    return firstValueFrom(this.http.post(`workflow/node-editor-data/${nodeId}`, workflow));
  }

  public async getProcedures() {
    return firstValueFrom(this.http.get<any[]>("workflow/list-proc"));
  }

  public getFiles() {
    const schema = this.getSchema();
    return _.flattenDeep<any>(this.flattenSchema(schema))
      .filter((e) => {
        return e.type === "binary";
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  public getUserSelectData(): Promise<{ label: string; value: any }[]> {
    return firstValueFrom(this.http.post<any>("workflow/user", {}));
  }

  public flattenSchema(schema: any, path: string[] = [], schemas = []) {
    if (schema.properties) {
      return Object.entries(schema.properties).map(([k, s]) => {
        return this.flattenSchema(s, [...path, k], [...schemas, schema]);
      });
    } else {
      return {
        label: [...schemas, schema]
          .slice(1)
          .map((s) => s.title)
          .join(" > "),
        value: "context" + path.map((p) => `[${JSON.stringify(p)}]`).join(""),
        type: schema.type,
      };
    }
  }

  public getSelectData(
    wrap = false,
    filter?: RegExp,
    includeMultiple = false,
  ): { label: string; value: any; groupLabel: string }[] {
    const schema = this.getSchema();
    const res = _.flattenDeep<any>(this.flattenSchema(schema))
      .sort((a, b) => a.label.localeCompare(b.label))
      .filter((e) => (filter ? filter.test(e.label) : e));
    return wrap
      ? res.map((e) => {
          return {
            ...e,
            value: `{{${e.value}}}`,
          };
        })
      : res;
  }

  public getSchema() {
    const schema = {
      $schema: "https://json-schema.org/draft/2020-12/schema",
      $id: `context`,
      title: "Context",
      description: "Root Context",
      type: "object",
      properties: {},
    };

    const currentData = this.getCurrentNodeData();
    for (const { node, data, port } of currentData.data) {
      if (data.schema && Object.keys(data.schema).length > 0) {
        if (!schema.properties[node.id]) {
          schema.properties[node.id] = {
            $schema: "https://json-schema.org/draft/2020-12/schema",
            $id: `context/${node.id}`,
            title: node.name,
            description: node.id,
            type: "object",
            properties: {},
          };
        }
        _.set(schema.properties, [node.id, "properties", port], data.schema);
      }
    }

    Object.assign(schema.properties, this.description.globalSchema.properties);
    return schema;
  }

  private getPreviousNodes(
    workflow: IWorkflow,
    node: IWorkflowNode,
    checkedNodes: IWorkflowNode[] = [],
  ): (IWorkflowNode & { port: string })[] {
    if (checkedNodes.includes(node)) {
      return [];
    }
    checkedNodes.push(node);
    const inputConnections = workflow.connections.filter((e) => e.to === node.id);
    const r = inputConnections.map((con) => {
      const prevNode = workflow.nodes.find((e) => e.id === con.from);

      return [
        ...this.getPreviousNodes(workflow, prevNode, checkedNodes),
        {
          ...prevNode,
          port: con.fromPort,
        },
      ];
    });
    return _.uniqBy(_.flattenDeep(r), (e) => {
      return JSON.stringify(e);
    });
  }
}
