/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

export interface IAppConfig {
  isSas: boolean;
  isSelfHosted: boolean;
  isDemo: boolean;
  production: boolean;
  hostname: string;
  company: {
    name: string;
  };
  versionInfo: {
    version: string;
    buildNum: string;
    buildAt: string;
    gitRef: string;
  };
  features: {
    shadowMode: {
      enabled: boolean;
    };
    whiteLabel: {
      enabled: boolean;
    };
    assistent: {
      enabled: boolean;
    };
    email_client: {
      enabled: boolean;
    };
    sentry: {
      enabled: boolean;
    } & Record<string, any>;
  };
  login: {
    message?: string;
    allowSelfService: boolean;
    methods: {
      saml?: { enabled: boolean; type: string };
    };
  };
  styles: Record<string, string>;
}
