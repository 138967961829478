import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  NgModule,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { MetaIconStyle, MetaTagMode, MetaTagType } from "@meta/enums";
import type { NzPresetColor, NzStatusColor } from "ng-zorro-antd/core/color";
import { NzTagModule } from "ng-zorro-antd/tag";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";

export class IMetaTag {
  id?: string;
  size?: "large" | "default" | "small" = "default";
  label: string | TemplateRef<any>;
  icon?: string;
  iconStyle?: MetaIconStyle = MetaIconStyle.Solid;
  mode?: MetaTagMode | string = MetaTagMode.default;
  type?: MetaTagType | string = MetaTagType.default;
  color?: string | NzStatusColor | NzPresetColor;

  colorText?: string;
  maxWidth?: number;
  onClose?: (id: string) => void;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-tag",
  template: `
    <nz-tag
      [nzMode]="$any(maParams.mode)"
      class="{{ maParams.type }} size-{{ maParams.size }} {{ maParams.maxWidth ? 'truncate' : '' }}"
      [style.color]="maParams.colorText ? maParams.colorText : null"
      (nzOnClose)="onClose()"
      [nzColor]="maParams.color"
    >
      <i *ngIf="maParams.icon" class="{{ maParams.iconStyle }} fa-{{ maParams.icon }}" [style.padding-right.px]="5"></i>
      <span *ngIf="!hasTemplate" [innerHTML]="maParams.label"></span>
      <ng-container *ngIf="hasTemplate">
        <span>
          <ng-container *ngTemplateOutlet="$any(maParams.label)"></ng-container>
        </span>
      </ng-container>
    </nz-tag>
  `,
  styleUrls: ["./metaTag.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaTagComponent implements OnInit {
  public hasTemplate = false;
  @HostBinding("style.max-width") public maxWidth: string;

  private _maParams: IMetaTag = new IMetaTag();

  @Input()
  public get maParams(): IMetaTag {
    return this._maParams;
  }

  public set maParams(value: IMetaTag) {
    this._maParams = { ...this._maParams, ...value };
  }

  ngOnInit() {
    if (this.maParams.label instanceof TemplateRef) {
      this.hasTemplate = true;
    }
    this.maxWidth = `${this.maParams.maxWidth}px`;
  }

  public onClose() {
    if (this.maParams.id === undefined) {
      throw new Error("To close a tag you need to provide an id");
    }

    this.maParams.onClose(this.maParams.id);
  }
}

@NgModule({
  declarations: [MetaTagComponent],
  imports: [CommonModule, NzTagModule],
  exports: [MetaTagComponent],
})
export class MetaTagModule {}
