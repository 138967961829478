/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { MetaFormBaseComponent, MetaFormComponentInfo, MetaFormComponentProperty } from "./index";
import { EntityType } from "@meta/api-interfaces";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";
import { Expose } from "class-transformer";

@MetaFormComponentInfo({
  selector: "ma-document",
  type: "document",
  name: "Dokumente",
})
export class DocumentManager extends MetaFormBaseComponent {
  @MetaFormComponentProperty({
    name: "Entity Type",
    type: "select",
    tab: "data",
    values: "form-builder/entities",
  })
  public entityType: EntityType;

  public static createElement(attributes: any) {
    const res = new DocumentManager();
    Object.assign(res, attributes);
    return res;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        entityType: this.entityType,
      },
    });
  }
}
