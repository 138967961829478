/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, OnInit, signal } from "@angular/core";
import { NzModalRef } from "ng-zorro-antd/modal";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { firstValueFrom } from "rxjs";
import { NzSelectOptionInterface } from "ng-zorro-antd/select/select.types";
import { NzMessageService } from "ng-zorro-antd/message";

@Component({
  templateUrl: "./meta-print-modal.component.html",
  styleUrls: ["./meta-print-modal.component.less"],
})
export class MetaPrintModalComponent implements OnInit {
  public previewUrl = signal<SafeResourceUrl>(null);
  public printers: NzSelectOptionInterface[] = [];
  public printOptions = new UntypedFormGroup({
    copies: new UntypedFormControl(1, Validators.required),
    printer: new UntypedFormControl(null, Validators.required),
    bw: new UntypedFormControl(false),
    type: new UntypedFormControl(),
    locale: new UntypedFormControl(null),
    id: new UntypedFormControl(),
    formId: new UntypedFormControl(null),
    landscape: new UntypedFormControl(false),
    pageSize: new UntypedFormControl("A4"),
    duplex: new UntypedFormControl(false),
    bin: new UntypedFormControl(0),
  });
  public paperSizes = [
    {
      label: "4A0",
      value: "4A0",
    },
    {
      label: "2A0",
      value: "2A0",
    },
    {
      label: "A0",
      value: "A0",
    },
    {
      label: "A1",
      value: "A1",
    },
    {
      label: "A2",
      value: "A2",
    },
    {
      label: "A3",
      value: "A3",
    },
    {
      label: "A4",
      value: "A4",
    },
    {
      label: "A5",
      value: "A5",
    },
    {
      label: "A6",
      value: "A6",
    },
    {
      label: "A7",
      value: "A7",
    },
    {
      label: "A8",
      value: "A8",
    },
    {
      label: "A9",
      value: "A9",
    },
    {
      label: "A10",
      value: "A10",
    },
    {
      label: "B0",
      value: "B0",
    },
    {
      label: "B1",
      value: "B1",
    },
    {
      label: "B2",
      value: "B2",
    },
    {
      label: "B3",
      value: "B3",
    },
    {
      label: "B4",
      value: "B4",
    },
    {
      label: "B5",
      value: "B5",
    },
    {
      label: "B6",
      value: "B6",
    },
    {
      label: "B7",
      value: "B7",
    },
    {
      label: "B8",
      value: "B8",
    },
    {
      label: "B9",
      value: "B9",
    },
    {
      label: "B10",
      value: "B10",
    },
    {
      label: "C0",
      value: "C0",
    },
    {
      label: "C1",
      value: "C1",
    },
    {
      label: "C2",
      value: "C2",
    },
    {
      label: "C3",
      value: "C3",
    },
    {
      label: "C4",
      value: "C4",
    },
    {
      label: "C5",
      value: "C5",
    },
    {
      label: "C6",
      value: "C6",
    },
    {
      label: "C7",
      value: "C7",
    },
    {
      label: "C8",
      value: "C8",
    },
    {
      label: "C9",
      value: "C9",
    },
    {
      label: "C10",
      value: "C10",
    },
    {
      label: "RA0",
      value: "RA0",
    },
    {
      label: "RA1",
      value: "RA1",
    },
    {
      label: "RA2",
      value: "RA2",
    },
    {
      label: "RA3",
      value: "RA3",
    },
    {
      label: "RA4",
      value: "RA4",
    },
    {
      label: "SRA0",
      value: "SRA0",
    },
    {
      label: "SRA1",
      value: "SRA1",
    },
    {
      label: "SRA2",
      value: "SRA2",
    },
    {
      label: "SRA3",
      value: "SRA3",
    },
    {
      label: "SRA4",
      value: "SRA4",
    },
    {
      label: "Executive",
      value: "Executive",
    },
    {
      label: "Folio",
      value: "Folio",
    },
    {
      label: "Legal",
      value: "Legal",
    },
    {
      label: "Letter",
      value: "Letter",
    },
    {
      label: "Tabloid",
      value: "Tabloid",
    },
  ];
  public isImage = true;
  public bins: NzSelectOptionInterface[] = [
    {
      label: "Automatische Auswahl",
      value: 0,
    },
    {
      label: "Fach 1",
      value: 1,
    },
    {
      label: "Fach 2",
      value: 2,
    },
    {
      label: "Fach 3",
      value: 3,
    },
    {
      label: "Fach 4",
      value: 4,
    },
    {
      label: "Fach 5",
      value: 5,
    },
  ];

  constructor(
    private modalRef: NzModalRef,
    private readonly domSanitizer: DomSanitizer,
    private readonly http: HttpClient,
    private message: NzMessageService,
  ) {}

  public updatePreviewUrl() {
    const searchParams = new URLSearchParams(this.printOptions.value);
    this.previewUrl.set(
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `/api/v2/printing/preview?${searchParams.toString()}#toolbar=0&navpanes=0&view=fitH`,
      ),
    );
  }

  private loadPrinters() {
    this.http.get<Record<string, any>[]>("printing/list-printers").subscribe((p) => {
      const defaultPrinter = p.find((e) => e.default);
      this.printOptions.get("printer").setValue(defaultPrinter?.value || null);
      this.printers = p.map((e) => {
        return {
          label: `${e.label} ${e.default ? "| Standard" : ""} ${e.online ? "" : "| Offline"}`.trim(),
          value: e.value,
          groupLabel: e.gateway?.name,
        };
      });
    });
  }

  cancel() {
    this.modalRef.close(null);
  }

  ngOnInit(): void {
    this.loadPrinters();
    this.printOptions.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.updatePreviewUrl();
    });
    this.updatePreviewUrl();
  }

  print() {
    firstValueFrom(
      this.http.post("printing/print", {
        ...this.printOptions.value,
      }),
    )
      .then(() => {
        this.message.success("Dokument wird gedruckt…");
      })
      .catch((e) => {
        this.message.error("Fehler beim drucken des Dokumentes.");
      })
      .finally(() => {
        this.modalRef.close(true);
      });
  }

  public printLocal() {
    document.querySelector<HTMLIFrameElement>(".preview")?.contentWindow?.print();
  }
}
