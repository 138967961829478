/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponent, MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-subform",
  type: "subform",
  name: "Subform",
  icon: "burrito",
  hideInPallet: true,
})
export class MetaSubformComponent extends MetaFormComponent {
  @MetaFormComponentProperty({
    name: "Unterformular Parameter",
    type: "json",
    tab: "general",
    default: {},
  })
  public params?: Record<string, any> = {};

  @MetaFormComponentProperty({
    name: "Unterformular Id",
    type: "text",
    tab: "general",
    default: "",
    isTemplate: false,
  })
  public formId?: string;

  @MetaFormComponentProperty({
    name: "Edit Modus",
    type: "condition",
    tab: "general",
    default: false,
    renderDisplayValue: true,
  })
  public editing?: string | boolean;

  constructor() {
    super();
  }

  public toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        formId: this.formId,
        params: this.params,
        editing: this.editing === true,
      },
      type: `meta-${this.constructor["meta"]["type"]}`,
    });
  }
}
