/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { AgGridModule, ICellEditorAngularComp, IHeaderGroupAngularComp } from "@ag-grid-community/angular";
import "./ag-grid";
import {
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  ColumnApi,
  GridApi,
  GridOptions,
  ICellEditorParams,
  ICellRendererParams,
  IHeaderGroupParams,
  IServerSideDatasource,
  IsServerSideGroupOpenByDefaultParams,
  RowGroupOpenedEvent,
  RowNode,
} from "@ag-grid-community/core";
import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Directive,
  effect,
  ElementRef,
  Inject,
  Injector,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { FormsModule, UntypedFormGroup } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import {
  MetaButtonType,
  MetaDatepickerType,
  MetaGroupStyle,
  MetaIconStyle,
  MetaSize,
  MetaState,
  MetaSuffixType,
  MetaTagType,
  ScrollStates,
} from "@meta/enums";
import { FormlyConfig, FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import { FormlyAttributeEvent } from "@ngx-formly/core/lib/models/fieldconfig";
import { TranslateService } from "@tolgee/ngx";
import { MetaFormCondition } from "libs/forms/src/metaState.dto";
import * as _ from "lodash";
import * as moment from "moment";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzModalRef } from "ng-zorro-antd/modal";
import { NzResizableModule, NzResizeEvent } from "ng-zorro-antd/resizable";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { BehaviorSubject, firstValueFrom, skip, takeUntil } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";
import * as uuid from "uuid";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaActionHandlerFactory } from "../../base/metaForm/actions/actionHandler.factory";
import { MetaFormService } from "../../base/metaForm/metaForm.service";
import { PipesModule } from "../../pipes/pipes.module";
import { MetaAppService } from "../../services/metaApp.service";
import { MetaEventService } from "../../services/metaEvents.service";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaAvatarModule } from "../metaAvatar/metaAvatar.component";
import { MetaButton, MetaButtonModule } from "../metaButton/metaButton.component";
import { MetaDrodpownModule, MetaDropdown, MetaDropdownAction } from "../metaDropdown/metaDropdown.component";
import { MetaEmptyModule } from "../metaEmpty/metaEmpty.component";
import { MetaFieldWrapperModule } from "../metaFieldWrapper/metaFieldWrapper.component";
import { MetaGroupModule } from "../metaGroup/metaGroup.component";
import { MetaIconModule } from "../metaIcon/metaIcon.component";
import { MetaInputModule } from "../metaInput/metaInput.component";
import { MetaLoaderModule } from "../metaLoader/metaLoader.component";
import { MetaProgressModule, MetaProgressSize } from "../metaProgress/metaProgress.component";
import { MetaSectionModule } from "../metaSection/metaSection.component";
import { MetaSkeletonModule } from "../metaSkeleton/metaSkeleton.component";
import { MetaTagModule } from "../metaTag/metaTag.component";
import { MetaTooltipModule } from "../metaTooltip/metaTooltip.component";
import { localStorageKey, MetaTableState, TableView } from "./metaTable.interface";
import { MetaTableService } from "./metaTable.service";

const deletingAnimationTime = 2000;
const deletedAnimationTime = 200;

@Directive({
  selector: "[columnColor]",
})
export class ColumnColorDirective implements OnChanges {
  @Input("color") color: string;
  @Input("backgroundColor") backgroundColor: string;
  private bgEle: HTMLDivElement;

  constructor(private readonly ele: ElementRef<HTMLElement>) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.color) {
      if (changes.color.previousValue) {
        this.ele.nativeElement.style.color = null;
        this.ele.nativeElement.classList.add(`text-color-${changes.color.previousValue}`);
      }
      if (typeof changes.color.currentValue === "string") {
        if (changes.color.currentValue.indexOf("#") === 0) {
          this.ele.nativeElement.style.color = changes.color.currentValue;
        } else {
          this.ele.nativeElement.classList.add(`text-color-${changes.color.currentValue}`);
        }
      }
    }
    if (changes.backgroundColor) {
      if (this.bgEle) {
        this.bgEle.remove();
        this.bgEle = null;
      }
      if (typeof changes.backgroundColor.currentValue === "string") {
        if (changes.backgroundColor.currentValue.indexOf("#") === 0) {
          this.bgEle = document.createElement("div");
          this.ele.nativeElement.append(this.bgEle);
          this.bgEle.style.backgroundColor = changes.backgroundColor.currentValue;
          this.bgEle.style.position = "absolute";
          this.bgEle.style.inset = "0";
          this.bgEle.style.zIndex = "-1";
        }
      }
    }
  }
}

export class MetaTable {
  id?: string;
  columnDefs?: (ColDef | ColGroupDef)[] | null | undefined;
  condition?: MetaFormCondition = {
    skip: 0,
    take: 10,
    searchString: "",
  };
  createable? = false;
  editable? = false;
  fullEditMode? = false;
  deletable? = false;
  clickable? = false;
  groupable? = true;
  searchable? = true;
  hideToolbar? = false;
  hideFooter? = false;
  exportable? = true;
  showAsTreeview?: boolean = false;
  treeViewIdField?: string;
  treeViewIdLabel?: string;
  treeViewParamField?: string;
  treeViewGroupIndicatorField?: string;
  maximizeWindowUrl?: string;
  data?: MetaTableResult;
  params: any;
  rowHeight: number;
  height: string | number | "auto" = "auto";
  actions: MetaButton[] = [];
  customData?: any;
  selectable: boolean;
  hasSelectableTemplate: boolean;
  selectionType?: "single" | "multiple" = "multiple";
  filter: FormlyFieldConfig[];
  selectedRows: any[] = [];
  showGroupWrapper = false;
  groupWrapperStyle: MetaGroupStyle = MetaGroupStyle.default;
  groupWrapperSize: MetaSize = MetaSize.default;
  label: string;
  sublabel: string;
  url: string;
  deleteTooltip = "Diesen Eintrag löschen";
  detailForm?: string;
  detailRowHeight?: number;
  queryParams: string[];
  hideSidebar: boolean;
  isDetail: boolean;
  noInitDataLoad?: boolean = false;
  cardTemplates?: boolean = false;
  cardLoadingIndicatorCount?: number | null = null;
  defaultView?: TableView = TableView.table;
  flex: number | string = 1;
  onSelect: FormlyAttributeEvent;
  onEdit: FormlyAttributeEvent;
  onDelete: FormlyAttributeEvent;
  onSelectedRowsChange: FormlyAttributeEvent;
}

export interface MetaTableResult {
  resultSet: {
    [name: string]: string | number | any;
  }[];
  displayResultSet?: {
    [name: string]: string | number | any;
  }[];
  total: number;
  skip: number;
}

@Component({
  selector: "meta-header-renderer",
  template: `<span [innerHTML]="params.displayName"></span>`,
})
export class HeaderRendererComponent implements IHeaderGroupAngularComp {
  public params!: IHeaderGroupParams;

  public agInit(params: IHeaderGroupParams): void {
    this.params = params;
  }
}

@Component({
  selector: "meta-default-cell-renderer",
  template: `
    <ng-container *ngIf="params?.props?.tooltipForm; else content">
      <meta-tooltip
        [maParams]="{
          tooltipForm: params.props.tooltipForm,
          displayValue: displayValue,
          tooltipPlacement: params.props.tooltipPlacement,
          tooltipHeight: params.props.tooltipHeight,
          tooltipWidth: params.props.tooltipWidth
        }"
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </meta-tooltip>
    </ng-container>
    <ng-template #content>
      <span
        columnColor
        [color]="params?.color"
        [backgroundColor]="params?.backgroundColor"
        class="cell-prefix cell-format-{{ params?.strong ? 'strong' : '' }}"
        [ngClass]="{ clickable: params?.props?.onClick }"
        *ngIf="params?.prefix"
        (click)="onItemClick()"
      >
        <ng-container *ngIf="params.prefixType === 'progress'; else noProgressIcon">
          <i *ngIf="params?.prefix == 1" class="fas fa-circle-quarter text-color-warning"></i>
          <i *ngIf="params?.prefix == 2" class="fas fa-circle-half text-color-warning"></i>
          <i *ngIf="params?.prefix == 3" class="fas fa-circle-three-quarters text-color-warning"></i>
          <i *ngIf="params?.prefix == 4" class="fas fa-circle-check text-color-success"></i>
          <i *ngIf="params?.prefix === 'red'" class="fas fa-circle text-color-error"></i>
          <i *ngIf="params?.prefix === 'yellow'" class="fas fa-circle text-color-warning"></i>
          <i *ngIf="params?.prefix === 'green'" class="fas fa-circle text-color-success"></i>
          <i class="fas fa-circle cell-progress-bg text-color-warning"></i>
        </ng-container>
        <ng-template #noProgressIcon>
          <ng-container *ngIf="params.prefixType === 'icon'; else noIcon">
            <i class="fas fa-{{ params?.prefix }}"></i>
          </ng-container>
        </ng-template>

        <ng-template #noIcon>
          <span [innerHTML]="params?.prefix | sanitizeHtml"> </span>
        </ng-template>
      </span>

      @if (params?.inputType === "percent") {
        <span
          columnColor
          [color]="params?.color"
          [backgroundColor]="params?.backgroundColor"
          class="cell-format-{{ params?.strong ? 'strong' : '' }}"
          [ngClass]="{ clickable: params?.props?.onClick }"
          (click)="onItemClick()"
          [innerHTML]="value * 100 | metaNumber"
        ></span>
      } @else {
        <span
          columnColor
          [color]="params?.color"
          [backgroundColor]="params?.backgroundColor"
          class="cell-format-{{ params?.strong ? 'strong' : '' }}"
          [ngClass]="{ clickable: params?.props?.onClick }"
          (click)="onItemClick()"
          [innerHTML]="
            params?.inputType === 'currency' || params?.inputType === 'decimal' || params?.inputType === 'int'
              ? (value
                | metaNumber: { digits: params.digits, minDigits: params?.inputType === 'currency' ? 2 : undefined })
              : value
          "
        ></span>
      }

      <span
        columnColor
        [color]="params?.color"
        [backgroundColor]="params?.backgroundColor"
        class="cell-suffix cell-format-{{ params?.strong ? 'strong' : '' }}"
        [ngClass]="{ clickable: params?.props?.onClick }"
        (click)="onItemClick()"
        *ngIf="params?.suffix"
      >
        <ng-container *ngIf="params.prefixType === 'progress'; else noProgressIcon">
          <i *ngIf="params?.prefix == 1" class="fas fa-circle-quarter"></i>
          <i *ngIf="params?.prefix == 2" class="fas fa-circle-half"></i>
          <i *ngIf="params?.prefix == 3" class="fas fa-circle-three-quarters"></i>
          <i *ngIf="params?.prefix == 4" class="fas fa-circle-check"></i>
          <i *ngIf="params?.prefix === 'red'" class="fas fa-circle text-color-error"></i>
          <i *ngIf="params?.prefix === 'yellow'" class="fas fa-circle text-color-warning"></i>
          <i *ngIf="params?.prefix === 'green'" class="fas fa-circle text-color-success"></i>
        </ng-container>
        <ng-template #noProgressIcon>
          <ng-container *ngIf="params.suffixType === 'icon'; else noIcon">
            <i class="fas fa-{{ params?.suffix }}"></i>
          </ng-container>
        </ng-template>

        <ng-template #noIcon>
          <span [innerHTML]="params?.suffix | sanitizeHtml"> </span>
        </ng-template>
      </span>
    </ng-template>
    <meta-button
      *ngIf="params.colDef.editable"
      class="inline-edit"
      [maParams]="{ icon: 'pen', iconStyle: 'fas', type: 'link', size: 'small' }"
      (click)="onClick()"
    ></meta-button>
  `,
  styleUrls: ["./tableCell.component.less"],
})
class DefaultCellRendererComponent {
  public params: any;
  public value: string;
  public displayValue: any;

  constructor(private readonly _metaActionHandler: MetaActionHandlerFactory) {}

  agInit(params) {
    this.displayValue = params.data?.displayResultSet[params.colDef.field] || {};
    params.prefix = this.displayValue.prefix;
    params.suffix = this.displayValue.suffix;
    params.color = this.displayValue.color;
    params.backgroundColor = this.displayValue.backgroundColor;
    this.value = this.displayValue.value || params.value;
    this.params = params;
  }

  public onClick() {
    this.params.api.startEditingCell({ rowIndex: this.params.rowIndex, colKey: this.params.colDef.field });
  }

  public onItemClick() {
    if (!this.params?.props?.onClick) {
      return;
    }
    this._metaActionHandler
      .executeClickAction({
        controlId: this.params.colDef.field,
        formId: this.params.tableId,
        data: {
          ...this.params.data,
        },
        passthroughData: {
          formId: this.params.parentFormId,
        },
        formIsValid: true,
      })
      .catch((e) => {
        console.error(e);
      });
  }
}

@Component({
  selector: "meta-date-cell-renderer",
  template: ` <div columnColor [backgroundColor]="params.backgroundColor" [color]="params.color">
      {{ params.value | date: dateFormat }}
    </div>
    <meta-button
      *ngIf="params.colDef.editable"
      class="inline-edit"
      [maParams]="{ icon: 'pen', iconStyle: 'fas', type: 'link', size: 'small' }"
      (click)="onClick()"
    ></meta-button>`,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;

        .inline-edit {
          opacity: 0;
        }
      }
    `,
  ],
})
class DateCellRendererComponent {
  public params: any;
  public dateFormat = "mediumDate";
  public displayValue: any;

  public agInit(params) {
    this.displayValue = params.data?.displayResultSet[params.colDef.field] || {};
    switch (params.datepickerType) {
      case MetaDatepickerType.datetime:
        this.dateFormat = "medium";
        break;
      case MetaDatepickerType.time:
        this.dateFormat = "shortTime";
        break;
      case MetaDatepickerType.month:
        this.dateFormat = "LLLL";
        break;
      case MetaDatepickerType.week:
        this.dateFormat = "ww";
        break;
      case MetaDatepickerType.year:
        this.dateFormat = "yyyy";
        break;
    }
    this.params = params;
    params.color = this.displayValue.color;
    params.backgroundColor = this.displayValue.backgroundColor;
  }

  public onClick() {
    this.params.api.startEditingCell({ rowIndex: this.params.rowIndex, colKey: this.params.colDef.field });
  }
}

@Component({
  selector: "meta-progress-cell-renderer",
  template: ` <meta-progress
    *ngIf="show"
    [maParams]="{
      steps: params.customData?.columns.length,
      percent: params.customData ? getCustomPercentage(params.data) : params.value,
      size: metaProgressSize.small,
      color: params.color,
      isStepped: props.isStepped,
      stepData: stepData,
      hideStepLabel: true
    }"
  ></meta-progress>`,
})
class ProgressCellRendererComponent {
  public params: any;
  public metaProgressSize = MetaProgressSize;
  public props: any;
  public stepData: any[] = [];
  public show = true;

  agInit(params) {
    const displayValue = params.data.displayResultSet[params.colDef.field] || {};
    params.color = displayValue.color;
    this.params = params;
    this.props = params.colDef?.cellRendererParams?.props || {};
    if (this.props.isStepped) {
      this.stepData = displayValue["steps"];
    }
    this.show = params.customData || (params.value !== null && params.value !== undefined);
  }

  public getCustomPercentage(templateScope: any) {
    if (templateScope && this.params.customData?.columns) {
      const { columns } = this.params.customData;
      // Needs Pipeline.Column type instead of any
      const phase = columns.find(({ id }: any) => id.match(templateScope.primaryItem.PhaseID));
      if (phase) {
        const percentage = +(100 / columns.length) * (phase.order + 1);
        return +(percentage / 100) + 0.1;
      }
    }
  }
}

@Component({
  selector: "meta-link",
  template: `
    <ng-container *ngIf="value">
      <span
        columnColor
        [color]="params?.color"
        [backgroundColor]="params?.backgroundColor"
        class="cell-prefix cell-format-{{ params?.strong ? 'strong' : '' }}"
        *ngIf="params?.prefix"
      >
        <ng-container *ngIf="params.prefixType === 'progress'; else noProgressIcon">
          <i *ngIf="params?.prefix == 1" class="fas fa-circle-quarter text-color-warning"></i>
          <i *ngIf="params?.prefix == 2" class="fas fa-circle-half text-color-warning"></i>
          <i *ngIf="params?.prefix == 3" class="fas fa-circle-three-quarters text-color-warning"></i>
          <i *ngIf="params?.prefix == 4" class="fas fa-circle-check text-color-success"></i>
          <i *ngIf="params?.prefix === 'error'" class="fas fa-exclamation-circle text-color-error"></i>
          <i *ngIf="params?.prefix === 'red'" class="fas fa-circle text-color-error"></i>
          <i class="fas fa-circle cell-progress-bg text-color-warning"></i>
        </ng-container>
        <ng-template #noProgressIcon>
          <ng-container *ngIf="params.prefixType === 'icon'; else noIcon">
            <i class="fas fa-{{ params?.prefix }}"></i>
          </ng-container>
        </ng-template>

        <ng-template #noIcon>
          <span [innerHTML]="params?.prefix | sanitizeHtml"> </span>
        </ng-template>
      </span>
      <a
        columnColor
        *ngIf="!isExternal"
        [color]="params?.color"
        [routerLink]="link"
        [innerHTML]="value | sanitizeHtml"
      ></a>
      <a
        columnColor
        target="_blank"
        *ngIf="isExternal"
        [color]="params?.color"
        [href]="link"
        [innerHTML]="value | sanitizeHtml"
      ></a>
    </ng-container>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
      }
    `,
  ],
})
export class LinkCellRendererComponent {
  public params;
  public isExternal = false;
  public value: string;
  public link: string;
  public displayValue: any;

  public agInit(params: any): void {
    this.displayValue = params.data?.displayResultSet[params.colDef.field] || {};
    params.prefix = this.displayValue.prefix;
    params.suffix = this.displayValue.suffix;
    params.color = this.displayValue.color;
    params.backgroundColor = this.displayValue.backgroundColor;

    this.params = params;
    this.value = (params.data?.displayResultSet[params.colDef.field] || {})["value"] || params.value;
    this.link = params?.data?.displayResultSet[params.field]?.link || "";
    if (this.link.indexOf("/api/") === 0 || this.link.indexOf("http") === 0) {
      this.isExternal = true;
    }
  }
}

@Component({
  selector: "meta-table-detail",
  template: ` <ng-template #out></ng-template> `,
  styleUrls: ["./detailRenderer.component.less"],
})
export class DetailRendererComponent implements AfterViewInit {
  @ViewChild("out", { read: ViewContainerRef })
  public readonly out: ViewContainerRef;
  public data: Record<string, any>;
  public formId: string;
  public itemId: string;
  private parentTable: MetatableComponent;

  constructor(
    private readonly injector: Injector,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  agInit(params: { data: Record<any, any>; formId: string; parentTable: MetatableComponent }): void {
    this.parentTable = params.parentTable;
    this.data = params.data;
    this.formId = params.formId;
    this.itemId = _.get(this.data, "_meta.detailId", this.data["_id"].join(","));
  }

  ngAfterViewInit(): void {
    const injector = Injector.create({
      providers: [
        {
          provide: NzModalRef,
          useValue: {
            updateConfig: () => {},
          },
        },
        {
          provide: "PARENT_TABLE",
          useValue: this.parentTable,
        },
      ],
      parent: this.injector,
    });
    setTimeout(async () => {
      const { MetaFormComponent } = await import("../../base/metaForm/metaForm.component");
      const ref = this.out.createComponent(MetaFormComponent, {
        injector,
      });
      ref.instance.maFormId = this.formId;
      ref.instance.maItemId = this.itemId;
      ref.instance.maNoBrowserTitle = true;
      this.changeDetectorRef.markForCheck();
    }, 150);
  }
}

@Component({
  selector: "meta-editor-cell-renderer",
  template: ` <ng-container #target></ng-container>
    <meta-button
      class="inline-edit"
      [maParams]="{ icon: 'check', iconStyle: 'fas', type: 'primary' }"
      (click)="onOkClick()"
    ></meta-button>
    <meta-button
      class="inline-edit"
      [maParams]="{ icon: 'times', iconStyle: 'fas', type: 'primary', danger: true }"
      (click)="onCancelClick()"
    ></meta-button>`,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
      }
    `,
  ],
})
class EditCellRendererComponent implements ICellEditorAngularComp, AfterViewInit {
  public maParams: any;
  public value: any;
  @ViewChild("target", { read: ViewContainerRef })
  public target: ViewContainerRef;
  public params: ICellEditorParams;
  private compRef: ComponentRef<any>;

  constructor(
    private readonly formlyConfig: FormlyConfig,
    private _metaFormService: MetaFormService,
    private readonly _metaActionHandler: MetaActionHandlerFactory,
  ) {}

  agInit(params: ICellEditorParams) {
    this.params = params;
  }

  getValue() {
    // this simple editor doubles any value entered into the input
    return this.value;
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart() {
    const id = this.params.colDef.cellRendererParams.field;
    return !!this.params.data?.displayResultSet[id]?.disabledCondition;
  }

  // Gets called once when editing is finished (eg if Enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd() {
    return false;
  }

  public onOkClick() {
    this.params.api.stopEditing();
  }

  public onCancelClick() {
    this.params.api.stopEditing(true);
  }

  ngAfterViewInit(): void {
    const cellEditorParams = this.params.colDef.cellEditorParams;
    const c = this.formlyConfig.types[cellEditorParams.type];
    if (!c) {
      return;
    }
    this.compRef = this.target.createComponent(c.component, {});
    this.compRef.instance.maParams = {
      ...cellEditorParams.props,
      editing: true,
      parentFormId: this.params.colDef.cellRendererParams.tableId,
      id: this.params.colDef.cellRendererParams.field,
      contextData: this.params.data,
      isTableControl: true,
    };
    this.value = this.params?.value;
    this.compRef.instance.writeValue(this.value);
    this.compRef.instance.registerOnChange(async (newValue) => {
      this.value = newValue;
      if (this.params["type"] === "meta-select") {
        await this._metaActionHandler.executeSelectAction({
          formId: this.params.colDef.cellRendererParams.tableId,
          controlId: this.params["id"],
          data: {
            value: newValue,
            ...this.params["data"],
            [this.params["id"]]: newValue,
          },
          ctx: this.params["data"]["_ctx"],
          subFormPath: [],
          index: this.params["rowIndex"],
          passthroughData: {
            formId: this.params.colDef.cellRendererParams.parentFormId,
          },
        });
      }
    });
  }
}

@Component({
  selector: "meta-list-input-cell-renderer",
  template: `
    <span *ngIf="count === 1; else elseTmp">{{ count }} Eintrag</span>
    <ng-template #elseTmp>
      <span>{{ count }} Einträge</span>
    </ng-template>
  `,
})
class ListInputCellRendererComponent {
  public params: any;
  public count: number;

  agInit(params) {
    this.params = params;
    this.count = params?.value?.length || 0;
  }
}

@Component({
  selector: "meta-filesize-cell-renderer",
  template: `{{ params.value | metaFileSize }}`,
})
class FilesizeCellRendererComponent {
  public params: any;

  agInit(params) {
    this.params = params;
  }
}

@Component({
  selector: "meta-checkbox-cell-renderer",
  template: `
    <span
      columnColor
      [color]="params?.color"
      [backgroundColor]="params?.backgroundColor"
      *ngIf="params.displayValue"
      [innerHTML]="params.displayValue"
    ></span>
    <ng-container *ngIf="!params.displayValue">
      <span columnColor [color]="params?.color" [backgroundColor]="params?.backgroundColor">
        {{ params.value ? "Ja" : "Nein" }}
      </span>
    </ng-container>
  `,
})
class CheckboxCellRendererComponent {
  public params: any;

  agInit(params) {
    const displayResultSet = params.data?.displayResultSet[params.colDef.field] || {};
    params.displayValue = displayResultSet["value"];
    params.color = displayResultSet?.color;
    params.backgroundColor = displayResultSet?.backgroundColor;
    this.params = params;
  }
}

@Component({
  selector: "meta-multiselect-cell-renderer",
  template: ` <ng-container *ngIf="values">
    <meta-tag
      *ngFor="let v of values"
      [maParams]="{
        label: v.value,
        type: v.color || MetaTagType.default,
        size: 'default'
      }"
    ></meta-tag>
  </ng-container>`,
})
class MultiselectCellRendererComponent {
  public MetaTagType = MetaTagType;
  public params: any;
  public values: any[] = [];

  agInit(params) {
    this.params = params;
    this.values = _.isArray(params.data?.displayResultSet[params.field])
      ? params.data?.displayResultSet[params.field]
      : _.isArray(params.data?.displayResultSet[params.field]?.values)
        ? params.data?.displayResultSet[params.field].values
        : params.data?.displayResultSet[params.field];
  }
}

@Component({
  selector: "meta-action-cell-renderer",
  template: `
    <ng-container *ngFor="let a of spotlightActions">
      <meta-button
        *ngIf="!a.hide"
        [maParams]="
          merge(a, {
            type: a.buttonType,
            loading: actionsLoading[a.id],
            disabled:
              params.data.displayResultSet &&
              params.data.displayResultSet[a.id] &&
              params.data.displayResultSet[a.id].disabledCondition !== undefined
                ? params.data.displayResultSet[a.id].disabledCondition
                : false
          })
        "
      >
      </meta-button>
    </ng-container>
    <meta-section *ngIf="spotlightActions.length > 0" [maParams]="{ sectionType: 'vr' }"></meta-section>
    <meta-dropdown
      *ngIf="actions.length > 0"
      [maParams]="maParams"
      (maActionEvent)="actionEvent(params.data, $event)"
    ></meta-dropdown>
    <meta-button *ngIf="params.editable" [maParams]="editParams"></meta-button>
    <meta-button *ngIf="params.deletable" [maParams]="deleteParams"></meta-button>
  `,
})
class ActionCellRendererComponent {
  public params: any;
  public maParams: MetaDropdown;
  public deleteParams: MetaButton;
  public editParams: MetaButton;
  public spotlightActions: MetaButton[];
  public actions: MetaButton[];
  public isDeleting: boolean;
  public isEditing: boolean;
  public actionsLoading = {};
  public onClick;
  protected readonly MetaFormDataState = MetaState;

  constructor(
    private readonly _metaActionHandler: MetaActionHandlerFactory,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  public merge<A, B>(a: A, b: B): A & B {
    return { ...a, ...b };
  }

  agInit(params: any) {
    this.params = params;
    const cItems = JSON.parse(JSON.stringify(this.params.actionsMenu));
    for (let j = 0; j < cItems.length; j++) {
      cItems[j].disabled = !!this.params.data.displayResultSet[cItems[j].id]?.disabledCondition;
      cItems[j].invisible = !this.params.data.displayResultSet[cItems[j].id]?.condition;
      cItems[j].onClick = () => {
        if (cItems[j].disabled) {
          return;
        }
        this.actionsLoading[cItems[j].id] = true;
        this._metaActionHandler
          .executeClickAction({
            controlId: cItems[j].id,
            formId: this.params.tableId,
            data: {
              ...this.params.data,
            },
            passthroughData: {
              formId: this.params.parentFormId,
            },
            formIsValid: true,
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.actionsLoading[cItems[j].id] = false;
            this.changeDetectorRef.detectChanges();
          });
      };
    }
    this.spotlightActions = cItems.filter((e) => e.spotlight && !e.invisible);
    this.actions = cItems.filter((e) => !e.spotlight && !e.invisible);
    this.maParams = {
      icon: "ellipsis-v",
      iconStyle: MetaIconStyle.Solid,
      items: _.clone(this.actions),
      type: "link",
      size: "small",
    };

    this.deleteParams = {
      loading: this.isDeleting,
      icon: "trash",
      iconStyle: MetaIconStyle.Solid,
      type: "link",
      danger: true,
      onClick: () => {
        this.deleteEvent(params.data);
      },
    };

    this.editParams = {
      loading: this.isEditing,
      icon: "pencil",
      iconStyle: MetaIconStyle.Solid,
      type: "link",
      onClick: () => {
        this.editEvent(params.data);
      },
    };
  }

  public actionEvent(data: any, event: MetaDropdownAction) {
    this.params.onAction({
      action: event,
      data,
    });
  }

  public deleteEvent(data: any) {
    this.isDeleting = true;
    this.params.onDelete(data);
    this.isDeleting = false;
  }

  public editEvent(data: any) {
    this.isEditing = true;
    this.params.onEdit(data);
    this.isEditing = false;
  }
}

@Component({
  selector: "meta-loading-cell-renderer",
  template: ` <meta-skeleton
    [style.width.%]="100"
    [maParams]="{
      loading: true,
      noMargin: true
    }"
  ></meta-skeleton>`,
})
class LoadingCellRendererComponent {
  public params: any;

  agInit(params) {
    this.params = params;
  }
}

@Component({
  selector: "meta-image-cell-renderer",
  template: `<img
    *ngFor="let image of params.value"
    importance="low"
    class="table-image"
    alt=""
    src="/api/v2/file/{{ image }}/download?width=50&height=50&fit=inside"
  />`,
})
class ImageCellRendererComponent {
  public params: any;

  agInit(params) {
    this.params = params;
  }
}

// noinspection TypeScriptValidateTypes
@MetaUnsubscribe()
@Component({
  selector: "meta-table",
  template: `
    <meta-group
      *ngIf="ma.showGroupWrapper && metaAppService.appBreakpoint() !== 'xs'; else content"
      [maParams]="{
        groupStyle: metaTableService.state().view === 'grid' ? 'transparent' : 'default',
        flex: ma.flex
      }"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </meta-group>
    <ng-template #content>
      <ng-container *ngIf="ma.selectable && ma.hasSelectableTemplate; else defaultTpl">
        <div nz-row>
          <div
            class="col"
            nz-col
            nz-resizable
            (nzResize)="onResize($event)"
            [nzMinColumn]="12"
            [nzMaxColumn]="20"
            [nzGridColumnCount]="24"
            [nzSpan]="selectedRows.length > 0 ? col : 24"
          >
            <div class="split-wrapper" [ngClass]="{ modal: field && formState.loadedAsModal }">
              <nz-resize-handles
                class="resize-handle"
                [style.height]="field && formState.loadedAsModal ? null : null"
                [nzDirections]="['right']"
              ></nz-resize-handles>
              <ng-container *ngTemplateOutlet="defaultTpl"></ng-container>
            </div>
          </div>
          <div *ngIf="selectedRows.length > 0" class="split-content" nz-col [nzSpan]="24 - col">
            <div class="grid-selection">
              <h2>Ausgewählte Elemente</h2>
              <div *ngFor="let row of selectedRows" class="grid-selection-item">
                <div class="grid-selection-item-content">
                  <ng-container *ngFor="let text of row.displayResultSet._meta.selectableTemplate; let index = index">
                    <strong *ngIf="index === 0; else regularTextTpl" [innerHTML]="text"></strong>
                    <ng-template #regularTextTpl>
                      <div [innerHTML]="text"></div>
                    </ng-template>
                  </ng-container>
                </div>
                <div class="grid-selection-item-actions">
                  <meta-button
                    [maParams]="{
                      icon: 'trash',
                      iconStyle: MetaIconStyle.Solid,
                      type: 'link',
                      danger: true,
                      onClick: deselectNode.bind(this, row)
                    }"
                  >
                  </meta-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #defaultTpl>
        <ng-container *ngIf="ma.filter?.length > 0 && !ma.hideToolbar">
          <nz-row
            [ngClass]="{
              'grid-toolbar': metaTableService.state().view === 'grid',
              'grid-toolbar-mobile': metaAppService.appBreakpoint() === 'xs'
            }"
            [nzGutter]="12"
            [ngStyle]="{
              height: maxHeightOfFilterRow > 10 ? maxHeightOfFilterRow + 'px' : 'inherit'
            }"
          >
            <nz-col [nzSpan]="ma.searchable ? 18 : 24">
              <formly-form
                [fields]="ma.filter"
                [options]="options"
                [form]="filterForm"
                [model]="filterFormModel"
              ></formly-form>
            </nz-col>
            <nz-col *ngIf="ma.searchable" [nzSpan]="6" class="table-search-with-filter">
              <meta-field-wrapper [style.width.%]="100">
                <ng-container *ngTemplateOutlet="tmpSearch"></ng-container>
              </meta-field-wrapper>
              <ng-container *ngIf="ma.filter?.length > 0 && metaAppService.appBreakpoint() !== 'xs'">
                <ng-container *ngTemplateOutlet="tmpTableActions"></ng-container>
              </ng-container>
            </nz-col>
          </nz-row>
        </ng-container>
        <div
          *ngIf="!ma.hideToolbar && ma.filter?.length === 0"
          class="table-toolbar"
          [ngClass]="{
            'no-group': !ma.groupable,
            'has-filter': ma.filter?.length > 0,
            'grid-toolbar': metaTableService.state().view === 'grid' || metaAppService.appBreakpoint() === 'xs',
            'grid-toolbar-mobile': metaAppService.appBreakpoint() === 'xs'
          }"
          [style.width]="ma.groupable ? '500px' : '100%'"
        >
          <ng-container *ngIf="metaTableService.state().view === 'grid'">
            <ng-container *ngTemplateOutlet="countTpl"></ng-container>
          </ng-container>
          <div class="table-search" [style.width.%]="100">
            <ng-container *ngTemplateOutlet="tmpSearch"></ng-container>
          </div>
          <ng-container *ngIf="metaAppService.appBreakpoint() !== 'xs'">
            <ng-container *ngTemplateOutlet="tmpTableActions"></ng-container>
          </ng-container>
        </div>

        <div id="{{ _id }}"></div>
        <ng-container *ngIf="metaTableService.state().view === 'table'; else gridTpl">
          <ag-grid-angular
            #table
            *ngIf="parentDataLoaded"
            style="width: 100%"
            [style.height.px]="ma.height !== 'auto' ? ma.height : height"
            [style.minHeight.px]="ma.height !== 'auto' ? ma.height : parentTable ? undefined : 300"
            class="ag-theme-alpine"
            [ngClass]="{ 'has-filter': ma.filter?.length > 0, 'is-clickable': ma.clickable }"
            [getRowId]="getRowNodeId"
            [defaultColDef]="defaultColDef"
            [columnTypes]="columnTypes"
            [columnDefs]="columnDefs"
            [headerHeight]="paramHeaderHeight"
            [groupHeaderHeight]="paramGroupHeaderHeight"
            [floatingFiltersHeight]="paramFloatingFiltersHeight"
            [pivotHeaderHeight]="paramPivotHeaderHeight"
            [pivotGroupHeaderHeight]="paramPivotGroupHeaderHeight"
            [rowData]="ma.data ? ma.data.resultSet : null"
            [animateRows]="paramAnimateRows"
            [gridOptions]="gridOptions"
            (gridReady)="onGridReady($event)"
            (sortChanged)="onStateChange($event)"
            (columnResized)="onStateChange($event)"
            (columnVisible)="onStateChange($event)"
            (columnPivotChanged)="onStateChange($event)"
            (columnRowGroupChanged)="onStateChange($event)"
            (columnValueChanged)="onStateChange($event)"
            (columnMoved)="onStateChange($event)"
            (columnPinned)="onStateChange($event)"
            (filterChanged)="onStateChange($event)"
            (cellClicked)="onCellClicked($event)"
            (filterModified)="onFilterMod($event)"
            (selectionChanged)="onSelectionChange($event)"
            (rowGroupOpened)="onRowGroupOpened($event)"
          >
          </ag-grid-angular>

          <ng-container *ngTemplateOutlet="countTpl"></ng-container>
        </ng-container>
        <ng-template #countTpl>
          @if (!ma.hideFooter) {
            <ng-container *ngIf="metaTableService.total() !== null && !ma.isDetail">
              <div
                [ngClass]="{
                  'grid-total': metaTableService.state().view === 'grid',
                  'table-total': metaTableService.state().view === 'table'
                }"
              >
                <span
                  ><strong>{{ metaTableService.total() | metaNumber: { minDigits: 0 } }}</strong
                  >&nbsp;Datensätze</span
                >
                <span *ngIf="selectedRows.length > 0"
                  >&nbsp;|&nbsp;<strong>{{ selectedRows.length | metaNumber: { minDigits: 0 } }}</strong
                  >&nbsp;Ausgewählt</span
                >
              </div>
            </ng-container>
          }
        </ng-template>
        <ng-template #gridTpl>
          <div class="table-grid" [ngClass]="{ 'table-grid-mobile': metaAppService.appBreakpoint() === 'xs' }">
            <div
              *ngFor="let card of gridData; let index = index; trackBy: trackById"
              class="table-card"
              [ngClass]="{
                'has-actions': gridMainActions.length > 0,
                'has-progress': card._meta.cardTemplates.progress,
                deleting: card.deleting,
                deleted: card.deleted
              }"
            >
              <a *ngIf="card._meta.cardTemplates.link; else cardContent" [routerLink]="card._meta.cardTemplates.link">
                <ng-container *ngTemplateOutlet="cardContent"></ng-container>
              </a>
              <ng-template #cardContent>
                <div class="table-card-deleting-{{ card.deletingStyle }}">
                  <meta-icon
                    *ngIf="card.deleting"
                    [maParams]="{
                      icon: card.deletingStyle === 'success' ? 'zczmziog' : 'zlhhhefv',
                      trigger: 'loop',
                      size: 128,
                      style: 'light'
                    }"
                  ></meta-icon>
                </div>
                <div class="table-card-head">
                  <meta-dropdown
                    *ngIf="card._gridActionsMenu?.items.length > 0"
                    (click)="$event.stopPropagation()"
                    [maParams]="card._gridActionsMenu"
                  ></meta-dropdown>
                  <meta-avatar [maParams]="{ label: card._meta.cardTemplates.avatar, size: 50 }"></meta-avatar>
                </div>
                <div class="table-card-body table-card-body-deleting-{{ card.deletingStyle }}">
                  <div class="table-card-body-inner">
                    <span *ngIf="card._meta.cardTemplates.status?.indicator" class="table-card-indicator-wrapper">
                      <span
                        class="table-card-indicator cell-bg-{{ card._meta.cardTemplates.status.indicator }}"
                        [innerHtml]="card._meta.cardTemplates.status.label"
                      ></span>
                    </span>
                    <ng-container *ngFor="let title of card._meta.cardTemplates.title; let index = index">
                      <ng-container *ngIf="index === 0; else elseTitleTpl">
                        <strong [innerHtml]="title"></strong>
                      </ng-container>
                      <ng-template #elseTitleTpl>
                        <span [innerHtml]="title"></span>
                      </ng-template>
                    </ng-container>
                    <meta-section [maParams]="{ sectionType: 'hr', spacingTop: 2, spacingBottom: 2 }"></meta-section>
                    <ng-container *ngFor="let content of card._meta.cardTemplates.content">
                      <span [innerHtml]="content"></span>
                    </ng-container>
                  </div>
                </div>
                <div
                  *ngIf="card._meta.cardTemplates.progress"
                  class="table-card-progress table-card-progress-deleting-{{ card.deletingStyle }}"
                >
                  <div class="table-card-progress-inner">
                    <div class="table-card-progress-user">
                      <meta-avatar
                        *ngIf="card._meta.cardTemplates.progress.user"
                        [maParams]="{
                          id: card._meta.cardTemplates.progress.user.id,
                          label: card._meta.cardTemplates.progress.user.name,
                          size: 40
                        }"
                      ></meta-avatar>
                    </div>
                    <div class="table-card-progress-bar">
                      <span
                        *ngIf="card._meta.cardTemplates.progress.label"
                        [innerHtml]="card._meta.cardTemplates.progress.label"
                        nz-tooltip
                        [nzTooltipTitle]="card._meta.cardTemplates.progress.tooltip"
                      ></span>
                      <meta-progress
                        [maParams]="{
                          percent: card._meta.cardTemplates.progress.value,
                          color: '2',
                          size: metaProgressSize.small,
                          hideStepLabel: true
                        }"
                      ></meta-progress>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="gridMainActions.length > 0"
                  class="table-card-actions table-card-actions-num-{{ gridMainActions.length }}"
                >
                  <meta-button
                    *ngFor="let action of gridMainActions"
                    [maParams]="{
                      label: action.label,
                      icon: action.icon,
                      type: action.buttonType,
                      loading: gridActionsLoading[index],
                      disabled: gridData[index][action.id] ? gridData[index][action.id].disabledCondition : false,
                      onClick: onCardActionClick.bind(this, action, card._resultSet, index)
                    }"
                  ></meta-button>
                </div>
              </ng-template>
            </div>
            <ng-container *ngIf="isLoading">
              <meta-loader></meta-loader>
            </ng-container>
          </div>
        </ng-template>
      </ng-template>

      <ng-template #actionTemplate>
        <meta-button
          *ngIf="ma.maximizeWindowUrl"
          [maParams]="{
            type: MetaButtonType.default,
            icon: 'expand',
            iconStyle: MetaIconStyle.Solid,
            size: 'small',
            onClick: maximizeWindow
          }"
          nz-tooltip
          nzTooltipTitle="Maximieren"
        ></meta-button>
      </ng-template>
      <ng-template #tmpSearch>
        <meta-input
          [maParams]="{
            suffix: 'search',
            suffixType: MetaSuffixType.icon,
            placeholder: 'Suche...',
            editing: true
          }"
          [(ngModel)]="search"
          (ngModelChange)="searchTerm.next($event)"
        ></meta-input>
      </ng-template>
      <ng-template #tmpTableActions>
        <meta-button
          *ngIf="ma.condition.filter?.filters?.length > 0 || gridApi?.isAnyFilterPresent() || searchString?.length > 0"
          [maParams]="{
            type: MetaButtonType.primary,
            danger: true,
            icon: 'times',
            iconStyle: MetaIconStyle.Solid,
            onClick: resetFilters
          }"
          nz-tooltip
          nzTooltipTitle="Filter zurücksetzen"
          nzTooltipPlacement="left"
        ></meta-button>
        <meta-button
          [maParams]="{
            type: 'default',
            icon: 'sync',
            onClick: refresh
          }"
          nz-tooltip
          nzTooltipTitle="Tabelle aktualisieren"
          nzTooltipPlacement="left"
        ></meta-button>
        <meta-button
          *ngIf="ma.exportable && metaTableService.state().view === 'table'"
          [maParams]="{
            type: MetaButtonType.default,
            icon: 'file-excel',
            loading: isExportingToExcel
          }"
          (click)="exportToFile('xlsx')"
          nz-tooltip
          nzTooltipTitle="Excel export"
          nzTooltipPlacement="left"
        >
        </meta-button>
        <meta-button
          *ngIf="metaTableService.state().view === 'table' && ma.cardTemplates"
          [maParams]="btnViewGrid"
          nz-tooltip
          nzTooltipTitle="Gridansicht"
          nzTooltipPlacement="left"
        >
        </meta-button>
        <meta-button
          *ngIf="metaTableService.state().view === 'grid' && ma.cardTemplates"
          [maParams]="btnViewTable"
          nz-tooltip
          nzTooltipTitle="Tabellenansicht"
          nzTooltipPlacement="left"
        >
        </meta-button>
        <meta-button
          *ngIf="ma.createable"
          [maParams]="{
            type: MetaButtonType.primary,
            icon: 'plus',
            label: ma.createable + ' hinzufügen',
            iconStyle: MetaIconStyle.Solid,
            onClick: addEntry.bind(this)
          }"
          nz-tooltip
          nzTooltipTitle="Neuen Datensatz erstellen"
        ></meta-button>
      </ng-template>
    </ng-template>
  `,
  styleUrls: ["./metaTable.component.scss", "./metaTableGrid.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [MetaTableService],
})
export class MetatableComponent extends MetaComponentBase implements OnInit, AfterViewInit {
  public _id: string;
  public MetaSuffixType = MetaSuffixType;
  public MetaButtonType = MetaButtonType;
  public MetaIconStyle = MetaIconStyle;
  public btnViewGrid = {
    type: MetaButtonType.default,
    icon: "grid",
    onClick: () => {
      this.switchToGrid();
    },
  };
  public btnViewTable = {
    type: MetaButtonType.default,
    icon: "table",
    onClick: () => {
      this.switchToTable();
    },
  };
  public tableGridIterator: AsyncIterator<any> | any;

  public search: string;
  public gridOptions: GridOptions;
  public gridColumnApi: ColumnApi;
  public gridApi: GridApi;
  public defaultColDef: ColDef | undefined;
  public columnDefs: (ColDef | ColGroupDef)[] | null | undefined;
  public isExportingToExcel: boolean;
  public cacheKey: string;
  public height: number;
  public parentDataLoaded = false;
  public parentContextId: string;
  public filterForm: UntypedFormGroup = new UntypedFormGroup({});
  public filterFormModel: any = {};
  public gridRendered: boolean;
  public forcedRefresh: boolean;
  public gridData: any[] = [];
  public gridTake = 10;
  public gridDataLoader: any[] = [];
  public gridMainActions: MetaButton[] = [];
  public gridActionsLoading = {};
  public init = true;

  public searchTerm = new BehaviorSubject<string>("");
  public selectedRows: any[] = [];

  public detailCellRendererParams: Record<string, any>;
  public detailOpenedRow: RowNode;
  public metaProgressSize = MetaProgressSize;

  public paramResizeOnFirstRender = true; // Resizes the columns after first data render.
  public paramHeaderHeight = 40; // The height in pixels for the row containing the column label header.
  public paramGroupHeaderHeight = 30; // The height in pixels for the rows containing header column groups.
  public paramFloatingFiltersHeight = 20; // The height in pixels for the row containing the floating filters.
  public paramPivotHeaderHeight = 40; // The height in pixels for the row containing the columns when in pivot mode.
  public paramPivotGroupHeaderHeight = 30; // The height in pixels for the row containing header column groups when in pivot mode.
  public paramAnimateRows = true; // Enables sort animations.
  public paramDebounceTime = 750; // Debounce time of filter and search
  public paramTake = 10;
  public columnTypes:
    | {
        [key: string]: ColDef;
      }
    | undefined = {
    "meta-datepicker": {
      filter: "agDateColumnFilter",
    },
    datepicker: {
      filter: "agDateColumnFilter",
    },
    number: {
      filter: "agNumberColumnFilter",
    },
    "meta-progress": {
      filter: false,
    },
    select: {
      filter: "agSetColumnFilter",
    },
    progress: {
      filter: "agNumberColumnFilter",
    },
    "meta-input": {
      filter: "agTextColumnFilter",
    },
    input: {
      filter: "agTextColumnFilter",
    },
    "meta-checkbox": {
      filter: "agTextColumnFilter",
    },
    checkbox: {
      filter: "agTextColumnFilter",
    },
  };
  public searchString: string;
  public maxHeightOfFilterRow: number;
  public col: number = 20;
  colId = -1;
  private filter = {
    equals: "eq",
    notEqual: "neq",
    contains: "contains",
    notContains: "doesnotcontain",
    startsWith: "startswith",
    endsWith: "endswith",
    lessThan: "lt",
    lessThanOrEqual: "lte",
    greaterThan: "gt",
    greaterThanOrEqual: "gte",
    empty: "isempty",
    inRange: "inRange",
  };
  private _filterCache: Record<string, Promise<any[]>> = {};
  private _hasSavedState: boolean;
  private additionalDatasourceParams: Record<string, any> = {};

  constructor(
    public readonly metaTableService: MetaTableService,
    public readonly metaHelperService: MetaHelperService,
    private readonly _router: Router,
    public readonly metaFormService: MetaFormService,
    private readonly _metaActionHandler: MetaActionHandlerFactory,
    public readonly metaAppService: MetaAppService,
    @Optional()
    @Inject("PARENT_TABLE")
    public readonly parentTable: MetatableComponent,
    _changeDetectorRef: ChangeDetectorRef,
    _metaHelperService: MetaHelperService,
    private readonly translateService: TranslateService,
    private readonly _metaEventService: MetaEventService,
  ) {
    super();
    super.maParams = new MetaTable();
    this.getGridData = _.debounce(this.getGridData, 300);
    effect(() => {
      const tableState = this.metaTableService.state();

      if (JSON.stringify(tableState.filt || []) !== JSON.stringify(tableState?.filt || [])) {
        if (tableState.view === TableView.table) {
          if (this.gridApi) {
            //TODO: BETTER FIX
            //this.gridApi.setFilterModel(tableState.filt);
          }
        } else if (tableState.view === TableView.grid) {
          this.getGridData(0, 100);
        }
      }
    });
  }

  get ma(): MetaTable {
    return super.ma;
  }

  async ngOnInit() {
    if (this.field) {
      this.metaTableService.state.set({
        ...new MetaTableState(),
        ...JSON.parse(localStorage.getItem(localStorageKey) || "{}")[this.field.id],
      });
    }
    if (!this.field) {
      this._id = this.ma.id || uuid.v4();
    } else {
      this._id = this.id;
    }
    // make sure translations are loaded
    await firstValueFrom(this.translateService.translate("", { ns: "ag-grid" }));
    if (this.field) {
      this.options.formState
        .onFormDataReady()
        .pipe(takeUntil((this as any).destroyed$))
        .subscribe({
          next: (form) => {
            this.refresh();
            this.parentContextId = form?._ctx;
            this.parentDataLoaded = true;
            this.changeDetectorRef.markForCheck();
          },
        });
    } else {
      this.parentDataLoaded = true;
      this.changeDetectorRef.markForCheck();
    }

    this._metaEventService.reloadTrigger$
      .pipe(
        debounceTime(50),
        filter((x) => x !== undefined && x !== null),
        takeUntil((this as any).destroyed$),
      )
      .subscribe({
        next: async (trigger) => {
          if (trigger.specific) {
            if (trigger.formId === this._id) {
              this.refresh(false, false, true);
            }
          } else {
            this.refresh(false, false);
          }
        },
      });

    this._metaEventService.setFilterTrigger$
      .pipe(
        debounceTime(50),
        filter((x: any) => {
          return x !== undefined && x !== null && x.fieldId === this._id;
        }),
        takeUntil((this as any).destroyed$),
      )
      .subscribe({
        next: async (trigger) => {
          const state = this.metaTableService.state();
          if (state) {
            state.filt = _.merge(state.filt, trigger.filter);
            this.metaTableService.state.set(state);
          }

          this.gridApi.setFilterModel(state.filt);
          this.gridApi.onFilterChanged();
          await this.refresh(true, true);
        },
      });

    this._metaEventService.setParamTrigger$
      .pipe(
        debounceTime(50),
        filter((x) => x !== undefined && x !== null && x.fieldId === this._id),
        takeUntil((this as any).destroyed$),
      )
      .subscribe((trigger) => {
        //this.additionalDatasourceParams = {...trigger.}
        Object.assign(this.additionalDatasourceParams, trigger.params);
        this.refresh(true, true, true);
      });

    this._metaEventService.removeItemTrigger$
      .pipe(
        debounceTime(50),
        filter((x) => x !== undefined && x !== null && x.formId === this._id),
        takeUntil((this as any).destroyed$),
      )
      .subscribe({
        next: async (trigger) => {
          if (this.metaTableService.state().view === TableView.grid) {
            trigger.dataItemIds.forEach((id) => {
              const item = this.gridData.find((gd) => JSON.stringify(gd._resultSet._id) === JSON.stringify(id));
              if (item) {
                item.deleting = true;
                item.deletingStyle = trigger.style;
                this.changeDetectorRef.markForCheck();
              }
            });
            setTimeout(() => {
              trigger.dataItemIds.forEach((id) => {
                const item = this.gridData.find((gd) => JSON.stringify(gd._resultSet._id) === JSON.stringify(id));
                if (item) {
                  item.deleted = true;
                  this.changeDetectorRef.markForCheck();
                }
              });
              setTimeout(() => {
                this.gridData = this.gridData.filter(function (objFromA) {
                  return !trigger.dataItemIds.find(function (objFromB) {
                    return JSON.stringify(objFromA._resultSet._id) === JSON.stringify(objFromB);
                  });
                });
                this.metaTableService.total.set(this.metaTableService.total() - trigger.dataItemIds.length);
                this.changeDetectorRef.markForCheck();
              }, deletedAnimationTime);
              this.changeDetectorRef.markForCheck();
            }, deletingAnimationTime);
          }
        },
      });
    this._metaEventService.updateTableTrigger$
      .pipe(
        debounceTime(50),
        filter((x) => x !== undefined && x !== null && x.options.formId === this._id),
        takeUntil((this as any).destroyed$),
      )
      .subscribe({
        next: async (object) => {
          this.gridApi.forEachNode((node) => {
            if (node.rowIndex === object.options.index) {
              node.updateData({ ...node.data, Recipient: object.action.value });
            }
          });
          this.changeDetectorRef.markForCheck();
        },
      });

    if (this.ma.detailForm) {
      this.detailCellRendererParams = {
        refreshStrategy: "everything",
        formId: this.ma.detailForm,
        parentTable: this,
      };
    }

    this.defaultColDef = {
      flex: 1,
      sortable: true,
      resizable: true,
      enableRowGroup: this.ma.groupable,
      enablePivot: false,
      enableValue: true,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: this.paramDebounceTime,
      },
      suppressMovable: this.ma.isDetail,
    };

    this.gridOptions = {
      rowHeight: this.ma.rowHeight || 65,
      rowMultiSelectWithClick: this.ma.selectable,
      rowSelection: this.ma.selectable ? this.ma.selectionType : null,
      suppressRowClickSelection: true,
      cacheBlockSize: this.paramTake,
      masterDetail: !!this.detailCellRendererParams,
      detailCellRendererParams: this.detailCellRendererParams,
      detailCellRenderer: DetailRendererComponent,
      detailRowHeight: this.ma.detailRowHeight
        ? this.ma.detailRowHeight
        : Math.max(400, Math.floor(window.innerHeight * 0.5)),
      keepDetailRows: false,
      cacheOverflowSize: 50,
      onCellValueChanged: this.cellValueChanged.bind(this),
      maxConcurrentDatasourceRequests: 3,
      infiniteInitialRowCount: 50,
      rowModelType: this.ma.data ? "clientSide" : "serverSide",
      serverSideInfiniteScroll: true,
      enableCellTextSelection: false,
      enableRangeSelection: true,
      suppressMultiRangeSelection: true,
      enableCharts: true,
      animateRows: false,
      rowGroupPanelShow: this.ma.groupable ? "always" : "never",
      groupDisplayType: "multipleColumns",
      groupSelectsChildren: this.ma.selectable,
      groupIncludeFooter: !this.ma.data,
      groupIncludeTotalFooter: !this.ma.data,
      treeData: this.ma.showAsTreeview,
      isServerSideGroup: this.ma.showAsTreeview
        ? (dataItem: any) => {
            // indicate if node is a group
            return dataItem[this.ma.treeViewGroupIndicatorField];
          }
        : null,
      getServerSideGroupKey: this.ma.showAsTreeview
        ? (dataItem: any) => {
            // specify which group key to use
            return dataItem[this.ma.treeViewIdField];
          }
        : null,
      isServerSideGroupOpenByDefault: this.ma.showAsTreeview
        ? (params: IsServerSideGroupOpenByDefaultParams) => {
            // open first two levels by default
            return params.rowNode.level < 2;
          }
        : null,
      autoGroupColumnDef: this.ma.showAsTreeview
        ? {
            field: this.ma.treeViewIdField,
            headerName: this.ma.treeViewIdLabel,
            cellRendererParams: {
              innerRenderer: (params: ICellRendererParams) => {
                // display employeeName rather than group key (employeeId)
                return params.data[this.ma.treeViewIdField];
              },
            },
          }
        : null,
      suppressCsvExport: true,
      getLocaleText: ({ key, defaultValue }) => this.translateService.instant(key, { ns: "ag-grid" }) || defaultValue,
      components: {
        progress: ProgressCellRendererComponent,
        link: LinkCellRendererComponent,
        datepicker: DateCellRendererComponent,
        filesize: FilesizeCellRendererComponent,
        default: DefaultCellRendererComponent,
        loading: LoadingCellRendererComponent,
        action: ActionCellRendererComponent,
        multiselect: MultiselectCellRendererComponent,
        checkbox: CheckboxCellRendererComponent,
        image: ImageCellRendererComponent,
        listInput: ListInputCellRendererComponent,
        header: HeaderRendererComponent,
        cellEditor: EditCellRendererComponent,
      },
      sideBar: this.ma.hideSidebar
        ? undefined
        : {
            toolPanels: [
              {
                id: "columns",
                labelDefault: "Spalten",
                labelKey: "Spalten",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressValues: true,
                  suppressPivots: true,
                  suppressPivotMode: true,
                  suppressColumnFilter: true,
                  suppressColumnSelectAll: true,
                  suppressColumnExpandAll: true,
                },
              },
              "filters",
            ],
          },
      loadingCellRenderer: "loading",
      popupParent: document.querySelector("body"),
      pivotMode: false,
      pivotPanelShow: "never",
      customChartThemes: {
        argon: {
          baseTheme: "ag-pastel",
          palette: {
            fills: [
              getComputedStyle(document.documentElement).getPropertyValue("--charts-color-01"),
              getComputedStyle(document.documentElement).getPropertyValue("--charts-color-02"),
              getComputedStyle(document.documentElement).getPropertyValue("--charts-color-03"),
              getComputedStyle(document.documentElement).getPropertyValue("--charts-color-04"),
              getComputedStyle(document.documentElement).getPropertyValue("--charts-color-05"),
              getComputedStyle(document.documentElement).getPropertyValue("--charts-color-06"),
              getComputedStyle(document.documentElement).getPropertyValue("--charts-color-07"),
              getComputedStyle(document.documentElement).getPropertyValue("--charts-color-08"),
            ],
            strokes: [
              getComputedStyle(document.documentElement).getPropertyValue("--charts-color-01"),
              getComputedStyle(document.documentElement).getPropertyValue("--charts-color-02"),
              getComputedStyle(document.documentElement).getPropertyValue("--charts-color-03"),
              getComputedStyle(document.documentElement).getPropertyValue("--charts-color-04"),
              getComputedStyle(document.documentElement).getPropertyValue("--charts-color-05"),
              getComputedStyle(document.documentElement).getPropertyValue("--charts-color-06"),
              getComputedStyle(document.documentElement).getPropertyValue("--charts-color-07"),
              getComputedStyle(document.documentElement).getPropertyValue("--charts-color-08"),
            ],
          },
        },
      },
      chartThemes: ["argon"],
    };

    if (this.ma.selectedRows) {
      this.selectedRows = this.ma.selectedRows;
    }

    // Check for local data
    if (this.ma.data) {
      this.ma.data.resultSet.map((r, i) => {
        r.displayResultSet = this.ma.data.displayResultSet[i];
        return r;
      });
      this.metaTableService.total.set(this.ma.data.resultSet.length);
    }
    this.columnDefs = (this.field ? this.field.fieldArray["fieldGroup"] : this.ma.columnDefs)
      .sort(function (a, b) {
        return a.sort - b.sort;
      })
      .map((col, i) => {
        const useAgGroupCellRenderer = this.detailCellRendererParams && i === 0;
        const editable = this.ma.editable && col.editable;
        const colDef = {
          field: col.id,
          headerName: col.props.label,
          //headerComponent: "header",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
              '    <span class="ag-header-cell-text" role="columnheader">' +
              col.props.label +
              (editable ? '<i class="fas fa-pen icon-editable"></i>' : "") +
              "</span>" +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          editable,
          cellEditorPopup: true,
          type:
            col.props.filter ||
            (col.props.inputType === "number" || col.props.inputType === "decimal" || col.props.inputType === "currency"
              ? ["numericColumn", "number"]
              : col.type),
          pinned: col.props.sticky,
          filterParams: {
            newRowsAction: "keep",
            browserDatePicker: true,
            comparator: (callValue) => true,
            values: col.props.filter
              ? async (params) => {
                  const result: any = await this.loadFilter(col.id);
                  this.determineMaxHeightOfFiltersRow();
                  const labels: Record<string, any> = {};
                  params.colDef.filterParams["__labels"] = labels;
                  params.success(
                    result.map((r) => {
                      labels[String(r.value)] = r.label;
                      return String(r.value);
                    }),
                  );
                }
              : null,
            valueFormatter: (params) => {
              const labels = params.colDef?.filterParams["__labels"] || {};
              return labels[params.value] || params.value;
            },
          },
          cellRenderer: useAgGroupCellRenderer ? "agGroupCellRenderer" : this.setCellRenderer(col),
          cellRendererParams: {
            field: col.id,
            parentFormId: this.field ? this.metaFormService.formState.formId : null,
            tableId: this._id,
            prefix: col.props.prefix,
            prefixType: col.props.prefixType,
            inputType: col.props.inputType,
            strong: col.props.strong,
            digits: col.props.digits,
            customData: this.ma.customData,
            datepickerType: col.props.datepickerType,
            props: col.props || {},
            ...(() => {
              if (useAgGroupCellRenderer) {
                return {
                  innerRenderer: this.setCellRenderer(col),
                };
              } else {
                return {};
              }
            })(),
          },
          flex: col.props.flex || null,
          minWidth: col.props.minWidth || 50,
          width: col.props.width,
        } as ColDef;

        if (
          (col.props.inputType === "number" ||
            col.props.inputType === "decimal" ||
            col.props.inputType === "currency") &&
          !col.props.alignment
        ) {
          if (Array.isArray(colDef.type)) {
            colDef.type.push("rightAligned");
          } else {
            colDef.type = [colDef.type, "rightAligned"];
          }
        } else if (col.props.alignment) {
          if (Array.isArray(colDef.type)) {
            colDef.type.splice(colDef.type.indexOf("numericColumn"), 1);
            colDef.type.push(`${col.props.alignment}Aligned`);
          } else {
            colDef.type = [colDef.type, `${col.props.alignment}Aligned`];
          }
        }

        if (editable) {
          colDef.cellEditor = "cellEditor";
          colDef.cellEditorParams = { ...col };
        }

        if (useAgGroupCellRenderer) {
          colDef.lockVisible = true;
          colDef.lockPosition = true;
          colDef.suppressMovable = true;
        }
        if (col.type === "meta-datepicker") {
          colDef.filter = "agDateColumnFilter";
        }
        // Check for saved state
        if (!this._hasSavedState) {
          colDef.hide = col.props.hide;
        } else {
          colDef.hide = !!this.gridColumnApi?.getColumnState()[col.id]?.hide;
        }
        // Check if Table is selectable
        if (this.ma.selectable && i === 0) {
          colDef.checkboxSelection = true;
        }
        return colDef;
      });
    if (this.ma.actions.length > 0 || this.ma.deletable || this.ma.editable) {
      this.columnDefs = [
        ...this.columnDefs,
        {
          headerName: "",
          minWidth: this.ma.editable && this.ma.deletable ? 120 : 60,
          width: this.ma.editable && this.ma.deletable ? 120 : 60,
          cellRenderer: "action",
          suppressMenu: true,
          editable: false,
          colId: "_action",
          pinned: "right",
          hide: false,
          cellRendererParams: {
            actionsMenu: this.ma.actions.map((a) => {
              a.size = "small";
              //a.displayData = this.formState.displayData[a.id];
              return a;
            }),
            deletable: this.ma.deletable,
            editable: this.ma.fullEditMode,
            deleteTooltip: this.ma.deleteTooltip,
            tableId: this._id,
            parentFormId: this.field ? this.metaFormService.formState.formId : null,
            onAction: (data: any) => {
              if (this.ma.onEdit instanceof Function) {
                this.ma.onEdit(this.field, data);
              }
            },
            onEdit: async (data: any) => {
              await this._metaActionHandler.executeSocketAction({
                formId: this._id,
                field: null,
                type: "onEdit",
                data: data,
                passthroughData: {
                  formId: this.metaFormService.formState.formId,
                },
              });
              if (this.ma.onEdit instanceof Function) {
                this.ma.onEdit(this.field, data);
              }
              this.refresh();
            },
            onDelete: async (data: any) => {
              if (typeof this.ma.onDelete === "function") {
                this.ma.onDelete(this.field, data);
              } else {
                await this._metaActionHandler.executeSocketAction({
                  formId: this._id,
                  field: null,
                  type: "onDelete",
                  data: data,
                  passthroughData: {
                    formId: this.metaFormService.formState.formId,
                  },
                });
              }
              await this.refresh();
              // Delete row in datasource if its local
              if (this.ma.data) {
                const index = this.ma.data.resultSet.findIndex((r) => r._id.join(",") === data._id.join(","));
                this.ma.data.resultSet.splice(index, 1);
                this.ma.data.displayResultSet.splice(index, 1);
                this.ma.data.total -= 1;
                this.ma.data.skip -= 1;
                this.gridApi.setRowData(this.ma.data.resultSet);
                this.gridApi.refreshClientSideRowModel();
              }
            },
          },
        },
      ];
    }

    this.searchTerm
      .pipe(takeUntil((this as any).destroyed$), skip(1), debounceTime(this.paramDebounceTime))
      .subscribe((value) => {
        if (value !== undefined && value !== null && this.searchString !== value) {
          this.searchString = value;
          this.refresh(true, true);
          this.updateCondition();
        }
      });

    this.metaAppService.app$.pipe(takeUntil((this as any).destroyed$)).subscribe((state) => {
      if (
        (state.scrollState === ScrollStates.aboveEightyPercentBottom ||
          state.scrollState === ScrollStates.OnehundredPercentBottom) &&
        this.metaTableService.state().view === TableView.grid
      ) {
        if (this.init) {
          this.getGridData(0, 100);
          this.init = false;
        } else {
          this.getGridData();
        }
      }
    });
  }

  public getGridData(skipOverride?: number, takeOverride?: number) {
    const take = takeOverride || this.gridTake;
    const skip = skipOverride !== undefined ? skipOverride : (this.ma.condition.skip || 0) + this.ma.condition.take;
    if (this.metaTableService.total() <= skip && skipOverride === undefined && takeOverride === undefined) {
      return;
    }

    this.gridDataLoader = new Array(this.ma.cardLoadingIndicatorCount || takeOverride || this.gridTake).fill({});

    this.isLoading = true;
    this.changeDetectorRef.markForCheck();

    this.ma.condition.skip = skip;
    this.ma.condition.take = take;
    this.ma.condition.searchString = this.searchString;

    this.ma.condition.cacheKey = this.cacheKey;
    this.ma.condition.parentContextId = this.model._ctx;
    if (!this.ma.condition.params) {
      this.ma.condition.params = {};
    }
    const queryParams = this._router.routerState?.root?.snapshot?.queryParams || {};
    for (const name of this.ma.queryParams) {
      if (queryParams[name]) {
        this.ma.condition.params[name] = queryParams[name];
      }
    }

    const condition = {
      ...this.ma.condition,
      group: null,
    };

    condition.params = {
      ...condition.params,
      ...this.additionalDatasourceParams,
    };

    this.metaTableService
      .getData({
        formId: this._id,
        fieldId: this._id,
        condition,
        params: null,
        silentUpdate: true,
      })
      .pipe(takeUntil((this as any).destroyed$))
      .subscribe((result) => {
        const items = this.ma.actions.filter((a) => a.cardPosition === "secondary");
        if (condition.skip === 0) {
          this.metaTableService.total.set(result.total);
        }
        this.gridData.push(...result.displayResultSet);
        for (let i = 0; i < this.gridData.length; i++) {
          const cItems = JSON.parse(JSON.stringify(items));
          for (let j = 0; j < cItems.length; j++) {
            cItems[j].onClick = this.onCardActionClick.bind(this, cItems[j], result.resultSet[i], i);
            cItems[j].disabled = this.gridData[i][cItems[j].id].disabledCondition;
          }
          this.gridData[i]._resultSet = result.resultSet[i];
          this.gridData[i]._gridActionsMenu = {
            icon: "ellipsis-v",
            iconStyle: MetaIconStyle.Solid,
            data: result.resultSet[i],
            items: _.clone(cItems),
            type: "link",
          };
        }
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  public getOffset(element) {
    let top = 0,
      left = 0;
    do {
      top += element.offsetTop || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while (element);

    return {
      top: top,
      left: left,
    };
  }

  public loadConfig() {
    this._setDefaultConditions();
    this.metaTableService.loadTableStateFromStorage(this._id);
    const state: MetaTableState = this.metaTableService.state();
    this.cacheKey = uuid.v4();
    if (state) {
      this._hasSavedState = true;
      if (this.gridColumnApi && this.gridApi) {
        this.gridColumnApi.applyColumnState({ state: state.cols, applyOrder: true });
        this.gridApi.setFilterModel(state.filt);
      }
      this.searchString = state.searchString;
      this.search = state.searchString;
      this.filterForm.patchValue(state.filterBar, { emitEvent: false });
      if (this.gridApi) {
        let instance;
        for (const filtKey in state.filt) {
          if (state.filt[filtKey].filterType === "set") {
            instance = this.gridApi.getFilterInstance(filtKey);
            instance.setModel({ values: state.filt[filtKey].values });
            //this.gridApi.onFilterChanged();
          }
        }
      }
    }
    this.gridRendered = true;
  }

  public async onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // Load config
    this.loadConfig();
    // Load data
    const datasource: IServerSideDatasource = {
      getRows: async (p) => {
        if (Object.keys(p.request.filterModel).length !== Object.keys(this.metaTableService.state().filt).length) {
          return;
        }

        if (this.ma.noInitDataLoad && !this.forcedRefresh) {
          return p.success({
            rowData: [],
            rowCount: 0,
          });
        }
        this.isLoading = true;
        this.changeDetectorRef.markForCheck();
        const queryParams = this._router.routerState?.root?.snapshot?.queryParams || {};
        p.request.filterModel = this.metaTableService.state().filt;
        const condition = this.prepareCondition(p, queryParams);
        condition.params = {
          ...condition.params,
          ...this.additionalDatasourceParams,
        };
        this.metaTableService
          .getData({
            formId: this._id,
            fieldId: this._id,
            condition,
            params: null,
            silentUpdate: true,
          })
          .pipe(takeUntil((this as any).destroyed$))
          .subscribe((result) => {
            if (result.skip === 0) {
              this.metaTableService.total.set(result.total);
            }
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
            return p.success({
              rowData: result.resultSet.map((r, i) => {
                r.displayResultSet = result.displayResultSet[i];
                return r;
              }),
              rowCount: this.metaTableService.total(),
            });
          });
      },
    };
    this.gridApi.setServerSideDatasource(datasource);
    this.filterForm.valueChanges.pipe(takeUntil((this as any).destroyed$)).subscribe({
      next: (value) => {
        this.updateCondition();
      },
    });
  }

  public async ngAfterViewInit() {
    const state: MetaTableState = this.metaTableService.state();

    if (state?.view === TableView.grid) {
      await this.switchToGrid(false);
    } else if (state?.view === TableView.table) {
      await this.switchToTable(false);
    } else {
      switch (this.ma.defaultView) {
        case TableView.table:
          this.switchToTable(false);
          break;
        case TableView.grid:
          await this.switchToGrid(false);
          break;
        default:
          this.switchToTable(false);
      }
    }
  }

  public onStateChange(event: any) {
    this.updateCondition(event.api, event.columnApi, event.type);
  }

  public deselectNode(node: any) {
    this.gridApi.forEachNode((n) =>
      JSON.stringify(n.data._id) === JSON.stringify(node._id) ? n.setSelected(false) : 0,
    );
    this.selectedRows = this.gridApi.getSelectedRows();
  }

  public async onSelectionChange(selection: any) {
    this.selectedRows = this.gridApi.getSelectedRows();
    if (this.ma.onSelectedRowsChange instanceof Function) {
      this.ma.onSelectedRowsChange(this.field, this.selectedRows);
    }
    await this._metaActionHandler.executeSelectAction({
      formId: this.field.id,
      controlId: null,
      data: {
        ...this.model,
        selection: this.selectedRows,
      },
      passthroughData: {
        formId: this.metaFormService.formState.formId,
      },
    });
    const selectedItems = this.metaFormService.formState.selectedItems() || {};
    selectedItems[this._id] = this.selectedRows;
    this.metaFormService.formState.selectedItems.set({ ...selectedItems });
  }

  public async onCellClicked(event: any) {
    if (event.column.colId !== "_action" && this.ma.clickable) {
      await this._metaActionHandler.executeClickAction({
        formId: this.field.id,
        controlId: null,
        data: {
          ...event.data,
        },
        formIsValid: true,
        passthroughData: {
          formId: this.metaFormService.formState.formId,
        },
      });
    }
  }

  public onFilterMod(event: any) {
    return event;
  }

  async loadFilter(id: any) {
    if (this._filterCache[id]) {
      return this._filterCache[id];
    }
    const res = await firstValueFrom(this.metaTableService.getFilterData(this._id, id));
    this._filterCache[id] = res;
    return res;
  }

  determineMaxHeightOfFiltersRow(): void {
    if (this.ma.filter?.length > 0) {
      let height = 0;

      for (const [key, value] of Object.entries(this.filterForm?.controls)) {
        const x = parseInt(value["_fields"][0].props.maxHeight, 10);
        if (x > height) {
          height = x;
        }
      }
      this.maxHeightOfFilterRow = height + 10;
    }
  }

  public getRowNodeId(data) {
    return data._id ? data._id.join(",") : data.data?._id ? data.data._id.join(",") : uuid.v4();
  }

  public refresh = async (purge = false, keepFilter = false, clearSelection = false) => {
    if (!keepFilter && this.ma.filter?.length > 0) {
      const result: any = await this.metaFormService.getInitializationObject(this.formState.formId);
      this.filterForm.reset(result.result, { emitEvent: false });
    }
    if (this.metaTableService.state().view === TableView.table) {
      if (this.gridApi) {
        this.forcedRefresh = true;
        this.cacheKey = uuid.v4();
        this.gridApi.refreshServerSide({ purge });
      }
    } else if (this.metaTableService.state().view === TableView.grid) {
      this.gridData = [];
      this.getGridData(0, 100);
    }
    if (clearSelection) {
      this.gridApi?.deselectAll();
    }
  };

  public resetFilters = async () => {
    if (document.activeElement.tagName !== "BUTTON") {
      return;
    }
    this.metaTableService.setTableState(this._id, {
      searchString: "",
      filt: [],
      filterBar: {},
    });
    this.search = "";
    this.searchString = "";
    this.updateCondition();

    if (this.gridApi) {
      this.gridApi.setFilterModel(null);
      //this.gridApi.setSortModel(null);
    }
    await this.refresh(true);
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  };

  public cellValueChanged(event: CellValueChangedEvent): void {
    const newData = {
      ...event.data,
      displayResultSet: null,
    };
    const oldData = {
      ...newData,
      [event.colDef.field]: event.oldValue,
    };
    firstValueFrom(
      this.metaTableService.updateData({
        formId: this.field.id,
        itemId: newData["_id"],
        oldData,
        newData,
        publish: false,
      }),
    ).then(async (e) => {
      const newItem = {
        ...e.result,
        displayResultSet: e.displayResult,
      };
      this.gridApi.forEachNode((rowNode) => {
        if (JSON.stringify(rowNode.data?._id) === JSON.stringify(newItem._id)) {
          rowNode.setData(newItem);
        }
      });
    });
  }

  public updateCondition(api?: GridApi, columnApi?: ColumnApi, type?: string) {
    if (!this.gridRendered) {
      return;
    }

    const gridApi = api || this.gridApi;
    const gridColumnApi = columnApi || this.gridColumnApi;

    let config: any = {
      searchString: this.searchString,
      filterBar: this.filterForm?.value,
      view: this.metaTableService.state().view,
      filt: this.metaTableService.state().filt,
    };

    if (gridApi && gridColumnApi) {
      config.cols = gridColumnApi.getColumnState();

      if (type === "filterChanged") {
        const filter = gridApi.getFilterModel();
        if (filter && Object.keys(filter).length > 0) {
          config.filt = filter;
        } else {
          config.filt = {};
        }
      }
    }

    if (type === "columnResized" || type === "sortChanged") {
      config.cols = gridColumnApi.getColumnState();
    }

    this.metaTableService.setTableState(this._id, config);
  }

  public async exportToFile(format = "xlsx") {
    let condition = JSON.parse(JSON.stringify(this.ma.condition));
    delete condition.columns;
    condition = {
      ...condition,
      format,
    };
    this.isExportingToExcel = true;
    const resp = await this.metaTableService.exportToExcel(this._id, condition);
    const downloadLink = document.createElement("a");
    const objectUrl = window.URL.createObjectURL(resp.body);
    downloadLink.href = objectUrl;

    const re = /filename="(.*?)"/i;
    const filename = re.exec(String(resp.headers.get("content-disposition")))[1];
    downloadLink.setAttribute("download", filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    this.isExportingToExcel = false;
    this.changeDetectorRef.markForCheck();
    setTimeout(() => {
      window.URL.revokeObjectURL(objectUrl);
    }, 2500);
  }

  public onRowGroupOpened($event: RowGroupOpenedEvent) {
    if (!$event.node.expanded || $event.node.group) return;
    if (this.detailOpenedRow && this.detailOpenedRow !== $event.node) {
      this.detailOpenedRow.setExpanded(false);
    }
    this.detailOpenedRow = $event.node;
  }

  public maximizeWindow(): void {
    if (this.ma.url) {
      this._router.navigate([this.ma.url]);
    }
  }

  public async addEntry() {
    const result = await this._metaActionHandler.executeSocketAction({
      formId: this._id,
      field: null,
      type: "onCreate",
      data: this.formState.data(),
      passthroughData: {
        formId: this.metaFormService.formState.formId,
      },
    });
    // TODO: @Peter - Check why result is undefined
    await this.refresh();
  }

  public async switchToGrid(updateCondition = true) {
    this.gridTake = 100;
    this.selectedRows = [];
    this.gridData = [];
    this.changeDetectorRef.markForCheck();

    this.gridMainActions = this.ma.actions.filter((a) => a.cardPosition === "primary");
    this.loadConfig();
    const state = this.metaTableService.state();
    state.view = TableView.grid;
    this.metaTableService.state.set(state);
    this.field.parent.wrappers = [];
    this.getGridData(0, 100);
    if (updateCondition) {
      this.updateCondition();
    }
  }

  public switchToTable(updateCondition = true) {
    this.gridTake = 10;
    const state = this.metaTableService.state();
    state.view = TableView.table;
    this.metaTableService.state.set(state);
    if (updateCondition) {
      this.updateCondition();
    }
  }

  public trackById(index, item) {
    return item._id;
  }

  public onCardActionClick(action: MetaButton, item: any, index?: number) {
    if (
      action.disabledCondition &&
      this.gridData[index][action.id] &&
      this.gridData[index][action.id].disabledCondition !== undefined
        ? this.gridData[index][action.id].disabledCondition
        : false
    ) {
      return;
    }
    if (index !== undefined) {
      this.gridActionsLoading[index] = true;
    }

    this._metaActionHandler
      .executeClickAction({
        controlId: action.id,
        formId: this._id,
        data: {
          ...item,
        },
        formIsValid: true,
        passthroughData: {
          formId: this.metaFormService.formState.formId,
        },
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        if (index !== undefined) {
          this.gridActionsLoading[index] = false;
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  public onResize({ col }: NzResizeEvent): void {
    cancelAnimationFrame(this.colId);
    this.colId = requestAnimationFrame(() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.col = col!;
    });
    this.metaAppService.setFormSettings(this._id, { col });
  }

  // Helper function to prepare condition
  private prepareCondition(p, queryParams) {
    this.ma.condition.skip = p.request.startRow;
    this.ma.condition.take = this.paramTake;
    this.ma.condition.sort = p.request.sortModel.map((sort) => ({ field: sort.colId, dir: sort.sort }));
    this.ma.condition.group = p.request.rowGroupCols.map((g) => ({ aggregates: [], dir: "asc", field: g.field }));
    if (this.ma.showAsTreeview) {
      this.ma.condition.params = {
        ...this.ma.condition.params,
        [this.ma.treeViewParamField]: p.request.groupKeys[0],
      };
    } else {
      this.ma.condition.groupKeys = p.request.groupKeys;
    }
    this.ma.condition.searchString = this.searchString;
    this.ma.condition.filter = this._createFilter(p.request.filterModel);
    this.ma.condition.cacheKey = this.cacheKey;
    this.ma.condition.parentContextId = this.model._ctx;
    if (!this.ma.condition.params) {
      this.ma.condition.params = {};
    }
    for (const name of this.ma.queryParams) {
      if (queryParams[name]) {
        this.ma.condition.params[name] = queryParams[name];
      }
    }
    return this.ma.condition;
  }

  private _createFilter(filterModel: any): any {
    const filter = {
      filters: [],
      logic: "and",
    };

    // Loop through filters
    for (const f in filterModel) {
      // eslint-disable-next-line no-prototype-builtins
      if (filterModel.hasOwnProperty(f)) {
        switch (filterModel[f].filterType) {
          case "set":
            filter.filters.push({
              logic: "or",
              filters:
                filterModel[f].values.length === 0
                  ? [
                      {
                        field: f,
                        operator: "eq",
                        value: null,
                      },
                    ]
                  : filterModel[f].values.map((v) => ({
                      field: f,
                      operator: "eq",
                      value: v === "null" ? null : v,
                    })),
            });
            break;
          case "date":
            if (filterModel[f].condition1) {
              filter.filters.push({
                logic: filterModel[f].operator.toLowerCase(),
                filters: [
                  {
                    field: f,
                    operator: this.filter[filterModel[f].condition1.type],
                    value:
                      filterModel[f].condition1.type === "inRange"
                        ? [filterModel[f].condition1.dateFrom, filterModel[f].condition1.dateTo]
                        : filterModel[f].condition1.dateFrom,
                  },
                  {
                    field: f,
                    operator: this.filter[filterModel[f].condition2.type],
                    value:
                      filterModel[f].condition2.type === "inRange"
                        ? [filterModel[f].condition2.dateFrom, filterModel[f].condition2.dateTo]
                        : filterModel[f].condition2.dateFrom,
                  },
                ],
              });
            } else {
              if (this.filter[filterModel[f].type] === "eq") {
                filter.filters.push({
                  field: f,
                  operator: "inRange",
                  value: [
                    moment(filterModel[f].dateFrom).startOf("day").toDate(),
                    moment(filterModel[f].dateFrom).endOf("day").toDate(),
                  ],
                });
              } else {
                filter.filters.push({
                  field: f,
                  operator: this.filter[filterModel[f].type],
                  value:
                    filterModel[f].type === "inRange"
                      ? [
                          moment(filterModel[f].dateFrom).endOf("day").toDate(),
                          moment(filterModel[f].dateTo).endOf("day").toDate(),
                        ]
                      : moment(filterModel[f].dateFrom).endOf("day").toDate(),
                });
              }
            }
            break;
          case "number":
          case "text":
            if (filterModel[f].condition1) {
              filter.filters.push({
                logic: filterModel[f].operator.toLowerCase(),
                filters: [
                  {
                    field: f,
                    operator: this.filter[filterModel[f].condition1.type],
                    value:
                      filterModel[f].condition1.type === "inRange"
                        ? [filterModel[f].condition1.filter, filterModel[f].condition1.filterTo]
                        : filterModel[f].condition1.filter,
                  },
                  {
                    field: f,
                    operator: this.filter[filterModel[f].condition2.type],
                    value:
                      filterModel[f].condition2.type === "inRange"
                        ? [filterModel[f].condition2.filter, filterModel[f].condition2.filterTo]
                        : filterModel[f].condition2.filter,
                  },
                ],
              });
            } else {
              filter.filters.push({
                field: f,
                operator: this.filter[filterModel[f].type],
                value:
                  filterModel[f].type === "inRange"
                    ? [filterModel[f].filter, filterModel[f].filterTo]
                    : filterModel[f].filter === "null"
                      ? null
                      : filterModel[f].filter,
              });
            }
            break;
        }
      }
    }
    return filter;
  }

  private _setDefaultConditions() {
    if (!this.ma.condition.sort || !this.gridColumnApi) {
      return;
    }

    const columnState = this.gridColumnApi.getColumnState();
    this.gridColumnApi.applyColumnState({
      state: columnState.map((state, i) => {
        const find = this.ma.condition.sort.find((s) => s.field === state.colId);
        if (find) {
          state.sort = find.dir;
          state.sortIndex = i;
        }
        return state;
      }),
    });
  }

  private setCellRenderer(col: any) {
    if (col.type === "meta-progress") {
      return "progress";
    }
    if (col.type === "meta-datepicker") {
      return "datepicker";
    }
    if (col.props.inputType === "filesize") {
      return "filesize";
    }
    if (col.props.link) {
      return "link";
    }
    if (col.type === "meta-multiselect") {
      return "multiselect";
    }
    if (col.type === "meta-checkbox") {
      return "checkbox";
    }
    if (col.type === "meta-image-gallery") {
      return "image";
    }
    if (col.type === "meta-list-input") {
      return "listInput";
    }
    return "default";
  }
}

@NgModule({
  declarations: [
    MetatableComponent,
    DefaultCellRendererComponent,
    DateCellRendererComponent,
    ProgressCellRendererComponent,
    ListInputCellRendererComponent,
    FilesizeCellRendererComponent,
    CheckboxCellRendererComponent,
    MultiselectCellRendererComponent,
    ActionCellRendererComponent,
    LoadingCellRendererComponent,
    ImageCellRendererComponent,
    LinkCellRendererComponent,
    DetailRendererComponent,
    HeaderRendererComponent,
    EditCellRendererComponent,
    ColumnColorDirective,
  ],
  imports: [
    CommonModule,
    FormlyModule,
    MetaInputModule,
    MetaButtonModule,
    FormsModule,
    NzToolTipModule,
    AgGridModule,
    MetaLoaderModule,
    PipesModule,
    MetaProgressModule,
    MetaTagModule,
    MetaDrodpownModule,
    MetaSkeletonModule,
    RouterModule,
    NzGridModule,
    MetaFieldWrapperModule,
    MetaTooltipModule,
    MetaAvatarModule,
    MetaSectionModule,
    MetaGroupModule,
    MetaIconModule,
    NzResizableModule,
    MetaEmptyModule,
  ],
  exports: [MetatableComponent],
})
export class MetaTableModule {}
