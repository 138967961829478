/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */



export interface IUpdateManifest {
  latest: string;
  versions: IUpdateManifestVersion[]
}

export interface IUpdateManifestFile {
  id: string;
  platform: any;
  arch: any;
  md5: string;
  sig: string;
}

export enum SQL_SERVER_VERSION {
  "SQL_2016" = 13,
  "SQL_2017" = 14,
  "SQL_2019" = 15
}

export interface IUpdateManifestVersion {
  id: string;
  version: string;
  ref: string;
  minSqlVersion: SQL_SERVER_VERSION;
  files: IUpdateManifestFile[];
}
