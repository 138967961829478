import { TemplatePortal } from "@angular/cdk/portal";
import { DestroyRef, Injectable, signal } from "@angular/core";

export type ToolbarTargetType = "global" | string;

export class MetaFormHeaderTarget {
  public readonly actionsPortals = signal<TemplatePortal[]>([]);
  public readonly labelPortals = signal<TemplatePortal[]>([]);
  public readonly sublabelPortals = signal<TemplatePortal[]>([]);

  public attachActionsPortal(portal: TemplatePortal, destroyRef: DestroyRef, target: ToolbarTargetType) {
    this.actionsPortals.set([...this.actionsPortals(), portal]);
    destroyRef.onDestroy(() => this.detachActionsPortal(portal));
  }

  public detachActionsPortal(portal: TemplatePortal) {
    this.actionsPortals.set(this.removeFromArray(this.actionsPortals(), portal));
  }

  public attachLabelPortal(portal: TemplatePortal, destroyRef: DestroyRef, target: ToolbarTargetType) {
    this.labelPortals.set([...this.labelPortals(), portal]);
    destroyRef.onDestroy(() => this.detachLabelPortal(portal));
  }

  public detachLabelPortal(portal: TemplatePortal) {
    this.labelPortals.set(this.removeFromArray(this.labelPortals(), portal));
  }

  public attachSublabelPortal(portal: TemplatePortal, destroyRef: DestroyRef, target: ToolbarTargetType) {
    this.sublabelPortals.set([...this.sublabelPortals(), portal]);
    destroyRef.onDestroy(() => this.detachSublabelPortal(portal));
  }

  public detachSublabelPortal(portal: TemplatePortal) {
    this.sublabelPortals.set(this.removeFromArray(this.sublabelPortals(), portal));
  }

  private removeFromArray(portals: TemplatePortal[], portalToRemove: TemplatePortal) {
    const currentPortals = [...portals];
    const index = currentPortals.indexOf(portalToRemove);
    if (index !== -1) {
      currentPortals.splice(index, 1);
    }
    return currentPortals;
  }
}

@Injectable({
  providedIn: "root",
})
export class MetaFormHeaderService {
  public targets = signal<Record<ToolbarTargetType, MetaFormHeaderTarget>>({});

  public attachSublabelPortal(portal: TemplatePortal, destroyRef: DestroyRef, target: ToolbarTargetType) {
    this.updateTarget(target, (t) => {
      t.sublabelPortals.set([...t.sublabelPortals(), portal]);
      destroyRef.onDestroy(() => t.detachSublabelPortal(portal));
    });
  }

  public attachLabelPortal(portal: TemplatePortal, destroyRef: DestroyRef, target: ToolbarTargetType) {
    this.updateTarget(target, (t) => {
      t.labelPortals.set([...t.labelPortals(), portal]);
      destroyRef.onDestroy(() => t.detachLabelPortal(portal));
    });
  }

  public attachActionsPortal(portal: TemplatePortal, destroyRef: DestroyRef, target: ToolbarTargetType) {
    this.updateTarget(target, (t) => {
      t.actionsPortals.set([...t.actionsPortals(), portal]);
      destroyRef.onDestroy(() => t.detachActionsPortal(portal));
    });
  }

  private updateTarget(target: ToolbarTargetType, handler: (target: MetaFormHeaderTarget) => void) {
    let existingTarget = this.targets()?.[target];
    if (!existingTarget) {
      existingTarget = new MetaFormHeaderTarget();
    }
    handler(existingTarget);
    this.targets.set({
      ...this.targets(),
      [target]: existingTarget,
    });
    return existingTarget;
  }
}
