/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { NzModalRef } from "ng-zorro-antd/modal";
import { NzResizeEvent } from "ng-zorro-antd/resizable";
import { firstValueFrom, Subject, takeUntil } from "rxjs";
import { SpecificationObject } from "../../../components/metaSpecifications/metaSpecifications.service";
import { DirtyCheckService } from "../../../services/dirtyCheckService";
import { MetaAppService } from "../../../services/metaApp.service";
import { MetaEventService } from "../../../services/metaEvents.service";
import { MetaHelperService } from "../../../services/metaHelper.service";
import { MetaFormService } from "../metaForm.service";

@Component({
  selector: "meta-form-splitview",
  template: `
    <formly-form
      [hidden]="true"
      [form]="metaFormService.formState.formGroup()"
      [fields]="metaFormService.formState.form().children"
      [model]="metaFormService.formState.data()"
      [options]="metaFormService.options"
      (ngModelChange)="metaFormService.formState.data.set($event)"
    ></formly-form>
    @if (master && detail) {
      <div nz-row [style.flex]="1">
        <div
          class="col"
          nz-col
          nz-resizable
          (nzResize)="onResize($event)"
          [nzMinColumn]="3"
          [nzMaxColumn]="12"
          [nzGridColumnCount]="24"
          [nzSpan]="col"
        >
          <div class="split-view-wrapper" [ngClass]="{ modal: metaFormService.formState.loadedAsModal }">
            <nz-resize-handles class="resize-handle" [nzDirections]="['right']"></nz-resize-handles>
            <formly-field [field]="master"></formly-field>
          </div>
        </div>
        <div class="split-view-content" nz-col [nzSpan]="24 - col">
          @if (metaFormService.formState.itemId()) {
            <ng-container *ngFor="let component of detail.fieldGroup; let i = index; let last = last">
              <formly-field [field]="component"></formly-field>
            </ng-container>
          } @else {
            <meta-empty
              class="no-selection"
              [maParams]="{
                description: 'Du hast noch nichts ausgewählt',
                icon: 'solar-system'
              }"
            ></meta-empty>
          }
        </div>
      </div>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaFormSplitviewComponent implements OnInit, OnDestroy {
  public master: FormlyFieldConfig;
  public detail: FormlyFieldConfig;
  public col: number = 8;
  colId = -1;
  @Input()
  public onSelectItem: (field: string, item: any) => void;
  public replaceUrl = false;
  public readonly isModal: boolean;
  private _destroyed$ = new Subject();

  constructor(
    private readonly _metaAppService: MetaAppService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _dirtyCheckService: DirtyCheckService,
    private readonly _metaEventService: MetaEventService,
    public readonly metaFormService: MetaFormService,
    _metaHelperService: MetaHelperService,
    @Optional()
    private readonly _modalRef: NzModalRef,
  ) {
    this.isModal = !!this._modalRef;
  }

  ngOnInit() {
    const listComponent = this.metaFormService.formState
      .form()
      .children[0]["fieldGroup"].find((f) => f.type === "meta-list");
    const pageComponent = this.metaFormService.formState
      .form()
      .children[0]["fieldGroup"].find((f) => f.type === "meta-split-view-detail");
    pageComponent.props.createable = this.metaFormService.formState.form().creatable;
    listComponent.props = {
      ...listComponent.props,
      editable: false,
      deletable: false,
      creatable: this.metaFormService.formState.form().creatable,
      selectable: false,
      orderable: false,
      sortable: true,
      markLockedItems: true,
      virtualScroll: true,
      detail: pageComponent,
      height: "auto",
      layoutTemplateRows: 1,
      onSelect: this._onSelectItem,
      onCreate: this.onCreateItem,
      afterCreate: this.afterCreateItem,
      label: null,
      splitViewMode: true,
    };
    listComponent.props.condition.take = 100;
    this.master = listComponent;
    this.detail = pageComponent;

    this._metaEventService.saveTrigger$.pipe(takeUntil(this._destroyed$)).subscribe({
      next: async (opts) => {
        if (
          opts.itemId !== this.metaFormService.formState.itemId() &&
          opts.formId === this.metaFormService.formState.formId
        ) {
          await this._onSelectItem(null, { _id: opts.itemId.split(",") });
        }
      },
    });
  }

  public ngOnDestroy() {
    this._destroyed$.next(null);
    this._destroyed$.unsubscribe();
  }

  public onResize({ col }: NzResizeEvent): void {
    cancelAnimationFrame(this.colId);
    this.colId = requestAnimationFrame(() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.col = col!;
    });
    this._metaAppService.setFormSettings(this.metaFormService.formState.formId, { col });
  }

  public _onSelectItem = async (field, item) => {
    let success = false;
    if (this._modalRef || this.metaFormService.formState.loadedAsSubform) {
      if (this._dirtyCheckService.isDirty(this._modalRef)) {
        if (!(await firstValueFrom(this._dirtyCheckService.showDirtyAlert(this._modalRef)))) {
          return;
        }
      }
      success = true;
      if (item._id) {
        await this.metaFormService.getFormData({
          itemId: item._id.join(","),
          silent: false,
        });
      }
    } else {
      const url: string[] = ["/", this.metaFormService.formState.formId, item.callbackId || item._id.join(",")];
      success = await this._router.navigate(url, {
        replaceUrl: this.replaceUrl,
        queryParamsHandling: "merge",
        queryParams: this._activatedRoute.snapshot.queryParams.t !== undefined ? { t: 0 } : {},
      });
    }
    if (success) {
      if (this.onSelectItem) {
        this.onSelectItem(field, item);
        this._changeDetectorRef.markForCheck();
      }
    }
  };

  public onCreateItem = async (field, item) => {
    if (this._modalRef || this.metaFormService.formState.loadedAsSubform) {
      this.metaFormService.getFormData({
        itemId: "create",
        silent: false,
      });
    } else {
      const url: string[] = ["/", this.metaFormService.formState.formId, "create"];
      await this._router.navigate(url, {
        replaceUrl: this.replaceUrl,
        queryParamsHandling: "merge",
        queryParams: this._activatedRoute.snapshot.queryParams.t !== undefined ? { t: 0 } : {},
      });
    }
  };

  public afterCreateItem = (field, id) => {
    this._onSelectItem(field, { _id: id });
  };
}
