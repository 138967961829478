/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ValidatableComponent } from "./validatableComponent";
import { ExposeProperty, MetaFormComponentInfo } from "./baseComponent";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-checkbox",
  type: "checkbox",
  name: "Checkbox",
  icon: "check-square",
  palletGroup: "input",
})
export class CheckboxComponent extends ValidatableComponent {
  @ExposeProperty() public variant: "checkbox" | "switch" = "checkbox";
  @ExposeProperty() public hideYesNoLabel = false;
  @ExposeProperty() public uniqueGroup?: string;

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        variant: this.variant,
        hideYesNoLabel: this.hideYesNoLabel,
        uniqueGroup: this.uniqueGroup,
        onChange: !!this.onChange,
      },
    });
  }
}
