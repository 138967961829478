/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { KeyValue } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  Pipe,
  PipeTransform,
  ViewChild,
} from "@angular/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { SearchService } from "./search.service";
import { BehaviorSubject, debounceTime, firstValueFrom, merge, Observable, of, switchMap } from "rxjs";
import { FormControl, FormGroup } from "@angular/forms";
import { catchError, filter, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { TranslateService } from "@tolgee/ngx";

@Pipe({ name: "facetedToSelect", pure: true })
export class SearchFacetedToSelect implements PipeTransform {
  constructor() {}
  transform(data: Record<string, number>) {
    if (!data) {
      return [];
    }
    return Object.entries(data).map(([tag, count]) => {
      return {
        label: `${tag} (${count})`,
        value: tag,
      };
    });
  }
}

@Pipe({ name: "filterDisplay", pure: true })
export class SearchFilterDisplay implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}
  transform(data: any[], placeholder = true) {
    if (data && data.length > 0) {
      const t = [...data];
      const trans = t.map((e) =>
        this.translateService.instant(`entities.${String(e.value).toLowerCase()}.name_plural`),
      );
      return this.translateService.instant(`search.placeholder_no_filter`, { product: trans.join(", ") });
    }
    return placeholder ? this.translateService.instant("search.placeholder_no_filter", { product: "Argon" }) : "";
  }
}

@Component({
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements AfterViewInit, OnDestroy {
  public result$: Observable<any>;
  public searching$ = new BehaviorSubject<boolean>(false);

  public readonly form: FormGroup;

  public readonly types$: Observable<any[]>;

  public selectedIndex = 0;
  public tagFiltering = true;
  public pageIndex = 1;
  public hitsPerPage = 5;

  public showProperties = false;

  private result: any[] = [];

  @ViewChild("inputElement", { static: true })
  private inputElement?: ElementRef<HTMLInputElement>;

  private static lastFormValue: any;
  public compareWith(a, b) {
    return a === b || a?.value === b?.value;
  }
  private static sessionStorageLastSearchKey = "search.lastSearch";
  private static sessionStorageShowPropertiesKey = "search.showProperties";

  public facetDistribution: any;
  public showSidebar = false;
  public compareFn(a: KeyValue<string, number>, b: KeyValue<string, number>) {
    return b.value - a.value;
  }

  public trackById(index: number, item: any) {
    return item.id;
  }

  constructor(
    public readonly searchService: SearchService,
    private readonly router: Router,
    private readonly nzMessageService: NzMessageService,
  ) {
    if (localStorage["searchHitsPerPage"]) {
      this.hitsPerPage = parseInt(localStorage["searchHitsPerPage"], 10);
    }
    this.showSidebar = localStorage["search.showSidebar"] === "true";
    const lastSearch = sessionStorage[SearchComponent.sessionStorageLastSearchKey]
      ? JSON.parse(sessionStorage[SearchComponent.sessionStorageLastSearchKey])
      : null;

    this.showProperties = sessionStorage[SearchComponent.sessionStorageShowPropertiesKey] === "true";

    this.form = new FormGroup(
      {
        q: new FormControl(lastSearch?.q || ""),
        categories: new FormControl(lastSearch?.categories || []),
        tags: new FormControl(lastSearch?.tags || []),
      },
      {
        updateOn: "change",
      },
    );
    this.types$ = this.searchService.types$;
    this.result$ = merge(this.form.valueChanges, of(lastSearch).pipe(filter((e) => !!e))).pipe(
      tap(() => {
        this.searching$.next(true);
      }),
      debounceTime(150),
      switchMap(({ categories, q, tags }) =>
        this.searchService.search(
          q,
          categories.map((c) => c.value),
          tags.map((e) => (typeof e === "string" ? e : e.value)),
          this.pageIndex,
          this.hitsPerPage,
        ),
      ),
      catchError(() => {
        return null;
      }),
      tap((r) => {
        this.searching$.next(false);
        this.result = r ? r.result : [];
        this.facetDistribution = r ? r.facetDistribution : null;
        this.selectedIndex = 0;
        this.selectItem(0);
      }),
    );
  }

  ngOnDestroy(): void {
    sessionStorage.setItem(SearchComponent.sessionStorageShowPropertiesKey, JSON.stringify(this.showProperties));
    sessionStorage.setItem(SearchComponent.sessionStorageLastSearchKey, JSON.stringify({ ...this.form.value }));
  }

  public handleClose() {
    const control = this.form.get("q");
    if (String(control.value).length === 0) {
      return true;
    }
    this.form.get("q").setValue("");
    return false;
  }

  public ngAfterViewInit() {
    this.inputElement?.nativeElement.focus();
    this.inputElement?.nativeElement.select();
  }

  public openFocused() {
    const item = this.result[this.selectedIndex];
    if (item.link) {
      this.router.navigateByUrl(item.link);
      this.searchService.hideSearch();
    } else {
      this.itemClick(item);
    }
  }

  public itemClick(item: any) {
    if (item.type === "category") {
      this.form.get("categories").setValue([item.item]);
      this.form.get("q").setValue("");
    }
  }

  public close() {
    this.searchService.hideSearch();
  }

  public selectItem(number: number, $event?: KeyboardEvent) {
    $event?.preventDefault();
    $event?.stopPropagation();
    this.selectedIndex += number;
    if (this.selectedIndex >= this.result.length) {
      this.selectedIndex = 0;
    } else if (this.selectedIndex < 0) {
      this.selectedIndex = this.result.length - 1;
    }
    document.querySelector(`.search-container li:nth-child(${this.selectedIndex + 1})`)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  public toggleFilterOptions() {}

  public tagClick($event: MouseEvent, tag: string) {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.tagFiltering) {
      const control = this.form.get("tags");
      control.setValue([...control.value, tag]);
    } else {
      const control = this.form.get("q");
      control.setValue(`${control.value || ""} "${tag}"`.trim());
    }
  }

  public pageIndexChange($event: number) {
    this.form.patchValue({});
  }

  public pageSizeChange() {
    this.pageIndex = 1;
    this.form.patchValue({});
    localStorage["searchHitsPerPage"] = `${this.hitsPerPage}`;
  }

  public toggleTagFilter(key: string) {
    const tags = [...this.form.controls.tags.value];
    const index = tags.indexOf(key);
    if (index === -1) {
      tags.push(key);
    } else {
      tags.splice(index, 1);
    }
    this.form.controls.tags.patchValue(tags);
  }

  public toggleFavorite(item: Record<string, any>) {
    item.favorite = !item.favorite;
    firstValueFrom(this.searchService.toggleFavorite(item.id, item.type, !item.favorite))
      .catch(() => {
        item.favorite = !item.favorite;
        this.nzMessageService.success(`Fehler beim hinzufügen zu den Favoriten.`);
      })
      .then(() => {
        if (item.favorite) {
          this.nzMessageService.success(`"${item.titel}" wurde zu deinen Favoriten hinzugefügt.`);
        } else {
          this.nzMessageService.success(`"${item.titel}" wurde von deinen Favoriten entfernt.`);
        }
      });
  }
}
