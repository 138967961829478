/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ValidatableComponent } from "./validatableComponent";
import { Exclude, Expose } from "class-transformer";
import {
  DataSourceParameters,
  ExposeProperty,
  MetaFormComponentInfo,
  MetaFormComponentProperty,
} from "./baseComponent";
import { IsOptional } from "class-validator";
import type { FormEventActionHandler } from "../interfaces";
import * as _ from "lodash";
import type { DataSourceType } from "../../../database/src/lib/types";
import { MetaSelectType } from "../../../api-interfaces/src";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-select",
  type: "select",
  name: "Dropdown",
  icon: "list-dropdown",
  palletGroup: "input",
})
export class MetaFormSelectComponent<DataSource = {}, SelectDataSource = {}> extends ValidatableComponent {
  /**
   * Sets the data item field that represents the item text.
   */
  @MetaFormComponentProperty({
    name: "Select Label Feld",
    description: "Sets the data item field that represents the item text.",
    type: "path",
    datasourceField: "selectDataSource",
    tab: "data",
    required: true,
  })
  public selectLabelField: string;
  /**
   * Sets the data item field that represents the locked state of an item
   */
  @MetaFormComponentProperty({
    name: "Select Locked Field",
    type: "path",
    tab: "data",
    datasourceField: "selectDataSource",
    description: "Sets the data item field that represents the locked state of an item",
    required: true,
  })
  public selectLockedField = "Sperre";
  /**
   * Sets the data item field that represents the item text.
   */
  @MetaFormComponentProperty({
    name: "Select Label Template",
    type: "text",
    tab: "data",
    isTemplate: true,
    description: "Sets the data item field that represents the item text.",
    required: false,
  })
  public selectLabelDisplayValue: string;

  @MetaFormComponentProperty({
    name: "Select Group Template",
    type: "text",
    tab: "data",
    isTemplate: true,
    description: "Sets the data item field that represents the item text.",
    required: false,
  })
  public selectGroupDisplayValue: string;

  /**
   * Sets the data item field that represents the item value.
   */
  @MetaFormComponentProperty({
    name: "Select Wert Feld",
    type: "path",
    datasourceField: "selectDataSource",
    tab: "data",
    description: "Sets the data item field that represents the item value.",
    required: true,
  })
  public selectValueField: string;

  @MetaFormComponentProperty({
    name: "Select Gruppen Feld",
    type: "path",
    datasourceField: "selectDataSource",
    tab: "data",
    required: false,
  })
  public selectGroupField: string;

  @MetaFormComponentProperty({
    name: "Select Sortierung Feld",
    type: "path",
    datasourceField: "selectDataSource",
    tab: "data",
    required: false,
  })
  public selectSortField: string;

  @MetaFormComponentProperty({
    name: "Select Sortierung",
    type: "select",
    tab: "data",
    default: "asc",
    values: [
      { value: "asc", label: "Aufsteigend" },
      { value: "desc", label: "Absteigend" },
    ],
    required: false,
  })
  public selectSortDirection: "asc" | "desc" = "asc";

  @ExposeProperty() public selectParentValueField?: SelectDataSource extends (infer T)[]
    ? keyof T
    : keyof SelectDataSource;
  @IsOptional()
  public selectDataSourceFilter?: string;

  @MetaFormComponentProperty({
    name: "On Select",
    type: "action",
    tab: "hooks",
  })
  public onSelect?: FormEventActionHandler;
  @MetaFormComponentProperty({
    name: "On Click",
    type: "action",
    tab: "hooks",
  })
  public onClick?: FormEventActionHandler;

  /**
   * Gets called before the select data list is loaded
   */
  @MetaFormComponentProperty({
    name: "Before Open",
    type: "action",
    tab: "hooks",
  })
  public onBeforeOpen?: FormEventActionHandler;
  @MetaFormComponentProperty({
    type: "boolean",
    default: false,
    name: "Multiple",
    description: "Allows the selection of multiple elements.",
  })
  public multiple? = false;

  @ExposeProperty()
  public uniqueGroup?: string;

  /**
   * Sets the data source used for the select box
   */
  @MetaFormComponentProperty({
    name: "Select Datenquelle",
    type: "dataSource",
    description: "The data source to be used to retrieve the data.",
    tab: "data",
    required: true,
  })
  public selectDataSource: DataSourceType;

  @ExposeProperty()
  public dropdownMinWidth: number;

  @MetaFormComponentProperty({
    type: "boolean",
    default: true,
    name: "clearable",
    description: "If true, the selected value cannot be cleared (nulled)",
  })
  public clearable: boolean;

  public selectDataSourceParams: DataSourceParameters;

  @ExposeProperty()
  public selectType?: MetaSelectType = MetaSelectType.single;

  /**
   * The Form used to edit the entries
   */
  @ExposeProperty() public editForm?: string;

  @ExposeProperty() public icon?: string;

  @ExposeProperty() public input?: string;

  @ExposeProperty()
  public getAllKeys? = false;

  @ExposeProperty()
  public autoFillOnChange?: {
    [key: string]: string;
  } = {};

  @ExposeProperty() public loadDataOnInit?: boolean;

  public toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        link: this.link,
        mode: this.selectType,
        onSelect: !!this.onSelect,
        onClick: !!this.onClick,
        onBeforeOpen: !!this.onBeforeOpen,
        editForm: this.editForm,
        loadDataOnInit: this.loadDataOnInit,
        dropdownMinWidth: this.dropdownMinWidth,
        filter: this.filter || "select",
        selectGroupField: this.selectGroupField,
        clearable: this.clearable,
        reloadDataOnOpen:
          !!this.onBeforeOpen || Object.keys(this.selectDataSourceParams?.primaryResultParams || {}).length > 0,
      },
    });
  }
}
