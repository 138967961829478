/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { UserService } from "../../../../../libs/ui/src/lib/services/user.service";
import { firstValueFrom } from "rxjs";
import { environment } from "../../environments/environment";
import { MetaModalService } from "../../../../../libs/ui/src/lib/services/metaModalService";

@Injectable({
  providedIn: "root",
})
export class IsAuthenticatedGuard implements CanActivate {
  private changelogShown = false;
  constructor(
    private userService: UserService,
    private router: Router,
    private modal: MetaModalService,
  ) {}

  public async canActivate() {
    if (await this.userService.isAuth()) {
      await this.showChangeLog();
      return true;
    } else {
      this.navigateToLogin();
      return false;
    }
  }

  private async showChangeLog() {
    const cRef = this.userService.user.value?.appSettings?.cRef;
    if (cRef !== BUILD_NUM && !this.changelogShown && environment.production) {
      this.changelogShown = true;
      const comp = await import("../pages/changelog/changelog.component").then((e) => e.ChangelogComponent);
      const changelog = await fetch(`https://update.metaargon.de/changelogs/${String(VERSION).replace(/\./gim, "-")}/`)
        .then((r) => (r.ok ? r.text() : null))
        .catch(() => null);
      if (changelog) {
        await firstValueFrom(
          this.modal.create({
            nzTitle: "Changelog",
            nzContent: comp,
            nzOkText: "Schließen",
            nzCancelText: null,
            nzWidth: "75vw",
            nzCloseOnNavigation: false,
            nzMaskClosable: false,
            nzClosable: false,
          }).afterClose,
        );
        this.userService.setChangeLogRef(BUILD_NUM);
      }
    }
  }

  private navigateToLogin() {
    const redirectUrl = window.location.href.slice(window.location.origin.length);
    if (redirectUrl.indexOf("/auth") === 0) return;
    this.router.navigate(["/auth"], {
      queryParams: {
        redirectUrl,
      },
    });
  }
}
