/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponentInfo, MetaFormLayoutComponent } from "../components";
import { IFormUserConfiguration } from "../formUserConfiguration";

@MetaFormComponentInfo({
  selector: "ma-split-view-detail",
  type: "split-view-detail",
  name: "Split View Detail",
})
export class SplitViewDetailComponent extends MetaFormLayoutComponent {
  public static createElement(attributes, children: any[]) {
    const element = new SplitViewDetailComponent();
    Object.assign(element, {
      children,
    });
    return {
      type: "detail-internal",
      attributes,
      element,
    };
  }

  toFormly(config: IFormUserConfiguration): any {
    return {
      ...super.toFormly(config),
      type: "meta-split-view-detail",
    };
  }
}
