/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { MetaForm } from "./form";
import { MetaFormComponentInfo, MetaFormComponentProperty } from "../components";
import * as _ from "lodash";
import { DataSourceType } from "@meta/database/lib/types";
import { FormEventActionHandler } from "../interfaces";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  type: "editable-form",
  icon: "table",
  name: "Editable Form",
  hideInPallet: true,
})
export class EditableForm extends MetaForm {
  constructor(opts: Partial<EditableForm>) {
    super(opts);
    Object.assign(this, opts);
  }

  @MetaFormComponentProperty({
    name: "Create Query",
    type: "sql",
    tab: "hooks",
    default: null,
  })
  public createQuery?: string = null;

  @MetaFormComponentProperty({
    name: "Initialize Datasource",
    type: "dataSource",
    tab: "data",
    default: null,
  })
  public initializeProcedure?: DataSourceType = null;

  @MetaFormComponentProperty({
    name: "Löschbar",
    type: "select",
    tab: "data",
    default: null,
    values: [
      {
        label: "Nein",
        value: null,
      },
      {
        label: "Soft Delete",
        value: "soft",
      },
      {
        label: "Hard Delete",
        value: "hard",
      },
    ],
    hidden: true,
  })
  public deletable?: "soft" | "hard" = null;

  @MetaFormComponentProperty({
    name: "Vor dem veröffentlichen",
    type: "js",
    tab: "hooks",
    description: "Wird vor dem Veröffentlichen eines Datensatzes ausgeführt.",
  })
  public beforePublish?: FormEventActionHandler<any, string[] | void>;
  @MetaFormComponentProperty({
    name: "Nach dem veröffentlichen",
    type: "js",
    tab: "hooks",
    description: "Wird nach dem Veröffentlichen eines Datensatzes ausgeführt.",
  })
  public afterPublish?: FormEventActionHandler<any, void>;
  @MetaFormComponentProperty({
    name: "Nach Erstellen",
    type: "js",
    tab: "hooks",
    description: "Wird nach dem Anlegen eines Datensatzes ausgeführt.",
  })
  public afterCreate?: FormEventActionHandler;
  @MetaFormComponentProperty({
    name: "Nach Speichern",
    type: "js",
    tab: "hooks",
    description: "Wird nach dem Aktualisieren eines Datensatzes ausgeführt.",
  })
  public afterUpdate?: FormEventActionHandler;

  @MetaFormComponentProperty({
    name: "Vor Speichern",
    type: "js",
    tab: "hooks",
    description: "Wird vor dem Aktualisieren eines Datensatzes ausgeführt.",
  })
  public beforeUpdate?: FormEventActionHandler;
  @MetaFormComponentProperty({
    name: "Vor Erstellen",
    type: "js",
    tab: "hooks",
    description: "Wird vor dem Anlegen eines Datensatzes ausgeführt.",
  })
  public beforeCreate?: FormEventActionHandler;

  @MetaFormComponentProperty({
    name: "Vor Löschen",
    type: "js",
    tab: "hooks",
    description: "Wird vor dem Löschen eines Datensatzes ausgeführt.",
  })
  public beforeDelete?: FormEventActionHandler;

  @MetaFormComponentProperty({
    name: "Im Bearbeitungsmodus öffnen",
    type: "boolean",
    tab: "general",
    default: false,
  })
  public editing?: boolean;

  @MetaFormComponentProperty({
    name: "Nach dem Erstellen navigieren",
    type: "subform",
  })
  public navigateAfterCreate?: string;

  @MetaFormComponentProperty({
    name: "Bearbeitbar",
    type: "condition",
    tab: "general",
    default: true,
  })
  public editable? = true;

  public toFormly(config: IFormUserConfiguration) {
    return deepMerge(super.toFormly(config), {
      editing: this.editing,
      editable: this.editable,
      navigateAfterCreate: this.navigateAfterCreate,
    });
  }
}
