/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MetaButtonType, MetaState } from "@meta/enums";
import * as _ from "lodash";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalRef } from "ng-zorro-antd/modal";
import { Socket } from "ngx-socket-io";
import { firstValueFrom } from "rxjs";
import { MetaButton } from "../../../components/metaButton/metaButton.component";
import { MetaEventService } from "../../../services/metaEvents.service";
import { MetaModalService } from "../../../services/metaModalService";
import { MetaFormlyFormOptions } from "../../metaForm/metaForm.interface";
import { MetaFormService } from "../metaForm.service";

@Component({
  selector: "meta-form-edit",
  template: ` @if (options.formState.state() >= MetaState.editing && options.formState.state() <= MetaState.saving) {
      <meta-button [maParams]="configCancel" [maDisabled]="options.formState.isLoading()"></meta-button>
      <meta-button
        [maParams]="
          options.formState.form().publishable
            ? {
                id: '__metaSaveDraft',
                label: 'Entwurf speichern',
                icon: 'check',
                loading: this.options.formState.state() === MetaState.saving,
                type: 'primary',
                onClick: onConfirmEdit.bind(this)
              }
            : {
                id: '__metaSave',
                label: 'Speichern',
                icon: 'check',
                loading: this.options.formState.state() === MetaState.saving,
                type: 'primary',
                onClick: onConfirmEdit.bind(this)
              }
        "
        [maDisabled]="options.formState.isLoading() || lockEditModeWorkflow()"
      ></meta-button>
    } @else {
      <meta-button
        [nz-tooltip]="disabledReason()"
        nzTooltipPlacement="bottomRight"
        [maParams]="configEdit"
        [maDisabled]="
          options.formState.isLoading() ||
          !options.formState.metaData()?.isEditable ||
          lockEditModeWorkflow() ||
          activeExecutions().length > 0
        "
      ></meta-button>
    }`,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaFormEditComponent implements OnInit {
  @Input() options: MetaFormlyFormOptions;
  @Input() modalRef: NzModalRef;
  @Input() maCloseModalAfterCancel: boolean;
  @Input() maCloseModalAfterSave: boolean;

  public configEdit: MetaButton;
  public configCancel: MetaButton;
  protected readonly MetaState = MetaState;

  constructor(
    public readonly metaFormService: MetaFormService,
    private readonly _nzMessageService: NzMessageService,
    private readonly _metaEventService: MetaEventService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _modalService: MetaModalService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router,
    private readonly _socket: Socket,
  ) {}

  public readonly activeExecutions = computed(() => {
    const wf = this.metaFormService.formState.workflows();
    if (!wf?.executions) return [];
    return wf.executions.filter((w) => w.active);
  });

  public readonly lockEditModeWorkflow = computed(() => {
    const wf = this.options.formState?.workflows();
    if (!wf) return false;
    return !!wf.executions.find((e) => e.lock && e.active);
  });

  public readonly disabledReason = computed(() => {
    if (this.lockEditModeWorkflow()) {
      return "Ein Workflow wird ausgeführt.";
    } else {
      return null;
    }
  });

  ngOnInit() {
    this.configEdit = {
      id: "__metaEdit",
      label: "Bearbeiten",
      icon: "pencil-alt",
      type: MetaButtonType.primary,
      onClick: async () => {
        this.options.formState.formGroup().markAsPristine();
        this.options.formState.state.set(MetaState.editing);
        this.options.formState.oldData.set(_.cloneDeep(this.options.formState.data()));
        this.metaFormService.updateDisplayValues();
      },
    };

    this.configCancel = {
      id: "__metaCancel",
      label: "Abbrechen",
      icon: "times",
      type: MetaButtonType.text,
      onClick: async () => {
        if (this.modalRef && this.maCloseModalAfterCancel) {
          this.modalRef.close(false);
        }
        if (!this.modalRef && this.options.formState.itemId() === "create") {
          history.back();
        }
        this.options.formState.data.set(this.options.formState.oldData());
        this.options.formState.oldData.set({} as any);
        this.options.formState.formGroup().markAsUntouched();
        this.options.formState.formGroup().markAsPristine();
        this.options.formState.state.set(MetaState.canceled);
        this._metaEventService.emitCancelTrigger({
          formId: this.options.formState.formId,
          timestamp: new Date().getTime(),
        });
        this.metaFormService.updateDisplayValues();
      },
    };
  }

  public async onConfirmEdit() {
    {
      if (this.options.formState.formGroup().pristine && this.options.formState.itemId() !== "create") {
        this.options.formState.state.set(MetaState.saved);
        this._metaEventService.emitSaveTrigger({
          formId: this.options.formState.formId,
          itemId: this.options.formState.itemId(),
          timestamp: new Date().getTime(),
        });
        this._nzMessageService.success("Datensatz gespeichert");
        if (this.modalRef && this.maCloseModalAfterSave) {
          this.modalRef.close(true);
        }
        return true;
      } else if (this.options.formState.formGroup().valid) {
        const ref = this._nzMessageService.loading("Datensatz wird gespeichert…", {
          nzDuration: 30000,
        });

        try {
          const result = await firstValueFrom(
            this.metaFormService.updateFormData({
              formId: this.options.formState.formId,
              itemId: this.options.formState.itemId(),
              oldData: this.options.formState.itemId() === "create" ? {} : this.options.formState.oldData(),
              newData: this.options.formState.data(),
              state: this.options.formState.state(),
              publish: false,
              completeEditMode: true,
            }),
          );
          this._nzMessageService.remove(ref.messageId);
          this.metaFormService.formState.formGroup().markAsPristine();
          this._metaEventService.emitSaveTrigger({
            formId: this.options.formState.formId,
            itemId: result.result._id.join(","),
            timestamp: new Date().getTime(),
          });
          // Nagigatee
          if (this.metaFormService.formState.form().navigateAfterCreate) {
            await this._router.navigate([
              "/",
              this.metaFormService.formState.form().navigateAfterCreate,
              result.result._id.join(","),
            ]);
          } else if (this.modalRef && this.maCloseModalAfterSave) {
            this.modalRef.close(true);
          } else if (this.options.formState.itemId() === "create" && !this.modalRef) {
            const url: string[] = ["/", this.metaFormService.formState.formId, result.result._id.join(",")];
            await this._router.navigate(url, {
              replaceUrl: true,
              queryParamsHandling: "merge",
              queryParams: this._activatedRoute.snapshot.queryParams.t !== undefined ? { t: 0 } : {},
            });
            this._socket.emit("OPEN_FORM", {
              form: this.metaFormService.formState.formId,
              itemId: result.result._id.join(","),
            });
          }
          return true;
        } catch (e) {
          this._nzMessageService.remove(ref.messageId);
          this.options.formState.formGroup().markAllAsTouched();
          this._changeDetectorRef.markForCheck();
          return false;
        }
      } else {
        this._nzMessageService.error("Eingabe ungültig. Bitte prüfe alle Pflichtfelder und versuch es noch einmal.");
        this.options.formState.formGroup().markAllAsTouched();
        this._changeDetectorRef.markForCheck();
        return false;
      }
    }
  }
}
