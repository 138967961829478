/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ValidationMessageOption, ValidatorOption } from "@ngx-formly/core/lib/models/config";
import { AbstractControl } from "@angular/forms";
import {
  isBIC,
  isCurrency,
  isEmail,
  isIBAN,
  isLocale,
  isPhoneNumber,
  isURL,
  matches,
  max,
  maxLength,
  min,
  minLength,
} from "class-validator";
import { FormlyFieldConfig } from "@ngx-formly/core/lib/models/fieldconfig";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

let httpClient: HttpClient;
export const validators: ValidatorOption[] = [];

export function initializeValidators(http: HttpClient) {
  httpClient = http;
}

export const validationMessages: ValidationMessageOption[] = [
  { name: "required", message: "Das ist ein Pflichtfeld" },
  {
    name: "max",
    message: (err, config) => {
      return `Der Wert darf nicht Größer ${config.props.max} sein.`;
    },
  },
  {
    name: "min",
    message: (err, config) => {
      return `Der Wert darf nicht Kleiner ${config.props.min} sein.`;
    },
  },
  {
    name: "minLength",
    message: (err, config) => {
      return `Der Wert muss Minimal ${config.props.minLength} Zeichen lang sein.`;
    },
  },
  {
    name: "maxLength",
    message: (err, config) => {
      return `Der Wert darf Maximal ${config.props.maxLength} Zeichen lang sein.`;
    },
  },
];

interface IValidator {
  name: string;
  validator: (
    control: AbstractControl,
    field: FormlyFieldConfig,
    options: Record<string, any>,
  ) => boolean | Observable<boolean>;
  message: string | ((options: Record<string, any>, field: FormlyFieldConfig) => string);
}

function registerValidator(validator: IValidator) {
  validators.push({
    name: validator.name,
    validation: (...args) => {
      const control = args[0];
      if (control.value === null || control.value === undefined) {
        return null;
      }
      const res = validator.validator(...args);
      if (res instanceof Observable) {
        return res.pipe(
          map((r) => {
            if (r) {
              return null;
            } else {
              return {
                [validator.name]: args[2] || {},
              };
            }
          }),
        );
      } else if (res) {
        return null;
      } else {
        return {
          [validator.name]: args[2] || {},
        };
      }
    },
  });
  validationMessages.push({ name: validator.name, message: validator.message });
}

registerValidator({
  name: "min-validator",
  message: (opts) => {
    return `Der Wert muss > ${opts.min} sein.`;
  },
  validator: (control: AbstractControl, field: FormlyFieldConfig, options: Record<string, any>) => {
    return min(Number(control.value), options.min);
  },
});

registerValidator({
  name: "max-validator",
  message: (opts) => {
    return `Der Wert muss < ${opts.max} sein.`;
  },
  validator: (control: AbstractControl, field: FormlyFieldConfig, options: Record<string, any>) => {
    return max(Number(control.value), options.max);
  },
});

registerValidator({
  name: "max-length-validator",
  message: (opts) => {
    return `Der eingegebene Text muss < ${opts.max} sein.`;
  },
  validator: (control: AbstractControl, field: FormlyFieldConfig, options: Record<string, any>) => {
    return maxLength(String(control.value), options.max);
  },
});

registerValidator({
  name: "min-length-validator",
  message: (opts) => {
    return `Der eingegebene Text muss < ${opts.min} sein.`;
  },
  validator: (control: AbstractControl, field: FormlyFieldConfig, options: Record<string, any>) => {
    return minLength(String(control.value), options.min);
  },
});

registerValidator({
  name: "email-validator",
  message: (opts) => {
    return `Ungültige Email-Adresse`;
  },
  validator: (control: AbstractControl, field: FormlyFieldConfig, options: Record<string, any>) => {
    return isEmail(String(control.value));
  },
});

registerValidator({
  name: "iban-validator",
  message: (opts) => {
    return `Ungültige IBAN`;
  },
  validator: (control: AbstractControl, field: FormlyFieldConfig, options: Record<string, any>) => {
    return isIBAN(String(control.value));
  },
});

registerValidator({
  name: "bic-validator",
  message: (opts) => {
    return `Ungültige BIC/SWIFT-CODE`;
  },
  validator: (control: AbstractControl, field: FormlyFieldConfig, options: Record<string, any>) => {
    return isBIC(String(control.value));
  },
});

registerValidator({
  name: "is-phone-validator",
  message: (opts) => {
    return `Die eingegebene Telefonnummer ist ungültig.\nBitte geben Sie die Telefonnummer in folgendem Format an: +49 211 635533-55`;
  },
  validator: (control: AbstractControl, field: FormlyFieldConfig, options: Record<string, any>) => {
    return isPhoneNumber(String(control.value));
  },
});

registerValidator({
  name: "is-currency-validator",
  message: (opts) => {
    return `Die eingabe ist keine gültige Währung.`;
  },
  validator: (control: AbstractControl, field: FormlyFieldConfig, options: Record<string, any>) => {
    return isCurrency(String(control.value));
  },
});

registerValidator({
  name: "match-validator",
  message: (opts) => {
    return `Die eingabe ist ungültig.`;
  },
  validator: (control: AbstractControl, field: FormlyFieldConfig, options: Record<string, any>) => {
    const re = new RegExp(options.regexp, options.modifiers);
    return matches(String(control.value), re);
  },
});

registerValidator({
  name: "password-policy-validator",
  message: (opts) => {
    return `Die eingabe ist ungültig.`;
  },
  validator: (control: AbstractControl, field: FormlyFieldConfig, options: Record<string, any>) => {
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    return matches(String(control.value), re);
  },
});

const scriptFieldResponseWeakMap = new WeakMap<FormlyFieldConfig, string>();

registerValidator({
  name: "script-validator",
  message: (opts, field) => {
    return scriptFieldResponseWeakMap.get(field) || "script-validator";
  },
  validator: (control: AbstractControl, field: FormlyFieldConfig, options: Record<string, any>) => {
    const formId = field.options.formState.formId;
    return httpClient
      .post(`forms/${formId}/validate`, {
        ...control.root.value,
        [field.id]: control.value,
      })
      .pipe(
        map((e) => {
          scriptFieldResponseWeakMap.set(field, e["_messages"]?.[field.id] || null);
          return !e[field.id];
        }),
      );
  },
});

registerValidator({
  name: "url-validator",
  message: (opts) => `Ungültige URL.`,
  validator: (control: AbstractControl, field: FormlyFieldConfig, options: Record<string, any>) => {
    return isURL(String(control.value));
  },
});
registerValidator({
  name: "is-locale-validator",
  message: (opts) => `Ungültige Locale.`,
  validator: (control: AbstractControl, field: FormlyFieldConfig, options: Record<string, any>) => {
    return isLocale(String(control.value));
  },
});
