/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Expose } from "class-transformer";
import { ExposeProperty, MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";
import { UploadComponent } from "./uploadComponent";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-image-gallery",
  type: "image-gallery",
  icon: "images",
  name: "Image Gallery",
})
export class ImageGalleryComponent<PrimaryDataSource = any> extends UploadComponent<PrimaryDataSource> {
  @MetaFormComponentProperty({
    name: "width",
    type: "css-property",
    property: "width",
    tab: "style",
  })
  public width = "100%";
  @MetaFormComponentProperty({
    name: "height",
    type: "css-property",
    property: "height",
    tab: "style",
  })
  public height = "100%";
  @MetaFormComponentProperty({
    name: "Editierbar",
    type: "text",
    tab: "general",
    isTemplate: true,
  })
  public title: string;

  public static create<T>(dataSource: T, opts: Partial<ImageGalleryComponent<T>>) {
    const input = new ImageGalleryComponent<T>();
    input.setOptions(opts as any);
    return input;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        width: this.width,
        height: this.height,
      },
    });
  }
}
