import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method === "JSONP") {
      return next.handle(req);
    } else {
      // The x-bid header is used to get the correct browser window for a socket
      const headers = new HttpHeaders({
        "x-bid": window["bid"],
      });

      if (req.headers.keys().length > 0) {
        req.headers.keys().forEach((k) => {
          headers.set(k, req.headers.get(k));
        });
      }
      const apiReq = req.clone({
        url: `api/v2${(req.url.indexOf("/") === 0 ? "" : "/") + req.url}`,
        headers,
      });
      return next.handle(apiReq);
    }
  }
}
