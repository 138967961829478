/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Injectable } from "@angular/core";
import { MetaMediaLibraryComponent } from "./metaMediaLibrary.component";
import { firstValueFrom } from "rxjs";
import { MetaMediaLibraryFilePreviewComponent } from "./metaMediaLibraryFilePreview.component";
import { MetaMediaLibraryUploadModalComponent } from "./metaMediaLibraryUploadModal.component";
import { HttpClient, HttpErrorResponse, HttpEventType, HttpResponse } from "@angular/common/http";
import { MetaMediaLibraryShareComponent } from "./share/metaMediaLibraryShare.component";
import { MetaMediaLibraryDeleteComponent } from "./delete/metaMediaLibraryDelete.component";
import { MetaMediaLibraryEditComponent } from "./edit/metaMediaLibraryEdit.component";
import { MetaPrintService } from "../meta-print-modal/meta-print.service";
import {
  IMetaMediaLibraryFile,
  IMetaMediaLibraryFilePrintOptions,
  IMetaMediaLibrarySelectFileOption,
} from "./interfaces";
import { filter, tap } from "rxjs/operators";
import { MetaModalService } from "../../services/metaModalService";

@Injectable({
  providedIn: "root",
})
export class MetaMediaLibraryService {
  constructor(
    private readonly modalService: MetaModalService,
    private readonly httpClient: HttpClient,
    private readonly metaPrintService: MetaPrintService
  ) {}

  public async uploadSingleFile(options: IMetaMediaLibrarySelectFileOption): Promise<IMetaMediaLibraryFile> {
    return await this.openUpload(options).then((f) => f[0]);
  }

  public async uploadMultipleFiles(options: IMetaMediaLibrarySelectFileOption): Promise<IMetaMediaLibraryFile[]> {
    return await this.openUpload(options);
  }

  public async directUploadFiles(
    files: File[],
    collection?: string,
    progress?: (percent: number, uploadedBytes: number, totalBytes: number) => void
  ) {
    const uploadedFile: IMetaMediaLibraryFile[] = [];
    for (let file of files) {
      const formData = new FormData();
      formData.append("file", file as unknown as Blob);
      formData.append("name", file.name);
      if (file["__fullPath"]) {
        formData.append("fullPath", file["__fullPath"]);
      }

      if (collection) {
        formData.append("collection", collection);
      }
      const res = (await firstValueFrom(
        this.httpClient
          .post("/collection/upload", formData, {
            reportProgress: true,
            observe: "events",
          })
          .pipe(
            tap((e) => {
              if (e.type === HttpEventType.UploadProgress) {
                const percent = Math.floor((100 / e.total) * e.loaded);
                if (progress) {
                  progress(percent, e.loaded, e.total);
                }
              }
            }),
            filter((e) => e instanceof HttpResponse)
          )
      )) as HttpResponse<IMetaMediaLibraryFile>;
      uploadedFile.push(res.body);
    }
    return uploadedFile;
  }

  public async selectSingleFile(options: IMetaMediaLibrarySelectFileOption): Promise<IMetaMediaLibraryFile> {
    return await this.openMediaLibrary(options, {
      isMultiselect: false,
    }).then((f) => f[0]);
  }

  public async selectMultipleFiles(options: IMetaMediaLibrarySelectFileOption): Promise<IMetaMediaLibraryFile[]> {
    return await this.openMediaLibrary(options, {
      isMultiselect: true,
    });
  }

  public open(options: IMetaMediaLibrarySelectFileOption): void {
    this.openMediaLibrary(options, {
      isMultiselect: false,
      isSelect: false,
    }).catch((e) => {
      console.error(e);
    });
  }

  public async getFile(id: string): Promise<IMetaMediaLibraryFile> {
    return await firstValueFrom<any>(
      this.httpClient.get(`media-library/file-info/${id}`, {
        params: {
          additional: true,
        },
      })
    );
  }

  public previewFileById(fileId: string): void {
    this.getFile(fileId)
      .then((file) => {
        this.previewFile(file);
      })
      .catch((e) => {
        if (e instanceof HttpErrorResponse) {
          this.modalService.error({
            nzTitle: e.statusText,
            nzContent: e.error?.message,
          });
        }
      });
  }

  public previewFile(file: IMetaMediaLibraryFile) {
    const ref = this.modalService.create<MetaMediaLibraryFilePreviewComponent>({
      nzContent: MetaMediaLibraryFilePreviewComponent,
      nzComponentParams: { file, showInfo: true, autoplay: true },
      nzTitle: `${file.name}`,
      nzCloseOnNavigation: true,
      nzWidth: "100vw",
      nzBodyStyle: {
        height: "calc(100vh - 200px)",
        position: "relative",
        display: "flex",
        "flex-direction": "column",
        overflow: "hidden",
      },
      nzFooter: null,
    });
    ref.componentInstance.load();
  }

  public getIcon(file: Pick<IMetaMediaLibraryFile, "name" | "mime" | "type">) {
    if (!file || !file.name) {
      return "file";
    }
    if (file.type === "collection") {
      return "folder";
    }
    const ext = file.name.split(".").pop().toLowerCase();
    const type = file.mime.split("/")[0].toLowerCase();
    if (["doc", "docx"].includes(ext)) {
      return "file-word";
    } else if (["xlsx"].includes(ext)) {
      return "file-excel";
    } else if (["ppt"].includes(ext)) {
      return "file-powerpoint";
    } else if (type === "image") {
      return "file-image";
    } else if (type === "audio") {
      return "file-audio";
    } else if (type === "video") {
      return "file-video";
    } else if (ext === "csv") {
      return "file-csv";
    } else if (ext === "pdf") {
      return "file-pdf";
    } else if (["zip", "gz", "tar", "7z", "rar", "dmg", "iso"].includes(ext)) {
      return "file-zipper";
    } else {
      return "file";
    }
  }

  async getPublicUrl(
    file: IMetaMediaLibraryFile | IMetaMediaLibraryFile[],
    options: { expiresOn?: Date; password?: string; zip?: boolean } = {}
  ) {
    if (options.zip) {
      return firstValueFrom(
        this.httpClient.get<{ url: string }>(`download/public-zip-url`, {
          params: {
            ...(options.expiresOn ? { expiresOn: options.expiresOn.getTime() } : {}),
            ids: JSON.stringify(Array.isArray(file) ? file.map((f) => f.id) : [file.id]),
            password: options.password,
          },
        })
      );
    } else if (!Array.isArray(file)) {
      return firstValueFrom(
        this.httpClient.get<{ url: string }>(`file/${file.id}/public-url`, {
          params: {
            ...(options.expiresOn ? { expiresOn: options.expiresOn.getTime() } : {}),
          },
        })
      );
    } else {
      throw new Error("Fehler");
    }
  }

  shareFile(files: IMetaMediaLibraryFile[]) {
    const ref = this.modalService.create({
      nzContent: MetaMediaLibraryShareComponent,
      nzOkText: null,
      nzCloseOnNavigation: true,
      nzComponentParams: {
        files,
      },
      nzTitle:
        files.length === 1
          ? `"${files[0].name}" Freigeben`
          : `"${files[0].name}" und ${files.length - 1} weitere Freigeben`,
    });
    return firstValueFrom(ref.afterClose);
  }

  deleteFile(file: IMetaMediaLibraryFile) {
    const ref = this.modalService.create({
      nzContent: MetaMediaLibraryDeleteComponent,
      nzOkText: null,
      nzCloseOnNavigation: true,
      nzComponentParams: {
        fielId: file.id,
      },
      nzTitle: `"${file.name}" Löschen`,
    });
    return firstValueFrom<{ success: boolean }>(ref.afterClose);
  }

  editFile(file: IMetaMediaLibraryFile) {
    const ref = this.modalService.create({
      nzContent: MetaMediaLibraryEditComponent,
      nzOkText: null,
      nzCloseOnNavigation: true,
      nzComponentParams: {
        file,
        fileId: file.id,
      },
      nzTitle: `"${file.name}" Ändern`,
    });
    return firstValueFrom(ref.afterClose);
  }

  private async openUpload(options: IMetaMediaLibrarySelectFileOption): Promise<IMetaMediaLibraryFile[]> {
    const ref = this.modalService.create<MetaMediaLibraryUploadModalComponent, IMetaMediaLibraryFile[]>({
      nzContent: MetaMediaLibraryUploadModalComponent,
      nzComponentParams: {
        collection: options.collection,
      },
      nzTitle: "Hochladen",
      nzCloseOnNavigation: true,
      nzOnOk: (c) => {
        return c.uploadedFiles;
      },
      nzOnCancel: () => {
        return [];
      },
    });
    return await firstValueFrom(ref.afterClose);
  }

  private async openMediaLibrary(
    options: IMetaMediaLibrarySelectFileOption,
    params?: Partial<MetaMediaLibraryComponent>
  ) {
    const ref = this.modalService.create<MetaMediaLibraryComponent, IMetaMediaLibraryFile[]>({
      nzContent: MetaMediaLibraryComponent,
      nzComponentParams: { ...params, initialType: options.type },
      nzTitle: "Medien Sammlung",
      nzCloseOnNavigation: true,
      nzWidth: "80vw",
      nzBodyStyle: {
        height: "calc(100vh - 300px)",
        position: "relative",
        display: "flex",
        "flex-direction": "column",
        overflow: "hidden",
      },
      nzOnOk: (c) => {
        return c.selectedFiles;
      },
      nzOnCancel: () => {
        return [];
      },
    });
    return await firstValueFrom(ref.afterClose);
  }

  printFile(file: IMetaMediaLibraryFile, opts: Partial<IMetaMediaLibraryFilePrintOptions>) {
    this.metaPrintService.printFile(file);
  }

  public async moveItems(item: IMetaMediaLibraryFile[], srcCollection: string, targetCollection: string) {
    await firstValueFrom(
      this.httpClient.post<{ url: string }>(`media-library/move`, {
        items: item.map((e) => {
          return {
            id: e.id,
            type: e.type,
          };
        }),
        targetCollection,
        srcCollection,
      })
    );
    return true;
  }

  public async copyItem(item: IMetaMediaLibraryFile[], targetCollection: string) {
    await firstValueFrom(
      this.httpClient.post<{ url: string }>(`media-library/copy`, {
        items: item.map((e) => {
          return {
            id: e.id,
            type: e.type,
          };
        }),
        targetCollection,
      })
    );
    return true;
  }

  public async createCollection(name: string, targetCollection: string) {
    await firstValueFrom(
      this.httpClient.put<{ url: string }>(`media-library/createCollection`, {
        name,
        targetCollection,
      })
    );
    return true;
  }

  async deleteCollection(file: IMetaMediaLibraryFile) {
    return;
    const ok = confirm(`Möchten sie wirklich "${file.name}" löschen?`);
    if (ok) {
      await firstValueFrom(
        this.httpClient.post<{ url: string }>(`media-library/deleteCollection`, {
          collection: file.id,
        })
      );
    }
  }

  async editCollection(file: IMetaMediaLibraryFile) {
    const name = prompt("Neuer Name");
    await firstValueFrom(
      this.httpClient.put<{ url: string }>(`media-library/renameCollection`, {
        name,
        collection: file.id,
      })
    );
    return true;
  }
}
