/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { Component, Input } from "@angular/core";
import { MetaAvatarModule } from "@meta/ui";

@Component({
  selector: "meta-scheduler-timeline-scale-label",
  template: `<div class="container">
    <meta-avatar
      [maParams]="{
        label: maLabel,
        colorString: maColorString,
        id: maId,
        type: 'ADR',
        size: 50
      }"
    ></meta-avatar>
    <span class="label">{{ maLabel }}</span>
  </div>`,
  styles: [
    `
      .container {
        display: flex;
        flex-direction: column;
        line-height: 0;
        row-gap: 10px;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        overflow: visible;
      }

      .label {
        line-height: initial;
      }
    `,
  ],
  imports: [MetaAvatarModule],
  standalone: true,
})
export class MetaSchedulerTimelineScaleLabelComponent {
  @Input() maLabel: string;
  @Input() maColorString: string;
  @Input() maId: string;
}
