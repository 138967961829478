/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  NgModule,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from "@angular/core";
import { FormlyModule } from "@ngx-formly/core";
import * as _ from "lodash";
import { DynamicModule } from "ng-dynamic-component";
import { NzPopoverDirective, NzPopoverModule } from "ng-zorro-antd/popover";
import { distinctUntilChanged, takeUntil } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { MetaFormComponent } from "../../base/metaForm/metaForm.component";
import { IMetaWrapperBase, MetaWrapperBase } from "../../base/metaWrapperBase/metaWrapperBase.component";
import { PipesModule } from "../../pipes/pipes.module";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaEmptyModule } from "../metaEmpty/metaEmpty.component";

export class MetaTooltip extends IMetaWrapperBase {
  tooltipForm?: string;
  tooltipId?: string;
  tooltipSize?: "small" | "medium" | "large";
  tooltipWidth?: string;
  tooltipHeight?: string;
  tooltipPlacement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom"
    | Array<string> = ["rightTop", "leftTop"];
  displayValue?: any;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-tooltip",
  template: `
    <div
      class="meta-tooltip-wrapper"
      [nzPopoverContent]="ma.tooltipForm ? tmpPopoverContent : null"
      [nzPopoverPlacement]="ma.tooltipPlacement"
      [nzPopoverMouseEnterDelay]="1"
      [nzPopoverMouseLeaveDelay]="1"
      (nzPopoverVisibleChange)="popoverVisibleChange($event)"
      [nzPopoverArrowPointAtCenter]="true"
      [nzPopoverOverlayClassName]="'popover-content-' + ma.tooltipSize || 'medium'"
      [nzPopoverOverlayStyle]="{
        width: ma.tooltipWidth || undefined,
        height: ma.tooltipHeight || undefined,
        'min-width': ma.tooltipWidth || undefined,
        'min-height': ma.tooltipHeight || undefined,
        'max-height': '80vh',
        'max-width': '80vw'
      }"
      nz-popover
    >
      <ng-container #fieldComponent></ng-container>
      <ng-content></ng-content>
    </div>
    <ng-template #tmpPopoverContent>
      <ng-container *ngIf="showPopoverContent && inputs; else noDataTpl">
        <ng-template [ngComponentOutlet]="component" [ndcDynamicInputs]="inputs"></ng-template>
      </ng-container>
      <ng-template #noDataTpl>
        <meta-empty [maParams]="{ description: 'Keine Daten verfügbar' }"></meta-empty>
      </ng-template>
    </ng-template>
  `,
  styleUrls: ["./metaTooltip.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaTooltipComponent extends MetaWrapperBase implements OnInit, OnDestroy {
  public previewValues: string;
  public showPopoverContent = false;
  public activeTooltipComponent: any;
  public activeTooltipIndex = 0;

  public readonly component = MetaFormComponent;
  public inputs: any;

  public tooltipParams: Record<string, any>;
  public tooltipId: string;
  @ViewChildren("tooltipDynamicComponent") public tooltipDynamicComponents: QueryList<MetaFormComponent>;
  @ViewChild(NzPopoverDirective) public metaTooltip: NzPopoverDirective;

  constructor() {
    super();
    super.maParams = new MetaTooltip();
  }

  ngOnInit() {
    if (this.field) {
      const obs = this.formControl.valueChanges.pipe(
        takeUntil((this as any).destroy$),
        distinctUntilChanged((a, b) => {
          return _.isEqual(a, b);
        }),
      );
      obs.pipe(debounceTime(1500)).subscribe(() => this.updateTooltip());
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public updateTooltip() {
    const dv = this.ma.displayValue || this.displayData;
    const c = this.tooltipDynamicComponents.get(0);
    if (c && dv) {
      this.tooltipParams = dv.tooltipParams || null;
      this.tooltipId = this.ma.tooltipId || dv.tooltipId || null;
      c.maExternalData = this.tooltipParams;
      c.metaFormService.getFormData({
        itemId: c.maItemId,
      });
    }
    this._updateInputs();
    setTimeout(() => {
      this.metaTooltip?.component?.updatePosition();
    }, 500);
  }

  popoverVisibleChange(visible: boolean) {
    // TODO: Besseren Weg implementieren
    const dv = this.ma.displayValue || this.displayData;
    if (this.field) {
      for (const [key, value] of Object.entries(dv["tooltipParams"])) {
        if (value === null || value === undefined || value === "") {
          this.showPopoverContent = false;
          return;
        }
      }
    }
    this.showPopoverContent = false;
    this.tooltipParams = dv ? dv["tooltipParams"] : null;
    this.tooltipId = this.ma.tooltipId || (dv ? dv["tooltipId"] : undefined);
    if (visible) {
      this.showPopoverContent = true;
    }
    this._updateInputs();
    if (visible) {
      setTimeout(() => {
        this.metaTooltip?.component?.updatePosition();
      }, 500);
    }
  }

  private _updateInputs() {
    this.inputs = {
      maFormId: this.ma.tooltipForm,
      maItemId: this.tooltipId?.length > 0 ? this.tooltipId : null,
      maExternalData: this.tooltipParams || {},
      maIsTooltip: true,
      maHideToolbar: true,
    };
  }
}

@NgModule({
  declarations: [MetaTooltipComponent],
  imports: [CommonModule, FormlyModule, NzPopoverModule, DynamicModule, MetaEmptyModule, PipesModule],
  exports: [MetaTooltipComponent],
})
export class MetaTooltipModule {}
