/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ExposeProperty, MetaFormButtonComponent, MetaFormComponentInfo } from "../components";
import { Expose } from "class-transformer";
import { Page } from "./page";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-widget",
  type: "widget",
  icon: "file-invoice",
  name: "Widget",
})
export class Widget extends Page {
  static selector = "ma-widget";
  @ExposeProperty()
  public isWidget = true;
  @ExposeProperty()
  public resizeable: boolean;
  @ExposeProperty()
  public defaultWidth: number;
  @ExposeProperty()
  public defaultHeight: number;

  constructor(props: Page) {
    super(props);
  }

  public static createElement(attributes, children: any[]): any {
    return new Widget({
      ...attributes,
      children,
      actions: [
        ...(attributes?.actions || []),
        MetaFormButtonComponent.createElement({
          icon: "expand",
          onClick: `$.Navigate('/${attributes.id}')`,
        }),
      ],
    });
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      type: "widget",
      category: this.category,
      props: {
        label: this.label || this.label,
        resizeable: this.resizeable,
        defaultWidth: this.defaultWidth,
        defaultHeight: this.defaultHeight,
      },
    });
  }
}
