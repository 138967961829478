/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, Input } from "@angular/core";
import { IArgonNotificationRef } from "./notification.service";

@Component({
  styleUrls: ["./notification-item.component.less"],
  template: `
    <div class="item-title">
      <span>{{ ref.options.title | translate }} </span>
      <i *ngIf="ref.options.removable" (click)="ref.close(true)" class="fal fa-times"></i>
    </div>
    <div *ngIf="ref.options.content" class="item-content">{{ ref.options.content | translate }}</div>

    <div class="actions-container">
      <div class="item-meta">{{ ref.date | metaSince }}</div>
      <div
        role="button"
        (click)="action.click(ref, true)"
        *ngFor="let action of ref.options.actions"
        class="action {{ action.color }}"
      >
        {{ action.label | translate }}
      </div>
    </div>
  `,
  selector: "meta-notification-item",
})
export class NotificationItemComponent {
  @Input()
  public ref: IArgonNotificationRef;

  @Input()
  public size: "default" | "small" = "default";
}
