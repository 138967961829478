/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, Input, OnInit } from "@angular/core";
import { MetaHelperService } from "../../../services/metaHelper.service";
import { IFeedResultItem } from "@meta/api-interfaces";

export class MetaTimelineTemplateFormUpdated {
  description: string;
  data: IFeedResultItem;
}

@Component({
  selector: "meta-timeline-template-form-updated",
  template: ` <meta-translate key="timeline.form-updated">
      <ng-template meta-translate-child key="link">
        <meta-live-card
          [cardLazyLoad]="true"
          [cardType]="item.data.entity.type"
          cardSize="link"
          [cardId]="item.data.entity.id"
          [cardData]="item.data.cardData"
        ></meta-live-card>
      </ng-template>
    </meta-translate>

    <br />
    <div class="log-changelist" [innerHTML]="item.description"></div>

    <ng-template #tplLabel
      ><i class="far fa-{{ item.data.entity.icon }}"></i>{{ item.data.entity.displayId || "" }}
      {{ item.data.entity.title }}</ng-template
    >`,
})
export class MetaTimelineTemplateFormUpdatedComponent implements OnInit {
  @Input() public item: MetaTimelineTemplateFormUpdated;

  public color: string;
  public colorText: string;

  constructor(public metaHelperService: MetaHelperService) {}

  ngOnInit() {
    this.color = this.metaHelperService.generateColor(this.item.data.entity.name);
    this.colorText = getComputedStyle(document.documentElement).getPropertyValue("--component-background");
  }
}
