/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import {
  ActivityComponent,
  AddressComponent,
  BarcodeComponent,
  CardComponent,
  ChartGaugeComponent,
  ChartMapComponent,
  ChartMultiSeriesComponent,
  ChartSingleSeriesComponent,
  CheckboxComponent,
  CollapseComponent,
  ColumnComponent,
  ContainerComponent,
  DocumentViewComponent,
  EditorComponent,
  FilesComponent,
  GroupComponent,
  IconComponent,
  ImageComponent,
  ImageGalleryComponent,
  InputComponent,
  ListInputComponent,
  ListItemComponent,
  MetaAutocompleteComponent,
  MetaCalculationComponent,
  MetaCalculationTemplatesComponent,
  MetaDiagramComponent,
  MetaDropAreaComponent,
  MetaFormButtonComponent,
  MetaFormDatepickerComponent,
  MetaFormHeatmapComponent,
  MetaFormInfoboxComponent,
  MetaFormMapComponent,
  MetaFormMultiSelectComponent,
  MetaFormProgressComponent,
  MetaFormProgressInputComponent,
  MetaFormRadioComponent,
  MetaFormSectionComponent,
  MetaFormSelectComponent,
  MetaFormSliderComponent,
  MetaFormStatisticComponent,
  MetaSubformComponent,
  NoOutputComponent,
  RowComponent,
  SpecificationGroupCombinationsComponent,
  SpecificationsComponent,
  SwitcherComponent,
  TabComponent,
  TabsComponent,
  TagComponent,
  TaskCreateComponent,
  TaskViewComponent,
  TextareaComponent,
  TreeSelectComponent,
  TreeViewComponent,
  UploadComponent,
  WebviewComponent,
  WizardStepComponent,
  DocumentManager,
  MetaGanttComponent,
  MetaSchedulerComponent,
  SchedulerFilter,
  FieldWrapperComponent,
} from "./components";
import {
  HtmlReport,
  List,
  MetaForm,
  MetaFormSearch,
  Page,
  Pipeline,
  Report,
  SplitView,
  SplitViewDetailComponent,
  SplitViewMasterComponent,
  Table,
  Widget,
  WizardComponent,
} from "./pages";
import { MetaSectionType } from "@meta/api-interfaces";
import * as _ from "lodash";
import {
  MetaFormBooleanFilter,
  MetaFormDateFilter,
  MetaFormDateTimeFilter,
  MetaFormFilterGroup,
  MetaFormInputFilter,
  MetaFormSelectFilter,
} from "./filters";
import {
  IsBicValidator,
  IsCurrency,
  IsEmailValidator,
  IsGtin,
  IsIbanValidator,
  IsISO31661Alpha2,
  IsLocaleValidator,
  IsPhoneNumber,
  IsPznValidator,
  IsStringValidatorOptions,
  IsURL,
  Length,
  MaxValidator,
  MinValidator,
  ScriptValidator,
} from "./validators";
import { SqlDataSource, SqlRelation } from "./sqlDataSource";
import { MatchesValidator } from "./validators/matchesValidator";
import { ComponentFactories } from "./metadataStore";

interface MaHeadline {
  icon?: string;
}

interface MaLogo {
  displayValue?: string;
}

interface MaHorizontalRule {
  style?: "default" | "dashed" | "dotted";
}

interface MaIf {
  condition: string;
}

type FormElementType<T> = Omit<T, "setOptions" | "toFormly">;
type PartialFormElementType<T> = Partial<FormElementType<T>>;

export declare namespace JSX {
  interface ElementChildrenAttribute {
    children: {}; // specify children name to use
  }

  interface IntrinsicElements {
    "ma-page": PartialFormElementType<Page>;
    "ma-table": PartialFormElementType<Table>;
    "ma-list": PartialFormElementType<List>;
    "ma-report": PartialFormElementType<Report>;
    "ma-html-report": PartialFormElementType<HtmlReport>;
    "ma-search": PartialFormElementType<MetaFormSearch>;
    "ma-widget": PartialFormElementType<Widget>;
    "ma-col": PartialFormElementType<ColumnComponent>;
    "ma-collapse": PartialFormElementType<CollapseComponent>;
    "ma-row": PartialFormElementType<RowComponent>;
    "ma-input": PartialFormElementType<InputComponent>;
    "ma-textarea": PartialFormElementType<TextareaComponent>;
    "ma-checkbox": PartialFormElementType<CheckboxComponent>;
    "ma-switcher": PartialFormElementType<SwitcherComponent>;
    "ma-datepicker": PartialFormElementType<MetaFormDatepickerComponent>;
    "ma-progress": PartialFormElementType<MetaFormProgressComponent>;
    "ma-select": PartialFormElementType<MetaFormSelectComponent>;
    "ma-autocomplete": PartialFormElementType<MetaAutocompleteComponent>;
    "ma-section": PartialFormElementType<MetaFormSectionComponent>;
    "ma-tabs": PartialFormElementType<TabsComponent>;
    "ma-tab": PartialFormElementType<TabComponent>;
    "ma-card": PartialFormElementType<CardComponent>;
    "ma-group": PartialFormElementType<GroupComponent>;
    "ma-stat": PartialFormElementType<MetaFormStatisticComponent>;
    "ma-chart-multi-series": PartialFormElementType<ChartMultiSeriesComponent>;
    "ma-chart-single-series": PartialFormElementType<ChartSingleSeriesComponent>;
    "ma-chart-map": PartialFormElementType<ChartMapComponent>;
    "ma-chart-gauge": PartialFormElementType<ChartGaugeComponent>;
    "ma-map": PartialFormElementType<MetaFormMapComponent>;
    "ma-heatmap": PartialFormElementType<MetaFormHeatmapComponent>;
    "ma-barcode": PartialFormElementType<BarcodeComponent>;
    "ma-list-input": PartialFormElementType<ListInputComponent>;
    "ma-activity": PartialFormElementType<ActivityComponent>;
    "ma-multiselect": PartialFormElementType<MetaFormMultiSelectComponent>;
    "ma-h1": MaHeadline;
    "ma-h2": MaHeadline;
    "ma-h3": MaHeadline;
    "ma-h4": MaHeadline;
    "ma-hseparator": MaHeadline;
    "ma-p": MaHeadline;
    "ma-span": PartialFormElementType<MetaFormSectionComponent>;
    "ma-strong": MaHeadline;
    "ma-br": MaHeadline;
    "ma-hr": MaHorizontalRule;
    "ma-image": PartialFormElementType<ImageComponent>;
    "ma-image-gallery": PartialFormElementType<ImageGalleryComponent>;
    "ma-link": MaHeadline;
    "ma-comment": MaHeadline;
    "ma-if": MaIf;
    "ma-no-output": PartialFormElementType<NoOutputComponent>;
    "ma-tag": PartialFormElementType<TagComponent>;
    "ma-infobox": PartialFormElementType<MetaFormInfoboxComponent>;
    "ma-filters": PartialFormElementType<MetaFormFilterGroup>;
    "ma-input-filter": PartialFormElementType<MetaFormInputFilter>;
    "ma-select-filter": PartialFormElementType<MetaFormSelectFilter>;
    "ma-boolean-filter": PartialFormElementType<MetaFormBooleanFilter>;
    "ma-date-filter": PartialFormElementType<MetaFormDateFilter>;
    "ma-date-time-filter": PartialFormElementType<MetaFormDateTimeFilter>;
    "ma-script-validator": PartialFormElementType<ScriptValidator>;
    "ma-sql": PartialFormElementType<SqlDataSource>;
    "ma-document": PartialFormElementType<DocumentManager>;
    "ma-specifications": PartialFormElementType<SpecificationsComponent>;
    "ma-specification-group-combinations": PartialFormElementType<SpecificationGroupCombinationsComponent>;
    "ma-wizard": PartialFormElementType<WizardComponent>;
    "ma-wizard-step": PartialFormElementType<WizardStepComponent>;
    "ma-upload": PartialFormElementType<UploadComponent<any>>;
    "ma-editor": PartialFormElementType<EditorComponent<any>>;
    "ma-relation": PartialFormElementType<SqlRelation>;
    "ma-button": PartialFormElementType<MetaFormButtonComponent>;
    "ma-drop-area": PartialFormElementType<MetaDropAreaComponent>;
    "ma-tree-select": PartialFormElementType<TreeSelectComponent>;
    "ma-list-item": PartialFormElementType<ListItemComponent>;
    "ma-pipeline": PartialFormElementType<Pipeline>;
    "ma-progress-input": PartialFormElementType<MetaFormProgressInputComponent>;
    "ma-diagram": PartialFormElementType<MetaDiagramComponent>;
    "ma-split-view": PartialFormElementType<SplitView>;
    "ma-split-view-master": PartialFormElementType<SplitViewMasterComponent>;
    "ma-split-view-detail": PartialFormElementType<SplitViewDetailComponent>;
    "ma-tree-view": PartialFormElementType<TreeViewComponent>;
    "ma-calculation": PartialFormElementType<MetaCalculationComponent>;
    "ma-calculation-templates": PartialFormElementType<MetaCalculationTemplatesComponent>;
    "ma-address": PartialFormElementType<AddressComponent>;
    "ma-document-view": PartialFormElementType<DocumentViewComponent>;
    "ma-subform": PartialFormElementType<MetaSubformComponent>;
    "ma-slider": PartialFormElementType<MetaFormSliderComponent>;
    "ma-container": PartialFormElementType<ContainerComponent>;
    "ma-files": PartialFormElementType<FilesComponent>;
    "ma-icon": PartialFormElementType<IconComponent>;
    "ma-webview": PartialFormElementType<WebviewComponent>;
    "ma-radio": PartialFormElementType<MetaFormRadioComponent>;
    "ma-task-create": PartialFormElementType<TaskCreateComponent>;
    "ma-task-view": PartialFormElementType<TaskViewComponent>;
    "ma-gantt": PartialFormElementType<MetaGanttComponent>;
    "ma-scheduler": PartialFormElementType<MetaSchedulerComponent>;
    "ma-scheduler-filter": FormElementType<SchedulerFilter>;
    "ma-field-wrapper": FormElementType<FieldWrapperComponent>;
    // Validators
    "ma-validator-is-string": PartialFormElementType<IsStringValidatorOptions>;
    "ma-validator-matches": PartialFormElementType<MatchesValidator>;
    "ma-length-validator": PartialFormElementType<Length>;
    "ma-min-validator": PartialFormElementType<MinValidator>;
    "ma-max-validator": PartialFormElementType<MaxValidator>;
    "ma-is-email-validator": PartialFormElementType<IsEmailValidator>;
    "ma-is-iban-validator": PartialFormElementType<IsIbanValidator>;
    "ma-is-bic-validator": PartialFormElementType<IsBicValidator>;
    "ma-is-phone-validator": PartialFormElementType<IsPhoneNumber>;
    "ma-is-currency-validator": PartialFormElementType<IsCurrency>;
    "ma-is-url-validator": PartialFormElementType<IsURL>;
    "ma-is-locale-validator": Partial<IsLocaleValidator>;
    "ma-is-gtin-validator": Partial<IsGtin>;
    "ma-is-pzn-validator": Partial<IsPznValidator>;
    "ma-is-iso31661-alpha2-validator": Partial<IsISO31661Alpha2>;
  }
}

export function createFormElement(tagName: string, attributes: any, ..._children: any[]) {
  _children = _.flatten(_children);
  if (tagName === "ma-h1") {
    const section = new MetaFormSectionComponent();
    section.sectionType = MetaSectionType.headline1;
    Object.assign(section, attributes);
    if (typeof _children[0] === "function") {
      section.displayValue = _children[0];
    } else {
      section.displayValue = _children.map((e) => e.toString()).join("");
    }
    return section;
  }
  if (tagName === "ma-h2") {
    const section = new MetaFormSectionComponent();
    section.sectionType = MetaSectionType.headline2;
    Object.assign(section, attributes);
    if (typeof _children[0] === "function") {
      section.displayValue = _children[0];
    } else {
      section.displayValue = _children.map((e) => e.toString()).join("");
    }
    return section;
  }
  if (tagName === "ma-h3") {
    const section = new MetaFormSectionComponent();
    section.sectionType = MetaSectionType.headline3;
    Object.assign(section, attributes);

    if (typeof _children[0] === "function") {
      section.displayValue = _children[0];
    } else {
      section.displayValue = _children.map((e) => e.toString()).join("");
    }

    return section;
  }
  if (tagName === "ma-h4") {
    const section = new MetaFormSectionComponent();
    section.sectionType = MetaSectionType.headline4;
    Object.assign(section, attributes);
    if (typeof _children[0] === "function") {
      section.displayValue = _children[0];
    } else {
      section.displayValue = _children.map((e) => e.toString()).join("");
    }
    return section;
  }
  if (tagName === "ma-hseparator") {
    const section = new MetaFormSectionComponent();
    section.sectionType = MetaSectionType.headlineSeparator;
    Object.assign(section, attributes);
    if (typeof _children[0] === "function") {
      section.displayValue = _children[0];
    } else {
      section.displayValue = _children.map((e) => e.toString()).join("");
    }
    return section;
  }
  if (tagName === "ma-p") {
    const section = new MetaFormSectionComponent();
    section.sectionType = MetaSectionType.paragraph;
    Object.assign(section, attributes);
    if (typeof _children[0] === "function") {
      section.displayValue = _children[0];
    } else {
      section.displayValue = _children.map((e) => e.toString()).join("");
    }
    return section;
  }
  if (tagName === "ma-span") {
    const section = new MetaFormSectionComponent();
    section.sectionType = MetaSectionType.span;
    Object.assign(section, attributes);
    if (typeof _children[0] === "function") {
      section.displayValue = _children[0];
    } else {
      section.displayValue = _children.map((e) => e.toString()).join("");
    }
    return section;
  }
  if (tagName === "ma-strong") {
    const section = new MetaFormSectionComponent();
    section.sectionType = MetaSectionType.strong;
    Object.assign(section, attributes);
    section.displayValue = _children.map((e) => e.toString()).join("");
    return section;
  }
  if (tagName === "ma-br") {
    const section = new MetaFormSectionComponent();
    section.sectionType = MetaSectionType.space;
    Object.assign(section, attributes);
    return section;
  }
  if (tagName === "ma-hr") {
    const section = new MetaFormSectionComponent();
    section.sectionType = MetaSectionType.horizontalLine;
    Object.assign(section, attributes);
    if (attributes) {
      section.style = attributes["style"];
    }
    return section;
  }
  if (tagName === "ma-a") {
    const section = new MetaFormSectionComponent();
    section.sectionType = MetaSectionType.link;
    Object.assign(section, attributes);
    section.link = attributes["link"];
    return section;
  }

  if (tagName === "ma-comment") {
    return null;
  }
  if (tagName === "ma-if") {
    const container = new ContainerComponent();
    container.children = [..._children];
    container.condition = attributes.condition;
    return container;
  }

  const children = _children.filter((e) => e !== null);
  const factory = ComponentFactories.get(tagName);

  if (factory) {
    if (!factory.createElement) {
      console.error(`No Factory "createElement" method Found for ${tagName}`);
      return null;
    }
    const ele = factory.createElement(attributes, children);
    if (ele instanceof MetaForm) {
      MetaForm.addForm(ele);
      MetaForm.internalForms.add(ele);
    }

    return ele;
  } else if (typeof tagName === "function") {
    return (tagName as any)();
  } else {
    console.error(`No Factory Found for ${tagName}`);
    return null;
  }
}

globalThis["createFormElement"] = createFormElement;
