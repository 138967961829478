<!--
  ~ Copyright (C) MetaCarp GmbH - All Rights Reserved
  ~ Unauthorized copying of this file, via any medium is strictly prohibited
  ~ Proprietary and confidential
  ~ Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
  ~ Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
  -->

<ng-template let-value="value" #tooltipTemplate>
  <div [innerHTML]="value"></div>
</ng-template>
<ng-template let-items let-extra="extra" #searchDescriptionTemplate>
  <div class="desc-container">
    <ng-container *ngFor="let item of items | keyvalue">
      <ng-container *ngIf="item.value | descriptionType as type">
        <div class="desc-item">
          <ng-template #val>
            <div
              nz-tooltip
              [nzTooltipTitle]="tooltipTemplate"
              [nzTooltipTitleContext]="item"
              class="desc-value"
              *ngIf="type === 'string'"
              [innerHTML]="item.value"
            ></div>
            <div class="desc-value" *ngIf="type === 'boolean'">{{ item.value ? "Ja" : "Nein" }}</div>
            <div class="desc-value" *ngIf="type === 'object'">
              <ng-container
                *ngTemplateOutlet="
                  searchDescriptionTemplate;
                  context: { $implicit: item.value, extra: extra?.[item.key] }
                "
              ></ng-container>
            </div>
            <div class="desc-value" *ngIf="type === 'array'">
              <mark
                (click)="tagClick($event, item.value[i])"
                *ngFor="let t of item.value; let i = index"
                [innerHTML]="t"
                style="margin-right: 7px"
              ></mark>
            </div>
          </ng-template>
          <div class="desc-label">{{ item.key }}</div>
          @if (extra?.[item.key]?.link; as link) {
            @if (link.includes(":")) {
              <a [href]="link">
                <ng-container *ngTemplateOutlet="val"></ng-container>
              </a>
            } @else {
              <a [routerLink]="link">
                <ng-container *ngTemplateOutlet="val"></ng-container>
              </a>
            }
          } @else {
            <ng-container *ngTemplateOutlet="val"></ng-container>
          }
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
<div class="search-container">
  @if (showSidebar) {
    @if (result$ | async; as result) {
      <div class="search-sidebar">
        @if (result.facetDistribution.tags; as tags) {
          @defer (on idle) {
            <ul style="max-height: 50%">
              @for (tag of tags | keyvalue: compareFn; track $index) {
                <li style="padding: 4px">
                  <label
                    [ngModel]="form.controls.tags.value.includes(tag.key)"
                    (ngModelChange)="toggleTagFilter(tag.key)"
                    nz-checkbox
                    >{{ tag.key }} ({{ tag.value }})</label
                  >
                </li>
              }
            </ul>
          }
        }
      </div>
    }
  }

  <div class="search-container-inner">
    <div [formGroup]="form" class="search-input-wrapper">
      <i class="fas fa-search search-icon"></i>
      <input
        (keyup.arrowDown)="selectItem(1, $event)"
        (keyup.arrowUp)="selectItem(-1, $event)"
        (keyup.enter)="openFocused()"
        placeholder="{{ form.value?.categories | filterDisplay: true }}"
        formControlName="q"
        #inputElement
      />
      <span class="categories-text" *ngIf="form.get('q').value">{{
        form.value?.categories | filterDisplay: false
      }}</span>
      <ng-template #filterTemplate>
        <div style="display: flex; gap: 9px; flex-direction: column">
          <nz-select
            [nzMaxTagCount]="9"
            formControlName="categories"
            nzMode="multiple"
            nzPlaceHolder="Kategorien filtern…"
            style="width: 200px"
            [compareWith]="compareWith"
            [nzAllowClear]="true"
            [nzBackdrop]="true"
          >
            @for (item of types$ | async; track item.value) {
              <nz-option
                [nzLabel]="'entities.' + item.value + '.name_singular' | lowercase | translate: item.label"
                [nzValue]="item"
              ></nz-option>
            }
          </nz-select>
          <div [formGroup]="form" *ngIf="tagFiltering">
            <nz-select formControlName="tags" style="width: 100%" nzMode="tags" nzPlaceHolder="Tags filtern…">
              <nz-option
                *ngFor="let option of facetDistribution?.tags | facetedToSelect"
                [nzLabel]="option.label"
                [nzValue]="option.value"
              ></nz-option>
            </nz-select>
          </div>
        </div>
      </ng-template>
      <div
        [nzPopoverBackdrop]="true"
        [ngClass]="{ 'has-active-filter': form.value?.categories?.length > 0 || form.value?.tags?.length > 0 }"
        nzPopoverTrigger="click"
        nz-popover
        nzPopoverPlacement="bottom"
        [nzPopoverContent]="filterTemplate"
        role="button"
        class="filter-button"
      >
        <i class="fal fa-bars-filter"></i>
      </div>

      <div class="close-btn" (click)="close()" role="button"><i class="fal fa-times"></i></div>
    </div>

    <ng-container>
      <ng-container *ngIf="result$ | async as result">
        <ul>
          @for (item of result.result; track item.id) {
            <li [ngClass]="{ selected: selectedIndex === $index, favorite: item.favorite }" [class]="item.class">
              <ng-template #itemContent>
                <img
                  loading="lazy"
                  class="previewImage"
                  *ngIf="item.imageUrl; else noImageTemplate"
                  alt="logo"
                  [src]="item.imageUrl"
                />
                <ng-template #noImageTemplate>
                  <div [ngStyle]="{ 'background-color': item.typeColor }" class="previewImage">
                    <i class="fal fa-{{ item.icon }}"></i>
                  </div>
                </ng-template>
                <div class="item-title">
                  <div class="title">
                    <span class="type" [innerHTML]="item._formatted?.typeName || item.typeName"></span>
                    <b
                      class="type"
                      *ngIf="item.displayId"
                      [innerHTML]="item._formatted?.displayId || item.displayId || ''"
                    ></b>
                  </div>
                  <div class="title">
                    <span [innerHTML]="item._formatted?.titel || item.titel"></span>
                  </div>
                  <div class="tags" *ngIf="item._formatted?.tags">
                    @for (t of $any(item._formatted.tags); track $index) {
                      <mark (click)="tagClick($event, item.tags[$index])" [innerHTML]="t"></mark>
                    }
                  </div>
                  @if(item.favorite !== undefined) {
                    <button
                      (click)="$event.stopPropagation(); toggleFavorite(item)"
                      class="fav-btn"
                      nzSize="small"
                      nzType="link"
                      nz-button
                    >
                      @if (item.favorite) {
                        <i class="fas fa-star"></i>
                      } @else {
                        <i class="fal fa-star"></i>
                      }
                    </button>
                  }
                </div>
              </ng-template>
              <div
                *ngIf="item.type !== 'category'"
                (click)="searchService.hideSearch()"
                [routerLink]="item.link | argonRouterLink: 'url'"
                [queryParams]="item.link | argonRouterLink: 'params'"
                class="item-header"
              >
                <ng-container *ngTemplateOutlet="itemContent"></ng-container>
              </div>

              <div *ngIf="item.type === 'category'" (click)="itemClick(item)" class="item-header">
                <ng-container *ngTemplateOutlet="itemContent"></ng-container>
              </div>
              <div *ngIf="item.description || item.imageUrl" class="description">
                <ng-container *ngIf="item.description | descriptionType as type">
                  @if (type === "string") {
                    <p [innerHTML]="item._formatted?.description || item.description"></p>
                  } @else {
                    <ng-container
                      *ngTemplateOutlet="
                        searchDescriptionTemplate;
                        context: {
                          $implicit: item._formatted.description,
                          extra: item.descriptionExtra,
                          size: 4,
                          layout: 'vertical'
                        }
                      "
                    ></ng-container>
                  }
                </ng-container>
              </div>
              @if (item._formatted?.properties; as props) {
                @if (showProperties) {
                  <div class="properties-title">
                    Eingenschaften
                    <button nzSize="small" nzType="link" nz-button (click)="showProperties = false">
                      Eigenschaften Ausblenden <i class="fal fa-chevron-up"></i>
                    </button>
                  </div>
                  @defer (on viewport) {
                    <ng-container
                      *ngTemplateOutlet="
                        searchDescriptionTemplate;
                        context: {
                          $implicit: item._formatted.properties,
                          size: 4,
                          layout: 'vertical'
                        }
                      "
                    ></ng-container>
                  } @placeholder {
                    <div></div>
                  }
                } @else {
                  <button nzSize="small" nzType="link" nz-button (click)="showProperties = true">
                    Eigenschaften Anzeigen <i class="fal fa-chevron-down"></i>
                  </button>
                }
              }
            </li>
          }
        </ul>
        <div *ngIf="result.result.length === 0" class="no-result">
          <span>{{ "search.no_results" | translate }}</span>
        </div>

        <div *ngIf="result.result.length > 0" class="result-count">
          <nz-pagination
            [nzPageSizeOptions]="[5, 10, 25, 50]"
            [(nzPageIndex)]="pageIndex"
            (nzPageIndexChange)="pageIndexChange($event)"
            [nzTotal]="result.estimatedTotalHits"
            nzShowSizeChanger
            [(nzPageSize)]="hitsPerPage"
            (nzPageSizeChange)="pageSizeChange()"
          ></nz-pagination>
        </div>
        <div *ngIf="result.error" class="result-error">{{ result.error }}</div>
      </ng-container>
    </ng-container>
  </div>
</div>
