<ng-container *ngIf="userService.userSignal() as u">
  <ng-template #msgTpl>
    Du arbeitest derzeit in Vertretung für <b>{{ u.vorName }} {{ u.nachName }}</b>
  </ng-template>
  <ng-template #actionTpl>
    <button nz-button nzSize="small" nzType="primary" nzGhost (click)="switchUser(u.primaryUser)">Abmelden</button>
  </ng-template>
  @if (u.primaryUser) {
    <nz-alert [nzAction]="actionTpl" nzBanner nzCloseable [nzMessage]="msgTpl"></nz-alert>
  }

  @if (userService.userFeatures()?.chatbot && breakpoint() !== "xs") {
    <meta-chat-button />
  }
</ng-container>

<ng-template #statusTooltip>
  @if (appConfigService.appConfigSignal(); as config) {
    Host: {{ config.hostname }}
    <br />
    Version: {{ config.versionInfo.version }}
    <br />
    Ref: #{{ config.versionInfo.gitRef }}
    <br />
    Datum: {{ config.versionInfo.buildAt | date: "short" }}
    <ng-container *ngIf="config.versionInfo.buildNum">
      <br />
      Build: {{ config.versionInfo.buildNum || "-" }}
    </ng-container>
  }
</ng-template>
<ng-container *ngIf="useBlankLayout || maLayout === 'blank'; else elseTpl">
  <ng-container *ngTemplateOutlet="router"></ng-container>
</ng-container>
<ng-template #elseTpl>
  <ng-container [ngSwitch]="layoutOverride || layout">
    <!-- VERTICAL LAYOUT -->
    <nz-layout *ngSwitchCase="layouts.vertical" class="layout-vertical">
      <nz-header>
        <div class="logo">
          <a [routerLink]="'/'">
            <meta-brand [theme]="'dark'"></meta-brand>
          </a>
        </div>
        <ng-container *ngTemplateOutlet="menuTemplate; context: { layout: 'horizontal' }"></ng-container>
        <ng-container *ngTemplateOutlet="userActions"></ng-container>
        <ng-container *ngTemplateOutlet="userInfoTemplate"></ng-container>
      </nz-header>
      <nz-content>
        <ng-container *ngTemplateOutlet="formHeaderTpl"></ng-container>
        <div class="inner-content">
          <ng-container *ngTemplateOutlet="router"></ng-container>
        </div>
      </nz-content>
    </nz-layout>
    <!-- VERTICAL BOXED LAYOUT -->
    <nz-layout
      *ngSwitchCase="layouts.vertical_boxed"
      class="layout-vertical layout-vertical-boxed"
      [ngClass]="{ 'meta-absolute-layout': absoluteLayout }"
    >
      <nz-header>
        <div class="logo">
          <a [routerLink]="'/'">
            <meta-brand [theme]="'dark'"></meta-brand>
          </a>
        </div>
        <ng-container *ngTemplateOutlet="menuTemplate; context: { layout: 'horizontal' }"></ng-container>
        <ng-container *ngTemplateOutlet="userActions"></ng-container>
        <ng-container *ngTemplateOutlet="userInfoTemplate"></ng-container>
      </nz-header>
      <nz-content>
        <ng-container *ngTemplateOutlet="formHeaderTpl"></ng-container>
        <div class="inner-content">
          <ng-container *ngTemplateOutlet="router"></ng-container>
        </div>
      </nz-content>
    </nz-layout>
    <!-- HORIZONTAL LAYOUT -->
    <nz-layout
      *ngSwitchCase="layouts.horizontal"
      [ngClass]="{ 'layout-sider-collapsed': isCollapsed, 'meta-absolute-layout': absoluteLayout }"
      class="layout-horizontal"
    >
      @if (userService.user?.value?.id) {
        <nz-sider
          nzCollapsible
          [nzCollapsed]="isCollapsed"
          [nzCollapsedWidth]="0"
          [nzWidth]="'292px'"
          class="xs-show sider-left sider-left-mobile"
          (nzCollapsedChange)="toggleCollapse($event, true)"
        >
          <div class="logo expanded">
            <meta-brand [theme]="currentTheme" [maType]="'logo'"></meta-brand>
            @if (appConfigService.appConfigSignal(); as config) {
              <div nz-tooltip [nzTooltipTitle]="statusTooltip" class="version">
                {{ majorVersion() }}
              </div>
            }
          </div>
          <ng-container *ngTemplateOutlet="userInfoTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="menuTemplate; context: { layout: 'inline' }"></ng-container>
          <ng-container *ngIf="enableSearchFeature">
            <ng-container *ngTemplateOutlet="searchTemplate"></ng-container>
          </ng-container>
        </nz-sider>
        <nz-sider
          nzCollapsible
          [nzTrigger]="menu === 'fixed' ? undefined : null"
          [(nzCollapsed)]="isCollapsed"
          [nzCollapsedWidth]="88"
          [nzWidth]="'292px'"
          class="sider-left xs-hidden"
          (nzCollapsedChange)="toggleCollapse($event)"
          (mouseenter)="addHoverClass()"
          (mouseleave)="removeHoverClass()"
        >
          <div [ngClass]="{ collapsed: isCollapsed, expanded: !isCollapsed }" class="logo">
            <a [routerLink]="'/'">
              <meta-brand [theme]="'dark'" [maType]="isCollapsed ? 'logoIcon' : 'logo'"></meta-brand>
            </a>
            @if (appConfigService.appConfigSignal(); as config) {
              <div
                (click)="copyVersion()"
                nz-tooltip
                [nzTooltipTitle]="statusTooltip"
                *ngIf="!isCollapsed"
                class="version"
                role="button"
              >
                {{ majorVersion() }}
              </div>
            }
          </div>
          <ng-container *ngTemplateOutlet="userInfoTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="userActions"></ng-container>

          <ng-container *ngTemplateOutlet="menuTemplate; context: { layout: 'inline' }"></ng-container>
          <ng-container *ngIf="enableSearchFeature">
            <ng-container *ngTemplateOutlet="searchTemplate"></ng-container>
          </ng-container>
        </nz-sider>
      }
      <nz-layout class="layout-right" [style.marginLeft.px]="menu === 'dynamic' ? 96 : isCollapsed ? 96 : 300">
        <ng-container *ngTemplateOutlet="formHeaderTpl"></ng-container>
        <nz-content>
          <div class="inner-content">
            <ng-container *ngTemplateOutlet="router"></ng-container>
          </div>
        </nz-content>
      </nz-layout>
    </nz-layout>
    <ng-container *ngSwitchCase="layouts.blank">
      <ng-container *ngTemplateOutlet="router"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #formHeaderTpl>
  <meta-form-header headerType="page" targetId="default" />
</ng-template>

<ng-template #menuTemplate let-layout="layout">
  <ul [nzInlineCollapsed]="isCollapsed && layout === 'inline'" [nzMode]="layout" id="menu" nz-menu nzTheme="dark">
    <ng-container *ngIf="layout === 'inline'; else hLayout">
      <ng-container
        *ngTemplateOutlet="
          submenuTemplate;
          context: {
            $implicit:
              (metaLayoutService.entryId()
              | getChildMenuItems: metaLayoutService.menu() : (isCollapsed ? '' : searchValue)),
            level: 1
          }
        "
      ></ng-container>
    </ng-container>
    <ng-template #hLayout>
      <li nz-submenu [routerLink]="'/'" nzTitle="Übersicht">
        <a [routerLink]="'/'"><span class="title">Übersicht</span></a>
      </li>
      <li nz-submenu nzTitle="Hauptmenü">
        <ul class="subpanel">
          <ng-container *ngTemplateOutlet="searchTemplate"></ng-container>
          <div class="subpanel-grid" [class.subpanel-grid-search]="searchValue?.length > 0">
            <ng-container
              *ngTemplateOutlet="
                subpanelTemplate;
                context: {
                  $implicit: (metaLayoutService.entryId() | getChildMenuItems: metaLayoutService.menu() : searchValue),
                  level: 1
                }
              "
            ></ng-container>
          </div>
        </ul>
      </li>
      <li nz-submenu nzTitle="Favoriten">
        <ul class="subpanel-fav">
          <ng-container *ngTemplateOutlet="subpanelFavTemplate"></ng-container>
        </ul>
      </li>
    </ng-template>

    <ng-template #submenuTemplate let-items let-level="level">
      @for (item of items; track item.id) {
        <li
          *ngIf="(item.id | getChildMenuItems: metaLayoutService.menu()).length == 0"
          nz-menu-item
          [nzPaddingLeft]="isCollapsed ? 0 : level * 25"
          nzMatchRouter
          nz-tooltip
          [nzTooltipTitle]="item.description"
          nzTooltipPlacement="right"
        >
          <a *ngIf="item.path" [routerLink]="item.path"
            ><i *ngIf="item.icon && level === 1" class="fa-light fa-{{ item.icon }} mr-2"></i
            ><span class="title">{{ item.label }}</span></a
          >
          <span *ngIf="!item.path"
            ><i *ngIf="item.icon && level === 1" class="fa-light fa-{{ item.icon }} mr-2"></i
            ><span class="title">{{ item.label }}</span></span
          >
        </li>
        <li
          *ngIf="(item.id | getChildMenuItems: metaLayoutService.menu()).length > 0"
          [nzTitle]="titleTemplate"
          [nzPaddingLeft]="isCollapsed ? 0 : level * 25"
          nz-submenu
        >
          <ul>
            <ng-container
              *ngTemplateOutlet="
                submenuTemplate;
                context: {
                  $implicit: (item.id | getChildMenuItems: metaLayoutService.menu()),
                  level: level + 1
                }
              "
            ></ng-container>
          </ul>
          <ng-template #titleTemplate>
            <i *ngIf="item.icon" class="fa-light fa-{{ item.icon }} mr-2"></i
            ><span class="title">{{ item.label }}</span>
          </ng-template>
        </li>
      }
    </ng-template>

    <ng-template #subpanelTemplate let-items let-level="level">
      <li class="ant-menu-item ant-menu-item-subpanel" *ngIf="level === 1">
        <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
        <ul>
          @for (item of items; track item.id) {
            <li
              *ngIf="(item.id | getChildMenuItems: metaLayoutService.menu()).length == 0"
              nz-menu-item
              [nzPaddingLeft]="isCollapsed ? 0 : level * 25"
              nzMatchRouter
            >
              <a *ngIf="item.path" [routerLink]="item.path"
                ><span class="title">{{ item.label }}</span></a
              >
              <span *ngIf="!item.path"
                ><span class="title">{{ item.label }}</span></span
              >
            </li>
          }
        </ul>
        <ng-template #titleTemplate>
          <i class="fa-light fa-planet-moon mr-2"></i><span class="title">Allgemein</span>
        </ng-template>
      </li>
      @for (item of items; track item.id) {
        <li
          *ngIf="(item.id | getChildMenuItems: metaLayoutService.menu()).length == 0 && level > 1"
          nz-menu-item
          [nzPaddingLeft]="isCollapsed ? 0 : level * 25"
          nzMatchRouter
        >
          <a *ngIf="item.path" [routerLink]="item.path"
            ><i *ngIf="item.icon && level === 1" class="fa-light fa-{{ item.icon }} mr-2"></i
            ><span class="title">{{ item.label }}</span></a
          >
          <span *ngIf="!item.path"
            ><i *ngIf="item.icon && level === 1" class="fa-light fa-{{ item.icon }} mr-2"></i
            ><span class="title">{{ item.label }}</span></span
          >
        </li>
        <li
          *ngIf="(item.id | getChildMenuItems: metaLayoutService.menu()).length > 0"
          class="ant-menu-item ant-menu-item-subpanel"
        >
          <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
          <ul>
            <ng-container
              *ngTemplateOutlet="
                subpanelTemplate;
                context: {
                  $implicit: (item.id | getChildMenuItems: metaLayoutService.menu()),
                  level: level + 1
                }
              "
            ></ng-container>
          </ul>
          <ng-template #titleTemplate>
            <i *ngIf="item.icon" class="fa-light fa-{{ item.icon }} mr-2"></i
            ><span class="title">{{ item.label }}</span>
          </ng-template>
        </li>
      }
    </ng-template>

    <ng-template #subpanelFavTemplate let-level="level">
      <meta-empty
        [maParams]="{
          description: 'Keine Favoriten vorhanden',
          icon: 'cloud-hail-mixed'
        }"
      ></meta-empty>
    </ng-template>
  </ul>
</ng-template>

<ng-template #router>
  <ng-content></ng-content>
</ng-template>

<ng-template #searchTemplate>
  <ng-template #suffixIconSearch>
    <i class="fal fa-search"></i>
  </ng-template>
  <div class="search-input-container" *ngIf="!isCollapsed || (layoutOverride || layout) !== layouts.horizontal">
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input [(ngModel)]="searchValue" type="text" nz-input placeholder="{{ 'menu.filter.placeholder' | translate }}" />
    </nz-input-group>
  </div>
</ng-template>

<ng-template #userInfoTemplate>
  <div class="user-info-wrapper">
    <div *ngIf="userService.userSignal() as user" class="user-info-container">
      <div class="avatar">
        <meta-avatar
          [hideTooltip]="true"
          [maParams]="{ id: user.id, type: 'ANW', size: 'large', label: user.vorName + ' ' + user.nachName }"
        ></meta-avatar>
      </div>
      <div class="user-info-data">
        <div class="user-info-data-name">
          {{ user.vorName }} {{ user.nachName }}<br />
          <span>{{ user.company }}</span>
        </div>
        <div class="user-info-data-company">{{ user.position }}</div>
      </div>
      <a href="/api/v2/auth/logout" class="user-info-logout">
        <i class="fal fa-sign-out"></i>
      </a>
    </div>
  </div>
</ng-template>

<ng-template #userActions>
  <div class="user-actions">
    <nz-badge [nzCount]="notificationCenter.notificationCenterUnread$ | async">
      <meta-button
        (click)="notificationCenter.toggleNotificationCenter()"
        [maParams]="{ icon: 'bell', type: 'link', iconStyle: 'fa-solid' }"
        class="notification"
      ></meta-button>
    </nz-badge>
    <meta-button
      [maParams]="{
        icon: 'calendar',
        type: 'link',
        iconStyle: 'fa-solid',
        disabled: !userService.userFeatures()?.calendar
      }"
    ></meta-button>
    <meta-button
      (click)="notificationCenter.showNotificationCenter(1)"
      [maParams]="{ icon: 'list-check', type: 'link', iconStyle: 'fa-solid' }"
    ></meta-button>
    <meta-button
      (click)="openSearch()"
      [maParams]="{ icon: 'magnifying-glass', type: 'link', iconStyle: 'fa-solid' }"
    ></meta-button>
    <meta-button
      [maParams]="{ icon: 'gear', type: 'link', iconStyle: 'fa-solid' }"
      (click)="openSettings()"
    ></meta-button>
  </div>
</ng-template>
