/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, Input, OnInit } from "@angular/core";
import { IFeedResultItem } from "@meta/api-interfaces";
import { MetaHelperService } from "../../../services/metaHelper.service";

export class MetaTimelineTemplateWorkflowExecutionError {
  data: IFeedResultItem;
}

@Component({
  selector: "meta-timeline-template-workflow-execution-error",
  template: ` <meta-translate key="timeline.workflow-execution-error">
      <ng-template meta-translate-child key="workflowTag">
        <a [href]="item.data.entity.link">
          <meta-tag [maParams]="{ label: tplLabel, color: color, colorText: colorText, maxWidth: 250 }"></meta-tag>
        </a>
      </ng-template>
      <ng-template meta-translate-child key="error">
        <span class="log-danger">Fehler</span>
      </ng-template>
    </meta-translate>

    <ng-template #tplLabel
      ><i class="far fa-{{ item.data.entity.icon }}"></i>{{ item.data.entity.title }}</ng-template
    >`,
})
export class MetaTimelineTemplateWorkflowExecutionErrorComponent implements OnInit {
  @Input() public item: MetaTimelineTemplateWorkflowExecutionError;

  public color: string;
  public colorText: string;

  constructor(public metaHelperService: MetaHelperService) {}

  ngOnInit() {
    this.color = this.metaHelperService.generateColor(this.item.data.entity.type);
    this.colorText = getComputedStyle(document.documentElement).getPropertyValue("--component-background");
  }
}
