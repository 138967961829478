/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, NgModule, OnInit, ViewEncapsulation } from "@angular/core";
import { MetaButtonModule, MetaDrodpownModule } from "@meta/ui";
import { FormlyModule } from "@ngx-formly/core";
import * as _ from "lodash";
import { NzListModule } from "ng-zorro-antd/list";
import { firstValueFrom } from "rxjs";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { IMetaMediaLibrarySelectFileOption } from "../metaMediaLibrary/interfaces";
import { MetaMediaLibraryService } from "../metaMediaLibrary/metaMediaLibrary.service";

export class MetaFiles {
  itemId?: string;
  entityType?: string;
  onAddFiles?: (files: any[]) => void;
  onRemoveFiles?: (files: any[]) => void;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-files",
  template: `
    <nz-list *ngIf="field" nzItemLayout="horizontal">
      @for (item of formControl.value; track item) {
        <nz-list-item>
          <nz-list-item-meta [nzAvatar]="avatarTpl" [nzDescription]="null">
            <nz-list-item-meta-title>
              <a (click)="_metaMediaLibraryService.previewFileById(item)" target="_blank">{{
                displayData?.values[$index]?.name || item
              }}</a>
            </nz-list-item-meta-title>
          </nz-list-item-meta>
          <ng-template #avatarTpl>
            <i class="fas fa-file"></i>
          </ng-template>
          <ul nz-list-item-actions>
            <nz-list-item-action>
              <meta-dropdown
                [maParams]="{
                  icon: 'ellipsis-h',
                  iconStyle: 'fas',
                  size: 'small',
                  type: 'text',
                  items: [
                    {
                      label: 'Löschen',
                      icon: 'trash',
                      onClick: delete.bind(this, $index)
                    }
                  ]
                }"
              >
              </meta-dropdown>
            </nz-list-item-action>
          </ul>
        </nz-list-item>
      }
    </nz-list>
    <nz-list *ngIf="!field" nzItemLayout="horizontal">
      @for (item of items; track item) {
        <nz-list-item>
          <nz-list-item-meta [nzAvatar]="avatarTpl" [nzDescription]="null">
            <nz-list-item-meta-title>
              <a (click)="_metaMediaLibraryService.previewFileById(item.id)" target="_blank">{{ item.name }}</a>
            </nz-list-item-meta-title>
          </nz-list-item-meta>
          <ng-template #avatarTpl>
            <i class="fas fa-file"></i>
          </ng-template>
          <ul nz-list-item-actions>
            <nz-list-item-action>
              <meta-dropdown
                [maParams]="{
                  icon: 'ellipsis-h',
                  iconStyle: 'fas',
                  size: 'small',
                  type: 'text',
                  items: [
                    {
                      label: 'Löschen',
                      icon: 'trash',
                      onClick: delete.bind(this, $index)
                    }
                  ]
                }"
              >
              </meta-dropdown>
            </nz-list-item-action>
          </ul>
        </nz-list-item>
      }
    </nz-list>
    <meta-button
      [maParams]="{
        label: 'Dokumente hinzufügen',
        type: 'link',
        icon: 'plus'
      }"
      (click)="showMediaLibrary()"
    ></meta-button>
  `,
  styleUrls: ["./metaFIles.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaFilesComponent extends MetaComponentBase implements OnInit {
  public selectedItem: any[] = [];
  public items: any[];
  public loading: boolean[] = [];

  constructor(
    private _http: HttpClient,
    public readonly _metaMediaLibraryService: MetaMediaLibraryService,
  ) {
    super();
    super.maParams = new MetaFiles();
  }

  get ma(): MetaFiles {
    return super.ma;
  }

  public async ngOnInit() {
    super.ngOnInit();
    if (!this.field) {
      (this.items as any) = await this.getData();
    }
  }

  public async getSelected() {
    this.formControl.patchValue((this.formControl.value || []).concat(this.selectedItem));
    if (this.field) {
      this.form.markAsDirty();
    }
    (this.items as any) = await this.getData();
  }

  public onSelected(event: any) {
    if (event.length > 0) {
      this.selectedItem = event.map((file) => file.id);
    }
  }

  public delete(index: number) {
    const files = _.clone(this.field ? this.formControl.value : this.items);
    files.splice(index, 1);
    if (this.field) {
      this.formControl.patchValue(files);
      this.form.markAsDirty();
    } else {
      if (this.ma.onRemoveFiles instanceof Function) {
        this.ma.onRemoveFiles(files.map((e) => e.id));
      }
      this.items = files;
    }
  }

  public async showMediaLibrary() {
    const options: IMetaMediaLibrarySelectFileOption = {};
    const newFiles = await this._metaMediaLibraryService.selectMultipleFiles(options);
    const files = this.field ? ((this.form.get(this.id).value || []) as string[]) : this.items;
    files.push(...newFiles);
    if (this.field) {
      this.formControl.patchValue(Array.from(new Set(files)));
      this.form.markAsDirty();
    } else {
      if (this.ma.onAddFiles instanceof Function) {
        this.ma.onAddFiles(files.map((e) => e.id));
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  public async getData() {
    return await firstValueFrom(this._http.get(`linkedDocuments/${this.ma.entityType}/${this.ma.itemId}`));
  }

  public async refreshData() {
    (this.items as any) = await this.getData();
    this.changeDetectorRef.markForCheck();
  }
}

@NgModule({
  declarations: [MetaFilesComponent],
  imports: [CommonModule, FormlyModule, NzListModule, MetaButtonModule, MetaDrodpownModule],
  exports: [MetaFilesComponent],
})
export class MetaFilesModule {}
