/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2021
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2021
 * Written by Yannick Küchlin <y.kuechlin@taifun-tofu.de>, 2019-2020
 */

import {
  IsArray,
  IsBoolean,
  IsDateString,
  IsEnum, IsNumber,
  IsOptional,
  IsString
} from "class-validator";
import { EntityType } from "@meta/enums";


export enum ACTIVITY_TYP {
  NOTE = "NOTE",
  APPOINTMENT = "APPOINTMENT",
  REPORT = "REPORT",
}

export enum MetaActivityAppointment {
  call = "phone",
  meeting = "handshake",
  task = "tasks",
  deadline = "clock",
  email = "envelope",
  dinner = "utensils"
}

export class ActivitiesNoteRequest {
  @IsString()
  @IsOptional()
  Id: string;
  @IsString()
  quellId: string;
  @IsEnum(EntityType)
  quellTyp: EntityType;
  @IsString()
  note: string;
}

export class ActivitiesReportRequest {
  @IsString()
  quellId: string;
  @IsEnum(EntityType)
  quellTyp: EntityType;
  @IsString()
  report: string;
}

export class ActivitiesAppointmentRequest {
  @IsString()
  @IsOptional()

  quellId?: string;

  @IsEnum(EntityType)
  @IsOptional()

  quellTyp?: EntityType;

  @IsEnum(MetaActivityAppointment)

  appointmentType: MetaActivityAppointment;

  @IsString()

  title: string;

  @IsBoolean()

  isAppointment: boolean;

  @IsBoolean()

  isClosed: boolean;

  @IsDateString()
  @IsOptional()

  dateFrom?: string;

  @IsDateString()
  @IsOptional()

  dateTo?: string;

  @IsBoolean()

  fullDay: boolean;

  @IsString()

  @IsOptional()
  assignedto?: string;

  @IsArray()

  @IsOptional()
  participants?: string[];

  @IsString()

  @IsOptional()
  description?: string;

  @IsString()

  @IsOptional()
  location?: string;

  @IsString()

  @IsOptional()
  message?: string;

  @IsDateString()

  @IsOptional()
  deadline?: string;

  @IsNumber()

  @IsOptional()
  organisation?: number;
}

export class ActivitiesAppointmentDateTimeRequest {
  @IsDateString()
  dateFrom: string;
  @IsDateString()
  dateTo: string;
  @IsBoolean()
  fullDay: boolean;
}
