/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  NgModule,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { EntityType, MetaControlLayout } from "@meta/enums";
import {
  MetaAvatarModule,
  MetaButtonModule,
  MetaFieldWrapperModule,
  MetaGroupModule,
  MetaInputModule,
  MetaListModule,
  MetaLoaderModule,
  MetaRadioModule,
  MetaSectionModule,
  MetaSelectModule,
  MetaSliderModule,
  MetaSwitchModule,
  MetaTagModule,
  MetaToolbarModule,
} from "@meta/ui";
import { select } from "@ngneat/elf";
import { FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import jsonCrush from "jsoncrush";
import { MetaFormCondition } from "libs/forms/src/metaState.dto";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzModalRef } from "ng-zorro-antd/modal";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  Observable,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs";
import { v4 as uuid } from "uuid";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaActionHandlerFactory } from "../../base/metaForm/actions/actionHandler.factory";
import { PipesModule } from "../../pipes/pipes.module";
import { DirtyCheckService } from "../../services/dirtyCheckService";
import { MetaAppService } from "../../services/metaApp.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaAutocompleteComponent, MetaAutocompleteModule } from "../metaAutocomplete/metaAutocomplete.component";
import { IMetaSearchStore, MetaSearchRepository, MetaSearchStateObject } from "./metaSearch.repository";
import { MetaSearchService } from "./metaSearch.service";

export class MetaSearch {
  totalLabel = "Ergebnissen";
  route: any;
  entityType: EntityType;
  showResultsAsTable = false;
  showAsInline = false;
  showFavorites = true;
  showRecentItems = true;
  actions: any;
  canSelectItemsToDatabase: boolean;
  processSelectedItemsLabel = "Elemente verarbeiten";
  layoutTemplateRows = 2;
  showToolbar?: boolean;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-search",
  template: `
    <meta-toolbar
      *ngIf="ma.showToolbar"
      [maParams]="{
        showBackButton: false
      }"
    >
      <meta-radio
        [maParams]="{
          editing: true,
          iconOnly: true,
          style: 'solid',
          data: views
        }"
        [(ngModel)]="view"
        (ngModelChange)="onSearchSwitch($event)"
      ></meta-radio>
    </meta-toolbar>
    <div [hidden]="view === 1" nz-row>
      <div nz-col [nzSpan]="(metaSearchService.itemsToSave$ | async).length === 0 ? 24 : 16" class="search-animation">
        <div class="search-head" [ngClass]="{ 'show-as-inline': ma.showAsInline }">
          <div nz-row>
            <div
              [nzMd]="
                (metaSearchService.itemsToSave$ | async).length === 0
                  ? { push: 4, pull: 4, span: 16 }
                  : { push: 2, pull: 2, span: 20 }
              "
              class="search-animation"
              nz-col
              nzXs="24"
              [ngClass]="{ 'mt-1': ma.showAsInline, 'mt-5': !ma.showAsInline }"
            >
              <meta-autocomplete
                #autocomplete
                [maParams]="{
                  id: id,
                  editing: true,
                  returnType: 'object',
                  placeholder: 'Suche...',
                  searchType: 'search',
                  actions: ma.actions
                }"
                [(ngModel)]="searchModel.search"
                (ngModelChange)="onChange($event)"
                (keyup.enter)="onEnter()"
              ></meta-autocomplete>
            </div>
          </div>
          <div nz-row>
            <div
              [nzMd]="
                (metaSearchService.itemsToSave$ | async).length === 0 ? { push: 4, span: 12 } : { push: 2, span: 10 }
              "
              [nzSm]="24"
              [nzXs]="24"
              class="search-meta search-animation"
              nz-col
            >
              <ng-container *ngIf="searchTagArray.length > 0">
                <meta-button
                  [ngStyle]="{ margin: '0px 2px 0px 0px' }"
                  [maParams]="{
                    danger: true,
                    icon: 'times',
                    iconStyle: 'fas',
                    size: 'small',
                    onClick: resetAllFilter.bind(this)
                  }"
                ></meta-button>
              </ng-container>

              <ng-container *ngFor="let tag of searchTagArray">
                <meta-tag
                  [maParams]="{
                    label: tagLabelTemplate,
                    id: tag.id,
                    type: tag.exclude ? 'danger' : tag.data.IsSpezifikation ? 'primary' : 'default',
                    size: 'large',
                    mode: 'closeable',
                    onClose: onTagClose.bind(this)
                  }"
                ></meta-tag>
                <ng-template #tagLabelTemplate>
                  <span
                    class="pointer-cursor"
                    nz-popover
                    [nzPopoverContent]="tagContentTemplate"
                    [nzPopoverPlacement]="'bottom'"
                    [nzPopoverTitle]="tagTitleTemplate"
                    [nzPopoverTrigger]="tag.data.IsSpezifikation ? 'click' : null"
                    (nzPopoverVisibleChange)="onClose($event)"
                    [nzPopoverOverlayStyle]="{ width: '300px' }"
                    (click)="setForm(tag)"
                    [innerHTML]="getFilterByType(tag)"
                  ></span>
                </ng-template>
                <ng-template #tagTitleTemplate>
                  <span class="tag-title-wrapper">{{ tag.value }}</span>
                </ng-template>
                <ng-template #tagContentTemplate>
                  <meta-loader *ngIf="!isReady" [style.minHeight.px]="100"></meta-loader>
                  <div *ngIf="isReady" class="tag-content-wrapper">
                    <meta-field-wrapper
                      [maParams]="{
                        label: 'Filter ausschließen',
                        labelWidth: 11,
                        inputWidth: 13
                      }"
                    >
                      <meta-switcher
                        [maParams]="{
                          editing: true
                        }"
                        [(ngModel)]="searchModel.exclude"
                        (ngModelChange)="onExcludeParamChange($event, tag)"
                      ></meta-switcher>
                    </meta-field-wrapper>
                    <meta-section [maParams]="{ sectionType: 'hr', spacingTop: 1, spacingBottom: 1 }"></meta-section>
                    <meta-radio
                      *ngIf="tag.data.Spezifikation_Typ === 'ZAHL'"
                      [maParams]="{
                        data: assignedValueTypes,
                        editing: true,
                        style: 'solid'
                      }"
                      [(ngModel)]="searchModel.assignedValueType"
                      (ngModelChange)="onTypeParamChange($event, tag)"
                    ></meta-radio>
                    <meta-field-wrapper
                      [maParams]="{
                        label: 'Filterwert',
                        layout: metaControlLayout.vertical
                      }"
                    >
                      <meta-autocomplete
                        *ngIf="searchModel.assignedValueType === 0 && tag.data.Spezifikation_Typ === 'TEXT'"
                        [maParams]="{
                          id: tag.data.TAG_ID || tag.id,
                          searchType: 'tags',
                          editing: true,
                          placeholder: 'Wert...'
                        }"
                        [(ngModel)]="searchModel.assignedValue"
                        (ngModelChange)="onParamChange($event, tag)"
                      ></meta-autocomplete>
                      <meta-input
                        *ngIf="searchModel.assignedValueType === 0 && tag.data.Spezifikation_Typ === 'ZAHL'"
                        [maParams]="{
                          id: tag.data.TAG_ID || tag.id,
                          editing: true,
                          placeholder: 'Wert...'
                        }"
                        [(ngModel)]="searchModel.assignedValue"
                        (ngModelChange)="onParamChange($event, tag)"
                      ></meta-input>
                      <meta-select
                        *ngIf="searchModel.assignedValueType === 0 && tag.data.Spezifikation_Typ === 'TAG'"
                        [maParams]="{
                          id: tag.data.TAG_ID || tag.id,
                          loadDataOnInit: true,
                          tagId: tag.data.TAG_ID,
                          editing: true,
                          mode: 'multiple',
                          returnType: 'object'
                        }"
                        [(ngModel)]="searchModel.assignedValue"
                        (ngModelChange)="onTagParamChange($event, tag)"
                      ></meta-select>
                      <meta-slider
                        *ngIf="searchModel.assignedValueType === 1"
                        [maParams]="{
                          id: tag.data.TAG_ID || tag.id,
                          editing: true,
                          range: true,
                          min: rangeMin,
                          max: rangeMax,
                          showInputs: true
                        }"
                        [(ngModel)]="searchModel.assignedValue"
                        (ngModelChange)="onParamChange($event, tag)"
                      ></meta-slider>
                    </meta-field-wrapper>
                  </div>
                </ng-template>
              </ng-container>
            </div>
            <div
              *ngIf="!ma.showResultsAsTable"
              [nzMd]="
                (metaSearchService.itemsToSave$ | async).length === 0 ? { push: 4, span: 4 } : { push: 4, span: 8 }
              "
              [nzSm]="24"
              [nzXs]="24"
              class="text-right search-meta search-animation"
              nz-col
            >
              <span *ngIf="(metaSearchRepository.getSearchData$(id) | async)?.length > 0"
                ><strong>{{ (metaSearchRepository.getSearchData$(id) | async)?.length }}</strong> von
                <strong>{{
                  (metaSearchRepository.getSearchState$(id) | async)?.searchTotal | metaNumber: { digits: 0 }
                }}</strong>
                {{ ma.totalLabel }}</span
              >
            </div>
          </div>
        </div>
        <div *ngIf="!ma.showResultsAsTable" nz-row class="mb-5" [nzGutter]="12">
          <!-- SEARCH RESULTS -->
          <div
            *ngIf="searchTagArray.length > 0"
            [nzMd]="
              (metaSearchService.itemsToSave$ | async).length === 0
                ? { push: 4, pull: 4, span: 16 }
                : { push: 2, pull: 2, span: 20 }
            "
            class="search-animation"
            nz-col
            nzXs="24"
          >
            <meta-group [maParams]="{ label: 'Ergebnisse' }">
              <meta-list
                [maParams]="{
                  avatarField: 'avatar',
                  avatarSize: 'large',
                  data: metaSearchRepository.getSearchData$(id) | async,
                  layoutTemplateRows: ma.layoutTemplateRows,
                  searchable: false,
                  editable: false,
                  condition: {
                    skip: condition.skip,
                    take: condition.take
                  },
                  isLoading: isSearching,
                  loadingScale: 80,
                  markLockedItems: true,
                  showLoadMore:
                    (metaSearchRepository.getSearchData$(id) | async).length <
                    (metaSearchRepository.getSearchState$(id) | async)?.searchTotal,
                  onSelect: onClick.bind(this),
                  onLoadMore: onLoadMore.bind(this),
                  isSearchMode: true
                }"
              >
                <ng-template #maExtraTemplate let-data="data">
                  <ng-container *ngIf="ma.canSelectItemsToDatabase">
                    <meta-button
                      *ngIf="(metaSearchService.itemsToSave$ | async).indexOf(data?.id) > -1"
                      [maParams]="{
                        icon: 'trash',
                        danger: true,
                        type: 'primary',
                        loading: isDeletingItem && isDeletingItem === data?.id + '-results'
                      }"
                      (click)="onRemoveItem(data, $event, 'results')"
                      nz-tooltip
                      nzTooltipTitle="Aus List entfernen"
                    ></meta-button>
                    <meta-button
                      *ngIf="(metaSearchService.itemsToSave$ | async).indexOf(data?.id) === -1"
                      [maParams]="{
                        icon: 'plus',
                        type: 'primary',
                        loading: isAddingItem && isAddingItem === data?.id + '-results'
                      }"
                      (click)="onAddItem(data, $event, 'results')"
                      nz-tooltip
                      nzTooltipTitle="Zur Liste hinzufügen"
                    ></meta-button>
                  </ng-container>
                  <ng-container *ngIf="!ma.canSelectItemsToDatabase">
                    <meta-button
                      *ngIf="favorites.indexOf(data?.id) > -1"
                      [maParams]="{
                        icon: 'star',
                        iconStyle: 'fas',
                        type: 'link',
                        loading: isDeletingFavorite && isDeletingFavorite === data?.id + '-results'
                      }"
                      class="btn-fav"
                      (click)="onRemoveFavorite(data, $event, 'results')"
                      nz-tooltip
                      nzTooltipTitle="Favorit entfernen"
                    ></meta-button>
                    <meta-button
                      *ngIf="favorites.indexOf(data?.id) === -1"
                      [maParams]="{
                        icon: 'star',
                        type: 'link',
                        loading: isAddingFavorite && isAddingFavorite === data?.id + '-results'
                      }"
                      (click)="onSetFavorite(data, $event, 'results')"
                      class="btn-fav"
                      nz-tooltip
                      nzTooltipTitle="Als Favorit setzen"
                    ></meta-button>
                  </ng-container>
                </ng-template>
              </meta-list>
            </meta-group>
          </div>
          <ng-container *ngIf="searchTagArray.length === 0">
            <div [nzMd]="{ push: 4, span: 16 }" nz-col nzXs="24" *ngIf="ma.showRecentItems">
              <!-- FAVORITES -->
              <meta-group [maParams]="{ label: 'Favoriten' }">
                <meta-list
                  [maParams]="{
                    avatarField: 'avatar',
                    avatarSize: 'large',
                    layoutTemplateRows: ma.layoutTemplateRows,
                    data: metaSearchRepository.getFavoriteData$(id) | async,
                    searchable: false,
                    editable: false,
                    isLoading: isLoadingFavorites,
                    markLockedItems: true,
                    layoutMaxRows: 2,
                    noDataLabel: 'Du hast noch keine Favoriten...:(',
                    noDataIcon: 'star-shooting',
                    showLoadMore:
                      (metaSearchRepository.getFavoriteData$(id) | async)
                        ? (metaSearchRepository.getFavoriteData$(id) | async).length <
                          (metaSearchRepository.getSearchState$(id) | async)?.favoriteTotal
                        : false,
                    onSelect: onClick.bind(this),
                    onLoadMore: onLoadMoreFavorites.bind(this)
                  }"
                >
                  <ng-template #maExtraTemplate let-data="data">
                    <meta-button
                      [maParams]="{
                        icon: 'trash',
                        iconStyle: 'fas',
                        danger: true,
                        type: 'link',
                        loading: isDeletingFavorite && isDeletingFavorite === data.id + '-favorites'
                      }"
                      (click)="onRemoveFavorite(data, $event, 'favorites')"
                      class="delete-favorite"
                      nz-tooltip
                      nzTooltipTitle="Favorit entfernen"
                    ></meta-button>
                  </ng-template>
                </meta-list>
              </meta-group>
              <!-- PREVIOUS SEARCHES -->
              <meta-group [maParams]="{ label: 'Verlauf' }">
                <meta-list
                  [maParams]="{
                    avatarField: 'avatar',
                    avatarSize: 'large',
                    layoutTemplateRows: ma.layoutTemplateRows,
                    data: metaSearchRepository.getHistoryData$(id) | async,
                    searchable: false,
                    editable: false,
                    isLoading: isLoadingHistory,
                    markLockedItems: true,
                    layoutMaxRows: 2,
                    noDataLabel: 'Du hast noch nichts gefunden... Leg los!',
                    noDataIcon: 'telescope',
                    onSelect: onClick.bind(this)
                  }"
                >
                  <ng-template #maExtraTemplate let-data="data">
                    <meta-button
                      *ngIf="favorites.indexOf(data.id) > -1"
                      [maParams]="{
                        icon: 'star',
                        iconStyle: 'fas',
                        type: 'link',
                        loading: isDeletingFavorite && isDeletingFavorite === data.id + '-history'
                      }"
                      class="btn-fav"
                      (click)="onRemoveFavorite(data, $event, 'history')"
                      nz-tooltip
                      nzTooltipTitle="Favorit entfernen"
                    ></meta-button>
                    <meta-button
                      *ngIf="favorites.indexOf(data.id) === -1"
                      [maParams]="{
                        icon: 'star',
                        type: 'link',
                        loading: isAddingFavorite && isAddingFavorite === data.id + '-history'
                      }"
                      (click)="onSetFavorite(data, $event, 'history')"
                      class="btn-fav"
                      nz-tooltip
                      nzTooltipTitle="Als Favorit setzen"
                    ></meta-button>
                  </ng-template>
                </meta-list>
              </meta-group>
            </div>
          </ng-container>
        </div>
        <div *ngIf="ma.showResultsAsTable && table$ | async as table">
          <ng-container *ngFor="let f of field.fieldGroup; let i = index; let last = last">
            <formly-field [field]="f"></formly-field>
          </ng-container>
        </div>
      </div>
      <div *ngIf="metaSearchService.itemsToSaveDisplayValue$ | async as itemsToSave" nz-col nzSpan="8">
        <div *ngIf="itemsToSave.length > 0" class="items-to-save mt-5">
          <meta-section [maParams]="{ sectionType: 'h2', spacingTop: 3, spacingBottom: 4 }">Warenkorb</meta-section>
          <ul class="list">
            <li *ngFor="let item of itemsToSave; let index = index" class="item">
              <meta-avatar
                [maParams]="{
                  label: item.label,
                  size: 'small'
                }"
              ></meta-avatar>
              <div class="label">
                {{ item.label }}<br />
                {{ item.description[0] }}
              </div>
              <div class="quantity">
                <meta-input
                  [maParams]="{
                    inputType: 'number',
                    editing: true,
                    digits: 0,
                    min: 0
                  }"
                  [(ngModel)]="item._qty"
                ></meta-input>
              </div>
              <div class="actions">
                <meta-button
                  [maParams]="{
                    icon: 'trash',
                    iconStyle: 'fas',
                    type: 'link',
                    danger: true,
                    size: 'small',
                    loading: isDeletingItem && isDeletingItem === item.id + '-selection',
                    onClick: onRemoveItem.bind(this, item, 'selection')
                  }"
                  class="delete-favorite"
                  nz-tooltip
                  nzTooltipTitle="Entfernen"
                ></meta-button>
              </div>
            </li>
          </ul>
          <meta-button
            [maParams]="{
              label: ma.processSelectedItemsLabel,
              type: 'primary',
              fullWidth: true
            }"
            (click)="processSelectedItems($event)"
          ></meta-button>
        </div>
      </div>
    </div>
    <div [hidden]="view === 0" class="search-wrapper">
      <meta-group [maParams]="{ flex: 1 }">
        <formly-field [field]="field.fieldGroup[0]"></formly-field>
      </meta-group>
    </div>
  `,
  styleUrls: ["./metaSearch.component.less"],
  providers: [MetaSearchService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaSearchComponent extends MetaComponentBase implements OnInit, OnDestroy {
  @ViewChild("autocomplete") public autocomplete: MetaAutocompleteComponent;
  public view: 1 | 0 = 0;
  public views: any[] = [
    { label: "Suche", value: 0, icon: "search" },
    { label: "Tabellenansicht", value: 1, icon: "table" },
  ];
  public favorites: string[] = [];
  public history: string[] = [];
  public isSearching = true;
  public isLoadingFavorites = true;
  public isDeletingFavorite: string;
  public isAddingFavorite: string;
  public isDeletingItem: string;
  public isAddingItem: string;
  public isLoadingHistory = false;
  public take = 10;
  public skip = 0;
  public takeFavorites = 50;
  public skipFavorites = 0;
  public takeHistory = 10;
  public skipHistory = 0;
  public searchTagArray: any[] = [];
  public currentSearch: string;
  public isReady = false;
  public rangeMin = 0;
  public rangeMax = 100;
  public metaControlLayout = MetaControlLayout;
  public assignedValueTypes = [
    { label: "Wert", value: 0 },
    { label: "Bereich", value: 1 },
  ];
  public formParamsAndQueries: any;
  public condition: MetaFormCondition | any = {};
  public table$: Observable<FormlyFieldConfig>;
  public search$: Subject<any> = new Subject<any>();
  public searchModel: {
    search?: string | any;
    id?: string;
    exclude?: boolean;
    assignedValueType?: number;
    assignedValue?: any;
  } = {};
  public cartModel: number[] = [];

  private _lastDataState: string;
  private _lastHistoryState: string;
  private _lastHistoryFormState: string;
  private _lastHistoryIdsState: string;
  private _lastFavoriteState: string;
  private _lastSearchString: string;
  private cacheKey = uuid();
  private _params$: Observable<any>;
  private _queries$: Observable<any>;

  constructor(
    public metaSearchService: MetaSearchService,
    public metaSearchRepository: MetaSearchRepository,
    private readonly _dirtyCheckService: DirtyCheckService,
    private readonly _metaAppRepository: MetaAppService,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _metaActionHandler: MetaActionHandlerFactory,
    @Optional() private readonly _modalRef: NzModalRef,
  ) {
    super();
    super.maParams = new MetaSearch();
  }

  get ma(): MetaSearch {
    return super.ma;
  }

  async ngOnInit() {
    super.ngOnInit();
    this.search$
      .pipe(
        debounceTime(750),
        takeUntil((this as any).destroyed$),
        filter((e) => !this.ma.showResultsAsTable),
        tap((loadAdditionalData) => {
          if (!loadAdditionalData) {
            this.condition.skip = 0;
          }
          this.isSearching = true;
          this.changeDetectorRef.markForCheck();
        }),
        switchMap((e: boolean) =>
          this.metaSearchRepository.getSearchData({
            fieldId: this.id,
            condition: this.condition,
          }),
        ),
        tap(() => (this.isSearching = false)),
      )
      .subscribe();

    if (
      this._activatedRoute.queryParams["value"]["search"] ||
      this._activatedRoute.queryParams["value"]["searchString"]
    ) {
      if (this._activatedRoute.queryParams["value"]["search"]) {
        this.searchTagArray = JSON.parse(
          jsonCrush.uncrush(decodeURIComponent(this._activatedRoute.queryParams["value"]["search"])),
        );
        this.currentSearch = this.searchModel.search;
      }
      if (this._activatedRoute.queryParams["value"]["searchString"]) {
        this.form
          .get("search")
          .patchValue(decodeURIComponent(this._activatedRoute.queryParams["value"]["searchString"]));
        this.currentSearch = this.searchModel.search;
      }
      this._createFilterObject();
      this.search$.next(false);
    }

    this.metaSearchRepository
      .getFavoriteData$(this.id)
      .pipe(takeUntil((this as any).destroyed$))
      .subscribe((favorites) => {
        this.favorites = favorites.map((f) => f.id);
        this.isLoadingFavorites = false;
        this.changeDetectorRef.markForCheck();
      });

    this.metaSearchRepository
      .getHistoryData$(this.id)
      .pipe(takeUntil((this as any).destroyed$))
      .subscribe((history) => {
        this.history = history.map((f) => f.id);
        this.isLoadingHistory = false;
        this.changeDetectorRef.markForCheck();
      });

    // LoadHistoryData
    this.metaSearchRepository
      .getSearchState$(this.id)
      .pipe(
        debounceTime(500),
        takeUntil((this as any).destroyed$),
        distinctUntilChanged((a: MetaSearchStateObject, b: MetaSearchStateObject) => a.historyIds === b.historyIds),
      )
      .subscribe((state) => {
        this.isLoadingHistory = true;
        this.metaSearchRepository
          .searchDataByIds({
            fieldId: this.id,
            entityType: this.ma.entityType,
            condition: {
              skip: this.skipFavorites,
              take: this.takeFavorites,
            },
            ids: state.historyIds,
          })
          .subscribe()
          .add(() => {
            this.isLoadingHistory = false;
            this.changeDetectorRef.markForCheck();
          });
      });

    const appState = await firstValueFrom(this._metaAppRepository.form$.pipe(select((f) => f[this.id])));
    if (appState?.view !== undefined) {
      this.view = appState.view;
    }

    this.getFavorites();
    this._dirtyCheckService.registerObject(
      this,
      () => {
        return this.metaSearchService.itemsToSaveDisplayValue$.value.length > 0;
      },
      this._modalRef,
    );
  }

  ngOnDestroy() {
    if (this && this?.formId) {
      this.metaSearchRepository.clearSearchData(this.id);
      this.metaSearchRepository.deleteHistoryData();
      this._dirtyCheckService.unregisterObject(this);
    }
  }

  public getFavorites(loadAdditionalData = false) {
    this.isLoadingFavorites = true;
    if (!loadAdditionalData) {
      this.skip = 0;
    }
    this.metaSearchRepository
      .getFavoriteData({
        fieldId: this.id,
        entityType: this.ma.entityType,
        condition: {
          skip: this.skipFavorites,
          take: this.takeFavorites,
        },
      })
      .pipe(takeUntil((this as any).destroyed$))
      .subscribe()
      .add(() => {
        this.isLoadingFavorites = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  public setForm(tag) {
    if (tag.data.IsSpezifikation) {
      if (tag.data.Spezifikation_Typ === "ZAHL") {
        this.getRange(tag);
      }
      this.searchModel.id = tag.id;
      this.searchModel.exclude = tag.exclude;
      this.searchModel.assignedValueType = tag.assignedValueType;
      this.searchModel.assignedValue = tag.assignedValue;
      this.changeDetectorRef.markForCheck();
      if (tag.data.Spezifikation_Typ !== "ZAHL") {
        this.isReady = true;
      }
    } else {
      tag.exclude = !tag.exclude;
      this.changeDetectorRef.markForCheck();
      this._createFilterObject();
      this.search$.next(false);
    }
  }

  public onTagClose = (id: string) => {
    this.searchTagArray = this.searchTagArray.filter((o) => o.id !== id);
    if (this.searchTagArray.length === 0) {
      this.metaSearchRepository.clearSearchData(this.id);
      this._createFilterObject();
    } else {
      this.changeDetectorRef.markForCheck();
      this._createFilterObject();
      this.search$.next(false);
    }
    this.changeDetectorRef.markForCheck();
  };

  public resetAllFilter() {
    if (document.activeElement.tagName === "INPUT") {
      return;
    }
    this.searchTagArray = [];
    this.metaSearchRepository.clearSearchData(this.id);
    this._createFilterObject();
    this.changeDetectorRef.markForCheck();
  }

  public onEnter() {
    const item = this.searchModel.search;
    if (item === undefined || item === null) {
      return;
    }

    if (typeof item === "object") {
      if (this.searchTagArray.filter((o) => o.value.toLowerCase() === item.label.toLowerCase()).length > 0) {
        this.searchModel.search = null;
        return;
      }
      this.searchTagArray.push({
        id: item.data && item.data.TAG_ID ? item.data.TAG_ID : this.searchTagArray.length,
        value: item.label,
        data: item.data,
        assignedValueType: 0,
        assignedValue: null,
      });
    } else {
      if (this.searchTagArray.filter((o) => o.value.toLowerCase() === item.toLowerCase()).length > 0) {
        this.searchModel.search = null;
        return;
      }
      this.searchTagArray.push({
        id: this.searchTagArray.length,
        value: item,
        data: {},
      });
      this.metaSearchService
        .getSpecification(this.id, item)
        .pipe(take(1))
        .subscribe((result) => {
          if (result && result[0] && result[0].label.toLowerCase() === item.toLowerCase()) {
            this.searchTagArray.splice(this.searchTagArray.length - 1, 1);
            this.searchTagArray.push({
              id: result[0].data && result[0].data.TAG_ID ? result[0].data.TAG_ID : this.searchTagArray.length,
              value: result[0].label,
              data: result[0].data,
              assignedValueType: 0,
              assignedValue: null,
            });
            this.searchModel.search = null;
            this._createFilterObject();
            this.search$.next(false);
          }
        });
    }
    this.searchModel.search = null;
    this.currentSearch = null;
    this.autocomplete.clearData();
    this._createFilterObject();
    this.search$.next(false);
    this.changeDetectorRef.markForCheck();
  }

  public resetFilter(tag: any) {
    this.searchModel.exclude = false;
    this.searchModel.assignedValueType = 0;
    this.searchModel.assignedValue = null;
  }

  public getFilterByType(_filter: any) {
    let result;
    switch (_filter.data.Spezifikation_Typ) {
      case "ZAHL":
        if (Array.isArray(_filter.assignedValue)) {
          result =
            _filter.value + ": <strong>" + _filter.assignedValue[0] + " bis " + _filter.assignedValue[1] + "</strong>";
        } else {
          result = _filter.value + (_filter.assignedValue ? ": <strong>" + _filter.assignedValue + "</strong>" : "");
        }
        break;
      case "TAG":
        result =
          _filter.value +
          (_filter.assignedValue ? ": <strong>" + _filter.assignedValue.map((o) => o.label) + "</strong>" : "");
        break;
      default:
        result = _filter.value + (_filter.assignedValue ? ": <strong>" + _filter.assignedValue + "</strong>" : "");
    }
    return result;
  }

  public onParamChange(value: any, tag: any) {
    this.searchTagArray.map((t) => {
      if (t.id === tag.id) {
        t.assignedValueType = tag.assignedValueType;
        t.assignedValue = value;
      }
      return t;
    });
    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
      this._createFilterObject();
      this.search$.next(false);
    });
  }

  public onTagParamChange(value: any, tag: any) {
    this.searchTagArray.map((t) => {
      if (t.id === tag.id) {
        t.assignedValueType = tag.assignedValueType;
        t.assignedValue = value;
      }
      return t;
    });
    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
      this._createFilterObject();
      this.search$.next(false);
    });
  }

  public onExcludeParamChange(value: any, tag: any) {
    this.searchTagArray.map((t) => {
      if (t.id === tag.id) {
        t.assignedValueType = tag.assignedValueType;
        t.exclude = value;
      }
      return t;
    });
    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
      this._createFilterObject();
      this.search$.next(false);
    });
  }

  public onTypeParamChange(value: any, tag: any) {
    this.searchTagArray.map((t) => {
      if (t.id === tag.id) {
        t.assignedValueType = value;
        if (value === 0) {
          t.assignedValue = null;
        } else {
          t.assignedValue = [this.rangeMin, this.rangeMax];
        }
        this.searchModel.assignedValue = t.assignedValue;
      }
      return t;
    });
    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
      this._createFilterObject();
      this.search$.next(false);
    });
  }

  public onClose(event) {
    if (!event) {
      this.isReady = false;
    }
  }

  public onChange = (event) => {
    if (event?.label) {
      this.onEnter();
    }
  };

  public getRange(tag: any) {
    this.metaSearchService
      .getRange(tag.id)
      .pipe(take(1))
      .subscribe({
        next: (value: any) => {
          this.rangeMin = value.min;
          this.rangeMax = value.max;
          this.isReady = true;
          this.changeDetectorRef.markForCheck();
        },
      });
  }

  public onSetFavorite(data: any, $event: any, listType: string) {
    $event.stopPropagation();
    this.isAddingFavorite = data.id + "-" + listType;
    this.metaSearchRepository
      .setFavoriteData({
        fieldId: this.id,
        quellId: data._id.join(","),
        entityType: this.ma.entityType,
        condition: {
          skip: this.skip,
          take: this.take,
        },
      })
      .subscribe()
      .add(() => {
        this.isAddingFavorite = null;
        this.changeDetectorRef.markForCheck();
      });
  }

  public onRemoveFavorite(data: any, $event: any, listType: string) {
    $event.stopPropagation();
    this.isDeletingFavorite = data.id + "-" + listType;
    this.metaSearchRepository
      .removeFavoriteData({
        quellId: data._id.join(","),
        fieldId: this.id,
        entityType: this.ma.entityType,
      })
      .subscribe()
      .add(() => {
        this.isDeletingFavorite = null;
        this.changeDetectorRef.markForCheck();
      });
  }

  public async onAddItem(data: any, $event: any, listType: string) {
    $event.stopPropagation();
    this.isAddingItem = data.id + "-" + listType;
    const items: IMetaSearchStore[] = await firstValueFrom(this.metaSearchRepository.getSearchData$(this.id));
    this.metaSearchService.addItemToSave(data.id, items.find((o) => o.id === data.id) as any);
    this.isAddingItem = null;
  }

  public onRemoveItem(data: any, listType: string) {
    if (this.autocomplete.hasFocus) {
      return;
    }
    this.isDeletingItem = data.id + "-" + listType;
    this.metaSearchService.removeItemFromSave(data.id);
    this.isDeletingItem = null;
  }

  public async onClick(field, item): Promise<void> {
    const state: MetaSearchStateObject = await firstValueFrom(this.metaSearchRepository.getSearchState$(this.id));
    this.history = state.historyIds || [];
    const id = item._id.join(",");

    if (this.history.indexOf(id) === -1) {
      this.history.unshift(id);
    } else {
      this.history = this.history.filter((o) => o !== id);
      this.history.unshift(id);
    }
    await this.metaSearchRepository.setHistoryData({
      fieldId: this.id,
      historyData: this.history,
    });
    await this._metaActionHandler.executeSelectAction({
      controlId: null,
      formId: this.id,
      data: {
        ...item,
      },
    });
  }

  public async onLoadMore() {
    const data: IMetaSearchStore[] = await firstValueFrom(this.metaSearchRepository.getSearchData$(this.id));
    this.condition.skip = data.length;
    this.search$.next(true);
  }

  public onLoadMoreFavorites(): void {
    this.takeFavorites = this.takeFavorites + this.takeFavorites;
    this.getFavorites(true);
  }

  public async processSelectedItems($event: MouseEvent) {
    this._dirtyCheckService.unregisterObject(this);
    await this.metaSearchService.saveSelectedItems(this.id, this.formState.itemId());
    const itemsToSave = await this.metaSearchService.getSelectedItemsToSave();
    await this._metaActionHandler.executeProcessSelectedItems(this.id, itemsToSave, this.model?._ctx);
  }

  public onSearchSwitch(event: any) {
    this._metaAppRepository.setFormSettings(this.id, {
      view: event,
    });
  }

  private _createFilterObject() {
    this.condition = {
      filter: {
        filters: [],
        logic: "and",
      },
      take: this.take,
      cacheKey: this.cacheKey,
    };
    this.condition.searchString =
      this.searchModel.search && this.searchModel.search.length > 2
        ? this.searchModel.search
        : this.searchModel.search?.label?.length > 2
          ? this.searchModel.search?.label
          : "";
    this.searchTagArray.forEach((_filter) => {
      if (_filter.data.IsSpezifikation) {
        const f = [];
        if (Array.isArray(_filter.assignedValue)) {
          f.push({
            field: "Name",
            value: _filter.value,
            operator:
              _filter.exclude && (_filter.assignedValue === undefined || _filter.assignedValue) === null ? "neq" : "eq",
          });
          if (_filter.assignedValue !== undefined && _filter.assignedValue !== null) {
            if (typeof _filter.assignedValue[0] === "object") {
              _filter.assignedValue.forEach((av) => {
                f.push({
                  field: "Wert",
                  value: av.label,
                  operator: _filter.exclude ? "neq" : "eq",
                });
              });
            } else {
              if (_filter.exclude) {
                f.push({
                  filters: [
                    {
                      field: "Wert",
                      value: _filter.assignedValue[0],
                      operator: "lte",
                    },
                    {
                      field: "Wert",
                      value: _filter.assignedValue[1],
                      operator: "gte",
                    },
                  ],
                  logic: "or",
                  jsonScope: "SearchField_TAG",
                });
              } else {
                f.push({
                  field: "Wert",
                  value: _filter.assignedValue[0],
                  operator: "gte",
                });
                f.push({
                  field: "Wert",
                  value: _filter.assignedValue[1],
                  operator: "lte",
                });
              }
            }
          }
        } else {
          f.push({
            field: "Name",
            value: _filter.value,
            operator:
              _filter.exclude && (_filter.assignedValue === undefined || _filter.assignedValue === null)
                ? "doesnotcontain"
                : "contains",
          });
          if (_filter.assignedValue !== undefined && _filter.assignedValue !== null && _filter.assignedValue !== "") {
            f.push({
              field: "Wert",
              value: _filter.assignedValue,
              operator: _filter.exclude ? "neq" : "eq",
            });
          }
        }
        this.condition.filter.filters.push({
          filters: f,
          logic: "and",
          jsonScope: "SearchField_TAG",
        });
      } else {
        this.condition.filter.filters.push({
          field: "",
          value: _filter.value,
          operator: _filter.exclude ? "doesnotcontain" : "contains",
        });
      }
    });
    if (!this.ma.canSelectItemsToDatabase) {
      if (
        !this.ma.showAsInline &&
        (this.searchTagArray.length > 0 || (this.searchModel.search && this.searchModel.search.length > 2))
      ) {
        this._router.navigate([], {
          relativeTo: this._activatedRoute,
          queryParams: {
            ...this._activatedRoute.snapshot.queryParams,
            search: jsonCrush.crush(JSON.stringify(this.searchTagArray)),
            searchString: encodeURIComponent(
              this.searchModel.search && this.searchModel.search.length > 2 ? this.searchModel.search : "",
            ),
          },
          replaceUrl: true,
        });
      } else {
        if (!this.ma.showAsInline) {
          this._router.navigate([], {
            relativeTo: this._activatedRoute,
            replaceUrl: true,
          });
        }
      }
    }
  }
}

@NgModule({
  declarations: [MetaSearchComponent],
  imports: [
    CommonModule,
    FormlyModule,
    NzGridModule,
    MetaAutocompleteModule,
    MetaSwitchModule,
    MetaButtonModule,
    MetaRadioModule,
    MetaTagModule,
    NzPopoverModule,
    MetaLoaderModule,
    MetaSectionModule,
    FormsModule,
    MetaInputModule,
    MetaSelectModule,
    MetaSliderModule,
    MetaListModule,
    NzToolTipModule,
    MetaGroupModule,
    MetaAvatarModule,
    PipesModule,
    MetaFieldWrapperModule,
    MetaToolbarModule,
  ],
  exports: [MetaSearchComponent],
})
export class MetaSearchModule {}
