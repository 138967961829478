/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CreateTaskDto } from "../../../../../api-interfaces/src/lib/api-interfaces/task/createTask.dto";
import { firstValueFrom } from "rxjs";
import { TaskDto } from "../../../../../api-interfaces/src/lib/api-interfaces/task/task.dto";
import { tap } from "rxjs/operators";
import { MetaTaskRepository } from "./metaTask.repository";
import { UpdateTaskStateDto } from "../../../../../api-interfaces/src/lib/api-interfaces/task/updateTaskState.dto";
import { TaskFilterType } from "../../../../../../apps/api/src/app/modules/task/task.interfaces";
import { UpdateTaskPriorityDto } from "../../../../../api-interfaces/src/lib/api-interfaces/task/updateTaskPriority.dto";
import { NzMessageService } from "ng-zorro-antd/message";
import { MetaTaskFormComponent } from "./metaTask.form.component";
import { FileAssignementRequest } from "@meta/api-interfaces";
import { MetaModalService } from "../../services/metaModalService";

export class MetaTaskRequest {
  skip? = 0;
  take? = 5000;
  sourceType?: string;
  sourceId?: string;
  filter?: TaskFilterType;
  parentId?: string;
  taskId?: string;
}

export class MetaTaskDataResponse {
  resultSet: TaskDto[];
  total?: number;
}

export class MetaTaskOpenTaskParams {
  id: string;
  sourceType?: string;
  sourceId?: string;
  showAdditionalFields?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class MetaTaskService {
  constructor(
    private _http: HttpClient,
    private _metaTaskRepository: MetaTaskRepository,
    private _messageService: NzMessageService,
    public modalService: MetaModalService
  ) {}

  async create(payload: Partial<CreateTaskDto>) {
    const task: TaskDto = await firstValueFrom(this._http.put<TaskDto>(`task`, payload));
    await this._metaTaskRepository.createTask(task);
    if (!task.priority) {
      this._messageService.success("Aufgabe erfolgreich erstellt");
    }
    return true;
  }

  async update(id: string, payload: Partial<TaskDto>) {
    const task: TaskDto = await firstValueFrom(this._http.patch<TaskDto>(`task/${id}`, payload));
    await this._metaTaskRepository.updateSingleTask(task);
    this._messageService.success("Aufgabe erfolgreich aktualisiert");
    return true;
  }

  async getTask(id: string) {
    return await firstValueFrom(this._http.get<TaskDto>(`task/${id}`));
  }

  getTasks(p: MetaTaskRequest) {
    if (p.parentId) {
      const params = new URLSearchParams();
      if (p.filter !== undefined) {
        params.set("filter", `${p.filter}`);
      }
      this._http
        .get<MetaTaskDataResponse>(`task/${p.parentId}/children?${params}`)
        .pipe(
          tap((data: MetaTaskDataResponse) =>
            this._metaTaskRepository.updateTasksFromAPI({
              data: data,
              params: p,
            })
          )
        )
        .subscribe();
    } else {
      const params = new URLSearchParams();
      if (p.skip !== undefined) {
        params.set("skip", `${p.skip}`);
      }
      if (p.take !== undefined) {
        params.set("take", `${p.take}`);
      }
      if (p.sourceType !== undefined) {
        params.set("sourceType", `${p.sourceType}`);
        params.set("sourceId", `${p.sourceId}`);
      }
      if (p.taskId !== undefined) {
        params.set("taskId", `${p.taskId}`);
      } else if (p.filter !== undefined) {
        params.set("filter", `${p.filter}`);
      }
      this._http
        .get<MetaTaskDataResponse>(`task?${params}`)
        .pipe(
          tap((data: MetaTaskDataResponse) =>
            this._metaTaskRepository.updateTasksFromAPI({
              data: data,
              params: p,
            })
          )
        )
        .subscribe();
    }
  }

  async setTaskAsDone(task: TaskDto) {
    task.done = true;
    (task as any).dateDone = new Date();
    await firstValueFrom(this._http.post<UpdateTaskStateDto>(`task/${task.id}/state`, { done: task.done }));
    await this._metaTaskRepository.updateSingleTask(task, true);
  }

  async setTaskAsOpen(task: TaskDto) {
    task.done = false;
    (task as any).dateDone = null;
    await firstValueFrom(this._http.post<UpdateTaskStateDto>(`task/${task.id}/state`, { done: task.done }));
    await this._metaTaskRepository.updateSingleTask(task, true);
  }

  async setTaskPrio(task: TaskDto, prio: number) {
    task.priority = prio;
    await firstValueFrom(
      this._http.post<UpdateTaskPriorityDto>(`task/${task.id}/priority`, { priority: task.priority })
    );
    await this._metaTaskRepository.updateSingleTask(task);
  }

  async deleteTask(task: TaskDto) {
    await firstValueFrom(this._http.delete<UpdateTaskStateDto>(`task/${task.id}`));
    await this._metaTaskRepository.deleteSingleTask(task);
    if (!task.parent) {
      this._messageService.success("Aufgabe wurde gelöscht");
    }
  }

  async getPriorities() {
    this._metaTaskRepository.priorities = await firstValueFrom(this._http.post("task/select-data/priorities", {}));
  }

  public openTask(p: MetaTaskOpenTaskParams) {
    if (p.id) {
      p.showAdditionalFields = true;
    }

    const modal = this.modalService.create({
      nzContent: MetaTaskFormComponent,
      nzTitle: `Aufgabe ${p.id ? "bearbeiten" : "erstellen"}`,
      nzWidth: "700px",
      nzMaskClosable: false,
      nzClosable: true,
      nzClassName: "ma-subform",
      nzComponentParams: {
        maParams: p,
      },
    });
    modal.afterClose.subscribe((result) => {
      console.log(result);
    });
  }

  public async assignFiles(files: string[], targetId: string, targetType: string) {
    for (const file of files) {
      const request: Partial<FileAssignementRequest> = {
        targetId: targetId,
        targetType: targetType,
      };
      await firstValueFrom(this._http.post(`file/${file}/assign/${targetId}`, request));
    }
  }

  public async unassignFiles(file: string, targetId: string, targetType: string) {
    await firstValueFrom(
      this._http.post(`file/${file}/unassign/${targetId}`, {
        entityType: targetType,
      })
    );
  }
}
