/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  EventEmitter,
  inject,
  Injectable,
  Injector,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { ModalOptions, NZ_MODAL_DATA, NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { ConfirmType } from "ng-zorro-antd/modal/modal-types";

@Component({
  standalone: true,
  template: ``,
  selector: "meta-modal-wrapper",
  styles: `
    meta-modal-wrapper {
      display: none !important;
    }
  `,
  encapsulation: ViewEncapsulation.None,
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaModalWrapper<T> {
  public readonly componentRef: ComponentRef<T>;

  constructor(
    private readonly injector: Injector,
    private readonly viewContainerRef: ViewContainerRef,
  ) {
    const modalData = inject<InternalModalData>(NZ_MODAL_DATA);
    const modalRef = inject(NzModalRef);

    const wrapAction = (options: ModalOptions["nzOnOk"]) => {
      if (options instanceof EventEmitter) {
        return options;
      } else if (typeof options === "function") {
        return () => options(this.componentRef.instance);
      } else {
        return null;
      }
    };

    const configUpdateObject: Partial<ModalOptions> = {};
    if (modalData.options.nzOnOk) {
      configUpdateObject.nzOnOk = wrapAction(modalData.options.nzOnOk);
    }
    if (modalData.options.nzOnCancel) {
      configUpdateObject.nzOnCancel = wrapAction(modalData.options.nzOnCancel);
    }
    if (Object.keys(configUpdateObject).length > 0) {
      modalRef.updateConfig(configUpdateObject);
    }
    this.componentRef = this.viewContainerRef.createComponent(modalData.component, {
      injector: Injector.create({
        providers: [
          { provide: NZ_MODAL_DATA, useValue: modalData.data },
          { provide: NzModalRef, useValue: this.proxifyModalRef(modalRef) },
        ],
        parent: this.injector,
      }),
    });
    if (modalData.componentParams) {
      Object.assign(this.componentRef.instance, modalData.componentParams);
      this.componentRef.changeDetectorRef.markForCheck();
    }
  }

  private proxifyModalRef<T, R>(ref: NzModalRef) {
    return new Proxy(ref, {
      get: (target: NzModalRef<T, R>, p: string | symbol, receiver: any) => {
        if (p === "componentInstance") {
          return this.componentRef?.instance;
        } else {
          return target[p];
        }
      },
    }) as NzModalRef<T, R>;
  }
}

interface InternalModalData {
  componentParams: Record<string, any>;
  component: any;
  data: Record<string, any>;
  options: MetaModalOptions<any, any, any>;
}

export type MetaModalOptions<T = any, D = any, R = any> = ModalOptions<T, D, R> & {
  nzComponentParams?: Record<string, any>;
};

@Injectable({
  providedIn: "any",
})
export class MetaModalService extends NzModalService {
  public get openModalsProxys(): NzModalRef[] {
    return super.openModals.map((ref) => this.proxifyModalRef(ref));
  }

  public create<T, D = NzSafeAny, R = NzSafeAny>(options: MetaModalOptions<T, D, R>): NzModalRef<T, R> {
    return this.proxifyModalRef<T, R>(super.create(this.injectWrapper(options)));
  }

  public info<T>(options?: MetaModalOptions<T, any, any>): NzModalRef<T> {
    return this.proxifyModalRef<T, any>(super.info(this.injectWrapper(options)));
  }

  public warning<T>(options?: MetaModalOptions<T, any, any>): NzModalRef<T> {
    return this.proxifyModalRef<T, any>(super.warning(this.injectWrapper(options)));
  }

  public error<T>(options?: MetaModalOptions<T, any, any>): NzModalRef<T> {
    return this.proxifyModalRef<T, any>(super.error(this.injectWrapper(options)));
  }

  public success<T>(options?: MetaModalOptions<T, any, any>): NzModalRef<T> {
    return this.proxifyModalRef<T, any>(super.success(this.injectWrapper(options)));
  }

  public confirm<T>(options?: MetaModalOptions<T, any, any>, confirmType?: ConfirmType): NzModalRef<T> {
    return this.proxifyModalRef<T, any>(super.confirm(this.injectWrapper(options), confirmType));
  }

  private injectWrapper<T, D = NzSafeAny, R = NzSafeAny>(options: MetaModalOptions<T, D, R>) {
    if (options.nzContent && typeof options.nzContent !== "string") {
      return {
        ...options,
        nzData: {
          componentParams: options.nzComponentParams,
          component: options.nzContent,
          data: options.nzData,
          options,
        },
        nzContent: MetaModalWrapper<T> as any,
      } as ModalOptions<T, D, R>;
    } else {
      return options;
    }
  }

  private proxifyModalRef<T, R>(ref: NzModalRef) {
    return new Proxy(ref, {
      get(target: NzModalRef<T, R>, p: string | symbol, receiver: any): any {
        if (p === "componentInstance") {
          return (target.componentInstance?.["componentRef"] as ComponentRef<T>)?.instance;
        } else {
          return target[p];
        }
      },
    }) as NzModalRef<T, R>;
  }
}
