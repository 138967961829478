/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, Input, OnInit } from "@angular/core";
import { MetaHelperService } from "../../../services/metaHelper.service";
import { IFeedResultItem } from "@meta/api-interfaces";

export class MetaTimelineTemplatePipelineObjectMoved {
  data: IFeedResultItem;
}

@Component({
  selector: "meta-timeline-template-workflow-execution-aborted",
  template: `
    <meta-translate key="timeline.workflow-execution-aborted">
      <ng-template meta-translate-child key="workflowTag">
        <a [href]="item.data.entity.link">
          <meta-tag [maParams]="{ label: tplLabel, color: color, colorText: colorText, maxWidth: 250 }"></meta-tag>
        </a>
      </ng-template>
    </meta-translate>
    <ng-template #tplLabel><i class="far fa-{{ item.data.entity.icon }}"></i>{{ item.data.entity.title }}</ng-template>
    <meta-live-card
      [cardLazyLoad]="true"
      cardType="WF_EXEC"
      cardSize="small"
      [cardId]="item.data.entity.id"
      [cardData]="item.data.cardData"
    ></meta-live-card>
  `,
})
export class MetaTimelineTemplateWorkflowExecutionAbortedComponent {
  @Input() public item: MetaTimelineTemplatePipelineObjectMoved;

  public readonly color: string;
  public readonly colorText: string;

  constructor(public metaHelperService: MetaHelperService) {
    this.color = getComputedStyle(document.documentElement).getPropertyValue("--error-color");
    this.colorText = getComputedStyle(document.documentElement).getPropertyValue("--text-color");
  }
}
