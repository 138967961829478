/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import "reflect-metadata";
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { devTools } from "@ngneat/elf-devtools";

if (environment.production) {
  enableProdMode();
} else {
  devTools();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
