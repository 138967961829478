/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponent, MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

export const SupportedBarcodes = {
  auspost: "AusPost 4 State Customer Code",
  azteccode: "Aztec Code",
  azteccodecompact: "Compact Aztec Code",
  aztecrune: "Aztec Runes",
  bc412: "BC412",
  channelcode: "Channel Code",
  codablockf: "Codablock F",
  code11: "Code 11",
  code128: "Code 128",
  code16k: "Code 16K",
  code2of5: "Code 25",
  code32: "Italian Pharmacode",
  code39: "Code 39",
  code39ext: "Code 39 Extended",
  code49: "Code 49",
  code93: "Code 93",
  code93ext: "Code 93 Extended",
  codeone: "Code One",
  coop2of5: "COOP 2 of 5",
  daft: "Custom 4 state symbology",
  databarexpanded: "GS1 DataBar Expanded",
  databarexpandedcomposite: "GS1 DataBar Expanded Composite",
  databarexpandedstacked: "GS1 DataBar Expanded Stacked",
  databarexpandedstackedcomposite: "GS1 DataBar Expanded Stacked Composite",
  databarlimited: "GS1 DataBar Limited",
  databarlimitedcomposite: "GS1 DataBar Limited Composite",
  databaromni: "GS1 DataBar Omnidirectional",
  databaromnicomposite: "GS1 DataBar Omnidirectional Composite",
  databarstacked: "GS1 DataBar Stacked",
  databarstackedcomposite: "GS1 DataBar Stacked Composite",
  databarstackedomni: "GS1 DataBar Stacked Omnidirectional",
  databarstackedomnicomposite: "GS1 DataBar Stacked Omnidirectional Composite",
  databartruncated: "GS1 DataBar Truncated",
  databartruncatedcomposite: "GS1 DataBar Truncated Composite",
  datalogic2of5: "Datalogic 2 of 5",
  datamatrix: "Data Matrix",
  datamatrixrectangular: "Data Matrix Rectangular",
  datamatrixrectangularextension: "Data Matrix Rectangular Extension",
  dotcode: "DotCode",
  ean13: "EAN-13",
  ean13composite: "EAN-13 Composite",
  ean14: "GS1-14",
  ean2: "EAN-2 (2 digit addon)",
  ean5: "EAN-5 (5 digit addon)",
  ean8: "EAN-8",
  ean8composite: "EAN-8 Composite",
  flattermarken: "Flattermarken",
  "gs1-128": "GS1-128",
  "gs1-128composite": "GS1-128 Composite",
  "gs1-cc": "GS1 Composite 2D Component",
  gs1datamatrix: "GS1 Data Matrix",
  gs1datamatrixrectangular: "GS1 Data Matrix Rectangular",
  gs1dotcode: "GS1 DotCode",
  gs1northamericancoupon: "GS1 North American Coupon",
  gs1qrcode: "GS1 QR Code",
  hanxin: "Han Xin Code",
  hibcazteccode: "HIBC Aztec Code",
  hibccodablockf: "HIBC Codablock F",
  hibccode128: "HIBC Code 128",
  hibccode39: "HIBC Code 39",
  hibcdatamatrix: "HIBC Data Matrix",
  hibcdatamatrixrectangular: "HIBC Data Matrix Rectangular",
  hibcmicropdf417: "HIBC MicroPDF417",
  hibcpdf417: "HIBC PDF417",
  hibcqrcode: "HIBC QR Code",
  iata2of5: "IATA 2 of 5",
  identcode: "Deutsche Post Identcode",
  industrial2of5: "Industrial 2 of 5",
  interleaved2of5: "Interleaved 2 of 5 (ITF)",
  isbn: "ISBN",
  ismn: "ISMN",
  issn: "ISSN",
  itf14: "ITF-14",
  japanpost: "Japan Post 4 State Customer Code",
  kix: "Royal Dutch TPG Post KIX",
  leitcode: "Deutsche Post Leitcode",
  mailmark: "Royal Mail Mailmark",
  matrix2of5: "Matrix 2 of 5",
  maxicode: "MaxiCode",
  micropdf417: "MicroPDF417",
  microqrcode: "Micro QR Code",
  msi: "MSI Modified Plessey",
  onecode: "USPS Intelligent Mail",
  pdf417: "PDF417",
  pdf417compact: "Compact PDF417",
  pharmacode: "Pharmaceutical Binary Code",
  pharmacode2: "Two-track Pharmacode",
  planet: "USPS PLANET",
  plessey: "Plessey UK",
  posicode: "PosiCode",
  postnet: "USPS POSTNET",
  pzn: "Pharmazentralnummer (PZN)",
  qrcode: "QR Code",
  rationalizedCodabar: "Codabar",
  raw: "Custom 1D symbology",
  rectangularmicroqrcode: "Rectangular Micro QR Code",
  royalmail: "Royal Mail 4 State Customer Code",
  sscc18: "SSCC-18",
  symbol: "Miscellaneous symbols",
  telepen: "Telepen",
  telepennumeric: "Telepen Numeric",
  ultracode: "Ultracode",
  upca: "UPC-A",
  upcacomposite: "UPC-A Composite",
  upce: "UPC-E",
  upcecomposite: "UPC-E Composite",
};

export type BarcodeType = keyof typeof SupportedBarcodes;

@MetaFormComponentInfo({
  selector: "ma-barcode",
  type: "barcode",
  name: "Barcode",
  icon: "barcode",
  palletGroup: "reporting",
})
export class BarcodeComponent extends MetaFormComponent {
  @MetaFormComponentProperty({
    name: "Type",
    type: "select",
    tab: "general",
    default: "qrcode",
    values: Object.entries(SupportedBarcodes)
      .map(([key, v]) => {
        return {
          label: v,
          value: key,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label)),
  })
  codeType: BarcodeType;

  @MetaFormComponentProperty({
    name: "Daten",
    type: "text",
    isTemplate: true,
  })
  public displayValue?: string;

  @MetaFormComponentProperty({
    name: "Include Text",
    type: "boolean",
    tab: "style",
    default: false,
  })
  public includetext = false;

  @MetaFormComponentProperty({
    name: "Barcode Color",
    type: "color",
    default: "#000000",
    tab: "style",
  })
  public barcolor = "#000000";

  @MetaFormComponentProperty({
    name: "Background Color",
    type: "color",
    default: "#ffffff",
    tab: "style",
  })
  public backgroundcolor = "#ffffff";

  @MetaFormComponentProperty({
    name: "Border Color",
    type: "color",
    default: "#000000",
    tab: "style",
  })
  public bordercolor = "#000000";

  @MetaFormComponentProperty({
    name: "Text Color",
    type: "color",
    default: "#000000",
    tab: "style",
  })
  public textcolor = "#000000";

  @MetaFormComponentProperty({
    name: "Height",
    type: "number",
    min: 0,
    default: 50,
    tab: "style",
  })
  public height?: number;
  @MetaFormComponentProperty({
    name: "Width",
    type: "number",
    min: 0,
    default: 50,
    tab: "style",
  })
  public width?: number & string;

  @MetaFormComponentProperty({
    name: "Align",
    type: "select",
    default: "left",
    values: [
      {
        label: "Left",
        value: "left",
      },
      {
        label: "Right",
        value: "right",
      },
      {
        label: "Center",
        value: "center",
      },
    ],
    tab: "style",
  })
  public align?: "left" | "right" | "center" = "left";

  constructor() {
    super();
  }

  public static create<T>(dataSource: T, opts: Partial<BarcodeComponent>) {
    const input = new BarcodeComponent();
    Object.assign(input, opts);
    return input;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      key: this.id,
      type: `meta-${this.constructor["meta"]["type"]}`,
      props: {
        codeType: this.codeType,
        width: this.width,
        height: this.height,
        align: this.align,
        barcolor: this.barcolor,
        textcolor: this.textcolor,
        bordercolor: this.bordercolor,
        backgroundcolor: this.backgroundcolor,
        includetext: this.includetext,
      },
    });
  }
}
