/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2021
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2021
 */

import { Type } from "class-transformer";
import { Allow, IsArray, IsNumber, IsOptional, IsString, IsUUID, ValidateNested } from "class-validator";

export class SpecificationItemDto {
  @IsUUID()
  @IsOptional()
  public id?: string;
  @IsString()
  @IsOptional()
  public description: string;
  @IsString()
  @IsOptional()
  public label: string;
  @IsString()
  @IsOptional()
  public type: string;
  @Allow()
  @IsOptional()
  public value: any;
  @IsString()
  @IsOptional()
  public unit: string;
}

export class SpecificationDataDto {
  @IsUUID()
  public id: string;
  @IsString()
  public label: string;
  @IsString()
  @IsOptional()
  public description: string;
  @IsNumber()
  public w: number;
  @IsNumber()
  public h: number;
  @IsNumber()
  public y: number;
  @IsNumber()
  public x: number;
  @Type(() => SpecificationItemDto)
  @ValidateNested()
  children: SpecificationItemDto[];
}

export class GetAllGroupsResponseDto {
  @IsString()
  public id: string;
  @IsString()
  @IsUUID()
  public formId: string;
  @Type(() => SpecificationDataDto)
  @ValidateNested()
  children: SpecificationDataDto[];
}

export class SpecificationResponseDto {
  @IsString()
  public id: string;
  @IsString()
  public formId: string;
  @Type(() => SpecificationDataDto)
  @ValidateNested()
  children: SpecificationDataDto[];
  @Type(() => SpecificationDataDto)
  @ValidateNested()
  relatedChildren: SpecificationDataDto[];
}

export class SpecificationsByGroupRequestDto {
  @IsString()
  @IsOptional()
  type: string;
}

export class SpecificationSelectRequestDto {
  @IsString()
  @IsOptional()
  searchString: string;

  @IsNumber()
  @IsOptional()
  skip: number;

  @IsNumber()
  @IsOptional()
  take: number;
}

export class SpecificationSelectResponseItemDto {
  @IsString()
  label: string;
  @IsString()
  value: string;
}

export class SpecificationSelectResponseDto {
  @IsNumber()
  total: number;
  @IsArray()
  @Type(() => SpecificationSelectResponseItemDto)
  items: SpecificationSelectResponseItemDto[];
}

export class SpecificationCombinationsDto {
  @IsString()
  sourceTag: string;
  @IsString()
  targetTag: string;
  @IsString()
  @IsArray()
  @IsOptional()
  value: any;
}

export class SpecificationGroupsDto {
  @IsString()
  targetGroupId: number;
  @IsArray()
  @Type(() => SpecificationCombinationsDto)
  combinations: SpecificationCombinationsDto[];
}

export class SpecificationCombinationsRequestDto {
  @IsArray()
  @Type(() => SpecificationGroupsDto)
  groups: SpecificationGroupsDto[];
}
