/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ExposeProperty, MetaFormComponentInfo, MetaFormComponentProperty } from "./components";
import type { DataSourceType } from "@meta/database/lib/types";
import { Expose } from "class-transformer";

@MetaFormComponentInfo({
  selector: "ma-sql",
  type: "sqlDataSource",
})
export class SqlDataSource {
  @MetaFormComponentProperty({
    name: "useTempTable",
    type: "boolean",
    default: false,
  })
  public useTempTable = false;
  @MetaFormComponentProperty({
    name: "query",
    type: "sql",
  })
  public query?: string;
  @MetaFormComponentProperty({
    name: "updatable",
    type: "boolean",
    default: false,
  })
  public updatable = false;

  public static fromString(queryString: string, updatable = false) {
    const src = new SqlDataSource();
    src.query = queryString.trim();
    const lastChar = src.query.slice(-1);
    if (lastChar === ";") {
      src.query = src.query.slice(0, -1);
    }
    const queryLowerCase = src.query.toLowerCase();
    if (queryLowerCase.includes("order by")) {
      src.useTempTable = true;
    }
    src.updatable = updatable;
    return src;
  }

  public static createElement(attributes: any, children: any[]) {
    if (Array.isArray(children) && children.length > 0 && typeof children[0] === "string") {
      return this.fromString(children[0]);
    } else {
      throw new Error("No Sql query found.");
    }
  }

  toString() {
    return this.query;
  }
}

@MetaFormComponentInfo({
  selector: "ma-relation",
  type: "sqlRelation",
})
export class SqlRelation {
  @MetaFormComponentProperty({
    name: "DataSourceType",
    type: "dataSource",
  })
  table: DataSourceType | string;
  @MetaFormComponentProperty({
    name: "Alias",
    type: "text",
  })
  alias: string;
  @MetaFormComponentProperty({
    name: "On",
    type: "text",
  })
  on: string;
  @MetaFormComponentProperty({
    name: "joinMethod",
    type: "select",
    values: [
      {
        label: "join",
        value: "join",
      },
      {
        label: "apply",
        value: "apply",
      },
    ],
  })
  joinMethod?: "join" | "apply" = "join";
  @MetaFormComponentProperty({
    name: "joinType",
    type: "select",
    values: [
      {
        label: "inner",
        value: "inner",
      },
      {
        label: "left",
        value: "left",
      },
      {
        label: "left outer",
        value: "left outer",
      },
      {
        label: "cross",
        value: "cross",
      },
      {
        label: "outer",
        value: "outer",
      },
    ],
  })
  joinType?: "inner" | "left" | "left outer" | "cross" | "outer" = "left";
  @MetaFormComponentProperty({
    name: "type",
    type: "select",
    values: [
      {
        label: "procedure",
        value: "procedure",
      },
      {
        label: "table",
        value: "table",
      },
    ],
  })
  type?: "procedure" | "table" = "table";
  @ExposeProperty()
  autoGenerated? = false;
  @ExposeProperty()
  defaultParams?: Record<string, any> = {};
  @MetaFormComponentProperty({
    name: "Erstellen erlauben",
    type: "boolean",
  })
  allowInsert? = true;

  constructor(opts: SqlRelation) {
    Object.assign(this, opts);
  }
}
