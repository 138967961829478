/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { NgModule } from "@angular/core";
import { NzBadgeComponent } from "ng-zorro-antd/badge";
import { NzSkeletonComponent } from "ng-zorro-antd/skeleton";
import { ChatComponent } from "./chat.component";
import { ChatButtonComponent } from "./chat-button.component";
import { CommonModule } from "@angular/common";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { ChatService } from "./chat.service";
import { ReactiveFormsModule } from "@angular/forms";
import { RenderMarkDownPipe } from "./markdown.pipe";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { MetaIconModule } from "../../../../../../libs/ui/src/lib/components/metaIcon/metaIcon.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzListModule } from "ng-zorro-antd/list";
import { NgxTolgeeModule } from "@tolgee/ngx";

@NgModule({
  providers: [ChatService],
  imports: [
    CommonModule,
    NzToolTipModule,
    NzPopoverModule,
    ReactiveFormsModule,
    NzPopconfirmModule,
    MetaIconModule,
    NzButtonModule,
    NzInputModule,
    NzListModule,
    NgxTolgeeModule,
    RenderMarkDownPipe,
    NzSkeletonComponent,
    NzBadgeComponent,
  ],
  declarations: [ChatComponent, ChatButtonComponent],
  exports: [ChatButtonComponent],
})
export class ChatModule {}
