/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2021
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2021
 */

import { takeUntil } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, forwardRef, NgModule, OnInit, ViewEncapsulation } from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import { FormlyAttributeEvent } from "@ngx-formly/core/lib/models/fieldconfig";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzFormModule } from "ng-zorro-antd/form";
import { MetaComponentBase, MetaFormBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaActionHandlerFactory } from "../../base/metaForm/actions/actionHandler.factory";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";

export class MetaCheckbox extends MetaFormBase {
  checkedIcon? = "check";
  uncheckedIcon? = "times";
  uniqueGroup?: string | null = null;
  boxLabel?: string;
  onClick?: FormlyAttributeEvent;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-checkbox",
  template: `
    <ng-container *ngIf="editing && !ma.readonly; else outputTpl">
      <nz-checkbox-wrapper *ngIf="field; else ngModelTpl" (nzOnChange)="onClick(field, $event)">
        <label [formControl]="fc" [formlyAttributes]="field" nz-checkbox>{{ ma.boxLabel }}</label>
      </nz-checkbox-wrapper>
      <ng-template #ngModelTpl>
        <nz-checkbox-wrapper (nzOnChange)="onClick(field, $event)">
          <label [(ngModel)]="value" (ngModelChange)="onChange($event)" nz-checkbox>{{ ma.boxLabel }}</label>
        </nz-checkbox-wrapper>
      </ng-template>
    </ng-container>
    <ng-template #outputTpl>
      <div class="output" [ngClass]="{ true: field ? model[id] : value, false: !(field ? model[id] : value) }">
        <ng-container
          *ngTemplateOutlet="(field ? model[id] : value) ? checkedTemplate : unCheckedTemplate"
        ></ng-container>
      </div>
    </ng-template>
    <ng-template #checkedTemplate><i class="far fa-{{ ma.checkedIcon }}"></i></ng-template>
    <ng-template #unCheckedTemplate><i class="far fa-{{ ma.uncheckedIcon }}"></i></ng-template>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetaCheckboxComponent),
      multi: true,
    },
  ],
  styleUrls: ["./metaCheckbox.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaCheckboxComponent extends MetaComponentBase {
  constructor(
    private readonly _metaActionHandler: MetaActionHandlerFactory,
    private readonly _metaHelperService: MetaHelperService,
  ) {
    super();
    super.maParams = new MetaCheckbox();
  }

  get ma(): MetaCheckbox {
    return super.ma;
  }

  public ngOnInit() {
    super.ngOnInit();
    if (this.field) {
      this.formControl.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(350), takeUntil((this as any).destroyed$))
        .subscribe((value) => this.executeChangeAction(value));
    }
  }

  private async executeChangeAction(value: any) {
    if (this.formControl.pristine) {
      return;
    }

    if (value === "" && this.formControl.dirty) {
      this.formControl.reset(null);
      return;
    }

    if (!this.ma.onChange) {
      return;
    }
    let model = this.formState.data();
    if (Object.keys(model).length === 0) {
      model = this.form.value;
    }
    await this._metaActionHandler.executeChangeAction({
      formId: this.formState.formId,
      controlId: this.id,
      data: {
        ...model,
        [this.id]: value,
      },
      subFormPath: this._metaHelperService.getFormlySubFormPath(this.field),
      ctx: model?._ctx,
      index: this._metaHelperService.getFormlyFieldArrayIndex(this.field),
    });
  }

  public onClick(field: FormlyFieldConfig, $event: any) {
    if (this.ma.uniqueGroup) {
      const subFormPath = this.metaHelperService.getFormlySubFormPath(this.field);
      const subFormIndexPath = this.metaHelperService.getFormlyFieldArrayIndex(this.field);
      const model = this.formState.data();

      if (subFormPath.length === 1) {
        model[subFormPath[0]].forEach((elem, i) => {
          if (i !== subFormIndexPath) {
            elem[this.id] = false;
          }
        });
      }
    }

    if (this.ma.onClick instanceof Function) {
      this.ma.onClick(field, $event);
    }
  }
}

@NgModule({
  declarations: [MetaCheckboxComponent],
  imports: [CommonModule, FormlyModule, FormsModule, ReactiveFormsModule, NzFormModule, NzCheckboxModule],
  exports: [MetaCheckboxComponent],
})
export class MetaCheckboxModule {}
