/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2021
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2021
 * Written by Yannick Küchlin <y.kuechlin@taifun-tofu.de>, 2019-2020
 */


export type MetaNotificationType = "success" | "info" | "warning" | "error";

export class MetaMessage {
  public content: string;
  public type: MetaNotificationType = "info";
  public duration?: number;
  public id?: string;
}


export type MetaNotificationScope = "current-user" | "everyone";

export class MetaNotification {
  public title: string;
  public content?: string;
  public type: MetaNotificationType = "info";
  public duration?: number;
  public id?: string;
  public scope?: MetaNotificationScope = "current-user";
}
