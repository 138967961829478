/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NzButtonComponent, NzButtonModule } from "ng-zorro-antd/button";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { SearchComponent, SearchFacetedToSelect, SearchFilterDisplay } from "./search.component";
import { SearchService } from "./search.service";
import { OverlayModule } from "@angular/cdk/overlay";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzListModule } from "ng-zorro-antd/list";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { SearchDescriptionPipe } from "./searchDescriptionPipe";
import { NzDescriptionsModule } from "ng-zorro-antd/descriptions";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { ArgonRouterLink } from "../../../../../../libs/ui/src/lib/pipes/metaRouterLink";
import { NgxTolgeeModule } from "@tolgee/ngx";

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    ReactiveFormsModule,
    RouterModule,
    NzTagModule,
    NzListModule,
    NzSelectModule,
    FormsModule,
    NzPaginationModule,
    NzPopoverModule,
    NzDescriptionsModule,
    NzToolTipModule,
    ArgonRouterLink,
    NgxTolgeeModule,
    NzButtonModule,
    NzCheckboxModule,
  ],
  declarations: [SearchComponent, SearchFacetedToSelect, SearchFilterDisplay, SearchDescriptionPipe],
  exports: [SearchComponent],
  providers: [SearchService],
})
export class SearchModule {
  constructor(private readonly searchService: SearchService) {
    searchService.init();
  }
}
