/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { MetaIconStyle } from "@meta/enums";
import { MetaInputModule } from "@meta/ui";
import { FormlyModule } from "@ngx-formly/core";
import { NzMarks, NzSliderModule } from "ng-zorro-antd/slider";
import * as uuid from "uuid";
import { MetaComponentBase, MetaFormBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaColumnModule } from "../metaColumn/metaColumn.component";
import { MetaRowModule } from "../metaRow/metaRow.component";

export class MetaSlider extends MetaFormBase {
  range? = false;
  min? = 0;
  max? = 100;
  marks?: NzMarks = {};
  step?: number | null = 1;
  included? = true;
  iconStyleBefore?: MetaIconStyle = MetaIconStyle.Light;
  iconStyleAfter?: MetaIconStyle = MetaIconStyle.Light;
  iconBefore?: string;
  iconAfter?: string;
  tooltipVisible?: "default" | "always" | "never" = "default";
  showInputs? = false;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-slider",
  template: `
    <ng-container *ngTemplateOutlet="editing && !ma.readonly ? controlTpl : outputTpl"></ng-container>
    <i *ngIf="ma.iconBefore" class="{{ ma.iconStyleBefore }} fa-{{ ma.iconBefore }} before"></i>
    <i *ngIf="ma.iconAfter" class="{{ ma.iconStyleAfter }} fa-{{ ma.iconAfter }} after"></i>
    <ng-template #controlTpl>
      <nz-slider
        *ngIf="field; else ngModelTpl"
        [formControl]="fc"
        [formlyAttributes]="field"
        [nzDisabled]="ma.disabled"
        [nzIncluded]="ma.included"
        [nzTooltipVisible]="ma.tooltipVisible"
        [nzMarks]="ma.marks"
        [nzMax]="ma.max"
        [nzMin]="ma.min"
        [nzRange]="ma.range"
        [nzStep]="ma.step"
        (nzOnAfterChange)="onRangeChange($event)"
      ></nz-slider>
      <meta-row *ngIf="ma.showInputs && ma.range">
        <meta-column [maParams]="{ span: 11 }">
          <meta-input
            *ngIf="ma.showInputs && ma.range"
            [maParams]="{
              inputType: 'number',
              digits: 0,
              editing: true
            }"
            [(ngModel)]="min"
            (ngModelChange)="onValueChange($event)"
          ></meta-input>
        </meta-column>
        <meta-column [maParams]="{ span: 2 }"> -</meta-column>
        <meta-column [maParams]="{ span: 11 }">
          <meta-input
            *ngIf="ma.showInputs && ma.range"
            [maParams]="{
              inputType: 'number',
              digits: 0,
              editing: true
            }"
            [(ngModel)]="max"
            (ngModelChange)="onValueChange($event)"
          ></meta-input>
        </meta-column>
      </meta-row>
      <ng-template #ngModelTpl>
        <nz-slider
          [nzDisabled]="ma.disabled"
          [nzIncluded]="ma.included"
          [nzTooltipVisible]="ma.tooltipVisible"
          [nzMarks]="ma.marks"
          [nzMax]="ma.max"
          [nzMin]="ma.min"
          [nzRange]="ma.range"
          [nzStep]="ma.step"
          [(ngModel)]="value"
          (ngModelChange)="onChange($event)"
          (nzOnAfterChange)="onRangeChange($event)"
        ></nz-slider>
      </ng-template>
    </ng-template>
    <ng-template #outputTpl>
      <div class="output" [ngClass]="{ true: model[_id], false: !model[_id] }">
        <span *ngIf="model[_id] !== undefined && model[_id] !== null && !ma.range">{{ model[_id] }}</span>
        <span *ngIf="model[_id] !== undefined && model[_id] !== null && ma.range"
          >{{ model[_id][0] }} bis {{ model[_id][1] }}</span
        >
      </div>
    </ng-template>
  `,
  styleUrls: ["./metaSlider.component.less"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetaSliderComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaSliderComponent extends MetaComponentBase implements OnInit, OnChanges {
  public _id: string;
  public min: number;
  public max: number;

  constructor(_changeDetectorRef: ChangeDetectorRef, _metaHelperService: MetaHelperService) {
    super();
    super.maParams = new MetaSlider();
  }

  get ma(): MetaSlider {
    return super.ma;
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.field) {
      this._id = this.ma.id || uuid.v4();
    } else {
      this._id = this.id;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.maParams?.currentValue?.min !== undefined || changes.maParams?.currentValue?.max !== undefined) {
      this.onMinMaxChange(changes.maParams?.currentValue);
    }
  }

  writeValue(value: any) {
    super.writeValue(value);
    if (value !== undefined && value !== null) {
      this.min = value[0];
      this.max = value[1];
    }
  }

  onMinMaxChange(value: any) {
    this.min = value.min;
    this.max = value.max;
    this.changeDetectorRef.markForCheck();
  }

  onRangeChange(event) {
    this.onChange(event);
    this.min = event[0];
    this.max = event[1];
  }

  onValueChange(value) {
    this.onChange([this.min, this.max]);
  }
}

@NgModule({
  declarations: [MetaSliderComponent],
  imports: [
    CommonModule,
    FormlyModule,
    ReactiveFormsModule,
    FormsModule,
    NzSliderModule,
    MetaInputModule,
    MetaRowModule,
    MetaColumnModule,
  ],
  exports: [MetaSliderComponent],
})
export class MetaSliderModule {}
