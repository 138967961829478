/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, OnInit, Signal } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { toSignal } from "@angular/core/rxjs-interop";
import { BehaviorSubject, firstValueFrom, switchMap } from "rxjs";
import { RenameFileRequest } from "@meta/api-interfaces";
import { NzModalRef } from "ng-zorro-antd/modal";
import { debounceTime, map } from "rxjs/operators";
import { IMetaMediaLibraryFile } from "../interfaces";

@Component({
  styleUrls: ["./metaMediaLibraryEdit.component.less"],
  template: `
    <meta-field-wrapper [maParams]="{ label: 'Name' }">
      <meta-input [maParams]="{ editing: !disabled }" [(ngModel)]="file.name"></meta-input>
    </meta-field-wrapper>
    <meta-field-wrapper [maParams]="{ label: 'Gültigkeit von' }">
      <meta-datepicker
        [maParams]="{
          editing: !disabled,
          placeholder: 'Von',
          datepickerType: 'date'
        }"
        [(ngModel)]="file.validFrom"
      ></meta-datepicker>
    </meta-field-wrapper>
    <meta-field-wrapper [maParams]="{ label: 'Gültigkeit bis' }">
      <meta-datepicker
        [maParams]="{
          editing: !disabled,
          placeholder: 'Bis',
          datepickerType: 'date'
        }"
        [(ngModel)]="file.validTo"
      ></meta-datepicker>
    </meta-field-wrapper>
    <meta-field-wrapper [maParams]="{ label: 'Klassifizierung' }">
      <meta-select
        [maParams]="{
          editing: !disabled,
          selectDataUrl: 'media-library/classifications',
          editForm: 'frmDocumentClassifications',
          loadDataOnInit: true
        }"
        [(ngModel)]="file.classification"
      ></meta-select>
    </meta-field-wrapper>
    <meta-field-wrapper [maParams]="{ label: 'Aufbewahrungsrichtlinie' }">
      <meta-select
        [maParams]="{
          editing: !disabled,
          disabled: file.retentionPolicy > 0 && oldRetentionPolicy > 0,
          selectDataUrl: 'media-library/retention-policies',
          loadDataOnInit: true
        }"
        [(ngModel)]="file.retentionPolicy"
      ></meta-select>
    </meta-field-wrapper>
    @if (file.retentionPolicy !== 0 && oldRetentionPolicy === 0) {
      <nz-alert
        nzType="warning"
        nzMessage="Das Ändern der Aufbewahrungsrichtlinie kann nicht rückgängig gemacht werden."
      ></nz-alert>
    }
    <meta-field-wrapper [maParams]="{ label: 'Öffentlich' }">
      <meta-switcher [maParams]="{ editing: !disabled }" [(ngModel)]="file.external"></meta-switcher>
    </meta-field-wrapper>
    <meta-field-wrapper [maParams]="{ label: 'Beschreibung' }">
      <meta-textarea
        [maParams]="{ editing: !disabled, placeholder: 'Beschreibung' }"
        [(ngModel)]="file.description"
      ></meta-textarea>
    </meta-field-wrapper>

    <meta-field-wrapper [maParams]="{ label: 'Tags' }">
      <nz-select
        [nzServerSearch]="true"
        (nzOnSearch)="searchTags($event)"
        style="width: 100%"
        [(ngModel)]="tags"
        nzMode="multiple"
      >
        @for (t of tagsSelectData(); track t.value) {
          <nz-option [nzValue]="t.value" [nzLabel]="t.label" />
        }
      </nz-select>
    </meta-field-wrapper>
  `,
})
export class MetaMediaLibraryEditComponent implements OnInit {
  public file: IMetaMediaLibraryFile;
  public fileId: string;
  public tags: any[] = [];

  public validRange: [Date, Date] = null;
  public disabled = false;
  public classifications: any[] = [];
  public selectDataUrl = "media-library/tags";
  public oldRetentionPolicy: number;

  public readonly tagsSelectData: Signal<any[]>;
  public readonly tagSearchText$ = new BehaviorSubject<string>("");

  constructor(
    private readonly httpClient: HttpClient,
    private readonly modalRef: NzModalRef,
  ) {
    this.tagsSelectData = toSignal(
      this.tagSearchText$.pipe(
        debounceTime(350),
        switchMap((value) => {
          return this.httpClient
            .post<any>("media-library/tags", {
              searchString: value || "",
            })
            .pipe(
              map((res) => {
                return [
                  ...this.file?.tags.map((t: any) => {
                    return {
                      label: t.name,
                      value: t.id,
                    };
                  }),
                  ...res,
                ];
              }),
            );
        }),
      ),
    );
  }

  ngOnInit(): void {
    this.tags = ((this.file as any)?.tags || []).map((t) => t.id);
    this.oldRetentionPolicy = this.file?.retentionPolicy;
    this.modalRef.updateConfig({
      nzOkText: "Übernehmen",
      nzOnOk: async () => {
        await this.save();
        return true;
      },
    });
  }

  public async save() {
    this.disabled = true;
    const request: RenameFileRequest = {
      validFrom: this.file.validFrom,
      validUntil: this.file.validTo,
      classification: this.file.classification as any,
      description: this.file.description,
      external: this.file.external,
      name: this.file.name,
      tags: this.tags,
      retentionPolicy: this.file.retentionPolicy,
    };
    await firstValueFrom(this.httpClient.post(`file/${this.file.id}/rename`, request));
  }

  public searchTags(q: string) {
    this.tagSearchText$.next(q);
  }
}
