/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzIconDirective } from "ng-zorro-antd/icon";
import {
  NzResultComponent,
  NzResultIconDirective,
  NzResultSubtitleDirective,
  NzResultTitleDirective,
} from "ng-zorro-antd/result";
import { NzTypographyComponent } from "ng-zorro-antd/typography";
import { MetaButtonModule } from "../metaButton/metaButton.component";
import { MetaIconModule } from "../metaIcon/metaIcon.component";
import { MetaToolbarModule } from "../metaToolbar/metaToolbar.component";
import { MetaTranslateChildDirective, MetaTranslateComponent } from "../metaTranslate/metaTranslate.component";
import { MetaErrorComponent } from "./metaError.compponent";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzEmptyModule,
    MetaIconModule,
    MetaButtonModule,
    MetaToolbarModule,
    NzResultComponent,
    NzTypographyComponent,
    NzIconDirective,
    NzButtonComponent,
    NgxTolgeeModule,
    MetaTranslateComponent,
    MetaTranslateChildDirective,
    NzResultTitleDirective,
    NzResultSubtitleDirective,
    NzResultIconDirective,
  ],
  declarations: [MetaErrorComponent],
  exports: [MetaErrorComponent],
})
export class MetaErrorModule {}
