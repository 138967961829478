/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "lineBreak" })
export class LineBreakPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.replace(/\\n/g, "<br>");
    }
    return "";
  }
}
