/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, forwardRef, NgModule, OnInit, ViewEncapsulation } from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { MetaPrefixType, MetaSuffixType } from "@meta/enums";
import { MetaButtonModule, MetaLoaderModule, MetaTagModule } from "@meta/ui";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyAttributeEvent } from "@ngx-formly/core/lib/models/fieldconfig";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { NzAutocompleteModule } from "ng-zorro-antd/auto-complete";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzModalService } from "ng-zorro-antd/modal";
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  mergeMap,
  skip,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import * as uuid from "uuid";
import { MetaComponentBase, MetaFormBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaActionHandlerFactory } from "../../base/metaForm/actions/actionHandler.factory";
import { MetaFormService } from "../../base/metaForm/metaForm.service";
import { PipesModule } from "../../pipes/pipes.module";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaButton } from "../metaButton/metaButton.component";
import { MetaAutocompleteService } from "./metaAutocomplete.service";

export class MetaAutocomplete extends MetaFormBase {
  data?: any[];
  skip? = 0;
  take? = 50;
  placeholder? = "";
  prefix?: string;
  prefixType?: MetaPrefixType = MetaPrefixType.text;
  suffix?: string;
  suffixType?: MetaSuffixType = MetaSuffixType.text;
  searchType?: "tags" | "search" | "default" = "default";
  datasourceLabel? = "label";
  datasourceValue? = "value";
  datasourceGroup? = "Gruppe";
  returnType?: "value" | "object" = "value";
  tagId?: string;
  actions?: MetaButton[];
  creatable? = false;
  isTableControl?: boolean;
  parentFormId?: string = null;
  allowSelectLockedEntries?: boolean;
  contextData?: any;

  onSelect?: boolean;
  onEnter?: FormlyAttributeEvent;
  onValueChange?: FormlyAttributeEvent;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-autocomplete",
  template: `
    <ng-container *ngIf="editing && !ma.readonly; else outputTpl">
      <nz-input-group
        [nzSuffix]="displayData.suffix || ma.suffix ? suffixTpl : null"
        [nzPrefix]="displayData.prefix || ma.prefix ? prefixTpl : null"
        [nzAddOnAfter]="ma.actions?.length > 0 ? addOnAfterTemplate : null"
        [nzSearch]="ma.actions?.length > 0"
        [ngClass]="{ 'input-group-wrapper-disabled': ma.disabled }"
      >
        <ng-container *ngTemplateOutlet="inputTpl"></ng-container>
      </nz-input-group>
      <nz-autocomplete [nzDefaultActiveFirstOption]="false" [nzBackfill]="true" #autoString>
        <ng-container *ngIf="isLoading">
          <meta-loader *ngIf="isLoading" [style.minHeight.px]="50"></meta-loader>
        </ng-container>
        <ng-container *ngIf="!isLoading">
          <nz-auto-option
            *ngFor="
              let option of ma?.data || (metaAutocompleteService.autocompleteData$ | async);
              let i = index;
              trackBy: trackById
            "
            [nzValue]="option[ma.datasourceLabel] || option"
            (click)="onSelect(option[ma.datasourceLabel] || option)"
          >
            <div>{{ option[ma.datasourceLabel] || option }}</div>
          </nz-auto-option>
        </ng-container>
      </nz-autocomplete>
      <nz-autocomplete [nzDefaultActiveFirstOption]="false" [nzBackfill]="true" [compareWith]="compare" #autoObject>
        <ng-container *ngIf="isLoading">
          <meta-loader *ngIf="isLoading" [style.minHeight.px]="50"></meta-loader>
        </ng-container>
        <ng-container *ngIf="!isLoading">
          <nz-auto-option
            *ngFor="
              let option of ma?.data || (metaAutocompleteService.autocompleteData$ | async);
              let i = index;
              trackBy: trackById
            "
            [nzValue]="option"
            [nzLabel]="option[ma.datasourceLabel]"
            (click)="onSelect(option)"
          >
            <div>
              <span [ngClass]="{ add: option.new, 'text-danger': option?.locked === 1 || option?.locked === true }"
                ><i
                  class="fas fa-lock"
                  [style.paddingRight.px]="8"
                  *ngIf="option?.locked === 1 || option?.locked === true"
                ></i
                ><span *ngIf="option?.locked === 1 || option?.locked === true">&nbsp;</span>
                @if (option.icon === "google") {
                  <i [style.paddingRight.px]="8" class="fa-brands fa-google"></i>
                } @else if (option.icon) {
                  <span *ngIf="option.icon" [innerHTML]="option.icon | sanitizeHtml"></span>
                }
                <span
                  [innerHTML]="
                    ma.translateLabels ? (option[ma.datasourceLabel] | translate) : option[ma.datasourceLabel]
                  "
                ></span>
                <span *ngIf="option.new">einladen</span></span
              >
            </div>
            <div *ngIf="option?.data && option?.data[ma.datasourceGroup]" class="autocomplete-sub-option">
              in {{ option?.data[ma.datasourceGroup] }}
            </div>
            <div
              *ngIf="option?.primaryItem && option?.primaryItem[ma.datasourceGroup]"
              class="autocomplete-sub-option"
              [ngClass]="{ 'text-danger': option?.locked === 1 || option?.locked === true }"
            >
              {{ option?.primaryItem[ma.datasourceGroup] || "-" }}
            </div>
            <div *ngIf="option['_source'] === 'google'" class="autocomplete-sub-option">
              aus <span [style.color]="'#3a7aed'">G</span><span [style.color]="'#e63d33'">o</span
              ><span [style.color]="'#fab42d'">o</span><span [style.color]="'#3a7aed'">g</span
              ><span [style.color]="'#319e4f'">l</span><span [style.color]="'#e63d33'">e</span> übernehmen
            </div>
          </nz-auto-option>
        </ng-container>
      </nz-autocomplete>

      <ng-template #inputTpl>
        <input
          *ngIf="field; else ngModelTpl"
          autocomplete="off"
          (ngModelChange)="onInput($event)"
          [formControl]="fc"
          [formlyAttributes]="field"
          [nzAutocomplete]="ma.returnType === 'value' ? autoString : autoObject"
          [placeholder]="ma.placeholder"
          nz-input
          type="text"
          (blur)="onBlur()"
          (focus)="onFocus()"
        />
        <ng-template #ngModelTpl>
          <input
            autocomplete="off"
            (ngModelChange)="onInput($event)"
            [(ngModel)]="value"
            [nzAutocomplete]="ma.returnType === 'value' ? autoString : autoObject"
            [placeholder]="ma.placeholder"
            nz-input
            type="text"
            [disabled]="ma.disabled"
            (blur)="onBlur()"
            (focus)="onFocus()"
          />
        </ng-template>
        <meta-loader *ngIf="isLoading" [maParams]="{ scale: 50 }"></meta-loader>
        <meta-tag
          *ngIf="showNewTag()"
          class="new-tag"
          [maParams]="{
            label: 'Neu',
            type: 'success',
            size: 'small'
          }"
        ></meta-tag>
      </ng-template>
    </ng-container>
    <ng-template #outputTpl>
      <p class="output">
        <ng-container *ngTemplateOutlet="prefixTpl"></ng-container>
        {{ field && model ? model[_id] : value }}
        <ng-container *ngTemplateOutlet="suffixTpl"></ng-container>
      </p>
    </ng-template>
    <ng-template #addOnAfterTemplate>
      <meta-button
        *ngFor="let action of ma.actions"
        [maParams]="action.props"
        (click)="action.onClick ? action.onClick(this.field, $event) : this.onDefaultClick(action)"
      ></meta-button>
    </ng-template>
    <ng-template #prefixTpl>
      <ng-container *ngIf="displayData.prefix || ma.prefix">
        <ng-container [ngSwitch]="ma.prefixType">
          <ng-container *ngSwitchCase="metaPrefixType.text">
            {{ displayData.prefix || ma.prefix }}
          </ng-container>
          <ng-container *ngSwitchCase="metaPrefixType.icon">
            <i class="fas fa-{{ displayData.prefix || ma.prefix }}"></i>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
    <ng-template #suffixTpl>
      <ng-container *ngIf="displayData.suffix || ma.suffix">
        <ng-container [ngSwitch]="ma.suffixType">
          <ng-container *ngSwitchCase="metaSuffixType.text">
            {{ displayData.suffix || ma.suffix }}
          </ng-container>
          <ng-container *ngSwitchCase="metaSuffixType.icon">
            <i class="fas fa-{{ displayData.suffix || ma.suffix }}"></i>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
  `,
  styleUrls: ["./metaAutocomplete.component.less"],
  providers: [
    MetaAutocompleteService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetaAutocompleteComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaAutocompleteComponent extends MetaComponentBase implements OnInit {
  public _id: string;
  public metaSuffixType = MetaSuffixType;
  public metaPrefixType = MetaPrefixType;
  public executeAction: boolean;
  public hasFocus = false;
  public isLocalData: boolean;

  public searchTerm = new BehaviorSubject<string>("");
  private _skip = new BehaviorSubject<number>(null);

  constructor(
    private readonly _metaActionHandler: MetaActionHandlerFactory,
    private readonly _nzModalService: NzModalService,
    public readonly metaAutocompleteService: MetaAutocompleteService,
  ) {
    super();
    super.maParams = new MetaAutocomplete();
  }

  get ma(): MetaAutocomplete {
    return super.ma;
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.field) {
      this._id = this.ma.id || uuid.v4();
    } else {
      this._id = this.id;
    }

    if (this.ma.data) {
      this.isLocalData = true;
      this.prepareData(this.ma.data);
    }

    this.searchTerm
      .pipe(
        filter(
          (value, index) =>
            typeof value !== "object" &&
            value !== null &&
            value !== undefined &&
            value !== "[object Object]" &&
            value !== "",
        ),
        skip(1), // Skip initial value from BehaviorSubject
        debounceTime(750),
        distinctUntilChanged(),
        takeUntil((this as any).destroyed$),
        tap((e) => {
          this.isLoading = true;
          this.changeDetectorRef.markForCheck();
        }),
        switchMap((term: string) =>
          this.metaAutocompleteService.getData({
            formId: this.field ? this.formState.formId : null,
            fieldId: this._id,
            skip: 0,
            take: this.ma.take,
            filter: term,
            searchType: this.ma.searchType,
            tagId: this.ma.tagId,
            data: this.field ? this.formState.data() : this.ma.contextData,
            contextId: this.field ? this.formState.data()._ctx : this.ma.contextData?._ctx,
          }),
        ),
        tap(() => {
          this.isLoading = false;
          this.changeDetectorRef.markForCheck();
        }),
      )
      .subscribe();

    this._skip
      .pipe(
        filter((value, index) => typeof value !== "object" && value !== null && value !== undefined),
        skip(1), // Skip initial value from BehaviorSubject
        distinctUntilChanged(),
        takeUntil((this as any).destroyed$),
        tap(() => {
          this.isLoading = true;
          this.changeDetectorRef.markForCheck();
        }),
        mergeMap((_skip: number) =>
          this.metaAutocompleteService.getData({
            formId: this.field ? this.formState.formId : null,
            fieldId: this._id,
            skip: _skip,
            take: this.ma.take,
            filter: this.searchTerm.getValue(),
            searchType: this.ma.searchType,
            tagId: this.ma.tagId,
            data: this.field ? this.formState.data() : this.ma.contextData,
            contextId: this.field ? this.formState.data()._ctx : this.ma.contextData?._ctx,
          }),
        ),
        tap(() => {
          this.isLoading = false;
          this.changeDetectorRef.markForCheck();
        }),
      )
      .subscribe();
  }

  public prepareData(data) {
    if (this.ma.returnType === "value") {
      this.ma.data = data.map((o) => o[this.ma.datasourceLabel]);
    } else {
      this.ma.data = data;
    }
    this.changeDetectorRef.markForCheck();
  }

  public compare = (o1: any | string, o2: any) => {
    if (o1) {
      return typeof o1 === "string"
        ? o1 === o2[this.ma.datasourceLabel]
        : o1[this.ma.datasourceValue] === o2[this.ma.datasourceValue];
    } else {
      return false;
    }
  };

  onInput(term: string | any): void {
    if (this.isLocalData) {
      return;
    }

    if (!this.field) {
      this.onChange(term);
    }

    if (typeof term !== "object" && term !== null && term !== undefined) {
      this.searchTerm.next(term.toString());
    } else {
      this.isLoading = false;
    }
  }

  public onBlur() {
    this.hasFocus = false;
  }

  public onFocus() {
    this.hasFocus = true;
  }

  public clearData() {
    setTimeout(() => {
      this.writeValue(null);
      this.changeDetectorRef.markForCheck();
    });
  }

  public trackById(index: number, item: any) {
    return item[this.ma?.datasourceValue || "value"];
  }

  public async onSelect(item: any) {
    if (this.isLocalData || !this.ma.onSelect) {
      return;
    }

    if (item.locked) {
      await firstValueFrom(
        this._nzModalService.error({
          nzContent: `Dieser Datensatz ist gesperrt und kann nicht ausgewählt werden.`,
          nzTitle: `Gesperrt`,
          nzOkDanger: true,
          nzCancelDisabled: true,
          nzOkText: "Ok",
          nzOnOk: () => {
            return true;
          },
        }).afterClose,
      );
      return;
    }

    await new Promise((r) => setTimeout(r, 200));
    this.isLoading = true;
    this.ma.disabled = true;
    this.changeDetectorRef.markForCheck();
    await this._metaActionHandler.executeSelectAction({
      controlId: this._id,
      data: {
        value: item,
        ...this.model,
      },
      formId: this.formState.formId,
    });
    this.isLoading = false;
    this.ma.disabled = false;
    this.changeDetectorRef.markForCheck();
  }

  public async onDefaultClick(control: MetaButton) {
    this.executeAction = true;

    const model = this.formState.data();
    await this._metaActionHandler.executeClickAction({
      formId: this.formState.formId,
      controlId: control.id,
      data: {
        ...model,
      },
      subFormPath: this.metaHelperService.getFormlySubFormPath(this.field),
      ctx: model?._ctx,
      formIsValid: true,
      passthroughData: { formId: this.formState.formId },
    });

    this.executeAction = false;
    this.changeDetectorRef.markForCheck();
  }

  public showNewTag(): boolean {
    return (
      !this.hasFocus &&
      this.ma.creatable &&
      (typeof this.model[this._id] === "string" || this.model[this._id]?._source === "google") &&
      this.model[this._id] !== ""
    );
  }
}

@NgModule({
  declarations: [MetaAutocompleteComponent],
  imports: [
    CommonModule,
    FormlyModule,
    ReactiveFormsModule,
    FormsModule,
    NzInputModule,
    NzAutocompleteModule,
    MetaLoaderModule,
    MetaButtonModule,
    MetaTagModule,
    NgxTolgeeModule,
    PipesModule,
  ],
  exports: [MetaAutocompleteComponent],
})
export class MetaAutocompleteModule {}
