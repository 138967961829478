/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Expose } from "class-transformer";
import { IsOptional, IsString } from "class-validator";
import { ExposeProperty, MetaFormComponentInfo } from "./baseComponent";
import { MetaFormLayoutComponent } from "./layoutComponent";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-wizard-step",
  type: "wizard-step",
  name: "WizardStep",
  hideInPallet: true,
})
export class WizardStepComponent extends MetaFormLayoutComponent {
  @ExposeProperty() @IsString() label: string;
  @ExposeProperty() @IsString() @IsOptional() sublabel?: string;
  @ExposeProperty() @IsString() @IsOptional() icon?: string;

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        label: this.label,
        sublabel: this.sublabel,
        description: this.description,
        icon: this.icon,
      },
    });
  }
}
