/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaUnsubscribe } from "../../../services/metaUnsubscribe.hoc";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { MetaHelperService } from "../../../services/metaHelper.service";
import { CommonModule } from "@angular/common";
import { FormlyModule } from "@ngx-formly/core";
import {
  MetaAutocompleteModule,
  MetaButtonModule,
  MetaEmptyModule,
  MetaFieldWrapperModule,
  MetaGroupModule,
  MetaInputModule,
  MetaSectionModule,
  MetaSelectModule,
} from "@meta/ui";
import { MetaInfoboxModule } from "../../metaInfobox/metaInfobox.component";
import { CdkDragDrop, DragDropModule, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { FormsModule } from "@angular/forms";
import { Observable, Subject, takeUntil } from "rxjs";
import { MetaState } from "@meta/enums";
import { filter } from "rxjs/operators";

export class MetaSpecificationsGroup {
  public parentFormId: string;
  public formState: any;
  public data: any = {};
  public item: any = {};
  public controlsDisabled?: boolean;
  public readonly?: boolean;
  public editing? = false;
  public onItemUpdate?: (data) => void;
  public onValueUpdate?: (data) => void;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-specifcations-group",
  template: `
    <meta-group
      id="meta-group-{{ ma.item.id }}"
      [maParams]="{
        label: ma.item.data.name,
        actions: editing && !ma.readonly ? [actionSetting] : [],
        fullHeight: true,
        flex: 1
      }"
    >
      <div class="inner-wrapper">
        <meta-infobox
          *ngIf="ma.item.data.description && getDescription(ma.item.data.description).length > 0"
          [maParams]="{ label: ma.item.data.description }"
        ></meta-infobox>
        <div cdkDropList [cdkDropListData]="items" [cdkDropListDisabled]="!editing" (cdkDropListDropped)="drop($event)">
          <div
            class="item item-list"
            cdkDrag
            cdkDragPreviewClass="list-input-drag-preview"
            (cdkDragDropped)="dropItem($event)"
            *ngFor="let item of items; let index = index"
          >
            <div class="item-container">
              <div *ngIf="editing" class="row-handler-wrapper">
                <div class="drag-handler" cdkDragHandle>
                  <i class="fal fa-bars"></i>
                </div>
              </div>
              <div class="item-content">
                <meta-field-wrapper
                  [maParams]="{
                    inputWidth: 12,
                    labelWidth: 12,
                    label: item.label,
                    description: item.Beschreibung
                  }"
                >
                  <meta-autocomplete
                    *ngIf="item.Typ === 'TEXT'"
                    [maParams]="{
                      editing: editing,
                      searchType: 'tags',
                      tagId: item.ID,
                      disabled: ma.controlsDisabled || ma.data.maEditing
                    }"
                    (ngModelChange)="emitOnItemUpdate()"
                    [(ngModel)]="specModel[item.ID + '_' + ma.item.id]"
                    [style.width.%]="100"
                  ></meta-autocomplete>
                  <meta-input
                    *ngIf="item.Typ === 'ZAHL'"
                    [maParams]="{
                      inputType: 'decimal',
                      digits: 10,
                      suffix: item.Mengeneinheit,
                      editing: editing,
                      disabled: ma.controlsDisabled || ma.data.maEditing
                    }"
                    (ngModelChange)="emitOnItemUpdate()"
                    [(ngModel)]="specModel[item.ID + '_' + ma.item.id]"
                    [style.width.%]="100"
                  ></meta-input>
                  <meta-select
                    *ngIf="item.Typ === 'TAG'"
                    [maParams]="{
                      id: item.ID,
                      returnType: 'object',
                      mode: 'multiple',
                      tagId: item.ID,
                      editing: editing,
                      parentFormId: ma.parentFormId,
                      disabled: ma.controlsDisabled || ma.data.maEditing,
                      specPreviewValue: previewFormValues ? previewFormValues[item.ID] : null
                    }"
                    (ngModelChange)="emitOnItemUpdate()"
                    [(ngModel)]="specModel[item.ID + '_' + ma.item.id]"
                    [style.width.%]="100"
                  ></meta-select>
                </meta-field-wrapper>
              </div>
              <div class="item-actions">
                <meta-button
                  *ngIf="editing"
                  class="btn-delete"
                  [maParams]="{
                    icon: 'trash',
                    iconStyle: 'fas',
                    type: 'link',
                    danger: true,
                    size: 'small',
                    popConfirm: {
                      label: 'Diese Spezifikation wirklich löschen?',
                      placement: 'left',
                      onOk: delete.bind(this, index)
                    }
                  }"
                ></meta-button>
              </div>
            </div>
          </div>
        </div>
        <meta-empty
          *ngIf="items.length === 0"
          [maParams]="{
            description: 'Keine Spezifikationen vorhanden',
            icon: 'stream'
          }"
        ></meta-empty>
      </div>
    </meta-group>

    <ng-template #actionSetting>
      <ng-container>
        <meta-select
          [maParams]="{
            id: ma.data.maUuid,
            returnType: 'object',
            editing: true,
            parentFormId: ma.parentFormId,
            placeholder: 'Spezifikation zum hinzufügen wählen...',
            noRefreshCheck: true
          }"
          class="mb-2"
          [(ngModel)]="actionModel[ma.data.maUuid]"
        ></meta-select>
        <meta-button
          [maParams]="{
            label: 'Hinzufügen',
            icon: 'plus',
            type: 'primary',
            disabled: !actionModel[ma.data.maUuid],
            fullWidth: true
          }"
          (click)="add()"
        ></meta-button>
      </ng-container>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaSpecificationsGroupComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  public items: any[] = [];
  public specModel: any = {};
  public actionModel: any = {};
  public editing = false;
  public pristine = false;
  public previewFormValues = {};

  private _destroyed$ = new Subject();

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _metaHelperService: MetaHelperService,
  ) {}

  private _maParams: MetaSpecificationsGroup = new MetaSpecificationsGroup();

  @Input()
  public get maParams(): MetaSpecificationsGroup {
    return this._maParams;
  }

  public set maParams(value: MetaSpecificationsGroup) {
    this._maParams = { ...this._maParams, ...value };
  }

  get ma(): MetaSpecificationsGroup {
    return this.maParams;
  }

  ngOnInit() {
    this.emitOnItemUpdate();
    this.editing = this.ma.editing;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.maParams?.currentValue?.item &&
      JSON.stringify(changes.maParams?.currentValue?.item?.data) !==
        JSON.stringify(changes.maParams?.previousValue?.item?.data)
    ) {
      if (this.ma.item.data && this.ma.item.data.items) {
        this.items = [];
        this.ma.item.data.items.forEach((o) => {
          o = { ...o };
          o.ID = o.ID.toLowerCase();
          this.previewFormValues[o.ID] = o.value?.length > 0 && o.Typ === "TAG" ? o.value : null;
          this._changeDetectorRef.detectChanges();
          this.specModel[o.ID + "_" + this.ma.item.id] = o.Typ === "ZAHL" && o.value === null ? 0 : o.value;
          this.items.push(o);
        });
        this._changeDetectorRef.markForCheck();
      }
    }
  }

  ngAfterViewInit() {
    this.ma.formState.state$
      .pipe(
        takeUntil(this._destroyed$),
        filter((x: MetaState) => x !== undefined),
      )
      .subscribe({
        next: (state: MetaState) => {
          this.editing = state >= MetaState.editing && state <= MetaState.saving;
          this._changeDetectorRef.markForCheck();
        },
      });
  }

  ngOnDestroy() {
    this._destroyed$.next(null);
    this._destroyed$.complete();
  }

  public dropItem(event): void {
    this._changeDetectorRef.detectChanges();
    this.emitOnItemUpdate();
  }

  public drop(event: CdkDragDrop<any>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      this.specModel[event.previousContainer.data[event.previousIndex].ID + "_" + this.ma.item.id] =
        event.previousContainer.data[event.previousIndex].Typ === "ZAHL" &&
        (event.previousContainer.data[event.previousIndex].value === null ||
          event.previousContainer.data[event.previousIndex].value === undefined)
          ? 0
          : event.previousContainer.data[event.previousIndex].value;
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.emitOnItemUpdate();
  }

  public delete(index: any): void {
    delete this.specModel[this.items[index].ID];
    this.items.splice(index, 1);
    this.emitOnItemUpdate();
  }

  public add(): void {
    if (this.actionModel[this.ma.data.maUuid]) {
      let item = this.actionModel[this.ma.data.maUuid];
      if (item?.primaryItem) {
        item = { ...item, ...item.primaryItem };
      }
      item.ID = item.ID.toLowerCase();
      this.specModel[item.ID + "_" + this.ma.item.id] = item.Typ === "ZAHL" ? 0 : item.Typ === "TAG" ? [] : "";
      this.items.push(item);
    }
    setTimeout(() => {
      const elem = document.getElementById(`meta-group-${this.ma.item.id}`).getElementsByClassName("ant-card-body")[0];
      elem.scrollTop = elem.scrollHeight;
    });
    this.actionModel[this.ma.data.maUuid] = null;
    this.emitOnItemUpdate();
  }

  public onCompleteEditMode(ids: string[]): void {
    if (this.ma.onValueUpdate instanceof Function) {
      this.ma.onValueUpdate({
        id: this.ma.item.id,
        items: this.items.map((i) => {
          i.value = this.specModel[i.ID + "_" + this.ma.item.id];
          return i;
        }),
        ids,
      });
    }
    //this.mode = MetaControlMode.default;
  }

  public emitOnItemUpdate() {
    setTimeout(() => {
      if (this.ma.onItemUpdate instanceof Function) {
        this.ma.onItemUpdate({
          id: this.ma.item.id,
          items: this.items.map((i) => {
            if (this.specModel) {
              return {
                ...i,
                value: this.specModel[i.ID + "_" + this.ma.item.id],
              };
            }
            return i;
          }),
        });
      }
    });
  }

  public getDescription(description: string) {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = description;
    return tmp.textContent || tmp.innerText || "";
  }
}

@NgModule({
  declarations: [MetaSpecificationsGroupComponent],
  imports: [
    CommonModule,
    FormlyModule,
    MetaGroupModule,
    MetaInfoboxModule,
    DragDropModule,
    MetaAutocompleteModule,
    MetaInputModule,
    MetaSelectModule,
    MetaButtonModule,
    MetaEmptyModule,
    FormsModule,
    MetaFieldWrapperModule,
    MetaSectionModule,
  ],
  exports: [MetaSpecificationsGroupComponent],
})
export class MetaSpecificationsGroupModule {}
