<!--
  ~ Copyright (C) MetaCarp GmbH - All Rights Reserved
  ~ Unauthorized copying of this file, via any medium is strictly prohibited
  ~ Proprietary and confidential
  ~ Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
  ~ Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
  -->

<ng-template #actionTemplate>
  <meta-button
    (click)="upload()"
    [maParams]="{
      label: 'Dokument hinzufügen',
      type: 'primary'
    }"
  ></meta-button>
</ng-template>

<ng-template #uploadProgressTemplate>
  <h4>[{{ uploadProgress()?.filesDone }} / {{ uploadProgress()?.filesTotal }}] {{ uploadProgress()?.message }}</h4>
  <nz-progress [nzPercent]="uploadProgress()?.percentTotal || 0"></nz-progress>
</ng-template>

<ng-template #infoActionsTemplate let-file>
  <ng-container *ngIf="isFormly">
    <meta-button (click)="unassigne(file)" [maParams]="{ icon: 'link-slash', danger: true }"></meta-button>
  </ng-container>
</ng-template>

<nz-breadcrumb *ngIf="collection && history.length > 1">
  <nz-breadcrumb-item (click)="backToIndex(i)" *ngFor="let h of history; let i = index">{{
    h.name
  }}</nz-breadcrumb-item>
  <nz-breadcrumb-item>{{ name }}</nz-breadcrumb-item>
</nz-breadcrumb>

<div class="media-library-toolbar">
  <ng-container *ngIf="collection">
    @if (modalRef) {
      <meta-button
        (click)="back()"
        [maParams]="{
          icon: 'arrow-left',
          disabled: history.length === 0
        }"
      ></meta-button>
    }
    <meta-button
      (click)="home()"
      [maParams]="{
        icon: 'home',
        disabled: collection === 'root'
      }"
    ></meta-button>
  </ng-container>
  <meta-button
    *ngIf="collection"
    (click)="createCollection()"
    [maParams]="{
      icon: 'folder-plus'
    }"
  ></meta-button>
  <ng-container [formGroup]="filterForm">
    <meta-button
      (click)="upload()"
      [maParams]="{
        icon: isFormly ? 'cloud-arrow-up' : 'cloud-arrow-up'
      }"
    ></meta-button>
    <input class="search-input" formControlName="query" nz-input placeholder="Dokumente durchsuchen" />
    <nz-select
      *ngIf="allowTypeChange"
      class="type-select"
      formControlName="type"
      nzAllowClear
      nzPlaceHolder="Medien Type"
    >
      <nz-option *ngFor="let f of mimeFilters" [nzLabel]="f.label" [nzValue]="f.value"></nz-option>
    </nz-select>
    <nz-select *ngIf="!tableView" class="type-select" formControlName="sort" nzAllowClear nzPlaceHolder="Sortierung">
      <nz-option nzLabel="Name" nzValue="name"></nz-option>
      <nz-option nzLabel="Anlagedatum" nzValue="date"></nz-option>
    </nz-select>
  </ng-container>
  <span *ngIf="isMultiselect">
    <nz-radio-group (ngModelChange)="changeDetectorRef.markForCheck()" [(ngModel)]="showMode" nzButtonStyle="solid">
      <label [nzValue]="'search'" nz-radio-button><i class="fa-solid fa-border-none"></i></label>
      <label [nzValue]="'selection'" nz-radio-button><i class="fa-solid fa-grid-2"></i></label>
    </nz-radio-group>
  </span>
  <span>
    <nz-button-group>
      <button [nzType]="tableView ? 'default' : 'primary'" (click)="setView('grid')" nz-button>
        <i class="fa-solid fa-grid-2"></i>
      </button>
      <button [nzType]="tableView ? 'primary' : 'default'" (click)="setView('table')" nz-button>
        <i class="fa-solid fa-table"></i>
      </button>
    </nz-button-group>
  </span>
</div>
<div (dragover)="dragOver($event)" (drop)="dropFiles($event)" class="media-library-wrapper">
  <ng-container *ngIf="showMode === 'selection' ? selectedFiles : files as _files">
    <div
      *ngIf="tableView"
      [ngClass]="{ multiselect: isMultiselect, empty: _files.length === 0 }"
      class="media-library-table-container"
    >
      <nz-table
        #basicTable
        [nzLoading]="isLoading"
        [nzTotal]="total"
        [nzPageSize]="take"
        [nzData]="_files"
        [nzFrontPagination]="false"
        [nzShowPagination]="false"
        nzSimple
        nzSize="small"
      >
        <thead>
          <tr>
            <th *ngIf="isMultiselect" [nzChecked]="false"></th>
            <th></th>
            <th class="{{ getFilterClass('name') }}" (click)="changeSort('name')">Name</th>
            <th class="{{ getFilterClass('date') }}" (click)="changeSort('date')">Datum</th>
            <th class="{{ getFilterClass('type') }}" (click)="changeSort('type')">Type</th>
            <th class="{{ getFilterClass('size') }}" (click)="changeSort('size')">Größe</th>
            <th class="{{ getFilterClass('classificationName') }}" (click)="changeSort('classificationName')">
              Klassifizierung
            </th>
            <th class="{{ getFilterClass('tags') }}">Tags</th>
          </tr>
        </thead>
        <tbody>
          <tr
            (contextmenu)="contextMenu($event, menu, file)"
            (click)="fileClick(file, $event)"
            *ngFor="let file of basicTable.data"
            [ngClass]="{ focused: focusedFile === file, selected: selectedFileIds.includes(file.id) }"
            (dblclick)="openPreview(file)"
          >
            <td
              (nzCheckedChange)="fileClick(file, $event)"
              *ngIf="isMultiselect"
              [nzChecked]="selectedFileIds.includes(file.id)"
            ></td>
            <td>
              <div class="preview-container">
                <ng-container *ngIf="file.type === 'image'; else elseTmpl">
                  <img
                    (error)="handleImageError($event)"
                    class="media-library-item-image"
                    loading="lazy"
                    src="/api/v2/file/{{ file.id }}/download?width=90&height=90&fit=inside"
                  />
                </ng-container>
                <ng-template #elseTmpl>
                  <i class="fal {{ file | getFileIcon }}"></i>
                </ng-template>
              </div>
            </td>
            <td>
              <a (click)="openPreview(file)">{{ file.name }}</a>
            </td>
            <td>{{ file.date | date: "short" }}</td>

            <td>{{ file.fileDescription }}</td>
            <td>{{ file.size | nzBytes }}</td>
            <td>{{ file.classificationName }}</td>
            <td>
              @for (t of file.tags; track t.id) {
                <nz-tag>{{ t.label }}</nz-tag>
              }
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>

    @if (!tableView) {
      <div class="media-library-container-outer">
        <div
          [ngClass]="{ multiselect: isMultiselect, empty: _files.length === 0 }"
          class="media-library-container"
          [ngStyle]="{ 'grid-template-columns': 'repeat(auto-fill, minmax(' + itemSize() + 'px, 1fr))' }"
          (contextmenu)="contextMenu($event, menuBackdrop, null)"
          (click)="clearSelection()"
        >

          @for (file of _files; track file.id) {
            <div
              (click)="fileClick(file, $event); $event.stopPropagation()"
              (dblclick)="openPreview(file)"
              (contextmenu)="contextMenu($event, menu, file)"
              [ngClass]="{ focused: focusedFile === file, selected: selectedFileIds.includes(file.id) }"
              [title]="file.name"
              [ngStyle]="{ height: itemSize() + 'px' }"
              class="media-library-item"
            >
              @if (file.type === "image") {
                <img
                  alt=""
                  (error)="handleImageError($event)"
                  *ngIf="file.type === 'image'"
                  class="media-library-item-bg"
                  loading="lazy"
                  src="/api/v2/file/{{ file.id }}/download?width=250&height=250&fit=inside"
                />
              }
              <div class="media-library-item-preview">
                <ng-container *ngIf="file.type === 'image'; else elseTmpl">
                  <img
                    alt=""
                    (error)="handleImageError($event)"
                    *ngIf="file.type === 'image'"
                    class="media-library-item-image"
                    loading="lazy"
                    src="/api/v2/file/{{ file.id }}/download?width=250&height=250&fit=inside"
                  />
                </ng-container>
                <ng-template #elseTmpl>
                  <i class="fal {{ file | getFileIcon }}"></i>
                </ng-template>
              </div>
              <div class="media-library-item-name">{{ file.name }}</div>
            </div>
          } @empty {
            <meta-empty
              [maParams]="{
                description: showMode === 'selection' ? 'Keine Dokumente ausgewählt' : 'Keine Dokumente gefunden',
                icon: 'file',
                action: actionTemplate
              }"
            ></meta-empty>
          }

        </div>
        <div class="media-library-container-footer">
          @if (selectedFileIds.length) {
            <span>{{ selectedFileIds.length }} von {{ _files.length }} ausgewählt</span>
          } @else {
            <span>{{ _files.length }} Elemente</span>
          }
          <div class="spacer"></div>
          <nz-slider [(ngModel)]="zoom" [nzStep]="0.025" [nzMin]="1" [nzMax]="3" />
        </div>
      </div>
    }
  </ng-container>
  <meta-media-library-file-info
    (fileDeleted)="fileDeleted($event)"
    (fileChanged)="loadData(0)"
    *ngIf="showInfo"
    [actionsTemplate]="infoActionsTemplate"
    [file]="focusedFileInfoData || focusedFile"
    #fileInfoComponent
    style="width: 420px; flex-grow: 0; flex-shrink: 0"
  ></meta-media-library-file-info>
</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <li (click)="openPreview(this.selectedFiles[0])" nz-menu-item>Vorschau</li>
    <li (click)="edit(this.selectedFiles[0])" nz-menu-item>Bearbeiten</li>
    <li nz-menu-item (click)="copySelection(this.selectedFiles)" [nzDisabled]="selectedFiles.length === 0">
      Auswahl Kopieren
    </li>
    <li nz-menu-item (click)="cutSelection(this.selectedFiles)" [nzDisabled]="selectedFiles.length === 0">
      Auswahl Ausschneiden
    </li>
    <li nz-menu-item (click)="share(this.selectedFiles)" [nzDisabled]="selectedFiles.length === 0">Auswahl Teilen</li>
    <li nz-menu-item (click)="delete(this.selectedFiles)" [nzDisabled]="selectedFiles.length === 0">Auswahl Löschen</li>
    <li nz-menu-item (click)="download(this.selectedFiles)" [nzDisabled]="selectedFiles.length === 0">Herunterladen</li>
  </ul>
</nz-dropdown-menu>

<nz-dropdown-menu #menuBackdrop="nzDropdownMenu">
  <ul nz-menu>
    <li [nzDisabled]="!fileOperation" (click)="paste()" nz-menu-item>
      {{ fileOperation?.items?.length || 0 }} Elemente {{ fileOperation?.type === "move" ? "Verschieben" : "Einfügen" }}
    </li>
  </ul>
</nz-dropdown-menu>
