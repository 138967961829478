/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaBaseValidator } from "./baseValidator";
import * as validator from "class-validator";
import { MetaFormComponentInfo } from "../components";

export class MatchesValidatorOptions {
  regexp: string;
  modifiers?: string;
}

@MetaFormComponentInfo({
  selector: "ma-validator-matches",
  type: "match-validator",
  name: "Regex Validator",
})
export class MatchesValidator extends MetaBaseValidator {
  public regexp: string;
  public modifiers?: string;
  constructor(options: MatchesValidatorOptions) {
    super();
    Object.assign(this, options);
  }

  public static createElement(attributes: MatchesValidatorOptions) {
    return new MatchesValidator(attributes);
  }

  async validate(value: any): Promise<boolean> {
    return validator.matches(`${value}`, new RegExp(this.options.regexp, this.options.modifiers));
  }

  toFormly(formlyFieldConfig: Record<string, any>) {
    const res = super.toFormly(formlyFieldConfig, true);
    return {
      name: res.name,
      options: {
        isAsync: false,
        regexp: this.regexp,
        modifiers: this.modifiers,
      },
    };
  }
}
