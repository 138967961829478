/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { ChangeDetectionStrategy, Component, Injector, OnInit } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { MetaState } from "@meta/enums";
import { FormlyAttributeEvent } from "@ngx-formly/core/lib/models/fieldconfig";
import { debounceTime, identity, iif, mergeMap, of, take, takeUntil } from "rxjs";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaActionHandlerFactory } from "../../base/metaForm/actions/actionHandler.factory";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { DynamicDatasource } from "./dynamicDatasource";
import { MetaTreeviewService, TreeNode } from "./metaTreeview.service";

export class MetaTreeview {
  onClick?: FormlyAttributeEvent;
  autoExpand?: number;
  dontReloadOnNavigation?: boolean;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-treeview",
  templateUrl: "./metaTreeview.component.html",
  styleUrls: ["./metaTreeview.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
export class MetaTreeviewComponent extends MetaComponentBase implements OnInit {
  public readonly treeControl = new FlatTreeControl<TreeNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  public selectListSelection = new SelectionModel<TreeNode>(false);

  public dataSource: DynamicDatasource;

  constructor(
    private readonly _metaTreeviewService: MetaTreeviewService,
    private readonly _MetaActionHandler: MetaActionHandlerFactory,
  ) {
    super();
    super.maParams = new MetaTreeview();
  }

  get ma(): MetaTreeview {
    return super.ma;
  }

  public readonly hasChild = (_: number, node: TreeNode): boolean => node.expandable;

  ngOnInit() {
    super.ngOnInit();
    if (this.field) {
      this.formState.state$.pipe(debounceTime(250)).subscribe((state: MetaState) => {
        if (Object.values(state).find((e) => e.state === MetaState.saved)) {
          this.dataSource?.clear();
          this.dataSource?.refresh();
        }
      });

      this.options.formState
        .onFormDataReady()
        .pipe(
          takeUntil((this as any).destroyed$),
          debounceTime(350),
          this.ma.dontReloadOnNavigation ? take(1) : identity,
        )
        .subscribe({
          next: (form: any) => {
            setTimeout(() => {
              const displayData: Record<string, any> = this.formState.displayData()?.[String(this.key)];
              if (form && Object.keys(form).length > 0) {
                this.dataSource = new DynamicDatasource(this.treeControl, this._metaTreeviewService, {
                  formId: this.formState.formId,
                  fieldId: String(this.key),
                  id: form._id,
                  _ctx: form._ctx,
                  data: {
                    ...form,
                  },
                });
                const root = displayData?.rootLabel
                  ? {
                      link: null,
                      prefix: displayData?.prefix || null,
                      suffix: displayData?.suffix || null,
                      title: displayData?.rootLabel,
                    }
                  : null;
                this.dataSource?.refresh(root);
              } else {
                this.dataSource?.clear();
              }
              this.changeDetectorRef.markForCheck();
            });
          },
        });
    }
  }

  onClick(node: TreeNode) {
    this.selectListSelection.select(node);
    this.changeDetectorRef.markForCheck();
    this._MetaActionHandler
      .executeClickAction({
        formId: this.formState.formId,
        controlId: this.field.id,
        data: {
          ...node,
        },
        passthroughData: { formId: this.formState.formId },
        formIsValid: true,
      })
      .then(() => {
        this.changeDetectorRef.markForCheck();
      });
  }
}
