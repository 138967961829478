/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { plainToInstance, Transform, Type } from "class-transformer";
import {
  Allow,
  IsArray,
  IsBoolean,
  IsEnum,
  IsIn,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from "class-validator";

export class MetaFilterDescriptor<Form = any> {
  @IsIn([
    "eq",
    "neq",
    "isnull",
    "isnotnull",
    "lt",
    "lte",
    "gt",
    "gte",
    "startswith",
    "endswith",
    "contains",
    "doesnotcontain",
    "isempty",
    "isnotempty",
    "sounds",
    "inRange",
    "notinrange",
  ])
  public operator:
    | "eq"
    | "neq"
    | "isnull"
    | "isnotnull"
    | "lt"
    | "lte"
    | "gt"
    | "gte"
    | "startswith"
    | "endswith"
    | "contains"
    | "doesnotcontain"
    | "isempty"
    | "isnotempty"
    | "sounds"
    | "inRange"
    | "notinrange";

  @Allow()
  @IsOptional()
  public value?: any;

  @IsString()
  public field: string;

  @IsBoolean()
  @IsOptional()
  public ignoreCase?: boolean;

  constructor(opts: MetaFilterDescriptor) {
    Object.assign(this, opts);
  }
}

export class MetaCompositeFilterDescriptor<Form = any> {
  @IsIn(["or", "and"])
  public logic: "or" | "and";

  @Transform((params) => {
    if (Array.isArray(params.value)) {
      return params.value.map((e) => {
        if (e["filters"]) {
          return plainToInstance(MetaCompositeFilterDescriptor, e);
        } else {
          return plainToInstance(MetaFilterDescriptor, e);
        }
      });
    } else {
      return [];
    }
  })
  @ValidateNested()
  public filters: Array<MetaFilterDescriptor<Form> | MetaCompositeFilterDescriptor<Form>>;

  @IsString()
  @IsOptional()
  public jsonScope?: string;

  constructor(opts: MetaCompositeFilterDescriptor) {
    Object.assign(this, opts);
  }
}

export class MetaSortDescriptor<Form = any> {
  @IsString()
  public field: string;

  @IsIn(["asc", "desc"])
  public dir: "asc" | "desc";

  constructor(opts: MetaSortDescriptor) {
    Object.assign(this, opts);
  }
}

export class MetaGroupDescriptor<Form = any> {
  @IsString()
  public field: string;

  @IsIn(["asc", "desc"])
  public dir?: "asc" | "desc";

  @Type(() => MetaAggregateDescriptor)
  @IsOptional()
  @ValidateNested()
  public aggregates?: Array<MetaAggregateDescriptor>;

  constructor(opts: MetaGroupDescriptor) {
    Object.assign(this, opts);
  }
}

export type MetaFormAggregator = "count" | "sum" | "average" | "min" | "max";

export class MetaAggregateDescriptor {
  @IsString()
  public field: string;

  @IsString()
  @IsIn(["count", "sum", "average", "min", "max"])
  public aggregate: MetaFormAggregator;

  constructor(opts: MetaAggregateDescriptor) {
    Object.assign(this, opts);
  }
}

export class MetaFormCondition<Form = any> {
  @Type(() => MetaCompositeFilterDescriptor)
  @IsOptional()
  @ValidateNested()
  public filter?: MetaCompositeFilterDescriptor<Form>;

  @Type(() => MetaSortDescriptor)
  @IsOptional()
  @ValidateNested()
  public sort?: Array<MetaSortDescriptor<Form>>;

  @Type(() => MetaGroupDescriptor)
  @IsOptional()
  @ValidateNested()
  public group?: Array<MetaGroupDescriptor<Form>>;

  @IsOptional()
  @IsArray()
  public groupKeys?: string[];

  @IsNumber()
  @IsOptional()
  public skip?: number;

  @IsNumber()
  @IsOptional()
  public take?: number;

  @IsNumber()
  @IsOptional()

  // Caching Time in Seconds
  public cacheTTL?: number;

  @IsString()
  @IsOptional()

  // Caching uuid
  public cacheKey?: string;

  @Allow()
  @IsOptional()
  public params?: Record<string, any>;

  @IsBoolean()
  @IsOptional()
  public returnDisplayResult? = true;

  @IsString()
  @IsOptional()
  public searchString?: string;

  @IsString({ each: true })
  @IsArray()
  @IsOptional()
  public onlyLoadComponentsWithId?: string[];

  @IsString()
  @IsOptional()
  public parentContextId?: string;

  constructor(opts: MetaFormCondition) {
    Object.assign(this, opts);
  }
}

export class MetaFormBulkUpdate {
  @Type(() => MetaCompositeFilterDescriptor)
  @IsOptional()
  @ValidateNested()
  public filter?: MetaCompositeFilterDescriptor;

  @Allow()
  @IsOptional()
  public params?: Record<string, any>;

  @IsString()
  @IsOptional()
  public searchString?: string;

  @Allow()
  @IsOptional()
  public data?: Record<string, any>;

  @Allow()
  @IsOptional()
  public ids?: string[];

  @IsBoolean()
  @IsOptional()
  public preview? = true;

  constructor(opts: MetaFormCondition) {
    Object.assign(this, opts);
  }
}

export enum MetaFormExportFormat {
  json = "json",
  xlsx = "xlsx",
  csv = "csv",
  html = "html",
  txt = "txt",
}

export class MetaFormExportRequest<Form = any> extends MetaFormCondition<Form> {
  @IsEnum(MetaFormExportFormat)
  public format?: MetaFormExportFormat;
}

export class MetaFormBulkFieldsRequestDto {
  @Allow()
  @IsOptional()
  ids: any[];
}

export class MetaFormBulkFormRequestDto {
  @IsString()
  formId: string;
  @Allow()
  @IsOptional()
  components: any[];
}

export class MetaFormBatchRequest<Form = any> {
  /**
   * Updates an item if it not exits it will be created
   */
  @IsOptional()
  update: Partial<Form>[];

  @IsOptional()
  remove: (string | number)[];
}

export class SearchCompilationDto {
  id: string;
  quantity: number;
  price: number;
}
