/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

export interface GetHubResult {
  resultSet: any[];
  total: number;
}

export interface GetQuoteResult {
  Zitat: string;
  Autor: string;
}

export type MetaHubSkipTake = {
  [id in MetaHubElement]: {
    skip: number;
    take: number;
  };
};

export type MetaHubLoadMore = {
  [id in MetaHubElement]: boolean;
};

export enum MetaHubElement {
  activities = "activities",
  actions = "actions",
  //appointments = "appointments",
  history = "history",
  favorites = "favorites",
}
