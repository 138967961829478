/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

// redirect-action.handler.ts
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class RedirectActionHandler {
  constructor(private _router: Router) {}

  async handle(action: any) {
    if (action.url.indexOf("http") === 0 || action.url.indexOf("/api") === 0) {
      window.open(action.url, "_blank", "noopener");
    } else {
      await this._router.navigateByUrl(action.url);
    }
  }
}
