/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormlyFieldConfig } from "@ngx-formly/core";

export const METASELECT_UNIQUE_ID = "abe0e776-7525-11ec-b8fa-cf1968cf9404";
export const METALIST_UNIQUE_ID = "abe0e776-7525-11ec-b8fa-cf1968cf9405";
export const METAACTIVIY_UNIQUE_ID = "abe0e776-7525-11ec-b8fa-cf1968cf9406";
export const METATASKS_UNIQUE_ID = "fae274d9-74f2-4fa5-8204-7a984f7b48f6";
export const METASEARCH_UNIQUE_ID = "abe0e776-7525-11ec-b8fa-cf1968cf9407";

const re = /[^a-zA-Z0-9.@ ]/g;

@Injectable()
export class MetaHelperService {
  constructor(private _http: HttpClient) {}

  /**
   * Formats a given number by adding a dot as thousands separator.
   *
   * @param {number} number - The number to be formatted.
   * @return {string|null} - The formatted number as a string, or null if the input is not a number.
   */
  public formatNumber(number) {
    if (isNaN(number)) {
      return null;
    }
    return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }

  /**
   * Generates a hash code for the given string.
   *
   * @param {string} s - The string to generate the hash code for.
   * @returns {number} - The generated hash code.
   */
  public generateHashCode(s) {
    let h;
    for (let i = 0; i < s.length; i++) h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;

    return h;
  }

  /**
   * Capitalizes the first letter of a given string.
   *
   * @param {string} string - The string to capitalize the first letter of.
   * @return {string} - The string with the first letter capitalized.
   */
  public capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * Retrieves the import statement for a specified component.
   *
   * @param {string} componentName - The name of the component.
   * @return {Promise<any>} - The import statement for the specified component.
   */
  public async getComponentImport(componentName: string): Promise<any> {
    switch (componentName) {
      case "input":
        return await import(`../components/metaInput/metaInput.component`);
    }
  }

  /**
   * Retrieves an array of validatable components from a given FormlyFieldConfig object.
   *
   * @param {FormlyFieldConfig} field - The FormlyFieldConfig object to retrieve the validatable components from.
   *
   * @return {Array} - An array of validatable components.
   */
  public getFormlyValidatableComponents(field: FormlyFieldConfig) {
    const array = [];
    const iter = (arr) => {
      arr.forEach((a) => {
        if (a.key && a.type !== "meta-list-input") {
          array.push(a);
        }
        if (a.fieldGroup?.length > 0) {
          iter(a.fieldGroup);
        }
      });
    };
    if (field?.fieldArray) {
      iter(field.fieldArray["fieldGroup"] || []);
    }
    return array;
  }

  /**
   * Retrieves the path of a Formly subform.
   *
   * @param {FormlyFieldConfig} field - The Formly field to search for the subform path.
   *
   * @return {Array} - The path of the subform, represented as an array of keys, in reverse order.
   */
  public getFormlySubFormPath(field: FormlyFieldConfig) {
    const array = [];
    const iter = (obj) => {
      if (obj?.parent.type === "meta-list-input") {
        array.push(obj.parent.key);
      }
      if (obj?.parent.parent) {
        iter(obj.parent);
      }
    };
    if (field?.parent) {
      iter(field);
    }
    array.reverse();
    return array;
  }

  /**
   * Returns the index of the given Formly field in the parent Formly field array.
   *
   * @param {FormlyFieldConfig} field - The Formly field for which to get the index.
   * @param {boolean} [returnAll=false] - Flag indicating whether to return all indices in the parent Formly field array.
   * @returns {Array<number>|number} - The index of the field in the parent Formly field array, or an array of all indices if `returnAll` is true.
   */
  public getFormlyFieldArrayIndex(field: FormlyFieldConfig, returnAll = false) {
    if (returnAll) {
      const array = [];
      const iter = (obj) => {
        if (obj?.parent.type === "meta-list-input") {
          array.push(obj.index);
        }
        if (obj?.parent.parent) {
          iter(obj.parent);
        }
      };
      if (field?.parent) {
        iter(field);
      }
      array.reverse();
      return array;
    } else {
      let index;
      const iter = (obj) => {
        if (obj?.parent.type === "meta-list-input") {
          index = obj.index;
        } else if (obj?.parent.parent) {
          iter(obj.parent);
        }
      };
      if (field?.parent) {
        iter(field);
      }
      return index;
    }
  }

  /**
   * Retrieves a list of organisations based on the specified parameters.
   *
   * @param {number} [skip=0] - The number of records to skip before returning results. Default is 0.
   * @param {number} [take=50] - The maximum number of records to return. Default is 50.
   * @param {string|null} [searchString=null] - The search string to filter the organisations by. Default is null.
   * @param {any} [selectedValue] - The selected value to filter the organisations by. Optional.
   *
   * @return {Promise<any>} - A Promise that resolves to an array of organisation objects.
   */
  public getOrganisations(skip = 0, take = 50, searchString = null, selectedValue?: any): Promise<any> {
    const params = new URLSearchParams();
    params.set("skip", `${skip}`);
    params.set("take", `${take}`);
    if (searchString) {
      params.set("searchString", `${searchString}`);
    }
    if (selectedValue) {
      params.set("selectedValue", `${selectedValue}`);
    }
    return this._http.get<any>(`shared/addresses/organisations?${params}`, {}).toPromise();
  }

  /**
   * Calculates the offset of an element relative to its offsetParent.
   * The offset includes both the top and left distances.
   *
   * @param {HTMLElement} element - The element for which to calculate the offset.
   * @return {Object} - An object containing the top and left offset values.
   */
  public getOffset(element) {
    let top = 0,
      left = 0;
    do {
      top += element.offsetTop || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while (element);

    return {
      top: top,
      left: left,
    };
  }

  /**
   * Generate a color string based on the given input string and optional parameters.
   *
   * @param {string} inputStr - The input string to generate the color from.
   * @param {number} [alpha] - The alpha value (optional).
   * @param {number} [saturation=30] - The saturation value (default: 30).
   * @param {number} [lightness=50] - The lightness value (default: 50).
   * @return {string} The generated color string.
   */
  public generateColor(inputStr: string, alpha?: number, saturation: number = 30, lightness: number = 50): string {
    let hue = Math.floor(this.generateNumberFromString(inputStr)); // between 0 and 340
    // color adjustment for eye's lack of sensitivity to blue
    if (hue > 215 && hue < 265) {
      const gain = 20;
      let blueness = 1 - Math.abs(hue - 240) / 25;
      let colorAdjustment = Math.floor(gain * blueness);
      lightness += colorAdjustment;
      saturation -= colorAdjustment;
    }
    const colorType = alpha ? `hsla` : `hsl`;
    const color = alpha ? `${hue}, ${saturation}%, ${lightness}%, ${alpha}` : `${hue}, ${saturation}%, ${lightness}%`;
    return `${colorType}(${color})`;
  }

  /**
   * Generates a number from a given string.
   *
   * @param {string} str - The input string.
   * @return {number} The generated number.
   */
  public generateNumberFromString(str: string) {
    let hash = 0;
    if (!str || str.length === 0) {
      return hash;
    }
    for (let i = 0; i < str.length; i++) {
      let char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash % 340) + 1;
  }

  /**
   * Generates a letter avatar based on the given label.
   *
   * @param {string} label - The label to generate the avatar from.
   * @param {string} [letterDelimiter=" "] - The delimiter used to split the label into letters.
   * @param {number} [letterCount=2] - The number of letters to include in the avatar.
   * @return {string} The generated letter avatar.
   */
  public generateLetterAvatar(label: string, letterDelimiter = " ", letterCount = 2): string {
    if (!label) {
      return null;
    }
    // Strip everything
    let letter: string | null = null;
    label = label.replace(re, "");
    try {
      let charArray = label.split(letterDelimiter);
      // If there are no Splits then try to find Dots and split there
      if (charArray.length === 1 && label.indexOf(".") > -1) {
        // If this is an E-Mail address, split the @ first
        if (label.indexOf("@") > -1) {
          label = label.split("@")[0];
        }
        charArray = label.split(".");
      }
      letter = "";
      for (let i = 0; i < charArray.length && i < letterCount; i++) {
        letter = letter + charArray[i].substr(0, 1).toUpperCase();
      }
    } catch (error) {
      try {
        letter = label.substr(0, 1).toUpperCase();
      } catch (error) {
        letter = "";
      }
    }
    return letter;
  }

  /**
   * Resizes the container element.
   *
   * @param {string} elementId - The ID of the container element to be resized.
   * @return {void}
   */
  public resizeContainer(elementId: string) {
    setTimeout(() => {
      var element;
      if (elementId) {
        element = document.getElementById(elementId).getElementsByClassName("ant-modal")[0] as HTMLElement;
      } else {
        element = document.getElementsByClassName("ant-modal")[0] as HTMLElement;
      }
      var resizer = document.createElement("div");
      resizer.className = "meta-resizer";
      element.appendChild(resizer);
      resizer.addEventListener("mousedown", initResize, false);

      function initResize(e) {
        window.addEventListener("mousemove", Resize, false);
        window.addEventListener("mouseup", stopResize, false);
      }
      function Resize(e) {
        const size = (e.clientX - e.view.innerWidth) * -1;
        element.style.width = (size <= 345 ? 345 : size) + "px";
      }
      function stopResize(e) {
        window.removeEventListener("mousemove", Resize, false);
        window.removeEventListener("mouseup", stopResize, false);
      }
    });
  }

  /**
   * Merges two objects of different types into a single object.
   *
   * @template A The type of the first object.
   * @template B The type of the second object.
   * @param {A} a The first object.
   * @param {B} b The second object.
   * @returns {A & B} The merged object.
   */
  public merge<A, B>(a: A, b: B): A & B {
    return { ...a, ...b };
  }
}
