/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2020
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2020
 * Written by Yannick Küchlin <y.kuechlin@taifun-tofu.de>, 2019-2020
 */

import { NgModule } from "@angular/core";
import { MetaErrorHandlerService } from "./metaErrorHandler.service";
import { MetaHelperService } from "./metaHelper.service";
import { MetaModalService } from "./metaModalService";

@NgModule({
  providers: [MetaErrorHandlerService, MetaHelperService, MetaModalService],
})
export class ServicesModule {}
