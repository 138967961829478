/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  signal,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Themes } from "@meta/enums";
// @ts-ignore
import { toCanvas, toSVG } from "bwip-js";
import { NzSanitizerPipe } from "ng-zorro-antd/pipes";
import { Subject, takeUntil } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { MetaComponentBase, MetaFormBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaAppService } from "../../services/metaApp.service";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";

export class MetaBarcode extends MetaFormBase {
  codeType: string;
  height?: number;
  width?: number;
  align?: string;
  barcolor?: string;
  textcolor?: string;
  bordercolor?: string;
  backgroundcolor?: string;
  includetext?: boolean;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-barcode",
  template: `
    @if (svg(); as _svg) {
      <div [innerHTML]="_svg | nzSanitizer: 'html'" class="output {{ ma.align }}"></div>
    } @else {
      <div class="output {{ ma.align }}">-</div>
    }
  `,
  styleUrls: ["./metaBarcode.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaBarcodeComponent extends MetaComponentBase implements OnChanges, OnInit, OnDestroy {
  @Input() public barcodeData: string;
  @Input() public barcodeType: string;
  @ViewChild("renderTarget", {
    static: true,
  })
  public canvas: ElementRef<HTMLCanvasElement>;
  public empty = true;
  public svg = signal<string>(null);
  private bwipToSVG: typeof toSVG;
  private bwipToCanvas: typeof toCanvas;
  private appTheme: Themes;
  private readonly renderSubject = new Subject<string>();

  constructor(
    _changeDetectorRef: ChangeDetectorRef,
    _metaHelperService: MetaHelperService,
    public _metaAppRepository: MetaAppService,
  ) {
    super();
    super.maParams = new MetaBarcode();
    this.renderSubject.pipe(distinctUntilChanged()).subscribe((data) => {
      this.renderBarcode(data).catch(console.error);
    });
  }

  get ma(): MetaBarcode {
    return super.ma;
  }

  public async renderBarcode(data: string) {
    if (!this.bwipToSVG) {
      this.bwipToSVG = await import("bwip-js").then((e) => e.toSVG);
    }
    if (this.canvas) {
      if (data && data.length > 0) {
        this.empty = false;
        this.bwipToCanvas(this.canvas.nativeElement, {
          bcid: this.ma.codeType || this.barcodeType || "qrcode",
          text: data,
          scale: 2, // 3x scaling factor
          height: this.ma.height || 30, // Bar height, in millimeters
          width: this.ma.width || 30,
          includetext: !!this.ma.includetext, // Show human-readable text
          textxalign: "center", // Always good to set this,
          barcolor: this.convertColor(this.ma.barcolor),
          textcolor: this.convertColor(this.ma.textcolor || "#000000"),
          backgroundcolor: this.convertColor(this.ma.backgroundcolor),
          bordercolor: this.convertColor(this.ma.bordercolor),
        });
        this.canvas.nativeElement.style.display = "inline";
      } else {
        this.empty = true;
        const context = this.canvas.nativeElement.getContext("2d");
        context.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
        this.canvas.nativeElement.style.display = "none";
      }
    }
    this.changeDetectorRef.markForCheck();
    const svg = this.bwipToSVG({
      bcid: this.ma.codeType || this.barcodeType || "qrcode",
      text: data || "1234",
      scale: 2, // 3x scaling factor
      height: this.ma.height || 30, // Bar height, in millimeters
      width: this.ma.width || 30,
      includetext: !!this.ma.includetext, // Show human-readable text
      textxalign: "center", // Always good to set this,
      barcolor: this.convertColor(this.ma.barcolor || "#000000"),
      textcolor: this.convertColor(this.ma.textcolor || "#000000"),
      backgroundcolor: this.convertColor(this.ma.backgroundcolor),
      bordercolor: this.convertColor(this.ma.bordercolor || "#000000"),
    });
    this.svg.set(svg);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.field) {
      this.formState.displayData$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
        this.renderSubject.next(this.displayData?.value);
      });
    }
    if (this.ma.showDummy) {
      this.renderSubject.next("123456789");
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.barcodeData) {
      this.renderSubject.next(changes.barcodeData.currentValue);
    }
    if (changes.barcodeType) {
      this.renderSubject.next(changes.barcodeData.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.renderSubject.unsubscribe();
  }

  private convertColor(color: string) {
    if (typeof color === "string" && color.indexOf("#") === 0) {
      return color.slice(1);
    }
    return color;
  }
}

@NgModule({
  declarations: [MetaBarcodeComponent],
  imports: [CommonModule, NzSanitizerPipe],
  exports: [MetaBarcodeComponent],
})
export class MetaBarcodeModule {}
