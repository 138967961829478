import { HttpClient } from "@angular/common/http";
import { Injectable, signal, WritableSignal } from "@angular/core";
import { MetaState } from "@meta/enums";
import { MetaFormCondition } from "@meta/forms";
import { NzMessageService } from "ng-zorro-antd/message";
import { firstValueFrom, Observable, take } from "rxjs";
import { tap } from "rxjs/operators";
import { MetaFormData } from "../../base/metaForm/metaForm.interface";
import { MetaErrorHandlerService } from "../../services/metaErrorHandler.service";
import {
  IMetaTableGetParams,
  IMetaTableResponse,
  IMetaTableStore,
  localStorageKey,
  MetaTableState,
} from "./metaTable.interface";
import * as deepDiff from "return-deep-diff";

@Injectable()
export class MetaTableService {
  public total: WritableSignal<number> = signal(0);
  public state: WritableSignal<MetaTableState> = signal(new MetaTableState());
  public selectedItems?: WritableSignal<IMetaTableStore[]> = signal([]);

  constructor(
    private readonly _http: HttpClient,
    private readonly _nzMessageService: NzMessageService,
    private readonly _metaErrorHandlerService: MetaErrorHandlerService,
  ) {}

  /**
   * Update form data.
   *
   * @param {Object} options - The options for updating form data.
   * @param {string} options.formId - The ID of the form.
   * @param {string} options.itemId - The ID of the item.
   * @param {Object} options.oldData - The old data to update.
   * @param {Object} options.newData - The new data to update.
   * @param {boolean} [options.silentUpdate] - Indicates whether to perform a silent update. Default is false.
   * @param {boolean} [options.completeEditMode] - Indicates whether to complete the edit mode. Default is false.
   * @param {string} [options.state] - The state of the form.
   * @param {boolean} [options.publish] - Indicates whether to publish the changes. Default is false.
   *
   * @returns {Observable<any>} - An Observable that emits the result of updating the form data.
   */
  updateData(options: {
    formId: string;
    itemId: string;
    oldData: Record<string, any>;
    newData: Record<string, any>;
    publish?: boolean;
  }): Observable<any> {
    // TODO: Only send changed data to backend
    const diff = deepDiff(options.oldData, options.newData, true);
    // Convert "" to null
    Object.keys(options.newData).forEach((key) => {
      if (options.newData[key] === "") {
        options.newData[key] = null;
      }
    });

    let url, message;
    if (options.itemId !== "create") {
      url = `forms/${options.formId}/handler/${encodeURIComponent(options.itemId)}`;
      message = `Datensatz gespeichert`;
      return this._http.patch(url, options.newData, { params: { publish: options.publish } }).pipe(
        tap(
          (res: MetaFormData) => {
            this._nzMessageService.success(message);
          },
          (err) => {
            this._metaErrorHandlerService
              .handleError(err)
              .pipe(take(1))
              .subscribe({
                error: (value) => {
                  this._nzMessageService.error(value);
                },
              });
          },
        ),
      );
    } else {
      url = `forms/${options.formId}/handler`;
      message = `Datensatz erstellt`;
      return this._http.post(url, options.newData, { params: { publish: options.publish } }).pipe(
        tap(
          (res: MetaFormData) => {
            this._nzMessageService.success(message);
          },
          (err) => {
            this._metaErrorHandlerService
              .handleError(err)
              .pipe(take(1))
              .subscribe({
                error: (value) => {
                  this._nzMessageService.error(value);
                },
              });
          },
        ),
      );
    }
  }

  public getData(p: IMetaTableGetParams): Observable<IMetaTableResponse> {
    if (p.params) {
      p.condition.params = p.params;
    }

    const url = `forms/${p.formId}/find`;
    return this._http.post<IMetaTableResponse>(url, p.condition).pipe(
      tap((data) => {
        if (data.total) {
          this.total.set(data.total);
        }
      }),
    );
  }

  public getFilterData(formId: string, fieldId: string): Observable<any> {
    return this._http.get<any>(`forms/${formId}/distinct/${fieldId}`);
  }

  public exportToExcel(formId: string, condition: MetaFormCondition) {
    return firstValueFrom(
      this._http.post(`forms/${formId}/export`, condition, { observe: "response", responseType: "blob" }),
    );
  }

  setTableState(fieldId: string, payload: Partial<MetaTableState>) {
    let state = this.state();
    state = { ...state, ...payload };
    this.state.set(state);
    const tableState = JSON.parse(localStorage.getItem(localStorageKey) || "{}");
    tableState[fieldId] = payload;
    localStorage.setItem(localStorageKey, JSON.stringify(tableState));
  }

  public loadTableStateFromStorage(fieldId: string) {
    const tableState = JSON.parse(localStorage.getItem(localStorageKey) || "{}")[fieldId];
    if (tableState) {
      this.state.set(tableState);
    }
  }
}
