<!--
  ~ Copyright (C) MetaCarp GmbH - All Rights Reserved
  ~ Unauthorized copying of this file, via any medium is strictly prohibited
  ~ Proprietary and confidential
  ~ Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2021
  ~ Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2021
  ~ Written by Yannick Küchlin <y.kuechlin@taifun-tofu.de>, 2019-2020
  -->

<meta-layout>
  <router-outlet></router-outlet>
</meta-layout>
<meta-notification></meta-notification>
