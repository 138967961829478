/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Exclude, Expose } from "class-transformer";
import type { DataSourceType } from "@meta/database/lib/types";
import { DataSourceParameters, ExposeProperty, MetaFormComponentInfo } from "./baseComponent";
import { ValidatableComponent } from "./validatableComponent";

@MetaFormComponentInfo({
  selector: "ma-progress-input",
  type: "progress-input",
  name: "Progress Input",
})
export class MetaFormProgressInputComponent extends ValidatableComponent {
  public sectionDataSource: DataSourceType;
  @ExposeProperty() public sectionLabelField: string;
  @ExposeProperty() public sectionValueField: string;
  @ExposeProperty()
  public sectionDataSourceParameters?: DataSourceParameters;

  constructor() {
    super();
  }
}
