/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  Input,
  NgModule,
  OnInit,
  Optional,
  Pipe,
  PipeTransform,
  QueryList,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { MetaState } from "@meta/enums";
import { FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import * as _ from "lodash";
import { DynamicIoModule } from "ng-dynamic-component";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzModalRef } from "ng-zorro-antd/modal";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { distinctUntilChanged, firstValueFrom, takeUntil } from "rxjs";
import { MetaActionHandlerFactory } from "../../base/metaForm/actions/actionHandler.factory";
import { IMetaWrapperBase, MetaWrapperBase } from "../../base/metaWrapperBase/metaWrapperBase.component";
import { MetaAppService } from "../../services/metaApp.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaButtonModule } from "../metaButton/metaButton.component";
import { MetaToolbarModule } from "../metaToolbar/metaToolbar.component";

export class MetaTabs extends IMetaWrapperBase {
  routerTabs? = false;
  initialCreateTab?: number;
  hideTabs? = false;
  filterable? = true;
  tabsPosition?: "top" | "right" | "bottom" | "left" = "top";
  style?: "primary" | "secondary" = "primary";
}

export class MetaTab extends IMetaWrapperBase {}

const SWIPE_ACTION = { LEFT: "swipeleft", RIGHT: "swiperight" };

@Pipe({
  name: "metaTabState",
})
class MetaTabStatePipe implements PipeTransform {
  constructor(
    private readonly router: Router,
    public readonly activatedRoute: ActivatedRoute,
  ) {}

  transform(
    value: boolean,
    args: { tabIndex: number; currentIndex: number; initialIndex: number; fieldGroup: FormlyFieldConfig[] },
  ): boolean {
    if (value && args.tabIndex === args.currentIndex) {
      const firstNotDisabledIndex = args.fieldGroup.findIndex((e) => !e.props.disabled);
      this.router.navigate([], {
        replaceUrl: true,
        relativeTo: this.activatedRoute,
        queryParamsHandling: "merge",
        queryParams: {
          t: args.initialIndex || firstNotDisabledIndex,
        },
      });
    }
    return value;
  }
}

@Directive({
  selector: "[metaTab]",
})
export class MetaTabDirective {
  constructor(private el: ElementRef) {}

  private _maParams: MetaTab = new MetaTab();

  @Input()
  public get maParams(): MetaTab {
    return this._maParams;
  }

  public set maParams(value: MetaTab) {
    this._maParams = { ...this._maParams, ...value };
  }

  get ma(): MetaTab {
    return this.maParams;
  }
}

@MetaUnsubscribe()
@Component({
  selector: "meta-tabs",
  template: `
    <div
      *ngIf="ma as options"
      class="{{ 'style-' + ma.style }} meta-tabs-outer-wrapper"
      [class.meta-tabs-outer-wrapper-main]="ma.routerTabs"
      (swipeleft)="swipe($event.type)"
      (swiperight)="swipe($event.type)"
    >
      <ng-container *ngIf="metaAppService.appBreakpoint() === 'sm' || metaAppService.appBreakpoint() === 'xs'">
        <div class="tab-dropdown-wrapper" (swipeleft)="swipe($event.type)" (swiperight)="swipe($event.type)">
          <nz-select
            name="tabDrodpown"
            [(ngModel)]="selectedIndex"
            (ngModelChange)="onTabDropdownSelect($event)"
            class="tab-dropdown"
          >
            <nz-option
              *ngFor="let tab of field.fieldGroup; let i = index"
              [nzValue]="i"
              [nzLabel]="field ? getTabLabel(tab) : tab.props?.label"
            ></nz-option>
          </nz-select>
          <div class="tab-dropdown-overlay">
            <span class="tab-dropdown-overlay-label">{{
              field ? getTabLabel(field.fieldGroup[selectedIndex]) : field.fieldGroup[selectedIndex].props?.label
            }}</span>
            <span class="tab-dropdown-overlay-dots">
              <span *ngFor="let tab of field.fieldGroup; let i = index" [class.active-tab]="selectedIndex === i"></span>
            </span>
          </div>
        </div>
      </ng-container>
      <nz-tabset
        *ngIf="options.routerTabs"
        [nzSize]="'large'"
        [nzTabBarStyle]="{
          display: metaAppService.appBreakpoint() === 'xs' ? 'none' : 'flex'
        }"
        [nzHideAll]="ma.hideTabs"
        [nzTabBarExtraContent]="options.filterable ? extra : null"
        [nzTabBarGutter]="0"
        [nzTabPosition]="options.tabsPosition"
        [nzAnimated]="animated"
        [nzSelectedIndex]="selectedIndex"
        nzLinkRouter
        (nzSelectedIndexChange)="onIndexChange($event)"
      >
        <nz-tab
          *ngFor="let tab of field.fieldGroup; let i = index; trackBy: idTrackBy"
          [nzDisabled]="
            tab.props.disabled
              | metaTabState
                : {
                    tabIndex: i,
                    currentIndex: selectedIndex,
                    fieldGroup: field.fieldGroup,
                    initialIndex: ma.initialCreateTab
                  }
          "
        >
          <a
            *nzTabLink
            nz-tab-link
            [replaceUrl]="replaceUrl"
            [relativeTo]="activatedRoute"
            [routerLink]="[]"
            [queryParamsHandling]="'merge'"
            [queryParams]="{
              t: i
            }"
          >
            {{ field ? getTabLabel(tab) : tab.props?.label }}
          </a>
          <ng-container *ngIf="tab.props.lazyLoad && metaAppService.appBreakpoint() !== 'xs'">
            <ng-template nz-tab>
              <meta-toolbar [maParams]="{ showBackButton: false }">
                <meta-button
                  *ngFor="let action of tab?.props?.actions"
                  [maParams]="
                    merge(action, {
                      loading: actionsLoading[action.id]
                    })
                  "
                ></meta-button>
              </meta-toolbar>
              <formly-field [field]="tab"></formly-field>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="!tab.props.lazyLoad || metaAppService.appBreakpoint() === 'xs'">
            <formly-field [field]="tab"></formly-field>
          </ng-container>
        </nz-tab>
      </nz-tabset>
      <nz-tabset
        *ngIf="!options.routerTabs"
        [nzSize]="'large'"
        [nzTabBarStyle]="{
          display: metaAppService.appBreakpoint() === 'sm' || metaAppService.appBreakpoint() === 'xs' ? 'none' : 'flex'
        }"
        [nzHideAll]="ma.hideTabs"
        [nzTabBarExtraContent]="options.filterable ? extra : null"
        [nzTabBarGutter]="0"
        [nzTabPosition]="options.tabsPosition"
        [nzAnimated]="animated"
      >
        <nz-tab *ngFor="let tab of tabsDataArray; let i = index" [nzTitle]="tab.label">
          <ng-container *ngTemplateOutlet="tabsTemplateArray[i]"></ng-container>
        </nz-tab>
        <nz-tab
          [nzTitle]="titleTemplate"
          *ngFor="let tab of field?.fieldGroup; let i = index"
          [nzDisabled]="tab.props.disabled"
        >
          <ng-template #titleTemplate>
            {{ field ? getTabLabel(tab) : tab.props?.label }}
            <ng-container *ngIf="tabConfiguration">
              <i
                (click)="tabConfiguration[i].hidden = !tabConfiguration[i].hidden; onConfigurationChange()"
                class="fa fa-{{ tabConfiguration[i].hidden ? 'eye-slash' : 'eye' }}"
              ></i>
            </ng-container>
          </ng-template>
          <ng-container *ngIf="tab.props?.lazyLoad">
            <ng-template nz-tab>
              <formly-field [field]="tab"></formly-field>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="!tab.props?.lazyLoad">
            <formly-field [field]="tab"></formly-field>
          </ng-container>
        </nz-tab>
      </nz-tabset>
    </div>

    <ng-template #extra>
      <input
        nz-input
        class="tab-search"
        [(ngModel)]="tabSearch"
        (ngModelChange)="onSearch($event)"
        placeholder="Inhalte filtern..."
      />
    </ng-template>
  `,
  styleUrls: ["./metaTabs.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaTabsComponent extends MetaWrapperBase implements OnInit, AfterViewInit {
  @ContentChildren(TemplateRef) tabTemplates: QueryList<TemplateRef<MetaTabDirective>>;
  @ContentChildren(MetaTabDirective) tabData: QueryList<MetaTabDirective>;
  public tabSearch: string;
  public replaceUrl = false;
  public animated = false;
  public selectedIndex: number;
  public tabsDataArray: MetaTab[] = [];
  public tabsTemplateArray: TemplateRef<MetaTabDirective>[] = [];
  public actionsLoading = {};
  public tabConfiguration: any[];

  constructor(
    public readonly metaAppService: MetaAppService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    public readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly _metaActionHandler: MetaActionHandlerFactory,
    @Optional()
    private readonly modal: NzModalRef,
  ) {
    super();
    this.onSearch = _.debounce(this.onSearch, 100);
    this.swipe = _.debounce(this.swipe, 250);
  }

  public idTrackBy(index, item: any) {
    return item.id;
  }

  public ngAfterViewInit() {
    if (this.tabData) {
      this.tabsDataArray = this.tabData.toArray().map((e) => e.ma);
      this.tabsTemplateArray = this.tabTemplates.toArray();
    }
  }

  public ngOnInit(): void {
    if (this.field && this.formState?.config) {
      this.field.fieldGroup = this.field.fieldGroup.map((g) => {
        if (g.props.lazyLoadUntilFirstActivation) {
          g.props.lazyLoad = true;
        }
        return g;
      });
      this.tabConfiguration = this.field.fieldGroup.map((g) => {
        return (this.formState.config[g.id] = this.formState.config[g.id] || { hidden: false });
      });
      this.props.routerTabs = false;
    }
    if (this.field) {
      this.field.fieldGroup = this.field.fieldGroup.map((g) => {
        g.props.actions = (g.props.actions || []).map((action) => {
          return {
            ...action.props,
            disabled: false,
            onClick: () => {
              if (action.disabledCondition && this.options.formState.displayData()?.[action.id]?.disabledCondition) {
                return;
              }
              this.actionsLoading[action.id] = true;
              this._changeDetectorRef.markForCheck();

              const oldState = this.options.formState.state;
              if (
                this.modal &&
                this.options.formState.state() >= MetaState.editing &&
                this.options.formState.state() <= MetaState.saving
              ) {
                this.formState.state.set(MetaState.disabled);
              }
              this.form.markAllAsTouched();

              const model = this.formState.data();
              this._metaActionHandler
                .executeClickAction({
                  controlId: action.id,
                  ctx: this.model["_ctx"],
                  formId: this.formState.formId,
                  data: {
                    ..._.merge(this.model, model),
                  },
                  formIsValid: this.form.valid,
                  passthroughData: { formId: this.formState.formId },
                })
                .catch((e) => {
                  console.error(e);
                })
                .finally(() => {
                  if (this.modal) {
                    this.formState.state.set(oldState);
                  }
                  this.actionsLoading[action.id] = false;
                  this._changeDetectorRef.markForCheck();
                });
            },
          };
        });
        return g;
      });
    }
    if (this.modal || (this.field && this.formState.loadedAsTooltip)) {
      this.props.routerTabs = false;
    }
    if (this.ma.routerTabs) {
      this.activatedRoute.queryParams
        .pipe(
          takeUntil(this.destroy$),
          distinctUntilChanged((a: any, b: any) => JSON.stringify(a.t) === JSON.stringify(b.t)),
        )
        .subscribe(async (queryParams) => {
          if (queryParams.t === undefined) {
            const params = await firstValueFrom(this.activatedRoute.params);
            const initTab =
              typeof this.ma.initialCreateTab === "number" && params.itemId === "create" ? this.ma.initialCreateTab : 0;
            this.selectedIndex = initTab;
            await this.router.navigate([], {
              replaceUrl: true,
              relativeTo: this.activatedRoute,
              queryParamsHandling: "merge",
              queryParams: {
                t: initTab,
              },
            });
          } else {
            this.selectedIndex = Number(queryParams.t);
          }
          if (this.field && this.field.fieldGroup[this.selectedIndex].props?.lazyLoadUntilFirstActivation) {
            this.field.fieldGroup[this.selectedIndex].props.lazyLoad = false;
          }
        });
    }
  }

  public onSearch(term: string) {
    this.formState.fieldFilter$.next(term);
  }

  public onConfigurationChange() {
    this.formState.dirty = true;
  }

  public getTabLabel(tab: FormlyFieldConfig): string | undefined {
    if (!tab) {
      return;
    }

    this.subFormPath = this.subFormPath || this._metaHelperService.getFormlySubFormPath(tab);
    this.subFormIndex = this.subFormIndex || this._metaHelperService.getFormlyFieldArrayIndex(tab, true);

    const getLabel = (path: string[], index: number[]): string | undefined => {
      try {
        let result = this.formState.displayData();
        for (let i = 0; i < path.length; i++) {
          result = result[path[i]].values[index[i]];
        }
        return result[tab.id]?.label;
      } catch {
        return tab.props?.label;
      }
    };

    switch (this.subFormPath.length) {
      case 1:
        return getLabel(this.subFormPath, this.subFormIndex);
      case 2:
        return getLabel(this.subFormPath, this.subFormIndex);
      default:
        return this.formState.displayData()?.[tab.id]?.label ?? tab.props?.label;
    }
  }

  public async onTabDropdownSelect(index: number) {
    await this.router.navigate([], {
      replaceUrl: true,
      relativeTo: this.activatedRoute,
      queryParamsHandling: "merge",
      queryParams: {
        t: index,
      },
    });
  }

  public async swipe(action = SWIPE_ACTION.RIGHT) {
    let index;
    // Out of range
    if (
      this.selectedIndex < 0 /* starter point as 1 */ ||
      this.selectedIndex > this.field.fieldGroup.length /* here it is */
    )
      return;

    // Swipe left, next tab
    if (action === SWIPE_ACTION.LEFT) {
      const isLast = this.selectedIndex === this.field.fieldGroup.length;
      index = isLast ? 0 : this.selectedIndex + 1;
    }

    // Swipe right, previous tab
    if (action === SWIPE_ACTION.RIGHT) {
      const isFirst = this.selectedIndex === 0; /* starter point as 1 */
      index = isFirst ? 1 : this.selectedIndex - 1;
    }

    await this.router.navigate([], {
      replaceUrl: true,
      relativeTo: this.activatedRoute,
      queryParamsHandling: "merge",
      queryParams: {
        t: index,
      },
    });
  }

  public onIndexChange(item: any) {
    if (this.field && this.field.fieldGroup[item].props?.lazyLoadUntilFirstActivation) {
      this.field.fieldGroup[item].props.lazyLoad = false;
    }
  }

  public merge<A, B>(a: A, b: B): A & B {
    return { ...a, ...b };
  }
}

@NgModule({
  declarations: [MetaTabsComponent, MetaTabDirective, MetaTabStatePipe],
  imports: [
    CommonModule,
    FormlyModule,
    NzTabsModule,
    RouterModule,
    FormsModule,
    NzSelectModule,
    NzInputModule,
    DynamicIoModule,
    MetaToolbarModule,
    MetaButtonModule,
  ],
  exports: [MetaTabsComponent, MetaTabDirective],
})
export class MetaTabsModule {}
