/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Exclude, Expose, instanceToPlain, Type } from "class-transformer";
import {
  ExposeProperty,
  MetaFormBaseComponent,
  MetaFormComponent,
  MetaFormComponentInfo,
  MetaFormComponentProperty,
} from "../components";
import type { FormEventActionHandler } from "../interfaces";
import { IsArray } from "class-validator";
import { FormlyHideExpression } from "@meta/enums";
import { EditableForm } from "./editableForm";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { toPlain } from "../formSerializer";

export class TableCardTemplates {
  @MetaFormComponentProperty({
    name: "Status",
    type: "text",
    tab: "general",
  })
  status?: { indicator: string; label: string };
  @MetaFormComponentProperty({
    name: "Avatar",
    type: "text",
    tab: "general",
  })
  avatar?: string;
  @MetaFormComponentProperty({
    name: "Content",
    type: "text",
    tab: "general",
  })
  content?: string[];
  @MetaFormComponentProperty({
    name: "title",
    type: "text",
    tab: "general",
  })
  title?: string[];
  @MetaFormComponentProperty({
    name: "Link",
    type: "text",
    tab: "general",
  })
  link?: string;
  @MetaFormComponentProperty({
    name: "Progress",
    type: "text",
    tab: "general",
  })
  progress?: { value: number; label?: string; tooltip?: string; user?: { id: string; name: string } };
}

@Exclude()
@MetaFormComponentInfo({
  selector: "ma-table",
  type: "table",
  icon: "table",
  name: "Tabelle",
  allowedChildren: ["input", "select", "checkbox", "datepicker"],
})
export class Table extends EditableForm {
  static selector = "ma-table";
  @MetaFormComponentProperty({
    name: "Detail Form",
    type: "subform",
    tab: "data",
  })
  public detailForm: string;

  @MetaFormComponentProperty({
    name: "Columns",
    type: "children",
    tab: "general",
    allowedChildren: ["input"],
  })
  public children: MetaFormBaseComponent[] = [];

  @MetaFormComponentProperty({
    name: "Detail Height",
    type: "number",
    tab: "style",
    default: 250,
    condition: (o) => o.detailForm,
  })
  public detailHeight: number;
  @ExposeProperty() public height: number;

  @MetaFormComponentProperty({
    name: "Detail Id",
    type: "text",
    isTemplate: true,
    tab: "data",
    condition: (o) => o.detailForm,
  })
  public detailId: string;
  @MetaFormComponentProperty({
    name: "On Select",
    type: "action",
    tab: "hooks",
  })
  public onSelect?: FormEventActionHandler;
  @MetaFormComponentProperty({
    name: "On Click",
    type: "action",
    tab: "hooks",
  })
  public onClick?: FormEventActionHandler;
  @MetaFormComponentProperty({
    name: "On Edit",
    type: "action",
    tab: "hooks",
  })
  public onEdit?: FormEventActionHandler;
  @MetaFormComponentProperty({
    name: "On Reorder",
    type: "action",
    tab: "hooks",
  })
  public onReorder?: FormEventActionHandler;
  @MetaFormComponentProperty({
    name: "Selectable",
    type: "boolean",
    default: false,
  })
  public selectable?: boolean;
  @MetaFormComponentProperty({
    name: "Selection type",
    type: "select",
    tab: "table",
    default: "multiple",
    values: [
      { value: "multiple", label: "Mehrfachauswahl" },
      { value: "single", label: "Einzelauswahl" },
    ],
    required: false,
  })
  public selectionType?: "single" | "multiple" = "multiple";
  @MetaFormComponentProperty({
    name: "Exportable",
    description: "Allows exporting the table as xlsx/json/csv",
    type: "boolean",
    default: true,
  })
  public exportable? = true;
  @MetaFormComponentProperty({
    name: "Mark selected element",
    type: "boolean",
    default: false,
  })
  public highlightSelectedItem = false;
  @MetaFormComponentProperty({
    name: "Sortable",
    type: "boolean",
    default: true,
  })
  public sortable = true;
  @MetaFormComponentProperty({
    name: "Bearbeitbar",
    type: "condition",
    tab: "general",
    default: false,
  })
  public editable? = false;
  @MetaFormComponentProperty({
    name: "Checkable",
    type: "boolean",
    default: true,
  })
  public checkable? = false;
  @MetaFormComponentProperty({
    name: "Hide Sidebar",
    type: "boolean",
    default: false,
  })
  public hideSidebar? = false;
  @MetaFormComponentProperty({
    name: "Hide Toolbar",
    type: "boolean",
  })
  public hideToolbar? = false;
  @MetaFormComponentProperty({
    name: "Hide Footer",
    type: "boolean",
  })
  public hideFooter? = false;
  @MetaFormComponentProperty({
    name: "Chunk Size",
    type: "number",
    default: 50,
    min: 0,
    max: 1000,
  })
  public chunkSize = 50;

  @MetaFormComponentProperty({
    name: "Searchable",
    type: "boolean",
    default: true,
  })
  public searchable? = true;

  @ExposeProperty()
  public searchFields?: string[];
  @MetaFormComponentProperty({
    name: "Is Detail",
    type: "boolean",
    default: false,
  })
  public isDetail? = false;

  @MetaFormComponentProperty({
    name: "Load data on init",
    type: "boolean",
    default: false,
  })
  public noInitDataLoad?: false;

  @MetaFormComponentProperty({
    name: "Dense mode",
    type: "boolean",
    default: false,
  })
  public dense? = false;

  @MetaFormComponentProperty({
    name: "Card loading indicator count",
    type: "number",
    default: null,
    min: 0,
    max: 50,
  })
  public cardLoadingIndicatorCount?: number | null = null;

  @MetaFormComponentProperty({
    name: "Default view",
    type: "select",
    tab: "style",
    default: "table",
    values: [
      { value: "table", label: "Tabelle" },
      { value: "grid", label: "Raster" },
    ],
    required: false,
  })
  public defaultView: "table" | "grid" = "table";

  @Type(() => TableCardTemplates)
  @ExposeProperty()
  public cardTemplates?: TableCardTemplates;
  @MetaFormComponentProperty({
    name: "Selectable Template",
    type: "text",
    default: null,
  })
  public selectableTemplate?: string[];

  @ExposeProperty()
  @IsArray()
  public filter: MetaFormComponent[] = [];

  @MetaFormComponentProperty({
    name: "Groupable",
    type: "boolean",
    default: true,
  })
  public groupable = true;

  @MetaFormComponentProperty({
    name: "Show as treeview",
    type: "boolean",
    tab: "general",
    default: false,
  })
  public showAsTreeview? = false;

  @MetaFormComponentProperty({
    name: "Id Field for each treeview group",
    type: "path",
    tab: "data",
  })
  public treeViewIdField: string;

  @MetaFormComponentProperty({
    name: "Label for each treeview group",
    type: "path",
    tab: "data",
  })
  public treeViewIdLabel: string;

  @MetaFormComponentProperty({
    name: "Params Field for each treeview group that is send to primaryDatasourceParams",
    type: "path",
    tab: "data",
  })
  public treeViewParamField: string;

  @MetaFormComponentProperty({
    name: "Indicator field if a recordset has childs",
    type: "path",
    tab: "data",
  })
  public treeViewGroupIndicatorField: string;

  constructor(props: Partial<Table>) {
    super(props);
    Object.assign(this, props);
  }

  public static createElement(attributes, children: any[]): any {
    return new Table({
      ...attributes,
      children,
    });
  }

  toFormly(config: IFormUserConfiguration): any {
    return {
      id: this.id,
      type: `meta-${this.constructor["meta"]["type"]}`,
      props: {
        filter: this.filter.map((child) => child.toFormly(config)).filter((c) => !!c),
        selectable: !!this.onSelect || this.selectable,
        editable: !!this.onEdit || this.editable,
        fullEditMode: !!this.onEdit,
        deletable: !!this.onDelete,
        clickable: !!this.onClick,
        createable: this.createable,
        groupable: !this.isDetail && this.groupable,
        sortable: this.sortable,
        label: this.label,
        exportable: this.exportable,
        condition: {
          take: this.chunkSize,
          ...this.defaultCondition,
        },
        entityType: this.entityType || this.primaryForEntityType,
        queryParams: Object.keys(this.dataSourceParameters?.customParams || {}),
        detailForm: this.detailForm,
        detailRowHeight: this.detailHeight || undefined,
        hideSidebar: this.isDetail || this.hideSidebar,
        hideToolbar: this.isDetail || this.hideToolbar,
        searchable: this.searchable,
        isDetail: this.isDetail,
        height: this.height,
        actions: this.actions.map((action) => toPlain(action)),
        noInitDataLoad: this.noInitDataLoad,
        cardTemplates: !!this.cardTemplates,
        showGroupWrapper: this.showGroupWrapper,
        showAsTreeview: this.showAsTreeview,
        treeViewIdField: this.treeViewIdField,
        treeViewIdLabel: this.treeViewIdLabel,
        treeViewGroupIndicatorField: this.treeViewGroupIndicatorField,
        cardLoadingIndicatorCount: this.cardLoadingIndicatorCount,
        treeViewParamField: this.treeViewParamField,
        defaultView: this.defaultView,
        rowHeight: this.dense ? 45 : 65,
        selectionType: this.selectionType,
        hasSelectableTemplate: !!this.selectableTemplate,
        hideFooter: this.hideFooter,
      },
      fieldArray: {
        fieldGroup: this.children.map((c) => c.toFormly(config)).filter((e) => !!e),
      },
      hideExpression: FormlyHideExpression,
      wrappers: [],
    };
  }
}
