/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, Input, OnInit } from "@angular/core";
import { EntityType } from "@meta/enums";
import { MetaHelperService } from "../../../services/metaHelper.service";
import { IFeedResultItem } from "@meta/api-interfaces";

export class MetaTimelineTemplatePipelineObjectMoved {
  data: IFeedResultItem<{
    current: {
      link: string;
      phase: {
        id: string;
        name: string;
      };
      pipeline: {
        id: string;
        name: string;
        link: string;
      };
    };
    previous: {
      link: string;
      phase: {
        id: string;
        name: string;
      };
      pipeline: {
        id: string;
        name: string;
        link: string;
      };
    };
  }>;
}

@Component({
  selector: "meta-timeline-template-pipeline-object-moved",
  template: `
    <meta-translate key="timeline.pipeline-object-moved">
      <ng-template meta-translate-child key="entity">
        <meta-live-card
          [cardLazyLoad]="true"
          [cardType]="item.data.entity.type"
          cardSize="link"
          [cardId]="item.data.entity.id"
          [cardData]="item.data.cardData"
        ></meta-live-card>
      </ng-template>

      <ng-template meta-translate-child key="phase">
        <a [href]="item.data.data.current.pipeline.link">
          <meta-tag
            [maParams]="{ label: tplLabelPipelineNew, color: colorPipeline, colorText: colorText, maxWidth: 250 }"
          ></meta-tag>
        </a>
      </ng-template>
    </meta-translate>

    <ng-template #tplLabelPipelineNew
      ><i class="fas fa-{{ item.data.entity.icon }}"></i><b>{{ item.data.data.current.pipeline.name }}</b> |
      {{ item.data.data.current.phase.name }}
    </ng-template>
  `,
})
export class MetaTimelineTemplatePipelineObjectMovedComponent implements OnInit {
  @Input() public item: MetaTimelineTemplatePipelineObjectMoved;
  public entityType = EntityType;
  public colorPipeline: string;
  public colorText: string;

  constructor(public metaHelperService: MetaHelperService) {}

  ngOnInit() {
    this.colorPipeline = this.metaHelperService.generateColor(this.item.data.entity.type);
    this.colorText = getComputedStyle(document.documentElement).getPropertyValue("--component-background");
  }
}
