/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MetaPrintModalComponent } from "./meta-print-modal.component";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzSelectModule } from "ng-zorro-antd/select";
import { MetaPrintService } from "./meta-print.service";
import { MetaInputModule } from "../metaInput/metaInput.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MetaFieldWrapperModule } from "../metaFieldWrapper/metaFieldWrapper.component";
import { MetaSelectModule } from "../metaSelect/metaSelect.component";
import { MetaSwitchModule } from "../metaSwitch/metaSwitch.component";
import { MetaCollapseModule } from "../metaCollapse/metaCollapse.component";
import { MetaButtonModule } from "../metaButton/metaButton.component";

@NgModule({
  declarations: [MetaPrintModalComponent],
  imports: [
    CommonModule,
    NzGridModule,
    NzButtonModule,
    NzSelectModule,
    MetaInputModule,
    ReactiveFormsModule,
    MetaFieldWrapperModule,
    MetaSelectModule,
    MetaSwitchModule,
    MetaCollapseModule,
    MetaButtonModule,
  ],
  providers: [MetaPrintService],
  exports: [MetaPrintModalComponent],
})
export class MetaPrintModalModule {}
