/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponentInfo } from "../components";
import { EditableForm } from "./editableForm";
import { IFormUserConfiguration } from "../formUserConfiguration";

@MetaFormComponentInfo({
  selector: "ma-app",
  type: "app",
  icon: "mobile",
  name: "App",
})
export class MetaApp extends EditableForm {
  public static createElement(attributes: Partial<MetaApp>, children: any[]): any {
    return new MetaApp({
      ...attributes,
      children,
    });
  }
  toFormly(config: IFormUserConfiguration): any {
    return {
      ...super.toFormly(config),
      type: "app",
    };
  }
}
