/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

export enum DATA_SOURCE_TYPE {
  PROCEDURE,
  VIEW,
  TABLE,
  ARRAY,
  SQL,
  JS,
  TABLE_VALUED_FUNCTION,
  CUSTOM,
}
