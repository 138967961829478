/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, OnInit } from "@angular/core";
import { MetaMediaLibraryService } from "../metaMediaLibrary.service";
import { NzMessageService } from "ng-zorro-antd/message";
import * as moment from "moment";
import { IMetaMediaLibraryFile } from "../interfaces";

@Component({
  template: ` <ng-container *ngIf="files">
    <meta-field-wrapper [maParams]="{ label: 'Gültigkeit' }">
      <meta-datepicker
        [(ngModel)]="date"
        [maParams]="{ datepickerType: 'daterange', editing: true, placeholder: ['Von', 'Bis'] }"
      ></meta-datepicker>
    </meta-field-wrapper>
    <meta-field-wrapper [maParams]="{ label: 'Passwort' }">
      <meta-input [(ngModel)]="password" [maParams]="{ editing: true, placeholder: 'Password' }"></meta-input>
    </meta-field-wrapper>
    <meta-field-wrapper [maParams]="{ label: 'Link' }">
      <meta-button
        (click)="copyLink()"
        [maParams]="{ label: 'In Zwischenablage kopieren', icon: 'clipboard' }"
      ></meta-button>
    </meta-field-wrapper>
    <meta-field-wrapper *ngIf="showNativeShare" [maParams]="{ label: 'Datei' }">
      <meta-button (click)="nativeShare()" [maParams]="{ label: 'Teilen', icon: 'share' }"></meta-button>
    </meta-field-wrapper>
  </ng-container>`,
  styleUrls: ["./metaMediaLibraryShare.component.less"],
})
export class MetaMediaLibraryShareComponent implements OnInit {
  public files: IMetaMediaLibraryFile[];
  public successful = false;
  public date: [Date, Date] = [new Date(), moment().add(1, "week").toDate()];
  public showNativeShare = false;
  public password = "";

  constructor(private readonly service: MetaMediaLibraryService, private readonly messageService: NzMessageService) {}

  public get downloadAsZip() {
    return this.password.length > 0 || this.files.length > 1;
  }

  copyLink() {
    this.service
      .getPublicUrl(this.files.length === 1 ? this.files[0] : this.files, {
        expiresOn: this.date[1],
        zip: this.downloadAsZip,
        password: this.password,
      })
      .then(({ url }) => {
        return navigator.clipboard.writeText(url);
      })
      .catch((e) => {
        this.messageService.error("Datei konnte nicht freigegeben werden.");
      })
      .then(() => {
        this.successful = true;
        this.messageService.success("Freigabe Link wurde erfolgreich in die Zwischenablage kopiert.");
      });
  }

  async nativeShare() {
    const { url } = await this.service.getPublicUrl(this.files.length === 1 ? this.files[0] : this.files, {
      expiresOn: this.date[1],
      zip: this.downloadAsZip,
      password: this.password,
    });
    const shareData: ShareData = {
      title: this.files.length === 1 ? this.files[0].name : "archive.zip",
      text: this.files.length === 1 ? this.files[0].description : "Zip Archive",
      url,
    };
    navigator
      .share(shareData)
      .then(() => {
        console.log("ok");
      })
      .catch((e) => {
        console.error(e);
      });
  }

  ngOnInit(): void {
    if (window.navigator.share) {
      this.showNativeShare = true;
    }
  }
}
