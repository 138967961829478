/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { MetaTimelineItem } from "@meta/ui";
import { BehaviorSubject, Subscription, take } from "rxjs";
import { EntityType } from "@meta/enums";
import { GetHubResult, GetQuoteResult, MetaHubElement, MetaHubLoadMore, MetaHubSkipTake } from "./hub.interfaces";
import { HttpClient } from "@angular/common/http";
import { MetaErrorHandlerService } from "../../../../../../libs/ui/src/lib/services/metaErrorHandler.service";
import { NzMessageService } from "ng-zorro-antd/message";
import * as Diff from "diff";
import * as moment from "moment";
import { IFeedResultItem } from "@meta/api-interfaces";
import { formatDate } from "@angular/common";
import { DateTime } from "luxon";

@Injectable({ providedIn: "root" })
export class HubService {
  public activities$: BehaviorSubject<MetaTimelineItem[]> = new BehaviorSubject<MetaTimelineItem[]>([]);
  public activitiesTotal$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public actions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public actionsTotal$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public appointments$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public appointmentsTotal$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public tasksTotal$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public timeline$: BehaviorSubject<MetaTimelineItem[]> = new BehaviorSubject<MetaTimelineItem[]>([]);
  public timelineTotal$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public favorites$: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);

  public entityFilters$: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);

  public users$: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);

  public quote$: BehaviorSubject<string> = new BehaviorSubject<any>(null);
  public quoteAuthor$: BehaviorSubject<string> = new BehaviorSubject<any>(null);

  public history$: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);

  public historyThemes$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  public skipTake: MetaHubSkipTake = {
    activities: {
      skip: 0,
      take: 25,
    },
    actions: {
      skip: 0,
      take: 1,
    },
    history: {
      skip: 0,
      take: 10,
    },
    favorites: {
      skip: 0,
      take: 10,
    },
  };

  public isLoadingMore: MetaHubLoadMore = {
    [MetaHubElement.activities]: false,
    [MetaHubElement.actions]: false,
    [MetaHubElement.history]: false,
    [MetaHubElement.favorites]: false,
  };

  public isLoadingActivities: boolean;
  public isSearchingActivities: boolean;

  private loadingActivitiesSubscription: Subscription;

  constructor(
    private _http: HttpClient,
    private _metaErrorHandlerService: MetaErrorHandlerService,
    private _nzMessageService: NzMessageService,
    @Inject(LOCALE_ID)
    private readonly localeId: string,
  ) {}

  public async getActivities({
    quelleId,
    quelleTyp,
    filter,
    user,
    startDate,
    onlyShowFavorites,
  }: {
    quelleId?: string;
    quelleTyp?: string;
    filter?: string;
    user?: string;
    startDate?: Date;
    onlyShowFavorites?: boolean;
  } = {}) {
    if (this.loadingActivitiesSubscription) {
      this.loadingActivitiesSubscription.unsubscribe();
      this.loadingActivitiesSubscription = null;
    }

    const params = new URLSearchParams();
    if (this.skipTake.activities.skip === 0) {
      this.isSearchingActivities = true;
      this.isLoadingActivities = true;
      this.activities$.next([]);
    }
    params.set("skip", `${this.skipTake.activities.skip}`);
    params.set("take", `${this.skipTake.activities.take}`);
    params.set("favorites", String(onlyShowFavorites || false));

    if (filter) params.set("filter", `${filter}`);
    if (quelleId) params.set("quelleId", `${quelleId}`);
    if (quelleTyp) params.set("quelleTyp", `${quelleTyp}`);
    if (user) params.set("user", `${user}`);
    if (startDate) params.set("startDate", startDate.toISOString());

    this.loadingActivitiesSubscription = this._http.get<GetHubResult>(`hub/log?${params}`).subscribe({
      next: (value) => {
        if (this.skipTake.activities.skip > 0) {
          let activities = this.activities$.getValue();
          if (!activities) {
            this.skipTake.activities.skip = 0;
            activities = [];
          }
          activities = activities.concat(
            this._mapActivities(value.resultSet, quelleId !== undefined && quelleId !== null),
          );
          this.activities$.next(activities);
        } else {
          this.activities$.next(this._mapActivities(value.resultSet, quelleId !== undefined && quelleId !== null));
        }
        this.activitiesTotal$.next(value.total);
      },
      error: (err) => {
        this._metaErrorHandlerService
          .handleError(err)
          .pipe(take(1))
          .subscribe({
            error: (value) => {
              this._nzMessageService.error(value);
              this.isLoadingMore.activities = false;
              this.isLoadingActivities = false;
              this.isSearchingActivities = false;
            },
          });
      },
      complete: () => {
        this.isLoadingMore.activities = false;
        this.isLoadingActivities = false;
        this.isSearchingActivities = false;
      },
    });
    if (!this.historyThemes$.value || this.historyThemes$.value.length === 0) {
      this._http.get<any[]>(`hub/history/themes`).subscribe((res) => {
        this.historyThemes$.next(res);
      });
    }
  }

  public _mapActivities(resultSet: IFeedResultItem[], hideLabel = false) {
    return resultSet.map((r) => {
      try {
        let dateTooltip: string;
        try {
          dateTooltip = formatDate(r.date, "medium", this.localeId);
        } catch (e) {
          dateTooltip = formatDate(r.date, "medium", window.navigator.language);
        }
        return {
          ID: r.id,
          style: "log",
          icon: r.operation.icon,
          type: r.entity.type,
          link: hideLabel ? null : r.entity.link ? r.entity.link : "/",
          label: "", //r.operation.name || r.data?.description || "",
          description: this.generateDescription(r.data),
          avatar: r.user.id,
          avatarName: r.user.name,
          metadata: [
            {
              icon: "clock",
              label: DateTime.fromISO(r.date).setLocale(this.localeId).toRelative(),
              tooltip: dateTooltip,
            },
            {
              label: r.user.name,
              avatar: r.user.id,
              link: "/frmUsers/" + r.user.id,
            },
          ],
          data: r,
        };
      } catch (e) {
        const message = "Fehler beim laden des Datensatzes.";
        return {
          ID: Date.now(),
          style: "error",
          metadata: [],
          data: {},
          label: "", //message,
          description: message,
          icon: "bug",
        };
      }
    });
  }

  public getTotalTasks() {
    this._http.get<GetHubResult>(`task?filter=open`).subscribe({
      next: (value) => {
        this.tasksTotal$.next(value.total);
      },
      error: (err) => {
        this._metaErrorHandlerService
          .handleError(err)
          .pipe(take(1))
          .subscribe({
            error: (value) => this._nzMessageService.error(value),
          });
      },
      complete: () => {
        this.isLoadingMore.actions = false;
      },
    });
  }

  public getActions() {
    this._http
      .get<GetHubResult>(`activities/actions?skip=${this.skipTake.actions.skip}&take=${this.skipTake.actions.take}`)
      .subscribe({
        next: (value) => {
          if (this.skipTake.actions.skip > 0) {
            let actions = this.actions$.getValue();
            actions = actions.concat(this._mapActions(value.resultSet));
            this.actions$.next(actions);
          } else {
            this.actions$.next(this._mapActions(value.resultSet));
          }
          this.actionsTotal$.next(value.total);
        },
        error: (err) => {
          this._metaErrorHandlerService
            .handleError(err)
            .pipe(take(1))
            .subscribe({
              error: (value) => this._nzMessageService.error(value),
            });
        },
        complete: () => {
          this.isLoadingMore.actions = false;
        },
      });
  }

  public getEntityFilter() {
    this._http.get<any[]>(`hub/getEntityFilter`).subscribe({
      next: (value) => {
        this.entityFilters$.next(value);
      },
      error: (err) => {
        this._metaErrorHandlerService
          .handleError(err)
          .pipe(take(1))
          .subscribe({
            error: (value) => this._nzMessageService.error(value),
          });
      },
    });
  }

  public getUsers() {
    this._http.get<any[]>(`hub/users`).subscribe({
      next: (value) => {
        this.users$.next(value);
      },
      error: (err) => {
        this._metaErrorHandlerService
          .handleError(err)
          .pipe(take(1))
          .subscribe({
            error: (value) => this._nzMessageService.error(value),
          });
      },
    });
  }

  public getHistory() {
    this._http.get<any[]>(`hub/history`).subscribe({
      next: (value) => {
        this.history$.next(value);

        if (this.skipTake.history.skip > 0) {
          let history = this.history$.getValue();
          history = history.concat(this._mapHistory(value));
          this.history$.next(history);
        } else {
          this.history$.next(this._mapHistory(value));
        }
      },
      error: (err) => {
        this._metaErrorHandlerService
          .handleError(err)
          .pipe(take(1))
          .subscribe({
            error: (value) => this._nzMessageService.error(value),
          });
      },
    });
  }

  public getFavorites() {
    this._http.get<any[]>(`hub/favorites`).subscribe({
      next: (value: any) => {
        if (this.skipTake.favorites.skip > 0) {
          let favorites = this.favorites$.getValue();
          favorites = [...favorites, ...value];
          this.favorites$.next(favorites);
        } else {
          this.favorites$.next(value);
        }
      },
      error: (err) => {
        this._metaErrorHandlerService
          .handleError(err)
          .pipe(take(1))
          .subscribe({
            error: (value) => this._nzMessageService.error(value),
          });
      },
    });
  }

  public setEntityFilter(entity: EntityType) {
    this._http.post<any[]>(`hub/setEntityFilter?entity=${entity}`, {}).subscribe({
      next: (value) => {
        this.entityFilters$.next(value);
      },
      error: (err) => {
        this._metaErrorHandlerService
          .handleError(err)
          .pipe(take(1))
          .subscribe({
            error: (value) => this._nzMessageService.error(value),
          });
      },
    });
  }

  public getQuote() {
    this._http.get<GetQuoteResult>(`hub/quote`).subscribe({
      next: (value) => {
        this.quote$.next(value.Zitat);
        this.quoteAuthor$.next(value.Autor);
      },
      error: () => {},
    });
  }

  private generateDescription(data: any) {
    return data?.isCreate
      ? null
      : (data?.entries || [])
          .map((e) => {
            const diff = Diff.diffLines(this._formatValue(e.oldDisplayValue), this._formatValue(e.newDisplayValue));
            let span,
              title = null;
            const fragment = document.createElement("span");
            title = document.createElement("span");
            title.classList.add("log-label");
            title.appendChild(document.createTextNode(`${e.lable || e.field}: `));
            fragment.appendChild(title);
            diff.forEach((part, index) => {
              const color = part.added ? "log-added" : part.removed && e.old !== null ? "log-removed" : "log-default";
              span = document.createElement("span");
              span.classList.add(color);
              if (part.removed) {
                span.appendChild(document.createTextNode(`${e.old !== null ? part.value : "<Kein Wert>"}`));
              } else {
                span.appendChild(document.createTextNode(`${e.new !== null ? part.value : "<Kein Wert>"}`));
              }
              fragment.appendChild(span);
              if (index !== diff.length - 1) {
                const icon = document.createElement("i");
                icon.classList.add("fas");
                icon.classList.add("fa-caret-right");
                icon.classList.add("mx-2");
                fragment.appendChild(icon);
              }
            });
            return fragment.outerHTML;
          })
          .join("<br/>");
  }

  private _formatValue(value: any) {
    if (value === true) {
      value = "Ja";
    } else if (value === false) {
      value = "Nein";
    } else if (
      (moment(value, "YYYY-MM-DDTHH:mm:ss.ms", true).isValid() ||
        moment(value, "YYYY-MM-DDTHH:mm:ss", true).isValid()) &&
      isNaN(value)
    ) {
      value = moment(value).format("DD.MM.YYYY");
    }
    return String(value !== null && value !== undefined ? value : "");
  }

  private _mapActions(resultSet: any[]) {
    return resultSet.map((rs: any) => {
      return {
        id: rs.Id,
        label: rs.Titel,
        new: moment(rs.DatumAnlage).add(1, "hour").isAfter(moment()),
        description: [rs.Beschreibung],
        icon: rs.AktivitaetTyp,
        data: rs,
      };
    });
  }

  private _mapAppointments(resultSet: any[]) {
    return resultSet.map((rs: any) => {
      return {
        id: rs.ID,
        label: rs.Titel,
        new: moment(rs.DatumAnlage).add(1, "hour").isAfter(moment()),
        description: [
          `${moment(rs.Start).format(rs.Ganztaegig ? "DD.MM.YYYY" : "DD.MM.YYYY HH:mm")} ${rs.Ganztaegig ? "" : "Uhr"}`,
        ],
        icon: rs.AktivitaetTyp,
        data: rs,
      };
    });
  }

  private _mapTimeline(resultSet: any[]): any {
    const dateNow = moment();
    return resultSet.map((rs: any) => {
      return {
        ID: rs.ID,
        label: rs.Titel,
        description: rs.Beschreibung,
        icon: rs.AktivitaetTyp,
        color: dateNow > moment(rs.Start) ? "danger" : "default",
        metadata: [
          {
            icon: "clock",
            label: moment(rs.Start).fromNow(),
            tooltip: moment(rs.Start).format("dd, DD.MM.yyyy") + " " + moment(rs.Start).format("HH:mm") + " Uhr",
          },
          {
            label: rs.ZugewiesenAn,
            icon: "user",
            link: "#",
          },
        ],
        data: rs,
        participants:
          rs.Aktivitaeten_Teilnehmer?.length > 0
            ? rs.Aktivitaeten_Teilnehmer.map((at) => {
                return {
                  label: `${at.Name}` || at.Email_Gast,
                };
              })
            : null,
      };
    });
  }

  private _mapHistory(resultSet: any[]) {
    return resultSet.map((rs: any) => {
      return {
        ...rs,
        label: rs.Title,
        type: [rs.Beschreibung],
        link: rs.Link,
        data: rs,
      };
    });
  }
}
