/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { debounce } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { MetaFormBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaActionHandlerFactory } from "../../base/metaForm/actions/actionHandler.factory";
import { MetaButton, MetaButtonModule } from "../metaButton/metaButton.component";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  effect,
  inject,
  Injector,
  Input,
  NgModule,
  OnInit,
  Pipe,
  ViewEncapsulation,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { FieldArrayType, FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import { MetaButtonType, MetaState, MetaIconStyle, MetaSectionType } from "@meta/enums";
import { SortablejsModule } from "@moddi3/ngx-sortablejs";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SortableOptions } from "sortablejs";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaSectionModule } from "../metaSection/metaSection.component";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaEmptyModule } from "../metaEmpty/metaEmpty.component";
import { PipeTransform } from "@nestjs/common/interfaces/features/pipe-transform.interface";
import memo from "memo-decorator";
import { NzPopoverModule } from "ng-zorro-antd/popover";

export class MetaListInput extends MetaFormBase {
  addable? = true;
  editable? = true;
  deletable?: boolean | "deletable" = true;
  sortable?: boolean | "showRowNumber" = false;
  header?: FormlyFieldConfig[];
  footer?: FormlyFieldConfig[];
  newPositionLabel? = "Eintrag hinzufügen";
  deletePositionLabel?: string;
  showDividerPerItem?: boolean;
  alternateBetweenBackgrounds?: boolean;
  showNoDataNotification: boolean;
  noDataLabel = "Keine Daten vorhanden";
  noDataIcon = "list";
  addButtonSize: "large" | "small" | "default" = "small";
  height: number;
  actions: MetaButton[] = [];
}

@Pipe({
  name: "posNum",
  pure: true,
})
class posNum implements PipeTransform {
  @memo()
  public transform(value: any, args?: any): number {
    return Number(value) + 1;
  }
}

@MetaUnsubscribe()
@Component({
  selector: "meta-list-input",
  template: `
    <ng-container *ngIf="ma.height; else contentTpl">
      <div class="meta-list-input-container" [style.max-height.px]="ma.height">
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
      </div>
    </ng-container>

    <ng-template #contentTpl>
      <ng-container *ngIf="ma as ma">
        <ng-container *ngIf="field.fieldGroup?.length > 0">
          <ng-container *ngIf="ma.header?.length > 0; else outputTpl">
            <div class="report-container">
              <div class="report-header">
                <div class="report-header-cell">
                  <div class="report-header-wrapper">
                    <div *ngIf="ma.sortable" class="report-header-num">
                      <span>#</span>
                    </div>
                    <formly-form
                      [fields]="ma.header"
                      [options]="merge(options, { subFormPath: getFooterPath() })"
                    ></formly-form>
                    <div
                      class="report-header-del"
                      [style.width.px]="
                        (ma.deletable === 'inline' && editing ? 46 : 0) +
                        (hasNotification() ? 32 : 0) +
                        32 * ma.actions.length
                      "
                    ></div>
                  </div>
                </div>
              </div>
              <div *ngIf="!editing; else outputTpl" class="report-content">
                @defer (on viewport) {
                  <div
                    *ngFor="let field of field.fieldGroup; let i = index; trackBy: trackById"
                    [ngClass]="{
                      'alternate-between-backgrounds': ma.alternateBetweenBackgrounds,
                      'item-container-divider': ma.showDividerPerItem
                    }"
                    class="content-item"
                  >
                    <div *ngIf="ma.sortable === 'showRowNumber'" class="meta-list-input-sort-handler">
                      <span>{{ i + 1 }}.</span>
                    </div>
                    <div class="report-content-cell">
                      <div
                        [ngClass]="{
                          editing: editing,
                          sortable: ma.sortable
                        }"
                        class="row meta-list-input-item"
                      >
                        <formly-field class="col" [field]="field"></formly-field>
                      </div>
                    </div>
                    <div class="inline-notifications" *ngIf="hasNotification()">
                      <i
                        *ngIf="getNotification(i) as notification"
                        class="fas fa-{{ notification.icon }} text-{{ notification.color }} fa-shake"
                        style="--fa-animation-duration: 1.5s; --fa-animation-iteration-count: 5;"
                        [nzPopoverContent]="tmpPopoverContent"
                        [nzPopoverMouseEnterDelay]="1"
                        [nzPopoverMouseLeaveDelay]="1"
                        [nzPopoverArrowPointAtCenter]="true"
                        [nzPopoverOverlayClassName]="'popover-content-medium'"
                        nz-popover
                      ></i>
                      <ng-template #tmpPopoverContent>
                        @for (item of getNotification(i).items; track $index) {
                          <div class="notification-item">
                            <i class="fas fa-{{ item.icon }} text-{{ item.color }}"></i> <span>{{ item.label }}</span>
                          </div>
                        }
                      </ng-template>
                    </div>
                    <div *ngIf="ma.actions.length > 0" class="inline-actions meta-list-input-actions">
                      <ng-container *ngFor="let a of ma.actions">
                        <meta-button
                          *ngIf="!a.hide"
                          [maParams]="
                            merge(a.props, {
                              type: a.props.type,
                              size: 'small',
                              loading: actionsLoading[a.id],
                              disabled:
                                formState.displayData() &&
                                formState.displayData()[a.id] &&
                                formState.displayData()[a.id].disabledCondition !== undefined
                                  ? formState.displayData()[a.id].disabledCondition
                                  : false,
                              onClick: onActionClick.bind(this, a, i)
                            })
                          "
                        >
                        </meta-button>
                      </ng-container>
                    </div>
                  </div>
                } @placeholder {
                  <div></div>
                }
              </div>
            </div>
          </ng-container>
          <ng-template #outputTpl>
            <div [sortablejs]="field.fieldGroup" [sortablejsOptions]="sortableOptions" class="meta-list-input-wrapper">
              @defer (on viewport) {
                <div
                  *ngFor="let childField of field.fieldGroup; let i = index; trackBy: trackById"
                  [ngClass]="{
                    editing: editing,
                    sortable: ma.sortable,
                    'alternate-between-backgrounds': ma.alternateBetweenBackgrounds,
                    'item-container-divider': ma.showDividerPerItem
                  }"
                  class="row meta-list-input-item"
                >
                  <div *ngIf="ma.sortable" class="meta-list-input-sort-handler">
                    <!--i *ngIf="editing" class="fas fa-bars handler"></i-->
                    <span *ngIf="ma.sortable === 'showRowNumber'">{{ i + 1 }}.</span>
                  </div>
                  <ng-container *ngIf="!editing && getDisplayValue(i) as displayValue; else itemTpl">
                    <span class="meta-list-input-item-preview" [innerHTML]="displayValue"></span>
                  </ng-container>
                  <ng-template #itemTpl>
                    <div class="col">
                      <formly-field [field]="childField"></formly-field>
                    </div>
                  </ng-template>
                  <div class="inline-notifications" *ngIf="hasNotification()">
                    <i
                      *ngIf="getNotification(i) as notification"
                      class="fas fa-{{ notification.icon }} text-{{ notification.color }} fa-shake"
                      style="--fa-animation-duration: 1.5s; --fa-animation-iteration-count: 5;"
                      [nzPopoverContent]="tmpPopoverContent"
                      [nzPopoverMouseEnterDelay]="1"
                      [nzPopoverMouseLeaveDelay]="1"
                      [nzPopoverArrowPointAtCenter]="true"
                      [nzPopoverOverlayClassName]="'popover-content-medium'"
                      nz-popover
                    ></i>
                    <ng-template #tmpPopoverContent>
                      @for (item of getNotification(i).items; track $index) {
                        <div class="notification-item">
                          <i class="fas fa-{{ item.icon }} text-{{ item.color }}"></i> <span>{{ item.label }}</span>
                        </div>
                      }
                    </ng-template>
                  </div>
                  <div [ngClass]="{ 'inline-actions': ma.deletable === 'inline' }" class="meta-list-input-actions">
                    <ng-container *ngFor="let a of ma.actions">
                      <meta-button
                        *ngIf="!a.hide"
                        [maParams]="
                          merge(a.props, {
                            type: a.props.type,
                            size: 'small',
                            loading: actionsLoading[a.id],
                            disabled:
                              formState.displayData() &&
                              formState.displayData()[a.id] &&
                              formState.displayData()[a.id].disabledCondition !== undefined
                                ? formState.displayData()[a.id].disabledCondition
                                : false,
                            onClick: onActionClick.bind(this, a, i)
                          })
                        "
                      >
                      </meta-button>
                    </ng-container>
                    <meta-button
                      *ngIf="editing && ma.deletable"
                      [maParams]="removeParams"
                      [ngClass]="{ 'action-delete': ma.actions.length > 0 }"
                      (click)="onRemove(i)"
                    ></meta-button>
                  </div>
                </div>
              } @placeholder {
                <div></div>
              }
            </div>
          </ng-template>
          <ng-container *ngIf="ma.footer?.length > 0">
            <div class="report-container">
              <div class="report-footer">
                <div class="report-footer-cell">
                  <meta-section
                    [maParams]="{
                      sectionType: MetaSectionType.horizontalLine,
                      spacingBottom: 1,
                      spacingTop: 1
                    }"
                  ></meta-section>
                  <div class="report-footer-wrapper">
                    <div *ngIf="ma.sortable" class="report-footer-num"></div>
                    <formly-form
                      [fields]="ma.footer"
                      [options]="merge(options, { subFormPath: getFooterPath() })"
                    ></formly-form>
                    <div *ngIf="ma.deletable === 'inline' && editing" class="report-footer-del"></div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <meta-button
          *ngIf="editing && ma.editable && ma.addable && (field.fieldGroup.length > 0 || !ma.showNoDataNotification)"
          [maParams]="addParams"
          class="mt-1 mb-3"
        ></meta-button>
        <ng-container *ngIf="ma.showNoDataNotification && field.fieldGroup.length === 0">
          <ng-template #emptyAction>
            <meta-button *ngIf="editing && ma.addable" [maParams]="editAndAddParams"></meta-button>
          </ng-template>
          <meta-empty
            [maParams]="{ icon: ma.noDataIcon, description: ma.noDataLabel, action: emptyAction, iconSize: '1.5rem' }"
          ></meta-empty>
        </ng-container>
        <ng-container *ngIf="!editing && !ma.showNoDataNotification && field.fieldGroup.length === 0"></ng-container>
      </ng-container>
    </ng-template>
  `,
  styleUrls: ["./metaListInput.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaListInputComponent extends FieldArrayType implements OnInit {
  public addParams: MetaButton;
  public editAndAddParams: MetaButton;
  public removeParams: MetaButton;
  public sortableOptions: SortableOptions;
  public MetaSectionType = MetaSectionType;
  public actionsLoading = {};
  public editing: boolean = false;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly metaHelperService: MetaHelperService,
    private readonly _metaActionHandler: MetaActionHandlerFactory,
    private readonly _destroyRef: DestroyRef,
  ) {
    super();
    effect(() => {
      this.editing = this.options.formState.state ? this.options.formState.state() === 10 : true;
      this.changeDetectorRef.markForCheck();
    });
  }

  private _maParams: MetaListInput = new MetaListInput();

  @Input()
  public get maParams(): MetaListInput {
    return this._maParams;
  }

  public set maParams(value: MetaListInput) {
    this._maParams = { ...this._maParams, ...value };
  }

  get ma(): MetaListInput {
    return this.field ? { ...this.maParams, ...(this.to as MetaListInput) } : this.maParams;
  }

  public onAddClick() {
    const initalModel = {};
    const validatableComponents = this.metaHelperService.getFormlyValidatableComponents(this.field);
    validatableComponents.forEach((c) => {
      if (c.props.inputType === "number" || c.props.inputType === "currency" || c.props.inputType === "percent") {
        initalModel[c.key] = 0;
      }
      if (c.props.uniqueGroup) {
        if (this.model === null || this.model?.length === 0) {
          initalModel[c.key] = c.props.defaultValue;
        }
      } else {
        initalModel[c.key] = c.props.defaultValue;
      }
    });
    this.add(null, initalModel);
  }

  public onEditAndAddClick() {
    this.formState.state?.set(MetaState.editing);
    setTimeout(() => {
      this.onAddClick();
    }, 50);
  }

  ngOnInit() {
    this.addParams = {
      label: `+ ${this.ma.newPositionLabel}`,
      type: MetaButtonType.link,
      size: this.ma.addButtonSize,
      onClick: this.onAddClick.bind(this),
    };
    this.editAndAddParams = {
      label: `+ ${this.ma.newPositionLabel}`,
      type: MetaButtonType.link,
      size: "small",
      onClick: this.onEditAndAddClick.bind(this),
    };
    this.removeParams = {
      icon: "trash",
      iconStyle: MetaIconStyle.Solid,
      type: MetaButtonType.link,
      size: "small",
      danger: true,
    };
    this.sortableOptions = {
      onSort: this.onSort.bind(this),
      disabled: !this.ma.sortable,
      handle: ".handler",
    };
    this.formState?.displayData$?.pipe(takeUntilDestroyed(this._destroyRef), debounceTime(50)).subscribe(() => {
      (<any>this.field.options).detectChanges(this.field);
    });
  }

  public onRemove = (index: number) => {
    if (document.activeElement.tagName === "INPUT") {
      return;
    }
    this.remove(index);
  };

  public onSort = (event) => {
    this._reorder(event.oldDraggableIndex, event.newDraggableIndex);
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  };

  public trackById(index, item) {
    return item.id;
  }

  public getDisplayValue(index: number) {
    try {
      return this.ma?.displayData.values[index].displayValue;
    } catch (e) {
      return null;
    }
  }

  public getNotification(index: number) {
    try {
      return this.ma?.displayData.values[index]._notification;
    } catch (e) {
      return null;
    }
  }

  public hasNotification() {
    try {
      for (let item of this.ma?.displayData.values) {
        if (item._notification) {
          return true;
        }
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  private _reorder(oldI: number, newI: number) {
    this._reorderItems(this.model, oldI, newI);
    this._reorderItems(this.formControl.controls, oldI, newI);
    this.formControl.markAsDirty();
  }

  private _reorderItems(obj: any[], oldI: number, newI: number) {
    const f = obj.splice(oldI, 1)[0];
    obj.splice(newI, 0, f);
  }

  public merge<A, B>(a: A, b: B): A & B {
    return { ...a, ...b };
  }

  public async onActionClick(action: any, index: number) {
    if (action.disabledCondition && this.formState.displayData()[action.id]?.disabledCondition) {
      return;
    }
    this.actionsLoading[action.id] = true;
    this.changeDetectorRef.markForCheck();
    this._metaActionHandler
      .executeClickAction({
        controlId: action.id,
        ctx: this.formState.data()._ctx,
        formId: this.formState.formId,
        data: this.formState.data(),
        index: index,
        subFormPath: [...this.metaHelperService.getFormlySubFormPath(this.field), this.id],
        formIsValid: this.form.valid,
        passthroughData: { formId: this.formState.formId },
        isEdit:
          this.options.formState.state() >= MetaState.editing && this.options.formState.state() <= MetaState.saving,
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        this.actionsLoading[action.id] = false;
        this.changeDetectorRef.markForCheck();
      });
    this.actionsLoading[action.id] = false;
  }

  public getFooterPath() {
    return this.metaHelperService.getFormlySubFormPath(this.field);
  }

  protected readonly MetaFormDataState = MetaState;
}

@NgModule({
  declarations: [MetaListInputComponent, posNum],
  imports: [
    CommonModule,
    FormlyModule,
    ReactiveFormsModule,
    MetaButtonModule,
    FormsModule,
    SortablejsModule,
    DragDropModule,
    MetaSectionModule,
    MetaEmptyModule,
    NzPopoverModule,
  ],
  exports: [MetaListInputComponent],
})
export class MetaListInputModule {}
