/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Pipe,
  PipeTransform,
  runInInjectionContext,
  Signal,
  signal,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MetaMediaLibraryService } from "./metaMediaLibrary.service";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom, takeUntil } from "rxjs";
import { NzModalRef } from "ng-zorro-antd/modal";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, filter } from "rxjs/operators";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { EntityType } from "@meta/enums";
import { FileAssignementRequest } from "@meta/api-interfaces";
import { IMetaMediaLibraryFile } from "./interfaces";
import { NzContextMenuService, NzDropdownMenuComponent } from "ng-zorro-antd/dropdown";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { truncate } from "lodash";
import { MetaMediaLibraryFileInfoComponent } from "./metaMediaLibraryFileInfo.component";

@Pipe({ name: "getFileIcon" })
export class GetFileIcon implements PipeTransform {
  constructor(private readonly metaMediaLibraryService: MetaMediaLibraryService) {}

  transform(file: IMetaMediaLibraryFile | string): string {
    if (typeof file === "string") {
      return `fa-${this.metaMediaLibraryService.getIcon({
        mime: file,
        name: file,
        type: "document",
      })}`;
    } else {
      return `fa-${this.metaMediaLibraryService.getIcon(file)}`;
    }
  }
}

export class MetaMediaLibraryParams {
  entityType?: EntityType;
}

export interface IHistory {
  id: string;
  name: string;
}

export interface IFileOperation {
  items: IMetaMediaLibraryFile[];
  type: "copy" | "move" | "delete";
  collection?: string;
}

interface IUploadProgress {
  percentTotal: number;
  message: string;
  filesTotal: number;
  filesDone: number;
}

@Component({
  selector: "meta-media-library",
  templateUrl: "./metaMediaLibrary.component.html",
  styleUrls: ["./metaMediaLibrary.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaMediaLibraryComponent extends MetaComponentBase implements OnInit, OnChanges {
  public selectedFiles: IMetaMediaLibraryFile[] = [];
  public selectedFileIds: string[] = [];
  public focusedFile: IMetaMediaLibraryFile;
  public focusedFileInfoData: IMetaMediaLibraryFile;

  public focusedFileInfoDataLoading = false;
  public showMode: "selection" | "search" = "search";
  public initialType: string;
  public allowTypeChange = true;

  public files: IMetaMediaLibraryFile[] = [];
  public isMultiselect = false;
  public isSelect = true;
  public filterForm = new UntypedFormGroup({
    type: new UntypedFormControl(null),
    query: new UntypedFormControl(""),
    sort: new UntypedFormControl("date"),
    dir: new UntypedFormControl("asc"),
  });

  public entityType: EntityType;
  public entityId: any;

  public fileOperation: IFileOperation;

  public history: IHistory[] = [];

  public total = 1;
  public name: string;

  public uploadProgress = signal<IUploadProgress>(null);
  @ViewChild("uploadProgressTemplate")
  public uploadProgressTemplate: TemplateRef<any>;

  @ViewChild("fileInfoComponent", {
    static: false,
  })
  public fileInfoComponent?: MetaMediaLibraryFileInfoComponent;

  public mimeFilters = [
    { label: "Bilder", value: JSON.stringify("image%"), key: "image" },
    { label: "Videos", value: JSON.stringify("video%"), key: "video" },
    { label: "Audio", value: JSON.stringify("audio%"), key: "audio" },
    { label: "Pdf", value: JSON.stringify("application/pdf"), key: "audio" },
    {
      label: "Dokument",
      value: [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.text",
        "application/vnd.oasis.opendocument.spreadsheet",
        "application/vnd.oasis.opendocument.presentation",
        "application/mspowerpoint",
        "application/msword",
      ],
      key: "document",
    },
  ];
  public take = 1000;
  @Input()
  public showInfo = true;
  public isFormly = false;
  public tableView = false;
  public collection: string;
  public readonly zoom = signal(1.5);

  public readonly itemSize: Signal<number>;

  @Input()
  public path: string[] = [];

  @Output()
  public pathChange = new EventEmitter<string[]>();

  get ma(): MetaMediaLibraryParams {
    return super.ma;
  }
  constructor(
    private readonly httpClient: HttpClient,
    @Optional()
    public readonly modalRef: NzModalRef,
    private readonly metaMediaLibraryService: MetaMediaLibraryService,
    private readonly nzContextMenuService: NzContextMenuService,
    private readonly notificationService: NzNotificationService,
  ) {
    super();
    super.maParams = new MetaMediaLibraryParams();
    this.setView(localStorage.getItem("media-library.view") as any);
    this.itemSize = computed(() => {
      return Math.ceil(this.zoom() * 65);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["path"]) {
      const path: string[] = changes["path"].currentValue || [];
      if (path.length > 0) {
        this.goToCollection(path[path.length - 1], false);
      }
    }
  }

  public setView(view: "grid" | "table") {
    localStorage.setItem("media-library.view", view || "grid");
    this.tableView = view === "table";
  }

  public getFilterClass(name: string) {
    if (this.filterForm.get("sort").value === name) {
      return this.filterForm.get("dir").value;
    }
    return "";
  }

  public copySelection(items: IMetaMediaLibraryFile[]) {
    this.fileOperation = {
      items: [...items],
      type: "copy",
      collection: this.collection,
    };
  }

  public cutSelection(items: IMetaMediaLibraryFile[]) {
    this.fileOperation = {
      items: [...items],
      type: "move",
      collection: this.collection,
    };
  }

  public async paste() {
    if (!this.fileOperation) {
      return;
    }
    if (this.fileOperation.type === "copy" && this.collection !== this.fileOperation.collection) {
      await this.metaMediaLibraryService.copyItem(this.fileOperation.items, this.collection);
    } else if (this.fileOperation.type === "move" && this.collection !== this.fileOperation.collection) {
      await this.metaMediaLibraryService.moveItems(
        this.fileOperation.items,
        this.fileOperation.collection,
        this.collection,
      );
    }
    this.fileOperation = null;
    await this.loadData(0);
  }

  public contextMenu($event: MouseEvent, menu: NzDropdownMenuComponent, file: IMetaMediaLibraryFile) {
    this.nzContextMenuService.create($event, menu);
    $event.stopPropagation();
  }

  public async loadData(skip = 0) {
    this.isLoading = true;
    this.changeDetectorRef.markForCheck();
    const type = this.filterForm.get("type").value;
    const { data, total, name } = await firstValueFrom<any>(
      this.httpClient.get(`media-library/find`, {
        params: {
          ...Object.fromEntries(Object.entries(this.filterForm.value).filter((e) => !!e[1])),
          take: this.take,
          skip: skip,
          ...(this.collection ? { collection: this.collection } : {}),
          ...(type ? { type: Array.isArray(type) ? JSON.stringify(type) : String(type) } : {}),
          ...(this.entityType ? { entityType: this.entityType, entityId: this.entityId } : {}),
        },
      }),
    );
    this.name = name;
    this.total = total;
    if (skip === 0) {
      this.files = data;
      this.focusFile(this.focusedFile ? this.files.find((f) => f.id === this.focusedFile.id) : null);
    } else {
      this.files.push(...data);
    }
    this.isLoading = false;
    this.changeDetectorRef.markForCheck();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isLoading = true;
    if (this.field && this.ma.entityType) {
      this.initFormly();
    } else {
      this.collection = this.path?.[this.path.length - 1] || "root";
      this.initStandalone();
    }
  }

  public initFormly() {
    this.showInfo = false;
    this.isFormly = true;
    this.isSelect = false;

    runInInjectionContext(this.inj, () => {
      effect(() => {
        const form = this.formState.form();
        const itemId = this.formState.itemId();
        if (itemId) {
          this.entityId = itemId;
          this.entityType = this.ma.entityType || form.entityType;
          this.init();
        }
      });
    });
  }

  public initStandalone() {
    if (this.initialType) {
      const v = this.mimeFilters.find((f) => f.key === this.initialType);
      if (v) {
        this.allowTypeChange = false;
        this.filterForm.get("type").setValue(v.value, { emitEvent: false });
      }
    }
    if (this.modalRef) {
      if (this.isSelect) {
        this.modalRef.updateConfig({
          nzOkDisabled: true,
          nzOkText: "Auswahl übernehmen",
        });
      } else {
        this.modalRef.updateConfig({
          nzOkText: "Schließen",
          nzOkDisabled: false,
          nzCancelText: null,
        });
      }
    }
    this.init();
  }

  public init() {
    this.filterForm.valueChanges.pipe(debounceTime(350)).subscribe(() => this.loadData(0));
    this.loadData();
  }

  handleImageError($event: ErrorEvent) {
    const image = $event.target as HTMLImageElement;
    image.classList.add("error");
  }

  public focusFile(file: IMetaMediaLibraryFile) {
    if (this.focusedFile?.id === file?.id) return;
    this.focusedFile = file;
    this.focusedFileInfoData = null;
    this.focusedFileInfoDataLoading = true;
    if (file) {
      this.showInfo = true;
      if (file.type !== "collection") {
        this.loadFileInfo(file).then((info) => {
          if (this.focusedFile === file) {
            this.focusedFileInfoData = info;
            this.focusedFileInfoDataLoading = false;
            this.changeDetectorRef.markForCheck();
          }
        });
      }
    }
  }

  public fileClick(file: IMetaMediaLibraryFile, $event: MouseEvent) {
    if (file.type === "collection" && this.isSelect) {
      this.selectedFileIds = [file.id];
      this.selectedFiles = [];
      this.focusFile(file);
      return;
    }

    if ($event.shiftKey) {
      const endIndex = this.files.indexOf(file);
      const lastFile = this.selectedFiles[this.selectedFiles.length - 1];
      const startIndex = lastFile ? this.files.indexOf(lastFile) : 0;

      this.selectedFiles.push(...this.files.slice(startIndex, endIndex + 1));
      this.selectedFiles = Array.from(new Set(this.selectedFiles));
      this.selectedFileIds = this.selectedFiles.map((e) => e.id);
      return;
    }

    if (this.isSelect || $event.metaKey) {
      if (this.isMultiselect || $event.metaKey) {
        const index = this.selectedFiles.findIndex((e) => e.id === file.id);
        if (index === -1) {
          this.focusFile(file);
          this.selectedFiles.push(file);
        } else {
          this.selectedFiles.splice(index, 1);
          this.focusedFile = this.selectedFiles.length > 0 ? this.selectedFiles[0] : null;
        }
      } else {
        this.focusFile(file);
        this.selectedFiles = [file];
      }
      this.selectedFileIds = this.selectedFiles.map((e) => e.id);
      this.updateModalActions();
    } else {
      this.selectedFiles = [file];
      this.selectedFileIds = [file.id];
      this.focusFile(file);
    }
  }

  updateModalActions() {
    if (this.modalRef && this.isSelect) {
      this.modalRef.updateConfig({
        nzOkDisabled: this.selectedFiles.length === 0,
        nzOkText: `Auswahl übernehmen${this.isMultiselect ? ` (${this.selectedFiles.length})` : ""}`,
      });
    }
  }

  download(files: IMetaMediaLibraryFile[]) {
    if (files.length === 1) {
      window.open(`/api/v2/file/${files[0].id}/download`, "_blank");
    } else {
      window.open(
        `/api/v2/download/zip?${new URLSearchParams({
          ids: JSON.stringify(files.map((f) => f.id)),
        })}`,
        "_blank",
      );
    }
  }

  async delete(focusedFile: IMetaMediaLibraryFile[]) {
    if (focusedFile.length === 1) {
      await this.metaMediaLibraryService.deleteFile(focusedFile[0]);
    } else {
      if (confirm(`Möchtest du wirklich ${focusedFile.length} Dateien löschen.`)) {
        for (let file of focusedFile) {
          await firstValueFrom(this.httpClient.delete("file/" + file.id));
        }
        await this.loadData(0);
      }
    }
  }

  share(focusedFile: IMetaMediaLibraryFile[]) {
    this.metaMediaLibraryService.shareFile(focusedFile);
  }

  upload() {
    if (this.isFormly) {
      this.metaMediaLibraryService
        .selectMultipleFiles({})
        .then(async (files) => {
          for (const file of files) {
            await this.assign(file);
          }
          return files;
        })
        .then((files) => {
          for (const file of files) {
            const index = this.files.findIndex((f) => f.id === file.id);
            if (index === -1) {
              this.files.unshift(file);
            }
          }
          return this.loadData(0);
        });
    } else {
      this.metaMediaLibraryService.uploadMultipleFiles({ collection: this.collection }).then((files) => {
        if (Array.isArray(this.files)) {
          this.files.unshift(...files);
          this.changeDetectorRef.markForCheck();
        }
        return this.loadData(0);
      });
    }
  }

  openPreview(file: IMetaMediaLibraryFile) {
    this.fileInfoComponent?.stop();
    if (file.type === "collection") {
      this.history.push({
        id: this.collection,
        name: this.name,
      });
      this.goToCollection(file.id);
      return;
    }
    if (this.focusedFileInfoData?.id === file.id) {
      this.metaMediaLibraryService.previewFile(this.focusedFileInfoData);
    } else {
      this.metaMediaLibraryService.previewFileById(file.id);
    }
  }

  public async assign(file: IMetaMediaLibraryFile) {
    const request: FileAssignementRequest = {
      targetId: this.entityId,
      targetType: this.entityType,
      formId: this.formState.form()?.id,
    };
    await firstValueFrom(this.httpClient.post(`file/${file.id}/assign/${this.entityId}`, request));
    return file;
  }

  public async unassigne(file: IMetaMediaLibraryFile) {
    await firstValueFrom(
      this.httpClient.post(`file/${file.id}/unassign/${this.entityId}`, {
        entityType: this.entityType,
      }),
    );
    const index = this.files.findIndex((f) => f.id === file.id);
    if (index !== -1) {
      this.files.splice(index, 1);
    }
    if (this.files.length > 0) {
      this.focusFile(this.files[0]);
      this.selectedFiles = [this.files[0]];
      this.selectedFileIds[this.files[0].id];
    } else {
      this.focusFile(null);
      this.selectedFiles = [];
      this.selectedFileIds = [];
    }
    this.changeDetectorRef.markForCheck();
  }

  public trackById(index: number, file: IMetaMediaLibraryFile) {
    return file.id;
  }

  fileDeleted(file: IMetaMediaLibraryFile) {
    /*alert("OK");
    {
      const index = this.files.findIndex((f) => f.id === file.id);
      if (index !== -1) {
        this.files.splice(index, 1);
      }
    }
    {
      const index = this.selectedFileIds.indexOf(file.id);
      if (index !== -1) {
        this.selectedFileIds.splice(index, 1);
      }
    }
    {
      const index = this.selectedFiles.findIndex((f) => f.id === file.id);
      if (index !== -1) {
        this.selectedFiles.splice(index, 1);
      }
    }
    if (this.focusedFile?.id === file.id) {
      this.focusFile(null);
    }*/
    this.focusFile(null);
    this.updateModalActions();
    this.loadData(0).catch(() => {});
  }

  goToCollection(id: string, updatePath = true) {
    this.collection = id;
    this.selectedFileIds = [];
    this.selectedFiles = [];
    this.loadData(0).then(() => {
      this.changeDetectorRef.markForCheck();
    });
    if (updatePath) {
      this.path = [id];
      this.pathChange.next(this.path);
    }
  }

  home() {
    this.history = [];
    this.goToCollection("root");
  }

  back() {
    const h = this.history.pop();
    this.name = h.name;
    this.goToCollection(h.id);
  }

  createCollection() {
    const name = prompt("Sammlung Name", "neue sammlung");
    if (name) {
      this.metaMediaLibraryService.createCollection(name, this.collection).then(() => {
        return this.loadData(0);
      });
    }
  }

  editFile(file: IMetaMediaLibraryFile) {
    this.metaMediaLibraryService.editFile(file).then(() => {
      return this.loadData(0);
    });
  }

  backToIndex(i: number) {
    const res = this.history.splice(i);
    this.history = [...this.history];
    this.name = res[0]["name"];
    this.goToCollection(res[0]["id"]);
  }

  edit(file: IMetaMediaLibraryFile) {
    if (file.type === "collection") {
      this.metaMediaLibraryService.editCollection(file);
    } else {
      this.editFile(file);
    }
  }

  public changeSort(sort: string) {
    this.filterForm.get("sort").setValue(sort);
    const c = this.filterForm.get("dir");
    c.setValue(c.value === "asc" ? "desc" : "asc");
  }

  public async dropFiles($event: DragEvent) {
    $event.preventDefault();
    if ($event.dataTransfer?.items) {
      const ref = this.notificationService.template(this.uploadProgressTemplate, {
        nzDuration: null,
        nzCloseIcon: null,
        nzPlacement: "bottom",
        nzAnimate: true,
      });
      const files: File[] = [];
      //const files = Array.from($event.dataTransfer.items);

      for (let item of Array.from($event.dataTransfer.items)) {
        if (item.webkitGetAsEntry) {
          const entry = item.webkitGetAsEntry();
          if (entry.isFile) {
            files.push(item.getAsFile());
          } else if (entry.isDirectory) {
            const getNested = async (n: FileSystemEntry) => {
              const nestedEntries = await new Promise<FileSystemEntry[]>((resolve) => {
                const directoryReader = n["createReader"]();
                directoryReader.readEntries((entries: FileSystemEntry[]) => resolve(entries));
              });
              for (let e of nestedEntries) {
                if (e.isFile) {
                  files.push(
                    await new Promise((r) =>
                      e["file"]((file: File) => {
                        file["__fullPath"] = e.fullPath;
                        r(file);
                      }),
                    ),
                  );
                } else if (e.isDirectory) {
                  await getNested(e);
                }
              }
            };
            await getNested(entry);
          }
        } else {
          files.push(item.getAsFile());
        }
      }
      const totalSize = files.reduce((acc, v) => acc + v.size, 0);
      let totalUploadedSize = 0;
      for (let f of files) {
        console.log(f);

        const message = `"${truncate(f.name, {
          length: 64,
        })}" wird hochgeladen…`;
        this.uploadProgress.set({
          message,
          percentTotal: Math.ceil((100 / totalSize) * totalUploadedSize),
          filesTotal: files.length,
          filesDone: files.indexOf(f) + 1,
        });
        const [file] = await this.metaMediaLibraryService.directUploadFiles(
          [f],
          this.collection,
          (percent, uploadedBytes) => {
            this.uploadProgress.update((v) => {
              return {
                ...v,
                percentTotal: Math.ceil((100 / totalSize) * (totalUploadedSize + uploadedBytes)),
              };
            });
          },
        );
        totalUploadedSize += f.size;
        if (this.entityType) {
          await this.assign(file);
        }
        await this.loadData(0);
      }
      this.notificationService.remove(ref.messageId);
    }
  }

  public dragOver($event: DragEvent) {
    // Prevent default behavior (Prevent file from being opened)
    $event.preventDefault();
  }

  private async loadFileInfo(file: IMetaMediaLibraryFile) {
    return await firstValueFrom<any>(
      this.httpClient.get(`media-library/file-info/${file.id}`, {
        params: {
          additional: true,
        },
      }),
    );
  }

  public clearSelection() {
    this.selectedFileIds = [];
    this.selectedFiles = [];
    this.focusFile(null);
    this.changeDetectorRef.markForCheck();
  }
}
