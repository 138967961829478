/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponentInfo } from "./baseComponent";
import { UploadComponent } from "./uploadComponent";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-files",
  type: "files",
  name: "File-view",
  icon: "file-pdf",
})
export class FilesComponent<PrimaryDataSource = any> extends UploadComponent<PrimaryDataSource> {
  public static create<T>(dataSource: T, opts: Partial<FilesComponent<T>>) {
    const input = new FilesComponent<T>();
    input.setOptions(opts as any);
    return input;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {},
    });
  }
}
