<!--
  ~ Copyright (C) MetaCarp GmbH - All Rights Reserved
  ~ Unauthorized copying of this file, via any medium is strictly prohibited
  ~ Proprietary and confidential
  ~ Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
  ~ Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
  -->

<ng-template #actionTemplate>
  <meta-button
    [maParams]="{label: 'Bilder hinzufügen'}"
    (click)="addImage()"
  ></meta-button>
</ng-template>
<ng-container *ngIf="ma.showDummy">
  <ng-container *ngTemplateOutlet="preview; context: {$implicit: dummyFiles, dummy: true}"></ng-container>
</ng-container>
<ng-container *ngIf="field && formState?.form()">
  <nz-form-item [formGroup]="form">
    <ng-container *ngIf="editing && formControl.value as files">
      <div *ngIf="files?.length > 0" class="image-gallery" [sortablejsOptions]="sortablejsOptions" [sortablejs]="files">
        <div (dblclick)="imageClick(image)" class="image-gallery-box" *ngFor="let image of files;  let i = index">
          <div title="Primäres Bild" *ngIf="i === 0" class="feature-image">
            <i class="fas fa-crown"></i>
          </div>
          <img src="/api/v2/file/{{image}}/download?width=300&height=300&fit=inside" />
          <div nz-popconfirm
               nzPopconfirmTitle="Sind Sie sicher, dass Sie dieses Bild löschen wollen?"
               nzPopconfirmPlacement="bottom"
               (nzOnConfirm)="removeImage(i)"
               role="button" class="remove-image">
            <i class="fal fa-times"></i>
          </div>
        </div>

      </div>
      <meta-button
        *ngIf="files?.length > 0"
        class="add-new-image"
        [maParams]="{label: 'Bilder hinzufügen', icon: 'image'}"
        (click)="addImage()"
      ></meta-button>

      <ng-container *ngIf="!files?.length">
        <meta-empty [maParams]="{ description: 'Keine Bilder', icon: 'images', action: actionTemplate}"></meta-empty>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!editing && formControl.value as files">
      <ng-container *ngIf="!files?.length">
        <meta-empty [maParams]="{ description: 'Keine Bilder', icon: 'images'}"></meta-empty>
      </ng-container>
      <ng-container *ngIf="files?.length > 0">
        <ng-container *ngTemplateOutlet="preview; context: {$implicit: files}"></ng-container>
      </ng-container>
    </ng-container>
  </nz-form-item>

</ng-container>

<ng-template #preview let-dummy="dummy" let-files>
  <div class="image-preview">
    <img *ngIf="!dummy" (click)="imageClick(files[selectedImage])" importance="high" src="/api/v2/file/{{files[selectedImage]}}/download">
    <img *ngIf="dummy"  importance="high" src="{{dummyFiles[selectedImage]}}">

  </div>
  <div class="carousel-container">
    <div class="carousel">

      <div class="carousel-navigate left" (click)="scroll('left')">
        <i class="fas fa-chevron-left"></i>
      </div>
      <ng-container *ngFor="let image of files;  let i = index">
        <img *ngIf="!dummy" (dblclick)="imageClick(image)" [ngClass]="{selected: selectedImage === i}" importance="{{(selectedImage === image) ? 'high' : 'low'}}" class="carousel-item item" loading="lazy"
             src="/api/v2/file/{{image}}/download?width=200&height=200&fit=inside"
             (click)="selectedImage = i">
        <img *ngIf="dummy" [ngClass]="{selected: selectedImage === i}" importance="{{(selectedImage === image) ? 'high' : 'low'}}" class="carousel-item item" loading="lazy"
             src="{{image}}">
      </ng-container>
      <div class="carousel-navigate right" (click)="scroll('right')">
        <i class="fas fa-chevron-right"></i>
      </div>
    </div>
  </div>
</ng-template>
