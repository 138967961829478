/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { OverlayModule } from "@angular/cdk/overlay";
import { registerLocaleData } from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
  withJsonpSupport,
} from "@angular/common/http";
import de from "@angular/common/locales/de";
import { APP_INITIALIZER, ErrorHandler, inject, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { MetaImageGalleryComponent, MetaLayoutComponent, MetaTreeviewComponent } from "@meta/ui";
import { SortablejsModule } from "@moddi3/ngx-sortablejs";
import { InputMaskModule } from "@ngneat/input-mask";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyNgZorroAntdModule } from "@ngx-formly/ng-zorro-antd";
import * as Sentry from "@sentry/angular-ivy";

import type { TolgeeOptions } from "@tolgee/core/src/types";

import { BackendFetch, NgxTolgeeModule, Tolgee, TOLGEE_INSTANCE } from "@tolgee/ngx";
import { InContextTools } from "@tolgee/web/tools";
import { FusionChartsModule } from "angular-fusioncharts";
import { GridsterModule } from "angular2gridster";
import { de as deFns } from "date-fns/locale";
import { Settings as LuxonSettings } from "luxon";
import * as moment from "moment";

import { NZ_CONFIG, NzConfig } from "ng-zorro-antd/core/config";
import { de_DE, NZ_DATE_LOCALE, NZ_I18N } from "ng-zorro-antd/i18n";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalService } from "ng-zorro-antd/modal";
import { NzNotificationModule } from "ng-zorro-antd/notification";
import { SocketIoConfig, SocketIoModule } from "ngx-socket-io";
import { v4 as uuidV4 } from "uuid";
import { CloseFormActionHandler } from "../../../../libs/ui/src/lib/base/metaForm/actions/closeForm.action";
import { ConfirmActionHandler } from "../../../../libs/ui/src/lib/base/metaForm/actions/confirm.action";
import { DownloadActionHandler } from "../../../../libs/ui/src/lib/base/metaForm/actions/download.action";
import { OpenFormActionHandler } from "../../../../libs/ui/src/lib/base/metaForm/actions/openForm.action";
import { RedirectActionHandler } from "../../../../libs/ui/src/lib/base/metaForm/actions/redirect.action";
import { RemoveItemActionHandler } from "../../../../libs/ui/src/lib/base/metaForm/actions/removeItem.action";
import { SetFilterActionHandler } from "../../../../libs/ui/src/lib/base/metaForm/actions/setFilter.action";
import { SetParamsActionHandler } from "../../../../libs/ui/src/lib/base/metaForm/actions/setParams.action";
import { UploadActionHandler } from "../../../../libs/ui/src/lib/base/metaForm/actions/upload.action";
import { MetaFormService } from "../../../../libs/ui/src/lib/base/metaForm/metaForm.service";
import { FormSupervisorService } from "../../../../libs/ui/src/lib/base/metaForm/metaForm.supervisor";
import { MetaActivityComponent } from "../../../../libs/ui/src/lib/components/metaActivity/metaActivity.component";
import { MetaAddressComponent } from "../../../../libs/ui/src/lib/components/metaAddress/metaAddress.component";
import { MetaAutocompleteComponent } from "../../../../libs/ui/src/lib/components/metaAutocomplete/metaAutocomplete.component";
import { MetaBarcodeComponent } from "../../../../libs/ui/src/lib/components/metaBarcode/metaBarcode.component";
import { MetaButtonComponent } from "../../../../libs/ui/src/lib/components/metaButton/metaButton.component";
import { MetaCalculationComponent } from "../../../../libs/ui/src/lib/components/metaCalculation/metaCalculation.component";
import { MetaCalculationTemplatesComponent } from "../../../../libs/ui/src/lib/components/metaCalculation/metaCalculationTemplates.component";
import { MetaCardComponent } from "../../../../libs/ui/src/lib/components/metaCard/metaCard.component";
import { MetaChartComponent } from "../../../../libs/ui/src/lib/components/metaChart/metaChart.component";
import { MetaCheckboxComponent } from "../../../../libs/ui/src/lib/components/metaCheckbox/metaCheckbox.component";
import { MetaCollapseComponent } from "../../../../libs/ui/src/lib/components/metaCollapse/metaCollapse.component";
import { MetaColumnComponent } from "../../../../libs/ui/src/lib/components/metaColumn/metaColumn.component";
import { MetaContainerComponent } from "../../../../libs/ui/src/lib/components/metaContainer/metaContainer.component";
import { MetaCronComponent } from "../../../../libs/ui/src/lib/components/metaCron/metaCron.component";
import { MetaDatepickerComponent } from "../../../../libs/ui/src/lib/components/metaDatepicker/metaDatepicker.component";
import { MetaDiagramComponent } from "../../../../libs/ui/src/lib/components/metaDiagram/metaDiagram.component";
import { MetaDocumentViewComponent } from "../../../../libs/ui/src/lib/components/metaDocumentView/metaDocumentView.component";
import { MetaEditorComponent } from "../../../../libs/ui/src/lib/components/metaEditor/metaEditor.component";
import { MetaFieldWrapperComponent } from "../../../../libs/ui/src/lib/components/metaFieldWrapper/metaFieldWrapper.component";
import { MetaFilesComponent } from "../../../../libs/ui/src/lib/components/metaFiles/metaFiles.component";
import { MetaGanttComponent } from "../../../../libs/ui/src/lib/components/metaGantt/metaGantt.component";
import { MetaGroupComponent } from "../../../../libs/ui/src/lib/components/metaGroup/metaGroup.component";
import { MetaIconComponent } from "../../../../libs/ui/src/lib/components/metaIcon/metaIcon.component";
import { MetaImageComponent } from "../../../../libs/ui/src/lib/components/metaImage/metaImage.component";
import { MetaInfoboxComponent } from "../../../../libs/ui/src/lib/components/metaInfobox/metaInfobox.component";
import { MetaInputComponent } from "../../../../libs/ui/src/lib/components/metaInput/metaInput.component";
import { MetaLayoutService } from "../../../../libs/ui/src/lib/components/metaLayout/metaLayout.service";
import { MetaListComponent } from "../../../../libs/ui/src/lib/components/metaList/metaList.component";
import { MetaListInputComponent } from "../../../../libs/ui/src/lib/components/metaListInput/metaListInput.component";
import { MetaMapComponent } from "../../../../libs/ui/src/lib/components/metaMap/metaMap.component";
import { MetaMediaLibraryComponent } from "../../../../libs/ui/src/lib/components/metaMediaLibrary/metaMediaLibrary.component";
import { MetaPageReferenceComponent } from "../../../../libs/ui/src/lib/components/metaPageReference/metaPageReference.component";
import { MetaProgressComponent } from "../../../../libs/ui/src/lib/components/metaProgress/metaProgress.component";
import { MetaRadioComponent } from "../../../../libs/ui/src/lib/components/metaRadio/metaRadio.component";
import { MetaRowComponent } from "../../../../libs/ui/src/lib/components/metaRow/metaRow.component";
import { MetaSchedulerComponent } from "../../../../libs/ui/src/lib/components/metaScheduler/metaScheduler.component";
import { MetaSearchComponent } from "../../../../libs/ui/src/lib/components/metaSearch/metaSearch.component";
import { MetaSectionComponent } from "../../../../libs/ui/src/lib/components/metaSection/metaSection.component";
import { MetaSelectComponent } from "../../../../libs/ui/src/lib/components/metaSelect/metaSelect.component";
import { MetaSliderComponent } from "../../../../libs/ui/src/lib/components/metaSlider/metaSlider.component";
import { MetaSpecificationsComponent } from "../../../../libs/ui/src/lib/components/metaSpecifications/metaSpecifications.component";
import { MetaSplitViewComponent } from "../../../../libs/ui/src/lib/components/metaSplitView/metaSplitView.component";
import { MetaStatisticComponent } from "../../../../libs/ui/src/lib/components/metaStatistic/metaStatistic.component";
import { MetaSubformComponent } from "../../../../libs/ui/src/lib/components/metaSubform/metaSubform.component";
import { MetaSwitchComponent } from "../../../../libs/ui/src/lib/components/metaSwitch/metaSwitch.component";
import { MetatableComponent } from "../../../../libs/ui/src/lib/components/metaTable/metaTable.component";
import { MetaTabsComponent } from "../../../../libs/ui/src/lib/components/metaTabs/metaTabs.component";
import { MetaTaskFormFormlyComponent } from "../../../../libs/ui/src/lib/components/metaTask/metaTask.form.formly";
import { MetaTaskListFormlyComponent } from "../../../../libs/ui/src/lib/components/metaTask/metaTask.list.formly";
import { MetaTextareaComponent } from "../../../../libs/ui/src/lib/components/metaTextarea/metaTextarea.component";
import { MetaTooltipComponent } from "../../../../libs/ui/src/lib/components/metaTooltip/metaTooltip.component";
import { MetaUploadComponent } from "../../../../libs/ui/src/lib/components/metaUpload/metaUpload.component";
import { MetaWebViewComponent } from "../../../../libs/ui/src/lib/components/metaWebView/metaWebView.component";
import { MetaErrorHandlerService } from "../../../../libs/ui/src/lib/services/metaErrorHandler.service";
import { MetaEventService } from "../../../../libs/ui/src/lib/services/metaEvents.service";
import { MetaHelperService } from "../../../../libs/ui/src/lib/services/metaHelper.service";

import { UserNameFormatter } from "../../../../libs/ui/src/lib/util/formatters";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { APIInterceptor } from "./interceptors/apiInterceptor";
import { HubService } from "./pages/hub/hub.service";
import { NotificationModule } from "./pages/notification/notification.module";
import { SearchModule } from "./pages/search/search.module";
import { AppConfigService } from "./services/app-config.service";
import { MetaDeepLinkingService } from "./services/meta-deep-linking.service";
import { FormatNested } from "./tolgeeNested";

import { initializeValidators, validationMessages, validators } from "./validators";

window["bid"] = uuidV4();

const config: SocketIoConfig = {
  url: window.location.origin,
  options: {
    query: {
      bid: window["bid"],
    },
  },
};
const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
export const PAGE_TITLE = isStandalone ? "" : " | Argon";

export enum BREAKPOINT_MEDIA_QUERY {
  xs = "(max-width: 575px)",
  sm = "(min-width: 576px) and (max-width: 767px)",
  md = "(min-width: 768px) and (max-width: 991px)",
  lg = "(min-width: 992px) and (max-width: 1199px)",
  xl = "(min-width: 1200px) and (max-width: 1599px)",
  xxl = "(min-width: 1600px)",
}

export enum BREAKPOINT {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  xxl = "xxl",
}

registerLocaleData(de);

moment.updateLocale("de", {
  relativeTime: {
    future: "In %s",
    past: "Vor %s",
    s: "einigen Sekunden",
    ss: "%d Sekunden",
    m: "einer Minute",
    mm: "%d Minuten",
    h: "einer Stunde",
    hh: "%d Stunden",
    d: "einem Tag",
    dd: "%d Tagen",
    w: "einer Woche",
    M: "einem Monat",
    MM: "%d Monaten",
    y: "einem Jahr",
    yy: "%d Jahren",
  },
});

const ngZorroConfig: NzConfig = {
  message: { nzDuration: 3000, nzAnimate: true },
  notification: { nzPlacement: "bottomRight" },
};

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    GridsterModule.forRoot(),
    NzNotificationModule,
    InputMaskModule,
    OverlayModule,
    SearchModule,
    SortablejsModule.forRoot({ animation: 150 }),
    SocketIoModule.forRoot(config),
    FormlyModule.forRoot({
      types: [
        {
          name: "meta-input",
          component: MetaInputComponent,
          defaultOptions: {
            defaultValue: null,
          },
        },
        {
          name: "meta-button",
          component: MetaButtonComponent,
        },
        {
          name: "meta-dropdown",
          component: MetaButtonComponent,
        },
        { name: "meta-list-input", component: MetaListInputComponent },
        { name: "meta-infobox", component: MetaInfoboxComponent },
        { name: "meta-select", component: MetaSelectComponent },
        {
          name: "meta-multiselect",
          component: MetaSelectComponent,
          defaultOptions: {
            props: {
              mode: "multiple",
              returnType: "object",
            },
          },
        },
        { name: "meta-icon", component: MetaIconComponent },
        { name: "meta-section", component: MetaSectionComponent },
        { name: "meta-switcher", component: MetaSwitchComponent },
        { name: "meta-radio", component: MetaRadioComponent },
        { name: "meta-textarea", component: MetaTextareaComponent },
        { name: "meta-checkbox", component: MetaCheckboxComponent },
        { name: "meta-progress", component: MetaProgressComponent },
        { name: "meta-map", component: MetaMapComponent },
        { name: "meta-heatmap", component: MetaMapComponent },
        { name: "meta-statistic", component: MetaStatisticComponent },
        { name: "meta-singleChart", component: MetaChartComponent },
        { name: "meta-multiChart", component: MetaChartComponent },
        { name: "meta-gaugeChart", component: MetaChartComponent },
        { name: "meta-mapChart", component: MetaChartComponent },
        { name: "meta-datepicker", component: MetaDatepickerComponent },
        { name: "meta-autocomplete", component: MetaAutocompleteComponent },
        { name: "meta-address", component: MetaAddressComponent },
        { name: "meta-document-view", component: MetaDocumentViewComponent },
        { name: "meta-list", component: MetaListComponent },
        { name: "meta-editor", component: MetaEditorComponent },
        { name: "meta-split-view", component: MetaSplitViewComponent },
        { name: "meta-table", component: MetatableComponent },
        { name: "meta-activity", component: MetaActivityComponent },
        { name: "meta-barcode", component: MetaBarcodeComponent },
        { name: "meta-search", component: MetaSearchComponent },
        { name: "meta-image-gallery", component: MetaImageGalleryComponent },
        { name: "meta-document", component: MetaMediaLibraryComponent },
        { name: "meta-image", component: MetaImageComponent },
        { name: "meta-upload", component: MetaUploadComponent },
        { name: "meta-treeview", component: MetaTreeviewComponent },
        { name: "meta-subform", component: MetaSubformComponent },
        { name: "meta-calculation", component: MetaCalculationComponent },
        { name: "meta-calculation-templates", component: MetaCalculationTemplatesComponent },
        { name: "meta-specifications", component: MetaSpecificationsComponent },
        { name: "meta-files", component: MetaFilesComponent },
        { name: "meta-diagram", component: MetaDiagramComponent },
        { name: "report", component: MetaPageReferenceComponent },
        { name: "page", component: MetaPageReferenceComponent },
        { name: "meta-webview", component: MetaWebViewComponent },
        { name: "meta-cron", component: MetaCronComponent },
        { name: "meta-task-create", component: MetaTaskFormFormlyComponent },
        { name: "meta-task-view", component: MetaTaskListFormlyComponent },
        { name: "meta-gantt", component: MetaGanttComponent },
        { name: "meta-scheduler", component: MetaSchedulerComponent },
        { name: "meta-slider", component: MetaSliderComponent },
      ],
      wrappers: [
        { name: "meta-tabs", component: MetaTabsComponent },
        { name: "meta-group", component: MetaGroupComponent },
        { name: "meta-field-wrapper", component: MetaFieldWrapperComponent },
        { name: "meta-collapse", component: MetaCollapseComponent },
        { name: "meta-column", component: MetaColumnComponent },
        { name: "meta-row", component: MetaRowComponent },
        { name: "meta-container", component: MetaContainerComponent },
        { name: "meta-tooltip", component: MetaTooltipComponent },
        { name: "meta-card", component: MetaCardComponent },
      ],
      validators: validators,
      validationMessages: validationMessages,
      extras: {
        lazyRender: true,
        checkExpressionOn: "changeDetectionCheck",
        resetFieldOnHide: false,
        renderFormlyFieldElement: false,
      },
    }),
    FormlyNgZorroAntdModule,
    FusionChartsModule.forRoot(),
    NotificationModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    NgxTolgeeModule,
    MetaLayoutComponent,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: true,
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService, MetaDeepLinkingService],
      multi: true,
    },
    MetaFormService,
    FormSupervisorService,
    CloseFormActionHandler,
    ConfirmActionHandler,
    DownloadActionHandler,
    OpenFormActionHandler,
    RedirectActionHandler,
    RemoveItemActionHandler,
    SetFilterActionHandler,
    SetParamsActionHandler,
    UploadActionHandler,
    AppConfigService,
    UserNameFormatter,
    MetaHelperService,
    MetaEventService,
    HubService,
    MetaErrorHandlerService,
    {
      provide: LOCALE_ID,
      useFactory: () => {
        return localStorage["locale"] || "de-DE";
      },
    },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    { provide: NZ_I18N, useValue: de_DE },
    { provide: NZ_DATE_LOCALE, useValue: deFns },
    NzModalService,
    MetaLayoutService,
    NzMessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },
    MetaDeepLinkingService,
    {
      provide: TOLGEE_INSTANCE,
      deps: [LOCALE_ID],
      useFactory: () => {
        const localeId = inject(LOCALE_ID);
        LuxonSettings.defaultLocale = localeId;
        const language = localeId.split("-")[0];
        const options: TolgeeOptions = {
          language,
          availableLanguages: ["de", "en", "it", "fr", "pl", "es", "ja"],
          defaultLanguage: "de",
        };
        const apiKey = environment.tolgeeApiKey || localStorage["tolgeeApiKey"];
        if (apiKey) {
          return Tolgee()
            .use(InContextTools())
            .use(FormatNested())
            .init({
              // for development
              ...options,
              apiUrl: environment.tolgeeApiUrl,
              apiKey,
            });
        } else {
          return Tolgee()
            .use(FormatNested())
            .use(
              BackendFetch({
                getPath: ({ language, namespace }) => {
                  return `/assets/i18n/${namespace ? namespace + "/" : ""}${language}.${GIT_REF}.json`;
                },
              }),
            )
            .init({
              ...options,
              // Fallback to German in production
              fallbackLanguage: ["de", "en"],
            });
        }
      },
    },
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
  ],
})
export class AppModule {
  constructor(http: HttpClient) {
    initializeValidators(http);
  }
}
