/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ChangeDetectionStrategy, Component, NgModule, OnInit, ViewEncapsulation } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormlyModule } from "@ngx-formly/core";
import { NzInputModule } from "ng-zorro-antd/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MetaComponentBase, MetaFormBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaButtonModule } from "../metaButton/metaButton.component";
import { InputMaskModule } from "@ngneat/input-mask";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { NzFormModule } from "ng-zorro-antd/form";
import { Options as SortablejsOptions } from "sortablejs";
import { SortablejsModule } from "@moddi3/ngx-sortablejs";
import { MetaEmptyModule } from "../metaEmpty/metaEmpty.component";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { MetaMediaLibraryModule } from "../metaMediaLibrary/metaMediaLibrary.module";
import { MetaMediaLibraryService } from "../metaMediaLibrary/metaMediaLibrary.service";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { IMetaMediaLibrarySelectFileOption } from "../metaMediaLibrary/interfaces";

export class MetaImageGallery extends MetaFormBase {
  height = "300px";
  width: string;
  useMediaLibrary = true;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-image-gallery",
  templateUrl: "./metaImageGallery.component.html",
  styleUrls: ["./metaImageGallery.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaImageGalleryComponent extends MetaComponentBase implements OnInit {
  public selectedImage = 0;

  public sortablejsOptions: SortablejsOptions = {
    onUpdate: (e) => {
      this.selectedImage = 0;
      const files = this.formControl.value || [];
      this.formControl.patchValue(files);
      this.form.markAsDirty();
      this.changeDetectorRef.markForCheck();
    },
  };
  public dummyFiles: string[] = [
    "https://placehold.co/600x400",
    "https://placehold.co/600x400",
    "https://placehold.co/600x400",
    "https://placehold.co/600x400",
  ];
  get ma(): MetaImageGallery {
    return super.ma;
  }
  constructor(private readonly _metaMediaLibraryService: MetaMediaLibraryService) {
    super();
    super.maParams = new MetaImageGallery();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  scroll(direction: string) {
    document.querySelector(".carousel").scrollBy({
      left: direction === "left" ? -800 : 800,
      behavior: "smooth",
    });
  }

  imageClick(id: string) {
    this._metaMediaLibraryService.previewFileById(id);
  }

  removeImage(index: number) {
    const files = (this.formControl.value || []) as string[];
    files.splice(index, 1);
    this.formControl.patchValue(files);
    this.formControl.markAsDirty();
  }

  async addImage() {
    const options: IMetaMediaLibrarySelectFileOption = { type: "image" };
    const newFiles = this.ma.useMediaLibrary
      ? await this._metaMediaLibraryService.selectMultipleFiles(options)
      : await this._metaMediaLibraryService.uploadMultipleFiles(options);
    const files = (this.formControl.value || []) as string[];
    files.push(...newFiles.map((e) => e.id));
    this.formControl.patchValue(Array.from(new Set(files)));
    this.formControl.markAsDirty();
  }
}

@NgModule({
  declarations: [MetaImageGalleryComponent],
  imports: [
    CommonModule,
    FormlyModule,
    NzInputModule,
    ReactiveFormsModule,
    MetaButtonModule,
    InputMaskModule,
    FormsModule,
    NzFormModule,
    SortablejsModule,
    MetaEmptyModule,
    NzUploadModule,
    MetaMediaLibraryModule,
    NzPopconfirmModule,
  ],
  exports: [MetaImageGalleryComponent],
})
export class MetaImageGalleryModule {}
