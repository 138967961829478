/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  Injector,
  Input,
  OnInit,
  signal,
  Signal,
  ViewChild,
  ViewContainerRef,
  WritableSignal,
} from "@angular/core";
import { Router } from "@angular/router";
import { MetaButtonType, MetaState } from "@meta/enums";
import { TranslateService } from "@tolgee/ngx";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalRef, NzModalState } from "ng-zorro-antd/modal";
import { firstValueFrom } from "rxjs";
import { MetaButton } from "../../../components/metaButton/metaButton.component";
import { DirtyCheckService } from "../../../services/dirtyCheckService";
import { MetaHelperService } from "../../../services/metaHelper.service";
import { MetaModalOptions, MetaModalService } from "../../../services/metaModalService";
import { UserInfoResponse, UserService } from "../../../services/user.service";
import { MetaForm, MetaFormlyFormOptions } from "../../metaForm/metaForm.interface";
import { MetaActionHandlerFactory } from "../actions/actionHandler.factory";
import { MetaFormService } from "../metaForm.service";

@Component({
  selector: "meta-form-additional-actions",
  template: ` @if (
    metaFormService.formState.form().actions?.length > 0 ||
    metaFormService.formState.form().changeManagementForm ||
    metaFormService.formState.form().versionable ||
    metaFormService.formState.form().showFillingLevel
  ) {
    @if (
      options.formState.form().versionable &&
      metaFormService.formState.versionsData().length > 0 &&
      (options.formState.state() > MetaState.saving || options.formState.state() === MetaState.none) &&
      options.formState.state() !== MetaState.editingForm
    ) {
      <meta-select
        [maParams]="{
          data: metaFormService.formState.versionsData(),
          editing: true,
          clearable: false,
          disabled: metaFormService.activePublishWorkflows().length > 0
        }"
        (ngModelChange)="onVersionSelect($event)"
        [ngModel]="options.formState.version()"
        [style.minWidth.px]="275"
      >
      </meta-select>
    }
    @if (
      (options.formState.state() === MetaState.none ||
        options.formState.state() > MetaState.saving ||
        !options.formState.form().hasDataSource) &&
      options.formState.state() !== MetaState.editingForm &&
      options.formState.form().type !== "wizard"
    ) {
      <ng-container *ngIf="!options.formState.form().hasDataSource; else spotlightActionsTpl">
        <ng-container *ngFor="let a of actions">
          <meta-button
            *ngIf="!a.hide"
            [maParams]="
              metaHelperService.merge(a, {
                type: a.buttonType,
                loading: actionsLoading[a.id],
                disabled: isActionDisabled()[a.id]
              })
            "
          >
          </meta-button>
        </ng-container>
      </ng-container>
    }
    @if (
      (options.formState.state() === MetaState.none || options.formState.state() > MetaState.saving) &&
      options.formState.form().hasDataSource &&
      actions?.length > 0 &&
      options.formState.state() !== MetaState.editing
    ) {
      <meta-dropdown
        [maParams]="{
          icon: 'chevron-down',
          iconPosition: 'end',
          label: 'common.more' | translate,
          type: MetaButtonType.default,
          disabled:
            (options.formState.state() >= MetaState.editing && options.formState.state() <= MetaState.saving) ||
            options.formState.isLoading(),
          items: actions
        }"
      ></meta-dropdown>
    }

    <ng-template #spotlightActionsTpl>
      <ng-container *ngFor="let a of spotlightActions">
        <meta-button
          *ngIf="!a.hide"
          [maParams]="
            metaHelperService.merge(a, {
              type: a.buttonType,
              loading: actionsLoading[a.id],
              disabled: isActionDisabled()[a.id]
            })
          "
        >
        </meta-button>
      </ng-container>
    </ng-template>
    @if (
      user().administrator &&
      (options.formState.state() > MetaState.saving || options.formState.state() === MetaState.none) &&
      options.formState.form().showFillingLevel
    ) {
      <meta-button
        [routerLink]="'/form-configuration/' + options.formState.formId"
        nz-tooltip
        nzTooltipTitle="Formular konfigurieren"
        [maParams]="{ type: 'special', icon: 'wrench', iconStyle: 'fas' }"
      >
      </meta-button>
    }
    <ng-container #container></ng-container>
  }`,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaFormAdditionalActionsComponent implements OnInit {
  @Input() options: MetaFormlyFormOptions;
  @ViewChild("container", { read: ViewContainerRef, static: true }) container: ViewContainerRef;

  public config: MetaButton;
  public actions: MetaButton[] = [];
  public spotlightActions: MetaButton[] = [];
  public actionsLoading: WritableSignal<{ [id: string]: boolean }> = signal({});
  public isActionDisabled: Signal<{ [id: string]: boolean }>;
  public isActionHidden: Signal<{ [id: string]: boolean }>;
  public isDropdownDisabled: Signal<boolean>;
  protected readonly MetaState = MetaState;
  protected readonly MetaButtonType = MetaButtonType;
  public test: Signal<Record<string, any>>;
  public user: Signal<UserInfoResponse>;

  constructor(
    public readonly metaFormService: MetaFormService,
    public readonly metaHelperService: MetaHelperService,
    private readonly _metaModalService: MetaModalService,
    private readonly _nzMessageService: NzMessageService,
    private readonly _router: Router,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _metaActionHandler: MetaActionHandlerFactory,
    private readonly _dirtyCheckService: DirtyCheckService,
    private readonly injector: Injector,
    private readonly userService: UserService,
    private readonly translateService: TranslateService,
  ) {
    this.user = this.userService.userSignal;
  }

  ngOnInit() {
    this.spotlightActions = this.options.formState.form().actions?.filter((action) => action.spotlight);
    this.initializeDisabledStates();
    this.initializeActions();
  }

  public async onToggleLock() {
    // TODO
    /*
    this.options.formState.data().Sperre = !this.options.formState.data().Sperre;
    if (this.options.formState.formGroup().valid) {
      await firstValueFrom(
        this.metaFormService.updateFormData({
          formId: this.maFormId,
          itemId: this.maItemId,
          oldData: this.maItemId === "create" ? {} : this.oldModel,
          newData: this.model,
          state: this.options.formState.state,
          completeEditMode: true,
        }),
      );
    }*/
    this._changeDetectorRef.markForCheck();
  }

  public onVersionSelect(event: any) {
    this.options.formState.version.set(event);
    const itemId: string[] = this.options.formState.itemId().split(",");
    itemId[itemId.length - 1] = event;
    if (this.options.formState.modalRef || this.options.formState.loadedAsModal) {
      // TODO2: _setFormData still required?
      //this._setFormData(itemId.join(","));
    } else {
      this._router.navigate([this.options.formState.formId, itemId.join(",")]);
    }
  }

  private initializeActions() {
    this.actions = (this.options.formState.form().actions || []).map((action) => {
      return {
        ...action,
        hide: this.isActionHidden()[action.id],
        disabled: this.isActionDisabled()[action.id],
        onClick: () => {
          if (this.isActionDisabled()[action.id]) {
            return;
          }
          const currentLoadingState = this.actionsLoading();
          const updatedLoadingState = { ...currentLoadingState, [action.id]: true };
          this.actionsLoading.set(updatedLoadingState);

          const oldState = this.options.formState.state();
          if (
            this.options.formState.modalRef &&
            this.options.formState.state() >= MetaState.editing &&
            this.options.formState.state() <= MetaState.saving
          ) {
            this.options.formState.state.set(MetaState.disabled);
          }
          this.options.formState.formGroup().markAllAsTouched();

          this._metaActionHandler
            .executeClickAction({
              controlId: action.id,
              ctx: this.options.formState.data()["_ctx"],
              formId: this.options.formState.formId,
              data: this.options.formState.data(),
              formIsValid: this.options.formState.formGroup().valid,
              passthroughData: { formId: this.options.formState.formId },
            })
            .catch((e) => {
              console.error(e);
            })
            .finally(() => {
              if (this.options.formState.modalRef) {
                this.options.formState.state.set(oldState);
              }
              this.actionsLoading()[action.id] = false;
            });
        },
      };
    });
    this.actions = this.addFormFeatures(this.options.formState.form(), this.actions);
    this.spotlightActions = this.actions.filter((action) => action.spotlight);
    this.actions = this.actions.filter((action) => !action.spotlight);
  }

  private initializeDisabledStates() {
    const isDisabled = computed(
      () =>
        [this.MetaState.disabled, this.MetaState.saving].includes(this.options.formState.state()) ||
        this.options.formState.isLoading(),
    );

    this.isDropdownDisabled = isDisabled;

    this.isActionDisabled = computed(() => {
      return this.options.formState.form().actions?.reduce((acc, action) => {
        acc[action.id] =
          isDisabled() ||
          ((this.options.formState.displayData() &&
            this.options.formState.displayData()[action.id]?.disabledCondition) ??
            false);
        return acc;
      }, {});
    });

    this.isActionHidden = computed(() => {
      return this.options.formState.form().actions?.reduce((acc, action) => {
        acc[action.id] =
          this.options.formState.displayData()[action.id] &&
          this.options.formState.displayData()[action.id].condition !== undefined
            ? !this.options.formState.displayData()[action.id].condition
            : false;
        return acc;
      }, {});
    });

    // Setup effect to recalculate disabled states when displayData changes
    effect(
      () => {
        this.isDropdownDisabled();
        this.isActionDisabled();
        this.isActionHidden();
        this.initializeActions();
      },
      { injector: this.injector },
    );
  }

  private addFormFeatures(form: MetaForm, action: MetaButton[]): MetaButton[] {
    if (!form) {
      return;
    }

    if (form.changeManagementForm) {
      if (action.findIndex((e) => e.id === "changeManagement") > -1) {
        action.splice(
          action.findIndex((e) => e.id === "changeManagement"),
          1,
        );
      }
      action.push({
        id: "changeManagement",
        icon: "money-check-edit",
        label: "Massenbearbeitung",
        onClick: async () => {
          const { MetaFormBulkEdit } = await import("../features/bulkEdit/metaForm.bulkEdit");
          const params: MetaModalOptions = {
            nzContent: MetaFormBulkEdit,
            nzTitle: "Massenbearbeitung",
            nzMaskClosable: false,
            nzWidth: "95%",
            nzFooter: null,
            nzClassName: `ma-subform`,
            nzWrapClassName: "ma-subform-wrapper",
            nzBodyStyle: {
              position: "relative",
              display: "flex",
              "flex-direction": "column",
            },
            nzClosable: true,
            nzOnCancel: () => {
              if (modalRef.state !== NzModalState.CLOSED && this._dirtyCheckService.isDirty(modalRef)) {
                return firstValueFrom(this._dirtyCheckService.showDirtyAlert(modalRef));
              }
            },
            nzCloseOnNavigation: true,
            nzComponentParams: {
              maFormId: this.metaFormService.formState.formId,
              maBulkEditFormId: form.changeManagementForm,
              maEntityType: this.metaFormService.formState.form().entityType,
            },
          };

          let modalRef: NzModalRef;
          modalRef = this._metaModalService.create(params);
        },
      });
    }
    if (form.versionable && form.editable) {
      if (action.findIndex((e) => e.id === "createVersion") > -1) {
        action.splice(
          action.findIndex((e) => e.id === "createVersion"),
          1,
        );
      }
      action.push({
        id: "createVersion",
        icon: "plus",
        label: this.options.formState.form().createVersionLabel || "Version erstellen",
        onClick: async () => {
          await this.onCreateVersion();
        },
      });
    }
    if (form.versionable && form.editable) {
      if (action.findIndex((e) => e.id === "deleteVersion") > -1) {
        action.splice(
          action.findIndex((e) => e.id === "deleteVersion"),
          1,
        );
      }
      action.push({
        id: "deleteVersion",
        icon: "lock",
        label: this.options.formState.form().deleteVersionLabel || "Version stornieren",
        onClick: async () => {
          await this.onDeleteVersion();
        },
      });
    }
    if (form.lockable && form.editable) {
      if (action.findIndex((e) => e.id === "lock") > -1) {
        action.splice(
          action.findIndex((e) => e.id === "lock"),
          1,
        );
      }

      const entityName = this.translateService.instant(
        `entities.${String(this.options.formState.form().entityType).toLowerCase()}.name_singular`,
      );
      action.push({
        id: "lock",
        icon: this.options.formState.data()?.Sperre ? "lock-open" : "lock",
        label: `${entityName} ${this.options.formState.data()?.Sperre ? "entsperren" : "sperren"}`,
        onClick: () => {
          this.onToggleLock();
        },
      });
    }
    return action;
  }

  private async onCreateVersion() {
    const result = await this._metaActionHandler.executeCreateVersionAction({
      controlId: null,
      formId: this.options.formState.formId,
      data: this.options.formState.data(),
      ctx: this.options.formState.data()?._ctx,
    });
    if (result[this.options.formState.form()?.versionField]) {
      this.metaFormService.formState.versionsData.set(
        await this.metaFormService.getVersions(this.options.formState.formId, this.options.formState.itemId()),
      );
      this.metaFormService.formState.version.set(result[this.options.formState.form()?.versionField]);
      this._nzMessageService.success("Version erstellt");
      this.onVersionSelect(this.metaFormService.formState.version());
      this._changeDetectorRef.markForCheck();
    }
  }

  private async onDeleteVersion() {
    const result = await this._metaActionHandler.executeDeleteVersionAction({
      controlId: null,
      formId: this.options.formState.formId,
      data: this.options.formState.data(),
      ctx: this.options.formState.data()?._ctx,
    });
    if (result) {
      this.metaFormService.formState.versionsData.set(
        await this.metaFormService.getVersions(this.options.formState.formId, this.options.formState.itemId()),
      );
      this._nzMessageService.success("Version storniert");
      this._changeDetectorRef.markForCheck();
    }
  }
}
