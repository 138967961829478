/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2021
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2021
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule, ViewEncapsulation } from "@angular/core";
import { FormlyModule } from "@ngx-formly/core";
import { MetaWrapperBase } from "../../base/metaWrapperBase/metaWrapperBase.component";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";

export class MetaContainer {}

@MetaUnsubscribe()
@Component({
  selector: "meta-container",
  template: ` <ng-container #fieldComponent></ng-container> `,
  styles: [
    `
      meta-container {
        display: contents;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaContainerComponent extends MetaWrapperBase {
  constructor() {
    super();
  }
}

@NgModule({
  declarations: [MetaContainerComponent],
  imports: [CommonModule, FormlyModule],
  exports: [MetaContainerComponent],
})
export class MetaContainerModule {}
