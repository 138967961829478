/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponentInfo, MetaFormComponentProperty } from "../components";
import { Expose } from "class-transformer";
import { EditableForm } from "./editableForm";
import { IFormUserConfiguration } from "../formUserConfiguration";

@MetaFormComponentInfo({
  selector: "ma-split-view",
  type: "split-view",
  icon: "columns",
  name: "Split View",
})
export class SplitView extends EditableForm {
  static selector = "ma-split-view";

  constructor(props: Partial<SplitView>) {
    super(props);
  }
  public static createElement(attributes, children: any[]): any {
    if (children.length !== 2) {
      throw new Error("A Split View should only contain 2 elements");
    }

    const detailInternal = children.find((c) => c["type"] === "detail-internal");

    if (detailInternal) {
      attributes = {
        ...attributes,
        ...detailInternal.attributes,
        id: attributes.id,
      };
      children.splice(children.indexOf(detailInternal), 1, detailInternal.element);
    }

    return new SplitView({
      ...attributes,
      children,
    });
  }

  toFormly(config: IFormUserConfiguration): any {
    return {
      ...super.toFormly(config),
      children: [
        {
          id: this.id,
          label: this.label,
          fieldGroup: super.toFormly(config).children.map((c) => {
            if (c.type === "page") {
              c.fieldGroup = c.children;
            }
            return c;
          }),
          props: {
            leftColumnWidth: 8,
          },
          type: `meta-${this.constructor["meta"]["type"]}`,
        },
      ],
      creatable: !!this.createable,
      type: "splitview",
    };
  }
}
