/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import {
  DataSourceParameters,
  MetaFormBaseComponent,
  MetaFormComponentInfo,
  MetaFormComponentProperty,
} from "./baseComponent";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";
import { MetaGantAndSchedulerBase } from "./metaGantAndSchedulerBase";

@MetaFormComponentInfo({
  selector: "ma-gantt",
  type: "gantt",
  name: "Gant",
  icon: "chart-gantt",
})
export class MetaGanttComponent extends MetaGantAndSchedulerBase {
  public static create(opts: Partial<MetaGanttComponent>) {
    const item = new MetaGanttComponent();
    Object.assign(item, opts);
    return item;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      key: this.id,
      type: `meta-${this.constructor["meta"]["type"]}`,
      props: {
        hasCondition: !!this.condition,
        itemGroupField: "group",
        itemGroupLabelField: "group",
      },
    });
  }
}
