/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { HttpClient } from "@angular/common/http";
import { Injectable, signal, WritableSignal } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { firstValueFrom, of, switchMap } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { MetaErrorHandlerService } from "../../services/metaErrorHandler.service";

export class MetaMenu {
  id: string;
  parentId?: string;
  position: number;
  icon: string;
  label: string;
  path: string;
}

class MetaMenuResponse {
  entryId: string;
  items: MetaMenu[];
}

@Injectable({ providedIn: "root" })
export class MetaLayoutService {
  public menu: WritableSignal<MetaMenu[]> = signal([]);
  public entryId: WritableSignal<string> = signal(null);
  private _menuKey = "userMenu";

  constructor(
    private _http: HttpClient,
    private readonly _metaErrorHandlerService: MetaErrorHandlerService,
    private readonly _nzMessageService: NzMessageService,
  ) {}

  public async reloadMenu() {
    sessionStorage.removeItem(this._menuKey);
    await this.getMenu();
  }

  public async getMenu() {
    if (sessionStorage[this._menuKey]) {
      this.menu.set(JSON.parse(sessionStorage[this._menuKey]));
    }
    await firstValueFrom(
      this._http.get<MetaMenuResponse>("menu").pipe(
        tap((data) => {
          if (data) {
            sessionStorage[this._menuKey] = JSON.stringify(data);
            this.menu.set(data.items);
            this.entryId.set(data.entryId);
          }
        }),
        catchError((err) =>
          this._metaErrorHandlerService.handleError(err).pipe(
            switchMap(() => of(null)), // Return a safe observable to continue the stream
            tap((value) => {
              if (value) {
                this._nzMessageService.error(value);
              }
            }),
          ),
        ),
      ),
    );
  }
}
