/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

const stringFormats = {
  "date-time": "clock",
  email: "at",
  uri: "link",
  uuid: "fingerprint",
  user: "user",
  "user-group": "users",
} as const;

export function getVariableTypeIcon(e: { type: string; $id?: string; format?: string }) {
  //Types
  switch (e?.$id) {
    // Schemas
    case "argon/file":
      return "file";
  }
  //Types
  switch (e?.type) {
    case "string":
      return stringFormats[e.format] || "input-text";
    case "number":
    case "integer":
      return "hashtag";
    case "object":
      return "dice-d6";
    case "boolean":
      return "square-check";
    case "array":
      return "list";
    case "binary":
      return "binary";
    default:
      return "question";
  }
}
