/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgModule,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  EntityType,
  MetaButtonType,
  MetaControlMode,
  MetaDatepickerType,
  MetaRadioStyle,
  MetaSelectType,
  MetaState,
  MetaUploadType,
} from "@meta/enums";
import { select } from "@ngneat/elf";
import { FormlyModule } from "@ngx-formly/core";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { distinctUntilChanged, Subject, takeUntil } from "rxjs";
import { filter } from "rxjs/operators";
import * as uuid from "uuid";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { UserService } from "../../services/user.service";
import { MetaButtonModule } from "../metaButton/metaButton.component";
import { MetaDrodpownModule, MetaDropdownActionButton } from "../metaDropdown/metaDropdown.component";
import { MetaEditorModule } from "../metaEditor/metaEditor.module";
import { MetaGroupModule } from "../metaGroup/metaGroup.component";
import { MetaHubService } from "../metaHub/metaHub.service";
import { MetaInputModule } from "../metaInput/metaInput.component";
import { MetaLoaderModule } from "../metaLoader/metaLoader.component";
import { IMetaMediaLibrarySelectFileOption } from "../metaMediaLibrary/interfaces";
import { MetaMediaLibraryModule } from "../metaMediaLibrary/metaMediaLibrary.module";
import { MetaMediaLibraryService } from "../metaMediaLibrary/metaMediaLibrary.service";
import { MetaPillModule } from "../metaPill/metaPill.component";
import { MetaSectionModule } from "../metaSection/metaSection.component";
import { MetaSkeletonModule } from "../metaSkeleton/metaSkeleton.component";
import { MetaTaskFormModule } from "../metaTask/metaTask.form.component";
import { MetaTaskListModule } from "../metaTask/metaTask.list.component";
import { MetaTimelineItem, MetaTimelineModule } from "../metaTimeline/metaTimeline.component";
import { MetaActivityRepository } from "./metaActivity.repository";

export class MetaActivity {
  id?: string;
  sourceId?: string;
  entityType: EntityType;
  showNotes?: boolean;
  showTasks? = true;
  showAppointments?: boolean;
  showCalls?: boolean;
  showFiles?: boolean;
  showLog? = true;
  showMarketInfos?: boolean;
  disabled?: boolean;
  showDummy?: boolean;
  showMainTabs?: boolean;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-activity",
  template: `
    <nz-tabset class="mb-4" [ngClass]="{ hideTabs: !ma.showMainTabs }" [nzAnimated]="false">
      <nz-tab *ngIf="ma.showNotes" nzTitle="Notiz">
        <meta-editor
          *ngIf="!ma.showDummy"
          [maParams]="{
            editing: true,
            placeholder: 'Notiz erfassen...',
            disabled: isSavingNote || ma.disabled || (field ? formState.itemId() === 'create' : false)
          }"
          [(ngModel)]="activityModel.note"
        ></meta-editor>
        <meta-skeleton
          *ngIf="ma.showDummy"
          [maParams]="{
            loading: true,
            active: false,
            avatar: false,
            rows: 2
          }"
        ></meta-skeleton>
        <meta-button
          [maParams]="{
            fullWidth: true,
            type: 'primary',
            label: 'Notiz speichern',
            loading: isSavingNote,
            disabled: ma.disabled || !activityModel.note
          }"
          (click)="onSaveNote()"
        ></meta-button>
      </nz-tab>
      <nz-tab *ngIf="ma.showTasks" nzTitle="Aufgabe">
        <meta-task-form
          *ngIf="_sourceId !== undefined"
          [maParams]="{ sourceId: _sourceId?.toString(), sourceType: ma.entityType }"
        ></meta-task-form>
      </nz-tab>
      <nz-tab *ngIf="ma.showFiles && formState.form()?.editable" nzTitle="Datei">
        <meta-button
          [maParams]="{
            label: 'Dateien verknüpfen',
            icon: 'link',
            onClick: onAddFiles.bind(this),
            fullWidth: true,
            type: 'primary'
          }"
        ></meta-button>
      </nz-tab>
    </nz-tabset>
    <ng-container>
      <meta-task-list
        *ngIf="_sourceId !== undefined && ma.showTasks"
        class="mb-4"
        [maParams]="{ sourceId: _sourceId?.toString(), sourceType: ma.entityType, dataToShow: 'open', condensed: true }"
      ></meta-task-list>
    </ng-container>
    <nz-tabset [nzAnimated]="false" (nzSelectedIndexChange)="onSubIndexChange($event)">
      <nz-tab [nzTitle]="'Alle (' + (activitiesTotal === undefined ? 0 : activitiesTotal) + ')'">
        <ng-container *ngIf="ma.showDummy">
          <meta-skeleton
            *ngFor="let dummy of numberOfDummiesToShow"
            [maParams]="{
              loading: true,
              active: false,
              avatar: true,
              rows: 2,
              width: 'random'
            }"
          ></meta-skeleton>
        </ng-container>
        <meta-loader *ngIf="isLoadingActivities"></meta-loader>
        <meta-timeline
          *ngIf="!isLoadingActivities"
          [maParams]="{
            data: activities,
            style: 'boxed',
            noDataLabel: 'Noch keine Einträge vorhanden.',
            noDataIcon: 'inbox'
          }"
          class="meta-activity-timeline"
        ></meta-timeline>
        <div class="mb-5 mt-4 d-block">
          <meta-button
            *ngIf="(skip + take < activitiesTotal || isLoadingMoreActivities) && !isLoadingActivities"
            [maParams]="{
              fullWidth: true,
              label: 'Mehr laden',
              icon: 'chevron-down',
              loading: skip > 0 ? isLoadingMoreActivities : false
            }"
            (click)="onLoadMoreActivities()"
          ></meta-button>
        </div>
      </nz-tab>
      <nz-tab [nzDisabled]="!ma.showNotes" [nzTitle]="'Notizen (' + (notesTotal || 0) + ')'">
        <meta-timeline
          *ngIf="!isLoadingNotes"
          [maParams]="{
            data: notes,
            style: 'boxed',
            noDataLabel: 'Noch keine Notizen vorhanden.',
            noDataIcon: 'sticky-note'
          }"
          class="meta-activity-timeline"
        ></meta-timeline>
        <meta-loader *ngIf="isLoadingNotes"></meta-loader>
        <div class="mb-5 mt-4 d-block">
          <meta-button
            *ngIf="(skipNotes + takeNotes < notesTotal || isLoadingMoreNotes) && !isLoadingNotes"
            [maParams]="{
              fullWidth: true,
              label: 'Mehr laden',
              icon: 'chevron-down',
              loading: skipNotes > 0 ? isLoadingMoreNotes : false
            }"
            (click)="onLoadMoreNotes()"
          ></meta-button>
        </div>
      </nz-tab>
      <nz-tab [nzDisabled]="!ma.showTasks" [nzTitle]="'Aufgaben (' + (totalDoneTasks || 0) + ')'">
        <meta-task-list
          *ngIf="_sourceId !== undefined"
          [maParams]="{
            sourceId: _sourceId?.toString(),
            sourceType: ma.entityType,
            dataToShow: 'done',
            style: 'timeline',
            condensed: true
          }"
          (doneTasksChanged)="totalDoneTasks = $event"
        ></meta-task-list>
      </nz-tab>
      <nz-tab [nzDisabled]="!ma.showAppointments" [nzTitle]="'Termine (' + (closedAppointmentsTotal || 0) + ')'">
        <meta-timeline
          *ngIf="!isLoadingAppointments"
          [maParams]="{
            data: closedAppointments,
            style: 'boxed',
            noDataLabel: 'Noch keine Termine vorhanden.',
            noDataIcon: 'calendar-times'
          }"
          class="meta-activity-timeline"
        ></meta-timeline>
        <meta-loader *ngIf="isLoadingAppointments"></meta-loader>
        <div class="mb-5 mt-4 d-block">
          <meta-button
            *ngIf="
              (skipClosedAppointments + takeClosedAppointments < closedAppointmentsTotal ||
                isLoadingMoreClosedAppointments) &&
              !isLoadingAppointments
            "
            [maParams]="{
              fullWidth: true,
              label: 'Mehr laden',
              icon: 'chevron-down',
              loading: skipClosedAppointments > 0 ? isLoadingMoreClosedAppointments : false
            }"
            (click)="onLoadMoreClosedAppointments()"
          ></meta-button>
        </div>
      </nz-tab>
      <nz-tab [nzDisabled]="!ma.showCalls" [nzTitle]="'Anrufe (' + (callsTotal || 0) + ')'">
        <meta-timeline
          *ngIf="!isLoadingCalls"
          [maParams]="{
            data: calls,
            style: 'boxed',
            noDataLabel: 'Noch keine Anrufe vorhanden.',
            noDataIcon: 'notification'
          }"
          class="meta-activity-timeline"
        ></meta-timeline>
        <meta-loader *ngIf="isLoadingCalls"></meta-loader>
        <div class="mb-5 mt-4 d-block">
          <meta-button
            *ngIf="(skipCalls + takeCalls < callsTotal || isLoadingMoreCalls) && !isLoadingCalls"
            [maParams]="{
              fullWidth: true,
              label: 'Mehr laden',
              icon: 'chevron-down',
              loading: skipCalls > 0 ? isLoadingMoreCalls : false
            }"
            (click)="onLoadMoreCalls()"
          ></meta-button>
        </div>
      </nz-tab>
      <nz-tab [nzDisabled]="!ma.showMarketInfos" [nzTitle]="'Marktinformationen (' + (marketInfosTotal || 0) + ')'">
        <meta-input [maParams]="{ placeholder: 'Marktinformationen durchsuchen...', editing: true }"></meta-input>
        <meta-section [maParams]="{ sectionType: 'space', spacingTop: 2, spacingBottom: 2 }"></meta-section>
        <meta-timeline
          *ngIf="!isLoadingMarketInfos"
          [maParams]="{
            data: marketInfos,
            style: 'boxed',
            noDataLabel: 'Noch keine Marktinformationen vorhanden.',
            noDataIcon: 'magnifying-glass-chart'
          }"
          class="meta-activity-timeline"
        ></meta-timeline>
        <meta-loader *ngIf="isLoadingMarketInfos"></meta-loader>
        <div class="mb-5 mt-4 d-block">
          <meta-button
            *ngIf="
              (skipMarketInfos + takeMarketInfos < marketInfosTotal || isLoadingMoreMarketInfos) &&
              !isLoadingMarketInfos
            "
            [maParams]="{
              fullWidth: true,
              label: 'Mehr laden',
              icon: 'chevron-down',
              loading: skipMarketInfos > 0 ? isLoadingMoreMarketInfos : false
            }"
            (click)="onLoadMoreMarketInfos()"
          ></meta-button>
        </div>
      </nz-tab>
      <nz-tab [nzDisabled]="!ma.showFiles" [nzTitle]="'Dateien (' + (files.length || 0) + ')'">
        <meta-timeline
          [maParams]="{
            data: files,
            style: 'boxed',
            noDataLabel: 'Noch keine Dateien vorhanden.',
            noDataIcon: 'file',
            extra: fileMenu
          }"
          class="meta-activity-timeline"
        ></meta-timeline>
      </nz-tab>
      <nz-tab [nzDisabled]="!ma.showLog" [nzTitle]="'Protokoll (' + (logsTotal || 0) + ')'">
        <meta-timeline
          *ngIf="!isLoadingLogs"
          [maParams]="{
            data: (metaHubService.activities$ | async),
            style: 'log',
            noDataLabel: 'Keine Aktivitäten',
            noDataIcon: 'file',
            expandable: true
          }"
          class="meta-activity-timeline"
          maNoDataLabel="Keine Aktivitäten"
        ></meta-timeline>
        <meta-loader *ngIf="isLoadingLogs"></meta-loader>
        <div class="mb-5 mt-4 d-block">
          <meta-button
            *ngIf="
              metaHubService.skipTake.activities.skip + metaHubService.skipTake.activities.take <
                (metaHubService.activitiesTotal$ | async) ||
              metaHubService.isLoadingMore.activities ||
              !isLoadingLogs
            "
            [maParams]="{
              fullWidth: true,
              label: 'Mehr laden',
              icon: 'chevron-down',
              loading: metaHubService.skipTake.activities.skip > 0 ? metaHubService.isLoadingMore.activities : false
            }"
            (click)="onLoadMoreLogs()"
          ></meta-button>
        </div>
      </nz-tab>
    </nz-tabset>

    <ng-template #configTemplate let-item>
      <meta-dropdown
        [maParams]="{
          icon: 'ellipsis-h',
          iconStyle: 'fas',
          items: actionMenu,
          size: 'small'
        }"
      ></meta-dropdown>
    </ng-template>

    <ng-template #fileMenu let-item>
      <button nzTrigger="click" nzSize="small" nz-button nz-dropdown [nzDropdownMenu]="menu">
        <i class="fas fa-ellipsis-h"></i>
      </button>

      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li (click)="onUnassign(field, item)" nz-menu-item>Verknüpfung entfernen</li>
        </ul>
      </nz-dropdown-menu>
    </ng-template>
  `,
  styleUrls: ["./metaActivity.component.less", "../metaTabs/metaTabs.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaActivityComponent extends MetaComponentBase implements OnInit, OnDestroy {
  public activityModel: any = {
    note: null,
    taskTitle: null,
    taskDescription: null,
  };

  public metaRadioStyle = MetaRadioStyle;
  public metaControlMode = MetaControlMode;
  public metaDatepickerType = MetaDatepickerType;
  public metaSelectType = MetaSelectType;
  public metaUploadType = MetaUploadType;
  public metaButtonType = MetaButtonType;

  public _id: string;
  public totalOpenTasks = 0;
  public totalDoneTasks = 0;
  public skip = 0;
  public take = 10;
  public skipNotes = 0;
  public takeNotes = 10;
  public skipClosedAppointments = 0;
  public takeClosedAppointments = 10;
  public skipAppointments = 0;
  public takeAppointments = 5;
  public skipLogs = 0;
  public takeLogs = 10;
  public skipFiles = 0;
  public takeFiles = 10;
  public skipCalls = 0;
  public takeCalls = 10;
  public skipMarketInfos = 0;
  public takeMarketInfos = 10;
  public activitiesTotal = 0;
  public notesTotal = 0;
  public tasksTotal = 0;
  public closedAppointmentsTotal = 0;
  public appointmentsTotal = 0;
  public callsTotal = 0;
  public marketInfosTotal = 0;
  public logsTotal = 0;
  public skipOrgas = 0;
  public takeOrgas = 50;
  public searchOrgas: string;
  public organisations: any[] = [];
  public initNotes: boolean;
  public initClosedAppointments: boolean;
  public initAppointments: boolean;
  public initLogs: boolean;
  public initCalls: boolean;
  public initMarketInfos: boolean;
  public initFiles: boolean;
  public initActivities = true;
  public isLoadingNotes: boolean;
  public isLoadingClosedAppointments: boolean;
  public isLoadingAppointments: boolean;
  public isLoadingLogs: boolean;
  public isLoadingCalls: boolean;
  public isLoadingMarketInfos: boolean;
  public isLoadingFiles: boolean;
  public isLoadingActivities = false;
  public isLoadingMoreActivities: boolean;
  public isLoadingMoreNotes: boolean;
  public isLoadingMoreClosedAppointments: boolean;
  public isLoadingMoreAppointments: boolean;
  public isLoadingMoreLogs: boolean;
  public isLoadingMoreCalls: boolean;
  public isLoadingMoreMarketInfos: boolean;
  public isSavingNote: boolean;
  public isSavingAppointments: boolean;
  public isUploadingFile: boolean;
  public lastFormState: string;
  public lastDataState: string;
  public activities: MetaTimelineItem[] = [];
  public notes: MetaTimelineItem[] = [];
  public closedAppointments: MetaTimelineItem[] = [];
  public appointments: MetaTimelineItem[] = [];
  public files: MetaTimelineItem[] = [];
  public calls: MetaTimelineItem[] = [];
  public marketInfos: MetaTimelineItem[] = [];
  public logs: MetaTimelineItem[] = [];
  public actionMenu: MetaDropdownActionButton[];
  public currentUser: any;
  public lastFulltimeChange: boolean;
  public numberOfDummiesToShow = Array(5)
    .fill(null)
    .map((x, i) => i);
  public users: any[] = [];
  public participants: any[] = [];
  public appointmentsInitialized: boolean;
  public showAdditionalFields: boolean;
  public hideOrganisation: boolean;
  public _sourceId: string;
  public priorities: any;
  private filesTotal: number;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    _changeDetectorRef: ChangeDetectorRef,
    _metaHelperService: MetaHelperService,
    private readonly _userService: UserService,
    public metaActivityRepository: MetaActivityRepository,
    public metaHubService: MetaHubService,
    private _http: HttpClient,
    public readonly _metaMediaLibraryService: MetaMediaLibraryService,
    private readonly notificationService: NzNotificationService,
  ) {
    super();
    super.maParams = new MetaActivity();
  }

  get ma(): MetaActivity {
    return super.ma;
  }

  async ngOnInit() {
    super.ngOnInit();
    if (!this.field) {
      this._id = this.ma.id || uuid.v4();
      this._sourceId = this.ma.sourceId;
    } else {
      this._id = this.id;
    }
    if (this.ma.showDummy) {
      return;
    }
    this.storeSubscription();
    this.notesSubscription();
    this._createActionsMenu();

    //for saving notes when parent form is saved
    if (this.field) {
      this.formState.state$
        .pipe(
          takeUntil(this.destroy$),
          filter((x) => x !== undefined),
        )
        .subscribe({
          next: (state: MetaState) => {
            if (state === MetaState.canceled) {
              if (this.activityModel.note) {
                this.onSaveNote();
              }
            }
          },
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public async onAppointmentInit() {
    if (!this.appointmentsInitialized) {
      // get users
      this.users = await this._userService.getUsers();
      this.users.forEach((user) => (user.label = `${user.Vorname} ${user.Name1}`));
      this.participants = (await this.metaActivityRepository.getParticipants()).map((e) => {
        return {
          label: `${e.Name} (${e.Organisation})`,
          ...e,
        };
      });

      this._userService.getUser().subscribe((user) => {
        //this.appointmentForm.get("assignedto").patchValue(user.id);
        this.currentUser = user.id;
      });

      // Get Organisations
      this.organisations = await this.metaHelperService.getOrganisations(
        this.skipOrgas,
        this.takeOrgas,
        this.searchOrgas,
      );

      this.appointmentsInitialized = true;
    }
  }

  public onMainIndexChange(data: any) {
    if (data.item?.maIndex === 2 && this.users.length === 0) {
      this.onAppointmentInit();
    }
  }

  public onSubIndexChange(index: number) {
    if (!this._sourceId) {
      return;
    }

    switch (index) {
      case 0:
        if (this.initActivities) {
          return;
        }
        this.isLoadingActivities = true;
        this.initActivities = true;
        this.metaActivityRepository
          .loadData({
            fieldId: this._id,
            type: EntityType.ACTIVITY,
            quellId: this._sourceId.toString(),
            quellTyp: this.ma.entityType,
            condition: {
              skip: this.skipNotes,
              take: this.takeNotes,
            },
          })
          .subscribe()
          .add(() => {
            this.isLoadingActivities = false;
            this.changeDetectorRef.markForCheck();
          });
        break;
      case 1:
        if (this.initNotes) {
          return;
        }
        this.isLoadingNotes = true;
        this.initNotes = true;
        this.metaActivityRepository
          .loadData({
            fieldId: this._id,
            type: EntityType.NOTE,
            quellId: this._sourceId.toString(),
            quellTyp: this.ma.entityType,
            condition: {
              skip: this.skipNotes,
              take: this.takeNotes,
            },
          })
          .subscribe()
          .add(() => {
            this.isLoadingNotes = false;
            this.changeDetectorRef.markForCheck();
          });
        break;
      case 3:
        if (this.initAppointments) {
          return;
        }
        this.isLoadingAppointments = true;
        this.initAppointments = true;
        this.metaActivityRepository.getUsers();
        this.metaActivityRepository
          .loadData({
            fieldId: this._id,
            type: EntityType.CLOSEDAPPOINTMENT,
            quellId: this._sourceId.toString(),
            quellTyp: this.ma.entityType,
            condition: {
              skip: this.skipNotes,
              take: this.takeNotes,
            },
          })
          .subscribe()
          .add(() => {
            this.isLoadingAppointments = false;
            this.changeDetectorRef.markForCheck();
          });
        break;
      case 4:
        if (this.initCalls) {
          return;
        }
        this.isLoadingCalls = true;
        this.initCalls = true;
        this.metaActivityRepository
          .loadData({
            fieldId: this._id,
            type: EntityType.Call,
            quellId: this._sourceId.toString(),
            quellTyp: this.ma.entityType,
            condition: {
              skip: this.skipCalls,
              take: this.takeCalls,
            },
          })
          .subscribe()
          .add(() => {
            this.isLoadingCalls = false;
            this.changeDetectorRef.markForCheck();
          });
        break;
      case 5:
        if (this.initMarketInfos) {
          return;
        }
        this.isLoadingMoreMarketInfos = true;
        this.initMarketInfos = true;
        this.metaActivityRepository
          .loadData({
            fieldId: this._id,
            type: EntityType.MarketInfo,
            quellId: this._sourceId.toString(),
            quellTyp: this.ma.entityType,
            condition: {
              skip: this.skipMarketInfos,
              take: this.takeMarketInfos,
            },
          })
          .subscribe()
          .add(() => {
            this.isLoadingMarketInfos = false;
            this.changeDetectorRef.markForCheck();
          });
        break;
      case 6:
        if (this.initFiles) {
          return;
        }

        this.initFiles = true;
        this.loadFiles();
        break;
      case 7:
        this.metaHubService.getActivities(this._sourceId.toString(), this.ma.entityType);
        break;
    }
  }

  public beforeUpload(file: any): void {
    //this.uploadForm.get("label").patchValue(file.name.replace(/\.[^/.]+$/, ""));
    //this.uploadForm.get("label").markAsDirty();
  }

  storeSubscription(): void {
    if (this.field) {
      this.options.formState
        .onFormDataReady()
        .pipe(takeUntil(this.destroy$), distinctUntilChanged())
        .subscribe({
          next: (formData) => {
            if (formData) {
              this._sourceId = formData["_id"]?.join(",");
              // this.isLoading = false;
              this.loadActivities();
              this.metaHubService.getActivities(this._sourceId.toString(), this.ma.entityType);
              if (this.ma.showFiles) {
                this.loadFiles();
              }
            }
          },
        });
    } else {
      this.loadActivities();
      this.metaHubService.getActivities(this._sourceId.toString(), this.ma.entityType);
      if (this.ma.showFiles) {
        this.initFiles = true;
        this.loadFiles();
      }
    }
  }

  notesSubscription(): void {
    this.metaActivityRepository.activityState$
      .pipe(
        select((f) => f[this._id]),
        takeUntil(this.destroy$),
        filter((x) => x !== undefined),
      )
      .subscribe((state) => {
        this.activitiesTotal = state.activityTotal;
        this.notesTotal = state.noteTotal;
        this.callsTotal = state.callTotal;
        this.marketInfosTotal = state.marketInfosTotal;
        this.filesTotal = state.filesTotal;
        this.closedAppointmentsTotal = state.closedappointmentTotal;
        this.logsTotal = state.changelogTotal;
      });

    this.metaActivityRepository
      .getData$(EntityType.ACTIVITY, this._id)
      .pipe(
        takeUntil(this.destroy$),
        filter((x) => x !== undefined),
      )
      .subscribe((data) => {
        data = data.filter((e) => e.quellId === this._sourceId);
        this.activities = data.map((o) => {
          let entityType;
          if (o.Notiz) {
            entityType = EntityType.NOTE;
          } else if (o.style) {
            entityType = EntityType.ChangeLog;
          } else if (o.DatenKlasse) {
            entityType = EntityType.Phone;
          } else if (o.Marktinformationen) {
            entityType = EntityType.MarketInfo;
          } else if (o.Start) {
            entityType = EntityType.APPOINTMENT;
          } else if (o.filename) {
            entityType = EntityType.File;
          }
          return this.metaActivityRepository.returnEntityObject(o, entityType);
        });
        this.isSavingNote = false;
        this.activityModel.note = null;
        this.changeDetectorRef.markForCheck();
      });

    this.metaActivityRepository
      .getData$(EntityType.NOTE, this._id)
      .pipe(
        takeUntil(this.destroy$),
        filter((x) => x !== undefined),
      )
      .subscribe((data) => {
        data = data.filter((e) => e.quellId === this._sourceId);
        this.notes = data.map((o) => this.metaActivityRepository.returnEntityObject(o, EntityType.NOTE));
        this.isSavingNote = false;
        this.activityModel.note = null;
        this.changeDetectorRef.markForCheck();
      });

    this.metaActivityRepository
      .getData$(EntityType.APPOINTMENT, this._id)
      .pipe(
        takeUntil(this.destroy$),
        filter((x) => x !== undefined),
      )
      .subscribe((data) => {});

    this.metaActivityRepository
      .getData$(EntityType.CLOSEDAPPOINTMENT, this._id)
      .pipe(
        takeUntil(this.destroy$),
        filter((x) => x !== undefined),
      )
      .subscribe((data) => {});

    this.metaActivityRepository
      .getData$(EntityType.Call, this._id)
      .pipe(
        takeUntil(this.destroy$),
        filter((x) => x !== undefined),
      )
      .subscribe((data) => {});

    this.metaActivityRepository
      .getData$(EntityType.MarketInfo, this._id)
      .pipe(
        takeUntil(this.destroy$),
        filter((x) => x !== undefined),
      )
      .subscribe((data) => {
        data = data.filter((e) => e.quellId === this._sourceId);
        this.marketInfos = data.map((o) => this.metaActivityRepository.returnEntityObject(o, EntityType.MarketInfo));
        this.changeDetectorRef.markForCheck();
      });

    this.metaActivityRepository
      .getData$(EntityType.File, this._id)
      .pipe(
        takeUntil(this.destroy$),
        filter((x) => x !== undefined),
      )
      .subscribe((data) => {});

    this.metaActivityRepository
      .getData$(EntityType.ChangeLog, this._id)
      .pipe(
        takeUntil(this.destroy$),
        filter((x) => x !== undefined),
      )
      .subscribe((data) => {});
  }

  public onSaveNote(): void {
    this.isSavingNote = true;
    this.metaActivityRepository
      .createNote(
        {
          fieldId: this._id,
          type: EntityType.NOTE,
          quellId: this._sourceId.toString(),
          quellTyp: this.ma.entityType,
          condition: {
            skip: this.skip,
            take: this.take,
          },
        },
        this.activityModel.note,
      )
      .subscribe();
  }

  public onLoadMoreActivities(): void {
    this.isLoadingMoreActivities = true;
    this.skip = this.skip + this.take;
    this.metaActivityRepository
      .loadData({
        fieldId: this._id,
        type: EntityType.ACTIVITY,
        quellId: this._sourceId.toString(),
        quellTyp: this.ma.entityType,
        condition: {
          skip: this.skip,
          take: this.take,
        },
      })
      .subscribe()
      .add(() => {
        this.isLoadingMoreActivities = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  public onLoadMoreNotes(): void {
    this.isLoadingMoreNotes = true;
    this.skipNotes = this.skipNotes + this.takeNotes;
    this.metaActivityRepository
      .loadData({
        fieldId: this._id,
        type: EntityType.NOTE,
        quellId: this._sourceId.toString(),
        quellTyp: this.ma.entityType,
        condition: {
          skip: this.skipNotes,
          take: this.takeNotes,
        },
      })
      .subscribe()
      .add(() => {
        this.isLoadingMoreNotes = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  public onLoadMoreCalls(): void {
    this.isLoadingMoreCalls = true;
    this.skipCalls += this.takeCalls;
    this.metaActivityRepository
      .loadData({
        fieldId: this._id,
        type: EntityType.Call,
        quellId: this._sourceId.toString(),
        quellTyp: this.ma.entityType,
        condition: {
          skip: this.skipCalls,
          take: this.takeCalls,
        },
      })
      .subscribe()
      .add(() => {
        this.isLoadingMoreCalls = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  public onLoadMoreMarketInfos(): void {
    this.isLoadingMoreMarketInfos = true;
    this.skipMarketInfos += this.takeMarketInfos;
    this.metaActivityRepository
      .loadData({
        fieldId: this._id,
        type: EntityType.MarketInfo,
        quellId: this._sourceId.toString(),
        quellTyp: this.ma.entityType,
        condition: {
          skip: this.skipMarketInfos,
          take: this.takeMarketInfos,
        },
      })
      .subscribe()
      .add(() => {
        this.isLoadingMoreMarketInfos = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  public onLoadMoreLogs(): void {
    this.isLoadingMoreLogs = true;
    this.skipLogs += this.takeLogs;
    this.metaActivityRepository
      .loadData({
        fieldId: this._id,
        type: EntityType.Log,
        quellId: this._sourceId.toString(),
        quellTyp: this.ma.entityType,
        condition: {
          skip: this.skipLogs,
          take: this.takeLogs,
        },
      })
      .subscribe()
      .add(() => {
        this.isLoadingMoreLogs = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  public async onSaveFile() {
    /*this.isUploadingFile = true;
    const res = await this._metaDocumentService.uploadFile(
      null,
      this.uploadForm,
      this.uploadForm.get("label").value +
        "." +
        this.uploadForm
          .get("file")
          .value[0].name.substr(this.uploadForm.get("file").value[0].name.lastIndexOf(".") + 1),
      this.uploadForm.get("description").value,
      this.uploadForm.get("classification").value,
      this.uploadForm.get("validUntil").value,
      this.maParentFormId && this._sourceId.toString() ? this.maParentFormId : null,
      this._sourceId.toString() ? this._sourceId.toString() : null
    );
    this.uploadForm.reset();
    this.isUploadingFile = false;
    this._messageService.success("Datei erfolgreich hochgeladen.");*/
  }

  public onLoadMoreClosedAppointments(): void {
    this.isLoadingMoreClosedAppointments = true;
    this.skipClosedAppointments = this.skipClosedAppointments + this.takeClosedAppointments;
    this.metaActivityRepository
      .loadData({
        fieldId: this._id,
        type: EntityType.CLOSEDAPPOINTMENT,
        quellId: this._sourceId.toString(),
        quellTyp: this.ma.entityType,
        condition: {
          skip: this.skipClosedAppointments,
          take: this.takeClosedAppointments,
        },
      })
      .subscribe()
      .add(() => {
        this.isLoadingMoreClosedAppointments = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  public async searchOrganisations(searchString: string) {
    this.searchOrgas = searchString;
    this.skipOrgas = 0;
    this.organisations = [];
    this.organisations = await this.metaHelperService.getOrganisations(
      this.skipOrgas,
      this.takeOrgas,
      this.searchOrgas,
    );
  }

  public async loadMoreOrganisations() {
    this.skipOrgas = this.skipOrgas + this.takeOrgas;
    const result = await this.metaHelperService.getOrganisations(this.skipOrgas, this.takeOrgas, this.searchOrgas);
    this.organisations = this.organisations.concat(result);
  }

  public async onAddFiles() {
    const options: IMetaMediaLibrarySelectFileOption = {};
    const newFiles = await this._metaMediaLibraryService.selectMultipleFiles(options);
    await this.metaActivityRepository.assignFiles(
      newFiles.map((nf) => nf.id),
      this._sourceId.toString(),
      this.ma.entityType,
    );
    this.loadActivities();
    this.loadFiles();
  }

  public async onUnassign(field, item) {
    await this.metaActivityRepository.unassignFiles(item.file.id, item.data.sourceId, item.data.sourceType);
    this.files = this.files.filter((o) => o.ID !== item.file.id);
    this.loadActivities();
    this.changeDetectorRef.markForCheck();
  }

  private _createActionsMenu(): void {
    this.actionMenu = [
      {
        label: "Termin bearbeiten",
        icon: "pencil",
        //action: { action: "edit" },
      },
      {
        label: "Als erledigt markieren",
        icon: "check",
        //action: { action: "close" },
      },
    ];
  }

  private loadFiles() {
    this.isLoadingFiles = true;
    this.metaActivityRepository
      .loadData({
        fieldId: this._id,
        type: EntityType.File,
        quellId: this._sourceId?.toString(),
        quellTyp: this.ma.entityType,
        condition: {
          skip: this.skipFiles,
          take: this.takeFiles,
        },
      })
      .subscribe((data) => {
        this.files = data["resultSet"].map((o) => this.metaActivityRepository.returnEntityObject(o, EntityType.File));
      })
      .add(() => {
        this.isLoadingFiles = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  private loadActivities() {
    this.isLoadingActivities = true;
    this.metaActivityRepository
      .loadData({
        fieldId: this._id,
        type: EntityType.ACTIVITY,
        quellId: this._sourceId?.toString(),
        quellTyp: this.ma.entityType,
        condition: {
          skip: this.skipNotes,
          take: this.takeNotes,
        },
      })
      .subscribe()
      .add(() => {
        this.isLoadingActivities = false;
        this.changeDetectorRef.markForCheck();
      });
  }
}

@NgModule({
  declarations: [MetaActivityComponent],
  imports: [
    CommonModule,
    FormlyModule,
    MetaGroupModule,
    MetaEditorModule,
    MetaSkeletonModule,
    MetaButtonModule,
    MetaSectionModule,
    MetaTimelineModule,
    MetaDrodpownModule,
    NzTabsModule,
    FormsModule,
    MetaPillModule,
    MetaLoaderModule,
    MetaInputModule,
    MetaTaskFormModule,
    MetaTaskListModule,
    MetaMediaLibraryModule,
    NzButtonModule,
    NzDropDownModule,
  ],
  providers: [MetaHubService],
  exports: [MetaActivityComponent],
})
export class MetaActivityModule {}
