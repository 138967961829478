/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, DestroyRef, forwardRef, NgModule, ViewEncapsulation } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { FormlyModule } from "@ngx-formly/core";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzTypographyModule } from "ng-zorro-antd/typography";
import { debounceTime } from "rxjs/operators";
import { MetaComponentBase, MetaFormBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaActionHandlerFactory } from "../../base/metaForm/actions/actionHandler.factory";
import { MetaFormService } from "../../base/metaForm/metaForm.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaFieldWrapperModule } from "../metaFieldWrapper/metaFieldWrapper.component";
import { MetaSwitchModule } from "../metaSwitch/metaSwitch.component";

export class MetaTextarea extends MetaFormBase {
  rows? = 4;
  copyable? = true;
  placeholder? = "";
  canReplace?: boolean;
  onChange?: boolean;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-textarea",
  template: `
    <ng-container *ngIf="editing; else outputTpl">
      <ng-container *ngIf="field; else ngModelTpl">
        <ng-container *ngIf="isReplacer; else elseReplacer">
          <textarea
            nz-input
            [(ngModel)]="pattern"
            [rows]="ma.rows"
            (ngModelChange)="onReplacePatternChangeEvent($event)"
            [placeholder]="'Nach welchem Wert soll gesucht werden?'"
            [formlyAttributes]="field"
            [style.width.%]="50"
          ></textarea>
          <textarea
            nz-input
            [(ngModel)]="replacer"
            [rows]="ma.rows"
            (ngModelChange)="onReplaceValueChangeEvent($event)"
            [placeholder]="'Wie lautet der neue Wert?'"
            [formlyAttributes]="field"
            [style.width.%]="50"
          ></textarea>
        </ng-container>
        <ng-template #elseReplacer>
          <textarea
            *ngIf="field; else ngModelTpl"
            nz-input
            [rows]="ma.rows"
            [placeholder]="ma.placeholder || ''"
            [formControl]="fc"
            [formlyAttributes]="field"
          ></textarea>
        </ng-template>
        <meta-field-wrapper
          *ngIf="ma.canReplace"
          [maParams]="{
            labelWidth: 4,
            inputWidth: 20,
            description:
              'Wenn Aktiv, wird nicht der komplette Inhalt des Feldes überschrieben, sondern nur ein bestimmter angegebener Text mit einem anderen Text ersetzt.',
            label: 'Teilwert ändern'
          }"
        >
          <meta-switcher
            [maParams]="{
              disabled: ma.disabled,
              editing: true
            }"
            [(ngModel)]="isReplacer"
            (ngModelChange)="onCanReplaceChange($event)"
          ></meta-switcher>
        </meta-field-wrapper>
      </ng-container>
      <ng-template #ngModelTpl>
        <textarea
          nz-input
          [placeholder]="ma.placeholder || ''"
          [(ngModel)]="value"
          [rows]="ma.rows"
          (ngModelChange)="onChange($event)"
        ></textarea>
      </ng-template>
    </ng-container>
    <ng-template #outputTpl>
      <ng-container *ngIf="field && model ? model[id] : value as content; else emptyTemplate">
        <p [nzCopyable]="ma.copyable" class="output" nz-paragraph nzContent="{{ content }}"></p>
      </ng-container>
      <ng-template #emptyTemplate>-</ng-template>
    </ng-template>
  `,
  styleUrls: ["./metaTextarea.component.less"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetaTextareaComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaTextareaComponent extends MetaComponentBase {
  public isReplacer: boolean;
  public pattern: string;
  public replacer: string;

  constructor(
    private readonly _metaFormService: MetaFormService,
    private readonly _metaActionHandler: MetaActionHandlerFactory,
  ) {
    super();
    super.maParams = new MetaTextarea();
  }

  get ma(): MetaTextarea {
    return super.ma;
  }

  public onCanReplaceChange(value: boolean) {
    this.pattern = null;
    this.replacer = null;
    this.formControl.patchValue(null);
  }

  public onReplacePatternChangeEvent(value: string) {
    this.formControl.patchValue({
      pattern: value,
      value: this.replacer || null,
    });
  }

  public onReplaceValueChangeEvent(value: string) {
    this.formControl.patchValue({
      pattern: this.pattern || null,
      value: value,
    });
  }

  public ngOnInit() {
    super.ngOnInit();
    if (this.field) {
      this.formControl.valueChanges.pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
        if (this.formControl.pristine) {
          return;
        }
        if (this.ma.onChange) {
          let model = this.formState.data();
          if (Object.keys(model).length === 0) {
            model = this.form.value;
          }
          this._metaActionHandler
            .executeChangeAction({
              formId: this.formState.formId,
              controlId: this.id,
              data: {
                ...model,
                [this.id]: value,
              },
              subFormPath: this.metaHelperService.getFormlySubFormPath(this.field),
              ctx: model?._ctx,
              index: this.metaHelperService.getFormlyFieldArrayIndex(this.field),
            })
            .then(
              () => {},
              () => {},
            );
        }
      });
    }
  }
}

@NgModule({
  declarations: [MetaTextareaComponent],
  imports: [
    CommonModule,
    FormlyModule,
    NzInputModule,
    ReactiveFormsModule,
    FormsModule,
    NzTypographyModule,
    MetaFieldWrapperModule,
    MetaSwitchModule,
  ],
  exports: [MetaTextareaComponent],
})
export class MetaTextareaModule {}
