/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Expose } from "class-transformer";
import {
  ExposeProperty,
  FormlySanitizeTemplate,
  MetaFormComponent,
  MetaFormComponentInfo,
  MetaFormComponentProperty,
} from "./baseComponent";
import { IsOptional } from "class-validator";
import { MetaFormLayoutComponent } from "./layoutComponent";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { customAlphabet } from "nanoid";
import { deepMerge } from "../deepMerge";
import { MetaFormButtonComponent } from "./buttonComponent";
export const nanoid = customAlphabet("1234567890abcdefghijklmnopgrstufwxyz", 6);

@MetaFormComponentInfo({
  selector: "ma-tab",
  type: "tab",
  name: "Tab",
  icon: "browser",
  hideInPallet: true,
})
export class TabComponent<Sources = {}> extends MetaFormLayoutComponent {
  @MetaFormComponentProperty({
    name: "Name",
    isTemplate: false,
    type: "text",
    tab: "general",
  })
  public label: string;
  @MetaFormComponentProperty({
    name: "Deaktiviert",
    type: "boolean",
    default: false,
    tab: "general",
  })
  public disabled: string | boolean = false;
  @ExposeProperty() public parentId: string = null;
  @ExposeProperty() public condition: string = null;

  @MetaFormComponentProperty({
    name: "Befüllungswerte berechnen",
    type: "boolean",
    default: false,
    hidden: true,
  })
  public calculateFillingLevel = false;
  @MetaFormComponentProperty({
    name: "Verzögertes Laden",
    type: "boolean",
    default: false,
    hidden: true,
  })
  public lazyLoad = true;
  @MetaFormComponentProperty({
    name: "Verzögertes Laden bis zur ersten Aktivierung",
    type: "boolean",
    default: false,
    hidden: true,
  })
  public lazyLoadUntilFirstActivation = false;

  @ExposeProperty()
  public children: MetaFormComponent[] = [];

  @MetaFormComponentProperty({
    name: "Aktionen",
    type: "children",
    allowedChildren: ["button"],
    tab: "hooks",
  })
  public actions: MetaFormButtonComponent[] = [];

  public static create(opts: Partial<TabComponent>) {
    const row = new TabComponent();
    Object.assign(row, opts);
    return row;
  }

  public static createDefault() {
    const tab = new TabComponent();
    tab.label = "New Tab";
    tab.id = `tab-${nanoid()}`;
    return tab;
  }

  public toFormly(config: IFormUserConfiguration): any {
    const hidden = !!config.components?.[this.id]?.hidden;
    return {
      id: this.id,
      disabled: true,
      props: {
        label: config.sanitizeTemplates ? FormlySanitizeTemplate(this.label) : this.label,
        lazyLoad: this.lazyLoad,
        lazyLoadUntilFirstActivation: this.lazyLoadUntilFirstActivation,
        disabled: true,
        actions: this.actions.map((e) => e.toFormly(config)),
      },
      expressions: {
        "props.editing": `formState.state() >=  10 && formState.state() <= 30`,
        "props.disabled": hidden
          ? true
          : `formState?.displayData() && formState.displayData()[field.id] && formState.displayData()[field.id].disabledCondition !== undefined ? formState.displayData()[field.id].disabledCondition : false`,
      },
      fieldGroup: this.children.map((e) => e.toFormly(config)).filter((e) => !!e),
    };
  }
}

@MetaFormComponentInfo({
  selector: "ma-tabs",
  type: "tabs",
  name: "Tabs",
  icon: "browsers",
  palletGroup: "layout",
  allowedChildren: ["tab"],
})
export class TabsComponent<Sources = {}> extends MetaFormLayoutComponent {
  @MetaFormComponentProperty({
    name: "Tabs",
    type: "children",
    tab: "general",
    allowedChildren: ["tab"],
  })
  public children: TabComponent[] = [];

  @ExposeProperty() public routerTabs?: boolean;
  @ExposeProperty() public hideTabs? = false;
  @ExposeProperty() public filterable? = true;

  @MetaFormComponentProperty({
    name: "Initialer Tab",
    type: "number",
    tab: "other",
    required: true,
    min: 0,
    max: 99,
    default: () => 0,
  })
  public initialTabIndex?: number;
  @ExposeProperty() public tabsPosition?: "top" | "right" | "bottom" | "left" = "top";

  @ExposeProperty() public style?: "primary" | "secondary" = "primary";

  public static create(opts: Partial<TabsComponent>) {
    const row = new TabsComponent();
    Object.assign(row, opts);
    return row;
  }

  public static createDefault() {
    const tabSet = new TabsComponent();
    tabSet.id = `tabs-${nanoid()}`;
    tabSet.children = [TabComponent.createDefault()];
    return tabSet;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        // Set routerTabs to undefined if not set in form-definition
        // If routerTabs is undefined, it gets overwritten in page.ts when ma-tabs is the first child of the page
        routerTabs: this.routerTabs !== undefined ? this.routerTabs : undefined,
        hideTabs: this.hideTabs,
        filterable: this.filterable,
        tabsPosition: this.tabsPosition,
        initialCreateTab: this.initialTabIndex || 0,
        style: this.style,
      },
    });
  }
}
