/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { clamp } from "lodash";

export interface IFormatNumberArgs {
  shorten?: boolean;
  id?: boolean;
  digits?: number;
  minDigits?: number;
  locale?: string;
}

export function formatNumber(value: any, args: IFormatNumberArgs = {}): string {
  if (value === null || value === undefined || Number.isNaN(value)) {
    return "k.A.";
  }
  if (args.shorten) {
    const abs = Math.abs(value);
    if (abs === 0) {
      return value;
    }
    if (abs >= Math.pow(10, 12)) {
      // trillion
      return (value / Math.pow(10, 12)).toFixed(1) + "T";
    } else if (abs < Math.pow(10, 12) && abs >= Math.pow(10, 9)) {
      // billion
      return (value / Math.pow(10, 9)).toFixed(1) + "B";
    } else if (abs < Math.pow(10, 9) && abs >= Math.pow(10, 6)) {
      // a million
      return (value / Math.pow(10, 6)).toFixed(1) + "M";
    } else if (abs < Math.pow(10, 6) && abs >= Math.pow(10, 3)) {
      // a thousand
      return (value / Math.pow(10, 3)).toFixed(1) + "K";
    } else if (abs < Math.pow(10, 3) && abs >= Math.pow(10, 0)) {
      // a thousand
      return (value / Math.pow(10, 0)).toFixed(1);
    }
  }

  const numberFormatter = new Intl.NumberFormat(args.locale || "de-DE", {
    maximumFractionDigits: clamp(args.digits === undefined ? 20 : args.digits, 0, 20),
    minimumFractionDigits: clamp(args.minDigits === undefined ? 0 : args.minDigits, 0, 20),
  });
  return numberFormatter.format(value);
}
