/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { CommonModule, NgIf } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  effect,
  EventEmitter,
  Inject,
  Input,
  NgModule,
  NO_ERRORS_SCHEMA,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Signal,
  signal,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { MetaState } from "@meta/enums";
import { NzBadgeComponent } from "ng-zorro-antd/badge";
import { NzButtonComponent, NzButtonGroupComponent } from "ng-zorro-antd/button";
import { NzDescriptionsComponent, NzDescriptionsItemComponent } from "ng-zorro-antd/descriptions";
import { NzTagComponent } from "ng-zorro-antd/tag";
import { MetaAvatarModule } from "../../components/metaAvatar/metaAvatar.component";
import { FormlyModule } from "@ngx-formly/core";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { NzColDirective, NzRowDirective } from "ng-zorro-antd/grid";
import { NzModalFooterDirective, NzModalRef, NzModalTitleDirective } from "ng-zorro-antd/modal";
import { NzPopoverDirective } from "ng-zorro-antd/popover";
import { NzResizableDirective, NzResizeHandlesComponent } from "ng-zorro-antd/resizable";
import { NzTabComponent, NzTabDirective, NzTabSetComponent } from "ng-zorro-antd/tabs";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { Socket } from "ngx-socket-io";
import { combineLatest, Observable, of, skip } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, shareReplay } from "rxjs/operators";
import { PAGE_TITLE } from "../../../../../../apps/meta-argon/src/app/app.module";
import { MetaButtonModule } from "../../components/metaButton/metaButton.component";
import { MetaDocumentViewModule } from "../../components/metaDocumentView/metaDocumentView.component";
import { MetaDrodpownModule } from "../../components/metaDropdown/metaDropdown.component";
import { MetaEditorModule } from "../../components/metaEditor/metaEditor.module";
import { MetaEmptyModule } from "../../components/metaEmpty/metaEmpty.component";
import { MetaErrorModule } from "../../components/metaError/metaError.module";
import { MetaFieldWrapperModule } from "../../components/metaFieldWrapper/metaFieldWrapper.component";
import { MetaFormHeaderModule } from "../../components/metaFormHeader/metaFormHeader.component";
import { MetaIconModule } from "../../components/metaIcon/metaIcon.component";
import { MetaInputModule } from "../../components/metaInput/metaInput.component";
import { MetaListModule } from "../../components/metaList/metaList.component";
import { MetaLoaderModule } from "../../components/metaLoader/metaLoader.component";
import { MetaSectionModule } from "../../components/metaSection/metaSection.component";
import { MetaSelectModule } from "../../components/metaSelect/metaSelect.component";
import { MetaToolbarModule } from "../../components/metaToolbar/metaToolbar.component";
import { DirtyCheckService } from "../../services/dirtyCheckService";
import { MetaEventService } from "../../services/metaEvents.service";
import { MetaFormData } from "../metaForm/metaForm.interface";
import { MetaActionHandlerFactory } from "./actions/actionHandler.factory";
import { LocalActionHandlerFactory } from "./actions/actionHandler.factory.local";
import { GetSelectionActionHandler } from "./actions/getSelection.action";
import { InitializeFormActionHandler } from "./actions/initializeForm.action";
import { ReloadFormActionHandler } from "./actions/reload.action";
import { SaveActionHandler } from "./actions/save.action";
import { SetDisplayValueActionHandler } from "./actions/setDisplayValue.action";
import { SetValueActionHandler } from "./actions/setValue.action";
import { UpdateDisplayValuesActionHandler } from "./actions/updateDisplayValues.action";
import { MetaFormService } from "./metaForm.service";
import { FormSupervisorService } from "./metaForm.supervisor";
import { MetaFormActiveUsersComponent } from "./toolbarControls/metaForm.activeUsers";
import { MetaFormAdditionalActionsComponent } from "./toolbarControls/metaForm.additionalActions";
import { MetaFormCreateComponent } from "./toolbarControls/metaForm.create";
import { MetaFormEditComponent } from "./toolbarControls/metaForm.edit";
import { MetaFormPublishComponent } from "./toolbarControls/metaForm.publish";
import { MetaFormWorkflowsComponent } from "./toolbarControls/metaForm.workflows";
import { MetaFormAppComponent } from "./types/metaForm.app";
import { MetaFormBaseComponent } from "./types/metaForm.base";
import { MetaFormReportComponent } from "./types/metaForm.report";
import { MetaFormSplitviewComponent } from "./types/metaForm.splitview";
import { MetaFormWizardComponent } from "./types/metaForm.wizard";

@Component({
  selector: "meta-form",
  template: `<!------------------>
    <!---- TOOLBAR ----->
    <!------------------>
    @if (!maHideToolbar) {
      <meta-toolbar
        [maParams]="
          metaFormService.formState.loadedAsSubform && !maForceFullToolbar
            ? { toolbarTargetId: maToolbarTarget, showBackButton: false }
            : {
                label: metaFormService.formState.form().label,
                sublabel: sublabel(),
                icon: metaFormService.formState.form().icon,
                toolbarTargetId: maToolbarTarget,
                searchFormId:
                  !modalRef?.componentInstance && !metaFormService.formState.loadedAsSubform
                    ? metaFormService.formState.form().searchable
                    : null
              }
        "
        [loadedAsModal]="!!metaFormService.formState.modalRef"
      >
        @if (metaFormService.formState.form().creatable && !metaFormService.formState.form().editable) {
          <meta-form-create [options]="metaFormService.options"></meta-form-create>
        }
        @if (
          metaFormService.formState.form().editable &&
          metaFormService.formState.form().hasDataSource &&
          metaFormService.formState.form().type !== "wizard"
        ) {
          <meta-form-edit
            [options]="metaFormService.options"
            [modalRef]="modalRef"
            [maCloseModalAfterCancel]="maCloseModalAfterCancel"
            [maCloseModalAfterSave]="maCloseModalAfterSave"
          ></meta-form-edit>
        }
        @if (!maHideWorkflows) {
          <meta-form-publish [options]="metaFormService.options"></meta-form-publish>
          <meta-form-workflows [options]="metaFormService.options"></meta-form-workflows>
        }
        <meta-form-additional-actions [options]="metaFormService.options"></meta-form-additional-actions>
        <meta-form-active-users [options]="metaFormService.options"></meta-form-active-users>
        <ng-template #toolbar></ng-template>
      </meta-toolbar>
    }
    <div class="modal-title" *nzModalTitle>
      <meta-form-header [targetId]="metaFormService.formState.formId" headerType="modal" />
    </div>
    <ng-template #fillingLevel></ng-template>
    <!------------------>
    <!------ BODY ------>
    <!------------------>
    <ng-container *ngIf="metaFormService.formState.error()">
      <meta-error
        [isWidget]="metaFormService.formState.loadedAsModal || metaFormService.formState.loadedAsSubform"
        [error]="metaFormService.formState.error()"
      ></meta-error>
    </ng-container>
    <ng-container
      *ngIf="
        !metaFormService.formState.error() &&
        metaFormService.formState.formGroup() &&
        metaFormService.formState.form() as form
      "
    >
      @defer (on viewport) {
        @switch (form.type) {
          @case ("app") {
            <meta-form-app />
          }
          @case ("report") {
            <meta-form-report [maPdfOptions]="maPdfOptions" [maHideActivity]="maHideActivity" />
          }
          @case ("wizard") {
            <meta-form-wizard />
          }
          @case ("splitview") {
            <meta-form-splitview />
          }
          @default {
            <meta-form-base />
          }
        }
      } @placeholder {
        <p>…</p>
      }
    </ng-container>`,
  styleUrls: ["./metaForm.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    MetaFormService,
    LocalActionHandlerFactory,
    InitializeFormActionHandler,
    ReloadFormActionHandler,
    SaveActionHandler,
    SetDisplayValueActionHandler,
    SetValueActionHandler,
    GetSelectionActionHandler,
    UpdateDisplayValuesActionHandler,
  ],
})
export class MetaFormComponent implements OnInit, OnDestroy, OnChanges {
  // This will be used to check in other classes for this specific class without import it to prevent circular dependencies
  static readonly componentIdentifier = "MetaFormComponent";
  @Input() public maFormParams: any;
  @Input() public maTabId: number;
  @Input() public maHideToolbar: boolean;
  @Input() public maHideWorkflows? = false;
  @Input() public maHideActivity? = false;
  @Input() public maEditing?: boolean = false;
  @Input() public maLoadDataWithoutItemId?: boolean = false;
  @Input() public maToolbarTarget: string;
  @Input() public maNoBrowserTitle: boolean;
  @Input() public maCloseModalAfterCancel: boolean;
  @Input() public maCloseModalAfterSave: boolean;
  @Input() public maPdfOptions?: {
    toolbar?: string;
    fit?: string;
    navPanes?: string;
    pageMode?: string;
    additionalData?: Record<string, any>;
  };
  @Output() public maOnSelectedItemsChange = new EventEmitter<any>();
  @Output() public maOnModelChange = new EventEmitter<any>();
  @ViewChild("toolbar", { read: ViewContainerRef }) toolbar: ViewContainerRef;
  @ViewChild("fillingLevel", { read: ViewContainerRef }) fillingLevel: ViewContainerRef;
  /**
   * Represents an object of angular form inputs generated from the formly config.
   */
  public loading = signal<boolean>(false);
  public MetaState = MetaState;
  public fillingLevelComponent;
  public readonly title: Signal<string> = computed(() => {
    return "<span>" + (this.metaFormService.formState.metaData()?.title || "") + "</span>";
  });
  public readonly subtitle: Signal<string> = computed(() => {
    let subtitle = "";
    if (this.metaFormService.formState.metaData()?.subtitlePrefix) {
      if (this.metaFormService.formState.metaData()?.subtitlePrefixType === "progress") {
        subtitle += '<span class="cell-prefix pr-1">';
        switch (this.metaFormService.formState.metaData()?.subtitlePrefix) {
          case "1":
            subtitle += `<i class="fas fa-circle-quarter text-color-warning"></i>`;
            break;
          case "2":
            subtitle += `<i class="fas fa-circle-half text-color-warning"></i>`;
            break;
          case "3":
            subtitle += `<i class="fas fa-circle-three-quarters text-color-warning"></i>`;
            break;
          case "4":
            subtitle += `<i class="fas fa-circle-check text-color-success"></i>`;
            break;
          case "error":
          case "red":
            subtitle += `<i class="fas fa-circle text-color-error"></i>`;
            break;
          case "lock":
            subtitle += `<i class="fas fa-lock text-color-error"></i>`;
            this.metaFormService.formState.metaData().subtitle = "<strong class='text-color-error'>Gesperrt</strong>";
            break;
        }
        subtitle += `<i class="fas fa-circle cell-progress-bg text-color-warning"></i>`;
        subtitle += "</span>";
      }
    }
    subtitle += this.metaFormService.formState.metaData()?.subtitle || "";
    return subtitle.length > 0 ? "<div class='subtitle'>" + subtitle + "</div>" : "";
  });
  public readonly sublabel: Signal<string> = computed(() => {
    try {
      return this.title() + this.subtitle();
    } catch (e) {
      return "";
    }
  });

  constructor(
    public readonly metaFormService: MetaFormService,
    private readonly _titleService: Title,
    private readonly _socket: Socket,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _route: ActivatedRoute,
    private readonly _dirtyCheckService: DirtyCheckService,
    private readonly _metaEventService: MetaEventService,
    private readonly _localActionHandlerFactory: LocalActionHandlerFactory,
    private readonly _actionHandlerFactory: MetaActionHandlerFactory,
    private readonly _supervisor: FormSupervisorService,
    private fb: UntypedFormBuilder,
    @Inject(DestroyRef) private _destroyRef: DestroyRef,
    @Optional() public modalRef: NzModalRef,
  ) {
    this._route.queryParams.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.metaFormService.pdfPreviewOptions["locale"] = this._route.snapshot.queryParams.locale || null;
      this.metaFormService.formState.pdfPreviewOptions.next(this.metaFormService.pdfPreviewOptions);
    });
    this.metaFormService.formState.data$ = toObservable(this.metaFormService.formState.data);
    this.metaFormService.formState.displayData$ = toObservable(this.metaFormService.formState.displayData);
    this.metaFormService.formState.metaData$ = toObservable(this.metaFormService.formState.metaData);
    this.metaFormService.formState.state$ = toObservable(this.metaFormService.formState.state);
    this.metaFormService.formState.selectedItems$ = toObservable(this.metaFormService.formState.selectedItems);
    this.metaFormService.formState.modalRef = modalRef;
    this._metaEventService.reloadTrigger$
      .pipe(
        debounceTime(50),
        filter((x) => x !== undefined && x !== null),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe({
        next: async (trigger) => {
          if (trigger.specific) {
            if (trigger.formId === this.metaFormService.formState.formId) {
              await this.metaFormService.getFormData({
                itemId: this.metaFormService.formState.itemId(),
                silent: false,
              });
            }
          } else {
            await this.metaFormService.getForm({
              formId: this.metaFormService.formState.formId,
              route: this.metaFormService.formState.formRoute(),
            });
            await this.metaFormService.getFormData({
              itemId: this.metaFormService.formState.itemId(),
              silent: false,
            });
          }
        },
      });

    this._metaEventService.changeTrigger$
      .pipe(
        debounceTime(250),
        filter((x) => x !== undefined && x.formId === this.metaFormService.formState.formId),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe({
        next: async () => {
          this.metaFormService.updateDisplayValues();
        },
      });

    combineLatest([this.metaFormService.formState.data$, this.metaFormService.formState.state$])
      .pipe(skip(1), debounceTime(250), distinctUntilChanged(), takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: (data) => {
          this.metaFormService.updateDisplayValues();
        },
      });
    effect(() => {
      const meta = this.metaFormService.formState.metaData();
      if (this.fillingLevelComponent) {
        this.fillingLevelComponent.instance.metaData = this.metaFormService.formState.metaData;
      }
    });
    effect(() => {
      this.maOnSelectedItemsChange.emit(this.metaFormService.formState.selectedItems());
    });
    effect(() => {
      const displayData = this.metaFormService.formState.displayData();
      this._checkFormlyExpressions();
    });
  }

  @Input()
  set maFormId(value: any) {
    this.metaFormService.formState.formId = value;
    this._localActionHandlerFactory.registerFormService(this.metaFormService.formState.formId, this._destroyRef);
  }

  get maFormId() {
    return this.metaFormService.formState.formId;
  }

  @Input()
  set maForm(value: any) {
    this.metaFormService.formState.form.set(value);
    this.metaFormService.formState.workflows.set({ workflows: [], executions: [] });
  }

  @Input()
  set maItemId(value: any) {
    this.metaFormService.formState.itemId.set(value);
  }

  @Input()
  set maFormRoute(value: any) {
    this.metaFormService.formState.formRoute.set(value);
  }

  @Input()
  set maExternalData(value: any) {
    this.metaFormService.formState.externalData.set(value);
  }

  @Input()
  set maLoadedAsModal(value: any) {
    this.metaFormService.formState.loadedAsModal = value;
  }

  @Input()
  set maLoadedAsSubform(value: any) {
    this.metaFormService.formState.loadedAsSubform = value;
  }

  @Input()
  set maLoadedAsWidget(value: any) {
    this.metaFormService.formState.loadedAsWidget = value;
  }

  @Input()
  public maForceFullToolbar = false;

  async ngOnInit() {
    // Creates a new Control for each Data Key. We have to do this to avoid side effects
    // with formly because it takes some cycles until formly builds the formGroup internally
    this.metaFormService.formState.formGroup.set(this.fb.group({}));
    Object.keys(this.metaFormService.formState.externalData()).forEach((key) => {
      this.metaFormService.formState
        .formGroup()
        .addControl(key, new UntypedFormControl(this.metaFormService.formState.externalData()[key]));
    });
    // Sets the loading indicator so that forms with data to load are not visible
    // until the data is fully loaded. This prevents layout shifts and other side effects like disabled tabs
    if (this.metaFormService.formState.itemId() && this.metaFormService.formState.itemId() !== "create") {
      this.metaFormService.formState.isLoading.set(true);
    }
    if (this.metaFormService.formState.formId) {
      if (!this.metaFormService.formState.form?.()?.id) {
        await this.metaFormService.getForm({
          formId: this.metaFormService.formState.formId,
          route: this.metaFormService.formState.formRoute(),
        });
      }
      this._dirtyCheckService.registerForm(
        this.metaFormService.formState.formGroup(),
        this.metaFormService.formState.form(),
        this.modalRef,
        this._destroyRef,
      );
      if (
        this.modalRef?.componentInstance ||
        (this.metaFormService.formState.loadedAsWidget && !this._route.snapshot?.queryParams?.maximized) ||
        this.maNoBrowserTitle ||
        this.maLoadedAsSubform
      ) {
      } else {
        this._titleService.setTitle(this.metaFormService.formState.form().label + PAGE_TITLE);
      }
    } else {
      throw new Error("Form id is required to create a new form");
    }
    this.onFormDataReady();

    if (
      (this.metaFormService.formState.itemId() || this.maLoadDataWithoutItemId) &&
      this.metaFormService.formState.form().hasDataSource
    ) {
      await this.metaFormService.getFormData({
        itemId: this.metaFormService.formState.itemId(),
      });
      // Check for filling level
      if (
        this.metaFormService.formState.form().showFillingLevel &&
        this.metaFormService.formState.metaData?.()?.fillingLevel &&
        !this.metaFormService.formState.error()
      ) {
        const { MetaFormFillingLevelComponent } = await import("./features/fillingLevel/metaForm.fillingLevel");
        this.fillingLevelComponent = this.fillingLevel.createComponent(MetaFormFillingLevelComponent);
        this.fillingLevelComponent.instance.metaData = this.metaFormService.formState.metaData;
      }
      // Set Page title
      if (!this.modalRef) {
        await this.setPageTitle(this.metaFormService.formState.metaData?.()?.title);
      }
    }

    // Some forms have to start in edit mode like forms that create a new dataset
    // or forms that are opened in a modal and require direct data manipulation like the copy article feature
    if (this.maEditing || this.metaFormService.formState.itemId() === "create") {
      this.metaFormService.formState.state.set(MetaState.editing);
    }

    // Get Versions if form is versionable
    await this.getVersions();

    // Apply externalData to the data signal. External data will always overwrite existing data
    // from the api or from initialization
    if (this.metaFormService.formState.externalData()) {
      this.metaFormService.formState.data.set({
        ...this.metaFormService.formState.data(),
        ...this.metaFormService.formState.externalData(),
      });
    }

    this.metaFormService.formState
      .formGroup()
      .valueChanges.pipe(
        takeUntilDestroyed(this._destroyRef),
        distinctUntilChanged((a: any, b: any) => JSON.stringify(a) === JSON.stringify(b)),
        debounceTime(250),
      )
      .subscribe({
        next: async (f) => {
          this.maOnModelChange.emit({
            model: this.metaFormService.formState.data(),
            form: this.metaFormService.formState.formGroup(),
          });
          this.metaFormService.updateDisplayValues();
        },
      });

    this._socket
      .fromEvent("FORM_FORCE_RELOAD")
      .pipe(
        filter(
          (e: any) =>
            e.formId === this.metaFormService.formState.formId && e.itemId === this.metaFormService.formState.itemId(),
        ),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe((e) => {
        this._dirtyCheckService.unregisterForm(this.metaFormService.formState.formGroup());
        window.location.reload();
      });

    this.subscribeToFormDataReady();

    if (this.metaFormService.formState.form().onOpen) {
      await this._actionHandlerFactory.executeOnOpenAction({
        formId: this.metaFormService.formState.formId,
        data: this.metaFormService.formState.externalData(),
      });
    }

    if (!this.metaFormService.formState.itemId()) {
      this.metaFormService.formState.isLoading.set(false);
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.maItemId?.currentValue !== undefined && !changes.maItemId.firstChange) {
      await this.metaFormService.getFormData({
        itemId: changes?.maItemId?.currentValue,
        silent: false,
      });
      this.getVersions();
    }
  }

  public ngOnDestroy() {
    this._dirtyCheckService.unregisterForm(this.metaFormService.formState.formGroup());
    this._supervisor.unregisterFormService(this.metaFormService.formState.formId);
    if (this.metaFormService.formState.itemId()) {
      this._socket.emit("CLOSE_FORM", {
        form: this.metaFormService.formState.formId,
        itemId: Array.isArray(this.metaFormService.formState.itemId())
          ? this.metaFormService.formState.itemId()
          : this.metaFormService.formState.itemId().split(","),
      });
    }
  }

  onFormDataReady() {
    this.metaFormService.formState.onFormDataReady = (): Observable<any> => {
      if (
        this.metaFormService.formState.itemId() !== undefined &&
        this.metaFormService.formState.itemId() !== null &&
        this.metaFormService.formState.itemId() !== "create"
      ) {
        return this.metaFormService.formState.data$.pipe(
          filter(
            (x) =>
              x &&
              Object.keys(x).length > 0 &&
              Object.getPrototypeOf(x) === Object.prototype &&
              !this.areAllPropertiesNullOrUndefined(x),
          ),
          takeUntilDestroyed(this._destroyRef),
          shareReplay(1),
          //take(1),
        );
      } else {
        return of(null);
      }
    };
  }

  public areAllPropertiesNullOrUndefined(obj: Record<string, any>): boolean {
    return Object.values(obj).every((value) => value === null || value === undefined);
  }

  public async getVersions() {
    if (!this.metaFormService.formState.form()?.versionable || !this.metaFormService.formState.itemId()) {
      return;
    }

    if (this.metaFormService.formState.form()?.versionable && this.metaFormService.formState.itemId() !== "create") {
      this.metaFormService.formState.versionsData.set([]);
      this.metaFormService.formState.versionsData.set(
        await this.metaFormService.getVersions(
          this.metaFormService.formState.formId,
          this.metaFormService.formState.itemId(),
        ),
      );

      switch (this.metaFormService.formState.form()?.versionType) {
        case "numeric":
          this.metaFormService.formState.version.set(1);
          break;
        case "alphabetic":
          this.metaFormService.formState.version.set("A");
          break;
      }
      if (this.metaFormService.formState.versionsData().length > 1) {
        let itemId: any = this.metaFormService.formState.itemId().split(",");
        itemId = isNaN(itemId[itemId.length - 1]) ? itemId[itemId.length - 1] : Number(itemId[itemId.length - 1]);
        this.metaFormService.formState.version.set(itemId);
      }
      this._changeDetectorRef.markForCheck();
    }
  }

  public reload() {
    this._metaEventService.emitReloadTrigger({
      timestamp: Date.now(),
    });
  }

  private subscribeToFormDataReady() {
    this.metaFormService.formState
      .onFormDataReady()
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter((e) => e !== null && e !== undefined),
      )
      .subscribe((data: MetaFormData) => {
        this.metaFormService.formState.isLoading.set(false);
        this._changeDetectorRef.markForCheck();
        if (data && data["_id"]) {
          this._socket.emit("OPEN_FORM", {
            form: this.metaFormService.formState.formId,
            itemId: data["_id"],
          });
        } else {
          this._socket.emit("OPEN_FORM", {
            form: this.metaFormService.formState.formId,
            itemId: [null],
          });
        }
      });
  }

  public async setPageTitle(title: string) {
    if (
      this.modalRef?.componentInstance ||
      (this.metaFormService.formState.loadedAsWidget && !this._route.snapshot?.queryParams?.maximized) ||
      this.maNoBrowserTitle ||
      this.maLoadedAsSubform
    ) {
      return;
    }

    if (!title) {
      this._titleService.setTitle(this.metaFormService.formState.form()?.label + PAGE_TITLE);
    }

    this._titleService.setTitle(
      `${this.metaFormService.formState.form()?.label?.substring(0, 1)} | ${
        (title || "") + " | " + `${this.metaFormService.formState.form()?.label}` + PAGE_TITLE
      }`,
    );
  }

  private _checkFormlyExpressions() {
    if (this.metaFormService.formState.form().children && this.metaFormService.formState.form().children.length > 0) {
      this.metaFormService.formState.form().children.forEach((field) => {
        if (field && field.options) {
          (<any>field.options).checkExpressions(field);
        }
      });
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormlyModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgxTolgeeModule,
    MetaErrorModule,
    MetaToolbarModule,
    MetaButtonModule,
    NzTooltipDirective,
    MetaDrodpownModule,
    NzPopoverDirective,
    MetaDocumentViewModule,
    NzTabSetComponent,
    NzTabComponent,
    NzRowDirective,
    MetaIconModule,
    NzColDirective,
    MetaSectionModule,
    MetaFieldWrapperModule,
    MetaInputModule,
    MetaEditorModule,
    MetaSelectModule,
    MetaLoaderModule,
    NzTabDirective,
    MetaEmptyModule,
    NzResizableDirective,
    NzResizeHandlesComponent,
    MetaListModule,
    MetaFormHeaderModule,
    NzModalTitleDirective,
    NzModalFooterDirective,
    RouterLink,
    MetaAvatarModule,
    NzButtonGroupComponent,
    NzButtonComponent,
    NzDescriptionsComponent,
    NzDescriptionsItemComponent,
    NzBadgeComponent,
    NzTagComponent,
    MetaFormWorkflowsComponent,
  ],
  declarations: [
    MetaFormComponent,
    MetaFormAdditionalActionsComponent,
    MetaFormEditComponent,
    MetaFormCreateComponent,
    MetaFormActiveUsersComponent,
    MetaFormPublishComponent,
    MetaFormBaseComponent,
    MetaFormAppComponent,
    MetaFormReportComponent,
    MetaFormWizardComponent,
    MetaFormSplitviewComponent,
  ],
  exports: [MetaFormComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class MetaFormModule {}
