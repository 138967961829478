/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { Injectable, Injector } from "@angular/core";
import {
  ServerTemplateResponse,
  ServerTemplateResponseCloseFormAction,
  ServerTemplateResponseConfirmAction,
  ServerTemplateResponseDownloadAction,
  ServerTemplateResponseOpenFormAction,
  ServerTemplateResponseRedirectAction,
  ServerTemplateResponseRemoveItemsAction,
  ServerTemplateResponseSetFilterAction,
  ServerTemplateResponseSetParamsAction,
  ServerTemplateResponseUploadAction,
} from "@meta/forms";
import { plainToInstance } from "class-transformer";
import { CloseFormActionHandler } from "./closeForm.action";
import { ConfirmActionHandler } from "./confirm.action";
import { DownloadActionHandler } from "./download.action";
import { OpenFormActionHandler } from "./openForm.action";
import { RedirectActionHandler } from "./redirect.action";
import { RemoveItemActionHandler } from "./removeItem.action";
import { SetFilterActionHandler } from "./setFilter.action";
import { SetParamsActionHandler } from "./setParams.action";
import { UploadActionHandler } from "./upload.action";

// Import other global handlers...

@Injectable({
  providedIn: "root",
})
export class GlobalActionHandlerFactory {
  private handlers = new Map();

  constructor(private injector: Injector) {
    this.handlers.set(ServerTemplateResponseOpenFormAction, this.injector.get(OpenFormActionHandler));
    this.handlers.set(ServerTemplateResponseCloseFormAction, this.injector.get(CloseFormActionHandler));
    this.handlers.set(ServerTemplateResponseRedirectAction, this.injector.get(RedirectActionHandler));
    this.handlers.set(ServerTemplateResponseConfirmAction, this.injector.get(ConfirmActionHandler));
    this.handlers.set(ServerTemplateResponseDownloadAction, this.injector.get(DownloadActionHandler));
    this.handlers.set(ServerTemplateResponseRemoveItemsAction, this.injector.get(RemoveItemActionHandler));
    this.handlers.set(ServerTemplateResponseSetFilterAction, this.injector.get(SetFilterActionHandler));
    this.handlers.set(ServerTemplateResponseSetParamsAction, this.injector.get(SetParamsActionHandler));
    this.handlers.set(ServerTemplateResponseUploadAction, this.injector.get(UploadActionHandler));
  }

  getHandler(actionType: any) {
    return this.handlers.get(actionType);
  }

  public async handleSocketResponse(data: any, ack: CallableFunction) {
    const res = plainToInstance(ServerTemplateResponse, data);
    const resp = await this.handleActionResponse({
      res,
      formId: res.formId,
      subFormPath: res.subFormPath,
      index: res.index,
      passthroughData: res.passthroughData,
    });
    ack(resp);
  }

  async handleActionResponse(opts: {
    res: any;
    formId: string;
    subFormPath?: string[];
    index: number;
    passthroughData: Record<string, any>;
  }) {
    const responses: any[] = [];
    for (const action of opts.res.actions) {
      const globalHandler = this.handlers.get(action.constructor);
      if (globalHandler) {
        const resp = await globalHandler.handle(action, opts);
        responses.push(resp);
      } else {
        console.warn(`No handler found for action type: ${action.constructor.name}`);
      }
    }
    responses.push(Date.now());
    return responses;
  }
}
