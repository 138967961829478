/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaBaseValidator } from "./baseValidator";
import * as validator from "class-validator";
import { MetaFormComponentInfo } from "../components";

export class IsIBicValidatorOptions {}

@MetaFormComponentInfo({
  selector: "ma-is-bic-validator",
  type: "bic-validator",
  name: "BIC/SWIFT Validator",
})
export class IsBicValidator extends MetaBaseValidator {
  constructor(public options?: IsIBicValidatorOptions) {
    super();
  }

  public static createElement(attributes: IsIBicValidatorOptions) {
    return new IsBicValidator(attributes);
  }

  async validate(value): Promise<boolean> {
    return validator.isBIC(value);
  }

  toFormly(formlyFieldConfig: Record<string, any>) {
    return super.toFormly(formlyFieldConfig, true);
  }
}
