/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2021
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2021
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, NgModule } from "@angular/core";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaIconModule } from "../metaIcon/metaIcon.component";

export class IMetaLoader {
  scale? = 60;
  size? = 32;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-loader",
  template: `
    <meta-icon [maParams]="{ icon: 'ymrqtsej', delay: 0, scale: maParams.scale, size: maParams.size }"></meta-icon>
  `,
  styleUrls: ["./metaLoader.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaLoaderComponent {
  private _maParams: IMetaLoader = new IMetaLoader();

  @Input()
  public get maParams(): IMetaLoader {
    return this._maParams;
  }

  public set maParams(value: IMetaLoader) {
    this._maParams = { ...this._maParams, ...value };
  }
}

@NgModule({
  declarations: [MetaLoaderComponent],
  imports: [CommonModule, MetaIconModule],
  exports: [MetaLoaderComponent],
})
export class MetaLoaderModule {}
