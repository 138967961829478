/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaForm } from "./form";
import { Exclude, Expose } from "class-transformer";
import { ExposeProperty, MetaFormComponentInfo, MetaFormComponentProperty } from "../components";
import type { DataSourceType } from "@meta/database/lib/types";
import type { FormEventActionHandler } from "../interfaces";
import { IFormUserConfiguration } from "../formUserConfiguration";

@MetaFormComponentInfo({
  selector: "ma-search",
  type: "search",
  icon: "file-search",
  name: "Suche",
})
export class MetaFormSearch extends MetaForm {
  static selector = "ma-search";

  @MetaFormComponentProperty({
    name: "Layoutvorlagen",
    type: "text",
    tab: "general",
    isTemplate: true,
  })
  public layoutTemplates: string[] | string;
  @ExposeProperty()
  public layoutTemplateRows: number;
  @MetaFormComponentProperty({
    name: "Avatar",
    type: "text",
    tab: "general",
    isTemplate: true,
  })
  public avatar: string;

  @ExposeProperty()
  public onSelect?: FormEventActionHandler;

  @MetaFormComponentProperty({
    name: "Quelle automatisch vervollständigen",
    type: "dataSource",
    tab: "data",
  })
  public autoCompleteSource: DataSourceType;

  @ExposeProperty()
  public autoCompleteLabel: string;

  @ExposeProperty()
  public autoCompleteValue: string;

  @ExposeProperty()
  public autoCompleteSortFields: any;

  @MetaFormComponentProperty({
    name: "Ergebnisse als Tabelle anzeigen",
    type: "boolean",
    tab: "general",
  })
  public showResultsAsTable = false;

  @ExposeProperty()
  public canSelectItemsToDatabase: boolean;

  @MetaFormComponentProperty({
    name: "showFavorites",
    type: "boolean",
    tab: "data",
  })
  public showFavorites: boolean;

  @MetaFormComponentProperty({
    name: "showRecentItems",
    type: "boolean",
    tab: "data",
  })
  public showRecentItems: boolean;

  @ExposeProperty()
  public processSelectedItemsLabel: string;

  public onProcessSelectedItems: FormEventActionHandler;

  @MetaFormComponentProperty({
    name: "Link",
    type: "text",
    tab: "general",
  })
  @ExposeProperty()
  public link: string;

  @ExposeProperty()
  public searchFields?: string[];

  constructor(props: MetaFormSearch) {
    super(props);
  }

  public static createElement(attributes, children: any[]): any {
    return new MetaFormSearch({
      ...attributes,
      children,
    });
  }

  toFormly(config: IFormUserConfiguration): any {
    if (this.parentForm) {
      return {
        ...super.toFormly(config),
        id: this.id,
        label: this.label,
        fieldGroup: super.toFormly(config).children.map((c) => {
          if (c.type === "page") {
            c.fieldGroup = c.children;
          }
          return c;
        }),
        props: {
          showToolbar: false,
          showRecentItems: false,
          showFavorites: false,
          entityType: this.entityType,
          processSelectedItemsLabel: this.processSelectedItemsLabel,
          canSelectItemsToDatabase: this.canSelectItemsToDatabase,
          showResultsAsTable: this.showResultsAsTable,
        },
        type: `meta-${this.constructor["meta"]["type"]}`,
      };
    } else {
      return {
        ...super.toFormly(config),
        editable: false,
        children: [
          {
            id: this.id,
            label: this.label,
            fieldGroup: super.toFormly(config).children.map((c) => {
              if (c.type === "page") {
                c.fieldGroup = c.children;
              }
              return c;
            }),
            props: {
              showToolbar: true,
              showRecentItems: this.showRecentItems,
              showFavorites: this.showFavorites,
              entityType: this.entityType,
              showResultsAsTable: this.showResultsAsTable,
              hasDataSource: !!this.primaryDataSource,
            },
            type: `meta-${this.constructor["meta"]["type"]}`,
          },
        ],
      };
    }
  }
}
