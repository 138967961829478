/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponentInfo } from "./baseComponent";
import { MetaFormSelectComponent } from "./selectComponent";

@MetaFormComponentInfo({
  selector: "ma-specification-group-combinations",
  type: "specification-group-combinations",
  hideInPallet: true,
})
export class SpecificationGroupCombinationsComponent extends MetaFormSelectComponent<any> {
  public static selectDataSource: any;

  public static createElement(attributes: any, children: any[]) {
    const component = new SpecificationGroupCombinationsComponent();
    Object.assign(component, {
      ...attributes,
      children: Array.isArray(children) ? children : [],
    });
    component.getAllKeys = true;
    /*component.selectDataSource = SqlDataSource.fromString(`
    SELECT * FROM TAG_Sammlungen where IsSpezifikation = 1
    `);*/
    (component as any).selectValueField = "ArtikelgruppenID";
    (component as any).selectLabelField = "Artikelgruppe";
    return component;
  }
}
