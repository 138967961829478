/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ValidatableComponent } from "./validatableComponent";
import { MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";
import * as _ from "lodash";
import { MetaDatepickerType } from "../../../api-interfaces/src";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-datepicker",
  type: "datepicker",
  name: "Datepicker",
  icon: "calendar-week",
  palletGroup: "input",
})
export class MetaFormDatepickerComponent extends ValidatableComponent {
  @MetaFormComponentProperty({
    name: "Type",
    type: "select",
    default: MetaDatepickerType.date,
    values: Object.entries(MetaDatepickerType).map(([key, v]) => {
      return {
        label: v,
        value: key,
      };
    }),
  })
  datepickerType?: MetaDatepickerType = MetaDatepickerType.date;

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        datepickerType: this.datepickerType,
        onChange: !!this.onChange,
      },
    });
  }
}
