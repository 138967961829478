/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, Input, OnInit } from "@angular/core";
import { MetaHelperService } from "../../../services/metaHelper.service";
import { IFeedResultItem } from "@meta/api-interfaces";

export class MetaTimelineTemplateDocumentDeleted {
  data: IFeedResultItem<{
    filename: string;
    type: {
      label: string;
      value: string;
    };
  }>;
}

@Component({
  selector: "meta-timeline-template-document-deleted",
  template: `{{ item.data.entity.name }}
    <meta-tag [maParams]="{ label: tplLabel, color: color, colorText: colorText, maxWidth: 250 }"></meta-tag>
    wurde <span class="log-danger">gelöscht</span>.

    <ng-template #tplLabel
      ><i class="far fa-{{ item.data.entity.icon }}"></i>&nbsp; {{ item.data.entity.title }}</ng-template
    >`,
  styles: [
    `
      :host {
        ::ng-deep {
          .ant-tag {
            cursor: default !important;
          }
        }
      }
    `,
  ],
})
export class MetaTimelineTemplateDocumentDeletedComponent implements OnInit {
  @Input() public item: MetaTimelineTemplateDocumentDeleted;

  public color: string;
  public colorText: string;

  constructor(public metaHelperService: MetaHelperService) {}

  ngOnInit() {
    this.color = this.metaHelperService.generateColor(this.item.data.entity.type);
    this.colorText = getComputedStyle(document.documentElement).getPropertyValue("--component-background");
  }
}
