/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Overlay } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewContainerRef,
  ViewEncapsulation
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { IEditorVariable, IEditorVariableGroup } from "@meta/enums";
import { Editor, Toolbar } from "ngx-editor";
import { imageUploader } from "prosemirror-image-uploader";
import { DOMSerializer, Node } from "prosemirror-model";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import * as xss from "xss";
import { MetaComponentBase, MetaFormBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaHelperService } from "../../services/metaHelper.service";
import { VariablesService } from "../metaWorkflow/components/meta-workflow-node-settings/settingsVariables.service";

import { getVarSchema } from "./metaEditorVariablesPlugin";

export class MetaEditor extends MetaFormBase {
  isTemplateEditor? = false;
  autoUploadImages? = true;
  editorType?: "html" | "text" = "html";
  placeholder? = "";
  editorToolbar?: "none" | "default" | "full" = "default";
  variables?: { names: any; variables: IEditorVariable[]; groups: IEditorVariableGroup[] };
  onChange? = false;
  schema?: object;
}

@Component({
  selector: "meta-editor",
  templateUrl: "./metaEditor.component.html",
  styleUrls: ["./metaEditor.component.less"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetaEditorComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.Emulated,
})
export class MetaEditorComponent extends MetaComponentBase<string> implements OnInit, OnDestroy {
  public editor: Editor;
  @Input()
  public schema: any;
  public toolbar: Toolbar = [
    // default value
    ["bold", "italic"],
    ["underline", "strike"],
    ["code", "blockquote"],
    ["ordered_list", "bullet_list"],
    [{ heading: ["h1", "h2", "h3"] }],
    ["link", "image"],
    ["text_color", "background_color"],
    ["align_left", "align_center", "align_right", "align_justify"],
    ["format_clear"],
  ];

  constructor(
    _changeDetectorRef: ChangeDetectorRef,
    _metaHelperService: MetaHelperService,
    public readonly overlay: Overlay,
    public readonly injector: Injector,
    public readonly viewContainerRef: ViewContainerRef,
    public readonly destroyRef: DestroyRef,
    @Optional() public readonly variablesService: VariablesService,
  ) {
    super();
    super.maParams = new MetaEditor();
  }

  get ma(): MetaEditor {
    return super.ma;
  }

  ngOnInit() {
    super.ngOnInit();

    const self = this;
    const uploadPlugin = imageUploader({
      async upload(fileOrUrl: File | string, view: any) {
        if (typeof fileOrUrl === "string") {
          return fileOrUrl;
        } else {
          return self.upload(fileOrUrl);
        }
      },
    });

    const { schema, serializerSchema, plugin } = getVarSchema(this.getJsonSchema(), (node, domNode) => {
      this.showVariablesPicker(node, domNode);
    });
    if (this.getJsonSchema()) {
      this.editor = new Editor({
        schema,
        plugins: [plugin, uploadPlugin],
      });
    } else {
      this.editor = new Editor({
        schema,
        plugins: [uploadPlugin],
      });
    }
    this.updateEditorContent();
    this.editor.update
      .pipe(
        debounceTime(300),
        takeUntilDestroyed(this.destroyRef),
        map((view) => {
          const fragment = DOMSerializer.fromSchema(serializerSchema).serializeFragment(view.state.doc.content);
          const ele = document.createElement("div");
          ele.append(fragment);
          if (this.ma.editorType === "text") {
            ele.querySelectorAll("p").forEach((q) => q.after(document.createTextNode("\n")));
          }
          return this.ma.editorType === "html" ? ele.innerHTML : ele.innerText.trim();
        }),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        this.value = value;
        this.onChange?.(value);
        if (this.field) {
          this.formControl.patchValue(value);
          this.formControl.markAsDirty();
        }
      });
    if (this.variablesService) {
      this._maParams.editing = this.variablesService.editable;
    }
    if (this.field) {
      this.formControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.updateEditorContent();
      });
    }
  }

  public writeValue(value: any) {
    super.writeValue(value);
    this.updateEditorContent();
  }

  public updateEditorContent() {
    const v = (this.field ? this.formControl.value : this.value) || "";
    if (this.field && v === this.value) {
      return;
    }
    let content = v.replace(/\{\{(.+?)}}/gim, (s, g) => {
      return `<span data-var="${xss.escapeQuote(`{{${g}}}`)}">{{${g}}}</span>`;
    });
    if (this.ma.editorType === "text") {
      content = content.replace(/\n/g, "<br/ >");
    }
    this.editor.setContent(content);
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    this.editor?.destroy();
  }

  public async upload(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    const res = await fetch(`/api/v2/collection/upload`, {
      body: formData,
      method: "post",
    }).then((e) => e.json());
    return `/api/v2/file/${res.id}/download`;
  }

  public markAsTouched() {
    try {
      if (this.fc) {
        this.formControl?.markAsTouched();
      }
    } catch (e) {}
  }

  private getJsonSchema() {
    return this.ma.schema || this.schema || this.variablesService?.getSchema();
  }

  private showVariablesPicker(node: Node, domNode: HTMLElement) {
    if (this.getJsonSchema()) {
      const overlayRef = this.overlay.create({
        hasBackdrop: true,
        backdropClass: "var-overlay-backdrop",
        disposeOnNavigation: true,
        positionStrategy: this.overlay
          .position()
          .flexibleConnectedTo(domNode)
          .withPositions([
            {
              originX: "start",
              overlayY: "top",
              originY: "bottom",
              overlayX: "start",
            },
            {
              originX: "start",
              overlayY: "bottom",
              originY: "top",
              overlayX: "start",
            },
          ]),
      });
      const currentValue = String(domNode.dataset["var"])
        .trim()
        .replace(/^\{\{|}}$/gm, "");
      import("../metaVaribables/meta-variables.component").then(({ MetaVariablesComponent }) => {
        const portal = new ComponentPortal(
          MetaVariablesComponent,
          this.viewContainerRef,
          Injector.create({
            providers: [
              {
                useValue: true,
                provide: "EDITABLE",
              },
            ],
            parent: this.injector,
          }),
        );
        const componentRef = overlayRef.attach(portal);
        componentRef.setInput("schema", this.getJsonSchema());
        componentRef.setInput("value", currentValue);
        componentRef.setInput("allowedTypes", ["string", "number", "date", "boolean"]);
        componentRef.instance.onOk.subscribe((value) => {
          const v = `{{${value}}}`;
          domNode.dataset["var"] = v;

          let pos: number = null;

          this.editor.view.state.doc.descendants((n, p) => {
            if (n === node) {
              pos = p;
            }
            return true;
          });

          if (pos !== null) {
            const transaction = this.editor.view.state.tr;
            transaction.setNodeAttribute(pos, "var", v);
            this.editor.view.dispatch(transaction);
          }

          overlayRef.detach();
          overlayRef.dispose();
        });
        componentRef.instance.onCancel.subscribe(() => {
          overlayRef.detach();
          overlayRef.dispose();
        });
        overlayRef.backdropClick().subscribe(() => {
          overlayRef.detach();
          overlayRef.dispose();
        });
      });
    }
  }
}
