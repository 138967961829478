/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, NgModule, OnInit, ViewEncapsulation } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzSelectModule } from "ng-zorro-antd/select";
import { BehaviorSubject, merge, Observable, of, Subject, switchMap } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, shareReplay } from "rxjs/operators";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaActionHandlerFactory } from "../../base/metaForm/actions/actionHandler.factory";
import { MetaFormService } from "../../base/metaForm/metaForm.service";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaModalService } from "../../services/metaModalService";
import { MetaFieldWrapperModule } from "../metaFieldWrapper/metaFieldWrapper.component";
import { MetaInputModule } from "../metaInput/metaInput.component";
import { MetaAddressEditModalComponent } from "./metaAddressEditModal/metaAddressEditModal.component";

export interface IAddressObject {
  id: number;
  display: string;
  name1: string;
  name2: any;
  name3: string;
  name4: any;
  nameSuffix: any;
  zipcode: string;
  street: string;
  streetNumber: string;
  city: string;
  country: {
    code: string;
    name: string;
  };
  type: "google" | "address";
}

class MetaAddress {
  disabled?: boolean;
  readonly?: boolean;
}

@Component({
  templateUrl: "./metaAddress.component.html",
  styleUrls: ["./metaAddress.component.less"],
  selector: "meta-address",
  encapsulation: ViewEncapsulation.None,
})
export class MetaAddressComponent extends MetaComponentBase implements OnInit {
  public selectData$: Observable<any[]>;
  public searchFilter: BehaviorSubject<string>;
  public displayAddress$: Observable<IAddressObject>;
  public showFullAddress = true;
  public allowNew = false;
  public allowEdit = true;
  private update$ = new Subject<number>();

  constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly metaHelperService: MetaHelperService,
    protected readonly _metaFormService: MetaFormService,
    protected readonly http: HttpClient,
    protected readonly _modalService: MetaModalService,
    private readonly _metaActionHandler: MetaActionHandlerFactory,
  ) {
    super();
    super.maParams = new MetaAddress();
  }

  get ma(): MetaAddress {
    return super.ma;
  }

  public compareWith = (a, b) => String(a) === String(b);

  public ngOnInit() {
    super.ngOnInit();
    this.searchFilter = new BehaviorSubject<string>("");
    this.selectData$ = this.searchFilter.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      switchMap((searchString) => {
        return this.http.post<any[]>(`forms/${this.formState.formId}/selectData/${this.id}`, {
          skip: 0,
          take: 25,
          searchString: searchString || "",
          _ctx: this.model["_ctx"],
          data: {
            value: this.formControl.value,
            ...this.model,
          },
        });
      }),
    );
    this.displayAddress$ = merge(of(this.formControl.value), this.formControl.valueChanges, this.update$).pipe(
      debounceTime(150),
      filter((e) => e !== undefined),
      switchMap((value) => this.loadAddress(value)),
      shareReplay(),
    );
  }

  public async execSelectAction(value: string) {
    await this._metaActionHandler.executeSelectAction({
      controlId: this.id,
      data: {
        value,
        ...this.model,
      },
      formId: this.formState.formId,
    });
  }

  public editAddress() {
    this._modalService
      .create({
        nzContent: MetaAddressEditModalComponent,
        nzComponentParams: {
          id: this.model[this.field.id],
        },
      })
      .afterClose.subscribe((adr: IAddressObject) => {
        if (adr) {
          this.update$.next(adr.id);
        }
      });
  }

  public clear() {
    this.formControl.setValue(null);
  }

  private loadAddress(addressNr: string | number) {
    if (addressNr === null || addressNr === undefined) {
      return of(null);
    }
    if (String(addressNr).includes("place_id")) {
      return this.http.get<IAddressObject>("forms/addressData", {
        params: {
          placeId: String(addressNr),
        },
      });
    } else {
      return this.http.get<IAddressObject>("forms/addressData", {
        params: {
          addressNr: String(addressNr),
        },
      });
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    NzSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzDividerModule,
    NzButtonModule,
    MetaFieldWrapperModule,
    MetaInputModule,
  ],
  declarations: [MetaAddressComponent, MetaAddressEditModalComponent],
  exports: [MetaAddressComponent],
})
export class MetaAddressModule {}
