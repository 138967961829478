/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  createComponent,
  ElementRef,
  EnvironmentInjector,
  Injector,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import type { SchedulerConfigOptions, SchedulerStatic, SchedulerTemplates } from "@meta/dhtmlx-scheduler";
import { MetaRadioStyle } from "@meta/enums";
import {
  MetaButtonModule,
  MetaEmptyModule,
  MetaFieldWrapperModule,
  MetaGroupModule,
  MetaLoaderModule,
  MetaRadioModule,
  MetaToolbarModule,
} from "@meta/ui";
import { FormlyModule } from "@ngx-formly/core";
import * as moment from "moment";
import { AutocompleteDataSourceItem, NzAutocompleteModule } from "ng-zorro-antd/auto-complete";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzColDirective, NzRowDirective } from "ng-zorro-antd/grid";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { BehaviorSubject, firstValueFrom, Observable, of, skip, Subject, switchMap, takeUntil } from "rxjs";
import { debounceTime, filter, map, tap } from "rxjs/operators";
import { v4 as uuid } from "uuid";
import { MetaComponentBase, MetaFormBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaEventService } from "../../services/metaEvents.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaButton } from "../metaButton/metaButton.component";
import { MetaDatepickerModule } from "../metaDatepicker/metaDatepicker.component";
import { MetaRadio } from "../metaRadio/metaRadio.component";
import { MetaTooltipModule } from "../metaTooltip/metaTooltip.component";
import { ISchedulerLegendItem, MetaSchedulerService } from "./metaScheduler.service";
import { MetaSchedulerEventBarTextComponent } from "./templates/metaScheduler.eventBarText.component";
import { MetaSchedulerTimelineScaleLabelComponent } from "./templates/metaScheduler.timelineScaleLabel.component";
import DurationConstructor = moment.unitOfTime.DurationConstructor;

export class MetaScheduler extends MetaFormBase {
  draggable = true;
  showDay = true;
  showWeek = true;
  showMonth = true;
  showYear = true;
  showTimeline = true;
  showTimelineWeek = true;
  showTimelineMonth = true;
  showTimelineMatrix = true;
  showAgenda = true;
  eventNameSingular = "Event";
  eventNamePlural = "Events";
  sectionNameSingular = "Gruppierung";
  sectionNamePlural = "Gruppierungen";
  resizable = true;
  defaultView: "day" | "week" | "month" | "timeline" | "timelineWeek" | "timelineMonth" | "timelineMatrix" | "agenda" =
    "timeline";
}

export class MetaSchedulerGetDataResponse {
  items: MetaSchedulerGetDataResponseItems[];
  sections: MetaSchedulerGetDataResponseSections[];
  tooltipForm: string;
  tooltipHeight: string;
  tooltipPlacement:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom"
    | Array<string>;
  tooltipSize: "small" | "medium" | "large";
  tooltipWidth: string;
}

export class MetaSchedulerGetDataResponseItems {
  id: string;
  origin: any;
  section_id: string;
  start_date: string;
  end_date: string;
  text: string;
  icon: string;
  color: string;
  tooltipParams?: string[];
}

export class MetaSchedulerGetDataResponseSections {
  key: string;
  label: string;
}

@Pipe({ name: "filterSelectData", pure: true, standalone: true })
export class FilterSelectDataPipe implements PipeTransform {
  constructor(private readonly http: HttpClient) {}

  transform(
    filter: Record<string, any> & { loaded$: Subject<any[]> },
    formId: string,
    ganttId: string,
    onLoaded?: (filter: any) => void,
  ): Observable<any[]> {
    return this.http.get<any[]>(`forms/scheduler/${formId}/${ganttId}/filters/${filter.id}`).pipe(
      tap((e) => {
        filter.loaded$.next(e);
        filter.loaded$.complete();
      }),
    );
  }
}

@MetaUnsubscribe()
@Component({
  selector: "meta-scheduler",
  template: `
    <meta-group [maParams]="{ flex: 1 }">
      <div nz-row>
        <div nz-col>
          <div class="scheduler-filter">
            @for (filter of filters$ | async; track $index) {
              @switch (filter.type) {
                @case ("select") {
                  <meta-field-wrapper>
                    <nz-select
                      [style.min-width.px]="200"
                      [(ngModel)]="filterState[filter.id]"
                      (ngModelChange)="filterValue$.next(filterValue$.value)"
                      [nzPlaceHolder]="filter.label"
                      nzAllowClear
                      [nzShowSearch]="true"
                    >
                      @for (s of filter | filterSelectData: formState.formId : id | async; track s.value) {
                        <nz-option [nzValue]="s.value" [nzLabel]="s.label"></nz-option>
                      }
                    </nz-select>
                  </meta-field-wrapper>
                }
              }
            }
            <meta-field-wrapper>
              <input
                placeholder="Suchen"
                [style.min-width.px]="300"
                nz-input
                [ngModel]="searchValue$ | async"
                (ngModelChange)="searchValue$.next($event)"
                [nzAutocomplete]="auto"
              />
            </meta-field-wrapper>
            <nz-autocomplete [nzDefaultActiveFirstOption]="false" nzBackfill #auto>
              <nz-auto-optgroup *ngFor="let group of searchAutocompleteData$ | async" [nzLabel]="groupTitle">
                <ng-template #groupTitle>
                  <span>
                    {{ group.title }}
                  </span>
                </ng-template>
                <nz-auto-option *ngFor="let option of group.children" [nzLabel]="option.label" [nzValue]="option">
                  <div style="white-space: break-spaces">{{ option.label }}</div>
                </nz-auto-option>
              </nz-auto-optgroup>
            </nz-autocomplete>
          </div>
          <div class="scheduler-navigation">
            <div class="navigation-bar-date">
              <meta-button [maParams]="btnPrev"></meta-button>
              <meta-button [maParams]="btnNext"></meta-button>
              <meta-button [maParams]="btnToday"></meta-button>
              @if (currentView === "day" || currentView === "timeline") {
                <div class="navigation-bar-current-date">
                  {{ scheduler ? (scheduler.getState().min_date | date: "fullDate") : null }}
                </div>
              } @else if (currentView === "month" || currentView === "timeline_month") {
                <div class="navigation-bar-current-date">
                  {{ scheduler ? (scheduler.getState().min_date | date: "MMMM yyyy") : null }}
                </div>
              } @else {
                <div class="navigation-bar-current-date">
                  {{ scheduler ? (scheduler.getState().min_date | date: "fullDate") : null }} -
                  {{ scheduler ? (scheduler.getState().max_date | date: "fullDate" : "UTC +0") : null }}
                </div>
              }
              <meta-datepicker
                *ngIf="scheduler"
                class="navigation-bar-picker"
                [maParams]="{
                  placeholder: 'Von',
                  datepickerType: 'date',
                  editing: true
                }"
                [ngModel]="currentDate"
                (ngModelChange)="onDatePickerChange($event)"
              ></meta-datepicker>
            </div>
            <meta-radio
              [maParams]="radioOpts"
              [(ngModel)]="currentView"
              (ngModelChange)="selectView($event)"
            ></meta-radio>
          </div>
        </div>
      </div>
      <div [hidden]="currentView === 'agenda_view'" #metaScheduler class="scheduler-container">
        <h3 *ngIf="isLoading">
          <meta-loader></meta-loader>
        </h3>
        <div class="dhx_cal_navline"></div>
        <div class="dhx_cal_header"></div>
        <div class="dhx_cal_data"></div>
      </div>
      <div *ngIf="currentView === 'agenda_view'" class="agenda-view">
        <h3 *ngIf="isLoading">
          <meta-loader></meta-loader>
        </h3>
        @if (agendaArray.length === 0) {
          <meta-empty
            [maParams]="{
              description: 'Keine ' + ma.eventNamePlural + ' für diesen Zeitraum vorhanden',
              icon: 'solar-system'
            }"
          ></meta-empty>
        }
        @if (agendaArray.length > 0) {
          <div nz-row>
            <div nz-col [nzSpan]="6">
              <div class="agenda-header-cell agenda-header-group-cell">{{ ma.sectionNameSingular }}</div>
            </div>
            <div nz-col [nzSpan]="18">
              <div nz-row>
                <div nz-col [nzSpan]="6">
                  <div class="agenda-header-cell">Datum</div>
                </div>
                <div nz-col [nzSpan]="18">
                  <div nz-row>
                    <div nz-col class="agenda-header-cell" [nzSpan]="6">
                      <div class="agenda-header-cell">Zeit</div>
                    </div>
                    <div nz-col [nzSpan]="18">
                      <div class="agenda-header-cell">{{ ma.eventNameSingular }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          @for (agenda of agendaArray; track $index) {
            <div class="agenda-wrapper">
              <div nz-row>
                <div nz-col class="agenda-section" [nzSpan]="6">
                  <meta-scheduler-timeline-scale-label
                    [maLabel]="agenda.label"
                    [maColorString]="agenda.key"
                    (click)="onSectionClick(agenda.key)"
                  ></meta-scheduler-timeline-scale-label>
                </div>
                <div nz-col [nzSpan]="18">
                  @for (date of agenda._dates; track $index) {
                    <div nz-row>
                      <div nz-col class="agenda-date" [nzSpan]="6">
                        <div class="agenda-date-wrapper">
                          <div class="agenda-date-day">{{ date.labelDay }}</div>
                          <div class="agenda-date-month-wrapper">
                            <div class="agenda-date-weekday">{{ date.labelWeekday }}</div>
                            <div class="agenda-date-month">{{ date.labelMonth }}</div>
                          </div>
                        </div>
                      </div>
                      <div nz-col [nzSpan]="18">
                        @for (time of date._times; track $index) {
                          <div nz-row>
                            <div nz-col class="agenda-time" [nzSpan]="6">
                              <div class="agenda-time-label">
                                @if (time.showChevronLeft) {
                                  <i
                                    class="fas fa-caret-left pr-1"
                                    [nzTooltipTitle]="
                                      ma.eventNameSingular + ' reicht noch weiter in die Vergangenheit.'
                                    "
                                    nz-tooltip
                                  ></i>
                                }
                                <span [innerHTML]="time.labelTime"></span>
                                @if (time.showChevronRight) {
                                  <i
                                    class="fas fa-caret-right pl-1"
                                    [nzTooltipTitle]="ma.eventNameSingular + ' reicht noch weiter in die Zukunft.'"
                                    nz-tooltip
                                  ></i>
                                }
                              </div>
                            </div>
                            <div nz-col class="agenda-event" [nzSpan]="18">
                              <ng-container *ngIf="data.tooltipForm; else content">
                                <meta-tooltip
                                  [maParams]="{
                                    tooltipForm: data.tooltipForm,
                                    tooltipId: time.tooltipParams?._id,
                                    displayValue: { tooltipParams: time.tooltipParams },
                                    tooltipPlacement: data.tooltipPlacement,
                                    tooltipHeight: data.tooltipHeight,
                                    tooltipWidth: data.tooltipWidth
                                  }"
                                >
                                  <ng-container *ngTemplateOutlet="content"></ng-container>
                                </meta-tooltip>
                              </ng-container>

                              <ng-template #content>
                                <div
                                  id="event_{{ time.id }}"
                                  (dblclick)="onEventClick(time.id)"
                                  class="dhx_cal_event_line"
                                  [style.background-color]="time.color"
                                >
                                  <meta-scheduler-event-bar-text
                                    [maLabel]="time.label"
                                    [maIcon]="time.icon"
                                  ></meta-scheduler-event-bar-text>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
        }
      </div>
      <div class="scheduler-legend">
        <div class="scheduler-legend-wrapper">
          <div *ngFor="let item of legend$ | async" class="scheduler-legend-item">
            <div class="legend-item-indicator">
              <i class="fas fa-{{ item.icon }} text-color-{{ item.color }}"></i>
            </div>
            <div class="legend-item-label">{{ item.label }}</div>
          </div>
        </div>
        <div class="scheduler-datacount">
          <strong>{{ data?.items?.length }}</strong> {{ ma.eventNamePlural }} in diesem Zeitraum
        </div>
      </div>
    </meta-group>
  `,
  styleUrls: [
    "../../../../../../node_modules/@meta/dhtmlx-scheduler/codebase/dhtmlxscheduler.css",
    "./metaScheduler.component.less",
  ],
  providers: [MetaSchedulerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    FormlyModule,
    FormsModule,
    MetaGroupModule,
    MetaToolbarModule,
    MetaRadioModule,
    MetaButtonModule,
    MetaLoaderModule,
    NzInputModule,
    NzAutocompleteModule,
    NzButtonModule,
    NzIconModule,
    NzSelectModule,
    FilterSelectDataPipe,
    MetaFieldWrapperModule,
    MetaSchedulerTimelineScaleLabelComponent,
    MetaSchedulerEventBarTextComponent,
    MetaEmptyModule,
    MetaDatepickerModule,
    MetaTooltipModule,
    NzToolTipModule,
    NzRowDirective,
    NzColDirective,
  ],
})
export class MetaSchedulerComponent extends MetaComponentBase implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("metaScheduler") metaScheduler: ElementRef<HTMLElement>;
  @ViewChild("myEventTemplate", { static: true }) myEventTemplate: ElementRef;
  public data: MetaSchedulerGetDataResponse;
  public isLoading = true;
  public isLoadingMuted = false;
  public currentView: string = "timeline";
  public currentDate: Date;
  public radioOpts: MetaRadio;
  public views: any[] = [];
  public scheduler: SchedulerStatic;
  public timelineSections: any[] = [];
  public agendaArray: any[] = [];
  public viewDate: Date;
  public btnPrev: MetaButton = {
    icon: "chevron-left",
    onClick: this.prevTimeSegment.bind(this),
  };
  public btnNext: MetaButton = {
    icon: "chevron-right",
    onClick: this.nextTimeSegment.bind(this),
  };
  public btnToday: MetaButton = {
    label: "Heute",
    onClick: this.todaysTimeSegment.bind(this),
  };

  public config: Partial<SchedulerConfigOptions> = {
    start_on_monday: true,
    container_autoresize: true,
    max_month_events: 3,
    nav_height: 0,
    time_step: 30,
  };
  public templates: Partial<SchedulerTemplates> = {
    parse_date: function (date) {
      return new Date(date);
    },
    format_date: function (date) {
      return date.toISOString();
    },
  };

  public legend$: Observable<ISchedulerLegendItem[]> = of([]);
  public filters$: Observable<any[]> = of([]);
  public readonly filtersReady$ = new Subject();

  public searchAutocompleteData$: Observable<{ title: string; children: AutocompleteDataSourceItem[] }[]> = of([]);
  public data$: Observable<MetaSchedulerGetDataResponse> = of(null);
  public filterState: Record<string, any> = {};
  public currentItemId: string = null;

  public readonly searchValue$ = new BehaviorSubject<string>("");
  public readonly filterValue$ = new BehaviorSubject<string>("");
  private observer: MutationObserver;
  private refs = new Set<ComponentRef<any>>();

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    public metaSchedulerService: MetaSchedulerService,
    private readonly _metaEventService: MetaEventService,
    private readonly injector: Injector,
    private readonly environmentInjector: EnvironmentInjector,
  ) {
    super();
    super.maParams = new MetaScheduler();
  }

  get ma(): MetaScheduler {
    return super.ma;
  }

  ngOnInit() {
    this._metaEventService.reloadTrigger$
      .pipe(
        skip(1),
        debounceTime(50),
        filter((x) => x !== undefined && x !== null),
        takeUntil((this as any).destroyed$),
      )
      .subscribe({
        next: async (trigger) => {
          if (trigger.specific) {
            if (trigger.formId === this.id) {
              this.refresh();
            }
          } else {
            this.refresh();
          }
        },
      });
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    this.observer?.disconnect();
    for (let ref of this.refs) {
      ref.destroy();
    }
    this.refs.clear();
    this.scheduler?.destructor();
    this.scheduler = null;
  }

  public onSectionClick(key: string) {
    this.metaSchedulerService.handleSectionClick(
      {
        formId: this.formState.formId,
        ganttId: this.id,
        contextId: this.formState.contextId,
      },
      key,
    );
  }

  public onEventClick(eventId: string) {
    const event = this.data.items.find((e) => String(e.id) === String(eventId));
    if (event) {
      this.metaSchedulerService.handleClick(
        {
          formId: this.formState.formId,
          ganttId: this.id,
          contextId: this.formState.contextId,
        },
        event,
      );
    }
  }

  public async ngAfterViewInit() {
    const { Scheduler } = await import("@meta/dhtmlx-scheduler");
    this.scheduler = Scheduler.getSchedulerInstance();
    const initDate = new Date();
    this.scheduler.i18n.setLocale("de");
    this.scheduler.config = { ...this.scheduler.config, ...this.config };
    this.scheduler.config.drag_resize = this.ma.resizable;
    this.scheduler.templates = { ...this.scheduler.templates, ...this.templates };
    this.scheduler.plugins({
      limit: true,
      timeline: this.ma.showTimeline,
      daytimeline: this.ma.showTimeline,
      treetimeline: this.ma.showTimeline,
      agenda_view: this.ma.showAgenda,
      active_links: true,
      year_view: true,
      grid_view: true,
    });
    this.scheduler.attachEvent("onDblClick", (eventId: string) => {
      this.onEventClick(eventId);
    });

    this.scheduler.attachEvent("onYScaleClick", (index: number, section: MetaSchedulerGetDataResponseSections) => {
      this.onSectionClick(section.key);
    });

    this.scheduler.attachEvent("onEventChanged", (eventId: string, ...args: any) => {
      const event = this.data.items.find((e) => String(e.id) === String(eventId));
      if (event) {
        this.isLoadingMuted = true;
        this.metaSchedulerService
          .handleEventChanged(
            {
              formId: this.formState.formId,
              ganttId: this.id,
              contextId: this.formState.contextId,
            },
            event,
          )
          .then(({ successful }) => this.refresh());
      }
    });
    this.timelineSections = this.scheduler.serverList("timeline_sections", []);
    this.setTimelineTemplates();
    // Load DayView
    this.ma.showDay && this.initDayView();
    // Load Weekview
    this.ma.showWeek && this.initWeekView();
    // Load Monthview
    this.ma.showMonth && this.initMonthView();
    // Load Yearview
    this.ma.showYear && this.initYearView();
    // Load Timelineview
    this.ma.showTimeline && this.initTimelineView(this.timelineSections);
    // Load TimelineWeekview
    this.ma.showTimelineWeek && this.initTimelineWeekView(this.timelineSections);
    // Load TimelineMonthview
    this.ma.showTimelineMonth && this.initTimelineMonthView(this.timelineSections);
    // Load TimelineMatrixview
    this.ma.showTimelineMatrix && this.initTimelineMatrixView();
    // Load AgendaView
    this.ma.showAgenda && this.initAgendaView(this.timelineSections);
    // Build Toolbar
    this.initToolbar();
    // Init scheduler
    this.viewDate = initDate;
    this.scheduler.init(this.metaScheduler.nativeElement, initDate, this.ma.defaultView);

    const getStateHash = () => {
      const state = this.scheduler.getState();
      return JSON.stringify([state.mode, state.date, state.min_date, state.max_date]);
    };
    let oldStateHash = getStateHash();
    this.scheduler.attachEvent("onViewChange", () => {
      const newStateHash = getStateHash();
      if (oldStateHash !== getStateHash()) {
        this.refresh();
        oldStateHash = newStateHash;
      }
    });

    this.legend$ = this.metaSchedulerService.getLegend({ formId: this.formState.formId, ganttId: this.id });
    this.filters$ = this.metaSchedulerService.getFilters({ formId: this.formState.formId, ganttId: this.id }).pipe(
      tap((filters) => {
        Promise.all(
          filters
            .filter((f) => f.type === "select" && !f.clearable)
            .map((f) =>
              firstValueFrom(f.loaded$ as Observable<any[]>).then((data) => {
                return { filter: f, data };
              }),
            ),
        ).then((filters) => {
          for (let { filter, data } of filters) {
            if (!filter.clearable) {
              this.filterState[filter.id] = data[0]?.value;
            }
          }
          this.filtersReady$.next(true);
          this.filtersReady$.complete();
        });
      }),
    );

    const filterReady = firstValueFrom(this.filtersReady$);

    this.data$ = this.filterValue$.pipe(
      debounceTime(750),
      tap(() => {
        if (!this.isLoadingMuted) {
          this.isLoading = true;
        }
        this.changeDetectorRef.markForCheck();
      }),
      switchMap(() => filterReady),
      switchMap(() => {
        return this.getData(
          typeof this.searchValue$.value === "string" ? this.searchValue$.value : "",
          this.filterState,
        );
      }),
    );

    this.data$.subscribe((data) => {
      const scrollState = this.scheduler.getView().getScrollPosition();
      this.scheduler.clearAll();
      this.scheduler.deleteMarkedTimespan();
      data.sections =
        data.sections ||
        data["groups"].map((g) => {
          return {
            key: g.id,
            label: g.title,
          };
        }) ||
        [];
      const schedulerState = this.scheduler.getState() as { min_date: Date; max_date: Date; mode: string };
      if (schedulerState.mode.indexOf("timeline") === 0 || schedulerState.mode === "agenda_view") {
        this.scheduler.updateCollection("timeline_sections", data.sections);
      }
      if (data["availabilities"]) {
        for (let a of data["availabilities"]) {
          this.scheduler.addMarkedTimespan({
            start_date: moment(new Date(a.from)).startOf("days").toDate(),
            end_date: new Date(a.from),
            css: "blocked-zone",
          });
          this.scheduler.addMarkedTimespan({
            end_date: moment(new Date(a.to)).endOf("days").toDate(),
            start_date: new Date(a.to),
            css: "blocked-zone",
          });
        }
      }
      this.scheduler.parse(data.items || []);
      this.data = data;
      if (schedulerState.mode === "agenda_view") {
        this.createAgendaArray(schedulerState.min_date, schedulerState.max_date);
      }
      this.isLoading = false;
      if (this.currentItemId && data.items[0]) {
        if (schedulerState.mode === "timeline_matrix") {
          const view = this.scheduler.getView();
          const matrixIndex = (view._matrix as Array<any[]>).findIndex((m) =>
            m.find((e) => String(e.id) === String(this.currentItemId)),
          );
          const sectionId = Object.entries(view.order).find(([id, index]) => index === matrixIndex)?.[0];
          const event = this.scheduler.getEvent(this.currentItemId);
          event["timeline-weektimeline_matrix"] = sectionId;
          this.scheduler.showEvent(this.currentItemId);
        } else {
          this.scheduler.showEvent(this.currentItemId);
        }
        this.scheduler.select(this.currentItemId);
        if (schedulerState.mode !== "agenda_view") {
          this.currentItemId = null;
        }
      }
      if (scrollState.left + scrollState.top > 0) {
        this.scheduler.getView().scrollTo({ left: scrollState.left, top: scrollState.top });
      }
      this.changeDetectorRef.detectChanges();
    });
    this.searchValue$.subscribe((e) => {
      const schedulerState = this.scheduler.getState() as { min_date: Date; max_date: Date; mode: string };
      if (e && typeof e === "object") {
        this.currentItemId = (e as any)["value"];
        this.scheduler.addEvent({
          ...(e as any),
        });
        try {
          this.scheduler.showEvent((e as any).id);
        } catch (e) {}
        this.scheduler.select((e as any).id);
        this.scheduler.eventRemove((e as any).id);
      } else {
        this.currentItemId = null;
        this.filterValue$.next(this.filterValue$.value);
      }
    });
    this.searchAutocompleteData$ = this.searchValue$.pipe(
      filter((e) => typeof e === "string"),
      debounceTime(450),
      switchMap((searchString) => {
        return this.metaSchedulerService.getAutocomplete({
          searchString,
          ganttId: this.id,
          formId: this.formState.formId,
        });
      }),
    );
  }

  public refresh() {
    this.filterValue$.next(this.filterValue$.value);
  }

  private getData(searchString: string = null, filterState: Record<string, any> = {}) {
    const schedulerState = this.scheduler.getState() as { min_date: Date; max_date: Date; mode: string };
    this.currentView = schedulerState.mode;
    if (!this.isLoadingMuted) {
      this.isLoading = true;
    } else {
      this.isLoadingMuted = false;
    }
    this._changeDetectorRef.markForCheck();
    return this.metaSchedulerService
      .getItems({
        formId: this.formState.formId,
        ganttId: this.id,
        contextId: this.formState.contextId,
        start: schedulerState.min_date.toISOString(),
        end: schedulerState.max_date.toISOString(),
        searchString,
        filterState,
      })
      .pipe(
        map((data) => {
          data.items.forEach((e) => {
            if (!e.color) {
              e.color = this.metaHelperService.generateColor(e.section_id);
            } else {
              e.color = getComputedStyle(document.documentElement).getPropertyValue("--text-color-" + e.color);
            }
          });
          return data;
        }),
      );
  }

  private initToolbar() {
    this.radioOpts = {
      data: this.views,
      editing: true,
      width: "dynamic",
      style: MetaRadioStyle.solid,
    };
    this._changeDetectorRef.markForCheck();
  }

  private initDayView() {
    this.views.push({
      icon: "calendar-day",
      label: "Tag",
      value: "day",
    });
  }

  private initWeekView() {
    this.views.push({
      icon: "calendar-week",
      label: "Woche",
      value: "week",
    });
  }

  private initMonthView() {
    //this.scheduler.config.max_month_events = this.ma.maxMonthEvents;
    this.views.push({
      icon: "calendar",
      label: "Monat",
      value: "month",
    });
  }

  private initYearView() {
    this.views.push({
      icon: "calendar-days",
      label: "Jahr",
      value: "year",
    });
    this.scheduler.config.year;
  }

  private initTimelineView(timelineSections: any) {
    this.scheduler.locale.labels.timeline = "Timeline";
    this.scheduler.xy.nav_height = 0;
    this.views.push({
      icon: "list-timeline",
      label: "Timeline",
      value: "timeline",
    });
    this.scheduler.createTimelineView({
      name: "timeline",
      x_unit: "minute", // measuring unit of the X-Axis.
      x_date: "%H:%i", // date format of the X-Axis
      x_step: 60, // X-Axis step in 'x_unit's
      x_size: 24, // X-Axis length specified as the total number of 'x_step's
      x_start: 0, // X-Axis offset in 'x_unit's
      x_length: 24, // number of 'x_step's that will be scrolled at a time
      // sections of the view (titles of Y-Axis)
      y_unit: timelineSections,
      y_property: "section_id", // mapped data property
      dy: 120,
      render: "bars", // view mode
      event_dy: 60,
      event_min_dy: 60,
      second_scale: {
        x_unit: "day",
        x_date: "%l, %d. %F",
      },
    });
  }

  private initTimelineWeekView(timelineSections: any) {
    this.scheduler.locale.labels.timeline_week = "Timeline (Woche)";
    this.scheduler.xy.nav_height = 0;
    this.views.push({
      icon: "list-timeline",
      label: "Timeline (Woche)",
      value: "timeline_week",
    });
    this.scheduler.createTimelineView({
      name: "timeline_week",
      x_unit: "day", // measuring unit of the X-Axis.
      x_date: "%l, %d. %F", // date format of the X-Axis
      x_step: 1, // X-Axis step in 'x_unit's
      x_size: 7, // X-Axis length specified as the total number of 'x_step's
      x_start: 0, // X-Axis offset in 'x_unit's
      y_unit: timelineSections,
      y_property: "section_id", // mapped data property
      dy: 120,
      render: "bars", // view mode
      event_dy: 60,
      event_min_dy: 60,
      second_scale: {
        x_unit: "week",
        x_date: "Kalenderwoche %W, %Y",
      },
    });
  }

  private initTimelineMonthView(timelineSections: any) {
    this.scheduler.locale.labels.timeline_Month = "Timeline (Monat)";
    this.scheduler.xy.nav_height = 0;
    this.views.push({
      icon: "list-timeline",
      label: "Timeline (Monat)",
      value: "timeline_month",
    });
    this.scheduler.createTimelineView({
      name: "timeline_month",
      scrollable: true,
      smart_rendering: true,
      column_width: 100,
      x_unit: "day", // measuring unit of the X-Axis.
      x_date: "%d", // date format of the X-Axis
      x_step: 1, // X-Axis step in 'x_unit's
      x_size: moment().daysInMonth(), // X-Axis length specified as the total number of 'x_step's
      y_unit: timelineSections,
      y_property: "section_id", // mapped data property
      dy: 120,
      render: "bars", // view mode
      event_dy: 60,
      event_min_dy: 60,
      second_scale: {
        x_unit: "month",
        x_date: "%F, %Y",
      },
    });
  }

  private initTimelineMatrixView() {
    this.scheduler.locale.labels.timeline_matrix = "Timeline (Matrix)";
    this.scheduler.xy.nav_height = 0;
    this.views.push({
      icon: "list-timeline",
      label: "Timeline (Matrix)",
      value: "timeline_matrix",
    });
    this.scheduler.createTimelineView({
      name: "timeline_matrix",
      x_unit: "minute",
      x_date: "%H:%i",
      x_step: 60,
      x_size: 24,
      x_start: 0,
      dy: 120,
      render: "days",
      days: 28,
      event_dy: 60,
      event_min_dy: 60,
      second_scale: {
        x_unit: "day",
        x_date: "%l, %d. %F",
      },
    });
  }

  private initAgendaView(timelineSections: any) {
    this.scheduler.locale.labels.agenda_tab = "Agenda";
    this.views.push({
      icon: "list",
      label: "Agenda",
      value: "agenda_view",
    });
    this.scheduler.createTimelineView({
      name: "agenda_view",
      x_unit: "day", // measuring unit of the X-Axis.
      x_date: "%l, %d. %F", // date format of the X-Axis
      x_step: 1, // X-Axis step in 'x_unit's
      x_size: 28, // X-Axis length specified as the total number of 'x_step's
      x_start: 0, // X-Axis offset in 'x_unit's
      y_unit: timelineSections,
      y_property: "section_id", // mapped data property
      render: "bars", // view mode
      event_dy: 60,
      event_min_dy: 60,
      second_scale: {
        x_unit: "month",
        x_date: "%F, %Y",
      },
    });
  }

  public createAgendaArray(startDate: Date, endDate: Date) {
    const arr = this.timelineSections.map((ts) => ({
      label: ts.label,
      key: ts.key,
      _dates: (() => {
        {
          const objectsArray = [];
          let currentDate = moment(startDate);

          while (currentDate.isBefore(endDate, "day")) {
            objectsArray.push({
              start_date: currentDate.format("YYYY-MM-DD"),
            });

            currentDate.add(1, "day");
          }

          return objectsArray;
        }
      })().map((item) => ({
        labelDay: moment(item.start_date).format("DD"),
        labelWeekday: moment(item.start_date).format("dddd"),
        labelMonth: `${moment(item.start_date).format("MMM")} ${moment(item.start_date).format("YYYY")}`,
        _times: this.data.items
          .filter((time) => {
            if (time.section_id !== ts.key) {
              return false;
            }
            if (
              moment(time.start_date).isAfter(moment(item.start_date).startOf("day")) &&
              moment(time.start_date).isBefore(moment(item.start_date).endOf("day"))
            ) {
              return true;
            } else if (
              moment(time.end_date).isAfter(moment(item.start_date).startOf("day")) &&
              moment(time.end_date).isBefore(moment(item.start_date).endOf("day"))
            ) {
              return true;
            } else if (
              moment(time.start_date).isBefore(moment(item.start_date).startOf("day")) &&
              moment(time.end_date).isAfter(moment(item.start_date).endOf("day"))
            ) {
              return true;
            } else {
              return false;
            }
          })
          .sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime())
          .map((event) => ({
            labelTime: `${
              moment(event.start_date).isBefore(moment(item.start_date).startOf("day"))
                ? "00:00"
                : moment(event.start_date).format("HH:mm")
            } - ${
              moment(event.end_date).isAfter(moment(item.start_date).endOf("day"))
                ? "00:00"
                : moment(event.end_date).format("HH:mm")
            }`,
            showChevronLeft: moment(event.start_date).isBefore(moment(item.start_date).startOf("day")),
            showChevronRight: moment(event.end_date).isAfter(moment(item.start_date).endOf("day")),
            id: event.id,
            label: event.text,
            icon: event.icon,
            tooltipParams: event.tooltipParams,
            color: this.metaHelperService.generateColor(event.section_id),
          })),
      })),
    }));
    arr.forEach((obj) => {
      obj._dates = obj._dates.filter((dateObj) => dateObj._times && dateObj._times.length > 0);
    });
    this.agendaArray = arr.filter((obj) => obj._dates && obj._dates.length > 0);
    this.changeDetectorRef.markForCheck();
    setTimeout(() => {
      if (this.currentItemId) {
        document.getElementById(`event_${this.currentItemId}`).scrollIntoView({ behavior: "smooth", block: "start" });
      }
    });
  }

  public selectView(view) {
    this.isLoading = true;
    let date: any;
    if (view === "timeline_month") {
      date = moment(this.viewDate).startOf("month");
      this.scheduler.config.time_step = 1440;
    } else if (view === "timeline_week") {
      date = moment(this.viewDate).startOf("week");
      this.scheduler.config.time_step = 30;
    } else {
      date = moment(this.viewDate).toDate();
      this.scheduler.config.time_step = 30;
    }
    this.setCurrentView(new Date(date), view);
  }

  public setTimelineTemplates() {
    this.scheduler.attachEvent("onTemplatesReady", () => {
      const config = { attributes: false, childList: true, subtree: true };
      const init = new Map<string, (node: HTMLElement) => ComponentRef<any>>();
      this.observer = new MutationObserver(() => {
        const needInit = this.metaScheduler.nativeElement.querySelectorAll(".ng_event_init");
        needInit.forEach((node: HTMLElement) => {
          node.classList.remove("ng_event_init");
          const initFunction = init.get(node.id);
          if (initFunction) {
            node.style.display = "contents";
            //TODO: Possible Memory Leak but necessary to allow virtual scrolling.
            //init.delete(node.id);
            this.refs.add(initFunction(node));
          }
        });
        for (let ref of this.refs) {
          if (!document.body.contains(ref.location.nativeElement)) {
            ref.destroy();
            this.refs.delete(ref);
          }
        }
      });
      this.observer.observe(this.metaScheduler.nativeElement, config);
      this.scheduler.templates.event_bar_text = (start, end, event) => {
        const id = uuid();
        init.set(id, (node) => {
          const eventBarText = createComponent(MetaSchedulerEventBarTextComponent, {
            environmentInjector: this.environmentInjector,
            hostElement: node,
            elementInjector: this.injector,
          });
          const state: any = this.scheduler.getState();
          eventBarText.setInput("maLabel", event.text);
          eventBarText.setInput("maStatus", event.text);
          eventBarText.setInput("maIcon", event.icon);
          eventBarText.setInput("maIconLabel", event.iconLabel);
          eventBarText.setInput("maEventNameSingular", this.ma.eventNameSingular);
          eventBarText.setInput("maShowChevronLeft", moment(event.start_date).isBefore(state.min_date));
          eventBarText.setInput("maShowChevronRight", moment(event.end_date).isAfter(state.max_date));
          eventBarText.setInput("maTooltipForm", this.data.tooltipForm);
          eventBarText.setInput("maTooltipId", event.tooltipParams?._id);
          eventBarText.setInput("maTooltipParams", event.tooltipParams);
          eventBarText.setInput("maTooltipPlacement", this.data.tooltipPlacement);
          eventBarText.setInput("maTooltipHeight", this.data.tooltipHeight);
          eventBarText.setInput("maTooltipWidth", this.data.tooltipWidth);
          eventBarText.changeDetectorRef.detectChanges();
          return eventBarText;
        });
        return `<div class="ng_event_init" id="${id}"></div>`;
      };

      const scaleLabel = (key: string, label: string, section: any) => {
        const id = uuid();
        init.set(id, (node) => {
          const timelineScaleLabel = createComponent(MetaSchedulerTimelineScaleLabelComponent, {
            environmentInjector: this.environmentInjector,
            hostElement: node,
            elementInjector: this.injector,
          });
          timelineScaleLabel.setInput("maLabel", label);
          timelineScaleLabel.setInput("maId", key);
          timelineScaleLabel.setInput("maColorString", key);
          timelineScaleLabel.changeDetectorRef.detectChanges();
          return timelineScaleLabel;
        });
        return `<div class="ng_event_init" id="${id}"></div>`;
      };
      this.scheduler.templates["timeline_scale_label"] = scaleLabel;
      this.scheduler.templates["timeline_week_scale_label"] = scaleLabel;
      this.scheduler.templates["timeline_month_scale_label"] = scaleLabel;
      this.scheduler.templates.event_class = (start, end, ev) => {
        return this.scheduler.getState().select_id === ev.id ? "selected" : "";
      };
    });
  }

  public prevTimeSegment() {
    this.isLoading = true;
    const state = this.scheduler.getState();
    let unitOfTime: DurationConstructor = "day";
    let amount: number = 1;

    switch (state.mode) {
      case "timeline":
        unitOfTime = "day";
        break;
      case "timeline_week":
        unitOfTime = "week";
        break;
      case "agenda_view":
        unitOfTime = "week";
        amount = 4;
        this.scheduler.getView().to = moment(this.viewDate).add(amount, unitOfTime).toDate();
        this.scheduler.getView().from = state.date;
        break;
      case "timeline_month":
        unitOfTime = "month";
        this.scheduler.getView("timeline_month").x_size = moment(state.date).daysInMonth();
        break;
      case "timeline_matrix":
        unitOfTime = "week";
        amount = 4;
        this.scheduler.getView().to = moment(this.viewDate).add(amount, unitOfTime).toDate();
        this.scheduler.getView().from = state.date;
        break;
    }

    this.viewDate = moment(this.viewDate).subtract(amount, unitOfTime).toDate();

    let date: any;
    if (state.mode === "timeline_month") {
      date = moment(this.viewDate).startOf("month");
      this.scheduler.config.time_step = 1440;
    } else if (state.mode === "timeline_week") {
      date = moment(this.viewDate).startOf("week");
      this.scheduler.config.time_step = 30;
    } else {
      date = moment(this.viewDate).toDate();
      this.scheduler.config.time_step = 30;
    }
    this.setCurrentView(new Date(date), state.mode);

    this.changeDetectorRef.markForCheck();
  }

  public nextTimeSegment() {
    this.isLoading = true;
    const state = this.scheduler.getState();
    let unitOfTime: DurationConstructor = "day";
    let amount: number = 1;

    switch (state.mode) {
      case "timeline":
        unitOfTime = "day";
        break;
      case "timeline_week":
        unitOfTime = "week";
        break;
      case "agenda_view":
        unitOfTime = "week";
        amount = 4;
        this.scheduler.getView().from = moment(this.viewDate).add(amount, unitOfTime).toDate();
        this.scheduler.getView().to = this.viewDate;
        break;
      case "timeline_month":
        unitOfTime = "month";
        this.scheduler.getView("timeline_month").x_size = moment(state.date).daysInMonth();
        break;
      case "timeline_matrix":
        unitOfTime = "week";
        amount = 4;
        this.scheduler.getView().from = moment(this.viewDate).add(amount, unitOfTime).toDate();
        this.scheduler.getView().to = this.viewDate;
        break;
    }

    this.viewDate = moment(this.viewDate).add(amount, unitOfTime).toDate();

    let date: any;
    if (state.mode === "timeline_month") {
      date = moment(this.viewDate).startOf("month");
      this.scheduler.config.time_step = 1440;
    } else if (state.mode === "timeline_week") {
      date = moment(this.viewDate).startOf("week");
      this.scheduler.config.time_step = 30;
    } else {
      date = moment(this.viewDate).toDate();
      this.scheduler.config.time_step = 30;
    }
    this.setCurrentView(new Date(date), state.mode);

    this.changeDetectorRef.markForCheck();
  }

  public todaysTimeSegment() {
    this.isLoading = true;
    const state = this.scheduler.getState();
    if (state.mode === "timeline_month") {
      this.scheduler.getView("timeline_month").x_size = moment().daysInMonth();
    }
    this.viewDate = new Date();
    this.setCurrentView(new Date(), state.mode);
    this.changeDetectorRef.markForCheck();
  }

  public onDatePickerChange(date: Date) {
    this.isLoading = true;
    const state = this.scheduler.getState();
    if (state.mode === "timeline_month" || state.mode === "timeline_matrix") {
      this.scheduler.getView("timeline_month").x_size = moment().daysInMonth();
    }
    this.viewDate = date;
    this.setCurrentView(date, state.mode);
    this.changeDetectorRef.markForCheck();
  }

  public setCurrentView(date: Date, mode: string) {
    this.currentDate = date;
    this.scheduler.setCurrentView(date, mode);
  }
}
