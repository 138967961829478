/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { MetaBaseValidator } from "./baseValidator";
import { MetaFormComponentInfo } from "@meta/forms";

export class IsGtinValidatorOptions {}

@MetaFormComponentInfo({
  selector: "ma-is-gtin-validator",
  type: "gtin-validator",
})
export class IsGtin extends MetaBaseValidator {
  constructor(public options?: IsGtinValidatorOptions) {
    super();
  }

  async validate(value: any): Promise<boolean> {
    const gtin = String(value).split("");
    const check = gtin.pop();
    return check === String(this.checkDigit(gtin));
  }

  private checkDigit(input: string[]) {
    const array = [...input].reverse();
    let total = 0;
    let i = 1;
    array.forEach((s) => {
      let number = parseInt(s);
      if (i % 2 === 0) {
        total = total + number;
      } else {
        total = total + number * 3;
      }
      i++;
    });
    return Math.ceil(total / 10) * 10 - total;
  }
}
