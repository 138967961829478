/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

export enum Themes {
  default = "default",
  dark = "dark",
  system = "system",
}

export enum Menu {
  fixed = "fixed",
  dynamic = "dynamic",
}

export enum Layouts {
  horizontal = "horizontal",
  vertical = "vertical",
  vertical_boxed = "vertical_boxed",
  blank = "blank",
  pwa = "pwa",
}

export enum MetaButtonType {
  primary = "primary",
  special = "special",
  secondary = "text",
  dashed = "dashed",
  default = "default",
  link = "link",
  text = "text",
}

export enum MetaControlLayout {
  horizontal = "horizontal",
  vertical = "vertical",
}

export enum MetaSectionType {
  headline1 = "h1",
  headline2 = "h2",
  headline3 = "h3",
  headline4 = "h4",
  headlineSeparator = "hSeparator",
  paragraph = "p",
  span = "span",
  strong = "strong",
  link = "link",
  horizontalLine = "hr",
  verticalLine = "vr",
  space = "space",
  header = "header",
  footer = "footer",
  stripes = "stripes",
  pageBreak = "pageBreak",
}

export enum MetaGroupStyle {
  default = "default",
  transparent = "transparent",
}

export enum MetaSize {
  default = "default",
  small = "small",
}

export enum MetaSkeletonSize {
  default = "default",
  large = "large",
  small = "small",
}

export enum MetaColor {
  success = "success",
  warning = "warning",
  danger = "danger",
}

export enum MetaTagMode {
  closeable = "closeable",
  default = "default",
  checkable = "checkable",
}

export enum MetaTagType {
  primary = "primary",
  danger = "danger",
  warning = "warning",
  success = "success",
  default = "default",
  mute = "mute",
}

export type MetaBrandType = "logo" | "logoIcon";

export enum MetaPrefixType {
  icon = "icon",
  text = "text",
}

export enum MetaSuffixType {
  icon = "icon",
  text = "text",
}

export enum MetaControlMode {
  default = "default",
  editing = "editing",
}

export enum MetaAdminActionViews {
  Fields = "fields",
  Permissions = "permissions",
  FillingLevel = "fillingLevel",
}

export enum MetaIconStyle {
  Light = "fa-light",
  Solid = "fa-solid",
  Thin = "fa-thin",
}

export type MetaInputType =
  | "text"
  | "textarea"
  | "number"
  | "decimal"
  | "currency"
  | "percent"
  | "password"
  | "template"
  | "color"
  | "hidden"
  | "secure-text";

export enum MetaInfoboxType {
  success = "success",
  info = "info",
  warning = "warning",
  error = "error",
}

export enum MetaDatepickerType {
  date = "date",
  datetime = "datetime",
  time = "time",
  month = "month",
  year = "year",
  week = "week",
  daterange = "daterange",
  timerange = "timerange",
}

export enum MetaDatepickerFormat {
  date = "dd.MM.yyyy",
  datetime = "dd.MM.yyyy HH:mm",
  time = "HH:mm",
  month = "MM.yyyy",
  year = "yyyy",
  week = "w/yyyy",
  daterange = "dd.MM.yyyy",
  datetimerange = "dd.MM.yyyy HH:mm",
}

export enum MetaUploadType {
  drag = "drag",
  inline = "inline",
  avatar = "avatar",
}

export enum MetaTimelineMode {
  left = "left",
  right = "right",
  alternate = "alternate",
}

export enum MetaTimelineStyle {
  default = "default",
  boxed = "boxed",
  log = "log",
}

export enum MetaTimelineColor {
  default = "default",
  success = "success",
  warning = "warning",
  danger = "danger",
}

export enum MetaRadioStyle {
  default = "default",
  list = "list",
  outline = "outline",
  solid = "solid",
  arrow = "arrow",
  card = "card",
}

export enum MetaActivityAppointmentType {
  call = "phone",
  meeting = "handshake",
  task = "tasks",
  deadline = "clock",
  email = "envelope",
  dinner = "utensils",
}

export enum MetaSelectType {
  multiple = "multiple",
  tags = "tags",
  single = "default",
}

export enum MetaChartSingleSeriesType {
  column2d = "column2d",
  column3d = "column3d",
  line = "line",
  area2d = "area2d",
  bar2d = "bar2d",
  bar3d = "bar3d",
  pie2d = "pie2d",
  pie3d = "pie3d",
  doughnut2d = "doughnut2d",
  doughnut3d = "doughnut3d",
  pareto2d = "pareto2d",
  pareto3d = "pareto3d",
  spline = "spline",
  splinearea = "splinearea",
}

export enum MetaChartMultiSeriesType {
  mscolumn2d = "mscolumn2d",
  mscolumn3d = "mscolumn3d",
  msline = "msline",
  msbar2d = "msbar2d",
  msbar3d = "msbar3d",
  overlappedcolumn2d = "overlappedcolumn2d",
  overlappedbar2d = "overlappedbar2d",
  msarea = "msarea",
  marimekko = "marimekko",
  zoomline = "zoomline",
  zoomlinedy = "zoomlinedy",
  msspline = "msspline",
  mssplinearea = "mssplinearea",
  stackedcolumn2d = "stackedcolumn2d",
  stackedcolumn3d = "stackedcolumn3d",
  stackedbar2d = "stackedbar2d",
  stackedbar3d = "stackedbar3d",
  stackedarea2d = "stackedarea2d",
  scrollcolumn2d = "scrollcolumn2d",
  scrollbar2d = "scrollbar2d",
  scrollline2d = "scrollline2d",
  scrollarea2d = "scrollarea2d",
  scrollstackedcolumn2d = "scrollstackedcolumn2d",
  scrollstackedbar2d = "scrollstackedbar2d",
  inversemsarea = "inversemsarea",
  inversemscolumn2d = "inversemscolumn2d",
  inversemsline = "inversemsline",
}

export enum MetaChartCombinationSeriesType {
  mscombi2d = "mscombi2d",
  mscombi3d = "mscombi3d",
  mscolumnline3d = "mscolumnline3d",
  stackedcolumn2dline = "stackedcolumn2dline",
  stackedcolumn3dline = "stackedcolumn3dline",
  scrollcombi2d = "scrollcombi2d",
  scrollcombidy2d = "scrollcombidy2d",
}

export enum MetaChartCombinationDYSeriesType {
  stackedcolumn2dlinedy = "stackedcolumn2dlinedy",
  stackedarea2dlinedy = "stackedarea2dlinedy",
  mscombidy2d = "mscombidy2d",
  mscombidy3d = "mscombidy3d",
  mscolumn3dlinedy = "mscolumn3dlinedy",
  stackedcolumn3dlinedy = "stackedcolumn3dlinedy",
}

export enum MetaChartXYPlotSeriesType {
  scatter = "scatter",
  zoomscatter = "zoomscatter",
}

export enum MetaChartMSStackedSeriesType {
  msstackedcolumn2d = "msstackedcolumn2d",
  scrollmsstackedcolumn2d = "scrollmsstackedcolumn2d",
}

export enum MetaChartMSStackedLineSeriesType {
  msstackedcolumn2dlinedy = "msstackedcolumn2dlinedy",
  scrollmsstackedcolumn2dlinedy = "scrollmsstackedcolumn2dlinedy",
}

export enum MetaChartSparkSeriesType {
  sparkline = "sparkline",
  sparkcolumn = "sparkcolumn",
}

export enum MetaChartErrorSeriesType {
  errorbar2d = "errorbar2d",
  errorline = "errorline",
}

export enum MetaChartSankeySeriesType {
  sankey = "sankey",
  chord = "chord",
}

export enum MetaChartFunnelType {
  funnel = "funnel",
}

export type EditorVariableType = "string" | "number" | "boolean" | "date" | "file";

export interface IEditorVariableGroup {
  id: string;
  label: string;
  icon?: string;
}

export interface IEditorVariable {
  label: string;
  labelShort: string;
  labelLong: string;
  id: string;
  description?: string;
  type?: EditorVariableType;
  icon?: string;
  group?: string;

  multiple: boolean;
}

export enum MetaChartSeriesType {
  column2d = "column2d",
  column3d = "column3d",
  line = "line",
  area2d = "area2d",
  funnel = "funnel",
  bar2d = "bar2d",
  bar3d = "bar3d",
  pie2d = "pie2d",
  pie3d = "pie3d",
  doughnut2d = "doughnut2d",
  doughnut3d = "doughnut3d",
  pareto2d = "pareto2d",
  pareto3d = "pareto3d",
  spline = "spline",
  splinearea = "splinearea",
  mscolumn2d = "mscolumn2d",
  mscolumn3d = "mscolumn3d",
  msline = "msline",
  msbar2d = "msbar2d",
  msbar3d = "msbar3d",
  overlappedcolumn2d = "overlappedcolumn2d",
  overlappedbar2d = "overlappedbar2d",
  msarea = "msarea",
  marimekko = "marimekko",
  zoomline = "zoomline",
  zoomlinedy = "zoomlinedy",
  msspline = "msspline",
  mssplinearea = "mssplinearea",
  stackedcolumn2d = "stackedcolumn2d",
  stackedcolumn3d = "stackedcolumn3d",
  stackedbar2d = "stackedbar2d",
  stackedbar3d = "stackedbar3d",
  stackedarea2d = "stackedarea2d",
  scrollcolumn2d = "scrollcolumn2d",
  scrollbar2d = "scrollbar2d",
  scrollline2d = "scrollline2d",
  scrollarea2d = "scrollarea2d",
  scrollstackedcolumn2d = "scrollstackedcolumn2d",
  scrollstackedbar2d = "scrollstackedbar2d",
  inversemsarea = "inversemsarea",
  inversemscolumn2d = "inversemscolumn2d",
  inversemsline = "inversemsline",
  mscombi2d = "mscombi2d",
  mscombi3d = "mscombi3d",
  mscolumnline3d = "mscolumnline3d",
  stackedcolumn2dline = "stackedcolumn2dline",
  stackedcolumn3dline = "stackedcolumn3dline",
  scrollcombi2d = "scrollcombi2d",
  scrollcombidy2d = "scrollcombidy2d",
  stackedcolumn2dlinedy = "stackedcolumn2dlinedy",
  stackedarea2dlinedy = "stackedarea2dlinedy",
  mscombidy2d = "mscombidy2d",
  mscombidy3d = "mscombidy3d",
  mscolumn3dlinedy = "mscolumn3dlinedy",
  stackedcolumn3dlinedy = "stackedcolumn3dlinedy",
  scatter = "scatter",
  zoomscatter = "zoomscatter",
  msstackedcolumn2d = "msstackedcolumn2d",
  scrollmsstackedcolumn2d = "scrollmsstackedcolumn2d",
  msstackedcolumn2dlinedy = "msstackedcolumn2dlinedy",
  scrollmsstackedcolumn2dlinedy = "scrollmsstackedcolumn2dlinedy",
  bulb = "bulb",
  cylinder = "cylinder",
  hled = "hled",
  thermometer = "thermometer",
  vled = "vled",
  sparkline = "sparkline",
  sparkcolumn = "sparkcolumn",
  errorbar2d = "errorbar2d",
  errorline = "errorline",
  sankey = "sankey",
  chord = "chord",
  angulargauge = "angulargauge",
  hlineargauge = "hlineargauge",
  bubble = "bubble",
  sparkwinloss = "sparkwinloss",
  errorscatter = "errorscatter",
  sunburst = "sunburst",
  treemap = "treemap",
  multilevelpie = "multilevelpie",
  timeseries = "timeseries",
}

export enum TooltipSize {
  small = "small",
  medium = "medium",
  large = "large",
}

export enum ScrollStates {
  belowEightyPercentBottom = "below80pBottom",
  aboveEightyPercentBottom = "above80pBottom",
  OnehundredPercentBottom = "100pBottom",
}

export enum MetaFormCategory {
  einkauf = "einkauf",
  verkauf = "verkauf",
  lager = "lager",
  core = "core",
}

export enum PipelineType {
  Project = "PROJEKT",
  Lead = "LEAD",
  Reklamation = "REKLA",
}

export enum FeedType {
  Call = "ANRUF",
  Protocol = "PROTOKOLL",
  Pipeline = "PIPELINE",
  Workflow = "WORKFLOW",
}

export enum LeadState {
  open = 0,
  won = 2,
  lost = 3,
}

export enum ProjectState {
  archive = 2,
}

export enum ComplaintState {
  open = 0,
  closed = 2,
}

export enum EntityType {
  Anwender = "ANW",
  Kunde = "KUN",
  Adresse = "ADR",
  Lieferant = "LIE",
  Ansprechpartner = "ANSP",
  Artikel = "ART",
  Charge = "CHARGE",
  Preislist = "PREISLIST",
  Artikelstatus = "ARTSTAT",
  Stueckliste = "STKLISTE",
  Rohstoff = "ROHSTOFF",
  Rezeptur = "REZEPTUR",
  ArtikelShop = "ART_SHOP",
  PipelineObject = "PLO",
  Projekt = "PROJEKT",
  Lead = "LEAD",
  ProjektPipeline = "PROJEKTPL",
  LeadPipeline = "LEADPL",
  Angebot = "ANG",
  Retoure = "RET",
  Reklamation = "REKLA",
  Avisierung = "AVS",
  Auftrag = "AUF",
  Fertigungsauftrag = "FA",
  Transportauftrag = "TA",
  Arbeitsplan = "ARBPLAN",
  Arbeitsgang = "ARBGANG",

  Aenderungsmitteilung = "AM",
  Packschein = "PACK",
  Lieferschein = "LIEF",
  Rechnung = "RECH",
  Gutschrift = "GUTS",
  ProformaRechnung = "PROFRECH",
  AVISIERUNG = "AVIS",
  ACTIVITY = "ACTIVITY",
  NOTE = "NOTE",
  TASK = "TASK",
  APPOINTMENT = "APPOINTMENT",
  CLOSEDAPPOINTMENT = "CLOSEDAPPOINTMENT",
  REPORT = "REPORT",
  ArtikelGruppe = "ART_GRP",
  Tags = "TAG",
  File = "FILE",
  Log = "LOG",
  Workflow = "WF",
  WorkflowAusfuehrung = "WF_EXEC",
  ChangeLog = "PROTOKOLL",
  Phone = "TEL",
  Call = "ANRUF",
  MarketInfo = "MARKTINFO",
  Branche = "BRA",
  LiefArtikelPreise = "LAP",
  Firmenstamm = "FSTM",
  Feed = "FEED",
  Warenklasse = "WKLASSE",
  Mengeneinheit = "MENG",
  Erloeskonto = "ERLOESK",
  Sachkonto = "SACHK",
  Kostenarten = "KOSTART",
  Kostenstellen = "KOSTSTE",
  Kostentraeger = "KOSTTRG",
  Lieferantenstatus = "LIEFSTTS",
  Auftragsarten = "AUFTRGART",
  Kundenstatus = "KNDSTTS",
  Anwendergruppen = "ANWENDG",
  Sammelkonto = "SAMMELK",
  Waehrung = "WAEHRUNG",
  Lieferbedingung = "LIEFBED",
  Sprache = "SPRACHE",
  Warenhauptgruppe = "WHAUPTG",
  Warenuntergruppen = "WUNTERG",
  Zahlungsart = "ZAHLA",
  Zahlungsbedingung = "ZAHLB",
  Versandart = "VERSART",
  Anrede = "ANREDE",
  Kundenklasse = "KUNKLA",
  Abteilung = "ABTEIL",
  Kundengruppe = "KUNGRU",
  Kundenhauptgruppe = "KUNHAUPTG",
  Kundenuntergruppe = "KUNUNTERG",
  Vertriebsweg = "VERTRW",
  RPMGebiet = "RPMG",
  Absatzweg = "ABSATZW",
  Lieferantenklasse = "LIEFKLA",
  Lieferantengruppe = "LIEFGRU",
  Bezugsgruppe = "BEZUGGRU",
  TourSpedition = "TOURSPED",
  Lieferantenhauptgruppe = "LIEFHAUPTG",
  Lieferantenuntergruppe = "LIEFUNTERG",
  Interessent = "INTR",
  KundenArtikel = "KUNDARTIK",
  Land = "LAND",
  BulkEdit = "BULK_EDIT",
  DokumentenKlassifizierung = "DOKKLASS",
  Groessenklasse = "GRKLASSE",
  Kostenverteilung = "KOSVER",
  Bestellung = "BSTEL",
  BestellungBestaetigen = "BSTELBE",
  PipelineActionReasons = "PIPEACTREA",
  Bestellart = "BESTART",
  IFA = "IFA",
  EKKontrakt = "EKKONTRAKT",
  VKKontrakt = "VKKONTRAKT",
  VKKontraktart = "VKKONTRAKTART",
  EKKontraktart = "EKKONTRAKTART",
  Bestellvorschlag = "BESTVOR",
  KundenPotential = "KUNDENPOTENTIAL",
  Besuchsbericht = "BESBE",
  Einstufungen = "EINST",
  Abgabegruppe = "ABGAB",
  Arbeitsplatz = "ARBEITPl",
  Arbeitsplatztyp = "ARBPLTYP",
  Arbeitsplatzgruppe = "ARBPLGRP",
  Eingangsrechnung = "EKRECH",
  Pruefplan = "PRUEFPLAN",
  INCI = "INCI",
  Fehlerkatalog = "FEHLERKATALOG",
  Methodentyp = "METHODENTYP",
}

export const AvisStatus = [
  { id: "0", label: "In Bearbeitung" },
  { id: "10", label: "Bestätigt" },
  { id: "20", label: "Wareneingang erfolgt" },
  { id: "90", label: "Abgelehnt" },
];

export const EntityTypeNamesSingular: Record<EntityType, string> = {
  ANSP: "Ansprechpartner",
  ANW: "Anwender",
  ADR: "Adresse",
  KUN: "Kunde",
  LIE: "Lieferant",
  ART: "Artikel",
  CHARGE: "Charge",
  ARTSTAT: "Artikelstatus",
  STKLISTE: "Stückliste",
  ROHSTOFF: "Rohstoff",
  REZEPTUR: "Rezeptur",
  ART_SHOP: "Artikel Shop",
  PLO: "Pipeline Objekt",
  PROJEKT: "Projekt",
  LEAD: "Lead",
  PROJEKTPL: "Projektpipeline",
  LEADPL: "Leadpipeline",
  ANG: "Angebot",
  RET: "Retoure",
  REKLA: "Reklamation",
  AVS: "Avisierung",
  AUF: "Auftrag",
  FA: "Fertigungsauftrag",
  BSTEL: "Bestellung",
  BSTELBE: "Bestellbestätigung",
  PACK: "Packschein",
  LIEF: "Lieferschein",
  RECH: "Rechnung",
  GUTS: "Gutschrift",
  PROFRECH: "Proformarechnung",
  KOSVER: "Kostenverteilung",
  AVIS: "Avisierung",
  NOTE: "Notiz",
  APPOINTMENT: "Termin",
  CLOSEDAPPOINTMENT: "Abgeschl. Termin",
  REPORT: "Report",
  ART_GRP: "Artikel Gruppe",
  TAG: "Tag/Spezifikation",
  FILE: "Datei",
  WF: "Workflow",
  WF_EXEC: "Workflow Ausführung",
  PROTOKOLL: "Änderungsprotokoll",
  TEL: "Telefonnummer",
  ANRUF: "Anruf",
  BRA: "Branche",
  LAP: "Lieferanten-Artikel-Beziehung",
  FSTM: "Firmenstamm",
  FEED: "Feed",
  WKLASSE: "Warenklasse",
  MENG: "Mengeneinheit",
  ERLOESK: "Erlöskonto",
  SACHK: "Sachkonto",
  KOSTART: "Kostenarten",
  KOSTSTE: "Kostenstellen",
  KOSTTRG: "Kostentraeger",
  LIEFSTTS: "Lieferantenstatus",
  AUFTRGART: "Auftragsart",
  KNDSTTS: "Kundenstatus",
  ANWENDG: "Anwendergruppe",
  SAMMELK: "Sammelkonto",
  WAEHRUNG: "Währung",
  LIEFBED: "Lieferbedingung",
  SPRACHE: "Sprache",
  WHAUPTG: "Warenhauptgruppe",
  WUNTERG: "Warenuntergruppe",
  ZAHLA: "Zahlungsart",
  ZAHLB: "Zahlungsbedingung",
  VERSART: "Versandart",
  ANREDE: "Anrede",
  KUNKLA: "Kundenklasse",
  ABTEIL: "Abteilung",
  KUNGRU: "Kundengruppe",
  KUNHAUPTG: "Kundenhauptgruppe",
  KUNUNTERG: "Kundenuntergruppe",
  VERTRW: "Vertriebsweg",
  RPMG: "RPM-Gebiet",
  ABSATZW: "Absatzweg",
  LIEFKLA: "Lieferantenklasse",
  LIEFGRU: "Lieferantengruppe",
  BEZUGGRU: "Bezugsgruppe",
  TOURSPED: "Tour / Spedition",
  LIEFHAUPTG: "Lieferantenhauptgruppe",
  LIEFUNTERG: "Lieferantenuntergruppe",
  INTR: "Interessent",
  KUNDARTIK: "KundenArtikel",
  LAND: "Land ",
  BULK_EDIT: "Massenbearbeitung",
  DOKKLASS: "Dokument klassifizierung",
  GRKLASSE: "Größenklasse",
  LOG: "Log",
  ACTIVITY: "Aktivität",
  PIPEACTREA: "Aktionen Grund",
  BESTART: "Bestellart",
  IFA: "IFA",
  EKKONTRAKT: "Einkaufskontrakt",
  VKKONTRAKT: "Verkaufskontrakt",
  VKKONTRAKTART: "Verkaufskontraktart",
  EKKONTRAKTART: "Einkaufskontrakart",
  BESTVOR: "Bestellvorschlag",
  KUNDENPOTENTIAL: "Kundenpotential",
  BESBE: "Besuchsbericht",
  MARKTINFO: "Marktinformation",
  TASK: "Aufgabe",
  PREISLIST: "Preislist",
  TA: "Transportauftrag",
  AM: "Änderungsmitteilung",
  EINST: "Einstufung",
  ABGAB: "Abgabegruppe",
  ARBPLAN: "Arbeitsplan",
  ARBGANG: "Arbeitsgang",
  ARBEITPl: "Arbeitsplatz",
  ARBPLTYP: "Arbeitsplatztyp",
  ARBPLGRP: "Arbeitsplatzgruppe",
  EKRECH: "Eingangsrechnung",
  PRUEFPLAN: "Prüfplan",
  INCI: "INCI",
  FEHLERKATALOG: "Fehlerkatalog",
  METHODENTYP: "Methodentyp",
};

export enum MetaProgressType {
  line = "line",
  circle = "circle",
  dashboard = "dashboard",
  arrows = "arrows",
  bigArrows = "bigArrows",
}

export enum MetaState {
  none = 0,
  editing = 10,
  disabled = 20,
  saving = 30,
  saved = 40,
  canceled = 50,
  editingForm = 100,
}

export const allowedActions = [
  "onClick",
  "onSelect",
  "onBeforeOpen",
  "onFocus",
  "onChange",
  "onBlur",
  "onEdit",
  "onCreate",
  "onDelete",
  "onProcessSelectedItems",
  "onBeforeLoad",
  "onCreateVersion",
  "onDeleteVersion",
  "onOpen",
  "onLoaded",
  "onPipelineStateChange",
  "onNextStep",
  "onPreviousStep",
];

export type ReportFormatType = "A3" | "A4" | "A5" | "DIN_5008" | "dynamic" | "fullWidth";
export type ReportLayoutType = "portrait" | "landscape";

export const FormlyHideExpression =
  "field.props.hide || !(formState?.displayData?.()?.[field.id]?.condition ?? true) || !(field?.displayValue?.condition ?? true) || (formState.fieldFilter$.value?.length > 0 && field.props.validatableComponent ? !(field.props.label?.toLowerCase()?.includes(formState.fieldFilter$.value.toLowerCase()) && !field.props.hideLabel) : false)";
