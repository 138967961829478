/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2021
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2021
 */

import { IMetaWrapperBase, MetaWrapperBase } from "../../base/metaWrapperBase/metaWrapperBase.component";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { CommonModule } from "@angular/common";
import { FormlyModule } from "@ngx-formly/core";
import { NzCollapseModule } from "ng-zorro-antd/collapse";

export class IMetaGroup extends IMetaWrapperBase {
  active? = false;
  disabled? = false;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-collapse",
  template: `
    <nz-collapse [nzBordered]="false" [nzGhost]="true">
      <nz-collapse [nzBordered]="false" [nzGhost]="true">
        <nz-collapse-panel
          [nzHeader]="ma.label"
          [(nzActive)]="ma.active"
          [nzDisabled]="ma.disabled"
          (nzActiveChange)="onActiveChange($event)"
        >
          <ng-container #fieldComponent></ng-container>
          <ng-content></ng-content>
        </nz-collapse-panel>
      </nz-collapse>
    </nz-collapse>
  `,
  styleUrls: ["./metaCollapse.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaCollapseComponent extends MetaWrapperBase {
  @Output() public activeChanged = new EventEmitter<boolean>();

  public onActiveChange(active: boolean) {
    this.activeChanged.emit(active);
  }
}

@NgModule({
  declarations: [MetaCollapseComponent],
  imports: [CommonModule, FormlyModule, NzCollapseModule],
  exports: [MetaCollapseComponent],
})
export class MetaCollapseModule {}
