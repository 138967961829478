/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, Input } from "@angular/core";
import { IFeedResultItem } from "@meta/api-interfaces";

export class MetaTimelineTemplatePipelineObjectMoved {
  data: IFeedResultItem;
}

@Component({
  selector: "meta-timeline-template-form-created",
  template: `
    <meta-translate [key]="'timeline.form-created'">
      <ng-template meta-translate-child key="entity">
        <meta-live-card
          cardSize="link"
          [fallbackText]="item.data.entity.title"
          [cardType]="item.data.entity.type"
          [cardId]="item.data.entity.id"
          [cardData]="item.data.cardData"
        />
      </ng-template>
    </meta-translate>
  `,
})
export class MetaTimelineTemplateFormCreatedComponent {
  @Input() public item: MetaTimelineTemplatePipelineObjectMoved;
}
