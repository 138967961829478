/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { FormlyModule } from "@ngx-formly/core";
import cronstrue from "cronstrue";
import "cronstrue/locales/de";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalModule } from "ng-zorro-antd/modal";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaModalService } from "../../services/metaModalService";
import { MetaCronModalComponent } from "./metaCronModal.component";

@Component({
  selector: "meta-cron",
  template: `
    <span>{{ formatCron() }}</span>
    <button nz-button (click)="editCron()">
      <i class="fal fa-pen"></i>
    </button>
  `,
  styleUrls: ["./metaCron.component.less"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FormlyModule, FormsModule, ReactiveFormsModule, NzFormModule, NzModalModule, NzButtonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetaCronComponent),
      multi: true,
    },
  ],
})
export class MetaCronComponent extends MetaComponentBase {
  private _onChange: CallableFunction;

  constructor(
    _changeDetectorRef: ChangeDetectorRef,
    _metaHelperService: MetaHelperService,
    private readonly modalService: MetaModalService,
    private readonly messageService: NzMessageService,
  ) {
    super();
  }

  public get cronValue() {
    return this.field ? this.formControl.value : this.value;
  }

  public formatCron() {
    if (!this.cronValue) {
      return "-";
    }
    return cronstrue.toString(this.cronValue, {
      locale: navigator.language.split("-")[0],
      use24HourTimeFormat: true,
    });
  }

  public registerOnChange(fn: any) {
    this._onChange = fn;
  }

  public editCron() {
    const c = this.modalService.create({
      nzTitle: "Cron Editor",
      nzContent: MetaCronModalComponent,
      nzOkText: "Übernahmen",
      nzCancelText: "Abbrechen",
      nzOnOk: (c) => {
        const value = c.value();
        try {
          if (value) {
            cronstrue.toString(value);
          }
          this.writeValue(value);
          this._onChange?.(value);
          try {
            this.formControl?.setValue(value);
          } catch (e) {}
        } catch (e) {
          this.messageService.warning(`Ungültiger Cron Ausdruck.`);
        }
        return true;
      },
    });
    c.componentInstance.value.set(this.cronValue);
  }
}
