/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, Input, OnInit } from "@angular/core";
import { MetaHelperService } from "../../../services/metaHelper.service";
import { MetaMediaLibraryService } from "../../metaMediaLibrary/metaMediaLibrary.service";
import { IFeedResultItem } from "@meta/api-interfaces";

export class MetaTimelineTemplateDocumentAssigned {
  data: IFeedResultItem<{
    fileId: string;
    filename: string;
    type: {
      label: string;
      value: string;
    };
  }>;
}

@Component({
  selector: "meta-timeline-template-document-assigned",
  template: `
    <meta-translate key="timeline.document-assigned">
      <ng-template meta-translate-child key="file">
        <meta-live-card
          [cardLazyLoad]="true"
          cardType="FILE"
          cardSize="link"
          [cardId]="item.data.data.fileId"
        ></meta-live-card>
      </ng-template>
      <ng-template meta-translate-child key="item">
        <meta-live-card
          [cardLazyLoad]="true"
          [cardType]="item.data.entity.type"
          cardSize="link"
          [cardId]="item.data.entity.id"
          [cardData]="item.data.cardData"
        ></meta-live-card>
      </ng-template>
    </meta-translate>
  `,
})
export class MetaTimelineTemplateDocumentAssignedComponent implements OnInit {
  @Input() public item: MetaTimelineTemplateDocumentAssigned;

  public color: string;
  public linkColor: string;
  public colorText: string;

  constructor(
    public metaHelperService: MetaHelperService,
    private metaMediaLibraryService: MetaMediaLibraryService,
  ) {}

  ngOnInit() {
    this.colorText = getComputedStyle(document.documentElement).getPropertyValue("--component-background");
    this.color = this.metaHelperService.generateColor(this.item.data.entity.type);
    this.linkColor = this.metaHelperService.generateColor(this.item.data.entity.type);
  }

  openFile() {
    this.metaMediaLibraryService.previewFileById(this.item.data.data.fileId);
  }
}
