/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityType } from "@meta/enums";
import { createState, select, Store, withProps } from "@ngneat/elf";
import {
  addEntities,
  deleteAllEntities,
  deleteEntitiesByPredicate,
  selectAllApply,
  upsertEntities,
  withEntities,
} from "@ngneat/elf-entities";
import { localStorageStrategy, persistState } from "@ngneat/elf-persist-state";
import { MetaFormCondition } from "libs/forms/src/metaState.dto";
import { firstValueFrom, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import * as uuid from "uuid";
import { METASEARCH_UNIQUE_ID } from "../../services/metaHelper.service";

export interface IMetaSearchStore {
  id: string;
  _id: string[];
  type: EntityType;
  avatar?: string;
  values: any[];
  fieldId: string;
  label: string;
  description: string[];
}

export interface IMetaSearchResult {
  resultSet: any[];
  total: number;
}

interface IMetaSearchGetParams {
  fieldId: string;
  quellId?: string;
  entityType?: EntityType;
  condition?: MetaFormCondition;
  params?: unknown;
  silentUpdate?: boolean;
}

export class MetaSearchStateStore {
  [id: string]: MetaSearchStateObject;
}

export class MetaSearchStateObject {
  historyIds: string[];
  view: number;
  searchTotal: number;
  favoriteTotal: number;
}

const { state: favState, config: favConfig } = createState(withEntities<IMetaSearchStore>());
const { state: hisState, config: hisConfig } = createState(withEntities<IMetaSearchStore>());
const { state: findState, config: findConfig } = createState(withEntities<IMetaSearchStore>());
const searchState = createState(withProps<MetaSearchStateStore>({}));

const favStore = new Store({ name: "searchFavoriteData", state: favState, config: favConfig });
const hisStore = new Store({ name: "searchHistoryData", state: hisState, config: hisConfig });
const searchStore = new Store({ name: "searchData", state: findState, config: findConfig });
const searchStateStore = new Store({ name: "searchState", state: searchState.state, config: searchState.config });

export const persistAppStore = persistState(searchStateStore, {
  key: "searchState",
  storage: localStorageStrategy,
});

@Injectable({ providedIn: "root" })
export class MetaSearchRepository {
  searchState$ = searchStateStore;

  constructor(private readonly _http: HttpClient) {}

  public getSearchState$(fieldId: string): Observable<MetaSearchStateObject> {
    return searchStateStore.pipe(select((f) => f[fieldId]));
  }

  public getFavoriteData$(fieldId: string): Observable<IMetaSearchStore[]> {
    return favStore.pipe(selectAllApply({ filterEntity: (e) => e.fieldId === fieldId }));
  }

  public getHistoryData$(fieldId: string): Observable<IMetaSearchStore[]> {
    return hisStore.pipe(selectAllApply({ filterEntity: (e) => e.fieldId === fieldId }));
  }

  public getSearchData$(fieldId: string): Observable<IMetaSearchStore[]> {
    return searchStore.pipe(selectAllApply({ filterEntity: (e) => e.fieldId === fieldId }));
  }

  public deleteHistoryData() {
    return hisStore.update(deleteAllEntities());
  }

  setSearchState(fieldId: string, payload: Partial<MetaSearchStateObject>) {
    searchStateStore.update((s) => ({
      ...s,
      [fieldId]: {
        ...s[fieldId],
        ...payload,
      },
    }));
  }

  public async addFavoriteData(response: { data: IMetaSearchResult | any } & { params: IMetaSearchGetParams }) {
    const { data, params } = response;
    if (data.resultSet) {
      const dataWithIds = data.resultSet.map((d) => ({
        ...d,
        id: uuid.v5(`${params.fieldId}-${d.id}`, METASEARCH_UNIQUE_ID),
        type: params.entityType,
        fieldId: params.fieldId,
        _id: [d.id],
        label: d.values[0],
        description: d.values.map((v, i) => {
          if (i !== 0) {
            return v;
          }
        }),
      }));
      favStore.update(upsertEntities(dataWithIds, { prepend: true }));
      this.setSearchState(params.fieldId, {
        favoriteTotal: data.total,
      });
    } else {
      favStore.update(
        addEntities(
          {
            ...data,
            id: uuid.v5(`${params.fieldId}-${data.id}`, METASEARCH_UNIQUE_ID),
            type: params.entityType,
            fieldId: params.fieldId,
            _id: [data.id],
            label: data.values[0],
            description: data.values.map((v, i) => {
              if (i !== 0) {
                return v;
              }
            }),
          },
          { prepend: true },
        ),
      );
    }
  }

  public async addHistoryData(response: { data: IMetaSearchResult } & { params: IMetaSearchGetParams }) {
    const { data, params } = response;
    const state = await firstValueFrom(this.getSearchState$(params.fieldId));
    data.resultSet = data.resultSet.sort((a, b) => state.historyIds.indexOf(a.id) - state.historyIds.indexOf(b.id));
    const dataWithIds = data.resultSet.map((d) => ({
      ...d,
      ...d[params.fieldId],
      id: uuid.v5(`${params.fieldId}-${d.id}`, METASEARCH_UNIQUE_ID),
      type: params.entityType,
      fieldId: params.fieldId,
      _id: [d.id],
      label: d.values[0],
      description: d.values.map((v, i) => {
        if (i !== 0) {
          return v;
        }
      }),
    }));
    hisStore.update(upsertEntities(dataWithIds, { prepend: true }));
  }

  public async addSearchData(response: { data: IMetaSearchResult } & { params: IMetaSearchGetParams }) {
    const { data, params } = response;
    const dataWithIds = data.resultSet.map((d) => ({
      ...d,
      ...d[params.fieldId],
      id: uuid.v5(`${params.fieldId}-${d.id}`, METASEARCH_UNIQUE_ID),
      type: params.entityType,
      fieldId: params.fieldId,
      _id: [d.id],
      label: d.values[0],
      description: d.values.map((v, i) => {
        if (i !== 0) {
          return v;
        }
      }),
    }));
    searchStore.update(deleteAllEntities());
    searchStore.update(upsertEntities(dataWithIds));
    this.setSearchState(params.fieldId, {
      searchTotal: data.total,
    });
  }

  public clearSearchData(fieldId) {
    searchStore.update(deleteEntitiesByPredicate((predicate) => predicate.fieldId === fieldId));
  }

  public getFavoriteData(p: IMetaSearchGetParams): Observable<IMetaSearchStore[]> {
    const param = new URLSearchParams();
    if (p.condition?.skip !== undefined) {
      param.set("skip", `${p.condition.skip}`);
    }
    if (p.condition?.take !== undefined) {
      param.set("take", `${p.condition.take}`);
    }
    if (p) {
      p.condition.params = p.params;
    }
    const url = `favorites/${p.entityType}/${p.fieldId}?${param}`;

    if (p.condition.skip === 0 && !p.silentUpdate) {
      favStore.update(deleteEntitiesByPredicate((predicate) => predicate.fieldId === p.fieldId));
    }

    return this._http.get<IMetaSearchStore[]>(url, p.condition).pipe(
      tap((data) =>
        this.addFavoriteData({
          data: data as any,
          params: p,
        }),
      ),
    );
  }

  public getSearchData(p: IMetaSearchGetParams): Observable<IMetaSearchStore[]> {
    const param = new URLSearchParams();
    if (p.condition?.skip !== undefined) {
      param.set("skip", `${p.condition.skip}`);
    }
    if (p.condition?.take !== undefined) {
      param.set("take", `${p.condition.take}`);
    }
    if (p) {
      p.condition.params = p.params;
    }
    p.condition.filter.filters.forEach((filter: any) => {
      if (filter.value) {
        filter = { ...filter };
        filter.value = filter.value.replace(/ /g, "%");
      }
    });

    const url = `search/${p.fieldId}/byCondition`;

    if (p.condition.skip === 0 && !p.silentUpdate) {
      searchStore.update(deleteEntitiesByPredicate((predicate) => predicate.fieldId === p.fieldId));
    }

    return this._http.post<IMetaSearchStore[]>(url, p.condition).pipe(
      tap((data) =>
        this.addSearchData({
          data: data as any,
          params: p,
        }),
      ),
    );
  }

  public getHistoryData(p: IMetaSearchGetParams): Observable<IMetaSearchStore[]> {
    const param = new URLSearchParams();
    if (p.condition?.skip !== undefined) {
      param.set("skip", `${p.condition.skip}`);
    }
    if (p.condition?.take !== undefined) {
      param.set("take", `${p.condition.take}`);
    }
    if (p) {
      p.condition.params = p.params;
    }
    const url = `forms/${p.fieldId}/find?${param}`;

    if (p.condition.skip === 0 && !p.silentUpdate) {
      hisStore.update(deleteEntitiesByPredicate((predicate) => predicate.fieldId === p.fieldId));
    }

    return this._http.post<IMetaSearchStore[]>(url, p.condition).pipe(
      tap((data) =>
        this.addHistoryData({
          data: data as any,
          params: p,
        }),
      ),
    );
  }

  public removeFavoriteData(p: IMetaSearchGetParams): Observable<boolean> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: {
        quellId: p.quellId,
        formObjectId: p.fieldId,
        type: p.entityType,
      },
    };
    return this._http
      .delete<any>(`favorites`, options)
      .pipe(
        tap((data) =>
          favStore.update(
            deleteEntitiesByPredicate((e) => e.id === uuid.v5(`${p.fieldId}-${p.quellId}`, METASEARCH_UNIQUE_ID)),
          ),
        ),
      );
  }

  public setFavoriteData(p: IMetaSearchGetParams): Observable<any> {
    return this._http
      .post<any>(`favorites`, {
        quellId: p.quellId,
        formObjectId: p.fieldId,
        type: p.entityType,
      })
      .pipe(
        tap((data) =>
          this.addFavoriteData({
            data: data as any,
            params: p,
          }),
        ),
      );
  }

  public async setHistoryData(p: { fieldId: string; historyData: string[] }) {
    this.setSearchState(p.fieldId, {
      historyIds: p.historyData,
    });
  }

  public searchDataByIds(p: IMetaSearchGetParams & { ids: string[] }): Observable<any> {
    const param = new URLSearchParams();
    if (p.condition?.skip !== undefined) {
      param.set("skip", `${p.condition.skip}`);
    }
    if (p.condition?.take !== undefined) {
      param.set("take", `${p.condition.take}`);
    }
    return this._http.post<any>(`search/${p.fieldId}?${param}`, p.ids).pipe(
      tap((data) =>
        this.addHistoryData({
          data: data as any,
          params: p,
        }),
      ),
    );
  }
}
