/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { IMetaFormComponentPropertyOptions, metadataKey } from "./components";
import { MetaForm } from "./pages";
import { SerialisationTypeMap } from "./metadataStore";

const typeProperty = "__type" as const;

function toPlainValue(form: MetaForm, parentObject: any, value: any, opts?: IMetaFormComponentPropertyOptions) {
  if (opts?.toPlain) {
    return opts.toPlain(parentObject, value);
  } else if (Array.isArray(value)) {
    return _toPlain(form, value);
  } else if (typeof value === "function") {
    const serializeFunction = false;
    if (serializeFunction) {
      console.warn(`Serializing functions may lead to broken Forms.`, value.toString());
      const re = /^(async)?\s*\(\$\)\s*=>\s*\{(.+?)}$/gims;
      return (re.exec(value.toString().trim())?.[2] || value.toString()).trim();
    } else {
      const refId = {
        form: form instanceof MetaForm ? form.id : null,
        component: parentObject.id !== form.id ? parentObject.id : null,
        property: opts.prop,
      };
      //console.warn(`The Form "${form.id}" contains JavaScript Functions.`, refId);
      return `$.CallRef(${JSON.stringify(refId)});`;
    }
  } else if (value instanceof Date) {
    return {
      [typeProperty]: "Date",
      value: value.toJSON(),
    };
  } else if (value === undefined || value === null) {
    return value;
  } else if (typeof value === "object") {
    return _toPlain(form, value);
  }
  return value;
}
export function toPlain<T = MetaForm | MetaForm[]>(target: T) {
  return Array.isArray(target) ? target.map((t) => _toPlain(t, t)) : _toPlain(target as MetaForm, target);
}

function _toPlain(form: MetaForm, target: any | any[]) {
  if (Array.isArray(target)) {
    return target.map((t) => toPlainValue(form, null, t));
  }
  const props = Reflect.getMetadata(metadataKey, target);
  if (props) {
    const r = Object.fromEntries(
      Object.entries(props).map(([k, v]) => [k, toPlainValue(form, target, target[k], v as any)]),
    );
    if (target?.constructor?.meta?.type) {
      r[typeProperty] = target?.constructor?.meta?.type;
    }
    return r;
  } else {
    return Object.fromEntries(
      Object.entries(target)
        .map(([k, v]) => {
          return [k, toPlainValue(form, target, v, null)];
        })
        .filter(([k, v]) => v !== undefined),
    );
  }
}

export function fromPlain<T = MetaForm>(plainObject: any) {
  const typeName = plainObject?.[typeProperty];
  if (typeName === "Date") {
    return new Date(plainObject["value"]);
  }
  const type = SerialisationTypeMap[typeName];
  if (!type) {
    return plainObject;
  }
  const instance = new (type as any)();
  const props = Reflect.getMetadata(metadataKey, type.prototype) || {};
  Object.entries(props).forEach(([k, v]) => {
    const value = plainObject[k];
    if (Array.isArray(value)) {
      instance[k] = value.map((e) => {
        if (value && typeof e === "object") {
          return fromPlain(e);
        } else {
          return e;
        }
      });
    } else if (value && typeof value === "object") {
      instance[k] = fromPlain(value);
    } else {
      instance[k] = value;
    }
  });
  return instance as unknown as T;
}

export function cloneForm<T extends MetaForm>(form: T): T {
  return fromPlain(JSON.parse(JSON.stringify(toPlain(form))));
}
