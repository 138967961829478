/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Expose } from "class-transformer";
import { ExposeProperty, MetaFormComponentInfo } from "./baseComponent";
import { UploadComponent } from "./uploadComponent";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-image",
  type: "image",
  icon: "image",
  name: "Image",
})
export class ImageComponent<PrimaryDataSource = any> extends UploadComponent<PrimaryDataSource> {
  @ExposeProperty() public width = "100%";
  @ExposeProperty() public height = "100%";
  /**
   * The title is a Template.
   */
  @ExposeProperty() public title: string;
  @ExposeProperty() public fit?: "cover" | "contain" = "cover";

  public static create<T>(dataSource: T, opts: Partial<ImageComponent<T>>) {
    const input = new ImageComponent<T>();
    input.setOptions(opts as any);
    return input;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        width: this.width,
        height: this.height,
        fit: this.fit,
      },
    });
  }
}
