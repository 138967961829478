/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormBaseComponent, MetaFormComponentInfo } from "./baseComponent";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-calculation-templates",
  type: "calculation-templates",
  name: "Calculation-templates",
  hideInPallet: true,
})
export class MetaCalculationTemplatesComponent extends MetaFormBaseComponent {
  constructor() {
    super();
  }

  public static create<T extends new () => any, S extends new () => any>(
    dataSource: T,
    opts: Partial<MetaCalculationTemplatesComponent>
  ) {
    const input = new MetaCalculationTemplatesComponent();
    Object.assign(input, opts);
    return input;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {});
  }
}
