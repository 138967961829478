/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2020
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2020
 * Written by Yannick Küchlin <y.kuechlin@taifun-tofu.de>, 2019-2020
 */

import { MetaNumberPipe } from "./metaNumber.pipe";
import { NgModule } from "@angular/core";
import { MetaFileSizePipe } from "./filesize.pipe";
import { SearchHighlightPipe } from "./searchHighlight.pipe";
import { HtmlSanitizerPipe } from "./htmlSanitizer.pipe";
import { LineBreakPipe } from "./lineBreak.pipe";

@NgModule({
  exports: [MetaNumberPipe, MetaFileSizePipe, SearchHighlightPipe, HtmlSanitizerPipe, LineBreakPipe],
  declarations: [MetaNumberPipe, MetaFileSizePipe, SearchHighlightPipe, HtmlSanitizerPipe, LineBreakPipe],
})
export class PipesModule {}
