/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2021
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2021
 */

import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Input,
  NgModule,
  Optional,
  Signal,
  ViewEncapsulation,
  input,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { PortalModule } from "@angular/cdk/portal";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaFormHeaderService, MetaFormHeaderTarget, ToolbarTargetType } from "./metaFormHeader.service";

@MetaUnsubscribe()
@Component({
  selector: "meta-form-header",
  template: `
    @if (target()) {
      @switch (headerType) {
        @case ("page") {
          <nz-page-header [nzGhost]="false">
            <nz-page-header-title>
              <span id="meta-page-header-label-container" class="meta-page-header-label-container">
                @for (portal of target()?.labelPortals(); track portal) {
                  <ng-container *cdkPortalOutlet="portal" />
                }
              </span>
            </nz-page-header-title>
            <nz-page-header-subtitle>
              <span id="meta-page-header-sublabel-container">
                @for (portal of target()?.sublabelPortals(); track portal) {
                  <ng-container *cdkPortalOutlet="portal" />
                }
              </span>
            </nz-page-header-subtitle>
            <nz-page-header-extra>
              <div id="meta-page-header-actions-container">
                @for (portal of target()?.actionsPortals(); track portal) {
                  <ng-container *cdkPortalOutlet="portal" />
                }
              </div>
            </nz-page-header-extra>
          </nz-page-header>
        }
        @case ("modal") {
          <div class="modal-header meta-modal-header-label-container">
            <div class="modal-header-label">
              @for (portal of target()?.labelPortals(); track portal) {
                <ng-container *cdkPortalOutlet="portal" />
              }
            </div>
            <div class="modal-header-sublabel">
              @for (portal of target()?.sublabelPortals(); track portal) {
                <ng-container *cdkPortalOutlet="portal" />
              }
            </div>
            <div class="modal-header-actions">
              @for (portal of target()?.actionsPortals(); track portal) {
                <ng-container *cdkPortalOutlet="portal" />
              }
            </div>
          </div>
        }
      }
    }
  `,
  styleUrls: ["./metaFormHeader.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaFormHeaderComponent {
  public target: Signal<MetaFormHeaderTarget>;
  constructor(
    @Optional()
    public metaFormHeaderService: MetaFormHeaderService,
  ) {
    this.target = computed(() => {
      return this.metaFormHeaderService.targets()?.[this.targetId()];
    });
  }

  public targetId = input<ToolbarTargetType>();

  @Input({
    required: true,
  })
  public headerType: "modal" | "page";
}

@NgModule({
  declarations: [MetaFormHeaderComponent],
  imports: [CommonModule, PortalModule, NzPageHeaderModule],
  exports: [MetaFormHeaderComponent],
})
export class MetaFormHeaderModule {}
