/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { FormlyModule } from "@ngx-formly/core";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaAppService } from "../../services/metaApp.service";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaMediaLibraryService } from "../metaMediaLibrary/metaMediaLibrary.service";

export class MetaImage {
  width?: number;
  height?: number;
  editing? = false;
  showDummy? = false;
  fit = "cover";
}

@MetaUnsubscribe()
@Component({
  selector: "meta-image",
  template: `
    <div role="button" (click)="click(!editing)" [ngClass]="{ hasError: error }" class="image-container">
      <div *ngIf="editing" class="overlay">
        <i class="fas fa-upload"></i>
      </div>
      <ng-container *ngIf="url as _url; else elseTpl">
        <img [ngStyle]="{ 'object-fit': ma.fit || 'cover' }" (error)="handleError($event)" [src]="_url" />
      </ng-container>
      <ng-template #elseTpl></ng-template>
    </div>
  `,
  styleUrls: ["./metaImage.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaImageComponent extends MetaComponentBase {
  public error: any;
  public errorValue: string;
  private placeholderImage = `/assets/images/no-image-placeholder.svg`;

  constructor(
    _changeDetectorRef: ChangeDetectorRef,
    _metaHelperService: MetaHelperService,
    public metaAppRepository: MetaAppService,
    public sanitizer: DomSanitizer,
    public readonly mediaLibraryService: MetaMediaLibraryService,
  ) {
    super();
    super.maParams = new MetaImage();
  }

  get ma(): MetaImage {
    return super.ma;
  }

  get url() {
    if (this.ma.showDummy || !this.formControl.value) {
      return this.placeholderImage;
    }
    if (this.formControl.value === this.errorValue) {
      return this.placeholderImage;
    }
    return this.sanitizer.bypassSecurityTrustUrl(
      `/api/v2/file/${this.formControl.value}/download?width=128&height=128&fit=inside`,
    );
  }

  async ngOnInit() {
    super.ngOnInit();
  }

  click(preview = false) {
    if (this.formControl.value && preview) {
      this.mediaLibraryService.previewFileById(this.formControl.value);
    } else if (!preview) {
      this.selectFile();
    }
  }

  selectFile() {
    if (this.ma.showDummy) {
      return;
    }
    this.mediaLibraryService
      .selectSingleFile({
        type: "image",
      })
      .then((file) => {
        if (file) {
          this.formControl.setValue(file.id);
          this.formControl.markAsDirty();
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  handleError($event: ErrorEvent) {
    this.errorValue = this.formControl.value;
    this.error = $event;
    this.changeDetectorRef.markForCheck();
  }
}

@NgModule({
  declarations: [MetaImageComponent],
  schemas: [],
  imports: [CommonModule, FormlyModule, NzUploadModule, ReactiveFormsModule],
  exports: [MetaImageComponent],
})
export class MetaIconModule {}
