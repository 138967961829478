/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule, TemplateRef, ViewEncapsulation } from "@angular/core";
import { MetaIconStyle } from "@meta/enums";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaIconModule } from "../metaIcon/metaIcon.component";

export class MetaEmpty {
  action?: TemplateRef<any>;
  icon?: string;
  iconType?: "fa" | "lord";
  iconStyle?: MetaIconStyle | string = MetaIconStyle.Light;
  iconSize?: string;
  description?: string;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-empty",
  template: `
    <nz-empty [nzNotFoundContent]="contentTpl" [nzNotFoundFooter]="ma.action" [nzNotFoundImage]="imageTpl">
      <ng-template #imageTpl>
        @switch (ma.iconType) {
          @case ("lord") {
            <meta-icon [maParams]="{ icon: ma.icon, size: 200, delay: 0 }" />
          }
          @default {
            <i [style.font-size]="ma.iconSize" class="{{ ma.iconStyle }} fa-{{ ma.icon }}"></i>
          }
        }
      </ng-template>
      <ng-template #contentTpl>
        <span [innerHTML]="ma.description"></span>
      </ng-template>
    </nz-empty>
  `,
  styleUrls: ["./metaEmpty.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaEmptyComponent extends MetaComponentBase {
  constructor(_changeDetectorRef: ChangeDetectorRef, _metaHelperService: MetaHelperService) {
    super();
    super.maParams = new MetaEmpty();
  }

  get ma(): MetaEmpty {
    return super.ma;
  }
}

@NgModule({
  declarations: [MetaEmptyComponent],
  imports: [CommonModule, NzEmptyModule, MetaIconModule],
  exports: [MetaEmptyComponent],
})
export class MetaEmptyModule {}
