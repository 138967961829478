/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ExposeProperty, MetaFormBaseComponent, MetaFormComponentInfo } from "./baseComponent";
import { Expose } from "class-transformer";
import { IsOptional } from "class-validator";
import * as _ from "lodash";
import { EntityType } from "../../../api-interfaces/src";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-task-view",
  type: "task-view",
  name: "Task view",
  icon: "check",
})
export class TaskViewComponent extends MetaFormBaseComponent {
  @ExposeProperty()
  public sourceType: string;
  @ExposeProperty()
  public idField: string;

  public static createElement<T>(opts: Partial<TaskViewComponent>) {
    const input = new TaskViewComponent();
    Object.assign(input, opts);
    return input;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        sourceType: this.sourceType,
        idField: this.idField,
      },
    });
  }
}
