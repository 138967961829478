/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

export const defaultActionTarget = "meta-page-header-actions-container";
export const defaultLabelTarget = "meta-page-header-label-container";
export const defaultSublabelTarget = "meta-page-header-sublabel-container";
export const modalActionTarget = "meta-modal-header-actions-container";
export const modalLabelTarget = "meta-modal-header-label-container";
export const modalSublabelTarget = "meta-modal-header-sublabel-container";
