/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Expose } from "class-transformer";
import { MetaFormBaseComponent, MetaFormComponentInfo, MetaFormComponentProperty } from ".";
import { MetaFormLayoutComponent } from "./layoutComponent";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-card-list-item",
  type: "card-list-item",
  name: "card-list-item",
  hideInPallet: true,
})
class ListItem {
  value: string;
  icon?: string;
}

@MetaFormComponentInfo({
  selector: "ma-card",
  type: "card",
  name: "Card",
  icon: "address-card",
  hideInPallet: true,
})
export class CardComponent<Sources = {}, ComponentType = []> extends MetaFormLayoutComponent {
  @MetaFormComponentProperty({
    name: "Avatar",
    type: "text",
    isTemplate: true,
  })
  public avatar?: string;
  @MetaFormComponentProperty({
    name: "List",
    type: "children",
    allowedChildren: ["card-list-item"],
  })
  public list: ListItem[];
  @MetaFormComponentProperty({
    name: "List Style",
    type: "select",
    values: [
      {
        label: "vertical",
        value: "vertical",
      },
      {
        label: "horizontal",
        value: "horizontal",
      },
    ],
    default: "horizontal",
  })
  public listStyle?: "vertical" | "horizontal" = "horizontal";

  @MetaFormComponentProperty({
    name: "Children",
    type: "children",
    allowedChildren: "all",
  })
  public children: MetaFormBaseComponent[] = [];

  constructor() {
    super();
  }

  public static create<T>(dataSource: T, opts: Partial<CardComponent>) {
    const input = new CardComponent();
    Object.assign(input, opts);
    return input;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        listStyle: this.listStyle,
        showGroupWrapper: false,
      },
    });
  }
}
