/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Expose } from "class-transformer";
import {
  DataSourceParameters,
  ExposeProperty,
  MetaFormBaseComponent,
  MetaFormComponentInfo,
  MetaFormComponentProperty,
} from "./baseComponent";

import { IMetaFormChartFilter, MetaChartSeriesType, MetaGroupStyle, MetaSize } from "@meta/api-interfaces";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

export abstract class ChartComponent extends MetaFormBaseComponent {
  @MetaFormComponentProperty({
    name: "Chart Type",
    type: "select",
    tab: "data",
    values: Object.entries(MetaChartSeriesType).map((k, v) => {
      return {
        value: String(k[0]),
        label: String(k[0]),
      };
    }),
  })
  public seriesType: keyof typeof MetaChartSeriesType | string;
  @MetaFormComponentProperty({
    name: "Chart Datasource",
    type: "dataSource",
    tab: "data",
    paramsField: "dataSourceParameters",
  })
  public dataSource: any;
  /**
   * Cache Time in Seconds default 1h set -1 to disable caching
   */
  @MetaFormComponentProperty({
    name: "Cache TTL",
    type: "number",
    tab: "data",
    unit: "seconds",
    min: 0,
    max: 60 * 60 * 12,
    default: 0,
  })
  public maxCacheTime: number = 60 * 5;
  @ExposeProperty() public trendlines: { label: string; path: string; color: string }[] = [];
  @ExposeProperty() public showGroupWrapper = false;
  @ExposeProperty() public showValues = false;
  @ExposeProperty() public showLegend = true;
  @MetaFormComponentProperty({
    name: "Height",
    type: "number",
    tab: "style",
    unit: "px",
  })
  public height: number;
  @MetaFormComponentProperty({
    name: "Value Link",
    type: "text",
    isTemplate: true,
    tab: "general",
  })
  public valueLink: string;
  @ExposeProperty() public orderBy?: string = null;
  @ExposeProperty() public orderByDirection?: "asc" | "desc" = "asc";

  @MetaFormComponentProperty({
    name: "Display Label",
    type: "text",
    isTemplate: true,
    tab: "general",
  })
  public displayLabel?: string;

  @MetaFormComponentProperty({
    name: "Chart style",
    type: "text",
    tab: "style",
  })
  public style: "default" | "blank" = "default";

  @ExposeProperty()
  public dataSourceParameters?: DataSourceParameters;

  @ExposeProperty() public groupWrapperStyle: MetaGroupStyle;
  @ExposeProperty() public groupWrapperSize: MetaSize;

  @ExposeProperty() public filters: IMetaFormChartFilter[];

  @ExposeProperty()
  public components: any = {};

  protected constructor() {
    super();
    this.readonly = true;
  }

  toFormly(config: IFormUserConfiguration) {
    return deepMerge(super.toFormly(config), {
      props: {
        seriesType: this.seriesType,
        height: this.height,
        showGroupWrapper: this.showGroupWrapper,
        groupWrapperStyle: this.groupWrapperStyle,
        groupWrapperSize: this.groupWrapperSize,
        showValues: this.showValues,
        showLegend: this.showLegend,
        style: this.style,
      },
    });
  }
}

@MetaFormComponentInfo({
  selector: "ma-chart-gauge",
  type: "gaugeChart",
  name: "Gauge Chart",
  icon: "gauge",
  palletGroup: "reporting",
})
export class ChartGaugeComponent extends ChartComponent {
  @MetaFormComponentProperty({
    name: "valueField",
    type: "path",
    datasourceField: "dataSource",
    tab: "data",
  })
  public valueField: string;
  @MetaFormComponentProperty({
    name: "trendField",
    type: "path",
    datasourceField: "dataSource",
    tab: "data",
  })
  public trendField?: string;

  @MetaFormComponentProperty({
    name: "trendLabel",
    type: "text",
    tab: "general",
    isTemplate: true,
  })
  public trendLabel?: string;

  constructor() {
    super();
    this.seriesType = MetaChartSeriesType.angulargauge;
  }

  public static create(opts: Partial<ChartGaugeComponent>) {
    const item = new ChartGaugeComponent();
    Object.assign(item, opts);
    return item;
  }
}

@MetaFormComponentInfo({
  selector: "ma-chart-map",
  type: "mapChart",
  name: "Map Chart",
  icon: "globe",
  palletGroup: "reporting",
})
export class ChartMapComponent extends ChartComponent {
  @MetaFormComponentProperty({
    name: "Label Feld",
    type: "path",
    datasourceField: "dataSource",
    tab: "data",
  })
  public labelField: string;
  @MetaFormComponentProperty({
    name: "Label Field Label",
    type: "text",
    isTemplate: true,
    tab: "general",
  })
  public labelFieldLabel: string;
  @MetaFormComponentProperty({
    name: "Value Field",
    type: "path",
    datasourceField: "dataSource",
    tab: "data",
  })
  public valueField: string;
  @MetaFormComponentProperty({
    name: "Value Field Label",
    type: "text",
    tab: "general",
    isTemplate: true,
  })
  public valueFieldLabel: string;

  constructor() {
    super();
    this.seriesType = "europe";
  }

  public static create(opts: Partial<ChartMapComponent>) {
    const item = new ChartMapComponent();
    Object.assign(item, opts);
    return item;
  }

  public static createElement(attributes: any, children: any[]) {
    const component = new ChartMapComponent();
    component.setOptions(attributes);
    return component;
  }
}

@MetaFormComponentInfo({
  selector: "ma-chart-single-series",
  type: "singleChart",
  name: "Simple Chart",
  icon: "chart-pie",
  palletGroup: "reporting",
})
export class ChartSingleSeriesComponent extends ChartComponent {
  @MetaFormComponentProperty({
    name: "Label Feld",
    type: "path",
    datasourceField: "dataSource",
    tab: "data",
  })
  public labelField: string;
  @MetaFormComponentProperty({
    name: "Label Field Label",
    type: "text",
    isTemplate: true,
    tab: "general",
  })
  public labelFieldLabel: string;
  @MetaFormComponentProperty({
    name: "Value Field",
    type: "path",
    datasourceField: "dataSource",
    tab: "data",
  })
  public valueField: string;
  @MetaFormComponentProperty({
    name: "Value Field Label",
    type: "text",
    tab: "general",
    isTemplate: true,
  })
  public valueFieldLabel: string;

  constructor() {
    super();
    this.seriesType = MetaChartSeriesType.area2d;
  }

  public static create(opts: Partial<ChartSingleSeriesComponent>) {
    const item = new ChartSingleSeriesComponent();
    Object.assign(item, opts);
    return item;
  }

  public static createElement(attributes: any, children: any[]) {
    const component = new ChartSingleSeriesComponent();
    component.setOptions(attributes);
    return component;
  }
}

@MetaFormComponentInfo({
  selector: "ma-chart-multi-series",
  type: "multiChart",
  name: "Chart",
  icon: "chart-pie",
  palletGroup: "reporting",
})
export class ChartMultiSeriesComponent extends ChartComponent {
  @MetaFormComponentProperty({
    name: "Category Field",
    type: "path",
    datasourceField: "dataSource",
    tab: "data",
  })
  public categoryField: string;
  @MetaFormComponentProperty({
    name: "Series Field",
    type: "path",
    datasourceField: "dataSource",
    tab: "data",
  })
  public seriesField: string;
  @MetaFormComponentProperty({
    name: "Value Field",
    type: "path",
    datasourceField: "dataSource",
    tab: "data",
  })
  public valueField: string;

  constructor() {
    super();
    this.seriesType = MetaChartSeriesType.area2d;
  }

  public static create(opts: Partial<ChartMultiSeriesComponent>) {
    const item = new ChartMultiSeriesComponent();
    Object.assign(item, opts);
    return item;
  }

  public static createElement(attributes: any, children: any[]) {
    const component = new ChartMultiSeriesComponent();
    component.setOptions(attributes);
    return component;
  }
}
