// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  tolgeeApiUrl: "https://tolgee.metaargon.de",
  tolgeeApiKey: "tgpak_gfpxaz3ugzzdonjxm5xhgzlbmi3g2m3bmizhe5tgnqzq",
  sentry: {
    dsn: "",
  },
};

const favIconSvg: HTMLLinkElement = document.querySelector("#favicon_svg");
favIconSvg.href = "/assets/images/logo-icon-dev.svg";
const favIconIco: HTMLLinkElement = document.querySelector("#favicon_ico");
favIconIco.remove();
