/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MetaChartService {
  constructor(private _http: HttpClient) {}

  public getChart(formId: string, chartId: string, condition?: any, filters?: any[]): Observable<any> {
    const filter: any = {
      logic: "and",
      filters: [...(filters ? filters : [])],
    };
    const cond = { ...condition };
    if (cond?.filter?.logic === "and") {
      cond.filter.filters.push(filter);
    } else if (cond?.filter?.logic === "or") {
      filter.filters.push(cond.filter);
      cond.filter = filter;
    } else {
      cond.filter = filter;
    }
    return this._http.post<any>(`chart/${formId}/${chartId}`, cond);
  }
}
