/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { FormEventActionHandler } from "../interfaces";
import { MetaFormComponent, MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";
import { DataSourceType } from "@meta/database/lib/types";
import { IsBoolean, IsString } from "class-validator";
import { ISchedulerDatasourceResponse, MetaGantAndSchedulerBase } from "./metaGantAndSchedulerBase";
export type SchedulerFilterType = "select" | "boolean" | "text" | "hidden" | "search";
@MetaFormComponentInfo({
  selector: "ma-scheduler-filter",
  type: "scheduler-filter",
  name: "Scheduler Filter",
  hideInPallet: true,
})
export class SchedulerFilter extends MetaFormComponent {
  @IsString()
  public label: string;
  @IsString()
  public type: SchedulerFilterType;
  @IsBoolean()
  public clearable? = true;
  @IsString()
  public defaultField?: string;
  @MetaFormComponentProperty({
    name: "Filter dataSource",
    type: "dataSource",
    required: true,
  })
  public dataSource?: FormEventActionHandler<
    {} & Record<string, string | number | boolean>,
    {
      label: string;
      value: string | number;
      default?: boolean;
    }[]
  >;
}

@MetaFormComponentInfo({
  selector: "ma-scheduler",
  type: "scheduler",
  name: "Scheduler",
  icon: "list-timeline",
})
export class MetaSchedulerComponent extends MetaGantAndSchedulerBase {
  @MetaFormComponentProperty({
    name: "showDay",
    type: "boolean",
    required: false,
  })
  showDay = true;
  @MetaFormComponentProperty({
    name: "showWeek",
    type: "boolean",
    required: false,
  })
  showWeek: boolean;
  @MetaFormComponentProperty({
    name: "showMonth",
    type: "boolean",
    required: false,
  })
  showMonth: boolean;
  @MetaFormComponentProperty({
    name: "showYear",
    type: "boolean",
    required: false,
  })
  showYear: boolean;
  @MetaFormComponentProperty({
    name: "showTimeline",
    type: "boolean",
    required: false,
  })
  showTimeline: boolean;
  @MetaFormComponentProperty({
    name: "showTimelineWeek",
    type: "boolean",
    required: false,
  })
  showTimelineWeek: boolean;
  @MetaFormComponentProperty({
    name: "showTimelineMonth",
    type: "boolean",
    required: false,
  })
  showTimelineMonth: boolean;
  @MetaFormComponentProperty({
    name: "showTimelineMatrix",
    type: "boolean",
    required: false,
  })
  showTimelineMatrix: boolean;
  @MetaFormComponentProperty({
    name: "showAgenda",
    type: "boolean",
    required: false,
  })
  showAgenda: boolean;
  @MetaFormComponentProperty({
    name: "defaultView",
    type: "select",
    required: false,
  })
  defaultView: "day" | "week" | "month" | "timeline" | "timelineWeek" | "timelineMonth" | "timelineMatrix" | "agenda" =
    "month";

  public static create(opts: Partial<MetaSchedulerComponent>) {
    const item = new MetaSchedulerComponent();
    Object.assign(item, opts);
    return item;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      key: this.id,
      type: `meta-${this.constructor["meta"]["type"]}`,
      props: {
        hasCondition: this.condition && typeof this.condition === "string",
        showDay: this.showDay,
        showWeek: this.showWeek,
        showMonth: this.showMonth,
        showYear: this.showYear,
        showTimeline: this.showTimeline,
        showTimelineWeek: this.showTimelineWeek,
        showTimelineMonth: this.showTimelineMonth,
        showTimelineMatrix: this.showTimelineMatrix,
        showAgenda: this.showAgenda,
        defaultView: this.defaultView,
      },
      wrappers: null,
    });
  }
}
