/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { NgModule } from "@angular/core";
import { MetaTreeviewComponent } from "./metaTreeview.component";
import { NzTreeViewModule } from "ng-zorro-antd/tree-view";
import { CommonModule } from "@angular/common";
import { NzTreeModule } from "ng-zorro-antd/tree";
import { RouterModule } from "@angular/router";
import { PipesModule } from "../../pipes/pipes.module";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzSanitizerPipe } from "ng-zorro-antd/pipes";
import { MetaTreeViewColorPipe } from "./MetaTreeviewColor.pipe";
@NgModule({
  declarations: [MetaTreeviewComponent],
  exports: [MetaTreeviewComponent],
  imports: [
    NzTreeViewModule,
    CommonModule,
    RouterModule,
    PipesModule,
    NzIconModule,
    NzSanitizerPipe,
    MetaTreeViewColorPipe,
  ],
  providers: [],
})
export class MetaTreeviewModule {}
