/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2020
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2020
 */

import { IsOptional, IsString } from "class-validator";

export class MetaPrinter {
  name: string;
  id: string;
}


export class PrintRequest {
  @IsString()
  @IsOptional()
  fileId?: string;

  @IsString()
  @IsOptional()
  url?: string;

  public static createFromUrl(url: string) {
    const request = new PrintRequest();
    request.url = url;
    return request;
  }

  public static createFromFileId(fileId: string) {
    const request = new PrintRequest();
    request.fileId = fileId;
    return request;
  }
}
