import { DatePipe, JsonPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed, effect, input, Input, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { RouterModule } from "@angular/router";
import { MetaState } from "@meta/enums";
import { MetaAvatarModule } from "../../../components/metaAvatar/metaAvatar.component";
import { interval, Subject, takeUntil } from "rxjs";
import { MetaButtonModule } from "../../../components/metaButton/metaButton.component";
import { MetaInputModule } from "../../../components/metaInput/metaInput.component";

import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDescriptionsModule } from "ng-zorro-antd/descriptions";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { ApproveService } from "../../../../../../../apps/meta-argon/src/app/pages/approve/approve.service";
import { DurationPipe } from "../../../pipes/duration.pipe";
import { MetaHelperService } from "../../../services/metaHelper.service";
import { MetaFormService } from "../metaForm.service";

@Component({
  selector: "meta-form-active-workflow",
  template: `
    <nz-button-group>
      <button
        nz-popover
        nzPopoverTrigger="hover"
        [nzPopoverContent]="contentTemplate"
        [nzLoading]="true"
        [disabled]="true"
        (nzPopoverVisibleChange)="$popoverVisibleChange.next([$event, exec])"
        nz-button
        nzType="primary"
      >
        @if (exec().statusObject) {
          @switch (exec().statusObject.type) {
            @case ("wait-time") {
              Wartet {{ waitTime() | metaDuration: "long" }}
            }
            @default {
              {{ exec().status }}
            }
          }
        } @else {
          {{ exec().status }}
        }
      </button>
      <ng-template #contentTemplate>
        <nz-descriptions [nzColumn]="4" nzBordered>
          <nz-descriptions-item [nzSpan]="2" nzTitle="Workflow"
            ><a routerLink="/workflow/{{ exec().workflow.id }}/executions/{{ exec().id }}">{{
              exec().workflow.name
            }}</a>
          </nz-descriptions-item>
          <nz-descriptions-item [nzSpan]="2" nzTitle="Gestartet von">
            <meta-avatar [maParams]="{ id: $any(exec().user.id), size: 'tiny' }" />
            <span style="margin-left: 6px">{{ exec().user.name }}</span>
          </nz-descriptions-item>
          <nz-descriptions-item [nzSpan]="2" nzTitle="Start">{{
            exec().startDate | date: "short"
          }}</nz-descriptions-item>
          <nz-descriptions-item [nzSpan]="2" nzTitle="Dauer">{{
            duration() | metaDuration: "short"
          }}</nz-descriptions-item>

          <nz-descriptions-item nzTitle="Status" [nzSpan]="4">
            <nz-badge nzStatus="processing" nzText="{{ exec().status }}"></nz-badge>
          </nz-descriptions-item>
        </nz-descriptions>
      </ng-template>
      @if (exec().action) {
        <button (click)="openAction(exec().action.id)" nz-button nzType="primary">
          <i class="fal fa-check-double"></i>
        </button>
      }
    </nz-button-group>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  standalone: true,
  imports: [
    MetaButtonModule,
    MetaInputModule,
    NzToolTipModule,
    NzButtonModule,
    NzDescriptionsModule,
    NzPopoverModule,
    NzBadgeModule,
    DatePipe,
    RouterModule,
    DurationPipe,
    MetaAvatarModule,
    MetaAvatarModule,
    JsonPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaFormActiveWorkflowComponent {
  public readonly exec = input<any>();
  public readonly $popoverVisibleChange = new Subject<[boolean, any]>();

  public readonly duration = signal(0);
  public readonly waitTime = signal(0);

  constructor(
    public readonly metaFormService: MetaFormService,
    public readonly metaHelperService: MetaHelperService,
    private readonly approveService: ApproveService,
  ) {
    let n: number;
    interval(1000)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        if (n) {
          cancelAnimationFrame(n);
        }
        n = requestAnimationFrame(() => {
          this.draw();
          n = null;
        });
      });
  }

  public draw() {
    const durationMs = new Date().getTime() - new Date(this.exec().startDate).getTime();
    this.duration.set(durationMs);
    const statusObject = this.exec()?.statusObject;
    if (statusObject) {
      const date = statusObject.data?.date;
      if (date) {
        const durationMs = new Date().getTime() - new Date(date).getTime();
        this.waitTime.set(durationMs);
      }
    }
  }

  public openAction(id: string) {
    this.approveService.showModal(String(id).toLowerCase()).catch(console.error);
  }
}
