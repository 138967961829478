/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, Input, OnInit } from "@angular/core";
import { MetaHelperService } from "../../../services/metaHelper.service";
import { IFeedResultItem } from "@meta/api-interfaces";

export class MetaTimelineTemplatePipelineObjectMoved {
  data: IFeedResultItem<{
    execId: string;
    workflowId: string;
    name: string;
  }>;
}

@Component({
  selector: "meta-timeline-template-form-workflow-execution-end",
  template: `Workflow
    <a href="/workflow/{{ item.data.data.workflowId }}/executions/{{ item.data.data.execId }}">
      <meta-tag [maParams]="{ label: tplWf, color: colorWorkflow, maxWidth: 250 }"></meta-tag>
    </a>
    für {{ item.data.entity.name }}
    <a href="{{ item.data.entity.link }}">
      <meta-tag [maParams]="{ label: tplLabel, color: color, maxWidth: 250 }"></meta-tag>
    </a>
    wurde beendet.

    <ng-template #tplWf><i class="far fa-stop"></i>{{ item.data.data.name }}</ng-template>
    <ng-template #tplLabel
      ><i class="far fa-{{ item.data.entity.icon }}"></i>{{ item.data.entity.title }}</ng-template
    >`,
})
export class MetaTimelineTemplateFormWorkflowExecutionEndComponent implements OnInit {
  @Input() public item: MetaTimelineTemplatePipelineObjectMoved;

  public color: string;
  public colorWorkflow: string;
  constructor(public metaHelperService: MetaHelperService) {}

  ngOnInit() {
    this.color = this.item.data.entity.color || this.metaHelperService.generateColor(this.item.data.entity.name);
    this.colorWorkflow =
      this.item.data.entity.color || this.metaHelperService.generateColor(this.item.data.data.workflowId);
  }
}
