/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormBaseComponent, MetaFormComponentInfo } from "./baseComponent";

@MetaFormComponentInfo({
  selector: "ma-calculation",
  type: "calculation",
  name: "Calculation",
  hideInPallet: true,
})
export class MetaCalculationComponent<T = {}> extends MetaFormBaseComponent {
  constructor() {
    super();
  }

  public static create<T extends new () => any, S extends new () => any>(
    dataSource: T,
    opts: Partial<MetaCalculationComponent>
  ) {
    const input = new MetaCalculationComponent<T>();
    Object.assign(input, opts);
    return input;
  }
}
