/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { HttpClient } from "@angular/common/http";
import { ApplicationRef, Injectable } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { EntityType } from "@meta/enums";
import * as _ from "lodash";
import { NzMessageService } from "ng-zorro-antd/message";
import { BehaviorSubject } from "rxjs";
import { take } from "rxjs/operators";
import * as uuid from "uuid";
import { FormSupervisorService } from "../../base/metaForm/metaForm.supervisor";
import { MetaErrorHandlerService } from "../../services/metaErrorHandler.service";

export interface MetaCalculactionGroupItem {
  id: string;
  typId: string;
  label: string;
  children?: MetaCalculactionItem[];
  groupType?: "minor" | "major" | number;
  sortOrder: number;
  sticky?: boolean;
}

export interface MetaCalculactionItem {
  id: string;
  groupId?: string;
  typId: string;
  label: string;
  sortOrder: number;
  valueType: "currency" | "percent";
  calcType: "add" | "subtract";
  value?: number;
}

export interface MetaCalculactionPosition {
  [id: string]: any;
}

export interface MetaCalculactionTemplate {
  ID: number;
  label: string;
  description: string;
  items?: MetaCalculactionItem[];
}

interface MetaCalculationResult {
  children?: MetaCalculactionGroupItem[];
  version: number;
  titel?: string;

  [id: string]: any;
}

@Injectable({
  providedIn: "root",
})
export class MetaCalculationService {
  public priceField = "pPreisProEinheit";
  public priceUnitField = "priceUnit";
  public quantityField = "quantity";
  public quantityUnitField = "quantityUnit";
  public idField = "ArtikelNr";
  public baseSuffixFIeld = "Eur";
  public versionData$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public editing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public saving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public parentFormId: string;
  public tabId: string;
  public sourceId: string;
  public uuid: string;
  public isLoading = true;
  public isCreating = false;
  public isDeleting = false;
  public expandSubpos: boolean;

  public calcTypes = [
    {
      value: 0,
      label: "Abschlag",
    },
    {
      value: 1,
      label: "Aufschlag",
    },
  ];

  public valueTypes = [
    {
      value: 0,
      label: "Absolut",
    },
    {
      value: 1,
      label: "Prozentual",
    },
  ];

  public inputSources = [
    {
      value: 0,
      label: "EK-Preis",
    },
    {
      value: 1,
      label: "Standardpreis",
    },
    {
      value: 2,
      label: "Periodischer Durchschnittspreis",
    },
  ];

  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Positions are the vertical rows (e.g. articles)
  public positions$: BehaviorSubject<MetaCalculactionPosition[]> = new BehaviorSubject<MetaCalculactionPosition[]>([]);
  public positionsModel: any = {};

  // Items are the horizontal rows (e.g. Discounts or Duty etc.)
  public items$: BehaviorSubject<MetaCalculactionGroupItem[]> = new BehaviorSubject<MetaCalculactionGroupItem[]>([]);
  public titel: string;

  public itemModel: any = {};

  public templates$: BehaviorSubject<MetaCalculactionTemplate[]> = new BehaviorSubject<MetaCalculactionTemplate[]>([]);
  public templateModel: any = {
    template: null,
  };
  public versionModel: any = {
    version: 1,
  };
  public groupSettingsModel: any = {};
  public itemSettingsModel: any = {};

  public hasCalculation$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

  constructor(
    private _http: HttpClient,
    private _metaErrorHandlerService: MetaErrorHandlerService,
    private _nzMessageService: NzMessageService,
    private _fb: UntypedFormBuilder,
    private _applicationRef: ApplicationRef,
    private readonly _formSupervisorService: FormSupervisorService,
  ) {}

  public hasCalculation(quelleId: any, quelleTyp: EntityType): void {
    this._http.get<any[]>(`calculation/hasCalculation/${quelleId}/${quelleTyp}`).subscribe({
      next: async (result: any[]) => {
        this.hasCalculation$.next(result.length > 0);
        if (result.length > 0) {
          await this.getCalculation(quelleId, quelleTyp, result[result.length - 1].value);
        }
      },
      error: (err) => {
        this._metaErrorHandlerService
          .handleError(err)
          .pipe(take(1))
          .subscribe({
            error: (value) => this._nzMessageService.error(value),
          });
      },
    });
  }

  public async createCalculation(version?: number) {
    this.isCreating = true;
    this.isLoading = true;
    console.log(this._formSupervisorService.getServiceById("frmOffer"));
    const offer = this._formSupervisorService.getServiceById("frmOffer").metaFormService.formState.data();
    this._http
      .post<MetaCalculationResult>(`calculation`, {
        template: !version ? this.templateModel.template : null,
        version: version || 1,
        quelleId: offer._id.join(),
        quelleTyp: EntityType.Angebot,
        validUntil: offer.DatumGueltig,
        currency: offer.Waehrungskuerzel,
        positions: offer.positions,
        posData: this.positions$.getValue(),
      })
      .subscribe({
        next: async (result: MetaCalculationResult) => {
          this.titel = result.titel;
          result.children[result.children.length - 1].sticky = true;
          this.items$.next(
            result.children.map((group) => {
              if (group.children) {
                group.children.map((item) => {
                  item.groupId = group.id;
                  item.calcType = item.calcType ? "add" : ("subtract" as any);
                  item.valueType = item.valueType ? "currency" : ("percent" as any);
                  return item;
                });
              }
              group.groupType = group.groupType ? "major" : ("minor" as any);
              return group;
            }),
          );
          this.initControls();
          await this.updateControls(result.version);
          this.hasCalculation$.next(true);
          this.isCreating = false;
          this.isLoading = false;
          this._nzMessageService.success("Kalkulation erstellt");
          await this.getVersions(this.sourceId, EntityType.Angebot);
        },
        error: (err) => {
          this._metaErrorHandlerService
            .handleError(err)
            .pipe(take(1))
            .subscribe({
              error: (value) => this._nzMessageService.error(value),
            });
        },
      });
  }

  public updateCalculation(version?: number): void {
    this.isLoading = true;
    this._http
      .patch<MetaCalculationResult>(`calculation`, {
        version: version || 1,
        quelleId: this.sourceId,
        quelleTyp: EntityType.Angebot,
        items: this.items$
          .getValue()
          .filter((group) => group.sortOrder === 0 || group.children.length !== 0)
          .map((group) => {
            if (group.children) {
              group.children.forEach((item) => {
                (item.label = this.itemModel[`title${item.typId}`]),
                  (item.groupId = group.id),
                  (item.calcType = item.calcType === "add" ? 1 : (0 as any));
                item.valueType = item.valueType === "currency" ? 1 : (0 as any);
              });
            }
            (group.label = this.itemModel[`title${group.typId}`]),
              (group.groupType = group.groupType === "major" ? 1 : (0 as any));
            return group;
          }),
        positions: this.positions$.getValue().map((pos, i) => {
          let _obj = {};
          for (const [key, value] of Object.entries(this.positionsModel[i])) {
            const x = Number(value);
            _obj[key] = Number.isNaN(x) ? value : x;
          }
          pos._form = _obj; //this.positionsModel[i];
          //this.positionsForm[i].markAsPristine();
          //this.positionsForm[i].markAsUntouched();
          return pos;
        }),
      })
      .subscribe({
        next: async (result: MetaCalculationResult) => {
          this.titel = result.titel;
          result.children[result.children.length - 1].sticky = true;
          this.items$.next(
            result.children.map((group) => {
              if (group.children) {
                group.children.map((item) => {
                  (item.groupId = group.id), (item.calcType = item.calcType ? "add" : ("subtract" as any));
                  item.valueType = item.valueType ? "currency" : ("percent" as any);
                  return item;
                });
              }
              group.groupType = group.groupType ? "major" : ("minor" as any);
              return group;
            }),
          );
        },
        error: (err) => {
          this._metaErrorHandlerService
            .handleError(err)
            .pipe(take(1))
            .subscribe({
              error: (value) => this._nzMessageService.error(value),
            });
        },
        complete: () => {
          this.editing$.next(false);
          this.saving$.next(false);
          this.isLoading = false;
        },
      });
  }

  public deleteCalculation(quelleId: string, quelleTyp: EntityType) {
    if (this.versionModel.version === undefined || this.versionModel.version === null) {
      this._nzMessageService.warning("Es wurde keine Version zum löschen ausgewählt");
      return;
    }

    this.isDeleting = true;
    this.editing$.next(false);
    this._http
      .delete<MetaCalculactionTemplate[]>(`calculation/${quelleId}/${quelleTyp}/${this.versionModel.version}`)
      .subscribe({
        next: async (result: MetaCalculactionTemplate[]) => {
          this.items$.next([]);
          this.positions$.next([]);
          /*const controls: any = await this._metaDynamicControlService
            .selectByProperty("pageId", this.parentFormId)
            .pipe(first())
            .toPromise();*/
          //this._metaDynamicControlService.removeControls(controls);
          //this._metaFormService.detachFormsFromPageByPageId(this.parentFormId);
          this.hasCalculation$.next(false);
          this.isDeleting = false;
          this.hasCalculation(quelleId, quelleTyp);
          await this.getVersions(quelleId, quelleTyp);
        },
        error: (err) => {
          this._metaErrorHandlerService
            .handleError(err)
            .pipe(take(1))
            .subscribe({
              error: (value) => this._nzMessageService.error(value),
            });
        },
      });
  }

  public getTemplates(): void {
    this._http.get<MetaCalculactionTemplate[]>(`calculation/templates`).subscribe({
      next: (result: MetaCalculactionTemplate[]) => {
        this.templates$.next(result);
      },
      error: (err) => {
        this._metaErrorHandlerService
          .handleError(err)
          .pipe(take(1))
          .subscribe({
            error: (value) => this._nzMessageService.error(value),
          });
      },
    });
  }

  public getTemplate(id: number): void {
    this.isLoading = true;
    this.items$.next([]);
    this.itemModel = {};
    this._http.get<MetaCalculationResult>(`calculation/template/${id}`).subscribe({
      next: async (result: MetaCalculationResult) => {
        result.children[result.children.length - 1].sticky = true;
        this.items$.next(
          result.children.map((group) => {
            if (group.children) {
              group.children.forEach((item) => {
                (item.groupId = group.id), (item.calcType = item.calcType ? "add" : ("subtract" as any));
                item.valueType = item.valueType ? "currency" : ("percent" as any);
              });
            }
            group.groupType = group.groupType ? "major" : ("minor" as any);
            return group;
          }),
        );
        this.initControls();
        this.hasCalculation$.next(true);
        this.isLoading = false;
      },
      error: (err) => {
        this._metaErrorHandlerService
          .handleError(err)
          .pipe(take(1))
          .subscribe({
            error: (value) => this._nzMessageService.error(value),
          });
      },
    });
  }

  public getCalculation(quelleId: string, quelleTyp: EntityType, version: number): void {
    this.isLoading = true;
    this._http.post<MetaCalculationResult>(`calculation/${quelleId}/${quelleTyp}`, { version }).subscribe({
      next: async (result: MetaCalculationResult) => {
        result.children[result.children.length - 1].sticky = true;
        this.titel = result.titel;
        this.items$.next(
          result.children.map((group) => {
            if (group.children) {
              group.children.forEach((item) => {
                (item.groupId = group.id), (item.calcType = item.calcType ? "add" : ("subtract" as any));
                item.valueType = item.valueType ? "currency" : ("percent" as any);
              });
            }
            group.groupType = group.groupType ? "major" : ("minor" as any);
            return group;
          }),
        );
        this.initControls();
        await this.updateControls(version);
        this.hasCalculation$.next(true);
        this.isLoading = false;
      },
      error: (err) => {
        this._metaErrorHandlerService
          .handleError(err)
          .pipe(take(1))
          .subscribe({
            error: (value) => this._nzMessageService.error(value),
          });
      },
    });
  }

  public async getPositions(quelleId: string, quelleTyp: EntityType, version: number): Promise<any> {
    const result: any = await this._http
      .get<MetaCalculactionPosition[]>(`calculation/positions/${quelleId}/${quelleTyp}/${version}`)
      .toPromise();
    return result.map((res) => {
      return {
        ...res,
        ...JSON.parse(res.JSON_Daten),
      };
    });
  }

  public async getVersions(quelleId: string, quelleTyp: EntityType) {
    const result: any = await this._http
      .get<MetaCalculactionPosition[]>(`calculation/versions/${quelleId}/${quelleTyp}`)
      .toPromise();
    if (!result || result.length === 0) return;
    this.versionData$.next(result);
    this.versionModel.version = result[result.length - 1].value;
  }

  public toggleSubpos() {
    this.expandSubpos = !this.expandSubpos;
    const pos = this.positions$.getValue();
    pos.forEach((p) => {
      p.showSubpos = this.expandSubpos;
    });
  }

  public updateTemplate(id: number): void {
    this.isLoading$.next(true);
    this._http
      .patch<MetaCalculactionTemplate>(`calculation/template`, {
        ID: id,
        items: this.items$.getValue().map((group) => {
          if (group.children) {
            group.children.forEach((item) => {
              item.label = this.itemModel[`title${item.typId}`];
              item.value = parseFloat(this.itemModel[`value${item.typId}`]);
              item.groupId = group.id;
              item.calcType = item.calcType === "add" ? 1 : (0 as any);
              item.valueType = item.valueType === "currency" ? 1 : (0 as any);
            });
          }
          (group.label = this.itemModel[`title${group.typId}`]),
            (group.groupType = group.groupType === "major" ? 1 : (0 as any));
          return group;
        }),
      })
      .subscribe({
        next: (result: any) => {
          this.items$.next(
            result.children.map((group) => {
              if (group.children) {
                group.children.forEach((item) => {
                  (item.groupId = group.id), (item.calcType = item.calcType ? "add" : ("subtract" as any));
                  item.valueType = item.valueType ? "currency" : ("percent" as any);
                });
              }
              group.groupType = group.groupType ? "major" : ("minor" as any);
              return group;
            }),
          );
          this.isLoading$.next(false);
        },
        error: (err) => {
          this._metaErrorHandlerService
            .handleError(err)
            .pipe(take(1))
            .subscribe({
              error: (value) => this._nzMessageService.error(value),
            });
        },
      });
  }

  public getItems(): void {
    this.hasCalculation(this.sourceId, EntityType.Angebot);
    this.getVersions(this.sourceId, EntityType.Angebot);
    this.getTemplates();
  }

  // Adds a new row in the calculation
  public addGroup(label: string, index: number): void {
    const items = this.items$.getValue();
    const id = this.getNewId();
    const newItem = {
      id: id,
      typId: id,
      label: label || null,
      sortOrder: index,
      children: [],
    } as any;
    items.splice(index, 0, newItem);
    items.forEach((item, i) => {
      item.sortOrder = i;
    });
    this.items$.next(items);

    this.itemModel[`title${newItem.typId}`] = newItem.label;
  }

  public addItem(label: string, index: number, parentIndex: number) {
    const items = this.items$.getValue();
    const id = this.getNewId();
    const newItem = {
      id: id,
      label: label || null,
      valueType: "percent" as any,
      groupId: items[parentIndex].typId,
      typId: id,
      sortOrder: index,
      calcType: "add" as any,
    };

    items[parentIndex].children.splice(index, 0, newItem);
    items.forEach((item, i) => {
      item.sortOrder = i;
    });
    this.items$.next(items);

    this.itemModel[`title${newItem.typId}`] = newItem.label;

    // Add new controls for the positions forms (2nd to nth column)
    const positions = this.positions$.getValue();
    positions.forEach((pos, i) => {
      this.positionsModel[i][`value${newItem.typId}_${i}`] = 0;
      if (pos.subpos) {
        pos.subpos.forEach((subpos, j) => {
          this.positionsModel[i][`value${newItem.typId}_${i}_${j}`] = 0;
        });
      }
    });

    setTimeout(() => {
      this._applicationRef.tick();
    }, 1000);
  }

  public removeGroup(index: number): void {
    if (index === 0) {
      return;
    }
    const items = this.items$.getValue();
    items.splice(index, 1);
    this.items$.next(items);
    this._updateSums();
  }

  public removeItem(index: number, parentIndex: number): void {
    const items = this.items$.getValue();
    items[parentIndex].children.splice(index, 1);
    this.items$.next(items);
    this._updateSums();
  }

  public getNewId(): string {
    return uuid.v4();
  }

  // Initializes the controls for each item loaded
  public initControls(): void {
    this.items$.getValue().forEach((item, i) => {
      if (item.children) {
        item.children.forEach((child, j) => {
          this.itemModel[`title${child.typId}`] = child.label;
          this.itemModel[`value${child.typId}`] = child.value;
        });
      }
      this.itemModel[`title${item.typId}`] = item.label;
    });
    this.groupSettingsModel = {
      groupType: [false],
    };
    this.itemSettingsModel = {
      valueType: [0],
      calcType: [0],
      inputSource: [],
      subinputSource: [],
    };
  }

  // Updates the controls every time there is a new position update
  // e.g. adding or removing an position from the offer
  public async updateControls(version?: number) {
    const positions: any = await this.getPositions(
      this.sourceId,
      EntityType.Angebot,
      version ? version : this.versionModel.version,
    );
    positions.forEach((pos, i) => {
      const id = uuid.v4();
      //this._metaFormService.addFormFromControlsConfig(id, {});
      this.positionsModel[i] = {};
      //this._metaFormService.attachFormToPage(id, this.parentFormId, this.tabId);
      this.items$.getValue().forEach((item, j) => {
        if (!pos["_values"]) {
          pos["_values"] = {};
        }
        if (!pos["_sum"]) {
          pos["_sum"] = {};
        }

        let data;
        if (pos.JSON_Daten) {
          data = JSON.parse(pos.JSON_Daten);
        }
        pos["_sum"][`sum${item.typId}`] = data && data["_sum"] ? data["_sum"][`sum${item.typId}`] : 0;

        if (pos.subpos) {
          pos.subpos.forEach((subpos, k) => {
            if (!subpos["_values"]) {
              subpos["_values"] = {};
            }
            if (!subpos["_sum"]) {
              subpos["_sum"] = {};
            }
            subpos["_sum"][`sum${item.typId}`] =
              data.subpos[k] && data.subpos[k]["_sum"] ? data.subpos[k]["_sum"][`sum${item.typId}`] : 0;
          });
        }
      });
    });
    this.positions$.next(positions);
    this.items$.getValue().forEach((item, i) => {
      if (item.children) {
        item.children.forEach((child, j) => {
          this.positions$.getValue().forEach((pos, k) => {
            this.positionsModel[k][`value${child.typId}_${k}`] = pos["_form"]
              ? pos["_form"][`value${child.typId}_${k}`]
              : 0;
            if (pos.subpos) {
              pos.subpos.forEach((subpos, l) => {
                this.positionsModel[k][`value${child.typId}_${k}_${l}`] = pos["_form"]
                  ? pos["_form"][`value${child.typId}_${k}_${l}`]
                  : 0;
              });
            }
          });
        });
      } else if (item.sortOrder === 0) {
        this.positions$.getValue().forEach((pos, k) => {
          let data;
          if (pos.JSON_Daten) {
            data = JSON.parse(pos.JSON_Daten);
          }
          this.positionsModel[k][`sum${item.typId}_${k}`] = data && data["_sum"] ? data["_sum"][`sum${item.typId}`] : 0;
          if (pos.subpos) {
            pos.subpos.forEach((subpos, l) => {
              this.positionsModel[k][`sum${item.typId}_${k}_${l}`] =
                data?.subpos && data.subpos[l] && data.subpos[l]["_sum"]
                  ? data.subpos[l]["_sum"][`sum${item.typId}`]
                  : 0;
            });
          }
        });
      }
    });

    // Add controls
    /*this.positionsForm.forEach((form, index) => {
      for (const control in form.controls) {
        if (form.controls.hasOwnProperty(control)) {
          this._metaDynamicControlService.addControl({
            name: `${control}`,
            id: `${control}`,
            parentId: "_calculation",
            pageId: this.parentFormId,
            formId: this.uuid,
            editable: true,
            editing: this.editing$.getValue(),
          });
        }
      }
    });
    for (const control in this.itemForm.controls) {
      if (this.itemForm.controls.hasOwnProperty(control)) {
        this._metaDynamicControlService.addControl({
          name: `${control}`,
          id: `${control}`,
          parentId: "_calculation",
          pageId: this.parentFormId,
          formId: this.uuid,
          editable: true,
          editing: this.editing$.getValue(),
        });
      }
    }*/

    this._updateSums();
  }

  public division(
    baseValue: number = 0,
    index: number,
    parentIndex: number,
    item: MetaCalculactionItem,
    subIndex?: number,
  ): number {
    let result, value;
    if (subIndex === undefined) {
      result =
        ((baseValue || 0) / (this.positions$.getValue()[index][this.quantityField] || 1)) *
        (this.positions$.getValue()[index][this.priceUnitField] || 1);
      value = this.positions$.getValue()[index][`value${item.typId}`];
    } else {
      result =
        ((baseValue || 0) / (this.positions$.getValue()[index].subpos[subIndex][this.quantityField] || 1)) *
        (this.positions$.getValue()[index][this.priceUnitField] || 1);
      value = this.positions$.getValue()[index].subpos[subIndex][`value${item.typId}`];
    }

    if (result !== value) {
      const positions = _.cloneDeep(this.positions$.getValue());
      if (subIndex === undefined) {
        positions[index]["_values"][`value${item.typId}`] = result;
        positions[index] = this.sum(positions[index]);
      } else {
        positions[index].subpos[subIndex]["_values"][`value${item.typId}`] = result;
        positions[index].subpos[subIndex] = this.sum(positions[index].subpos[subIndex]);
      }
      this.positions$.next(positions);
    }
    return result;
  }

  public percentOf(
    percentValue: number = 0,
    index: number,
    parentIndex: number,
    item: MetaCalculactionItem,
    subIndex?: number,
  ): number {
    const prevParentId = this.items$.getValue()[parentIndex - 1].typId;
    let prevSum, value;
    if (subIndex === undefined) {
      prevSum = this.positions$.getValue()[index]["_sum"][`sum${prevParentId}`];
      value = this.positions$.getValue()[index][`value${item.typId}`];
    } else {
      prevSum = this.positions$.getValue()[index].subpos[subIndex]["_sum"][`sum${prevParentId}`];
      value = this.positions$.getValue()[index].subpos[subIndex][`value${item.typId}`];
    }
    const result = (prevSum || 0) * (percentValue || 0);

    if (result !== value) {
      const positions = _.cloneDeep(this.positions$.getValue());
      if (subIndex === undefined) {
        positions[index]["_values"][`value${item.typId}`] = result;
        positions[index] = this.sum(positions[index]);
      } else {
        positions[index].subpos[subIndex]["_values"][`value${item.typId}`] = result;
        positions[index].subpos[subIndex] = this.sum(positions[index].subpos[subIndex]);
      }
      this.positions$.next(positions);
    }

    return result;
  }

  public sum(positions: MetaCalculactionPosition): MetaCalculactionPosition {
    // Get all dynamic fields
    const positionValues = [];

    const posValue = positions["_values"];
    const posSum = positions["_sum"];

    for (const control in posValue) {
      // eslint-disable-next-line no-prototype-builtins
      if (posValue.hasOwnProperty(control) && control.substr(0, 5) === "value") {
        positionValues.push({
          id: control.substr(5, 50),
          value: posValue[control],
        });
      }
    }
    const items = this.items$.getValue();
    items.forEach((item, index) => {
      if (item.children) {
        const dynamicFields = [];
        item.children.forEach((o) => {
          const elem = positionValues.find((u) => u.id === o.typId);
          if (elem) {
            dynamicFields.push(o.calcType === "subtract" ? elem.value * -1 : elem.value);
          }
        });
        posSum[`sum${item.typId}`] =
          posSum[`sum${items[index - 1].typId}`] + dynamicFields.reduce((a, b) => (a || 0) + (b || 0), 0);
      }
    });
    return positions;
  }

  public initGroupForm(index: number) {
    const groupType = this.items$.getValue()[index].groupType;
    this.groupSettingsModel.groupType = groupType === "major";
  }

  public initItemForm(index: number, parentIndex: number) {
    const calcType = this.items$.getValue()[parentIndex].children[index].calcType;
    const valueType = this.items$.getValue()[parentIndex].children[index].valueType;
    this.itemSettingsModel.calcType = calcType === "add" ? 1 : 0;
    this.itemSettingsModel.valueType = valueType === "percent" ? 1 : 0;
  }

  public onCalcTypeChange(event: any, index: number, parentIndex: number) {
    const items = this.items$.getValue();
    items[parentIndex].children[index].calcType = event ? "add" : "subtract";
    this.items$.next(items);
    this._updateSums();
  }

  public onValueTypeChange(event: any, index: number, parentIndex: number) {
    const items = this.items$.getValue();
    items[parentIndex].children[index].valueType = event ? "percent" : "currency";
    this.items$.next(items);
    this._updateSums();
  }

  public onGroupTypeChange(event: any, index: number) {
    const items = this.items$.getValue();
    items[index].groupType = event ? "major" : "minor";
    this.items$.next(items);
    this._updateSums();
  }

  public _updateSums() {
    const positions = this.positions$.getValue();
    positions.forEach((position, i) => {
      const items = this.items$.getValue();
      items.forEach((group, j) => {
        if (group.children) {
          group.children.forEach((child) => {
            if (child.valueType === "percent") {
              this.percentOf(this.positionsModel[i][`value${child.typId}_${i}`], i, j, child);
              if (position.subpos) {
                position.subpos.forEach((subpos, k) => {
                  this.percentOf(this.positionsModel[i][`value${child.typId}_${i}_${k}`], i, j, child, k);
                });
              }
            }
            if (child.valueType === "currency") {
              this.division(this.positionsModel[i][`value${child.typId}_${i}`], i, j, child);
              if (position.subpos) {
                position.subpos.forEach((subpos, k) => {
                  this.division(this.positionsModel[i][`value${child.typId}_${i}_${k}`], i, j, child, k);
                });
              }
            }
          });
        }
      });
    });
  }

  public async updateOffer(): Promise<boolean> {
    const payload = [];
    const finalSumFieldId = this._getFinalSumFieldId();
    const positions = this.positions$.getValue();
    positions.forEach((pos) => {
      const payloadSub = [];
      if (pos.subpos) {
        pos.subpos.forEach((subpos) => {
          payloadSub.push({
            quantity: subpos[this.quantityField],
            value:
              subpos?._sum && subpos?._sum[`sum${finalSumFieldId}`] !== undefined
                ? Number(subpos._sum[`sum${finalSumFieldId}`]).toFixed(2)
                : 0,
          });
        });
      }
      payload.push({
        id: pos[this.idField],
        position: pos.SortOrder,
        value: pos._sum && pos._sum[`sum${finalSumFieldId}`] ? Number(pos._sum[`sum${finalSumFieldId}`]).toFixed(2) : 0,
        subpos: payloadSub,
      });
    });
    const result: any = await this._http
      .post<boolean>(`calculation/updateOffer/${this.sourceId}/${EntityType.Angebot}`, payload)
      .toPromise();
    return result;
  }

  private _getFinalSumFieldId() {
    const result = this.items$.getValue().find((i) => i.sticky);
    if (result) {
      return result.typId;
    } else {
      throw new Error("Es wurde kein finales Summenfeld gefunden");
    }
  }

  public async onInputSourceChange(event: any, articleNo: string, amount: number) {
    const result: any = await this._http
      .get<boolean>(`calculation/getInputSource/${event}/${articleNo}/${amount}`)
      .toPromise();
    return result;
  }
}
