/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2021
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2021
 */

import { Expose } from "class-transformer";

interface IPushMessageBaseAction {
  title: string | { key: string; params: Record<string, any> };
  color: string;
}

export interface IPushMessageLinkAction extends IPushMessageBaseAction {
  link?: string;
}

export interface IPushMessageCustomAction extends IPushMessageBaseAction {
  name?: string;
  data?: Record<string, any>;
}

export type IPushMessageAction = IPushMessageLinkAction & IPushMessageCustomAction;

export type PushMessageType = "success" | "info" | "warning" | "error" | "silent" | "call" | "action";

export class PushMessage<T extends Record<string, any> = Record<string, any>> {
  @Expose()
  public id: string;

  @Expose()
  public title = "";

  @Expose()
  public message: string | { key: string; params?: Record<string, any> };

  @Expose()
  public date: number;

  @Expose()
  public duration = 5000;

  @Expose()
  public type: PushMessageType = "info";

  @Expose()
  public sound = false;

  @Expose()
  public actions: IPushMessageAction[] = [];

  @Expose()
  public removable = true;

  @Expose()
  public group: string;

  @Expose()
  public alwaysOnTop = false;

  @Expose()
  public textInputValue = "";

  @Expose()
  public data: T = {} as any;

  @Expose()
  public persistent = true;

  @Expose()
  public delay = 0;

  constructor(opts: Partial<PushMessage>) {
    Object.assign(this, opts);
  }
}
