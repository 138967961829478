/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2021
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2021
 */

import { Injectable } from "@angular/core";
import { CallbackInfoResponseDto } from "@meta/api-interfaces";
import { HttpClient } from "@angular/common/http";
import { delay, firstValueFrom } from "rxjs";
import { ApproveModalComponent } from "./approveModal.component";
import { ModalButtonOptions } from "ng-zorro-antd/modal/modal-types";
import { MetaModalService } from "../../../../../../libs/ui/src/lib/services/metaModalService";
import { MetaFormComponent } from "libs/ui/src/lib/base/metaForm/metaForm.component";

@Injectable({
  providedIn: "root",
})
export class ApproveService {
  constructor(
    private readonly http: HttpClient,
    private readonly modalService: MetaModalService,
  ) {}

  public getAction(id: string) {
    return this.http.get<CallbackInfoResponseDto>("/workflow/action/" + id);
  }

  public async showModal(id: string) {
    try {
      const data = await firstValueFrom(this.getAction(id));

      const actions: ModalButtonOptions[] = data.commands.map((c) => {
        return {
          label: c.label,
          type: c.color === "primary" ? "primary" : "default",
          danger: c.color === "danger",
          onClick: async () => {
            try {
              const params = new URLSearchParams({
                command: c.name,
                payload: JSON.stringify({ comment: modalRef.componentInstance.comment }),
              });
              const url = `/workflow/callback/${encodeURIComponent(id)}?${params.toString()}`;
              await firstValueFrom(this.http.get(url).pipe(delay(750)));
            } catch (e) {
              return false;
            }
            modalRef.close(true);
            return true;
          },
        };
      });

      if (data.itemId) {
        actions.push({
          type: "dashed",
          label: "Vorschau",
          onClick: () => {
            this.showItem(data);
          },
        });
      }

      const modalRef = this.modalService.create({
        nzContent: ApproveModalComponent,
        nzComponentParams: {
          body: data.body,
          subject: data.subject,
          actions,
        },
        nzWidth: 650,
      });
      await firstValueFrom(modalRef.afterClose);
    } catch (e) {
      this.modalService.error({
        nzTitle: "Freigabe nicht gefunden.",
        nzContent: "Die ausgewählte Freigabe wurde nicht gefunden.",
      });
    }
  }

  public showItem(data: CallbackInfoResponseDto) {
    this.modalService.create({
      nzContent: MetaFormComponent,
      nzMaskClosable: false,
      nzWidth: "100vw",
      nzFooter: null,
      nzClassName: `ma-subform ma-subform-max`,
      nzWrapClassName: "ma-subform-wrapper",
      nzBodyStyle: {
        height: "calc(100vh - 120px)",
        position: "relative",
        display: "flex",
        "flex-direction": "column",
      },
      nzClosable: true,
      nzCloseOnNavigation: true,
      nzComponentParams: {
        maFormId: data.formId,
        maItemId: data.itemId,
        maHideWorkflows: true,
        maCloseModalAfterSave: true,
        maCloseModalAfterCancel: true,
        maToolbarTarget: data.formId,
      },
    });
  }
}
