/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2021
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2021
 */

import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { MetaIconStyle } from "@meta/enums";
import { FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import { NzCardModule } from "ng-zorro-antd/card";
import { takeUntil } from "rxjs";
import { filter } from "rxjs/operators";
import { IMetaWrapperBase, MetaWrapperBase } from "../../base/metaWrapperBase/metaWrapperBase.component";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaInfoboxModule } from "../metaInfobox/metaInfobox.component";

export class MetaGroup extends IMetaWrapperBase {
  icon?: string;
  iconStyle?: MetaIconStyle = MetaIconStyle.Light;
  hide?: boolean;
  description?: string;
  actionTemplate?: TemplateRef<any>;
  fullHeight?: boolean;
  verticalAlign?: "center" | "default" = "default";
  actions?: Array<TemplateRef<void>>;
  groupStyle?: "default" | "transparent" | "borderless" = "default";
}

@MetaUnsubscribe()
@Component({
  selector: "meta-group",
  template: `
    <ng-container *ngIf="ma as ma">
      <nz-card
        [style.flex]="ma.flex"
        [hidden]="ma.hide"
        [nzBordered]="false"
        [nzExtra]="ma.actionTemplate ? ma.actionTemplate : null"
        [ngClass]="{
          'full-height': ma.fullHeight,
          'vertical-center': ma.verticalAlign === 'center',
          transparent: ma.groupStyle === 'transparent',
          borderless: ma.groupStyle === 'borderless',
          'has-actions': ma.actions?.length > 0,
          'has-hidden-actions': ma.actions
        }"
        class="meta-group"
        [nzTitle]="displayData.label ? labelTemplate : ma.label ? labelTemplate : null"
        [nzActions]="ma.actions?.length > 0 ? ma.actions : []"
      >
        <meta-infobox *ngIf="ma.description" [maParams]="{ label: ma.description }"></meta-infobox>
        <ng-container #fieldComponent></ng-container>
        <ng-content></ng-content>
      </nz-card>

      <ng-template #labelTemplate>
        @if (ma.icon) {
          <i class="{{ ma.iconStyle }} fa-{{ ma.icon }}"></i>
        }

        @if (displayData.label || ma.label) {
          <span>
            {{ displayData.label || ma.label }}
          </span>
        }
      </ng-template>
    </ng-container>
  `,
  styleUrls: ["./metaGroup.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaGroupComponent extends MetaWrapperBase implements OnInit, OnChanges {
  constructor(private _changeDetectorRef: ChangeDetectorRef) {
    super();
    this.maParams = new MetaGroup();
  }

  ngOnInit() {
    if (this.field) {
      this.formState.fieldFilter$
        .pipe(
          takeUntil((this as any).destroyed$),
          filter((x) => x !== undefined),
        )
        .subscribe({
          next: (fieldFilter: string) => {
            if (fieldFilter !== undefined && fieldFilter !== null && this.field.fieldGroup?.length > 0) {
              this.field.hide = false;
              (<any>this.options).detectChanges(this.field);
              this.field.hide = this._checkForVisibleFields(this.field.fieldGroup);
              this._changeDetectorRef.markForCheck();
            }
          },
        });
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.maParams.currentValue?.actions) {
      this.ma.actions = changes.maParams.currentValue?.actions;
    }
  }

  private _checkForVisibleFields(fields: FormlyFieldConfig[]): boolean {
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (field.key?.toString().length > 0 && field.hide === false) {
        return false;
      } else if (field.fieldGroup?.length > 0) {
        const result = this._checkForVisibleFields(field.fieldGroup);
        if (result === false) {
          return false;
        }
      }
    }
    return true;
  }
}

@NgModule({
  declarations: [MetaGroupComponent],
  imports: [CommonModule, FormlyModule, NzCardModule, MetaInfoboxModule],
  exports: [MetaGroupComponent],
})
export class MetaGroupModule {}
