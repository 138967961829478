/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { DestroyRef, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MetaMessage, MetaNotification } from "@meta/api-interfaces";
import { ServerTemplateResponse } from "@meta/forms";
import * as Sentry from "@sentry/angular-ivy";
import { plainToInstance } from "class-transformer";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Socket } from "ngx-socket-io";
import { MetaModalService } from "../../services/metaModalService";
import { GlobalActionHandlerFactory } from "./actions/actionHandler.factory.global";
import { LocalActionHandlerFactory } from "./actions/actionHandler.factory.local";

@Injectable({
  providedIn: "root",
})
export class FormSupervisorService {
  private formServices = new Map<string, LocalActionHandlerFactory>();
  private eventHandlers = new Map<string, CallableFunction>();

  constructor(
    private _socketService: Socket,
    private readonly _nzMessageService: NzMessageService,
    private readonly _notificationService: NzNotificationService,
    private readonly _globalActionHandlerFactory: GlobalActionHandlerFactory,
    private readonly _router: Router,
    private readonly _modal: MetaModalService,
  ) {
    this._socketService.on("formClientAction", (res: any, ack: CallableFunction) => {
      const globalHandler = this._globalActionHandlerFactory.getHandler(
        plainToInstance(ServerTemplateResponse, JSON.parse(JSON.stringify(res))).actions[0].constructor,
      );
      if (globalHandler) {
        this._globalActionHandlerFactory.handleSocketResponse(res, ack).catch((e) => {
          console.log(e);
          Sentry.captureException(e, {
            extra: {
              ...res,
            },
          });
          ack(false);
        });
      } else {
        const formService = this.formServices.get(res.passthroughData?.formId || res.formId);
        if (formService) {
          formService.handleSocketResponse(res, ack).catch((e) => {
            console.log(e);
            Sentry.captureException(e, {
              extra: {
                ...res,
              },
            });
            ack(false);
          });
        }
        this.eventHandlers.get(res.formId)?.(res, ack);
      }
    });

    this._socketService.on("alert", (msg) => {
      this._modal.confirm({
        nzTitle: "Benachrichtigung",
        nzContent: msg,
        nzCancelText: null,
        nzClosable: true,
      });
    });
    this._socketService.on("showMessage", (data: MetaMessage) => {
      this._nzMessageService.create(data.type, data.content, {
        nzDuration: data.duration || 15_000,
      });
    });
    this._socketService.on("notification", (data: MetaNotification) => {
      this._notificationService.create(data.type, data.title, data.content, {
        nzDuration: data.duration || 15_000,
        nzKey: data.id,
      });
    });

    this._socketService.on("navigate", (url) => {
      this._router.navigateByUrl(url);
    });
    this._socketService.on("getFormData", async (data, akg: CallableFunction) => {
      const form = this.getServiceById(data.formId);
      if (!form) {
        akg({
          error: "Form not found",
        });
      } else {
        akg({
          state: {
            id: data.formId,
            result: this.getServiceById(data.formId).metaFormService.formState.data(),
          },
        });
      }
    });

    this._socketService.on("bulk-update-progress", async (data) => {
      if (data) {
        // TODO
        /*this.changeManagementProgress$.next({
          ...data,
          estFormated: moment.duration(data.est, "seconds").humanize(true),
        });*/
      } else {
        /*this.changeManagementProgress$.next({
          bulkId: null,
          est: 0,
          percent: 0,
        });*/
      }
    });
    this.initHMR();
  }

  private initHMR() {
    if (sessionStorage.getItem("IS_HMR_RELOAD")) {
      this._notificationService.info("HMR | Änderungen Neu geladen", sessionStorage.getItem("IS_HMR_RELOAD"), {
        nzDuration: 3_000,
      });
      sessionStorage.removeItem("IS_HMR_RELOAD");
    }
    this._socketService.on("HMR.RELOAD", (e: any) => {
      sessionStorage.setItem("IS_HMR_RELOAD", String(e));
      window.location.reload();
    });
    this._socketService.on("HMR.COMPILING", (e: any) => {
      this._notificationService.info("HMR | Kompilierung…", String(e), {
        nzDuration: 0,
      });
    });
  }

  registerFormService(formId: string, service: LocalActionHandlerFactory, destroyRef: DestroyRef) {
    this.formServices.set(formId, service);
    destroyRef.onDestroy(() => this.formServices.delete(formId));
  }

  registerSocketEventHandler(
    formId: string,
    handler: (res: any, ack: CallableFunction) => any,
    destroyRef: DestroyRef,
  ) {
    this.eventHandlers.set(formId, handler);
    destroyRef.onDestroy(() => this.eventHandlers.delete(formId));
  }

  unregisterFormService(formId: string) {
    this.formServices.delete(formId);
  }

  getServiceById(formId: string) {
    return this.formServices.get(formId);
  }
}
