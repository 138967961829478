/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { Injectable } from "@angular/core";
import { MetaModalService } from "../../../services/metaModalService";
import { MetaFormComponent } from "../metaForm.component";

@Injectable({
  providedIn: "root",
})
export class CloseFormActionHandler {
  constructor(private _modal: MetaModalService) {}
  async handle(action: any) {
    console.log(this._modal.openModalsProxys, this._modal);
    this._modal.openModalsProxys.forEach((m) => {
      if (
        m.componentInstance instanceof MetaFormComponent &&
        m.componentInstance.metaFormService.formState.formId === action.formId
      ) {
        m.close(action.result);
      }
    });
    return true;
  }
}
