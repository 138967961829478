/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponent, MetaFormComponentInfo } from "./baseComponent";
import type { FormEventActionHandler } from "../interfaces";
import { Exclude } from "class-transformer";

@MetaFormComponentInfo({
  selector: "ma-drop-area",
  type: "drop-area",
  name: "DropArea",
})
export class MetaDropAreaComponent extends MetaFormComponent {
  @Exclude()
  public onDrop?: FormEventActionHandler;
  public label?: string;
  public icon?: string;
  public dropAreaValue?: number;
  public dropAreaType?: "default" | "primary" | "danger" | "success" | "warning" | "link" = "default";
  public filterLabel?: string;

  constructor() {
    super();
  }
}
