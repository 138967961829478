/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaBaseValidator } from "./baseValidator";
import * as validator from "class-validator";
import { MetaFormComponentInfo } from "../components";

@MetaFormComponentInfo({
  selector: "ma-is-phone-validator",
  type: "is-phone-validator",
})
export class IsPhoneNumber extends MetaBaseValidator {
  public region: string;

  async validate(value): Promise<boolean> {
    return validator.isPhoneNumber(value, this.options.region as any);
  }

  toFormly(formlyFieldConfig: Record<string, any>) {
    return super.toFormly(formlyFieldConfig, true);
  }

  public static createElement(attributes: Partial<IsPhoneNumber>) {
    const val = new IsPhoneNumber();
    Object.assign(val, attributes);
    return val;
  }
}
