/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { Injectable, signal } from "@angular/core";
import { firstValueFrom, last, Observable, ReplaySubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Socket } from "ngx-socket-io";
import { takeUntil } from "rxjs/operators";
import { NzPopoverDirective } from "ng-zorro-antd/popover";
export interface IChatMessage {
  user: string;
  content: string;
  type: "user" | "ai";
}

@Injectable()
export class ChatService {
  public chatPopover: NzPopoverDirective;
  public readonly toolRunning = signal(false);
  constructor(
    private readonly http: HttpClient,
    private readonly socketService: Socket,
  ) {}
  public chat(
    message: string,
    conversation: string,
    signal: AbortSignal,
    additionalContext?: string,
  ): Observable<IChatMessage> {
    const resMessage: IChatMessage = { user: "Argon Assistent", content: "", type: "ai" };
    const sub = new ReplaySubject<IChatMessage>(1);
    const abortHandler = () => {
      this.socketService.emit("chatbot.abort", {
        conversation,
      });
      this.toolRunning.set(false);
      if (!sub.closed) {
        sub.complete();
      }
    };
    sub.next(resMessage);
    this.socketService
      .fromEvent(`chatbot.answer`)
      .pipe(takeUntil(sub.pipe(last())))
      .subscribe(({ content, final }) => {
        //if (signal.aborted) return;
        resMessage.content += content;
        sub.next(resMessage);
        if (final) {
          signal.removeEventListener("abort", abortHandler);
          sub.complete();
          this.toolRunning.set(false);
        }
      });

    this.socketService
      .fromEvent(`chatbot.tool_start`)
      .pipe(takeUntil(sub.pipe(last())))
      .subscribe(() => this.toolRunning.set(true));
    this.socketService
      .fromEvent(`chatbot.tool_end`)
      .pipe(takeUntil(sub.pipe(last())))
      .subscribe(() => this.toolRunning.set(false));
    this.socketService.emit("chatbot.ask", {
      message,
      conversation,
      additionalContext,
    });
    signal.addEventListener("abort", abortHandler, { once: true });
    return sub;
  }

  public getSuggestions(id: string) {
    return this.http.get<any>(`chatbot/${id}/suggestions`);
  }

  public getChat(id: string) {
    return firstValueFrom(this.http.get<IChatMessage[]>(`chatbot/${id}`));
  }
}
