/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ValidatableComponent } from "./validatableComponent";
import { Expose } from "class-transformer";
// import { MetaEditorToolbarType } from '@meta/api-interfaces';
import { ExposeProperty, MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-editor",
  type: "editor",
  name: "Editor",
  icon: "text",
  palletGroup: "input",
})
export class EditorComponent<PrimaryDataSource> extends ValidatableComponent<PrimaryDataSource> {
  @ExposeProperty() public toolbar: "full" | "none" = "none";

  public static create<T>(dataSource: T, opts: Partial<EditorComponent<T>>) {
    const input = new EditorComponent<T>();
    input.setOptions(opts as any);
    return input;
  }

  @MetaFormComponentProperty({
    name: "variablesSchema",
    type: "json",
    tab: "hooks",
  })
  public variablesSchema?: any;

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        editorType: "html",
        placeholder: this.placeholder,
        defaultValue: this.defaultValue,
        toolbar: this.toolbar,
        onChange: !!this.onChange,
        isTemplateEditor: false,
        schema: this.variablesSchema || null,
      },
    });
  }
}
