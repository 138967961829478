/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaBaseValidator } from "./baseValidator";
import * as validator from "class-validator";
import { MetaFormComponentInfo } from "../components";

export class LengthValidatorOptions {
  min: number;
  max?: number;
}

/**
 * @Deprecated will be removed
 */
@MetaFormComponentInfo({
  selector: "ma-length-validator",
  type: "length-validator",
  name: "Length Validator",
})
export class Length extends MetaBaseValidator {
  public min: number;
  public max: number;

  constructor(public options: LengthValidatorOptions) {
    super();
    if (options) {
      this.min = options.min;
      this.max = options.max;
    }
  }

  public static createElement(attributes: LengthValidatorOptions) {
    return new this(attributes);
  }

  async validate(value): Promise<boolean> {
    return validator.length(value, this.options.min, this.options.max);
  }
}
