/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, Input, OnInit } from "@angular/core";
import { MetaHelperService } from "../../../services/metaHelper.service";
import { IFeedResultItem } from "@meta/api-interfaces";

export class MetaTimelineTemplateFormApproved {
  data: IFeedResultItem<{ nodeName: string; nodeId: string; workflowId: string; description: string }>;
}

@Component({
  selector: "meta-timeline-template-form-approved",
  template: `
    <meta-translate [key]="'timeline.form-approved'">
      <ng-template meta-translate-child key="name">
        {{ item.data.entity.name }}
      </ng-template>

      <ng-template meta-translate-child key="link">
        <a [href]="item.data.entity.link">
          <meta-tag [maParams]="{ label: tplLabel, color: color, colorText: colorText, maxWidth: 250 }"></meta-tag>
        </a>
      </ng-template>
      <ng-template meta-translate-child key="node">
        <meta-tag
          *ngIf="item.data.data?.nodeName"
          [maParams]="{ label: item.data.data.nodeName, color: nodeColor, colorText: colorText, maxWidth: 400 }"
        ></meta-tag>
      </ng-template>
      <ng-template meta-translate-child key="operation">
        <span *ngIf="type === 'approved'" class="log-added "><i class="fas fa-check"></i> Freigegeben</span>
        <span *ngIf="type === 'denied'" class="log-danger "><i class="fas fa-times"></i> Abgelehnt</span>
        <span *ngIf="type === 'timeout'" class="log-danger "><i class="fas fa-clock"></i> Zeitüberschreitung</span>
      </ng-template>
    </meta-translate>
    <ng-template #tplLabel
      ><i class="fas fa-{{ item.data.entity.icon }}"></i>{{ item.data.entity.displayId || "" }}
      {{ item.data.entity.title }}
    </ng-template>

    <ng-container *ngIf="item.data.data.description">
      <h3>Kommentar</h3>
      <comment>{{ item.data.data.description }}</comment>
    </ng-container>
  `,
})
export class MetaTimelineTemplateFormApprovedComponent implements OnInit {
  @Input() public item: MetaTimelineTemplateFormApproved;
  @Input() public type: "approved" | "denied" | "timeout";

  public color: string;
  public nodeColor: string;
  public colorText: string;

  constructor(public metaHelperService: MetaHelperService) {}

  ngOnInit() {
    this.color = this.metaHelperService.generateColor(this.item.data.entity.type);
    this.nodeColor = this.metaHelperService.generateColor(this.item.data.data.nodeId);
    this.colorText = getComputedStyle(document.documentElement).getPropertyValue("--component-background");
  }
}
