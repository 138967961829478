/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { DataSourceParameters, ExposeProperty, MetaFormComponentInfo, MetaFormComponentProperty } from "../components";
import { PipelineType } from "@meta/api-interfaces";
import { Table } from "./table";
import { DataSourceType } from "@meta/database/lib/types";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { FormEventActionHandler } from "../interfaces";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-pipeline",
  type: "pipeline",
  name: "Pipeline",
  hideInPallet: true,
})
export class Pipeline extends Table {
  static selector = "ma-pipeline";
  @ExposeProperty()
  pipelineType: PipelineType;
  @ExposeProperty()
  layoutTemplates: string[] = [];
  @ExposeProperty()
  cardBackgroundColor: string;
  @ExposeProperty()
  ownerField = "AnwenderAnlage";
  @ExposeProperty()
  maShowObjectDetails: boolean;
  @ExposeProperty()
  pipelineFields: string[] = [];
  @ExposeProperty()
  pipelineObjectIdField = "id";
  @ExposeProperty()
  showReasonForDelete: boolean;

  @MetaFormComponentProperty({
    name: "On State Change",
    type: "action",
    tab: "hooks",
    description: `Will be executed when a card changes its state`,
  })
  public onPipelineStateChange?: FormEventActionHandler;

  @MetaFormComponentProperty({
    name: "Datenquelle Log",
    type: "dataSource",
    tab: "data",
  })
  public logDataSource?: DataSourceType = null;

  constructor(props: Pipeline) {
    super(props);
    Object.assign(this, props);
  }

  public static createElement(attributes, children: any[]): any {
    return new Pipeline({
      ...attributes,
      children,
    });
  }

  public toFormly(config: IFormUserConfiguration): any {
    return {
      id: this.id,
      children: [this._formly(config)],
      type: "page",
      label: this.label,
      icon: "",
      filters: [],
      calculateFillingLevel: false,
      actions: [],
      editable: false,
      creatable: false,
      deletable: false,
      onStateChangeAction: !!this.onPipelineStateChange,
    };
  }

  private _formly(config: IFormUserConfiguration) {
    return deepMerge(super.toFormly(config), {
      type: "meta-table",
    });
  }
}
