/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponentInfo } from "../components";
import { MetaBaseValidator } from "./baseValidator";

@MetaFormComponentInfo({
  selector: "ma-optional-validator",
  type: "optional-validator",
})
export class IsOptionalValidator extends MetaBaseValidator {
  constructor() {
    super();
  }

  async validate(value): Promise<boolean> {
    return true;
  }
}
