/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaBaseValidator } from "./baseValidator";
import * as validator from "class-validator";
import { MetaFormComponentInfo } from "../components";

export class MaxValidatorOptions {
  max: number;
}

@MetaFormComponentInfo({
  selector: "ma-max-validator",
  type: "max-validator",
  name: "Max Validator",
})
export class MaxValidator extends MetaBaseValidator {
  public max: number;

  constructor(public options: MaxValidatorOptions) {
    super();
    this.max = options?.max;
  }

  public static createElement(attributes: MaxValidatorOptions) {
    return new MaxValidator(attributes);
  }

  async validate(value): Promise<boolean> {
    return validator.max(Number(value), this.options.max);
  }

  toFormly(formlyFieldConfig: Record<string, any>) {
    formlyFieldConfig.props["max"] = this.options.max;
    return super.toFormly(formlyFieldConfig, true);
  }
}
