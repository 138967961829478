/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Allow, IsOptional, IsString } from "class-validator";
import { EntityType } from "@meta/enums";
import type { JsonSchema } from "json-schema-library";

export interface IWorkFlowNodeStatus {
  /**
   * Node Id
   */
  id: string;
  /**
   * Node Name
   */
  name: string;
  done: boolean;
  active: boolean;
  error: boolean;
  log: string[];
  text: string;
  outputs: string[];
}

export interface IWorkFlowStatus {
  nodes: IWorkFlowNodeStatus[];
  done: boolean;
  canceled: boolean;
  startDate: Date;
  endDate: Date;
  log: IWorkflowExecutorLogEntry[];
  hasError: boolean;
  user: { name: string; id: string };
  info: string;
}

export interface IWorkflowExecutorLogEntry {
  date: number;
  message: string;
  type: string;
}

export interface IWorkflowEditorSettings {
  autoLayout?: "default" | "auto";
  direction?: "horizontal" | "vertical";
}

export interface IWorkflow {
  /**
   * Id of the workflow
   */
  id: string;
  /**
   * Numeric field used to identify the version of the schema. Use incrementing numbers
   */
  version: number;
  /**
   * Name of the workflow
   */
  name: string;
  /**
   * Description of the workflow
   *
   */

  description: string;
  /**
   * Sets if the Workflow can be used
   */
  published: boolean;

  /**
   * Sets if the Workflow owner
   */
  owner: string;

  userGroups: number[];

  /**
   * Sets the layout for the Workflow-Designer
   * Has no relevance for the workflow-engine
   */
  layout: Record<string, any>;

  icon: string;

  nodes: IWorkflowNode[];

  nodeLayouts?: IWorkflowNodeLayout[];

  timeout: number;

  silent: boolean;

  connections: { from: string; to: string; fromPort: string; toPort: string }[];

  editorSettings?: IWorkflowEditorSettings;
}

export interface MetaWorkflowStyle {
  color?: string;
}

export const WorkflowIcons = {
  house: {
    shape:
      "M570.69 236.28L512 184.45V48a16 16 0 0 0-16-16h-64a16 16 0 0 0-16 16v51.69L314.75 10.31a39.85 39.85 0 0 0-53.45 0l-256 226a16 16 0 0 0-1.21 22.6L25.5 282.7a16 16 0 0 0 22.6 1.21L277.42 81.63a16 16 0 0 1 21.17 0L527.91 283.9a16 16 0 0 0 22.6-1.21l21.4-23.82a16 16 0 0 0-1.22-22.59zM288 115L69.47 307.71c-1.62 1.46-3.69 2.14-5.47 3.35V496a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V368a16 16 0 0 1 16-16h96a16 16 0 0 1 16 16v128a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V311.1c-1.7-1.16-3.72-1.82-5.26-3.2z",
    width: 20,
    height: 20,
  },
  flag: {
    shape:
      "M448 327.4V258c-15.5 9.4-44 19-72 22.9v70.4c28.7-2.8 54.8-13.5 72-23.9zm0-207.3c-21.2 8.1-46.7 15.8-72 20.2v70.6c25-4 48.6-12.5 72-21.4zM88 336.8c21.7-7 47.2-11.9 72-14.5v-70.1c-24.3 2.4-48 7.6-72 15.3zm357.1-285C409.2 67.3 371.6 80 336.2 80c-49.1 0-93.3-32-161.9-32-31.3 0-58.3 6.5-80.8 15.2 2.2-6.7 3-13.7 2.1-20.7C93.1 19.6 74.2 1.6 51.2.1 23.2-1.7 0 20.4 0 48c0 17.8 9.7 33.3 24 41.6V496c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-83.4c37.9-17.3 71.3-28.6 127.8-28.6 49.1 0 93.3 32 161.9 32 58.5 0 102-22.6 128.5-40 13.6-8.9 21.7-24 21.7-40.2V95.9c.1-34.4-35.2-57.7-66.8-44.1zM464 336c-21.8 15.4-60.8 32-102.3 32-59.9 0-102-32-161.9-32-43.4 0-96.4 9.4-127.8 24V128c21.8-15.4 60.8-32 102.3-32 59.9 0 102 32 161.9 32 43.3 0 96.3-17.4 127.8-32zM88 136.6V206c15.5-9.4 44-19 72-22.9v-70.4c-28.7 2.8-54.8 13.4-72 23.9zm72 46.5v69.1c30.5-3 51.4-1.3 72 3.1v-67c-28.5-7.6-48.7-8.4-72-5.2zm144 92.7c-23.7-6.2-46.5-15.2-72-20.5v67.5c25.9 4.3 48.9 12.9 72 19.6v-66.6c28.5 7.5 48.7 8.3 72 5.2v-70c-23.8 3.8-46.5 3.3-72-2.1zm0-134.5c-25.9-4.3-48.8-12.9-72-19.7v66.6c23.8 6.3 46.5 15.2 72 20.5z",
    width: 20,
    height: 20,
  },
  check: {
    shape:
      "M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z",
    width: 17,
    height: 14,
  },
  times: {
    shape:
      "M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z",
    width: 14,
    height: 14,
  },
  clock: {
    shape:
      "M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z",
    width: 14,
    height: 14,
  },
};

export interface IWorkflowTaskConnectionPoint<> {
  name: string;
  displayName?: string;
  description?: string;
  icon?: any;
  color?: string;
  hidden?: boolean;
}

export interface IInputVar {
  name: string;
  type: "string" | "number" | "boolean" | "date";
  required: boolean;
}

export interface IWorkflowNodeLayout {
  id: string;
  x: number;
  y: number;
}

export interface IWorkflowNode<Options = Record<string, any>> {
  options: Options;
  waitForAllPreviousNodes?: boolean;
  id: string;
  type?: string;
  name?: string;
  hookUser?: string;
  displayData?: Record<string, any>;
  dynamicOutputs?: { id: string; name: string }[];
  state?: any;
  input?: any;
}

export interface IWorkflowNodeResultDescriptionField {
  name: string;
  path?: string;
  description: string;
  type: string;
  sqlType?: string;
  label?: string;
  isArray?: boolean;
}

export interface IWorkflowNodeDescription {
  id: string;
  name?: string;
  icon?: string;
  color?: string;
  info?: string;
  output: Record<string, IWorkflowNodeResultDescription>;
  error?: string;
  errors?: string[];
  warnings?: string[];
}
export type IWorkflowNodeDescribeResult = Omit<IWorkflowNodeDescription, "id">;

export interface IWorkflowDescription {
  nodes: Record<string, IWorkflowNodeDescription>;
  variables?: IWorkflowNodeResultDescriptionField[];
  globalSchema: any;
}

export type WorkflowJsonSchema = Record<string, any>;

export interface IWorkflowNodeDescription2 {
  input: boolean;
  outputSchemas: WorkflowJsonSchema[];
  warnings: string[];
  errors: string[];
}

export interface IWorkflowDescription2 {
  nodes: Record<string, IWorkflowNodeDescription2>;
  globalSchema: WorkflowJsonSchema;
}

export const DatabaseOperations = {
  backup: "Backup",
} as const;

export interface IWorkflowDatabaseOperationOptions {
  filename: string;
  backupPath?: string;
  compress?: boolean;
}

export interface IWorkflowNodeResultDescription {
  multiple: boolean;
  name: string;
  displayName?: string;
  icon?: string;
  schema?: JsonSchema;
  color?: string;
}

export type WorkflowNodeGroup = "common" | "developer" | string;

export interface IWorkflowNodeMetadata {
  /**
   * Name of the Task that resonates with it's function.
   */
  name: string;
  /**
   * Description of the task
   */
  description?: string;
  allowMultipleExecutions?: boolean;
  id: string;
  groups?: WorkflowNodeGroup[];
  /**
   * Array of keys of task's expected input. Used for documenting task's input
   */
  inputs?: IWorkflowTaskConnectionPoint[];
  /**
   * Array of keys of task's expected output. Used for documenting task's output
   */
  outputs?: IWorkflowTaskConnectionPoint[];
  hideInPallet?: boolean;
  styling?: MetaWorkflowStyle;
  icon?: string;
  iconFontFamily?: string;
  defaultOptions?: Record<string, any>;
  dynamicOutputs?: boolean;
  /**
   * Simple nodes can't wait for events
   */
  simple?: boolean;
}

export class ExecuteFormWorkflowResponseDto {
  executionId: string;

  workflowId: string;
}

export class CallbackInfoResponseCommandDto {
  label: string;

  name: string;

  url: string;

  color?: string;
}

export class CallbackInfoResponseDto {
  commands: CallbackInfoResponseCommandDto[];

  callbackId: string;

  subject: string;

  body: string;

  attachments: any[];

  formId?: string;

  itemId?: string;

  itemEntityTypeName?: string;
}

export class CallbackResponseDto {
  successful: boolean;

  message: string;
}

export class IWorkflowUserActionDto {
  id: string;

  title: string;

  description: string;

  date: Date;

  callbackId: string;

  timeout: number;
}

export class ExecuteFormWorkflowRequestDto {
  @IsString()
  formId: string;

  @IsString()
  itemId: string;

  @IsString()
  @IsOptional()
  workflowId?: string;

  @IsString()
  @IsOptional()
  nodeId?: string;

  @Allow()
  @IsOptional()
  payload?: Record<string, any>;
}

export interface ISendNotificationNodeOptions {
  to: string[];
  cc: string[];
  bcc: string[];
  body: string;
  subject: string;
  attachments: string[];
  priority: string;
  from: string;
  useHtmlEditor: boolean;
  locale?: string;
}

export interface IWaitNodeOptions {
  delay: number;
  delayUnit: string;
}

export interface ISqlNodeOptions {
  query: string;
  fullResultSet: boolean;
  ignoreSqlAnalyzeError: boolean;
}

export interface IForEacNodeOptions {
  path: string;
}

export interface ISqlProcOptions {
  procedure: string;
  inputs: Record<string, any>;
  fixed?: boolean;
}

export type StartNodeInputType = "form" | "data" | "procedure" | "trigger" | "cron";

export interface IStartNodeCronOptions {
  cron: string;
  cronAdvanced: boolean;
}

export interface ICronStartNodeOptions {
  cron: string;
  advanced: boolean;
}

export interface IFsStartNodeOptions {
  glob: string;
  engineId: string;
}
export interface IStartNodeOptions {
  linkedForm?: string;
  data?: string;
  inputFormId?: string;
  inputVariables?: IInputVar[];
  inputProcedure?: string;
  inputType: StartNodeInputType;
  allowHookExecution?: boolean;
  entityType?: EntityType | string;
  hookUser?: string;
  triggerName?: string;
  cronOptions?: IStartNodeCronOptions;
}

export interface IScriptNodeOptions {
  code: string;
}

export interface IPdfNodeOptions {
  formId: string;
  ids: Record<string, any>;
  includeAttachments: boolean;
  watermark?: string;
  locale?: string;
  filename?: string;
}

export interface ISubWorkflowOptions {
  workflow: string;
  waitForSubWorkflow: boolean;

  inputVariables: Record<string, string>;
}

export interface IApproveNodeOptions {
  // users?: string[];
  group?: string;
  mail?: string[];
  body?: string;
  description?: string;
  subject?: string;
  allowConfirmFromMail?: boolean;
  timeout?: number;
  timeoutUnit?: string;
  attachments: string[];
  formId?: string;
  groups?: string[];
  sendNotifications?: boolean;
  allowExternalCallback?: boolean;

  approveButtonText: string;
  denyButtonText: string;
  deniedMessage: string;
  approvedMessage: string;
}
