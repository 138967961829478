/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { MetaErrorHandlerService } from "../../services/metaErrorHandler.service";

@Injectable({
  providedIn: "root",
})
export class MetaDiagramService {
  constructor(
    private _http: HttpClient,
    private _metaErrorHandlerService: MetaErrorHandlerService,
    private _nzMessageService: NzMessageService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/ban-types
  public async getData(formId: string, itemId: string, params?: any): Promise<Object> {
    return await this._http
      .post(`chart/${formId}/${itemId}`, {
        params: params,
      })
      .toPromise();
  }
}
