/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { NgModule } from "@angular/core";
import { SincePipe } from "../../../../../../libs/ui/src/lib/pipes/since.pipe";
import { NotificationComponent } from "./notification.component";
import { CommonModule } from "@angular/common";
import { MetaButtonModule, MetaCollapseModule } from "@meta/ui";
import { ApproveModule } from "../approve/approve.module";
import { NotificationItemComponent } from "./notification-item.component";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { MetaTaskListModule } from "../../../../../../libs/ui/src/lib/components/metaTask/metaTask.list.component";
import { MetaTaskFormModule } from "../../../../../../libs/ui/src/lib/components/metaTask/metaTask.form.component";
import { NgxTolgeeModule } from "@tolgee/ngx";

@NgModule({
  providers: [],
  declarations: [NotificationComponent, NotificationItemComponent],
  exports: [NotificationComponent, NotificationItemComponent],
  imports: [
    CommonModule,
    MetaButtonModule,
    ApproveModule,
    NzTabsModule,
    MetaCollapseModule,
    MetaTaskFormModule,
    MetaTaskListModule,
    NgxTolgeeModule,
    SincePipe,
  ],
})
export class NotificationModule {}
