/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Exclude, Expose } from "class-transformer";
import { ExposeProperty, MetaFormComponentInfo, MetaFormComponentProperty } from "../components";
import { Allow, IsBoolean, IsString } from "class-validator";
import { EditableForm } from "./editableForm";
import { IFormUserConfiguration } from "../formUserConfiguration";
import * as _ from "lodash";
import { deepMerge } from "../deepMerge";
import { FormEventActionHandler } from "../interfaces";

// TODO: refactor
@MetaFormComponentInfo({
  selector: "ma-wizard",
  type: "wizard",
  icon: "magic",
  name: "Wizard",
})
export class WizardComponent extends EditableForm {
  static selector = "ma-wizard";
  @ExposeProperty() @IsString() title: string;
  @ExposeProperty() @IsBoolean() hideSteps?: boolean;

  procedure: any;

  constructor(props: WizardComponent) {
    super(props);
  }

  public static createElement(attributes, children: any[]): any {
    const page = new WizardComponent({
      ...attributes,
      children,
    });
    if (!page.label) {
      page.label = "Wizard";
    }
    return page;
  }

  @MetaFormComponentProperty({
    name: "Beim nächstem Schritt.",
    type: "js",
    tab: "hooks",
  })
  public onNextStep?: FormEventActionHandler;

  @MetaFormComponentProperty({
    name: "Beim vorherigen Schritt.",
    type: "js",
    tab: "hooks",
  })
  public onPreviousStep?: FormEventActionHandler;

  @MetaFormComponentProperty({
    name: "Label Next",
    type: "text",
    tab: "general",
    formly: true,
  })
  public labelNext?: string;

  @MetaFormComponentProperty({
    name: "Label Previous",
    type: "text",
    tab: "general",
    formly: true,
  })
  public labelPrevious?: string;

  @MetaFormComponentProperty({
    name: "Label Complete",
    type: "text",
    tab: "general",
    formly: true,
  })
  public labelComplete?: string;

  public toFormly(config: IFormUserConfiguration) {
    return deepMerge(super.toFormly(config), {
      type: "wizard",
      onNextStep: !!this.onNextStep,
      onPreviousStep: !!this.onPreviousStep,
      labelNext: this.labelNext || "Weiter",
      labelPrevious: this.labelPrevious || "Zurück",
      labelComplete: this.labelComplete || "Fertig",
    });
  }
}
