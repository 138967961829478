/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

export interface IMetaMediaLibrarySelectFileOption {
  type?: "image" | "audio" | "video" | "pdf";
  collection?: string;
}

export const retentionPolicyText = {
  0: "Standard",
  1: "Versioniert",
  2: "Langzeit Archivierung",
} as const;

export interface IMetaMediaLibraryFile {
  validFrom: any;
  validTo: any;
  classification: string;
  classificationName: string;
  name: string;
  id: string;
  type: "image" | "document" | "collection";
  mime: string;
  size: number;
  external: boolean;
  owner: string;
  ownerId: string;
  description: string;
  fileDescription: string;
  date: any;
  links: any[];
  retentionPolicy: number;
  tags: { label: string; id: string }[];
}

export interface IMetaMediaLibraryFilePrintOptions {
  local: boolean;
  printer: string;
  copies: number;
}
