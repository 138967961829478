/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponentInfo } from "./baseComponent";
import { ValidatableComponent } from "./validatableComponent";
import { SqlDataSource } from "../sqlDataSource";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-address",
  type: "address",
  name: "Address",
})
export class AddressComponent extends ValidatableComponent {
  public addressType: string[] = ["KUN", "LIE", "INTR"];

  public createable = false;

  public showGoogleResults = true;
  public showMap = false;

  public data?: string | SqlDataSource;

  constructor() {
    super();
  }

  public static createElement(attributes: Partial<AddressComponent>) {
    const component = new AddressComponent();
    Object.assign(component, attributes);
    return component;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        addressType: this.addressType,
        showGoogleResults: this.showGoogleResults,
        showMap: this.showMap,
      },
    });
  }
}
