@if (editing || ma.editing) {
  <div class="NgxEditor__Wrapper">
    @if (ma.editorToolbar !== 'none' && ma.editorType === 'html') {
      <ngx-editor-menu [toolbar]="toolbar" [editor]="editor"></ngx-editor-menu>
    }
    <ngx-editor (focusIn)="markAsTouched()" [editor]="editor" [placeholder]="ma.placeholder"></ngx-editor>
    @if (ma.schema || schema || variablesService) {
      <div class="help-overlay">
        <meta-translate [key]="'components.editor.help_overlay'">
          <ng-template meta-translate-child key="key">
            <code>{{</code>
          </ng-template>
        </meta-translate>
      </div>
    }

  </div>
} @else if (ma.editorType === 'html') {
  <div class="output" [innerHTML]="formControl?.value || value || '-' | nzSanitizer: 'html'"></div>
} @else {
  <div class="output" [innerText]="formControl?.value || value || '-'"></div>
}

