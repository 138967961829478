/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Table } from "../pages";
import { ValidatableComponent } from "./validatableComponent";
import { Expose } from "class-transformer";
import {
  DataSourceParameters,
  MetaFormComponent,
  MetaFormComponentInfo,
  MetaFormComponentProperty,
} from "./baseComponent";
import type { DataSourceType } from "@meta/database/lib/types";
import { SqlRelation } from "../sqlDataSource";
import { MetaButtonType } from "@meta/enums";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

export interface ListInputComponentNotification {
  icon: string;
  color: string;
  label?: string;
  items?: ListInputComponentNotification[];
}

@MetaFormComponentInfo({
  selector: "ma-list-input",
  type: "list-input",
  name: "List input",
  icon: "sheep",
})
export class ListInputComponent<PrimaryDataSource = any> extends ValidatableComponent<PrimaryDataSource> {
  @MetaFormComponentProperty({
    name: "sourceId",
    type: "primaryKey",
    required: true,
  })
  public sourceId: string[] = [];

  @MetaFormComponentProperty({
    name: "targetId",
    type: "primaryKey",
    required: true,
  })
  public targetId: string[] = [];

  @MetaFormComponentProperty({
    name: "newPositionLabel",
    type: "text",
  })
  public newPositionLabel = "Eintrag hinzufügen";

  @MetaFormComponentProperty({
    name: "Notification",
    type: "js",
    renderDisplayValue: true,
  })
  public notification: ($: any) => Promise<ListInputComponentNotification> | ListInputComponentNotification;

  @MetaFormComponentProperty({
    name: "newPositionLabelType",
    type: "select",
    values: [
      {
        label: "primary",
        value: "primary",
      },
      {
        label: "default",
        value: "default",
      },
      {
        label: "link",
        value: "link",
      },
    ],
  })
  public newPositionLabelType: MetaButtonType;

  @MetaFormComponentProperty({
    name: "deletePositionLabel",
    type: "text",
  })
  public deletePositionLabel: string;

  @MetaFormComponentProperty({
    name: "showNoDataNotification",
    type: "boolean",
    default: false,
  })
  public showNoDataNotification = false;

  @MetaFormComponentProperty({
    name: "noDataLabel",
    type: "text",
  })
  public noDataLabel: string;

  @MetaFormComponentProperty({
    name: "noDataIcon",
    type: "icon",
    iconSet: "fa",
  })
  public noDataIcon: string;

  @MetaFormComponentProperty({
    name: "showNoDataNotification",
    type: "boolean",
    default: false,
  })
  public hideIfEmpty: boolean;

  @MetaFormComponentProperty({
    name: "listDataSource",
    type: "dataSource",
    required: true,
  })
  public listDataSource?: DataSourceType;

  @MetaFormComponentProperty({
    name: "listDataSourceParameters",
    type: "dataSourceParameters",
    required: false,
  })
  public listDataSourceParameters?: DataSourceParameters;

  // Id fields used to identify the item
  @MetaFormComponentProperty({
    name: "idFields",
    type: "primaryKey",
    required: true,
  })
  public idFields?: string[] = [];

  @MetaFormComponentProperty({
    name: "orderBy",
    type: "text",
    default: null,
  })
  public orderBy?: string = null;

  @MetaFormComponentProperty({
    name: "showDividerPerItem",
    type: "boolean",
    default: false,
  })
  public showDividerPerItem?: boolean;

  @MetaFormComponentProperty({
    name: "addButtonSize",
    type: "select",
    values: [
      {
        label: "large",
        value: "large",
      },
      {
        label: "small",
        value: "small",
      },
      {
        label: "default",
        value: "default",
      },
    ],
    default: "default",
  })
  public addButtonSize: "large" | "small" | "default";

  @MetaFormComponentProperty({
    name: "alternateBetweenBackgrounds",
    type: "boolean",
    default: false,
  })
  public alternateBetweenBackgrounds?: boolean;

  @MetaFormComponentProperty({
    name: "orderByDirection",
    type: "select",
    values: [
      {
        label: "asc",
        value: "asc",
      },
      {
        label: "desc",
        value: "desc",
      },
    ],
    default: "asc",
  })
  public orderByDirection?: "asc" | "desc" = "asc";

  @Expose() public defaults: Record<string, any> = {};

  @MetaFormComponentProperty({
    name: "deletable",
    type: "boolean",
    default: true,
  })
  public deletable?: boolean | "deletable" = true;

  @MetaFormComponentProperty({
    name: "addable",
    type: "boolean",
    default: true,
  })
  public addable? = true;

  @MetaFormComponentProperty({
    name: "hasFieldWrapper",
    type: "boolean",
    default: false,
  })
  public hasFieldWrapper? = false;

  @MetaFormComponentProperty({
    name: "showRowNumber",
    type: "boolean",
    default: false,
  })
  public showRowNumber? = false;

  @MetaFormComponentProperty({
    name: "height",
    type: "number",
    default: null,
    unit: "px",
  })
  public height?: number;

  public onUpdate?: (
    records: Record<string, any>[],
    primaryItem?: Record<string, any>,
  ) => Record<string, any>[] | Promise<Record<string, any>[]>;

  @MetaFormComponentProperty({
    name: "relations",
    type: "relations",
  })
  public relations?: SqlRelation[] = [];

  @MetaFormComponentProperty({
    name: "Children",
    type: "children",
    allowedChildren: "all",
  })
  public children: MetaFormComponent[] = [];

  @MetaFormComponentProperty({
    name: "Header",
    type: "children",
    allowedChildren: "all",
  })
  public header: MetaFormComponent[] = [];

  @MetaFormComponentProperty({
    name: "Footer",
    type: "children",
    allowedChildren: "all",
  })
  public footer: MetaFormComponent[] = [];

  @MetaFormComponentProperty({
    name: "displayValue",
    type: "text",
    isTemplate: true,
  })
  public displayValue = "";

  public getNestedChildren(excludeListInputs = true, excludeTables = false) {
    const res: MetaFormComponent[] = [];

    function getNested(children: MetaFormComponent[]) {
      for (const child of children) {
        if (child instanceof Table && excludeListInputs) {
          continue;
        }

        if (!(child instanceof ListInputComponent) || !excludeListInputs) {
          res.push(child);
          if (Array.isArray(child["children"])) {
            getNested(child["children"]);
          }
        } else {
          getNested(child.header);
          getNested(child.footer);
        }
      }
    }

    getNested(this.children);
    return res;
  }

  public getNestedListInputs() {
    return this.getNestedChildren(false, true).filter((e) => {
      return e instanceof ListInputComponent;
    }) as ListInputComponent[];
  }

  public init(parent: any, setChildIds: (children: any[], prefix: string) => void) {
    super.init(parent, setChildIds);
    setChildIds(this.header, "header-");
    setChildIds(this.footer, "footer-");
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        header: this.header.map((child) => child.toFormly(config)).filter((c) => !!c),
        footer: this.footer.map((child) => child.toFormly(config)).filter((c) => !!c),
        newPositionLabel: this.newPositionLabel,
        deletePositionLabel: this.deletePositionLabel,
        newPositionLabelType: this.newPositionLabelType,
        alternateBetweenBackgrounds: this.alternateBetweenBackgrounds,
        showDividerPerItem: this.showDividerPerItem,
        deletable: this.deletable,
        addable: this.addable,
        showNoDataNotification: this.showNoDataNotification,
        noDataLabel: this.noDataLabel,
        noDataIcon: this.noDataIcon,
        addButtonSize: this.addButtonSize,
        sortable: this.sortable,
        height: this.height,
      },
      fieldArray: {
        fieldGroup: this.children.map((child) => child.toFormly(config)).filter((e) => !!e),
        /*expressionProperties: {
          "props.label": "field.key",
        },*/
      },
      expressions: {
        ...super.toFormly(config).expressions,
        hide: false,
      },
      wrappers: this.label ? ["meta-field-wrapper"] : [],
    });
  }
}
