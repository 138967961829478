/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Exclude, Type } from "class-transformer";
import { Allow, IsBoolean, IsOptional, IsString, isUUID } from "class-validator";
import {
  DataSourceParameters,
  ExposeProperty,
  IMetaPipelineViews,
  ListInputComponent,
  MetaFormBaseComponent,
  MetaFormButtonComponent,
  MetaFormComponent,
  MetaFormComponentInfo,
  MetaFormComponentProperty,
  MetaFormMultiSelectComponent,
  MetaFormSelectComponent,
  NoOutputComponent,
  TagComponent,
} from "../components";
import { EntityType, MetaFormCategory, MetaGroupStyle } from "@meta/enums";
import { v5 as uuid } from "uuid";

import { MetaFormFilter } from "../filters";
import { MetaFormCondition } from "../metaState.dto";
import { SqlRelation } from "../sqlDataSource";
import type { DataSourceType } from "@meta/database/lib/types";
import type { FormEventActionHandler } from "../interfaces";
import { Table } from ".";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { toPlain } from "../formSerializer";

export class ChangeManagementConfig {
  selectFormId: string;
  additionalFields?: string[] = [];
}

export class WizardOptions {
  @MetaFormComponentProperty({
    name: "Size",
    type: "select",
    values: [
      {
        label: "Small",
        value: "small",
      },
      {
        label: "Medium",
        value: "medium",
      },
      {
        label: "Large",
        value: "large",
      },
      {
        label: "Max",
        value: "max",
      },
    ],
  })
  size: "small" | "medium" | "large" | "max" = "medium";
}

@MetaFormComponentInfo({
  type: "editor-settings",
  hideInPallet: true,
})
export class MetaFormEditorSettings {
  @ExposeProperty()
  public previewId: string;

  @ExposeProperty()
  public previewLocale: string;

  @ExposeProperty()
  public editMode: boolean;
}

@MetaFormComponentInfo({
  type: "form",
  icon: "table",
  name: "Form",
  hideInPallet: true,
})
export abstract class MetaForm {
  public static currentCompilerSet: Set<MetaForm>;

  public static readonly allForms = new Set<MetaForm>();
  public static readonly internalForms = new Set<MetaForm>();
  public static readonly customForms = new Set<MetaForm>();
  protected static readonly formHashStore = new WeakMap<MetaForm, string>();

  @ExposeProperty()
  public editorSettings: MetaFormEditorSettings;

  @MetaFormComponentProperty({
    name: "Form ID",
    type: "id",
    tab: "general",
    required: true,
  })
  public id: string;
  @Exclude() public isPreview = false;

  @MetaFormComponentProperty({
    name: "Primary Key",
    type: "primaryKey",
    tab: "data",
  })
  public idField?: string[] = [];

  @MetaFormComponentProperty({
    name: "Label",
    type: "text",
    tab: "general",
    formly: true,
  })
  public label?: string;

  @MetaFormComponentProperty({
    name: "Beschreibung",
    type: "text",
    tab: "general",
    showAsTextarea: true,
  })
  public description?: string;

  @MetaFormComponentProperty({
    name: "Primary for Entity",
    type: "select",
    tab: "data",
    values: "form-builder/entities",
  })
  public primaryForEntityType?: EntityType | string;

  /**
   * A Template that will be used to overwrite the default changelog title
   * [EntityName] [YOUR TEMPLATE] []
   */
  @MetaFormComponentProperty({
    name: "Log Title Template",
    type: "text",
    isTemplate: true,
    hidden: true,
  })
  public logTitleTemplate?: string;

  @MetaFormComponentProperty({
    name: "Title Template",
    type: "text",
    isTemplate: true,
    tab: "general",
  })
  public titleTemplate?: string;

  @MetaFormComponentProperty({
    name: "Subtitle Template",
    type: "text",
    isTemplate: true,
    tab: "general",
    hidden: true,
    default: null,
  })
  public subtitleTemplate?: string;

  @MetaFormComponentProperty({
    name: "Subtitle Prefix Template",
    type: "text",
    isTemplate: true,
    tab: "general",
    hidden: true,
  })
  public subtitlePrefixTemplate?: string;

  @MetaFormComponentProperty({
    name: "Subtitle Prefix Type",
    type: "select",
    values: [
      {
        label: "Icon",
        value: "icon",
      },
      {
        label: "Text",
        value: "text",
      },
      {
        label: "Progress",
        value: "progress",
      },
    ],
    hidden: true,
    default: "text",
  })
  public subtitlePrefixType?: "icon" | "text" | "progress" = "text";

  @MetaFormComponentProperty({
    name: "Workflows",
    type: "action",
    tab: "workflow",
  })
  public workflows?: FormEventActionHandler<string[]>;

  @MetaFormComponentProperty({
    name: "Entity Type",
    type: "select",
    tab: "data",
    values: "form-builder/entities",
  })
  public entityType: EntityType | string;

  @MetaFormComponentProperty({
    name: "Class",
    type: "js",
    tab: "other",
    hidden: true,
    default: "class Form {}",
  })
  public class: string;

  @MetaFormComponentProperty({
    name: "Class Name",
    type: "text",
    tab: "other",
    hidden: true,
    default: "Form",
  })
  public className = "Form";

  @MetaFormComponentProperty({
    name: "Icon",
    type: "icon",
    iconSet: "lord",
    tab: "style",
  })
  public icon = "nocovwne";

  @MetaFormComponentProperty({
    name: "Menu Icon",
    type: "icon",
    iconSet: "fa",
    tab: "style",
    hidden: true,
  })
  public menuIcon: string;

  @MetaFormComponentProperty({
    name: "Show Gruppen Wrapper",
    type: "boolean",
    tab: "style",
    default: false,
  })
  public showGroupWrapper? = false;

  @MetaFormComponentProperty<MetaForm>({
    name: "Gruppen Wrapper Style",
    type: "select",
    tab: "style",
    condition: (o) => o.showGroupWrapper,
    values: Object.entries(MetaGroupStyle).map(([value, label]) => {
      return {
        label,
        value,
      };
    }),
    hidden: true,
  })
  public groupWrapperStyle?: MetaGroupStyle;

  @MetaFormComponentProperty({
    name: "Create Form",
    type: "subform",
  })
  public createable?: string;

  @MetaFormComponentProperty({
    name: "Sperrbar",
    type: "boolean",
    default: false,
  })
  public lockable = false;
  @Exclude()
  @Allow()
  public wizard?: any;

  @MetaFormComponentProperty<MetaForm>({
    name: "WizardOptions",
    type: "sub-options",
    condition: (o) => !!o.wizard,
  })
  public wizardOptions?: WizardOptions;
  @ExposeProperty()
  @IsString()
  @IsOptional()
  public changeManagementForm?: string;
  @MetaFormComponentProperty<MetaForm>({
    name: "ChangeManagementConfig",
    type: "sub-options",
    condition: (o) => !!o.changeManagementForm,
  })
  public changeManagementConfig?: ChangeManagementConfig;

  @MetaFormComponentProperty({
    name: "Locked Field",
    type: "path",
    tab: "data",
  })
  public lockedField? = "Sperre";

  @MetaFormComponentProperty({
    name: "Locked Reason Field",
    type: "path",
    tab: "data",
  })
  public lockedReasonField? = "SperreGrund";

  @MetaFormComponentProperty({
    name: "Locked Reason Mode",
    type: "path",
    tab: "data",
  })
  public lockedReasonMode: "onLock" | "onUnlock" | "both" | "none" = "none";

  @MetaFormComponentProperty({
    name: "Relationen",
    type: "relations",
    tab: "data",
  })
  public relations?: SqlRelation[] = [];

  @MetaFormComponentProperty({
    name: "Beim öffnen",
    type: "action",
    tab: "hooks",
  })
  public onLoaded?: FormEventActionHandler;

  @MetaFormComponentProperty({
    name: "I18n",
    type: "json",
    tab: "data",
    hidden: true,
  })
  public i18n?: Record<string, Record<string, string>>;

  @MetaFormComponentProperty({
    name: "Beim Löschen",
    type: "action",
    tab: "hooks",
  })
  public onDelete?: FormEventActionHandler;
  @MetaFormComponentProperty({
    name: "Beim Erstellen",
    type: "action",
    tab: "hooks",
  })
  public onCreate?: FormEventActionHandler;
  @MetaFormComponentProperty({
    name: "Beim Version Erstellen",
    type: "action",
    tab: "hooks",
  })
  public onCreateVersion?: FormEventActionHandler;
  @MetaFormComponentProperty({
    name: "Beim Version Erstellen Löschen",
    type: "action",
    tab: "hooks",
  })
  public onDeleteVersion?: FormEventActionHandler;
  @MetaFormComponentProperty({
    name: "Version Erstellen Label",
    type: "text",
  })
  public createVersionLabel = "Neue Version erstellen";
  @MetaFormComponentProperty({
    name: "Version Löschen Label",
    type: "text",
  })
  public deleteVersionLabel = "Version sperren";
  @Exclude()
  public parentForm?: MetaForm;

  @MetaFormComponentProperty({
    name: "Versionierbar",
    type: "boolean",
    default: false,
  })
  public versionable? = false;

  @MetaFormComponentProperty({
    name: "Feld Version",
    type: "text",
    default: "VersionsNr",
    condition: (c) => c.versionable,
  })
  public versionField? = "VersionsNr";

  @MetaFormComponentProperty({
    name: "Version Label",
    type: "text",
    default: "Version",
    condition: (c) => c.versionable,
  })
  public versionLabel?: "Version";

  @MetaFormComponentProperty({
    name: "Versiontype",
    type: "select",
    values: [
      {
        label: "Numeric",
        value: "numeric",
      },
      {
        label: "Alphabetic",
        value: "alphabetic",
      },
    ],
    default: "numeric",
    condition: (c) => c.versionable,
  })
  public versionType?: "numeric" | "alphabetic" = "numeric";

  @MetaFormComponentProperty({
    name: "Beim Öffnen",
    type: "js",
    tab: "hooks",
  })
  public onOpen?: FormEventActionHandler;

  @IsBoolean()
  public autoLoadRelations? = false;
  @ExposeProperty()
  public filters?: MetaFormFilter<any>[] = [];
  @ExposeProperty()
  @Type(() => DataSourceParameters)
  public dataSourceParameters?: DataSourceParameters;

  @MetaFormComponentProperty({
    name: "Kategorie",
    type: "select",
    tab: "other",
    allowCustom: true,
    values: Object.entries(MetaFormCategory).map(([value, label]) => {
      return {
        label,
        value,
      };
    }),
  })
  public category?: MetaFormCategory;

  @ExposeProperty()
  public defaultCondition?: MetaFormCondition<MetaFormBaseComponent[]>;

  @MetaFormComponentProperty({
    name: "Bedingung",
    type: "condition",
    description: 'Zeigt das Element, wenn die Bedingung einen wahren Wert ergibt, oder den String "true".',
    default: true,
  })
  public condition?: string | boolean = true;

  @MetaFormComponentProperty({
    name: "Haupt Datenquelle",
    type: "dataSource",
    tab: "data",
  })
  public primaryDataSource?: DataSourceType = null;

  @MetaFormComponentProperty({
    name: "Workflow Datenquelle",
    type: "dataSource",
    tab: "workflow",
  })
  public workflowDataSource?: DataSourceType = null;

  @MetaFormComponentProperty({
    name: "Aktionen",
    type: "children",
    allowedChildren: ["button"],
    tab: "hooks",
  })
  public actions: MetaFormButtonComponent[] = [];

  @MetaFormComponentProperty({
    name: "Children",
    type: "children",
    description: "Child Elements of the component. ",
    allowedChildren: "all",
  })
  public children: MetaFormBaseComponent[] = [];

  @MetaFormComponentProperty({
    name: "Admin Form",
    type: "boolean",
    default: false,
    hidden: true,
  })
  public isAdmin = false;

  @MetaFormComponentProperty({
    name: "Veröffentlichen Field",
    type: "path",
  })
  public publishField?: string;

  public groups?: string[] = [];

  public views?: IMetaPipelineViews[] = [];

  public deleteActionLabel: string = "Löschen";

  public deleteActionState: string = "Gelöscht";

  @MetaFormComponentProperty({
    name: "Implizite  Abhängigkeiten",
    type: "subformArray",
  })
  public implicitDependencies?: string[] = [];

  @Exclude()
  private usedIds?: Set<string> = new Set<string>();

  @MetaFormComponentProperty({
    name: "Befüllungsgrad anzeigen",
    type: "boolean",
  })
  public showFillingLevel = false;

  @MetaFormComponentProperty({
    name: "Befüllungsgrad berechnen",
    type: "boolean",
    default: false,
  })
  public calculateFillingLevel: boolean;

  @MetaFormComponentProperty({
    name: "In Suche Anzeigen",
    type: "boolean",
    default: false,
  })
  public searchShortcut = false;

  protected constructor(opts: Partial<MetaForm>) {
    Object.assign(this, opts);
    if (!this.entityType && this.primaryForEntityType) {
      this.entityType = this.primaryForEntityType;
    }
    this.getComponentsArray()
      .filter((e) => e.id)
      .forEach((e) => {
        if (this.usedIds.has(e.id)) {
          throw new Error(`Duplicate component id: "${e.id}" found on form with id ${this.id}`);
        }
        this.usedIds.add(e.id);
      });

    this.setChildIds([...(this.children || []), ...(this.actions || [])], this.id, this.id, this);
  }

  @Exclude()
  public get hash() {
    return MetaForm.getFormHash(this);
  }

  public static getFormById<T extends MetaForm = MetaForm>(id: string, getInternal = false): T {
    const res = Array.from(getInternal ? MetaForm.internalForms : MetaForm.allForms).find(
      (e) => e.id === id,
    ) as unknown as T;
    if (res) {
      return res;
    }
    return null;
  }

  public static getAllForms(): MetaForm[] {
    return Array.from(MetaForm.allForms);
  }

  public static getInternalForms(): MetaForm[] {
    return Array.from(MetaForm.internalForms);
  }

  public static getFormHash(form: MetaForm) {
    let hash = this.formHashStore.get(form);
    if (!hash) {
      hash = uuid(form.id + BUILT_AT, "A3F2B195-D32D-436F-88F3-3CEDB705A300");
      this.formHashStore.set(form, hash);
    }
    return hash;
  }

  public static addForm(form: MetaForm) {
    const f = MetaForm.getFormById(form.id);
    if (f) {
      throw new Error(`The id for the form ${form.id} must be unique.`);
    }
    if (form.primaryForEntityType) {
      const pf = MetaForm.getPrimaryFormForEntity(form.primaryForEntityType);
      if (pf) {
        throw new Error(
          `Cold not set "${form.id}" as Primary for Entity ${pf.primaryForEntityType} because ${pf.id} is already marked as Primary for Entity.`,
        );
      }
    }

    MetaForm.allForms.add(form);
    if (this.currentCompilerSet) {
      this.currentCompilerSet.add(form);
    }
  }

  public static removeForm(form: MetaForm) {
    MetaForm.allForms.delete(form);
  }

  public static replaceForm(form: MetaForm) {
    const f = MetaForm.getFormById(form.id);
    if (f) {
      MetaForm.allForms.delete(f);
      console.log(`Replacing Form ${form.id}…`);
    }
    MetaForm.allForms.add(form);
    // TODO: @Allan: Warum werden hier alle Unterformulare replaced?
    // Das führt dazu, dass ich bei Hooks nicht mehr auf den ParenForm zugreifen kann
    // Musst du beheben.
    const subForms = form.getSubForms();
    subForms.forEach((subForm) => {
      const oldSubForm = MetaForm.getFormById(subForm.id);
      if (!oldSubForm) {
        console.log(`NEW sub Form ${subForm.id}…`);
        MetaForm.allForms.add(subForm);
      }
    });
  }

  public static getPrimaryFormForEntity(entity: EntityType | string) {
    return MetaForm.getAllForms().find((f) => {
      return f.primaryForEntityType === entity;
    });
  }

  public static getUrlForEntity(entity: EntityType | string, itemId: string, skipEncoding = false) {
    const form = MetaForm.getPrimaryFormForEntity(entity);
    if (!form) {
      if (entity === "LEADPL") {
        return `/pipeline/lead-management/${itemId}`;
      } else if (entity === "PROJEKTPL") {
        return `/pipeline/projekt-management/${itemId}`;
      } else if (entity === "TASK") {
        return `/deep-link?type=task&id=${itemId}`;
      } else if (entity === "FILE") {
        return `/deep-link/?type=file&id=${itemId}`;
      } else if (entity === "WF") {
        return `/workflow/${itemId}`;
      } else if (entity === "WF_EXEC") {
        return `/workflow/${itemId}/executions`;
      } else if (entity === "MAIL") {
        return `/deep-link?type=mail&id=${itemId}`;
      }
    }
    return form?.getUrl(itemId, skipEncoding);
  }

  public toFormly(config: IFormUserConfiguration = {}) {
    let changeManagementForm: string;
    if (this.changeManagementForm) {
      const form = MetaForm.getFormById(this.changeManagementForm);
      if (form) {
        changeManagementForm = form.changeManagementConfig?.selectFormId || this.changeManagementForm;
      }
    }
    return {
      id: this.id,
      label: this.label || this.label,
      description: this.description,
      icon: this.icon,
      // TODO: replace instanceToPlain with toFormly(config)
      actions: this.actions.map((action) => toPlain(action)),
      children: this.children.filter((e) => !!e).map((child) => child.toFormly(config)),
      filters: this.filters.map((filter) => filter.toFormly(config)),
      changeManagementForm,
      deletable: !!this.onDelete,
      creatable: this.createable,
      lockable: this.lockable,
      lockedReasonMode: this.lockedReasonMode,
      publishable: !!this.publishField,
      deleteVersionLabel: this.deleteVersionLabel,
      createVersionLabel: this.createVersionLabel,
      hasDataSource: !!this.primaryDataSource,
      hasIdField: this.idField?.length > 0,
      entityType: this.primaryForEntityType || this.entityType,
      wizard: this.wizard?.id,
      wizardOptions: this.wizardOptions,
      onOpen: !!this.onOpen,
      onLoaded: !!this.onLoaded,
      versionable: !!this.versionable,
      versionField: this.versionField,
      versionType: this.versionType,
      deleteActionLabel: this.deleteActionLabel,
      showFillingLevel: !!this.showFillingLevel,
    };
  }

  public getUrl(itemId: string, skipEncode = false): string {
    const id = Array.isArray(itemId) ? itemId.join(",") : itemId;
    return `/${this.id}/${skipEncode ? id : encodeURI(id)}`;
  }

  public getParentListInputForComponent(c: MetaFormBaseComponent): ListInputComponent {
    const comps = this.getComponentsArray();
    while (c && c.parentId) {
      c = comps.find((e) => e.id === c.parentId);
      if (c instanceof ListInputComponent) {
        return c;
      }
      if (c instanceof MetaForm) {
        break;
      }
    }
    return null;
  }

  public getSubForms(deep = false) {
    const comps = this.getComponentsArray({
      includeSubForms: true,
    });
    return comps.filter((e) => e instanceof MetaForm) as unknown as MetaForm[];
  }

  public getParentForComponent<T = any>(c: MetaFormComponent): T {
    const comps = this.getComponentsArray({
      includeSubForms: true,
      includeActions: true,
    });
    if (this.id === c.parentId) {
      return this as any;
    }
    return comps.find((e) => e.id === c.parentId) as any;
  }

  public getParentFormForComponent<T extends MetaForm>(c: MetaFormComponent): T {
    while (c && !(c instanceof MetaForm)) {
      c = this.getParentForComponent(c);
    }
    return c as T;
  }

  public getComponentsArray(
    options: {
      excludeListInputChildren?: boolean;
      excludeSelects?: boolean;
      excludeTags?: boolean;
      excludeNoOutput?: boolean;
      includeSubForms?: boolean;
      includeSubFormsWithoutChildren?: boolean;
      excludeReadonly?: boolean;
      includeActions?: boolean;
    } = {},
  ) {
    return this._getComponentsArray(options).map((e) => e[1]);
  }

  public getComponentByPath<T extends MetaFormBaseComponent>(path: string): T {
    const comps = this.getComponentsArray();

    let p = path.split(".");
    if (p[0].toLowerCase() === "main" && p.length > 1) {
      p.pop();
    }
    const normalizedPath = p.join(".").toLowerCase();

    for (let c of comps) {
      if (c.path && c.path.toLowerCase() === normalizedPath) {
        return c as T;
      }
    }
    return null;
  }

  public getComponentsTree(
    options: {
      excludeListInputChildren?: boolean;
      excludeSelects?: boolean;
      excludeTags?: boolean;
      excludeNoOutput?: boolean;
      includeSubForms?: boolean;
      includeSubFormsWithoutChildren?: boolean;
      excludeReadonly?: boolean;
      includeActions?: boolean;
    } = {},
  ) {
    return this._getComponentsArray(options);
  }

  public _getComponentsArray(
    options: {
      excludeListInputChildren?: boolean;
      excludeSelects?: boolean;
      excludeTags?: boolean;
      excludeNoOutput?: boolean;
      includeSubForms?: boolean;
      includeSubFormsWithoutChildren?: boolean;
      excludeReadonly?: boolean;
      includeActions?: boolean;
    } = {},
    _children?: any[],
    _parent?: any,
    path: string[] = [],
  ): [string[], MetaFormBaseComponent][] {
    if (_parent) {
      path.push(_parent.id);
    }
    const children = (_children || this.children || []) as MetaFormBaseComponent[];
    const result: [string[], MetaFormBaseComponent][] = [];

    if (options.includeActions) {
      result.push(
        ...(this.actions.map((a) => {
          return [[...path], a];
        }) as any),
      );
    }

    for (const child of children) {
      child.parentId = _parent?.id || this.id;
      if (options.excludeNoOutput && child instanceof NoOutputComponent) {
        continue;
      }
      if (child instanceof ListInputComponent) {
        result.push(...this._getComponentsArray(options, child.header, child, [...path]));
        result.push(...this._getComponentsArray(options, child.footer, child, [...path]));
        if (options.excludeListInputChildren) {
          result.push([[...path], child]);
          continue;
        }
      }
      if (child instanceof Table) {
        result.push(...this._getComponentsArray(options, child.filter, child, [...path]));
      }
      if (child instanceof MetaForm && options.includeSubFormsWithoutChildren) {
        result.push([[...path], child]);
        continue;
      }
      if (child instanceof MetaForm && !options.includeSubForms) {
        continue;
      }
      if (child instanceof TagComponent && !options.excludeTags) {
        continue;
      }
      if (child instanceof MetaFormSelectComponent && options.excludeSelects) {
        continue;
      }
      if (child.readonly && options.excludeReadonly) {
        continue;
      }
      result.push([[...path], child]);
      if (Array.isArray(child["children"])) {
        result.push(...this._getComponentsArray(options, child["children"], child, [...path]));
      }
    }
    return result;
  }

  private getNewIdForComponent?(comp: MetaFormComponent, parentId: string) {
    return null;
  }

  private setChildIds?(children: any[], parentId: string, parentName: string, parent: any, prefix?: string) {
    let i = 0;
    for (const child of children) {
      if (child) {
        child["parentId"] = parentId;

        if (child instanceof MetaForm) {
          child.parentForm = this;
          continue;
        }

        if (!child["id"]) {
          if (isUUID(parentId)) {
            child["id"] = this.getNewIdForComponent(child, parentId) || uuid(i.toString(), parentId);
            i++;
          } else {
            child["id"] = this.getNewIdForComponent(child, parentId) || `${parentId}-${i}`;
            i++;
          }
          if (prefix) {
            child["id"] = `${prefix}${child["id"]}`;
          }
        }
        this.setChildIds([...(child.children || []), ...(child.actions || [])], child.id, child.name, child);
        if (child instanceof MetaFormMultiSelectComponent) {
          child.children[0]["id"] = `${child.id}-value`;
          child.selectId = child.children[0]["id"];
        }
        if (typeof child.init === "function") {
          child.init(parent, (c: any[], prefix: string) => {
            this.setChildIds(c, child.id, child.name, child, prefix);
          });
        }
      }
    }
  }
}
