import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChildren } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { NzModalRef } from "ng-zorro-antd/modal";
import { NzResizeEvent } from "ng-zorro-antd/resizable";
import { firstValueFrom, Subject, takeUntil } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { MetaEventService } from "../../../services/metaEvents.service";
import { MetaActionHandlerFactory } from "../actions/actionHandler.factory";
import { MetaFormService } from "../metaForm.service";

@Component({
  selector: "meta-form-wizard",
  template: ` <div nz-row>
    <div
      #steps
      *ngFor="let step of this.metaFormService.formState.form().children; let index = index"
      class="split-view-content"
      [ngClass]="{ hidden: paramCurrentStep !== index }"
      nz-col
      [nzSpan]="24"
    >
      <h2 class="split-view-title">
        Schritt {{ index + 1 + " von " + this.metaFormService.formState.form().children?.length }}
        <span class="text-accent">{{ step.label || step.props["label"] }}</span>
      </h2>
      @if (metaFormService.formState.initializationLoaded()) {
        <formly-form
          [fields]="step['fieldGroup']"
          [options]="metaFormService.options"
          [model]="metaFormService.formState.data()"
          [form]="stepForm[index]"
        ></formly-form>
      }
      <div class="slide-navigation-container">
        <meta-section [maParams]="{ sectionType: 'hr' }"></meta-section>
        <div class="slide-navigation-actions">
          <meta-button
            *ngIf="paramCurrentStep > 0 && !this.metaFormService.formState.form().children[paramCurrentStep].hidePrev"
            (click)="handlePrev()"
            [maParams]="{
              label: 'Zurück',
              type: 'default'
            }"
          ></meta-button>
          <meta-button
            *ngIf="paramCurrentStep + 1 === this.metaFormService.formState.form().children?.length"
            (click)="handleComplete()"
            [maParams]="{
              label: metaFormService.formState.form().labelComplete,
              type: 'primary',
              loading: metaFormService.formState.isLoading(),
              disabled: metaFormService.formState.form().children[paramCurrentStep]?.condition
                ? !metaFormService.formState.form().children[paramCurrentStep]?.condition()
                : false
            }"
          ></meta-button>
          <meta-button
            *ngIf="paramCurrentStep + 1 < this.metaFormService.formState.form().children?.length"
            (click)="handleNext()"
            [maParams]="{
              label: 'Weiter',
              type: 'primary',
              loading: metaFormService.formState.isLoading(),
              disabled: metaFormService.formState.form().children[paramCurrentStep]?.condition
                ? !metaFormService.formState.form().children[paramCurrentStep]?.condition()
                : false
            }"
          ></meta-button>
        </div>
      </div>
    </div>
    <div class="modal-footer" *nzModalFooter>
      @if (paramCurrentStep > 0 && !metaFormService.formState.form().children[paramCurrentStep].hidePrev) {
        <meta-button
          (click)="handlePrev()"
          [maParams]="{
            label: 'Zurück',
            type: 'default'
          }"
        ></meta-button>
      }
      @if (paramCurrentStep + 1 === this.metaFormService.formState.form().children?.length) {
        <meta-button
          (click)="handleComplete()"
          [maParams]="{
            label: metaFormService.formState.form().labelComplete,
            type: 'primary',
            loading: metaFormService.formState.isLoading(),
            disabled: metaFormService.formState.form().children[paramCurrentStep]?.condition
              ? !metaFormService.formState.form().children[paramCurrentStep]?.condition()
              : false
          }"
        ></meta-button>
      }
      @if (paramCurrentStep + 1 < this.metaFormService.formState.form().children?.length) {
        <meta-button
          (click)="handleNext()"
          [maParams]="{
            label: 'Weiter',
            type: 'primary',
            loading: metaFormService.formState.isLoading(),
            disabled: metaFormService.formState.form().children[paramCurrentStep]?.condition
              ? !metaFormService.formState.form().children[paramCurrentStep]?.condition()
              : false
          }"
        ></meta-button>
      }
    </div>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaFormWizardComponent implements OnInit {
  public paramCurrentStep = 0; // The current step, counting from 0.
  public paramCol = 4;
  public paramId = -1;
  public stepIsInitialized: boolean[] = [true]; // Initializes the first step
  public stepForm: UntypedFormGroup[] = [];
  public readonly destroyed$ = new Subject<any>();
  @ViewChildren("steps") steps;

  constructor(
    public readonly metaFormService: MetaFormService,
    public readonly changeDetectorRef: ChangeDetectorRef,
    private readonly _metaActionHandler: MetaActionHandlerFactory,
    private readonly _metaEventService: MetaEventService,
    private readonly _modalRef: NzModalRef,
  ) {}

  async ngOnInit() {
    if (this.metaFormService.formState.formId) {
      await this.metaFormService.getInitialization();
    }

    if (this.metaFormService.formState.form().children?.length > 0) {
      this.metaFormService.formState.form().children.forEach((d, i) => {
        this.stepForm[i] = new UntypedFormGroup({});
        this.stepForm[i].valueChanges
          .pipe(
            takeUntil(this.destroyed$),
            distinctUntilChanged((a: any, b: any) => JSON.stringify(a) === JSON.stringify(b)),
            debounceTime(50),
          )
          .subscribe({
            next: async (f) => {
              this.updateDisplayValues(true);
            },
          });
      });
    } else {
      // Implement Service to get Steps from API
    }

    if (this.metaFormService.formState.form().actions.length > 0) {
      this.metaFormService.formState.form().labelComplete = this.metaFormService.formState.form().actions[0].label;
    }
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(null);
      this.destroyed$.complete();
    }
  }

  onResize({ col }: NzResizeEvent): void {
    cancelAnimationFrame(this.paramId);
    this.paramId = requestAnimationFrame(() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.paramCol = col!;
    });
  }

  public async handleNext() {
    if (!this.stepForm[this.paramCurrentStep].valid) {
      this.stepForm[this.paramCurrentStep].markAllAsTouched();
      this.changeDetectorRef.markForCheck();
      return;
    }

    if (this.metaFormService.formState.form()?.onNextStep) {
      const result = await this._metaActionHandler
        .executeNextStepAction({
          ctx: this.metaFormService.formState.data()._ctx,
          formId: this.metaFormService.formState.formId,
          data: this.metaFormService.formState.data(),
          index: this.paramCurrentStep,
          formIsValid: this.stepForm[this.paramCurrentStep].valid,
        })
        .catch((e) => {
          console.error(e);
        });
      if (!result) {
        return;
      }
    }

    this.paramCurrentStep += 1;
    this.stepIsInitialized[this.paramCurrentStep] = true;
    this.changeDetectorRef.markForCheck();
    this._checkFormlyExpressions();
    setTimeout(() => {
      this.steps.toArray()[this.paramCurrentStep].nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    });
  }

  public async handlePrev() {
    if (this.metaFormService.formState.form()?.onPreviousStep) {
      const result = await this._metaActionHandler
        .executePreviousStepAction({
          ctx: this.metaFormService.formState.data()._ctx,
          formId: this.metaFormService.formState.formId,
          data: this.metaFormService.formState.data(),
          index: this.paramCurrentStep,
          formIsValid: this.stepForm[this.paramCurrentStep].valid,
        })
        .catch((e) => {
          console.error(e);
        });
      if (!result) {
        return;
      }
    }

    this.paramCurrentStep -= 1;
    this.changeDetectorRef.markForCheck();
    this._checkFormlyExpressions();
    setTimeout(() => {
      this.steps.toArray()[this.paramCurrentStep].nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    });
  }

  public async handleComplete() {
    if (!this.stepForm[this.paramCurrentStep].valid) {
      this.stepForm[this.paramCurrentStep].markAllAsTouched();
      this.changeDetectorRef.markForCheck();
      return;
    }
    if (this.metaFormService.formState.form()?.actions?.length > 0) {
      const action = this.metaFormService.formState.form().actions[0];
      if (this.metaFormService.formState.displayData()?.[action.id]?.disabledCondition) {
        return;
      }
      this.metaFormService.formState.isLoading.set(true);
      this.changeDetectorRef.markForCheck();

      this._metaActionHandler
        .executeClickAction({
          controlId: action.id,
          ctx: this.metaFormService.formState.data()._ctx,
          formId: this.metaFormService.formState.formId,
          data: this.metaFormService.formState.data(),
          formIsValid: this.stepForm[this.paramCurrentStep].valid,
          passthroughData: { formId: this.metaFormService.formState.formId },
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.metaFormService.formState.isLoading.set(false);
          this.changeDetectorRef.markForCheck();
        });
    } else {
      this.metaFormService.formState.isLoading.set(true);
      this.changeDetectorRef.markForCheck();
      try {
        const res = await firstValueFrom(
          this.metaFormService.updateFormData({
            formId: this.metaFormService.formState.formId,
            itemId: "create",
            oldData: {},
            newData: this.metaFormService.formState.data(),
            state: this.metaFormService.formState.state(),
            publish: false,
            completeEditMode: true,
          }),
        );
        this._metaEventService.emitSaveTrigger({
          formId: this.metaFormService.formState.formId,
          itemId: res.result._id.join(","),
          timestamp: new Date().getTime(),
        });
        this._modalRef.close(res.result);
      } catch (e) {
      } finally {
        this.metaFormService.formState.isLoading.set(false);
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  public async updateDisplayValues(editing: boolean) {
    // Cancel if model is empty
    if (
      this.metaFormService.formState.data().length === 0 &&
      Object.getPrototypeOf(this.metaFormService.formState.data()) === Object.prototype
    ) {
      return;
    }
    // Cancel if model has only initial values (null and undefined)
    if (Object.values(this.metaFormService.formState.data()).every((x) => x === null || x === undefined)) {
      return;
    }
    this.metaFormService.updateDisplayValues();
  }

  private _checkFormlyExpressions() {
    if (this.metaFormService.formState.data() && this.metaFormService.formState.data().length > 0) {
      this.metaFormService.formState.data().forEach((d) => {
        d.fieldGroup.forEach((field) => {
          if (field && field.options) {
            (<any>field.options).checkExpressions(field);
          }
        });
      });
    }
  }
}
