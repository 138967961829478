/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Allow, IsArray, IsBoolean, IsNumber, IsOptional, IsString } from "class-validator";
import type { IMetaSortableOptions } from "../columnOptions";
import { IColumnOptions } from "../columnOptions";
import { MetaFormAggregator } from "../metaState.dto";
import type { TemplateRootType } from "../interfaces";
import { FormlyHideExpression, MetaIconStyle, TooltipSize } from "../../../api-interfaces/src/lib/enums";
import { ComponentFactories, SerialisationTypeMap } from "../metadataStore";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { MetaForm, Table } from "../pages";
import { deepMerge } from "../deepMerge";
export const metadataKey = "__componentProperties" as const;

export interface MetaFormComponentInternalInfo {
  isPartOfUniqueKey: boolean;
  isNullable: boolean;
  isEditable: boolean;
}
const FormlySanitizeTemplateRegExp = /{{.+?}}/gim;

export function FormlySanitizeTemplate(template: any): string {
  if (typeof template === "string") {
    return template.replace(FormlySanitizeTemplateRegExp, "");
  } else if (typeof template === "function") {
    return "";
  } else {
    return template as any;
  }
}

@MetaFormComponentInfo({
  type: "component",
  icon: "sort-numeric-up-alt",
  name: "Component",
  hideInPallet: true,
})
export abstract class MetaFormComponent {
  public static meta?: IMetaFormComponentMetadata;

  public info?: MetaFormComponentInternalInfo;

  /**
   * An id which uniquely identifies the element.
   * The id **must only** occur 1 times in a form.
   */
  @MetaFormComponentProperty({
    name: "Id",
    type: "text",
    tab: "general",
    description: "The id of the element and it can be uniquely identified.",
  })
  public id?: string;
  /**
   * Marks the item and all children as read only.
   */
  @MetaFormComponentProperty({
    name: "Readonly",
    type: "boolean",
    description: "Marks the element and all child elements as read-only.",
    hidden: true,
  })
  public readonly? = false;

  @MetaFormComponentProperty({
    name: "Beschreibung",
    type: "text",
    showAsTextarea: true,
    description: "Eine Beschreibung, die neben dem Element angezeigt wird.",
    renderDisplayValue: true,
    tab: "general",
  })
  public description?: string;

  @MetaFormComponentProperty({
    name: "Tooltip Formular",
    type: "subform",
    description: "Id of the form to open in the tooltip.",
    group: "Tooltip",
  })
  public tooltipForm?: string;
  @MetaFormComponentProperty({
    name: "Tooltip-Params",
    type: "text",
    group: "Tooltip",
    renderDisplayValue: true,
    condition: (f) => f.tooltipForm,
  })
  public tooltipParams?: Record<string, string>;
  @MetaFormComponentProperty({
    name: "Tooltip-Size",
    type: "text",
    group: "Tooltip",
    condition: (f) => f.tooltipForm,
  })
  public tooltipSize?: TooltipSize = TooltipSize.medium;
  @MetaFormComponentProperty({
    name: "Tooltip-Width",
    type: "text",
    group: "Tooltip",
    condition: (f) => f.tooltipForm,
  })
  public tooltipWidth?: string;
  @MetaFormComponentProperty({
    name: "Tooltip-Height",
    type: "text",
    group: "Tooltip",
    condition: (f) => f.tooltipForm,
  })
  public tooltipHeight?: string;
  @MetaFormComponentProperty({
    name: "Tooltip-Placement",
    type: "text",
    group: "Tooltip",
    renderDisplayValue: true,
    condition: (f) => f.tooltipForm,
  })
  public tooltipPlacement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom"
    | Array<string> = ["rightTop", "leftTop"];

  // TODO: @peter remove from MetaFormComponent
  @MetaFormComponentProperty({
    name: "eventNameSingular",
    type: "text",
    group: "general",
    hidden: true,
  })
  public eventNameSingular?: string;

  // TODO: @peter remove from MetaFormComponent
  @MetaFormComponentProperty({
    name: "eventNamePlural",
    type: "text",
    group: "general",
    hidden: true,
  })
  eventNamePlural?: string;
  // TODO: @peter remove from MetaFormComponent
  @MetaFormComponentProperty({
    name: "sectionNameSingular",
    type: "text",
    group: "general",
    hidden: true,
  })
  sectionNameSingular?: string;
  // TODO: @peter remove from MetaFormComponent
  @MetaFormComponentProperty({
    name: "sectionNamePlural",
    type: "text",
    group: "general",
    hidden: true,
  })
  sectionNamePlural?: string;

  @MetaFormComponentProperty({
    name: "Sichtbar",
    type: "condition",
    description: 'Zeigt das Element, wenn die Bedingung einen wahren Wert ergibt, oder den String "true".',
    tab: "general",
    renderDisplayValue: true,
  })
  public condition?: string | boolean = true;

  public parentId?: string = null;

  public init?(parent: any, setChildIds: (children: any[], prefix: string) => void) {}

  public toFormly(config: IFormUserConfiguration): any {
    return {
      id: this.id,
      props: {
        hide: (typeof this.condition === "boolean" && this.condition === false) || config.components?.[this.id]?.hidden,
        tooltipForm: this.tooltipForm,
        tooltipParams: this.tooltipParams,
        tooltipSize: this.tooltipSize,
        tooltipPlacement: this.tooltipPlacement,
        tooltipWidth: this.tooltipWidth,
        tooltipHeight: this.tooltipHeight,
        eventNameSingular: this.eventNameSingular,
        eventNamePlural: this.eventNamePlural,
        sectionNameSingular: this.sectionNameSingular,
        sectionNamePlural: this.sectionNamePlural,
      },
      hideExpression: FormlyHideExpression,
      wrappers: this.tooltipForm ? ["meta-tooltip"] : [],
    };
  }
}
export const CreateElements = new Set<any>();

@MetaFormComponentInfo({
  type: "data-source-parameters",
  name: "DataSourceParameters",
  hideInPallet: true,
})
export class DataSourceParameters<DataSource = any> {
  @ExposeProperty()
  public primaryResultParams?: { [key: string]: string | number | boolean } = null;
  @ExposeProperty()
  public customParams?: { [key: string]: string | number | boolean } = null;
  @ExposeProperty()
  public defaultParams?: { [key: string]: string | number | boolean } = null;

  public static create(opts: Partial<DataSourceParameters>) {
    const params = new DataSourceParameters();
    Object.assign(params, opts);
    return params;
  }
}

export class IMetaPipelineViews {
  label: string;
  icon: string;
  formId: string;
}

export interface IMetaFormComponentMetadata {
  name?: string;
  description?: string;
  examples?: { name: string; code: string; description?: string }[];
  // JSX-Selector
  selector?: string;
  // Type used for Serialisation
  type?: string;
  // For UI builder
  allowedChildren?: string[] | "all" | "none";
  forbiddenChildren?: string[];
  icon?: string;
  hideInPallet?: boolean;
  palletGroup?: string;
  color?: string;
}

export function MetaFormComponentInfo(meta: IMetaFormComponentMetadata) {
  return function (a) {
    SerialisationTypeMap[meta.type] = a;
    a["meta"] = {
      color: "#f78ae0",
      ...meta,
    };
    if (meta.selector) {
      ComponentFactories.set(meta.selector, a);
      if (!a.createElement || CreateElements.has(a.createElement)) {
        a.createElement = function (attributes: any, children: any[]) {
          const component = new a();
          Object.assign(component, {
            ...attributes,
            children: Array.isArray(children) ? children : [],
          });
          return component;
        };
        CreateElements.add(a.createElement);
      }
    }
  } as any;
}

type ComponentPropertyTab = "other" | "general" | "style" | "data" | "hooks";

interface IMetaFormComponentPropertyBaseOptions<T = Record<string, any>> {
  name: string;
  prop?: string;
  description?: string;
  formly?: boolean;
  required?: boolean;
  tab?: ComponentPropertyTab | string;
  group?: string;
  condition?: (f: T, form: MetaForm) => boolean;
  hidden?: boolean;
  deprecated?: boolean;
  toPlain?: (object: any, value: any) => any;
  fromPlain?: (object: any, value: any) => any;
  renderDisplayValue?: boolean;
  isArray?: boolean;
  placeholder?: string;
}

interface IMetaFormComponentPropertyDataSourceOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "dataSource";
  default?: string | (() => string);
  paramsField?: string;
}

interface IMetaFormComponentPropertyDataSourceParametersOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "dataSourceParameters";
}

interface IMetaFormComponentPropertyBooleanOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "boolean";
  default?: boolean | (() => boolean);
}

interface IMetaFormComponentPropertyConditionOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "condition";
  default?: boolean | (() => boolean | string) | string;
}

interface IMetaFormComponentPropertyTextOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "text";
  isTemplate?: boolean;
  showAsTextarea?: boolean;
  default?: string | (() => string);
}

interface IMetaFormComponentPropertyColorOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "color";
  isTemplate?: boolean;
  default?: string | (() => string);
}

interface IMetaFormComponentPropertyPathOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "path";
  datasourceField?: string | (() => string);
  default?: string;
}

interface IMetaFormComponentPropertySubOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "sub-options";
}

interface IMetaFormComponentPropertyCSSOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "css";
}

interface IMetaFormComponentPropertyCSSPropertyOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "css-property";
  property: "font-size" | "height" | "width";
  default?: string | (() => string);
}

interface IMetaFormComponentPropertyFontOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "font";
  default?: string | (() => string);
}

interface IMetaFormComponentPropertyNumberOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "number";
  default?: number | (() => number);
  unit?: "px" | "cm" | "mm" | "pt" | "%" | "seconds" | "rem" | "em";
  min?: number;
  max?: number;
}

interface IMetaFormComponentPropertySelectOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "select";
  default?: any | (() => any);
  allowCustom?: boolean;
  values?: { label?: string; value: any; description?: string; icon?: string }[] | string | string[];
}

interface IMetaFormComponentPropertyRelationsOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "relations";
  default?: any | (() => any);
}

interface IMetaFormComponentPropertyJsonOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "json";
  default?: any | (() => any);
}

interface IMetaFormComponentPropertySqlOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "sql";
  default?: string | (() => string);
}

interface IMetaFormComponentPropertyJsOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "js";
  default?: string | (() => string);
}

interface IMetaFormComponentPropertyActionOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "action";
  default?: string;
}

interface IMetaFormComponentPropertyActionArrayOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "actionArray";
}

interface IMetaFormComponentPropertyChildrenArrayOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "children";
  allowedChildren?: string[] | "all" | "none";
}

interface IMetaFormComponentPropertyValidatorsArrayOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "validators";
}

interface IMetaFormComponentPropertyIconOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "icon";
  iconSet?: "fa" | "lord";
  default?: string | (() => string);
}

interface IMetaFormComponentPropertyIdOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "id";
}

interface IMetaFormComponentPropertySubformOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "subform";
  allowed?: string[];
}

interface IMetaFormComponentPropertySubformArrayOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "subformArray";
  allowed?: string[];
}

interface IMetaFormComponentPropertyPrimaryKeyOptions<T> extends IMetaFormComponentPropertyBaseOptions<T> {
  type: "primaryKey";
}

export type IMetaFormComponentPropertyOptions<T = Record<string, any>> =
  | IMetaFormComponentPropertyPathOptions<T>
  | IMetaFormComponentPropertyColorOptions<T>
  | IMetaFormComponentPropertyTextOptions<T>
  | IMetaFormComponentPropertyDataSourceOptions<T>
  | IMetaFormComponentPropertyBooleanOptions<T>
  | IMetaFormComponentPropertyNumberOptions<T>
  | IMetaFormComponentPropertySelectOptions<T>
  | IMetaFormComponentPropertyRelationsOptions<T>
  | IMetaFormComponentPropertyJsonOptions<T>
  | IMetaFormComponentPropertySqlOptions<T>
  | IMetaFormComponentPropertyJsOptions<T>
  | IMetaFormComponentPropertyIconOptions<T>
  | IMetaFormComponentPropertyActionOptions<T>
  | IMetaFormComponentPropertyActionArrayOptions<T>
  | IMetaFormComponentPropertyIdOptions<T>
  | IMetaFormComponentPropertyPrimaryKeyOptions<T>
  | IMetaFormComponentPropertySubformOptions<T>
  | IMetaFormComponentPropertyChildrenArrayOptions<T>
  | IMetaFormComponentPropertyValidatorsArrayOptions<T>
  | IMetaFormComponentPropertySubOptions<T>
  | IMetaFormComponentPropertySubformArrayOptions<T>
  | IMetaFormComponentPropertyCSSOptions<T>
  | IMetaFormComponentPropertyDataSourceParametersOptions<T>
  | IMetaFormComponentPropertyCSSPropertyOptions<T>
  | IMetaFormComponentPropertyFontOptions<T>
  | IMetaFormComponentPropertyConditionOptions<T>;

export function GetComponentProperties(c: MetaFormComponent): Record<string, IMetaFormComponentPropertyOptions> {
  return Reflect.getMetadata(metadataKey, c);
}

export function MetaFormComponentProperty<T = Record<string, any>>(opts: IMetaFormComponentPropertyOptions<T>) {
  return function (a: any, prop: any) {
    const previous = Reflect.getMetadata(metadataKey, a);
    const result = previous ? { ...previous } : {};

    Reflect.defineMetadata(metadataKey, result, a); // defensive set
    if (!opts.required) {
      IsOptional()(a, prop);
    }
    if (opts.type === "text") {
      IsString()(a, prop);
    } else if (opts.type === "boolean") {
      IsBoolean()(a, prop);
    } else if (opts.type === "number") {
      IsNumber()(a, prop);
    } else if (opts.type === "select") {
      if (Array.isArray(opts.values)) {
        opts.values = opts.values.map((value: any) => (typeof value === "object" ? value : { value }));
      }
    } else if (opts.type === "dataSource") {
    } else if (opts.type === "children") {
    } else if (opts.type === "relations") {
    } else if (opts.type === "primaryKey") {
      IsString()(a, prop);
      IsArray()(a, prop);
    } else if (opts.type === "validators") {
    } else {
      Allow()(a, prop);
    }
    result[prop] = {
      group: "general",
      tab: "other",
      ...opts,
      prop,
    };
  } as any;
}

export function ExposeProperty<T = Record<string, any>>() {
  return MetaFormComponentProperty({
    type: null,
    name: null,
    hidden: true,
  });
}

@MetaFormComponentInfo({
  type: "base",
  icon: "sort-numeric-up-alt",
  name: "Base Component",
  hideInPallet: true,
})
export abstract class MetaFormBaseComponent<PrimaryDataSource = any>
  extends MetaFormComponent
  implements IColumnOptions
{
  constructor() {
    super();
  }

  // TODO: move to validatableComponents
  @MetaFormComponentProperty({
    name: "Aktionen",
    type: "children",
    allowedChildren: ["button"],
    tab: "hooks",
    description: "Actions that next to the component.",
  })
  public actions?: any[] = [];
  /**
   * Label Displayed next to the Component
   */
  @MetaFormComponentProperty({
    name: "Label",
    isTemplate: true,
    type: "text",
    tab: "general",
    group: "Label",
    description: "A text that is displayed next to the component.",
    renderDisplayValue: true,
  })
  public label?: string;
  @MetaFormComponentProperty({
    name: "Label Ausblenden",
    type: "boolean",
    tab: "general",
    group: "Label",
    description: "Hides the label.",
  })
  public hideLabel?: boolean;
  @ExposeProperty()
  labelType?: "text" | "icon" = "text";
  @ExposeProperty()
  iconStyle?: MetaIconStyle = MetaIconStyle.Solid;
  @ExposeProperty()
  iconText?: string;
  @ExposeProperty()
  public condensed? = false;
  @MetaFormComponentProperty({
    name: "Farbe",
    type: "color",
    tab: "general",
    group: "Label",
    renderDisplayValue: true,
  })
  public color?: string;
  @MetaFormComponentProperty({
    name: "Hintergrundfarbe",
    type: "color",
    tab: "general",
    group: "Label",
    renderDisplayValue: true,
  })
  public backgroundColor?: string;

  @MetaFormComponentProperty({
    name: "Fett",
    type: "boolean",
    tab: "general",
    group: "Label",
    condition: (e, form) => form instanceof Table,
  })
  public strong?: boolean;

  /**
   * The path of the value in the data source
   */
  // TODO: move to validatableComponent (keep ma-progress in mind)
  @MetaFormComponentProperty({
    name: "Datenbindung",
    type: "path",
    tab: "data",
    description: `Database field from the primary data source or from a relation`,
    required: true,
  })
  public path: string;

  /**
   * Value Prefix
   * Can be a template that is rendered on the server side.
   */
  @MetaFormComponentProperty({
    name: "Prefix",
    type: "text",
    isTemplate: true,
    renderDisplayValue: true,
  })
  public prefix?: string | { type: string; template: string }[];
  @MetaFormComponentProperty({
    name: "Prefix Type",
    type: "select",
    values: [
      {
        label: "Icon",
        value: "icon",
      },
      {
        label: "Text",
        value: "text",
      },
      {
        label: "Progress",
        value: "progress",
      },
    ],
  })
  public prefixType?: "icon" | "text" | "progress" = "text";

  @MetaFormComponentProperty({
    name: "Link",
    type: "text",
    isTemplate: true,
    renderDisplayValue: true,
  })
  public link?: TemplateRootType;

  /**
   * Value Suffix
   * Can be a template that is rendered on the server side.
   * See [here]() for more information
   */
  @MetaFormComponentProperty({
    name: "Suffix",
    type: "text",
    isTemplate: true,
    renderDisplayValue: true,
  })
  public suffix?: TemplateRootType | { type: string; template: string }[];
  @MetaFormComponentProperty({
    name: "Suffix Type",
    type: "select",
    values: [
      {
        label: "Icon",
        value: "icon",
      },
      {
        label: "Text",
        value: "text",
      },
    ],
  })
  public suffixType?: "icon" | "text" | "progress" = "text";

  // TODO: move to inputComponent
  @MetaFormComponentProperty({
    name: "Maximale Bruchziffern",
    type: "number",
    min: 0,
    max: 20,
    default: 20,
  })
  public maximumFractionDigits = 20;

  @MetaFormComponentProperty({
    name: "Deaktiviert",
    type: "condition",
    renderDisplayValue: true,
    tab: "general",
    description: "Deaktiviert alle Benutzereingaben für das Element.",
  })
  public disabled?: string;
  @MetaFormComponentProperty({
    name: "Aggregat",
    type: "select",
    values: [
      { label: "Count", value: "count" },
      { label: "Sum", value: "sum" },
      { label: "Average", value: "average" },
      { label: "Min", value: "min" },
      { label: "Max", value: "max" },
      { label: "None", value: "none" },
    ],
    condition: (o, form) => form instanceof Table,
  })
  public aggregate?: MetaFormAggregator | "none";
  @MetaFormComponentProperty({
    name: "Standardwert",
    type: "text",
    description: "The default value.",
  })
  public defaultValue?: string | number | boolean;
  @MetaFormComponentProperty({
    name: "Anzeigenwert",
    type: "text",
    isTemplate: true,
    tab: "general",
    description: "The value to be displayed when the form is not in edit mode.",
    renderDisplayValue: true,
  })
  public displayValue?: string;
  // TODO: remove & refactor (Peter)
  @MetaFormComponentProperty({
    name: "Breite",
    type: "number",
    tab: "style",
    unit: "px",
    default: null,
    condition: (o, f) => f instanceof Table,
  })
  public width?: string;
  @MetaFormComponentProperty({
    name: "Sticky",
    type: "select",
    values: [
      {
        label: "Links",
        value: "left",
      },
      {
        label: "Rechts",
        value: "right",
      },
    ],
    tab: "style",
    condition: (o, f) => f instanceof Table,
  })
  public sticky?: "left" | "right";
  // Column options
  // TODO: move to listInput
  @MetaFormComponentProperty({
    name: "Sortierbar",
    type: "boolean",
    condition: (o, f) => f instanceof Table,
  })
  public sortable?: IMetaSortableOptions = false;

  @MetaFormComponentProperty({
    name: "Flex",
    type: "number",
  })
  public flex?: number;

  @MetaFormComponentProperty({
    name: "Mindestbreite",
    type: "number",
    condition: (o, f) => f instanceof Table,
  })
  public minWidth?: number;

  public setOptions(opts: Partial<this>) {
    Object.assign(this, opts);
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      key: this.id,
      type: `meta-${this.constructor["meta"]["type"]}`,
      props: {
        label: config.sanitizeTemplates ? FormlySanitizeTemplate(this.label) : this.label,
        hideLabel: this.hideLabel,
        labelType: this.labelType,
        iconText: this.iconText,
        iconStyle: this.iconStyle,
        description: this.description,
        prefix: config.sanitizeTemplates ? FormlySanitizeTemplate(this.prefix) : this.prefix,
        prefixType: this.prefixType,
        suffix: config.sanitizeTemplates ? FormlySanitizeTemplate(this.suffix) : this.suffix,
        suffixType: this.suffixType,
        sticky: this.sticky,
        link: this.link,
        flex: this.flex,
        minWidth: this.minWidth,
        width: this.width,
        color: this.color,
        strong: this.strong,
        actions: this.actions.map((e) => e.toFormly(config)),
        defaultValue: this.defaultValue || undefined,
        sortable: this.sortable,
        condensed: this.condensed,
        disabled: typeof this.disabled === "boolean" && this.disabled,
      },
      expressions: {
        "props.displayData": `formState?.displayData() && formState.displayData()[field.id] ? formState.displayData()[field.id] : {}`,
      },
    });
  }
}
