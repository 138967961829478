/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponent, MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";
import * as _ from "lodash";
import { SqlDataSource } from "../sqlDataSource";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-map",
  type: "map",
  name: "Map",
  icon: "map",
})
export class MetaFormMapComponent extends MetaFormComponent {
  @MetaFormComponentProperty({
    type: "number",
    name: "Height",
    tab: "style",
    unit: "px",
  })
  public height: number;
  @MetaFormComponentProperty({
    type: "number",
    name: "Longitude",
    tab: "data",
  })
  public longitude: number;
  @MetaFormComponentProperty({
    type: "number",
    name: "Latitude",
    tab: "data",
  })
  public latitude: number;
  @MetaFormComponentProperty({
    type: "number",
    name: "Zoom",
    tab: "style",
    default: 5,
  })
  public zoom: number;

  @MetaFormComponentProperty({
    isTemplate: true,
    type: "text",
    name: "Adresse",
    tab: "data",
    description: "Die Adresse die in der Karte angezeigt werden soll.",
  })
  public address?: string;

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      type: `meta-map`,

      props: {
        height: this.height,
        longitude: this.longitude,
        latitude: this.latitude,
        zoom: this.zoom,
        address: this.address,
      },
    });
  }
}

@MetaFormComponentInfo({
  selector: "ma-heatmap",
  type: "heatmap",
  name: "Heatmap",
  icon: "map",
})
export class MetaFormHeatmapComponent extends MetaFormMapComponent {
  @MetaFormComponentProperty({
    type: "dataSource",
    name: "Heat Map Datenquelle",
  })
  public heatMapDataSource: SqlDataSource | string | (() => any);

  @MetaFormComponentProperty({
    type: "path",
    name: "Heat Map Longitude",
  })
  public longitudeField: string;
  @MetaFormComponentProperty({
    type: "path",
    name: "Heat Map Latitude",
  })
  public latitudeField: string;

  @MetaFormComponentProperty({
    isTemplate: true,
    name: "Tooltip",
    type: "text",
    description: "Das tooltip was beim Hover angezeigt wid.",
  })
  public tooltip: string;
}
