/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, NgModule } from "@angular/core";
import { MetaInfoboxType } from "@meta/enums";
import { FormlyModule } from "@ngx-formly/core";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { IMetaWrapperBase, MetaWrapperBase } from "../../base/metaWrapperBase/metaWrapperBase.component";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";

export class IMetaInfobox extends IMetaWrapperBase {
  type?: MetaInfoboxType = MetaInfoboxType.info;
  closable?: boolean;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-infobox",
  template: ` <nz-alert [nzType]="ma.type" [nzMessage]="messageTemplate" [nzCloseable]="ma.closable"></nz-alert>
    <ng-template #messageTemplate>
      <span [innerHTML]="displayData['label'] || ma.label"></span>
    </ng-template>`,
  styleUrls: ["./metaInfobox.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaInfoboxComponent extends MetaWrapperBase {}

@NgModule({
  declarations: [MetaInfoboxComponent],
  imports: [CommonModule, FormlyModule, NzAlertModule],
  exports: [MetaInfoboxComponent],
})
export class MetaInfoboxModule {}
