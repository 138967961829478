/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { DragDropModule, transferArrayItem } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgModule,
  OnDestroy,
  OnInit,
  Optional,
  ViewEncapsulation,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MetaState } from "@meta/enums";
import { FormlyModule } from "@ngx-formly/core";
import * as _ from "lodash";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzModalRef } from "ng-zorro-antd/modal";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { Subject, takeUntil } from "rxjs";
import { filter } from "rxjs/operators";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaAvatarModule } from "../../components/metaAvatar/metaAvatar.component";
import { MetaButtonModule } from "../../components/metaButton/metaButton.component";
import { MetaCheckboxModule } from "../../components/metaCheckbox/metaCheckbox.component";
import { MetaDrodpownModule } from "../../components/metaDropdown/metaDropdown.component";
import { MetaEmptyModule } from "../../components/metaEmpty/metaEmpty.component";
import { MetaFieldWrapperModule } from "../../components/metaFieldWrapper/metaFieldWrapper.component";
import { MetaInputModule } from "../../components/metaInput/metaInput.component";
import { MetaSectionModule } from "../../components/metaSection/metaSection.component";
import { MetaSelectModule } from "../../components/metaSelect/metaSelect.component";
import { MetaToolbarModule } from "../../components/metaToolbar/metaToolbar.component";
import { modalActionTarget } from "../../components/metaToolbar/metaToolbar.interface";
import { PipesModule } from "../../pipes/pipes.module";
import { DirtyCheckService } from "../../services/dirtyCheckService";
import { MetaEventService } from "../../services/metaEvents.service";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaInfoboxModule } from "../metaInfobox/metaInfobox.component";
import { MetaPopoverModule } from "../metaPopover/metaPopover.component";
import { MetaSkeletonModule } from "../metaSkeleton/metaSkeleton.component";
import { MetaCalculationService } from "./metaCalculation.service";

export class MetaCalculation {
  public parentFormId: string;
  public itemId: string;
  public height: string | "auto" = "auto";
}

@MetaUnsubscribe()
@Component({
  selector: "meta-calculation-templates",
  template: `
    <div class="calc-wrapper" *ngIf="(metaCalculationService.hasCalculation$ | async) === true">
      <div class="calc-labels">
        <div
          class="calc-labels-header"
          *ngIf="metaCalculationService.editing$ | async"
          [style.height.px]="130"
          [style.min-height.px]="130"
        >
          <div class="calc-labels-item-header">
            <meta-infobox
              [maParams]="{ label: 'Ziehe ein Element in die Liste um eine neue Position hinzuzufügen.' }"
            ></meta-infobox>
            <div nz-row>
              <div nz-col [nzSpan]="12">
                <div
                  cdkDropList
                  [cdkDropListConnectedTo]="connectedGroups"
                  [cdkDropListDisabled]="(metaCalculationService.editing$ | async) === false"
                >
                  <div
                    cdkDrag
                    class="calc-labels-drag-item"
                    cdkDragPreviewClass="calc-labels-drag-preview"
                    (mouseenter)="onGroupDragStart($event)"
                    [cdkDragLockAxis]="'y'"
                    [cdkDragData]="{}"
                  >
                    + Gruppe
                  </div>
                </div>
              </div>
              <div nz-col [nzSpan]="12">
                <div
                  cdkDropList
                  [cdkDropListConnectedTo]="connectecItems"
                  [cdkDropListDisabled]="(metaCalculationService.editing$ | async) === false"
                >
                  <div
                    class="calc-labels-drag-item"
                    cdkDrag
                    cdkDragPreviewClass="calc-labels-drag-preview"
                    (mouseenter)="onItemDragStart($event)"
                    [cdkDragLockAxis]="'y'"
                    [cdkDragData]="{}"
                  >
                    + Posten
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="calc-labels-group"
          id="groups-drag-container"
          cdkDropList
          cdkDropListGroup
          (cdkDropListDropped)="dropGroupEntity($event)"
          [cdkDropListDisabled]="(metaCalculationService.editing$ | async) === false"
        >
          <div
            *ngFor="let item of metaCalculationService.items$ | async; trackBy: trackById; let i = index"
            cdkDrag
            [cdkDragDisabled]="item.sortOrder === 0"
            cdkDragPreviewClass="calc-labels-drag-preview"
            [cdkDragLockAxis]="'y'"
            [cdkDragData]="item"
          >
            <div
              class="calc-labels-childs"
              [ngClass]="{ 'calc-labels-childs-placeholder': item?.children?.length === 0 }"
              [id]="'childs-drag-container' + item.typId"
              cdkDropList
              (cdkDropListDropped)="dropChildEntity($event, i)"
              [cdkDropListDisabled]="(metaCalculationService.editing$ | async) === false"
            >
              <div
                *ngFor="let child of item.children; trackBy: trackById; let j = index"
                class="calc-labels-cell calc-indent"
                cdkDrag
                cdkDragPreviewClass="list-input-drag-preview"
                [cdkDragLockAxis]="'y'"
                [cdkDragData]="child"
              >
                <div class="row-handler-wrapper" *ngIf="metaCalculationService.editing$ | async">
                  <div class="drag-handler" cdkDragHandle>
                    <i class="fal fa-bars"></i>
                  </div>
                </div>
                <span class="calc-labels-counter">{{ child.calcType === "add" ? "+" : "-" }} </span>
                <meta-input
                  [maParams]="{
                    placeholder: 'Bezeichnung',
                    editing: metaCalculationService.editing$ | async
                  }"
                  [(ngModel)]="metaCalculationService.itemModel['title' + child.typId]"
                  [style.width.%]="100"
                ></meta-input>
                <div class="calc-labels-actions" *ngIf="metaCalculationService.editing$ | async">
                  <meta-popover
                    [maParams]="{
                      content: itemSettingsTemplate,
                      modalWidth: '250px',
                      position: 'right',
                      iconStyle: 'fas',
                      type: 'link',
                      icon: 'cog',
                      label: 'Einstellungen'
                    }"
                    (click)="metaCalculationService.initItemForm(j, i)"
                  ></meta-popover>
                  <ng-template #itemSettingsTemplate>
                    <meta-field-wrapper
                      [maParams]="{
                        label: 'Berechnungsart wählen',
                        layout: 'vertical'
                      }"
                    >
                      <meta-select
                        [maParams]="{
                          data: metaCalculationService.calcTypes,
                          editing: metaCalculationService.editing$ | async,
                          placeholder: 'Berechnungsart wählen'
                        }"
                        (ngModelChange)="metaCalculationService.onCalcTypeChange($event, j, i)"
                        [(ngModel)]="metaCalculationService.itemSettingsModel.calcType"
                      ></meta-select>
                    </meta-field-wrapper>
                    <meta-field-wrapper
                      [maParams]="{
                        label: 'Wertart wählen',
                        layout: 'vertical'
                      }"
                    >
                      <meta-select
                        [maParams]="{
                          data: metaCalculationService.valueTypes,
                          editing: metaCalculationService.editing$ | async,
                          placeholder: 'Wertart wählen'
                        }"
                        (ngModelChange)="metaCalculationService.onValueTypeChange($event, j, i)"
                        [(ngModel)]="metaCalculationService.itemSettingsModel.valueType"
                      ></meta-select>
                    </meta-field-wrapper>
                  </ng-template>
                  <meta-button
                    [maParams]="{
                      icon: 'trash',
                      iconStyle: 'fas',
                      danger: true,
                      type: 'link',
                      size: 'small'
                    }"
                    (click)="metaCalculationService.removeItem(j, i)"
                  ></meta-button>
                </div>
              </div>
            </div>
            <div
              class="calc-labels-cell calc-strong calc-alt"
              [ngClass]="{ 'calc-double-line': item.groupType === 'major' }"
            >
              <div *ngIf="(metaCalculationService.editing$ | async) && item.sortOrder > 0" class="row-handler-wrapper">
                <div class="drag-handler" cdkDragHandle>
                  <i class="fal fa-bars"></i>
                </div>
              </div>
              <span class="calc-labels-counter">= </span>
              <meta-input
                [maParams]="{
                  placeholder: 'Bezeichnung',
                  editing: metaCalculationService.editing$ | async
                }"
                [(ngModel)]="metaCalculationService.itemModel['title' + item.typId]"
                [style.width.%]="100"
              ></meta-input>
              <div class="calc-labels-actions" *ngIf="metaCalculationService.editing$ | async">
                <meta-popover
                  *ngIf="i !== 0"
                  [maParams]="{
                    content: groupSettingsTemplate,
                    modalWidth: '175px',
                    position: 'right',
                    iconStyle: 'fas',
                    type: 'link',
                    icon: 'cog',
                    label: 'Einstellungen'
                  }"
                  (click)="metaCalculationService.initGroupForm(i)"
                ></meta-popover>
                <ng-template #groupSettingsTemplate>
                  <meta-field-wrapper
                    [maParams]="{
                      inputWidth: 4,
                      labelWidth: 20,
                      label: 'Hauptgruppe'
                    }"
                  >
                    <meta-checkbox
                      [maParams]="{
                        editing: metaCalculationService.editing$ | async
                      }"
                      (ngModelChange)="metaCalculationService.onGroupTypeChange($event, i)"
                      [(ngModel)]="metaCalculationService.groupSettingsModel.groupType"
                    ></meta-checkbox>
                  </meta-field-wrapper>
                </ng-template>
                <meta-button
                  *ngIf="i !== 0"
                  [maParams]="{
                    icon: 'trash',
                    iconStyle: 'fas',
                    type: 'link',
                    danger: true,
                    size: 'small'
                  }"
                  (click)="metaCalculationService.removeGroup(i)"
                ></meta-button>
              </div>
            </div>
            <meta-section
              *ngIf="item.groupType === 'major'"
              [maParams]="{
                sectionType: 'stripes'
              }"
            ></meta-section>
          </div>
        </div>
      </div>
      <div class="calc-position">
        <div class="calc-position-wrapper">
          <div
            class="calc-position-header"
            *ngIf="metaCalculationService.editing$ | async"
            [style.height.px]="130"
            [style.min-height.px]="130"
          ></div>
          <ng-container *ngFor="let item of metaCalculationService.items$ | async; trackBy: trackById; let j = index">
            <div
              class="calc-position-childs"
              [ngClass]="{ 'calc-position-childs-placeholder': item?.children?.length === 0 }"
            >
              <div
                *ngFor="let child of item.children; trackBy: trackById; let k = index"
                class="calc-position-cell calc-position-item"
              >
                <div class="calc-position-input">
                  <meta-skeleton
                    *ngIf="metaCalculationService.isLoading$ | async"
                    [maParams]="{
                      loading: true,
                      avatar: false
                    }"
                  ></meta-skeleton>
                  <meta-input
                    *ngIf="(metaCalculationService.isLoading$ | async) === false"
                    [maParams]="{
                      suffix: child.valueType === 'currency' ? 'Eur' : '%',
                      inputType: child.valueType,
                      editing: metaCalculationService.editing$ | async
                    }"
                    [(ngModel)]="metaCalculationService.itemModel['value' + child.typId]"
                  ></meta-input>
                </div>
                <div class="calc-position-output">
                  <span
                    >{{ child.calcType === "add" ? "Aufschlag" : "Abschlag" }}
                    {{ child.valueType === "currency" ? "absolut" : "prozentual" }}
                  </span>
                </div>
              </div>
            </div>
            <div
              *ngIf="item.sortOrder !== 0"
              class="calc-position-cell calc-position-item calc-strong calc-alt"
              [ngClass]="{ 'calc-double-line': item.groupType === 'major' }"
            >
              <div class="calc-position-input"></div>
            </div>
            <div
              *ngIf="item.sortOrder === 0"
              class="calc-position-cell calc-position-item calc-strong calc-alt"
              [ngClass]="{ 'calc-double-line': item.groupType === 'major' }"
            >
              <div class="calc-position-input"></div>
            </div>
            <meta-section *ngIf="item.groupType === 'major'" [maParams]="{ sectionType: 'stripes' }"></meta-section>
          </ng-container>
        </div>
      </div>
    </div>
  `,
  styleUrls: ["metaCalculation.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [MetaCalculationService],
})
export class MetaCalculationTemplatesComponent extends MetaComponentBase implements OnInit, OnDestroy {
  public modalActionTarget = modalActionTarget;
  public itemModelCopy: any = {};
  public positionsModelCopy: any = {};
  public groupSettingsModelCopy: any = {};
  public itemSettingsModelCopy: any = {};
  public connectedGroups: string[] = [];
  public connectecItems: string[] = [];
  public height: number;
  public newVersion: boolean;
  public toolbarLabelTarget = "meta-subform-label-container";
  public toolbarActionTarget = "meta-subform-actions-container";
  public basewidth = 283;
  public isLoadingEditMode: boolean;
  public calculationId: number;
  private _destroyed$ = new Subject<void>();
  private _prevPositions: string;

  constructor(
    _changeDetectorRef: ChangeDetectorRef,
    _metaHelperService: MetaHelperService,
    public metaCalculationService: MetaCalculationService,
    private readonly _dirtyCheckService: DirtyCheckService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _metaEventService: MetaEventService,
    @Optional() private readonly _modalRef: NzModalRef,
  ) {
    super();
    super.maParams = new MetaCalculation();
  }

  async ngOnInit() {
    this.metaCalculationService.parentFormId = this.ma.parentFormId;
    this.metaCalculationService.sourceId = this.formState.itemId();

    this._activatedRoute.params.pipe(takeUntil(this._destroyed$)).subscribe({
      next: (params) => {
        if (params.itemId === "create") {
        } else {
          this.metaCalculationService.getTemplate(params.itemId);
          this.calculationId = Number(params.itemId);
        }
      },
    });

    this.formState.state$
      .pipe(
        takeUntil(this._destroyed$),
        filter((x: MetaState) => x !== undefined),
      )
      .subscribe({
        next: (state: MetaState) => {
          if (state >= MetaState.editing && state <= MetaState.saving) {
            this.edit();
          }
        },
      });

    this._metaEventService.saveTrigger$
      .pipe(
        takeUntil(this._destroyed$),
        filter((x: any) => x.formId === this.formState.formId),
      )
      .subscribe({
        next: (trigger) => {
          this.handleSave(Number(trigger.itemId));
        },
      });

    this._metaEventService.cancelTrigger$
      .pipe(
        takeUntil(this._destroyed$),
        filter((x: any) => x.formId === this.formState.formId),
      )
      .subscribe({
        next: (trigger) => {
          this.handleCancel();
        },
      });

    this._dirtyCheckService.registerObject(
      this,
      () => {
        return this.metaCalculationService.editing$.value;
      },
      this._modalRef,
    );
  }

  ngOnDestroy() {
    this._dirtyCheckService.unregisterObject(this);
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public trackById(index, item) {
    return item.id;
  }

  public async dropGroupEntity(event: any) {
    if (event.currentIndex > 0) {
      const items = this.metaCalculationService.items$.getValue();
      if (event.previousContainer === event.container) {
        const f = items.splice(event.previousIndex, 1)[0];
        items.splice(event.currentIndex, 0, f);
      } else {
        if (!event.previousContainer.data) {
          this.metaCalculationService.addGroup("Neue Gruppe", event.currentIndex);
        } else {
          transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex,
          );
        }
      }
      items.forEach((item, i) => {
        item.sortOrder = i;
      });
      this.metaCalculationService.items$.next(items);
      this.metaCalculationService._updateSums();
      //this.metaCalculationService.itemForm.markAsDirty();
    }
  }

  public async dropChildEntity(event: any, parentIndex: number) {
    const items = this.metaCalculationService.items$.getValue();
    if (event.previousContainer === event.container) {
      const f = items[parentIndex].children.splice(event.previousIndex, 1)[0];
      items[parentIndex].children.splice(event.currentIndex, 0, f);
    } else if (event.previousContainer.id.indexOf("cdk-drop-list") > -1) {
      if (!event.previousContainer.data) {
        this.metaCalculationService.addItem("Neuer Posten", event.currentIndex, parentIndex);
      } else {
        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      }
    } else {
      const prevId = event.previousContainer.id.substr(21, 100);
      const nextId = event.container.id.substr(21, 100);
      const prevItemIndex = this.metaCalculationService.items$.getValue().findIndex((item) => item.typId === prevId);
      const nextItemIndex = this.metaCalculationService.items$.getValue().findIndex((item) => item.typId === nextId);
      const f = items[prevItemIndex].children.splice(event.previousIndex, 1)[0];
      items[nextItemIndex].children.splice(event.currentIndex, 0, f);
    }
    items.forEach((item, i) => {
      if (item.children) {
        item.children.map((child, j) => {
          child.sortOrder = j;
        });
      }
      item.sortOrder = i;
    });
    this.metaCalculationService.items$.next(items);
    this.metaCalculationService._updateSums();
    //this.metaCalculationService.itemForm.markAsDirty();
    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  public onGroupDragStart(event: any) {
    this.connectecItems = [];
    this.connectedGroups = ["groups-drag-container"];
  }

  public onItemDragStart(event: any) {
    this.connectedGroups = [];
    this.connectecItems = this.metaCalculationService.items$.getValue().map((o) => `childs-drag-container${o.typId}`);
  }

  public onValueChange(positionIndex: number, subPositionIndex?: number) {
    setTimeout(() => {
      const items = this.metaCalculationService.items$.getValue();
      items.forEach((group, i) => {
        if (group.children) {
          group.children.forEach((child) => {
            if (child.valueType === "percent") {
              if (subPositionIndex === undefined) {
                this.metaCalculationService.percentOf(
                  this.metaCalculationService.positionsModel[positionIndex][`value${child.typId}_${positionIndex}`],
                  positionIndex,
                  i,
                  child,
                );
              } else {
                this.metaCalculationService.percentOf(
                  this.metaCalculationService.positionsModel[positionIndex][
                    `value${child.typId}_${positionIndex}_${subPositionIndex}`
                  ],
                  positionIndex,
                  i,
                  child,
                  subPositionIndex,
                );
              }
            }
            if (child.valueType === "currency") {
              if (subPositionIndex === undefined) {
                this.metaCalculationService.division(
                  this.metaCalculationService.positionsModel[positionIndex][`value${child.typId}_${positionIndex}`],
                  positionIndex,
                  i,
                  child,
                );
              } else {
                this.metaCalculationService.division(
                  this.metaCalculationService.positionsModel[positionIndex][
                    `value${child.typId}_${positionIndex}_${subPositionIndex}`
                  ],
                  positionIndex,
                  i,
                  child,
                  subPositionIndex,
                );
              }
            }
          });
        }
      });
    });
  }

  public edit() {
    this.itemModelCopy = _.cloneDeep(this.metaCalculationService.itemModel);
    this.positionsModelCopy = _.cloneDeep(this.metaCalculationService.positionsModel);
    this.groupSettingsModelCopy = _.cloneDeep(this.metaCalculationService.groupSettingsModel);
    this.itemSettingsModelCopy = _.cloneDeep(this.metaCalculationService.itemSettingsModel);
    this.metaCalculationService.editing$.next(true);
  }

  public handleSave(itemId: number) {
    this.metaCalculationService.saving$.next(true);
    this.metaCalculationService.updateTemplate(itemId);
    this.metaCalculationService.editing$.next(false);
    this.isLoadingEditMode = false;
  }

  public handleCancel() {
    this.metaCalculationService.itemModel = _.cloneDeep(this.itemModelCopy);
    this.metaCalculationService.positionsModel = _.cloneDeep(this.positionsModelCopy);
    this.metaCalculationService.groupSettingsModel = _.cloneDeep(this.groupSettingsModelCopy);
    this.metaCalculationService.itemSettingsModel = _.cloneDeep(this.itemSettingsModelCopy);
    this.metaCalculationService.editing$.next(false);
  }
}

@NgModule({
  declarations: [MetaCalculationTemplatesComponent],
  imports: [
    CommonModule,
    FormlyModule,
    MetaInfoboxModule,
    DragDropModule,
    MetaButtonModule,
    MetaPopoverModule,
    NzGridModule,
    NzToolTipModule,
    FormsModule,
    NzPopoverModule,
    PipesModule,
    MetaToolbarModule,
    MetaSectionModule,
    MetaDrodpownModule,
    MetaSelectModule,
    MetaInputModule,
    MetaFieldWrapperModule,
    MetaCheckboxModule,
    MetaAvatarModule,
    MetaEmptyModule,
    MetaSkeletonModule,
  ],
  exports: [MetaCalculationTemplatesComponent],
})
export class MetaCalculationTemplatesModule {}
