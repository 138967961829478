/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import * as _ from "lodash";
import { NzPipesModule } from "ng-zorro-antd/pipes";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";

class MetaWebview {
  link: string;
  sandbox: boolean;
  height: string;
}

@Component({
  standalone: true,
  template: `
    <ng-container *ngIf="getLink() as src">
      <ng-container *ngIf="ma.sandbox; else noSandboxTpl">
        <iframe
          [style.height.px]="ma.height"
          sandbox="allow-scripts allow-popups allow-forms allow-modals"
          referrerpolicy="no-referrer"
          [src]="src | nzSanitizer: 'resourceUrl'"
        ></iframe>
      </ng-container>
      <ng-template #noSandboxTpl>
        <iframe
          [style.height.px]="ma.height"
          referrerpolicy="no-referrer"
          *ngIf="src"
          [src]="src | nzSanitizer: 'resourceUrl'"
        ></iframe>
      </ng-template>
    </ng-container>
  `,
  imports: [NzPipesModule, CommonModule],
  styleUrls: ["./metaWebView.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@MetaUnsubscribe()
export class MetaWebViewComponent extends MetaComponentBase {
  constructor(_changeDetectorRef: ChangeDetectorRef, _metaHelperService: MetaHelperService) {
    super();
    super.maParams = new MetaWebview();
  }

  get ma(): MetaWebview {
    return super.ma;
  }

  public getLink() {
    return this.field ? _.get(this.formState, ["displayResult", this.field.id, "link"], this.ma.link) : this.ma.link;
  }
}
