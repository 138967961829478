/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */
import { Injectable } from "@angular/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ConfirmActionHandler {
  constructor(private _modal: NzModalService) {}

  async handle(action: any) {
    const confirm = this._modal.confirm({
      nzTitle: action.label,
      nzContent: action.content,
      nzCancelText: action.cancelLabel,
      nzOkText: action.okLabel,
      nzClosable: false,
      nzModalType: "confirm",
      nzOnOk: () => true,
    });

    const resp = await firstValueFrom(confirm.afterClose);
    return resp;
  }
}
