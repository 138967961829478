/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Injectable, signal } from "@angular/core";
import { IUserFeatures } from "@meta/api-interfaces";
import { BehaviorSubject, firstValueFrom, Observable } from "rxjs";
import { filter, map, retry, shareReplay, tap } from "rxjs/operators";
import { plainToInstance } from "class-transformer";
import { HttpClient } from "@angular/common/http";
import * as Sentry from "@sentry/angular-ivy";
import { MetaAppService } from "./metaApp.service";

export class UserInfoResponse {
  id: string;
  vorName: string;
  nachName: string;
  administrator: boolean;
  standort: string;
  email: string;
  company: string;
  lang: string;
  isoLang: string;
  locale: string;
  showTwoFactorSetup?: boolean;
  werk: {
    id: string;
    name: string;
  };
  appSettings: Record<string, any>;
  primaryUser?: string;
}

export type UserInfoResponseType = UserInfoResponse & Record<string, any>;

@Injectable({
  providedIn: "root",
})
export class UserService {
  public readonly user: BehaviorSubject<UserInfoResponseType> = new BehaviorSubject(null);

  public readonly userSignal = signal<UserInfoResponseType>(null);

  public readonly userFeatures = signal<IUserFeatures>(null);

  private requestInProgress: boolean;

  constructor(
    private _httpClient: HttpClient,
    private readonly _metaAppRepository: MetaAppService,
  ) {}

  public setUser(user: UserInfoResponse): UserInfoResponseType {
    Sentry.setUser({ email: user.email, id: user.id, username: user.email || user.id, primaryUser: user.primaryUser });
    this.user.next(user);
    this.userSignal.set(user);
    this.loadUserFeatures();
    if (user.appSettings) {
      this._metaAppRepository.setAppSettings(
        {
          ...user.appSettings,
          menuCollapsed: user.appSettings.menuCollapsed === "true",
        },
        false,
      );
    }
    return user;
  }

  private loadUserFeatures() {
    firstValueFrom(this._httpClient.get<IUserFeatures>("public/features"))
      .then((r) => {
        this.userFeatures.set(r);
      })
      .catch(() => {
        this.userFeatures.set(null);
      });
  }

  public getUser(): Observable<UserInfoResponseType> {
    if (!this.user.value && !this.requestInProgress) {
      this.retrieveUser().subscribe({ next: () => {}, error: () => {} });
    }
    return this.user.asObservable().pipe(
      filter((user) => user !== null),
      shareReplay(),
    );
  }

  public async isAuth() {
    if (!this.user.value) {
      try {
        return await firstValueFrom(this.retrieveUser()).then((e) => !!e);
      } catch (e) {
        return false;
      }
    }
    return !!this.user.value;
  }

  // ===========================================================================
  // Gets all users from the plattform

  public async getUsers(skip = 0, take = 1000): Promise<any> {
    return this._httpClient.get(`activities/users?skip=${skip}&take=${take}`).toPromise();
  }

  public setChangeLogRef(cRef: string) {
    this._metaAppRepository.setAppSettings(
      {
        cRef,
      },
      true,
    );
  }

  private retrieveUser(): Observable<UserInfoResponseType> {
    this.requestInProgress = true;
    return this._httpClient.get<UserInfoResponseType>("/auth/user").pipe(
      tap((userResult: UserInfoResponseType) => {
        const user = plainToInstance(UserInfoResponse, userResult);
        this.requestInProgress = false;
        this.setUser(user);
      }),
      retry(3),
      shareReplay(),
    );
  }
}
