/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component } from "@angular/core";
import { MetaMediaLibraryService } from "../metaMediaLibrary.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalRef } from "ng-zorro-antd/modal";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
import { IMetaMediaLibraryFile } from "../interfaces";

@Component({
  styleUrls: ["metaMediaLibraryDelete.component.less"],
  template: `
    <h3>Möchtest du diese Datei wirklich löschen?</h3>
    <p>Diese Datei hat "{{ file?.links?.length }}" zuordnungen.</p>
    <ul>
      @for (l of file.links; track $index) {
        <li>
          <meta-live-card cardSize="small" [cardType]="l.type" [cardId]="l.id" />
        </li>
      }
    </ul>
  `,
})
export class MetaMediaLibraryDeleteComponent {
  public fielId: string;
  public file: IMetaMediaLibraryFile;

  constructor(
    private readonly service: MetaMediaLibraryService,
    private readonly messageService: NzMessageService,
    private readonly modalRef: NzModalRef,
    private readonly httpClient: HttpClient,
  ) {}

  public async delete() {
    if (this.file) {
      try {
        await firstValueFrom(this.httpClient.delete("file/" + this.file.id));
      } catch (e) {
        this.messageService.error("Datei konnte nicht gelöscht werden.");
      }
    }
  }

  ngOnInit(): void {
    if (!this.file && this.fielId) {
      this.service.getFile(this.fielId).then((file) => {
        this.file = file;
      });
    }
    this.modalRef.updateConfig({
      nzOkText: "Datei Löschen",
      nzOkDanger: true,
      nzOnOk: async () => {
        await this.delete();
        return { success: true };
      },
      nzOnCancel: () => {
        return { success: false };
      },
    });
  }
}
