/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { MetaState } from "@meta/enums";
import { MetaPrintService } from "../../../components/meta-print-modal/meta-print.service";
import { MetaFormService } from "../metaForm.service";

@Component({
  selector: "meta-form-report",
  template: ` <div nz-row>
    <div nz-col class="report-wrapper">
      <div
        class="report-inner"
        [style.width]="metaFormService.formState.form().format === 'fullWidth' ? '100%' : 'auto'"
      >
        <div
          class="page"
          [attr.format]="metaFormService.formState.form().format"
          [attr.layout]="metaFormService.formState.form().layout"
          [style.position]="'sticky'"
          [style.top.px]="80"
        >
          <ng-container *ngIf="metaFormService.formState.form().isHtmlReport">
            <meta-document-view
              [maParams]="{
                formId: metaFormService.formState.formId,
                itemId: metaFormService.formState.itemId(),
                pdfOptions: maPdfOptions
              }"
            ></meta-document-view>
          </ng-container>
          <meta-form-base></meta-form-base>
        </div>
        <div nz-col class="report-panel" *ngIf="!metaFormService.formState.form().hideActivityPanel">
          <nz-tabset [nzAnimated]="false">
            <nz-tab *ngIf="!maHideActivity" nzTitle="Aktivitäten">
              <ng-template nz-tab>
                <formly-form
                  [fields]="metaFormService.formState.form().activity"
                  [options]="metaFormService.options"
                ></formly-form>
              </ng-template>
            </nz-tab>
            <nz-tab
              *ngIf="metaFormService.formState.form().printable || metaFormService.formState.form().sendable"
              nzTitle="Drucken / Versenden"
              [nzDisabled]="
                metaFormService.formState.state() >= MetaFormDataState.editing &&
                metaFormService.formState.state() <= MetaFormDataState.saving
              "
              [nzForceRender]="true"
            >
              <ng-template nz-tab>
                <nz-row class="mb-4" [style.flex]="0">
                  <nz-col [nzSpan]="5">
                    <meta-icon [maParams]="{ icon: 'yatdpoii', size: 60, delay: 3000 }"></meta-icon>
                  </nz-col>
                  <nz-col [nzSpan]="19"
                    >Druckt das aktuelle Dokument auf dem im Browser hinterlegten Standarddrucker aus. Vorhandene
                    Wasserzeichen bleiben dabei erhalten.
                  </nz-col>
                </nz-row>
                <meta-button
                  *ngIf="metaFormService.formState.form().printable"
                  class="mb-3"
                  [maParams]="{
                    icon: 'print',
                    label: 'Dokument drucken',
                    type: 'primary',
                    fullWidth: true
                  }"
                  (click)="
                    printService.printReport(metaFormService.formState.formId, metaFormService.formState.itemId())
                  "
                ></meta-button>
                <meta-section
                  *ngIf="metaFormService.formState.form().printable && metaFormService.formState.form().sendable"
                  [maParams]="{
                    sectionType: 'hSeparator',
                    label: 'oder'
                  }"
                ></meta-section>
                <nz-row class="mb-4" [style.flex]="0">
                  <nz-col [nzSpan]="5">
                    <meta-icon [maParams]="{ icon: 'imkozzwk', size: 60, delay: 3000 }"></meta-icon>
                  </nz-col>
                  <nz-col [nzSpan]="19"
                    >Versendet das aktuelle Dokument als PDF-Anhang an den / die Empfänger. Vorhandene Wasserzeichen
                    bleiben dabei erhalten.
                  </nz-col>
                </nz-row>
                <meta-field-wrapper
                  [maParams]="{
                    layout: 'vertical',
                    label: 'Empfänger',
                    required: true
                  }"
                >
                  <meta-input [(ngModel)]="sendMailModel.recipient" [maParams]="{ editing: true }"></meta-input>
                </meta-field-wrapper>
                <nz-row [nzGutter]="6" [style.flex]="0">
                  <nz-col [nzSpan]="12">
                    <meta-field-wrapper
                      [maParams]="{
                        layout: 'vertical',
                        label: 'Kopie an'
                      }"
                    >
                      <meta-input [(ngModel)]="sendMailModel.cc" [maParams]="{ editing: true }"></meta-input>
                    </meta-field-wrapper>
                  </nz-col>
                  <nz-col [nzSpan]="12">
                    <meta-field-wrapper
                      [maParams]="{
                        layout: 'vertical',
                        label: 'Blindkopie an'
                      }"
                    >
                      <meta-input [(ngModel)]="sendMailModel.bcc" [maParams]="{ editing: true }"></meta-input>
                    </meta-field-wrapper>
                  </nz-col>
                </nz-row>
                <meta-field-wrapper
                  [maParams]="{
                    layout: 'vertical',
                    label: 'Betreff',
                    required: true
                  }"
                >
                  <meta-input [(ngModel)]="sendMailModel.subject" [maParams]="{ editing: true }"></meta-input>
                </meta-field-wrapper>
                <meta-field-wrapper
                  [maParams]="{
                    layout: 'vertical',
                    label: 'Nachricht',
                    required: true
                  }"
                >
                  <meta-editor [(ngModel)]="sendMailModel.body" [maParams]="{ editing: true }"></meta-editor>
                </meta-field-wrapper>
                <meta-button
                  *ngIf="metaFormService.formState.form().printable"
                  [maParams]="{
                    icon: 'envelope',
                    label: 'Dokument versenden',
                    type: 'primary',
                    fullWidth: true,
                    loading: isSendingMail
                  }"
                  (click)="onSendMail()"
                ></meta-button>
              </ng-template>
            </nz-tab>
            <nz-tab
              nzTitle="Einstellungen"
              [nzDisabled]="
                metaFormService.formState.state() >= MetaFormDataState.editing &&
                metaFormService.formState.state() <= MetaFormDataState.saving
              "
            >
              <ng-template nz-tab>
                <meta-field-wrapper [maParams]="{ label: 'Sprache' }">
                  <meta-select
                    [(ngModel)]="metaFormService.pdfPreviewOptions.locale"
                    (ngModelChange)="
                      metaFormService.formState.pdfPreviewOptions.next(metaFormService.pdfPreviewOptions)
                    "
                    [maParams]="{
                      loadDataOnInit: true,
                      selectDataUrl: '/shared/languages',
                      editing: true,
                      clearable: true
                    }"
                  ></meta-select>
                </meta-field-wrapper>
              </ng-template>
            </nz-tab>
          </nz-tabset>
        </div>
      </div>
    </div>
  </div>`,
  styleUrls: ["../metaForm.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaFormReportComponent implements OnInit {
  @Input() maPdfOptions?: {
    toolbar?: string;
    fit?: string;
    navPanes?: string;
    pageMode?: string;
    additionalData?: Record<string, any>;
  };
  @Input() public maHideActivity? = false;

  public sendMailModel = {
    recipient: null,
    cc: null,
    bcc: null,
    subject: null,
    body: null,
  };
  public isSendingMail: boolean;
  protected readonly MetaFormDataState = MetaState;

  constructor(
    public readonly metaFormService: MetaFormService,
    public readonly printService: MetaPrintService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {}

  public async onSendMail() {
    this.isSendingMail = true;
    await this.metaFormService.sendReport({
      formId: this.metaFormService.formState.formId,
      itemId: this.metaFormService.formState.itemId(),
      to: this.sendMailModel.recipient,
      bcc: this.sendMailModel.bcc,
      cc: this.sendMailModel.cc,
      subject: this.sendMailModel.subject,
      body: this.sendMailModel.body,
      additionalData: this.maPdfOptions.additionalData,
    });
    this.isSendingMail = false;
    this._changeDetectorRef.markForCheck();
  }
}
