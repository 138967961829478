/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { Component, Input, OnInit } from "@angular/core";
import { IFeedResultItem } from "@meta/api-interfaces";
import { MetaHelperService } from "../../../services/metaHelper.service";
export class MetaTimelineTemplateText {
  data: IFeedResultItem<{
    Title: string;
    Beschreibung: string;
  }>;
}
@Component({
  selector: "meta-timeline-template-text",
  template: `
    <b [innerHTML]="item.data.data.Title" *ngIf="item.data?.data?.Title"></b>
    <br />

    <meta-live-card
      [cardLazyLoad]="true"
      [cardType]="item.data.entity.type"
      cardSize="link"
      [cardId]="item.data.entity.id"
      [cardData]="item.data.cardData"
    ></meta-live-card>

    <p *ngIf="item.data?.data?.Beschreibung" [innerHTML]="item.data?.data?.Beschreibung"></p>

    <ng-template #tplLabel><i class="far fa-{{ item.data.entity.icon }}"></i>{{ item.data.entity.title }}</ng-template>
  `,
})
export class MetaTimelineTemplateTextComponent {
  @Input() public item: MetaTimelineTemplateText;
}
