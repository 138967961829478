<!--
  ~ Copyright (C) MetaCarp GmbH - All Rights Reserved
  ~ Unauthorized copying of this file, via any medium is strictly prohibited
  ~ Proprietary and confidential
  ~ Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
  ~ Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
  -->

<h1>
  <i class="fal fa-print"></i> Druckvorschau</h1>
<div class="print-dialog">
  @if (previewUrl(); as src) {
    <iframe [ngClass]="{bw: printOptions?.value?.bw}" class="preview"  [src]="src"></iframe>
  }
  <div class="options-wrapper">
    <div class="options">
      <form [formGroup]="printOptions" nz-form>
        <meta-field-wrapper [maParams]="{label: 'Drucker'}">
          <nz-select
            style="width: 100%"
            formControlName="printer"
            nzPlaceHolder="Drucker auswählen…"
            [nzOptions]="printers"
          >
          </nz-select>
        </meta-field-wrapper>
        <meta-field-wrapper [maParams]="{label: 'Kopien'}">
          <meta-input formControlName="copies" [maParams]="{editing: true,inputType: 'number', min: 1, max: 100}"></meta-input>
        </meta-field-wrapper>

        <meta-field-wrapper [maParams]="{label: 'Schwarz-Weiß'}">
          <meta-switcher formControlName="bw" [maParams]="{editing: true}"></meta-switcher>
        </meta-field-wrapper>
        <meta-field-wrapper [maParams]="{label: 'Beidseitig'}">
          <meta-switcher formControlName="duplex" [maParams]="{editing: true}"></meta-switcher>
        </meta-field-wrapper>
        <ng-container *ngIf="isImage">
          <meta-field-wrapper  *ngIf="printOptions.value.type === 'file'" [maParams]="{label: 'Querformat'}">
            <meta-switcher formControlName="landscape" [maParams]="{editing: true}"></meta-switcher>
          </meta-field-wrapper>
          <meta-field-wrapper *ngIf="printOptions.value.type === 'file'" [maParams]="{label: 'Papiergröße'}">
            <meta-select formControlName="pageSize" [maParams]="{data: paperSizes,editing: true}"></meta-select>
          </meta-field-wrapper>
        </ng-container>
        <meta-field-wrapper [maParams]="{label: 'Fach'}">
          <nz-select
            style="width: 100%"
            formControlName="bin"
            nzPlaceHolder="Fach auswählen…"
            [nzOptions]="bins"
          >
          </nz-select>
        </meta-field-wrapper>
      </form>
    </div>
    <div class="footer">
      <meta-button (click)="print()" [maParams]="{label: 'Drucken', type: 'primary', icon: 'print', disabled: printOptions.invalid}"></meta-button>
      <meta-button (click)="printLocal()" [maParams]="{label: 'Im Browser Drucken', type: 'default', icon: 'print'}"></meta-button>
      <meta-button (click)="cancel()" [maParams]="{label: 'Abbrechen', type: 'default'}"></meta-button>
    </div>
  </div>

</div>
