/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import {
  FormlySanitizeTemplate,
  MetaFormComponent,
  MetaFormComponentInfo,
  MetaFormComponentProperty,
} from "./baseComponent";
import { MetaFormLayoutComponent } from "./layoutComponent";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";
import { MetaFormButtonComponent } from "./buttonComponent";

@MetaFormComponentInfo({
  selector: "ma-group",
  type: "group",
  name: "Group",
  icon: "object-group",
  palletGroup: "layout",
})
export class GroupComponent<Sources = {}> extends MetaFormLayoutComponent {
  @MetaFormComponentProperty({
    name: "Label",
    type: "text",
    default: "Neue Gruppe",
    isTemplate: true,
    tab: "general",
  })
  public label: string;
  @MetaFormComponentProperty({
    name: "Kopf nicht anzeigen",
    type: "boolean",
    tab: "style",
  })
  public noHeader = false;
  @MetaFormComponentProperty({
    name: "Hat Padding",
    type: "boolean",
    tab: "style",
  })
  public hasPadding = true;

  @MetaFormComponentProperty({
    name: "Aktionen",
    type: "children",
    allowedChildren: ["button"],
    tab: "general",
  })
  public actions: MetaFormButtonComponent[] = [];

  @MetaFormComponentProperty({
    name: "Group Style",
    type: "select",
    tab: "style",
    default: null,
    values: [
      {
        label: "-",
        value: null,
      },
      {
        label: "Borderless",
        value: "borderless",
      },
      {
        label: "Transparent",
        value: "transparent",
      },
    ],
  })
  public groupStyle: "borderless" | "transparent";
  @MetaFormComponentProperty({
    name: "Sub Label",
    type: "text",
    isTemplate: true,
    tab: "general",
  })
  public sublabel?: string;

  @MetaFormComponentProperty({
    name: "Children",
    type: "children",
    tab: "other",
  })
  public children: MetaFormComponent[] = [];

  public parentId: string = null;
  public static create<T>(opts: Partial<GroupComponent>) {
    const input = new GroupComponent();
    Object.assign(input, opts);
    return input;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        label: config.sanitizeTemplates ? FormlySanitizeTemplate(this.label) : this.label,
        groupStyle: this.groupStyle,
        actions: (this.actions || []).map((a) => a.toFormly(config)),
      },
    });
  }
}
