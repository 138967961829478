/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { Injectable } from "@angular/core";
import { MetaEventService, SetParamTriggerEvent } from "../../../services/metaEvents.service";

@Injectable({
  providedIn: "root",
})
export class SetParamsActionHandler {
  constructor(private readonly _metaEventService: MetaEventService) {}

  async handle(action: SetParamTriggerEvent) {
    this._metaEventService.emitSetParamTrigger(action);
  }
}
