/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, Input, OnInit } from "@angular/core";
import { MetaHelperService } from "../../../services/metaHelper.service";
import { IFeedResultItem } from "@meta/api-interfaces";

export class MetaTimelineTemplateBulkEdit {
  data: IFeedResultItem;
}

@Component({
  selector: "meta-timeline-template-bulk-edit",
  template: `{{ item.data.entity.name }}:
    <a [href]="item.data.entity.link">
      <meta-tag [maParams]="{ label: tplLabel, color: color, maxWidth: 250 }"></meta-tag> </a
    >.

    <ng-template #tplLabel
      ><i class="far fa-{{ item.data.entity.icon }}"></i>{{ item.data.entity.title }}</ng-template
    >`,
})
export class MetaTimelineTemplateBulkEditComponent implements OnInit {
  @Input() public item: MetaTimelineTemplateBulkEdit;

  public color: string;
  public colorText: string;

  constructor(public metaHelperService: MetaHelperService) {}

  ngOnInit() {
    this.color = this.metaHelperService.generateColor(this.item.data.entity.type, 1);
    this.colorText = getComputedStyle(document.documentElement).getPropertyValue("--component-background");
  }
}
