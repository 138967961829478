/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { Pipe } from "@angular/core";
import { marked } from "marked";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

class ArgonCard extends HTMLElement {
  public static cardData: Record<string, any> = [];
  // connect component
  connectedCallback() {
    this.innerHTML = `Loading…`;
    this.load();
  }

  private load() {
    const c = `${this.dataset["type"]}-${this.dataset["id"]}}`;
    if (!ArgonCard.cardData[c]) {
      ArgonCard.cardData[c] = fetch(`/api/v2/entity/cardData/${this.dataset["type"]}/${this.dataset["id"]}`).then((r) =>
        r.json(),
      );
    }
    ArgonCard.cardData[c].then((e) => {
      if (e) {
        this._render(e);
      }
    });
  }
  private _render(data: any) {
    this.innerHTML = `
     <a href="${data.link}" target="_blank">
      <p>${data.title}</p>
      <p>${data.type}</p>
     </a>
     `;
  }
}

customElements.define("argon-card", ArgonCard);

@Pipe({
  pure: true,
  name: "renderMarkDown",
  standalone: true,
})
export class RenderMarkDownPipe {
  constructor(private readonly s: DomSanitizer) {}
  transform(value: string): string | SafeHtml {
    try {
      return this.s.bypassSecurityTrustHtml(marked(String(value).trim()));
    } catch (e) {
      return value;
    }
  }
}
