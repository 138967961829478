<!--
  ~ Copyright (C) MetaCarp GmbH - All Rights Reserved
  ~ Unauthorized copying of this file, via any medium is strictly prohibited
  ~ Proprietary and confidential
  ~ Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
  ~ Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
  -->

<ng-container *ngIf="address">
  <meta-field-wrapper [maParams]="{ label: 'Name1' }">
    <meta-input [(ngModel)]="address.name1" [maParams]="{ editing: true }"></meta-input>
  </meta-field-wrapper>
</ng-container>
