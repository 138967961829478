/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponent, MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-webview",
  type: "webview",
  name: "Webview",
  icon: "browser",
})
export class WebviewComponent extends MetaFormComponent {
  @MetaFormComponentProperty({
    name: "Url",
    type: "text",
    isTemplate: true,
    tab: "general",
  })
  public link: string;

  @MetaFormComponentProperty({
    name: "Height",
    type: "number",
    tab: "style",
    min: 0,
  })
  public height: number | string;

  @MetaFormComponentProperty({
    name: "Sandbox",
    type: "boolean",
    tab: "general",
    default: true,
  })
  public sandbox = true;

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      type: `meta-${this.constructor["meta"]["type"]}`,
      props: {
        src: this.link,
        height: this.height,
      },
    });
  }
}
