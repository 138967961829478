/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

function getColor(prop: string) {
  return getComputedStyle(document.documentElement).getPropertyValue(`--${prop}`); // #999999
}

export function getVariableTypeColor(type: string) {
  switch (type) {
    case "empty":
      return getColor("special-color");
    case "string":
      return getColor("primary-color");
    case "number":
    case "integer":
      return getColor("groups-color-02");
    case "object":
      return getColor("groups-color-03");
    case "boolean":
      return getColor("groups-color-04");
    case "array":
      return getColor("groups-color-05");
    case "binary":
      return getColor("groups-color-06");
    default:
      return getColor("error-color");
  }
}
