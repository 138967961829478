/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2020
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2020
 * Written by Yannick Küchlin <y.kuechlin@taifun-tofu.de>, 2019-2020
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: "metaSearchHighlight",
  pure: true
})

export class SearchHighlightPipe implements PipeTransform {

  transform(value: any, args: any): any {
    if (!args || value === null) {return value;}
    const re = new RegExp(args, 'gi');
    return value.toString().replace(re, "<mark>$&</mark>");
  }
}
