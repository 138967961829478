/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgModule,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { MetaHubService } from "../metaHub/metaHub.service";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaButtonModule, MetaEmptyModule, MetaSectionModule } from "@meta/ui";
import { MetaRowModule } from "../metaRow/metaRow.component";
import { MetaColumnModule } from "../metaColumn/metaColumn.component";
import * as _ from "lodash";
import { MetaTaskListModule } from "./metaTask.list.component";

export class MetaTaskListFormly {
  idField?: string;
  sourceType?: string | null = null;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-task-list-formly",
  template: `
    <meta-task-list
      *ngIf="model && model?.primaryItem[ma.idField]"
      [maParams]="{
        taskId: model?.primaryItem[ma.idField],
        sourceId: formState.itemId(),
        sourceType: ma.sourceType,
        isFormlyContext: true
      }"
    ></meta-task-list>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaTaskListFormlyComponent extends MetaComponentBase implements OnInit {
  get ma(): MetaTaskListFormly {
    return super.ma;
  }

  constructor(_changeDetectorRef: ChangeDetectorRef, _metaHelperService: MetaHelperService) {
    super();
    super.maParams = new MetaTaskListFormly();
    this.onTaskFormChange = _.debounce(this.onTaskFormChange, 500);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public onTaskFormChange(data: any) {
    const model = this.formState.data();
    model[this.field.parent.parent.id][this.field.parent["index"]][this.field.id] = JSON.stringify(data);
    this.formState.data.set(model);
  }
}

@NgModule({
  declarations: [MetaTaskListFormlyComponent],
  imports: [
    CommonModule,
    MetaButtonModule,
    MetaEmptyModule,
    MetaRowModule,
    MetaColumnModule,
    MetaSectionModule,
    MetaTaskListModule,
  ],
  providers: [MetaHubService],
  exports: [MetaTaskListFormlyComponent],
})
export class MetaTaskListFormlyModule {}
