/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MetaButtonType, MetaState } from "@meta/enums";
import { MetaButton } from "../../../components/metaButton/metaButton.component";
import { MetaFormlyFormOptions } from "../../metaForm/metaForm.interface";
import { MetaFormService } from "../metaForm.service";

@Component({
  selector: "meta-form-create",
  template: `@if (options.formState.state() < MetaState.editing || options.formState.state() > MetaState.saving) {
    <meta-button [maParams]="config" [maDisabled]="options.formState.isLoading()"></meta-button>
  }`,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaFormCreateComponent implements OnInit {
  @Input() options: MetaFormlyFormOptions;

  public config: MetaButton;
  protected readonly MetaState = MetaState;

  constructor(
    public readonly metaFormService: MetaFormService,
    private readonly _router: Router,
  ) {}

  ngOnInit() {
    this.config = {
      id: "__metaCreate",
      label: "Erstellen",
      icon: "plus",
      type: MetaButtonType.primary,
      onClick: async () => {
        if (this.metaFormService.formState.form().wizard) {
          const modal = await this.metaFormService.openModal({
            formId: this.metaFormService.formState.form().wizard,
            title: "", //form.label,
            size: this.metaFormService.formState.form().wizardOptions?.size || "small",
            type: "wizard",
            editing: true,
            componentParams: {
              maParams: {
                formId: this.metaFormService.formState.form().wizard,
                icon: "", // form.icon,
                editing: true,
              },
            },
          });
          modal.afterClose.subscribe((result) => {
            if (result?._id) {
              this._router.navigate([
                "/",
                this.metaFormService.formState.form().navigateAfterCreate ||
                  this.metaFormService.formState.form().creatable,
                result._id.join(","),
              ]);
            }
          });
        } else {
          this._router.navigate(["/", this.metaFormService.formState.form().creatable, "create"]);
        }
      },
    };
  }
}
