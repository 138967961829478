/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MetaSectionType } from "@meta/enums";
import { FormlyModule } from "@ngx-formly/core";
import * as _ from "lodash";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { PipesModule } from "../../pipes/pipes.module";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";

export class MetaSection {
  sectionType?: MetaSectionType | string = MetaSectionType.paragraph;
  sectionStyle?: "none" | "condensed";
  link?: any[] | string;
  spacingTop?: 1 | 2 | 3 | 4 | 5;
  spacingBottom?: 1 | 2 | 3 | 4 | 5;
  style?: "default" | "dashed" | "dotted" = "default";
  align?: "left" | "right" = "left";
  icon?: string;
  label?: string;
  height? = 32;
  hasFieldWrapper?: boolean;
  fontSize?: string;
  color?: "danger" | "error" | "warning" | "success";
  textTrim?: boolean;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-section",
  template: `
    <ng-container [ngSwitch]="ma.sectionType">
      <h1
        *ngSwitchCase="metaTextType.headline1"
        [class.align-left]="ma.align === 'left'"
        [class.align-right]="ma.align === 'right'"
        [class.align-center]="ma.align === 'center'"
        [class.text-success]="displayData.color === 'success' || ma.color === 'success'"
        [class.text-warning]="displayData.color === 'warning' || ma.color === 'warning'"
        [class.text-error]="
          displayData.color === 'error' ||
          ma.color === 'error' ||
          displayData.color === 'danger' ||
          ma.color === 'danger'
        "
        [class.text-mute]="displayData.color === 'mute' || ma.color === 'mute'"
        [class]="
          (ma.spacingBottom !== undefined ? 'mb-' + ma.spacingBottom : '') +
          ' ' +
          (ma.spacingTop !== undefined ? 'mt-' + ma.spacingTop : '')
        "
      >
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </h1>
      <h2
        *ngSwitchCase="metaTextType.headline2"
        [class.align-left]="ma.align === 'left'"
        [class.align-right]="ma.align === 'right'"
        [class.align-center]="ma.align === 'center'"
        [class.text-success]="displayData.color === 'success' || ma.color === 'success'"
        [class.text-warning]="displayData.color === 'warning' || ma.color === 'warning'"
        [class.text-error]="
          displayData.color === 'error' ||
          ma.color === 'error' ||
          displayData.color === 'danger' ||
          ma.color === 'danger'
        "
        [class.text-mute]="displayData.color === 'mute' || ma.color === 'mute'"
        [class]="
          (ma.spacingBottom !== undefined ? 'mb-' + ma.spacingBottom : '') +
          ' ' +
          (ma.spacingTop !== undefined ? 'mt-' + ma.spacingTop : '')
        "
      >
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </h2>
      <h3
        *ngSwitchCase="metaTextType.headline3"
        [class.align-left]="ma.align === 'left'"
        [class.align-right]="ma.align === 'right'"
        [class.align-center]="ma.align === 'center'"
        [class.text-success]="displayData.color === 'success' || ma.color === 'success'"
        [class.text-warning]="displayData.color === 'warning' || ma.color === 'warning'"
        [class.text-error]="
          displayData.color === 'error' ||
          ma.color === 'error' ||
          displayData.color === 'danger' ||
          ma.color === 'danger'
        "
        [class.text-mute]="displayData.color === 'mute' || ma.color === 'mute'"
        [class]="
          (ma.spacingBottom !== undefined ? 'mb-' + ma.spacingBottom : '') +
          ' ' +
          (ma.spacingTop !== undefined ? 'mt-' + ma.spacingTop : '')
        "
      >
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </h3>
      <h4
        *ngSwitchCase="metaTextType.headline4"
        [class.align-left]="ma.align === 'left'"
        [class.align-right]="ma.align === 'right'"
        [class.align-center]="ma.align === 'center'"
        [class.text-success]="displayData.color === 'success' || ma.color === 'success'"
        [class.text-warning]="displayData.color === 'warning' || ma.color === 'warning'"
        [class.text-error]="
          displayData.color === 'error' ||
          ma.color === 'error' ||
          displayData.color === 'danger' ||
          ma.color === 'danger'
        "
        [class.text-mute]="displayData.color === 'mute' || ma.color === 'mute'"
        [class]="
          (ma.spacingBottom !== undefined ? 'mb-' + ma.spacingBottom : '') +
          ' ' +
          (ma.spacingTop !== undefined ? 'mt-' + ma.spacingTop : '')
        "
      >
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </h4>
      <h1
        *ngSwitchCase="metaTextType.headlineSeparator"
        [class.align-left]="ma.align === 'left'"
        [class.align-right]="ma.align === 'right'"
        [class.text-success]="displayData.color === 'success' || ma.color === 'success'"
        [class.text-warning]="displayData.color === 'warning' || ma.color === 'warning'"
        [class.text-error]="
          displayData.color === 'error' ||
          ma.color === 'error' ||
          displayData.color === 'danger' ||
          ma.color === 'danger'
        "
        [class.text-mute]="displayData.color === 'mute' || ma.color === 'mute'"
        [class]="
          (ma.spacingBottom !== undefined ? 'mb-' + ma.spacingBottom : '') +
          ' ' +
          (ma.spacingTop !== undefined ? 'mt-' + ma.spacingTop : '') +
          ' headline-separator'
        "
      >
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </h1>
      <p
        *ngSwitchCase="metaTextType.paragraph"
        [class.align-left]="ma.align === 'left'"
        [class.align-right]="ma.align === 'right'"
        [class.align-center]="ma.align === 'center'"
        [class.text-success]="displayData.color === 'success' || ma.color === 'success'"
        [class.text-warning]="displayData.color === 'warning' || ma.color === 'warning'"
        [class.text-error]="
          displayData.color === 'error' ||
          ma.color === 'error' ||
          displayData.color === 'danger' ||
          ma.color === 'danger'
        "
        [class.text-mute]="displayData.color === 'mute' || ma.color === 'mute'"
        [class.condensed]="ma.sectionStyle === 'condensed'"
        [style.font-size]="ma.fontSize"
        [style.line-height]="ma.fontSize"
        [class]="
          (ma.spacingBottom !== undefined ? 'mb-' + ma.spacingBottom : '') +
          ' ' +
          (ma.spacingTop !== undefined ? 'mt-' + ma.spacingTop : '')
        "
      >
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </p>
      <span
        *ngSwitchCase="metaTextType.span"
        [class.align-left]="ma.align === 'left'"
        [class.align-right]="ma.align === 'right'"
        [class.align-center]="ma.align === 'center'"
        [class.condensed]="ma.sectionStyle === 'condensed'"
        [class.text-trim]="ma.textTrim"
        [class.text-success]="displayData.color === 'success' || ma.color === 'success'"
        [class.text-warning]="displayData.color === 'warning' || ma.color === 'warning'"
        [class.text-error]="
          displayData.color === 'error' ||
          ma.color === 'error' ||
          displayData.color === 'danger' ||
          ma.color === 'danger'
        "
        [class.text-mute]="displayData.color === 'mute' || ma.color === 'mute'"
        [style.font-size]="ma.fontSize"
        [style.line-height]="ma.fontSize"
        [style.height]="ma.height"
        [class]="
          (ma.spacingBottom !== undefined ? 'mb-' + ma.spacingBottom : '') +
          ' ' +
          (ma.spacingTop !== undefined ? 'mt-' + ma.spacingTop : '') +
          ' wrapper'
        "
      >
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </span>
      <strong
        *ngSwitchCase="metaTextType.strong"
        [class.align-left]="ma.align === 'left'"
        [class.align-right]="ma.align === 'right'"
        [class.align-center]="ma.align === 'center'"
        [class.condensed]="ma.sectionStyle === 'condensed'"
        [class.text-success]="displayData.color === 'success' || ma.color === 'success'"
        [class.text-warning]="displayData.color === 'warning' || ma.color === 'warning'"
        [class.text-error]="
          displayData.color === 'error' ||
          ma.color === 'error' ||
          displayData.color === 'danger' ||
          ma.color === 'danger'
        "
        [class.text-mute]="displayData.color === 'mute' || ma.color === 'mute'"
        [style.font-size]="ma.fontSize"
        [style.line-height]="ma.fontSize"
        [style.height]="ma.height"
        [class]="
          (ma.spacingBottom !== undefined ? 'mb-' + ma.spacingBottom : '') +
          ' ' +
          (ma.spacingTop !== undefined ? 'mt-' + ma.spacingTop : '') +
          ' wrapper'
        "
      >
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </strong>
      <a
        *ngSwitchCase="metaTextType.link"
        [routerLink]="getLink()"
        [class.align-left]="ma.align === 'left'"
        [class.align-right]="ma.align === 'right'"
        [class.align-center]="ma.align === 'center'"
        [class.condensed]="ma.sectionStyle === 'condensed'"
        [style.font-size]="ma.fontSize"
        [class]="
          (ma.spacingBottom !== undefined ? 'mb-' + ma.spacingBottom : '') +
          ' ' +
          (ma.spacingTop !== undefined ? 'mt-' + ma.spacingTop : '') +
          ' wrapper'
        "
      >
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </a>
      <hr
        *ngSwitchCase="metaTextType.horizontalLine"
        [class]="
          (ma.spacingBottom !== undefined ? 'mb-' + ma.spacingBottom : '') +
          ' ' +
          (ma.spacingTop !== undefined ? 'mt-' + ma.spacingTop : '') +
          ' ' +
          (ma.style ? 'linestyle-' + ma.style : '')
        "
      />
      <div *ngSwitchCase="metaTextType.verticalLine" class="vl"></div>
      <div
        *ngSwitchCase="metaTextType.space"
        [class]="
          (ma.spacingBottom !== undefined ? 'mb-' + ma.spacingBottom : '') +
          ' ' +
          (ma.spacingTop !== undefined ? 'mt-' + ma.spacingTop : '') +
          ' space'
        "
      ></div>
      <div
        *ngSwitchCase="metaTextType.stripes"
        [style.height.px]="ma.height"
        [class]="
          'stripes ' +
          (ma.spacingBottom !== undefined ? 'mb-' + ma.spacingBottom : '') +
          ' ' +
          (ma.spacingTop !== undefined ? 'mt-' + ma.spacingTop : '')
        "
      ></div>
      <div *ngSwitchCase="metaTextType.pageBreak" class="ma-print-pagebreak"></div>
      <footer *ngSwitchCase="metaTextType.footer">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </footer>
      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </ng-container>
    </ng-container>

    <ng-template #contentTemplate>
      <ng-container *ngIf="ma.hasFieldWrapper; else valueTemplate">
        <p class="output">
          <ng-container *ngTemplateOutlet="valueTemplate"></ng-container>
        </p>
      </ng-container>
    </ng-template>

    <ng-template #valueTemplate>
      <i *ngIf="ma.icon" class="fal fa-{{ ma.icon }} pr-2"></i>
      <span
        *ngIf="displayData && displayData['value']; else elseTpl"
        class="inner-span"
        [innerHTML]="
          (displayData['value']
            ? displayData['value']['displayValue']
              ? displayData['value']['displayValue']
              : displayData['value']
            : null
          )
            | lineBreak
            | sanitizeHtml
        "
      ></span>
      <ng-template #elseTpl>
        <span *ngIf="ma.label" [innerHTML]="ma.label | lineBreak | sanitizeHtml" class="inner-span"></span>
      </ng-template>
      <ng-content></ng-content>
    </ng-template>
  `,
  styleUrls: ["./metaSection.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaSectionComponent extends MetaComponentBase {
  public metaTextType = MetaSectionType;

  constructor() {
    super();
    super.maParams = new MetaSection();
  }

  get ma(): MetaSection {
    return super.ma;
  }

  public getLink() {
    console.log(this.formState.displayData());
    return this.field ? _.get(this.formState.displayData(), [this.field.id, "link"], null) : null;
  }
}

@NgModule({
  declarations: [MetaSectionComponent],
  imports: [CommonModule, FormlyModule, RouterModule, PipesModule],
  exports: [MetaSectionComponent],
})
export class MetaSectionModule {}
