/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2020
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2020
 */

export interface IMetaFormChartFilter {
  field: string;
  required: boolean;
  label: string;
  type: MetaFormChartFilterType;
  componentId?: string;
}

export type MetaFormChartFilterType =
  "date"
  | "date-time"
  | "time"
  | "year"
  | "month"
  | "text"
  | "number"
  | "customer"
  | "user"
  | "location"
  | "complex";


