/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation } from "@angular/core";
import { FormlyModule } from "@ngx-formly/core";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzAlign, NzJustify } from "ng-zorro-antd/grid/row.directive";
import { MetaWrapperBase } from "../../base/metaWrapperBase/metaWrapperBase.component";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";

export class MetaRow {
  align?: NzAlign | null;
  justify?: NzJustify | null;
  style?: "light" | "dark" | "default" = "default";
  flex?: string | number | null = "inherit";
}

@MetaUnsubscribe()
@Component({
  selector: "meta-row",
  template: `
    <nz-row
      [style.flex]="ma?.flex"
      [nzAlign]="ma?.align"
      [nzJustify]="ma?.justify"
      [nzGutter]="[8, 8]"
      [class.darken]="ma?.style === 'dark'"
      [class.lighten]="ma?.style === 'light'"
    >
      <ng-container #fieldComponent></ng-container>
      <ng-content></ng-content>
    </nz-row>
  `,
  styleUrls: ["./metaRow.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaRowComponent extends MetaWrapperBase {
  constructor() {
    super();
    super.maParams = new MetaRow();
  }
}

@NgModule({
  declarations: [MetaRowComponent],
  imports: [CommonModule, FormlyModule, NzGridModule],
  exports: [MetaRowComponent],
})
export class MetaRowModule {}
