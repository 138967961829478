/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  effect,
  inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { Subject } from "rxjs";
import { CommonModule } from "@angular/common";
import { ControlValueAccessor, UntypedFormControl } from "@angular/forms";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaControlLayout, MetaState } from "@meta/enums";
import { MetaHelperService } from "../../services/metaHelper.service";
import { animate, style, transition, trigger } from "@angular/animations";
import * as _ from "lodash";

export class MetaFormBase {
  id?: string;
  formId?: string;
  loading?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  required?: boolean;
  copyable?: boolean;
  editing?: boolean;
  hide?: boolean;
  label?: string;
  layout?: MetaControlLayout | string = MetaControlLayout.horizontal;

  [id: string]: any;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-component-base",
  template: `META_COMPONENT_BASE`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule],
  animations: [
    trigger("fadeInFromBottom", [
      transition(":enter", [style({ transform: "translateY(30px)" }), animate(".3s cubic-bezier(.37,.66,.12,1.17)")]),
      transition(":leave", [animate(".3s cubic-bezier(.37,.66,.12,1.17)", style({ transform: "translateY(30px)" }))]),
    ]),
    trigger("fadeInFromBottomWithEdit", [
      transition(":enter", [style({ transform: "translateY(60px)" }), animate(".3s cubic-bezier(.37,.66,.12,1.17)")]),
      transition(":leave", [animate(".3s cubic-bezier(.37,.66,.12,1.17)", style({ transform: "translateY(60px)" }))]),
    ]),
  ],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MetaComponentBase<T = any, P = any>
  extends FieldType
  implements OnInit, OnDestroy, ControlValueAccessor, OnChanges
{
  public formId: string;
  public isLoading: boolean;
  public value: T;

  onChange: (val: any) => void;
  onTouch: () => void;
  public readonly destroyed$ = new Subject<any>();
  public subFormPath: string[];
  public subFormIndex: number[];
  protected readonly changeDetectorRef: ChangeDetectorRef;
  protected readonly metaHelperService: MetaHelperService;
  protected readonly inj: Injector;
  protected readonly destroyRef = inject(DestroyRef);

  constructor() {
    super();
    this.changeDetectorRef = inject(ChangeDetectorRef);
    this.metaHelperService = inject(MetaHelperService);
    this.inj = inject(Injector);
  }

  @Input() editing: boolean = false;

  get fc(): UntypedFormControl | unknown {
    return this.formControl;
  }

  public _maParams: any = null;

  @Input()
  public get maParams(): P {
    return this._maParams;
  }

  public set maParams(value: P) {
    this._maParams = _.merge({}, new MetaFormBase(), this._maParams, value);
  }

  get ma(): Record<string, any> & P {
    return this.field ? { ...this.maParams, ...(this.props as any) } : this.maParams;
  }

  public get displayData(): any {
    if (this.field && this.formState?.displayData?.()) {
      this.subFormPath =
        this.subFormPath || this.options?.["subFormPath"] || this.metaHelperService.getFormlySubFormPath(this.field);
      this.subFormIndex = this.metaHelperService.getFormlyFieldArrayIndex(this.field, true);
      return this._getNestedDisplayData(this.formState.displayData(), this.subFormPath, this.subFormIndex, this.id);
    }
    return {};
  }

  private _getNestedDisplayData(data: any, path: string[], indices: number[], id: string): any {
    if (id === "g-strong-01") {
      debugger;
    }
    if (path.length > 0 && indices.length > 0) {
      const nestedData = _.get(data, path.concat(["values"]));
      if (nestedData && indices.length > 0) {
        const index = indices.shift();
        if (Array.isArray(nestedData) && nestedData[index]) {
          return this._getNestedDisplayData(nestedData[index], path, indices, id);
        }
      }
    }
    if (path.length > 0 && indices.length === 0) {
      const nestedData = _.get(data, path.concat(["values"]));
      if (nestedData) {
        if (Array.isArray(nestedData)) {
          return this._getNestedDisplayData(nestedData[0], path, [], id);
        }
      }
    }
    if (data[id] !== undefined) {
      return data[id];
    }
    return {};
  }

  writeValue(value: any) {
    this.value = value;
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnInit() {
    if (this.ma.editing || this.ma.isEditing) {
      this.editing = true;
      return;
    }

    if (this.ma.isEditing === false) {
      this.editing = false;
      return;
    }

    if (this.field && this.field.options) {
      effect(
        () => {
          this.editing =
            this.ma.editing ||
            (this.options.formState?.state() >= MetaState.editing &&
              this.options.formState?.state() <= MetaState.saving);
          (this.maParams as any) = { showDummy: this.options.formState?.isDummyForm };
          this.changeDetectorRef.markForCheck();
        },
        { injector: this.inj },
      );
      this.options.formState.displayData$.subscribe((data) => {
        (this.field as any).displayValue = this.displayData;
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes?.maParams?.currentValue?.editing !== undefined) {
      this.editing = changes.maParams.currentValue.editing;
    }
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(null);
      this.destroyed$.complete();
    }
  }
}
