/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ValidatableComponent } from "./validatableComponent";
import { ExposeProperty, MetaFormComponentInfo } from "./baseComponent";
import { EntityType } from "@meta/enums";

import { IFormFindDatabaseResult } from "../../../../apps/api/src/app/queryBuilder/IFormFindDatabaseResult";

@MetaFormComponentInfo({
  selector: "ma-upload",
  type: "upload",
  name: "File-upload",
  icon: "upload",
  palletGroup: "input",
})
export class UploadComponent<PrimaryDataSource> extends ValidatableComponent<PrimaryDataSource> {
  @ExposeProperty() public multiple: boolean;
  @ExposeProperty() public entityType?: EntityType;

  public static create<T>(dataSource: T, opts: Partial<UploadComponent<T>>) {
    const input = new UploadComponent<T>();
    input.setOptions(opts as any);
    return input;
  }

  public render(data: IFormFindDatabaseResult) {
    return {
      filename: data.selectData[this.id]?.[0]?.["name"],
      mime: data.selectData[this.id]?.[0]?.["type"],
    };
  }
}
