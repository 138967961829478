/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */
import { Injectable } from "@angular/core";
import { SearchCompilationDto, ServerTemplateResponse } from "@meta/forms";
import { plainToInstance } from "class-transformer";
import { Socket } from "ngx-socket-io";

@Injectable({
  providedIn: "root",
})
export class MetaActionHandlerFactory {
  constructor(private readonly _socketService: Socket) {}

  public async executeAction(
    control: any,
    formId: string,
    model: any,
    validateForm = false,
    contextId?: string,
  ): Promise<any> {
    await this.executeSocketAction({
      formId,
      data: model,
      ctx: contextId,
      field: control.id,
      type: "onClick",
    });
    return true;
  }

  public async executeProcessSelectedItems(
    formId: string,
    items: SearchCompilationDto[],
    contextId?: string,
  ): Promise<any> {
    await this.executeSocketAction({
      formId,
      data: {
        items,
      },
      ctx: contextId,
      field: null,
      type: "onProcessSelectedItems",
    });
    return true;
  }

  public async executeRowAction(opts: { formId: string; data: Record<string, any> }) {
    await this.executeSocketAction({
      formId: opts.formId,
      data: {
        ...opts.data,
      },
      field: null,
      type: "onSelect",
    });
    return true;
  }

  public async executeCreateAction(opts: { formId: string; ctx?: string }) {
    return await this.executeSocketAction({
      formId: opts.formId,
      data: null,
      field: null,
      type: "onCreate",
      ctx: opts.ctx,
    });
  }

  public async executeEditAction(opts: { formId: string; data: Record<string, any>; ctx?: string }) {
    await this.executeSocketAction({
      formId: opts.formId,
      data: {
        ...opts.data,
      },
      field: null,
      ctx: opts.ctx,
      type: "onEdit",
    });
    return true;
  }

  public async executeChangeAction(opts: {
    controlId: string;
    formId: string;
    data: Record<string, any>;
    subFormPath?: string[];
    ctx?: string;
    index?: number;
  }) {
    if (!opts.formId) {
      return false;
    }
    return await this.executeSocketAction({
      formId: opts.formId,
      subFormPath: opts.subFormPath,
      data: {
        ...opts.data,
      },
      field: opts.controlId,
      type: "onChange",
      ctx: opts.ctx,
      index: opts.index,
    });
  }

  public async executeFocusAction(opts: {
    controlId: string;
    formId: string;
    data: Record<string, any>;
    subFormPath?: string[];
    ctx?: string;
    index?: number;
  }) {
    if (!opts.formId) {
      return false;
    }
    return await this.executeSocketAction({
      formId: opts.formId,
      subFormPath: opts.subFormPath,
      data: {
        ...opts.data,
      },
      field: opts.controlId,
      type: "onFocus",
      ctx: opts.ctx,
      index: opts.index,
    });
  }

  public async executeBlurAction(opts: {
    controlId: string;
    formId: string;
    data: Record<string, any>;
    subFormPath?: string[];
    ctx?: string;
    index?: number;
  }) {
    if (!opts.formId) {
      return false;
    }
    return await this.executeSocketAction({
      formId: opts.formId,
      subFormPath: opts.subFormPath,
      data: {
        ...opts.data,
      },
      field: opts.controlId,
      type: "onBlur",
      ctx: opts.ctx,
      index: opts.index,
    });
  }

  public async executeSelectAction(opts: {
    controlId: string;
    formId: string;
    data: Record<string, any>;
    subFormPath?: string[];
    ctx?: string;
    index?: number;
    passthroughData?: Record<string, any>;
  }) {
    if (!opts.formId) {
      return false;
    }
    return await this.executeSocketAction({
      formId: opts.formId,
      subFormPath: opts.subFormPath,
      data: {
        ...opts.data,
      },
      field: opts.controlId,
      ctx: opts.ctx,
      type: "onSelect",
      index: opts.index,
      passthroughData: opts.passthroughData,
    });
  }

  public async executeOnOpenAction(opts: { formId: string; data: Record<string, any> }) {
    if (!opts.formId) {
      return false;
    }
    return await this.executeSocketAction({
      formId: opts.formId,
      data: {
        ...opts.data,
      },
      field: undefined,
      ctx: undefined,
      type: "onOpen",
    });
  }

  public async executeOnLoadedAction(opts: { formId: string; data: Record<string, any> }) {
    if (!opts.formId) {
      return false;
    }
    return await this.executeSocketAction({
      formId: opts.formId,
      data: {
        ...opts.data,
      },
      field: undefined,
      ctx: undefined,
      type: "onLoaded",
    });
  }

  public async executeBeforeOpenAction(opts: {
    controlId: string;
    formId: string;
    data: Record<string, any>;
    subFormPath?: string[];
    ctx?: string;
    index?: number;
  }) {
    if (!opts.formId) {
      return false;
    }
    return await this.executeSocketAction({
      formId: opts.formId,
      subFormPath: opts.subFormPath,
      data: {
        ...opts.data,
      },
      field: opts.controlId,
      ctx: opts.ctx,
      type: "onBeforeOpen",
      index: opts.index,
    });
  }

  public async executePipelineStateChangeAction(opts: {
    controlId: string;
    formId: string;
    data: Record<string, any>;
    ctx?: string;
  }) {
    if (!opts.formId) {
      return false;
    }
    return await this.executeSocketAction({
      formId: opts.formId,
      data: {
        ...opts.data,
      },
      field: opts.controlId,
      ctx: opts.ctx,
      type: "onPipelineStateChange",
    });
  }

  public async executeBeforeLoadAction(opts: {
    controlId: string;
    formId: string;
    data: Record<string, any>;
    subFormPath?: string[];
    ctx?: string;
    index?: number;
  }) {
    if (!opts.formId) {
      return false;
    }
    return await this.executeSocketAction({
      formId: opts.formId,
      subFormPath: opts.subFormPath,
      data: {
        ...opts.data,
      },
      field: opts.controlId,
      ctx: opts.ctx,
      type: "onBeforeOpen",
      index: opts.index,
    });
  }

  public async executeCreateVersionAction(opts: {
    controlId: string;
    formId: string;
    data: Record<string, any>;
    subFormPath?: string[];
    ctx?: string;
    index?: number;
  }) {
    if (!opts.formId) {
      return false;
    }
    return await this.executeSocketAction({
      formId: opts.formId,
      subFormPath: opts.subFormPath,
      data: {
        ...opts.data,
      },
      field: opts.controlId,
      ctx: opts.ctx,
      type: "onCreateVersion",
      index: opts.index,
    });
  }

  public async executeDeleteVersionAction(opts: {
    controlId: string;
    formId: string;
    data: Record<string, any>;
    subFormPath?: string[];
    ctx?: string;
    index?: number;
  }) {
    if (!opts.formId) {
      return false;
    }
    return await this.executeSocketAction({
      formId: opts.formId,
      subFormPath: opts.subFormPath,
      data: {
        ...opts.data,
      },
      field: opts.controlId,
      ctx: opts.ctx,
      type: "onDeleteVersion",
      index: opts.index,
    });
  }

  public async executeClickAction(opts: {
    controlId: string;
    formId: string;
    data: Record<string, any>;
    subFormPath?: string[];
    ctx?: string;
    index?: number;
    formIsValid: boolean;
    isEdit?: boolean;
    passthroughData?: Record<string, any>;
  }) {
    if (!opts.formId) {
      return false;
    }
    return await this.executeSocketAction({
      formId: opts.formId,
      subFormPath: opts.subFormPath,
      data: {
        ...opts.data,
      },
      field: opts.controlId,
      ctx: opts.ctx,
      type: "onClick",
      index: opts.index,
      additionalData: {
        formIsValid: opts.formIsValid,
      },
      isEdit: opts.isEdit,
      passthroughData: opts.passthroughData,
    });
  }

  public async executeNextStepAction(opts: {
    formId: string;
    data: Record<string, any>;
    subFormPath?: string[];
    ctx?: string;
    index?: number;
    formIsValid: boolean;
    isEdit?: boolean;
  }) {
    if (!opts.formId) {
      return false;
    }
    return await this.executeSocketAction({
      formId: opts.formId,
      subFormPath: opts.subFormPath,
      data: {
        ...opts.data,
      },
      field: undefined,
      ctx: opts.ctx,
      type: "onNextStep",
      index: opts.index,
      additionalData: {
        formIsValid: opts.formIsValid,
      },
      isEdit: opts.isEdit,
    });
  }

  public async executePreviousStepAction(opts: {
    formId: string;
    data: Record<string, any>;
    subFormPath?: string[];
    ctx?: string;
    index?: number;
    formIsValid: boolean;
    isEdit?: boolean;
  }) {
    if (!opts.formId) {
      return false;
    }
    return await this.executeSocketAction({
      formId: opts.formId,
      subFormPath: opts.subFormPath,
      data: {
        ...opts.data,
      },
      field: undefined,
      ctx: opts.ctx,
      type: "onPreviousStep",
      index: opts.index,
      additionalData: {
        formIsValid: opts.formIsValid,
      },
      isEdit: opts.isEdit,
    });
  }

  public async handleSocketResponse(data: any, ack: CallableFunction) {
    const res = plainToInstance(ServerTemplateResponse, data);
    const resp = await this.handleActionResponse({
      res,
      formId: res.formId,
      subFormPath: res.subFormPath,
      index: res.index,
    });
    ack(resp);
  }

  public async executeSocketAction(opts: {
    formId: string;
    data: Record<string, any>;
    field: string;
    type: string;
    ctx?: string;
    subFormPath?: string[];
    selector?: string[];
    index?: number;
    additionalData?: Record<string, any>;
    isEdit?: boolean;
    passthroughData?: Record<string, any>;
  }) {
    return await new Promise<any>((resolve, reject) => {
      this._socketService.emit("formAction", opts, async (res: any) => {
        if (typeof res === "string") {
          alert(res);
          return reject(false);
        }
        await this.handleActionResponse({
          res: plainToInstance(ServerTemplateResponse, res),
          formId: opts.formId,
          subFormPath: opts.subFormPath,
          index: opts.index,
          passthroughData: opts.passthroughData,
        });
        resolve(res.result);
      });
    });
  }

  async handleActionResponse(opts: {
    res: any;
    formId: string;
    subFormPath?: string[];
    index: number;
    passthroughData?: Record<string, any>;
  }) {
    const responses: any[] = [];
    // TODO: MAYBE REMOVABLE
    /*for (const action of opts.res.actions) {
      const handler = this._localActionHandlerFactory.handlers.get(action.constructor);
      if (handler) {
        const resp = await handler.handle(action, opts);
        responses.push(resp);
      } else {
        console.warn(`No handler found for action type: ${action.constructor.name}`);
      }
    }*/
    responses.push(Date.now());
    return responses;
  }
}
