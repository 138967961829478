/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ValidatableComponent } from "./validatableComponent";
import { Expose } from "class-transformer";
import { ExposeProperty, MetaFormComponentInfo } from "./baseComponent";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-textarea",
  type: "textarea",
  name: "Textarea",
  icon: "pencil",
  palletGroup: "input",
})
export class TextareaComponent<PrimaryDataSource = any> extends ValidatableComponent<PrimaryDataSource> {
  @ExposeProperty() public rows?: number;

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        rows: this.rows,
        placeholder: this.placeholder,
        onChange: !!this.onChange,
      },
    });
  }
}
