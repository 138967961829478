/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { ChangeDetectionStrategy, Component, Input, NgModule, TemplateRef, ViewEncapsulation } from "@angular/core";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { CommonModule } from "@angular/common";
import { FormlyModule } from "@ngx-formly/core";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { NzTimelineModule } from "ng-zorro-antd/timeline";
import { MetaTimelineColor, MetaTimelineMode, MetaTimelineStyle } from "@meta/enums";
import { MetaAvatarData, MetaAvatarModule } from "../metaAvatar/metaAvatar.component";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzCommentModule } from "ng-zorro-antd/comment";
import * as moment from "moment";
import { FormlyAttributeEvent } from "@ngx-formly/core/lib/models";
import { MetaTimelineService } from "./metaTimeline.service";
import { UserService } from "../../services/user.service";
import { FormsModule } from "@angular/forms";
import { MetaDrodpownModule } from "../metaDropdown/metaDropdown.component";
import { MetaLoaderModule } from "../metaLoader/metaLoader.component";
import { MetaEmptyModule } from "../metaEmpty/metaEmpty.component";
import { MetaSectionModule } from "../metaSection/metaSection.component";
import { MetaInputModule } from "../metaInput/metaInput.component";
import { MetaButtonModule } from "../metaButton/metaButton.component";
import { PipesModule } from "../../pipes/pipes.module";
import { MetaActivityRepository } from "../metaActivity/metaActivity.repository";
import { MetaMediaLibraryModule } from "../metaMediaLibrary/metaMediaLibrary.module";
import { MetaMediaLibraryService } from "../metaMediaLibrary/metaMediaLibrary.service";
import { MetaTimelineTemplateEntityWorkflowExecutionStartedComponent } from "./templates/entityWorkflowExecutionStarted";
import { MetaTimelineTemplatePipelineObjectMovedComponent } from "./templates/pipelineObjectMoved";
import { MetaTagModule } from "../metaTag/metaTag.component";
import { MetaTooltipModule } from "../metaTooltip/metaTooltip.component";
import { MetaTimelineTemplateWorkflowExecutionEndedComponent } from "./templates/workflowExecutionEnded";
import { MetaTimelineTemplateFormCreatedComponent } from "./templates/formCreated";
import { MetaTimelineTemplateFormUnlockedComponent } from "./templates/formUnlocked";
import { MetaTimelineTemplateFormLockedComponent } from "./templates/formLocked";
import { MetaTimelineTemplateFormUpdatedComponent } from "./templates/formUpdated";
import { MetaTimelineTemplateWorkflowExecutionStartedComponent } from "./templates/workflowExecutionStarted";
import { MetaTimelineTemplatePipelineObjectAssignedComponent } from "./templates/pipelineObjectAssgned";
import { MetaTimelineTemplateWorkflowExecutionStatusChangedComponent } from "./templates/workflowExecutionStatusChanged";
import { MetaTimelineTemplatePipelineObjectStatusChangedComponent } from "./templates/pipelineObjectStatusChanged";
import { MetaTimelineTemplateReportSendComponent } from "./templates/reportSend";
import { MetaTimelineTemplateFormPublishedComponent } from "./templates/formPublished";
import { MetaTimelineTemplateDocumentUploadedComponent } from "./templates/documentUploaded";
import { MetaTimelineTemplateDocumentDeletedComponent } from "./templates/documentDeleted";
import { MetaTimelineTemplateDocumentAssignedComponent } from "./templates/documentAssigned";
import { MetaTimelineTemplateDocumentUnassignedComponent } from "./templates/documentUnassigned";
import { MetaTimelineTemplateBulkEditComponent } from "./templates/bulkEdit";
import { MetaTimelineTemplateWorkflowExecutionErrorComponent } from "./templates/workflowExecutionError";
import { RouterModule } from "@angular/router";
import { MetaTimelineTemplateFormApprovedComponent } from "./templates/formApproved";
import { NzFormModule } from "ng-zorro-antd/form";
import { MetaColumnModule } from "../metaColumn/metaColumn.component";
import { MetaCheckboxModule } from "../metaCheckbox/metaCheckbox.component";
import { MetaIconModule } from "../metaIcon/metaIcon.component";
import { MetaTimelineTemplateTextComponent } from "./templates/text";
import { MetaTimelineTemplateFormWorkflowExecutionStartedComponent } from "./templates/formWorkflowExecutionStarted";
import { MetaTimelineTemplateFormWorkflowExecutionEndComponent } from "./templates/formWorkflowExecutionEnd";
import { MetaLiveCardComponent } from "../metaLiveCard/metaLiveCard.component";
import { MetaTimelineTemplateFormWorkflowExecutionAbortedComponent } from "./templates/formWorkflowExecutionAborted";
import { MetaTimelineTemplateWorkflowExecutionAbortedComponent } from "./templates/workflowExecutionAborted";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { MetaTranslateChildDirective, MetaTranslateComponent } from "../metaTranslate/metaTranslate.component";

export class MetaTimeline {
  id?: string;
  data?: MetaTimelineItem[] = [];
  mode?: MetaTimelineMode | string = MetaTimelineMode.left;
  pending? = false;
  reverse? = false;
  extra?: TemplateRef<any> = null;
  body?: TemplateRef<any> = null;
  footer?: TemplateRef<any> = null;
  content?: TemplateRef<any> = null;
  style?: MetaTimelineStyle | string = MetaTimelineStyle.default;
  expandable?: boolean;
  noDataLabel?: string = "Keine Ereignisse";
  noDataIcon?: string = "calendar-times";
  searchable?: boolean = false;
  idField?: string = "id";
  groupField?: string = "DatumAnlage";
  searchPlaceholder?: string = "Suche...";
  isLoading?: boolean;
  isLoadingMore?: boolean;
  highlight?: string;
  onSearch?: FormlyAttributeEvent | (() => void);
  checkableField?: string;
  checkableTooltip?: string;
}

export interface MetaTimelineItem {
  ID?: string;
  type?: string;
  label?: string;
  description?: string;
  groupDescription?: any;
  metadata?: {
    label?: string;
    icon?: string;
    link?: string;
    tooltip?: string;
  }[];
  participants?: MetaAvatarData[];
  data?: any;
  icon?: string;
  avatar?: string;
  avatarName?: string;
  link?: string;
  length?: number;
  color?: MetaTimelineColor;
  click?: () => void;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-timeline",
  template: `
    <ng-container *ngIf="ma.data?.length > 0 && !ma.isLoading">
      <nz-timeline [nzMode]="ma.mode" [nzPending]="ma.pending" [nzReverse]="ma.reverse" class="log">
        <ng-container *ngFor="let item of ma.data; trackBy: trackByID; let index = index">
          <nz-timeline-item
            [nzColor]="
              item.color === 'warning'
                ? 'blue'
                : item.color === 'success'
                  ? 'green'
                  : item.color === 'danger'
                    ? 'red'
                    : 'gray'
            "
            [nzDot]="item.avatar || item.style !== 'log' ? dotTemplate : null"
          >
            <ng-container
              *ngTemplateOutlet="ma.content ? ma.content : logItem; context: { $implicit: item }"
            ></ng-container>
          </nz-timeline-item>
          <ng-template #dotTemplate>
            <ng-container *ngIf="true; else elseTemplate">
              <div class="meta-timeline-icon" [style.backgroundColor]="setBgColor(item)">
                <i class="fas fa-{{ item.icon }}"></i>
              </div>
              <div
                *ngIf="item.length"
                nzTooltipTitle="In dieser Nachricht wurden mehrere ähnliche Aktionen zusammengefasst"
                class="fas fa-question-circle description"
                nz-tooltip
                class="log-group-count"
              >
                <i class="fas fa-layer-group"></i>
                <div class="log-count">
                  {{ item.length }}
                </div>
              </div>
            </ng-container>
            <ng-template #elseTemplate>
              <i class="fas fa-{{ item.icon }}"></i>
            </ng-template>
          </ng-template>
        </ng-container>
      </nz-timeline>
      <meta-loader *ngIf="ma.isLoadingMore" class="loader" [style.minHeight.px]="80"></meta-loader>
    </ng-container>
    <meta-loader *ngIf="ma.isLoading" [style.minHeight.px]="350"></meta-loader>
    <meta-empty
      *ngIf="ma.data?.length === 0 && !ma.isLoading"
      class="mt-5"
      [maParams]="{
        description: ma.noDataLabel,
        icon: ma.noDataIcon
      }"
    ></meta-empty>

    <ng-template #logItem let-item>
      <div
        class="item"
        [ngClass]="{ 'checkable-item': ma.checkableField, 'animation-check-item': item?.data._markAsChecked }"
      >
        <div *ngIf="ma.extra" class="extra">
          <ng-container *ngTemplateOutlet="ma.extra; context: { $implicit: item }"></ng-container>
        </div>
        <div *ngIf="!ma.extra" class="extra">
          <ng-container *ngTemplateOutlet="defaultExtra; context: { $implicit: item }"></ng-container>
        </div>
        <div *ngIf="item.metadata" class="metadata">
          <ng-container *ngFor="let meta of item.metadata">
            <ng-container [ngSwitch]="meta.type">
              <ng-container *ngSwitchCase="'tag'">
                <meta-tag
                  nz-tooltip
                  [nzTooltipTitle]="meta.tooltip"
                  [maParams]="{ label: meta.label, type: meta.color, maxWidth: 400, icon: meta.icon }"
                  [style.padding-right.px]="10"
                ></meta-tag>
              </ng-container>
              <ng-container *ngSwitchCase="'avatars'">
                <meta-avatar
                  [maParams]="{
                    data: meta.data,
                    type: 'ANW',
                    size: 26
                  }"
                ></meta-avatar>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <span class="metadata-item {{ meta.color ? 'text-' + meta.color : '' }}">
                  <i *ngIf="meta.icon" class="fas fa-{{ meta.icon }}"></i>
                  <meta-avatar
                    *ngIf="meta.avatar"
                    [maParams]="{
                      id: meta.avatar,
                      label: meta.label,
                      type: 'ANW',
                      size: 'tiny'
                    }"
                    class="mr-1"
                  ></meta-avatar>

                  <ng-container *ngIf="meta.link; else labelTpl">
                    <a [routerLink]="meta.link">
                      <ng-container *ngTemplateOutlet="labelTpl"></ng-container>
                    </a>
                  </ng-container>

                  <ng-template #labelTpl>
                    <span nz-tooltip [nzTooltipTitle]="meta.tooltip">{{ meta.label }}</span>
                  </ng-template>

                  <!-- ACTIVATE WHEN LINKS FOR USERS ARE IMPLEMENTED -->
                  <!--a *ngIf="meta.link" nz-tooltip [nzTooltipTitle]="meta.tooltip" href="{{meta.link}}" target="_blank">{{meta.label}}</a-->
                </span>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div *ngIf="item.file?.id | file | async as file; else elseTpl">
          <span
            *ngIf="!item.data?.operation"
            [style.white-space]="'break-spaces'"
            (click)="mediaLibraryService.previewFile(file)"
            class="link"
            [innerHtml]="item.label | metaSearchHighlight: ma.highlight"
          ></span>
        </div>
        <ng-template #elseTpl>
          <div>
            <span
              *ngIf="!item.data?.operation"
              [style.white-space]="'break-spaces'"
              [innerHtml]="item.label | metaSearchHighlight: ma.highlight"
            ></span>
          </div>
        </ng-template>
        <div *ngIf="item.file?.id | file | async as file" class="file">
          <meta-media-library-file-preview
            style="margin-top: 12px; margin-bottom: 12px"
            (click)="mediaLibraryService.previewFile(file)"
            [showInfo]="false"
            [file]="file"
          ></meta-media-library-file-preview>
        </div>
        <div *ngIf="ma.body && !item.data?.operation" class="body mt-4">
          <ng-container *ngTemplateOutlet="ma.body; context: { $implicit: item }"></ng-container>
        </div>
        <div
          id="description-{{ item[ma.idField] }}"
          class="description"
          [ngClass]="{ expandable: isExpandable(item), expanded: isExpanded[item[ma.idField]] }"
        >
          <ng-container *ngIf="!item.groupDescription || item.groupDescription?.length === 0">
            <ng-container *ngTemplateOutlet="tplTemplates; context: { item: item }"></ng-container>
          </ng-container>
          <h3
            *ngIf="item.title && !item.data?.operation"
            [innerHTML]="item.title | metaSearchHighlight: ma.highlight"
            id="inner-title-{{ item[ma.idField] }}"
            class="inner-title"
          ></h3>
          <span
            *ngIf="item.description && !item.data?.operation"
            [innerHTML]="item.description | metaSearchHighlight: ma.highlight"
            id="inner-description-{{ item[ma.idField] }}"
            class="inner-description {{ item.title ? 'text-mute' : '' }}"
          ></span>
          <div
            *ngIf="item.groupDescription?.length > 0"
            id="inner-description-{{ item[ma.idField] }}"
            class="inner-description"
          >
            <nz-timeline [nzMode]="ma.mode" [nzPending]="ma.pending" [nzReverse]="ma.reverse" class="default">
              <nz-timeline-item
                *ngFor="let groupItem of item.groupDescription; trackBy: trackByID"
                [nzColor]="
                  groupItem.color === 'warning'
                    ? 'blue'
                    : groupItem.color === 'success'
                      ? 'green'
                      : groupItem.color === 'danger'
                        ? 'red'
                        : 'gray'
                "
                [nzDot]="groupItem.icon ? dotTemplate : null"
              >
                <div class="item">
                  <div *ngIf="groupItem.metadata" class="metadata">
                    <span
                      *ngFor="let meta of groupItem.metadata"
                      class="metadata-item {{ meta.color ? 'text-' + meta.color : '' }}"
                    >
                      <i *ngIf="meta.icon" class="fas fa-{{ meta.icon }}"></i>
                      <span nz-tooltip [nzTooltipTitle]="meta.tooltip">{{ meta.label }}</span>
                    </span>
                  </div>
                  <div class="description">
                    <ng-container *ngTemplateOutlet="tplTemplates; context: { item: groupItem }"></ng-container>
                    <span
                      *ngIf="!item.data?.operation"
                      [innerHTML]="groupItem.description | metaSearchHighlight: ma.highlight"
                    ></span>
                  </div>
                  <meta-section
                    *ngIf="groupItem.participants?.length > 0"
                    [maParams]="{
                      sectionType: 'hr',
                      spacingBottom: 2,
                      spacingTop: 2
                    }"
                  ></meta-section>
                  <meta-avatar
                    *ngIf="groupItem.participants?.length > 0"
                    [maParams]="{
                      data: groupItem.participants,
                      size: 'small'
                    }"
                    class="mt-2"
                  ></meta-avatar>
                </div>
                <ng-template #dotTemplate>
                  <i class="fas fa-{{ groupItem.icon }}"></i>
                </ng-template>
              </nz-timeline-item>
            </nz-timeline>
          </div>
        </div>
        <meta-button
          *ngIf="isExpandable(item) && !isExpanded[item[ma.idField]]"
          [maParams]="{
            type: 'link',
            label: 'Mehr anzeigen',
            icon: 'angle-down'
          }"
          (click)="toggleExpand(item)"
        ></meta-button>
        <meta-button
          *ngIf="isExpandable(item) && isExpanded[item[ma.idField]]"
          [maParams]="{
            type: 'link',
            label: 'Weniger anzeigen',
            icon: 'angle-up'
          }"
          (click)="toggleExpand(item)"
        ></meta-button>
        <meta-section
          *ngIf="item.participants?.length > 0"
          [maParams]="{
            sectionType: 'hr',
            spacingBottom: 2,
            spacingTop: 2
          }"
        ></meta-section>
        <meta-avatar
          maType="ANW"
          *ngIf="item.participants?.length > 0"
          [maParams]="{
            data: item.participants,
            size: 'small'
          }"
        ></meta-avatar>
      </div>
      <ng-container *ngIf="item.style === 'log'">
        <meta-button
          *ngIf="item.data.comments?.length > 0 && !toggleComments[item.data.id]"
          [maParams]="{
            icon: 'comment-alt-lines',
            type: 'text',
            label: item.data.comments.length + ' ' + (item.data.comments.length === 1 ? 'Kommentar' : 'Kommentare')
          }"
          (click)="toggleComments[item.data.id] = !toggleComments[item.data.id]"
        ></meta-button>
        <meta-button
          *ngIf="item.data.operation?.id === 'FORM_UPDATED'"
          class="btn-comment"
          [nz-tooltip]="'Kommentieren'"
          [maParams]="{
            icon: 'comment-alt-lines',
            type: 'text'
          }"
          (click)="toggleComments[item.data.id] = !toggleComments[item.data.id]"
        ></meta-button>
        <div *ngIf="item.data?.comments?.length > 0" class="log-count btn-comment-count">
          {{ item.data.comments.length }}
        </div>
        <ng-container *ngIf="toggleComments[item.data.id]">
          <div class="ant-comment" *ngFor="let comment of item.data.comments">
            <div class="ant-comment-inner">
              <div class="ant-comment-avatar">
                <meta-avatar
                  nz-comment-avatar
                  [maParams]="{
                    id: comment.user.id,
                    label: comment.user.name,
                    type: 'ANW',
                    size: 'tiny'
                  }"
                ></meta-avatar>
              </div>
              <div class="ant-comment-content">
                <div class="ant-comment-content-author">
                  <span class="ant-comment-content-author-name">{{ comment.user.name }}</span
                  ><span [nz-tooltip]="comment.date | date: 'fullDate'" class="ant-comment-content-author-time">{{
                    moment(comment.date).fromNow()
                  }}</span>
                </div>
                <nz-comment-content class="ant-comment-content-detail">
                  <p>{{ comment.value }}</p>
                </nz-comment-content>
              </div>
            </div>
          </div>
          <div class="comment mt-4">
            <meta-avatar
              [maParams]="{
                id: (userService.user | async)?.id,
                type: 'ANW',
                label: (userService.user | async)?.vorName + ' ' + (userService.user | async)?.nachName,
                size: 'tiny'
              }"
            ></meta-avatar>
            <meta-input
              [maParams]="{
                placeholder: 'Kommentar verfassen...',
                editing: true
              }"
              [(ngModel)]="commentModel[item.data.id]"
              (keydown.enter)="createComment(commentModel[item.data.id], item); $event.target.blur()"
              [style.width.%]="100"
              class="pl-2"
            ></meta-input>
          </div>
        </ng-container>
      </ng-container>
    </ng-template>

    <ng-template #defaultExtra let-item>
      <div *ngIf="item.icon == 'sticky-note' && user.id == item.metadata[1].label">
        <meta-dropdown
          [maParams]="{
            icon: 'ellipsis-h',
            iconStyle: 'fas',
            size: 'small',
            items: [
              {
                label: 'Löschen',
                icon: 'trash',
                onClick: onRemoveItem.bind(this, field, item)
              }
            ]
          }"
        >
        </meta-dropdown>
      </div>
    </ng-template>

    <ng-template let-cardData #liveCardTemplate let-item="item">
      <div [routerLink]="cardData.link" class="timeline-live-card">
        <img
          class="timeline-live-card-img"
          loading="lazy"
          *ngIf="cardData.image"
          src="/api/v2/file/{{ cardData.image }}/download"
        />
        <div *ngIf="!cardData.image" class="timeline-live-icon">
          <i class="fa fa-{{ cardData.icon }}"></i>
        </div>
        <div class="timeline-live-inner">
          <span *ngIf="cardData.title">{{ cardData.title }}</span>
          <p *ngIf="cardData.content" [innerHTML]="cardData.content"></p>
          <div *ngIf="cardData.progress">
            <progress [value]="cardData.progress.percent"></progress>
            {{ cardData.progress.label || "" }}
          </div>
          <div *ngIf="cardData.actions">
            <button *ngFor="let a of cardData.actions">{{ a.title }}</button>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #tplTemplates let-item="item">
      <ng-container [ngSwitch]="item.data?.operation?.id">
        <meta-timeline-template-bulk-edit *ngSwitchCase="'BULK_EDIT'" [item]="item"></meta-timeline-template-bulk-edit>
        <meta-timeline-template-pipeline-object-moved
          *ngSwitchCase="'PIPELINE_OBJECT_MOVED'"
          [item]="item"
        ></meta-timeline-template-pipeline-object-moved>
        <meta-timeline-template-workflow-execution-started
          *ngSwitchCase="'WORKFLOW_EXECUTION_STARTED'"
          [item]="item"
        ></meta-timeline-template-workflow-execution-started>
        <meta-timeline-template-workflow-execution-ended
          *ngSwitchCase="'WORKFLOW_EXECUTION_ENDED'"
          [item]="item"
        ></meta-timeline-template-workflow-execution-ended>
        <meta-timeline-template-workflow-execution-status-changed
          *ngSwitchCase="'WORKFLOW_EXECUTION_STATUS_CHANGED'"
          [item]="item"
        ></meta-timeline-template-workflow-execution-status-changed>
        <meta-timeline-template-workflow-execution-error
          *ngSwitchCase="'WORKFLOW_EXECUTION_ERROR'"
          [item]="item"
        ></meta-timeline-template-workflow-execution-error>
        <meta-timeline-template-form-created
          *ngSwitchCase="'FORM_CREATED'"
          [item]="item"
        ></meta-timeline-template-form-created>
        <meta-timeline-template-form-unlocked
          *ngSwitchCase="'FORM_UNLOCKED'"
          [item]="item"
        ></meta-timeline-template-form-unlocked>
        <meta-timeline-template-form-locked
          *ngSwitchCase="'FORM_LOCKED'"
          [item]="item"
        ></meta-timeline-template-form-locked>
        <meta-timeline-template-form-updated
          *ngSwitchCase="'FORM_UPDATED'"
          [item]="item"
        ></meta-timeline-template-form-updated>
        <meta-timeline-template-form-published
          *ngSwitchCase="'FORM_PUBLISHED'"
          [item]="item"
        ></meta-timeline-template-form-published>
        <meta-timeline-template-form-approved
          *ngSwitchCase="'FORM_APPROVED'"
          [type]="'approved'"
          [item]="item"
        ></meta-timeline-template-form-approved>
        <meta-timeline-template-form-approved
          *ngSwitchCase="'FORM_DENIED'"
          [type]="'denied'"
          [item]="item"
        ></meta-timeline-template-form-approved>
        <meta-timeline-template-form-approved
          *ngSwitchCase="'FORM_TIMEOUT'"
          [type]="'timeout'"
          [item]="item"
        ></meta-timeline-template-form-approved>
        <meta-timeline-template-pipeline-object-assigned
          *ngSwitchCase="'PIPELINE_OBJECT_ASSIGNED'"
          [item]="item"
        ></meta-timeline-template-pipeline-object-assigned>
        <meta-timeline-template-pipeline-object-status-changed
          *ngSwitchCase="'PIPELINE_OBJECT_STATUS_CHANGED'"
          [item]="item"
        ></meta-timeline-template-pipeline-object-status-changed>
        <meta-timeline-template-report-send
          *ngSwitchCase="'REPORT_SEND'"
          [item]="item"
        ></meta-timeline-template-report-send>
        <meta-timeline-template-document-uploaded
          *ngSwitchCase="'DOCUMENT_UPLOADED'"
          [item]="item"
        ></meta-timeline-template-document-uploaded>
        <meta-timeline-template-document-uploaded
          *ngSwitchCase="'DOCUMENT_UPDATED'"
          [item]="item"
        ></meta-timeline-template-document-uploaded>
        <meta-timeline-template-document-deleted
          *ngSwitchCase="'DOCUMENT_DELETED'"
          [item]="item"
        ></meta-timeline-template-document-deleted>
        <meta-timeline-template-document-assigned
          *ngSwitchCase="'DOCUMENT_ASSIGNED'"
          [item]="item"
        ></meta-timeline-template-document-assigned>
        <meta-timeline-template-document-unassigned
          *ngSwitchCase="'DOCUMENT_UNASSIGNED'"
          [item]="item"
        ></meta-timeline-template-document-unassigned>
        <meta-timeline-template-form-workflow-execution-started
          *ngSwitchCase="'FORM_WORKFLOW_EXECUTION_STARTED'"
          [item]="item"
        ></meta-timeline-template-form-workflow-execution-started>
        <meta-timeline-template-form-workflow-execution-end
          *ngSwitchCase="'FORM_WORKFLOW_EXECUTION_END'"
          [item]="item"
        ></meta-timeline-template-form-workflow-execution-end>
        <meta-timeline-template-form-workflow-execution-aborted
          *ngSwitchCase="'FORM_WORKFLOW_EXECUTION_ABORTED'"
          [item]="item"
        ></meta-timeline-template-form-workflow-execution-aborted>
        <meta-timeline-template-workflow-execution-aborted
          *ngSwitchCase="'WORKFLOW_EXECUTION_ABORTED'"
          [item]="item"
        ></meta-timeline-template-workflow-execution-aborted>
        <meta-timeline-template-text [item]="item" *ngSwitchCase="'TEXT'"> </meta-timeline-template-text>
        <ng-container *ngSwitchCase="'TEMPLATE'">
          @if (item.data?.data?.key) {
            <meta-translate
              [ns]="'feed'"
              [data]="item.data?.data?.data || {}"
              [key]="item.data?.data?.key"
            ></meta-translate>
          }
        </ng-container>
      </ng-container>
    </ng-template>
  `,
  styleUrls: ["./metaTimeline.component.less"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MetaTimelineService],
})
export class MetaTimelineComponent extends MetaComponentBase {
  public toggleComments: boolean[] = [];
  public isExpanded: {} = {};
  public collapsedHeight = 100;
  public commentModel: {
    [id: string]: string;
    post?: string;
  } = {};
  public moment = moment;
  public user: any;

  get ma(): MetaTimeline {
    return super.ma;
  }

  constructor(
    public timelineService: MetaTimelineService,
    public metaActivityRepo: MetaActivityRepository,
    public userService: UserService,
    public mediaLibraryService: MetaMediaLibraryService,
  ) {
    super();
    super.maParams = new MetaTimeline();
  }

  @Input()
  public type: "hub" | "other" = "other";

  ngOnInit() {
    this.userService.getUser().subscribe((user) => {
      this.user = user;
    });
  }

  public trackByID = (index: number, item: any) => {
    return item[this.ma.idField];
  };

  public isExpandable(item: any) {
    const innerElem = document.getElementById(`inner-description-${item[this.ma.idField]}`);
    if (innerElem) {
      return innerElem.offsetHeight > this.collapsedHeight;
    } else {
      return false;
    }
  }

  public toggleExpand = (item: any) => {
    return setTimeout(() => {
      this.isExpanded[item[this.ma.idField]] = !this.isExpanded[item[this.ma.idField]];
      const innerElem = document.getElementById(`inner-description-${item[this.ma.idField]}`);
      const elem = document.getElementById(`description-${item[this.ma.idField]}`);
      if (this.isExpanded[item[this.ma.idField]]) {
        elem.style.height = `${innerElem.offsetHeight + 30}px`;
      } else {
        elem.style.height = `${this.collapsedHeight}px`;
      }
      this.changeDetectorRef.markForCheck();
    });
  };

  public dateDiff(currentDate: string, previousDate: string) {
    if (!currentDate) {
      currentDate = new Date().toISOString();
    }
    if (!previousDate && previousDate !== null) {
      previousDate = new Date().toISOString();
    }
    if (
      moment(previousDate).diff(moment(currentDate), "days") !== 0 ||
      isNaN(moment(previousDate).diff(moment(currentDate), "days"))
    ) {
      return moment(currentDate).calendar(null, {
        lastDay: "[Gestern]",
        sameDay: "[Heute]",
        nextDay: "[Morgen]",
        lastWeek: "[Letzten] dddd",
        nextWeek: "dddd",
        sameElse: "L",
      });
    } else {
      return null;
    }
  }

  async createComment(comment: string, item: any) {
    await this.timelineService.createComment(comment, item.data);
    this.commentModel[item.data.id] = null;
    this.toggleComments[item[this.ma.idField]] = true;
    this.changeDetectorRef.markForCheck();
  }

  public async onRemoveItem(field, item) {
    this.metaActivityRepo.deleteNote(item.id).subscribe({
      next: (value) => {
        this.ma.data = this.ma.data.filter((o) => o["id"] !== item.id);
        this.changeDetectorRef.markForCheck();
      },
    });
  }

  public setBgColor(item: any) {
    return getComputedStyle(document.documentElement).getPropertyValue(
      `--operation-color-${item.data.operation?.id || "default"}`,
    );
  }
}

@NgModule({
  declarations: [
    MetaTimelineComponent,
    MetaTimelineTemplatePipelineObjectMovedComponent,
    MetaTimelineTemplateWorkflowExecutionEndedComponent,
    MetaTimelineTemplateWorkflowExecutionStartedComponent,
    MetaTimelineTemplateFormCreatedComponent,
    MetaTimelineTemplateFormUnlockedComponent,
    MetaTimelineTemplateFormLockedComponent,
    MetaTimelineTemplateFormUpdatedComponent,
    MetaTimelineTemplatePipelineObjectAssignedComponent,
    MetaTimelineTemplateWorkflowExecutionStatusChangedComponent,
    MetaTimelineTemplatePipelineObjectStatusChangedComponent,
    MetaTimelineTemplateReportSendComponent,
    MetaTimelineTemplateFormPublishedComponent,
    MetaTimelineTemplateDocumentUploadedComponent,
    MetaTimelineTemplateDocumentDeletedComponent,
    MetaTimelineTemplateDocumentAssignedComponent,
    MetaTimelineTemplateDocumentUnassignedComponent,
    MetaTimelineTemplateBulkEditComponent,
    MetaTimelineTemplateWorkflowExecutionErrorComponent,
    MetaTimelineTemplateFormApprovedComponent,
    MetaTimelineTemplateTextComponent,
    MetaTimelineTemplateFormWorkflowExecutionStartedComponent,
    MetaTimelineTemplateFormWorkflowExecutionEndComponent,
    MetaTimelineTemplateFormWorkflowExecutionAbortedComponent,
    MetaTimelineTemplateWorkflowExecutionAbortedComponent,
    MetaTimelineTemplateEntityWorkflowExecutionStartedComponent,
  ],
  imports: [
    CommonModule,
    FormlyModule,
    NzWaveModule,
    NzButtonModule,
    NzPopconfirmModule,
    NzTimelineModule,
    MetaAvatarModule,
    MetaLoaderModule,
    MetaEmptyModule,
    NzToolTipModule,
    MetaSectionModule,
    MetaButtonModule,
    MetaInputModule,
    MetaMediaLibraryModule,
    MetaDrodpownModule,
    NzCommentModule,
    FormsModule,
    PipesModule,
    MetaTagModule,
    MetaTooltipModule,
    RouterModule,
    NzFormModule,
    MetaColumnModule,
    MetaCheckboxModule,
    MetaIconModule,
    MetaLiveCardComponent,
    NgxTolgeeModule,
    MetaTranslateComponent,
    MetaTranslateChildDirective,
  ],
  exports: [MetaTimelineComponent],
  providers: [MetaTimelineService],
})
export class MetaTimelineModule {}
