/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ExposeProperty, MetaFormComponent, MetaFormComponentInfo } from "./baseComponent";
import { MetaFormLayoutComponent } from "./layoutComponent";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-container",
  type: "container",
  name: "Container",
  icon: "box",
  palletGroup: "layout",
})
export class ContainerComponent<Sources = {}, ComponentType = []> extends MetaFormLayoutComponent {
  @ExposeProperty() public children: MetaFormComponent[] = [] as any;

  constructor() {
    super();
  }

  public toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        hasCondition: !!this.condition,
      },
    });
  }
}
