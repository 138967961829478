/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UploadActionHandler {
  async handle(action: any) {
    const [fileHandle] = await (window as any).showOpenFilePicker();
    const file = (await fileHandle.getFile()) as File;

    const data = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    return {
      files: [
        {
          data,
          name: file.name,
          type: file.type,
          size: file.size,
        },
      ],
    };
  }
}
