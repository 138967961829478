/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { IsAlphanumeric, IsString, IsOptional } from "class-validator";

export class SecurpharmVerifyRequest {
  @IsString()
  pc: string;
  @IsString()
  sn: string;
  @IsString()
  lot: string;
  @IsAlphanumeric()
  exp: string;
}

export class SecurpharmUpdateRequest extends SecurpharmVerifyRequest {
  @IsString()
  @IsOptional()
  action?: string;

  @IsString()
  @IsOptional()
  act?: string;

  @IsOptional()
  reftx: any;
}
