/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { MetaFormComponent } from "./components";

export interface IFormComponentFactory {
  createElement: (attributes, children: any[]) => any;
}

export const ComponentFactories = new Map<string, IFormComponentFactory>();
export const SerialisationTypeMap: Record<string, typeof MetaFormComponent> = {};
