/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */
import { schema } from "ngx-editor";
import { Node, NodeSpec, Schema } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import { getSchemaFromPath } from "../metaVaribables/getSchemaFromPath";
import { getVariableTypeColor } from "../metaVaribables/getVariableTypeColor";
import { getVariableTypeIcon } from "../metaVaribables/getVariableTypeIcon";

const EMPTY_VALUE = "{{__empty__}}" as const;

export function getVarSchema(jsonSchema: any, click: (node: Node, domNode: HTMLElement) => void) {
  const VarNodeSpec: NodeSpec = {
    // Dinosaurs have one attribute, their type, which must be one of
    // the types defined above.
    // Brontosaurs are still the default dino.
    attrs: { var: { default: EMPTY_VALUE } },
    inline: true,
    group: "inline",
    draggable: true,
    content: "text*",

    // These nodes are rendered as images with a `dino-type` attribute.
    // There are pictures for all dino types under /img/dino/.
    toDOM: (node) => {
      const v = node.attrs.var;
      const dom = document.createElement("span");

      if (v === EMPTY_VALUE) {
        dom.innerText = "Keine Variable ausgewählt…";
        dom.style.backgroundColor = getVariableTypeColor("empty");
      } else {
        const s = getSchemaFromPath(jsonSchema, v);
        const color = getVariableTypeColor(s?.schema?.type);
        const icon = getVariableTypeIcon(s?.schema);
        dom.innerHTML = `<i class="fa fa-${icon}" style="margin-right: 6px"></i> ${s?.name || v}`;
        dom.style.backgroundColor = color;
      }

      dom.dataset["var"] = v;
      dom.addEventListener("dblclick", () => click(node, dom));
      return dom;
    },
    // When parsing, such an image, if its type matches one of the known
    // types, is converted to a dino node.
    parseDOM: [
      {
        tag: "span[data-var]",
        getAttrs: (dom: HTMLElement) => {
          return {
            ...dom.dataset,
          };
        },
      },
    ],
  };
  const VarNodeSerializerSpec: NodeSpec = {
    ...VarNodeSpec,
    toDOM: (node) => {
      return document.createTextNode(node.attrs.var);
    },
  };

  const editorSchema = new Schema({
    nodes: schema.spec.nodes.addBefore("image", "dino", VarNodeSpec),
    marks: schema.spec.marks,
  });

  let lc = 0;

  const plugin = new Plugin({
    view: (view) => {
      const l = (event: KeyboardEvent) => {
        if (event.key === "{") {
          lc++;
        } else {
          lc = 0;
        }
        if (lc === 2) {
          const from = view.state.selection.from - 1;
          const to = view.state.selection.to;
          const node = editorSchema.nodes["dino"].create({ var: EMPTY_VALUE });
          const trans = view.state.tr.replaceRangeWith(from, to, node);
          view.dispatch(trans);
          click(node, view.nodeDOM(from) as HTMLElement);
          lc = 0;
          event.preventDefault();
          event.stopPropagation();
        }
      };
      view.dom.addEventListener("keypress", l);
      return {
        destroy: () => view.dom.removeEventListener("keypress", l),
      };
    },
  });

  return {
    schema: editorSchema,
    serializerSchema: new Schema({
      nodes: schema.spec.nodes.addBefore("image", "dino", VarNodeSerializerSpec),
      marks: schema.spec.marks,
    }),
    plugin,
  };
}
