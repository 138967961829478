/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2021
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2021
 */

import { IsArray, IsEnum, IsNumber, IsOptional, IsString } from "class-validator";
import { PipelineType } from "@meta/enums";

export class PipelineBaseRequest {
  @IsEnum(PipelineType)
  Typ: PipelineType;
  @IsString()
  Label: string;
  @IsString()
  @IsOptional()
  DatumStart?: string;
  @IsString()
  @IsOptional()
  DatumEnde?: string;
}

export class PipelineCreateOneRequest extends PipelineBaseRequest {
}

export class PipelineUpdateOneRequest extends PipelineBaseRequest {
}

export class PhaseBaseRequest {
  @IsString()
  ID: string;
  @IsString()
  Label: string;
  @IsString()
  Pipeline: any;
  @IsNumber()
  Reihenfolge?: number;
  @IsNumber()
  Status?: number;
}

export class PhaseCreateManyRequest {
  @IsArray()
  createdPhases: Omit<PhaseBaseRequest, "ID">[];
}

export class PhaseUpdateManyRequest {
  @IsArray()
  updatedPhases: PhaseBaseRequest[];
}
