/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, Input, OnInit } from "@angular/core";
import { IFeedResultItem } from "@meta/api-interfaces";
import { MetaHelperService } from "../../../services/metaHelper.service";

export class MetaTimelineTemplateReportSend {
  data: IFeedResultItem<{
    subject: string;
    to: string[];
    cc: string[];
    bcc: string[];
  }>;
}

@Component({
  selector: "meta-timeline-template-report-send",
  template: `{{ item.data.entity.name }}
    <a [routerLink]="item.data.entity.link">
      <meta-tag [maParams]="{ label: tplLabel, color: color, colorText: colorText, maxWidth: 250 }"></meta-tag>
    </a>
    wurde
    <ng-container *ngIf="to.length > 0"
      >an
      <ng-container *ngFor="let t of to; let i = index"
        ><a href="mailto:{{ t }}"
          ><span class="log-default">{{ t }}</span></a
        ><span *ngIf="i < to.length - 2">, </span>
        <span *ngIf="i === to.length - 2"> und&nbsp;</span>
      </ng-container>
    </ng-container>
    versendet.
    <ng-template #tplLabel
      ><i class="far fa-{{ item.data.entity.icon }}"></i>{{ item.data.entity.displayId || "" }}
      {{ item.data.entity.title }}
    </ng-template>`,
})
export class MetaTimelineTemplateReportSendComponent implements OnInit {
  @Input() public item: MetaTimelineTemplateReportSend;

  public color: string;
  public colorText: string;
  public to = [];

  constructor(public metaHelperService: MetaHelperService) {}

  ngOnInit() {
    this.color = this.metaHelperService.generateColor(this.item.data.entity.name);
    this.colorText = getComputedStyle(document.documentElement).getPropertyValue("--component-background");
    if (this.item.data.data) {
      this.to = [...this.item.data.data.to, ...this.item.data.data.cc, ...this.item.data.data.bcc].filter((e) => !!e);
    }
  }
}
