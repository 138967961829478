/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { ColumnState } from "@ag-grid-community/core";
import { MetaFormCondition } from "@meta/forms";

export enum TableView {
  table = "table",
  grid = "grid",
}

export const localStorageKey = "tableState-v7";

export interface IMetaTableGetParams {
  formId: string;
  fieldId: string;
  condition: MetaFormCondition;
  params: unknown;
  silentUpdate?: boolean;
}

export interface IMetaTableStore {
  id: string | number | symbol;
  label?: string;
  value?: string;
  filter: string;
  fieldId: string;
}

export interface IMetaTableResponse {
  resultSet: Record<string, any>[];
  displayResultSet: Record<string, any>[];
  metaResultSet: Record<string, any>[];
  total?: number;
  skip: number;
}

export class MetaTableState {
  cols?: ColumnState[];
  filt?: { [key: string]: any } = {};
  filterBar?: any;
  searchString?: string;
  view?: TableView;
}
