/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { DataSourceType } from "@meta/database/lib/types";
import { deepMerge } from "../deepMerge";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { DataSourceParameters, MetaFormBaseComponent, MetaFormComponentProperty } from "./baseComponent";
import { FormEventActionHandler, SchedulerFilter } from "@meta/forms";

export interface ISchedulerAddItemEvent {
  group: string;
}

export interface ISchedulerAddGroupEvent {}

export interface ISchedulerItem {
  id: string | number;
  text: string;
  start_date: Date | string | number;
  duration: number;
  group?: string | number;
  icon?: string;
  iconLabel?: string;
  color?: string;
  parent?: string | number;
  progress?: number;
  open?: boolean;
  owner?: Array<{
    resource_id: string | number;
    value: any;
    start_date: string | Date | number;
    end_date: string | Date | number;
  }>;
}

export interface ISchedulerLink {
  source: string | number;
  target: string | number;
  id: string | number;
  type: number;
}

export interface ISchedulerColumn {
  id: string | number;
  label: string;
  value: string;
  suffix?: string;
  align?: string;
  width?: string | number;
}

export interface ISchedulerDatasourceResponse {
  links?: Array<ISchedulerLink>;
  items: Array<ISchedulerItem>;
  groups: Array<{
    title: string;
    id: string | number;
    icon?: string;
    color?: string;
  }>;
  resources?: Array<{
    id: string | number;
    text: string;
    unit: string;
  }>;
  columns?: Array<ISchedulerColumn>;
}

export abstract class MetaGantAndSchedulerBase extends MetaFormBaseComponent {
  public datasource: FormEventActionHandler<
    {
      from: Date;
      to: Date;
      searchString?: string;
    } & Record<string, string | number | boolean>,
    ISchedulerDatasourceResponse
  >;

  @MetaFormComponentProperty({
    name: "schedulerAutocompleteDataSource",
    type: "dataSource",
    required: true,
  })
  public autocompleteDataSource?: DataSourceType;

  @MetaFormComponentProperty({
    name: "schedulerItemClick",
    type: "action",
    required: false,
  })
  public itemClick?: FormEventActionHandler<ISchedulerItem>;

  @MetaFormComponentProperty({
    name: "schedulerSectionClick",
    type: "action",
    required: false,
  })
  public sectionClick?: FormEventActionHandler<any>;

  @MetaFormComponentProperty({
    name: "addItem",
    type: "action",
    required: false,
  })
  public addItem?: FormEventActionHandler<ISchedulerAddItemEvent>;

  @MetaFormComponentProperty({
    name: "deleteItem",
    type: "action",
    required: false,
  })
  public deleteItem?: FormEventActionHandler<any>;

  @MetaFormComponentProperty({
    name: "addItem",
    type: "action",
    required: false,
  })
  public addGroup?: FormEventActionHandler<ISchedulerAddGroupEvent>;

  @MetaFormComponentProperty({
    name: "deleteGroup",
    type: "action",
    required: false,
  })
  public deleteGroup?: FormEventActionHandler<any>;

  @MetaFormComponentProperty({
    name: "schedulerItemChange",
    type: "action",
    required: false,
  })
  public itemChange?: FormEventActionHandler<ISchedulerItem>;

  @MetaFormComponentProperty({
    name: "schedulerItemChange",
    type: "action",
    required: false,
  })
  public linkAdd?: FormEventActionHandler<{
    source: ISchedulerItem;
    target: ISchedulerItem;
    type: number;
  }>;

  @MetaFormComponentProperty({
    name: "schedulerItemChange",
    type: "action",
    required: false,
  })
  public linkRemove?: FormEventActionHandler<{
    source: ISchedulerItem;
    target: ISchedulerItem;
    id: string | number;
  }>;

  @MetaFormComponentProperty({
    name: "schedulerItemChange",
    type: "action",
    required: false,
  })
  public sortChange?: FormEventActionHandler<{ item: ISchedulerItem; from: number; to: number }>;

  @MetaFormComponentProperty({
    name: "schedulerLegend",
    type: "action",
    required: false,
  })
  public legend?: FormEventActionHandler;

  @MetaFormComponentProperty({
    name: "availabilities",
    type: "action",
    required: false,
  })
  public availabilities?: FormEventActionHandler;

  @MetaFormComponentProperty({
    name: "schedulerFilters",
    type: "children",
    required: false,
    allowedChildren: ["scheduler-filter"],
  })
  public filters?: SchedulerFilter[] = [];
  @MetaFormComponentProperty({
    name: "Height",
    type: "text",
    tab: "style",
  })
  public height?: number | string;

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        height: this.height,
      },
    });
  }
}
