/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponent, MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-icon",
  type: "icon",
  icon: "image",
  name: "Icon",
})
export class IconComponent extends MetaFormComponent {
  @MetaFormComponentProperty({
    name: "Icon",
    type: "text",
    tab: "style",
  })
  public icon: string;
  @MetaFormComponentProperty({
    name: "Icon Size",
    type: "number",
    tab: "style",
  })
  public size: number;
  @MetaFormComponentProperty({
    name: "Icon Delay",
    type: "number",
    tab: "style",
  })
  public delay: number;
  @MetaFormComponentProperty({
    name: "Icon Scale",
    type: "number",
    tab: "style",
  })
  public scale: number;

  @MetaFormComponentProperty({
    name: "Style",
    type: "select",
    tab: "style",
    default: "default",
    values: [
      {
        label: "originalColors",
        value: "originalColors",
      },
      {
        label: "light",
        value: "light",
      },
      {
        label: "dark",
        value: "dark",
      },
      {
        label: "default",
        value: "default",
      },
    ],
  })
  public style?: "originalColors" | "light" | "dark" | "default" = "default";

  public static create(opts: Partial<IconComponent>) {
    const item = new IconComponent();
    Object.assign(item, opts);
    return item;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      key: this.id,
      type: `meta-${this.constructor["meta"]["type"]}`,
      props: {
        icon: this.icon,
        size: this.size,
        delay: this.delay,
        scale: this.scale,
        style: this.style,
      },
    });
  }
}
