/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";

@Component({
  selector: "meta-form-app",
  template: `<div class="app-wrapper">
    <meta-form-base></meta-form-base>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaFormAppComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
