import { Pipe } from "@angular/core";

@Pipe({
  name: "argonRouterLink",
  pure: true,
  standalone: true,
})
export class ArgonRouterLink {
  transform(url: string, type: "url" | "params"): any {
    if (!url) {
      return url;
    }
    const [baseUrl, params] = url.split("?");
    if (type === "url") {
      return baseUrl;
    } else {
      if (params) {
        return Object.fromEntries(new URLSearchParams(params).entries());
      } else {
        return {};
      }
    }
  }
}
