/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IGetTreeDataOptions } from "./metaTreeview.interfaces";

export interface TreeNode {
  title: string;
  prefix: string;
  suffix: string;
  id: string[];
  link: string;
  key: string;
  expandable: boolean;
  isLeaf: boolean;
  expanded: boolean;
  level: number;
  loading: boolean;
}
@Injectable({
  providedIn: "root",
})
export class MetaTreeviewService {
  constructor(private readonly httpClient: HttpClient) {}

  public getTreeData(opts: IGetTreeDataOptions) {
    return this.httpClient.post<TreeNode[]>(`/forms/${opts.formId}/treeData/${opts.fieldId}`, {
      skip: 0,
      take: 100,
      data: {
        _id: opts.id,
      },
      params: {
        parentKey: opts.parentKey,
      },
      _ctx: opts._ctx,
    });
  }
}
