/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

export function deepMerge<A = any, B = any>(target: A, source: B): A & B {
  for (const [key, val] of Object.entries(source)) {
    if (Array.isArray(val)) {
      target[key] = [...new Set([...(target[key] || []), ...val])];
    } else if (val !== null && typeof val === `object`) {
      if (target[key] === undefined) {
        target[key] = new val.__proto__.constructor();
      }
      target[key] = deepMerge(target[key], val);
    } else {
      target[key] = val;
    }
  }
  return target as any;
}
