/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NzSliderComponent } from "ng-zorro-antd/slider";
import { GetFileIcon, MetaMediaLibraryComponent } from "./metaMediaLibrary.component";
import { MetaMediaLibraryService } from "./metaMediaLibrary.service";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzPipesModule } from "ng-zorro-antd/pipes";
import { NzButtonModule } from "ng-zorro-antd/button";
import { MetaMediaLibraryUploadModalComponent } from "./metaMediaLibraryUploadModal.component";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { MetaMediaLibraryFilePreviewComponent } from "./metaMediaLibraryFilePreview.component";
import { MetaDatepickerModule } from "../metaDatepicker/metaDatepicker.component";
import { MetaMediaLibraryFileInfoComponent } from "./metaMediaLibraryFileInfo.component";
import { MetaMediaLibraryShareComponent } from "./share/metaMediaLibraryShare.component";
import { MetaMediaLibraryDeleteComponent } from "./delete/metaMediaLibraryDelete.component";
import { RouterModule } from "@angular/router";
import { MetaMediaLibraryEditComponent } from "./edit/metaMediaLibraryEdit.component";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MetaButtonModule } from "../metaButton/metaButton.component";
import { MetaInputModule } from "../metaInput/metaInput.component";
import { MetaEmptyModule } from "../metaEmpty/metaEmpty.component";
import { MetaTextareaModule } from "../metaTextarea/metaTextarea.component";
import { MetaSelectModule } from "../metaSelect/metaSelect.component";
import { MetaFieldWrapperModule } from "../metaFieldWrapper/metaFieldWrapper.component";
import { MetaSwitchModule } from "../metaSwitch/metaSwitch.component";
import { MetaFilePipe } from "./file.pipe";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { MetaAvatarModule } from "../metaAvatar/metaAvatar.component";
import { MetaLiveCardComponent } from "../metaLiveCard/metaLiveCard.component";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { NzImageModule } from "ng-zorro-antd/image";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzDescriptionsModule } from "ng-zorro-antd/descriptions";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { MetaMediaLibraryPlayerComponent } from "./metaMediaLibraryPlayer.component";

@NgModule({
  declarations: [
    MetaMediaLibraryComponent,
    MetaMediaLibraryUploadModalComponent,
    MetaMediaLibraryFilePreviewComponent,
    GetFileIcon,
    MetaMediaLibraryFileInfoComponent,
    MetaMediaLibraryShareComponent,
    MetaMediaLibraryDeleteComponent,
    MetaMediaLibraryEditComponent,
    MetaFilePipe,
  ],
  providers: [MetaMediaLibraryService],
  imports: [
    CommonModule,
    NzModalModule,
    MetaButtonModule,
    NzInputModule,
    NzSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NzPipesModule,
    NzButtonModule,
    NzUploadModule,
    NzRadioModule,
    MetaEmptyModule,
    MetaInputModule,
    MetaFieldWrapperModule,
    MetaTextareaModule,
    MetaDatepickerModule,
    MetaSelectModule,
    MetaSwitchModule,
    RouterModule,
    NzTableModule,
    NzDividerModule,
    NzCollapseModule,
    NzCardModule,
    NzDropDownModule,
    NzBreadCrumbModule,
    DragDropModule,
    NzProgressModule,
    MetaAvatarModule,
    MetaLiveCardComponent,
    NgxTolgeeModule,
    NzImageModule,
    NzTagModule,
    NzDescriptionsModule,
    NzAlertModule,
    MetaMediaLibraryPlayerComponent,
    NzSliderComponent,
  ],
  exports: [MetaMediaLibraryComponent, MetaMediaLibraryFilePreviewComponent, MetaFilePipe, GetFileIcon],
})
export class MetaMediaLibraryModule {}
