/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Injectable, TemplateRef } from "@angular/core";
import { BehaviorSubject, firstValueFrom, Observable, Subject } from "rxjs";
import { Socket } from "ngx-socket-io";
import { IPushMessageAction, PushMessage } from "@meta/api-interfaces";
import { Router } from "@angular/router";
import { ApproveService } from "../approve/approve.service";
import { HttpClient } from "@angular/common/http";
import { UserService } from "../../../../../../libs/ui/src/lib/services/user.service";
import { filter, map } from "rxjs/operators";
import { MetaModalService } from "../../../../../../libs/ui/src/lib/services/metaModalService";
import { HubService } from "../hub/hub.service";

export interface IArgonNotificationActionOptions {
  label: string | { key: string; params: Record<string, any> };
  color?: "primary" | "danger" | "default";
  click: (ref: IArgonNotificationRef, insideNotificationCenter: boolean) => any;
}

export interface IArgonNotificationOptions {
  duration?: number;
  title: string | TemplateRef<any>;
  content?: string | TemplateRef<any> | { key: string; params?: Record<string, any> };
  icon?: string;
  actions?: IArgonNotificationActionOptions[];
  priority?: number;
  closeable?: boolean;
  data?: Record<string, any>;
  playSound?: boolean;
  key?: string;
  type: "success" | "info" | "warning" | "error" | "silent" | "call" | "action";
  removable: boolean;
  group?: string;
  date: number;
  delay?: number;
}

export class IArgonNotificationRef {
  private readonly closeSubject = new Subject<boolean>();
  public readonly close$ = this.closeSubject.asObservable();
  private timeoutRef: any;
  public readonly titleTemplate: TemplateRef<any>;
  public readonly contentTemplate: TemplateRef<any>;
  public readonly date: Date;
  private readonly key: string;

  constructor(public options: IArgonNotificationOptions) {
    this.options = {
      duration: 3500,
      closeable: true,
      priority: 0,
      data: {},
      playSound: false,
      actions: [],
      ...options,
    };
    this.date = options.date ? new Date(options.date) : new Date();
    this.key = this.options.key;
    if (this.options.duration > 0) {
      this.timeoutRef = setTimeout(() => this.close(), this.options.duration);
    }
    if (this.options.title instanceof TemplateRef) {
      this.titleTemplate = this.options.title;
    }
    if (this.options.content instanceof TemplateRef) {
      this.contentTemplate = this.options.content;
    }
    if (this.options.playSound) {
      this.playSound();
    }
  }

  public close(deleteMessage = false) {
    this.pauseTimeout();
    this.closeSubject.next(deleteMessage);
    this.closeSubject.complete();
  }

  public pauseTimeout() {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
      this.timeoutRef = null;
    }
  }

  public resetTimeout() {
    this.pauseTimeout();
    this.resumeTimeout();
  }

  public resumeTimeout() {
    if (this.options.duration > 0) {
      this.timeoutRef = setTimeout(() => this.close(), this.options.duration);
    }
  }

  private playSound(sound = "new-notification") {
    const audio = new Audio(`/assets/sounds/notification/${sound}.mp3`);
    audio.volume = 0.2;
    audio.play().catch(() => {});
  }
}

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  public selectedTabIndex = 0;
  public readonly pastNotifications$: Observable<IArgonNotificationRef[]>;
  public readonly pastActions$: Observable<IArgonNotificationRef[]>;

  public readonly notifications$: Observable<IArgonNotificationRef[]>;
  public readonly notificationCenterVisible$: Observable<boolean>;
  public readonly notificationCenterUnread$: Observable<number>;
  public readonly silentNotification$: Observable<PushMessage>;

  private readonly notificationsSubject: BehaviorSubject<IArgonNotificationRef[]>;
  private readonly pastNotificationsSubject: BehaviorSubject<IArgonNotificationRef[]>;
  private readonly notificationCenterVisibleSubject: BehaviorSubject<boolean>;
  private readonly notificationCenterUnreadSubject: BehaviorSubject<number>;
  private readonly silentNotificationSubject: Subject<PushMessage>;

  constructor(
    private socketService: Socket,
    private readonly router: Router,
    private readonly approveService: ApproveService,
    private readonly http: HttpClient,
    private readonly userService: UserService,
    private readonly modalService: MetaModalService,
    private readonly hubService: HubService,
  ) {
    this.notificationsSubject = new BehaviorSubject<IArgonNotificationRef[]>([]);
    this.notifications$ = this.notificationsSubject.asObservable();

    this.pastNotificationsSubject = new BehaviorSubject<IArgonNotificationRef[]>([]);
    this.pastNotifications$ = this.pastNotificationsSubject.asObservable().pipe(
      map((m) => {
        return m.filter((n) => n.options.type !== "action");
      }),
    );
    this.pastActions$ = this.pastNotificationsSubject.asObservable().pipe(
      map((m) => {
        return m.filter((n) => n.options.type === "action");
      }),
    );
    this.notificationCenterVisibleSubject = new BehaviorSubject<boolean>(false);
    this.notificationCenterVisible$ = this.notificationCenterVisibleSubject.asObservable();

    this.notificationCenterUnreadSubject = new BehaviorSubject(0);
    this.notificationCenterUnread$ = this.notificationCenterUnreadSubject.asObservable();

    this.silentNotificationSubject = new Subject<PushMessage>();
    this.silentNotification$ = this.silentNotificationSubject.asObservable();

    this.initialize();
    userService.user
      .pipe(
        filter((e) => !!e),
        //debounceTime(350)
      )
      .subscribe(() => this.loadPastNotifications());

    this.router.events.subscribe(() => this.hideNotificationCenter());
  }

  public toggleNotificationCenter() {
    this.notificationCenterVisibleSubject.next(!this.notificationCenterVisibleSubject.value);
  }

  public showNotificationCenter(tabIndex = 0) {
    this.selectedTabIndex = tabIndex;
    this.notificationCenterVisibleSubject.next(true);
  }
  public hideNotificationCenter() {
    this.notificationCenterVisibleSubject.next(false);
  }

  public notify(options: IArgonNotificationOptions): IArgonNotificationRef {
    const ref = new IArgonNotificationRef(options);
    setTimeout(() => {
      this.notificationsSubject.next([ref, ...this.notificationsSubject.value]);
      ref.close$.subscribe(() => this._close(ref));
    }, options.delay);
    return ref;
  }

  private _close(ref: IArgonNotificationRef) {
    const values = [...this.notificationsSubject.value];
    const index = values.indexOf(ref);
    if (index !== -1) {
      values.splice(index, 1);
      this.notificationsSubject.next(values);
    }
  }

  public loadPastNotifications() {
    this.http.get("notifications").subscribe((pushMessages: PushMessage[]) => {
      pushMessages.forEach((pushMessage) => {
        if (pushMessage.type === "silent") {
          this.silentNotificationSubject.next(pushMessage);
        } else {
          const options = this.pushToNotificationOptions(pushMessage);
          this.addToNotificationCenter({
            ...options,
            playSound: false,
            duration: 0,
          });
          if (pushMessage.alwaysOnTop) {
            this.notify({
              ...options,
              playSound: false,
              duration: 0,
            });
          }
        }
      });
    });
  }

  private initialize() {
    this.initializePhone();
    this.initializeNotificationCenter();
  }

  private pushToNotificationOptions(p: PushMessage): IArgonNotificationOptions {
    return {
      title: p.title,
      content: p.message,
      duration: p.duration !== undefined ? p.duration : 5000,
      playSound: p.sound,
      key: p.id,
      type: p.type,
      removable: p.removable,
      closeable: !p.alwaysOnTop,
      date: p.date,
      group: p.group,
      actions: (p.actions || []).map((a) => {
        return {
          label: a.title,
          click: (ref, insideNotificationCenter: boolean) => this.handleAction(a, p, ref, insideNotificationCenter),
        };
      }),
    };
  }

  private initializeNotificationCenter() {
    this.socketService.fromEvent<{ keys: string[] }>("removePushMessage").subscribe(({ keys }) => {
      [
        ...this.pastNotificationsSubject.value.filter((e) => keys.includes(e.options.key)),
        ...this.notificationsSubject.value.filter((e) => keys.includes(e.options.key)),
      ].forEach((ref) => ref.close());
    });

    this.socketService.fromEvent<PushMessage>("updatePushMessage").subscribe((p) => {
      this.updateNotification(p.id, p);
    });

    this.socketService.fromEvent<PushMessage>("pushMessage").subscribe((p) => {
      if (p.type === "silent") {
        this.silentNotificationSubject.next(p);
      } else {
        const options = this.pushToNotificationOptions(p);
        this.addToNotificationCenter({
          ...options,
          duration: 0,
        });
        this.notify(options);
      }
    });
  }

  private updateNotification(id: string, changes: Partial<PushMessage>) {
    const activeRef = this.notificationsSubject.value.find((e) => e.options.key === id);
    if (activeRef) {
      activeRef.options = {
        ...activeRef.options,
        title: changes.title || activeRef.options.title,
        content: changes.message || activeRef.options.content,
        closeable: changes.removable || activeRef.options.closeable,
      };
      activeRef.resetTimeout();
      this.notificationsSubject.next(this.notificationsSubject.value);
    }
    const centerRef = this.pastNotificationsSubject.value.find((e) => e.options.key === id);
    if (centerRef) {
      centerRef.options = {
        ...centerRef.options,
        title: changes.title || centerRef.options.title,
        content: changes.message || centerRef.options.content,
        closeable: changes.removable || centerRef.options.closeable,
      };
      centerRef.resetTimeout();
      this.pastNotificationsSubject.next(this.pastNotificationsSubject.value);
    }
  }

  private async handleAction(
    action: IPushMessageAction,
    message: PushMessage,
    ref: IArgonNotificationRef,
    insideNotificationCenter: boolean,
  ) {
    //this.hideNotificationCenter();
    if (!insideNotificationCenter && ref.options.type !== "call") {
      ref.close();
    }
    const content = (
      message.message && typeof message.message === "object" && message["key"] ? "" : message.message
    ) as string & TemplateRef<any>;
    if (action.link) {
      await this.router.navigateByUrl(action.link);
    } else if (action.name) {
      if (action.name === "approve") {
        await this.approveService.showModal(action.data.id);
      } else if (action.name === "open-task") {
        const { id, sourceId, sourceType } = action.data;
        // TODO: open task modal
      } else if (action.name === "showMessage") {
        this.modalService.info({
          nzContent: `${content || ""}\n\n${action.data?.body || ""}`.trim(),
          nzTitle: message.title,
          nzWidth: "460px",
        });
      } else {
        this.modalService.info({
          nzContent: content,
          nzTitle: message.title,
        });
      }
    }
  }

  private async addToNotificationCenter(options: IArgonNotificationOptions) {
    const ref = new IArgonNotificationRef({
      duration: 0,
      ...options,
    });
    this.pastNotificationsSubject.next([...this.pastNotificationsSubject.value, ref]);
    ref.close$.subscribe((deleteMessage) => {
      this.removeFromNotificationCenter(ref, deleteMessage);
    });
    this.notificationCenterUnreadSubject.next(this.pastNotificationsSubject.value.length);
    const action = await firstValueFrom(this.pastActions$);
    this.hubService.actionsTotal$.next(action.length);
    return ref;
  }

  private async removeFromNotificationCenter(ref: IArgonNotificationRef, deleteMessage = false) {
    const values = [...this.pastNotificationsSubject.value];
    const index = values.indexOf(ref);
    if (index !== -1) {
      values.splice(index, 1);
      this.pastNotificationsSubject.next(values);
    }
    if (deleteMessage) {
      this.deleteNotification(ref).catch(() => {});
    }
    this.notificationCenterUnreadSubject.next(this.pastNotificationsSubject.value.length);
    const action = await firstValueFrom(this.pastActions$);
    this.hubService.actionsTotal$.next(action.length);
  }

  private deleteNotification(ref: IArgonNotificationRef) {
    return firstValueFrom(this.http.delete(`notifications/${ref.options.key}`));
  }

  private initializePhone() {
    this.socketService.fromEvent("incomingCall").subscribe((call: any) => {
      const options: IArgonNotificationOptions = {
        content: call.description,
        title: "phone.notifications.incoming_call.title",
        closeable: false,
        icon: "phone",
        duration: 0,
        actions: this.getCallAction(call),
        type: "info",
        removable: false,
        date: Date.now(),
        data: {
          ...call,
        },
      };
      this.notify(options);
      this.addToNotificationCenter(options);
    });
    this.socketService.fromEvent("outgoingCall").subscribe((call: any) => {
      const options: IArgonNotificationOptions = {
        content: call.description,
        title: "phone.notifications.outgoing_call.title",
        closeable: false,
        icon: "phone",
        duration: 0,
        removable: false,
        actions: this.getCallAction(call),
        type: "info",
        date: Date.now(),
        data: {
          ...call,
        },
      };

      this.notify(options);
      this.addToNotificationCenter(options);
    });
    this.socketService.fromEvent("callAccepted").subscribe((call: any) => {
      const oldRef = this.notificationsSubject.value.find((e) => e.options.data.callId === call.callId);
      if (oldRef) {
        oldRef.close();
      }
      const options: IArgonNotificationOptions = {
        content: call.description,
        title: "phone.notifications.active_call.title",
        closeable: false,
        icon: "phone",
        duration: 0,
        actions: this.getCallAction(call),
        type: "info",
        removable: false,
        date: Date.now(),
        data: {
          ...call,
        },
      };
      this.notify(options);
      this.addToNotificationCenter(options);
    });
    this.socketService.fromEvent("callHungUp").subscribe((call: any) => {
      const oldRef = this.notificationsSubject.value.find((e) => e.options.data.callId === call.callId);
      if (oldRef) {
        oldRef.close();
      }
    });
  }

  private getCallAction(call: any): IArgonNotificationActionOptions[] {
    const action: IArgonNotificationActionOptions[] = [];
    if (call.url) {
      console.log(call);
      action.push({
        label: `${call.entityText || ""} Öffnen`.trim(),
        click: () => this.openCallUrl(call),
      });
    }
    return action;
  }

  private openCallUrl(call: any) {
    const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
    const features = isStandalone ? `width=${window.innerWidth},height=${window.innerHeight}` : "";
    window.open(call.url, isStandalone ? "new-window" : "_blank", features);
  }

  clearNotificationCenter() {
    this.pastNotificationsSubject.value.forEach((n) => {
      if (n.options.removable) {
        n.close(true);
      }
    });
  }
}
