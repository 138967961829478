import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  NgModule,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzNoAnimationModule } from "ng-zorro-antd/core/no-animation";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { NzDropdownMenuComponent, NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";

// Entferne die direkte Referenzierung und verwende Interfaces oder Lazy Loading
import { MetaButton, MetaButtonModule } from "../metaButton/metaButton.component";
import { MetaIconModule } from "../metaIcon/metaIcon.component";

export class MetaDropdown extends MetaButton {
  actions?: EventEmitter<MetaDropdownAction>;
  items?: MetaDropdownActionButton[];
  activeItems?: MetaDropdownActionButton[] = [];
}

export class MetaDropdownActionButton extends MetaButton {
  childs?: MetaDropdownActionButton[];
  data?: any;
}

export class MetaDropdownAction {
  action: string;
  actionType: string;
  data?: any;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-dropdown",
  template: `
    <meta-button
      [maParams]="ma"
      [nzTrigger]="'click'"
      [nzBackdrop]="true"
      [nzClickHide]="true"
      [nzDropdownMenu]="menu"
      nz-dropdown
    >
    </meta-button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu nzMode="vertical">
        <ng-template #recursiveList let-items>
          <ng-container *ngFor="let item of items; let index = index">
            <li
              *ngIf="!item.childs"
              [class.active]="ma.activeItems.includes(item)"
              [class.btn-color-green]="item.color === 'green'"
              [class.btn-color-yellow]="item.color === 'yellow'"
              [class.btn-color-red]="item.color === 'red'"
              (click)="onClick(field, item)"
              nz-menu-item
              [nzDisabled]="item.disabled"
            >
              <i *ngIf="item.icon !== undefined" class="fal fa-{{ item.icon }} pr-2"></i>{{ item.label }}
            </li>
            <li
              *ngIf="item.childs"
              (nzOpenChange)="toggleSub($event, index)"
              [nzOpen]="showSub[index]"
              nzNoAnimation
              [nzTitle]="title"
              nz-submenu
              [nzDisabled]="item.disabled"
            >
              <ul>
                <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: item.childs }"></ng-container>
              </ul>
            </li>
            <ng-template #title>
              <span [class]="item.color"
                ><i *ngIf="item.icon !== undefined" class="fal fa-{{ item.icon }} pr-2"></i>{{ item.label }}</span
              >
            </ng-template>
          </ng-container>
        </ng-template>
        <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: ma.items }"></ng-container>
      </ul>
    </nz-dropdown-menu>
  `,
  styleUrls: [`./metaDropdown.component.less`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaDropdownComponent extends MetaComponentBase {
  public showSub: Array<boolean> = [];
  @ViewChild("menu") public menu: NzDropdownMenuComponent;

  constructor(_changeDetectorRef: ChangeDetectorRef, _metaHelperService: MetaHelperService) {
    super();
    super.maParams = new MetaDropdown();
  }

  get ma(): MetaDropdown {
    return super.ma;
  }

  public onClick(field: FormlyFieldConfig, item: MetaDropdownActionButton) {
    if (item.onClick instanceof Function) {
      item.onClick(field, item);
    } else if (typeof item.onClick === "string") {
      // TODO: Implement async actions
    }
  }

  public toggleSub(event: boolean, index: number) {
    this.showSub[index] = event;
  }
}

@NgModule({
  declarations: [MetaDropdownComponent],
  imports: [
    CommonModule,
    FormlyModule,
    NzWaveModule,
    NzButtonModule,
    NzPopconfirmModule,
    MetaIconModule,
    NzDropDownModule,
    RouterModule,
    NzNoAnimationModule,
    MetaButtonModule,
  ],
  exports: [MetaDropdownComponent],
})
export class MetaDrodpownModule {}
