/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, Input } from "@angular/core";
import { IFeedResultItem } from "@meta/api-interfaces";

export class MetaTimelineTemplatePipelineObjectMoved {
  data: IFeedResultItem;
}

@Component({
  selector: "meta-timeline-template-workflow-execution-started",
  template: `<meta-translate key="timeline.workflow-execution-started">
    <ng-template meta-translate-child key="workflowTag">
      <meta-live-card
        [cardLazyLoad]="true"
        [cardType]="item.data.entity.type"
        cardSize="link"
        [cardId]="item.data.entity.id"
        [cardData]="item.data.cardData"
      ></meta-live-card>
    </ng-template>
  </meta-translate> `,
})
export class MetaTimelineTemplateWorkflowExecutionStartedComponent {
  @Input() public item: MetaTimelineTemplatePipelineObjectMoved;
}
