/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Validator } from "class-validator";
import { Expose } from "class-transformer";
import { ExposeProperty } from "@meta/forms";

export const validator = new Validator();

export abstract class MetaBaseValidator {
  @ExposeProperty() public options?: any = {};
  @ExposeProperty() public isAsync? = false;
  @ExposeProperty() public name?: string;

  public abstract validate(
    value: any,
    dataItem: any,
    opts?: {
      templateService: any;
      queryService: any;
    }
  ): Promise<boolean>;

  public toFormly(formlyFieldConfig: Record<string, any>, fallback = false) {
    if (fallback) {
      return {
        name: this.constructor["meta"]["type"],
        options: {
          ...this.options,
          isAsync: this.isAsync,
        },
      };
    }
    return null;
  }
}
