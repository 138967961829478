/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponent, MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";
import { EntityType } from "../../../api-interfaces/src";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-activity",
  type: "activity",
  name: "Activities",
  icon: "history",
})
export class ActivityComponent extends MetaFormComponent {
  @MetaFormComponentProperty({
    name: "Notizen Anzeigen",
    type: "boolean",
    default: true,
    tab: "general",
  })
  public showNotes? = true;
  @MetaFormComponentProperty({
    name: "Aufgaben Anzeigen",
    type: "boolean",
    default: true,
    tab: "general",
  })
  public showTasks? = true;
  @MetaFormComponentProperty({
    name: "Termine Anzeigen",
    type: "boolean",
    default: true,
    tab: "general",
  })
  public showAppointments? = true;
  @MetaFormComponentProperty({
    name: "Anrufe Anzeigen",
    type: "boolean",
    default: true,
    tab: "general",
  })
  public showCalls? = true;
  @MetaFormComponentProperty({
    name: "Marktinformationen Anzeigen",
    type: "boolean",
    default: true,
    tab: "general",
  })
  public showMarketInfos? = false;
  @MetaFormComponentProperty({
    name: "Dokumente Anzeigen",
    type: "boolean",
    default: true,
    tab: "general",
  })
  public showFiles? = true;
  @MetaFormComponentProperty({
    name: "Tabs Anzeigen",
    type: "boolean",
    default: true,
    tab: "general",
  })
  public showMainTabs? = true;

  @MetaFormComponentProperty({
    name: "Entity Type",
    type: "select",
    tab: "data",
    values: "form-builder/entities",
  })
  public entityType: EntityType | string;

  @MetaFormComponentProperty({
    name: "Daten Bindung",
    type: "path",
    tab: "data",
    description: `Database field from the primary data source or from a relation`,
    required: true,
  })
  public path: string;

  public static createElement<T>(opts: Partial<ActivityComponent>) {
    const input = new ActivityComponent();
    Object.assign(input, opts);
    return input;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      key: this.id,
      type: `meta-activity`,
      props: {
        showNotes: this.showNotes,
        showTasks: this.showTasks,
        showAppointments: this.showAppointments,
        showCalls: this.showCalls,
        showFiles: this.showFiles,
        entityType: this.entityType,
        showMainTabs: this.showMainTabs,
        showMarketInfos: this.showMarketInfos,
      },
    });
  }
}
