/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

export interface ILiveCardDataProgress {
  percent: number;
  label?: string;
}

export interface ILiveCardDataAction {
  title: string;
  link?: string;
}

export interface ILiveCardData {
  title?: string;
  content?: string;
  progress?: ILiveCardDataProgress;
  link?: string;
  image?: string;
  actions?: ILiveCardDataAction[];
  icon?: string;
  id?: string;
  type?: string;
  typeName?: string;
  color?: string;
}
