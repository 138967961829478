/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Injectable } from "@angular/core";
import { createState, Store, withProps } from "@ngneat/elf";
import { localStorageStrategy, persistState } from "@ngneat/elf-persist-state";

export interface IChartConfigState {
  seriesType?: string;
}

const chartConfig = createState(withProps<IChartConfigState>({}));

const chartConfigStore = new Store({ name: "form", state: chartConfig.state, config: chartConfig.config });

export const persist = persistState(chartConfigStore, {
  key: "chartConfig",
  storage: localStorageStrategy,
});

export interface IChartFilterState {
  value?: any;
}

const chartFilter = createState(withProps<IChartFilterState>({}));

const chartFilterStore = new Store({ name: "form", state: chartFilter.state, config: chartFilter.config });

export const persistFilter = persistState(chartFilterStore, {
  key: "chartFilter",
  storage: localStorageStrategy,
});

@Injectable({ providedIn: "root" })
export class MetaChartRepository {
  chartConfig$ = chartConfigStore;
  chartFilter$ = chartFilterStore;

  setConfig(chartId: string, config: IChartConfigState) {
    this.chartConfig$.update((s) => ({
      ...s,
      [chartId]: {
        ...s[chartId],
        ...config,
      },
    }));
  }

  setFilter(filterId: string, config: IChartFilterState) {
    this.chartFilter$.update((s) => ({
      ...s,
      [filterId]: {
        ...s[filterId],
        ...config,
      },
    }));
  }
}
