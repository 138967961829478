/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, tap } from "rxjs";
import * as _ from "lodash";

export class AutocompleteData {
  id: string | number | symbol;
  __id?: string[];
  label?: string;
  value?: string;
  filter: string;
  fieldId: string;
  formId: string;
  _ctx?: string;

  [params: string]: any;
}

class GetDataParams {
  formId: string;
  fieldId?: string;
  skip: number;
  take: number;
  filter: string;
  params?: any;
  data?: any;
  tagId?: string;
  contextId?: string;
  selectDataUrl?: string;
  refresh?: boolean;
  reset?: boolean;
  subformPath?: string[];
  index?: number;
  searchType: "tags" | "search" | "default";
}

@Injectable()
export class MetaAutocompleteService {
  private _autocompleteData: BehaviorSubject<AutocompleteData[]> = new BehaviorSubject([]);
  public readonly autocompleteData$: Observable<AutocompleteData[]> = this._autocompleteData.asObservable();
  constructor(public http: HttpClient) {}

  public getData(params: GetDataParams): Observable<AutocompleteData[]> {
    let url;
    switch (params.searchType) {
      case "tags":
        url = `tags/${params.tagId || params.fieldId}/selectData`;
        break;
      case "search":
        url = `search/${params.fieldId}/autocomplete`;
        break;
      default:
        url = `forms/${params.formId}/selectData/${encodeURIComponent(params.fieldId)}`;
    }

    return this.http
      .post<AutocompleteData[]>(url, {
        skip: params.skip,
        take: params.take,
        searchString: params.filter === null ? "" : params.filter,
        data: params.tagId ? undefined : params.data,
        subFormPath: params.subformPath?.length > 0 ? params.subformPath : undefined,
        index: params.index,
        _ctx: params.contextId,
        params: params.params,
      })
      .pipe(
        tap((data) => {
          this._autocompleteData.next(params.skip > 0 ? this._autocompleteData.getValue().concat(data) : data);
        }),
      );
  }
}
