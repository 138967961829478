/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Expose } from "class-transformer";
import {
  DataSourceParameters,
  ExposeProperty,
  MetaFormBaseComponent,
  MetaFormComponentInfo,
  MetaFormComponentProperty,
} from "./baseComponent";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";
import type { FormEventActionHandler } from "../interfaces";

@MetaFormComponentInfo({
  selector: "ma-stat",
  type: "statistic",
  icon: "sort-numeric-up-alt",
  name: "Statistic",
  palletGroup: "reporting",
})
export class MetaFormStatisticComponent<T = {}> extends MetaFormBaseComponent {
  @ExposeProperty() public size?: "small" | "default" = "default";
  @ExposeProperty() public shorten? = false;
  @ExposeProperty() public format?: "number" | "default" = "default";
  @ExposeProperty() public align?: "left" | "right" | "center" = "left";
  @MetaFormComponentProperty({
    name: "Datasource",
    type: "dataSource",
    tab: "data",
  })
  public dataSource: any;

  @ExposeProperty()
  public dataSourceParameters?: DataSourceParameters;

  @MetaFormComponentProperty({
    name: "Bei Klick",
    type: "action",
    tab: "general",
    description: "The action to be performed when the button is pressed.",
  })
  public onClick?: FormEventActionHandler;

  constructor() {
    super();
  }

  public static create<T extends new () => any, S extends new () => any>(
    dataSource: T,
    opts: Partial<MetaFormStatisticComponent>,
  ) {
    const input = new MetaFormStatisticComponent<T>();
    Object.assign(input, opts);
    input.dataSource = dataSource;
    return input;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        size: this.size,
        shorten: this.shorten,
        format: this.format,
        align: this.align,
        defaultValue: this.defaultValue,
        hasOnClick: !!this.onClick,
      },
    });
  }
}
