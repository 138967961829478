/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation } from "@angular/core";
import { FormlyModule } from "@ngx-formly/core";
import { NzGridModule } from "ng-zorro-antd/grid";
import { EmbeddedProperty } from "ng-zorro-antd/grid/col.directive";
import { MetaWrapperBase } from "../../base/metaWrapperBase/metaWrapperBase.component";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";

export class MetaColumn {
  xs?: string | number | EmbeddedProperty | null = 24;
  sm?: string | number | EmbeddedProperty | null = 24;
  md?: string | number | EmbeddedProperty | null = 24;
  lg?: string | number | EmbeddedProperty | null;
  xl?: string | number | EmbeddedProperty | null;
  xXl?: string | number | EmbeddedProperty | null;
  offset?: string | number | null = null;
  span?: string | number | null = null;
  flex?: string | number | null = null;
  hasPadding?: boolean;
  hasNoGutter?: boolean;
  keepContentTogether?: boolean;
  style?: string;
  width?: string;
  borderTop?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
  borderLeft?: boolean;
  paddingTop?: string;
  paddingRight?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  align?: string;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-column",
  template: `
    <nz-col
      [nzLg]="ma.lg || ma.span"
      [nzMd]="ma.md"
      [nzOffset]="ma.offset"
      [nzPull]="ma['pull']"
      [nzPush]="ma['push']"
      [nzSm]="ma.sm"
      [nzSpan]="ma.span"
      [nzXXl]="ma.xXl"
      [nzXl]="ma.xl"
      [nzXs]="ma.xs || 24"
      [nzFlex]="ma.flex"
      [style.width]="ma.width"
      [style.minWidth]="ma.width"
      [style.maxWidth]="ma.width"
      [style.border-top]="ma.borderTop ? '1px solid' : ''"
      [style.border-Right]="ma.borderRight ? '1px solid' : ''"
      [style.border-Bottom]="ma.borderBottom ? '1px solid' : ''"
      [style.border-left]="ma.borderLeft ? '1px solid' : ''"
      [style.padding-top]="ma.paddingTop"
      [style.padding-right]="ma.paddingRight"
      [style.padding-bottom]="ma.paddingBottom"
      [style.padding-left]="ma.paddingLeft"
      [style.text-align]="ma.align"
      class="{{ ma.hasPadding ? 'has-padding' : ma.hasPadding === false ? 'has-no-padding' : null }} gutter-row"
    >
      <ng-container *ngIf="ma.keepContentTogether; else column">
        <div class="keep-together-wrapper">
          <div class="keep-together">
            <ng-container *ngTemplateOutlet="column"></ng-container>
          </div>
        </div>
      </ng-container>
      <ng-template #column>
        <ng-container #fieldComponent></ng-container>
        <ng-content></ng-content>
      </ng-template>
    </nz-col>
  `,
  styleUrls: ["./metaColumn.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaColumnComponent extends MetaWrapperBase {
  constructor() {
    super();
    this.maParams = new MetaColumn();
  }
}

@NgModule({
  declarations: [MetaColumnComponent],
  imports: [CommonModule, FormlyModule, NzGridModule],
  exports: [MetaColumnComponent],
})
export class MetaColumnModule {}
