/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, NgModule, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MetaEmptyModule, MetaLoaderModule } from "@meta/ui";
import { FormlyModule } from "@ngx-formly/core";

import * as go from "gojs";
import { Spot } from "gojs";
import { DataSyncService, DiagramComponent, GojsAngularModule } from "gojs-angular";
import { skip, Subject, takeUntil } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaEventService } from "../../services/metaEvents.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaDiagramService } from "./metaDiagram.service";

export class MetaDiagram {}

@MetaUnsubscribe()
@Component({
  selector: "meta-diagram",
  template: ` <meta-empty
      *ngIf="diagramNodeData?.length === 0 && !isLoading"
      class="mt-5"
      [maParams]="{ description: 'Keine Daten vorhanden.', icon: 'project-diagram' }"
    ></meta-empty>
    <meta-loader *ngIf="isLoading" [style.minHeight.px]="50"></meta-loader>
    <gojs-diagram
      #diagram
      [hidden]="isLoading"
      *ngIf="diagramNodeData"
      [initDiagram]="initDiagram"
      [nodeDataArray]="diagramNodeData"
      [linkDataArray]="diagramLinkData"
      [skipsDiagramUpdate]="skipsDiagramUpdate"
      (modelChange)="diagramModelChange($event)"
      [divClassName]="diagramDivClassName"
    >
    </gojs-diagram>`,
  styleUrls: ["./metaDiagram.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaDiagramComponent extends MetaComponentBase implements OnInit {
  public diagramNodeData: Array<any>;
  public diagramLinkData: Array<any>;
  public diagramDivClassName = "diagram";
  public skipsDiagramUpdate = false;
  public dia: any;
  public diagramData: any;
  public observedDiagram: any;
  @ViewChild("diagram") public diagram: DiagramComponent;
  private _destroyed$ = new Subject();

  constructor(
    private _metaEventService: MetaEventService,
    protected _MetaDiagramService: MetaDiagramService,
  ) {
    super();
    super.maParams = new MetaDiagram();
  }

  get ma(): MetaDiagram {
    return super.ma;
  }

  async ngOnInit() {
    super.ngOnInit();

    this._metaEventService.setFilterTrigger$
      .pipe(
        skip(1),
        debounceTime(50),
        filter((x) => x !== undefined && x !== null && x.fieldId === this.id),
        takeUntil((this as any).destroyed$),
      )
      .subscribe({
        next: async (trigger) => {
          const diagram: any = await this._MetaDiagramService.getData(this.formState.formId, this.id, trigger.filter);
          if (diagram?.nodes) {
            this.diagramNodeData = diagram.nodes.map((o) => {
              return {
                ...o,
                key: o.ID,
                displayValue: Array.isArray(o.displayValue) ? o.displayValue.join("\n") : o.displayValue,
              };
            });
            this.diagramLinkData = diagram.connections.map((o) => {
              return {
                from: o.source || "",
                to: o.target || "",
              };
            });
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
          }
          setTimeout(() => {
            // in this snippet, this.myDiagramComponent is a reference to a GoJS/Angular Diagram Component
            // that has a valid GoJS Diagram
            this.observedDiagram = this.diagram.diagram;
            this.diagram.diagram.maybeUpdate();

            this.observedDiagram.scale = 1;
            this.observedDiagram.alignDocument(go.Spot.Center, go.Spot.Center);

            // IMPORTANT: without this, Angular will throw ExpressionChangedAfterItHasBeenCheckedError (dev mode only)
            this.changeDetectorRef.detectChanges();
          }, 200);
        },
      });
  }

  public diagramModelChange = (changes: go.IncrementalData) => {
    this.diagramNodeData = DataSyncService.syncNodeData(changes, this.diagramNodeData);
    this.diagramLinkData = DataSyncService.syncLinkData(changes, this.diagramLinkData);
  };

  public initDiagram = (): go.Diagram => {
    const $ = go.GraphObject.make;
    this.dia = $(go.Diagram, {
      layout: $(go.TreeLayout, {
        angle: 90,
        nodeSpacing: 20,
        layerSpacing: 80,
        layerStyle: go.TreeLayout.LayerUniform,
      }),
      allowZoom: true,
      initialContentAlignment: Spot.Center,
      "animationManager.initialAnimationStyle": go.AnimationManager.None,
      "animationManager.duration": 200,
      model: $(go.GraphLinksModel, {
        linkKeyProperty: "key",
      }),
    });

    this.initNodeTemplates($, this.dia);

    // replace the default Link template in the linkTemplateMap
    this.dia.linkTemplate = $(
      go.Link, // the whole link panel
      {
        curve: go.Link.Bezier,
        toEndSegmentLength: 80,
        fromEndSegmentLength: 80,
      },
      $(go.Shape, { strokeWidth: 1.5, stroke: this.getStylingColor("--text-color") }), // the link shape, with the default black stroke
      $(go.Shape, { stroke: "transparent", fill: this.getStylingColor("--text-color"), toArrow: "Triangle" }),
    );

    return this.dia;
  };

  public initNodeTemplates($, dia) {
    dia.nodeTemplateMap.add(
      "",
      $(
        go.Node,
        "Auto",
        this.nodeStyle(),
        $(
          go.Panel,
          "Auto",
          $(
            go.Shape,
            {
              width: 200,
              height: 60,
              cursor: "grab",
              figure: "RoundedRectangle",
            },
            {
              fill: this.getStylingColor("--primary-color"),
              strokeWidth: 0,
              name: "SHAPE",
            },
            new go.Binding("figure", "figure"),
            new go.Binding("fill", "color", (val, obj) => this.getStylingColor(val)),
          ),
          $(go.TextBlock, this.textStyle(), new go.Binding("text", "displayValue").makeTwoWay()),
        ),
      ),
    );
  }

  // helper definitions for node templates
  public nodeStyle = () => {
    return [
      // The Node.location comes from the "loc" property of the node data,
      // converted by the Point.parse static method.
      // If the Node.location is changed, it updates the "loc" property of the node data,
      // converting back using the Point.stringify static method.
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
      {
        deletable: false,
        click: (e, link: any) => {
          if (link?.data?.link) {
            window.open(link.data.link, "_blank");
          }
        },
      },
    ];
  };

  public textStyle = () => {
    return {
      font: "500 1rem sans-serif",
      stroke: getComputedStyle(document.documentElement).getPropertyValue("--text-color-dark"),
      overflow: go.TextBlock.OverflowEllipsis,
    };
  };

  public getStylingColor = (color: string) => {
    if (color?.startsWith("--")) {
      return getComputedStyle(document.documentElement).getPropertyValue(color);
    } else if (color) {
      return color;
    } else {
      return getComputedStyle(document.documentElement).getPropertyValue("--component-background-secondary");
    }
  };

  public getTextStyle = (textStyle: any) => {
    if (textStyle?.stroke?.startsWith("--")) {
      textStyle.stroke = getComputedStyle(document.documentElement).getPropertyValue(textStyle.stroke);
    }
    return textStyle;
  };
}

@NgModule({
  declarations: [MetaDiagramComponent],
  imports: [CommonModule, FormlyModule, GojsAngularModule, MetaLoaderModule, MetaEmptyModule],
  exports: [MetaDiagramComponent],
})
export class MetaDiagramModule {}
