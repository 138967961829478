/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { animate, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { MetaTaskListComponent } from "../../../../../../libs/ui/src/lib/components/metaTask/metaTask.list.component";
import { UserService } from "../../../../../../libs/ui/src/lib/services/user.service";
import { NotificationService } from "./notification.service";

@Component({
  selector: "meta-notification",
  styleUrls: ["./notification.component.less"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [style({ right: "-100%" }), animate("0.5s ease-out", style({ right: 0 }))]),
      transition(":leave", [style({ right: 0 }), animate("0.5s ease-in", style({ right: "-100%" }))]),
    ]),
    trigger("inOutAnimationBackdrop", [
      transition(":enter", [style({ opacity: 0 }), animate("0.3s ease-out", style({ opacity: 1 }))]),
      transition(":leave", [style({ opacity: 1 }), animate("0.3s ease-in", style({ opacity: 0 }))]),
    ]),
  ],
  template: `
    <div class="notification-wrapper">
      @for (ref of service.notifications$ | async; track $index) {
        <div
          (mouseenter)="ref.pauseTimeout()"
          (mouseleave)="ref.resumeTimeout()"
          class="notification-item {{ ref.options.type }}"
        >
          <div class="phone-title">
            <i *ngIf="ref.options.icon" class="fal fa-{{ ref.options.icon }}"></i>
            <span>
              <ng-container *ngIf="ref.titleTemplate; else elseBlock">
                <ng-container
                  *ngTemplateOutlet="ref.titleTemplate; context: { $implicit: ref.options.data, ref: ref }"
                ></ng-container>
              </ng-container>
              <ng-template #elseBlock>{{ ref.options.title | translate }}</ng-template>
            </span>
          </div>
          <div *ngIf="ref.options.content" class="phone-content">
            <ng-container *ngIf="ref.contentTemplate; else elseBlock">
              <ng-container
                *ngTemplateOutlet="ref.contentTemplate; context: { $implicit: ref.options.data, ref: ref }"
              ></ng-container>
            </ng-container>
            <ng-template #elseBlock>{{ ref.options.content | translate }}</ng-template>
          </div>
          <div class="phone-actions">
            <div
              *ngFor="let action of ref.options.actions"
              (click)="action.click(ref, false)"
              class="phone-action {{ action.color || 'default' }}"
            >
              {{ action.label | translate }}
            </div>
            <div *ngIf="ref.options.closeable" (click)="ref.close()" class="phone-action danger">
              {{ "common.close" | translate }}
            </div>
          </div>
        </div>
      }
    </div>
    <ng-container *ngIf="service.notificationCenterVisible$ | async">
      <div
        [@inOutAnimationBackdrop]
        (click)="service.hideNotificationCenter()"
        class="notification-center-backdrop"
      ></div>
      <div [@inOutAnimation] class="notification-center">
        <h2 t key="notification_center.title"></h2>
        <nz-tabset [(nzSelectedIndex)]="service.selectedTabIndex" [nzAnimated]="false">
          <ng-container *ngIf="service.pastNotifications$ | async as items">
            <nz-tab [nzTitle]="'notification_center.tab.notifications' | translate: { count: items.length }">
              <ng-template nz-tab>
                <div *ngIf="items.length > 0" class="notification-center-header">
                  <meta-button (click)="service.clearNotificationCenter()" [maParams]="{ icon: 'trash' }">
                  </meta-button>
                </div>
                <div [ngClass]="{ empty: items.length === 0 }" class="notification-center-content">
                  <div *ngIf="items.length === 0" class="empty-container">
                    <i class="fal fa-thumbs-up"></i>
                    <br />
                    {{ "notification_center.no_notifications" | translate }}
                  </div>
                  <meta-notification-item
                    [size]="'small'"
                    [ref]="ref"
                    *ngFor="let ref of items"
                  ></meta-notification-item>
                </div>
              </ng-template>
            </nz-tab>
          </ng-container>
          <ng-container *ngIf="service.pastActions$ | async as items">
            <nz-tab [nzTitle]="'notification_center.tab.actions' | translate: { count: items.length }">
              <ng-template nz-tab>
                <div [ngClass]="{ empty: items.length === 0 }" class="notification-center-content">
                  <div *ngIf="items.length === 0" class="empty-container">
                    <i class="fal fa-thumbs-up"></i>
                    <br />
                    {{ "notification_center.no_actions" | translate }}
                  </div>
                  <meta-notification-item
                    [size]="'small'"
                    [ref]="ref"
                    *ngFor="let ref of items"
                  ></meta-notification-item>
                </div>
              </ng-template>
            </nz-tab>
          </ng-container>
          @if (user.userFeatures()?.task) {
            <nz-tab [nzTitle]="'notification_center.tab.tasks' | translate">
              <ng-template nz-tab>
                <meta-button
                  [maParams]="{ label: 'Aufgabe erstellen', type: 'primary', fullWidth: true, icon: 'plus' }"
                  (click)="metaTaskList.createTask()"
                ></meta-button>
                <meta-task-list #metaTaskList></meta-task-list>
                <meta-collapse
                  [maParams]="{ label: 'Abgeschlossene Aufgaben' }"
                  (activeChanged)="showDoneTasks = $event"
                >
                  <meta-task-list *ngIf="showDoneTasks" [maParams]="{ dataToShow: 'done' }"></meta-task-list>
                </meta-collapse>
              </ng-template>
            </nz-tab>
          }

          @if (user.userFeatures()?.calendar) {
            <nz-tab [nzTitle]="'notification_center.tab.appointments' | translate">
              <div class="notification-center-content empty">
                <div class="empty-container">
                  <i class="fal fa-thumbs-up"></i>
                  <br />
                  {{ "notification_center.no_appointments" | translate }}
                </div>
              </div>
            </nz-tab>
          }
        </nz-tabset>
      </div>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  public showDoneTasks: boolean = false;
  constructor(
    public readonly service: NotificationService,
    public readonly user: UserService,
  ) {}
  @ViewChild("metaTaskList") public metaTaskList: MetaTaskListComponent;
}
