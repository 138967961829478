/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { ChangeDetectionStrategy, Component, computed, Input, OnDestroy, OnInit } from "@angular/core";
import { MetaButtonType, MetaState } from "@meta/enums";
import { MetaFormlyFormOptions } from "../../metaForm/metaForm.interface";
import { MetaFormService } from "../metaForm.service";

@Component({
  selector: "meta-form-publish",
  template: `@if (publishVisible()) {
    <meta-button
      nz-tooltip
      [nzTooltipTrigger]="'hover'"
      [nzTooltipMouseEnterDelay]="1"
      [nzTooltipTitle]="
        options.formState.metaData()?.published
          ? 'Dieser Bericht wurde bereits veröffentlicht und kann erst dann wieder veröffentlich werden, wenn er bearbeitet wurde.'
          : 'Bericht veröffentlichen um alle damit verknüpften Workflows zu starten.'
      "
      [maParams]="{
        id: '__metaConfirm',
        label: options.formState.metaData()?.published ? 'Bereits veröffentlicht' : 'Veröffentlichen',
        loading: metaFormService.activePublishWorkflows().length > 0,
        icon: 'check',
        type: MetaButtonType.primary,
        onClick: onPublish,
        disabled: publishDisabled()
      }"
    ></meta-button>
  }`,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaFormPublishComponent {
  @Input() options: MetaFormlyFormOptions;
  protected readonly MetaState = MetaState;
  protected readonly MetaButtonType = MetaButtonType;

  constructor(public readonly metaFormService: MetaFormService) {}

  public publishVisible = computed(() => {
    return (
      this.metaFormService.activePublishWorkflows().length === 0 &&
      this.options.formState.form().publishable &&
      this.options.formState.form().editable &&
      this.options.formState.form().hasDataSource &&
      (this.options.formState.state() === MetaState.none || this.options.formState.state() > MetaState.saving) &&
      this.options.formState.state() !== MetaState.editingForm
    );
  });

  public readonly lockEditModeWorkflow = computed(() => {
    const wf = this.options.formState?.workflows();
    if (!wf) return false;
    return !!wf.executions.find((e) => e.lock && e.active);
  });

  public publishDisabled = computed(() => {
    return (
      this.options.formState.state() === MetaState.saving ||
      this.getVersionData(this.options.formState.version())?.locked === 1 ||
      this.options.formState.metaData()?.locked ||
      this.lockEditModeWorkflow() ||
      (!this.options.formState.metaData()?.isEditable &&
        (this.options.formState.state() > MetaState.saving || this.options.formState.state() === MetaState.none)) ||
      (this.options.formState.form().versionable &&
        this.options.formState.versionsData().length > 0 &&
        this.options.formState.version() != this.options.formState.versionsData().length)
    );
  });
  public onPublish = () => this.metaFormService.runPublish();

  public getVersionData(version: number | string) {
    return this.options.formState.versionsData()?.find((e) => e.value === version);
  }
}
