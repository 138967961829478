/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaBaseValidator } from "./baseValidator";
import * as validator from "class-validator";
import { MetaFormComponentInfo } from "../components";

export class IsBooleanValidatorOptions {}

@MetaFormComponentInfo({
  selector: "is-boolean-validator",
  type: "is-boolean-validator",
})
export class IsBoolean extends MetaBaseValidator {
  constructor(public options?: IsBooleanValidatorOptions) {
    super();
  }

  async validate(value): Promise<boolean> {
    return validator.isBoolean(value);
  }
}
