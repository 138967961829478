/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2021
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2021
 */

import {
  ChangeDetectionStrategy,
  Component,
  effect,
  HostBinding,
  inject,
  Injector,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FieldWrapper } from "@ngx-formly/core";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { CommonModule } from "@angular/common";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaIconStyle, MetaState } from "@meta/enums";

export class IMetaWrapperBase {
  id?: string;
  label?: string | any;
  labelType?: "text" | "icon" = "text";
  iconStyle?: MetaIconStyle = MetaIconStyle.Solid;
  iconText?: string;
  flex?: number | string = 0;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-wrapper-base",
  template: `META_WRAPPER_BASE`,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaWrapperBase extends FieldWrapper implements OnInit, OnDestroy {
  public formId: string;
  public isLoading: boolean;
  public destroy$: Subject<boolean> = new Subject<boolean>();
  public subFormPath: string[];
  public subFormIndex: number[];
  protected readonly inj: Injector;

  private _maParams: any;
  protected readonly _metaHelperService: MetaHelperService;

  @Input()
  public get maParams(): any {
    return this._maParams;
  }

  public set maParams(value: any) {
    this._maParams = _.merge({}, new IMetaWrapperBase(), this._maParams, value);
  }

  get ma(): any {
    return this.field ? { ...this.maParams, ...(this.props as any) } : this.maParams;
  }

  constructor() {
    super();
    this._metaHelperService = inject(MetaHelperService);
    this.inj = inject(Injector);
  }

  public get displayData(): any {
    if (this.field && this.formState?.displayData?.()) {
      this.subFormPath = this.subFormPath || this._metaHelperService.getFormlySubFormPath(this.field);
      this.subFormIndex = this._metaHelperService.getFormlyFieldArrayIndex(this.field, true);
      return this._getNestedDisplayData(this.formState.displayData(), this.subFormPath, this.subFormIndex, this.id);
    }
    return {};
  }

  private _getNestedDisplayData(data: any, path: string[], indices: number[], id: string): any {
    if (path.length > 0) {
      const nestedData = _.get(data, path.concat(["values"]));
      if (nestedData && indices.length > 0) {
        const index = indices.shift();
        if (Array.isArray(nestedData) && nestedData[index]) {
          return this._getNestedDisplayData(nestedData[index], path, indices, id);
        }
      }
    }
    if (data[id] !== undefined) {
      return data[id];
    }
    return {};
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit() {
    if (this.field && this.field.options) {
      effect(
        () => {
          (this.maParams as any) = { showDummy: this.options.formState?.isDummyForm };
        },
        { injector: this.inj },
      );
    }
  }
}

@NgModule({
  declarations: [MetaWrapperBase],
  imports: [CommonModule],
})
export class MetaWrapperBaseModule {}
