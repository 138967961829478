/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2020
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2020
 * Written by Yannick Küchlin <y.kuechlin@taifun-tofu.de>, 2019-2020
 */

import { IsOptional, IsString } from "class-validator";

export class FavoritesRequest {
  @IsString()
  type: string;
  @IsString()
  quellId: string;
  @IsString()
  @IsOptional()
  formObjectId?: string;
}
