/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { NzModalRef, NzModalState } from "ng-zorro-antd/modal";
import { first, firstValueFrom, takeUntil } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";
import {
  modalActionTarget,
  modalLabelTarget,
  modalSublabelTarget,
} from "../../../components/metaToolbar/metaToolbar.interface";
import { DirtyCheckService } from "../../../services/dirtyCheckService";
import { MetaHelperService } from "../../../services/metaHelper.service";
import { MetaModalOptions, MetaModalService } from "../../../services/metaModalService";
import { MetaFormService } from "../metaForm.service";

@Injectable({
  providedIn: "root",
})
export class OpenFormActionHandler {
  constructor(
    private readonly _metaHelperService: MetaHelperService,
    private readonly _metaModalService: MetaModalService,
    private readonly _dirtyCheckService: DirtyCheckService,
    private readonly _router: Router,
  ) {}

  async handle(action: any) {
    if (action.newWindow) {
      const features = `width=${800},height=${600}`;
      const itemId = action.data?._id?.join(",") || "create";
      const winRef = window.open(`${action.formId}/${itemId}`, "new-window", features);
      await new Promise((resolve) => winRef.addEventListener("close", resolve));
      return {};
    }

    let size: string;
    switch (action.size) {
      case "small":
        size = "30%";
        break;
      case "large":
        size = "60%";
        break;
      case "max":
        size = "92%";
        break;
      case "default":
        size = "45%";
        break;
      default:
        size = action.size;
    }

    const { MetaFormComponent } = await import("../metaForm.component");
    const params: MetaModalOptions = {
      nzContent: MetaFormComponent,
      nzMaskClosable: false,
      nzWidth: size,
      nzFooter: null,
      nzClassName: `ma-subform`,
      nzWrapClassName: "ma-subform-wrapper",
      nzBodyStyle: {
        position: "relative",
        display: "flex",
        "flex-direction": "column",
      },
      nzClosable: true,
      nzOnCancel: () => {
        if (modalRef.state !== NzModalState.CLOSED && this._dirtyCheckService.isDirty(modalRef)) {
          return firstValueFrom(this._dirtyCheckService.showDirtyAlert(modalRef));
        }
      },
      nzCloseOnNavigation: true,
      nzComponentParams: {
        maFormId: action.formId,
        maExternalData: action.data || {},
        maItemId:
          (action.data["_id"] ? action.data["_id"].join(",") : action.isEdit === false ? null : "create") || null, // TODO: Needs to be reworked, "create" has to be defined in the metaForm
        maToolbarActionTarget: `${modalActionTarget}-${action.formId}`,
        maToolbarLabelTarget: `${modalLabelTarget}-${action.formId}`,
        maToolbarSubLabelTarget: `${modalSublabelTarget}-${action.formId}`,
        maEditing:
          action.modalType === "wizard" ? true : !!action.hasOwnProperty("isEdit") ? action.isEdit : !action.data?._id,
        maCloseModalAfterSave: action.closeAfterSave,
        maCloseModalAfterCancel: true,
      },
    };

    let modalRef: NzModalRef;
    if (action.type === "dialog") {
      modalRef = this._metaModalService.create({
        ...params,
        nzClassName: `ma-subform ant-modal-confirm`,
      });
    } else {
      modalRef = this._metaModalService.create(params);
      this._metaHelperService.resizeContainer(modalRef.containerInstance.modalElementRef.nativeElement.id);
    }

    this._router.events.pipe(takeUntil(modalRef.afterClose), first()).subscribe({
      next: () => {
        if (modalRef && modalRef.state === NzModalState.OPEN) {
          modalRef.close();
        }
      },
      error: () => {},
    });

    const resp = (await firstValueFrom(modalRef.afterClose.pipe(debounceTime(100)))) || false;
    return resp;
  }
}
