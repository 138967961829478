/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { HttpErrorResponse } from "@angular/common/http";
import { Component, computed, input, Input, OnInit, TemplateRef } from "@angular/core";
import * as _ from "lodash";
import { UserService } from "../../services/user.service";

const HTTP_ERROR_IMAGES = {
  404: ["https://assets10.lottiefiles.com/packages/lf20_4DPcyu.json"],
  403: "https://assets10.lottiefiles.com/packages/lf20_xafe7wbh.json",
  fallback: "https://assets7.lottiefiles.com/packages/lf20_ail5bspx.json",
};

export interface ICustomError {
  image?: string;
  title: string;
  subTitle?: string;
  content?: string | TemplateRef<any>;
  extra?: TemplateRef<any>;
}

export interface IMissingPermissionError {
  permission: string;
}

@Component({
  selector: "meta-error",
  templateUrl: "./metaError.component.html",
  styleUrls: ["./metaError.component.less"],
})
export class MetaErrorComponent {
  constructor(private readonly user: UserService) {}
  public readonly error = input<Error | ICustomError>(null);
  public readonly missingPermission = input<string>(null);

  public readonly httpError = computed<HttpErrorResponse>(() => {
    const e = this.error();
    return e instanceof HttpErrorResponse ? e : null;
  });
  public readonly customError = computed<ICustomError>(() => {
    const e = this.error();
    return e instanceof Error ? null : e;
  });
  public readonly errorImage = computed(() => {
    const httpError = this.httpError();
    const custom = this.customError();
    return httpError ? this.getImageForStatusCode(httpError.status) : custom?.image;
  });

  public readonly customErrorContentString = computed(() => {
    const custom = this.customError();
    return typeof custom?.content === "string" ? custom.content : null;
  });

  public readonly customErrorContentTemplate = computed(() => {
    const custom = this.customError();
    return custom?.content instanceof TemplateRef ? custom?.content : null;
  });

  @Input()
  public isWidget = false;

  public contactAdmin() {
    const p = new URLSearchParams({
      subject: "Berechtigungen Argon",
      body: `
Hallo Administrator,

bitte Gewähren sie mein Argon Konto: "${this.user.userSignal().email}" zugriff auf folgende Funktionen:
- Bitte Funktionen Auflisten

      `.trim(),
    });
    const to = "admin@example.com";
    window.open(`mailto:${to}?${p.toString()}`, "_blank");
  }

  private getImageForStatusCode(status: number | string) {
    if (Array.isArray(HTTP_ERROR_IMAGES[status])) {
      return _.sample(HTTP_ERROR_IMAGES[status]);
    } else {
      return HTTP_ERROR_IMAGES[status] || HTTP_ERROR_IMAGES.fallback;
    }
  }

  protected readonly window = window;
}
