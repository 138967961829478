/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { createState, Store } from "@ngneat/elf";
import { Injectable } from "@angular/core";
import {
  deleteEntities,
  selectAllApply,
  selectEntity,
  setEntities,
  upsertEntities,
  withEntities,
} from "@ngneat/elf-entities";
import { HttpClient } from "@angular/common/http";
import { DateTime } from "luxon";
import { firstValueFrom, Observable } from "rxjs";
import { TaskDto } from "../../../../../api-interfaces/src/lib/api-interfaces/task/task.dto";
import { MetaTaskDataResponse, MetaTaskRequest } from "./metaTask.service";
import * as moment from "moment";
import { map } from "rxjs/operators";

const { state, config } = createState(withEntities<TaskDto>());
const store = new Store({ name: "taskData", state, config });

@Injectable({ providedIn: "root" })
export class MetaTaskRepository {
  public total = {};
  public store = store;
  public priorities: any = [];
  constructor(private readonly _http: HttpClient) {}

  public getData$(
    sourceId: string,
    sourceType: string,
    done: boolean,
    parent: string,
    today?: boolean,
    taskId?: string,
  ): Observable<any> {
    let filterEntityFn: (e: any) => boolean;

    if (sourceId && sourceType) {
      if (taskId) {
        filterEntityFn = (e) =>
          e.sourceType === sourceType && e.sourceId === sourceId && e.parent === parent && e.id === taskId;
      } else {
        filterEntityFn = (e) =>
          e.sourceType === sourceType && e.sourceId === sourceId && e.done === done && e.parent === parent;
      }
    } else if (today) {
      filterEntityFn = (e) =>
        e.done === done && e.parent === parent && (e.dueDate as any) <= moment().endOf("day").toISOString();
    } else {
      filterEntityFn = (e) => e.done === done && e.parent === parent;
    }

    return store.pipe(
      selectAllApply({
        filterEntity: filterEntityFn,
      }),
      map((o) => this.transformTasks(o)),
    );
  }

  public async createTask(task: TaskDto, changeDoneStatus?: boolean) {
    await this.sortAndUpdate([task], changeDoneStatus);
    if (task.parent) {
      const parent = await firstValueFrom(store.pipe(selectEntity(task.parent)));
      parent.children += 1;
      store.update(upsertEntities([parent]));
    }
  }

  public async updateSingleTask(task: TaskDto, changeDoneStatus?: boolean) {
    await this.sortAndUpdate([task], changeDoneStatus);
  }

  public async deleteSingleTask(task: TaskDto) {
    store.update(deleteEntities(task.id));
    if (task.parent) {
      const parent = await firstValueFrom(store.pipe(selectEntity(task.parent)));
      parent.children -= 1;
      if (task.done) {
        parent.childrenDone -= 1;
      }
      store.update(upsertEntities([parent]));
    }
  }

  public async updateTasksFromAPI(response: { data: MetaTaskDataResponse } & { params: MetaTaskRequest }) {
    const { data, params } = response;
    await this.sortAndUpdate(data.resultSet);
    if (params.filter === "done") {
      this.total[params.sourceId + params.sourceType] = data.total;
    }
  }

  public async sortAndUpdate(tasks: TaskDto[], changeDoneStatus?: boolean) {
    let tasksDone = await firstValueFrom(
      store.pipe(
        selectAllApply({
          filterEntity: (e) => e.done,
        }),
      ),
    );
    let tasksOpen = await firstValueFrom(
      store.pipe(
        selectAllApply({
          filterEntity: (e) => !e.done,
        }),
      ),
    );
    if (changeDoneStatus && tasks[0].parent) {
      const index = tasksOpen.findIndex((e) => e.id === tasks[0].parent);
      if (index > -1) {
        tasksOpen[index].childrenDone = tasks[0].done
          ? tasksOpen[index].childrenDone + 1
          : tasksOpen[index].childrenDone - 1;
      } else {
        const index = tasksDone.findIndex((e) => e.id === tasks[0].parent);
        tasksDone[index].childrenDone = tasks[0].done
          ? tasksDone[index].childrenDone + 1
          : tasksDone[index].childrenDone - 1;
      }
    }
    tasks.forEach((task) => {
      if (task.done) {
        tasksOpen = tasksOpen.filter((e) => e.id !== task.id);
        const index = tasksDone.findIndex((e) => e.id === task.id);
        if (index > -1) {
          tasksDone[index] = task;
        } else {
          tasksDone.push(task);
        }
      } else {
        tasksDone = tasksDone.filter((e) => e.id !== task.id);
        const index = tasksOpen.findIndex((e) => e.id === task.id);
        if (index > -1) {
          tasksOpen[index] = task;
        } else {
          tasksOpen.push(task);
        }
      }
    });
    const fallbackDue = moment().subtract(10, "years").toISOString();
    tasksOpen.sort((a, b) => {
      const fallbackDateA = moment().subtract(moment(a["dateCreated"]).valueOf(), "milliseconds").toISOString();
      const fallbackDateB = moment().subtract(moment(b["dateCreated"]).valueOf(), "milliseconds").toISOString();
      return (
        moment(a.dueDate || fallbackDue)
          .toISOString()
          .localeCompare(moment(b.dueDate || fallbackDue).toISOString()) ||
        a.priority - b.priority ||
        moment(fallbackDateA).toISOString().localeCompare(moment(fallbackDateB).toISOString())
      );
    });
    tasksDone.sort((a, b) => moment(b.dateDone).toISOString().localeCompare(moment(a.dateDone).toISOString()));
    store.update(setEntities(tasksOpen.concat(tasksDone)));
  }

  transformTasks(data: TaskDto[]) {
    return data.map((o) => {
      const task: any = {
        id: o.id,
        data: o,
        avatar: (o as any).userCreated.value,
        avatarName: (o as any).userCreated.label,
        dueDate: o.dueDate,
        icon: "list-check",
        title: o.title,
        description: o.description,
        metadata: {},
      };
      if (o.done) {
        task.metadata.done = {
          icon: "circle-check",
          label: `${moment((o as any).dateDone).fromNow(false)}${
            (o as any).userDoneObject?.label ? " von " + (o as any).userDoneObject?.label : ""
          }`,
          tooltip:
            "Erledigt am " +
            moment((o as any).dateDone).format("LLL") +
            " Uhr" +
            ((o as any).userDoneObject?.label ? " von " + (o as any).userDoneObject?.label : "") +
            ".",
        };
      }
      if (o.dueDate && !o.done) {
        task.metadata.due = {
          icon: "clock",
          dueDate: DateTime.fromISO(String(o.dueDate)).toRelative(),
          label: `Fällig ${moment(o.dueDate).isBefore() ? "vor" : "in"} ${moment(o.dueDate).fromNow(true)}`,
          color:
            moment(o.dueDate).diff(moment(), "days") < 0
              ? "red"
              : moment(o.dueDate).diff(moment(), "days") <= 3
                ? "yellow"
                : "default",
          type: "tag",
          tooltip:
            moment(o.dueDate).format("dd, DD.MM.yyyy") +
            " " +
            (o.hasTime ? moment(o.dueDate).format("HH:mm") + " Uhr" : ""),
        };
      }
      task.metadata.created = {
        icon: "circle-plus",
        label: `${moment((o as any).dateCreated).fromNow(false)}${
          (o as any).userDoneObject?.label ? " von " + (o as any).userDoneObject?.label : ""
        }`,
        tooltip:
          "Erstellt am " +
          moment((o as any).dateCreated).format("LLL") +
          " Uhr" +
          ((o as any).userCreated?.label ? " von " + (o as any).userCreated?.label : ""),
      };
      task.metadata.users = {
        icon: "user",
        data: o.assignedToUsers.map((e: any) => {
          return {
            label: e.label,
            id: e.value,
          };
        }),
        type: "avatars",
      };
      task.metadata.comments = o.comments;
      return task;
    });
  }
}
