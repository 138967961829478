/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, NgModule, OnInit, ViewEncapsulation } from "@angular/core";
import { FormlyModule } from "@ngx-formly/core";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { IMetaWrapperBase, MetaWrapperBase } from "../../base/metaWrapperBase/metaWrapperBase.component";
import { PipesModule } from "../../pipes/pipes.module";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";

export class MetaFieldWrapper extends IMetaWrapperBase {
  hideLabel?: boolean;
  hideFeedback?: boolean;
  required?: boolean;
  description?: string;
  labelWidth?: string | number | null = 8;
  inputWidth?: string | number | null = 16;
  layout?: "vertical" | "horizontal" = "horizontal";
  childLayout?: "vertical" | "horizontal" | null = null;
  fillingGrade?: number;
  condensed?: boolean;
  hasMultipleInputs?: boolean;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-field-wrapper",
  template: `
    <ng-container *ngIf="ma as ma">
      <nz-form-item>
        <ng-container *ngIf="ma.label && !ma.hideLabel">
          <nz-form-label
            [nzXs]="10"
            [nzSm]="ma.layout === 'vertical' ? 24 : ma.labelWidth"
            nzNoColon
            [nzTooltipTitle]="ma.description ? tooltipTpl : null"
            [nzRequired]="ma.required"
            [nzFor]="field ? id : null"
            [ngClass]="ma.layout === 'vertical' ? 'vertical' : 'horizontal'"
            class="{{ ma.condensed ? 'condensed' : '' }}"
          >
            <span
              *ngIf="ma.labelType === 'text'"
              [innerHtml]="
                displayData?.label || ma.label | metaSearchHighlight: (field ? (formState.fieldFilter$ | async) : null)
              "
            ></span>
            <i
              *ngIf="ma.labelType === 'icon'"
              nz-tooltip
              [nzTooltipTitle]="ma.iconText ? tooltipTpl : null"
              class="{{ ma.iconStyle }} fa-{{ ma.label }}"
            ></i>
            <ng-container *ngIf="ma.fillingGrade">
              <i
                nz-tooltip
                [nzTooltipTitle]="
                  ma.fillingGrade === 1
                    ? 'Dieses Feld wurde als sehr wichtig gekennzeichnet und sollte so gut es geht gefüllt sein.'
                    : 'Dieses Feld wurde als relativ wichtig gekennzeichnet und ist für einen vollständig gepflegten Datensatz üblicherweise gefüllt.'
                "
                class="fas fa-font-awesome filling-{{ ma.fillingGrade }} fillinggrade"
              ></i>
            </ng-container>
          </nz-form-label>
        </ng-container>
        <nz-form-control
          [nzSm]="ma.layout === 'vertical' ? 24 : !ma.label || ma.hideLabel ? 24 : ma.inputWidth"
          [nzXs]="14"
          [nzHasFeedback]="!ma.hideFeedback"
          [nzValidateStatus]="field ? errorState : null"
          [nzErrorTip]="errorTpl"
          [ngClass]="{ 'child-horizontal': ma.childLayout === 'horizontal' }"
          class="{{ ma.condensed ? 'condensed' : '' }} {{ ma.hasMultipleInputs ? 'multiple' : '' }}"
        >
          <ng-container #fieldComponent></ng-container>
          <ng-content></ng-content>
          <ng-template #errorTpl let-control>
            <formly-validation-message [field]="field"></formly-validation-message>
          </ng-template>
        </nz-form-control>
        <ng-template #tooltipTpl>
          <span [innerHtml]="ma.description || ma.iconText"></span>
        </ng-template>
      </nz-form-item>
    </ng-container>
  `,
  styleUrls: ["./metaFieldWrapper.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaFieldWrapperComponent extends MetaWrapperBase implements OnInit {
  constructor() {
    super();
    super.maParams = new MetaFieldWrapper();
  }

  get errorState() {
    return this.showError ? "error" : this.formControl.dirty ? "" : "";
  }

  ngOnInit() {
    super.ngOnInit();
  }
}

@NgModule({
  declarations: [MetaFieldWrapperComponent],
  imports: [CommonModule, FormlyModule, NzGridModule, NzFormModule, PipesModule, NzToolTipModule],
  exports: [MetaFieldWrapperComponent],
})
export class MetaFieldWrapperModule {}
