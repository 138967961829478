/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { NzUploadFile } from "ng-zorro-antd/upload/interface";
import { NzModalRef } from "ng-zorro-antd/modal";
import { HttpClient, HttpEventType, HttpResponse } from "@angular/common/http";
import { RenameFileRequest } from "@meta/api-interfaces";
import { filter, tap } from "rxjs/operators";
import { firstValueFrom } from "rxjs";
import { IMetaMediaLibraryFile } from "./interfaces";
import { MetaModalService } from "../../services/metaModalService";

@Component({
  template: `
    <nz-upload
      *ngIf="fileList.length === 0"
      nzType="drag"
      [nzMultiple]="false"
      [nzLimit]="1"
      [(nzFileList)]="fileList"
      [nzBeforeUpload]="beforeUpload"
    >
      <p class="ant-upload-drag-icon">
        <i nz-icon nzType="inbox"></i>
      </p>
      <p class="ant-upload-text">Klicken oder ziehen Sie die Datei in diesen Bereich, um sie hochzuladen</p>
      <p class="ant-upload-hint">Alle gängigen Dateiformate werden unterstützt</p>
    </nz-upload>
    <ng-container *ngFor="let file of fileList">
      <meta-field-wrapper [maParams]="{ label: 'Name' }">
        <meta-input [maParams]="{ editing: !uploading }" [(ngModel)]="options.name"></meta-input>
      </meta-field-wrapper>
      <meta-field-wrapper [maParams]="{ label: 'Gültigkeit von' }">
        <meta-datepicker
          [maParams]="{
            editing: !uploading,
            placeholder: 'Von',
            datepickerType: 'date'
          }"
          [(ngModel)]="options.validFrom"
        ></meta-datepicker>
      </meta-field-wrapper>
      <meta-field-wrapper [maParams]="{ label: 'Gültigkeit bis' }">
        <meta-datepicker
          [maParams]="{
            editing: !uploading,
            placeholder: 'Bis',
            datepickerType: 'date'
          }"
          [(ngModel)]="options.validUntil"
        ></meta-datepicker>
      </meta-field-wrapper>
      <meta-field-wrapper [maParams]="{ label: 'Klassifizierung' }">
        <meta-select
          [maParams]="{
            editing: !uploading,
            selectDataUrl: 'media-library/classifications',
            editForm: 'frmDocumentClassifications',
            loadDataOnInit: true
          }"
          [(ngModel)]="options.classification"
        ></meta-select>
      </meta-field-wrapper>
      <meta-field-wrapper *ngIf="!uploading" [maParams]="{ label: 'Extern' }">
        <meta-switcher [maParams]="{ editing: !uploading }" [(ngModel)]="options.external"></meta-switcher>
      </meta-field-wrapper>
      <meta-field-wrapper [maParams]="{ label: 'Beschreibung' }">
        <meta-textarea
          [maParams]="{ editing: !uploading, placeholder: 'Beschreibung' }"
          [(ngModel)]="options.description"
        ></meta-textarea>
      </meta-field-wrapper>
    </ng-container>
  `,
})
export class MetaMediaLibraryUploadModalComponent implements OnInit {
  public uploading = false;
  public options: RenameFileRequest = {
    name: "",
    description: "",
    classification: null,
    external: false,
    validFrom: null,
    validUntil: null,
    retentionPolicy: 0,
  };

  public collection: string;
  public uploadedFiles: IMetaMediaLibraryFile[] = [];
  fileList: NzUploadFile[] = [];

  constructor(
    private readonly modalRef: NzModalRef,
    private readonly httpClient: HttpClient,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly modalService: MetaModalService
  ) {}

  beforeUpload = (file: NzUploadFile) => {
    this.fileList = this.fileList.concat(file);
    this.options.name = file.name;
    this.modalRef.updateConfig({ nzOkDisabled: false });
    return false;
  };

  public setUploadProgress(value: number) {
    this.modalRef.updateConfig({ nzOkText: `Wird hochgeladen (${value}%)…` });
  }

  public async uploadFiles() {
    const formData = new FormData();
    this.fileList.forEach((file: NzUploadFile) => {
      formData.append("file", file as unknown as Blob);
    });
    formData.append("name", this.options.name);
    formData.append("description", this.options.description);
    formData.append("external", String(this.options.external));

    if (this.options.classification !== null) {
      formData.append("classification", String(this.options.classification));
    }

    if (this.collection) {
      formData.append("collection", this.collection);
    }

    if (this.options.validFrom && this.options.validUntil) {
      formData.append("validFrom", this.options.validFrom.toString());
      formData.append("validUntil", this.options.validUntil.toString());
    }
    formData.append("retentionPolicy", String(this.options.retentionPolicy || 0));
    this.uploading = true;
    this.setUploadProgress(0);

    try {
      const res = (await firstValueFrom(
        this.httpClient
          .post("/collection/upload", formData, {
            reportProgress: true,
            observe: "events",
          })
          .pipe(
            tap((e) => {
              if (e.type === HttpEventType.UploadProgress) {
                const percent = Math.floor((100 / e.total) * e.loaded);
                this.setUploadProgress(percent);
              }
            }),
            filter((e) => e instanceof HttpResponse)
          )
      )) as HttpResponse<IMetaMediaLibraryFile>;
      return [res.body];
    } catch (e) {
      this.modalService.error({
        nzTitle: e.error?.message || "Fehler",
        nzContent: e.error?.error,
      });
    }
  }

  ngOnInit(): void {
    this.modalRef.updateConfig({
      nzOnOk: this.uploadFiles.bind(this),
      nzOkDisabled: true,
      nzOkText: "Hochladen",
    });
  }
}
