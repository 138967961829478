/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { IsNumber, IsString, IsOptional, IsDate } from "class-validator";

export class SickFmdPack {
  @IsNumber()
  @IsOptional()
  public id?: number;

  @IsDate()
  @IsOptional()
  public created?: Date;

  @IsDate()
  @IsOptional()
  public updated?: Date;

  @IsString()
  public productCode: string;

  @IsString()
  public serialNumber: string;

  @IsString()
  public charge: string;

  @IsDate()
  public expiryDate: Date;
}

export class SickTransaction {
  @IsNumber()
  @IsOptional()
  public id?: number;

  public pack?: SickFmdPack;
  public action?: string[];
}
