/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { CollectionViewer, DataSource, SelectionChange } from "@angular/cdk/collections";
import { TreeControl } from "@angular/cdk/tree";
import { BehaviorSubject, Observable, merge } from "rxjs";
import { map, tap } from "rxjs/operators";
import { MetaTreeviewService, TreeNode } from "./metaTreeview.service";

export class DynamicDatasource implements DataSource<TreeNode> {
  private readonly flattenedData: BehaviorSubject<TreeNode[]>;
  private readonly childrenLoadedSet = new Set<TreeNode>();
  private readonly prevExpandedKeys: string[] = [];
  private readonly storageKey: string;
  constructor(
    private treeControl: TreeControl<TreeNode>,
    private treeService: MetaTreeviewService,
    private options: {
      formId: string;
      fieldId: string;
      id: any[];
      _ctx: string;
      data: Record<string, any>;
      parentKey?: string;
    },
  ) {
    const init = [];
    this.flattenedData = new BehaviorSubject<TreeNode[]>(init);
    treeControl.dataNodes = init;
    this.storageKey = `tree_${this.options.formId}_${this.options.id}}`;
    if (localStorage[this.storageKey]) {
      this.prevExpandedKeys = JSON.parse(localStorage[this.storageKey]);
    }
  }

  public refresh(partialNode?: Pick<TreeNode, "title" | "suffix" | "prefix" | "link">) {
    this.treeService.getTreeData(this.options).subscribe((data) => {
      data.forEach((e) => (e.level = partialNode ? 1 : 0));
      const rootNode = partialNode
        ? {
            ...partialNode,
            expandable: true,
            expanded: false,
            id: [],
            isLeaf: false,
            key: "_root_",
            loading: false,
            level: 0,
          }
        : null;
      if (rootNode) {
        this.childrenLoadedSet.add(rootNode);
        data.unshift(rootNode);
      }
      this.flattenedData.next(data);
      this.treeControl.dataNodes = data;
      data.forEach((d) => this.restoreState(d));
    });
  }

  public clear() {
    const c = [];
    this.flattenedData.next(c);
    this.childrenLoadedSet.clear();
    this.treeControl.dataNodes = c;
  }

  public restoreState(node: TreeNode) {
    if (node.expandable && this.prevExpandedKeys.includes(node.key)) {
      this.treeControl.expand(node);
    }
  }

  connect(collectionViewer: CollectionViewer): Observable<TreeNode[]> {
    const changes = [
      collectionViewer.viewChange,
      this.treeControl.expansionModel.changed.pipe(tap((change) => this.handleExpansionChange(change))),
      this.flattenedData.asObservable(),
    ];
    return merge(...changes).pipe(map(() => this.expandFlattenedNodes(this.flattenedData.getValue())));
  }

  expandFlattenedNodes(nodes: TreeNode[]): TreeNode[] {
    const treeControl = this.treeControl;
    const results: TreeNode[] = [];
    const currentExpand: boolean[] = [];
    currentExpand[0] = true;

    nodes.forEach((node) => {
      let expand = true;
      for (let i = 0; i <= treeControl.getLevel(node); i++) {
        expand = expand && currentExpand[i];
      }
      if (expand) {
        results.push(node);
      }
      if (treeControl.isExpandable(node)) {
        currentExpand[treeControl.getLevel(node) + 1] = treeControl.isExpanded(node);
      }
    });
    return results;
  }

  handleExpansionChange(change: SelectionChange<TreeNode>): void {
    if (change.added) {
      change.added.forEach((node) => this.loadChildren(node));
    }
    localStorage[this.storageKey] = JSON.stringify(this.treeControl.expansionModel.selected.map((e) => e.key));
  }

  loadChildren(node: TreeNode): void {
    if (this.childrenLoadedSet.has(node)) {
      return;
    }
    node.loading = true;
    this.treeService
      .getTreeData({
        ...this.options,
        id: node.id,
        parentKey: (node.key === "_root_" ? null : node.id) as any,
      })
      .subscribe((children) => {
        children.forEach((e) => {
          e.level = node.level + 1;
        });
        node.loading = false;
        const flattenedData = this.flattenedData.getValue();
        const index = flattenedData.indexOf(node);
        if (index !== -1) {
          flattenedData.splice(index + 1, 0, ...children);
          this.childrenLoadedSet.add(node);
        }
        this.flattenedData.next(flattenedData);
        children.forEach((c) => this.restoreState(c));
      });
  }

  disconnect(): void {
    this.flattenedData.complete();
  }
}
