/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { IsBoolean, IsEmail, IsISO31661Alpha2, IsOptional, IsString } from "class-validator";

export class DLogoutRequest {
  allDevices: boolean;
}

export class CreateUserData {
  @IsString()
  firstName: string;
  @IsString()
  lastName: string;
  @IsISO31661Alpha2()
  lang: string;
  @IsBoolean()
  isAdministrator: boolean;
  @IsEmail()
  email: string;
  @IsString()
  password: string;
}

export class DLogoutResponse {
  success: boolean;
}

export class DLoginRequest {
  @IsString()
  username: string;

  @IsString()
  password: string;
}

export class CreateApiKeyRequest {
  @IsString()
  name: string;
}

export class CreateApiKeyResponse {
  @IsString()
  id: string;
  @IsString()
  name: string;
  @IsString()
  data: string;
}

export class UserInfoResponse {
  id: string;
  vorName: string;
  nachName: string;
  administrator: boolean;
  standort: string;
  email: string;
  company: string;
  lang: string;
  position: string;
  showTwoFactorSetup?: boolean;
  defaultPrinter?: string;
  werk: {
    id: string;
    name: string;
  };
  isoLang: string;
  locale: string;
  appSettings: Record<string, any>;
}

export class DLoginResponse extends UserInfoResponse {
  totp: boolean;
}

export class DLoginTotpRequiredResponse {
  token: string;
}

export class DLoginTotpRequest {
  @IsString()
  token: string;
}

export class RequestPasswordReset {
  @IsString()
  @IsOptional()
  captcha: string;
  @IsString()
  email: string;
}

export class PasswordReset {
  @IsString()
  secret: string;
  @IsString()
  password: string;
}

export class SwitchUserRequest {
  @IsString()
  user: string;
}
