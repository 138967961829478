/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MetaAvatarModule, MetaButtonModule } from "@meta/ui";
import { NgIf, NgTemplateOutlet } from "@angular/common";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { MetaTooltipModule } from "../../metaTooltip/metaTooltip.component";

@Component({
  selector: "meta-scheduler-event-bar-text",
  template: `<ng-container *ngIf="maTooltipForm; else content">
      <meta-tooltip
        [maParams]="{
          tooltipForm: maTooltipForm,
          tooltipId: maTooltipId,
          displayValue: { tooltipParams: maTooltipParams },
          tooltipPlacement: maTooltipPlacement,
          tooltipHeight: maTooltipHeight,
          tooltipWidth: maTooltipWidth
        }"
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </meta-tooltip>
    </ng-container>

    <ng-template #content>
      <div class="tpl-container">
        <div *ngIf="maIcon" class="tpl-icon">
          <i class="fas fa-{{ maIcon }}" [nzTooltipTitle]="maIconLabel" nz-tooltip></i>
          <i
            *ngIf="maShowChevronLeft"
            class="fas fa-caret-left"
            [nzTooltipTitle]="maEventNameSingular + ' reicht noch weiter in die Vergangenheit.'"
            nz-tooltip
          ></i>
        </div>
        <div class="tpl-content" [innerHTML]="maLabel"></div>
        <div *ngIf="maIcon" class="tpl-icon-right">
          <i
            *ngIf="maShowChevronRight"
            class="fas fa-caret-right"
            [nzTooltipTitle]="maEventNameSingular + ' reicht noch weiter in die Zukunft.'"
            nz-tooltip
          ></i>
        </div>
      </div>
    </ng-template>`,
  imports: [MetaAvatarModule, NgIf, MetaButtonModule, NzToolTipModule, MetaTooltipModule, NgTemplateOutlet],
  standalone: true,
})
export class MetaSchedulerEventBarTextComponent implements OnChanges {
  @Input() maLabel: string;
  @Input() maStatus: string;
  @Input() maIcon: string;
  @Input() maIconLabel: string;
  @Input() maEventNameSingular: string;
  @Input() maShowChevronLeft: boolean;
  @Input() maShowChevronRight: boolean;
  @Input() maTooltipForm: string;
  @Input() maTooltipId: string;
  @Input() maTooltipParams: any;
  @Input() maTooltipPlacement:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom"
    | Array<string>;
  @Input() maTooltipHeight: string;
  @Input() maTooltipWidth: string;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.maLabel.currentValue) {
      this.maLabel = (changes.maLabel.currentValue as any).replace("\n", "</strong>\n").replaceAll("\n", "<br>");
    }
  }
}
