/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponentInfo } from "../components";
import { MetaBaseValidator } from "./baseValidator";
import * as validator from "class-validator";

export class IsISO31661Alpha2ValidatorOptions {}

@MetaFormComponentInfo({
  selector: "ma-is-iso31661-alpha2",
  type: "iso31661-alpha2-validator",
})
export class IsISO31661Alpha2 extends MetaBaseValidator {
  constructor(public options?: IsISO31661Alpha2ValidatorOptions) {
    super();
  }

  async validate(value): Promise<boolean> {
    return validator.isISO31661Alpha2(value);
  }
}
