/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { ChangeDetectionStrategy, Component, DestroyRef, Input, model, OnInit, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Socket } from "ngx-socket-io";
import { Observable, take } from "rxjs";
import { debounceTime, filter, map, tap } from "rxjs/operators";
import { MetaFormlyFormOptions } from "../../metaForm/metaForm.interface";
import { MetaFormService } from "../metaForm.service";

@Component({
  selector: "meta-form-active-users",
  template: `
    @if (activeUsers$ | async; as activeUsers) {
      @if (activeUsers.length > 1) {
        <div
          [(nzTooltipVisible)]="showTooltip"
          nzTooltipPlacement="bottomRight"
          nz-tooltip
          [nzTooltipTitle]="'forms.active_users_message' | translate: { value: activeUsers.length - 1 }"
        >
          <meta-avatar [maParams]="{ data: activeUsers, type: 'ANW', size: 'small', label: '-' }"></meta-avatar>
        </div>
      }
    }
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaFormActiveUsersComponent implements OnInit {
  @Input() options: MetaFormlyFormOptions;
  public activeUsers$: Observable<Array<{ label: string; id: string }>>;
  public showTooltip = model(true);

  constructor(
    public readonly metaFormService: MetaFormService,
    private readonly _socket: Socket,
    private readonly destroyRef: DestroyRef,
  ) {}

  ngOnInit() {
    this.activeUsers$ = this._socket.fromEvent("FORM_ACTIVE_USERS").pipe(
      filter((e: any) => e.formId === this.options.formState.formId && e.itemId === this.options.formState.itemId()),
      debounceTime(350),
      tap((e: any) => {
        if (e.users?.length > 1) {
          this.showTooltip.set(true);
        }
      }),
      map((e) => e.users),
      takeUntilDestroyed(this.destroyRef),
    );
  }
}
