/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { createState, Store, withProps } from "@ngneat/elf";
import { Injectable } from "@angular/core";
import {
  addEntities,
  deleteEntitiesByPredicate,
  selectAllApply,
  upsertEntities,
  withEntities,
} from "@ngneat/elf-entities";
import { tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom, Observable } from "rxjs";
import * as uuid from "uuid";
import { METAACTIVIY_UNIQUE_ID } from "../../services/metaHelper.service";
import { MetaFormCondition } from "libs/forms/src/metaState.dto";
import { EntityType, MetaTimelineColor } from "@meta/enums";
import * as moment from "moment/moment";
import { MetaHubService } from "../metaHub/metaHub.service";
import { FileAssignementRequest } from "@meta/api-interfaces";

export interface IMetaActivityStore {
  id: string;
  label?: string;

  [key: string | number | symbol]: string;
}

export interface IMetaActivityResponse {
  resultSet: any[];
  total: number;
  totals?: any;
}

interface IMetaActivityGetParams {
  fieldId: string;
  type: EntityType;
  condition: MetaFormCondition;
  quellId: string;
  quellTyp: EntityType;
  silentUpdate?: boolean;
}

export type MetaFormAggregator = "count" | "sum" | "average" | "min" | "max";

export class MetaActivityStateStore {
  activityTotal?: number;
  noteTotal?: number;
  taskTotal?: number;
  appointmentTotal?: number;
  closedappointmentTotal?: number;
  fileTotal?: number;
  callTotal?: number;
  marketInfosTotal?: number;
  changelogTotal?: number;
}

const { state, config } = createState(withEntities<IMetaActivityStore>());
const activityState = createState(withProps<MetaActivityStateStore>({}));

const store = new Store({ name: "activityData", state, config });
const stateStore = new Store({
  name: "activityState",
  state: activityState.state,
  config: activityState.config,
});

@Injectable({ providedIn: "root" })
export class MetaActivityRepository {
  activityState$ = stateStore;

  constructor(
    private readonly _http: HttpClient,
    public metaHubService: MetaHubService,
  ) {}

  public getData$(type: EntityType, fieldId: string): Observable<IMetaActivityStore[]> {
    return store.pipe(selectAllApply({ filterEntity: (e) => e.fieldId === fieldId && e.type === type }));
  }

  setState(fieldId: string, payload: Partial<MetaActivityStateStore>) {
    stateStore.update((s) => ({
      ...s,
      [fieldId]: {
        ...s[fieldId],
        ...payload,
      },
    }));
  }

  public async addData(
    response: { data: IMetaActivityResponse } & { params: IMetaActivityGetParams } & { create?: boolean },
  ) {
    const { data, params, create } = response;
    if (create) {
      store.update(
        addEntities(
          [
            {
              ...data,
              ...data[params.fieldId],
              id: uuid.v5(`${params.fieldId}-${(data as any).ID}-${params.type}`, METAACTIVIY_UNIQUE_ID),
              fieldId: params.fieldId,
              type: params.type,
              quellId: params.quellId,
              itemId: (data as any).ID,
            },
            {
              ...data,
              ...data[params.fieldId],
              id: uuid.v5(`${params.fieldId}-${(data as any).ID}-${EntityType.ACTIVITY}`, METAACTIVIY_UNIQUE_ID),
              fieldId: params.fieldId,
              type: EntityType.ACTIVITY,
              quellId: params.quellId,
              itemId: (data as any).ID,
            },
          ],
          { prepend: true },
        ),
      );
      stateStore.update((s) => ({
        ...s,
        [params.fieldId]: {
          ...s[params.fieldId],
          [`activityTotal`]: s[params.fieldId][`activityTotal`] + 1,
          [`${params.type.toLowerCase()}Total`]: s[params.fieldId][`${params.type.toLowerCase()}Total`] + 1,
        },
      }));
    } else {
      const dataWithIds = data.resultSet.map((d) => ({
        ...d,
        ...d[params.fieldId],
        id: uuid.v5(`${params.fieldId}-${d.ID || d.id}-${params.type}`, METAACTIVIY_UNIQUE_ID),
        fieldId: params.fieldId,
        type: params.type,
        quellId: params.quellId,
        itemId: d.ID || d.id,
      }));
      store.update(upsertEntities(dataWithIds));
      const payload: MetaActivityStateStore | null = {};
      if (data.totals) {
        payload.activityTotal = data.total;
        payload.noteTotal = data["totals"].notes;
        payload.taskTotal = data["totals"].tasks;
        payload.closedappointmentTotal = data["totals"].closedAppointments;
        payload.callTotal = data["totals"].calls;
        payload.marketInfosTotal = data["totals"].marketInfos;
        payload.changelogTotal = data["totals"].logs;
      } else {
        payload[`${params.type.toLowerCase()}Total`] = data.total;
      }
      this.setState(params.fieldId, payload);
    }
  }

  public loadData(p: IMetaActivityGetParams): Observable<IMetaActivityStore[]> {
    const params = new URLSearchParams();
    if (p.condition?.skip !== undefined) {
      params.set("skip", `${p.condition.skip}`);
    }
    if (p.condition?.take !== undefined) {
      params.set("take", `${p.condition.take}`);
    }
    let url = "";

    switch (p.type) {
      case EntityType.ACTIVITY:
        url = `activities/${p.quellTyp}/${p.quellId}?${params}`;
        break;
      case EntityType.NOTE:
        url = `activities/notes/${p.quellTyp}/${p.quellId}?${params}`;
        break;
      /*case EntityType.APPOINTMENT:
        params.set("closed", `false`);
        url = `activities/appointments/${p.quellTyp}/${p.quellId}?${params}`;
        break;
      case EntityType.CLOSEDAPPOINTMENT:
        params.set("closed", `true`);
        url = `activities/appointments/${p.quellTyp}/${p.quellId}?${params}`;
        break;*/
      case EntityType.Call:
        url = `activities/calls/${p.quellTyp}/${p.quellId}?${params}`;
        break;
      case EntityType.MarketInfo:
        url = `activities/marketInfos/${p.quellTyp}/${p.quellId}?${params}`;
        break;
      case EntityType.File:
        url = `activities/files/${p.quellTyp}/${p.quellId}?${params}`;
        break;
      case EntityType.Log:
        url = `activities/logs/${p.quellTyp}/${p.quellId}?${params}`;
        url = `hub/log?group=false&quelleId=${p.quellId}&quelleTyp=${p.quellTyp}&${params}`;
        break;
    }

    if (p.condition.skip === 0 && !p.silentUpdate) {
      store.update(
        deleteEntitiesByPredicate((predicate) => predicate.fieldId === p.fieldId && predicate.type === p.type),
      );
    }

    return this._http.get<IMetaActivityStore[]>(url, p.condition).pipe(
      tap((data) =>
        this.addData({
          data: data as any,
          params: p,
        }),
      ),
    );
  }

  public createNote(p: IMetaActivityGetParams, note: string): Observable<any> {
    return this._http
      .post<any>(`activities/notes`, {
        quellId: p.quellId,
        quellTyp: p.quellTyp,
        note,
      })
      .pipe(
        tap((data) =>
          this.addData({
            data: data as any,
            params: p,
            create: true,
          }),
        ),
      );
  }

  public deleteNote(id: string | number): Observable<any> {
    store.update(deleteEntitiesByPredicate((predicate) => predicate.itemId === id));
    return this._http.delete(`activities/notes/${id}`, {});
  }

  public createTask(p: IMetaActivityGetParams, task: string): Observable<any> {
    return this._http
      .post<any>(`activities/tasks`, {
        quellId: p.quellId,
        quellTyp: p.quellTyp,
        task,
      })
      .pipe(
        tap((data) =>
          this.addData({
            data: data as any,
            params: p,
            create: true,
          }),
        ),
      );
  }

  public getUsers() {
    return this._http.get<any>(`users`);
  }

  public async getParticipants(skip = 0, take = 1000): Promise<any> {
    return this._http.get(`activities/participants?skip=${skip}&take=${take}`).toPromise();
  }

  public async getAppointment(id: number): Promise<any> {
    return this._http.get(`activities/appointmentById?id=${id}`).toPromise();
  }

  public removeAppointment(id: number): Observable<any> {
    return this._http.post<any>(`activities/appointments/remove/${id}`, {});
  }

  public closeAppointment(id: number): Observable<any> {
    return this._http.post<any>(`activities/appointments/close/${id}`, {});
  }

  public returnEntityObject(o: any, Typ: EntityType) {
    switch (Typ) {
      case EntityType.NOTE:
        return {
          id: o.ID,
          icon: "sticky-note",
          description: o.Notiz,
          data: o,
          avatar: o.AnwenderAnlage,
          avatarName: o.AnwenderAnlage,
          metadata: [
            {
              icon: "edit",
              label: moment(o.DatumAnlage).fromNow(),
              tooltip:
                moment(o.DatumAnlage).format("dd, DD.MM.yyyy") + " " + moment(o.DatumAnlage).format("HH:mm") + " Uhr",
            },
            {
              label: o.AnwenderAnlage,
              icon: "user",
            },
            {
              icon: "note",
              label: "Notiz",
            },
          ],
        };
      case EntityType.APPOINTMENT:
      case EntityType.CLOSEDAPPOINTMENT:
        return {
          id: o.ID,
          label: o.Titel,
          icon: o.AktivitaetTyp,
          description: o.Notiz,
          avatar: o.AnwenderAnlage,
          avatarName: o.AnwenderAnlage,
          color: o.Geschlossen
            ? MetaTimelineColor.default
            : o.Ganztaegig
              ? moment(o.Start).hour(23).minute(59).isBefore(new Date())
                ? MetaTimelineColor.danger
                : MetaTimelineColor.default
              : moment(o.Start).isBefore(new Date())
                ? MetaTimelineColor.danger
                : MetaTimelineColor.default,
          metadata: [
            {
              label: o.Ganztaegig
                ? o.Start === o.Ende
                  ? moment(o.Start).format("dd, DD.MM.yyyy")
                  : moment(o.Start).format("dd, DD.MM.yyyy") + " bis " + moment(o.Ende).format("dd, DD.MM.yyyy")
                : o.Start === o.Ende
                  ? moment(o.Start).format("dd, DD.MM.yyyy") + " " + moment(o.Start).format("HH:mm") + " Uhr"
                  : moment(o.Start).format("dd, DD.MM.yyyy") +
                    " " +
                    moment(o.Start).format("HH:mm") +
                    " Uhr bis " +
                    moment(o.Ende).format("dd, DD.MM.yyyy") +
                    " " +
                    moment(o.Ende).format("HH:mm") +
                    " Uhr",
            },
            {
              icon: "edit",
              label: moment(o.DatumAnlage).fromNow(),
              tooltip:
                moment(o.DatumAnlage).format("dd, DD.MM.yyyy") + " " + moment(o.DatumAnlage).format("HH:mm") + " Uhr",
            },
            {
              label: o.ZugewiesenAn || o.AnwenderAnlage,
              icon: "user",
              link: "user/" + (o.ZugewiesenAn || o.AnwenderAnlage),
            },
          ],
          participants:
            o.Aktivitaeten_Teilnehmer?.length > 0
              ? o.Aktivitaeten_Teilnehmer.map((at) => {
                  return {
                    label:
                      `${at.Adresse?.Vorname || at.Adresse[0]?.Vorname} ${at.Adresse?.Name1 || at.Adresse[0]?.Name1}` ||
                      at.Email_Gast,
                  };
                })
              : null,
          data: o,
        };
      case EntityType.Phone:
        // eslint-disable-next-line no-case-declarations
        const data = JSON.parse(o.Daten);
        return {
          ID: o.ID,
          link: null,
          color: data?.DatumStart ? MetaTimelineColor.default : MetaTimelineColor.danger,
          label: `${data?.DatumStart ? (data?.Ausgehend ? "Ausgehender" : "Eingehender") : "Verpasster"} Anruf`,
          description: `Der Anruf dauerte ${
            moment.utc(moment(data?.DatumEnde).diff(moment(data?.DatumStart))).format("mm") === "00"
              ? ""
              : moment.utc(moment(data?.DatumEnde).diff(moment(data?.DatumStart))).format("m") + " Min und"
          } ${moment.utc(moment(data?.DatumEnde).diff(moment(data?.DatumStart))).format("s")} Sek.`,
          icon: "phone",
          avatar: o.AnwenderAnlage,
          avatarName: o.AnwenderAnlage,
          metadata: [
            {
              icon: "clock",
              label: moment(o.DatumAnlage).fromNow(),
              tooltip:
                moment(o.DatumAnlage).format("dd, DD.MM.yyyy") + " " + moment(o.DatumAnlage).format("HH:mm") + " Uhr",
            },
            data?.Anwender
              ? {
                  label: data?.Anwender,
                  icon: "user",
                  link: "#",
                }
              : {},
          ],
          data: o,
        };
      case EntityType.MarketInfo:
        return {
          id: o.ID,
          data: o,
          avatar: o.AnwenderAnlage,
          avatarName: o.AnwenderAnlage,
          icon: "clipboard",
          description: o.Marktinformationen,
          metadata: [
            {
              icon: "edit",
              label: moment(o.DatumAnlage).fromNow(),
              tooltip:
                moment(o.DatumAnlage).format("dd, DD.MM.yyyy") + " " + moment(o.DatumAnlage).format("HH:mm") + " Uhr",
            },
            {
              label: o.AnwenderAnlage,
              icon: "user",
            },
            {
              link: "/frmtask/" + o.ID,
              icon: "file-invoice",
              label: "Marktinformation aus Bericht Nr. " + o.ID,
            },
          ],
        };
      case EntityType.ChangeLog:
        o = JSON.parse(JSON.stringify(o));
        return this.metaHubService._mapActivities([{ ...o }])[0];
      case EntityType.File:
        return {
          data: o,
          ID: o.id,
          label: o.filename,
          metadata: [
            {
              icon: "clock",
              label: moment(o.date).fromNow(),
              tooltip: moment(o.date).format("dd, DD.MM.yyyy") + " " + moment(o.date).format("HH:mm") + " Uhr",
            },
            {
              label: o?.userName,
              icon: "user",
              link: "#",
            },
          ],
          icon: "file",
          link: null,
          avatar: o.userId,
          avatarName: o.userName,
          file: {
            id: o.id,
            name: o.filename,
            mime: o.mime,
          },
          click: () => {
            alert("OK");
          },
        };
    }
  }

  public async assignFiles(files: string[], targetId: string, targetType: string) {
    for (const file of files) {
      const request: Partial<FileAssignementRequest> = {
        targetId: targetId,
        targetType: targetType,
      };
      await firstValueFrom(this._http.post(`file/${file}/assign/${targetId}`, request));
    }
  }

  public async unassignFiles(file: string, targetId: string, targetType: string) {
    await firstValueFrom(
      this._http.post(`file/${file}/unassign/${targetId}`, {
        entityType: targetType,
      }),
    );
  }
}
