/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormBaseComponent, MetaFormComponentInfo } from "./baseComponent";

@MetaFormComponentInfo({
  selector: "ma-countdown",
  type: "countdown",
  name: "Countdown",
})
export class MetaFormCountdownComponent extends MetaFormBaseComponent {
  constructor() {
    super();
  }

  public static create<T>(dataSource: T, opts: Partial<MetaFormCountdownComponent>) {
    const input = new MetaFormCountdownComponent();
    Object.assign(input, opts);
    return input;
  }
}
