/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Table } from "./table";
import { ListItemComponent, MetaFormComponentInfo, MetaFormComponentProperty } from "../components";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-list",
  type: "list",
  icon: "list",
  name: "Liste",
})
export class List extends Table {
  static selector = "ma-list";

  @MetaFormComponentProperty({
    name: "Template",
    isTemplate: true,
    type: "text",
  })
  public template: string;

  // TODO: remove
  @MetaFormComponentProperty({
    name: "GroupFields",
    isTemplate: false,
    type: "text",
  })
  public groupFields: string[] = [];

  // TODO: remove
  @MetaFormComponentProperty({
    name: "SortFields",
    isTemplate: false,
    type: "text",
  })
  public sortFields: string[] = [];

  // TODO: move to metaForm
  @MetaFormComponentProperty({
    name: "disabled",
    type: "text",
    isTemplate: true,
  })
  public disabled?: string;

  constructor(props: Partial<List>) {
    super(props);
  }

  public static createElement(attributes: any, children: any[]): any {
    return new List({
      ...attributes,
      children,
    });
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        defaultCondition: this.defaultCondition,
        sortable: false, //this.sortable,
        deletable: !!this.onDelete,
        creatable: !!this.onCreate,
        showTotal: true,
        condition: {
          take: 10,
        },
        listItemId: this.children.find((c) => c instanceof ListItemComponent)?.id,
      },
      wrappers: this.showGroupWrapper ? ["meta-group"] : [],
    });
  }
}
