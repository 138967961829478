/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { createState, Store, withProps } from "@ngneat/elf";
import { Injectable, signal, WritableSignal } from "@angular/core";
import { Layouts, Menu, ScrollStates, Themes } from "@meta/enums";
import { localStorageStrategy, persistState } from "@ngneat/elf-persist-state";
import { BREAKPOINT, BREAKPOINT_MEDIA_QUERY } from "../../../../../apps/meta-argon/src/app/app.module";
import { MetaFormRecordStore } from "../base/metaForm/metaForm.interface";
import { HttpClient } from "@angular/common/http";
import { debounceTime, firstValueFrom } from "rxjs";

export class IMetaAppStore {
  theme?: Themes;
  layout?: Layouts;
  menu?: Menu;
  scrollState?: ScrollStates;
  menuCollapsed? = false;
  cRef?: string;
}

const appState = createState(
  withProps<IMetaAppStore>({
    theme: Themes.default,
  }),
);

const formState = createState(withProps<MetaFormRecordStore>({}));

const appStore = new Store({ name: "appSettings", state: appState.state, config: appState.config });
const formStore = new Store({ name: "formSettings", state: formState.state, config: formState.config });

persistState(appStore, {
  key: "appSettings",
  storage: localStorageStrategy,
});

persistState(formStore, {
  key: "formSettings",
  storage: localStorageStrategy,
});

@Injectable({ providedIn: "root" })
export class MetaAppService {
  app$ = appStore;
  form$ = formStore;
  public appBreakpoint: WritableSignal<"xs" | "sm" | "md" | "lg" | "xl" | "xxl" | null> = signal(null);

  constructor(
    private readonly http: HttpClient,
    private _breakpointObserver: BreakpointObserver,
  ) {
    this._breakpointObserver
      .observe([
        BREAKPOINT_MEDIA_QUERY.xs,
        BREAKPOINT_MEDIA_QUERY.sm,
        BREAKPOINT_MEDIA_QUERY.md,
        BREAKPOINT_MEDIA_QUERY.lg,
        BREAKPOINT_MEDIA_QUERY.xl,
        BREAKPOINT_MEDIA_QUERY.xxl,
      ])
      .pipe(debounceTime(150))
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints[BREAKPOINT_MEDIA_QUERY.xs]) {
          this.appBreakpoint.set(BREAKPOINT.xs);
        } else if (state.breakpoints[BREAKPOINT_MEDIA_QUERY.sm]) {
          this.appBreakpoint.set(BREAKPOINT.sm);
        } else if (state.breakpoints[BREAKPOINT_MEDIA_QUERY.md]) {
          this.appBreakpoint.set(BREAKPOINT.md);
        } else if (state.breakpoints[BREAKPOINT_MEDIA_QUERY.lg]) {
          this.appBreakpoint.set(BREAKPOINT.lg);
        } else if (state.breakpoints[BREAKPOINT_MEDIA_QUERY.xl]) {
          this.appBreakpoint.set(BREAKPOINT.xl);
        } else if (state.breakpoints[BREAKPOINT_MEDIA_QUERY.xxl]) {
          this.appBreakpoint.set(BREAKPOINT.xxl);
        }
      });
  }

  resetAppSettings() {
    appStore.update(() => {
      return {
        theme: Themes.system,
      };
    });
  }

  setAppSettings(settings: Partial<IMetaAppStore>, saveToBackend = true) {
    appStore.update((s) => {
      const newSettings = {
        ...s,
        ...settings,
      };
      if (saveToBackend) {
        firstValueFrom(this.http.patch("auth/app-settings", newSettings)).catch(console.error);
      }
      return newSettings;
    });
  }

  setFormSettings(formId: string, settings: any) {
    formStore.update((s) => ({
      ...s,
      [formId]: {
        ...s[formId],
        ...settings,
      },
    }));
  }
}
