/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponentInfo } from "../components/baseComponent";
import { Exclude } from "class-transformer";
import { IFormUserConfiguration } from "../formUserConfiguration";

export abstract class MetaFormFilter<T> {
  public abstract label: string;
  public id: string;
  public path: string;
  public param: string;
  public default: T | (() => T);

  public toFormly(config: IFormUserConfiguration) {
    return {
      id: this.id,
      label: this.label,
      default: typeof this.default === "function" ? (this.default as CallableFunction)() : this.default,
    };
  }
}

@MetaFormComponentInfo({
  selector: "ma-input-filter",
  type: "input-filter",
})
export class MetaFormInputFilter extends MetaFormFilter<string> {
  public label: string;

  public static createElement(attributes: any, children: any[]) {
    const input = new MetaFormInputFilter();
    Object.assign(input, attributes);
    return input;
  }

  toFormly(config: IFormUserConfiguration): { id: string; label: string; type: string; default: any } {
    return {
      ...super.toFormly(config),
      type: "input-filter",
    };
  }
}

@MetaFormComponentInfo({
  selector: "ma-boolean-filter",
  type: "boolean-filter",
})
export class MetaFormBooleanFilter extends MetaFormFilter<boolean> {
  public label: string;

  public static createElement(attributes: any, children: any[]) {
    const input = new MetaFormBooleanFilter();
    Object.assign(input, attributes);
    return input;
  }

  toFormly(config: IFormUserConfiguration): { id: string; label: string; type: string; default: any } {
    return {
      ...super.toFormly(config),
      type: "boolean-filter",
    };
  }
}

@MetaFormComponentInfo({
  selector: "ma-date-filter",
  type: "date-filter",
})
export class MetaFormDateFilter extends MetaFormFilter<Date> {
  public label = "Datum";

  public static createElement(attributes: any, children: any[]) {
    const input = new MetaFormDateFilter();
    Object.assign(input, attributes);
    return input;
  }

  toFormly(config: IFormUserConfiguration): { id: string; label: string; type: string; default: any } {
    return {
      ...super.toFormly(config),
      type: "date-filter",
    };
  }
}

@MetaFormComponentInfo({
  selector: "ma-date-time-filter",
  type: "date-time-filter",
})
export class MetaFormDateTimeFilter extends MetaFormFilter<Date> {
  public label = "Datum";

  public static createElement(attributes: any, children: any[]) {
    const input = new MetaFormDateTimeFilter();
    Object.assign(input, attributes);
    return input;
  }

  toFormly(config: IFormUserConfiguration): { id: string; label: string; type: string; default: any } {
    return {
      ...super.toFormly(config),
      type: "date-time-filter",
    };
  }
}

@MetaFormComponentInfo({
  selector: "ma-select-filter",
  type: "select-filter",
})
export class MetaFormSelectFilter<T = any> extends MetaFormFilter<any> {
  public label: string;
  @Exclude()
  public selectDataSource: T;
  public valuePath: string;
  public labelPath: string;

  public static createElement(attributes: any, children: any[]) {
    const input = new MetaFormSelectFilter();
    Object.assign(input, attributes);
    return input;
  }

  toFormly(config: IFormUserConfiguration): { id: string; label: string; type: string; default: any } {
    return {
      ...super.toFormly(config),
      type: "select-filter",
    };
  }
}

@MetaFormComponentInfo({
  selector: "ma-filters",
  type: "filters",
})
export class MetaFormFilterGroup {
  constructor(public readonly children: MetaFormFilter<any>[]) {}

  public static createElement(attributes: any, children: any[]) {
    const input = new MetaFormFilterGroup(children.filter((e) => e instanceof MetaFormFilter));
    Object.assign(input, attributes);
    return input;
  }
}
