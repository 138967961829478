/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { Pipe } from "@angular/core";
import { uniqBy } from "lodash";

@Pipe({
  pure: true,
  name: "descriptionType",
})
export class SearchDescriptionPipe {
  transform(value: any): "string" | "object" | "array" {
    if (value === null || value === undefined || value === "") {
      return null;
    }
    if (Array.isArray(value)) {
      return "array";
    }
    return typeof value as any;
  }
}
