/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { Injectable } from "@angular/core";
import { MetaFormService } from "../metaForm.service";

@Injectable()
export class InitializeFormActionHandler {
  constructor(private metaFormService: MetaFormService) {}
  async handle(action: any) {
    await this.metaFormService.getInitialization(action.data);
  }
}
