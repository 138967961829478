/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class MetaMapService {
  private readonly APPKEY = "0blWvqWlNkcV2DjifOs1X7jvRIuA87oA";

  constructor(private _http: HttpClient) {}

  public loadHeatmapData(params: { formId: string; componentId: string; contextId: string }) {
    return this._http
      .get<[number, number, number][]>(`chart/heatMapData`, {
        params,
      })
      .toPromise();
  }

  public getPosition(address: string) {
    return this._http
      .get(
        `https://www.mapquestapi.com/geocoding/v1/address?key=${this.APPKEY}&location=${encodeURIComponent(
          address,
        )}&maxResults=1`,
      )
      .toPromise();
  }
}
