/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ActivityComponent, ExposeProperty, MetaFormComponentInfo, MetaFormComponentProperty } from "../components";
import { Expose } from "class-transformer";
import { EditableForm } from "./editableForm";
import { IFormUserConfiguration } from "../formUserConfiguration";

export interface IReportFormatCustom {
  /**
   * width in mm
   */
  width: number;
  /**
   * height in mm
   */
  height: number;

  margin?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
}

export type ReportFormatType = "A3" | "A4" | "A5" | "A6" | "DIN_5008" | "dynamic" | IReportFormatCustom;
export type ReportLayoutType = "portrait" | "landscape";

@MetaFormComponentInfo({
  selector: "ma-report",
  type: "report",
  icon: "file-invoice",
  name: "Bericht",
  description: "A report like for example a label, invoice et",
  palletGroup: "reporting",
  examples: [
    {
      name: "Example A4",
      code: `
<ma-report
  id="my-test-id"
  primaryDataSource="dbo.Anwender"
  format="A4"
>
  <ma-h1>Example A4</ma-h1>
</ma-report>
  `,
    },
  ],
})
export class Report extends EditableForm {
  static selector = "ma-report";
  @MetaFormComponentProperty({
    name: "format",
    type: "select",
    tab: "general",
    values: [
      {
        value: "A4",
        label: "A4",
      },
      {
        value: "dynamic",
        label: "dynamic",
      },
    ],
  })
  @ExposeProperty()
  public format?: ReportFormatType;
  @ExposeProperty() public layout?: ReportLayoutType = "portrait";

  // TODO: remove
  @ExposeProperty() public addressPath = "AdressNr";
  @ExposeProperty() public printable? = true;
  @ExposeProperty() public sendable? = true;

  @ExposeProperty()
  public hideActivityPanel?: boolean;

  @ExposeProperty()
  public showMarketInfo?: boolean;

  constructor(props: Report) {
    super(props);
  }

  public static createElement(attributes, children: any[]): any {
    return new Report({
      ...attributes,
      children,
    });
  }

  toFormly(config: IFormUserConfiguration): any {
    return {
      ...super.toFormly(config),
      format: this.format,
      layout: this.layout,
      printable: this.printable,
      sendable: this.sendable,
      hideActivityPanel: this.hideActivityPanel,
      activity: [
        ActivityComponent.createElement({
          showAppointments: false,
          showCalls: false,
          showFiles: true,
          showMainTabs: true,
          showMarketInfos: this.showMarketInfo,
          showTasks: true,
          entityType: this.entityType,
          id: "20CAE30F-54C9-4B51-925C-9ED7271A63B5",
        }).toFormly(config),
      ],
      type: "report",
    };
  }
}
