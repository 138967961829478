/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ReportFormatType, ReportLayoutType } from "./report";
import { MetaForm } from "./form";

import { ActivityComponent, ExposeProperty, MetaFormComponentInfo, MetaFormComponentProperty } from "../components";
import { FormEventActionHandler } from "../interfaces";
import { IFormUserConfiguration } from "../formUserConfiguration";

export type PrinterType = "normal" | "label";
export type WatermarkPosition = `${"top" | "center" | "bottom"}_${"left" | "middle" | "right"}`;
@MetaFormComponentInfo({
  selector: "ma-html-report",
  type: "html-report",
  icon: "receipt",
  name: "HTML Bericht",
  examples: [
    {
      name: "Example 1",
      code: `
<ma-html-report
  id="htmlReportExample1"
  primaryDataSource="dbo.Artikelstamm"
  idField={["ArtikelNr"]}>{\`
    <h1>It Works ({{ArtikelNr}})</h1>
  \`}</ma-html-report>
`,
    },
    {
      name: "Example 2",
      code: `
import { createFormElement } from "@meta/forms";
// @ts-ignore
import html from "./report.html";
<ma-html-report
  id="htmlReportExample2"
  primaryDataSource="dbo.Artikelstamm"
  html={html}
  idField={["ArtikelNr"]}
/>
`,
    },
  ],
})
export class HtmlReport extends MetaForm {
  @MetaFormComponentProperty({
    name: "Html Template",
    type: "text",
    isTemplate: true,
    default: null,
    hidden: true,
  })
  public html: string;

  @MetaFormComponentProperty({
    name: "CSS Theme",
    tab: "style",
    type: "select",
    values: [
      {
        label: "Din5008",
        value: "style_din5008",
      },
      {
        label: "Compact",
        value: "style_compact",
      },
    ],
    default: "style_din5008",
  })
  public styleFile?: string;

  @MetaFormComponentProperty({
    name: "Printer Type",
    tab: "style",
    type: "select",
    values: [
      {
        label: "Normal",
        value: "normal",
      },
      {
        label: "Etikett",
        value: "label",
      },
    ],
  })
  public printerType?: PrinterType;

  @ExposeProperty() public addQrCode? = false;
  @ExposeProperty() public addPageNumbers? = true;

  @MetaFormComponentProperty({
    name: "Watermark",
    type: "text",
    tab: "general",
    isTemplate: true,
  })
  public watermark?: string;

  @MetaFormComponentProperty({
    name: "Watermark Color",
    type: "color",
    tab: "style",
    isTemplate: true,
    condition: (f) => f.watermark,
  })
  public watermarkColor?: string;

  @MetaFormComponentProperty({
    name: "Watermark Opacity",
    type: "number",
    min: 0,
    max: 1,
    tab: "style",
    condition: (f) => f.watermark,
  })
  public watermarkOpacity?: number;

  @MetaFormComponentProperty({
    name: "Watermark Rotation",
    type: "number",
    tab: "style",
    default: 0,
    condition: (f) => f.watermark,
  })
  public watermarkRotation?: number;

  @MetaFormComponentProperty({
    name: "Watermark Size",
    type: "number",
    tab: "style",
    default: 60,
    condition: (f) => f.watermark,
  })
  public watermarkSize?: number;

  @MetaFormComponentProperty({
    name: "Watermark Position",
    type: "select",
    tab: "style",
    default: "center_middle",
    values: [
      "top_left",
      "top_middle",
      "top_right",
      "center_left",
      "center_middle",
      "center_right",
      "bottom_left",
      "bottom_middle",
      "bottom_right",
    ],
    condition: (f) => f.watermark,
  })
  public watermarkPosition?: WatermarkPosition;

  @MetaFormComponentProperty({
    name: "Locale",
    type: "text",
    tab: "general",
    isTemplate: true,
  })
  public locale?: string;

  @MetaFormComponentProperty({
    name: "Briefpapier",
    type: "text",
    tab: "style",
    isTemplate: true,
  })
  public backgroundFile?: string;

  @MetaFormComponentProperty({
    name: "Datei Anlagen",
    type: "js",
    tab: "other",
  })
  public fileAttachments?: FormEventActionHandler;

  @MetaFormComponentProperty({
    name: "format",
    type: "select",
    tab: "style",
    values: [
      {
        value: "A4",
        label: "A4",
      },
      {
        value: "dynamic",
        label: "dynamic",
      },
    ],
  })
  public format?: ReportFormatType;

  @MetaFormComponentProperty({
    name: "format",
    type: "select",
    tab: "style",
    values: [
      {
        value: "portrait",
        label: "Portrait",
      },
      {
        value: "landscape",
        label: "Landscape",
      },
    ],
  })
  public layout?: ReportLayoutType;

  @MetaFormComponentProperty({
    name: "Datei Archivieren",
    type: "boolean",
    tab: "other",
  })
  public archive?: false;

  @MetaFormComponentProperty({
    name: "Font",
    type: "font",
    tab: "style",
    required: false,
  })
  public fontFamily: string;

  public static createElement(attributes, children: any[]): any {
    const report = new HtmlReport({
      ...attributes,
    });

    if (typeof children[0] === "string") {
      report.html = children[0];
    } else if (!report.html) {
      report.html = "";
    }
    return report;
  }
  toFormly(config: IFormUserConfiguration): any {
    return {
      ...super.toFormly(config),
      isHtmlReport: true,
      type: "report",
      format: "A4",
      layout: this.layout,
      hideActivityPanel: false,
      printable: true,
      activity: [
        ActivityComponent.createElement({
          showAppointments: false,
          showCalls: false,
          showFiles: true,
          showMainTabs: true,
          showMarketInfos: false,
          showTasks: true,
          entityType: this.entityType,
          id: "20CAE30F-54C9-4B51-925C-9ED7271A63B5",
        }).toFormly(config),
      ],
    };
  }
}
