/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2021
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2021
 */

import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  NgModule,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { FormlyModule } from "@ngx-formly/core";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { takeUntil } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { MetaComponentBase, MetaFormBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaActionHandlerFactory } from "../../base/metaForm/actions/actionHandler.factory";
import { PipesModule } from "../../pipes/pipes.module";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaButtonModule } from "../metaButton/metaButton.component";
import { MetaIconModule } from "../metaIcon/metaIcon.component";

export class MetaSwitch extends MetaFormBase {
  displayType?: MetaSwitchType | string = MetaSwitchType.default;
  icon?: string;
  checkedIcon? = "check";
  uncheckedIcon? = "times";
  link?: string;
  description?: string;
}

export enum MetaSwitchType {
  default = "default",
  tile = "tile",
}

@MetaUnsubscribe()
@Component({
  selector: "meta-switcher",
  template: `
    <ng-container [ngSwitch]="ma.displayType">
      <ng-container *ngSwitchCase="'tile'">
        <div class="meta-switcher-tile">
          <meta-icon
            [maParams]="{
              icon: ma.icon,
              disabled: (field && !model[id]) || !value,
              trigger: 'enable',
              target: 'meta-switcher',
              stroke: 45
            }"
          ></meta-icon>
          <nz-form-label
            [nzXs]="24"
            nzNoColon
            [nzTooltipTitle]="ma.description"
            [nzRequired]="ma.required"
            [nzFor]="field ? id : null"
          >
            <span
              [innerHtml]="ma.label | metaSearchHighlight: (field ? (formState.fieldFilter$ | async) : null)"
            ></span>
            <ng-container *ngIf="ma.link && field && formControl.value">
              <a [href]="displayData?.link"><i class="fal fa-link"></i></a>
            </ng-container>
          </nz-form-label>
          <ng-container *ngTemplateOutlet="editing && !ma.readonly ? controlTpl : outputTpl"></ng-container>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'default'">
        <ng-container *ngTemplateOutlet="editing && !ma.readonly ? controlTpl : outputTpl"></ng-container>
      </ng-container>
    </ng-container>
    <ng-template #controlTpl>
      <nz-switch
        *ngIf="field; else ngModelTpl"
        [formControl]="fc"
        [formlyAttributes]="field"
        [nzCheckedChildren]="checkedTemplate"
        [nzUnCheckedChildren]="unCheckedTemplate"
      ></nz-switch>
      <ng-template #ngModelTpl>
        <nz-switch
          [(ngModel)]="value"
          (ngModelChange)="onChange($event)"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
        ></nz-switch>
      </ng-template>
      <ng-container *ngIf="ma.displayType !== 'tile' && ma.link && field && formControl.value">
        <a [href]="displayData?.link"><i class="fal fa-link"></i></a>
      </ng-container>
    </ng-template>
    <ng-template #outputTpl>
      <div
        class="meta-switcher-output"
        [ngClass]="{ true: field ? model[id] : value, false: field ? !model[id] : !value }"
      >
        <ng-container
          *ngTemplateOutlet="(field ? model[id] : value) ? checkedTemplate : unCheckedTemplate"
        ></ng-container>
        <ng-container *ngIf="ma.displayType !== 'tile' && ma.link && field && formControl.value">
          <a [href]="displayData?.link"><i class="fal fa-link"></i></a>
        </ng-container>
      </div>
    </ng-template>
    <ng-template #checkedTemplate><i class="far fa-{{ ma.checkedIcon }}"></i></ng-template>
    <ng-template #unCheckedTemplate><i class="far fa-{{ ma.uncheckedIcon }}"></i></ng-template>
  `,
  styleUrls: ["./metaSwitch.component.less"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetaSwitchComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaSwitchComponent extends MetaComponentBase implements OnInit {
  constructor(
    _changeDetectorRef: ChangeDetectorRef,
    private readonly _metaActionHandler: MetaActionHandlerFactory,
    private readonly _metaHelperService: MetaHelperService,
    private _router: Router,
  ) {
    super();
    super.maParams = new MetaSwitch();
  }

  get ma(): MetaSwitch {
    return super.ma;
  }

  public ngOnInit() {
    super.ngOnInit();
    if (this.field) {
      this.formControl.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(350), takeUntil((this as any).destroyed$))
        .subscribe((value) => this.executeChangeAction(value));
    }
  }

  public onLinkClick() {
    this._router.navigateByUrl(this.ma.link.replace(":id", this.formState.itemId()));
  }

  private async executeChangeAction(value: any) {
    if (this.formControl.pristine) {
      return;
    }

    if (value === "" && this.formControl.dirty) {
      this.formControl.reset(null);
      return;
    }

    if (!this.ma.onChange) {
      return;
    }
    let model = this.formState.data();
    if (Object.keys(model).length === 0) {
      model = this.form.value;
    }
    await this._metaActionHandler.executeChangeAction({
      formId: this.formState.formId,
      controlId: this.id,
      data: {
        ...model,
        [this.id]: value,
      },
      subFormPath: this._metaHelperService.getFormlySubFormPath(this.field),
      ctx: model?._ctx,
      index: this._metaHelperService.getFormlyFieldArrayIndex(this.field),
    });
  }
}

@NgModule({
  declarations: [MetaSwitchComponent],
  imports: [
    CommonModule,
    FormlyModule,
    NzSwitchModule,
    FormsModule,
    ReactiveFormsModule,
    MetaIconModule,
    NzFormModule,
    PipesModule,
    MetaButtonModule,
  ],
  exports: [MetaSwitchComponent],
})
export class MetaSwitchModule {}
