/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { MetaDatepickerFormat, MetaDatepickerType } from "@meta/enums";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyAttributeEvent } from "@ngx-formly/core/lib/models/fieldconfig";
import * as moment from "moment";
import { DisabledDateFn, DisabledTimeFn, NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzTimePickerModule } from "ng-zorro-antd/time-picker";
import { NzTypographyModule } from "ng-zorro-antd/typography";
import { skip, startWith, takeUntil } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { MetaComponentBase, MetaFormBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaActionHandlerFactory } from "../../base/metaForm/actions/actionHandler.factory";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaCheckboxModule } from "../metaCheckbox/metaCheckbox.component";
import { MetaFieldWrapperModule } from "../metaFieldWrapper/metaFieldWrapper.component";
import { MetaSwitchModule } from "../metaSwitch/metaSwitch.component";

export class MetaDatepicker extends MetaFormBase {
  datepickerType?: MetaDatepickerType | string = MetaDatepickerType.date;
  defaultOpenTimeValue?: Date = moment().set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate();
  defaultShowTime?: boolean = true;
  placeholder?: string | string[] = "Datum auswählen";
  onChange?: boolean;
  // Examples for DisabledDate:
  // https://ng.ant.design/components/date-picker/en
  disabledDate?: DisabledDateFn;
  disabledTime?: DisabledTimeFn;
  onValueChange?: FormlyAttributeEvent;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-datepicker",
  template: `
    <ng-container *ngIf="editing && !ma.readonly; else outputTpl">
      <ng-container *ngIf="field; else ngModelTpl" [ngSwitch]="ma.datepickerType">
        <nz-date-picker
          (ngModelChange)="onValueChangeEvent($event)"
          *ngSwitchCase="'date'"
          [formControl]="fc"
          [formlyAttributes]="field"
          [nzDisabledDate]="ma.disabledDate"
          [nzDisabledTime]="ma.disabledTime"
          [nzFormat]="format[ma.datepickerType]"
          [nzPlaceHolder]="ma.placeholder"
          (blur)="maOnBlur.emit(true)"
        ></nz-date-picker>
        <nz-date-picker
          (ngModelChange)="onValueChangeEvent($event)"
          *ngSwitchCase="'datetime'"
          [formControl]="fc"
          [formlyAttributes]="field"
          [nzDisabledDate]="ma.disabledDate"
          [nzDisabledTime]="ma.disabledTime"
          [nzFormat]="showTime ? format['datetime'] : format['date']"
          [nzPlaceHolder]="ma.placeholder"
          [nzShowTime]="
            showTime
              ? {
                  nzFormat: 'HH:mm',
                  nzDefaultOpenValue: ma.defaultOpenTimeValue,
                  nzMinuteStep: 15
                }
              : false
          "
          [nzRenderExtraFooter]="datetimeFooterTpl"
          (blur)="maOnBlur.emit(true)"
        >
        </nz-date-picker>
        <nz-time-picker
          (ngModelChange)="onValueChangeEvent($event)"
          *ngSwitchCase="'time'"
          [formControl]="fc"
          [formlyAttributes]="field"
          [nzFormat]="format[ma.datepickerType]"
          [nzPlaceHolder]="ma.placeholder"
          (blur)="maOnBlur.emit(true)"
        ></nz-time-picker>
        <nz-month-picker
          (ngModelChange)="onValueChangeEvent($event)"
          *ngSwitchCase="'month'"
          [formControl]="fc"
          [formlyAttributes]="field"
          [nzDisabledDate]="ma.disabledDate"
          [nzFormat]="format[ma.datepickerType]"
          [nzPlaceHolder]="ma.placeholder"
          (blur)="maOnBlur.emit(true)"
        ></nz-month-picker>
        <nz-year-picker
          (ngModelChange)="onValueChangeEvent($event)"
          *ngSwitchCase="'year'"
          [formControl]="fc"
          [formlyAttributes]="field"
          [nzDisabledDate]="ma.disabledDate"
          [nzFormat]="format[ma.datepickerType]"
          [nzPlaceHolder]="ma.placeholder"
          (blur)="maOnBlur.emit(true)"
        ></nz-year-picker>
        <nz-week-picker
          (ngModelChange)="onValueChangeEvent($event)"
          *ngSwitchCase="'week'"
          [formControl]="fc"
          [formlyAttributes]="field"
          [nzDisabledDate]="ma.disabledDate"
          [nzDisabledTime]="ma.disabledTime"
          [nzFormat]="format[ma.datepickerType]"
          [nzPlaceHolder]="ma.placeholder"
          (blur)="maOnBlur.emit(true)"
        ></nz-week-picker>
        <nz-range-picker
          (ngModelChange)="onValueChangeEvent($event)"
          *ngSwitchCase="'daterange'"
          [formControl]="fc"
          [formlyAttributes]="field"
          [nzDisabledDate]="ma.disabledDate"
          [nzFormat]="format[ma.datepickerType]"
          [nzPlaceHolder]="ma.placeholder"
          (blur)="maOnBlur.emit(true)"
        ></nz-range-picker>
        <div *ngSwitchCase="'timerange'" class="date-range-wrapper">
          <nz-time-picker
            (ngModelChange)="onValueChangeEvent($event)"
            *ngSwitchCase="'time'"
            [formControl]="fc"
            [formlyAttributes]="field"
            [nzFormat]="format[ma.datepickerType]"
            [nzPlaceHolder]="ma.placeholder"
            [style.width.%]="100"
            (blur)="maOnBlur.emit(true)"
          ></nz-time-picker>
          <div class="date-separator">-</div>
          <nz-time-picker
            (ngModelChange)="onValueChangeEvent($event)"
            *ngSwitchCase="'time'"
            [formControl]="fc"
            [formlyAttributes]="field"
            [nzFormat]="format[ma.datepickerType]"
            [nzPlaceHolder]="ma.placeholder"
            [style.width.%]="100"
            (blur)="maOnBlur.emit(true)"
          ></nz-time-picker>
        </div>
        <nz-range-picker
          (ngModelChange)="onValueChangeEvent($event)"
          *ngSwitchCase="'datetimerange'"
          [formControl]="fc"
          [formlyAttributes]="field"
          [nzDisabledDate]="ma.disabledDate"
          [nzFormat]="format[ma.datepickerType]"
          [nzPlaceHolder]="ma.placeholder"
          nzShowTime
          (blur)="maOnBlur.emit(true)"
        >
        </nz-range-picker>
      </ng-container>
      <ng-template #ngModelTpl>
        <ng-container [ngSwitch]="ma.datepickerType">
          <nz-date-picker
            (ngModelChange)="onValueChangeEvent($event)"
            *ngSwitchCase="'date'"
            [(ngModel)]="value"
            [nzDisabledDate]="ma.disabledDate"
            [nzDisabledTime]="ma.disabledTime"
            [nzFormat]="format[ma.datepickerType]"
            [nzPlaceHolder]="ma.placeholder"
            (blur)="maOnBlur.emit(true)"
          ></nz-date-picker>
          <nz-date-picker
            (ngModelChange)="onValueChangeEvent($event)"
            *ngSwitchCase="'datetime'"
            [(ngModel)]="value"
            [nzDisabledDate]="ma.disabledDate"
            [nzDisabledTime]="ma.disabledTime"
            [nzFormat]="showTime ? format['datetime'] : format['date']"
            [nzPlaceHolder]="ma.placeholder"
            [nzShowTime]="
              showTime
                ? {
                    nzFormat: 'HH:mm',
                    nzDefaultOpenValue: ma.defaultOpenTimeValue,
                    nzMinuteStep: 15
                  }
                : false
            "
            [nzRenderExtraFooter]="datetimeFooterTpl"
            (blur)="maOnBlur.emit(true)"
          >
          </nz-date-picker>
          <nz-time-picker
            (ngModelChange)="onValueChangeEvent($event)"
            *ngSwitchCase="'time'"
            [(ngModel)]="value"
            [nzFormat]="format[ma.datepickerType]"
            [nzPlaceHolder]="ma.placeholder"
            (blur)="maOnBlur.emit(true)"
          ></nz-time-picker>
          <nz-month-picker
            (ngModelChange)="onValueChangeEvent($event)"
            *ngSwitchCase="'month'"
            [(ngModel)]="value"
            [nzDisabledDate]="ma.disabledDate"
            [nzFormat]="format[ma.datepickerType]"
            [nzPlaceHolder]="ma.placeholder"
            (blur)="maOnBlur.emit(true)"
          ></nz-month-picker>
          <nz-year-picker
            (ngModelChange)="onValueChangeEvent($event)"
            *ngSwitchCase="'year'"
            [(ngModel)]="value"
            [nzDisabledDate]="ma.disabledDate"
            [nzFormat]="format[ma.datepickerType]"
            [nzPlaceHolder]="ma.placeholder"
            (blur)="maOnBlur.emit(true)"
          ></nz-year-picker>
          <nz-week-picker
            (ngModelChange)="onValueChangeEvent($event)"
            *ngSwitchCase="'week'"
            [(ngModel)]="value"
            [nzDisabledDate]="ma.disabledDate"
            [nzDisabledTime]="ma.disabledTime"
            [nzFormat]="format[ma.datepickerType]"
            [nzPlaceHolder]="ma.placeholder"
            (blur)="maOnBlur.emit(true)"
          ></nz-week-picker>
          <nz-range-picker
            (ngModelChange)="onValueChangeEvent($event)"
            *ngSwitchCase="'daterange'"
            [(ngModel)]="value"
            [nzDisabledDate]="ma.disabledDate"
            [nzFormat]="format[ma.datepickerType]"
            [nzPlaceHolder]="ma.placeholder"
            (blur)="maOnBlur.emit(true)"
          ></nz-range-picker>
          <div *ngSwitchCase="'timerange'" class="date-range-wrapper">
            <nz-time-picker
              (ngModelChange)="onValueChangeEvent($event)"
              *ngSwitchCase="'time'"
              [(ngModel)]="value"
              [nzFormat]="format[ma.datepickerType]"
              [nzPlaceHolder]="ma.placeholder"
              [style.width.%]="100"
              (blur)="maOnBlur.emit(true)"
            ></nz-time-picker>
            <div class="date-separator">-</div>
            <nz-time-picker
              (ngModelChange)="onValueChangeEvent($event)"
              *ngSwitchCase="'time'"
              [(ngModel)]="value"
              [nzFormat]="format[ma.datepickerType]"
              [nzPlaceHolder]="ma.placeholder"
              [style.width.%]="100"
              (blur)="maOnBlur.emit(true)"
            ></nz-time-picker>
          </div>
          <nz-range-picker
            (ngModelChange)="onValueChangeEvent($event)"
            *ngSwitchCase="'datetimerange'"
            [(ngModel)]="value"
            [nzDisabledDate]="ma.disabledDate"
            [nzFormat]="format[ma.datepickerType]"
            [nzPlaceHolder]="ma.placeholder"
            nzShowTime
            (blur)="maOnBlur.emit(true)"
          >
          </nz-range-picker>
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-template #datetimeFooterTpl>
      <div [style.width.px]="200">
        <meta-field-wrapper [maParams]="{ label: 'Uhrzeit verwenden', labelWidth: 15, inputWidth: 9 }">
          <meta-switcher
            [maParams]="{
              editing: true
            }"
            [ngModel]="showTime"
            (ngModelChange)="onShowTimeChange()"
          ></meta-switcher>
        </meta-field-wrapper>
      </div>
    </ng-template>
    <ng-template #outputTpl>
      <ng-container [ngSwitch]="ma.datepickerType">
        <p
          *ngSwitchCase="
            ma.datepickerType === 'daterange' || ma.datepickerType === 'datetimerange' ? ma.datepickerType : ''
          "
          [nzCopyable]="ma.copyable"
          class="output"
          nz-paragraph
          nzContent="{{ (field && model ? model[id][0] : value[0]) | date: format[ma.datepickerType] }} - {{
            (field && model ? model[id][1] : value[1]) | date: format[ma.datepickerType]
          }}"
        ></p>
        <p
          *ngSwitchCase="ma.datepickerType === 'timerange' ? ma.datepickerType : ''"
          [nzCopyable]="ma.copyable"
          class="output"
          nz-paragraph
          nzContent="{{ field && model ? model[id][0] : value[0] }} - {{ field && model ? model[id][1] : value[1] }}"
        ></p>
        <p
          *ngSwitchDefault
          [nzCopyable]="ma.copyable"
          class="output"
          nz-paragraph
          nzContent="{{ ((field && model ? model[id] : value) | date: format[ma.datepickerType]) || '-' }}"
        ></p>
      </ng-container>
    </ng-template>
  `,
  styleUrls: ["./metaDatepicker.component.less"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetaDatepickerComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaDatepickerComponent extends MetaComponentBase implements OnInit {
  public format = MetaDatepickerFormat;
  public showTime = true;
  @Input() hasTime: boolean;
  @Output() hasTimeChange = new EventEmitter<boolean>();
  @Output() public maOnBlur = new EventEmitter<boolean>();

  constructor(private readonly _metaActionHandler: MetaActionHandlerFactory) {
    super();
    super.maParams = new MetaDatepicker();
  }

  get ma(): MetaDatepicker {
    return super.ma;
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.field) {
      this.formControl.valueChanges
        .pipe(
          startWith(this.model[this.id]),
          distinctUntilChanged((a, b) => {
            return JSON.stringify(moment(a).toISOString()) === JSON.stringify(moment(b).toISOString());
          }),
          skip(1),
          debounceTime(350),
          takeUntil((this as any).destroyed$),
        )
        .subscribe((value) => {
          this.executeChangeAction(value);
        });
    }
    this.showTime = this.ma.defaultShowTime;
  }

  public async onValueChangeEvent($event: any) {
    if ($event) {
      if (this.ma.datepickerType === "time") {
        if (this.field) {
          this.model[this.id] = moment($event).toISOString();
        } else {
          this.value = moment($event).toISOString();
        }
      } else if (this.ma.datepickerType === "date") {
        if (this.field) {
          this.model[this.id] = moment($event).startOf("day").format("YYYY-MM-DDT00:00:00.000");
        } else {
          this.value = moment($event).startOf("day").format("YYYY-MM-DDT00:00:00.000");
        }
      }
    } else {
      if (this.field) {
        this.model[this.id] = null;
      } else {
        this.value = null;
      }
    }

    if (this.ma.onValueChange instanceof Function) {
      this.ma.onValueChange(this.field, $event);
    }
    if (!this.field) {
      this.onChange($event);
      await this.executeChangeAction($event);
    }
  }

  public onShowTimeChange() {
    this.showTime = !this.showTime;
    this.hasTimeChange.emit(this.showTime);
  }

  private async executeChangeAction(value) {
    setTimeout(async () => {
      if (value === "" && this.formControl.dirty) {
        this.formControl.reset(null);
        return;
      }

      if (!this.ma.onChange) {
        return;
      }

      let model = this.formState.data();
      if (model === null) {
        return;
      }

      if (Object.keys(model).length === 0) {
        model = this.form.value;
      }
      await this._metaActionHandler.executeChangeAction({
        formId: this.formState.formId,
        controlId: this.id,
        data: {
          ...model,
          [this.id]: value,
        },
        subFormPath: this.metaHelperService.getFormlySubFormPath(this.field),
        ctx: model?._ctx,
        index: this.metaHelperService.getFormlyFieldArrayIndex(this.field),
      });
    });
  }
}

@NgModule({
  declarations: [MetaDatepickerComponent],
  imports: [
    CommonModule,
    FormlyModule,
    ReactiveFormsModule,
    FormsModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzTypographyModule,
    MetaFieldWrapperModule,
    MetaCheckboxModule,
    MetaSwitchModule,
  ],
  exports: [MetaDatepickerComponent],
})
export class MetaDatepickerModule {}
