/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { IsBoolean, IsIn, IsNumber, IsOptional, Max, Min } from "class-validator";
import { ExposeProperty, MetaFormComponent, MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

type maAlignType = "top" | "middle" | "bottom";
type maJustifyType = "start" | "end" | "center" | "space-around" | "space-between";

@MetaFormComponentInfo({
  type: "layout",
  icon: "sort-numeric-up-alt",
  name: "Layout Component",
  hideInPallet: true,
})
export abstract class MetaFormLayoutComponent<Sources = {}, ComponentType = {}> extends MetaFormComponent {
  @ExposeProperty() public parentId?: string = null;
  @ExposeProperty() public flex?: string | number | null;

  @MetaFormComponentProperty({
    name: "children",
    type: "children",
    description: "children",
  })
  public children?: MetaFormComponent[] = [];
  toFormly(config: IFormUserConfiguration): any {
    const fieldGroup = this.children
      .filter((e) => !!e)
      .map((e) => e.toFormly(config))
      .filter((e) => !!e);
    return deepMerge(super.toFormly(config), {
      fieldGroup,
      props: {
        flex: this.flex,
      },
      wrappers: [`meta-${this.constructor["meta"]["type"]}`],
    });
  }
}

@MetaFormComponentInfo({
  selector: "ma-row",
  type: "row",
  name: "Row",
  icon: "horizontal-rule",
  palletGroup: "layout",
})
export class RowComponent<Sources = {}, ComponentType = {}> extends MetaFormLayoutComponent<Sources, ComponentType> {
  /**
   * Row Alignment (top, middle, bottom)
   */
  @IsIn(["top", "middle", "bottom"])
  public align?: maAlignType = "top";
  /**
   * Row justify (start, end, center, space-around, space-between)
   */
  @IsIn(["start", "end", "center", "space-around", "space-between"])
  public justify?: maJustifyType = "start";

  @ExposeProperty()
  public style?: "dark" | "light" | "default" = "default";

  public static create(opts: Partial<RowComponent>) {
    const row = new RowComponent();
    Object.assign(row, opts);
    return row;
  }

  public static createElement(attributes: any, children: any[]) {
    const element = new RowComponent();
    Object.assign(element, {
      ...attributes,
      children,
    });
    return element;
  }

  public toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        align: this.align,
        justify: this.justify,
        style: this.style,
        hasCondition: !!this.condition,
      },
    });
  }
}

type SpanType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | null;

@MetaFormComponentInfo({
  selector: "ma-col",
  type: "column",
  name: "Column",
  icon: "columns-3",
  palletGroup: "layout",
})
export class ColumnComponent<Sources = {}, ComponentType = {}> extends MetaFormLayoutComponent<Sources, ComponentType> {
  @ExposeProperty()
  @IsNumber()
  @IsOptional()
  public offset? = 0;
  @ExposeProperty()
  @IsNumber()
  @IsOptional()
  public pull? = 0;
  @ExposeProperty()
  @IsNumber()
  @IsOptional()
  public push? = 0;
  @ExposeProperty()
  @IsBoolean()
  @IsOptional()
  public keepContentTogether? = false;

  @ExposeProperty()
  @IsNumber()
  @IsOptional()
  @Min(1)
  @Max(24)
  public xs?: SpanType;

  @ExposeProperty()
  @IsNumber()
  @IsOptional()
  @Min(1)
  @Max(24)
  public sm?: SpanType;

  @ExposeProperty()
  @IsNumber()
  @IsOptional()
  @Min(1)
  @Max(24)
  public md?: SpanType;

  @ExposeProperty()
  @IsNumber()
  @IsOptional()
  @Min(1)
  @Max(24)
  public lg?: SpanType;

  @ExposeProperty()
  @IsNumber()
  @IsOptional()
  @Min(1)
  @Max(24)
  public xl?: SpanType;

  @ExposeProperty()
  @IsNumber()
  @IsOptional()
  @Min(1)
  @Max(24)
  public xXl?: SpanType;

  @ExposeProperty()
  @IsOptional()
  public width?: string;

  @ExposeProperty()
  @IsOptional()
  public align?: string;

  /**
   * Number of columns to be filled.
   */

  @MetaFormComponentProperty({
    name: "Span",
    type: "number",
    tab: "style",
    required: true,
    min: 1,
    max: 24,
    default: () => 8,
  })
  public span?: SpanType = 24;

  @ExposeProperty() public hasPadding?: boolean;

  @ExposeProperty() public hasNoGutter?: boolean;

  @ExposeProperty() public style?: "default" | "dark";

  @ExposeProperty() public borderTop?: boolean;
  @ExposeProperty() public borderRight?: boolean;
  @ExposeProperty() public borderBottom?: boolean;
  @ExposeProperty() public borderLeft?: boolean;

  @ExposeProperty() public paddingLeft?: string;

  @ExposeProperty() public paddingTop?: string;

  @ExposeProperty() public paddingBottom?: string;

  @ExposeProperty() public paddingRight?: string;

  public static create(opts: Partial<ColumnComponent>) {
    const row = new ColumnComponent();
    Object.assign(row, opts);
    return row;
  }

  public toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        xs: this.xs,
        sm: this.sm,
        md: this.md,
        lg: this.lg,
        xl: this.xl,
        xXl: this.xXl,
        span: this.span,
        flex: this.flex,
        width: this.width,
        hasPadding: this.hasPadding,
        hasNoGutter: this.hasNoGutter,
        style: this.style,
        keepContentTogether: this.keepContentTogether,
        hasCondition: !!this.condition,
        paddingLeft: this.paddingLeft,
        paddingRight: this.paddingRight,
        paddingBottom: this.paddingBottom,
        paddingTop: this.paddingTop,
        borderTop: this.borderTop,
        borderRight: this.borderRight,
        borderBottom: this.borderBottom,
        borderLeft: this.borderLeft,
        align: this.align,
      },
    });
  }
}

@MetaFormComponentInfo({
  selector: "ma-collapse",
  type: "collapse",
  name: "Collapse",
  icon: "compress",
  palletGroup: "layout",
})
export class CollapseComponent<Sources = {}, ComponentType = {}> extends MetaFormLayoutComponent<
  Sources,
  ComponentType
> {
  @MetaFormComponentProperty({
    name: "Label",
    type: "text",
    tab: "general",
  })
  public label?: string;
  @MetaFormComponentProperty({
    name: "Label Einrücken",
    type: "boolean",
    tab: "style",
    default: false,
  })
  public indentLabel = false;
  @MetaFormComponentProperty({
    name: "Größe",
    type: "select",
    tab: "style",
    values: [
      {
        label: "Normal",
        value: "default",
      },
      {
        label: "Klein",
        value: "small",
      },
    ],
  })
  public size: "small" | "default" = "default";
  @MetaFormComponentProperty({
    name: "Style",
    type: "select",
    tab: "style",
    values: [
      {
        label: "Transparent",
        value: "transparent",
      },
      {
        label: "Standard",
        value: "default",
      },
    ],
  })
  @ExposeProperty()
  public style: "transparent" | "default" = "default";
  @MetaFormComponentProperty({
    name: "Aufgeklappt",
    type: "boolean",
    tab: "style",
    default: false,
  })
  public expanded = false;

  public static create(opts: Partial<CollapseComponent>) {
    const row = new CollapseComponent();
    Object.assign(row, opts);
    return row;
  }

  public toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      fieldGroupClassName: "collapse",
      props: {
        label: this.label,
        indentLabel: this.indentLabel,
        size: this.size,
        style: this.style,
        expanded: this.expanded,
        hasCondition: !!this.condition,
      },
    });
  }
}

@MetaFormComponentInfo({
  selector: "ma-field-wrapper",
  type: "field-wrapper",
  name: "Field Wrapper",
  icon: "object-group",
  palletGroup: "layout",
})
export class FieldWrapperComponent<Sources = {}> extends MetaFormLayoutComponent {
  @MetaFormComponentProperty({
    name: "Label",
    type: "text",
    tab: "general",
  })
  public label?: string;

  public static create(opts: Partial<FieldWrapperComponent>) {
    const row = new FieldWrapperComponent();
    Object.assign(row, opts);
    return row;
  }

  public toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      fieldGroupClassName: "field-wrapper",
      props: {
        label: this.label,
        childLayout: "horizontal",
        hasCondition: !!this.condition,
      },
    });
  }
}
