/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2021
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2021
 */

export function ChartTheme(name: string) {
  const dataColorPalette = [
    getComputedStyle(document.documentElement).getPropertyValue("--charts-color-01"),
    getComputedStyle(document.documentElement).getPropertyValue("--charts-color-02"),
    getComputedStyle(document.documentElement).getPropertyValue("--charts-color-03"),
    getComputedStyle(document.documentElement).getPropertyValue("--charts-color-04"),
    getComputedStyle(document.documentElement).getPropertyValue("--charts-color-05"),
    getComputedStyle(document.documentElement).getPropertyValue("--charts-color-06"),
    getComputedStyle(document.documentElement).getPropertyValue("--charts-color-07"),
    getComputedStyle(document.documentElement).getPropertyValue("--charts-color-08"),
    getComputedStyle(document.documentElement).getPropertyValue("--charts-color-09"),
    getComputedStyle(document.documentElement).getPropertyValue("--charts-color-10"),
    getComputedStyle(document.documentElement).getPropertyValue("--charts-color-11"),
    getComputedStyle(document.documentElement).getPropertyValue("--charts-color-12"),
    getComputedStyle(document.documentElement).getPropertyValue("--charts-color-13"),
    getComputedStyle(document.documentElement).getPropertyValue("--charts-color-14"),
    getComputedStyle(document.documentElement).getPropertyValue("--charts-color-15"),
    getComputedStyle(document.documentElement).getPropertyValue("--charts-color-16"),
  ].join(",");

  const datasetColorPalette = [
    getComputedStyle(document.documentElement).getPropertyValue("--charts-colorset-01"),
    getComputedStyle(document.documentElement).getPropertyValue("--charts-colorset-02"),
    getComputedStyle(document.documentElement).getPropertyValue("--charts-colorset-03"),
  ].join(",");

  const componentBackgroundOdd = getComputedStyle(document.documentElement).getPropertyValue(
    "--component-background-odd",
  );
  // @ts-ignore
  return FusionCharts.register("theme", {
    name: name,
    theme: {
      base: {
        chart: {
          paletteColors: dataColorPalette,
          baseFontSize: "12",
          captionFontSize: "14",
          subcaptionFontSize: "14",
          subcaptionFontBold: "0",
          showBorder: "0",
          bgFillOpacity: "1",
          showShadow: "0",
          canvasBorderAlpha: "0",
          useplotgradientcolor: "0",
          useRoundEdges: "0",
          showPlotBorder: "0",
          showAlternateHGridColor: "1",
          alternateHGridColor: componentBackgroundOdd,
          alternateHGridAlpha: "100",
          showAlternateVGridColor: "0",
          legendBgAlpha: "0",
          legendBorderAlpha: "0",
          legendShadow: "0",
          legendItemFontSize: "10",
          divlineAlpha: "0",
          divlineThickness: "1",
          divLineIsDashed: "1",
          divLineDashLen: "1",
          divLineGapLen: "1",
          showHoverEffect: "1",
          valueFontSize: "12",
          showXAxisLine: "1",
          xAxisLineThickness: "1",
          xAxisLineColor: componentBackgroundOdd,
          transposeAxis: 1,
          setAdaptiveYMin: 1,
          setAdaptiveXMin: 1,
          drawCustomLegendIcon: "1",
          plotHoverEffect: 1,
          anchorHoverEffect: 0,
          plotFillHoverAlpha: 85,
          plotBorderHoverThickness: 0,
          plotBorderHoverAlpha: 0,
          use3DLighting: 0,
          usePlotGradientColor: 0,
          animation: 1,
          rotateValues: 0,
          adjustDiv: 1,
          plothighlighteffect: "fadeout",
          exportEnabled: 1,
          exportShowMenuItem: 0,
          canvasPadding: 0,
          chartLeftMargin: 0,
          chartRightMargin: 0,
          chartTopMargin: 0,
          chartBottomMargin: 0,
          canvasRightPadding: 10,
          canvasTopPadding: 5,
          canvasBottomPadding: 0,
          canvasLeftMargin: 0,
          canvasRightMargin: 0,
          canvasTopMargin: 0,
          canvasBottomMargin: 0,
          // tooltip customization
          tooltipPadding: 15,
          tooltipBgColor: "#000",
          tooltipColor: "#FFF",
          tooltipBorderRadius: 2,
          tooltipBorderThickness: 0,
          tooltipBgAlpha: 100,
          slantLabels: "1",
          labelDisplay: "auto",
          useEllipsesWhenOverflow: "1",
          showValues: 0,
          legendScrollBgColor: componentBackgroundOdd,
          legendScrollBarColor: componentBackgroundOdd,
        },
      },
      zoomline: {
        chart: {
          pixelsPerPoint: "0",
          pixelsPerLabel: "40",
          compactdatamode: "1",
          dataseparator: "|",
          scrollheight: "10",
          flatScrollBars: "1",
          scrollShowButtons: "0",
          scrollColor: getComputedStyle(document.documentElement).getPropertyValue("--component-background"),
          showPeakData: "1",
          showHoverEffect: "1",
          plotHoverEffect: "1",
          toolbarButtonColor: "#FF0000",
          toolbarButtonScale: 0,
          zoomPaneBgColor: getComputedStyle(document.documentElement).getPropertyValue("--primary-color"),
        },
      },
      column2d: {
        chart: {
          placeValuesInside: "0",
          rotateValues: "1",
          maxLabelHeight: 16,
        },
      },
      column3d: {
        chart: {
          use3DLighting: "0",
          showCanvasBase: "0",
          canvasBaseDepth: "0",
          showShadow: "0",
        },
      },
      funnel: {
        chart: {
          labelDistance: "15",
          showPercentValues: "1",
          percentOfPrevious: "1",
        },
      },
      bar2d: {
        chart: {
          placeValuesInside: "0",
          showAlternateVGridColor: "0",
          yAxisValuesPadding: "10",
        },
      },
      bar3d: {
        chart: {
          use3DLighting: "0",
          showCanvasBase: "0",
          canvasBaseDepth: "0",
          placeValuesInside: "0",
          showShadow: "0",
          adjustDiv: "1",
          showAlternateVGridColor: "0",
        },
      },
      pie2d: {
        chart: {
          showPercentInTooltip: "0",
          decimals: "1",
          tooltipBorderRadius: "20",
          enableSmartLabels: 1,
          skipOverlapLabels: 1,
          isSmartLineSlanted: 1,
          showLabels: 1,
          showValues: 0,
          useDataPlotColorForLabels: 1,
          legendNumColumns: 0,
          minimiseWrappingInLegend: 1,
          alignLegendWithCanvas: 0,
          legendNumRows: 0,
          legendPosition: "BOTTOM",
          legendCaptionAlignment: "center",
        },
      },
      pie3d: {
        chart: {
          showPercentInTooltip: "0",
          decimals: "1",
          tooltipBorderRadius: "20",
          enableSmartLabels: 1,
          skipOverlapLabels: 1,
          isSmartLineSlanted: 1,
          showLabels: 1,
          showValues: 0,
          useDataPlotColorForLabels: 1,
          legendNumColumns: 0,
          minimiseWrappingInLegend: 1,
          alignLegendWithCanvas: 0,
          legendNumRows: 0,
          legendPosition: "BOTTOM",
          legendCaptionAlignment: "center",
          pieYScale: "60",
        },
      },
      doughnut2d: {
        chart: {
          showPercentInTooltip: "0",
          decimals: "1",
          tooltipBorderRadius: "20",
          enableSmartLabels: 1,
          labelDistance: 1,
          smartLineThickness: 1,
          manageLabelOverflow: 1,
          useEllipsesWhenOverflow: 1,
          skipOverlapLabels: 1,
          isSmartLineSlanted: 1,
          showLabels: 0,
          showValues: 0,
          showLegend: 0,
          useDataPlotColorForLabels: 1,
          legendNumColumns: 0,
          minimiseWrappingInLegend: 1,
          alignLegendWithCanvas: 0,
          legendNumRows: 0,
          legendPosition: "BOTTOM",
          legendCaptionAlignment: "center",
          pieRadius: "60%",
        },
      },
      doughnut3d: {
        chart: {
          showPercentInTooltip: "0",
          decimals: "1",
          tooltipBorderRadius: "20",
          enableSmartLabels: 1,
          skipOverlapLabels: 1,
          isSmartLineSlanted: 1,
          showLabels: 1,
          showValues: 0,
          useDataPlotColorForLabels: 1,
          legendNumColumns: 0,
          minimiseWrappingInLegend: 1,
          alignLegendWithCanvas: 0,
          legendNumRows: 0,
          legendPosition: "BOTTOM",
          legendCaptionAlignment: "center",
          pieYScale: "60",
        },
      },
      msbar2d: {
        chart: {
          placeValuesInside: "0",
          showAlternateVGridColor: "0",
          showLegend: "1",
          legendIconSides: "4",
          yAxisValuesPadding: "10",
          paletteColors: datasetColorPalette,
        },
      },
      msbar3d: {
        chart: {
          use3DLighting: "0",
          showCanvasBase: "0",
          canvasBaseDepth: "0",
          placeValuesInside: "0",
          showShadow: "0",
          adjustDiv: "1",
          showAlternateVGridColor: "0",
          showLegend: "1",
          legendIconSides: "4",
          yAxisValuesPadding: "10",
          paletteColors: datasetColorPalette,
        },
      },
      mscolumn2d: {
        chart: {
          showLegend: "1",
          legendIconSides: "4",
          paletteColors: datasetColorPalette,
        },
      },
      mscolumn3d: {
        chart: {
          use3DLighting: "0",
          showLegend: "1",
          legendIconSides: "4",
          showCanvasBase: "0",
          canvasBaseDepth: "0",
          placeValuesInside: "0",
          showShadow: "0",
          adjustDiv: "1",
          showAlternateVGridColor: "0",
          paletteColors: datasetColorPalette,
        },
      },
      sankey: {
        chart: {
          nodeLabelFontSize: 14,
          nodeLabelPosition: "outside",
          nodeHoverAlpha: 75,
          legendPosition: "bottom",
          plothighlighteffect: "fadeout",
          linkColor: "source",
          linkHoverAlpha: 75,
          linkAlpha: 30,
          enableDrag: 1,
          paletteColors: dataColorPalette,
        },
      },
      chord: {
        chart: {
          drawCustomLegendIcon: 0,
          legendPosition: "right",
          nodeThickness: 10,
          nodeLabelFontSize: 11,
          nodeLabelPosition: "outside",
          nodeHoverAlpha: 100,
          nodeLinkPadding: 0,
          nodeSpacing: 3,
          nodeBorderThickness: 0,
          nodeAlpha: 80,
          linkAlpha: 40,
          linkBorderAlpha: 40,
          linkHoverAlpha: 75,
          paletteColors: dataColorPalette,
        },
      },
      sunburst: {
        chart: {
          useHoverColor: 0,
          showPlotBorder: 1,
          plotborderThickness: 0.5,
          plotBorderColor: getComputedStyle(document.documentElement).getPropertyValue("--component-background"),
        },
      },
      msspline: {
        chart: {
          showLegend: "1",
          legendIconSides: "2",
          drawCrossLine: "1",
          setAdaptiveYMin: "1",
          lineThickness: "2",
          drawAnchors: "1",
          legendIconBorderThickness: "2",
          anchorRadius: "4",
          anchorHoverRadius: "7",
          anchorBorderHoverThickness: "1",
          anchorBgHoverAlpha: "100",
          paletteColors: datasetColorPalette,
        },
      },
      mssplinearea: {
        chart: {
          showLegend: "1",
          legendIconSides: "4",
          plotFillAlpha: "80",
          drawCrossLine: "1",
          setAdaptiveYMin: "1",
          lineThickness: "2",
          drawAnchors: "1",
          legendIconBorderThickness: "2",
          anchorRadius: "4",
          anchorHoverRadius: "7",
          anchorBorderHoverThickness: "1",
          anchorBgHoverAlpha: "100",
          paletteColors: datasetColorPalette,
        },
      },
      hlineargauge: {
        chart: {
          showGaugeBorder: "0",
          setAdaptiveMin: "1",
          adjustTM: "1",
          placeTicksInside: "0",
          autoAlignTickValues: "1",
          minorTMnumber: "0",
          majorTMHeight: "8",
          majorTMAlpha: "50",
          gaugeFillMix: "{light-0}",
          valueAbovePointer: "1",
          paletteColors: datasetColorPalette,
        },
      },
      angulargauge: {
        chart: {
          gaugestartangle: "135",
          gaugeendangle: "45",
          gaugeouterradius: "175",
          showvalue: "1",
          showTickValues: "0",
          valuefontsize: "25",
          majorTMColor: getComputedStyle(document.documentElement).getPropertyValue("--component-background"),
          majorTMAlpha: "100",
          majorTMHeight: "15",
          majorTMThickness: "3",
          minorTMColor: getComputedStyle(document.documentElement).getPropertyValue("--component-background"),
          minorTMAlpha: "100",
          minorTMHeight: "5",
          minorTMThickness: "1",
          gaugeFillMix: "{light-0}",
          pivotFillColor: getComputedStyle(document.documentElement).getPropertyValue("--error-color"),
          pivotFillAlpha: "100",
          pivotRadius: "10",
          pivotFillMix: " ",
        },
        dials: {
          dial: [
            {
              bgColor: getComputedStyle(document.documentElement).getPropertyValue("--error-color"),
              borderColor: getComputedStyle(document.documentElement).getPropertyValue("--error-color"),
              borderThickness: "1",
            },
          ],
        },
      },
      errorline: {
        chart: {
          lineThickness: "2",
          anchorRadius: "4",
          anchorHoverEffect: "1",
          anchorHoverRadius: "4",
          anchorBorderHoverThickness: "1.5",
          legendIconBorderThickness: "1",
          showLegend: "1",
          legendIconSides: "2",
          errorBarThickness: "0.7",
          errorBarAlpha: "80",
          paletteColors: datasetColorPalette,
        },
      },
      errorbar2d: {
        chart: {
          errorBarThickness: 1,
          paletteColors: datasetColorPalette,
        },
      },
      errorscatter: {
        chart: {
          drawCustomLegendIcon: 0,
          anchorBorderThickness: 2,
          legendIconBorderThickness: 2,
          errorBarThickness: 1,
          paletteColors: datasetColorPalette,
        },
      },
      timeseries: {
        chart: {
          paletteColors: datasetColorPalette,
          multiCanvasTooltip: 1,
        },
        tooltip: {
          enabled: 1,
          style: {
            container: {
              background: "#000",
              border: "none",
            },
            text: {
              color: "#FFF",
            },
            header: {},
            body: {},
          },
        },
        navigator: {
          scrollbar: {
            style: {
              button: {
                fill: getComputedStyle(document.documentElement).getPropertyValue("--body-background"),
              },
              track: {
                fill: getComputedStyle(document.documentElement).getPropertyValue("--component-background"),
              },
              scroller: {
                fill: getComputedStyle(document.documentElement).getPropertyValue("--body-background"),
              },
              arrow: {
                fill: getComputedStyle(document.documentElement).getPropertyValue("--text-color-secondary"),
              },
              grip: {
                stroke: getComputedStyle(document.documentElement).getPropertyValue("--text-color-secondary"),
              },
            },
          },
          window: {
            style: {
              handle: {
                fill: getComputedStyle(document.documentElement).getPropertyValue("--body-background"),
              },
              "handle-grip": {
                stroke: getComputedStyle(document.documentElement).getPropertyValue("--text-color-secondary"),
              },
              mask: {
                opacity: 1,
                stroke: getComputedStyle(document.documentElement).getPropertyValue("--body-background"),
                "stroke-width": 1,
              },
            },
          },
        },
        crossline: {
          style: {
            line: {
              fill: getComputedStyle(document.documentElement).getPropertyValue("--text-color-secondary"),
              "stroke-width": 1,
            },
          },
        },
        plotconfig: {
          column: {
            style: {
              "plot:hover": {
                opacity: 0.85,
              },
              "plot:highlight": {
                opacity: 0.85,
              },
            },
          },
          line: {
            style: {
              plot: {
                "stroke-width": 2,
              },
            },
          },
          candlestick: {
            style: {
              bear: {
                stroke: getComputedStyle(document.documentElement).getPropertyValue("theme-charts-color-01"),
                fill: getComputedStyle(document.documentElement).getPropertyValue("theme-charts-color-01"),
              },
              "bear:hover": {
                opacity: 0.85,
              },
              "bear:highlight": {
                opacity: 0.85,
              },
              bull: {
                stroke: getComputedStyle(document.documentElement).getPropertyValue("--text-color-secondary"),
                fill: getComputedStyle(document.documentElement).getPropertyValue("--text-color-secondary"),
              },
              "bull:hover": {
                opacity: 0.85,
              },
              "bull:highlight": {
                opacity: 0.85,
              },
            },
          },
          ohlc: {
            style: {
              bear: {
                stroke: getComputedStyle(document.documentElement).getPropertyValue("theme-charts-color-01"),
                fill: getComputedStyle(document.documentElement).getPropertyValue("theme-charts-color-01"),
              },
              "bear:hover": {
                opacity: 0.85,
              },
              "bear:highlight": {
                opacity: 0.85,
              },
              bull: {
                stroke: getComputedStyle(document.documentElement).getPropertyValue("--text-color-secondary"),
                fill: getComputedStyle(document.documentElement).getPropertyValue("--text-color-secondary"),
              },
              "bull:hover": {
                opacity: 0.85,
              },
              "bull:highlight": {
                opacity: 0.85,
              },
            },
          },
        },
        yaxis: [
          {
            style: {
              "tick-mark": {
                stroke: getComputedStyle(document.documentElement).getPropertyValue("--text-color-secondary"),
                "stroke-width": 0.75,
                strokeOpacity: 0.25,
              },
              "grid-line": {
                stroke: getComputedStyle(document.documentElement).getPropertyValue("--text-color-secondary"),
                "stroke-width": 0.75,
                strokeOpacity: 0.25,
              },
            },
          },
        ],
        xaxis: {
          style: {
            "tick-mark-major": {
              stroke: getComputedStyle(document.documentElement).getPropertyValue("--text-color-secondary"),
              "stroke-width": 0.75,
              strokeOpacity: 0.5,
            },
            "tick-mark-minor": {
              stroke: getComputedStyle(document.documentElement).getPropertyValue("--text-color-secondary"),
              "stroke-width": 0.5,
              strokeOpacity: 0.25,
            },
            "grid-line": {
              stroke: getComputedStyle(document.documentElement).getPropertyValue("--text-color-secondary"),
              "stroke-width": 0.75,
              strokeOpacity: 0.25,
            },
          },
        },
        legend: {
          style: {
            text: {
              "font-size": 12,
            },
          },
        },
        extensions: {
          standardRangeSelector: {
            enabled: "0",
          },
          customRangeSelector: {
            enabled: "0",
          },
        },
      },
      mscombidy2d: {
        chart: {
          lineThickness: "2",
          anchorRadius: "4",
          drawCrossLine: "1",
          showLegend: "1",
          drawCustomLegendIcon: "0",
          anchorHoverEffect: "1",
          anchorHoverRadius: "4",
          anchorBorderHoverThickness: "1.5",
          legendIconBorderThickness: "1",
        },
      },
      multilevelpie: {
        chart: {
          useHoverColor: "1",
          hoverFillColor: getComputedStyle(document.documentElement).getPropertyValue("--primary-color"),
          showHoverEffect: "1",
          plotHoverEffect: "1",
          paletteColors: datasetColorPalette,
        },
        category: [
          {
            paletteColors: datasetColorPalette,
            category: [
              {
                paletteColors: datasetColorPalette,
                category: [
                  {
                    paletteColors: datasetColorPalette,
                  },
                ],
              },
            ],
          },
        ],
      },
      zoomlinedy: {
        chart: {
          compactDataMode: "1",
          pixelsPerPoint: "0",
          dataSeparator: "|",
          snumberPrefix: "$",
          pYAxisMaxValue: "1500",
          pYAxisMinValue: "900",
          sYAxisMaxValue: "23000",
          sYAxisMinValue: "15000",
          lineThickness: "2",
          flatScrollBars: "1",
          scrollShowButtons: "0",
          scrollHeight: "10",
          crossLineThickness: "1",
          showLegend: "1",
          legendIconSides: "2",
          anchorHoverEffect: "1",
          anchorHoverRadius: "4",
          anchorBorderHoverThickness: "1.5",
          legendIconBorderThickness: "1",
        },
      },
      scatter: {
        chart: {
          showCanvasBase: "0",
          canvasBaseDepth: "0",
          showShadow: "0",
          adjustDiv: "1",
          lineThickness: "0",
          anchorRadius: "8",
          anchorHoverEffect: "1",
          anchorHoverRadius: "10",
          anchorBorderHoverThickness: "0",
          showLegend: "1",
          drawCustomLegendIcon: "0",
          paletteColors: datasetColorPalette,
        },
      },
      zoomscatter: {
        chart: {
          showShadow: "0",
          adjustDiv: "1",
          lineThickness: "0",
          anchorRadius: "8",
          showLegend: "1",
          drawCustomLegendIcon: "0",
          anchorHoverRadius: "10",
          anchorBorderHoverThickness: "0",
          paletteColors: datasetColorPalette,
        },
      },
      geo: {
        chart: {
          showLabels: "0",
          legendScaleLineThickness: "0",
          legendaxisborderalpha: "0",
          legendShadow: "0",
          showEntityHoverEffect: "1",
          entityFillHoverAlpha: "90",
          connectorHoverAlpha: "90",
          markerBorderHoverAlpha: "90",
          showBorder: "1",
          borderThickness: "0.1",
          nullEntityColor: getComputedStyle(document.documentElement).getPropertyValue("--component-background"),
          nullEntityAlpha: "100",
          legendPosition: "bottom",
        },
        colorrange: {
          gradient: "0",
        },
      },
      scrollcolumn2d: {
        chart: {
          showLegend: "1",
          legendIconSides: "4",
          showCanvasBase: "0",
          canvasBaseDepth: "0",
          showShadow: "0",
          adjustDiv: "1",
          flatScrollBars: "1",
          scrollShowButtons: "0",
          scrollheight: "10",
          numVisiblePlot: "8",
        },
      },
      scrollline2d: {
        chart: {
          showShadow: "0",
          adjustDiv: "1",
          lineThickness: "2",
          anchorRadius: "4",
          showLegend: "1",
          legendIconSides: "2",
          flatScrollBars: "1",
          scrollShowButtons: "0",
          scrollheight: "10",
          drawCrossLine: "1",
          anchorHoverEffect: "1",
          anchorHoverRadius: "4",
          anchorBorderHoverThickness: "1.5",
          numVisiblePlot: "8",
          legendIconBorderThickness: "1",
        },
      },
      scrollarea2d: {
        chart: {
          showShadow: "0",
          adjustDiv: "1",
          lineThickness: "2",
          drawAnchors: "0",
          showLegend: "1",
          legendIconSides: "2",
          flatScrollBars: "1",
          scrollShowButtons: "0",
          scrollheight: "10",
          numVisiblePlot: "8",
        },
      },
      scrollstackedcolumn2d: {
        chart: {
          showLegend: "1",
          legendIconSides: "4",
          flatScrollBars: "1",
          scrollShowButtons: "0",
          scrollheight: "10",
          numVisiblePlot: "8",
        },
      },
      scrollcombi2d: {
        chart: {
          lineThickness: "2",
          anchorRadius: "4",
          showLegend: "1",
          flatScrollBars: "1",
          scrollShowButtons: "0",
          scrollheight: "10",
          drawCustomLegendIcon: "0",
          anchorHoverEffect: "1",
          anchorHoverRadius: "4",
          anchorBorderHoverThickness: "1.5",
          numVisiblePlot: "8",
          legendIconBorderThickness: "1",
        },
      },
      scrollcombidy2d: {
        chart: {
          lineThickness: "2",
          anchorRadius: "4",
          showLegend: "1",
          flatScrollBars: "1",
          scrollShowButtons: "0",
          scrollheight: "10",
          drawCustomLegendIcon: "0",
          anchorHoverEffect: "1",
          anchorHoverRadius: "4",
          anchorBorderHoverThickness: "1.5",
          numVisiblePlot: "8",
          legendIconBorderThickness: "1",
        },
      },
      bulb: {
        chart: {
          is3D: "0",
          placeValuesInside: "1",
          valueFont: "MerriweatherSans",
          paletteColors: datasetColorPalette,
        },
      },
      cylinder: {
        chart: {
          cylRadius: "40",
          cylYScale: "13",
          showValue: 1,
          cylFillColor: getComputedStyle(document.documentElement).getPropertyValue("--charts-colorset-02"),
        },
      },
      hled: {
        chart: {
          ledGap: "0",
          showGaugeBorder: "0",
          setAdaptiveMin: "1",
          adjustTM: "1",
          placeTicksInside: "0",
          autoAlignTickValues: "1",
          minortmnumber: "0",
          majorTMHeight: "8",
          majorTMAlpha: "50",
          paletteColors: datasetColorPalette,
        },
      },
      thermometer: {
        chart: {
          use3DLighting: "0",
          manageResize: "1",
          autoScale: "1",
          showGaugeBorder: "1",
          gaugeBorderAlpha: "40",
          placeTicksInside: "0",
          autoAlignTickValues: "1",
          minortmnumber: "0",
          majorTMHeight: "8",
          majorTMAlpha: "50",
          thmFillColor: getComputedStyle(document.documentElement).getPropertyValue("--charts-colorset-02"),
        },
      },
      vled: {
        chart: {
          ledGap: "0",
          showGaugeBorder: "0",
          setAdaptiveMin: "1",
          adjustTM: "1",
          placeTicksInside: "0",
          autoAlignTickValues: "1",
          minortmnumber: "0",
          majorTMHeight: "8",
          majorTMAlpha: "50",
          paletteColors: datasetColorPalette,
        },
      },
      sparkline: {
        chart: {
          captionPosition: "middle",
          plotFillColor: getComputedStyle(document.documentElement).getPropertyValue("--charts-color-01"),
          lineAlpha: 85,
          plotFillHoverColor: getComputedStyle(document.documentElement).getPropertyValue("--charts-color-01"),
          lineThickness: 2,
          anchorRadius: 4,
          anchorBorderThickness: 2,
          highColor: getComputedStyle(document.documentElement).getPropertyValue("--charts-color-03"),
          lowColor: getComputedStyle(document.documentElement).getPropertyValue("--charts-color-02"),
          showOpenAnchor: 0,
          showCloseAnchor: 0,
          showOpenValue: 0,
          showCloseValue: 0,
          showHighLowValue: 0,
          periodColor: getComputedStyle(document.documentElement).getPropertyValue("--charts-color-05"),
          chartLeftMargin: 5,
          chartRightMargin: 5,
        },
      },
      sparkcolumn: {
        chart: {
          captionPosition: "middle",
          plotFillColor: getComputedStyle(document.documentElement).getPropertyValue("--charts-color-01"),
          highColor: getComputedStyle(document.documentElement).getPropertyValue("--charts-color-03"),
          lowColor: getComputedStyle(document.documentElement).getPropertyValue("--charts-color-02"),
          periodColor: getComputedStyle(document.documentElement).getPropertyValue("--charts-color-05"),
          chartLeftMargin: 5,
        },
      },
      sparkwinloss: {
        chart: {
          captionPosition: "middle",
          winColor: getComputedStyle(document.documentElement).getPropertyValue("--charts-color-02"),
          lossColor: getComputedStyle(document.documentElement).getPropertyValue("--charts-color-03"),
          drawColor: getComputedStyle(document.documentElement).getPropertyValue("--charts-color-01"),
          scoreLessColor: getComputedStyle(document.documentElement).getPropertyValue("--charts-color-04"),
          periodColor: getComputedStyle(document.documentElement).getPropertyValue("--charts-color-05"),
          chartLeftMargin: 5,
        },
      },
      inversemsarea: {
        chart: {
          drawAnchors: 0,
          plotFillAlpha: 50,
          legendIconBgAlpha: 50,
          legendIconBorderAlpha: 0,
          legendIconSides: 2,
          drawCrossLine: 1,
          anchorBorderThickness: 2,
          plotHoverEffect: 0,
          paletteColors: datasetColorPalette,
        },
      },
      inversemscolumn2d: {
        chart: {
          drawCrossLine: 1,
          paletteColors: datasetColorPalette,
        },
      },
      inversemsline: {
        chart: {
          drawCrossLine: 1,
          lineThickness: 2,
          anchorBorderThickness: 2,
          legendIconSides: 2,
          legendIconBorderThickness: 2,
          plotHoverEffect: 0,
          paletteColors: datasetColorPalette,
        },
      },
      treemap: {
        chart: {
          parentLabelLineHeight: 16.5,
          labelFontSize: 13,
          showParent: 1,
          showNavigationBar: 0,
          plotBorderThickness: 0.5,
          plotBorderColor: getComputedStyle(document.documentElement).getPropertyValue("--popover-background"),
          labelGlow: 0,
          btnBackChartTooltext: "Back",
          btnResetChartTooltext: "Home",
          legendScaleLineThickness: 0,
          legendaxisborderalpha: 0,
          legendPointerColor: getComputedStyle(document.documentElement).getPropertyValue("--component-background"),
          legendpointerbordercolor: getComputedStyle(document.documentElement).getPropertyValue("--text-color"),
          legendPointerAlpha: 85,
          defaultParentBgColor: getComputedStyle(document.documentElement).getPropertyValue("--component-background"),
          nullEntityColor: getComputedStyle(document.documentElement).getPropertyValue("--component-background"),
          nullEntityAlpha: "100",
          paletteColors: datasetColorPalette,
        },
        data: [
          {
            fillcolor: getComputedStyle(document.documentElement).getPropertyValue("--component-background"),
            data: [
              {
                fillcolor: getComputedStyle(document.documentElement).getPropertyValue("--component-background"),
              },
            ],
          },
        ],
      },
      europe2: {
        chart: {
          fillColor: getComputedStyle(document.documentElement).getPropertyValue("--popover-background").trim(),
          nullEntityColor: getComputedStyle(document.documentElement).getPropertyValue("--popover-background").trim(),
          chartLeftMargin: 0,
          chartRightMargin: 0,
          chartTopMargin: 0,
          chartBottomMargin: 0,
          includeNameInLabels: 0,
          includeValueInLabels: 1,
          caption: "",
          hoverOnEmpty: 0,
          showLabels: 1,
          captionPosition: "top-left",
        },
      },
    },
  });
}
