/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation } from "@angular/core";
import { MetaButtonModule, MetaEmptyModule, MetaSectionModule } from "@meta/ui";
import * as _ from "lodash";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaColumnModule } from "../metaColumn/metaColumn.component";
import { MetaHubService } from "../metaHub/metaHub.service";
import { MetaRowModule } from "../metaRow/metaRow.component";
import { MetaTaskFormModule } from "./metaTask.form.component";

export class MetaTaskFormFormly {
  sourceType?: string | null = null;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-task-form-formly",
  template: `
    <meta-task-form
      [maParams]="{
        sourceType: ma.sourceType,
        formId: field ? formState.formId : null,
        isFormlyContext: true,
        onValueChange: onTaskFormChange.bind(this)
      }"
    ></meta-task-form>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaTaskFormFormlyComponent extends MetaComponentBase {
  constructor() {
    super();
    super.maParams = new MetaTaskFormFormly();
    this.onTaskFormChange = _.debounce(this.onTaskFormChange, 500);
  }

  get ma(): MetaTaskFormFormly {
    return super.ma;
  }

  public onTaskFormChange(data: any) {
    const model = this.formState.data();
    model[this.field.parent.parent.id][this.field.parent["index"]][this.field.id] = JSON.stringify(data);
    this.formState.data.set(model);
  }
}

@NgModule({
  declarations: [MetaTaskFormFormlyComponent],
  imports: [
    CommonModule,
    MetaTaskFormModule,
    MetaButtonModule,
    MetaEmptyModule,
    MetaRowModule,
    MetaColumnModule,
    MetaSectionModule,
  ],
  providers: [MetaHubService],
  exports: [MetaTaskFormFormlyComponent],
})
export class MetaTaskFormFormlyModule {}
