/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { DataSourceParameters, MetaFormComponentInfo } from "./baseComponent";
import { DataSourceType } from "@meta/database/lib/types";
import { ValidatableComponent } from "./validatableComponent";
import { FormEventActionHandler, IServerTemplateMainSocketContext } from "../interfaces";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-tree-view",
  type: "treeview",
  icon: "tree",
  name: "Tree view",
})
export class TreeViewComponent extends ValidatableComponent {
  constructor() {
    super();
  }

  public treeDataSource: DataSourceType;
  public treeDataSourceParams: DataSourceParameters;
  public treeDataSourceParamsF?: FormEventActionHandler<Record<string, any>>;

  public getChildren?: ($: IServerTemplateMainSocketContext<any>, parentKey: string) => Promise<Record<string, any>[]>;

  public expandableField?: string;
  public typeField?: string;
  public labelField: string;
  public labelTemplate: string | { type: string; template: string }[];
  public idFields: string[] = [];
  public autoExpand: number = 2;
  public rootLabelTemplate: string;
  public rootLabelPrefix: string;
  public dontReloadOnNavigation: boolean;

  public static createElement(attributes: Partial<TreeViewComponent>) {
    const ele = new TreeViewComponent();
    Object.assign(ele, attributes);
    return ele;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        autoExpand: this.autoExpand,
        dontReloadOnNavigation: this.dontReloadOnNavigation,
      },
    });
  }
}
