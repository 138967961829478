/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponent, MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";
import { MetaIconStyle, MetaSectionType } from "@meta/api-interfaces";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-section",
  type: "section",
  name: "Section",
  icon: "text",
})
export class MetaFormSectionComponent extends MetaFormComponent {
  @MetaFormComponentProperty({
    name: "Type",
    type: "select",
    tab: "style",
    default: MetaSectionType.paragraph,
    values: Object.entries(MetaSectionType).map(([label, value]) => {
      return {
        label,
        value,
      };
    }),
  })
  public sectionType: MetaSectionType = MetaSectionType.paragraph;
  @MetaFormComponentProperty({
    name: "Type",
    type: "select",
    tab: "style",
    default: "none",
    values: [
      {
        label: "None",
        value: "none",
      },
      {
        label: "Condensed",
        value: "condensed",
      },
    ],
  })
  public sectionStyle: "none" | "condensed" = "none";
  @MetaFormComponentProperty({
    name: "Icon",
    type: "icon",
    iconSet: "fa",
    tab: "style",
    condition: (o) => o.labelType === "icon",
  })
  public icon: string;
  @MetaFormComponentProperty({
    name: "Style",
    type: "css",
    tab: "style",
  })
  public style: string;
  @MetaFormComponentProperty({
    name: "Align",
    type: "select",
    values: [
      { label: "left", value: "left", icon: "align-left" },
      { label: "center", value: "center", icon: "align-center" },
      { label: "right", value: "right", icon: "align-right" },
    ],
    tab: "style",
  })
  public align: "left" | "right" | "center";
  @MetaFormComponentProperty({
    name: "Text trim",
    type: "boolean",
    tab: "style",
  })
  public textTrim: boolean;
  @MetaFormComponentProperty({
    name: "spacingBottom",
    type: "number",
    min: 1,
    max: 5,
    tab: "style",
  })
  public spacingTop: 1 | 2 | 3 | 4 | 5;
  @MetaFormComponentProperty({
    name: "spacingBottom",
    type: "number",
    min: 1,
    max: 5,
    tab: "style",
  })
  public spacingBottom: 1 | 2 | 3 | 4 | 5;
  @MetaFormComponentProperty({
    name: "height",
    type: "number",
    unit: "px",
    tab: "style",
  })
  public height: number | "auto";
  @MetaFormComponentProperty({
    name: "hasFieldWrapper",
    type: "boolean",
    default: false,
    tab: "style",
  })
  public hasFieldWrapper = false;
  @MetaFormComponentProperty({
    name: "Label",
    type: "text",
    isTemplate: true,
    tab: "style",
  })
  public label: string;
  @MetaFormComponentProperty({
    name: "Label Type",
    type: "select",
    values: [
      { label: "text", value: "text" },
      { label: "icon", value: "icon" },
    ],
    default: "text",
    tab: "style",
  })
  public labelType?: "text" | "icon" = "text";
  @MetaFormComponentProperty({
    name: "Icon Style",
    type: "select",
    values: [
      { label: "Light", value: "fa-light" },
      { label: "Solid", value: "fa-solid" },
      { label: "Thin", value: "fa-thin" },
    ],
    default: "fa-solid",
    condition: (o) => o.labelType === "icon",
    tab: "style",
  })
  public iconStyle?: MetaIconStyle = MetaIconStyle.Solid;
  @MetaFormComponentProperty({
    name: "Icon Text",
    type: "text",
    condition: (o) => o.labelType === "icon",
    tab: "style",
  })
  public iconText?: string;
  @MetaFormComponentProperty({
    name: "Condensed",
    type: "boolean",
    default: false,
    tab: "style",
  })
  public condensed? = false;
  @MetaFormComponentProperty({
    name: "Label Width",
    type: "number",
    tab: "style",
  })
  public labelWidth?: number;
  @MetaFormComponentProperty({
    name: "Input Width",
    type: "number",
    tab: "style",
  })
  public inputWidth?: number;
  @MetaFormComponentProperty({
    name: "Font Size",
    type: "css-property",
    tab: "style",
    property: "font-size",
  })
  public fontSize: string;

  @MetaFormComponentProperty({
    name: "Layout",
    type: "select",
    values: [
      {
        label: "Horizontal",
        value: "horizontal",
      },
      {
        label: "Vertical",
        value: "vertical",
      },
    ],
    tab: "style",
  })
  public layout?: "vertical" | "horizontal" = "horizontal";

  @MetaFormComponentProperty({
    name: "Display Value",
    type: "text",
    isTemplate: true,
    tab: "general",
    default: "Lorem ipsum dolor sit amet",
    description: "The value to be displayed when the form is not in edit mode.",
    showAsTextarea: true,
  })
  public displayValue?: string;
  @MetaFormComponentProperty({
    name: "Color",
    type: "color",
    tab: "style",
  })
  public color?: string;
  @MetaFormComponentProperty({
    name: "Flex",
    type: "number",
    tab: "style",
  })
  public flex?: number;
  @MetaFormComponentProperty({
    name: "Min Width",
    type: "number",
    tab: "style",
  })
  public minWidth?: number;

  @MetaFormComponentProperty({
    name: "Link",
    type: "text",
    isTemplate: true,
    tab: "general",
    condition: (c) => c.sectionType === "link",
  })
  public link?: string;

  public static create(opts: Partial<MetaFormSectionComponent>) {
    const item = new MetaFormSectionComponent();
    Object.assign(item, opts);
    return item;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      type: `meta-${this.constructor["meta"]["type"]}`,
      props: {
        sectionType: this.sectionType,
        sectionStyle: this.sectionStyle,
        spacingTop: this.spacingTop,
        spacingBottom: this.spacingBottom,
        style: this.style,
        align: this.align,
        icon: this.icon,
        height: this.height,
        label: this.label,
        labelWidth: this.labelWidth,
        inputWidth: this.inputWidth,
        layout: this.layout,
        hasFieldWrapper: this.hasFieldWrapper,
        flex: this.flex,
        minWidth: this.minWidth,
        fontSize: this.fontSize,
        color: this.color,
        hasCondition: this.condition && typeof this.condition === "string",
        labelType: this.labelType,
        iconStyle: this.iconStyle,
        iconText: this.iconText,
        condensed: this.condensed,
        textTrim: this.textTrim,
      },
      wrappers: this.hasFieldWrapper ? ["meta-field-wrapper"] : [],
    });
  }
}
