/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  OnDestroy,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { defineElement } from "@lordicon/element";
import { Themes } from "@meta/enums";
import { FormlyModule } from "@ngx-formly/core";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { firstValueFrom } from "rxjs";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaAppService } from "../../services/metaApp.service";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";

export class MetaIcon {
  icon: string;
  disabled? = false;
  trigger?: "loop" | "hover" | "click" | "enable" = "loop";
  target?: string;
  delay? = 2_500;
  stroke? = 55;
  scale? = 60;
  size?: number | "auto" = 32;
  width?: number | "auto";
  style?: "originalColors" | "light" | "dark" | "default" | "avatar" = "default";
}

@MetaUnsubscribe()
@Component({
  selector: "meta-icon",
  template: ``,
  styles: [
    `
      meta-icon {
        line-height: 1;
        display: inline-flex;
        align-items: center;

        .avatar {
          border-radius: 20%;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaIconComponent extends MetaComponentBase implements AfterViewInit, OnDestroy {
  private static initProm: Promise<void>;
  public theme: Themes;

  public themes = Themes;
  private icon: HTMLElement;

  constructor(
    _changeDetectorRef: ChangeDetectorRef,
    _metaHelperService: MetaHelperService,
    public metaAppRepository: MetaAppService,
    private viewContainerRef: ViewContainerRef,
  ) {
    super();
    super.maParams = new MetaIcon();
  }

  get ma(): MetaIcon {
    return super.ma;
  }

  get iconUrl(): string {
    const icon = this.ma.icon;
    if (icon.indexOf("http") === 0 || icon.indexOf("/") === 0) {
      return icon;
    } else {
      return `/api/v2/icons/lord/${icon}`;
    }
  }

  private static async initializeLottie() {
    if (!this.initProm) {
      this.initProm = (async () => {
        const lottie = await import("lottie-web").then((e) => e.default);
        defineElement(lottie.loadAnimation);
      })();
    }
    await this.initProm;
  }

  async ngOnInit() {
    super.ngOnInit();
    const result = await firstValueFrom(this.metaAppRepository.app$);
    if (result.theme === "system") {
      this.theme =
        window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches ? Themes.dark : Themes.default;
    } else {
      this.theme = result.theme;
    }
  }

  public renderIcon() {
    let bgColor: any;
    const ele = this.viewContainerRef.element.nativeElement as HTMLElement;
    const template = document.createElement("template");
    const attr = Object.entries(this.getAttrObject())
      .map(([key, value]) => {
        return `${key}="${encodeURI(value)}"`;
      })
      .join(" ");
    if (this.ma.style === "avatar") {
      bgColor = this.metaHelperService.generateColor(this.ma.icon);
    }
    const html = `<lord-icon class="${
      this.ma.style === "avatar" ? "avatar" : ""
    }" style="background-color: ${bgColor}" ${attr} ></lord-icon>`;
    template.innerHTML = html.trim();
    this.icon = template.content.firstChild as HTMLElement;
    ele.append(this.icon);
    this.updateIcon();
  }

  public getAttrObject() {
    const ma = this.ma;
    let colors: string;
    switch (this.ma.style) {
      case "originalColors":
        colors = null;
        break;
      case "light":
        colors = this.getColor("light");
        break;
      case "dark":
        colors = this.getColor("dark");
        break;
      case "avatar":
        colors = this.getColor("avatar");
        break;
      default:
        colors = this.getColor();
    }

    return {
      colors: colors,
      stroke: String(ma.stroke),
      trigger: ma.trigger === "enable" ? "hover" : ma.trigger,
      target: ma.target,
      delay: String(ma.delay || 0),
      scale: String(ma.scale),
      src: this.iconUrl,
    };
  }

  public updateIcon() {
    const icon = this.icon;
    if (!icon) return;
    const ma = this.ma;
    Object.entries(this.getAttrObject()).forEach(([k, v]) => {
      const old = this.icon.getAttribute(k);
      if (old !== v) {
        this.icon.setAttribute(k, v);
      }
    });
    if (ma.size === "auto") {
      icon.style.height = `100%`;
    } else {
      icon.style.height = `${ma.size}px`;
    }
    if (ma.width === "auto") {
      icon.style.width = "100%";
    } else {
      icon.style.width = `${ma.width || ma.size}px`;
    }
  }

  public getColor(style?: string) {
    if (style === "light") {
      return this.ma.disabled ? "primary:#339999,secondary:#454545" : "primary:#ffffff,secondary:#ffffff";
    } else if (style === "dark") {
      return this.ma.disabled ? "primary:#e52753,secondary:#16c79e" : "primary:#000000,secondary:#000000";
    } else if (style === "avatar") {
      return this.ma.disabled ? "primary:#000000,secondary:#000000" : "primary:#000000,secondary:#000000";
    } else {
      return this.ma.disabled
        ? this.theme === this.themes.dark
          ? "primary:#14c4bc,secondary:#14c4bc"
          : "primary:#14c4bc,secondary:#14c4bc"
        : this.theme === this.themes.dark
          ? "primary:#14c4bc,secondary:#00918a"
          : "primary:#14c4bc,secondary:#00918a";
    }
  }

  ngAfterViewInit(): void {
    MetaIconComponent.initializeLottie().then(() => {
      this.renderIcon();
    });
  }

  ngOnDestroy(): void {
    if (this.icon) {
      this.icon.remove();
      this.icon = null;
    }
  }
}

@NgModule({
  declarations: [MetaIconComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, FormlyModule, NzWaveModule, NzButtonModule, NzPopconfirmModule],
  exports: [MetaIconComponent],
})
export class MetaIconModule {}
