/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

export const chartTypeTranslations = [
  {
    id: "column2d",
    label: "Spalten (2D)",
  },
  {
    id: "column3d",
    label: "Spalten (3D)",
  },
  {
    id: "line",
    label: "Linien",
  },
  {
    id: "area2d",
    label: "Fläche (2D)",
  },
  {
    id: "funnel",
    label: "Trichter (2D)",
  },
  {
    id: "bar2d",
    label: "Balken (2D)",
  },
  {
    id: "bar3d",
    label: "Balken (3D)",
  },
  {
    id: "pie2d",
    label: "Kuchen (2D)",
  },
  {
    id: "pie3d",
    label: "Kuchen (3D)",
  },
  {
    id: "doughnut2d",
    label: "Donut (2D)",
  },
  {
    id: "doughnut3d",
    label: "Donut (3D)",
  },
  {
    id: "pareto2d",
    label: "Pareto (2D)",
  },
  {
    id: "pareto3d",
    label: "Pareto (3D)",
  },
  {
    id: "spline",
    label: "Kurven",
  },
  {
    id: "splinearea",
    label: "Kurvenfläche",
  },
  {
    id: "mscolumn2d",
    label: "Spalten (2D)",
  },
  {
    id: "mscolumn3d",
    label: "Spalten (3D)",
  },
  {
    id: "msline",
    label: "Linien",
  },
  {
    id: "msbar2d",
    label: "Balken (2D)",
  },
  {
    id: "msbar3d",
    label: "Balken (3D)",
  },
  {
    id: "overlappedcolumn2d",
    label: "Überlappende Spalten (2D)",
  },
  {
    id: "overlappedbar2d",
    label: "Überlappende Balken (3D)",
  },
  {
    id: "msarea",
    label: "Flächen",
  },
  {
    id: "marimekko",
    label: "Marimekko",
  },
  {
    id: "zoomline",
    label: "Linien mit Zoom",
  },
  {
    id: "zoomlinedy",
    label: "Duale Y-Linien mit Zoom",
  },
  {
    id: "msspline",
    label: "Kurven",
  },
  {
    id: "mssplinearea",
    label: "Kurvenflächen",
  },
  {
    id: "stackedcolumn2d",
    label: "Gestapelte Spalten (2D)",
  },
  {
    id: "stackedcolumn3d",
    label: "Gestapelte Spalten (3D)",
  },
  {
    id: "stackedbar2d",
    label: "Gestapelte Balken (2D)",
  },
  {
    id: "stackedbar3d",
    label: "Gestapelte Balken (3D)",
  },
  {
    id: "stackedarea2d",
    label: "Gestapelte Flächen (2D)",
  },
  {
    id: "stackedarea3d",
    label: "Gestapelte Flächen (3D)",
  },
  {
    id: "scrollcolumn2d",
    label: "Spalten mit Scrolling (2D)",
  },
  {
    id: "scrollbar2d",
    label: "Balken mit Scolling (2D)",
  },
  {
    id: "scrollline2d",
    label: "Linien mit Scrolling (2D)",
  },
  {
    id: "scrollarea2d",
    label: "Flächen mit Scrolling (2D)",
  },
  {
    id: "scrollstackedcolumn2d",
    label: "Gestapelte Spalten mit Scrolling (2D)",
  },
  {
    id: "scrollstackedbar2d",
    label: "Gestapelte Balken mit Scrolling (2D)",
  },
  {
    id: "inversemsarea",
    label: "Umgekehrte Flächen",
  },
  {
    id: "inversemscolumn2d",
    label: "Umgekehrte Spalten (2D)",
  },
  {
    id: "inversemsline",
    label: "Umgekehrte Linien",
  },
  {
    id: "mscombi2d",
    label: "Kombination (2D)",
  },
  {
    id: "mscombi3d",
    label: "Kombination (3D)",
  },
  {
    id: "mscolumnline3d",
    label: "Spalten mit Linien (3D)",
  },
  {
    id: "stackedcolumn2dline",
    label: "Gestapelte Spalten (2D) mit Linien",
  },
  {
    id: "stackedcolumn3dline",
    label: "Gestapelte Spalten (3D) mit Linien",
  },
  {
    id: "scrollcombi2d",
    label: "Kombination mit Scrolling (2D)",
  },
  {
    id: "scrollcombidy2d",
    label: "Duale Y-Kombination mit Scrolling (2D)",
  },
  {
    id: "stackedcolumn2dlinedy",
    label: "Duale Y-Gestapelte Spalten (2D) mit Linien",
  },
  {
    id: "stackedarea2dlinedy",
    label: "Duale Y-Gestapelte Flächen (2D) mit Linien",
  },
  {
    id: "mscombidy2d",
    label: "Duale Y-Kombination (2D)",
  },
  {
    id: "mscombidy3d",
    label: "Duale Y-Kombination (3D)",
  },
  {
    id: "mscolumn3dlinedy",
    label: "Duale Y-Spalten (3D) mit Linien",
  },
  {
    id: "stackedcolumn3dlinedy",
    label: "Duale Y-Gestapelte Spalten (3D) mit Linien",
  },
  {
    id: "scatter",
    label: "Streuung",
  },
  {
    id: "zoomscatter",
    label: "Streuung mit Zoom",
  },
  {
    id: "msstackedcolumn2d",
    label: "Gestapelte Spalten (2D)",
  },
  {
    id: "scrollmsstackedcolumn2d",
    label: "Gestapelte Spalten mit Scrolling (2D)",
  },
  {
    id: "msstackedcolumn2dlinedy",
    label: "Duale Y-Gestapelte Spalten (2D) mit Linien",
  },
  {
    id: "scrollmsstackedcolumn2dlinedy",
    label: "Duale Y-Gestapelte Spalten (2D) mit Scrolling und Linien",
  },
  {
    id: "bulb",
    label: "Birne",
  },
  {
    id: "cylinder",
    label: "Zylinder",
  },
  {
    id: "hled",
    label: "Horizontales LED",
  },
  {
    id: "vled",
    label: "Vertikales LED",
  },
  {
    id: "thermometer",
    label: "Thermometer",
  },
  {
    id: "sparkline",
    label: "Linien",
  },
  {
    id: "sparkcolumn",
    label: "Spalten",
  },
  {
    id: "errorbar2d",
    label: "Fehler-Balken (2D)",
  },
  {
    id: "errorline",
    label: "Fehler-Linien",
  },
  {
    id: "sankey",
    label: "Sankey",
  },
  {
    id: "chord",
    label: "Sehnen",
  },
  {
    id: "angulargauge",
    label: "Messgerät",
  },
  {
    id: "hlineargauge",
    label: "Horizontales lineares Messgerät",
  },
  {
    id: "bubble",
    label: "Blasen",
  },
  {
    id: "sparkwinloss",
    label: "Gewinn/Verlust",
  },
  {
    id: "errorscatter",
    label: "Fehler-Streuung",
  },
  {
    id: "sunburst",
    label: "Sonnenstrahlen",
  },
  {
    id: "treemap",
    label: "Baumstruktur",
  },
  {
    id: "multilevelpie",
    label: "Kuchen mit mehrfachen Ebenen",
  },
  {
    id: "timeseries",
    label: "Zeitstrahl",
  },
];
