/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, Input } from "@angular/core";

@Component({
  selector: "meta-approve-modal",
  template: `
    <ng-template #avatarTemplate> </ng-template>
    <ng-template #descTemplate>
      <span [innerHTML]="body"></span>
    </ng-template>
    <ng-template #subTemplate>
      <span [innerHTML]="[subject]"></span>
    </ng-template>
    <nz-card [nzActions]="[actionEllipsis]" [nzTitle]="subject">
      <div class="card-content">
        <nz-card-meta [nzTitle]="subTemplate" [nzDescription]="descTemplate" [nzAvatar]="avatarTemplate"></nz-card-meta>
        <meta-textarea [maParams]="{ placeholder: 'Kommentar', editing: true }" [(ngModel)]="comment"></meta-textarea>
      </div>
    </nz-card>

    <ng-template #actionEllipsis>
      <div class="actions-container">
        @for (a of actions; track a) {
          <meta-button [maParams]="a" />
        }
      </div>
    </ng-template>

    <div *nzModalTitle>Freigabe</div>
    <div *nzModalFooter></div>
  `,
  styleUrl: "./approveModal.component.less",
})
export class ApproveModalComponent {
  body: string;
  subject: string;
  comment = "";
  @Input()
  actions: any[] = [];
}
