/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IFeedResultItem } from "@meta/api-interfaces";
import { MetaTimelineItem } from "@meta/ui";
import * as Diff from "diff";
import * as moment from "moment";
import { NzMessageService } from "ng-zorro-antd/message";
import { BehaviorSubject, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { MetaErrorHandlerService } from "../../services/metaErrorHandler.service";

interface GetHubResult {
  resultSet: any[];
  total: number;
}

export type MetaHubSkipTake = {
  [id in MetaHubElement]: {
    skip: number;
    take: number;
  };
};

export type MetaHubLoadMore = {
  [id in MetaHubElement]: boolean;
};

export enum MetaHubElement {
  activities = "activities",
  actions = "actions",
  appointments = "appointments",
  tasks = "tasks",
  timeline = "timeline",
  history = "history",
}

@Injectable()
export class MetaHubService {
  public activities$: BehaviorSubject<MetaTimelineItem[]> = new BehaviorSubject<MetaTimelineItem[]>([]);
  public activitiesTotal$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public users$: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);

  public historyThemes$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  public skipTake: MetaHubSkipTake = {
    activities: {
      skip: 0,
      take: 15,
    },
    actions: {
      skip: 0,
      take: 1,
    },
    appointments: {
      skip: 0,
      take: 1,
    },
    tasks: {
      skip: 0,
      take: 1,
    },
    timeline: {
      skip: 0,
      take: 10,
    },
    history: {
      skip: 0,
      take: 10,
    },
  };

  public isLoadingMore: MetaHubLoadMore = {
    [MetaHubElement.activities]: false,
    [MetaHubElement.actions]: false,
    [MetaHubElement.appointments]: false,
    [MetaHubElement.history]: false,
    [MetaHubElement.tasks]: false,
    [MetaHubElement.timeline]: false,
  };

  public isLoadingActivities: boolean;
  public isSearchingActivities: boolean;

  private loadingActivitiesSubscription: Subscription;

  constructor(
    private _http: HttpClient,
    private _metaErrorHandlerService: MetaErrorHandlerService,
    private _nzMessageService: NzMessageService,
  ) {}

  public async getActivities(quelleId?: string, quelleTyp?: string, filter?: string, user?: string, startDate?: Date) {
    if (this.loadingActivitiesSubscription) {
      this.loadingActivitiesSubscription.unsubscribe();
      this.loadingActivitiesSubscription = null;
    }

    const params = new URLSearchParams();
    if (this.skipTake.activities.skip === 0) {
      this.isSearchingActivities = true;
      this.isLoadingActivities = true;
      this.activities$.next([]);
    }
    params.set("skip", `${this.skipTake.activities.skip}`);
    params.set("take", `${this.skipTake.activities.take}`);
    params.set("favorites", String(false));

    if (filter) params.set("filter", `${filter}`);
    if (quelleId) params.set("quelleId", `${quelleId}`);
    if (quelleTyp) params.set("quelleTyp", `${quelleTyp}`);
    if (user) params.set("user", `${user}`);
    if (startDate) params.set("startDate", startDate.toISOString());

    this.loadingActivitiesSubscription = this._http.get<GetHubResult>(`hub/log?${params}`).subscribe({
      next: (value) => {
        if (this.skipTake.activities.skip > 0) {
          let activities = this.activities$.getValue();
          if (!activities) {
            this.skipTake.activities.skip = 0;
            activities = [];
          }
          activities = activities.concat(
            this._mapActivities(value.resultSet, quelleId !== undefined && quelleId !== null),
          );
          this.activities$.next(activities);
        } else {
          this.activities$.next(this._mapActivities(value.resultSet, quelleId !== undefined && quelleId !== null));
        }
        this.activitiesTotal$.next(value.total);
      },
      error: (err) => {
        this._metaErrorHandlerService
          .handleError(err)
          .pipe(take(1))
          .subscribe({
            error: (value) => {
              this._nzMessageService.error(value);
              this.isLoadingMore.activities = false;
              this.isLoadingActivities = false;
              this.isSearchingActivities = false;
            },
          });
      },
      complete: () => {
        this.isLoadingMore.activities = false;
        this.isLoadingActivities = false;
        this.isSearchingActivities = false;
      },
    });
    if (!this.historyThemes$.value || this.historyThemes$.value.length === 0) {
      this._http.get<any[]>(`hub/history/themes`).subscribe((res) => {
        this.historyThemes$.next(res);
      });
    }
  }

  public _mapActivities(resultSet: IFeedResultItem[], hideLabel = false) {
    return resultSet
      .map((rs) => {
        return {
          ID: String(rs.id),
          style: "log",
          icon: rs.entity.icon,
          type: rs.entity.type,
          link: rs.entity.link,
          label: rs.entity.title,
          description: this.generateDescription(rs.data),
          avatar: rs.user.id,
          avatarName: rs.user.name,
          metadata: [
            {
              icon: "clock",
              label: moment(rs.date).fromNow(),
              tooltip: moment(rs.date).format("dd, DD.MM.yyyy") + " " + moment(rs.date).format("HH:mm") + " Uhr",
            },
            {
              label: rs.user.name,
              avatar: rs.user.id,
              link: "frmUsers/" + rs.user.id,
            },
          ],
          data: rs,
        };
      })
      .filter((e) => !!e);
  }

  public getUsers() {
    this._http.get<any[]>(`hub/users`).subscribe({
      next: (value) => {
        this.users$.next(value);
      },
      error: (err) => {
        this._metaErrorHandlerService
          .handleError(err)
          .pipe(take(1))
          .subscribe({
            error: (value) => this._nzMessageService.error(value),
          });
      },
    });
  }

  private generateDescription(data: any) {
    return data?.isCreate
      ? null
      : (data?.entries || [])
          .map((e) => {
            const diff = Diff.diffLines(this._formatValue(e.oldDisplayValue), this._formatValue(e.newDisplayValue));
            let span,
              title = null;
            const fragment = document.createElement("span");
            title = document.createElement("span");
            title.classList.add("log-label");
            title.appendChild(document.createTextNode(`${e.lable || e.field}: `));
            fragment.appendChild(title);
            diff.forEach((part, index) => {
              const color = part.added ? "log-added" : part.removed ? "log-removed" : "log-default";
              span = document.createElement("span");
              span.classList.add(color);
              span.appendChild(document.createTextNode(`${part.value}`));
              fragment.appendChild(span);
              if (index !== diff.length - 1) {
                const icon = document.createElement("i");
                icon.classList.add("fas");
                icon.classList.add("fa-caret-right");
                icon.classList.add("mx-2");
                fragment.appendChild(icon);
              }
            });
            return fragment.outerHTML;
          })
          .join("<br/>");
  }

  private _formatValue(value: any) {
    if (value === true) {
      value = "Ja";
    } else if (value === false) {
      value = "Nein";
    } else if (moment(value, moment.ISO_8601, true).isValid() && isNaN(value)) {
      value = moment(value).format("DD.MM.YYYY");
    }
    return String(value !== null && value !== undefined ? value : "");
  }
}
