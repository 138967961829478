/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SearchCompilationDto } from "libs/forms/src/metaState.dto";
import { NzMessageService } from "ng-zorro-antd/message";
import { BehaviorSubject, Observable } from "rxjs";
import { first, take } from "rxjs/operators";
import { MetaErrorHandlerService } from "../../services/metaErrorHandler.service";
import { IMetaListStoreResponse } from "../metaList/metaList.repository";

export interface Search {
  id: string;
  data: {
    id: string;
    avatar: string;
    values: string[];
    primaryItem: Record<string, any>;
  }[];
  total: number;
}

export interface SearchResponse {
  resultSet: {
    id: string;
    avatar: string;
    values: string[];
  }[];
  total: number;
}

@Injectable()
export class MetaSearchService {
  public itemsToSave$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public itemsToSaveDisplayValue$: BehaviorSubject<IMetaListStoreResponse[]> = new BehaviorSubject<
    IMetaListStoreResponse[]
  >([]);

  constructor(
    private _metaErrorHandlerService: MetaErrorHandlerService,
    private _nzMessageService: NzMessageService,
    private _http: HttpClient,
  ) {}

  public async addItemToSave(item: string, displayValue: IMetaListStoreResponse, qty = 1) {
    const items = await this.itemsToSave$.pipe(first()).toPromise();
    items.push(item);
    this.itemsToSave$.next(items);
    const itemsDV: IMetaListStoreResponse[] = await this.itemsToSaveDisplayValue$.pipe(first()).toPromise();
    itemsDV.push({
      ...displayValue,
      _qty: qty,
    });
    this.itemsToSaveDisplayValue$.next(itemsDV);
  }

  public async removeItemFromSave(item: string) {
    const items = await this.itemsToSave$.pipe(first()).toPromise();
    items.splice(items.indexOf(item), 1);
    this.itemsToSave$.next(items);
    const itemsDV: IMetaListStoreResponse[] = await this.itemsToSaveDisplayValue$.pipe(first()).toPromise();
    itemsDV.splice(
      itemsDV.findIndex((o) => o.id === item),
      1,
    );
    this.itemsToSaveDisplayValue$.next(itemsDV);
  }

  public getByIds(formId: string, ids: string[], skip: number, take: number): Observable<any> {
    const params = new URLSearchParams();
    params.set("skip", `${skip}`);
    params.set("take", `${take}`);
    return this._http.post<Search>(`search/${formId}?${params}`, ids);
  }

  public getRange(tagId: string): Observable<Search> {
    return this._http.get<any>(`tags/${tagId}/specification/getRange`);
  }

  public getSpecification(maUuid: string, searchString: string): Observable<any> {
    return this._http.post(`search/${maUuid}/autocomplete`, {
      skip: 0,
      take: 1,
      searchString,
    });
  }

  public async getSelectedItemsToSave(): Promise<SearchCompilationDto[]> {
    const itemsToSaveDp = await this.itemsToSaveDisplayValue$.pipe(take(1)).toPromise();
    return itemsToSaveDp.map((e) => {
      return {
        id: e._id.join(","),
        quantity: e._qty,
        price: null,
      };
    });
  }

  public async loadSelectedItems(formId: string, parentFormId: string) {
    /*const items = await this._http
      .get<SearchCompilationDto[]>(`search/${formId}/compilation`, {
        params: {
          itemId: await this._formsService.selectIdById(parentFormId).pipe(take(1)).toPromise(),
        },
      })
      .toPromise();
    const displayItems = await this.getByIds(
      formId,
      items.map((e) => e.id),
      0,
      9999
    ).toPromise();
    items.forEach((item, index) => {
      if (displayItems.resultSet[index]) {
        const o = displayItems.resultSet[index];
        this.addItemToSave(
          item.id,
          {
            id: o.id,
            avatar: o.avatar,
            label: o.values[0],
            description: o.values.slice(1, o.values.length),
          },
          item.quantity
        );
      }
    });*/
  }

  async saveSelectedItems(formId: string, itemId: string) {
    const itemsToSave = await this.getSelectedItemsToSave();
    return await this._http
      .post<boolean>(`search/${formId}/compilation`, itemsToSave, {
        params: {
          itemId: itemId,
        },
      })
      .toPromise();
  }
}
