/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MetaColumnModule } from "../metaColumn/metaColumn.component";
import { MetaRowModule } from "../metaRow/metaRow.component";
import { MetaDatepickerModule } from "../metaDatepicker/metaDatepicker.component";
import * as moment from "moment";
import { HttpClient } from "@angular/common/http";
import { UserService } from "../../services/user.service";
import { MetaTaskService } from "./metaTask.service";
import { MetaInputComponent, MetaInputModule } from "../metaInput/metaInput.component";
import { MetaButtonModule } from "../metaButton/metaButton.component";
import { MetaFieldWrapperModule } from "../metaFieldWrapper/metaFieldWrapper.component";
import { MetaTaskListModule } from "./metaTask.list.component";
import { MetaSectionModule } from "@meta/ui";

export class MetaTaskSubtasks {
  parentId: string;
  sourceId?: string | null = null;
  sourceType?: string | null = null;
  hideHeader?: boolean = false;
  editing?: boolean = false;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-task-subtasks",
  template: `
    <ng-container *ngIf="maParams.parentId">
      <meta-button
        *ngIf="!showForm && !maParams.editing"
        [maParams]="{ label: 'Unteraufgabe hinzufügen', icon: 'plus', type: 'link' }"
        (click)="showForm = true"
      ></meta-button>
      <ng-container *ngIf="showForm || maParams.editing">
        <meta-section [maParams]="{ sectionType: 'space', spacingBottom: 3 }"></meta-section>
        <meta-field-wrapper>
          <meta-input
            #titleInput
            [maParams]="{
              editing: true,
              placeholder: 'Aufgabentitel...',
              disabled: isSaving,
              required: true
            }"
            (keydown.enter)="onSave()"
            [(ngModel)]="taskModel.title"
          ></meta-input>
        </meta-field-wrapper>

        <div [style.display]="'flex'">
          <meta-button
            [maParams]="{
              type: 'primary',
              label: 'Aufgabe hinzufügen',
              loading: isSaving,
              disabled: !taskModel.title
            }"
            (click)="onSave()"
          ></meta-button>
          <meta-button
            [maParams]="{
              type: 'text',
              label: 'Abbrechen'
            }"
            (click)="resetForm(); showForm = false"
          ></meta-button>
        </div>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ["./metaTask.subtasks.component.less"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaTaskSubtasksComponent implements OnInit, AfterViewInit {
  public showForm: boolean;
  private _maParams: MetaTaskSubtasks = new MetaTaskSubtasks();
  public taskModel = {
    title: null,
    description: null,
    assignedToUsers: [],
    assignedToGroups: [],
    dueDate: null,
    priority: 4,
    hasTime: false,
    sourceId: null,
    sourceType: null,
    done: false,
    parent: null,
  };
  public isSaving: boolean;
  public defaultOpenTimeValue: Date = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();

  @ViewChild("titleInput") public titleInput: MetaInputComponent;

  @Input()
  public get maParams(): MetaTaskSubtasks {
    return this._maParams;
  }

  public set maParams(value: MetaTaskSubtasks) {
    this._maParams = { ...this._maParams, ...value };
  }

  constructor(
    private _http: HttpClient,
    private _userService: UserService,
    private _metaTaskService: MetaTaskService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    this.taskModel.assignedToUsers.push(this._userService.user.value.id);
    this.taskModel.sourceId = this.maParams.sourceId;
    this.taskModel.sourceType = this.maParams.sourceType;
    this.taskModel.parent = this.maParams.parentId;
  }

  public ngAfterViewInit() {}

  public async onSave() {
    if (
      this.taskModel.title === undefined ||
      this.taskModel.title === null ||
      this.taskModel.title === "" ||
      !this.maParams.parentId
    ) {
      return;
    }

    this.isSaving = true;
    this._changeDetectorRef.markForCheck();
    await this._metaTaskService.create({
      ...this.taskModel,
    });
    this.resetForm();
    this.isSaving = false;
    this._changeDetectorRef.markForCheck();
    this.titleInput.setFocus();
  }

  public resetForm() {
    this.taskModel = {
      title: null,
      description: null,
      assignedToUsers: [this._userService.user.value.id],
      assignedToGroups: [],
      dueDate: null,
      priority: 4,
      hasTime: false,
      sourceId: this.maParams.sourceId,
      sourceType: this.maParams.sourceType,
      done: false,
      parent: this.maParams.parentId,
    };
  }
}

@NgModule({
  declarations: [MetaTaskSubtasksComponent],
  imports: [
    CommonModule,
    MetaInputModule,
    MetaButtonModule,
    FormsModule,
    MetaFieldWrapperModule,
    MetaColumnModule,
    MetaRowModule,
    MetaDatepickerModule,
    MetaButtonModule,
    MetaFieldWrapperModule,
    MetaInputModule,
    MetaTaskListModule,
    MetaSectionModule,
  ],
  exports: [MetaTaskSubtasksComponent],
})
export class MetaTaskSubtaskModule {}
