/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ExposeProperty, MetaFormComponentInfo } from "./baseComponent";
import { MetaFormSelectComponent } from "./selectComponent";
import { Expose } from "class-transformer";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-specifications",
  type: "specifications",
  hideInPallet: true,
  name: "Specifications",
})
export class SpecificationsComponent extends MetaFormSelectComponent<any> {
  public static selectDataSource: any;
  @ExposeProperty()
  public controlsDisabled = false;
  @ExposeProperty()
  public toolbarTarget: string;
  @ExposeProperty()
  public showAsList: boolean;

  public static createElement(attributes: any, children: any[]) {
    const component = new SpecificationsComponent();
    Object.assign(component, {
      ...attributes,
      children: Array.isArray(children) ? children : [],
    });
    component.getAllKeys = true;
    /*component.selectDataSource = SqlDataSource.fromString(`
    SELECT * FROM TAG_Sammlungen where IsSpezifikation = 1
    `);*/
    (component as any).selectValueField = "ID";
    (component as any).selectLabelField = "TagName";
    (component as any).selectParentValueField = "Parent_TagID";
    return component;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        controlsDisabled: this.controlsDisabled,
      },
    });
  }
}
