/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { A11yModule } from "@angular/cdk/a11y";
import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  NgModule,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MetaInputType, MetaPrefixType, MetaSuffixType } from "@meta/enums";
import { createMask, InputMaskModule } from "@ngneat/input-mask";
import { InputmaskOptions } from "@ngneat/input-mask/lib/types";
import { FormlyModule } from "@ngx-formly/core";
import * as _ from "lodash";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzInputModule } from "ng-zorro-antd/input";
import { takeUntil } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import * as uuid from "uuid";
import { MetaComponentBase, MetaFormBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaActionHandlerFactory } from "../../base/metaForm/actions/actionHandler.factory";
import { PipesModule } from "../../pipes/pipes.module";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaButton, MetaButtonModule } from "../metaButton/metaButton.component";
import { MetaFieldWrapperModule } from "../metaFieldWrapper/metaFieldWrapper.component";
import { MetaSwitchModule } from "../metaSwitch/metaSwitch.component";

export class MetaInput extends MetaFormBase {
  inputType?: MetaInputType = "text";
  prefix?: string;
  prefixType?: MetaPrefixType = MetaPrefixType.text;
  suffix?: string;
  suffixType?: MetaSuffixType = MetaSuffixType.text;
  placeholder? = "";
  actions?: MetaButton[];
  digits?: number;
  min?: number;
  max?: number;
  currency? = "Eur";
  onChange?: boolean;
  onBlur?: boolean;
  onFocus?: boolean;
  canReplace?: boolean;
  link?: string;
  autocomplete? = "off";
}

const numberFormatter = new Intl.NumberFormat();
const currencyFormatter = new Intl.NumberFormat(navigator.language, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

@MetaUnsubscribe()
@Component({
  selector: "meta-input",
  template: `
    <ng-container *ngIf="ma as ma">
      <ng-container *ngIf="editing && !ma.readonly; else outputTpl">
        <ng-container
          *ngIf="
            displayData.suffix || displayData.prefix || ma.suffix || ma.prefix || ma.actions?.length > 0;
            else inputTpl
          "
        >
          <nz-input-group
            [nzSuffix]="displayData.suffix || ma.suffix ? suffixTpl : null"
            [nzPrefix]="displayData.prefix || ma.prefix ? prefixTpl : null"
            [nzAddOnAfter]="ma.actions?.length > 0 && !ma.disabled ? addOnAfterTemplate : null"
            [nzSearch]="ma.actions?.length > 0"
            [ngClass]="{ 'input-group-wrapper-disabled': ma.disabled }"
          >
            <ng-container *ngTemplateOutlet="inputTpl"></ng-container>
          </nz-input-group>
        </ng-container>
        <ng-template #inputTpl>
          <ng-container *ngIf="field; else ngModelTpl">
            <ng-container *ngIf="isReplacer; else elseReplacer">
              <input
                [cdkTrapFocusAutoCapture]="focus"
                [cdkTrapFocus]="focus"
                nz-input
                [autocomplete]="ma.autocomplete || 'off'"
                [(ngModel)]="pattern"
                (ngModelChange)="onReplacePatternChangeEvent($event)"
                [placeholder]="'Nach welchem Wert soll gesucht werden?'"
                [type]="ma.inputType"
                [formlyAttributes]="field"
                [style.width.%]="50"
              />
              <input
                [cdkTrapFocusAutoCapture]="focus"
                [cdkTrapFocus]="focus"
                nz-input
                [autocomplete]="ma.autocomplete || 'off'"
                [(ngModel)]="replacer"
                (ngModelChange)="onReplaceValueChangeEvent($event)"
                [placeholder]="'Wie lautet der neue Wert?'"
                [type]="ma.inputType"
                [formlyAttributes]="field"
                [style.width.%]="50"
              />
            </ng-container>
            <ng-template #elseReplacer>
              <input
                [cdkTrapFocusAutoCapture]="focus"
                [cdkTrapFocus]="focus"
                nz-input
                [autocomplete]="ma.autocomplete || 'off'"
                [placeholder]="ma.placeholder || ''"
                [formControl]="fc"
                [type]="ma.inputType === 'text' || ma.inputType === 'password' ? ma.inputType : undefined"
                [ngClass]="{ 'secure-text': ma.inputType === 'secure-text' }"
                [formlyAttributes]="field"
                [inputMask]="inputMask"
                (blur)="onBlur()"
                (focus)="onFocus()"
              />
            </ng-template>
            <meta-field-wrapper
              *ngIf="ma.canReplace && ma.inputType === 'text'"
              [maParams]="{
                labelWidth: 4,
                inputWidth: 20,
                description:
                  'Wenn Aktiv, wird nicht der komplette Inhalt des Feldes überschrieben, sondern nur ein bestimmter angegebener Text mit einem anderen Text ersetzt.',
                label: 'Teilwert ändern'
              }"
            >
              <meta-switcher
                [maParams]="{
                  disabled: ma.disabled,
                  editing: true
                }"
                [(ngModel)]="isReplacer"
                (ngModelChange)="onCanReplaceChange($event)"
              ></meta-switcher>
            </meta-field-wrapper>
          </ng-container>
        </ng-template>
        <ng-template #ngModelTpl>
          <input
            nz-input
            [cdkTrapFocusAutoCapture]="focus"
            [cdkTrapFocus]="focus"
            #inputEl="ngModel"
            [autocomplete]="ma.autocomplete || 'off'"
            [type]="
              ma.inputType === 'text' || ma.inputType === 'password' || ma.inputType === 'color'
                ? ma.inputType
                : undefined
            "
            [placeholder]="ma.placeholder || ''"
            [(ngModel)]="value"
            (ngModelChange)="onChange($event)"
            [disabled]="ma.disabled"
            [inputMask]="inputMask"
            (blur)="hasFocus = false; onBlur()"
            (focus)="hasFocus = true; onFocus()"
          />
        </ng-template>
        <ng-template #addOnAfterTemplate>
          <meta-button
            *ngFor="let action of ma.actions"
            [maParams]="action.props"
            (click)="action.onClick ? action.onClick(field, $event) : this.onDefaultClick(action)"
          ></meta-button>
        </ng-template>
      </ng-container>
      <ng-template #outputTpl>
        <p [ngClass]="{ 'secure-text': ma.inputType === 'secure-text' }" class="output text-{{ displayData.color }}">
          <ng-container *ngTemplateOutlet="prefixTpl"></ng-container>
          <span *ngIf="ma.inputType === 'decimal'; else elseTpl">
            {{ displayValue() | metaNumber: { digits: ma.digits } }}
          </span>
          <ng-template #elseTpl>{{ displayValue() }}</ng-template>

          <ng-container *ngTemplateOutlet="suffixTpl"></ng-container>
          <ng-container *ngIf="getLink() as link">
            <a title="Eintrag Anzeigen" class="select-link" [routerLink]="link"><i class="fal fa-link"></i></a>
          </ng-container>
        </p>
      </ng-template>
      <ng-template #prefixTpl>
        <ng-container *ngIf="displayData.prefix || ma.prefix">
          <ng-container [ngSwitch]="ma.prefixType">
            <ng-container *ngSwitchCase="metaPrefixType.text">
              {{ displayData.prefix || ma.prefix }}
            </ng-container>
            <ng-container *ngSwitchCase="metaPrefixType.icon">
              <i class="fas fa-{{ displayData.prefix || ma.prefix }}"></i>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
      <ng-template #suffixTpl>
        <ng-container *ngIf="displayData.suffix || ma.suffix">
          <ng-container [ngSwitch]="ma.suffixType">
            <ng-container *ngSwitchCase="metaSuffixType.text">
              {{ displayData.suffix || ma.suffix }}
            </ng-container>
            <ng-container *ngSwitchCase="metaSuffixType.icon">
              <i class="fas fa-{{ displayData.suffix || ma.suffix }}"></i>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ["./metaInput.component.less"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetaInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaInputComponent extends MetaComponentBase implements OnInit {
  public metaSuffixType = MetaSuffixType;
  public metaPrefixType = MetaPrefixType;
  public executeAction: boolean;
  public inputMask: InputmaskOptions<unknown>;
  public isReplacer: boolean;
  public pattern: string;
  public replacer: string;
  public uuid: string;
  public hasFocus: boolean;
  public focus: boolean;

  @Output() public maOnBlur = new EventEmitter<boolean>();
  @Output() public maOnFocus = new EventEmitter<boolean>();

  constructor(private readonly _metaActionHandler: MetaActionHandlerFactory) {
    super();
    super.maParams = new MetaInput();
  }

  get ma(): MetaInput {
    return super.ma;
  }

  public displayValue() {
    let value = this.field && this.model ? this.model[this.id] : this.value;
    if (value === null || value === undefined || value === "") {
      return "-";
    }
    if (!isNaN(Number(value)) && this.ma.inputType !== "text") {
      value = Number(value);
    }
    if (typeof value === "number") {
      if (this.ma.inputType === "currency") {
        return currencyFormatter.format(value);
      }
      if (this.ma.inputType === "percent") {
        return numberFormatter.format(value * 100);
      }
    }
    return value;
  }

  ngOnInit() {
    super.ngOnInit();
    this.uuid = uuid.v4();
    if (this.field) {
      this.formControl.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(350), takeUntil((this as any).destroyed$))
        .subscribe((value) => this.executeChangeAction(value));
    }

    this.inputMask = this.getInputMask();
  }

  public async onDefaultClick(control: MetaButton) {
    this.executeAction = true;
    const model = this.formState.data();
    await this._metaActionHandler.executeClickAction({
      formId: this.formState.formId,
      controlId: control.id,
      data: {
        ...model,
      },
      subFormPath: this.metaHelperService.getFormlySubFormPath(this.field),
      ctx: model?._ctx,
      formIsValid: true,
      passthroughData: { formId: this.formState.formId },
    });

    this.executeAction = false;
    this.changeDetectorRef.markForCheck();
  }

  public onCanReplaceChange(value: boolean) {
    this.pattern = null;
    this.replacer = null;
    this.formControl.patchValue(null);
  }

  public onReplacePatternChangeEvent(value: string) {
    this.formControl.patchValue({
      pattern: value,
      value: this.replacer || null,
    });
  }

  public onReplaceValueChangeEvent(value: string) {
    this.formControl.patchValue({
      pattern: this.pattern || null,
      value: value,
    });
  }

  public getLink() {
    return this.field ? _.get(this.formState, ["displayResult", this.field.id, "link"], null) : null;
  }

  public async onFocus() {
    this.maOnFocus.emit(true);
    this.hasFocus = true;
    if (!this.ma.onFocus) {
      return;
    }

    const model = this.formState.data();
    await this._metaActionHandler.executeFocusAction({
      formId: this.formState.formId,
      controlId: this.id,
      data: {
        ...model,
        [this.id]: this.formControl.value,
      },
      subFormPath: this.metaHelperService.getFormlySubFormPath(this.field),
      ctx: model?._ctx,
      index: this.metaHelperService.getFormlyFieldArrayIndex(this.field),
    });
  }

  public async onBlur() {
    this.maOnBlur.emit(true);
    this.focus = false;
    this.hasFocus = false;
    if (!this.ma.onBlur) {
      return;
    }

    const model = this.formState.data();
    await this._metaActionHandler.executeBlurAction({
      formId: this.formState.formId,
      controlId: this.id,
      data: {
        ...model,
        [this.id]: this.formControl.value,
      },
      subFormPath: this.metaHelperService.getFormlySubFormPath(this.field),
      ctx: model?._ctx,
      index: this.metaHelperService.getFormlyFieldArrayIndex(this.field),
    });
  }

  public setFocus() {
    this.focus = true;
  }

  private getInputMask() {
    const radixPoint = numberFormatter.format(1.1).split("")[1];
    const groupSeparator = radixPoint === "," ? "." : ",";
    switch (this.ma.inputType as string) {
      case "number":
        return createMask({
          alias: "integer",
          digits: 0,
          digitsOptional: false,
          unmaskAsNumber: true,
          inputType: "number",
          autoUnmask: true,
          min: this.ma.min,
          max: this.ma.max,
          showMaskOnFocus: false,
          showMaskOnHover: false,
        });
      case "decimal":
        return createMask({
          alias: "numeric",
          groupSeparator,
          unmaskAsNumber: true,
          autoUnmask: true,
          inputType: "number",
          radixPoint,
          digits: this.ma.digits === undefined ? 20 : this.ma.digits,
          digitsOptional: true,
          min: this.ma.min,
          max: this.ma.max,
          showMaskOnFocus: false,
          showMaskOnHover: false,
        });
      case "currency":
        return createMask({
          alias: "numeric",
          groupSeparator,
          radixPoint,
          autoUnmask: true,
          unmaskAsNumber: true,
          inputType: "number",
          digits: this.ma.digits === undefined ? 2 : this.ma.digits,
          digitsOptional: false,
          min: this.ma.min,
          max: this.ma.max,
          showMaskOnFocus: false,
          showMaskOnHover: false,
        });
      case "percent":
        return createMask({
          alias: "numeric",
          groupSeparator,
          radixPoint,
          autoUnmask: true,
          unmaskAsNumber: true,
          inputType: "number",
          digits: this.ma.digits === undefined ? 20 : this.ma.digits,
          digitsOptional: true,
          min: this.ma.min,
          max: this.ma.max,
          showMaskOnFocus: false,
          showMaskOnHover: false,
          onBeforeMask: (initialValue) => {
            if (this.hasFocus || isNaN(Number(initialValue))) {
              return initialValue;
            }
            return String(Number(initialValue) * 100);
          },
          onUnMask: (initialValue) => {
            const value =
              radixPoint === ","
                ? initialValue.replace(/(\.|,)/gim, (match) => {
                    return match === "," ? "." : "";
                  })
                : initialValue;
            if (isNaN(Number(value))) {
              return value;
            }
            return String(Number(value) / 100);
          },
        });
      case "email":
        return createMask({
          alias: "email",
          showMaskOnFocus: false,
          showMaskOnHover: false,
          autoUnmask: true,
        });
      case "url":
        return createMask({
          alias: "url",
        });
      default:
        return undefined;
    }
  }

  private async executeChangeAction(value: any) {
    if (this.formControl.pristine) {
      return;
    }

    if (value === "" && this.formControl.dirty) {
      this.formControl.reset(null);
      return;
    }

    if (!this.ma.onChange) {
      return;
    }
    let model = this.formState.data();
    await this._metaActionHandler.executeChangeAction({
      formId: this.formState.formId,
      controlId: this.id,
      data: {
        ...model,
        [this.id]: value,
      },
      subFormPath: this.metaHelperService.getFormlySubFormPath(this.field),
      ctx: model?._ctx,
      index: this.metaHelperService.getFormlyFieldArrayIndex(this.field),
    });
  }
}

@NgModule({
  declarations: [MetaInputComponent],
  imports: [
    CommonModule,
    FormlyModule,
    NzInputModule,
    ReactiveFormsModule,
    InputMaskModule,
    FormsModule,
    MetaFieldWrapperModule,
    MetaSwitchModule,
    RouterModule,
    PipesModule,
    A11yModule,
    NzButtonComponent,
    MetaButtonModule,
  ],
  exports: [MetaInputComponent],
})
export class MetaInputModule {}
