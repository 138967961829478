/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { DirtyCheckService } from "../../../services/dirtyCheckService";
import { MetaEventService } from "../../../services/metaEvents.service";
import { MetaFormService } from "../metaForm.service";

@Injectable()
export class ReloadFormActionHandler {
  constructor(
    private _metaFormService: MetaFormService,
    private _dirtyCheckService: DirtyCheckService,
    private _metaEventService: MetaEventService,
    private readonly _router: Router,
  ) {}

  async handle(action: any) {
    if (this._metaFormService.formState.itemId() === "create") {
      this._dirtyCheckService.unregisterForm(this._metaFormService.formState.formGroup());
      await this._router.navigateByUrl(
        this._metaFormService.formState
          .formRoute()
          .split("?")[0]
          .replace("create", this._metaFormService.formState.metaData().tempId.join(",")),
      );
    } else {
      this._metaEventService.emitReloadTrigger({
        formId: action.formId,
        specific: action.specific,
        timestamp: Date.now(),
      });
    }
  }
}
