/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaBaseValidator } from "../validators/baseValidator";
import { ExposeProperty, MetaFormBaseComponent, MetaFormComponentProperty } from "./baseComponent";
import type { FormEventActionHandler } from "../interfaces";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";
import { Table } from "../pages";

export type IMetaFormValidatableComponentOptions = Pick<
  ValidatableComponent,
  | "editable"
  | "required"
  | "autofocus"
  | "labelWidth"
  | "inputWidth"
  | "label"
  | "color"
  | "description"
  | "id"
  | "disabled"
  | "prefix"
  | "suffix"
  | "condition"
  | "readonly"
  | "sortable"
  | "path"
>;

export abstract class ValidatableComponent<PrimaryDataSource = any> extends MetaFormBaseComponent<PrimaryDataSource> {
  constructor() {
    super();
  }
  @MetaFormComponentProperty({
    name: "Von Modell trennen",
    type: "boolean",
    tab: "data",
    description:
      "Detaches a field from the model. Helpful for inputs like filters that are not part of the validation/save procedures.",
    required: false,
  })
  public detachFromModel: boolean;

  // TODO: refactor to editableCondition
  @MetaFormComponentProperty({
    name: "Editierbar",
    type: "boolean",
    default: true,
    tab: "general",
    hidden: true,
  })
  public editable? = true;
  @MetaFormComponentProperty({
    name: "Erforderlich",
    type: "boolean",
    default: false,
    tab: "data",
  })
  public required?: string | boolean = false;
  @MetaFormComponentProperty({
    name: "Autofokus",
    type: "boolean",
    default: false,
    tab: "other",
  })
  public autofocus? = false;
  @MetaFormComponentProperty({
    name: "Etikettenbreite",
    type: "number",
    unit: "px",
    tab: "style",
    condition: (f: any) => !f.hideLabel,
  })
  public labelWidth?: number;
  @MetaFormComponentProperty({
    name: "Eingabebreite",
    type: "number",
    unit: "px",
    tab: "style",
    condition: (f: any) => !f.hideLabel,
  })
  public inputWidth?: number;
  // TODO: rename to stronger name
  @MetaFormComponentProperty({
    name: "Filter Type",
    type: "select",
    tab: "data",
    default: "text",
    condition: (c, form) => form instanceof Table,
    values: [
      {
        label: "text",
        value: "text",
      },
      {
        label: "number",
        value: "number",
      },
      {
        label: "datepicker",
        value: "datepicker",
      },
      {
        label: "select",
        value: "select",
      },
    ],
  })
  public filter?: "text" | "number" | "datepicker" | "select";
  // TODO: rename to excludeFromBulkEdit
  @MetaFormComponentProperty({
    name: "Erlaube Massenbearbeitung",
    type: "boolean",
    default: true,
    tab: "other",
  })
  public allowBulkEdit? = true;
  @MetaFormComponentProperty({
    name: "Immer bearbeiten",
    type: "boolean",
    default: null,
    tab: "other",
  })
  public isEditing?: boolean | null = null;
  @MetaFormComponentProperty({
    name: "Platzhalter",
    type: "text",
    tab: "general",
    description: "Dieser Text wird angezeigt wenn das Feld leer ist.",
  })
  public placeholder?: string;
  @MetaFormComponentProperty({
    name: "Änderungsprotokoll",
    type: "select",
    default: "include",
    tab: "other",
    values: [
      {
        label: "Maskieren",
        value: "mask",
      },
      {
        label: "Ausschließen",
        value: "exclude",
      },
      {
        label: "Einschließen",
        value: "include",
      },
      {
        label: "Auto",
        value: "auto",
      },
    ],
  })
  public changelog?: "auto" | "include" | "mask" | "exclude" = "auto";
  @ExposeProperty() public alignment?: "left" | "right" | null = null;

  @MetaFormComponentProperty({
    name: "Bei Änderung",
    type: "action",
    tab: "hooks",
  })
  public onChange?: FormEventActionHandler;

  @MetaFormComponentProperty({
    name: "Layout",
    type: "select",
    tab: "style",
    default: "horizontal",
    values: [
      {
        label: "vertical",
        value: "vertical",
      },
      {
        label: "horizontal",
        value: "horizontal",
      },
    ],
  })
  public layout?: "vertical" | "horizontal" = "horizontal";

  @MetaFormComponentProperty({
    name: "Validatoren",
    type: "validators",
    tab: "data",
  })
  public validators?: MetaBaseValidator[] = [];

  // TODO: add enum for state
  toFormly(config: IFormUserConfiguration): any {
    const formlyField = deepMerge<any>(super.toFormly(config), {
      key: this.detachFromModel !== undefined ? null : this.id,
      editable: this.editable,
      defaultValue: undefined,
      props: {
        required: this.required,
        labelWidth: this.labelWidth,
        inputWidth: this.inputWidth,
        layout: this.layout,
        filter: this.filter,
        validatableComponent: true,
        hasCondition: !!this.condition,
        isEditing: this.isEditing,
        placeholder: this.placeholder,
        alignment: this.alignment,
        fillingGrade: config.components?.[this.id]?.grade || null,
      },
      wrappers: ["meta-field-wrapper"],
      expressions: {
        "props.disabled": `formState?.state() === 30 || field.disabled || (formState?.displayData() && formState.displayData()[field.id] && formState.displayData()[field.id].disabledCondition !== undefined ? formState.displayData()[field.id].disabledCondition : false)`,
      },
    });
    formlyField.validators = {
      validation: this.validators
        .filter((validator) => !validator.isAsync)
        .map((validator) => {
          try {
            return validator.toFormly(formlyField);
          } catch (e) {
            return null;
          }
        })
        .filter((config) => !!config),
    };
    formlyField.asyncValidators = {
      validation: this.validators
        .filter((validator) => validator.isAsync)
        .map((validator) => {
          try {
            return validator.toFormly(formlyField);
          } catch (e) {
            return null;
          }
        })
        .filter((config) => !!config),
    };
    return formlyField;
  }
}
