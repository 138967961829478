/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { IMetaFormValidatableComponentOptions, ValidatableComponent } from "./validatableComponent";
import { MetaFormComponentInfo } from "./baseComponent";

@MetaFormComponentInfo({
  selector: "ma-calendar",
  type: "calendar",
  name: "Calendar",
  icon: "calendar-days",
  hideInPallet: true,
})
export class MetaFormCalendarComponent extends ValidatableComponent {
  constructor() {
    super();
  }

  public static create<T>(dataSource: T, opts: Partial<IMetaFormValidatableComponentOptions>) {
    const input = new MetaFormCalendarComponent();
    Object.assign(input, opts);
    return input;
  }
}
