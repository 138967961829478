/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Pipe, PipeTransform } from "@angular/core";
import { IMetaMediaLibraryFile } from "./interfaces";
import { MetaMediaLibraryService } from "./metaMediaLibrary.service";

@Pipe({
  name: "file",
})
export class MetaFilePipe implements PipeTransform {
  constructor(private readonly service: MetaMediaLibraryService) {}
  async transform(id: string): Promise<IMetaMediaLibraryFile> {
    if (!id) {
      return null;
    }
    return this.service.getFile(id);
  }
}
