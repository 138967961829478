/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  NgModule,
  OnChanges,
  OnInit,
  QueryList,
  signal,
  SimpleChanges,
  ViewChildren,
  ViewEncapsulation,
} from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { FormlyModule } from "@ngx-formly/core";
import { BehaviorSubject, distinctUntilChanged, takeUntil } from "rxjs";
import { debounceTime, filter, skip } from "rxjs/operators";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaLoaderModule } from "../metaLoader/metaLoader.component";

export class MetaDocumentView {
  formId?: string;
  itemId?: string;
  pdfOptions?: {
    toolbar?: string;
    fit?: string;
    navPanes?: string;
    pageMode?: string;
    additionalData?: Record<string, any>;
  };
}

@MetaUnsubscribe()
@Component({
  selector: "meta-document-view",
  template: `
    <div class="document-container">
      <div class="loading-overlay initial-loader">
        <meta-loader [maParams]="{ size: 32, scale: 80 }"></meta-loader>
      </div>
      @if (dataObject(); as dataUrl) {
        <iframe [src]="dataUrl" class="document"></iframe>
      }
      <div *ngIf="isLoading" class="loading-overlay">
        <meta-loader [maParams]="{ size: 32, scale: 80 }"></meta-loader>
      </div>
    </div>
  `,
  styleUrls: ["./metaDocumentView.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaDocumentViewComponent extends MetaComponentBase implements OnInit, OnChanges {
  @ViewChildren("iframe") pdfViewer: QueryList<ElementRef<HTMLIFrameElement>>;
  public url: string;
  public dataObject = signal<SafeUrl>(null);
  public locale: string;

  constructor(
    _changeDetectorRef: ChangeDetectorRef,
    _metaHelperService: MetaHelperService,
    private readonly _ref: ChangeDetectorRef,
    private readonly _domSanitizer: DomSanitizer,
    private injector: Injector,
  ) {
    super();
    super.maParams = new MetaDocumentView();
  }

  get ma(): MetaDocumentView {
    return super.ma;
  }

  reload() {
    let formId: string;
    let itemId: string;
    let locale: string;
    if (this.field) {
      itemId = this.displayData?.idValues?.join(",") || this.formState.itemId();
      locale = this.locale || this.displayData?.locale;
      formId = this.ma.formId || this.formState.formId;
      if (itemId === null || !formId) {
        return;
      }
    } else {
      formId = this.ma.formId;
      itemId = this.ma.itemId;
    }

    this.isLoading = true;
    this._ref.markForCheck();

    this.url = `/api/v2/forms/${formId}/pdf-preview/${encodeURIComponent(itemId)}`;
    const urlParams = {
      ...(this.ma.pdfOptions as any),
    };
    delete urlParams.additionalData;
    const pdfParams = new URLSearchParams(urlParams);
    const params = new URLSearchParams({
      t: String(Date.now()),
      ...(locale ? { locale } : {}),
    });

    fetch(`${this.url}?${params.toString()}`, {
      method: "post",
      body: JSON.stringify(this.ma.pdfOptions?.additionalData || {}),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((r) => r.blob())
      .then((r) => URL.createObjectURL(r))
      .then((objectUrl) => {
        this.isLoading = false;
        this.dataObject.set(this._domSanitizer.bypassSecurityTrustResourceUrl(objectUrl + `#${pdfParams.toString()}`));
      })
      .catch(() => {
        this.isLoading = false;
        this._ref.markForCheck();
      });
  }

  ngOnInit(): void {
    // TODO: new sub for formData after processing
    if (this.field) {
      if (this.formState?.pdfPreviewOptions) {
        const previewOptions$ = this.formState?.pdfPreviewOptions as BehaviorSubject<Record<string, any>>;
        this.locale = previewOptions$.value.locale;
        previewOptions$.pipe(skip(1), takeUntil((this as any).destroyed$)).subscribe((value) => {
          if (this.url) {
            this.locale = value.locale;
            this.reload();
          }
        });
      }

      /*effect(
        () => {
          this.reload();
        },
        { injector: this.injector },
      );*/

      this.formState.data$
        .pipe(
          debounceTime(100),
          takeUntil((this as any).destroyed$),
          filter((x) => x !== undefined),
          distinctUntilChanged(),
        )
        .subscribe({
          next: () => {
            this.reload();
          },
        });
    } else {
      this.reload();
    }
  }

  public print() {
    this.pdfViewer.first.nativeElement.contentWindow.print();
  }

  download() {
    window.open(this.url, "_blank");
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.ma?.currentValue) {
      this.reload();
    }
  }
}

@NgModule({
  declarations: [MetaDocumentViewComponent],
  imports: [CommonModule, FormlyModule, MetaLoaderModule],
  exports: [MetaDocumentViewComponent],
})
export class MetaDocumentViewModule {}
