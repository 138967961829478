/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  NgModule,
  OnInit,
  QueryList,
  Signal,
  signal,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { MetaLoaderModule } from "@meta/ui";
import { FormlyModule } from "@ngx-formly/core";
import { DynamicIoModule } from "ng-dynamic-component";
import { takeUntil } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map } from "rxjs/operators";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaFormComponent } from "../../base/metaForm/metaForm.component";
import { DirtyCheckService } from "../../services/dirtyCheckService";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";

export class MetaSubform {
  formId: string;
  label?: string;
  editing: boolean;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-subform",
  template: `
    <meta-loader *ngIf="loading()" />
    <ng-container #subFormContainer></ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaSubformComponent extends MetaComponentBase implements OnInit {
  public loading: Signal<boolean> = signal(false);
  @ViewChild("subFormContainer", { read: ViewContainerRef })
  public subFormContainer: ViewContainerRef;
  @ViewChildren("tooltipDynamicComponent") public tooltipDynamicComponents: QueryList<MetaFormComponent>;
  public inputs: any;
  protected readonly MetaFormComponent = MetaFormComponent;
  private subFormComponentRef: ComponentRef<MetaFormComponent>;

  constructor(private readonly dirtyCheckService: DirtyCheckService) {
    super();
    super.maParams = new MetaSubform();
  }

  get ma(): MetaSubform {
    return super.ma;
  }

  public ngOnInit() {
    this.formState.displayData$
      .pipe(
        takeUntil(this.destroyed$),
        map((e) => e[this.id]),
        filter((e) => !!e),
        debounceTime(100),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      )
      .subscribe((data) => {
        if (this.subFormComponentRef?.instance?.metaFormService.formState.formGroup()?.dirty) {
          this.dirtyCheckService.showDirtyAlert().subscribe((res) => {
            if (res) {
              this.updateSubform(data);
            }
          });
          return;
        } else {
          this.updateSubform(data);
        }
      });
  }

  public updateSubform(data: any) {
    if (data["subForm"]) {
      if (this.subFormComponentRef && data["subForm"] !== this.subFormComponentRef.instance.maFormId) {
        this.subFormComponentRef.destroy();
        this.subFormComponentRef = null;
      }
      if (!this.subFormComponentRef) {
        this.subFormComponentRef = this.subFormContainer.createComponent(MetaFormComponent);
      }
      this.subFormComponentRef.setInput("maLoadedAsSubform", true);
      this.subFormComponentRef.setInput("maExternalData", data["subFormParams"] || {});
      this.subFormComponentRef.setInput("maFormId", data["subForm"]);
      this.subFormComponentRef.setInput("maItemId", data["subFormParams"]?.["_id"]);
      this.subFormComponentRef.setInput("maEditing", !!data["editing"]);
      this.loading = this.subFormComponentRef.instance.loading.asReadonly();
      this.changeDetectorRef.detectChanges();
    } else {
      this.subFormComponentRef.destroy();
      this.subFormComponentRef = null;
    }
  }
}

@NgModule({
  declarations: [MetaSubformComponent],
  imports: [CommonModule, FormlyModule, DynamicIoModule, MetaLoaderModule],
  exports: [MetaSubformComponent],
})
export class MetaSubformModule {}
