/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

// set-value-action.handler.ts
import { ChangeDetectorRef, Injectable } from "@angular/core";
import * as _ from "lodash";
import { MetaEventService } from "../../../services/metaEvents.service";
import { MetaFormService } from "../metaForm.service";

@Injectable()
export class SetValueActionHandler {
  constructor(
    private readonly _metaFormService: MetaFormService,
    private readonly _metaEventService: MetaEventService,
    private readonly _changeDetectionRef: ChangeDetectorRef,
  ) {}

  async handle(action: any, opts: any) {
    if (opts.subFormPath?.length > 0) {
      const pathKeys = [...opts.subFormPath, ...[action.fieldId]];
      const pathValue = action.value;

      this._metaFormService.formState.data.set(
        _.merge(
          {},
          this._metaFormService.formState.data(),
          pathKeys.reduceRight((value, key) => {
            if (opts.subFormPath.indexOf(key) !== -1) {
              const array = [];
              for (let i = 0; i < opts.index; i++) {
                array.push({});
              }
              array.push({ ...value });
              return { [key]: array };
            } else {
              return { [key]: value };
            }
          }, pathValue),
        ),
      );
    } else {
      this._metaFormService.formState.data.set({
        ...this._metaFormService.formState.data(),
        ...{ [action.fieldId]: action.value },
      });
    }
    this._metaEventService.emitChangeTrigger({
      formId: opts.formId,
      action: action,
      data: { [action.fieldId]: action.value },
      timestamp: Date.now(),
    });
    if (this._metaFormService.formState.pendingUpdates.indexOf(action.fieldId) === -1) {
      this._metaFormService.formState.pendingUpdates.push(action.fieldId);
    }
    if (this._metaFormService.formState.metaData()?.isEditable) {
      this._metaFormService.formState.formGroup().markAsDirty();
    }
    this._changeDetectionRef.detectChanges();
  }
}
