/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ExposeProperty, MetaFormComponent, MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";
import type { FormEventActionHandler } from "../interfaces";
import * as _ from "lodash";
import { MetaIconStyle } from "@meta/enums";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { Expose } from "class-transformer";
import { deepMerge } from "../deepMerge";

class MetaButtonPopConfirm {
  disabled? = false;
  placement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";
  label?: string;
  okText?: string;
  okType?: string;
  icon?: string;
  iconStyle?: MetaIconStyle = MetaIconStyle.Light;
}

@MetaFormComponentInfo({
  selector: "ma-button",
  type: "button",
  name: "Button",
  icon: "rectangle-wide",
  description: `Performs an action as soon as the button is pressed.`,
  color: "#4af2a1",
  palletGroup: "actions",
})
export class MetaFormButtonComponent extends MetaFormComponent {
  /**
   * The Function that gets executed on click
   */
  @MetaFormComponentProperty({
    name: "Bei Klick",
    type: "action",
    tab: "general",
    description: "The action to be performed when the button is pressed.",
  })
  public onClick?: FormEventActionHandler;

  @MetaFormComponentProperty({
    name: "Label",
    type: "text",
    tab: "general",
    default: "New Button",
    description: "The text that is displayed in the button",
  })
  public label?: string;
  @MetaFormComponentProperty({
    name: "Icon",
    type: "icon",
    tab: "general",
  })
  public icon?: string;
  @MetaFormComponentProperty({
    name: "Icon type",
    type: "icon",
    tab: "general",
  })
  public iconStyle?: string;
  @MetaFormComponentProperty({
    name: "Deaktiviert",
    type: "condition",
    tab: "general",
  })
  public disabled?: string | boolean;
  @MetaFormComponentProperty({
    name: "Button Type",
    type: "select",
    tab: "style",
    default: "default",
    values: [
      {
        label: "Default",
        value: "default",
      },
      {
        label: "Primary",
        value: "primary",
      },
      {
        label: "Dashed",
        value: "dashed",
      },
      {
        label: "Text",
        value: "text",
      },
      {
        label: "Link",
        value: "link",
      },
    ],
  })
  public buttonType?: "default" | "primary" | "dashed" | "link" | "text" = "default";
  @MetaFormComponentProperty({
    name: "Card Position",
    type: "select",
    tab: "style",
    default: "secondary",
    values: [
      {
        label: "Primary",
        value: "primary",
      },
      {
        label: "Secondary",
        value: "secondary",
      },
    ],
  })
  public cardPosition?: "primary" | "secondary" = "secondary";
  @MetaFormComponentProperty({
    name: "Danger",
    type: "boolean",
    tab: "style",
    default: false,
  })
  public danger?: boolean;
  @MetaFormComponentProperty({
    name: "Size",
    type: "select",
    tab: "style",
    default: "default",
    values: [
      {
        label: "default",
        value: "default",
      },
      {
        label: "large",
        value: "large",
      },
      {
        label: "small",
        value: "small",
      },
    ],
  })
  public size = "default";

  @MetaFormComponentProperty({
    name: "Spotlight",
    type: "boolean",
    tab: "general",
    description: 'Is used for toolbar buttons to appear prominent on the bar instead of the "more" dropdown',
  })
  public spotlight?: boolean;
  @MetaFormComponentProperty({
    name: "Show On Editing",
    type: "boolean",
    tab: "general",
    group: "Visibility",
  })
  public showOnEditing?: boolean;
  @ExposeProperty()
  public popConfirm?: MetaButtonPopConfirm = new MetaButtonPopConfirm();

  constructor() {
    super();
  }

  public static createElement<T>(opts: Partial<MetaFormButtonComponent>) {
    const input = new MetaFormButtonComponent();
    Object.assign(input, opts);
    return input;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      type: `meta-${this.constructor["meta"]["type"]}`,
      props: {
        label: this.label,
        icon: this.icon,
        iconStyle: this.iconStyle,
        fullWidth: true,
        danger: this.danger,
        type: this.buttonType,
        disabled: !this.onClick,
        showOnEditing: this.showOnEditing,
        popConfirm: this.popConfirm,
        cardPosition: this.cardPosition,
        spotlight: this.spotlight,
        description: this.description,
      },
    });
  }
}
