/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2021
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2021
 */

import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";
import { isArray } from "lodash";

@Injectable()
export class MetaErrorHandlerService {
  constructor() {}

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, ` + `body was: ${JSON.stringify(error.error)}`);
    }

    if (error.status === 404) {
      return throwError(() => "Der Datensatz konnte nicht gefunden werden…");
    }

    if (error.status === 422) {
      if (typeof error.error?.message === "string") {
        return throwError(() => error.error.message);
      } else {
        // TODO: Add Error descriptions
        return throwError(() =>
          `Ungültiger Datensatz<br/>${error.error?.message
            ?.map((e: any) => `Ungültiger Wert im Feld <b>${e["fieldName"]}</b>.`)
            .join("<br/>")}`.trim()
        );
      }
    }

    //error for duplicate primary key constraint
    if (
      error.status === 500 &&
      (error.error?.message.includes("Der doppelte Schlüsselwert ist") ||
        error.error?.message.includes("annot insert duplicate key"))
    ) {
      const regExp = /\(([^)]+)\)/;
      const matches = regExp.exec(error.error?.message);
      const key = isArray(matches) ? matches[1] : undefined;

      return throwError(
        () => `Für einen Schlüsselwert wurde ein schon vorhandener Wert eingegeben.
        ${key ? `Der Schlüsselwert "${key}" wird schon verwendet` : ""}`
      );
    }

    // Return an observable with a user-facing error message.
    return throwError(
      () => error.error?.message || "Ups da ist etwas schief gelaufen... bitte versuchen Sie es später noch einmal."
    );
  }
}
