/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { Pipe } from "@angular/core";

@Pipe({ pure: true, name: "nodeColor", standalone: true })
export class MetaTreeViewColorPipe {
  transform(color: string): string {
    return getComputedStyle(document.documentElement).getPropertyValue(`--text-color-${color}`) || null;
  }
}
