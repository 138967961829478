/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ValidatableComponent } from "./validatableComponent";
import { ExposeProperty, MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";
import { Expose } from "class-transformer";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-switcher",
  type: "switcher",
  name: "Switcher",
  icon: "toggle-on",
  palletGroup: "input",
})
export class SwitcherComponent extends ValidatableComponent {
  @MetaFormComponentProperty({
    name: "Display Type",
    type: "select",
    values: [
      {
        value: "default",
        label: "Standard",
      },
      {
        value: "tile",
        label: "Kachel",
      },
    ],
  })
  public displayType: "default" | "tile" = "default";
  @MetaFormComponentProperty({
    name: "Icon",
    type: "select",
    values: "form-builder/lord-icons",
  })
  public icon?: string;
  @ExposeProperty() public checkedIcon?: string;
  @ExposeProperty() public uncheckedIcon?: string;

  toFormly(config: IFormUserConfiguration): any {
    const result = deepMerge<any>(super.toFormly(config), {
      props: {
        displayType: this.displayType,
        icon: this.icon,
        checkedIcon: this.checkedIcon,
        uncheckedIcon: this.uncheckedIcon,
        link: this.link,
        description: this.description,
        onChange: !!this.onChange,
      },
    });
    if (this.displayType === "tile") {
      result.wrappers = undefined;
    }
    return result;
  }
}
