/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { IsBoolean, IsOptional, IsString } from "class-validator";

export class FormFindDisplayResultItemListResult {
  avatar?: string;
  values: string[];
}

export class FormFindDisplayResultItem<T = any> {
  value?: string | string[] | FormFindDisplayResultItemListResult;
  prefix?: string;
  suffix?: string;
  link?: string;
  label?: string;
  color?: string;
  backgroundColor?: string;
  fillingLevelColor?: number;
  disabledCondition?: boolean;
  condition?: boolean;
  description?: string;
}

export class FormFindDisplayResultSet<T = any> {
  [key: string]: FormFindDisplayResultItem;
}

export class FormFindResultResponseDto<T = any> {
  resultSet: T[];
  metaResultSet?: any[];
  displayResultSet?: FormFindDisplayResultSet<T>[];
  auxiliaryComponents?: any[][];
  total: number;
  skip: number;
  workflows?: any[];
}

export class FormGroupAggregationResultItem {
  field: string;
  value?: any;
  rawValue?: any;
}

export class FormGroupResultItem {
  field: string;
  value: any;
  aggregates?: FormGroupAggregationResultItem[];
}

export class FormFavoriteItem {
  name: string;
  id: string;
}

export class FormFindFormRequest {
  @IsOptional()
  @IsBoolean()
  isWidget?: boolean;

  @IsOptional()
  @IsString()
  searchString?: string;
}

export class FormFindFormResponse {
  id: string;
  name: string;
  description: string;
  isWidget: boolean;
  hasPermissions: boolean;
  icon: string;
}
