/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, NgModule, signal, ViewEncapsulation } from "@angular/core";
import { MetaButtonType, MetaIconStyle } from "@meta/enums";
import { FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import { FormlyAttributeEvent } from "@ngx-formly/core/lib/models/fieldconfig";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { MetaComponentBase, MetaFormBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaActionHandlerFactory } from "../../base/metaForm/actions/actionHandler.factory";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaIconModule } from "../metaIcon/metaIcon.component";

export class MetaButton extends MetaFormBase {
  type?: MetaButtonType | any;
  danger? = false;
  ghost? = false;
  icon?: string;
  iconStyle?: MetaIconStyle | string = MetaIconStyle.Light;
  iconPosition?: "start" | "end" = "start";
  shape?: "circle" | "round" | null = null;
  size?: "large" | "small" | "tiny" | "default" = "default";
  fullWidth? = false;
  popConfirm?: IMetaButtonPopConfirm = new IMetaButtonPopConfirm();
  onClick?: FormlyAttributeEvent | (() => void | Promise<void>);
  displayData?: any;
  color?: "green" | "yellow" | "red" | null = null;
  spotlight?: boolean; // Is used for toolbar buttons to appear prominent on the bar instead of the "more" dropdown
  description?: string;
}

class IMetaButtonPopConfirm {
  disabled? = false;
  placement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";
  label?: string;
  okText?: string;
  okType?: string;
  icon?: string;
  iconStyle?: MetaIconStyle = MetaIconStyle.Light;
  onOk?: FormlyAttributeEvent | (() => void);
  onCancel?: FormlyAttributeEvent;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-button",
  template: ` <button
      [style.width.%]="ma.fullWidth ? 100 : 'inherit'"
      [ngClass]="{
        'btn-special': ma.type === 'special',
        'btn-color-green': ma.color === 'green',
        'btn-color-yellow': ma.color === 'yellow',
        'btn-color-red': ma.color === 'red',
        'btn-size-tiny': ma.size === 'tiny'
      }"
      nz-button
      [nzShape]="ma.shape"
      [nzType]="ma.type === 'special' ? 'primary' : ma.type"
      [nzBlock]="ma.fullWidth"
      [nzDanger]="ma.danger"
      [nzGhost]="ma.ghost"
      [nzSize]="ma.size"
      [nzLoading]="ma.loading || loading()"
      [disabled]="ma.disabled || maDisabled || displayData['disabledCondition'] === false"
      (click)="onClick(field, $event)"
      nz-popconfirm
      [nzPopconfirmTitle]="ma.popConfirm?.label"
      [nzPopconfirmPlacement]="ma.popConfirm?.placement"
      [nzCondition]="ma.popConfirm?.disabled"
      [nzOkText]="ma.popConfirm?.okText"
      [nzOkType]="ma.popConfirm?.okType"
      [nzIcon]="ma.popConfirm?.icon ? iconTpl : null"
      (nzOnConfirm)="ma.popConfirm?.onOk ? onOk() : null"
      (nzOnCancel)="ma.popConfirm?.onCancel && ma.popConfirm?.onCancel(field, $event)"
      nz-tooltip
      [nzTooltipTitle]="ma.description"
    >
      <i
        *ngIf="ma.icon && !ma.loading && !loading() && ma.iconPosition === 'start'"
        class="{{ ma.iconStyle || 'fa-light' }} fa-{{ ma.icon }}"
      ></i>
      <ng-container *ngIf="ma.label">{{ displayData["label"] || ma.label }}</ng-container>
      <i
        *ngIf="ma.icon && !ma.loading && !loading() && ma.iconPosition === 'end'"
        class="{{ ma.iconStyle || 'fa-light' }} fa-{{ ma.icon }}"
      ></i>
    </button>
    <ng-template #iconTpl><i class="{{ ma.popConfirm?.iconStyle }} fa-{{ ma.popConfirm?.icon }}"></i></ng-template>`,
  styleUrls: ["./metaButton.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaButtonComponent extends MetaComponentBase<any, MetaButton> {
  @Input() maDisabled: boolean;

  public readonly loading = signal(false);

  constructor(private readonly _metaActionHandler: MetaActionHandlerFactory) {
    super();
    super.maParams = new MetaButton();
  }

  get ma(): MetaButton {
    return super.ma;
  }

  public async onClick(field: FormlyFieldConfig, $event: MouseEvent) {
    if (this.ma.showDummy) {
      return;
    }
    if (this.ma.onClick instanceof Function) {
      const r = this.ma.onClick(field, $event);
      if (r instanceof Promise) {
        this.loading.set(true);
        r.finally(() => this.loading.set(false));
      }
    } else if (this.field) {
      this.loading.set(true);
      this.changeDetectorRef.markForCheck();
      await this._metaActionHandler.executeClickAction({
        formId: this.formState.formId,
        controlId: this.field.id,
        data: this.formState.data(),
        index: this.metaHelperService.getFormlyFieldArrayIndex(field),
        subFormPath: this.metaHelperService.getFormlySubFormPath(field),
        ctx: this.model?._ctx,
        formIsValid: true,
        passthroughData: { formId: this.formState.formId },
      });
      this.loading.set(false);
      this.changeDetectorRef.markForCheck();
    }
  }

  public onOk() {
    this.ma.popConfirm?.onOk(this.field);
  }
}

@NgModule({
  declarations: [MetaButtonComponent],
  imports: [
    CommonModule,
    FormlyModule,
    NzWaveModule,
    NzButtonModule,
    NzPopconfirmModule,
    MetaIconModule,
    NzTooltipDirective,
  ],
  exports: [MetaButtonComponent],
})
export class MetaButtonModule {}
