/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaBaseValidator } from "./baseValidator";
import { MetaFormComponentInfo } from "../components";
import { Exclude } from "class-transformer";

export class ScriptValidatorOptions {
  script: any;
}

@MetaFormComponentInfo({
  selector: "ma-script-validator",
  type: "script-validator",
})
export class ScriptValidator extends MetaBaseValidator {
  @Exclude()
  public script: string;

  constructor(public options?: ScriptValidatorOptions) {
    super();
    this.isAsync = true;
    this.script = options?.script;
  }

  public static createElement(attributes: ScriptValidatorOptions) {
    return new ScriptValidator(attributes);
  }

  public async validate(value: any): Promise<boolean> {
    return true;
  }

  toFormly(formlyFieldConfig: Record<string, any>) {
    return super.toFormly(formlyFieldConfig, true);
  }
}
