/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaBaseValidator } from "./baseValidator";
import * as validator from "class-validator";

type MobilePhoneLocale =
  | "ar-AE"
  | "ar-DZ"
  | "ar-EG"
  | "ar-JO"
  | "ar-SA"
  | "ar-SY"
  | "be-BY"
  | "bg-BG"
  | "cs-CZ"
  | "de-DE"
  | "da-DK"
  | "el-GR"
  | "en-AU"
  | "en-GB"
  | "en-HK"
  | "en-IN"
  | "en-KE"
  | "en-NG"
  | "en-NZ"
  | "en-UG"
  | "en-RW"
  | "en-SG"
  | "en-TZ"
  | "en-PK"
  | "en-US"
  | "en-CA"
  | "en-ZA"
  | "en-ZM"
  | "es-ES"
  | "fa-IR"
  | "fi-FI"
  | "fo-FO"
  | "fr-FR"
  | "he-IL"
  | "hu-HU"
  | "id-ID"
  | "it-IT"
  | "ja-JP"
  | "kk-KZ"
  | "kl-GL"
  | "ko-KR"
  | "lt-LT"
  | "ms-MY"
  | "nb-NO"
  | "nn-NO"
  | "pl-PL"
  | "pt-PT"
  | "ro-RO"
  | "ru-RU"
  | "sk-SK"
  | "sr-RS"
  | "th-TH"
  | "tr-TR"
  | "uk-UA"
  | "vi-VN"
  | "zh-CN"
  | "zh-HK"
  | "zh-TW"
  | "any";

export class IsMobilePhoneValidatorOptions {
  locale: MobilePhoneLocale;
}

export class IsMobilePhone extends MetaBaseValidator {
  constructor(public options?: IsMobilePhoneValidatorOptions) {
    super();
  }

  async validate(value): Promise<boolean> {
    return validator.isMobilePhone(value, this.options.locale as any);
  }
}
