import { DatePipe, JsonPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed, Input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MetaState } from "@meta/enums";
import { MetaAvatarModule } from "../../../components/metaAvatar/metaAvatar.component";
import { MetaButtonModule } from "../../../components/metaButton/metaButton.component";
import { MetaInputModule } from "../../../components/metaInput/metaInput.component";

import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDescriptionsModule } from "ng-zorro-antd/descriptions";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { DurationPipe } from "../../../pipes/duration.pipe";
import { MetaHelperService } from "../../../services/metaHelper.service";
import { MetaFormlyFormOptions } from "../../metaForm/metaForm.interface";
import { MetaFormService } from "../metaForm.service";
import { MetaFormActiveWorkflowComponent } from "./metaForm.activeWorkflow";

@Component({
  selector: "meta-form-workflows",
  template: `
    @for (wf of runnableWorkflows(); track wf.id) {
      <meta-button
        nz-tooltip="Startet den Workflow '{{ wf.name }}'."
        [maParams]="{ label: wf.buttonLabel || wf.name, onClick: wf.run, icon: 'play' }"
      />
    }
    @for (exec of activeExecutions(); track exec.id) {
      <meta-form-active-workflow [exec]="exec" />
    }
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  standalone: true,
  imports: [
    MetaButtonModule,
    MetaInputModule,
    NzToolTipModule,
    NzButtonModule,
    NzDescriptionsModule,
    NzPopoverModule,
    NzBadgeModule,
    DatePipe,
    RouterModule,
    DurationPipe,
    MetaAvatarModule,
    MetaAvatarModule,
    JsonPipe,
    MetaFormActiveWorkflowComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaFormWorkflowsComponent {
  @Input() options: MetaFormlyFormOptions;

  public readonly runnableWorkflows = computed(() => {
    if (
      !this.metaFormService.formState.itemId() ||
      [MetaState.editingForm, MetaState.editing, MetaState.editingForm, MetaState.saving].includes(
        this.metaFormService.formState.state(),
      )
    ) {
      return [];
    }

    const wf = this.metaFormService.formState.workflows();
    if (!wf?.workflows) return [];
    const activeExecutions = this.activeExecutions();
    return wf.workflows
      .filter((w) => {
        return w.runnable && !activeExecutions.find((e) => e.workflow.id === w.id);
      })
      .map((w) => {
        return {
          ...w,
          run: () => this.runWorkflow(w.id),
        };
      });
  });

  public readonly activeExecutions = computed(() => {
    const wf = this.metaFormService.formState.workflows();
    if (!wf?.executions) return [];
    return wf.executions.filter((w) => w.active);
  });

  public async runWorkflow(id: string) {
    await this.metaFormService.runWorkflow(id);
    await new Promise((resolve) => setTimeout(resolve, 2_000));
  }

  constructor(
    public readonly metaFormService: MetaFormService,
    public readonly metaHelperService: MetaHelperService,
  ) {}
}
