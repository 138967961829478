import { Injectable, signal, Signal, WritableSignal } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { retry } from "rxjs/operators";
import { IAppConfig } from "@meta/api-interfaces";
import { firstValueFrom, Observable, Subject } from "rxjs";
import { Socket } from "ngx-socket-io";

export interface IVersionChangedEvent {
  previous: IAppConfig["versionInfo"];
  current: IAppConfig["versionInfo"];
}

export type ApplicationOnlineState = "ONLINE" | "OFFLINE" | "UNKNOWN";

@Injectable({
  providedIn: "root",
})
export class AppConfigService {
  public readonly versionChanged$: Observable<IVersionChangedEvent>;
  public readonly onRestart$: Observable<{ reason: string }>;
  public readonly onRestartComplete$: Observable<boolean>;
  public readonly offline$: Observable<boolean>;
  public readonly online$: Observable<boolean>;

  public readonly appConfigSignal: Signal<IAppConfig>;
  public readonly onlineSignal: Signal<ApplicationOnlineState>;

  private _isRestarting = false;
  public get appConfig(): IAppConfig | null {
    return this.appConfigSignal();
  }

  private readonly _versionChangedSubject = new Subject<IVersionChangedEvent>();
  private readonly _appConfigSignal: WritableSignal<IAppConfig>;
  private readonly _onRestart = new Subject<{ reason: string }>();
  private readonly _onRestartComplete = new Subject<boolean>();
  private readonly _onlineSignal: WritableSignal<ApplicationOnlineState>;
  private readonly _offline = new Subject<boolean>();
  private readonly _online = new Subject<boolean>();
  private updateStyle(styles: Record<string, string>) {
    const root = document.documentElement;
    Object.entries(styles).forEach(([property, value]) => {
      root.style.setProperty(`--${property}`, value);
    });
  }
  public update() {
    firstValueFrom(this.http.get<IAppConfig>("public/config").pipe(retry(10)))
      .then((current) => {
        this._appConfigSignal.update((previous) => {
          const event = {
            current: current.versionInfo,
            previous: previous?.versionInfo,
          };
          if (previous && previous.versionInfo.buildNum !== current.versionInfo.buildNum) {
            this._versionChangedSubject.next(event);
          }
          if (current.styles) {
            this.updateStyle(current.styles);
          }
          return current;
        });
      })
      .catch((e) => {
        console.error(e);
        this._appConfigSignal.set(null);
      });
  }

  constructor(
    private readonly http: HttpClient,
    public readonly socket: Socket,
  ) {
    this._appConfigSignal = signal(null);
    this.appConfigSignal = this._appConfigSignal.asReadonly();
    this.versionChanged$ = this._versionChangedSubject.asObservable();
    this.onRestartComplete$ = this._onRestartComplete.asObservable();
    this.onRestart$ = this._onRestart.asObservable();
    this._onlineSignal = signal("ONLINE");
    this.onlineSignal = this._onlineSignal.asReadonly();
    this.online$ = this._online.asObservable();
    this.offline$ = this._offline.asObservable();

    this.socket.on("disconnect", () => {
      this._onlineSignal.set("OFFLINE");
      this._offline.next(true);
    });

    this.socket.on("SHUTDOWN", () => {
      if (!this._isRestarting) {
        this._onRestart.next({ reason: "TODO" });
        this._isRestarting = true;
      }
    });

    this.socket.on("FORCE_LOGOUT", () => {
      window.location.href = "/auth?forced=1";
    });

    this.socket.fromEvent("connect").subscribe(() => {
      this._onlineSignal.set("ONLINE");
      this._online.next(true);
      if (this._isRestarting) {
        this._isRestarting = false;
        this._onRestartComplete.next(true);
        this.update();
      }
    });
    this.update();
  }
}
