/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { debounceTime, switchMap, take, tap } from "rxjs/operators";
import { BehaviorSubject, Subject } from "rxjs";
import { UntypedFormGroup } from "@angular/forms";
import { NzMessageService } from "ng-zorro-antd/message";
import { MetaErrorHandlerService } from "../../services/metaErrorHandler.service";
import * as _ from "lodash";

export interface SpecificationObject {
  id: string;
  parentId: string;
  data: GridItem[];
  relatedData: Group[];
}

export interface SpecificationGroup {
  targetGroupId?: string | number;
  targetGroupName?: string;
  combinations?: SpecificationCombination[];
}

export interface SpecificationCombination {
  id: string;
  sourceTag: Specification | null;
  targetTag: Specification | null;
  value: any;
}

export interface Specification {
  id?: string;
  description?: string;
  label: string;
  type: string;
  value: any;
  unit: string;
}

export interface Group {
  id?: string;
  label: string;
  description: string;
  children: any[];
}

export interface Grid {
  id: string;
  parentId: string;
  label?: string;
  icon?: string;
  description?: string;
  data: GridItem[];
}

export interface GridItem {
  id: string;
  w: number;
  h: number;
  x: number;
  y: number;
  parentId: string;
  data?: { [key: string]: any };
}

@Injectable()
export class MetaSpecificationsService {
  public groupCombinations$: BehaviorSubject<SpecificationGroup[]> = new BehaviorSubject<SpecificationGroup[]>([]);
  public specifications$: BehaviorSubject<SpecificationObject> = new BehaviorSubject<SpecificationObject>(null);
  public groups$: BehaviorSubject<Group[]> = new BehaviorSubject<Group[]>([]);
  public isSavingGrid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoadingGrid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private _getDataObs = new Subject<any>();
  private _getDataObs$ = this._getDataObs.asObservable();

  constructor(
    private _http: HttpClient,
    private _metaErrorHandlerService: MetaErrorHandlerService,
    private _nzMessageService: NzMessageService
  ) {
    this._getDataObs$
      .pipe(
        tap(() => this.isLoadingGrid$.next(true)),
        debounceTime(750),
        switchMap((obj) => this._http.get(`specification/${obj.formId}/${obj.itemId}`))
      )
      .subscribe({
        next: (grid) => {
          this.specifications$.next(this._mapGridObjectOutput(grid));
          this.isLoadingGrid$.next(false);
        },
        error: (err) => {
          this._metaErrorHandlerService
            .handleError(err)
            .pipe(take(1))
            .subscribe({
              error: (value) => this._nzMessageService.error(value),
            });
        },
      })
      .add(() => {
        this.isLoadingGrid$.next(false);
      });
    this.updateSpecification = _.debounce(this.updateSpecification, 300);
  }

  /**
   * Gets the specifications
   * If the request was successful, we remap the object to use it
   * in the grid component
   * @param formId Id of the formObject
   * @param itemId Id of the entity (e.g.: ArtikelNr or ArtikelgruppenID)
   */
  public async get(formId: string, itemId: string) {
    this._getDataObs.next({ formId, itemId });
  }

  public async getAllGroups() {
    this._http
      .get(`specification/groups`)
      .pipe(debounceTime(300))
      .subscribe({
        next: (groups: any) => {
          this.groups$.next(groups);
        },
        error: (err) => {
          this._metaErrorHandlerService.handleError(err);
        },
      });
  }

  /**
   * Updates the specifications
   * @param parentFormId Id of the formObject
   * @param itemId Id of the entity (e.g.: ArtikelNr or ArtikelgruppenID)
   * @param payload The GridItem-Array with the updated values
   * @param writeValues
   */
  public updateSpecification(parentFormId: string, itemId: string, payload: GridItem[], writeValues = false) {
    const data = this._mapGridObjectInput(parentFormId, itemId, payload);
    this._http
      .post(`specification?value=${writeValues}`, data)
      .pipe(debounceTime(300))
      .subscribe({
        next: (grid: any) => {
          this.specifications$.next(this._mapGridObjectOutput(grid));
          this.isSavingGrid$.next(false);
        },
        error: (err) => {
          this._metaErrorHandlerService.handleError(err);
        },
      });
  }

  /**
   * Gets the group combinations array
   * @param itemId Group ID
   */
  public async getGroupCombinations(itemId: string) {
    await this._http.get<SpecificationGroup[]>(`specification/${itemId}/combination`).subscribe({
      next: (groups) => this.groupCombinations$.next(groups),
      error: (err) => this._metaErrorHandlerService.handleError(err),
    });
  }

  /**
   * Updates the group combinations
   * @param itemId Group ID
   * @param groups Payload to update
   */
  public updateGroupCombinations(itemId: string, groups: SpecificationGroup[]) {
    return this._http.post<SpecificationGroup[]>(`specification/${itemId}/combination`, {
      groups: groups.map((g) => {
        return {
          targetGroupId: g.targetGroupId,
          combinations: g.combinations.map((c) => {
            c = { ...c };
            c.sourceTag = c.sourceTag.value;
            c.targetTag = c.targetTag.value;
            return c;
          }),
        };
      }),
    });
  }

  public addGroupToLocalObject(group: any) {
    const combinations: SpecificationGroup[] = this.groupCombinations$.getValue();
    combinations.push({
      targetGroupId: group.ArtikelgruppenID,
      targetGroupName: group.Artikelgruppe,
    });
    this.groupCombinations$.next(combinations);
  }

  public deleteGroupFromLocalObject(groupId: string): SpecificationCombination[] {
    let combinations: SpecificationGroup[] = this.groupCombinations$.getValue();
    const childs: SpecificationCombination[] = combinations.find((c) => c.targetGroupId === groupId).combinations;
    combinations = combinations.filter((c) => c.targetGroupId !== groupId);
    this.groupCombinations$.next(combinations);
    return childs;
  }

  public addCombinationToLocalObject(groupId: string, combinationId: string) {
    let combinations: SpecificationGroup[] = this.groupCombinations$.getValue();
    combinations = combinations.map((c) => {
      if (c.targetGroupId === groupId) {
        c.combinations = c.combinations ? c.combinations : [];
        c.combinations.push({
          id: combinationId,
          targetTag: null,
          sourceTag: null,
          value: null,
        });
      }
      return c;
    });
    this.groupCombinations$.next(combinations);
  }

  public updateCombinationToLocalObject(groupId: string, combinationId: string, form: UntypedFormGroup) {
    let combinations: SpecificationGroup[] = this.groupCombinations$.getValue();
    combinations = combinations.map((c) => {
      if (c.targetGroupId.toString() === groupId.toString())
        c.combinations = c.combinations.map((s) => {
          if (s.id.toString() === combinationId.toString())
            s = {
              id: s.id,
              targetTag: form.get(`targetTag-${s.id}`).value,
              sourceTag: form.get(`sourceTag-${s.id}`).value,
              value: form.get(`matchingsTo-${s.id}`).value
                ? [form.get(`matchings-${s.id}`).value, form.get(`matchingsTo-${s.id}`).value]
                : form.get(`matchings-${s.id}`).value,
            };
          return s;
        });
      return c;
    });
    this.groupCombinations$.next(combinations);
  }

  public deleteCombinationFromLocalObject(groupId: string, combinationId: string) {
    let combinations: SpecificationGroup[] = this.groupCombinations$.getValue();
    combinations = combinations.map((c) => {
      if (c.targetGroupId === groupId) {
        c.combinations = c.combinations.filter((s) => s.id !== combinationId);
        if (!c.combinations.length) c.combinations = null;
      }
      return c;
    });
    this.groupCombinations$.next(combinations);
  }

  /**
   * Gets all Specifications for a Group
   * @param itemId Group ID
   * @param type (optional) only return a specific type of specification
   */
  public getSpecificationsForGroup(itemId: string | number, type?: string) {
    this._http
      .post<Specification[]>(`specification/${itemId}/byGroup`, {
        type,
      })
      .subscribe({
        next: (specifications) => {
          /*let array = [];
          const specifications$ = this.specifications$.getValue();
          const specificationsFiltered$ = specifications$.filter((s) => s.id.toString() === itemId.toString());
          if (specificationsFiltered$.length > 0) {
            array = _.unionWith(specifications$, specifications, _.isEqual);
          } else {
            array = specifications$.concat(specifications);
          }
          this.specifications$.next(array);*/
        },
        error: (err) => this._metaErrorHandlerService.handleError(err),
      });
  }

  /**
   * If the request was successful, we remap the object to use it
   * in the grid component
   * @param grid The grid object to remap
   * @private
   */
  private _mapGridObjectOutput(grid): SpecificationObject {
    return {
      id: grid.id,
      parentId: grid.formId,
      data: grid.children
        ? grid.children.map((o: any) => {
            return {
              id: o.id,
              w: o.w,
              h: o.h,
              x: o.x,
              y: o.y,
              data: {
                icon: "object-group",
                name: o.label,
                description: o.description,
                items: o.children
                  ? o.children.map((i: any) => {
                      return {
                        ID: i.id,
                        label: i.label,
                        Beschreibung: i.description,
                        Typ: i.type,
                        Mengeneinheit: i.unit,
                        value: i.value,
                      };
                    })
                  : [],
              },
            };
          })
        : [],
      relatedData: grid.relatedChildren
        ? grid.relatedChildren.map((o: any) => {
            return {
              id: o.id,
              label: o.label,
              children: o.children.map((ch) => {
                return {
                  id: ch.id,
                  label: ch.label,
                  w: 4,
                  h: 4,
                  x: 0,
                  y: 0,
                  data: {
                    icon: "object-group",
                    name: ch.label,
                    description: ch.description,
                    items: ch.children
                      ? ch.children.map((i: any) => {
                          return {
                            ID: i.id,
                            label: i.label,
                            Beschreibung: i.description,
                            Typ: i.type,
                            Mengeneinheit: i.unit,
                            value: i.value,
                          };
                        })
                      : [],
                  },
                };
              }),
            };
          })
        : [],
    };
  }

  /**
   * We need to remap the object to send it to the backend API
   * @param parentFormId ID of the parent form
   * @param itemId ID of the group
   * @param gridItems GridItems to remap
   * @private
   */
  private _mapGridObjectInput(parentFormId: string, itemId: string, gridItems: GridItem[]): any {
    return {
      id: itemId,
      formId: parentFormId,
      children: gridItems
        ? gridItems.map((o: any) => {
            return {
              id: o.id,
              label: o.data.name,
              description: o.data.description,
              w: o.w,
              h: o.h,
              y: o.y,
              x: o.x,
              children: o.data.items
                ? o.data.items.map((i: any) => {
                    return {
                      id: i.ID,
                      description: i.Beschreibung,
                      label: i.label,
                      type: i.Typ,
                      value: i.value,
                      unit: i.Mengeneinheit,
                    };
                  })
                : [],
            };
          })
        : [],
    };
  }
}
