/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Type } from "class-transformer";
import { EntityType, MetaMessage, MetaNotification } from "@meta/api-interfaces";
import { MetaCompositeFilterDescriptor } from "./metaState.dto";
import { IFormatNumberArgs } from "@meta/shared/formatters/numberFormatter";

export type IServerTemplateGlobalContextEmitterType =
  | "onClick"
  | "onRender"
  | "onSelect"
  | "onBeforeOpen"
  | "onEdit"
  | "onCreate"
  | "onReorder"
  | "onCreateVersion"
  | "onDeleteVersion";

export interface ITemplateSendMailOptions {
  template?: string;
  data?: Record<string, any>;
  to: string;
  cc?: string;
  bcc?: string;
  replyTo?: string;
  subject: string;
  mandant?: number;
  attachments?: {
    filename?: string;
    content?: string;
    path?: string;
  };
}

export interface ICallOptions {
  to: string | number;
}

export interface ISendSmsOptions {
  to: string | number;
  message: string;
}

export interface ISendFaxOptions {
  user?: string;
  from?: string;
  to?: string;
  pdf: string;
}

export interface IServerTemplateTableRootContext {
  selection: () => Promise<any[]>;
  select: (ids: any[]) => void;
  deselect: (ids: any[]) => void;
  deselectAll: () => void;
  selectAll: () => void;
  reload: () => void;
}

export interface IFeedEntryBaseOptions {
  sourceId: string;
  sourceType: EntityType;
  title: string;
  description?: string;
  link?: string;
  priority: 1 | 2 | 3 | 5 | 6;
}

export interface IFeedEntryFormDeleteOptions extends IFeedEntryBaseOptions {
  data: {
    formId: string;
    itemId: any[];
  };
  operation: string;
}

export type IFeedEntryOptions = IFeedEntryFormDeleteOptions;

export interface IBarcodeOptions {
  data: string;
  type: string;

  height?: number;
  width?: number; // Bar height, in millimeters
  text?: string;
  includetext?: boolean; // Show human-readable text
  textxalign?: "left" | "right" | "center"; // Always good to set this
  textsize?: number;
}

export interface ITemplateUploadFileOptions {
  allowedFiletypes?: string[];
  maxFilesize?: number;
  maxFiles?: number;
}

export interface ITemplateUploadResultFile {
  name: string;
  type: string;
  size: string;
  readFile: (encoding: "utf8" | "base64") => Promise<string>;
}

export interface ITemplateUploadResult {
  files: ITemplateUploadResultFile[];
}

export interface ITemplateEntityInfo {
  nameSingular: string;
  namePlural: string;
  primaryForm: {
    name: string;
    id: string;
  };
  id: string;
}

export type DurationUnit = "minutes" | "hours" | "seconds" | "milliseconds" | "days";

type QueryFnType<T = Record<string, any>> =
  | ((params: TemplateStringsArray) => Promise<Array<T>>)
  | ((query: string, params?: Record<string, any> | any[]) => Promise<Array<T>>);

export interface IServerTemplateRootContext<T = Record<string, any>> {
  /**
   * The current username
   */
  currentUser: string;

  mandant: number;
  language: string;
  isoLanguage: string;
  params: T;

  // ID Oof the current item
  id: Array<string | number | boolean>;

  index?: number;

  PhoneLink: (to: string) => string;

  Hostname: () => string;

  Baseurl: () => string;

  Icon: (icon: string, style?: string, color?: string) => string;

  EntityLink: (entityType: EntityType, id: string, title: string) => string;
  EntityImage: (entityType: EntityType, id: string, width?: number, height?: number) => string;

  parent: IServerTemplateRootContext;

  GetElementById?: (id: string) => IServerTemplateChildContext;

  Currency: string;
  CurrencySymbol: string;

  Global: (name: string) => Promise<any>;
  EscapeHtml?: (html: string) => string;
  Debug: (data: any) => void;
  Print: (formId: string, itemId: any[] | any, printerId?: string) => void;
  EmmitEvent: (name: string, data: any) => void;
  SendMailTemplate: (options: ITemplateSendMailOptions) => void;
  AssetImage: (path: string, height?: string, width?: string) => string;
  RunWorkflow: (workflowId: string, data: Record<string, any>, startNodeId?: string) => void;
  RunWorkflowFromEvent: (eventName: any, input: Record<string, any>) => void;
  /**
   * Performs an sql query
   * @param sql
   * @param params
   * @constructor
   */
  Query: <T = Record<string, any>>(
    sql: string | TemplateStringsArray,
    params: any[] | Record<string | number, any> | any,
    ...args: any[]
  ) => Promise<T[]>;
  /**
   * Returns the next number using a key
   * @param key
   * @constructor
   */
  NaechsteNummer: (key: string, prefix?: string) => Promise<string | number>;
  /**
   * Returns the name from an addressNo
   * @param addressNo
   * @constructor
   */
  GetNameFromAddressNo: (addressNo: number) => Promise<string | number>;
  /**
   * Returns the userdata for the provided user
   * @param user
   * @constructor
   */
  GetUserData: (user: string) => Promise<Record<any, any>>;
  /**
   * Executes a Stored Procedure
   * @param procName
   * @param params
   * @constructor
   */
  ExecProcedure: (procName: string, params: Record<string, any>, useOutput?: boolean) => Promise<any>;
  /**
   * Navigates the browser after completion
   * @param url
   * @constructor
   */

  Validate: () => Promise<boolean>;

  t: (selector: string, data?: Record<string, any>) => Promise<string>;
  resolve: <T>(typeOrToken: T) => Promise<T extends new (...args: any) => any ? InstanceType<T> : T>;

  translateText: (text: string, destLang?: string, srcLang?: string) => Promise<string>;
  translateTextArray: (text: string[], destLang?: string, srcLang?: string) => Promise<string[]>;

  Barcode: (opt: IBarcodeOptions) => Promise<string>;

  dataItem: Record<string, any> & { _id: (string | number)[] };
  primaryItem: Record<string, any>;
  EmitterType: IServerTemplateGlobalContextEmitterType;
  getId: (field: string) => any;
  getForm?: (formId: string | "parent") => Promise<IServerTemplateMainContext>;
  getParentForm: () => Promise<IServerTemplateRootContext>;
  moment: any;
  _: any;
  formatNumber: (input: any, args?: IFormatNumberArgs) => string;
  formatDate: (input: Date | string | number, format?: string) => string;
  formatDuration: (duration: number, unit: DurationUnit, displayUnit: DurationUnit) => string;

  roundNumber: (x: number) => number;
  hashPassword: (password: string) => Promise<string>;
  getAddressByPlaceId: (placeId: any) => Promise<any>;
  parentForm?: Record<string, any>;
  additionalData: Record<string, any>;
  isCreate?: boolean;

  Color(color: string, text: string): string;

  GetEmailFromAdressNo(addressNo: number): Promise<string | number>;

  GetPhoneFromAdressNo(addressNo: number): Promise<string | number>;

  GetReturnAddress: (mandant: number) => Promise<string>;

  GetPublicFileUrl: (fileId: string, ttl?: number) => Promise<string>;
}

export interface IServerTemplateElementContext<T = any> {
  type: string;
  value: T;
}

export type TemplateRootType<T extends IServerTemplateRootContext = IServerTemplateRootContext> =
  | (($: T & ((id: string) => IServerTemplateElementContext)) => any)
  | string;

export interface IServerTemplateSocketContextDownloadOptions {
  filename: string;
  data: ArrayBuffer | Uint8Array | string;
}

export interface IServerTemplateSocketContext<Event = any>
  extends IServerTemplateRootContext<Event>,
    IServerTemplateTableRootContext {
  Event?: Event;

  Alert: (message: string, broadcast?: boolean) => void;

  Notify: (options: MetaNotification) => void;

  ShowMessage: (options: MetaMessage) => void;

  UploadFiles: (options: ITemplateUploadFileOptions) => Promise<ITemplateUploadResult>;

  Phone: {
    sendSms: (opts: ISendSmsOptions) => void;
    call: (opts: ICallOptions) => void;
    sendFax: (args: any) => void;
  };

  Navigate: (url: string) => void;

  Download: (opts: IServerTemplateSocketContextDownloadOptions) => void;

  FeedEntry: (opts: IFeedEntryOptions) => Promise<void>;

  EntityInfo: (entity: string) => ITemplateEntityInfo;

  CallRef: (ref: any) => Promise<any>;

  /**
   * The current value of the form
   */

  OpenModal: <T = any>(
    formId: string,
    dataItem: Record<string, any>,
    size?: "large" | "small" | "default" | "max",
    modalType?: "form" | "component" | "wizard",
    width?: string | number,
    isEdit?: boolean,
    closeAfterSave?: boolean,
  ) => Promise<T>;

  OpenEntity: <T = any>(entityType: string, itemId: string | number) => Promise<T>;

  CloseModal: (forceClose?: boolean, data?: any) => Promise<void>;

  Confirm: (opts: { label: string; content: string; okLabel: string; cancelLabel: string }) => void;

  LoadItem: (itemId: Record<string, any> | string | number, formId?: string) => Promise<any>;
  /**
   *  Reloads an entire form either
   * @constructor
   */
  Reload: (formId?: string) => Promise<void>;
  /**
   *  Updates DisplayValues for the current form
   * @constructor
   */
  UpdateDisplayValues: () => Promise<void>;
  /**
   * Removes an Item from a Table/Grid
   * @param dataItem
   * @constructor
   */
  RemoveItems: (formId: string, dataItemIds: string[], style: "success" | "error") => Promise<void>;
  /**
   * Triggers the initialize api with optional params
   * @param dataItem
   * @constructor
   */
  Initialize: (dataItem?: Record<string, any>) => Promise<void>;
  Save: (silent: boolean) => Promise<{ dataItem?: Record<string, any>; primaryItem?: Record<string, any> }>;
}

export interface IServerTemplateChildContext<T = any> {
  setValue: (value: T, opts?: { emmitEvent?: boolean }) => void;
  value: T;
  selectData: Record<string, any>;
  getSelectData: () => Record<string, any>;
  getValue: () => T;
  setFilter: (filter: Record<string, any> | MetaCompositeFilterDescriptor) => void;
  /**
   * Only works for Tables
   * @param params
   */
  setParams: (params: Record<string, any>) => void;
  /**
   * Only works for Tables
   * @param params
   */
  selection: () => Promise<any[]>;
}

export type IServerTemplateMainContext = IServerTemplateRootContext & ((id: string) => IServerTemplateChildContext);
export type IServerTemplateMainSocketContext<Event = any> = IServerTemplateSocketContext<Event> &
  ((id: string) => IServerTemplateChildContext);

export type FormEventActionHandler<Event = Record<string, any>, Return = any> =
  | string
  | (($: IServerTemplateMainSocketContext<Event>) => Return | Promise<Return>);

export abstract class ServerTemplateResponseAction {}

export class ServerTemplateResponseRedirectAction extends ServerTemplateResponseAction {
  public url: string;
}

export class ServerTemplateResponseValidateFormAction extends ServerTemplateResponseAction {
  public revalidate = true;
}

export class ServerTemplateResponseReloadAction extends ServerTemplateResponseAction {
  public formId: string;
  public fieldId: string;
}

export class ServerTemplateResponseSetValueAction extends ServerTemplateResponseAction {
  public fieldId: string;
  public value: any;
  public emmitEvent: boolean;
}

export class ServerTemplateResponseSetDisplayValueAction extends ServerTemplateResponseAction {
  public fieldId: string;
  public value: any;
}

export class ServerTemplateResponseSetFilterAction extends ServerTemplateResponseAction {
  public fieldId: string;
  public filter: Record<string, any>;
}

export class ServerTemplateResponseSetParamsAction extends ServerTemplateResponseAction {
  public fieldId: string;
  public params: Record<string, any>;
}

export class ServerTemplateResponseConfirmAction extends ServerTemplateResponseAction {
  public label: string;
  public content: string;
  public okLabel: string;
  public cancelLabel: string;
}

export class ServerTemplateResponseOpenFormAction extends ServerTemplateResponseAction {
  public formId: string;
  public data: Record<string, any>;
  public method: IServerTemplateGlobalContextEmitterType;
  public size: "large" | "default" | "small" | "max" = "default";
  public modalType: "form" | "component" | "wizard" = "form";
  public width: string | number;
  public newWindow?: boolean;
  public isEdit?: boolean;
  public closeAfterSave?: boolean;
}

export class ServerTemplateResponseReloadFormAction extends ServerTemplateResponseAction {
  public formId: string;
  public itemId: string[];
  public specific: boolean;
}

export class ServerTemplateResponseUpdateDisplayValuesFormAction extends ServerTemplateResponseAction {
  public formId: string;
}

export class ServerTemplateResponseRemoveItemsAction extends ServerTemplateResponseAction {
  public formId: string;
  public dataItemIds: string[];
  public style: "success" | "error";
}

export class ServerTemplateResponseInitializeFormAction extends ServerTemplateResponseAction {
  public formId: string;
  public data: Record<string, any>;
}

export class ServerTemplateResponseSaveAction extends ServerTemplateResponseAction {
  public formId: string;
  public silent = false;
}

export class ServerTemplateResponseCloseFormAction extends ServerTemplateResponseAction {
  public formId: string;
  public forceClose?: boolean;
  public result: any;
}

export class ServerTemplateResponseSelectAction extends ServerTemplateResponseAction {
  public ids: any[];
}

export class ServerTemplateResponseGetSelectionAction extends ServerTemplateResponseAction {
  public formId: string;
}

export class ServerTemplateResponseUploadAction extends ServerTemplateResponseAction {
  allowedFiletype?: string[];
}

export class ServerTemplateResponseDownloadAction extends ServerTemplateResponseAction {
  filename: string;
  data: string;
}

export class ServerTemplateResponse {
  public formId: string;

  public subFormPath?: string[];

  public index?: number;

  public originalData?: Record<string, any>;

  public passthroughData?: Record<string, any>;

  @Type(() => ServerTemplateResponseAction, {
    discriminator: {
      property: "type",
      subTypes: [
        { value: ServerTemplateResponseRedirectAction, name: "redirect" },
        { value: ServerTemplateResponseSetValueAction, name: "setValue" },
        { value: ServerTemplateResponseReloadAction, name: "reload" },
        { value: ServerTemplateResponseSetFilterAction, name: "filter" },
        { value: ServerTemplateResponseOpenFormAction, name: "openForm" },
        { value: ServerTemplateResponseConfirmAction, name: "confirm" },
        { value: ServerTemplateResponseSaveAction, name: "save" },
        { value: ServerTemplateResponseReloadFormAction, name: "reload-form" },
        { value: ServerTemplateResponseUpdateDisplayValuesFormAction, name: "update-display-values" },
        { value: ServerTemplateResponseRemoveItemsAction, name: "remove-item" },
        { value: ServerTemplateResponseInitializeFormAction, name: "initialize-form" },
        { value: ServerTemplateResponseSetDisplayValueAction, name: "display-value" },
        { value: ServerTemplateResponseValidateFormAction, name: "validate-form" },
        { value: ServerTemplateResponseSelectAction, name: "select" },
        { value: ServerTemplateResponseGetSelectionAction, name: "get-selection" },
        { value: ServerTemplateResponseCloseFormAction, name: "closeForm" },
        { value: ServerTemplateResponseUploadAction, name: "uploadFile" },
        { value: ServerTemplateResponseDownloadAction, name: "downloadFile" },
        { value: ServerTemplateResponseSetParamsAction, name: "setParams" },
      ],
    },
  })
  actions: (
    | ServerTemplateResponseRedirectAction
    | ServerTemplateResponseSetValueAction
    | ServerTemplateResponseReloadAction
    | ServerTemplateResponseSetFilterAction
    | ServerTemplateResponseOpenFormAction
    | ServerTemplateResponseSaveAction
    | ServerTemplateResponseReloadFormAction
    | ServerTemplateResponseUpdateDisplayValuesFormAction
    | ServerTemplateResponseRemoveItemsAction
    | ServerTemplateResponseInitializeFormAction
    | ServerTemplateResponseSetDisplayValueAction
    | ServerTemplateResponseValidateFormAction
    | ServerTemplateResponseSelectAction
    | ServerTemplateResponseGetSelectionAction
    | ServerTemplateResponseConfirmAction
    | ServerTemplateResponseCloseFormAction
    | ServerTemplateResponseUploadAction
    | ServerTemplateResponseDownloadAction
    | ServerTemplateResponseSetParamsAction
  )[] = [];
}

export class SocketUpdateFormDisplayValuesRequest {
  formId: string;
  patchData: Record<string, any>;
  ctx: string;
  additionalData?: Record<string, any>;
  isEdit: boolean;
}

export class SocketUpdateFormDisplayValuesAcknowledgmentResponse {
  displayResult: Record<string, any>;
}

export function WorkflowSchemaInfo(opts: any): any {
  return function (target: Function, prop: string): any {};
}
