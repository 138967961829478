/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export interface SaveTriggerEvent {
  formId: string;
  itemId: string;
  timestamp: number;
}

export interface CancelTriggerEvent {
  formId: string;
  timestamp: number;
}

export interface ReloadTriggerEvent {
  formId?: string;
  specific?: string;
  timestamp: number;
}

export interface SetFilterTriggerEvent {
  fieldId: string;
  filter: Record<string, any>;
  timestamp: number;
}

export interface SetParamTriggerEvent {
  fieldId: string;
  params: Record<string, any>;
  timestamp: number;
}

export interface RemoveItemTriggerEvent {
  formId: string;
  dataItemIds?: string[];
  style?: "success" | "error";
  timestamp: number;
}

export interface UpdateTableTriggerEvent {
  action: any;
  options: any;
  timestamp: number;
}

export interface ChangeTriggerEvent {
  formId: string;
  action: any;
  data: any;
  timestamp: number;
}

@Injectable({
  providedIn: "root",
})
export class MetaEventService {
  private saveTriggerSubject = new Subject<SaveTriggerEvent>();
  saveTrigger$ = this.saveTriggerSubject.asObservable();

  private cancelTriggerSubject = new Subject<CancelTriggerEvent>();
  cancelTrigger$ = this.cancelTriggerSubject.asObservable();

  private reloadTriggerSubject = new Subject<ReloadTriggerEvent>();
  reloadTrigger$ = this.reloadTriggerSubject.asObservable();

  private setFilterTriggerSubject = new Subject<SetFilterTriggerEvent>();
  setFilterTrigger$ = this.setFilterTriggerSubject.asObservable();

  private setParamTriggerSubject = new Subject<SetParamTriggerEvent>();
  setParamTrigger$ = this.setParamTriggerSubject.asObservable();

  private removeItemTriggerSubject = new Subject<RemoveItemTriggerEvent>();
  removeItemTrigger$ = this.removeItemTriggerSubject.asObservable();

  private updateTableTriggerSubject = new Subject<UpdateTableTriggerEvent>();
  updateTableTrigger$ = this.updateTableTriggerSubject.asObservable();

  private changeTriggerSubject = new Subject<ChangeTriggerEvent>();
  changeTrigger$ = this.changeTriggerSubject.asObservable();

  emitSaveTrigger(opts: SaveTriggerEvent) {
    this.saveTriggerSubject.next(opts);
  }

  emitCancelTrigger(opts: CancelTriggerEvent) {
    this.cancelTriggerSubject.next(opts);
  }

  emitReloadTrigger(opts: ReloadTriggerEvent) {
    this.reloadTriggerSubject.next(opts);
  }

  emitSetFilterTrigger(opts: SetFilterTriggerEvent) {
    this.setFilterTriggerSubject.next(opts);
  }

  emitSetParamTrigger(opts: SetParamTriggerEvent) {
    this.setParamTriggerSubject.next(opts);
  }

  emitRemoveItemTrigger(opts: RemoveItemTriggerEvent) {
    this.removeItemTriggerSubject.next(opts);
  }

  emitUpdateTableTrigger(opts: UpdateTableTriggerEvent) {
    this.updateTableTriggerSubject.next(opts);
  }

  emitChangeTrigger(opts: ChangeTriggerEvent) {
    this.changeTriggerSubject.next(opts);
  }
}
