/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { Pipe, PipeTransform } from "@angular/core";
import { uniqBy } from "lodash";

@Pipe({
  name: "getChildMenuItems",
  pure: true,
  standalone: true,
})
export class GetChildMenuItemsPipe implements PipeTransform {
  transform(value: any, menu: any, searchString?: string): any[] {
    if (value === undefined) {
      return;
    }

    if (searchString && searchString.length > 0) {
      const sl = searchString.toLowerCase();
      return uniqBy(
        Object.values(menu).filter(
          (e: any) => e.path && (e.label?.toLowerCase().includes(sl) || e.description?.toLowerCase().includes(sl)),
        ),
        "path",
      );
    } else {
      return Object.values(menu).filter((e) => e["parentId"] === value);
    }
  }
}
