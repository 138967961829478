/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ValidatableComponent } from "./validatableComponent";
import type { MetaInputType } from "@meta/api-interfaces";
import { MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";
import * as _ from "lodash";
import type { FormEventActionHandler } from "../interfaces";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-input",
  type: "input",
  name: "Input",
  icon: "input-text",
  palletGroup: "input",
  color: "#6638f0",
})
export class InputComponent<PrimaryDataSource = any> extends ValidatableComponent<PrimaryDataSource> {
  /**
   * Type of the input box  (text = default, number, currency, percent, password)
   */

  @MetaFormComponentProperty({
    name: "Eingabe Type",
    type: "select",
    tab: "general",
    values: [
      {
        value: "text",
        label: "Text",
      },
      {
        value: "textarea",
        label: "Textarea",
      },
      {
        value: "number",
        label: "Number",
      },
      {
        value: "decimal",
        label: "Dezimal",
      },
      {
        value: "currency",
        label: "Currency",
      },
      {
        value: "percent",
        label: "Percent",
      },
      {
        value: "password",
        label: "Password",
      },
      {
        value: "template",
        label: "Template",
      },
      {
        value: "hidden",
        label: "Hidden",
      },
      {
        value: "mail",
        label: "Mail",
      },
      {
        value: "secure-text",
        label: "Secure Text",
      },
    ],
  })
  public inputType?: MetaInputType = "text";
  @MetaFormComponentProperty({
    name: "Zeilen",
    type: "number",
    min: 1,
    max: 5,
    tab: "style",
    condition: (f) => f.inputType === "textarea",
  })
  public rows?: number;

  @MetaFormComponentProperty({
    name: "On Blur",
    type: "action",
    tab: "hooks",
    description: `Will be executed when leaving the field`,
  })
  public onBlur?: FormEventActionHandler;
  @MetaFormComponentProperty({
    name: "On Focus",
    type: "action",
    tab: "hooks",
    description: "Executed when focusing the field",
  })
  public onFocus?: FormEventActionHandler;

  @MetaFormComponentProperty({
    name: "On Click",
    type: "action",
    tab: "hooks",
  })
  public onClick?: FormEventActionHandler;

  @MetaFormComponentProperty({
    name: "Translatable",
    type: "boolean",
    tab: "other",
    hidden: true,
  })
  public translatable? = false;

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        inputType: this.inputType,
        placeholder: this.placeholder, // || (this.hidden && !config.sanitizeTemplates) ? this.path : undefined,
        defaultValue: this.defaultValue,
        link: !!this.link,
        onChange: !!this.onChange,
        onBlur: !!this.onBlur,
        onFocus: !!this.onFocus,
        onClick: !!this.onClick,
        digits: this.maximumFractionDigits,
        minWidth: this.minWidth,
      },
    });
  }
}
