/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  NgModule,
  OnInit,
  QueryList,
  signal,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { GoogleMap, GoogleMapsModule, MapInfoWindow } from "@angular/google-maps";
import { Themes } from "@meta/enums";
import { MetaEmptyModule } from "@meta/ui";
import { FormlyModule } from "@ngx-formly/core";
import { firstValueFrom, fromEvent, takeUntil } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaAppService } from "../../services/metaApp.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaMapService } from "./metaMap.service";

export class MetaMap {
  showDummy?: boolean;
  height? = 300;
  zoom? = 5;
  latitude? = 11;
  longitude? = 51;
  address?: string;
  heatMapData?: [number, number, number][];
}

@MetaUnsubscribe()
@Component({
  selector: "meta-map",
  template: `
    @if (!ma.showDummy) {
      <div class="map-wrapper" [style.min-height.px]="ma.height">
        @if (opts()) {
          <google-map
            [center]="markerPosition || center"
            [zoom]="markerPosition ? 13 : ma.zoom"
            [options]="opts()"
            [height]="ma.height"
            width="100%"
          >
            <map-marker [position]="markerPosition" [options]="markerOptions"></map-marker>
          </google-map>
        }
      </div>
    } @else {
      <meta-empty
        *ngIf="ma.showDummy"
        [style.height.px]="ma.height"
        [maParams]="{ icon: 'map-marked-alt' }"
      ></meta-empty>
    }
  `,
  styleUrls: ["./metaMap.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaMapComponent extends MetaComponentBase implements OnInit, AfterContentInit {
  public static apiLoaded = false;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  public center: any;
  public markerPosition: google.maps.LatLngLiteral = null;
  public display?: google.maps.LatLngLiteral;
  public opts = signal<google.maps.MapOptions>(null);
  public markerOptions: google.maps.MarkerOptions;
  public apiLoaded = false;
  public apiLoadedProm: Promise<any>;
  @ViewChildren(GoogleMap)
  public map = new QueryList<GoogleMap>(true);

  constructor(
    private readonly _httpClient: HttpClient,
    private _metaMapService: MetaMapService,
    private _metaAppRepository: MetaAppService,
  ) {
    super();
    super.maParams = new MetaMap();
    this.map.notifyOnChanges();
  }

  get ma(): MetaMap {
    return super.ma;
  }

  ngAfterContentInit() {
    if (this.ma.showDummy) {
      return;
    }
  }

  ngOnInit() {
    if (this.ma.showDummy) {
      return;
    }
    this.center = { lat: this.ma.latitude || 50.078217, lng: this.ma.longitude || 8.239761 };

    fromEvent(window.matchMedia("(prefers-color-scheme: dark)"), "change")
      .pipe(debounceTime(2_500), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.setMapOptions();
      });

    if (this.form) {
      this.map.changes.pipe(takeUntil(this.destroyed$)).subscribe((q) => {
        if (this.field) {
          this.options.formState
            .onFormDataReady()
            .pipe(takeUntil((this as any).destroyed$))
            .subscribe((data) => {
              if (data && data["_ctx"]) {
                this._metaMapService
                  .loadHeatmapData({
                    formId: this.formState.formId,
                    componentId: this.id,
                    contextId: data["_ctx"],
                  })
                  .then((data) => {
                    const heatmapData = data.map(([lat, log, weight]) => {
                      return {
                        location: new google.maps.LatLng(lat, log),
                        weight: weight || 1,
                      };
                    });
                    const heatmap = new google.maps.visualization.HeatmapLayer({
                      data: heatmapData,
                    });
                    const map = q.first as GoogleMap;
                    heatmap.setMap(map.googleMap);
                  });
              }
            });
        }
      });
    }

    this.loadApi()
      .then(() => new Promise((resolve) => setInterval(resolve, 350)))
      .then(() => this.setMapOptions())
      .then(() => this.getPosition())
      .catch((e) => console.error(e))
      .finally(() => {
        this.changeDetectorRef.markForCheck();
      });
  }

  public async getPosition() {
    const USE_MAPQUEST = false;
    if (this.displayData?.address || this.ma.address) {
      if (USE_MAPQUEST) {
        const pos: any = await this._metaMapService.getPosition(this.displayData?.address || this.ma.address);
        if (pos.results[0].locations[0].latLng) {
          this.markerPosition = pos.results[0].locations[0].latLng;
        }
      } else {
        const geocoder = new google.maps.Geocoder();
        const pos = await new Promise<any>((resolve, reject) => {
          geocoder.geocode(
            {
              address: this.displayData?.address || this.ma.address,
            },
            (results, status) => {
              if (status !== "OK") {
                resolve(null);
              } else {
                resolve(results[0]);
              }
            },
          );
        });

        if (pos) {
          this.markerPosition = pos.geometry.location.toJSON();
        }
      }
    }
  }

  private loadApi() {
    if (!this.apiLoadedProm) {
      this.apiLoadedProm = this._loadApi();
    }
    return this.apiLoadedProm;
  }

  private async _loadApi() {
    if (!MetaMapComponent.apiLoaded) {
      await firstValueFrom(
        this._httpClient.jsonp(
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyCdqS85_B1r4s7jyqMvhoZUz1I_tuk5YvQ&libraries=visualization",
          "callback",
        ),
      );
      MetaMapComponent.apiLoaded = true;
    }
    this.apiLoaded = true;
  }

  private setMapOptions() {
    this.opts.set({
      zoom: this.ma.zoom,
      controlSize: 20,
      fullscreenControl: true,
      zoomControl: true,
      scrollwheel: false,
      disableDefaultUI: true,
      styles: [
        {
          elementType: "geometry",
          stylers: [
            {
              color: getComputedStyle(document.documentElement).getPropertyValue("--text-color-gm-secondary").trim(),
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: getComputedStyle(document.documentElement).getPropertyValue("--text-color-gm").trim(),
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: getComputedStyle(document.documentElement).getPropertyValue("--text-color-gm-secondary").trim(),
            },
          ],
        },
        {
          featureType: "administrative.country",
          elementType: "geometry",
          stylers: [
            {
              color: getComputedStyle(document.documentElement).getPropertyValue("--component-background").trim(),
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: getComputedStyle(document.documentElement).getPropertyValue("--text-color-gm").trim(),
            },
          ],
        },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.stroke",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: getComputedStyle(document.documentElement).getPropertyValue("--text-color-gm").trim(),
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: getComputedStyle(document.documentElement).getPropertyValue("--text-color-gm").trim(),
            },
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              color: getComputedStyle(document.documentElement).getPropertyValue("--component-background-odd").trim(),
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: getComputedStyle(document.documentElement).getPropertyValue("--text-color-gm-mute").trim(),
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: getComputedStyle(document.documentElement).getPropertyValue("--component-background-odd").trim(),
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [
            {
              color: getComputedStyle(document.documentElement).getPropertyValue("--component-background-odd").trim(),
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: getComputedStyle(document.documentElement).getPropertyValue("--text-color-gm").trim(),
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: getComputedStyle(document.documentElement).getPropertyValue("--component-background").trim(),
            },
          ],
        },
      ],
    });

    const icon = {
      path: `M9.1,28.3C9.1,28.3,9.1,28.3,9.1,28.3c0,0,9.1-14,9.1-18.6C18.2,3,13.6,0,9.1,0C4.6,0,0,3,0,9.7
      C0,14.3,9.1,28.3,9.1,28.3L9.1,28.3z M6,9.3c0-1.7,1.4-3.2,3.2-3.2s3.2,1.4,3.2,3.2s-1.4,3.2-3.2,3.2C7.4,12.5,6,11.1,6,9.3z`,
      fillColor: getComputedStyle(document.documentElement).getPropertyValue("--primary-color"),
      strokeColor: getComputedStyle(document.documentElement).getPropertyValue("--component-background"),
      fillOpacity: 1,
      anchor: new google.maps.Point(16, 32),
      strokeWeight: 1,
      scale: 1,
    };

    this.markerOptions = {
      icon: icon,
    };
  }
}

@NgModule({
  declarations: [MetaMapComponent],
  imports: [CommonModule, FormlyModule, GoogleMapsModule, MetaEmptyModule],
  exports: [MetaMapComponent],
})
export class MetaMapModule {}
