/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormComponentInfo } from "./baseComponent";
import { ChartComponent } from "./chartComponent";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-diagram",
  type: "diagram",
  name: "Diagram",
  palletGroup: "reporting",
  icon: "diagram-project",
})
export class MetaDiagramComponent extends ChartComponent {
  public idField = "ID";
  public sourceId = "source";
  public targetId = "target";
  public target: string;

  public nodeDisplayTemplate: string[];
  public connectionDisplayTemplate: string[];

  constructor() {
    super();
  }

  toFormly(config: IFormUserConfiguration) {
    return deepMerge(super.toFormly(config), {});
  }
}
