/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed, input, Input, NgModule, Signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { MetaBrandType } from "@meta/enums";
import { map } from "rxjs/operators";
import { environment } from "../../../../../../apps/meta-argon/src/environments/environment";
import { MetaAppService } from "../../services/metaApp.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";

@MetaUnsubscribe()
@Component({
  selector: "meta-brand",
  template: `
    <ng-container *ngIf="whiteLabel">
      <img alt="" *ngIf="maType === 'logoIcon'" src="/api/v2/auth/icon?theme={{ currentTheme() }}" />
      <img alt="" *ngIf="maType === 'logo'" src="/api/v2/auth/logo?theme={{ currentTheme() }}" />
    </ng-container>
    <ng-container *ngIf="!whiteLabel">
      <svg
        *ngIf="maType === 'logo'"
        style="enable-background:new 0 0 1601.5 260.7;"
        version="1.1"
        viewBox="0 0 1601.5 260.7"
        x="0px"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        class="{{ currentTheme() }}"
        y="0px"
      >
        <path
          class="brand-text"
          d="M485.9,5.6c14.4,0,28.2,2.7,41.3,8c13.2,5.3,24,13.5,32.6,24.6c8.6,11.1,12.8,25.1,12.8,42.3  c0,15.4-4.4,28.4-13.2,39.1c-8.8,10.7-20,18.7-33.5,24c-13.5,5.3-27.5,8-41.9,8c-4.5,0-10.4-0.2-17.9-0.7l116.2,104.3H531  L423.7,150.9v104.3h-33.5V5.6H485.9z M423.7,37.2v80.1c8.2,2.2,17.1,4,26.8,5.2c9.7,1.2,19.2,1.9,28.7,1.9c18.9,0,33.5-4,43.8-11.9  c10.3-7.9,15.5-18.6,15.5-32c0-13.9-5.7-24.6-16.9-32c-11.3-7.4-25-11.2-41.2-11.2H423.7z"
        />
        <path
          class="brand-text"
          d="M798.7,118.8h109.9v102.4c-6,6.5-13.7,12.7-23.1,18.8c-9.4,6.1-20.3,11.1-32.6,14.9  c-12.3,3.8-25.8,5.8-40.4,5.8c-18.9,0-36.3-3.4-52.1-10.2c-15.9-6.8-29.8-16.2-41.7-28.1c-11.9-11.9-21.2-25.8-27.8-41.5  c-6.6-15.8-9.9-32.6-9.9-50.5s3.3-34.7,9.9-50.5c6.6-15.8,15.8-29.6,27.8-41.5s25.8-21.3,41.7-28.1C776.2,3.4,793.6,0,812.5,0  c18.1,0,33,2.5,44.5,7.4c11.5,5,21.7,11.4,30.4,19.4v36.9c-5.5-4-11.6-8.3-18.4-13c-6.8-4.7-14.8-8.9-23.8-12.5  c-9.1-3.6-19.9-5.4-32.6-5.4c-18.6,0-35.3,4.2-49.9,12.7c-14.7,8.4-26.2,20.1-34.6,34.8c-8.4,14.8-12.7,31.5-12.7,50.1  s4.2,35.3,12.7,49.9c8.4,14.7,20,26.3,34.6,34.8c14.6,8.6,31.3,12.9,49.9,12.9c12.7,0,22.8-1.3,30.5-3.9c7.7-2.6,13.8-5.6,18.3-8.9  c4.5-3.4,8.1-6.1,10.8-8.4l0.4-58.1h-73.8V118.8z"
        />
        <path
          class="brand-text"
          d="M1149.9,0c18.9,0,36.3,3.4,52.1,10.2c15.9,6.8,29.8,16.2,41.7,28.1c11.9,11.9,21.2,25.8,27.8,41.5  c6.6,15.8,9.9,32.6,9.9,50.5s-3.3,34.7-9.9,50.5c-6.6,15.8-15.8,29.6-27.8,41.5c-11.9,11.9-25.8,21.3-41.7,28.1  c-15.9,6.8-33.3,10.2-52.1,10.2c-18.9,0-36.3-3.4-52.1-10.2c-15.9-6.8-29.8-16.2-41.7-28.1c-11.9-11.9-21.2-25.8-27.8-41.5  c-6.6-15.8-9.9-32.6-9.9-50.5s3.3-34.7,9.9-50.5c6.6-15.8,15.8-29.6,27.8-41.5s25.8-21.3,41.7-28.1C1113.6,3.4,1131,0,1149.9,0z   M1149.9,32.8c-18.6,0-35.3,4.2-49.9,12.7c-14.7,8.4-26.2,20.1-34.6,34.8c-8.4,14.8-12.7,31.5-12.7,50.1s4.2,35.3,12.7,49.9  c8.4,14.7,20,26.3,34.6,34.8c14.6,8.6,31.3,12.9,49.9,12.9s35.3-4.3,49.9-12.9c14.6-8.6,26.2-20.2,34.6-34.8  c8.4-14.6,12.7-31.3,12.7-49.9s-4.2-35.3-12.7-50.1c-8.4-14.8-20-26.4-34.6-34.8C1185.1,37,1168.5,32.8,1149.9,32.8z"
        />
        <path class="brand-text" d="M1601.5,5.6v249.6h-12.7l-162-178.4v178.4h-33.5V5.6h12.7l162,180.7V5.6H1601.5z" />
        <g>
          <path
            class="brand-logo-sub"
            d="M226.7,254.5l44.6-76.4c1-1.6,1.1-2.9,0.1-4.6c-5.4-9.1-10.7-18.3-15.9-27.5c-5-8.7-10-17.3-15-26   c-32.3-13.1-67-20.1-102.8-20.3L226.7,254.5z"
          />
          <path
            class="brand-logo-sub"
            d="M0.7,173.3c-1,1.7-0.9,3,0.1,4.6l44.6,76.4l89-154.7c-35.7,0.1-70.4,7-102.6,19.9c-5,8.8-10.1,17.5-15.1,26.3   C11.3,155,6.1,164.2,0.7,173.3z"
          />
          <path
            class="brand-logo"
            d="M226.9,96L178.7,9.1c-1-1.7-2.1-2.2-4-2.2c-28.5,0.1-49,0-77.5,0c-0.4,0-0.8,0-1.3,0c-1.2-0.1-1.9,0.4-2.4,1.4   L45.3,95.6c28.8-9.2,59.1-13.9,90.2-13.9C167,81.7,197.8,86.6,226.9,96z"
          />
        </g>
      </svg>

      <svg
        *ngIf="maType === 'logoIcon'"
        style="enable-background:new 0 0 272 260.7;"
        version="1.1"
        viewBox="0 0 272 260.7"
        x="0px"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        y="0px"
        class="{{ currentTheme() }}"
      >
        <g>
          <path
            class="brand-logo-sub"
            d="M226.7,254.5l44.6-76.4c1-1.6,1.1-2.9,0.1-4.6c-5.4-9.1-10.7-18.3-15.9-27.5c-5-8.7-10-17.3-15-26
		c-32.3-13.1-67-20.1-102.8-20.3L226.7,254.5z"
          />
          <path
            class="brand-logo-sub"
            d="M0.7,173.3c-1,1.7-0.9,3,0.1,4.6l44.6,76.4l89-154.7c-35.7,0.1-70.4,7-102.6,19.9c-5,8.8-10.1,17.5-15.1,26.3
		C11.3,155,6.1,164.2,0.7,173.3z"
          />
          <path
            class="brand-logo"
            d="M226.9,96L178.7,9.1c-1-1.7-2.1-2.2-4-2.2c-28.5,0.1-49,0-77.5,0c-0.4,0-0.8,0-1.3,0c-1.2-0.1-1.9,0.4-2.4,1.4
		L45.3,95.6c28.8-9.2,59.1-13.9,90.2-13.9C167,81.7,197.8,86.6,226.9,96z"
          />
        </g>
      </svg>
    </ng-container>
  `,
  styleUrls: ["./metaBrand.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaBrandComponent {
  @Input()
  public maType: MetaBrandType = "logo";
  @Input()
  public whiteLabel = false;
  public readonly theme = input<"default" | "dark" | "system" | "dev">();
  private readonly userTheme: Signal<string>;

  public readonly currentTheme = computed(() => {
    if (!environment.production) {
      return "dev";
    }
    return this.theme() || this.userTheme();
  });

  constructor(private _metaAppRepository: MetaAppService) {
    this.userTheme = toSignal(this._metaAppRepository.app$.pipe(map((a) => a.theme)));
  }
}

@NgModule({
  declarations: [MetaBrandComponent],
  imports: [CommonModule],
  exports: [MetaBrandComponent],
})
export class MetaBrandModule {}
