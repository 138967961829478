/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { Injectable } from "@angular/core";
import { MetaModalService } from "../../../../../libs/ui/src/lib/services/metaModalService";
import { ApproveService } from "../pages/approve/approve.service";
import { Router, UrlTree } from "@angular/router";
import { MetaMediaLibraryService } from "../../../../../libs/ui/src/lib/components/metaMediaLibrary/metaMediaLibrary.service";
import { MetaTaskService } from "../../../../../libs/ui/src/lib/components/metaTask/metaTask.service";
import { MetaFormComponent } from "libs/ui/src/lib/base/metaForm/metaForm.component";

@Injectable({
  providedIn: "root",
})
export class MetaDeepLinkingService {
  constructor(
    private readonly approveService: ApproveService,
    private readonly mediaLibraryService: MetaMediaLibraryService,
    private readonly metaTaskService: MetaTaskService,
    private readonly metaModalService: MetaModalService,
    private readonly router: Router,
  ) {
    window.addEventListener("click", (e) => {
      const t = e.target as HTMLLinkElement;
      if (t?.tagName.toLowerCase() === "a") {
        const chatContainer = document.querySelector(".chat-container");
        if (chatContainer && chatContainer.contains(t) && t.href) {
          const url = new URL(t.href);
          if (url.protocol === "mailto:" || url.protocol === "tel:") {
            return;
          }
          if (url.pathname === "/deep-link") {
            e.preventDefault();
            this.open(Object.fromEntries(url.searchParams.entries()));
          } else {
            e.preventDefault();
            this.router
              .navigateByUrl(
                this.router.createUrlTree([url.pathname], {
                  queryParams: Object.fromEntries(url.searchParams.entries()),
                }),
              )
              .catch(console.error);
          }
        }
      }
    });
  }
  open(data: Record<string, any>): UrlTree | boolean | void {
    if (data.type === "file") {
      this.mediaLibraryService.previewFileById(data.id);
    } else if (data.type === "task") {
      this.metaTaskService.openTask({ id: data.id });
    } else if (data.type === "wizard") {
      this.openWizard(data as any).catch(console.error);
    } else if (data.type === "approve") {
      this.approveService.showModal(data.id).catch(console.error);
    } else if (data.type === "mail") {
      alert(JSON.stringify(["/mail", ...data.id.split("/")]));
      return this.router.createUrlTree(["/", "mail", ...data.id.split("/")]);
    } else if (data.type === "modal") {
      this.metaModalService.create({
        nzContent: MetaFormComponent,
        nzComponentParams: {
          formId: data.form,
          itemId: data.id,
        },
      });
    } else {
      this.approveService.showModal(data.id).catch(console.error);
    }
    if (this.router.url === "/") {
      return this.router.createUrlTree(["/"]);
    }

    return false;
  }

  private async openWizard(opts: { formId: string; itemId?: string }) {
    this.metaModalService.create({
      nzContent: MetaFormComponent,
      nzComponentParams: {
        formId: opts.formId,
        itemId: opts.itemId,
      },
    });
  }
}
