/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UserService } from "../../services/user.service";
import { EntityType } from "@meta/enums";
import { firstValueFrom, Observable } from "rxjs";
import { IFeedResultItem } from "@meta/api-interfaces";

@Injectable()
export class MetaTimelineService {
  constructor(private readonly _http: HttpClient, private readonly _userService: UserService) {}

  public async createComment(text: string, data: IFeedResultItem) {
    await firstValueFrom(
      this._http.post("activities/notes", {
        quellId: String(data.ID || data.id),
        quellTyp: EntityType.Feed,
        note: text,
      })
    );
    data.comments.push({
      date: new Date(),
      value: text,
      user: {
        id: this._userService.user.value.id,
        name: this._userService.user.value.email,
      },
    });
  }

  public deleteNote(id: string | number): Observable<any> {
    return this._http.delete(`activities/notes/${id}`, {});
  }
}
