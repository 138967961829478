/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ChangeDetectionStrategy, Component, Input, NgModule, OnInit, ViewEncapsulation } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MetaSkeletonSize } from "@meta/enums";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";

export class MetaSkeleton {
  public loading = false;
  public width?: string | number | "random" = "100%";
  public align?: "left" | "right" | "center" = "left";
  public rows? = 0;
  public avatar?: boolean | "only" = false;
  public noTitle?: boolean;
  public size?: MetaSkeletonSize = MetaSkeletonSize.default;
  public noMargin? = false;
  public active? = true;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-skeleton",
  template: `
    <nz-skeleton
      [class]="ma.align"
      [ngClass]="{ small: ma.size === MetaSkeletonSize.small, only: ma.avatar === 'only', 'no-margin': ma.noMargin }"
      [nzActive]="ma.active"
      [nzAvatar]="ma.avatar ? { shape: 'circle' } : false"
      [nzLoading]="ma.loading"
      [nzParagraph]="ma.rows > 0 ? { rows: ma.rows } : false"
      [nzTitle]="ma.avatar !== 'only' && !ma.noTitle ? { width: ma.width } : false"
    ></nz-skeleton>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaSkeletonComponent implements OnInit {
  public MetaSkeletonSize = MetaSkeletonSize;

  constructor() {}

  private _maParams: MetaSkeleton = new MetaSkeleton();

  @Input()
  public get maParams(): MetaSkeleton {
    return this._maParams;
  }

  public set maParams(value: MetaSkeleton) {
    this._maParams = { ...this._maParams, ...value };
  }

  get ma(): MetaSkeleton {
    return this.maParams;
  }

  ngOnInit() {
    if (this.ma.width === "random") {
      const min = 30;
      const max = 70;
      this.ma.width = Math.floor(Math.random() * (max - min + 1) + min) + "%";
    }
  }
}

@NgModule({
  declarations: [MetaSkeletonComponent],
  imports: [CommonModule, NzSkeletonModule],
  exports: [MetaSkeletonComponent],
})
export class MetaSkeletonModule {}
