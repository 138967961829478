<!--
  ~ Copyright (C) MetaCarp GmbH - All Rights Reserved
  ~ Unauthorized copying of this file, via any medium is strictly prohibited
  ~ Proprietary and confidential
  ~ Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
  ~ Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
  -->
<nz-select
  (ngModelChange)="execSelectAction($event)"
  (nzOnSearch)="searchFilter.next($event)"
  *ngIf="!formControl.value"
  [compareWith]="compareWith"
  [disabled]="ma.disabled || ma.readonly"
  [formControl]="fc"
  [nzDropdownRender]="allowNew ? renderTemplate : null"
  [nzShowArrow]="true"
  nzAllowClear
  nzPlaceHolder="Adresse auswählen"
  nzServerSearch
  nzShowSearch
>
  <ng-container *ngIf="displayAddress$ | async as address">
    <nz-option [nzLabel]="address.display" [nzValue]="address.id"></nz-option>
  </ng-container>
  <nz-option *ngFor="let o of selectData$ | async" [nzValue]="o.value" nzCustomContent>
    <i class="fal fa-{{ o.icon }}"></i> <span>{{ o.label }}</span>
    <ng-container *ngIf="o.description">
      <br />
      <small>{{ o.description }}</small>
    </ng-container>
  </nz-option>
</nz-select>


<ng-container *ngIf="displayAddress$ | async as address">
  <address>
    {{ address.name1 || "" }} {{ address.nameSuffix || "" }}<br />
    {{ address.street || "" }} {{ address.streetNumber || '' }}<br />
    {{ address.country?.code || "" }} - {{ address.zipcode || "" }} {{ address.city || "" }}<br />
  </address>
  <button (click)="editAddress()" *ngIf="formControl.value && allowEdit && address.type === 'address'" nz-button>
    <i class="fal fa-pen"></i>
  </button>
  <button (click)="clear()" nz-button>
    <i class="fal fa-times"></i>
  </button>
</ng-container>

<ng-template #renderTemplate>
  <nz-divider></nz-divider>
  <button (click)="editAddress()" nz-button>Neue Adresse</button>
</ng-template>
