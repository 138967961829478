/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ValidatableComponent } from "./validatableComponent";
import { ExposeProperty, MetaFormComponentInfo } from "./baseComponent";

@MetaFormComponentInfo({
  selector: "ma-tag",
  type: "tag",
  name: "Tag",
  icon: "tag",
  palletGroup: "input",
})
export class TagComponent extends ValidatableComponent {
  @ExposeProperty()
  public tagValueField: string;
  @ExposeProperty()
  public tagDataSourceId: string;

  constructor() {
    super();
    this.editable = false;
  }
}
