/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormBaseComponent, MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";
import * as _ from "lodash";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

export type DocumentViewComponentFitType = "fit" | "fitH" | "fitV";
export type DocumentViewComponentPageModeType = "thumbs" | "bookmarks" | "none";

@MetaFormComponentInfo({
  selector: "ma-document-view",
  type: "document-view",
  name: "Document View",
  icon: "file-pdf",
})
export class DocumentViewComponent extends MetaFormBaseComponent {
  @MetaFormComponentProperty({
    name: "Formular Id",
    type: "subform",
    tab: "general",
  })
  public formId?: string;

  @MetaFormComponentProperty({
    name: "Locale",
    type: "text",
    tab: "general",
    isTemplate: true,
  })
  public locale?: string;

  @MetaFormComponentProperty({
    name: "Formular Values",
    type: "text",
    isTemplate: true,
    tab: "general",
  })
  public values?: string[] = [];

  @MetaFormComponentProperty({
    name: "Show Toolbar",
    type: "boolean",
    default: false,
    tab: "general",
  })
  /**
   * Shows the browser integrated PDF-Viewer Toolbar.
   */
  public toolbar? = false;

  @MetaFormComponentProperty({
    name: "Nav Panes",
    type: "boolean",
    default: false,
    tab: "general",
  })
  public navPanes? = false;

  @MetaFormComponentProperty({
    name: "View",
    type: "select",
    default: "fit",
    tab: "general",
    values: [],
  })
  public view?: DocumentViewComponentFitType = "fitH";

  @MetaFormComponentProperty({
    name: "pageMode",
    type: "select",
    default: "none",
    tab: "general",
    values: [],
  })
  public pageMode?: DocumentViewComponentPageModeType = "none";

  toFormly(config: IFormUserConfiguration) {
    return deepMerge(super.toFormly(config), {
      props: {
        hasCondition: !!this.condition,
        formId: this.formId,
        pdfOptions: {
          view: this.view,
          toolbar: this.toolbar ? "1" : "0",
          navPanes: this.navPanes ? "1" : "0",
          pageMode: this.pageMode,
        },
      },
    });
  }
}
