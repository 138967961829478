/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2021
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2021
 * Written by Yannick Küchlin <y.kuechlin@taifun-tofu.de>, 2019-2020
 */

import { EventEmitter } from "@angular/core";
import { MetaColor, MetaTimelineColor } from "@meta/enums";

export interface MetaAction {
  label: string;
  action: EventEmitter<any>;
}

export interface MetaStatisticGroupPrimary {
  value: string | number;
  label: string | number;
  prefixType: "string" | "icon";
  prefix: string;
  suffixType: "string" | "icon";
  suffix: string;
  color: "default" | "success" | "danger" | "warning";
}

export interface MetaStatisticGroupSecondary {
  value: string | number;
  prefixType: "string" | "icon";
  prefix: string;
  suffixType: "string" | "icon";
  suffix: string;
  color: "default" | "success" | "danger" | "warning";
}

export interface MetaMenuItems {
  [key: string]: string | Object;
}

/* example:
[

  {
    label: 'Test-Item-label',
    icon: "",
    active: false,
    action: {
      param: 'Test-Item-Value',
      type: "test"
    }
  }
]
*/

export interface MetaBreadcrumb {
  [id: string]: string;
}

export interface MetaTimelineItem {
  type?: string,
  label?: string;
  description?: string;
  groupDescription?: any;
  metadata?: {
    label: string;
    icon?: string;
    link?: string;
    tooltip?: string;
  }[];
  participants?: any[];
  data?: any;
  icon?: string;
  avatar?: string;
  link?: string;
  length?: number;
  color?: MetaTimelineColor;
}

export type MetaProgressTreshold = {
  [key in MetaColor]?: number;
};

export interface MetaCardMetadata {
  label: string;
  icon?: string;
  link?: string;
}

export interface MetaRadioData {
  label: string;
  value: string | number;

  [key: string]: string | number;
}

export interface MetaColumns {
  label: string;
  id: string;
  link?: string;

  [key: string]: any;
}

export interface MetaChartData {
  label: string;
  value: string | number;
}

export interface MetaChartDataset {
  seriesname: string;
  data: { value: string | number }[];
}

export interface MetaChartCategory {
  category: { label: string }[];
}

export interface MetaChartNode {
  label: string;
}

export interface MetaChartLink {
  from: string;
  to: string;
  value: number;
}

export type SkimmedControl = Pick<any,
  "parentId" | "name" | "label" | "visible">;

export type GroupedLabels = Record<string, SkimmedControl[]>;
