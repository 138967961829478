/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { MetaButtonModule } from "@meta/ui";
import { FormlyModule } from "@ngx-formly/core";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { takeUntil } from "rxjs";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { IMetaMediaLibraryFile } from "../metaMediaLibrary/interfaces";
import { MetaMediaLibraryService } from "../metaMediaLibrary/metaMediaLibrary.service";

export class MetaUpload {
  width?: number;
  height?: number;
  editing? = false;
  directUpload? = false;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-upload",
  template: `
    <ng-template #tooltip>Vorschau öffnen</ng-template>
    <ng-container *ngIf="editing; else displayTpl">
      <meta-button
        *ngIf="!formControl.value"
        (click)="selectFile()"
        [maParams]="{ icon: 'upload', label: 'Datei Auswählen' }"
      ></meta-button>
      <div class="preview-container">
        <a [nz-tooltip]="tooltip" (click)="click(true)">
          <ng-container *ngIf="formControl.value"><i class="fal fa-{{ icon }}"></i></ng-container>
          {{ formControl.value ? selectedFile?.name || displayData["filename"] : "" }}</a
        >
        <meta-button
          *ngIf="formControl.value"
          (click)="clear()"
          title="Datei entfernen…"
          [maParams]="{ icon: 'times', danger: true }"
        ></meta-button>
      </div>
    </ng-container>
    <ng-template #displayTpl>
      <a [nz-tooltip]="tooltip" (click)="click(true)">
        <ng-container *ngIf="formControl.value"><i class="fal fa-{{ icon }}"></i></ng-container>
        {{ displayData["filename"] || "" }}</a
      >
    </ng-template>
  `,
  styleUrls: ["./metaUpload.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaUploadComponent extends MetaComponentBase {
  public icon: string;
  public selectedFile: IMetaMediaLibraryFile;
  protected readonly fileName: string;

  constructor(
    public sanitizer: DomSanitizer,
    public readonly mediaLibraryService: MetaMediaLibraryService,
  ) {
    super();
    super.maParams = new MetaUpload();
  }

  get ma(): MetaUpload {
    return super.ma;
  }

  get url() {
    if (!this.formControl.value) {
      return null;
    }
    return this.sanitizer.bypassSecurityTrustUrl(`/api/v2/file/${this.formControl.value}/download`);
  }

  get displayData(): any {
    return this.ma?.["displayData"] || {};
  }

  async ngOnInit() {
    super.ngOnInit();
    this.formControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.selectedFile = null;
      this.icon = this.mediaLibraryService.getIcon({
        name: this.displayData["filename"],
        mime: this.displayData["mime"],
        type: null,
      });
      this.updateDisplayValue();
    });
    this.updateDisplayValue();
  }

  click(preview = false) {
    if (this.formControl.value && preview) {
      this.mediaLibraryService.previewFileById(this.formControl.value);
    }
  }

  selectFile() {
    if (this.ma.directUpload) {
      const input = document.createElement("input");
      input.type = "file";
      input.addEventListener("change", (e) => {
        const file: File = e.target["files"]?.[0];
        if (file) {
          this.mediaLibraryService.directUploadFiles([file]).then(([file]) => {
            this.formControl.setValue(file.id);
            this.selectedFile = file;
            this.icon = this.mediaLibraryService.getIcon(file);
            this.formControl.markAsDirty();
            this.changeDetectorRef.markForCheck();
          });
        }
      });
      input.click();
    } else {
      this.mediaLibraryService.selectSingleFile({}).then((file) => {
        if (file) {
          this.formControl.setValue(file.id);
          this.selectedFile = file;
          this.icon = this.mediaLibraryService.getIcon(file);
          this.formControl.markAsDirty();
          this.changeDetectorRef.markForCheck();
        }
      });
    }
  }

  clear() {
    this.formControl.setValue(null);
    this.formControl.markAsDirty();
    this.selectedFile = null;
    this.changeDetectorRef.markForCheck();
  }

  private updateDisplayValue() {
    if (!this.formState.formId && this.formControl.value) {
      this.mediaLibraryService
        .getFile(this.formControl.value)
        .then((e) => {
          this.selectedFile = e;
          this.changeDetectorRef.markForCheck();
        })
        .catch(() => {
          this.selectedFile = null;
        });
    }
  }
}

@NgModule({
  declarations: [MetaUploadComponent],
  schemas: [],
  imports: [CommonModule, FormlyModule, NzUploadModule, ReactiveFormsModule, MetaButtonModule, NzToolTipModule],
  exports: [MetaUploadComponent],
})
export class MetaUploadModule {}
