/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaFormBaseComponent, MetaFormComponentInfo, MetaFormComponentProperty } from "./baseComponent";

@MetaFormComponentInfo({
  selector: "ma-list-item",
  type: "list-item",
  name: "List item",
})
export class ListItemComponent extends MetaFormBaseComponent {
  @MetaFormComponentProperty({
    name: "Label",
    type: "text",
    isTemplate: true,
  })
  public avatar?: string;

  @MetaFormComponentProperty({
    name: "layoutTemplates",
    isTemplate: true,
    type: "text",
  })
  public layoutTemplates?: string[] = [];

  @MetaFormComponentProperty({
    name: "group",
    isTemplate: true,
    type: "text",
  })
  public group?: string;
}
