/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@tolgee/ngx";
import intervalToDuration from "date-fns/intervalToDuration";

@Pipe({
  name: "metaDuration",
  pure: true,
  standalone: true,
})
export class DurationPipe implements PipeTransform {
  constructor(private readonly t: TranslateService) {}
  transform(ms: number, format: "long" | "short" = "long"): any {
    if (!ms) return "00:00";

    const duration = intervalToDuration({ start: 0, end: ms });

    let parts: string[] = [];

    if (format === "long") {
      if (duration.seconds > 0) {
        parts.unshift(
          this.t.instant(`metaDuration.seconds`, {
            value: duration.seconds,
          }),
        );
      }
      if (duration.minutes > 0) {
        parts.unshift(
          this.t.instant(`metaDuration.minutes`, {
            value: duration.minutes,
          }),
        );
      }
      if (duration.hours > 0) {
        parts.unshift(
          this.t.instant(`metaDuration.hours`, {
            value: duration.hours,
          }),
        );
      }
    } else {
      let formatted = `${String(duration.minutes).padStart(2, "0")}:${String(duration.seconds).padStart(2, "0")}`;
      if (duration.hours > 0) {
        formatted = String(duration.hours).padStart(2, "0") + `:` + formatted;
      }
      parts.unshift(formatted);
    }

    if (duration.days > 0) {
      parts.unshift(
        this.t.instant(`metaDuration.days`, {
          value: duration.days,
        }),
      );
    }
    if (duration.weeks > 0) {
      parts.unshift(
        this.t.instant(`metaDuration.weeks`, {
          value: duration.weeks,
        }),
      );
    }
    if (duration.months > 0) {
      parts.unshift(
        this.t.instant(`metaDuration.months`, {
          value: duration.months,
        }),
      );
    }
    if (duration.years > 0) {
      parts.unshift(
        this.t.instant(`metaDuration.years`, {
          value: duration.years,
        }),
      );
    }

    return parts.join(", ");
  }
}
