/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { queue } from "async";

export class ElevenLabs {
  private context = new AudioContext();
  private model = "eleven_multilingual_v2";
  private apiKey = window.localStorage["eleven-labs-api-key"];
  private useSocket = window.localStorage["eleven-labs-socket"] === "true";
  private isPlaying = false;
  private bufferSize = 0;
  private voice_settings = {
    stability: 0.5,
    similarity_boost: 0.5,
  };
  /**
   * 0	default mode (no latency optimizations)
   * 1	normal latency optimizations (about 50% of possible latency improvement of option 3)
   * 2	strong latency optimizations (about 75% of possible latency improvement of option 3)
   * 3	max latency optimizations
   * 4	max latency optimizations, but also with text normalizer turned off for even more latency savings (best latency, but can mispronounce eg numbers and dates).
   * @private
   */
  private optimize_streaming_latency: 0 | 1 | 2 | 3 | 4 = 2;
  /**
   * mp3_44100	default output format, mp3 with 44.1kHz sample rate
   * pcm_16000	PCM format (S16LE) with 16kHz sample rate
   * pcm_22050	PCM format (S16LE) with 22.05kHz sample rate
   * pcm_24000	PCM format (S16LE) with 24kHz sample rate
   * pcm_44100
   * @private
   */
  private output_format: "mp3_44100" | "pcm_16000" | "pcm_22050" | "pcm_24000" | "pcm_44100" = "mp3_44100";

  private textQueue = queue<{ text: string; voice_id: string }>(({ text, voice_id }, callback) => {
    if (this.useSocket) {
      return this.streamAudio(text, voice_id)
        .then(() => callback())
        .catch((err) => callback(err));
    } else {
      return this.fetchAudio(text, voice_id)
        .then((audioBuffer) => {
          this.bufferSize += audioBuffer.duration;
          this.playBackQueue.push([audioBuffer, callback]);
          this.playBackQueue.push([null, () => {}]);
          this.flushPlayBackBuffer();
        })
        .catch((err) => callback(err));
    }
  }, 1);

  private playBackQueue: [AudioBuffer, (err: any) => any][] = [];

  private flushPlayBackBuffer() {
    if (this.isPlaying) {
      return;
    }
    const isFinal = this.playBackQueue.findIndex(([audio]) => audio === null) !== -1;
    if (this.bufferSize < 2.5 && !isFinal) return;
    const next = this.playBackQueue.shift();
    if (!next) return;
    const [audioBuffer, callback] = next;
    if (!audioBuffer) {
      this.flushPlayBackBuffer();
      callback(null);
      return;
    }
    this.isPlaying = true;
    const source = new AudioBufferSourceNode(this.context, {
      buffer: audioBuffer,
      detune: 100,
    });
    source.connect(this.context.destination);
    source.addEventListener("ended", () => {
      source.disconnect();
      this.isPlaying = false;
      this.flushPlayBackBuffer();
      callback(null);
    });
    source.start(0);
    this.bufferSize -= audioBuffer.duration;
  }

  private decoderQueue = queue<{ audioBuffer: ArrayBuffer }>(({ audioBuffer }, callback) => {
    if (audioBuffer) {
      this.context
        .decodeAudioData(audioBuffer)
        .then((decodedAudioData) => {
          this.playBackQueue.push([decodedAudioData, () => {}]);
          this.bufferSize += decodedAudioData.duration;
          this.flushPlayBackBuffer();
          callback();
        })
        .catch((err) => {
          console.error(err);
          callback(err);
        });
    } else {
      this.playBackQueue.push([null, () => {}]);
      this.flushPlayBackBuffer();
      callback();
    }
  }, 1);

  public get enabled() {
    return !!this.apiKey;
  }

  constructor() {}

  public async getVoices() {
    if (!this.apiKey || this.apiKey === "mac") {
      return [];
    }
    const r: any = await fetch("https://api.elevenlabs.io/v1/voices", {
      headers: {
        "xi-api-key": this.apiKey,
      },
    }).then((e) => e.json());
    return r.voices.map((e: Record<string, any>) => {
      return {
        label: `11 Labs | ${e.name}`,
        value: `eleven-labs.${e.voice_id}`,
        preview_url: e.preview_url,
      };
    });
  }

  public queue(text: string, voice_id: string): Promise<void> {
    return this.textQueue.push({ text, voice_id });
  }

  public stop() {
    this.textQueue.remove(() => true);
    this.decoderQueue.remove(() => true);
  }

  private async fetchAudio(text: string, voice_id: string) {
    if (this.apiKey === "mac") {
      const response = await fetch("/api/v2/chatbot/say-text", {
        body: JSON.stringify({
          text,
        }),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      return await this.context.decodeAudioData(await response.arrayBuffer());
    } else {
      const response = await fetch(
        `https://api.elevenlabs.io/v1/text-to-speech/${voice_id}/stream?optimize_streaming_latency=${this.optimize_streaming_latency}&output_format=${this.output_format}`,
        {
          method: "post",
          body: JSON.stringify({
            text,
            model_id: this.model,
            voice_settings: this.voice_settings,
          }),
          headers: {
            Accept: "audio/mpeg",
            "Content-Type": "application/json",
            "xi-api-key": this.apiKey,
          },
        }
      );
      return await this.context.decodeAudioData(await response.arrayBuffer());
    }
  }

  private base64ToArrayBuffer(base64: string) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  private streamAudio(text: string, voice_id: string) {
    return new Promise<void>((resolve) => {
      const wsUrl = `wss://api.elevenlabs.io/v1/text-to-speech/${voice_id}/stream-input?model_id=${this.model}&optimize_streaming_latency=${this.optimize_streaming_latency}&output_format=${this.output_format}`;
      const socket = new WebSocket(wsUrl);
      // 2. Initialize the connection by sending the BOS message
      socket.onopen = () => {
        const bosMessage = {
          text: " ",
          voice_settings: this.voice_settings,
          xi_api_key: this.apiKey, // replace with your API key
        };

        socket.send(JSON.stringify(bosMessage));

        // 3. Send the input text message ("Hello World")
        const textMessage = {
          text: text + " ",
          try_trigger_generation: true,
        };

        socket.send(JSON.stringify(textMessage));

        // 4. Send the EOS message with an empty string
        const eosMessage = {
          text: "",
        };

        socket.send(JSON.stringify(eosMessage));
      };

      // 5. Handle server responses
      socket.onmessage = (event) => {
        const response = JSON.parse(event.data);

        console.log("Server response:", response);

        if (response.audio) {
          // decode and handle the audio data (e.g., play it)
          this.decoderQueue.push({ audioBuffer: this.base64ToArrayBuffer(response.audio) }).catch((e) => {
            console.error(e);
          });
          console.log("Received audio chunk");
        } else {
          console.log("No audio data in the response");
        }
        if (response.isFinal) {
          this.decoderQueue.push({ audioBuffer: null }).catch((e) => {
            console.error(e);
          });
        }
        if (response.normalizedAlignment) {
          // use the alignment info if needed
        }
      };

      // Handle errors
      socket.onerror = (error) => {
        console.error(`WebSocket Error: ${error}`);
      };

      // Handle socket closing
      socket.onclose = (event) => {
        if (event.wasClean) {
          console.info(`Connection closed cleanly, code=${event.code}, reason=${event.reason}`);
        } else {
          console.warn("Connection died");
        }
        resolve();
      };
    });
  }
}
