/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import * as _ from "lodash";
import { Pipe } from "@angular/core";

export function getSchemaFromPath(
  schema: any,
  path: string,
): { schema: any; ancestors: any[]; name: string; formatter: string; path: string } {
  if (!path)
    return {
      schema,
      ancestors: [],
      name: schema.title,
      formatter: null,
      path: null,
    };

  path = path.replace(/^{{/, "").replace(/}}$/, "").trim();

  let formatter: string = null;
  const res = /^\$\.(.+?)\((.+?)\)/i.exec(path);
  if (res) {
    formatter = res[1].trim();
    path = res[2].trim();
  }
  const segments = _.toPath(path);
  const ancestors: any[] = [];
  const childSchema = getSchema(schema, segments.slice(1), ancestors);
  if (!childSchema) return null;
  return {
    schema: childSchema,
    ancestors,
    formatter,
    path,
    name:
      [...ancestors.slice(1), childSchema]
        .map((e) => e.title)
        .filter((e) => !!e)
        .join(" > ") + (formatter ? " | " + formatter : ""),
  };
}

function getSchema(schema: any, segments: string[], ancestors: any[]) {
  if (!schema) return null;
  if (!segments.length) return schema;
  if (segments.length === 1 && !segments[0]) return schema;
  ancestors.push(schema);
  let nextSegment = segments[0];
  let subSegments = segments.slice(1);
  let subSchema = null;
  if (schema.properties) {
    return getSchema(schema.properties[nextSegment], subSegments, ancestors);
  } else if (schema.patternProperties) {
    let patterns = schema.patternProperties;
    for (let pattern in patterns) {
      if (new RegExp(pattern).test(nextSegment)) {
        return getSchema(patterns[pattern], subSegments, ancestors);
      }
    }
  } else if (schema.additionalProperties) {
    return getSchema(schema.additionalProperties, subSegments, ancestors);
  } else if (schema.items) {
    return getSchema(schema.items, subSegments, ancestors);
  } else if (schema.oneOf) {
    // Find oneOf element that has a matching property for next segment:
    const oneOfTarget = schema.oneOf.filter((item) => {
      return item.properties && item.properties[nextSegment];
    })[0];
    return getSchema(oneOfTarget && oneOfTarget.properties[nextSegment], subSegments, ancestors);
  } else {
    // There's no deeper schema defined
    return null;
  }
  return getSchema(subSchema, subSegments, ancestors);
}

@Pipe({
  standalone: true,
  name: "getSchemaFromPath",
})
export class getSchemaFromPathPipe {
  transform(
    value: string,
    schema: any,
  ): {
    schema: Record<string, any> & { title: string; type?: string; $id: string };
    name: string;
    ancestors: any[];
  } {
    return getSchemaFromPath(schema, value);
  }
}

@Pipe({
  standalone: true,
  name: "getNameFromPath",
})
export class getNameFromPathPipe {
  transform(value: string, schema: any): string {
    return getSchemaFromPath(schema, value)?.name;
  }
}
