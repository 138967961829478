/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { createState, Store, withProps } from "@ngneat/elf";
import { deleteEntitiesByPredicate, selectAllApply, upsertEntities, withEntities } from "@ngneat/elf-entities";
import { MetaFormCondition } from "libs/forms/src/metaState.dto";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import * as uuid from "uuid";
import { METALIST_UNIQUE_ID } from "../../services/metaHelper.service";

export interface IMetaListStore {
  id: string | number | symbol;
  label?: string;
  value?: string;
  filter: string;
  fieldId: string;
  itemId: string;
}

export interface IMetaListStoreResponse {
  _qty: number;

  [key: string | number | symbol]: any;
}

export interface IMetaListResponse {
  resultSet: any[];
  displayResultSet: any[];
  total: number;
  skip: number;
}

interface IMetaListGetParams {
  formId: string;
  fieldId: string;
  itemId: string;
  contextId: string;
  condition: MetaFormCondition;
  params: unknown;
  silentUpdate?: boolean;
  listItemId: string;
}

export class MetaListStateStore {
  total?: number;
  selectedItem?: number;
}

const { state, config } = createState(withEntities<IMetaListStore>());
const { state: displayState, config: displayConfig } = createState(withEntities<IMetaListStore>());

const listStateState = createState(withProps<MetaListStateStore>({}));

const store = new Store({ name: "listData", state, config });
const displayStore = new Store({ name: "listDisplayData", state: displayState, config: displayConfig });
const listStateStore = new Store({ name: "listState", state: listStateState.state, config: listStateState.config });

@Injectable({ providedIn: "root" })
export class MetaListRepository {
  listState$ = listStateStore;

  constructor(private readonly _http: HttpClient) {}

  public getData$(filter = "", fieldId: string, itemId: string): Observable<IMetaListStore[]> {
    return store.pipe(
      selectAllApply({ filterEntity: (e) => e.filter === filter && e.fieldId === fieldId && e.itemId === itemId }),
    );
  }

  public getDisplayData$(filter = "", fieldId: string, itemId: string): Observable<IMetaListStore[]> {
    return displayStore.pipe(
      selectAllApply({ filterEntity: (e) => e.filter === filter && e.fieldId === fieldId && e.itemId === itemId }),
    );
  }

  setListState(fieldId: string, payload: Partial<MetaListStateStore>) {
    listStateStore.update((s) => ({
      ...s,
      [fieldId]: {
        ...s[fieldId],
        ...payload,
      },
    }));
  }

  public async addData(
    response: { data: IMetaListResponse } & { params: IMetaListGetParams } & { listItemId: string },
  ) {
    const { data, params, listItemId } = response;
    const dataWithIds = data.resultSet.map((d) => ({
      ...d,
      id: uuid.v5(`${params.fieldId}-${d._id}-${params.condition.searchString}`, METALIST_UNIQUE_ID),
      fieldId: params.fieldId,
      itemId: params.itemId,
      filter: params.condition.searchString || "",
    }));
    const displayDataWithIds = data.displayResultSet.map((d, i) => ({
      ...d,
      ...d[listItemId],
      _id: data.resultSet[i]._id,
      id: uuid.v5(`${params.fieldId}-${data.resultSet[i]._id}-${params.condition.searchString}`, METALIST_UNIQUE_ID),
      fieldId: params.fieldId,
      itemId: params.itemId,
      filter: params.condition.searchString || "",
    }));
    store.update(upsertEntities(dataWithIds));
    displayStore.update(upsertEntities(displayDataWithIds));
    if (data.total !== undefined) {
      this.setListState(params.fieldId, {
        total: data.total,
      });
    }
  }

  public async removeData(fieldId: string, _id: any[], condition: MetaFormCondition) {
    store.update(
      deleteEntitiesByPredicate(
        (e) => e.id === uuid.v5(`${fieldId}-${_id}-${condition.searchString}`, METALIST_UNIQUE_ID),
      ),
    );
    displayStore.update(
      deleteEntitiesByPredicate(
        (e) => e.id === uuid.v5(`${fieldId}-${_id}-${condition.searchString}`, METALIST_UNIQUE_ID),
      ),
    );
  }

  public getData(p: IMetaListGetParams): Observable<IMetaListStore[]> {
    const param = new URLSearchParams();
    if (p.condition?.skip !== undefined) {
      param.set("skip", `${p.condition.skip}`);
    }
    if (p.condition?.take !== undefined) {
      param.set("take", `${p.condition.take}`);
    }
    if (p) {
      p.condition.params = p.params;
    }
    const url = `forms/${p.formId}/find?${param}`;

    if (p.condition.skip === 0 && !p.silentUpdate) {
      store.update(deleteEntitiesByPredicate((predicate) => predicate.fieldId === p.fieldId));
      displayStore.update(deleteEntitiesByPredicate((predicate) => predicate.fieldId === p.fieldId));
    }

    return this._http
      .post<IMetaListStore[]>(url, {
        ...p.condition,
        parentContextId: p.contextId,
      })
      .pipe(
        tap((data) =>
          this.addData({
            data: data as any,
            params: p,
            listItemId: p.listItemId,
          }),
        ),
      );
  }
}
