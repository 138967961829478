/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MetaFormService } from "../metaForm.service";

@Component({
  selector: "meta-form-base",
  template: ` @if (!metaFormService.formState.isLoading()) {
      @if (metaFormService.formState.form().children) {
        <form
          [formGroup]="metaFormService.formState.formGroup()"
          autocomplete="off"
          class="meta-dynamic-form-form"
          [style.display]="metaFormService.formState.form().showFillingLevel ? 'flex' : 'contents'"
          [style.margin-top.px]="metaFormService.formState.form().showFillingLevel ? 10 : 0"
          [style.flex]="metaFormService.formState.form().showFillingLevel ? 1 : 'inherit'"
        >
          <formly-form
            [form]="metaFormService.formState.formGroup()"
            [fields]="metaFormService.formState.form().children"
            [model]="metaFormService.formState.data()"
            [options]="metaFormService.options"
            (ngModelChange)="metaFormService.formState.data.set($event)"
          ></formly-form>
        </form>
      }
    } @else {
      <meta-loader [maParams]="{ size: 32, scale: 80 }"></meta-loader>
    }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaFormBaseComponent implements OnInit {
  constructor(public readonly metaFormService: MetaFormService) {}

  ngOnInit() {}
}
