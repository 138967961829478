/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { computed, Injectable, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MetaState } from "@meta/enums";
import { SocketUpdateFormDisplayValuesAcknowledgmentResponse, SocketUpdateFormDisplayValuesRequest } from "@meta/forms";
import * as _ from "lodash";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalRef, NzModalState } from "ng-zorro-antd/modal";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Socket } from "ngx-socket-io";
import * as deepDiff from "return-deep-diff";
import { BehaviorSubject, firstValueFrom, Observable, of, switchMap, take } from "rxjs";
import { catchError, filter, tap } from "rxjs/operators";
import { SendReportMailRequestDto } from "../../../../../api-interfaces/src/lib/api-interfaces/mail.dto";
import {
  modalActionTarget,
  modalLabelTarget,
  modalSublabelTarget,
} from "../../components/metaToolbar/metaToolbar.interface";
import { DirtyCheckService } from "../../services/dirtyCheckService";
import { MetaErrorHandlerService } from "../../services/metaErrorHandler.service";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaModalOptions, MetaModalService } from "../../services/metaModalService";
import { MetaForm, MetaFormData, MetaFormlyFormOptions } from "../metaForm/metaForm.interface";
import { MetaOpenModalOptions } from "./metaForm.interface";

interface GetFormOptions {
  route: string;
  formId: string;
  includeHidden?: boolean;
  returnForm?: boolean;
}

interface _GetFormDataOptions {
  route?: string;
  formId: string;
  itemId: string;
  clearData?: boolean;
  externalData?: Record<string, any>;
  params?: Record<string, any>;
  updateDisplayValues?: boolean;
}

interface GetFormDataOptions {
  itemId: string;
  silent?: boolean;
}

@Injectable()
export class MetaFormService {
  public pdfPreviewOptions: Record<string, any> = {};
  /**
   * Represents the formly configuration
   */
  public options: MetaFormlyFormOptions = {
    formState: {
      form: signal({} as any),
      formGroup: signal<UntypedFormGroup>(null),
      data: signal<Record<string, any>>({} as any),
      data$: of(null),
      oldData: signal<Record<string, any>>({} as any),
      oldData$: of(null),
      displayData: signal<Record<string, any>>({} as any),
      displayData$: of(null),
      metaData: signal<Record<string, any>>({} as any),
      metaData$: of(null),
      externalData: signal<Record<string, any>>({} as any),
      isLoading: signal<boolean>(false),
      formId: null,
      itemId: signal<string | null>(null),
      state: signal<MetaState>(MetaState.none),
      state$: of(null),
      fieldFilter$: new BehaviorSubject<string>(null),
      selectedItems: signal<any>({}),
      selectedItems$: of(null),
      modalRef: null,
      loadedAsModal: false,
      loadedAsSubform: false,
      loadedAsWidget: false,
      formRoute: signal<string>(null),
      onFormDataReady: () => of(null),
      language: new BehaviorSubject<string>(null),
      pdfPreviewOptions: new BehaviorSubject<any>(this.pdfPreviewOptions),
      pendingUpdates: [],
      versionsData: signal([]),
      version: signal(null),
      workflows: signal({ workflows: [], executions: [] }),
      error: signal(null),
      isDummyForm: false,
      initializationLoaded: signal(false),
    },
  };
  public formState = this.options.formState;

  constructor(
    private readonly _http: HttpClient,
    private readonly _route: ActivatedRoute,
    private readonly _nzMessageService: NzMessageService,
    private readonly _notificationService: NzNotificationService,
    private readonly _metaErrorHandlerService: MetaErrorHandlerService,
    private readonly _socketService: Socket,
    private readonly _dirtyCheckService: DirtyCheckService,
    private readonly _modal: MetaModalService,
    private readonly _helperService: MetaHelperService,
    private readonly _socket: Socket,
  ) {
    this.updateDisplayValues = _.debounce(this.updateDisplayValues, 250);

    this._socket
      .fromEvent("WORKFLOW_UPDATE")
      .pipe(filter((e: any) => e.formId === this.options.formState.formId))
      .pipe(takeUntilDestroyed())
      .subscribe((e) => {
        if (e.done) {
          this.getFormData({
            itemId: this.options.formState.itemId(),
            silent: true,
          }).catch(console.error);
        } else {
          this.updateWorkflows();
        }
      });
  }

  /**
   * Retrieves a form using the provided options.
   *
   * @param {FormOptions} options - The options for getting the form.
   * @param {string} options.formId - The ID of the form to be retrieved.
   * @param {boolean} [options.includeHidden] - Specifies whether to include hidden form fields.
   * @param {string} [options.route] - The route of the form, if available.
   * @returns {Observable<MetaForm>} An observable that emits the retrieved form.
   * @throws {Error} If the options do not include a valid formId.
   */
  async getForm(options: GetFormOptions): Promise<any> {
    if (!options.formId) {
      throw new Error("You need a formId to get a form.");
    }
    let params = new HttpParams();
    if (options.includeHidden) {
      params.set("includeHidden", options.includeHidden?.toString());
    }
    if (options.route) {
      const [r, p] = options.route.slice(1).split("?");
      const oldParams = new URLSearchParams(p);
      if (oldParams.has("sig")) {
        params = params.set("sig", oldParams.get("sig") || "");
      }
    }

    if (options.returnForm) {
      return this._http.get<MetaForm>(`forms/${options.formId}`, { params }).pipe(
        catchError((err) =>
          this._metaErrorHandlerService.handleError(err).pipe(
            switchMap(() => of(null)), // Return a safe observable to continue the stream
            tap((value) => {
              if (value) {
                this._nzMessageService.error(value);
              }
            }),
          ),
        ),
      );
    } else {
      await firstValueFrom(
        this._http.get<MetaForm>(`forms/${options.formId}`, { params }).pipe(
          tap((res) => {
            if (res) {
              this.formState.form.set(res);
            }
          }),
          catchError((err) => {
            if (err instanceof HttpErrorResponse) {
              this.formState.error.set(err);
            }
            return this._metaErrorHandlerService.handleError(err).pipe(
              switchMap(() => of(null)), // Return a safe observable to continue the stream
              tap((value) => {
                if (value) {
                  this._nzMessageService.error(value);
                }
              }),
            );
          }),
        ),
      );
    }
  }

  /**
   * Asynchronously retrieves form data based on the provided options.
   *
   * @param {GetFormDataOptions} options - The options to configure the retrieval of form data.
   * @property {boolean} options.silent - If set to true, the form data retrieval will not show loading indicators.
   *
   * @return {Promise<void>} - A promise that resolves once the form data has been retrieved.
   */
  async getFormData(options: GetFormDataOptions): Promise<void> {
    this.formState.isLoading.set(true);
    if (options.itemId !== this.formState.itemId()) {
      this.formState.itemId.set(options.itemId);
    }
    if (this.formState.itemId() === "create" || options.itemId === "create") {
      this._resetFormState();
      await this.getInitialization(this._route.snapshot.queryParams);
      this.formState.state.set(MetaState.editing);
    } else {
      if (!options.silent) {
        this.formState.isLoading.set(true);
      }
      this.loadWorkflows(this.formState.formId, options.itemId);
      await this._getFormData({
        route: this.formState.formRoute(),
        formId: this.formState.formId,
        itemId: options.itemId,
        externalData: this.formState.externalData(),
      });
      this.formState.state.set(MetaState.none);
    }
    this.formState.isLoading.set(false);
  }

  loadWorkflows(formId: string, itemId: string) {
    firstValueFrom(this._http.get(`forms/${formId}/workflows/${itemId}`))
      .then((workflows) => {
        this.formState.workflows.set(workflows as any);
      })
      .catch(() => {
        this.formState.workflows.set({ executions: [], workflows: [] });
      });
  }

  /**
   * Update form data.
   *
   * @param {Object} options - The options for updating form data.
   * @param {string} options.formId - The ID of the form.
   * @param {string} options.itemId - The ID of the item.
   * @param {Object} options.oldData - The old data to update.
   * @param {Object} options.newData - The new data to update.
   * @param {boolean} [options.silentUpdate] - Indicates whether to perform a silent update. Default is false.
   * @param {boolean} [options.completeEditMode] - Indicates whether to complete the edit mode. Default is false.
   * @param {string} [options.state] - The state of the form.
   * @param {boolean} [options.publish] - Indicates whether to publish the changes. Default is false.
   *
   * @returns {Observable<any>} - An Observable that emits the result of updating the form data.
   */
  updateFormData(options: {
    formId: string;
    itemId: string;
    oldData: Record<string, any>;
    newData: Record<string, any>;
    silentUpdate?: boolean;
    completeEditMode?: boolean;
    state?: MetaState;
    publish?: boolean;
  }): Observable<any> {
    // TODO: Only send changed data to backend
    const diff = deepDiff(options.oldData, options.newData, true);
    if (!diff && !options.publish && !options.silentUpdate) {
      this.options.formState.state.set(MetaState.canceled);
      return of(false);
    }
    if (options.state === MetaState.editing || !options.completeEditMode) {
      this.options.formState.state.set(MetaState.saving);
    }
    // Convert "" to null
    Object.keys(options.newData).forEach((key) => {
      if (options.newData[key] === "") {
        options.newData[key] = null;
      }
    });

    let url, message;
    if (options.itemId !== "create") {
      url = `forms/${options.formId}/handler/${encodeURIComponent(options.itemId)}`;
      message = `Datensatz gespeichert`;
      return this._http.patch(url, options.newData, { params: { publish: options.publish } }).pipe(
        tap(
          (res: MetaFormData) => {
            if (options.completeEditMode) {
              this.options.formState.state.set(MetaState.saved);
              this.options.formState.displayData.set(res.displayResult);
            } else {
              this.options.formState.state.set(MetaState.editing);
            }
            this.options.formState.data.set(res.result);
            this.options.formState.metaData.set(res.meta);
            if (!options.silentUpdate) {
              this._nzMessageService.success(message);
            }
          },
          (err) => {
            this.options.formState.state.set(MetaState.editing);
            this._metaErrorHandlerService
              .handleError(err)
              .pipe(take(1))
              .subscribe({
                error: (value) => {
                  this._nzMessageService.error(value);
                },
              });
          },
        ),
      );
    } else {
      url = `forms/${options.formId}/handler`;
      message = `Datensatz erstellt`;
      return this._http.post(url, options.newData, { params: { publish: options.publish } }).pipe(
        tap(
          (res: MetaFormData) => {
            if (options.completeEditMode) {
              this.options.formState.state.set(MetaState.saved);
              this.options.formState.displayData.set(res.displayResult);
            } else {
              this.options.formState.state.set(MetaState.editing);
            }
            this.options.formState.data.set(res.result);
            if (!options.silentUpdate) {
              this._nzMessageService.success(message);
            }
          },
          (err) => {
            this.options.formState.state.set(MetaState.editing);
            this._metaErrorHandlerService
              .handleError(err)
              .pipe(take(1))
              .subscribe({
                error: (value) => {
                  this._nzMessageService.error(value);
                },
              });
          },
        ),
      );
    }
  }

  /**
   * Retrieves the initialization data for a form.
   *
   * @param {string} formId - The ID of the form to initialize.
   * @param {Object} [params={}] - Additional parameters to include in the initialization request.
   * @param {boolean} [clearData=true] - Indicates whether existing form data should be cleared before setting the new initialization data.
   *
   * @return {void}
   */
  public async getInitialization(params = {}): Promise<void> {
    await firstValueFrom(
      this._http.post<MetaFormData>(`forms/${this.formState.formId}/initialize`, params).pipe(
        tap((res) => {
          if (Object.keys(res.result).length > 0) {
            this.formState.data.set({
              ...this.formState.data(),
              ...res.result,
            });
          }
          if (Object.keys(res.displayResult).length > 0) {
            this.formState.displayData.set(res.displayResult);
          }
          this.formState.initializationLoaded.set(true);
        }),
        catchError((err) =>
          this._metaErrorHandlerService.handleError(err).pipe(
            switchMap(() => of(null)),
            tap((value) => this._nzMessageService.error(value)),
          ),
        ),
      ),
    );
  }

  private _resetFormState() {
    this.formState.metaData.set({} as any);
    this.formState.data.set({} as any);
    this.formState.oldData.set({} as any);
    this.formState.selectedItems.set(null);
    this.formState.pendingUpdates = [];
    this.formState.versionsData.set([]);
    this.formState.version.set(null);
    this.formState.workflows.set({ workflows: [], executions: [] });
  }

  public getInitializationObject(
    formId: string,
    params = {},
    clearData: boolean = true,
    excludeNotResettable: boolean = false,
  ) {
    return firstValueFrom(this._http.post(`forms/${formId}/initialize`, { excludeNotResettable, params }));
  }

  public async runPublish() {
    await firstValueFrom(this._http.post(`forms/${this.formState.formId}/publish/${this.formState.itemId()}`, {}))
      .then(() => {
        this._nzMessageService.success("Erfolgreich veröffentlicht.");
      })
      .catch((e) => {
        this._nzMessageService.error("Fehler beim veröffentlichen");
      });
  }

  public async runWorkflow(id: string) {
    await firstValueFrom(
      this._http.post(`forms/${this.formState.formId}/run-workflow/${this.formState.itemId()}`, {
        id,
      }),
    )
      .then(() => {
        this._nzMessageService.success("Erfolgreich.");
      })
      .catch((e) => {
        this._nzMessageService.error("Fehler.");
      });
  }

  /**
   * Opens a modal window.
   * @param {MetaOpenModalOptions} options - The options for the modal window.
   * @returns {Promise<NzModalRef>} The modal reference.
   */
  async openModal(options: MetaOpenModalOptions): Promise<NzModalRef> {
    let size: string;
    switch (options.size) {
      case "small":
        size = "30%";
        break;
      case "large":
        size = "60%";
        break;
      case "max":
        size = "92%";
        break;
      case "default":
        size = "45%";
        break;
      default:
        size = options.size;
    }

    const { MetaFormComponent } = await import("./metaForm.component");
    const params: MetaModalOptions = {
      nzContent: MetaFormComponent,
      nzTitle: options.title,
      nzMaskClosable: false,
      nzWidth: size,
      nzFooter: null,
      nzClassName: `ma-subform`,
      nzWrapClassName: "ma-subform-wrapper",
      nzOkText: options.okText,
      nzBodyStyle: {
        position: "relative",
        display: "flex",
        "flex-direction": "column",
        ...options.bodyStyle,
      },
      nzClosable: true,
      nzOnCancel: () => {
        if (modalRef.state !== NzModalState.CLOSED && this._dirtyCheckService.isDirty(modalRef)) {
          return firstValueFrom(this._dirtyCheckService.showDirtyAlert(modalRef));
        }
        return true;
      },
      nzCloseOnNavigation: true,
      nzComponentParams: {
        title: options.title,
        maFormId: options.formId,
        maExternalData: options.data || {},
        maItemId: options.itemId || null,
        maToolbarActionTarget: `${modalActionTarget}-${options.formId}`,
        maToolbarLabelTarget: `${modalLabelTarget}-${options.formId}`,
        maToolbarSubLabelTarget: `${modalSublabelTarget}-${options.formId}`,
        maEditing: !!options.editing,
        maCloseModalAfterSave: options.closeModalAfterSave,
        maCloseModalAfterCancel: options.closeModalAfterCancel,
        ...options.componentParams,
      },
    };

    let modalRef: NzModalRef;
    if (options.type === "dialog") {
      modalRef = this._modal.create({
        ...params,
        nzClassName: `ma-subform ant-modal-confirm`,
      });
    } else {
      modalRef = this._modal.create(params);
      this._helperService.resizeContainer(modalRef.containerInstance.modalElementRef.nativeElement.id);
    }
    return modalRef;
  }

  public async sendReport(opts: SendReportMailRequestDto) {
    try {
      await firstValueFrom(
        this._http.post(`mail/send-report`, {
          ...opts,
        }),
      );
      this._notificationService.success("Versand erfolgreich", "Das Dokument wurde erfolgreich versand.");
    } catch (e) {
      this._notificationService.error("Versand fehlgeschlagen", "Es ist ein Fehler beim versenden aufgetreten.");
    }
  }

  public async print(formId: string, itemId: string, event: MouseEvent) {
    return await new Promise<void>((resolve) => {
      const formElement = (event.target as HTMLElement).closest("meta-form");
      const frames = formElement?.querySelectorAll("iframe");
      if (frames && frames.length > 0) {
        const frame = Array.from(frames).find((elem) => {
          const styles = window.getComputedStyle(elem);
          return !(styles.display === "none" || styles.visibility === "hidden");
        });
        if (frame) {
          frame.contentWindow.print();
        }
      }
      resolve();
    });
  }

  /**
   * Gets the available data versions if the form supports this
   *
   * @param pageId
   * @param itemId
   */
  public async getVersions(pageId: string, itemId: string): Promise<any[]> {
    return await firstValueFrom(this._http.get<any[]>(`shared/versions?pageId=${pageId}&itemId=${itemId}`));
  }
  public readonly activePublishWorkflows = computed(() => {
    const w = this.formState.workflows();
    if (!w) return [];
    return _.flatten(
      w.workflows
        .filter((e) => e.publishable)
        .map((wf) => w.executions?.filter((e) => e.workflow.id === wf.id && e.active) || []),
    );
  });

  public updateDisplayValues() {
    const req: SocketUpdateFormDisplayValuesRequest = {
      formId: this.formState.formId,
      patchData: this.formState.data(),
      additionalData: this.formState.externalData(),
      isEdit: this.formState.state() >= MetaState.editing && this.formState.state() <= MetaState.saving,
      ctx: this.formState.metaData()?._ctx,
    };

    if (req.patchData !== null && req.patchData !== undefined) {
      this._socketService.emit(
        "formUpdateDisplayValues",
        {
          ...req,
          ctx: this.formState.data()._ctx,
        },
        (res: SocketUpdateFormDisplayValuesAcknowledgmentResponse) => {
          if (res && res.displayResult) {
            this.formState.displayData.set(res.displayResult);
          }
        },
      );
    }
  }

  /**
   * Retrieves form data for a specific form and item.
   *
   * @param {_GetFormDataOptions} options - The options to retrieve form data.
   * @param {string} options.formId - The ID of the form.
   * @param {string} options.itemId - The ID of the item.
   * @param {Object} [options.params] - Additional parameters to include in the request.
   * @param {string} [options.route] - The route to append to the request URL.
   * @param {Object} [options.additionalData] - Additional data to include in the request body.
   *
   * @throws {Error} If formId or itemId is not provided.
   *
   * @returns {Observable<MetaFormData>} An observable that resolves to the retrieved form data.
   */
  private async _getFormData(options: _GetFormDataOptions): Promise<void> {
    // TODO: options.itemId is not needed for dashboard widgets
    if (!options.formId) {
      return;
      //throw new Error("You need a formId to get form data.");
    }

    if (!options.itemId) {
      options.itemId = null;
    }

    // Reset error
    this.formState.error.set(null);

    let params = new HttpParams();
    Object.keys(options.params || {}).forEach((key) => {
      params = params.set(key, options.params[key]);
    });

    if (options.route) {
      const [r, p] = options.route.slice(1).split("?");
      const oldParams = new URLSearchParams(p);
      if (oldParams.has("sig")) {
        params = params.set("sig", encodeURIComponent(oldParams.get("sig")));
      }
    }
    if (options.externalData) {
      params = params.set("data", JSON.stringify(options.externalData));
    }

    await firstValueFrom(
      this._http
        .get<MetaFormData>(`forms/${options.formId}/handler/${encodeURIComponent(options.itemId)}`, {
          params,
        })
        .pipe(
          tap((res) => {
            if (res) {
              this.formState.data.set(res.result);
              this.formState.displayData.set(res.displayResult);
              this.formState.metaData.set(res.meta);
              this.formState.itemId.set(options.itemId);
              this.formState.formGroup().markAsPristine();
            }
          }),
          catchError((err) => {
            return this._metaErrorHandlerService.handleError(err).pipe(
              catchError((processedError) => {
                this.formState.error.set(err);
                return of({ errorMessage: processedError });
              }),
            );
          }),
        ),
    );
  }

  private async _runWorkflow(formId: string, workflowId: string, itemId: any, payload: any, nodeId = "publish") {
    return await firstValueFrom(
      this._http.post<{ id: string }>(`workflow/execute-form`, {
        formId,
        itemId: Array.isArray(itemId) ? itemId.join(",") : itemId,
        workflowId,
        payload,
        nodeId,
      }),
    );
  }

  public updateWorkflows() {
    this.loadWorkflows(this.formState.formId, this.formState.itemId());
  }
}
