/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

export interface IUserFeatures {
  chatbot: boolean;
  task: boolean;
  calendar: boolean;
  admin: {
    "event-monitor": boolean;
    "cron-monitor": boolean;
    integrations: boolean;
    settings: boolean;
    "show-admin": boolean;
  };
}
