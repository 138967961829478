/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2020
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2020
 */

import { Type } from "class-transformer";

export class MenuResponseItem {
  id: string;

  parentId?: string;

  position: number;

  icon: string;

  label: string;

  path: string;

  description?: string;

  tags?: string[];
}

export class MenuResponse {
  entryId: string;
  @Type(() => MenuResponseItem)
  items: MenuResponseItem[];
}
