/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
  ViewContainerRef,
} from "@angular/core";
import { MetaMediaLibraryService } from "./metaMediaLibrary.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { NzModalRef } from "ng-zorro-antd/modal";
import * as moment from "moment";
import { IMetaMediaLibraryFile } from "./interfaces";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { NzImageService } from "ng-zorro-antd/image";

@Component({
  styleUrls: ["./metaMediaLibraryFilePreview.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div [ngClass]="{ 'has-sidebar': this.showInfo }" class="preview-wrapper">
      <div class="preview-container" [ngClass]="{ loading: isLoading }">
        <div class="preview-container-loading">
          <i class="fas fa-spinner-third fa-spin"></i>
        </div>
        <ng-container *ngIf="resourceUrl">
          <ng-container *ngIf="viewer === 'image'">
            <img
              class="preview-image"
              alt=""
              loading="lazy"
              (error)="handleImageError($event)"
              (load)="handleImageLoad($event)"
              [src]="resourceUrl"
              (click)="nzImageService.preview([{ src: $any($event.target).src, alt: '' }])"
            />
          </ng-container>
          <ng-container *ngIf="viewer === 'video'">
            <meta-media-library-player [size]="size" [autoplay]="autoplay" [src]="resourceUrl" />
          </ng-container>
          <ng-container *ngIf="viewer === 'audio'">
            <meta-media-library-player [size]="size" type="audio" [autoplay]="autoplay" [src]="resourceUrl" />
          </ng-container>
          <ng-container *ngIf="viewer === 'iframe'">
            <iframe (load)="handleImageLoad($event)" [src]="resourceUrl"></iframe>
          </ng-container>
          <ng-container *ngIf="viewer === 'eml'">
            <ng-container *ngIf="additionalData$ | async as data">
              <div class="email-preview">
                <h3>{{ "mail.subject" | translate }}: {{ data.subject }}</h3>
                <div class="email-info">
                  <div>
                    <b>{{ "mail.from" | translate }}:</b>
                  </div>
                  <div [innerHTML]="data.from?.html"></div>
                </div>
                <div class="email-info">
                  <div>
                    <b>{{ "mail.to" | translate }}:</b>
                  </div>
                  <div [innerHTML]="data.to?.html"></div>
                </div>
                <div *ngIf="data.cc?.html" class="email-info">
                  <div>
                    <b>{{ "mail.cc" | translate }}:</b>
                  </div>
                  <div [innerHTML]="data.cc?.html"></div>
                </div>
                <div *ngIf="data.replyTo?.html" class="email-info">
                  <div>
                    <b>{{ "mail.replyTo" | translate }}:</b>
                  </div>
                  <div [innerHTML]="data.replyTo?.html"></div>
                </div>
                <div *ngIf="data.attachments?.length > 0" class="email-info">
                  <div>
                    <b>{{ "mail.attachments" | translate }}:</b>
                  </div>
                  <div class="email-attachments">
                    <a target="_blank" [href]="a.link" *ngFor="let a of data.attachments">{{ a.filename }}</a>
                  </div>
                </div>
                <div class="email-content">
                  <iframe
                    *ngIf="size === 'default'"
                    sandbox="allow-popups"
                    (load)="handleImageLoad($event)"
                    [src]="resourceUrl"
                  ></iframe>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="viewer === 'unsupported' || (!resourceUrl && !isLoading)">
          <div class="not-supported-container">
            <i class="fal fa-{{ icon }}"></i>
            <span>Vorschau nicht möglich</span>
            <span *ngIf="error">{{ error }}</span>
          </div>
        </ng-container>
      </div>
      <meta-media-library-file-info
        (fileDeleted)="fileDeleted($event)"
        [hidePreview]="true"
        [hidePreviewButton]="true"
        *ngIf="showInfo"
        [file]="file"
      >
      </meta-media-library-file-info>
    </div>
  `,
  selector: "meta-media-library-file-preview",
})
export class MetaMediaLibraryFilePreviewComponent implements OnChanges {
  @Input()
  public file: IMetaMediaLibraryFile;
  @Input()
  public size: "default" | "small" = "default";
  @Input()
  public showInfo = false;
  @Input()
  public autoplay = false;
  @Input()
  public enableOfficeEmbed = true;
  public resourceUrl: SafeResourceUrl;
  public additionalData$: Observable<Record<string, any>>;

  public viewer: "image" | "iframe" | "video" | "audio" | "unsupported" | "eml";
  public icon: string;
  public isLoading = true;
  public error: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly sanitizer: DomSanitizer,
    public readonly changeDetectorRef: ChangeDetectorRef,
    @Optional()
    public readonly modalRef: NzModalRef,
    public readonly metaMediaLibraryService: MetaMediaLibraryService,
    public nzImageService: NzImageService,
    private viewContainerRef: ViewContainerRef,
  ) {}

  handleImageError($event: ErrorEvent) {
    ($event.target as HTMLElement).classList.add("error");
    this.isLoading = false;
    this.error = String($event.error);
    this.changeDetectorRef.detectChanges();
  }

  handleImageLoad($event: Event) {
    ($event.target as HTMLElement).classList.remove("error");
    this.isLoading = false;
    this.changeDetectorRef.detectChanges();
  }

  public stop() {
    const ele = this.viewContainerRef.element.nativeElement as HTMLElement;
    ele?.querySelector<HTMLAudioElement | HTMLVideoElement>("video,audio")?.pause();
  }

  async load() {
    this.isLoading = true;
    this.error = null;
    this.icon = this.metaMediaLibraryService.getIcon(this.file);
    const ext = this.file.name.split(".").pop().toLowerCase();
    this.resourceUrl = null;
    this.additionalData$ = null;
    this.changeDetectorRef.markForCheck();
    try {
      if (
        ["doc", "docx", "xlsx", "xls", "csv", "ppt", "odt", "rtf", "html", "ppt", "pptx"].includes(ext) &&
        this.enableOfficeEmbed
      ) {
        const { url } = await this.metaMediaLibraryService.getPublicUrl(this.file, {
          expiresOn: moment().add(15, "minutes").toDate(),
        });
        const officeUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`;
        this.resourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(officeUrl);
        this.viewer = "iframe";
      } else if (["eml", "emltpl"].includes(ext)) {
        this.viewer = "eml";
        const emlUrl = `/api/v2/media-library/eml/${this.file.id}/preview`;
        this.resourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(emlUrl);
        this.additionalData$ = this.httpClient.get(`media-library/eml/${this.file.id}`).pipe(
          tap(() => {
            if (this.size === "small") {
              this.isLoading = false;
            }
          }),
        );
      } else if (this.file.mime === "application/pdf") {
        this.resourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`/api/v2/file/${this.file.id}/download`);
        this.viewer = "iframe";
      } else if (this.file.mime.split("/")[0] === "image") {
        this.viewer = "image";
        const { url } = await this.metaMediaLibraryService.getPublicUrl(this.file, {
          expiresOn: moment().add(10, "minutes").toDate(),
        });
        this.resourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      } else if (this.file.mime.split("/")[0] === "video") {
        this.isLoading = false;
        this.viewer = "video";
        const { url } = await this.metaMediaLibraryService.getPublicUrl(this.file, {
          expiresOn: moment().add(8, "hours").toDate(),
        });
        this.resourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      } else if (this.file.mime.split("/")[0] === "audio") {
        this.isLoading = false;
        this.viewer = "audio";
        const { url } = await this.metaMediaLibraryService.getPublicUrl(this.file, {
          expiresOn: moment().add(8, "hours").toDate(),
        });
        this.resourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      } else {
        this.isLoading = false;
        this.viewer = "unsupported";
      }
    } catch (e) {
      this.isLoading = false;
      if (e instanceof HttpErrorResponse) {
        this.error = `${e.status} ${e.statusText}`;
      }
      this.viewer = "unsupported";
    }
    this.changeDetectorRef.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["file"] && changes["file"].previousValue?.id !== changes["file"].currentValue?.id) {
      this.load();
    }
  }

  fileDeleted($event: IMetaMediaLibraryFile) {
    if (this.showInfo && this.modalRef) {
      this.modalRef.close();
    }
  }
}
