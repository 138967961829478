/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { HttpClient } from "@angular/common/http";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  Pipe,
  PipeTransform,
  ViewChild,
  ViewEncapsulation,
  WritableSignal,
  signal,
} from "@angular/core";
import type { ISchedulerColumn, ISchedulerDatasourceResponse, ISchedulerItem } from "@meta/forms";
import { MetaEventService } from "../../services/metaEvents.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { FormlyModule } from "@ngx-formly/core";
import { CommonModule } from "@angular/common";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaGanttService } from "./metaGantt.service";
import type { GanttConfigOptions, GanttStatic, GanttTemplates } from "@meta/dhtmlx-gantt";

import { FormsModule } from "@angular/forms";
import { MetaButtonModule, MetaFieldWrapperModule, MetaGroupModule, MetaLoaderModule } from "@meta/ui";
import * as moment from "moment";
import { AutocompleteDataSourceItem, NzAutocompleteModule } from "ng-zorro-antd/auto-complete";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import { BehaviorSubject, firstValueFrom, Observable, of, Subject, switchMap, takeUntil } from "rxjs";
import { debounceTime, filter, map, tap } from "rxjs/operators";
import { MetaButton } from "../metaButton/metaButton.component";
import { MetaColumnModule } from "../metaColumn/metaColumn.component";
import { MetaDatepickerModule } from "../metaDatepicker/metaDatepicker.component";
import { MetaRowModule } from "../metaRow/metaRow.component";

export class MetaGantt {
  draggable = true;
  itemColumnLabels: [];
  taskNameSingular = "Task";
  taskNamePlural = "Tasks";
  groupNameSingular = "Gruppe";
  groupNamePlural = "Gruppen";
  itemGroupField?: string;
  itemGroupLabelField?: string;
  height?: string | number;
}

export type MetaGanttGetDataResponse = ISchedulerDatasourceResponse;

export type MetaGanttGetDataResponseItems = ISchedulerItem;

@Pipe({ name: "filterSelectData", pure: true, standalone: true })
export class FilterSelectDataPipe implements PipeTransform {
  constructor(private readonly http: HttpClient) {}

  transform(
    filter: Record<string, any> & { loaded$: Subject<any[]> },
    formId: string,
    ganttId: string,
    onLoaded?: (filter: any) => void,
  ): Observable<any[]> {
    return this.http.get<any[]>(`forms/scheduler/${formId}/${ganttId}/filters/${filter.id}`).pipe(
      tap((e) => {
        filter.loaded$.next(e);
        filter.loaded$.complete();
      }),
    );
  }
}

@MetaUnsubscribe()
@Component({
  selector: "meta-gantt",
  template: `
    <meta-group [maParams]="{ flex: 1 }" [style.flex]="1">
      <meta-row>
        <meta-column>
          <div class="toolbar-wrapper">
            <div class="actions">
              <meta-button [maParams]="paramCreateTaskButton"></meta-button>
            </div>
            <div class="scheduler-filter">
              @for (filter of filters$ | async; track $index) {
                @switch (filter.type) {
                  @case ("select") {
                    <meta-field-wrapper>
                      <nz-select
                        [style.min-width.px]="200"
                        [(ngModel)]="filterState[filter.id]"
                        (ngModelChange)="filterValue$.next(filterValue$.value)"
                        [nzPlaceHolder]="filter.label"
                        nzAllowClear
                      >
                        @for (s of filter | filterSelectData: formState.formId : id | async; track s.value) {
                          <nz-option [nzValue]="s.value" [nzLabel]="s.label"></nz-option>
                        }
                      </nz-select>
                    </meta-field-wrapper>
                  }
                }
              }
              <meta-field-wrapper>
                <input
                  placeholder="Suchen"
                  [style.min-width.px]="300"
                  nz-input
                  [ngModel]="searchValue$ | async"
                  (ngModelChange)="searchValue$.next($event)"
                  [nzAutocomplete]="auto"
                />
              </meta-field-wrapper>
              <nz-autocomplete [nzDefaultActiveFirstOption]="false" nzBackfill #auto>
                <nz-auto-optgroup *ngFor="let group of searchAutocompleteData$ | async" [nzLabel]="groupTitle">
                  <ng-template #groupTitle>
                    <span>
                      {{ group.title }}
                    </span>
                  </ng-template>
                  <nz-auto-option *ngFor="let option of group.children" [nzLabel]="option.label" [nzValue]="option">
                    <div style="white-space: break-spaces">{{ option.label }}</div>
                  </nz-auto-option>
                </nz-auto-optgroup>
              </nz-autocomplete>
              <meta-button [maParams]="paramFullScreenButton"></meta-button>
            </div>
          </div>
        </meta-column>
      </meta-row>
      <div #metaGantt class="gantt-chart" [style.height.px]="ma.height" [hidden]="!columnsSet()"></div>
      <meta-loader *ngIf="!columnsSet()"></meta-loader>
    </meta-group>
  `,
  styleUrls: [
    "../../../../../../node_modules/@meta/dhtmlx-gantt/codebase/dhtmlxgantt.css",
    "./metaGantt.component.less",
  ],
  providers: [MetaGanttService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    FormlyModule,
    FormsModule,
    MetaButtonModule,
    MetaColumnModule,
    MetaDatepickerModule,
    NzSelectModule,
    MetaFieldWrapperModule,
    NzAutocompleteModule,
    MetaRowModule,
    FilterSelectDataPipe,
    MetaGroupModule,
    NzInputModule,
    MetaLoaderModule,
  ],
})
export class MetaGanttComponent extends MetaComponentBase implements OnInit, AfterViewInit {
  @ViewChild("metaGantt") metaGantt: ElementRef<HTMLElement>;
  public gantt: GanttStatic;
  public data: MetaGanttGetDataResponse;
  public filters$: Observable<any[]> = of([]);
  public readonly filtersReady$ = new Subject();
  public filterState: Record<string, any> = {};
  public data$: Observable<MetaGanttGetDataResponse> = of(null);
  public readonly searchValue$ = new BehaviorSubject<string>("");
  public readonly filterValue$ = new BehaviorSubject<string>("");
  public searchAutocompleteData$: Observable<{ title: string; children: AutocompleteDataSourceItem[] }[]> = of([]);
  public currentItemId: string = null;
  public columnsSet: WritableSignal<boolean> = signal(false);
  public config: Partial<GanttConfigOptions> = {
    duration_unit: "minute",
    duration_step: 1,
    min_duration: 1,
    preserve_scroll: true,
    grid_resize: true,
    min_column_width: 10,
    bar_height: 26,
    row_height: 40,
    autoscroll: true,
    smart_rendering: true,
    scale_height: 60,
    details_on_dblclick: true,
    order_branch: true,
  };
  public templates: Partial<GanttTemplates> = {
    parse_date: function (date) {
      return new Date(date);
    },
    format_date: function (date) {
      return date.toISOString();
    },
  };
  public paramCreateTaskButton: MetaButton = null;
  public paramFullScreenButton: MetaButton = null;

  constructor(
    public metaGanttService: MetaGanttService,
    private readonly _metaEventService: MetaEventService,
  ) {
    super();
    super.maParams = new MetaGantt();
  }

  get ma(): MetaGantt {
    return super.ma;
  }

  ngOnInit() {}

  public async ngAfterViewInit() {
    const { gantt, Gantt } = await import("@meta/dhtmlx-gantt");
    this.gantt = gantt;
    this.gantt.plugins({
      grouping: true,
      drag_timeline: true,
      multiselect: true,
      fullscreen: true,
    });
    this.gantt.i18n.setLocale("de");
    this.gantt.config = { ...this.gantt.config, ...this.config };
    this.gantt.templates = { ...this.gantt.templates, ...this.templates };
    this.setConfig();
    this.gantt.attachEvent("onTaskDblClick", (taskId: string, event: any, test: any) => {
      const task = this.gantt.getTask(taskId);
      if (task.$rendered_type === "task") {
        this.onTaskClick(taskId);
      } else if (task.$rendered_type === "project") {
        this.onProjectClick(taskId);
      }
    });

    this.gantt.attachEvent("onBeforeGanttRender", function () {
      let timelineRange = gantt.getSubtaskDates();
      if (timelineRange.start_date && timelineRange.end_date) {
        gantt.config.start_date = timelineRange.start_date;
        gantt.config.end_date = moment(timelineRange.end_date).add(1, "hour").toDate();
      }
    });

    this.gantt.attachEvent("onBeforeTaskUpdate", (eventId: string, ...args: any) => {
      const event = this.data.items.find((e) => String(e.id) === String(eventId));
      if (event) {
        this.metaGanttService.handleEventChanged(
          {
            formId: this.formState.formId,
            ganttId: this.id,
            contextId: this.model._ctx,
          },
          event,
        );
      }
    });

    this.gantt.attachEvent("onAfterLinkDelete", (id, link) => {
      const source = this.data.items.find((e) => String(e.id) === String(link.source));
      const target = this.data.items.find((e) => String(e.id) === String(link.target));
      this.metaGanttService.handleLinkRemove(
        {
          formId: this.formState.formId,
          ganttId: this.id,
          contextId: this.model._ctx,
        },
        id,
        source,
        target,
      );
    });

    this.gantt.attachEvent("onBeforeRowDragEnd", (id, parent, tindex) => {
      const item = this.data.items.find((e) => String(e.id) === String(id));

      this.metaGanttService.handleSortChange(
        {
          formId: this.formState.formId,
          ganttId: this.id,
          contextId: this.model._ctx,
        },
        item,
        tindex,
        this.gantt.getGlobalTaskIndex(id),
      );
    });

    this.gantt.attachEvent("onAfterLinkAdd", (id, link) => {
      const source = this.data.items.find((e) => String(e.id) === String(link.source));
      const target = this.data.items.find((e) => String(e.id) === String(link.target));
      this.metaGanttService.handleLinkAdd(
        {
          formId: this.formState.formId,
          ganttId: this.id,
          contextId: this.model._ctx,
        },
        source,
        target,
        link.type,
      );
      //any custom logic here
    });

    this.gantt.init(this.metaGantt.nativeElement, new Date(0));
    this.filtersReady$.next(true);
    this.filtersReady$.complete();
    this.refresh();

    this.filters$ = this.metaGanttService.getFilters({ formId: this.formState.formId, ganttId: this.id }).pipe(
      tap((filters) => {
        Promise.all(
          filters
            .filter((f) => f.type === "select" && !f.clearable)
            .map((f) =>
              firstValueFrom(f.loaded$ as Observable<any[]>).then((data) => {
                return { filter: f, data };
              }),
            ),
        ).then((filters) => {
          for (let { filter, data } of filters) {
            if (!filter.clearable) {
              this.filterState[filter.id] = data[0]?.value;
            }
          }
          this.filtersReady$.next(true);
          this.filtersReady$.complete();
        });
      }),
    );

    this._metaEventService.reloadTrigger$
      .pipe(
        debounceTime(50),
        filter((x) => x !== undefined && x !== null),
        takeUntil((this as any).destroyed$),
      )
      .subscribe({
        next: async (trigger) => {
          if (trigger.specific) {
            if (trigger.formId === this.field.id) {
              this.refresh();
            }
          }
        },
      });

    const filterReady = firstValueFrom(this.filtersReady$);
    this.data$ = this.filterValue$.pipe(
      debounceTime(750),
      tap(() => {
        this.isLoading = true;
        this.changeDetectorRef.markForCheck();
      }),
      //switchMap(() => filterReady),
      switchMap(() => {
        return this.getData(
          typeof this.searchValue$.value === "string" ? this.searchValue$.value : "",
          this.filterState,
        );
      }),
    );
    this.data$.subscribe((data) => {
      this.gantt.clearAll();
      this.setColumns(data.columns);
      this.gantt.parse({
        tasks: data.items,
        links: data.links || [],
      });
      this.gantt.groupBy({
        groups: data["groups"],
        relation_property: "group",
        group_id: "id",
        group_text: "title",
      });
      this.gantt.sort("start");
      this.data = data;
      this.isLoading = false;
      this.changeDetectorRef.markForCheck();
    });
    this.searchValue$.subscribe((e) => {
      if (e && typeof e === "object") {
        this.currentItemId = (e as any)["value"];
        try {
          this.gantt.showTask((e as any).id);
        } catch (e) {}
      } else {
        this.currentItemId = null;
        this.filterValue$.next(this.filterValue$.value);
      }
    });
    this.searchAutocompleteData$ = this.searchValue$.pipe(
      filter((e) => typeof e === "string"),
      debounceTime(450),
      switchMap((searchString) => {
        return this.metaGanttService.getAutocomplete({
          searchString,
          ganttId: this.id,
          formId: this.formState.formId,
        });
      }),
    );

    this.paramCreateTaskButton = {
      label: `${this.ma.taskNameSingular} erstellen`,
      icon: "plus",
      size: "small",
      type: "primary",
      onClick: () => this.onTaskCreate(),
    };
    this.paramFullScreenButton = {
      icon: "up-right-and-down-left-from-center",
      size: "small",
      type: "link",
      onClick: () => {
        this.gantt.ext.fullscreen.toggle();
        this.changeDetectorRef.markForCheck();
      },
    };
  }

  /**
   * Sets the configuration for the Gantt chart.
   * @param {GanttStatic} gantt - The Gantt chart instance.
   */
  private setConfig() {
    this.gantt.config.drag_links = true;
    this.gantt.config.scales = [
      {
        unit: "minute",
        step: 1,
        format: function (date) {
          return "";
        },
      },
      {
        unit: "minute",
        step: 10,
        format: (date) => {
          return String(this.gantt.columnIndexByDate(date) + 1 * 10) + "min";
        },
      },
      {
        unit: "minute",
        step: 60,
        format: (date) => {
          return String(this.gantt.columnIndexByDate(date) / 60 + 1) + "h";
        },
      },
    ];

    this.gantt.templates.task_text = (start, end, task) => {
      return `${task.type !== "project" ? task.text : ""}<div class="task-arrow-left" style="border-left-color: ${task.color} !important;"></div><div class="task-arrow-right" style="border-right-color: ${task.color}!important;"></div>`;
    };
    this.gantt.templates.grid_row_class = this.gantt.templates.task_row_class = (start, end, task) => {
      if (task.$highlighted) return "gantt_selected";
    };
    this.gantt.templates.timeline_cell_class = (task, date) => {
      if ((this.gantt.columnIndexByDate(date) + 1) % 10 === 0) {
        return `time_block`;
      }
      return "";
    };
    this.gantt.$highlight_id = null;
    this.gantt.attachEvent("onMouseMove", (id, e) => {
      if ((!id && this.gantt.$highlight_id) || (id && this.gantt.$highlight_id && id != this.gantt.$highlight_id)) {
        this.gantt.batchUpdate(() => {
          this.gantt.eachTask((task) => {
            if (task.$highlighted) {
              task.$highlighted = false;
            }
          });
        });
        this.gantt.$highlight_id = null;
      }

      if (id) {
        var hover_task = this.gantt.getTask(id);
        if (!hover_task.$highlighted) {
          hover_task.$highlighted = true;
          this.gantt.refreshTask(id);
        }
        this.gantt.$highlight_id = id;
      }
    });
  }

  private setColumns(columns?: ISchedulerColumn[]) {
    const cols = [];
    columns.forEach((column) => {
      cols.push({
        name: column.id,
        label: column.label,
        width: column.width || "*",
        align: column.align,
        template: function (task) {
          return `${task.original[column.value]} ${column.suffix || ""}`;
        },
      });
    });
    this.gantt.config.columns = cols;
    this.columnsSet.set(true);
  }

  /**
   * Fetches data from the metaGanttService based on certain parameters
   *
   * @param {string} searchString - Optional. The search string to filter the data
   * @param {Record<string, any>} filterState - Optional. The filter state to apply on the data
   *
   * @returns {Observable<any>} - An observable that emits the data fetched from the metaGanttService
   */
  private getData(searchString: string = null, filterState: Record<string, any> = {}) {
    const schedulerState = this.gantt.getState() as { min_date: Date; max_date: Date; mode: string };
    this.isLoading = true;
    this.changeDetectorRef.markForCheck();
    return this.metaGanttService
      .getItems({
        formId: this.formState.formId,
        ganttId: this.id,
        contextId: this.model._ctx,
        start: schedulerState.min_date.toISOString(),
        end: schedulerState.max_date.toISOString(),
        searchString,
        filterState,
      })
      .pipe(
        map((data) => {
          data.items.forEach((e) => {
            if (!e.color) {
              e.color = this.metaHelperService.generateColor(`group${btoa(e.group || e["key"]).substr(0, 5)}`);
            } else {
              e.color = getComputedStyle(document.documentElement).getPropertyValue("--text-color-" + e.color);
            }
          });
          if (data.groups.length > 0) {
            data.groups.forEach((e) => {
              if (!e.color) {
                e.color = this.metaHelperService.generateColor(`group${btoa(e["key"]).substr(0, 5)}`);
              } else {
                e.color = getComputedStyle(document.documentElement).getPropertyValue("--text-color-" + e.color);
              }
            });
          }
          return data;
        }),
      );
  }

  public updateScale() {
    let timelineRange = this.gantt.getSubtaskDates();
    if (timelineRange.start_date && timelineRange.end_date) {
      this.gantt.config.start_date = timelineRange.start_date;
      this.gantt.config.end_date = moment(timelineRange.end_date).add(1, "hour").toDate();
    }
  }

  /**
   * Refreshes the filter value.
   *
   * @return undefined
   */
  public refresh() {
    this.filterValue$.next(this.filterValue$.value);
  }

  /**
   * Handles the click event of a task.
   *
   * @param {string} eventId - The ID of the clicked task.
   *
   * @return {void}
   */
  public onTaskClick(eventId: string) {
    console.log(this.metaGantt);
    const event = this.data.items.find((e) => String(e.id) === String(eventId));
    if (event) {
      this.metaGanttService.handleTaskClick(
        {
          formId: this.formState.formId,
          ganttId: this.id,
          contextId: this.model._ctx,
        },
        event,
      );
    }
  }

  public onTaskCreate() {
    console.log(this.metaGantt);
    this.metaGanttService.handleTaskCreate(
      {
        formId: this.formState.formId,
        ganttId: this.id,
        contextId: this.model._ctx,
      },
      this.model,
    );
  }

  /**
   * Handles the click event for a project.
   *
   * @param {string} projectId - The project ID to handle the click event for.
   *
   * @return {void}
   */
  public onProjectClick(projectId: string) {
    const event = this.data.items.find((e) => String(e.id) === String(projectId));
    if (event) {
      this.metaGanttService.handleProjectClick(
        {
          formId: this.formState.formId,
          ganttId: this.id,
          contextId: this.model._ctx,
        },
        event,
      );
    }
  }
}
