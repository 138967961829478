/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Pipe, PipeTransform } from "@angular/core";
import memo from "memo-decorator";
import { formatNumber, IFormatNumberArgs } from "@meta/shared/formatters/numberFormatter";

@Pipe({
  name: "metaNumber",
  pure: true,
})
export class MetaNumberPipe implements PipeTransform {
  @memo()
  public transform(value: any, args?: IFormatNumberArgs): string {
    return formatNumber(value, {
      ...args,
      locale: navigator.language,
    });
  }
}
