/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

// download-action.handler.ts
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DownloadActionHandler {
  async handle(action: any) {
    const b64toBlob = (base64, type = "application/octet-stream") =>
      fetch(`data:${type};base64,${base64}`).then((res) => res.blob());
    const link = document.createElement("a");
    link.download = action.filename;
    link.href = URL.createObjectURL(await b64toBlob(action.data));
    link.click();
  }
}
