/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */
import { Injectable } from "@angular/core";
import { IMetaMediaLibraryFile } from "../metaMediaLibrary/interfaces";
import { MetaPrintModalComponent } from "./meta-print-modal.component";
import { MetaModalService } from "../../services/metaModalService";

@Injectable({
  providedIn: "root",
})
export class MetaPrintService {
  constructor(private readonly modal: MetaModalService) {}

  printFile(file: IMetaMediaLibraryFile) {
    const ref = this.modal.create({
      nzTitle: null, //`Drucken (${file.name})`,
      nzContent: MetaPrintModalComponent,
      nzWidth: "70vw",
      nzClosable: false,
      nzOkText: null,
      nzCancelText: null,
      nzFooter: null,
      nzBodyStyle: {
        height: "70vh",
      },
    });
    ref.componentInstance.printOptions.get("type").setValue("file");
    ref.componentInstance.printOptions.get("id").setValue(file.id);
    ref.componentInstance.isImage = file.mime.split("/")[0] === "image";
  }

  printReport(formId: string, itemId: string, locale?: string) {
    const ref = this.modal.create({
      nzTitle: null, //`Drucken (${file.name})`,
      nzContent: MetaPrintModalComponent,
      nzWidth: "70vw",
      nzClosable: false,
      nzOkText: null,
      nzCancelText: null,
      nzFooter: null,
      nzBodyStyle: {
        height: "70vh",
      },
    });
    ref.componentInstance.printOptions.get("type").setValue("report");
    ref.componentInstance.printOptions.get("formId").setValue(formId);
    ref.componentInstance.printOptions.get("id").setValue(itemId);
    ref.componentInstance.printOptions.get("locale").setValue(locale || null);
    ref.componentInstance.isImage = false;
  }

  private print(options: Record<string, any>) {
    alert(JSON.stringify(options));
  }
}
