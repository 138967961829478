/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import {
  DataSourceParameters,
  MetaFormBaseComponent,
  MetaFormComponentInfo,
  MetaFormComponentProperty,
} from "./baseComponent";
import { MetaProgressType } from "@meta/api-interfaces";
import * as _ from "lodash";
import { FormEventActionHandler } from "../interfaces";
import { IsOptional } from "class-validator";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-progress",
  type: "progress",
  icon: "tasks-alt",
  name: "Progress",
})
export class MetaFormProgressComponent extends MetaFormBaseComponent {
  public progressType: MetaProgressType = MetaProgressType.line;
  public selectDataSource?: string;
  public selectValueField?: string;
  public selectLabelField?: string;
  public size?: "small" | "default" = "default";
  public selectDataSourceParams?: DataSourceParameters;
  @MetaFormComponentProperty({
    name: "Select Sortierung Feld",
    type: "text",
    tab: "data",
    required: false,
  })
  @IsOptional()
  public selectSortField: string;

  @MetaFormComponentProperty({
    name: "Select Sortierung",
    type: "select",
    tab: "data",
    default: "asc",
    values: [
      { value: "asc", label: "Aufsteigend" },
      { value: "desc", label: "Absteigend" },
    ],
    required: false,
  })
  @IsOptional()
  public selectSortDirection: "asc" | "desc" = "asc";

  public hasFieldWrapper = true;
  public onClick: FormEventActionHandler;

  public appendToToolbar = false;

  constructor() {
    super();
    this.readonly = true;
  }

  public static create<T>(dataSource: T, opts: Partial<MetaFormProgressComponent>) {
    const input = new MetaFormProgressComponent();
    input.setOptions(opts);
    return input;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      wrappers: this.hasFieldWrapper ? ["meta-field-wrapper"] : [],
      props: {
        type: this.progressType,
        isStepped: this.selectDataSource,
        appendToToolbar: this.appendToToolbar,
        onClick: !!this.onClick,
        size: this.size,
      },
    });
  }
}
