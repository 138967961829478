/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { NzPopoverDirective } from "ng-zorro-antd/popover";
import { ChatService } from "./chat.service";

@Component({
  template: `
    <ng-template #tmpl>
      <meta-chat class="popover"></meta-chat>
    </ng-template>
    <button
      [nzPopoverBackdrop]="true"
      nzPopoverPlacement="topRight"
      [nzPopoverTrigger]="'click'"
      [nzPopoverContent]="tmpl"
      [nzPopoverOverlayClassName]="'chat-popover'"
      (nzPopoverVisibleChange)="popoverVisibleChange($event)"
      nz-popover
    >
      <i class="fas fa-message-bot"></i>
    </button>
  `,
  selector: "meta-chat-button",
  styleUrls: ["./chat-button.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatButtonComponent implements AfterViewInit {
  @ViewChild(NzPopoverDirective) public chatPopover: NzPopoverDirective;

  constructor(private _chatService: ChatService) {}

  public ngAfterViewInit() {
    this._chatService.chatPopover = this.chatPopover;
  }

  public popoverVisibleChange(visible: boolean) {
    if (visible) {
      window.document.body.classList.add("noScroll");
    } else {
      window.document.body.classList.remove("noScroll");
    }
  }
}
