/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import {
  DataSourceParameters,
  ExposeProperty,
  MetaFormComponentInfo,
  MetaFormComponentProperty,
} from "./baseComponent";
import { IsOptional } from "class-validator";
import type { FormEventActionHandler } from "../interfaces";
import { IServerTemplateMainContext } from "../interfaces";
import { ValidatableComponent } from "./validatableComponent";
import type { DataSourceType } from "@meta/database/lib/types";
import { IFormUserConfiguration } from "../formUserConfiguration";
import { deepMerge } from "../deepMerge";

@MetaFormComponentInfo({
  selector: "ma-autocomplete",
  type: "autocomplete",
  name: "Autocomplete",
  icon: "magic",
  palletGroup: "input",
})
export class MetaAutocompleteComponent extends ValidatableComponent {
  public onSelect?: FormEventActionHandler;

  public onNewEntry?: (text: string, $: IServerTemplateMainContext) => number | string;

  @ExposeProperty()
  @IsOptional()
  public searchType?: "address" | "street" | "person" | "default" = "default";

  @ExposeProperty()
  @IsOptional()
  public disableOnlineSearch = false;

  @ExposeProperty()
  @IsOptional()
  public duplicateError?: string;

  @ExposeProperty()
  @IsOptional()
  public returnType?: "value" | "object" = "value";

  @ExposeProperty()
  @IsOptional()
  public allowSelectLockedEntries = false;

  @ExposeProperty()
  @IsOptional()
  public createPotentialCustomer: boolean;

  /**
   * Sets the data source used for the select box
   */
  @MetaFormComponentProperty({
    name: "Select Datasource",
    type: "dataSource",
    tab: "data",
    required: true,
  })
  public selectDataSource: DataSourceType;
  /**
   * Sets the data item field that represents the item text.
   */
  @ExposeProperty() public selectLabelField: string;
  /**
   * Sets the data item field that represents the locked state of an item
   */
  @MetaFormComponentProperty({
    name: "Select Locked Field",
    type: "path",
    tab: "data",
    required: true,
    datasourceField: "selectDataSource",
  })
  public selectLockedField = "Sperre";
  /**
   * Sets the data item field that represents the item text.
   */
  @ExposeProperty() public selectLabelDisplayValue: string;

  @MetaFormComponentProperty({
    name: "Select Sortierung Feld",
    type: "path",
    tab: "data",
    required: false,
    datasourceField: "selectDataSource",
  })
  public selectSortField: string;

  @MetaFormComponentProperty({
    name: "Select Sortierung",
    type: "select",
    tab: "data",
    default: "asc",
    values: [
      { value: "asc", label: "Aufsteigend" },
      { value: "desc", label: "Absteigend" },
    ],
    required: false,
  })
  public selectSortDirection: "asc" | "desc" = "asc";

  /**
   * Sets the data item field that represents the item value.
   */
  @ExposeProperty() public selectValueField: string;

  @ExposeProperty() @IsOptional() public selectGroupField: string;

  @ExposeProperty() public selectDataSourceParams?: DataSourceParameters;

  /**
   * Gets called before the select data list is loaded
   */
  public onBeforeOpen?: FormEventActionHandler;

  public creatable = false;

  public static createElement(attributes: Partial<MetaAutocompleteComponent>) {
    const component = new MetaAutocompleteComponent();
    let opts: Partial<MetaAutocompleteComponent> = {};
    if (attributes.searchType === "address") {
      opts = {
        selectDataSource: "core.qryAdressObjekte" as any,
        selectDataSourceParams: { defaultParams: { AdressTyp: 20 } } as any,
        selectValueField: "AdressNr",
        selectLabelField: "Name",
        selectGroupField: "Adresse",
        selectSortField: "Name",
        selectSortDirection: "asc",
      };
    } else if (attributes.searchType === "person") {
      opts = {
        selectDataSource: "core.qryAdressObjekte" as any,
        selectDataSourceParams: { defaultParams: { AdressTyp: 10 } } as any,
        selectValueField: "AdressNr",
        selectLabelField: "Name",
        selectGroupField: "Organisation",
        selectSortField: "Name",
        selectSortDirection: "asc",
      };
    } else if (attributes.searchType) {
      throw new Error(`The search type "${attributes.searchType}" is not supported.`);
    }
    component.setOptions({
      ...opts,
      ...attributes,
    });
    return component;
  }

  toFormly(config: IFormUserConfiguration): any {
    return deepMerge(super.toFormly(config), {
      props: {
        returnType: this.returnType,
        onSelect: !!this.onSelect,
        datasourceGroup: this.selectGroupField,
        creatable: this.creatable,
        placeholder: this.placeholder,
        allowSelectLockedEntries: this.allowSelectLockedEntries,
      },
    });
  }
}
