/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { formatDate } from "@angular/common";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment/moment";

type MetaSinceDateFormatType = "medium" | "long" | "short";

@Pipe({
  name: "metaSince",
  pure: true,
  standalone: true,
})
export class SincePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID)
    private readonly localeId: string,
  ) {}
  transform(value: any, format: MetaSinceDateFormatType = "medium"): any {
    if (!value) return "";
    const showFullDate = true;
    if (showFullDate && moment().subtract(7, "day").isBefore(value)) {
      return moment(value).fromNow();
    } else {
      return formatDate(value, format, this.localeId);
    }
  }
}
