/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { ChangeDetectionStrategy, Component, NgModule, OnInit, ViewEncapsulation } from "@angular/core";
import { MetaWrapperBase } from "../../base/metaWrapperBase/metaWrapperBase.component";
import { CommonModule } from "@angular/common";
import { FormlyModule } from "@ngx-formly/core";

@MetaUnsubscribe()
@Component({
  selector: "meta-split-view",
  template: ``,
  styleUrls: ["./metaSplitView.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaSplitViewComponent extends MetaWrapperBase implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}

@NgModule({
  declarations: [MetaSplitViewComponent],
  imports: [CommonModule, FormlyModule],
  exports: [MetaSplitViewComponent],
})
export class MetaSplitViewModule {}
