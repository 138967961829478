/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaEventService } from "../../services/metaEvents.service";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  OnInit,
  Optional,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { CommonModule } from "@angular/common";
import { FormlyModule } from "@ngx-formly/core";
import {
  MetaButtonModule,
  MetaEditorModule,
  MetaEmptyModule,
  MetaFieldWrapperModule,
  MetaInputModule,
  MetaLoaderModule,
  MetaSectionModule,
} from "@meta/ui";
import { MetaSpecificationsService, SpecificationObject } from "./metaSpecifications.service";
import { NzModalModule, NzModalRef } from "ng-zorro-antd/modal";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { GridsterComponent, GridsterModule, IGridsterDraggableOptions, IGridsterOptions } from "angular2gridster";
import { distinctUntilChanged, Subject, takeUntil } from "rxjs";
import { FormsModule } from "@angular/forms";
import { MetaSpecificationsGroupModule } from "./metaSpecificationsGroup/metaSpecificationsGroup.component";
import { NzGridModule } from "ng-zorro-antd/grid";
import { select } from "@ngneat/elf";
import { filter } from "rxjs/operators";
import { MetaFormStateStore } from "../../base/metaForm/metaForm.interface";
import { MetaState } from "@meta/enums";
import { NzResizableModule, NzResizeEvent } from "ng-zorro-antd/resizable";
import { MetaIconModule } from "../metaIcon/metaIcon.component";
import * as _ from "lodash";
import * as uuid from "uuid";
import { DirtyCheckService } from "../../services/dirtyCheckService";

export class MetaSpecifications {
  public controlsDisabled = false;
  public disabled = false;
  public showAsList: boolean;
  public editable = true;
  public previewValues: any;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-specifications",
  template: `
    <div nz-row>
      <div
        class="col"
        nz-col
        nz-resizable
        (nzResize)="onResize($event)"
        [nzMinColumn]="3"
        [nzMaxColumn]="20"
        [nzGridColumnCount]="24"
        [nzSpan]="editing ? col : 24"
      >
        <nz-resize-handles *ngIf="editing" [nzDirections]="['right']"></nz-resize-handles>
        <div [style.minHeight.px]="500" class="grid-wrapper">
          <div *ngIf="metaSpecificationService.isLoadingGrid$ | async" class="specification-saving-blocker">
            <div class="message">
              <meta-section [maParams]="{ sectionType: 'h3' }"> <meta-loader></meta-loader></meta-section>
            </div>
            <div class="backdrop"></div>
          </div>
          <div *ngIf="metaSpecificationService.isSavingGrid$ | async" class="specification-saving-blocker">
            <div class="message">
              <meta-section [maParams]="{ sectionType: 'h3' }"> <meta-loader></meta-loader></meta-section>
            </div>
            <div class="backdrop"></div>
          </div>

          <div *ngIf="!ma.showAsList; else showAsList" cdkDropListGroup>
            <meta-empty
              *ngIf="
                (metaSpecificationService.isLoadingGrid$ | async) !== true &&
                !editing &&
                (metaSpecificationService.specifications$ | async)?.data?.length === 0
              "
              [maParams]="{
                icon: 'border-none',
                description: 'Es wurden noch keine Spezifikationen zugewiesen.'
              }"
            ></meta-empty>
            <ngx-gridster
              *ngIf="
                (metaSpecificationService.isLoadingGrid$ | async) !== true &&
                (metaSpecificationService.specifications$ | async) as specifications
              "
              #grid
              [ngClass]="{ editing: editing }"
              [options]="gridsterOptions"
              [draggableOptions]="draggableOptions"
            >
              <ngx-gridster-item
                *ngFor="let item of specifications?.data; trackBy: trackById"
                [h]="8"
                [hSm]="8"
                [(hMd)]="item.h"
                [(wMd)]="item.w"
                [(xMd)]="item.x"
                [(yMd)]="item.y"
                [options]="{
                  minWidth: 8,
                  minHeight: 5,
                  defaultWidth: 12,
                  defaultHeight: 12
                }"
              >
                <meta-specifcations-group
                  [maParams]="{
                    controlsDisabled: ma.controlsDisabled,
                    parentFormId: formState.formId,
                    formState: formState,
                    data: itemContentInputs,
                    item: item,
                    editing: editing,
                    onItemUpdate: onItemUpdate.bind(this),
                    onValueUpdate: onValueUpdate.bind(this)
                  }"
                ></meta-specifcations-group>
                <div class="gridster-item-overlay">
                  <meta-button
                    [maParams]="{
                      size: 'small',
                      icon: 'pencil-alt',
                      iconStyle: 'fas',
                      type: 'link'
                    }"
                    class="btn-edit-item"
                    (click)="edit(item)"
                  ></meta-button>
                  <meta-button
                    [maParams]="{
                      size: 'small',
                      icon: 'trash',
                      iconStyle: 'fas',
                      type: 'link',
                      danger: true,
                      popConfirm: {
                        label: 'Gruppe wirklich entfernen?',
                        placement: 'left',
                        onOk: delete.bind(this, item.id)
                      }
                    }"
                    class="btn-delete"
                  ></meta-button>
                </div>
              </ngx-gridster-item>
            </ngx-gridster>

            <ng-container *ngIf="metaSpecificationService.specifications$ | async as specifications">
              <div *ngIf="specifications?.relatedData.length > 0" nz-row>
                <div nz-col>
                  <meta-section [maParams]="{ sectionType: 'h2' }">Verknüpfte Artikel</meta-section>
                </div>
                <div *ngFor="let group of specifications.relatedData; trackBy: trackByIndex" nz-col [nzSpan]="24">
                  <meta-section [maParams]="{ sectionType: 'h3' }"
                    ><a href="/frmArticle/{{ group.id }}">{{ group.id }}</a> - {{ group.label }}</meta-section
                  >
                  <div nz-row>
                    <div
                      *ngFor="let column of splitUp(group.children, 3); trackBy: trackByIndex"
                      nz-col
                      [nzSpan]="8"
                      class="spec-list"
                    >
                      <div *ngFor="let item of column; trackBy: trackByIndex">
                        <meta-specifcations-group
                          [maParams]="{
                            parentFormId: formState.formId,
                            formState: formState,
                            data: itemContentInputs,
                            item: item,
                            controlsDisabled: true,
                            readonly: true
                          }"
                        ></meta-specifcations-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <ng-template #showAsList>
            <div *ngIf="metaSpecificationService.specifications$ | async as specifications" nz-row>
              <div *ngFor="let column of splitUp(specifications.data, 3); trackBy: trackByIndex" nz-col [nzSpan]="8">
                <div *ngFor="let item of column; trackBy: trackByIndex">
                  <meta-specifcations-group
                    [maParams]="{ parentFormId: formState.formId, data: itemContentInputs, item: item }"
                  ></meta-specifcations-group>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div *ngIf="editing" class="col right" nz-col [nzSpan]="24 - col">
        <div [ngClass]="{ visible: editing }" class="widget-pane">
          <ul>
            <li
              (drop)="dropNewWidget(null, $event, item)"
              *ngFor="let item of widgets"
              [config]="{ helper: true }"
              [h]="6"
              [id]="item.id"
              [w]="6"
              class="widget-preview gridster-item-prototype"
              ngxGridsterItemPrototype
            >
              <div class="widget-preview-image-container">
                <i *ngIf="item.icon" class="fal fa-{{ item.icon }}"></i>
                <meta-icon
                  *ngIf="!item.icon"
                  [maParams]="{ icon: 'gqdnbnwt', trigger: 'enable', delay: 0, scale: 60 }"
                ></meta-icon>
              </div>
              <div class="widget-preview-text-container">
                <h3>{{ item.name }}</h3>
                <p>{{ item.items }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Edit item modal -->
    <nz-modal
      (nzOnCancel)="handleCancel()"
      *ngIf="editItem"
      [(nzVisible)]="editItem"
      [nzMaskClosable]="false"
      [nzWidth]="700"
      [nzFooter]="ModalFooter"
      [nzTitle]="modalEditItemTitleTemplate"
      nzWrapClassName="vertical-center-modal"
    >
      <ng-container *nzModalContent>
        <meta-field-wrapper [maParams]="{ label: 'Gruppenname' }">
          <meta-input [maParams]="{ editing: true }" [(ngModel)]="detailModel.label"></meta-input>
        </meta-field-wrapper>
        <meta-field-wrapper [maParams]="{ label: 'Beschreibung' }">
          <meta-editor
            [maParams]="{
              toolbar: 'none',
              editing: true
            }"
            [(ngModel)]="detailModel.description"
          ></meta-editor>
        </meta-field-wrapper>
      </ng-container>
      <ng-template #ModalFooter>
        <meta-button (click)="handleCancel()" maLabel="Abbrechen" maType="default"></meta-button>
        <meta-button
          (click)="handleSave()"
          [maParams]="{
            loading: isSaving,
            label: 'Übernehmen',
            type: 'primary',
            icon: 'check'
          }"
        ></meta-button>
      </ng-template>
    </nz-modal>

    <ng-template #modalEditItemTitleTemplate>
      <span><i class="fal fa-th-large"></i> Spezifikationsgruppe bearbeiten</span>
    </ng-template>
  `,
  styleUrls: ["./metaSpecifications.component.less"],
  providers: [MetaSpecificationsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaSpecificationsComponent extends MetaComponentBase implements OnInit {
  @ViewChild("grid") grid: GridsterComponent;
  public widgetPaneFilterText: string;
  public detailModel: any = {};
  public itemContentInputs: any;
  public itemContentOutputs: any;
  public editing = false;
  public editItem = false;
  public itemId: string | null = null;
  public isSaving: boolean;
  public editingItem: any = {};
  public widgets: any[] = [
    {
      icon: "asterisk",
      name: "Neue Gruppe",
      description: "Ziehen um eine Gruppe ohne Spezifikationen hinzuzufügen",
    },
  ];
  public widgetsFiltered: any[] = [];
  public gridsterOptions: IGridsterOptions;
  public draggableOptions: IGridsterDraggableOptions;
  col = 19;
  resizeId = -1;
  private _ids: string[] = [];
  private _destroyed$ = new Subject();
  private _oldSpec: any;

  get ma(): MetaSpecifications {
    return super.ma;
  }
  constructor(
    public metaSpecificationService: MetaSpecificationsService,
    private readonly _dirtyCheckService: DirtyCheckService,
    private readonly _metaEventService: MetaEventService,
    @Optional() private readonly _modalRef: NzModalRef,
  ) {
    super();
    super.maParams = new MetaSpecifications();
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.field) {
      return;
    }
    // Set grid options
    this._setOptions();
    this.updateContentInputs();
    this.options.formState
      .onFormDataReady()
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe({
        next: (formData) => {
          if (formData && this.formState.itemId() !== "create") {
            this.metaSpecificationService.get(this.formState.formId, this.formState.itemId());
          }
        },
      });
    this.formState.state$
      .pipe(
        takeUntil(this._destroyed$),
        filter((x: MetaState) => x !== undefined),
      )
      .subscribe({
        next: (state: MetaState) => {
          this.editing = state >= MetaState.editing && state <= MetaState.saving;
          if (this.grid) {
            // Set Grid Options
            this.grid.setOption("dragAndDrop", this.editing);
            this.grid.setOption("resizable", this.editing);
            this.grid.reload();
          }
          if (this.editing) {
            this.metaSpecificationService.getAllGroups();
            this._oldSpec = JSON.parse(JSON.stringify(this.metaSpecificationService.specifications$.value));
          }
        },
      });

    this._metaEventService.saveTrigger$.pipe(takeUntil(this._destroyed$)).subscribe({
      next: () => {
        this.metaSpecificationService.isSavingGrid$.next(true);
        const spec: SpecificationObject = this.metaSpecificationService.specifications$.value;
        if (spec?.data) {
          this.updateGrid(spec.data, true);
        }
      },
    });

    this._metaEventService.cancelTrigger$.pipe(takeUntil(this._destroyed$)).subscribe({
      next: () => {
        this.metaSpecificationService.specifications$.next(this._oldSpec);
        this._oldSpec = null;
      },
    });

    this.metaSpecificationService.groups$
      .pipe(takeUntil(this._destroyed$), distinctUntilChanged())
      .subscribe((groups) => {
        if (groups) {
          const arr = [];
          groups.forEach((grp, index) => {
            arr.push({
              id: index,
              name: grp.label,
              icon: "window-maximize",
              description: grp.description,
              items: grp.children.map((o) => o.label).join(", "),
              children: grp.children,
              isUnique: true,
            });
          });
          this.widgets = [
            {
              icon: "asterisk",
              name: "Neue Gruppe",
              description: "Ziehen um eine Gruppe ohne Spezifikationen hinzuzufügen",
            },
          ].concat(arr);
          setTimeout(() => {
            //this.grid.updateWidgetsView();
          });
          this.changeDetectorRef.markForCheck();
        }
      });
    this._dirtyCheckService.registerObject(
      this,
      () => {
        return this.editing;
      },
      this._modalRef,
    );
  }

  public updateContentInputs(): void {
    this.itemContentInputs = {
      maUuid: this.id,
      maParentFormId: this.formState.formId,
      maEditing: this.editing,
      maPreviewValues: this.displayData,
    };
    this.changeDetectorRef.markForCheck();
  }

  public ngOnDestroy() {
    this._destroyed$.next(true);
    this._destroyed$.unsubscribe();
    this.metaSpecificationService.isSavingGrid$.next(false);
    this._dirtyCheckService.unregisterObject(this);
  }

  public handleCancel(): void {
    this.editItem = false;
  }

  public handleSave(): void {
    if (this.form.valid) {
      this.isSaving = true;
      this.editingItem.data.name = this.detailModel.label;
      this.editingItem.data.description = this.detailModel.description;
      this.editItem = false;
      this.isSaving = false;
    }
  }

  public afterWidgetsUpdate(data: SpecificationObject) {
    data.data.forEach((d) => {
      if (d?.data?.children) {
        d.data.items = d.data.children.map((c) => {
          return {
            Beschreibung: c.description,
            ID: c.id,
            Mengeneinheit: c.unit,
            Typ: c.type,
            label: c.label,
            value: null,
          };
        });
        delete d.data.children;
      }
    });
    this.metaSpecificationService.specifications$.next(data);
  }

  public updateGrid(data = [], writeValues = false) {
    this.metaSpecificationService.updateSpecification(
      this.formState.formId,
      this.formState.itemId(),
      data,
      writeValues,
    );
  }

  public trackByIndex(index, item) {
    return index;
  }

  public splitUp(arr, n) {
    const rest = arr.length % n, // how much to divide
      partLength = Math.floor(arr.length / n),
      result = [];
    let restUsed = rest; // to keep track of the division over the elements

    for (let i = 0; i < arr.length; i += partLength) {
      let end = partLength + i,
        add = false;

      if (rest !== 0 && restUsed) {
        // should add one element for the division
        end++;
        restUsed--; // we've used one division element now
        add = true;
      }

      result.push(arr.slice(i, end)); // part of the array

      if (add) {
        i++; // also increment i in the case we added an extra element for division
      }
    }
    return result;
  }

  public startDrag($event: any, widget: any): void {
    document.getElementById(widget.id).style.display = "none";
    this.changeDetectorRef.markForCheck();
  }

  public cancelDrag($event: any, widget: any): void {
    document.getElementById(widget.id).style.display = "block";
    this.changeDetectorRef.markForCheck();
  }

  public trackById(index: number, item: any): number {
    return item.id;
  }

  public edit(item: any) {
    this.detailModel.label = item.data.name;
    this.detailModel.description = item.data.description;
    this.editingItem = item;
    this.editItem = true;
    this.changeDetectorRef.markForCheck();
  }

  public delete(id: string) {
    const spec = this.metaSpecificationService.specifications$.value;
    spec.data = spec.data.filter((data) => data.id !== id);
    this.metaSpecificationService.specifications$.next(spec);
  }

  public onItemUpdate(event) {
    if (event.id) {
      const spec: SpecificationObject = this.metaSpecificationService.specifications$.value;
      spec.data.forEach((o) => {
        if (o.id === event.id) {
          o.data.items = event.items;
        }
      });
      this.metaSpecificationService.specifications$.next(spec);
    }
  }

  public onValueUpdate(event) {
    if (event.id) {
      const spec: SpecificationObject = this.metaSpecificationService.specifications$.value;
      spec.data.forEach((o) => {
        if (o.id === event.id) {
          o.data.items = event.items;
        }
      });
      this.metaSpecificationService.specifications$.next(spec);
    }
  }

  onResize({ col }: NzResizeEvent): void {
    cancelAnimationFrame(this.resizeId);
    this.resizeId = requestAnimationFrame(() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.col = col!;
      this.grid.reload();
    });
  }

  filterWidgets() {
    const specs = this.metaSpecificationService.specifications$.value;
    const re = new RegExp(this.widgetPaneFilterText, "gmi");
    this.widgetsFiltered = Object.entries(
      _.groupBy(
        this.widgets
          .filter((e) => re.test(String(e.label)))
          .filter((w) => {
            return !specs?.data.find((c) => c.id === w.id);
          }),
        "category",
      ),
    ).map(([name, entries]) => {
      return {
        name,
        entries,
      };
    });
  }

  public dropNewWidget(gridster: GridsterComponent, event: any, page: any) {
    page = JSON.parse(JSON.stringify(page));
    const item = event.item;
    const spec = this.metaSpecificationService.specifications$.value;
    spec.data.push({
      id: page.components ? page.name : uuid.v4(),
      parentId: this.formState.formId,
      data: page,
      w: 8,
      h: 8,
      x: item.x,
      y: item.y,
    });
    this.afterWidgetsUpdate(spec);
    this.grid.reload();
    this.filterWidgets();
    this.changeDetectorRef.markForCheck();
  }

  private _setOptions() {
    this.gridsterOptions = {
      lanes: 24,
      lines: this.editing ? { visible: true, always: true, color: "#000", width: 1 } : { visible: false },
      widthHeightRatio: 1,
      cellHeight: 45,
      responsiveView: true,
      responsiveDebounce: 50,
      direction: "vertical",
      floating: true,
      shrink: true,
      dragAndDrop: this.editing,
      resizable: this.editing,
      useCSSTransforms: true,
      responsiveSizes: true,
      responsiveToParent: false,
      responsiveOptions: [
        {
          breakpoint: "sm",
          lanes: 1,
        },
        {
          breakpoint: "md",
          minWidth: 768,
          lanes: 24,
        },
      ],
    };
    this.draggableOptions = {
      handlerClass: "ant-card-head",
    };
    this.changeDetectorRef.markForCheck();
  }
}

@NgModule({
  declarations: [MetaSpecificationsComponent],
  imports: [
    CommonModule,
    FormlyModule,
    MetaSectionModule,
    MetaLoaderModule,
    NzModalModule,
    DragDropModule,
    MetaEmptyModule,
    GridsterModule,
    MetaButtonModule,
    MetaInputModule,
    MetaEditorModule,
    FormsModule,
    MetaFieldWrapperModule,
    MetaSpecificationsGroupModule,
    NzGridModule,
    NzResizableModule,
    MetaIconModule,
  ],
  providers: [MetaSpecificationsService],
  exports: [MetaSpecificationsComponent],
})
export class MetaSpecificationsModule {}
