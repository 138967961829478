/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

import { CommonModule } from "@angular/common";
import { Component, computed, effect, inject, input, Input, model, OnInit, Optional } from "@angular/core";
import { FormsModule } from "@angular/forms";
import cronstrue from "cronstrue";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzCronExpressionModule } from "ng-zorro-antd/cron-expression";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzModalRef } from "ng-zorro-antd/modal";

@Component({
  standalone: true,
  template: `
    <nz-cron-expression [nzExtra]="shortcuts" [(ngModel)]="value" />
    <ng-template #shortcuts>
      <button nz-button nz-dropdown [nzDropdownMenu]="menu">
        Vorlagen
        <span nz-icon nzType="down"></span>
      </button>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu nzSelectable>
          <li nz-menu-item *ngFor="let item of options" [value]="item.value" (click)="value.set(item.value)">
            {{ item.label }}
          </li>
        </ul>
      </nz-dropdown-menu>
    </ng-template>
    <br />
    <code>{{ displayCron() }}</code>
  `,
  imports: [
    CommonModule,
    FormsModule,
    NzInputModule,
    NzCronExpressionModule,
    NzButtonModule,
    NzDropDownModule,
    NzIconModule,
  ],
})
export class MetaCronModalComponent {
  constructor(@Optional() private readonly modalRef: NzModalRef) {
    this.options = this.examples.map((e) => {
      return {
        label: this.cronToString(e),
        value: e,
      };
    });
    effect(() => {
      modalRef.updateConfig({
        nzOkDisabled: !this.invalid(),
      });
    });
  }
  public value = model("");

  public displayCron = computed(() => {
    const cron = this.value();
    if (cron) {
      return this.cronToString(cron);
    } else {
      return "-";
    }
  });

  public invalid = computed(() => {
    try {
      this.cronToString(this.value());
      return true;
    } catch (e) {
      return false;
    }
  });

  public readonly examples = ["*/5 * * * *", "0 * * * MON-FRI", "0 6 * * * *", "0 0 * * MON", "0 0 1 * *"];
  public readonly options: { label: string; value: string }[] = [];

  private cronToString(cron: string) {
    return cronstrue.toString(cron, {
      locale: navigator.language.split("-")[0],
      use24HourTimeFormat: true,
    });
  }
}
