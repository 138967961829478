/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaBaseValidator } from "./baseValidator";
import * as validator from "class-validator";

type HashAlgorithm =
  | "md4"
  | "md5"
  | "sha1"
  | "sha256"
  | "sha384"
  | "sha512"
  | "ripemd128"
  | "ripemd160"
  | "tiger128"
  | "tiger160"
  | "tiger192"
  | "crc32"
  | "crc32b";

export class IsHashValidatorOptions {
  algorithm: HashAlgorithm;
}

export class IsHash extends MetaBaseValidator {
  constructor(public options?: IsHashValidatorOptions) {
    super();
  }

  async validate(value): Promise<boolean> {
    return validator.isHash(value, this.options.algorithm);
  }
}
