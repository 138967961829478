/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Type } from "class-transformer";
import { Allow, IsBoolean, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";

export class DashboardData {
  @IsString()
  @IsOptional()
  public id?: string;
  @IsNumber()
  public w: number;
  @IsNumber()
  public h: number;
  @IsNumber()
  public y: number;
  @IsNumber()
  public x: number;
  @Allow()
  public data: any;
}

export class DashboardResponse {
  @IsString()
  @IsOptional()
  id?: string;
  @IsString()
  @IsOptional()
  parentId?: string;
  @IsString()
  name: string;
  @IsString()
  @IsOptional()
  icon: string;
  @Type(() => DashboardData)
  @ValidateNested()
  children: DashboardData[];
  @IsBoolean()
  @IsOptional()
  isEditable?: boolean;
  @IsString()
  @IsOptional()
  description?: string;
  @Allow()
  owner?: string;
}

export class DashboardCreateRequest {
  @IsString()
  name: string;
  @IsString()
  icon: string;
}
