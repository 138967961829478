/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, forwardRef, NgModule, OnInit, ViewEncapsulation } from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { MetaRadioStyle } from "@meta/enums";
import { FormlyModule } from "@ngx-formly/core";
import { NzRadioModule } from "ng-zorro-antd/radio";
import * as uuid from "uuid";
import { MetaComponentBase, MetaFormBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaActionHandlerFactory } from "../../base/metaForm/actions/actionHandler.factory";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";

export class MetaRadio extends MetaFormBase {
  data?: MetaRadioData[] = [];
  style?: MetaRadioStyle | string = MetaRadioStyle.default;
  iconOnly? = false;
  checkedIcon? = "check";
  uncheckedIcon? = "times";
  onSelect?: boolean;
  width?: "dynamic" | "static" = "static";
}

export interface MetaRadioData {
  label: string;
  value: string | number;
  icon?: string;
  iconColor?: "red" | "yellow" | "green" | "default";

  [key: string]: string | number;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-radio",
  template: `
    <ng-container *ngTemplateOutlet="editing && !ma.readonly ? controlTpl : outputTpl"></ng-container>
    <ng-template #controlTpl>
      <nz-radio-group
        *ngIf="field; else ngModelTpl"
        [id]="_id"
        [formControl]="fc"
        [formlyAttributes]="field"
        [ngClass]="{
          'full-width': ma.style === metaRadioStyle.solid || ma.style === metaRadioStyle.outline,
          arrow: ma.style === metaRadioStyle.arrow,
          card: ma.style === metaRadioStyle.card,
          list: ma.style === metaRadioStyle.list
        }"
        [nzButtonStyle]="
          ma.style === metaRadioStyle.solid || ma.style === metaRadioStyle.outline
            ? ma.style
            : ma.style === metaRadioStyle.arrow || ma.style === metaRadioStyle.card
              ? metaRadioStyle.solid
              : null
        "
        (ngModelChange)="onSelect($event)"
      >
        <ng-container *ngIf="ma.style === metaRadioStyle.default || ma.style === metaRadioStyle.list">
          <label *ngFor="let item of ma.data" [nzValue]="item.value" nz-radio
            ><i
              *ngIf="item.icon"
              [ngClass]="{ 'icon-only': ma.iconOnly, 'icon-with-text': !ma.iconOnly }"
              class="fas fa-{{ item.icon }} text-{{ item.iconColor }}"
            ></i
            ><span *ngIf="!ma.iconOnly" class="label">{{ item.label }}</span></label
          >
        </ng-container>
        <ng-container *ngIf="ma.style !== metaRadioStyle.default && ma.style !== metaRadioStyle.list">
          <label
            *ngFor="let item of ma.data"
            [nzValue]="item.value"
            [style.width.%]="ma.width !== 'dynamic' ? 100 / ma.data.length : null"
            class="{{ ma.style === metaRadioStyle.card ? 'card-' + item.iconColor : '' }}"
            nz-radio-button
          >
            <i
              *ngIf="item.icon"
              [ngClass]="{ 'icon-only': ma.iconOnly, 'icon-with-text': !ma.iconOnly }"
              class="fas fa-{{ item.icon }} text-{{ item.iconColor }}"
            ></i
            ><span *ngIf="!ma.iconOnly" class="label">{{ item.label }}</span>
          </label>
        </ng-container>
      </nz-radio-group>
      <ng-template #ngModelTpl>
        <nz-radio-group
          [id]="_id"
          [(ngModel)]="value"
          (ngModelChange)="onChangeValue($event)"
          [ngClass]="{
            'full-width': ma.style === metaRadioStyle.solid || ma.style === metaRadioStyle.outline,
            arrow: ma.style === metaRadioStyle.arrow,
            card: ma.style === metaRadioStyle.card,
            list: ma.style === metaRadioStyle.list,
            disabled: ma.disabled
          }"
          [nzButtonStyle]="
            ma.style === metaRadioStyle.solid || ma.style === metaRadioStyle.outline
              ? ma.style
              : ma.style === metaRadioStyle.arrow || ma.style === metaRadioStyle.card
                ? metaRadioStyle.solid
                : null
          "
        >
          <ng-container *ngIf="ma.style === metaRadioStyle.default || ma.style === metaRadioStyle.list">
            <label *ngFor="let item of ma.data" [nzValue]="item.value" nz-radio
              ><i
                *ngIf="item.icon"
                [ngClass]="{ 'icon-only': ma.iconOnly, 'icon-with-text': !ma.iconOnly }"
                class="fas fa-{{ item.icon }} text-{{ item.iconColor }}"
              ></i
              ><span *ngIf="!ma.iconOnly" class="label">{{ item.label }}</span></label
            >
          </ng-container>
          <ng-container *ngIf="ma.style !== metaRadioStyle.default && ma.style !== metaRadioStyle.list">
            <label
              *ngFor="let item of ma.data"
              [nzValue]="item.value"
              [style.width.%]="ma.width !== 'dynamic' ? 100 / ma.data.length : null"
              class="{{ ma.style === metaRadioStyle.card ? 'card-' + (item.iconColor || 'default') : '' }}"
              nz-radio-button
            >
              <i
                *ngIf="item.icon"
                [ngClass]="{ 'icon-only': ma.iconOnly, 'icon-with-text': !ma.iconOnly }"
                class="fas fa-{{ item.icon }} text-{{ item.iconColor }}"
              ></i
              ><span *ngIf="!ma.iconOnly" class="label">{{ item.label }}</span>
            </label>
          </ng-container>
        </nz-radio-group>
      </ng-template>
    </ng-template>
    <ng-template #outputTpl>
      <div
        class="meta-radio-output"
        [ngClass]="{
          'full-width': ma.style === metaRadioStyle.solid || ma.style === metaRadioStyle.outline,
          arrow: ma.style === metaRadioStyle.arrow,
          card: ma.style === metaRadioStyle.card,
          list: ma.style === metaRadioStyle.list,
          disabled: ma.disabled
        }"
      >
        <ng-container *ngIf="ma.style === metaRadioStyle.default || ma.style === metaRadioStyle.list">
          <div class="output" *ngFor="let item of ma.data">
            <span *ngIf="(field ? model[_id] : value) === item.value"
              ><i
                *ngIf="item.icon"
                [ngClass]="{ 'icon-only': ma.iconOnly, 'icon-with-text': !ma.iconOnly }"
                class="fas fa-{{ item.icon }} text-{{ item.iconColor }}"
              ></i
              >{{ item.label }}</span
            >
          </div>
        </ng-container>
        <ng-container *ngIf="ma.style !== metaRadioStyle.default && ma.style !== metaRadioStyle.list">
          <label
            *ngFor="let item of ma.data"
            [nzValue]="item.value"
            [style.width.%]="ma.width !== 'dynamic' ? 100 / ma.data.length : null"
            class="{{ ma.style === metaRadioStyle.card ? 'card-' + (item.iconColor || 'default') : '' }} {{
              (field ? model[_id] : value) === item.value ? 'ant-radio-button-wrapper-checked' : ''
            }}"
            nz-radio-button
          >
            <i
              *ngIf="item.icon"
              [ngClass]="{ 'icon-only': ma.iconOnly, 'icon-with-text': !ma.iconOnly }"
              class="fas fa-{{ item.icon }} text-{{ item.iconColor }}"
            ></i
            ><span *ngIf="!ma.iconOnly" class="label">{{ item.label }}</span>
          </label>
        </ng-container>
      </div>
    </ng-template>
    <ng-template #checkedTemplate><i class="far fa-{{ ma.checkedIcon }}"></i></ng-template>
    <ng-template #unCheckedTemplate><i class="far fa-{{ ma.uncheckedIcon }}"></i></ng-template>
  `,
  styleUrls: ["./metaRadio.component.less"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetaRadioComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaRadioComponent extends MetaComponentBase<MetaRadioData> implements OnInit {
  public _id: string;
  public metaRadioStyle = MetaRadioStyle;

  constructor(private readonly _metaActionHandler: MetaActionHandlerFactory) {
    super();
    super.maParams = new MetaRadio();
  }

  get ma(): MetaRadio {
    return super.ma;
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.field) {
      this._id = this.ma.id || uuid.v4();
    } else {
      this._id = this.id;
    }
  }

  public async onSelect(item) {
    if (!this.ma.onSelect) {
      return;
    }
    setTimeout(async () => {
      let model =
        this.metaHelperService.getFormlySubFormPath(this.field).length > 0 ? this.formState.data() : this.model;
      if (Object.keys(model).length === 0) {
        model = this.form.value;
      }
      await new Promise((r) => setTimeout(r, 200));
      await this._metaActionHandler.executeSelectAction({
        formId: this.formState.formId,
        controlId: this._id,
        data: {
          value: item,
          ...model,
        },
        ctx: model._ctx,
        subFormPath: this.metaHelperService.getFormlySubFormPath(this.field),
        index: this.metaHelperService.getFormlyFieldArrayIndex(this.field),
      });
    });
  }

  public onChangeValue(item) {
    this.onChange(item);
    this.onSelect(item);
  }
}

@NgModule({
  declarations: [MetaRadioComponent],
  imports: [CommonModule, FormlyModule, NzRadioModule, ReactiveFormsModule, FormsModule],
  exports: [MetaRadioComponent],
})
export class MetaRadioModule {}
