/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule } from "@angular/core";
import { MetaWrapperBase } from "../../base/metaWrapperBase/metaWrapperBase.component";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaAvatarModule } from "../metaAvatar/metaAvatar.component";
import { MetaGroupModule } from "../metaGroup/metaGroup.component";

export class MetaCard {
  listStyle? = "vertical";
  meta: any[] = [];
}

@MetaUnsubscribe()
@Component({
  selector: "meta-card",
  template: `
    <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
    <ng-template #cardTemplate>
      <ng-container *ngIf="formState?.displayData() && formState?.displayData()[field.id]">
        <div class="card-container">
          <div class="card-avatar">
            <meta-image></meta-image>
            <meta-avatar [maParams]="{ label: formState?.displayData()[field.id].avatar, size: 'large' }"></meta-avatar>
          </div>
          <div class="card-data">
            <div class="meta-card-label">
              <span>{{ formState?.displayData()[field.id].label || "" }}</span>
            </div>
            <div *ngIf="ma.listStyle === 'horizontal'" class="meta-card-meta">
              <ng-container *ngIf="!isLoading">
                <span *ngFor="let meta of formState?.displayData()[field.id].list"
                  ><i *ngIf="meta.icon" class="fal fa-{{ meta.icon }}"></i>{{ meta.value }}</span
                >
              </ng-container>
            </div>
            <div *ngIf="ma.listStyle === 'vertical'" class="meta-card-meta-v">
              <ng-container *ngIf="!isLoading">
                <span *ngFor="let meta of formState?.displayData()[field.id].list"
                  ><i *ngIf="meta.icon" class="fal fa-{{ meta.icon }}"></i>{{ meta.value }}</span
                >
              </ng-container>
            </div>
            <div class="meta-card-description">
              <ng-container #fieldComponent></ng-container>
              <ng-content></ng-content>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
  `,
  styleUrls: ["./metaCard.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaCardComponent extends MetaWrapperBase {
  constructor(_changeDetectorRef: ChangeDetectorRef) {
    super();
  }
}

@NgModule({
  declarations: [MetaCardComponent],
  imports: [CommonModule, MetaGroupModule, MetaAvatarModule],
  exports: [MetaCardComponent],
})
export class MetaCardModule {}
