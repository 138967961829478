/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { Component, Input, OnInit } from "@angular/core";
import { MetaHelperService } from "../../../services/metaHelper.service";
import { IFeedResultItem } from "@meta/api-interfaces";

export class MetaTimelineTemplateWorkflowExecutionStatusChanged {
  data: IFeedResultItem<{
    node: {
      id: string;
      name: string;
      type: string;
    };
    status: string;
  }>;
}

@Component({
  selector: "meta-timeline-template-workflow-execution-status-changed",
  template: `
    <meta-translate key="timeline.workflow-execution-status-changed">
      <ng-template meta-translate-child key="workflowTag">
        <a [href]="item.data.entity.link">
          <meta-tag [maParams]="{ label: tplLabel, color: color, colorText: colorText, maxWidth: 250 }"></meta-tag>
        </a>
      </ng-template>
      <ng-template meta-translate-child key="status">
        <span class="log-default">{{ item.data.data?.status }}</span>
      </ng-template>
    </meta-translate>
    <ng-template #tplLabel><i class="far fa-{{ item.data.entity.icon }}"></i>{{ item.data.entity.title }}</ng-template>
    <meta-live-card
      [cardLazyLoad]="true"
      cardType="WF_EXEC"
      cardSize="small"
      [cardId]="item.data.entity.id"
      [cardData]="item.data.cardData"
    ></meta-live-card>
  `,
})
export class MetaTimelineTemplateWorkflowExecutionStatusChangedComponent implements OnInit {
  @Input() public item: MetaTimelineTemplateWorkflowExecutionStatusChanged;

  public color: string;
  public colorText: string;

  constructor(public metaHelperService: MetaHelperService) {}

  ngOnInit() {
    this.color = this.metaHelperService.generateColor(this.item.data.entity.type);
    this.colorText = getComputedStyle(document.documentElement).getPropertyValue("--component-background");
  }
}
