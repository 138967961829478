/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

// save-action.handler.ts
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { MetaFormService } from "../metaForm.service";

@Injectable()
export class SaveActionHandler {
  constructor(private _metaFormService: MetaFormService) {}

  async handle(action: any, opts: any) {
    if (this._metaFormService.formState.formGroup().valid) {
      const result = await firstValueFrom(
        this._metaFormService.updateFormData({
          formId: this._metaFormService.formState.formId,
          itemId: this._metaFormService.formState.itemId(),
          oldData:
            this._metaFormService.formState.itemId() === "create" ? {} : this._metaFormService.formState.oldData(),
          newData: this._metaFormService.formState.data(),
          publish: false,
          silentUpdate: true,
          completeEditMode: !action.silent,
        }),
      );
      return result;
    } else {
      this._metaFormService.formState.formGroup().markAllAsTouched();
      return false;
    }
  }
}
