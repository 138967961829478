/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { animate, style, transition, trigger } from "@angular/animations";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { EntityType, MetaSize, MetaState, MetaSuffixType } from "@meta/enums";
import { select } from "@ngneat/elf";
import { FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import { FormlyAttributeEvent } from "@ngx-formly/core/lib/models/fieldconfig";
import { MetaFormCondition } from "libs/forms/src/metaState.dto";
import * as _ from "lodash";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzListComponent, NzListModule } from "ng-zorro-antd/list";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalState } from "ng-zorro-antd/modal";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  first,
  firstValueFrom,
  mergeMap,
  Observable,
  skip,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import { filter } from "rxjs/operators";
import * as uuid from "uuid";
import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { MetaActionHandlerFactory } from "../../base/metaForm/actions/actionHandler.factory";
import { MetaFormComponent } from "../../base/metaForm/metaForm.component";
import { MetaFormService } from "../../base/metaForm/metaForm.service";
import { PipesModule } from "../../pipes/pipes.module";
import { MetaEventService } from "../../services/metaEvents.service";
import { MetaModalOptions, MetaModalService } from "../../services/metaModalService";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { MetaAvatarModule } from "../metaAvatar/metaAvatar.component";
import { MetaButtonModule } from "../metaButton/metaButton.component";
import { MetaDrodpownModule, MetaDropdownActionButton } from "../metaDropdown/metaDropdown.component";
import { MetaEmptyModule } from "../metaEmpty/metaEmpty.component";
import { MetaInputModule } from "../metaInput/metaInput.component";
import { MetaLoaderModule } from "../metaLoader/metaLoader.component";
import { MetaSectionModule } from "../metaSection/metaSection.component";
import { MetaSkeletonModule } from "../metaSkeleton/metaSkeleton.component";
import { MetaTagModule } from "../metaTag/metaTag.component";
import { modalActionTarget, modalLabelTarget, modalSublabelTarget } from "../metaToolbar/metaToolbar.interface";
import { MetaListRepository, MetaListStateStore } from "./metaList.repository";

export class MetaList {
  id?: string;
  data?: any[];
  condition?: MetaFormCondition = {
    skip: 0,
    take: 10,
    searchString: "",
  };
  selectedId?: any;
  defaultCondition?: MetaFormCondition;
  params?: { [key: string]: any };
  avatarField? = "label";
  avatarIconField? = "icon";
  descriptionField? = "description";
  avatarIdField? = "QuelleId";
  avatarEntityField? = "QuelleTyp";
  noDataLabel? = "Hier gibts nichts zu sehen";
  noDataIcon? = "ufo";
  labelField? = "label";
  avatarSize?: "large" | "default" | "small" = "default";
  showDummy? = false;
  sortable? = true;
  creatable? = true;
  orderable? = false;
  deletable? = false;
  selectable? = false;
  editable? = false;
  searchable? = true;
  showTotal? = false;
  height?: number | "auto" = 350;
  isLoading? = false;
  loadingScale? = 60;
  virtualScroll? = false;
  size?: MetaSize | string;
  markLockedItems? = false;
  showLoadMore? = false;
  layoutMaxRows?: number | null = null;
  layoutTemplateRows?: number | "infinite" = "infinite";
  extraTemplate?: QueryList<TemplateRef<any>>;
  listItemId?: string;
  hideAvatar? = false;
  entityType? = "ANW";
  splitViewMode? = false;
  onCreate?: FormlyAttributeEvent | ((field) => void);
  afterCreate?: FormlyAttributeEvent | ((field) => void);
  onSelect?: FormlyAttributeEvent | ((field) => void);
  onLoadMore?: FormlyAttributeEvent | ((field) => void);
  leftCompWidth?: number = 14;
  detail?: any;
  isSearchMode?: boolean;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-list",
  template: `
    <div class="_helper"></div>
    <ng-container *ngIf="ma as ma">
      <ng-container *ngIf="!ma.showDummy">
        <div *ngIf="ma.searchable" class="table-search mb-1" [style.width.%]="100">
          <meta-input
            [maParams]="{
              suffix: 'search',
              suffixType: MetaSuffixType.icon,
              placeholder: 'Suche...',
              editing: true
            }"
            [style.width.%]="100"
            [ngModel]="search"
            (ngModelChange)="onSearch($event)"
            class="search"
          ></meta-input>
          <meta-dropdown *ngIf="ma.sortable" [maParams]="sortParams"></meta-dropdown>
          <meta-button
            *ngIf="ma.creatable"
            [maParams]="{
              type: 'primary',
              icon: 'plus',
              disabled: ma.showDummy,
              onClick: addEntry
            }"
            class="btn-add"
            nz-tooltip
            nzTooltipTitle="Hinzufügen"
          ></meta-button>
        </div>
        <div *ngIf="ma.isLoading || isLoading">
          <meta-loader
            [@fadeInFromTop]
            [maParams]="{ scale: ma.loadingScale }"
            [style.minHeight.px]="200"
          ></meta-loader>
        </div>
        <div *ngIf="ma.showTotal && (listState$ | async)?.total > 0" class="list-total">
          {{ (listState$ | async).total | metaNumber }} {{ (listState$ | async).total === 1 ? "Eintrag" : "Einträge" }}
        </div>
        <ng-container
          *ngIf="
            (
              ma?.data ||
              (metaListRepository.getData$(
                ma.condition.searchString,
                _id,
                field && !ma.splitViewMode ? formState.itemId() : _id
              ) | async)
            ).length === 0 && !(ma.isLoading || isLoading)
          "
        >
          <ng-container *ngTemplateOutlet="noResultTemplate"></ng-container>
        </ng-container>
        <div
          (scroll)="onListScroll($event)"
          cdkDropList
          [cdkDropListData]="
            ma?.data ||
            (metaListRepository.getData$(
              ma.condition.searchString,
              _id,
              field && !ma.splitViewMode ? formState.itemId() : _id
            ) | async)
          "
          [cdkDropListDisabled]="!ma.orderable"
          [ngClass]="{ 'auto-height': ma.height === 'auto' }"
        >
          <nz-list
            #list
            [nzItemLayout]="'horizontal'"
            [nzLoadMore]="!ma.virtualScroll ? loadMoreTemplate : null"
            [nzNoResult]="noResultTemplate"
            [ngClass]="{ maSize: ma.size }"
          >
            <ng-container *ngIf="ma.virtualScroll; else tmpElse">
              <cdk-virtual-scroll-viewport
                itemSize="63"
                minBufferPx="{{ 63 * 12 }}"
                maxBufferPx="{{ 63 * 12 }}"
                (scrolledIndexChange)="onListScroll($event)"
                #scrollViewport
                class="example-viewport"
              >
                <ng-container
                  *cdkVirtualFor="
                    let item of ma?.data ||
                      (metaListRepository.getData$(
                        ma.condition.searchString,
                        _id,
                        field && !ma.splitViewMode ? formState.itemId() : _id
                      ) | async);
                    let index = index;
                    let even = even;
                    trackBy: trackByIndex
                  "
                >
                  <meta-section
                    class="list-separator"
                    *ngIf="
                      ((metaListRepository.getDisplayData$(
                        ma.condition.searchString,
                        _id,
                        field && !ma.splitViewMode ? formState.itemId() : _id
                      ) | async) || [])[index]?.group &&
                      ((metaListRepository.getDisplayData$(
                        ma.condition.searchString,
                        _id,
                        field && !ma.splitViewMode ? formState.itemId() : _id
                      ) | async) || [])[index]?.group !==
                        ((metaListRepository.getDisplayData$(
                          ma.condition.searchString,
                          _id,
                          field && !ma.splitViewMode ? formState.itemId() : _id
                        ) | async) || [])[index - 1]?.group
                    "
                    [maParams]="{
                      sectionType: 'hSeparator'
                    }"
                  >
                    <span>{{
                      ((metaListRepository.getDisplayData$(
                        ma.condition.searchString,
                        _id,
                        field && !ma.splitViewMode ? formState.itemId() : _id
                      ) | async) || [])[index]?.group
                    }}</span>
                  </meta-section>
                  <a [routerLink]="item.link" *ngIf="item.link">
                    <ng-container
                      *ngTemplateOutlet="tmpList; context: { item: item, index: index, even: even }"
                    ></ng-container>
                  </a>
                  <ng-container *ngIf="!item.link">
                    <ng-container
                      *ngTemplateOutlet="tmpList; context: { item: item, index: index, even: even }"
                    ></ng-container>
                  </ng-container>
                </ng-container>
              </cdk-virtual-scroll-viewport>
            </ng-container>
            <ng-template #tmpElse>
              <ng-container
                *ngFor="
                  let item of ma?.data ||
                    (metaListRepository.getData$(
                      ma.condition.searchString,
                      _id,
                      field && !ma.splitViewMode ? formState.itemId() : _id
                    ) | async);
                  let index = index;
                  let even = even;
                  trackBy: trackByIndex
                "
              >
                <a [routerLink]="item.link" *ngIf="item.link">
                  <ng-container
                    *ngTemplateOutlet="tmpList; context: { item: item, index: index, even: even }"
                  ></ng-container>
                </a>
                <ng-container *ngIf="!item.link">
                  <ng-container
                    *ngTemplateOutlet="tmpList; context: { item: item, index: index, even: even }"
                  ></ng-container>
                </ng-container>
              </ng-container>
            </ng-template>
            <ng-template #tmpList let-item="item" let-index="index" let-even="even">
              <nz-list-item
                cdkDrag
                [cdkDragData]="item"
                [ngClass]="{
                  selected:
                    (field && formState.itemId() === item._id.join(',')) ||
                    (ma.selectedId && item && ma.selectedId?.toString() === item._id?.toString()),
                  locked: ma.markLockedItems && item?._meta?.locked
                }"
              >
                <nz-list-item-meta
                  [nzAvatar]="
                    !ma.hideAvatar &&
                    (item[ma.avatarField] ||
                      ((metaListRepository.getDisplayData$(
                        ma.condition.searchString,
                        _id,
                        field && !ma.splitViewMode ? formState.itemId() : _id
                      ) | async) || [])[index]?.avatar)
                      ? avatarTemplate
                      : null
                  "
                  [nzDescription]="descriptionTemplate"
                  [nzTitle]="ma.layoutTemplateRows !== 1 ? titleTemplate : null"
                  (click)="selectEntity(item, index)"
                >
                  <ng-template #avatarTemplate>
                    <i *ngIf="ma.orderable" class="fas fa-grip-horizontal grip"></i>
                    <meta-avatar
                      *ngIf="item[ma.avatarField]"
                      [maParams]="{
                        label: item[ma.avatarField],
                        icon: item[ma.avatarIconField],
                        size: ma.size || 'large',
                        id: item._id?.join(',') || item.id || item[ma.avatarIdField],
                        type: entityType || item[ma.avatarEntityField]
                      }"
                    ></meta-avatar>
                    <meta-avatar
                      *ngIf="
                        ((metaListRepository.getDisplayData$(
                          ma.condition.searchString,
                          _id,
                          field && !ma.splitViewMode ? formState.itemId() : _id
                        ) | async) || [])[index]?.avatar
                      "
                      [maParams]="{
                        label: ((metaListRepository.getDisplayData$(
                          ma.condition.searchString,
                          _id,
                          field && !ma.splitViewMode ? formState.itemId() : _id
                        ) | async) || [])[index]?.avatar,
                        size: ma.avatarSize,
                        id: item._id?.join(',') || item.id || item[ma.avatarIdField],
                        type: entityType || item[ma.avatarEntityField]
                      }"
                    ></meta-avatar>
                  </ng-template>

                  <ng-template #descriptionTemplate>
                    <ng-container [ngSwitch]="ma.layoutTemplateRows">
                      <ng-container *ngSwitchCase="1">
                        <div
                          nz-row
                          *ngIf="
                            field &&
                            (
                              metaListRepository.getDisplayData$(
                                ma.condition.searchString,
                                _id,
                                field && !ma.splitViewMode ? formState.itemId() : _id
                              ) | async
                            )?.length > 0
                              ? ((metaListRepository.getDisplayData$(
                                  ma.condition.searchString,
                                  _id,
                                  field && !ma.splitViewMode ? formState.itemId() : _id
                                ) | async) || [])[index]?.layoutTemplates
                              : item[ma.descriptionField] as ele
                          "
                        >
                          <div nz-col [nzSpan]="ma.leftCompWidth" class="description-col">
                            <div nz-row>
                              <div nz-col [nzSpan]="24" class="description-item">
                                <span class="ant-list-item-meta-title" [innerHTML]="ele[0]"></span>
                              </div>
                              <div nz-col [nzSpan]="24" [innerHTML]="ele[1] || '&nbsp;'" class="description-item"></div>
                            </div>
                          </div>
                          <div nz-col [nzSpan]="24 - ma.leftCompWidth" class="description-col text-right">
                            <div nz-row>
                              <div
                                nz-col
                                [nzSpan]="24"
                                [innerHTML]="(item?._meta?.changed || item?._meta?.created | date: 'dd.MM.YYYY') || '-'"
                                class="description-item"
                              ></div>
                              <div nz-col [nzSpan]="24" class="description-item">
                                <meta-tag
                                  *ngIf="item?._meta?.isCreated"
                                  [maParams]="{
                                    label: 'Neu',
                                    type: 'success',
                                    size: 'small'
                                  }"
                                  class="new-tag"
                                  nz-tooltip
                                  nzTooltipTitle="Vor kurzem erstellt"
                                ></meta-tag>
                                <i
                                  *ngIf="item?._meta?.isEdited && !item?._meta?.isCreated"
                                  class="fas fa-pencil pl-2"
                                  nz-tooltip
                                  nzTooltipTitle="Vor kurzem bearbeitet"
                                ></i>
                                <i
                                  *ngIf="ma.markLockedItems && item?._meta?.locked"
                                  class="fas fa-lock pl-2"
                                  nz-tooltip
                                  nzTooltipTitle="Gesperrt"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchCase="2">
                        <div
                          nz-row
                          *ngIf="
                            field &&
                            (
                              metaListRepository.getDisplayData$(
                                ma.condition.searchString,
                                _id,
                                field && !ma.splitViewMode ? formState.itemId() : _id
                              ) | async
                            )?.length > 0
                              ? ((metaListRepository.getDisplayData$(
                                  ma.condition.searchString,
                                  _id,
                                  field && !ma.splitViewMode ? formState.itemId() : _id
                                ) | async) || [])[index]?.layoutTemplates
                              : item[ma.descriptionField] as ele
                          "
                        >
                          <div nz-col [nzSpan]="12" class="description-col">
                            <div nz-row>
                              <div nz-col [nzSpan]="24" [innerHTML]="ele[1]" class="description-item"></div>
                              <div nz-col [nzSpan]="24" [innerHTML]="ele[2]" class="description-item"></div>
                            </div>
                          </div>
                          <div nz-col [nzSpan]="8" class="description-col">
                            <div nz-row>
                              <div nz-col [nzSpan]="24" [innerHTML]="ele[3]" class="description-item"></div>
                              <div nz-col [nzSpan]="24" [innerHTML]="ele[4]" class="description-item"></div>
                            </div>
                          </div>
                          <div nz-col [nzSpan]="4" class="description-col">
                            <div nz-row>
                              <div nz-col [nzSpan]="24" [innerHTML]="ele[5]" class="description-item"></div>
                              <div nz-col [nzSpan]="24" [innerHTML]="ele[6]" class="description-item"></div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-template ngSwitchDefault>
                        @if (field) {
                          <ng-container
                            *ngFor="
                              let ele of field &&
                              (
                                metaListRepository.getDisplayData$(
                                  ma.condition.searchString,
                                  _id,
                                  field && !ma.splitViewMode ? formState.itemId() : _id
                                ) | async
                              )?.length > 0
                                ? ((metaListRepository.getDisplayData$(
                                    ma.condition.searchString,
                                    _id,
                                    field && !ma.splitViewMode ? formState.itemId() : _id
                                  ) | async) || [])[index]?.layoutTemplates
                                : item[ma.descriptionField];
                              let index = index
                            "
                          >
                            <div
                              *ngIf="
                                (!(
                                  item[ma.descriptionField] ||
                                  (metaListRepository.getDisplayData$(
                                    ma.condition.searchString,
                                    _id,
                                    field && !ma.splitViewMode ? formState.itemId() : _id
                                  ) | async)
                                )?.length === 0 ||
                                  ((
                                    item[ma.descriptionField] ||
                                    (metaListRepository.getDisplayData$(
                                      ma.condition.searchString,
                                      _id,
                                      field && !ma.splitViewMode ? formState.itemId() : _id
                                    ) | async)
                                  )?.length > 0 &&
                                    index > 0)) &&
                                (ma.layoutMaxRows === null || ma.layoutMaxRows > index)
                              "
                              [innerHTML]="ele"
                            ></div>
                          </ng-container>
                        } @else {
                          <ng-container *ngFor="let ele of item[ma.descriptionField]; let index = index">
                            <div [innerHTML]="ele"></div>
                          </ng-container>
                        }
                      </ng-template>
                    </ng-container>
                  </ng-template>

                  <ng-template #titleTemplate>
                    <span [ngClass]="{ locked: ma.markLockedItems && item?._meta?.locked }"
                      ><i
                        *ngIf="ma.markLockedItems && item?._meta?.locked"
                        class="fas fa-lock pr-2"
                        nz-tooltip
                        nzTooltipTitle="Gesperrt"
                      ></i>
                      <span
                        [innerHtml]="
                          field &&
                          (
                            metaListRepository.getDisplayData$(
                              ma.condition.searchString,
                              _id,
                              field && !ma.splitViewMode ? formState.itemId() : _id
                            ) | async
                          )?.length > 0
                            ? ((metaListRepository.getDisplayData$(
                                ma.condition.searchString,
                                _id,
                                field && !ma.splitViewMode ? formState.itemId() : _id
                              ) | async) || [])[index]?.layoutTemplates[0]
                            : item[ma.labelField]
                        "
                      ></span>
                      <meta-tag
                        *ngIf="item['new']"
                        [maParams]="{ label: 'Neu', type: 'success', size: 'small' }"
                      ></meta-tag
                    ></span>
                  </ng-template>
                </nz-list-item-meta>
                <ng-container *ngFor="let template of extraTemplate">
                  <ng-container *ngTemplateOutlet="template; context: { data: item }"></ng-container>
                </ng-container>
                <meta-button *ngIf="ma.deletable" [maParams]="getDeleteParams(item)" class="btn-edit"></meta-button>
              </nz-list-item>
            </ng-template>
          </nz-list>
        </div>
      </ng-container>
      <meta-empty *ngIf="ma.showDummy" [style.height.px]="350" [maParams]="{ icon: 'list' }"></meta-empty>

      <ng-template #loadMoreTemplate>
        <div
          *ngIf="
            (ma.showLoadMore || (listState$ | async)?.total > ma.condition?.take + ma.condition?.skip) &&
            ((!isLoading && !ma.isLoading) || ma.condition?.skip > 0)
          "
          class="mt-4 d-block"
        >
          <meta-button
            [maParams]="{
              loading: ma.condition?.skip > 0 ? isLoading || ma.isLoading : false,
              label: 'Mehr laden',
              icon: 'chevron-down',
              fullWidth: true,
              onClick: onLoadMore.bind(this)
            }"
          ></meta-button>
        </div>
      </ng-template>

      <ng-template #noResultTemplate>
        <meta-empty class="mt-5" [maParams]="{ description: ma.noDataLabel, icon: ma.noDataIcon }"></meta-empty>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ["./metaList.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("fadeInFromTop", [
      transition(":enter", [style({ transform: "translateY(-30px)" }), animate(".3s cubic-bezier(.37,.66,.12,1.17)")]),
      transition(":leave", [animate(".3s cubic-bezier(.37,.66,.12,1.17)", style({ transform: "translateY(-30px)" }))]),
    ]),
  ],
})
export class MetaListComponent extends MetaComponentBase implements OnInit, OnDestroy, OnChanges {
  @ViewChild("list") public list: NzListComponent;
  @ContentChildren("maExtraTemplate") public extraTemplate: QueryList<TemplateRef<any>>;
  public _id: string;
  public MetaSuffixType = MetaSuffixType;
  public entityType: EntityType;
  public searchTerm = new BehaviorSubject<string>("");
  public sortMenu: MetaDropdownActionButton[] = [];
  public search: string;
  public isSaving = false;
  public isDeleting = false;
  public isBelowScrollArea$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public height: number;
  public listState$: Observable<MetaListStateStore>;
  public sortParams = {
    items: this.sortMenu,
    icon: "sort-alt",
    type: "text",
  };
  public fireAfterCreateWithId: string[];
  @ViewChild("scrollViewport") private _cdkVirtualScrollViewport;
  private _skip = new BehaviorSubject<number>(null);
  private _destroyed$ = new Subject<void>();
  private _isInitialized = false;
  private _contextId: string;

  constructor(
    public metaListRepository: MetaListRepository,
    private readonly _nzMessageService: NzMessageService,
    private readonly _modalService: MetaModalService,
    private readonly _metaEventService: MetaEventService,
    private readonly _metaActionHandler: MetaActionHandlerFactory,
  ) {
    super();
    super.maParams = new MetaList();
  }

  get ma(): MetaList {
    return super.ma;
  }

  async ngOnInit() {
    if (this.field) {
      this.options.formState
        .onFormDataReady()
        .pipe(takeUntil(this._destroyed$), distinctUntilChanged())
        .subscribe({
          next: (model) => {
            if (this.ma.splitViewMode && this._isInitialized) {
              return;
            }
            if (model?._ctx) {
              this._contextId = model._ctx;
            } else {
              this._contextId = null;
            }
            if (!this._isInitialized) {
              this.init();
            } else {
              this.refreshData(true);
            }
          },
        });
    } else {
      await this.init();
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.maParams.currentValue && this.ma.isSearchMode) {
      this.ma.data = changes.maParams.currentValue.data;
    }
  }

  async init() {
    this._isInitialized = true;
    if (!this.field) {
      this._id = this.ma.id || uuid.v4();
    } else {
      this._id = this.id;
    }

    this.entityType = (this.ma.entityType || "ANW") as any;

    if (this.ma.defaultCondition) {
      this.ma.condition = _.clone(_.merge(this.ma.condition, this.ma.defaultCondition));
    }

    this.listState$ = this.metaListRepository.listState$.pipe(
      select((state) => state[this._id]),
      takeUntil(this._destroyed$),
    );

    if (this.field) {
      this.searchTerm
        .pipe(
          skip(1), // Skip initial value from BehaviorSubject
          debounceTime(500),
          distinctUntilChanged(),
          takeUntil(this._destroyed$),
          tap((e) => {
            this.isLoading = true;
            this.changeDetectorRef.markForCheck();
            this.ma.condition.searchString = e;
            this.ma.condition.skip = 0;
            this._skip.next(null);
          }),
          switchMap(() =>
            this.metaListRepository.getData({
              formId: this._id,
              fieldId: this._id,
              itemId: this.field && !this.ma.splitViewMode ? this.formState.itemId() : this._id,
              contextId: this._contextId,
              condition: this.ma.condition,
              params: null,
              listItemId: this.ma.listItemId,
            }),
          ),
          tap((e) => {
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
          }),
        )
        .subscribe();

      this._skip
        .pipe(
          skip(1), // Skip initial value from BehaviorSubject
          distinctUntilChanged(),
          takeUntil(this._destroyed$),
          filter((e) => e !== null),
          tap((e) => {
            this.isLoading = true;
            this.ma.condition.skip = e || 0;
          }),
          mergeMap(() =>
            this.metaListRepository.getData({
              formId: this._id,
              fieldId: this._id,
              itemId: this.field && !this.ma.splitViewMode ? this.formState.itemId() : this._id,
              contextId: this._contextId,
              condition: this.ma.condition,
              params: null,
              silentUpdate: true,
              listItemId: this.ma.listItemId,
            }),
          ),
          tap(() => (this.isLoading = false)),
        )
        .subscribe();

      this.isBelowScrollArea$
        .pipe(takeUntil(this._destroyed$), distinctUntilChanged(), debounceTime(500))
        .subscribe(async (isBelowScrollArea) => {
          const state = await firstValueFrom(this.metaListRepository.listState$.pipe(select((s) => s[this._id])));
          const total = state?.total || 0;
          if (isBelowScrollArea && total > (this._skip.value || 0) + (this.ma.condition.take || 0)) {
            this._skip.next((this._skip.value || 0) + this.ma.condition.take);
          }
          this.isBelowScrollArea$.next(false);
        });

      this.formState.data$
        .pipe(
          filter((x) => x !== undefined),
          first(),
          tap(() => this._skip.next(0)),
        )
        .subscribe();

      this.metaListRepository
        .getData$(
          this.ma.condition.searchString,
          this._id,
          this.field && !this.ma.splitViewMode ? this.formState.itemId() : this._id,
        )
        .pipe(
          filter((x) => x !== undefined),
          takeUntil(this._destroyed$),
        )
        .subscribe({
          next: () => {
            if (this.fireAfterCreateWithId) {
              this.ma.afterCreate(this.field, this.fireAfterCreateWithId);
              this.fireAfterCreateWithId = null;
            }
          },
        });

      this.formState.state$
        .pipe(
          takeUntil(this._destroyed$),
          filter((x) => x !== undefined && x === MetaState.saved && this.field?.parent?.type === "meta-split-view"),
        )
        .subscribe({
          next: (state: MetaState) => {
            if (state === MetaState.saved) {
              this.refreshData();
            }
          },
        });

      this._metaEventService.reloadTrigger$
        .pipe(
          debounceTime(50),
          filter((x) => x !== undefined && x !== null),
          takeUntil(this.destroyed$),
        )
        .subscribe({
          next: async (trigger) => {
            if (trigger.specific) {
              if (trigger.formId === this._id) {
                this.refreshData();
              }
            } else {
              this.refreshData();
            }
          },
        });

      this._skip.next(0);
      this.getListStructure();
    }
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public getListStructure() {
    this.field.fieldArray["fieldGroup"].forEach((f) => {
      if (f.type === "meta-input") {
        this.sortMenu.push({
          id: f.id,
          label: `${f.props.label} aufsteigend`,
          icon: "",
          data: {
            sort: "asc",
          },
          onClick: () => {
            this.sortEvent(f.id, "asc");
          },
        });
        this.sortMenu.push({
          id: f.id,
          label: `${f.props.label} absteigend`,
          icon: "",
          data: {
            sort: "desc",
          },
          onClick: () => {
            this.sortEvent(f.id, "desc");
          },
        });
      }
    });
  }

  public sortEvent(id: string, sort: "asc" | "desc") {
    this.ma.condition.sort = [
      {
        field: id,
        dir: sort,
      },
    ];
    this.sortMenu = this.sortMenu.map((sm: any) => {
      if (sm.id === id && sm.data.sort === sort) {
        sm.icon = "check";
      } else {
        sm.icon = "";
      }
      return sm;
    });
    this.refreshData();
  }

  public getOffset(element) {
    let top = 0,
      left = 0;
    do {
      top += element.offsetTop || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while (element);

    return {
      top: top,
      left: left,
    };
  }

  /**
   * Adds a new Entry to the list
   */
  public addEntry = async (field: FormlyFieldConfig, event?: any) => {
    if (this.ma.splitViewMode && this.options.formState.form().wizard) {
      const { MetaFormComponent } = await import("../../base/metaForm/metaForm.component");
      const modal = this._modalService.create({
        nzContent: MetaFormComponent,
        nzTitle: "",
        nzWidth: "1080px",
        nzMaskClosable: false,
        nzCloseOnNavigation: true,
        nzClosable: true,
        nzClassName: "ma-subform",
        nzWrapClassName: "ma-subform-wrapper",
        nzBodyStyle: {
          position: "relative",
          display: "flex",
          "flex-direction": "column",
        },
        nzComponentParams: {
          maFormId: this.options.formState.form().wizard,
          maExternalData: {},
          maItemId: "create",
          maEditing: true,
          maCloseModalAfterSave: true,
          maCloseModalAfterCancel: true,
        },
      });

      modal.afterClose.subscribe((result) => {
        if (result?._id) {
          if (this.ma.afterCreate) {
            this.fireAfterCreateWithId = result?._id;
          }
          this.refreshData();
        }
      });
    } else {
      if (this.ma.onCreate) {
        this.ma.onCreate(field, event);
      }
      const result = await this._metaActionHandler.executeCreateAction({
        formId: this.ma.detail?.parent?.id || this._id,
        ctx: this._contextId,
      });
      if (result) {
        this.refreshData();
      }
    }
  };

  public onListScroll($event) {
    const end = this._cdkVirtualScrollViewport.getRenderedRange().end;
    const total = this._cdkVirtualScrollViewport.getDataLength();

    if (this.ma.virtualScroll && total > 0) {
      if (end >= total - this.ma.condition.take + 1 || end === total) {
        this.isBelowScrollArea$.next(true);
      } else {
        this.isBelowScrollArea$.next(false);
      }
    }
  }

  /**
   * Gets called if a list item is selected
   * @param item Selected list item
   * @param index
   */
  public async selectEntity(item: any, index: number) {
    if (this.ma.selectable) {
      const result = await this._metaActionHandler.executeSelectAction({
        controlId: null,
        data: item,
        formId: this._id,
      });
      if (result) {
        this.refreshData(true);
      }
    } else if (item?._id) {
      this.metaListRepository.setListState(this._id, {
        selectedItem: item._id.join(","),
      });
    }
    if (this.ma.onSelect instanceof Function) {
      this.ma.onSelect(this.field, item);
    }
  }

  public onSearch(term: string) {
    this.searchTerm.next(term);
  }

  public async deleteEntry(item) {
    this.isDeleting = true;
    await this._metaActionHandler.executeSocketAction({
      formId: this._id,
      field: null,
      type: "onDelete",
      data: item,
    });
    this._nzMessageService.success("Datensatz erfolgreich gelöscht.");
    await this.metaListRepository.removeData(this._id, item._id, this.ma.condition);
    this.isDeleting = false;
  }

  public trackByIndex(index, item) {
    return index;
  }

  public getDeleteParams(item: any) {
    return {
      popConfirm: {
        label: "Diesen Datensatz wirklich löschen?",
        placement: "left",
        onOk: () => {
          this.deleteEntry(item);
        },
      },
      loading: this.isDeleting,
      icon: "trash",
      iconStyle: "fas",
      size: "small",
      type: "link",
      danger: true,
    };
  }

  public refreshData(softRefresh = false) {
    if (!softRefresh) {
      this._skip.next(null);
      this.ma.condition.skip = 0;
      this.isLoading = true;
    }

    this.metaListRepository
      .getData({
        formId: this._id,
        fieldId: this._id,
        itemId: this.field && !this.ma.splitViewMode ? this.formState.itemId() : this._id,
        contextId: this._contextId,
        condition: softRefresh
          ? {
              ...this.ma.condition,
              skip: 0,
              take: this.ma.condition.skip + this.ma.condition.take,
            }
          : this.ma.condition,
        listItemId: this.ma.listItemId,
        silentUpdate: softRefresh,
        params: null,
      })
      .pipe(
        first(),
        tap(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  public onLoadMore() {
    if (this.ma.onLoadMore instanceof Function) {
      this.ma.onLoadMore(this.field, (this._skip.value || 0) + this.ma.condition.take);
    }
    this._skip.next((this._skip.value || 0) + this.ma.condition.take);
  }
}

@NgModule({
  declarations: [MetaListComponent],
  imports: [
    CommonModule,
    FormlyModule,
    MetaInputModule,
    MetaDrodpownModule,
    MetaButtonModule,
    MetaSkeletonModule,
    NzListModule,
    ScrollingModule,
    DragDropModule,
    MetaAvatarModule,
    NzGridModule,
    MetaEmptyModule,
    FormsModule,
    NzToolTipModule,
    MetaTagModule,
    MetaLoaderModule,
    RouterModule,
    PipesModule,
    MetaSectionModule,
  ],
  exports: [MetaListComponent],
})
export class MetaListModule {}
