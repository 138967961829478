/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { ExposeProperty, MetaFormComponent, MetaFormComponentInfo } from "./baseComponent";
import { Expose } from "class-transformer";
import { IFormUserConfiguration } from "../formUserConfiguration";

@MetaFormComponentInfo({
  selector: "ma-no-output",
  type: "no-output",
  name: "No Output",
  icon: "times",
  hideInPallet: true,
  description: "Excludes everything inside from Rendering at the Frontend.",
})
export class NoOutputComponent extends MetaFormComponent {
  @ExposeProperty() public parentId?: string = null;
  @ExposeProperty() public children?: MetaFormComponent[] = [];

  public static createElement(attributes: any, children: any[]) {
    const element = new NoOutputComponent();
    Object.assign(element, {
      ...attributes,
      children,
    });
    return element;
  }

  toFormly(config: IFormUserConfiguration): any {
    return null;
  }
}
