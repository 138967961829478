/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2020
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2020
 */

export * from "./files.dto";
export * from "./activities.dto";
export * from "./print.dto";
export * from "./common.dto";
export * from "./auth.dto";
export * from "./dashboard.dto";
export * from "./favorites.dto";
export * from "./menu.dto";
export * from "./chart.dto";
export * from "./updater.dto";
export * from "./specifications.dto";
export * from "./pipelines.dto";
export * from "./workflow";
export * from "./notifications.dto";
export * from "./pipelineV2.dto";
export * from "./push";
export * from "./securpharm.dto";
export * from "./sick.dto";
export * from "./feed";
export * from "./live-card";
export * from "./appConfig";
export * from "./userFeatures";

export class ForbiddenExceptionResponse {
  statusCode: number;
  error: string;
  message: string;
}

export class UnauthorizedExceptionResponse {
  statusCode: number;

  error: string;

  message: string;
}
