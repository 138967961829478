/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { NgxTolgeeModule } from "@tolgee/ngx";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  MetaAvatarModule,
  MetaButtonModule,
  MetaDrodpownModule,
  MetaEditorModule,
  MetaEmptyModule,
  MetaFieldWrapperModule,
  MetaInputModule,
  MetaPillModule,
  MetaSectionModule,
  MetaTagModule,
  MetaTimelineModule,
} from "@meta/ui";
import { MetaTaskService } from "./metaTask.service";
import { MetaInputComponent } from "../metaInput/metaInput.component";
import { MetaTaskRepository } from "./metaTask.repository";
import { BehaviorSubject, firstValueFrom, Subject, takeUntil } from "rxjs";
import { UserService } from "../../services/user.service";
import { NzListModule } from "ng-zorro-antd/list";
import { MetaIconModule } from "../metaIcon/metaIcon.component";
import { MetaCheckboxModule } from "../metaCheckbox/metaCheckbox.component";
import { FormsModule } from "@angular/forms";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { RouterModule } from "@angular/router";
import { PipesModule } from "../../pipes/pipes.module";
import { DynamicIoModule } from "ng-dynamic-component";
import { MetaTaskSubtasksComponent } from "./metaTask.subtasks.component";
import { TaskDto } from "../../../../../api-interfaces/src/lib/api-interfaces/task/task.dto";
import { MetaHelperService } from "../../services/metaHelper.service";
import { MetaModalService } from "../../services/metaModalService";

const animationTime = 2000;
const animationDelay = 500;
const takeOpen = 5000;
const takeDone = 50;
const takeSub = 5000;

export class MetaTaskList {
  taskId?: string;
  style?: "list" | "timeline" = "list";
  condensed?: boolean = false;
  dataToShow?: "open" | "done" | "today" = "open";
  parentId?: string;
  sourceId?: string;
  sourceType?: string;
  checkableField?: string = "done";
  checkableTooltip?: string = "Aufgabe erledigen";
  isFormlyContext?: boolean;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-task-list",
  template: `
    <meta-pill
      *ngIf="
        !maParams.parentId && maParams.style === 'list' && maParams.dataToShow !== 'today' && !maParams.isFormlyContext
      "
      [maParams]="{ label: maParams.dataToShow === 'open' ? 'Offene Aufgaben' : 'Abgeschlossene Aufgaben' }"
    ></meta-pill>
    <meta-timeline
      *ngIf="
        maParams.style === 'timeline' &&
          !isLoading &&
          metaTaskRepository.getData$(
            maParams.sourceId,
            maParams.sourceType,
            maParams.dataToShow === 'done',
            maParams.parentId,
            maParams.dataToShow === 'today',
            maParams.taskId
          ) | async as data
      "
      [maParams]="{
        data: data,
        groupField: 'dateDone',
        style: 'boxed',
        noDataLabel: 'Noch keine Aufgaben vorhanden.',
        noDataIcon: 'clipboard',
        extra: extraTpl,
        content: taskTpl,
        checkableField: 'done',
        checkableTooltip: 'Aufgabe erledigen'
      }"
      class="meta-activity-timeline"
    ></meta-timeline>
    <ng-container
      *ngIf="
        maParams.style === 'list' &&
          !isLoading &&
          metaTaskRepository.getData$(
            maParams.sourceId,
            maParams.sourceType,
            maParams.dataToShow === 'done',
            maParams.parentId,
            maParams.dataToShow === 'today',
            maParams.taskId
          ) | async as data
      "
    >
      <meta-empty
        *ngIf="data.length === 0 && maParams.dataToShow === 'today'"
        [maParams]="{ description: ('tasks.empty_text' | translate), icon: 'planet-ringed' }"
      ></meta-empty>

      <ng-container *ngFor="let item of data; trackBy: trackByID; let index = index">
        <ng-container
          *ngTemplateOutlet="maParams.parentId ? subtaskTpl : taskTpl; context: { $implicit: item }"
        ></ng-container>
      </ng-container>
      <meta-button
        *ngIf="
          !maParams.parentId &&
          maParams.dataToShow === 'done' &&
          data.length < metaTaskRepository.total[maParams.sourceId + maParams.sourceType]
        "
        [maParams]="{ label: 'Mehr ...', type: 'default', fullWidth: true, loading: isLoadingMore }"
        (click)="loadMore()"
      ></meta-button>
    </ng-container>
    <ng-template #extraTpl let-item>
      <meta-dropdown
        [maParams]="{
          icon: 'ellipsis-h',
          iconStyle: 'fas',
          size: 'small',
          type: 'text',
          items: [
            {
              label: 'Bearbeiten',
              icon: 'pencil',
              onClick: onEditTask.bind(this, item)
            },
            {
              label: 'Löschen',
              icon: 'trash',
              onClick: onRemoveTask.bind(this, item),
              disabled: item.data.userCreated.value !== userService.user.value.id
            },
            {
              label: 'Unteraufgabe hinzufügen',
              icon: 'plus',
              onClick: toggleSubtaskForm.bind(this, item)
            }
          ]
        }"
      >
      </meta-dropdown>
    </ng-template>
    <ng-template #subExtraTpl let-item>
      <meta-dropdown
        [maParams]="{
          icon: 'ellipsis-h',
          iconStyle: 'fas',
          size: 'small',
          type: 'text',
          items: [
            {
              label: 'Löschen',
              icon: 'trash',
              onClick: onRemoveTask.bind(this, item)
            }
          ]
        }"
      >
      </meta-dropdown>
    </ng-template>
    <ng-template #prioTpl let-item>
      <meta-dropdown
        [maParams]="{
          label: 'Prio ' + item.data.priority,
          icon: 'flag',
          iconStyle: 'fas',
          size: 'tiny',
          color:
            item.data.priority === 1
              ? 'red'
              : item.data.priority === 2
                ? 'yellow'
                : item.data.priority === 3
                  ? 'green'
                  : null,
          items: [
            {
              label: 'Prio 1',
              icon: 'flag',
              color: 'red',
              onClick: onSetPrio.bind(this, item, 1)
            },
            {
              label: 'Prio 2',
              icon: 'flag',
              color: 'yellow',
              onClick: onSetPrio.bind(this, item, 2)
            },
            {
              label: 'Prio 3',
              icon: 'flag',
              color: 'green',
              onClick: onSetPrio.bind(this, item, 3)
            },
            {
              label: 'Prio 4',
              icon: 'flag',
              onClick: onSetPrio.bind(this, item, 4)
            }
          ]
        }"
      >
      </meta-dropdown>
    </ng-template>
    <ng-template #taskTpl let-item>
      <div
        class="item"
        [ngClass]="{
          'checkable-item': maParams.checkableField,
          'animation-check-item': item?.data._markAsChecked,
          'item-is-done': item.data[maParams.checkableField]
        }"
      >
        <ng-container>
          <div class="checkable-overlay">
            <meta-icon
              *ngIf="item?.data._markAsChecked"
              [maParams]="{
                icon: 'zczmziog',
                trigger: 'loop',
                size: 60,
                style: 'light'
              }"
            ></meta-icon>
          </div>
          <div class="checkable-check">
            <meta-checkbox
              [maParams]="{ editing: true }"
              [ngModel]="item.data[maParams.checkableField]"
              (ngModelChange)="onToggleCheck(item)"
              [nz-tooltip]="maParams.checkableTooltip"
              [nzTooltipTitle]="maParams.checkableTooltip"
              [nzTooltipMouseEnterDelay]="1"
            ></meta-checkbox>
          </div>
          <div class="checkable-content">
            <div class="checkable-inner-content">
              <div
                id="description-{{ item.id }}"
                class="description"
                [style.flex-direction]="maParams.condensed ? 'column' : 'row'"
                [ngClass]="{ expandable: isExpandable(item), expanded: isExpanded[item.id] }"
              >
                <span
                  *ngIf="!item.data._editing; else editingTaskTitleTpl"
                  [style.white-space]="'break-spaces'"
                  (click)="onEditTask(item)"
                  [innerHtml]="item.title"
                  nz-tooltip
                  [nzTooltipTitle]="
                    item.metadata?.created?.tooltip + (item.metadata.done ? '. ' + item.metadata.done.tooltip : '')
                  "
                ></span>
                @if (maParams.condensed) {
                  <ng-container *ngTemplateOutlet="metadata"></ng-container>
                }
              </div>
              <ng-template #metadata>
                <div *ngIf="item.metadata" class="metadata">
                  <ng-container *ngTemplateOutlet="prioTpl; context: { $implicit: item }"></ng-container>
                  <meta-tag
                    *ngIf="item.metadata.priority"
                    nz-tooltip
                    [nzTooltipTitle]="item.metadata.priority.tooltip"
                    [maParams]="{
                      label: item.metadata.priority.label,
                      type: item.metadata.priority.color,
                      maxWidth: 400,
                      icon: item.metadata.priority.icon
                    }"
                  ></meta-tag>
                  @if (item.metadata.due) {
                    <meta-tag
                      nz-tooltip
                      [nzTooltipTitle]="item.metadata.due.tooltip"
                      [maParams]="{
                        label: 'tasks.due_relative' | translate: item.metadata.due,
                        type: item.metadata.due.color,
                        maxWidth: 400,
                        icon: item.metadata.due.icon
                      }"
                    ></meta-tag>
                  }

                  <meta-tag
                    nz-tooltip
                    [nzTooltipTitle]="item.metadata.comments + ' Kommentar' + (item.metadata.comments !== 1 ? 'e' : '')"
                    [maParams]="{
                      label: item.metadata.comments,
                      type: 'default',
                      maxWidth: 400,
                      icon: 'comment'
                    }"
                  ></meta-tag>
                  <meta-avatar
                    *ngIf="item.metadata.users"
                    [maParams]="{
                      data: item.metadata.users.data,
                      type: 'ANW',
                      size: 26
                    }"
                  ></meta-avatar>
                  <ng-container *ngIf="!maParams.sourceType && item.data.entity?.link">
                    <a [routerLink]="item.data.entity.link">
                      <meta-tag
                        nz-tooltip
                        nzTooltipTitle="{{ item.data.entity.name }} |
                    {{ item.data.entity.title }}"
                        [maParams]="{
                          label: tplLabel,
                          color: metaHelperService.generateColor(item.data.sourceType),
                          maxWidth: 100
                        }"
                      ></meta-tag>
                    </a>
                    <ng-template #tplLabel
                      ><i class="fal fa-{{ item.data.entity.icon }}"></i> {{ item.data.entity.name }} |
                      {{ item.data.entity.title }}</ng-template
                    >
                  </ng-container>
                </div>
              </ng-template>
              @if (!maParams.condensed) {
                <ng-container *ngTemplateOutlet="metadata"></ng-container>
              }
              <div class="extra">
                <ng-container *ngTemplateOutlet="extraTpl; context: { $implicit: item }"></ng-container>
              </div>
            </div>
            <ng-template #editingTaskTitleTpl>
              <div [style.display]="'flex'" [style.flex-direction]="'column'" [style.width.%]="100">
                <meta-input
                  [style.width.%]="100"
                  class="mb-2"
                  [maParams]="{
                    editing: true,
                    placeholder: 'Aufgabentitel...',
                    disabled: isSaving,
                    required: true
                  }"
                  (keydown.enter)="onSave(item)"
                  [(ngModel)]="item.data.title"
                ></meta-input>
                <meta-editor
                  [maParams]="{
                    editing: true,
                    placeholder: 'Beschreibung...',
                    disabled: isSaving
                  }"
                  [(ngModel)]="item.data.description"
                ></meta-editor>
                <div [style.display]="'flex'">
                  <meta-button
                    [maParams]="{
                      type: 'primary',
                      label: 'Speichern',
                      size: 'small',
                      loading: isSaving,
                      disabled: !item.data.title
                    }"
                    (click)="onSave(item)"
                  ></meta-button>
                  <meta-button
                    [maParams]="{
                      type: 'text',
                      label: 'Abbrechen',
                      size: 'small'
                    }"
                    (click)="item.data._editing = false"
                  ></meta-button>
                </div>
              </div>
            </ng-template>
            <div class="subtasks" (click)="toggleSubtasks(item)" *ngIf="item.data.children > 0">
              <span class="subtasks-toggle" (click)="item.data._showSubtasks = !item.data._showSubtasks">
                <i class="arrow fas fa-{{ item.data._showSubtasks ? 'angle-down' : 'angle-right' }}"></i
                ><i class="fas fa-square-check"></i> {{ item.data.childrenDone }}/{{ item.data.children }}
              </span>
              <meta-section
                *ngIf="item.data._showSubtasks"
                [maParams]="{ sectionType: 'space', spacingBottom: 2 }"
              ></meta-section>
              <ng-template
                *ngIf="item.data._showSubtasks"
                [ngComponentOutlet]="componentTasklist"
                [ndcDynamicInputs]="{
                  maParams: {
                    sourceId: maParams.sourceId,
                    sourceType: maParams.sourceType,
                    dataToShow: 'open',
                    parentId: item.id
                  }
                }"
              ></ng-template>
              <ng-template
                *ngIf="item.data._showSubtasks"
                [ngComponentOutlet]="componentTasklist"
                [ndcDynamicInputs]="{
                  maParams: {
                    sourceId: maParams.sourceId,
                    sourceType: maParams.sourceType,
                    dataToShow: 'done',
                    parentId: item.id
                  }
                }"
              ></ng-template>
            </div>
            <meta-button
              *ngIf="isExpandable(item) && !isExpanded[item.id]"
              [maParams]="{
                type: 'link',
                label: 'Mehr anzeigen',
                icon: 'angle-down'
              }"
              (click)="toggleExpand(item)"
            ></meta-button>
            <meta-button
              *ngIf="isExpandable(item) && isExpanded[item.id]"
              [maParams]="{
                type: 'link',
                label: 'Weniger anzeigen',
                icon: 'angle-up'
              }"
              (click)="toggleExpand(item)"
            ></meta-button>
            <ng-container *ngIf="showSubtaskForm[item.id]">
              <ng-template
                [ngComponentOutlet]="componentSubtasks"
                [ndcDynamicInputs]="{
                  maParams: {
                    sourceId: maParams.sourceId,
                    sourceType: maParams.sourceType,
                    parentId: item.id,
                    editing: true
                  }
                }"
              ></ng-template>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-template>
    <ng-template #subtaskTpl let-item>
      <div
        class="item subitem"
        [ngClass]="{ 'checkable-item': maParams.checkableField, 'item-is-done': item.data[maParams.checkableField] }"
      >
        <ng-container>
          <div class="checkable-overlay">
            <meta-icon
              *ngIf="item?.data._markAsChecked"
              [maParams]="{
                icon: 'zczmziog',
                trigger: 'loop',
                size: 60,
                style: 'light'
              }"
            ></meta-icon>
          </div>
          <div class="checkable-check">
            <meta-checkbox
              [maParams]="{ editing: true }"
              [ngModel]="item.data[maParams.checkableField]"
              (ngModelChange)="onToggleCheck(item)"
              [nz-tooltip]="maParams.checkableTooltip"
              [nzTooltipTitle]="maParams.checkableTooltip"
              [nzTooltipMouseEnterDelay]="1"
            ></meta-checkbox>
          </div>
          <div class="checkable-content">
            <span
              *ngIf="!item.data._editing; else editingSubTaskTitleTpl"
              [style.white-space]="'break-spaces'"
              (click)="item.data._editing = true"
              >{{ item.title }}</span
            >
            <div class="extra sub-extra">
              <ng-container *ngIf="maParams.dataToShow === 'open'">
                <ng-container *ngTemplateOutlet="prioTpl; context: { $implicit: item }"></ng-container>
              </ng-container>
              <ng-container *ngTemplateOutlet="subExtraTpl; context: { $implicit: item }"></ng-container>
            </div>
            <ng-template #editingSubTaskTitleTpl>
              <div [style.display]="'flex'" [style.flex-direction]="'column'" [style.width.%]="100">
                <meta-input
                  [style.width.%]="100"
                  class="mb-2"
                  [maParams]="{
                    editing: true,
                    placeholder: 'Aufgabentitel...',
                    disabled: isSaving,
                    required: true
                  }"
                  (keydown.enter)="onSave(item)"
                  [(ngModel)]="item.data.title"
                ></meta-input>
                <div [style.display]="'flex'">
                  <meta-button
                    [maParams]="{
                      type: 'primary',
                      label: 'Speichern',
                      size: 'small',
                      loading: isSaving,
                      disabled: !item.data.title
                    }"
                    (click)="onSave(item)"
                  ></meta-button>
                  <meta-button
                    [maParams]="{
                      type: 'text',
                      label: 'Abbrechen',
                      size: 'small'
                    }"
                    (click)="item.data._editing = false"
                  ></meta-button>
                </div>
              </div>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </ng-template>
  `,
  styleUrls: ["./metaTask.list.component.less"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaTaskListComponent implements OnInit, OnDestroy, OnChanges {
  private _maParams: MetaTaskList = new MetaTaskList();
  public isLoading: boolean;
  private _destroyed$ = new Subject();
  public isExpanded: {} = {};
  public componentSubtasks = MetaTaskSubtasksComponent;
  public componentTasklist = MetaTaskListComponent;
  public isSaving: boolean;
  public isLoadingMore: boolean;
  public showSubtaskForm: {} = {};
  public subtasksIsExpanded: {} = {};
  public collapsedHeight = 100;

  @Output() public doneTasksChanged = new EventEmitter<number>();
  @ViewChild("titleInput") public titleInput: MetaInputComponent;
  public readonly openTaskCount$ = new BehaviorSubject<number>(0);

  @ViewChildren("goalInput") pizzaInput;

  @Input()
  public get maParams(): MetaTaskList {
    return this._maParams;
  }

  public set maParams(value: MetaTaskList) {
    this._maParams = { ...this._maParams, ...value };
  }

  constructor(
    private _metaTaskService: MetaTaskService,
    private _changeDetectorRef: ChangeDetectorRef,
    public metaTaskRepository: MetaTaskRepository,
    public userService: UserService,
    public modalService: MetaModalService,
    public metaHelperService: MetaHelperService,
  ) {}

  async ngOnInit() {
    this.metaTaskRepository
      .getData$(
        this.maParams.sourceId,
        this.maParams.sourceType,
        this.maParams.dataToShow === "done",
        this.maParams.parentId,
      )
      .pipe(takeUntil(this._destroyed$))
      .subscribe((tasks) => {
        this.doneTasksChanged.emit(tasks.length);
        this.openTaskCount$.next(tasks.length);
      });

    if (!this.maParams.parentId) {
      await this._metaTaskService.getPriorities();
    }

    // load data initially when sourceId is null, because this will not trigger ngOnChanges to load data
    if (this.maParams.sourceId === undefined) {
      this._metaTaskService.getTasks({
        skip: 0,
        take: this.maParams.parentId ? takeSub : this.maParams.dataToShow === "done" ? takeDone : takeOpen,
        parentId: this.maParams.parentId,
        sourceType: this.maParams.sourceType,
        sourceId: this.maParams.sourceId,
        filter: this.maParams.dataToShow,
        taskId: this.maParams.taskId,
      });
    }

    this.metaTaskRepository
      .getData$(
        this.maParams.sourceId,
        this.maParams.sourceType,
        this.maParams.dataToShow === "done",
        this.maParams.parentId,
        this.maParams.dataToShow === "today",
        this.maParams.taskId,
      )
      .pipe(takeUntil(this._destroyed$))
      .subscribe((result) => {
        this.isLoadingMore = false;
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.maParams.currentValue.sourceId) {
      this._metaTaskService.getTasks({
        skip: 0,
        take: this.maParams.parentId ? takeSub : this.maParams.dataToShow === "done" ? takeDone : takeOpen,
        parentId: this.maParams.parentId,
        sourceType: this.maParams.sourceType,
        sourceId: this.maParams.sourceId,
        filter: this.maParams.dataToShow,
        taskId: this.maParams.taskId,
      });
    }
  }

  ngOnDestroy() {
    this._destroyed$.next(null);
    this._destroyed$.complete();
  }

  public onRemoveTask(item: any) {
    this._metaTaskService.deleteTask(item.data);
  }

  public createTask() {
    this.onEditTask();
  }

  public onEditTask(item?: any) {
    const params = {
      id: item?.data.id,
      sourceId: item?.data.sourceId,
      sourceType: item?.data.sourceType,
    };

    this._metaTaskService.openTask(params);
  }

  public async onToggleCheck(item: any) {
    if (this.maParams.parentId || item.data.done) {
      if (item.data.done) {
        await this._metaTaskService.setTaskAsOpen(item.data);
      } else {
        await this._metaTaskService.setTaskAsDone(item.data);
      }
      this._changeDetectorRef.markForCheck();
    } else {
      setTimeout(() => {
        item.data._markAsChecked = !item.data[this.maParams.checkableField];
        this._changeDetectorRef.markForCheck();
        setTimeout(async () => {
          delete item.data._markAsChecked;
          await this._metaTaskService.setTaskAsDone(item.data);
          this._changeDetectorRef.markForCheck();
        }, animationTime);
      }, animationDelay);
    }
  }

  public async onSetPrio(item: any, prio: number) {
    await this._metaTaskService.setTaskPrio(item.data, prio);
  }

  public async loadMore() {
    this.isLoadingMore = true;
    this._changeDetectorRef.markForCheck();
    const data = await firstValueFrom(
      this.metaTaskRepository.getData$(
        this.maParams.sourceId,
        this.maParams.sourceType,
        this.maParams.dataToShow === "done",
        this.maParams.parentId,
        this.maParams.dataToShow === "today",
        this.maParams.taskId,
      ),
    );

    const take = this.maParams.parentId ? takeSub : this.maParams.dataToShow === "done" ? takeDone : takeOpen;
    const skip = data.length;

    await this._metaTaskService.getTasks({
      skip: skip,
      take: take,
      parentId: this.maParams.parentId,
      sourceType: this.maParams.sourceType,
      sourceId: this.maParams.sourceId,
      filter: this.maParams.dataToShow,
    });
    this._changeDetectorRef.markForCheck();
  }

  public isExpandable(item: any) {
    const innerElem = document.getElementById(`inner-description-${item.id}`);
    if (innerElem) {
      return innerElem.offsetHeight > this.collapsedHeight;
    } else {
      return false;
    }
  }

  public toggleExpand = (item: any) => {
    return setTimeout(() => {
      this.isExpanded[item.id] = !this.isExpanded[item.id];
      const innerElem = document.getElementById(`inner-description-${item.id}`);
      const elem = document.getElementById(`description-${item.id}`);
      if (this.isExpanded[item.id]) {
        elem.style.height = `${innerElem.offsetHeight + 30}px`;
      } else {
        elem.style.height = `${this.collapsedHeight}px`;
      }
      this._changeDetectorRef.markForCheck();
    });
  };

  public toggleSubtasks(item: any) {
    this.subtasksIsExpanded[item.id] = !this.subtasksIsExpanded[item.id];
  }

  public toggleSubtaskForm(item: any) {
    this.showSubtaskForm[item.id] = !this.showSubtaskForm[item.id];
  }

  public trackByID = (index: number, item: any) => {
    return item.id;
  };

  public async onSave(item: any) {
    if (item.data.title === undefined || item.data.title === null || item.data.title === "") {
      return;
    }

    this.isSaving = true;
    this._changeDetectorRef.markForCheck();

    const task: TaskDto = await this._metaTaskService.getTask(item.data.id);
    await this._metaTaskService.update(item.data.id, {
      ...task,
      title: item.data.title,
      description: item.data.description,
    });
    this.isSaving = false;
    this._changeDetectorRef.markForCheck();
    item.data._editing = false;
  }
}

@NgModule({
  declarations: [MetaTaskListComponent],
  imports: [
    CommonModule,
    MetaTimelineModule,
    MetaDrodpownModule,
    NzListModule,
    MetaIconModule,
    MetaCheckboxModule,
    FormsModule,
    NzToolTipModule,
    MetaTagModule,
    MetaAvatarModule,
    RouterModule,
    MetaButtonModule,
    PipesModule,
    MetaPillModule,
    DynamicIoModule,
    MetaSectionModule,
    MetaFieldWrapperModule,
    MetaInputModule,
    MetaEditorModule,
    MetaEmptyModule,
    NgxTolgeeModule,
  ],
  exports: [MetaTaskListComponent],
})
export class MetaTaskListModule {}
