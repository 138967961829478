<!--
  ~ Copyright (C) MetaCarp GmbH - All Rights Reserved
  ~ Unauthorized copying of this file, via any medium is strictly prohibited
  ~ Proprietary and confidential
  ~ Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
  ~ Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
  -->




@if (httpError()) {
  @let errorTitle = "components.metaError." + httpError().status + ".title" | translate;
  <meta-toolbar *ngIf="!isWidget" [maParams]="{ label: errorTitle, showBackButton: false }">
  </meta-toolbar>

  <ng-template #subTitleTmpl>
    @if (httpError().status === 403) {
      <div nz-result-subtitle>{{'components.metaError.perm.subTitle' | translate}}</div>
    } @else {
      @if (httpError().error?.message; as message) {
        <div nz-result-subtitle>{{message}}</div>
      } @else {
        <div nz-result-subtitle>{{httpError().error?.message}}</div>
      }
    }

  </ng-template>
  <ng-template #IconTmpl>
    <div nz-result-icon>
      <meta-icon
        style="width: 100%;height: 320px"
        [maParams]="{
        icon: errorImage(),
        width: 'auto',
        size: 'auto',
        trigger: 'loop',
        delay: 0
      }"
      ></meta-icon>
    </div>

  </ng-template>
  <nz-result nzStatus="error" [nzSubTitle]="subTitleTmpl">
    <div nz-result-title>{{errorTitle}}</div>
    <div class="result-extra" nz-result-extra>
      <button (click)="window.history.back()" nz-button nzType="primary">{{'common.back' | translate}}</button>
      <button (click)="contactAdmin()" nz-button nzType="dashed">{{'components.metaError.contactAdmin' | translate}}</button>
    </div>
  </nz-result>
} @else if (missingPermission()) {
  <nz-result
    [nzTitle]="'components.metaError.perm.title' | translate: { name: missingPermission() }"
    nzStatus="error"
    [nzSubTitle]="'components.metaError.perm.subTitle' | translate: { name: missingPermission() }"
  >
    <div class="result-extra" nz-result-extra>
      <button (click)="window.history.back()" nz-button nzType="primary">{{'common.back' | translate}}</button>
      <button (click)="contactAdmin()" nz-button nzType="dashed">{{'components.metaError.contactAdmin' | translate}}</button>
    </div>
  </nz-result>
} @else if (customError()) {
  <nz-result [nzTitle]="customError().title" nzStatus="error" [nzSubTitle]="customError().subTitle">
    @if (customErrorContentString()) {
      <div nz-result-content>
        <p>{{ customErrorContentString() }}</p>
      </div>
    } @else if (customErrorContentTemplate()) {
      <div nz-result-content>
        <ng-container *ngTemplateOutlet="customErrorContentTemplate()" />
      </div>
    }
    @if (customError().extra) {
      <div nz-result-extra>
        <ng-container *ngTemplateOutlet="customError().extra" />
      </div>
    }
  </nz-result>
}
