import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { BehaviorSubject, Observable, of, tap } from "rxjs";

export class SelectData {
  value?: number | string;
  label?: string;

  [params: string]: any;
}

class GetDataParams {
  formId?: string;
  fieldId?: string;
  skip: number;
  take: number;
  filter: string;
  params?: any;
  data?: any;
  tagId?: string;
  contextId?: string;
  selectDataUrl?: string;
  refresh?: boolean;
  reset?: boolean;
  subformPath?: string[];
  index?: number;
  isTableControl?: boolean;
}

@Injectable()
export class MetaSelectService {
  private _selectData: BehaviorSubject<SelectData[]> = new BehaviorSubject([]);
  public readonly selectData$: Observable<SelectData[]> = this._selectData.asObservable();

  constructor(public http: HttpClient) {}

  getData(params: GetDataParams): Observable<SelectData[]> {
    if (!params.selectDataUrl && !params.tagId && !params.formId) {
      return of([]);
    }
    const url =
      params.selectDataUrl ||
      (params.tagId ? `tags/${params.tagId}/selectData` : `forms/${params.formId}/selectData/${params.fieldId}`);

    return this.http
      .post<SelectData[]>(url, {
        skip: params.skip,
        take: params.take,
        searchString: params.filter === null ? "" : params.filter,
        data: params.tagId ? undefined : params.data,
        subFormPath: params.subformPath?.length > 0 ? params.subformPath : undefined,
        index: params.index,
        _ctx: params.contextId,
        params: params.params,
      })
      .pipe(
        tap((data) => {
          this._selectData.next(params.skip > 0 ? this._selectData.getValue().concat(data) : data);
        }),
      );
  }

  setData(data: SelectData[], key: string) {
    this._selectData.next(_.uniqBy(this._selectData.getValue().concat(data), key));
  }

  clearData() {
    this._selectData.next([]);
  }
}
