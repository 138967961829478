import { Subject } from "rxjs";
import { Type, ɵComponentDef, ɵComponentType } from "@angular/core";

// We need this interface override the readonly keyword
// on the properties that we want to re-assign.
export interface ComponentDef<T> extends ɵComponentDef<T> {
  factory: FactoryFn<T>;
  onDestroy: (() => void) | null;
}

export interface FactoryFn<T> {
  <U extends T>(t: ComponentType<U>): U;

  (t?: undefined): T;
}

export type ComponentType<T> = ɵComponentType<T>;

export function getComponentProp<T, K extends keyof T>(t: Type<T>, key: string): T[K] {
  if (t.hasOwnProperty(key)) {
    return t[key];
  }

  throw new Error("No Angular property found for " + t.name);
}

export function renameFunction(fn: () => any | void, newName: string): () => any | void {
  Object.defineProperty(fn, "name", {
    value: newName,
    writable: true
  });

  return fn;
}

/**
 * Automatically unsubscribes from observables in a component class
 * just by adding this: [obs]**`.pipe(takeUntil((this as any).destroyed$))`**[.subscribe()]`
 *
 * Optionally you can add extra actions to perform in ngOnDestroy().
 *
 * @example
 * ```ts
 *
 * @Component({})
 * export class ContainerComponent implements OnInit, OnDestroy {
 *  observable$: Observable<number> = interval(1000);
 *  subscription$$: Subscription;
 *
 *  ngOnInit(): void {
 *    this.subscription$$ = this.observable$
 *      .pipe(takeUntil((this as any).destroyed$))
 *      .subscribe();
 *  }
 *
 *  ngOnDestroy(): void {
 *    console.log('this.subscription$$.closed:::', this.subscription$$.closed);
 *  }
 * }
 * ```
 */
export function MetaUnsubscribe(): any {
  return (cmpType: ComponentType<any>) => {
    const cmp: ComponentDef<typeof cmpType> = getComponentProp(cmpType, "ɵcmp");
    const cmpOndestroy: (() => void) | null = cmp.onDestroy;
    cmpType.prototype.destroyed$ = new Subject<void>();
    // This cannot be an arrow function
    // So that we get the correct context of `this`.
    cmp.onDestroy = function() {
      (this as any).destroyed$.next();
      /**
       * Normally you would pass the method arguments to the function:
       * ```ts
       * cmpOndestroy.apply(this, arguments);
       * ```
       * But ngOnDestroy() does not take any arguments.
       */
      if (cmpOndestroy !== null) {
        cmpOndestroy.apply(this);
      }
    };
  };
}
