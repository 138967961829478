/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { MetaComponentBase } from "../../base/metaComponentBase/metaComponentBase.component";
import { ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormlyModule } from "@ngx-formly/core";
import { MetaFormComponent } from "../../base/metaForm/metaForm.component";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import { Themes } from "@meta/enums";
import { DynamicModule } from "ng-dynamic-component";

export class MetaPageReference {
  formId: string;
  itemId?: string;
  formData?: any;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-page-reference",
  template: `<ng-template [ngComponentOutlet]="component" [ndcDynamicInputs]="inputs"></ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaPageReferenceComponent extends MetaComponentBase {
  public readonly component = MetaFormComponent;
  public inputs;
  public theme: Themes;

  public themes = Themes;
  private icon: HTMLElement;

  get ma(): MetaPageReference {
    return super.ma;
  }
  constructor() {
    super();
    super.maParams = new MetaPageReference();
  }

  async ngOnInit() {
    super.ngOnInit();
    this.inputs = {
      maFormId: this.field.id,
      maItemId: this.ma.itemId,
      maFormData: this.ma.formData || {},
      maLoadedAsSubform: true,
      maHideToolbar: true,
      maLoadDataWithoutItemId: !this.field["hasIdField"],
    };
  }
}

@NgModule({
  declarations: [MetaPageReferenceComponent],
  imports: [CommonModule, FormlyModule, DynamicModule],
  exports: [MetaPageReferenceComponent],
})
export class MetaIconModule {}
