/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */

import { MetaFormComponentInfo } from "@meta/forms";
import { MetaBaseValidator } from "./baseValidator";

export class IsPznValidatorOptions {}

@MetaFormComponentInfo({
  selector: "ma-is-pzn-validator",
  type: "pzn-validator",
})
export class IsPznValidator extends MetaBaseValidator {
  constructor(public options?: IsPznValidatorOptions) {
    super();
  }

  async validate(value: any): Promise<boolean> {
    const pzn = String(value).split("");
    const check = pzn.pop();
    return check === String(pzn.map(Number).reduce((sum, current, index) => sum + current * (index + 1), 0) % 11);
  }
}
