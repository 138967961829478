/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2022
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2022
 */

import { NzAlertComponent } from "ng-zorro-antd/alert";
import { MetaFormComponent } from "../../base/metaForm/metaForm.component";
import { MetaUnsubscribe } from "../../services/metaUnsubscribe.hoc";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  MetaAvatarModule,
  MetaButtonModule,
  MetaCollapseModule,
  MetaEditorModule,
  MetaFieldWrapperModule,
  MetaInputModule,
  MetaRadioModule,
  MetaSectionModule,
  MetaSelectModule,
  MetaSwitchModule,
  MetaTimelineModule,
} from "@meta/ui";
import { MetaLiveCardComponent } from "../metaLiveCard/metaLiveCard.component";
import { FormsModule } from "@angular/forms";
import { MetaColumnModule } from "../metaColumn/metaColumn.component";
import { MetaRowModule } from "../metaRow/metaRow.component";
import { MetaDatepickerModule } from "../metaDatepicker/metaDatepicker.component";
import { firstValueFrom, Subject, takeUntil } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { MetaRadioData } from "../metaRadio/metaRadio.component";
import { UserService } from "../../services/user.service";
import { MetaTaskService } from "./metaTask.service";
import { MetaInputComponent } from "../metaInput/metaInput.component";
import { NzModalRef } from "ng-zorro-antd/modal";
import { MetaTaskSubtaskModule } from "./metaTask.subtasks.component";
import { MetaTaskListModule } from "./metaTask.list.component";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { EntityType } from "@meta/enums";
import { MetaActivityComponent } from "../metaActivity/metaActivity.component";
import { DynamicModule } from "ng-dynamic-component";
import { MetaFilesComponent, MetaFilesModule } from "../metaFiles/metaFiles.component";
import { MetaActivityRepository } from "../metaActivity/metaActivity.repository";
import { filter } from "rxjs/operators";
import { MetaHubService } from "../metaHub/metaHub.service";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import * as moment from "moment";
import { NzCommentModule } from "ng-zorro-antd/comment";

export class MetaTaskForm {
  id?: string;
  sourceId?: string | null = null;
  sourceType?: string | null = null;
  showAdditionalFields?: boolean = false;
  formId?: string;
  isFormlyContext?: boolean;
  onValueChange?: (data) => void;
}

@MetaUnsubscribe()
@Component({
  selector: "meta-task-form",
  template: `
    @if (hasPermissions()) {
      <meta-field-wrapper
        [maParams]="{
          label: 'text',
          labelType: 'icon',
          iconText: 'Aufgabentitel',
          labelWidth: 2,
          inputWidth: 22
        }"
      >
        <meta-input
          #titleInput
          [maParams]="{
            editing: true,
            placeholder: 'Aufgabentitel...',
            disabled: isSaving,
            required: true
          }"
          (keydown.enter)="onSave(true)"
          [(ngModel)]="taskModel.title"
          (ngModelChange)="onModelChange($event)"
        ></meta-input>
      </meta-field-wrapper>

      <meta-field-wrapper
        [maParams]="{
          label: 'align-left',
          labelType: 'icon',
          iconText: 'Beschreibung',
          labelWidth: 2,
          inputWidth: 22
        }"
      >
        <meta-editor
          [maParams]="{
            editing: true,
            placeholder: 'Beschreibung...',
            disabled: isSaving
          }"
          [(ngModel)]="taskModel.description"
          (ngModelChange)="onModelChange($event)"
        ></meta-editor>
      </meta-field-wrapper>

      <meta-collapse
        *ngIf="!maParams.showAdditionalFields; else additionalFieldsTpl"
        [maParams]="{ label: 'Weitere Einstellungen' }"
      >
        <ng-container *ngTemplateOutlet="additionalFieldsTpl"></ng-container>
      </meta-collapse>

      <ng-template #additionalFieldsTpl>
        <meta-field-wrapper
          [maParams]="{
            label: 'user',
            labelType: 'icon',
            iconText: 'Zugewiesene Anwender',
            labelWidth: 2,
            inputWidth: 22
          }"
        >
          <meta-select
            [maParams]="{
              editing: true,
              selectDataUrl: 'shared/users',
              loadDataOnInit: true,
              mode: 'multiple',
              disabled: isSaving
            }"
            [(ngModel)]="taskModel.assignedToUsers"
            (ngModelChange)="onModelChange($event)"
          ></meta-select>
        </meta-field-wrapper>

        <meta-field-wrapper
          [maParams]="{
            label: 'user-group',
            labelType: 'icon',
            iconText: 'Zugewiesene Gruppen',
            labelWidth: 2,
            inputWidth: 22
          }"
        >
          <meta-select
            [maParams]="{
              editing: true,
              selectDataUrl: 'shared/groups',
              loadDataOnInit: true,
              mode: 'multiple',
              disabled: isSaving
            }"
            [(ngModel)]="taskModel.assignedToGroups"
            (ngModelChange)="onModelChange($event)"
          ></meta-select>
        </meta-field-wrapper>

        <meta-field-wrapper
          [maParams]="{
            label: 'clock',
            labelType: 'icon',
            iconText: 'Fälligkeitsdatum',
            labelWidth: 2,
            inputWidth: 22
          }"
        >
          <meta-datepicker
            [maParams]="{
              editing: true,
              datepickerType: 'datetime',
              defaultOpenTimeValue: defaultOpenTimeValue,
              defaultShowTime: false,
              disabled: isSaving
            }"
            [(hasTime)]="taskModel.hasTime"
            [(ngModel)]="taskModel.dueDate"
            (ngModelChange)="onModelChange($event)"
          ></meta-datepicker>
        </meta-field-wrapper>

        <meta-field-wrapper
          [maParams]="{
            label: 'eye',
            labelType: 'icon',
            iconText: 'Öffentlich',
            labelWidth: 2,
            inputWidth: 22
          }"
        >
          <meta-switcher
            [maParams]="{
              editing: true,
              disabled: isSaving
            }"
            [(ngModel)]="taskModel.public"
            (ngModelChange)="onModelChange($event)"
          ></meta-switcher>
        </meta-field-wrapper>

        <meta-field-wrapper
          [maParams]="{
            label: 'flag',
            labelType: 'icon',
            iconText: 'Priorität',
            labelWidth: 2,
            inputWidth: 22
          }"
        >
          <meta-radio
            [maParams]="{
              editing: true,
              data: priorities$ | async,
              style: 'solid',
              disabled: isSaving
            }"
            [(ngModel)]="taskModel.priority"
            (ngModelChange)="onModelChange($event)"
          ></meta-radio>
        </meta-field-wrapper>
      </ng-template>

      <meta-section *ngIf="maParams.id" [maParams]="{ sectionType: 'hr' }"></meta-section>

      @if (maParams.id && maParams.sourceType) {
        <h2>Verknüpfungen</h2>
        <meta-live-card
          [cardType]="maParams.sourceType"
          [cardId]="maParams.sourceId"
          [cardStandalone]="true"
          cardSize="link"
          [cardLazyLoad]="true"
        ></meta-live-card>
      }
      <meta-row *ngIf="maParams.id">
        <meta-column [maParams]="{ span: 24 }">
          <h2>Unteraufgaben</h2>
          <meta-task-list
            [maParams]="{
              sourceId: maParams.sourceId,
              sourceType: maParams.sourceType,
              dataToShow: 'open',
              parentId: maParams.id
            }"
          ></meta-task-list>
          <meta-task-list
            [maParams]="{
              sourceId: maParams.sourceId,
              sourceType: maParams.sourceType,
              dataToShow: 'done',
              parentId: maParams.id
            }"
          ></meta-task-list>
          <meta-task-subtasks
            [maParams]="{ sourceId: maParams.sourceId, sourceType: maParams.sourceType, parentId: maParams.id }"
          ></meta-task-subtasks>
          <meta-section [maParams]="{ sectionType: 'space', spacingBottom: 3 }"></meta-section>
          <h2>Dokumente</h2>
          <meta-files
            #files
            *ngIf="maParams.id"
            [maParams]="{
              itemId: maParams.id,
              entityType: entityType.TASK,
              onAddFiles: onAddFiles.bind(this),
              onRemoveFiles: onRemoveFiles.bind(this)
            }"
          ></meta-files>
          <meta-section [maParams]="{ sectionType: 'space', spacingBottom: 4 }"></meta-section>
          <h2>Kommentare</h2>
          <div class="ant-comment" *ngFor="let comment of comments">
            <div class="ant-comment-inner">
              <div class="ant-comment-avatar">
                <meta-avatar
                  nz-comment-avatar
                  [maParams]="{
                    id: comment.AnwenderAnlage,
                    label: comment.AnwenderAnlage,
                    type: 'ANW',
                    size: 'tiny'
                  }"
                ></meta-avatar>
              </div>
              <div class="ant-comment-content">
                <div class="ant-comment-content-author">
                  <span class="ant-comment-content-author-name">{{ comment.AnwenderName }}</span
                  ><span
                    [nz-tooltip]="comment.DatumAnlage | date: 'fullDate'"
                    class="ant-comment-content-author-name"
                    >{{ moment(comment.DatumAnlage).fromNow() }}</span
                  >
                </div>
                <nz-comment-content class="ant-comment-content-detail">
                  <p [innerHTML]="comment.Notiz"></p>
                </nz-comment-content>
              </div>
            </div>
          </div>
          <meta-button
            *ngIf="!showWriteComment"
            [maParams]="{
              label: 'Kommentar hinzufügen',
              type: 'link',
              icon: 'plus'
            }"
            (click)="showWriteComment = true"
          ></meta-button>
          <div class="write-comment mt-4" *ngIf="showWriteComment">
            <meta-avatar
              [maParams]="{
                id: (userService.user | async)?.id,
                type: 'ANW',
                label: (userService.user | async)?.vorName + ' ' + (userService.user | async)?.nachName,
                size: 'tiny'
              }"
            ></meta-avatar>
            <meta-input
              [maParams]="{
                placeholder: 'Kommentar verfassen...',
                editing: true
              }"
              [(ngModel)]="comment"
              (keydown.enter)="onSaveComment(); $event.target.blur()"
              [style.width.%]="100"
              class="pl-2"
            ></meta-input>
          </div>
        </meta-column>
      </meta-row>

      <meta-row *ngIf="!isModal && !maParams.isFormlyContext">
        <meta-column [maParams]="{ span: 2 }"> </meta-column>
        <meta-column [maParams]="{ span: 22 }">
          <meta-button
            [maParams]="{
              fullWidth: true,
              type: 'primary',
              label: 'Aufgabe speichern',
              loading: isSaving,
              disabled: !taskModel.title
            }"
            (click)="onSave()"
          ></meta-button>
        </meta-column>
      </meta-row>
    } @else {
      <nz-alert
        nzType="warning"
        nzMessage="Keine Berechtigungen"
        nzDescription="Du hast leider keine Berechtigung, diese Funktion zu nutzen. Bitte wende dich an deinen Administrator."
        nzShowIcon
      ></nz-alert>
    }
  `,
  styleUrls: ["./metaTask.form.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaTaskFormComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  private _maParams: MetaTaskForm = new MetaTaskForm();
  public entityType = EntityType;
  public component = MetaActivityComponent;
  public comment: string;
  public comments: any[] = [];
  public showWriteComment: boolean;
  public isSavingComment: boolean;
  public isLoadingComments: boolean;
  public taskModel = {
    title: null,
    description: null,
    assignedToUsers: [],
    assignedToGroups: [],
    dueDate: null,
    priority: 4,
    hasTime: false,
    sourceId: null,
    sourceType: null,
    done: false,
    public: false,
  };
  public isSaving: boolean;
  public defaultOpenTimeValue: Date = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
  public priorities$: Promise<MetaRadioData[]>;
  public isModal = false;
  private destroy$ = new Subject<any>();
  public moment = moment;

  public readonly hasPermissions = computed(() => {
    return this._userService.userFeatures()?.task;
  });

  @ViewChild("files") public files: MetaFilesComponent;

  @ViewChild("titleInput") public titleInput: MetaInputComponent;

  @Input()
  public get maParams(): MetaTaskForm {
    return this._maParams;
  }

  public set maParams(value: MetaTaskForm) {
    this._maParams = { ...this._maParams, ...value };
  }

  constructor(
    public metaActivityRepository: MetaActivityRepository,
    private _http: HttpClient,
    private _userService: UserService,
    private _metaTaskService: MetaTaskService,
    private _changeDetectorRef: ChangeDetectorRef,
    public userService: UserService,
    @Optional() public modalRef: NzModalRef,
  ) {
    if (modalRef) {
      if (!(modalRef.componentInstance instanceof MetaFormComponent)) {
        this.isModal = true;
      }
    }
  }

  async ngOnInit() {
    this.priorities$ = firstValueFrom(this._http.post<MetaRadioData[]>("task/select-data/priorities", {}));
    if (this.maParams.id) {
      this.taskModel = await this._metaTaskService.getTask(this.maParams.id);
      this._changeDetectorRef.markForCheck();
    } else {
      this.taskModel.assignedToUsers.push(this._userService.user.value.id);
      this.taskModel.sourceId = this.maParams.sourceId;
      this.taskModel.sourceType = this.maParams.sourceType;
    }

    if (this.isModal) {
      this.modalRef.containerInstance.onOkClick = async () => {
        await this.onSave();
        this.modalRef.close();
      };

      this.isLoadingComments = true;
      this.metaActivityRepository
        .loadData({
          fieldId: this.maParams.id,
          type: EntityType.NOTE,
          quellId: this.maParams.id,
          quellTyp: this.entityType.TASK,
          condition: {
            skip: 0,
            take: 500,
          },
        })
        .subscribe()
        .add(() => {
          this.isLoadingComments = false;
          this._changeDetectorRef.markForCheck();
        });

      this.metaActivityRepository
        .getData$(EntityType.NOTE, this.maParams.id)
        .pipe(
          takeUntil(this.destroy$),
          filter((x) => x !== undefined),
        )
        .subscribe((data) => {
          data = data.filter((e) => e.quellId === this.maParams.id);
          this.comments = data.sort(function (a: any, b: any) {
            if (a.DatumAnlage < b.DatumAnlage) return -1;
            if (a.DatumAnlage > b.DatumAnlage) return 1;
            return 0;
          });
          this.isSavingComment = false;
          this.showWriteComment = false;
          this.comment = null;
          this._changeDetectorRef.markForCheck();
        });
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.maParams.currentValue.sourceId) {
      this.maParams.sourceId = changes.maParams.currentValue.sourceId;
      this.resetForm();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public ngAfterViewInit() {}

  public async onSave(triggeredThroughEnter?: boolean) {
    if (triggeredThroughEnter && this.isModal) {
      return;
    }

    if (this.taskModel.title === undefined || this.taskModel.title === null || this.taskModel.title === "") {
      return;
    }

    this.isSaving = true;
    this._changeDetectorRef.markForCheck();

    if (this.taskModel.assignedToUsers === null) {
      this.taskModel.assignedToUsers = [];
    }

    if (this.taskModel.assignedToGroups === null) {
      this.taskModel.assignedToGroups = [];
    }

    if (this.maParams.id) {
      await this._metaTaskService.update(this.maParams.id, {
        ...this.taskModel,
      });
    } else {
      await this._metaTaskService.create({
        ...this.taskModel,
      });
    }
    this.resetForm();
    this.isSaving = false;
    this._changeDetectorRef.markForCheck();
    this.titleInput.setFocus();
  }

  private resetForm() {
    this.taskModel = {
      title: null,
      description: null,
      assignedToUsers: [this._userService.user.value.id],
      assignedToGroups: [],
      dueDate: null,
      priority: 4,
      hasTime: false,
      sourceId: this.maParams.sourceId,
      sourceType: this.maParams.sourceType,
      done: false,
      public: false,
    };
  }

  public async onAddFiles(files?: any[]) {
    await this._metaTaskService.assignFiles(files, this.maParams.id, this.entityType.TASK);
    await this.files.refreshData();
  }

  public onRemoveFiles(file?: string) {
    this._metaTaskService.unassignFiles(file, this.maParams.id, this.entityType.TASK);
  }

  public onSaveComment() {
    this.isSavingComment = true;
    this.metaActivityRepository
      .createNote(
        {
          fieldId: this.maParams.id,
          type: EntityType.NOTE,
          quellId: this.maParams.id,
          quellTyp: this.entityType.TASK,
          condition: {
            skip: 0,
            take: 500,
          },
        },
        this.comment,
      )
      .subscribe();
  }

  public onModelChange(data) {
    if (this.maParams.onValueChange instanceof Function) {
      this.maParams.onValueChange(this.taskModel);
    }
  }
}

@NgModule({
  declarations: [MetaTaskFormComponent],
  imports: [
    CommonModule,
    MetaInputModule,
    MetaEditorModule,
    MetaButtonModule,
    FormsModule,
    MetaFieldWrapperModule,
    MetaSelectModule,
    MetaCollapseModule,
    MetaColumnModule,
    MetaRowModule,
    MetaDatepickerModule,
    MetaTaskSubtaskModule,
    MetaSectionModule,
    MetaTaskListModule,
    NzTabsModule,
    DynamicModule,
    MetaFilesModule,
    MetaTimelineModule,
    MetaAvatarModule,
    NzToolTipModule,
    NzCommentModule,
    MetaLiveCardComponent,
    MetaRadioModule,
    MetaSwitchModule,
    NzAlertComponent,
  ],
  providers: [MetaHubService],
  exports: [MetaTaskFormComponent],
})
export class MetaTaskFormModule {}
