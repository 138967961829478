/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2024
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2024
 */
import { MetaBaseValidator } from "./baseValidator";
import * as validator from "class-validator";
import { MetaFormComponentInfo } from "../components";

export class IsLocaleValidatorOptions {}

@MetaFormComponentInfo({
  selector: "ma-is-locale-validator",
  type: "is-locale-validator",
})
export class IsLocaleValidator extends MetaBaseValidator {
  constructor(public options?: IsLocaleValidatorOptions) {
    super();
  }

  public static createElement(attributes: IsLocaleValidatorOptions) {
    return new this(attributes);
  }

  async validate(value: string): Promise<boolean> {
    return validator.isLocale(value);
  }
}
